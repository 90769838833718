import React, { FC, ReactNode, useState } from "react";
import styles from "./WithTooltip.module.scss";
import classNames from "classnames";

export enum TooltipPosition {
    BOTTOM = 'BOTTOM',
    TOP = 'TOP'
}

export enum TooltipAlignment {
    LEFT = 'LEFT',
    CENTER = 'CENTER',
    RIGHT = 'RIGHT',
}

export enum TooltipWidth {
    FIT_CONTENT = 'FIT_CONTENT',
    WIDTH_2OREM = 'WIDTH_2OREM',
    XXXLARGE = 'XXXLARGE'
}
interface TooltipProps {
    tooltip: ReactNode;
    position?: TooltipPosition
    alignment?: TooltipAlignment
    width?: TooltipWidth
}

const WithTooltip: FC<TooltipProps> = (
    {
        position = TooltipPosition.TOP,
        alignment = TooltipAlignment.CENTER,
        width = TooltipWidth.FIT_CONTENT,
        ...props
    }): JSX.Element => {
    const [isShown, setIsShown] = useState(false);

    return <div className={classNames(styles.tooltipWrapper)}
                onMouseOver={(): void => setIsShown(true)}
                onMouseOut={(): void => setIsShown(false)}
    >
        {props.children}
        {isShown && <div className={classNames(styles.tooltip, {
            [styles.tooltipPositionTop]: position === TooltipPosition.TOP,
            [styles.tooltipPositionBottom]: position === TooltipPosition.BOTTOM,

            [styles.tooltipAlignmentLeft]: alignment === TooltipAlignment.LEFT,
            [styles.tooltipAlignmentRight]: alignment === TooltipAlignment.RIGHT,
            [styles.tooltipAlignmentCenter]: alignment === TooltipAlignment.CENTER,

            [styles.tooltipWidthXXXLarge]: width === TooltipWidth.XXXLARGE,
            [styles.tooltipWidthFitContent]: width === TooltipWidth.FIT_CONTENT,
            [styles.tooltipWidthWIDTH_2OREM]: width === TooltipWidth.WIDTH_2OREM
        })}>
            {props.tooltip}
            <div className={
                classNames(styles.tooltipArrow, {
                    [styles.tooltipArrowUp]: position === TooltipPosition.BOTTOM,
                    [styles.tooltipArrowDown]: position === TooltipPosition.TOP,
                })}
            />
        </div>}
    </div>
}

export default WithTooltip;