import { ActionDispatcher } from "actions/ActionInterface";
import { initNavigation } from "actions/common/ConnectedCarousel";
import { fetchPatientReports } from "actions/modular/modules/ReportsActions";
import ReportsList, { ReportsListRow } from "components/commonDesign/ReportsList";

import ReportsTable, { ReportsTableRow } from "components/commonDesign/ReportsTable";

import { TABLET_MAX_WIDTH } from "components/core/constants";
import { WhiteBoxWithShadow } from "components/designSystem/containers/Box";
import { TDisplayXSmall, TypographyColor } from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";

import BaseModule, {
    defaultBaseModuleState,
    ModuleState,
    OwnProps,
    setupModule
} from 'components/modules/modular/BaseModule';
import { reportPage } from "components/modules/modular/pages/builders";
import { capitalizeFirstLetter } from "core/utils/text";
import { APIText } from "models/common/message";
import { Entity } from 'models/entities/default';
import { ReportMetadata, ReportMetadataNormality } from "models/medicalReport/ReportModels";
import { ReportInfo } from "models/modular/report";
import { ModuleData, OwnPayloadConstraint } from "models/modular/storage";
import React, { ReactNode } from "react";
import MediaQuery from "react-responsive";

const REPORT_PER_PAGE_DEFAULT = 5;

export interface PreviousReportsModulePayload {
    title: APIText;
    patient_uuid: string;
    reports_per_page: number;
    target_report_uuid: string
}

export interface PreviousReportsModuleDispatchProps {
    getReports: (patientUUID: string) => void;
    setupNavigation: (identifier: string, defaultItems: string[]) => void;
}

interface PreviousReportsState {
    activePage:number;
}

interface OwnPayload extends OwnPayloadConstraint {
    reportsByPatientUUID: {
        [uuid: string]: ReportInfo[];
    }
    metadataByReportUUID: {
        [uuid: string]: ReportMetadata,
    }
}

class PreviousReportsModule extends BaseModule<PreviousReportsModuleDispatchProps, PreviousReportsModulePayload, OwnPayload >{

    componentDidMount() : void {
        const patientUUID = this.props.payload?.pagePayload.patient_uuid
        if (patientUUID){
            this.props.getReports(patientUUID);
        }
    }

    getEntity = (reportUuid: string): Entity | undefined => {
        return this.props.payload?.ownPayload.metadataByReportUUID?.[reportUuid]?.entity
    }

    getNormalityCount = (reportUuid: string): {[k in ReportMetadataNormality]?: number | null} | undefined => {
        return this.props.payload?.ownPayload.metadataByReportUUID?.[reportUuid]?.report_data_normality_count
    }

    state: ModuleState<PreviousReportsState> = defaultBaseModuleState(
        {
            activePage:1,
        }
    )



    goToReport = (
        uuid: string,
        patientUUID: string,
        reports: string[]
    ): void => {
        const navigationKey = 'report_patient_' + patientUUID
        this.props.setupNavigation(
            navigationKey,
            reports
        );
        this.props.changePage(reportPage(uuid, navigationKey, uuid == this.props.payload?.pagePayload.target_report_uuid))
    }

    protected _render(payload: ModuleData<PreviousReportsModulePayload, OwnPayload>): ReactNode {
        const reportsByPatientUUID = payload?.ownPayload.reportsByPatientUUID
        if (!reportsByPatientUUID) {
            return this.loading()
        }

        const patientUUID = payload.pagePayload.patient_uuid
        const reports = reportsByPatientUUID && reportsByPatientUUID[patientUUID] || [];
        const reportUUIDs = reports.map((report: ReportInfo) => report.uuid)

        const buildVisibleRows = (): ReportInfo[] => {
            return payload.pagePayload.reports_per_page ?  reports.slice(0, payload.pagePayload.reports_per_page ) :
                reports.slice(0, REPORT_PER_PAGE_DEFAULT )

        }

        return(
            <WhiteBoxWithShadow>
                <WithMargin margins={[MarginSize.LARGE, undefined, MarginSize.LARGE, MarginSize.MEDIUM]}>
                    <TDisplayXSmall color={TypographyColor.COLOR_TEXT_DEFAULT}>
                        {this.transApiText(payload.pagePayload.title, capitalizeFirstLetter)}
                    </TDisplayXSmall>
                </WithMargin>
                <MediaQuery maxWidth={TABLET_MAX_WIDTH}>
                    <ReportsList
                        rows={buildVisibleRows().map((r: ReportInfo) => ({
                            uuid: r.uuid,
                            laboratory: this.getEntity(r.uuid),
                            samplingDate: (new Date(r.samplingDate)),
                            indicators: [],
                            isNew: r.isNew,
                            normalityCount: this.getNormalityCount(r.uuid)
                        }))}
                        onRowClick={(r: ReportsListRow): void =>
                            this.goToReport(
                                r.uuid,
                                patientUUID,
                                reportUUIDs,
                            )
                        }
                        withoutHeader
                    />
                </MediaQuery>
                <MediaQuery minWidth={TABLET_MAX_WIDTH + 1}>
                    <ReportsTable
                        rows={buildVisibleRows().map((r: ReportInfo) => ({
                            uuid: r.uuid,
                            laboratory: this.getEntity(r.uuid),
                            samplingDate: (new Date(r.samplingDate)),
                            receptionDate: (new Date(r.receptionDate)),
                            indicators: [],
                            isNew: r.isNew,
                            isSent: r.isSent,
                            isAiSuggestionsEnriched: r.isAiSuggestionsEnriched,
                            normalityCount: this.getNormalityCount(r.uuid)
                        }))}
                        onRowClick={(r: ReportsTableRow): void =>
                            this.goToReport(
                                r.uuid,
                                patientUUID,
                                reportUUIDs,
                            )
                        }
                        minimizedVersion
                        isDoctor={false}
                    />
                </MediaQuery>
            </WhiteBoxWithShadow>
        )
    }
}

const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (
    dispatch: ActionDispatcher,
    ownProps: OwnProps
): PreviousReportsModuleDispatchProps => ({
    getReports: (patientUUID: string): void => {
        dispatch(fetchPatientReports(
            ownProps.identifier,
            [patientUUID]
        ))
    },
    setupNavigation: (identifier: string, defaultItems: string[]): void => dispatch(
        initNavigation(identifier, defaultItems)
    ),
});

export default setupModule(PreviousReportsModule, mapStateToProps, mapDispatchToProps);