import React from 'react';

import { ChartFilter, ChartFilterMapAreaSelected, ChartFilterType, ChartItem } from 'models/charts/charts';
import {ExtendedMapPayload, MapLegendItem, MapType} from 'models/charts/charts/Map';
import {TranslationMethod} from 'models/common/message';
import Container, {ContainerProfile, MarginType} from 'components/legacyDesignSystem/components/Container';
import {ChartItemMapArea, ChartItemMapData} from 'models/charts/items/MapArea';
import {cast} from 'core/utils/Typed';
import {computeDensityColor} from 'models/common/styles';
import Typography, {TypographyVariant, TypographyWeight} from 'components/legacyDesignSystem/components/Typography';
import {buildFrancePath, franceAreas} from 'components/legacyDesignSystem/components/charts/builders/maps/France';
import {Colors} from 'components/legacyDesignSystem/branding/constants';
import SimpleCard, {SimpleCardStyle} from 'components/legacyDesignSystem/components/cards/SimpleCard';

import 'components/commonDesign/charts/builders/CustomMap.scss';
import { chartColorsToHexCode } from 'components/legacyDesignSystem/components/charts/models';
import { ValueFormatterInterface } from 'models/charts/models';

export interface MapPath {
    id: string;
    color: string;
    handleAreaHover?: (id?: string) => void;
    handleAreaClick?: (id: string) => void,
}

function buildMapArea(
    item: ChartItemMapArea,
    payload: ExtendedMapPayload,
    handleAreaHover?: (id?: string) => void,
    selectedAera?: string,
    handleAreaClick?: (id: string) => void,
): JSX.Element {
    switch (payload.type) {
        case MapType.FRANCE:
            return (
                <>
                    {buildFrancePath({
                        id: item.map_area_id,
                        handleAreaHover,
                        handleAreaClick: item.prevent_click ? undefined : handleAreaClick,
                        color:
                            item.map_area_id !== selectedAera && selectedAera != null
                                ? computeDensityColor(payload.density_color, item.density, 0.2)
                                : computeDensityColor(payload.density_color, item.density),
                    })}
                </>
            );
        default:
            return <></>;
    }
}

function buildLegend(
    payload: ExtendedMapPayload,
    translator: TranslationMethod,
): JSX.Element {
    return (
        <Container profiles={[ContainerProfile.FLEX]} className={'custom-map__legend-container'}>
            <Container
                profiles={[
                    ContainerProfile.FLEX_COLUMN,
                    ContainerProfile.SPACE_BETWEEN,
                    ContainerProfile.ALIGN_FLEX_END,
                ]}
            >
                {payload.legend.map((item: MapLegendItem, i) => (
                    <Typography key={i} variant={TypographyVariant.OVERLINE} weight={TypographyWeight.BOLD}>
                        {translator(item.label)}
                    </Typography>
                ))}
            </Container>
            <div
                className="custom-map__legend-bar"
                style={{
                    background: `linear-gradient(${computeDensityColor(
                        payload.density_color,
                        payload.reversed ? 1 : 10,
                    )}, ${computeDensityColor(payload.density_color, payload.reversed ? 10 : 1)})`,
                }}
            ></div>
        </Container>
    );
}

function buildMap(
    items: ChartItem[],
    payload: ExtendedMapPayload,
    translator: TranslationMethod,
    filters?: ChartFilter[],
    valueFormatter?: ValueFormatterInterface
): JSX.Element {
    switch (payload.type) {
        case MapType.FRANCE:
            // eslint-disable-next-line no-case-declarations
            const chartItemsAreaIds: string[] = [];
            // eslint-disable-next-line no-case-declarations
            const tooltips: JSX.Element[] = [];

            // eslint-disable-next-line no-case-declarations
            let selectedArea: string | undefined = payload.selected_area;

            if (filters) {
                filters.filter((f) => f.type === ChartFilterType.MAP_AREA_SELECTED).forEach(
                    (f) => {
                        selectedArea = cast<ChartFilterMapAreaSelected>(f.payload).areaId
                    }
                )
            }
            // eslint-disable-next-line no-case-declarations
            const chartItemOutput = items.map((item: ChartItem): JSX.Element => {
                const chartItemMap = cast<ChartItemMapArea>(item.payload);

                chartItemsAreaIds.push(item.unique_id);

                if (chartItemMap.map_area_id === selectedArea && payload.tooltipX != null && payload.tooltipY != null ) {
                    tooltips.push(
                        <Container
                            key={item.unique_id}
                            className={'custom-map__tooltip'}
                            style={{ top: payload.tooltipY, left: payload.tooltipX, zIndex: 200 }}
                        >
                            <SimpleCard style={SimpleCardStyle.WHITE} withBackground>
                                {(chartItemMap.title || chartItemMap.value) && (
                                    <Container
                                        className={'custom-map__tooltip-container'}
                                        profiles={[
                                            ContainerProfile.FLEX,
                                            ContainerProfile.SPACE_BETWEEN,
                                            ContainerProfile.ALIGN_CENTER,
                                            ContainerProfile.WHITESPACE_NOWRAP,
                                            { type: MarginType.MB, value: 4 },
                                        ]}
                                    >
                                        {chartItemMap.title && (
                                            <Typography
                                                variant={TypographyVariant.OVERLINE}
                                                weight={TypographyWeight.BOLD}
                                                color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                            >
                                                {translator(chartItemMap.title)}
                                            </Typography>
                                        )}
                                        {chartItemMap.value && (
                                            <Typography
                                                variant={TypographyVariant.XSMALL}
                                                color={Colors.NEUTRALS_BLUE_GREY}
                                            >
                                                {valueFormatter ? valueFormatter(chartItemMap.value.value) : chartItemMap.value.raw_full_display}
                                            </Typography>
                                        )}
                                    </Container>
                                )}
                                {chartItemMap.data && chartItemMap.data.length > 0 && (
                                    <Container
                                        profiles={[ContainerProfile.FLEX_COLUMN]}
                                        className={'custom-map__tooltip-rows'}
                                    >
                                        {chartItemMap.data.map(
                                            (data: ChartItemMapData, i: number): JSX.Element => (
                                                <Container
                                                    key={i}
                                                    profiles={[ContainerProfile.FLEX]}
                                                    className={'custom-map__tooltip-row'}
                                                >
                                                    {data.color && (
                                                        <Container>
                                                            <span
                                                                className={'custom-map__tooltip-color'}
                                                                style={{ backgroundColor: chartColorsToHexCode(data.color) }}
                                                            />
                                                        </Container>
                                                    )}
                                                    <Container>
                                                        <Typography
                                                            variant={TypographyVariant.XSMALL}
                                                            color={Colors.NEUTRALS_BLUE_GREY}
                                                        >
                                                            {translator(data.title)}
                                                        </Typography>
                                                        <Typography
                                                            variant={TypographyVariant.XSMALL}
                                                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                                        >

                                                            {valueFormatter && data.value ? valueFormatter(data.value) : data.value.raw_full_display}
                                                        </Typography>
                                                    </Container>
                                                </Container>
                                            ),
                                        )}
                                    </Container>
                                )}
                            </SimpleCard>
                        </Container>,
                    );
                }

                return (
                    <React.Fragment key={item.unique_id}>
                        {buildMapArea(
                            cast<ChartItemMapArea>(item.payload),
                            payload,
                            payload.onAreaHover,
                            selectedArea,
                            payload.onAreaClick
                        )}
                    </React.Fragment>
                );
            });

            // eslint-disable-next-line no-case-declarations
            const defaultAreasOutput = franceAreas
                .filter((item) => !chartItemsAreaIds.includes(item))
                .map((id) => (
                    <React.Fragment key={id}>
                        {buildFrancePath({
                            id,
                            color: '#EEE',
                        })}
                    </React.Fragment>
                ));

            return (
                <Container
                    profiles={[ContainerProfile.FLEX_GROW_1, ContainerProfile.RELATIVE]}
                    onMouseMove={(e): void => {
                        const bounds = e.currentTarget.getBoundingClientRect();
                        payload.onMapHover && payload.onMapHover(e.clientX - bounds.left + 10, e.clientY - bounds.top + 10);
                    }}

                    onMouseLeave={(): void => {
                        payload.onMapHover && payload.onMapHover(undefined, undefined)
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                        {defaultAreasOutput}
                        {chartItemOutput}

                    </svg>
                    {tooltips}
                </Container>
            );
        default:
            return <div>Unsupported map</div>;
    }
}

export function buildCustomMap(
    items: ChartItem[],
    payload: ExtendedMapPayload,
    translator: TranslationMethod,
    filters?: ChartFilter[],
    valueFormatter?: ValueFormatterInterface
): JSX.Element {
    return (
        <Container className={'custom-map'} profiles={[ContainerProfile.FLEX]}>
            <Container profiles={[ContainerProfile.FLEX_GROW_1]}>{buildMap(items, payload, translator, filters, valueFormatter)}</Container>
            <Container>{buildLegend(payload, translator)}</Container>
        </Container>
    );
}
