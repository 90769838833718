import React from "react";
import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const WarningTriangle = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-warning-triangle'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_180_225)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M9.34164 0.82918C9.08755 0.321004 8.56816 0 8 0C7.43184 0 6.91245 0.321004 6.65836 0.82918L0.158361 13.8292C-0.0741305 14.2942 -0.0492845 14.8464 0.224025 15.2886C0.497335 15.7308 0.980135 16 1.5 16H14.5C15.0199 16 15.5027 15.7308 15.776 15.2886C16.0493 14.8464 16.0741 14.2942 15.8416 13.8292L9.34164 0.82918ZM1.5 14.5L8 1.5L14.5 14.5H1.5ZM8 4.25C8.41421 4.25 8.75 4.58579 8.75 5V10C8.75 10.4142 8.41421 10.75 8 10.75C7.58579 10.75 7.25 10.4142 7.25 10V5C7.25 4.58579 7.58579 4.25 8 4.25ZM9 12.5C9 13.0523 8.55229 13.5 8 13.5C7.44772 13.5 7 13.0523 7 12.5C7 11.9477 7.44772 11.5 8 11.5C8.55229 11.5 9 11.9477 9 12.5Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_180_225">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-warning-triangle'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.7889 2.10557C13.4501 1.428 12.7575 1 12 1C11.2425 1 10.5499 1.428 10.2111 2.10557L1.21115 20.1056C0.901159 20.7255 0.934287 21.4618 1.2987 22.0515C1.66311 22.6411 2.30685 23 3 23H21C21.6932 23 22.3369 22.6411 22.7013 22.0515C23.0657 21.4618 23.0988 20.7255 22.7889 20.1056L13.7889 2.10557ZM3 21L12 3L21 21H3ZM12 7C12.5523 7 13 7.44772 13 8V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V8C11 7.44772 11.4477 7 12 7ZM13.25 18.25C13.25 18.9404 12.6904 19.5 12 19.5C11.3096 19.5 10.75 18.9404 10.75 18.25C10.75 17.5596 11.3096 17 12 17C12.6904 17 13.25 17.5596 13.25 18.25Z"
                  fill="currentColor"/>
        </svg>
    )
}
