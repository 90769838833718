import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import 'components/legacyDesignSystem/components/PdfViewer.scss';
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";


export interface PdfViewerProps {
    link: string;
}

export const PdfViewer = (props: PdfViewerProps): JSX.Element => {
    const [nbPages, setNbPages] = useState<number>()

    return (
        <Document
            className={"pdf-viewer-container"}
            file={{
                url: props.link,
                withCredentials: true,
            }}
            onLoadSuccess={({ numPages }: { numPages: number }): void => {
                nbPages !== numPages && setNbPages(numPages)
            }}
            loading={
                <ModuleLoaderWrapper>
                    <div className={"module-loading module_V_NORMAL"}/>
                </ModuleLoaderWrapper>
            }
        >
            {Array.from(new Array(nbPages), (el, index) => (
                <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    className={'pdf-viewer-page'}
                    scale={1.5}
                    renderAnnotationLayer={false}
                />
            ))}
        </Document>
    )
}
