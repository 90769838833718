import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Syringe = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-syringe'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_451_178)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M10.4379 0.806343C10.7308 0.513448 11.2057 0.513445 11.4986 0.806336L14.9623 4.26996C15.2552 4.56285 15.2552 5.03772 14.9623 5.33062C14.6694 5.62352 14.1945 5.62352 13.9016 5.33063L13.5214 4.9504L12.236 6.23584L13.2303 7.23017C13.5232 7.52306 13.5232 7.99793 13.2303 8.29083C12.9374 8.58372 12.4625 8.58372 12.1696 8.29083L7.46588 12.9618C6.41159 14.009 4.79766 14.1512 3.59144 13.3883L2.12457 14.8552C1.83167 15.1481 1.3568 15.1481 1.06391 14.8552C0.771014 14.5623 0.771016 14.0874 1.06391 13.7945L2.55162 12.3068C1.85834 11.1076 2.02679 9.54636 3.05698 8.5231L7.7452 3.8664L7.65424 3.77545C7.36135 3.48255 7.36135 3.00768 7.65424 2.71479C7.94713 2.42189 8.42201 2.42189 8.7149 2.71479L9.76465 3.76453L11.0501 2.47912L10.4379 1.867C10.145 1.57411 10.145 1.09924 10.4379 0.806343ZM11.1753 5.17518L10.8253 4.82519L12.1107 3.53977L12.4607 3.88975L11.1753 5.17518ZM5.26059 8.44851L6.02909 7.68518L7.52052 9.17667C7.71578 9.37193 8.03237 9.37194 8.22763 9.17668C8.4229 8.98142 8.4229 8.66484 8.22764 8.46957L6.73858 6.98046L7.36598 6.35728L8.855 7.84634C9.05026 8.04161 9.36684 8.04162 9.5621 7.84636C9.75737 7.6511 9.75738 7.33452 9.56212 7.13925L8.07547 5.65255L8.60471 5.12687L10.9051 7.43155L6.4088 11.8976C5.77388 12.5283 4.74899 12.5283 4.11406 11.8976C3.47305 11.2609 3.47305 10.224 4.11406 9.58733L4.5511 9.15323L6.05129 10.6535C6.24655 10.8487 6.56313 10.8487 6.75839 10.6535C6.95366 10.4582 6.95366 10.1416 6.75841 9.94638L5.26059 8.44851Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_451_178">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-syringe'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.787 0.629568C16.1776 0.239041 16.8107 0.239037 17.2012 0.629558L22.7069 6.13513C23.0974 6.52565 23.0974 7.15881 22.7069 7.54934C22.3164 7.93987 21.6832 7.93987 21.2927 7.54935L20.5527 6.80937L18.2378 9.12426L19.9541 10.8405C20.3446 11.231 20.3446 11.8642 19.9541 12.2547C19.5635 12.6452 18.9304 12.6452 18.5399 12.2547L18.4035 12.1184L10.7916 19.6791C9.14301 21.3166 6.59895 21.5059 4.74329 20.2471L2.30134 22.689C1.91081 23.0795 1.27765 23.0795 0.887128 22.689C0.496605 22.2984 0.496609 21.6653 0.887135 21.2748L3.35765 18.8043C2.21145 16.9563 2.44367 14.496 4.05432 12.8962L11.6433 5.35819L11.3625 5.07735C10.9719 4.68682 10.9719 4.05366 11.3625 3.66313C11.753 3.27261 12.3862 3.27261 12.7767 3.66313L14.5812 5.46762L16.896 3.15275L15.787 2.04378C15.3965 1.65326 15.3965 1.02009 15.787 0.629568ZM16.8236 7.71005L15.9954 6.88183L18.3102 4.56695L19.1385 5.39516L16.8236 7.71005ZM7.25403 12.5369L8.53919 11.2604L11.0457 13.767C11.3386 14.0599 11.8134 14.0599 12.1063 13.767C12.3992 13.4741 12.3993 12.9992 12.1064 12.7063L9.60342 10.2033L10.6642 9.1496L13.167 11.6525C13.4599 11.9454 13.9348 11.9454 14.2277 11.6525C14.5206 11.3596 14.5206 10.8847 14.2277 10.5918L11.7285 8.09252L12.7378 7.08997L16.6659 11.0254L9.38217 18.2601C8.29799 19.337 6.54792 19.337 5.46375 18.2601C4.36918 17.1729 4.36918 15.4023 5.46375 14.3151L6.1898 13.594L8.71023 16.1145C9.00312 16.4074 9.478 16.4074 9.7709 16.1145C10.0638 15.8216 10.0638 15.3468 9.77091 15.0539L7.25403 12.5369Z"
                  fill="currentColor"/>
        </svg>
    )
}