import { API_RATING_RATE, getFeedbackIdentifier, rate, RateAction } from "actions/common/CommonActions";
import TrText from 'components/common/TrText';
import Alert, { AlertSeverity } from "components/designSystem/components/Alert";
import Button, { ButtonOption, ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import LikeDislikeRating from "components/designSystem/components/ratings/LikeDislikeRating";
import StarsRating from "components/designSystem/components/ratings/StarsRating";
import { WhiteBoxWithShadow } from "components/designSystem/containers/Box";
import Centered from "components/designSystem/containers/Centered";
import Inline, {
    InlineAlignItem,
    InlineJustifyContent,
    InlineOverflow
} from "components/designSystem/containers/Inline";
import { LargeSpacer, SmallSpacer } from "components/designSystem/containers/Spacer";
import { CenteredText } from "components/designSystem/containers/TextJustifier";
import { TBody, TCaption, TDisplayXSmall, TypographyColor } from "components/designSystem/containers/Typography";
import WithPadding, { PaddingSize } from "components/designSystem/containers/WithPadding";
import { IconName } from "components/designSystem/foundations/IconsData";
import { useModuleAttributes, useModulePayload } from "components/modules/modular/ModuleContainer";
import APIFeedbackMessageContainer from "components/pages/common/APIFeedbackMessage";
import { TransDomain } from "components/pages/common/MainComponent";
import { cast } from "core/utils/Typed";
import { Namespace, TFunction } from "i18next";
import { APIFeedbackMessage, APIFeedbackMessageType } from "models/common/APIMessaging";
import { APIText, toLocalTransKey } from "models/common/message";
import { RatingType } from "models/medicalReport/LegacyReportModels";
import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export const MODULE_RATING_PREFIX_ = 'MODULE_RATING_PREFIX_'

interface SurveyLinkPayload {
    thank_you_note: APIText
    link: string
}

interface RatingModulePayload {
    type: RatingType
    title: APIText
    sub_title: APIText | undefined
    token: string
    survey_link?: string // Depreciated used to not break SPA
    survey_payload?: SurveyLinkPayload
}

const TRANS_SUFFIX = TransDomain.MEDICAL_REPORT + '.rating';

const buildRatingFeedbackIdentifier = (identifier: string): string => getFeedbackIdentifier(
    MODULE_RATING_PREFIX_ + identifier
)

const getRatingByType = (ratingType: RatingType, rate: (rating: number | string) => void) : JSX.Element => {
    switch (ratingType) {
        case RatingType.SIMPLE_FIVE_STARS:
            return <StarsRating rate={rate} numberOfStars={5}/>
        case RatingType.LIKE_DISLIKE:
            return <Centered>
                <LikeDislikeRating rate={rate}/>
            </Centered>
    }
}

const getDefaultThankYouNoteByType = (
    ratingType: RatingType,
    translate: TFunction<Namespace<string>>,
    submittedRating?: string | number,
) : JSX.Element => {
    const defaultThankYouNote = <CenteredText>
        <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>
            {translate('thank_you_note.default')}
        </TCaption>
    </CenteredText>

    switch (ratingType) {
        case RatingType.LIKE_DISLIKE:
            if(submittedRating){
                const key = ['thank_you_note', ratingType.valueOf(), submittedRating].join('.')
                return <CenteredText>
                    <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>
                        <TrText input={toLocalTransKey(key, TRANS_SUFFIX)}>
                            <>
                                <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>0</TCaption>
                                <Button
                                    variant={ButtonVariant.INLINE_LINK}
                                    onClick={{href: 'mailto:support@kiro.bio', targetBlank: true}}
                                    size={ButtonSize.EXTRA_SMALL}
                                >1</Button>
                            </>
                        </TrText>
                    </TCaption>
                </CenteredText>
            } else {
                return defaultThankYouNote
            }
        default:
            return defaultThankYouNote
    }
}

const render_feedback = (
    message: APIFeedbackMessage | undefined,
    translate: TFunction<Namespace<string>>,
    ratingType: RatingType,
    surveyLinkPayload?: SurveyLinkPayload,
): JSX.Element => {
    if (message && message.type === APIFeedbackMessageType.ERROR) {
        return (
            <Alert
                severity={AlertSeverity.CRITICAL}
                description={<TrText input={{trkey: 'api_call_error'}}/>}
            />
        )
    } else if (message && message.type === APIFeedbackMessageType.SUCCESS) {
        const submittedRating : undefined | string | number = cast<RateAction>(message.originAction).payload.params?.rating
        return surveyLinkPayload ? <Centered>
            <SmallSpacer>
                <CenteredText>
                    <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>
                        {translate('thank_you_note.default')}
                    </TCaption>
                </CenteredText>
                <Inline
                    alignItems={InlineAlignItem.CENTER}
                    justifyContent={InlineJustifyContent.CENTER}
                    overflow={InlineOverflow.WRAP}>
                    <TrText input={surveyLinkPayload.thank_you_note}>
                        <>
                            <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>0</TCaption>
                            <Button
                                variant={ButtonVariant.LINK}
                                option={ButtonOption.DARK}
                                onClick={{href: surveyLinkPayload.link, targetBlank: true}}
                                icons={{right: IconName.CHEVRON_RIGHT}}
                                size={ButtonSize.EXTRA_SMALL}
                            >1</Button>
                        </>
                    </TrText>
                </Inline>
            </SmallSpacer>
        </Centered> : getDefaultThankYouNoteByType(
            ratingType, translate, submittedRating
        )
    }

    return <Fragment/>
}

export const RatingModule = (): JSX.Element => {
    const payload = useModulePayload<RatingModulePayload>();
    const attributes = useModuleAttributes();
    const dispatch = useDispatch();
    const {t} = useTranslation(undefined, {keyPrefix: TRANS_SUFFIX});

    const feedbackIdentifier = buildRatingFeedbackIdentifier(attributes.identifier)
    const dispatchRate = (rating: string | number, token: string): void => {
        dispatch(rate(API_RATING_RATE)(rating, token, feedbackIdentifier))
    }

    return <WhiteBoxWithShadow>
        <WithPadding paddings={[PaddingSize.MEDIUM]}>
            <LargeSpacer>
                <SmallSpacer>
                    <CenteredText>
                        <TDisplayXSmall color={TypographyColor.COLOR_TEXT_DEFAULT}>
                            <TrText input={payload.title}/>
                        </TDisplayXSmall>
                    </CenteredText>
                    {payload.sub_title && <CenteredText>
                        <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            <TrText input={payload.sub_title}/>
                        </TBody>
                    </CenteredText>}
                </SmallSpacer>
                {getRatingByType(
                    payload.type, (rating: number | string): void => {
                        dispatchRate(rating, payload.token)
                    }
                )}
                <APIFeedbackMessageContainer
                    identifier={feedbackIdentifier}
                    message_renderer={
                        (message: APIFeedbackMessage | undefined): JSX.Element => render_feedback(
                            message, t, payload.type, payload.survey_payload
                        )
                    }
                />
            </LargeSpacer>
        </WithPadding>
    </WhiteBoxWithShadow>
}
