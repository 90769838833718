export const getOrDefault = <T>(value: T | null | undefined, defaultValue: T): T => {
    return value === null || value === undefined ? defaultValue : value;
};

export const safeString = (value: string | null | undefined): string => {
    return value === null || value === undefined ? '' : value;
};

// To avoid Object.fromEntries support issues
export function fromEntries<P>(entries: Array<[string, P]>): Record<string, P> {
    const finalObj: Record<string, P> = {};
    for (const element of entries) {
        finalObj[element[0]] = element[1]
    }

    return finalObj

}

