import { FRENCH_CODE } from "components/utils/form/tmpNewSystem/formatters";

function onlyNumbers(value: string): boolean {
    return /^[0-9]+$/.test(value);
}

export function checkPhoneNumberInput(newValue?: string, country_code?: number): boolean {
    const used_country_code = country_code || FRENCH_CODE

    if (newValue === undefined || newValue === '') {
        return true
    }

    const valueWithoutSpace = newValue.replace(/ /g, '')

    if (used_country_code === FRENCH_CODE) {
        return /^(?:\+3?3?|0)[0-9]{0,9}$/.test(valueWithoutSpace)
    }

    return onlyNumbers(valueWithoutSpace);
}

export function checkDateInput(newValue?: string): boolean {
    if (!newValue || newValue === '//'){
        return true
    }

    const values = newValue.split(/\//g)

    if (values.length > 3){
        return false
    }

    const maxLength = [2, 2, 4];
    for (let i = 0; i < values.length; i++) {
        if(!((values[i] === '' || onlyNumbers(values[i])) && values[i].length <= maxLength[i])){
            return false
        }
    }

    return true;
}