import React, { FC } from 'react';

import styles from "components/designSystem/containers/WithMargin.module.scss"
import classNames from 'classnames';

export enum MarginSize {
    X_SMALL = 'X_SMALL', // 4px
    SMALL = 'SMALL', // 8px
    MEDIUM = 'MEDIUM', // 16px
    LARGE = 'LARGE', // 24px
    X_LARGE = 'X_LARGE', // 32px
    XX_LARGE = 'XX_LARGE', // 48px
    LARGE_PLUS_MEDIUM = 'LARGE_PLUS_MEDIUM', // 40px
    FIVE_TIMES_MEDIUM = 'FIVE_TIMES_MEDIUM', // 50px
    TEN_TIMES_MEDIUM = 'TEN_TIMES_MEDIUM', // 16Opx
    ELEVEN_TIMES_X_SMALL = 'ELEVEN_TIMES_X_SMALL', // 44px
}

interface WithMarginProps {
    // same as css all / top bottom / top right bottom left
    margins: (MarginSize | undefined)[],
    fullWidth?:boolean
}


const WithMargin: FC<WithMarginProps> = (
    props
): JSX.Element => {
    const {margins} = props;

    let marginTop: MarginSize | undefined = undefined
    let marginRight: MarginSize | undefined = undefined
    let marginBottom: MarginSize | undefined = undefined
    let marginLeft: MarginSize | undefined = undefined

    switch (margins.length) {
        case 1:
            marginTop = marginRight = marginBottom = marginLeft = margins[0];
            break;
        case 2:
            marginTop = marginBottom = margins[0];
            marginRight = marginLeft = margins[1];
            break;

        case 4:
            marginTop = margins[0];
            marginRight = margins[1];
            marginBottom = margins[2];
            marginLeft = margins[3];
            break;

    }

    return <div className={
        classNames(

            styles.withMargin,
            {
                [styles.withMarginFullWidth]: props.fullWidth ,
                // Top
                [styles.withMarginTopXSmall]: marginTop === MarginSize.X_SMALL,
                [styles.withMarginTopSmall]: marginTop === MarginSize.SMALL,
                [styles.withMarginTopMedium]: marginTop === MarginSize.MEDIUM,
                [styles.withMarginTopLarge]: marginTop === MarginSize.LARGE,
                [styles.withMarginTopLargePlusMedium]: marginTop === MarginSize.LARGE_PLUS_MEDIUM,
                [styles.withMarginTopXLarge]: marginTop === MarginSize.X_LARGE,
                [styles.withMarginTopTenTimesMedium]: marginTop === MarginSize.TEN_TIMES_MEDIUM,
                [styles.withMarginTopFiveTimesMedium]: marginTop === MarginSize.FIVE_TIMES_MEDIUM,
                [styles.withMarginTopElevenTimeXSmall]: marginTop === MarginSize.ELEVEN_TIMES_X_SMALL,
                [styles.withMarginTopXXLarge]: marginTop === MarginSize.XX_LARGE,

                // Right
                [styles.withMarginRightXSmall]: marginRight=== MarginSize.X_SMALL,
                [styles.withMarginRightSmall]: marginRight=== MarginSize.SMALL,
                [styles.withMarginRightMedium]: marginRight === MarginSize.MEDIUM,
                [styles.withMarginRightLarge]: marginRight === MarginSize.LARGE,
                [styles.withMarginRightLargePlusMedium]: marginRight === MarginSize.LARGE_PLUS_MEDIUM,
                [styles.withMarginRightXLarge]: marginRight === MarginSize.X_LARGE,
                [styles.withMarginRightTenTimesMedium]: marginRight === MarginSize.TEN_TIMES_MEDIUM,
                [styles.withMarginRightFiveTimesMedium]: marginRight === MarginSize.FIVE_TIMES_MEDIUM,
                [styles.withMarginRightElevenTimeXSmall]: marginRight === MarginSize.ELEVEN_TIMES_X_SMALL,
                [styles.withMarginRightXXLarge]: marginRight === MarginSize.XX_LARGE,

                // Bottom
                [styles.withMarginBottomXSmall]: marginBottom === MarginSize.X_SMALL,
                [styles.withMarginBottomSmall]: marginBottom === MarginSize.SMALL,
                [styles.withMarginBottomMedium]: marginBottom === MarginSize.MEDIUM,
                [styles.withMarginBottomLarge]: marginBottom === MarginSize.LARGE,
                [styles.withMarginBottomLargePlusMedium]: marginBottom === MarginSize.LARGE_PLUS_MEDIUM,
                [styles.withMarginBottomXLarge]: marginBottom === MarginSize.X_LARGE,
                [styles.withMarginBottomTenTimesMedium]: marginBottom === MarginSize.TEN_TIMES_MEDIUM,
                [styles.withMarginBottomFiveTimesMedium]: marginBottom === MarginSize.FIVE_TIMES_MEDIUM,
                [styles.withMarginBottomElevenTimeXSmall]: marginBottom === MarginSize.ELEVEN_TIMES_X_SMALL,
                [styles.withMarginBottomXXLarge]: marginBottom === MarginSize.XX_LARGE,

                // Left
                [styles.withMarginLeftXSmall]: marginLeft === MarginSize.X_SMALL,
                [styles.withMarginLeftSmall]: marginLeft === MarginSize.SMALL,
                [styles.withMarginLeftMedium]: marginLeft === MarginSize.MEDIUM,
                [styles.withMarginLeftLarge]: marginLeft === MarginSize.LARGE,
                [styles.withMarginLeftLargePlusMedium]: marginLeft === MarginSize.LARGE_PLUS_MEDIUM,
                [styles.withMarginLeftXLarge]: marginLeft === MarginSize.X_LARGE,
                [styles.withMarginLeftTenTimesMedium]: marginLeft === MarginSize.TEN_TIMES_MEDIUM,
                [styles.withMarginLeftFiveTimesMedium]: marginLeft === MarginSize.FIVE_TIMES_MEDIUM,
                [styles.withMarginLeftElevenTimeXSmall]: marginLeft === MarginSize.ELEVEN_TIMES_X_SMALL,
                [styles.withMarginLeftXXLarge]: marginLeft === MarginSize.XX_LARGE,
            }
        )

    }>
        {props.children}
    </div>
}

interface SingleMarginShortCut {
    margin?: MarginSize
}

export const WithVerticalMargin: FC<SingleMarginShortCut> = ({margin = MarginSize.MEDIUM, ...props}): JSX.Element => <WithMargin margins={[margin, undefined]}>{props.children}</WithMargin>
export const WithHorizontalMargin: FC<SingleMarginShortCut> = ({margin = MarginSize.MEDIUM, ...props}): JSX.Element => <WithMargin margins={[undefined, margin]}>{props.children}</WithMargin>

export const WithTopMargin: FC<SingleMarginShortCut> = ({margin = MarginSize.MEDIUM, ...props}): JSX.Element => <WithMargin margins={[margin, undefined, undefined, undefined]}>{props.children}</WithMargin>
export const WithRightMargin: FC<SingleMarginShortCut> = ({margin = MarginSize.MEDIUM, ...props}): JSX.Element => <WithMargin margins={[undefined, margin, undefined, undefined]}>{props.children}</WithMargin>
export const WithBottomMargin: FC<SingleMarginShortCut> = ({margin = MarginSize.MEDIUM, ...props}): JSX.Element => <WithMargin margins={[undefined, undefined, margin, undefined]}>{props.children}</WithMargin>
export const WithLeftMargin: FC<SingleMarginShortCut> = ({margin = MarginSize.MEDIUM, ...props}): JSX.Element => <WithMargin margins={[undefined, undefined, undefined, margin]}>{props.children}</WithMargin>

export default WithMargin;