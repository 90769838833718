import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const LockOpen = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-lock-open'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8 0.25C5.78152 0.25 4.53207 1.09982 3.87596 2.08397C3.5625 2.55416 3.4076 3.02097 3.33036 3.36855C3.29152 3.54331 3.27153 3.69145 3.26119 3.79999C3.25601 3.85439 3.25322 3.89925 3.25172 3.93301C3.25097 3.9499 3.25054 3.96405 3.2503 3.97527L3.25006 3.98989L3.25001 3.99552L3.25 3.9979L3.25 3.99899C3.25 3.9995 3.25 4 4 4H3.25V6H2.5C1.67157 6 1 6.67157 1 7.5V12.5C1 14.433 2.567 16 4.5 16H11.5C13.433 16 15 14.433 15 12.5V7.5C15 6.67157 14.3284 6 13.5 6H4.75V4.00611L4.75024 3.99961C4.75069 3.98942 4.7518 3.96983 4.75443 3.9422C4.75972 3.88668 4.77098 3.80044 4.79464 3.69395C4.8424 3.47903 4.9375 3.19584 5.12404 2.91603C5.46794 2.40018 6.21848 1.75 8 1.75C9.78152 1.75 10.5321 2.40018 10.876 2.91603C11.0625 3.19584 11.1576 3.47903 11.2054 3.69395C11.229 3.80044 11.2403 3.88668 11.2456 3.9422C11.2482 3.96983 11.2493 3.98942 11.2498 3.99961L11.25 4.00698C11.2538 4.41798 11.5881 4.75 12 4.75C12.4142 4.75 12.75 4.41421 12.75 4H12C12.75 4 12.75 3.9995 12.75 3.99899L12.75 3.9979L12.75 3.99552L12.7499 3.98989L12.7497 3.97527C12.7495 3.96405 12.749 3.9499 12.7483 3.93301C12.7468 3.89925 12.744 3.85439 12.7388 3.79999C12.7285 3.69145 12.7085 3.54331 12.6696 3.36855C12.5924 3.02097 12.4375 2.55416 12.124 2.08397C11.4679 1.09982 10.2185 0.25 8 0.25ZM2.5 12.5V7.5H13.5V12.5C13.5 13.6046 12.6046 14.5 11.5 14.5H4.5C3.39543 14.5 2.5 13.6046 2.5 12.5ZM8 9.75C8.41421 9.75 8.75 10.0858 8.75 10.5V11.5C8.75 11.9142 8.41421 12.25 8 12.25C7.58579 12.25 7.25 11.9142 7.25 11.5V10.5C7.25 10.0858 7.58579 9.75 8 9.75Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-lock-open'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 0C8.69284 0 6.86004 1.31291 5.90882 2.79258C5.45287 3.50184 5.22796 4.20526 5.11595 4.72797C5.05963 4.99081 5.03076 5.21299 5.01591 5.37469C5.00847 5.45572 5.0045 5.52211 5.00238 5.57144C5.00132 5.59612 5.00073 5.61659 5.0004 5.63255L5.00007 5.65308L5.00002 5.66076L5 5.66393L5 5.66536C5 5.66602 5 5.66667 6 5.66667H5V9H4C2.89543 9 2 9.89543 2 11V20C2 22.2091 3.79086 24 6 24H18C20.2091 24 22 22.2091 22 20V11C22 9.89543 21.1046 9 20 9H7V5.67308L7.00055 5.65708C7.00136 5.63805 7.00325 5.60417 7.00753 5.5576C7.01612 5.46409 7.03412 5.32169 7.07155 5.14703C7.14704 4.79474 7.29713 4.33149 7.59118 3.87409C8.13996 3.02043 9.30716 2 12 2C14.6928 2 15.86 3.02043 16.4088 3.87409C16.7029 4.33149 16.853 4.79474 16.9284 5.14703C16.9659 5.32169 16.9839 5.46409 16.9925 5.5576C16.9967 5.60417 16.9986 5.63805 16.9995 5.65708L17 5.67383C17.0039 6.22282 17.4501 6.66667 18 6.66667C18.5523 6.66667 19 6.21895 19 5.66667H18C19 5.66667 19 5.66602 19 5.66536L19 5.66393L19 5.66076L18.9999 5.65308L18.9996 5.63255C18.9993 5.61659 18.9987 5.59612 18.9976 5.57144C18.9955 5.52211 18.9915 5.45572 18.9841 5.37469C18.9692 5.21299 18.9404 4.99081 18.8841 4.72797C18.772 4.20526 18.5471 3.50184 18.0912 2.79258C17.14 1.31291 15.3072 0 12 0ZM20 20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V11H20V20ZM12 14.5C12.5523 14.5 13 14.9477 13 15.5V17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5V15.5C11 14.9477 11.4477 14.5 12 14.5Z"
                  fill="currentColor"/>
        </svg>
    )
}