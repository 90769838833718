import TrText from 'components/common/TrText';
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import Divider, { DividerOrientation, DividerVariant } from 'components/designSystem/components/Divider';
import Pill, { PillSeverity } from "components/designSystem/components/Pill";
import { IconTag as IconTagComponent, Tag, TagSeverity, TagSize } from "components/designSystem/components/Tag";
import WithTooltip from "components/designSystem/components/WithTooltip";
import { AlwaysInline, Grow, InlineAlignItem, InlineSpacing } from 'components/designSystem/containers/Inline';
import { SmallSpacer } from 'components/designSystem/containers/Spacer';
import { TCaption, TypographyColor } from 'components/designSystem/containers/Typography';
import WithMargin, { MarginSize, WithVerticalMargin } from 'components/designSystem/containers/WithMargin';
import { IconName } from "components/designSystem/foundations/IconsData";
import { Comment } from 'components/modules/modular/modules/report/content/models';
import { TransDomain } from 'components/pages/common/MainComponent';
import { htmlLineBeaks } from "components/utils/transformers/Text";
import { getIconByName } from "core/content/icons/IconFinder";
import LOGGER from "core/logging/Logger";
import { cast } from "core/utils/Typed";
import { isLocalTransKey, toLocalTransKey } from 'models/common/message';
import { IconTagPayload, ModuleReportDataTag, ModuleReportDataTagType, TextTagPayload } from "models/modular/report";
import React from 'react';

export interface MobileResultMenuProps {
    onDetailClick?: () => void;
    onHistoryClick?: () => void;
}

export const MobileResultMenu = (
    props: MobileResultMenuProps
): JSX.Element => (
    <>
        <Divider variant={DividerVariant.DEFAULT} />
        <AlwaysInline>
            <Grow>
                <WithMargin margins={[MarginSize.SMALL]}>
                    <Button
                        icons={{left: IconName.INFO_CIRCLE}}
                        variant={ButtonVariant.QUATERNARY}
                        size={ButtonSize.MEDIUM}
                        onClick={props.onDetailClick}
                        disabled={!props.onDetailClick}
                        fullWidth
                    >
                        <TrText capitalize input={toLocalTransKey("report.details", TransDomain.MODULES)} />
                    </Button>
                </WithMargin>

            </Grow>
            <WithVerticalMargin margin={MarginSize.SMALL}>
                <Divider variant={DividerVariant.DEFAULT} orientation={DividerOrientation.VERTICAL}/>
            </WithVerticalMargin>
            <Grow>
                <WithMargin margins={[MarginSize.SMALL]}>
                    <Button
                        icons={{left: IconName.CHART}}
                        variant={ButtonVariant.QUATERNARY}
                        size={ButtonSize.MEDIUM}
                        onClick={props.onHistoryClick}
                        disabled={!props.onHistoryClick}
                        fullWidth
                    >
                        <TrText capitalize input={toLocalTransKey("report.history", TransDomain.MODULES)} />
                    </Button>
                </WithMargin>

            </Grow>
        </AlwaysInline>
    </>
)

export function formatComments(comments?: Comment[], isMobile = false, isCategoryComment = false): JSX.Element {
    if (!comments || comments.length == 0) {
        return <div />
    }

    const commentsElements = <SmallSpacer>
        {comments.map(
            (comment, k: number): JSX.Element => <div key={k}>
                <TCaption
                    color={isCategoryComment ? TypographyColor.COLOR_TEXT_DEFAULT : TypographyColor.COLOR_TEXT_SUBDUED}>
                    <TrText input={comment.content}/>
                </TCaption>
            </div>
        )}
    </SmallSpacer>

    return isCategoryComment ? <>{commentsElements}</> : <WithMargin margins={[undefined, MarginSize.MEDIUM]}>
        <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
            {!isMobile && <Pill severity={PillSeverity.EMPTY}/>}
            {commentsElements}
        </AlwaysInline>
    </WithMargin>
}

export function formatTags(tags: ModuleReportDataTag[]): JSX.Element {
    return <AlwaysInline spacing={InlineSpacing.X_SMALL}>
        {tags?.map((tag: ModuleReportDataTag, key: number) => {
            const severity = tag.severity ?? TagSeverity.INFO

            switch (tag.payload.type) {
                case ModuleReportDataTagType.ICON:
                    // eslint-disable-next-line no-case-declarations
                    const iconTag = cast<IconTagPayload>(tag.payload)
                    // eslint-disable-next-line no-case-declarations
                    const icon = getIconByName(iconTag.iconName)
                    if(icon === undefined){
                        LOGGER.warning('No icon defined or wrong icon name: ' + iconTag.iconName)
                        return <div key={key}/>
                    }

                    // eslint-disable-next-line no-case-declarations
                    const tooltip = <TrText input={toLocalTransKey(iconTag.tooltip.valueOf(), TransDomain.MEDICAL_REPORT_TAGS)}/>
                    return <WithTooltip key={key} tooltip={tooltip}>
                        <IconTagComponent icon={icon} severity={severity}/>
                    </WithTooltip>
                case ModuleReportDataTagType.TEXT:
                    // eslint-disable-next-line no-case-declarations
                    const textTag = cast<TextTagPayload>(tag.payload)

                    return <Tag key={key} size={TagSize.EXTRA_SMALL} severity={severity}>
                        <TrText input={toLocalTransKey(textTag.value.valueOf(), TransDomain.MEDICAL_REPORT_TAGS)}/>
                        {textTag.additional_value && [' (', textTag.additional_value, ')'].concat()}
                    </Tag>
            }
        })}
    </AlwaysInline>
}

