import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Jar = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-jar'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.5 1.75C4.36193 1.75 4.25 1.86193 4.25 2V2.25H11.75V2C11.75 1.86193 11.6381 1.75 11.5 1.75H4.5ZM13.25 2.25V2C13.25 1.0335 12.4665 0.25 11.5 0.25H4.5C3.5335 0.25 2.75 1.0335 2.75 2V2.25H2C1.58579 2.25 1.25 2.58579 1.25 3V5C1.25 5.41421 1.58579 5.75 2 5.75H2.75V14C2.75 14.9665 3.5335 15.75 4.5 15.75H11.5C12.4665 15.75 13.25 14.9665 13.25 14V5.75H14C14.4142 5.75 14.75 5.41421 14.75 5V3C14.75 2.58579 14.4142 2.25 14 2.25H13.25ZM13.25 3.75H2.75V4.25H13.25V3.75ZM11.75 5.75H4.25V6.25H8.5C9.4665 6.25 10.25 7.0335 10.25 8V12C10.25 12.9665 9.4665 13.75 8.5 13.75H4.25V14C4.25 14.1381 4.36193 14.25 4.5 14.25H11.5C11.6381 14.25 11.75 14.1381 11.75 14V5.75ZM4.25 12.25H8.5C8.63807 12.25 8.75 12.1381 8.75 12V8C8.75 7.86193 8.63807 7.75 8.5 7.75H4.25V12.25Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-jar'}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4 2C4 0.895428 4.89543 0 6 0H19C20.1046 0 21 0.895431 21 2V3.14286H22C22.5523 3.14286 23 3.59057 23 4.14286V7.28571C23 7.838 22.5523 8.28571 22 8.28571H21V22C21 23.1046 20.1046 24 19 24H6C4.89543 24 4 23.1046 4 22V8.28571H3C2.44772 8.28571 2 7.838 2 7.28571V4.14286C2 3.59057 2.44772 3.14286 3 3.14286H4V2ZM4 5.14286H21V6.28571H4V5.14286ZM6 8.28571V10H13C14.1046 10 15 10.8954 15 12V18.5C15 19.6046 14.1046 20.5 13 20.5H6V22H19V8.28571H6ZM6 18.5H13V12H6V18.5ZM19 3.14286H6V2L19 2V3.14286Z"
                  fill="currentColor"/>
        </svg>
    )
}