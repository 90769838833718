import React from 'react';

const Folder = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.2758 14.8037C29.0045 14.4291 28.6468 14.1254 28.2331 13.9185C27.8194 13.7117 27.3618 13.6077 26.8993 13.6154H26.5927V11.6989C26.5927 11.3165 26.5165 10.9379 26.3685 10.5853C26.2204 10.2327 26.0035 9.91321 25.7304 9.64549C25.4574 9.37778 25.1336 9.16723 24.7782 9.02619C24.4227 8.88514 24.0427 8.81643 23.6604 8.82408H17.4507C17.2184 8.82271 16.9929 8.7453 16.8087 8.60368L12.9756 5.61387C12.451 5.20991 11.8074 4.9909 11.1453 4.99099H5.93232C5.54998 4.98334 5.16996 5.05205 4.81451 5.1931C4.45905 5.33414 4.13531 5.54469 3.86224 5.81241C3.58917 6.08012 3.37226 6.39963 3.2242 6.75222C3.07615 7.10481 2.99993 7.4834 3 7.86581V25.1147C2.99917 25.6452 3.1523 26.1645 3.44081 26.6096C3.70597 27.0311 4.07347 27.3787 4.5091 27.62C4.94474 27.8613 5.43433 27.9884 5.93232 27.9895H24.1395C24.7652 28.0051 25.3789 27.8161 25.8873 27.4512C26.3958 27.0862 26.7713 26.5653 26.9568 25.9676L29.6975 17.3431C29.8294 16.9162 29.86 16.4644 29.7868 16.0236C29.7136 15.5828 29.5386 15.1651 29.2758 14.8037ZM4.91655 7.86581C4.91631 7.73498 4.94287 7.60549 4.99458 7.48531C5.04629 7.36513 5.12207 7.25682 5.21723 7.16704C5.3124 7.07726 5.42494 7.00792 5.54792 6.96329C5.6709 6.91866 5.80172 6.89969 5.93232 6.90754H11.1453C11.3808 6.90752 11.6098 6.98497 11.7969 7.12794L15.63 10.1177C16.151 10.5216 16.7915 10.7408 17.4507 10.7406H23.6604C23.7909 10.7328 23.9218 10.7517 24.0447 10.7964C24.1677 10.841 24.2803 10.9104 24.3754 11.0001C24.4706 11.0899 24.5464 11.1982 24.5981 11.3184C24.6498 11.4386 24.6764 11.5681 24.6761 11.6989V13.6154H9.34377C8.72294 13.6061 8.11579 13.7981 7.61313 14.1625C7.11047 14.527 6.73932 15.0444 6.55519 15.6374L4.91655 20.8408V7.86581Z"
            />
        </svg>
    )
}

export default Folder;
