import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ArrowRightUp = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-arrow-right-up'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.8635 4.13642C12.1564 4.42931 12.1564 4.90418 11.8635 5.19708L5.19683 11.8637C4.90394 12.1566 4.42907 12.1566 4.13617 11.8637C3.84328 11.5709 3.84328 11.096 4.13617 10.8031L10.8028 4.13642C11.0957 3.84352 11.5706 3.84352 11.8635 4.13642Z"
                      fill="currentColor"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M3.9165 4.66675C3.9165 4.25253 4.25229 3.91675 4.6665 3.91675H11.3332C11.7474 3.91675 12.0832 4.25253 12.0832 4.66675V11.3334C12.0832 11.7476 11.7474 12.0834 11.3332 12.0834C10.919 12.0834 10.5832 11.7476 10.5832 11.3334V5.41675H4.6665C4.25229 5.41675 3.9165 5.08096 3.9165 4.66675Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-arrow-right-up'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V8H7C6.44772 8 6 7.55228 6 7Z"
                  fill="currentColor"/>
        </svg>
    )
}