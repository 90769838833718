import { modal, ModalContentBuilder, ModalType, redirect } from "actions/common/CommonActions";
import { alterContext as alterContextAction } from "actions/modular/ModularActions";
import ConnectedCarouselNavigator from "components/common/ConnectedCarouselNavigator";
import TrText, { TrTextInput } from "components/common/TrText";
import EntityLogo from 'components/commonDesign/EntityLogo';
import { TABLET_MAX_WIDTH_ACCESSIBLE } from 'components/core/constants';
import { PopUpStyle } from "components/core/containers/PopUp";
import { AvatarSize } from "components/designSystem/components/Avatar";
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import DropDown from 'components/designSystem/components/DropDown';
import DropDownItem, { DropDownType } from 'components/designSystem/components/DropDownItem';
import { SizedLogoSize } from 'components/designSystem/components/Logo';
import { SelectedButton, ToggleButton } from "components/designSystem/components/ToggleButton";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import FlexGrow, { FlexGrow8 } from "components/designSystem/containers/FlexGrow";
import Inline, {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from 'components/designSystem/containers/Inline';
import {
    LargeSpacer,
    MediumSpacer,
    SmallSpacer,
    SpacerJustifyContent,
    XSmallSpacer,
} from 'components/designSystem/containers/Spacer';
import { TDisplayXSmall700, TLabel, TLabel700, TypographyColor } from 'components/designSystem/containers/Typography';
import WithMargin, { MarginSize, WithLeftMargin } from "components/designSystem/containers/WithMargin";
import WithPadding, { PaddingSize } from "components/designSystem/containers/WithPadding";
import { IconName } from 'components/designSystem/foundations/IconsData';
import ShareReport from "components/features/ShareReport";
import OnlyLoggedFeature from "components/modules/modular/components/OnlyLoggedFeature";
import { useModuleAttributes, useModulePayload } from "components/modules/modular/ModuleContainer";
import { buildClickModuleAction } from "components/modules/modular/modules/builders/userAction";
import { TransDomain } from 'components/pages/common/MainComponent';
import { getIconByName } from "core/content/icons/IconFinder";
import LOGGER from "core/logging/Logger";
import { getRoute } from "core/routing/Helper";
import { ROUTE_MODULAR_PAGE } from "core/routing/Routes";
import { formatDate, formatDateTime, getAge } from 'core/utils/Date';
import { formatName } from 'core/utils/Name';
import { Entity } from 'models/entities/default';
import { ConnectionModeType } from "models/medicalReport/ConnectionModesModel";
import { LinkedResource, UserData } from "models/medicalReport/LegacyReportModels";
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useDispatch } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import _ from 'underscore';

interface WithNavigator {
    on_item_change_alter_context_key: string,
    identifier: string,
    item_key?: string
}

export enum ViewType {
    PDF_VIEW = 'PDF_VIEW',
    SIMPLIFIED = 'SIMPLIFIED'
}

interface ReportHeaderWithSwitchViewsToggle {
    on_item_change_alter_context_key: string,
    on_item_change_alter_context_pdf_view_value: string,
    on_item_change_alter_context_simplified_view_value: string,
    selected_view: ViewType
}

interface ReportHeaderPayload {
    uuid: string;
    external_id: string;
    laboratory_entity?: Entity;
    sampling_datetime: Date | null;
    result_datetime: Date | null;
    linked_resources: LinkedResource[];
    prescribers: string[];
    validators: string[];
    sampler_name: string | null,
    patient_data?: UserData,
    with_navigator?: WithNavigator,
    show_phone_number?: boolean,
    allow_redirect_to_patient_page?: boolean
    with_share_report_button?: boolean;
    with_switch_view_toggle?: ReportHeaderWithSwitchViewsToggle;
}

interface ReportHeaderParams {
    patientData: UserData,
    switchView: (newView: ViewType) => void,
    currentView?: ViewType
}

const PatientPhone = ({phone}: { phone: string }): JSX.Element =>
    <Button variant={ButtonVariant.LINK}
            size={ButtonSize.EXTRA_SMALL}
            icons={{middle: IconName.PHONE}}
            onClick={{href: 'tel:' + phone, targetBlank: false}}>
        <TLabel>{phone}</TLabel>
    </Button>

interface SwitchViewsToggleParams {
    switchView: (newView: ViewType) => void
    currentView: ViewType
}

const SwitchViewsToggle = (
    {currentView, switchView}: SwitchViewsToggleParams
): JSX.Element => {
    const attributes = useModuleAttributes();
    return <WithMargin margins={[MarginSize.MEDIUM, undefined]}>
        <ToggleButton
            leftButton={{
                id: 'pdf-view',
                content: <TrText input={{
                    trkey: 'switch_views_toggle.pdf_view',
                    trdomain: TransDomain.MEDICAL_REPORT + '.report_header',
                }}/>,
                onClick: (): void => {
                    if (attributes.pageId && attributes.type) {
                        LOGGER.userAction(
                            buildClickModuleAction(
                                attributes.pageId,
                                attributes.type,
                                'toggle_button:pdf_view'
                            )
                        )
                    }
                    switchView(ViewType.PDF_VIEW)
                }
            }}
            rightButton={{
                id: 'simplified-view',
                content: <TrText input={{
                    trkey: 'switch_views_toggle.simplified_vue',
                    trdomain: TransDomain.MEDICAL_REPORT + '.report_header',
                }}/>,
                onClick: (): void => {
                    if (attributes.pageId && attributes.type) {
                        LOGGER.userAction(
                            buildClickModuleAction(
                                attributes.pageId,
                                attributes.type,
                                'toggle_button:simplified_vue'
                            )
                        )
                    }
                    switchView(ViewType.SIMPLIFIED)
                }
            }}
            defaultButtonSelected={
                currentView === ViewType.SIMPLIFIED ?
                    SelectedButton.RIGHT : SelectedButton.LEFT
            }/>
    </WithMargin>
}

const ShareReportButton = (
    {reportUUID, isMobile}: { reportUUID: string, isMobile: boolean }
): JSX.Element => {
    const attributes = useModuleAttributes();
    const dispatch = useDispatch();
    const popup = (content: ModalContentBuilder, style: PopUpStyle | undefined, title: string | null): void => {
        dispatch(modal(ModalType.CENTER, content, {title: title ? {default: title} : undefined}))
    }
    return <Button
        id={'share-button'}
        variant={ButtonVariant.SECONDARY}
        size={isMobile ? ButtonSize.MEDIUM : ButtonSize.LARGE}
        onClick={(): void => {
            if (attributes.pageId && attributes.type) {
                LOGGER.userAction(
                    buildClickModuleAction(
                        attributes.pageId,
                        attributes.type,
                        'share_report_button'
                    )
                )
            }
            popup(() => (
                    <OnlyLoggedFeature
                        feature={(): JSX.Element => {
                            return <ShareReport reportUUID={reportUUID}/>
                        }}
                        descriptionByConnectionMode={{
                            [ConnectionModeType.LOGIN]: <TrText input={{
                                trkey: 'blocked_description.LOGIN',
                                trdomain: TransDomain.SHARE_REPORT_FEATURE,
                            }} capitalize/>,
                            [ConnectionModeType.SUBSCRIPTION]: <TrText input={{
                                trkey: 'blocked_description.SUBSCRIPTION',
                                trdomain: TransDomain.SHARE_REPORT_FEATURE,
                            }} capitalize/>,
                        }}
                        title={<TrText input={{trkey: 'title', trdomain: TransDomain.SHARE_REPORT_FEATURE}} capitalize/>}
                    />
                ),
                undefined,
                null
            )
        }}
        icons={
            {left: IconName.SEND}
        }
    >
        <TrText input={{trkey: 'report_header.share_report', trdomain: TransDomain.MEDICAL_REPORT}}/>
    </Button>
}

const SamplingDate = (
    {samplingDatetime}: { samplingDatetime: Date | null }
): JSX.Element => {
    return <TDisplayXSmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
        <TrText input={{
            trkey: 'report_header.report_info_title',
            trdata: {date: formatDate(samplingDatetime)},
            trdomain: TransDomain.MEDICAL_REPORT
        }}/>
    </TDisplayXSmall700>
}

const FileId = (externalId: string, laboratoryEntity?: Entity): JSX.Element => {
    return <AlwaysInline alignItems={InlineAlignItem.BASELINE} spacing={InlineSpacing.X_SMALL}>
        {laboratoryEntity?.name && <>
            <TLabel700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                {laboratoryEntity.name.toUpperCase()}
            </TLabel700>
            <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>-</TLabel>
        </>}
        <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
            <TrText input={{
                trkey: 'report_header.external_id_report',
                trdata: {external_id: externalId},
                trdomain: TransDomain.MEDICAL_REPORT
            }}/>
        </TLabel>
    </AlwaysInline>
}

const DownloadComponent = (
    {linkedResources, isMobile}: { linkedResources: LinkedResource[], isMobile: boolean }
): JSX.Element => {
    return linkedResources.length == 1 ? (
        <Button
            variant={ButtonVariant.SECONDARY}
            size={isMobile ? ButtonSize.MEDIUM : ButtonSize.LARGE}
            onClick={{
                href: `${linkedResources[0].value}&is_user_action=true`,
                targetBlank: true
            }}
            icons={
                {left: IconName.DOWNLOAD}
            }
        >
            <TrText input={{trkey: 'report_header.download', trdomain: TransDomain.MEDICAL_REPORT}}/>
        </Button>
    ) : (
        <DropDown closeOnClick={true} rightAlign={!isMobile} triggerBuilder={
            ((onClick, isOpen): JSX.Element => <Button
                variant={ButtonVariant.SECONDARY}
                size={isMobile ? ButtonSize.MEDIUM : ButtonSize.LARGE}
                onClick={onClick}
                icons={
                    {left: IconName.DOWNLOAD}
                }
                isActive={isOpen}

            >
                <TrText input={{trkey: 'report_header.download', trdomain: TransDomain.MEDICAL_REPORT}}/>
            </Button>)
        }>
            {
                linkedResources.map((resource: LinkedResource, index) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const iconName = getIconByName(resource.icon)
                    const resourceTitleInput : TrTextInput = resource.title ? resource.title : {
                        trkey: `report_header.PDF`, trdomain: TransDomain.MEDICAL_REPORT
                    }
                    return <DropDownItem
                        key={index}
                        label={
                            <TrText input={resourceTitleInput}/>
                        }
                        payload={{
                            type: DropDownType.ACTION,
                            onClick: {
                                href: `${resource.value}&is_user_action=true`,
                                targetBlank: true
                            },
                            icon: iconName
                        }}
                    />
                })
            }
        </DropDown>
    )
}

const LinkSection = (
    {
        linkedResources,
        isMobile,
        reportUUID,
        withShareReportButton,
    }: {
        linkedResources: LinkedResource[],
        isMobile: boolean,
        reportUUID: string,
        withShareReportButton?: boolean,
    }
): JSX.Element => {
    return (!_.isEmpty(linkedResources) || withShareReportButton) ? <AlwaysInline
        spacing={isMobile ? InlineSpacing.SMALL : InlineSpacing.MEDIUM}
        justifyContent={InlineJustifyContent.FLEX_START}
    >
        {withShareReportButton && <ShareReportButton reportUUID={reportUUID} isMobile={isMobile}/>}
        {!_.isEmpty(linkedResources) && <DownloadComponent linkedResources={linkedResources} isMobile={isMobile}/>}
    </AlwaysInline> : <Fragment/>
}

const ReportInfo = (
    {
        samplingDatetime, externalId, laboratoryEntity
    }: {
        samplingDatetime: Date | null, externalId: string, laboratoryEntity?: Entity
    }
): JSX.Element =>
    <SmallSpacer>
        <SamplingDate samplingDatetime={samplingDatetime}/>
        {FileId(externalId, laboratoryEntity)}
    </SmallSpacer>

const PrescriptionAndValidation = (
    {
        prescribers,
        validators,
        samplerName,
        resultDatetime,
    }: {
        prescribers: string[],
        validators: string[],
        samplerName: string | null,
        resultDatetime: Date | null
    }
): JSX.Element => {
    return <XSmallSpacer>
        {
            prescribers && !_.isEmpty(prescribers) &&
            <div>
                <TLabel color={TypographyColor.COLOR_TEXT_SUBDUED}>
                    <TrText input={{
                        trkey: 'prescribed_by',
                        trdata: {names: prescribers.map((p) => formatName(p, '')).join(', ')},
                        trdomain: TransDomain.MEDICAL_REPORT
                    }}/>
                    {samplerName && ' - '}
                    {samplerName && <TrText input={{
                        trkey: 'sampled_by',
                        trdata: {names: samplerName},
                        trdomain: TransDomain.MEDICAL_REPORT
                    }}/>}
                </TLabel>
            </div>
        }
        <div>
            <TLabel color={TypographyColor.COLOR_TEXT_SUBDUED}>
                {resultDatetime && <TrText input={{
                    trkey: 'validated_at',
                    trdata: {date: formatDateTime(resultDatetime, undefined)},
                    trdomain: TransDomain.MEDICAL_REPORT
                }}/>}
                {validators && !_.isEmpty(validators) && <TrText input={{
                    trkey: 'report_header.validated_at_by',
                    trdata: {names: validators.map((p) => formatName(p, '')).join(', ')},
                    trdomain: TransDomain.MEDICAL_REPORT
                }}/>}
            </TLabel>
        </div>
    </XSmallSpacer>
}

const Name = (patientData: UserData): JSX.Element => {
    return <AlwaysInline alignItems={InlineAlignItem.BASELINE} spacing={InlineSpacing.SMALL}>
        <TDisplayXSmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
            {formatName(patientData.first_name, patientData.last_name)}
        </TDisplayXSmall700>
        {patientData.birth_last_name && <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
            <TrText input={{
                trkey: 'born_name',
                trdata: {"birthName": patientData.birth_last_name},
                trdomain: TransDomain.USER
            }}/>
        </TLabel>}
    </AlwaysInline>;
}

const NameLink = (patientData: UserData): JSX.Element => {
    const payload = useModulePayload<ReportHeaderPayload>();
    const dispatch = useDispatch();
    const displayPatientPage = (patientId: string): void => {
        dispatch(redirect(getRoute(ROUTE_MODULAR_PAGE, {pageId: 'patient_profile', patient_uuid: patientId})))
    }
    return payload.allow_redirect_to_patient_page ? <Button
            variant={ButtonVariant.INLINE_LINK}
            onClick={(): void => displayPatientPage(patientData.id)}
        >
            <Name {...patientData}/>
        </Button> :
        <TDisplayXSmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
            {formatName(patientData.first_name, patientData.last_name)}
        </TDisplayXSmall700>
}

const PatientInfo = (patientData: UserData): JSX.Element => <>
    <NameLink {...patientData}/>
    <AlwaysInline spacing={InlineSpacing.MEDIUM}>
        {
            patientData.birth_date &&
            <>
                <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    {formatDate(patientData.birth_date, 'LL')}
                </TLabel>
                <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    <TrText input={{
                        trkey: 'report_header.patient_info.age',
                        trdata: {'age': getAge(patientData.birth_date)},
                        trdomain: TransDomain.MEDICAL_REPORT
                    }}/>
                </TLabel>
            </>
        }
        {
            patientData.gender &&
            <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
                <TrText
                    input={{
                        trkey: `genders_short.${patientData.gender.toLowerCase()}`,
                        trdomain: TransDomain.GLOBAL
                    }}
                    builder={(value): JSX.Element => <TrText input={{
                        trkey: 'report_header.patient_info.sex',
                        trdata: {'sex': value},
                        trdomain: TransDomain.MEDICAL_REPORT
                    }}/>}
                />
            </TLabel>
        }
    </AlwaysInline>
</>


const ReportHeaderMobile = (
    {
        patientData: patientData,
        switchView: switchView,
        currentView: currentView
    }: ReportHeaderParams
): JSX.Element => {
    const {
        sampler_name: samplerName,
        sampling_datetime: samplingDatetime,
        external_id: externalId,
        linked_resources: linkedResources,
        laboratory_entity: laboratoryEntity,
        with_navigator: withNavigator,
        prescribers,
        validators,
        with_share_report_button: withShareReportButton,
        uuid: reportUUID,
        with_switch_view_toggle: withSwitchViewsToggle,
        result_datetime: resultDatetime,
        show_phone_number: showPhoneNumber,
    } = useModulePayload<ReportHeaderPayload>();
    const dispatch = useDispatch();
    const alterContext = (key?: string, value?: string): void => {
        if (key !== undefined) {
            dispatch(alterContextAction(key, value))
        }
    }
    return <MediumSpacer>
        <Box background={BoxColor.BOLD} withPadding withRadius withFullWidth withOverflow>
            <LargeSpacer>
                <SmallSpacer>
                    <EntityLogo size={SizedLogoSize.X_LARGE} entity={laboratoryEntity} fallbackIcon={IconName.TUBE}
                                asAvatar={{size: AvatarSize.LARGE}}/>
                    <ReportInfo
                        samplingDatetime={samplingDatetime}
                        externalId={externalId}
                        laboratoryEntity={laboratoryEntity}/>
                    <XSmallSpacer>
                        <PrescriptionAndValidation
                            prescribers={prescribers}
                            validators={validators}
                            samplerName={samplerName}
                            resultDatetime={resultDatetime}/>
                    </XSmallSpacer>
                    <SmallSpacer>
                        <PatientInfo {...patientData}/>
                        {patientData.phone && showPhoneNumber &&
                            <PatientPhone phone={patientData.phone}/>}
                    </SmallSpacer>
                </SmallSpacer>

                <LinkSection
                    linkedResources={linkedResources}
                    isMobile={true}
                    reportUUID={reportUUID}
                    withShareReportButton={withShareReportButton}/>
            </LargeSpacer>
        </Box>
        {withNavigator && <WithPadding paddings={[PaddingSize.SMALL]}>
            <ConnectedCarouselNavigator
                identifier={withNavigator.identifier}
                bulletQty={0}
                initItem={withNavigator.item_key}
                onChange={
                    (item: string): void => alterContext(withNavigator.on_item_change_alter_context_key, item)
                }
                isVertical={false}
                small={true}
            />
        </WithPadding>}
        {withSwitchViewsToggle && currentView && <SwitchViewsToggle switchView={switchView} currentView={currentView}/>}
    </MediumSpacer>
}

const ReportHeaderDesktop = (
    {
        patientData: patientData,
        switchView: switchView,
        currentView: currentView
    }: ReportHeaderParams
): JSX.Element => {
    const {
        sampler_name: samplerName,
        sampling_datetime: samplingDatetime,
        external_id: externalId,
        linked_resources: linkedResources,
        laboratory_entity: laboratoryEntity,
        with_navigator: withNavigator,
        prescribers,
        validators,
        with_share_report_button: withShareReportButton,
        uuid: reportUUID,
        with_switch_view_toggle: withSwitchViewsToggle,
        result_datetime: resultDatetime,
        show_phone_number: showPhoneNumber,
    } = useModulePayload<ReportHeaderPayload>();
    const dispatch = useDispatch();
    const alterContext = (key?: string, value?: string): void => {
        if (key !== undefined) {
            dispatch(alterContextAction(key, value))
        }
    }
    return <MediumSpacer>
        <Inline alignItems={InlineAlignItem.CENTER}>
            <Box background={BoxColor.BOLD} withPadding withRadius withFullWidth withOverflow>
                <AlwaysInline justifyContent={InlineJustifyContent.SPACE_BETWEEN}>
                    <FlexGrow8>
                        <AlwaysInline spacing={InlineSpacing.MEDIUM}>
                            <EntityLogo size={SizedLogoSize.X_LARGE} entity={laboratoryEntity}
                                        fallbackIcon={IconName.TUBE} asAvatar={{size: AvatarSize.LARGE}}/>
                            <MediumSpacer justifyContent={SpacerJustifyContent.SPACE_BETWEEN}>
                                <ReportInfo
                                    samplingDatetime={samplingDatetime}
                                    externalId={externalId}
                                    laboratoryEntity={laboratoryEntity}/>
                                <PrescriptionAndValidation
                                    prescribers={prescribers}
                                    validators={validators}
                                    samplerName={samplerName}
                                    resultDatetime={resultDatetime}/>
                            </MediumSpacer>
                        </AlwaysInline>
                    </FlexGrow8>
                    <FlexGrow>
                        <MediumSpacer justifyContent={SpacerJustifyContent.SPACE_BETWEEN}>
                            <SmallSpacer>
                                <PatientInfo {...patientData}/>
                                {patientData.phone && showPhoneNumber &&
                                    <PatientPhone phone={patientData.phone}/>}
                            </SmallSpacer>
                            <LinkSection
                                linkedResources={linkedResources}
                                isMobile={false}
                                reportUUID={reportUUID}
                                withShareReportButton={withShareReportButton}/>
                        </MediumSpacer>
                    </FlexGrow>
                </AlwaysInline>
            </Box>
            {withNavigator && <WithLeftMargin margin={MarginSize.LARGE}>
                <ConnectedCarouselNavigator
                    identifier={withNavigator.identifier}
                    bulletQty={0}
                    initItem={withNavigator.item_key}
                    onChange={
                        (item: string): void => alterContext(withNavigator.on_item_change_alter_context_key, item)
                    }
                    isVertical={true}
                    small={false}
                />
            </WithLeftMargin>}
        </Inline>
        {withSwitchViewsToggle && currentView && <SwitchViewsToggle switchView={switchView} currentView={currentView}/>}
    </MediumSpacer>
}

const useSwitchViewFunction = (
    currentView: string | undefined,
    setCurrentView: Dispatch<SetStateAction<ViewType | undefined>>,
    withSwitchViewsToggle?: ReportHeaderWithSwitchViewsToggle
) : (newView: ViewType) => void => {
    const dispatch = useDispatch();
    const alterContext = (key?: string, value?: string): void => {
        if (key !== undefined) {
            dispatch(alterContextAction(key, value))
        }
    }
    return (newView: ViewType): void => {
        if(withSwitchViewsToggle && newView != currentView){
            setCurrentView(newView)
            if(newView === ViewType.SIMPLIFIED && withSwitchViewsToggle.selected_view != ViewType.SIMPLIFIED) {
                alterContext(
                    withSwitchViewsToggle.on_item_change_alter_context_key,
                    withSwitchViewsToggle.on_item_change_alter_context_simplified_view_value
                )
            }

            if(newView === ViewType.PDF_VIEW && withSwitchViewsToggle.selected_view != ViewType.PDF_VIEW) {
                alterContext(
                    withSwitchViewsToggle.on_item_change_alter_context_key,
                    withSwitchViewsToggle.on_item_change_alter_context_pdf_view_value
                )
            }
        }
    }
}

export const ReportHeaderModule = (): JSX.Element => {
    const {
        patient_data: patientData,
        with_switch_view_toggle: withSwitchViewsToggle,
    } = useModulePayload<ReportHeaderPayload>();
    const isMobile = useMediaQuery({minWidth: TABLET_MAX_WIDTH_ACCESSIBLE});
    const [currentView, setCurrentView] = useState<ViewType | undefined>(
        withSwitchViewsToggle ? withSwitchViewsToggle.selected_view : undefined
    )

    if (!patientData) {
        return <Fragment/>
    }

    const params: ReportHeaderParams = {
        patientData: patientData,
        switchView: useSwitchViewFunction(currentView, setCurrentView, withSwitchViewsToggle),
        currentView: currentView
    }

    return isMobile ? <ReportHeaderDesktop {...params}/> : <ReportHeaderMobile {...params}/>
}
