import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Refresh = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.81829 0.267578C6.52684 0.267581 5.48787 0.571297 4.47653 1.21492C3.59582 1.77541 2.7511 2.58559 1.7838 3.61225V1.01758C1.7838 0.603365 1.44801 0.267578 1.0338 0.267578C0.619583 0.267578 0.283797 0.603365 0.283797 1.01758V4.38714C0.283797 5.35364 1.0673 6.13714 2.0338 6.13714H5.34589C5.7601 6.13714 6.09589 5.80135 6.09589 5.38714C6.09589 4.97293 5.7601 4.63714 5.34589 4.63714H2.87918C3.8296 3.62919 4.5641 2.93719 5.28188 2.48039C6.0435 1.99568 6.80124 1.76758 7.8183 1.76758C9.54366 1.76757 10.6576 2.57498 11.3619 3.40138C11.718 3.81916 11.9659 4.23953 12.1242 4.55539C12.203 4.71253 12.2585 4.84155 12.2933 4.92829C12.3106 4.9716 12.3227 5.00416 12.33 5.02418L12.3376 5.04582L12.338 5.04693C12.4672 5.43924 12.8896 5.65337 13.2826 5.52533C13.6764 5.39704 13.8917 4.97376 13.7634 4.57992L13.0511 4.81196C13.7634 4.57991 13.7629 4.57841 13.7629 4.57841L13.7623 4.57656L13.7607 4.5718L13.7561 4.55816C13.7524 4.54727 13.7473 4.53282 13.7409 4.51503C13.7281 4.47946 13.7097 4.43049 13.6855 4.37004C13.6371 4.24927 13.5648 4.08182 13.4652 3.88322C13.2669 3.48759 12.956 2.95929 12.5036 2.42841C11.5909 1.35749 10.0888 0.267574 7.81829 0.267578Z" fill="currentColor"/>
            <path d="M9.60767 12.8583C8.59633 13.5019 7.55735 13.8057 6.2659 13.8057C3.9954 13.8057 2.49334 12.7157 1.58063 11.6448C1.12817 11.114 0.817308 10.5857 0.618994 10.19C0.51944 9.99142 0.447117 9.82398 0.39869 9.7032C0.374449 9.64275 0.356114 9.59378 0.343279 9.55822C0.33686 9.54043 0.33181 9.52597 0.328077 9.51508L0.323459 9.50145L0.321879 9.49669L0.321269 9.49483C0.321269 9.49483 0.320778 9.49333 1.03389 9.26102L0.320778 9.49333C0.19248 9.09948 0.407748 8.6762 0.801592 8.54791C1.19463 8.41987 1.61699 8.634 1.74622 9.02632L1.74659 9.02743L1.75422 9.04906C1.76145 9.06908 1.77357 9.10164 1.79094 9.14495C1.82572 9.23169 1.88119 9.36071 1.95996 9.51785C2.11829 9.83372 2.3662 10.2541 2.72226 10.6719C3.42658 11.4983 4.54053 12.3057 6.2659 12.3057C7.28296 12.3057 8.04069 12.0776 8.80232 11.5929C9.52009 11.1361 10.2546 10.4441 11.205 9.4361H8.73831C8.32409 9.4361 7.98831 9.10032 7.98831 8.6861C7.98831 8.27189 8.32409 7.9361 8.73831 7.9361H12.0504C13.0169 7.9361 13.8004 8.7196 13.8004 9.6861V13.0557C13.8004 13.4699 13.4646 13.8057 13.0504 13.8057C12.6362 13.8057 12.3004 13.4699 12.3004 13.0557V10.461C11.3331 11.4876 10.4884 12.2978 9.60767 12.8583Z" fill="currentColor"/>
        </svg>
        )
    }

    return (
        <svg width="24" height="24" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1594 0.00976563C9.25238 0.0097695 7.72307 0.457352 6.23251 1.40595C4.86796 2.27436 3.5578 3.55088 2.02588 5.18892V1.00977C2.02588 0.457481 1.57816 0.00976563 1.02588 0.00976563C0.473594 0.00976563 0.0258789 0.457481 0.0258789 1.00977V6.53633C0.0258789 7.6409 0.92131 8.53633 2.02588 8.53633H7.4666C8.01888 8.53633 8.4666 8.08861 8.4666 7.53633C8.4666 6.98404 8.01888 6.53633 7.4666 6.53633L3.50417 6.53633C5.0175 4.91975 6.17321 3.81435 7.30631 3.09324C8.46392 2.35653 9.61824 2.00977 11.1594 2.00977C13.7801 2.00976 15.4751 3.23837 16.5438 4.49233C17.0833 5.12541 17.4587 5.76193 17.6984 6.24011C17.8177 6.47815 17.9019 6.67393 17.9549 6.80623C17.9814 6.87228 18 6.92223 18.0113 6.95338C18.0169 6.96895 18.0207 6.97979 18.0226 6.98557L18.0235 6.98816L18.0241 6.98986C18.1963 7.51315 18.7597 7.79917 19.2838 7.62842C19.809 7.45736 20.096 6.89299 19.9249 6.36786L18.9741 6.6776C19.9249 6.36786 19.9249 6.36786 19.9249 6.36786L19.9243 6.36583L19.9234 6.36327L19.9212 6.3566L19.9146 6.33711C19.9092 6.32144 19.9019 6.30046 19.8925 6.27451C19.8738 6.22264 19.8469 6.15081 19.8113 6.06189C19.74 5.88422 19.6334 5.6372 19.4863 5.34388C19.1933 4.75935 18.734 3.97891 18.066 3.19503C16.7194 1.61506 14.507 0.00975883 11.1594 0.00976563Z" fill="currentColor"/>
            <path d="M13.7675 18.594C12.2769 19.5426 10.7476 19.9902 8.84065 19.9902C5.49302 19.9902 3.2806 18.3849 1.93404 16.805C1.26595 16.0211 0.806679 15.2407 0.513674 14.6561C0.366642 14.3628 0.259968 14.1158 0.188729 13.9381C0.153073 13.8492 0.126185 13.7774 0.107464 13.7255C0.098101 13.6995 0.0907711 13.6786 0.0854019 13.6629L0.0788025 13.6434L0.0765842 13.6367L0.0757431 13.6342L0.0753902 13.6331C0.0753902 13.6331 0.0750793 13.6321 1.0259 13.3224L0.0753902 13.6331C-0.0956722 13.108 0.19104 12.5426 0.716164 12.3716C1.24033 12.2008 1.80359 12.4865 1.97578 13.0098L1.97623 13.0112L1.97736 13.0144C1.97934 13.0202 1.98311 13.0311 1.98872 13.0466C1.99996 13.0778 2.01857 13.1277 2.04506 13.1938C2.0981 13.3261 2.1823 13.5218 2.30162 13.7599C2.54132 14.2381 2.91665 14.8746 3.45621 15.5077C4.52492 16.7616 6.21987 17.9902 8.84064 17.9902C10.3818 17.9902 11.5361 17.6435 12.6937 16.9068C13.8268 16.1856 14.9825 15.0802 16.4958 13.4637H12.5334C11.9811 13.4637 11.5334 13.016 11.5334 12.4637C11.5334 11.9114 11.9811 11.4637 12.5334 11.4637H17.9741C19.0787 11.4637 19.9741 12.3591 19.9741 13.4637V18.9902C19.9741 19.5425 19.5264 19.9902 18.9741 19.9902C18.4218 19.9902 17.9741 19.5425 17.9741 18.9902V14.8111C16.4422 16.4491 15.132 17.7256 13.7675 18.594Z" fill="currentColor"/>
        </svg>

    )
}