export const SPACING_UNIT = 4

export enum Colors {
    PRIMARY_MAIN = 'primaryMain',
    PRIMARY_LIGHT = 'primaryLight',
    SEMANTIC_RED = 'semanticRed',
    SEMANTIC_LIGHT_RED2 = 'semanticLightRed2',
    SEMANTIC_LIGHT_RED = 'semanticLightRed',
    SEMANTIC_GREEN = 'semanticGreen',
    SEMANTIC_LIGHT_GREEN = 'semanticLightGreen',
    SEMANTIC_YELLOW = 'semanticYellow',
    SEMANTIC_LIGHT_YELLOW = 'semanticLightYellow',
    NEUTRALS_DARK_BLUE_GREY = 'neutralsDarkBlueGrey',
    NEUTRALS_BLUE_GREY = 'neutralsBlueGrey',
    NEUTRALS_BLUE_GREY2 = 'neutralsBlueGrey2',
    NEUTRALS_PURPLE_GREY = 'neutralsPurpleGrey',
    NEUTRALS_LIGHT_BLUE_GREY = 'neutralsLightBlueGrey',
    NEUTRALS_LIGHT_BLUE_GREY2 = 'neutralsLightBlueGrey2',
    NEUTRALS_WHITE = 'neutralsWhite',
    SECONDARY_LIGHT_BLUE = 'secondaryLightBlue',
}

export enum RawColors {
    PRIMARY_MAIN = '#027AC5',
    PRIMARY_LIGHT = '#009DD6',
    SEMANTIC_RED = '#F64E4F',
    SEMANTIC_LIGHT_RED2 = '#FDDCDC',
    SEMANTIC_LIGHT_RED = '#FAA6A7',
    SEMANTIC_GREEN = '#04C094',
    SEMANTIC_LIGHT_GREEN = '#CDF2EA',
    SEMANTIC_YELLOW = '#F3AC23',
    SEMANTIC_LIGHT_YELLOW = '#FDEFD3',
    SEMANTIC_ORANGE = '#F87904',
    NEUTRALS_DARK_BLUE_GREY = '#001948',
    NEUTRALS_BLUE_GREY = '#41506C',
    NEUTRALS_BLUE_GREY2 = '#B3B9C5',
    NEUTRALS_LIGHT_BLUE_GREY = '#F2F4FB',
    NEUTRALS_LIGHT_BLUE_GREY2 = '#FCFDFE',
    NEUTRALS_WHITE = '#FFFFFF',
    NEUTRALS_LIGHT_GREY = '#E8EAEE',
    NEUTRALS_PURPLE_GREY = '#7984B4',
    SECONDARY_LIGHT_BLUE = '#E6F2F9',
    SECONDARY_SKY_BLUE = '#5EC1EC',
    SECONDARY_LIGHT_BLUE_GREEN = '#F2FAFB',
    CHART_AREA_GREEN = '#F2FAFB',
    SECONDARY_PURPLE = '#7B61FF',
    SECONDARY_NAVY_BLUE = '#0C398E',

    // tmp
    TMP_VERY_LOW = '#6FD08C',
    TMP_LOW = '#A3CE28',
    TMP_MODERATE_LOW = '#A3CE28',
    TMP_MODERATE = '#E0D703',
    TMP_MODERATE_HIGH = '#F3AC23',
    TMP_HIGH = '#D8762F',
    TMP_VERY_HIGH = '#DB593C',

}

export const BORDER_RADIUS = 20;
