import TrText from "components/common/TrText";
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import Pill from 'components/designSystem/components/Pill';
import ReferenceScale from 'components/designSystem/components/ReferenceScale';
import WithTooltip from "components/designSystem/components/WithTooltip";
import { WhiteBoxWithShadow } from 'components/designSystem/containers/Box';
import GridRow, { GridRowItem } from 'components/designSystem/containers/GridRow';
import Hoverable from 'components/designSystem/containers/Hoverable';
import Inline, {
    AlwaysInline,
    Grow,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from 'components/designSystem/containers/Inline';
import { XSmallSpacer } from "components/designSystem/containers/Spacer";
import {
    TBody500,
    TCaption,
    TCaption500,
    TOverline,
    TypographyColor
} from 'components/designSystem/containers/Typography';
import WithMargin, { MarginSize, WithBottomMargin } from 'components/designSystem/containers/WithMargin';
import Icon, { NullIcon } from 'components/designSystem/foundations/Icons';
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import {
    formatComments,
    formatTags,
    MobileResultMenu
} from 'components/modules/modular/modules/report/content/lines/utils';
import { BASE_GRID_SPLIT, NumericalReportLineProps } from 'components/modules/modular/modules/report/content/models';
import { TransDomain } from 'components/pages/common/MainComponent';
import { formatDate } from "core/utils/Date";
import { toLocalTransKey } from "models/common/message";
import { ModuleReportDataTagType, ValueTrend } from 'models/modular/report';
import React from 'react';

export const NumericalLine = (
    props: NumericalReportLineProps
): JSX.Element => {
    const notIconTags = props.tags ? props.tags.filter(
        (tag) => !(tag.payload.type === ModuleReportDataTagType.ICON)
    ) : []
    const iconTags = props.tags ? props.tags.filter(
        (tag) => tag.payload.type === ModuleReportDataTagType.ICON
    ) : []

    return props.isMobile ?
        <WhiteBoxWithShadow withOverflow>
            <WithMargin margins={[MarginSize.MEDIUM, MarginSize.MEDIUM]}>
                <XSmallSpacer>
                    <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                        <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                            <Pill severity={props.normality}/>
                            <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.name}</TCaption>
                            {formatTags(iconTags)}
                        </AlwaysInline>
                    </AlwaysInline>
                    {formatTags(notIconTags)}
                </XSmallSpacer>
            </WithMargin>
            <WithMargin margins={[MarginSize.SMALL, MarginSize.X_SMALL, MarginSize.SMALL, MarginSize.LARGE]}>
                <AlwaysInline alignItems={InlineAlignItem.CENTER}>
                    <Grow>
                        <AlwaysInline spacing={InlineSpacing.X_SMALL} alignItems={InlineAlignItem.CENTER}>
                            <TBody500 color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.value.value}</TBody500>
                            <TCaption color={TypographyColor.COLOR_TEXT_SUBDUED}>{props.value.unit}</TCaption>
                        </AlwaysInline>
                    </Grow>
                    {
                        props.additionalValue &&
                        <Grow>
                            <TOverline
                                color={TypographyColor.COLOR_TEXT_DISABLED}>{props.additionalValue.value}</TOverline>
                            <TOverline
                                color={TypographyColor.COLOR_TEXT_DISABLED}>{props.additionalValue.unit}</TOverline>
                        </Grow>
                    }
                </AlwaysInline>
            </WithMargin>
            <WithMargin margins={[MarginSize.X_SMALL, MarginSize.MEDIUM]}>
                {
                    props.referenceScale &&
                    <ReferenceScale withValue={false} line={props.referenceScale.line}
                                    points={props.referenceScale.points}/>
                }
            </WithMargin>
            {props.interpretation && <WithMargin margins={[MarginSize.SMALL, MarginSize.MEDIUM, MarginSize.LARGE, MarginSize.MEDIUM]}>
                <TCaption><TrText input={props.interpretation}/></TCaption>
            </WithMargin>}
            <WithBottomMargin margin={MarginSize.SMALL}>
                {formatComments(props.comments, props.isMobile)}
            </WithBottomMargin>
            <MobileResultMenu
                onDetailClick={props.onDetailClick}
                onHistoryClick={props.onHistoryClick}
            />
        </WhiteBoxWithShadow>
        :
        <>
            <Hoverable>
                <GridRow columnNb={BASE_GRID_SPLIT}>
                    <GridRowItem from={1} to={7}>
                        <WithMargin margins={[MarginSize.SMALL, MarginSize.MEDIUM]}>
                            <XSmallSpacer>
                                <Inline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                                    <Inline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                                        <Pill severity={props.normality}/>
                                        <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.name}</TCaption>
                                    </Inline>
                                    {formatTags(iconTags)}
                                </Inline>
                                {formatTags(notIconTags)}
                            </XSmallSpacer>
                        </WithMargin>
                    </GridRowItem>
                    <GridRowItem from={7} to={9}>
                        <WithMargin
                            margins={[MarginSize.SMALL, MarginSize.X_SMALL, MarginSize.SMALL, MarginSize.MEDIUM]}>
                            <Inline justifyContent={InlineJustifyContent.FLEX_END}>
                                <TBody500 color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.value.value}</TBody500>
                            </Inline>
                        </WithMargin>
                    </GridRowItem>
                    <GridRowItem from={9} to={11}>
                        <WithMargin
                            margins={[MarginSize.SMALL, MarginSize.MEDIUM, MarginSize.SMALL, MarginSize.X_SMALL]}>
                            <TCaption color={TypographyColor.COLOR_TEXT_SUBDUED}>{props.value.unit}</TCaption>
                        </WithMargin>
                    </GridRowItem>
                    <GridRowItem from={11} to={12}>
                        <TOverline color={TypographyColor.COLOR_TEXT_DISABLED}>{props.additionalValue?.value} </TOverline>
                        <TOverline color={TypographyColor.COLOR_TEXT_DISABLED}>{props.additionalValue?.unit}</TOverline>
                    </GridRowItem>
                    <GridRowItem from={13} to={18}>
                        <WithMargin margins={[MarginSize.X_SMALL, MarginSize.MEDIUM]}>
                            {
                                props.referenceScale &&
                                <ReferenceScale withValue={false} line={props.referenceScale.line}
                                                points={props.referenceScale.points}/>
                            }
                        </WithMargin>
                    </GridRowItem>
                    <GridRowItem from={18} to={20}>
                        <WithMargin margins={[MarginSize.SMALL, MarginSize.MEDIUM]}>
                            {
                                props.previousValue &&
                                <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
                                    {/*todo: use i18 when using right format date for english*/}
                                    <WithTooltip tooltip={
                                        <TrText input={
                                            {
                                                trkey: "previous_value_with_date",
                                                trdomain: TransDomain.MEDICAL_REPORT,
                                                trdata: {
                                                    date: formatDate(props.previousValue.date)
                                                }
                                            }
                                        } />
                                    }>
                                        <AlwaysInline
                                            spacing={InlineSpacing.SMALL}
                                            alignItems={InlineAlignItem.CENTER}
                                        >
                                            <TCaption500 color={TypographyColor.COLOR_TEXT_SUBDUED}>
                                                {props.previousValue.value.value}
                                            </TCaption500>
                                            <TCaption500 color={TypographyColor.COLOR_TEXT_SUBDUED}>
                                                {
                                                    props.previousValue.trend === ValueTrend.INCREASE &&
                                                    <Icon size={IconSize.EXTRA_SMALL} name={IconName.ARROW_RIGHT_UP} />
                                                }
                                                {
                                                    props.previousValue.trend === ValueTrend.DECREASE &&
                                                    <Icon size={IconSize.EXTRA_SMALL} name={IconName.ARROW_RIGHT_DOWN} />
                                                }
                                                {
                                                    props.previousValue.trend === ValueTrend.STABLE &&
                                                    <Icon size={IconSize.EXTRA_SMALL} name={IconName.EQUAL} />
                                                }
                                                {
                                                    [ValueTrend.UNKNOWN].includes(props.previousValue.trend) &&
                                                    <NullIcon size={IconSize.EXTRA_SMALL} />
                                                }
                                            </TCaption500>
                                        </AlwaysInline>
                                    </WithTooltip>
                                </AlwaysInline>
                            }

                        </WithMargin>
                    </GridRowItem>
                    <GridRowItem from={20} to={22}>
                        <WithMargin margins={[MarginSize.X_SMALL, MarginSize.SMALL]}>
                            <Inline justifyContent={InlineJustifyContent.FLEX_END} spacing={InlineSpacing.SMALL}>
                                <WithTooltip tooltip={
                                    <TrText capitalize input={toLocalTransKey("report.details", TransDomain.MODULES)}/>
                                }>
                                    <Button
                                        icons={{middle: IconName.INFO_CIRCLE}}
                                        variant={ButtonVariant.TERTIARY}
                                        size={ButtonSize.SMALL}
                                        onClick={props.onDetailClick}
                                        disabled={!props.onDetailClick}
                                    />
                                </WithTooltip>
                                <WithTooltip tooltip={
                                    <TrText capitalize input={toLocalTransKey("report.history", TransDomain.MODULES)}/>
                                }>
                                    <Button
                                        icons={{middle: IconName.CHART}}
                                        variant={ButtonVariant.TERTIARY}
                                        size={ButtonSize.SMALL}
                                        onClick={props.onHistoryClick}
                                        disabled={!props.onHistoryClick}
                                    />
                                </WithTooltip>
                            </Inline>
                        </WithMargin>
                    </GridRowItem>
                </GridRow>
            </Hoverable>
            {
                props.interpretation &&
                <WithMargin margins={[MarginSize.MEDIUM, MarginSize.SMALL]}>
                    <TCaption><TrText input={props.interpretation}/></TCaption>
                </WithMargin>
            }
            {
                formatComments(props.comments)
            }
        </>
}
