import { SCREEN_XL } from "components/designSystem/foundations/Breakpoints";

export const MOBILE_MAX_WIDTH = 576;
/**
 * @deprecated Using this constant for adaptive media queries is dangerous because the size of the content can vary due
 * to user accessibility settings. Prefer [TABLET_MAX_WIDTH_ACCESSIBLE] which takes into account the user's font size
 * defined through their accessibility preferences.
 */
export const TABLET_MAX_WIDTH = 1024;
export const TABLET_MAX_WIDTH_ACCESSIBLE = '64rem';

export const SUBSCRIPTION_MODULE_BREAKPOINT = 1400;
export const REDUCED_SIDE_BAR_BREAKPOINT = SCREEN_XL;