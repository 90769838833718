import React from 'react';

const LogoFull = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 186 34">
            <path d="M44.6503 13.0054H17.8326C15.9343 13.0054 14.396 14.5661 14.396 16.4921C14.396 18.4181 15.9343 19.9788 17.8326 19.9788H44.6503C46.5486 19.9788 48.0869 18.4181 48.0869 16.4921C48.0869 14.5661 46.5486 13.0054 44.6503 13.0054Z" fill="url(#paint0_linear_1865_6228)"/>
            <path d="M30.2543 22.0104H3.43658C1.53831 22.0104 0 23.5712 0 25.4972C0 27.4231 1.53831 28.9839 3.43658 28.9839H30.2543C32.1526 28.9839 33.6909 27.4231 33.6909 25.4972C33.6909 23.5712 32.1526 22.0104 30.2543 22.0104Z" fill="url(#paint1_linear_1865_6228)"/>
            <path d="M35.4179 4.00035H8.60016C6.70188 4.00035 5.16357 5.56111 5.16357 7.48709C5.16357 9.41307 6.70188 10.9738 8.60016 10.9738H35.4179C37.3161 10.9738 38.8544 9.41307 38.8544 7.48709C38.8544 5.56111 37.3161 4.00035 35.4179 4.00035Z" fill="url(#paint2_linear_1865_6228)"/>
            <path d="M106.737 0.582085H101.233V33.4208H106.737V0.582085Z" fill="#001948"/>
            <path d="M143.156 10.9909C143.156 4.28853 138.049 0.582085 131.707 0.582085H120.081V33.4179H125.542V21.3541H131.046L138.268 33.4179H144.697L136.904 20.4183C140.599 18.9003 143.156 15.6818 143.156 10.9909ZM131.22 16.4864H125.54V5.44982H131.22C135.009 5.44982 137.562 7.41575 137.562 10.9909C137.562 14.6089 135.054 16.4864 131.22 16.4864Z" fill="#001948"/>
            <path d="M169.532 0C160.066 0 153.063 7.23598 153.063 17.0228C153.063 26.8525 160.066 34 169.532 34C178.955 34 186 26.8525 186 17.0228C186.003 7.23884 178.955 0 169.532 0ZM169.532 28.8612C163.148 28.8612 158.657 23.9478 158.657 17.0228C158.657 10.0978 163.148 5.13881 169.532 5.13881C175.873 5.13881 180.409 10.0978 180.409 17.0228C180.409 23.9478 175.873 28.8612 169.532 28.8612Z" fill="#001948"/>
            <path d="M89.655 1.11851H82.3459L70.0563 15.3907V0.582085H64.5527V33.4179H70.0563V23.8793L74.8878 18.2697L85.2763 33.4179H91.9835L78.6197 13.9327L89.655 1.11851Z" fill="#001948"/>
            <defs>
                <linearGradient id="paint0_linear_1865_6228" x1="14.3943" y1="16.4925" x2="52.8732" y2="16.4925" gradientUnits="userSpaceOnUse">
                    <stop offset="0.3142" stopColor="#00B0F0"/>
                    <stop offset="0.8358" stopColor="#0285D0"/>
                    <stop offset="0.9513" stopColor="#027BC8"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1865_6228" x1="0.000956168" y1="25.4968" x2="33.6928" y2="25.4968" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#027BC8"/>
                    <stop offset="0.1581" stopColor="#0190D8"/>
                    <stop offset="0.3772" stopColor="#00A7E9"/>
                    <stop offset="0.5142" stopColor="#00B0F0"/>
                    <stop offset="0.6562" stopColor="#00A5E8"/>
                    <stop offset="0.9045" stopColor="#0288D2"/>
                    <stop offset="1" stopColor="#027BC8"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1865_6228" x1="5.16374" y1="7.48815" x2="38.8556" y2="7.48815" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#027BC8"/>
                    <stop offset="0.1581" stopColor="#0190D8"/>
                    <stop offset="0.3772" stopColor="#00A7E9"/>
                    <stop offset="0.5142" stopColor="#00B0F0"/>
                    <stop offset="0.6562" stopColor="#00A5E8"/>
                    <stop offset="0.9045" stopColor="#0288D2"/>
                    <stop offset="1" stopColor="#027BC8"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default LogoFull;
