import React, { Fragment } from 'react';

export function htmlLineBeaks(text: string): JSX.Element[] {
    return text.split('\n').map((item, key) =>
        item.length > 0 ? (
            <Fragment key={key}>
                {key > 0 && <br />}
                {item}
            </Fragment>
        ) : (
            <Fragment key={key}>
                <br />
            </Fragment>
        ),
    );
}