export enum PopUpInfoType {
    PATIENT_BETA = 'PATIENT_BETA',
}

export enum BusinessStructureType {
    AM = 'AM',
    FINESS = 'FINESS',
}

export enum NotificationInterval {
    DEFAULT = 'DEFAULT',
    NEVER = 'NEVER',
}

export interface DoctorInfo {
    practice_id: string;
    speciality: string;
    business_email: string;
    business_phone_number: string;
    business_address: string;
    business_structure_id: string;
    business_structure_type: string;
    notification_interval: string;
    pin_is_defined: boolean;
    [key: string]: string | boolean;
}

export interface AccountInfo {
    email: string;
    doctor_info: DoctorInfo | null;
    pin_is_defined: boolean;
}

export interface AccountInfoResponse {
    account_info: AccountInfo;
}



