import { ActionDispatcher, OnSuccessPutType } from 'actions/ActionInterface';
import { twoFASubmitCode } from 'actions/user/SignInSignUpActions';
import Form from "components/commonDesign/form/tmpNewSystem/Form";
import { MOBILE_MAX_WIDTH } from "components/core/constants";
import Shaker from 'components/core/containers/Shaker';
import Timer, { minutesAndSecondsToString } from 'components/core/items/Timer';
import { CenteredColumn } from "components/designSystem/Aliases";
import Alert from "components/designSystem/components/Alert";
import Button, { ButtonVariant } from "components/designSystem/components/Button";
import { PinCaseSize } from "components/designSystem/components/PinCase";
import PinField from "components/designSystem/components/PinField";
import Centered from "components/designSystem/containers/Centered";
import { ColumnWidthInREM } from "components/designSystem/containers/Column";
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import { LargeSpacer, MediumSpacer, XSmallSpacer } from "components/designSystem/containers/Spacer";
import { TBody, TDisplaySmall500, TDisplayXSmall500, TypographyColor } from "components/designSystem/containers/Typography";
import { IconName } from "components/designSystem/foundations/IconsData";
import APIFeedbackMessageContainer, {
    getAlertSeverity,
    ResetFunction
} from 'components/pages/common/APIFeedbackMessage';
import { setup, TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { TWO_FA_LENGTH } from "configuration/settings";
import { cast } from "core/utils/Typed";
import { APIFeedbackMessage } from 'models/common/APIMessaging';
import { TwoFaCodeFormat } from 'models/user/AuthModels';
import React, { ReactNode } from 'react';
import { Field as FinalField } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import MediaQuery from "react-responsive";

export const FEEDBACK_IDENTIFIER_API_TWO_FA_CODE = 'FEEDBACK_IDENTIFIER_API_TWO_FA_CODE';

export interface TwoFaCodeStateProps {
    token: string;
    partialEmail: string;
    timeoutInSeconds: number;
    codeFormat: TwoFaCodeFormat;
    onSuccessPut?: OnSuccessPutType;
    backToPreviousPage: () => void,
}

export interface TwoFaCodeDispatchProps {
    onSubmit: (data: { code: string; token: string }, onSuccessPut?: OnSuccessPutType) => void;
}

type FormValues = {
    TwoFA: string;
};

type TwoFAExtraData = {
    tooManyRequest?: boolean
}

class TwoFaCode extends WithTranslations<TwoFaCodeStateProps & TwoFaCodeDispatchProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT_2FA;

    onSubmit(values: FormValues): void {
        this.props.onSubmit(
            {
                code: values.TwoFA,
                token: this.props.token,
            },
            this.props.onSuccessPut,
        );
    }

    timeFormatter(minutes: number, seconds: number): ReactNode {
        const timerEnd = minutes + seconds <= 0
        return (
            <Centered>
                <TBody>
                    {
                        timerEnd ? this.trans('no_longer_time') : <AlwaysInline spacing={InlineSpacing.X_SMALL}>
                            {this.trans('remaining_time')}
                            <div className={'two_fa_timer_time'}>{minutesAndSecondsToString(minutes, seconds)}</div>
                        </AlwaysInline>
                    }
                </TBody>
            </Centered>
        );
    }

    formBuilder(isMobile: boolean): JSX.Element {
        let tries = 0
        const name = 'TwoFA';
        const pinSize: number | undefined = this.props.codeFormat === TwoFaCodeFormat.SIX_DIGITS ?
            TWO_FA_LENGTH : undefined;
        const directSubmit = (code: string): void =>
            this.props.onSubmit({code: code, token: this.props.token}, this.props.onSuccessPut);

        return <Form
            onSubmit={(values: FormValues): void => this.onSubmit(values)}
        >
            {(): JSX.Element => (
                <FinalField
                    name={name}
                    parse={(value: string[]): string => {
                        return value.join('');
                    }}
                >
                    {({input: {onChange}, meta}): JSX.Element => {
                        return (
                            <APIFeedbackMessageContainer
                                identifier={FEEDBACK_IDENTIFIER_API_TWO_FA_CODE}
                                message_renderer={(
                                    message: APIFeedbackMessage | undefined,
                                    resetErrors: ResetFunction,
                                ): JSX.Element => {
                                    if (message) {
                                        tries += 1;
                                    }
                                    const disableForm = message && message.extraData &&
                                        cast<TwoFAExtraData>(message.extraData).tooManyRequest
                                    return (
                                        <MediumSpacer>
                                            <Shaker shake={!!message}>
                                                <Centered>
                                                    <PinField
                                                        key={'key-f-' + tries}
                                                        name={name}
                                                        numberOfCase={pinSize}
                                                        onDone={(v): void => directSubmit(v)}
                                                        hidden={true}
                                                        autoComplete={'one-time-code'}
                                                        defaultFocus={true}
                                                        error={meta.error}
                                                        touched={meta.touched}
                                                        onChange={onChange}
                                                        disabled={disableForm}
                                                        sizeCase={isMobile ? PinCaseSize.X_LARGE : PinCaseSize.XX_LARGE}
                                                    />
                                                    <OnChange key={name} name={name}>
                                                        {(v: string): void => {
                                                            if (message && v.length < 6) {
                                                                resetErrors();
                                                            }
                                                            if (v.length >= 6) {
                                                                directSubmit(v);
                                                            }
                                                        }}
                                                    </OnChange>
                                                </Centered>
                                            </Shaker>
                                            {!disableForm && <Timer
                                                seconds={this.props.timeoutInSeconds}
                                                timeFormatter={(minutes: number, seconds: number): ReactNode =>
                                                    this.timeFormatter(minutes, seconds)
                                                }
                                            />}
                                            {message && (
                                                <Alert
                                                    severity={getAlertSeverity(message.type)}
                                                    description={this.fromLegacyTrans(message.message)}
                                                />
                                            )}
                                        </MediumSpacer>
                                    );
                                }}
                            />
                        )
                    }}
                </FinalField>
            )}
        </Form>
    }

    buildContent(isMobile: boolean): JSX.Element {
        const title = isMobile ? <TDisplayXSmall500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
            {this.trans('title')}
        </TDisplayXSmall500> : <TDisplaySmall500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
            {this.trans('title')}
        </TDisplaySmall500>

        return <LargeSpacer>
            <XSmallSpacer>
                <AlwaysInline justifyContent={InlineJustifyContent.SPACE_BETWEEN}
                              alignItems={InlineAlignItem.CENTER}
                >
                    {title}
                    <Button variant={ButtonVariant.TERTIARY} icons={{middle: IconName.CLOSE}}
                            onClick={(): void => this.props.backToPreviousPage()}
                    />
                </AlwaysInline>
                <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                    {
                        this.withBuilder(
                            <>Code at this address: <strong>address</strong></>
                        ).trans(
                            'explanation',
                            {email: this.props.partialEmail}
                        )
                    }
                </TBody>
            </XSmallSpacer>
            <XSmallSpacer>
                {this.formBuilder(isMobile)}
            </XSmallSpacer>
        </LargeSpacer>
    }

    render(): ReactNode {
        return (
            <>
                <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
                    {this.buildContent(true)}
                </MediaQuery>
                <MediaQuery minWidth={MOBILE_MAX_WIDTH + 1}>
                    <CenteredColumn widthInRem={ColumnWidthInREM.WIDTH_28}>
                        {this.buildContent(false)}
                    </CenteredColumn>
                </MediaQuery>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): TwoFaCodeDispatchProps => ({
    onSubmit: (data: { code: string; token: string }, onSuccessPut?: OnSuccessPutType): void => {
        dispatch(twoFASubmitCode(data, FEEDBACK_IDENTIFIER_API_TWO_FA_CODE, onSuccessPut));
    }
});

export default setup(TwoFaCode, () => ({}), mapDispatchToProps);
