import { TagSeverity } from "components/designSystem/components/Tag";
import { Chart } from 'models/charts/charts';
import { APIText } from 'models/common/message';
import {ExternalLink} from "models/modular/teleconsultation";


export interface ReportInfo {
    uuid: string,
    patientUUID: string;
    samplingDate: Date,
    receptionDate: Date,
    isNew: boolean,
    isSent?: boolean
    isAiSuggestionsEnriched?: boolean
}


export interface ApiReportInfo {
    uuid: string;
    patient_uuid: string;
    sampling_date: string;
    reception_date: string;
    is_new: boolean;
    access_token?: string;
    is_sent?: boolean;
    is_ai_suggestions_enriched?: boolean;
}

export interface APINumericalValue {
    display_value?: string;
    unit?: string;
}

export interface APICategoricalValue {
    display_value: string | null;
}

export interface APIAntibiogramBacteria {
    name: string;
}

export interface APIAntibiogramAntibiotic {
    name: string;
    sensibility: string | null;
}

export interface APIAntibiogramValue {
    bacteria: APIAntibiogramBacteria
    antibiotics: APIAntibiogramAntibiotic[]
}

export enum RSSeverity {
    EXTREME = 'EXTREME',
    ABNORMAL = 'ABNORMAL',
    NORMAL = 'NORMAL',
}

export interface RSLineSlice {
    end_position: number;
    severity: RSSeverity;
}

export interface RSLabel {
    position: number;
    value: string;
}

export interface RSZone {
    start_position: number;
    end_position: number;
    value: APIText;
}

export interface RSLine {
    slices: RSLineSlice[];
    labels: RSLabel[];
    zones: RSZone[];
}

export interface RSPoint {
    position: number;
    value: string;
    severity?: RSSeverity
}

export interface ReferenceScale {
    line: RSLine;
    points: RSPoint[];
}

export enum ValueTrend {
    UNKNOWN = 'UNKNOWN',
    STABLE = 'STABLE',
    INCREASE = 'INCREASE',
    DECREASE = 'DECREASE',
}

export interface NumericalResultHistoryPrevious {
    value: APINumericalValue,
    trend: ValueTrend,
    date: string,
}

export interface NumericalResultHistory {
    previous_value?: NumericalResultHistoryPrevious,
    history_chart?: Chart,
}


export interface NumericalResultPayload {
    value: APINumericalValue,
    reference_scale?: ReferenceScale,
    additional_value?: APINumericalValue,
    additional_value_indication?: APIText,
    history: NumericalResultHistory,
}

export interface CategoricalResultPayload {
    value: APICategoricalValue;
}

export interface AntibiogramResultPayload {
    value: APIAntibiogramValue;
}

export enum ModuleReportResultValueType {
    NUMERICAL = 'NUMERICAL',
    CATEGORICAL = 'CATEGORICAL',
    ANTIBIOGRAM = 'ANTIBIOGRAM',
}

export interface ModuleReportResultValue {
    type: ModuleReportResultValueType;
    payload: NumericalResultPayload | CategoricalResultPayload | AntibiogramResultPayload;
}


export interface ModuleReportResultIdentification {
    legacy_identifier: string;
    name: string;
    internal_id?: string;
}


export enum ModuleReportResultNormality {
    UNKNOWN = 'UNKNOWN',
    NORMAL = 'NORMAL',
    BAD = 'BAD',
    PARTIALLY_BAD = 'PARTIALLY_BAD',
}


export enum ModuleReportResultStatus {
    UNKNOWN = "UNKNOWN",
    TODO = "TODO",
    IN_PROGRESS = "IN_PROGRESS",
    FINAL = "FINAL",
    CANCELLED = "CANCELLED",
}


export interface ModuleReportResultInformationLink {
    name?: string;
    url: string;
}


export interface ModuleReportResultInformation {
    description: APIText;
    links: ModuleReportResultInformationLink[];
}


export interface ModuleReportComment {
    content: APIText;
}

export interface ModuleReportResultSource{
    name?: APIText;
    link: ExternalLink;
}



export interface ModuleReportResultRule{
    name?: APIText;
    description: APIText;
    specific_sources: ModuleReportResultSource[];
    match: boolean;
}


export interface ModuleReportResultInterpretation{
    content?: APIText
    global_sources: ModuleReportResultSource[]
    rules: ModuleReportResultRule[]
}

export enum ModuleReportDataTagType {
    ICON = 'ICON',
    TEXT = 'TEXT',
}

export enum TextTagValue {
    NOT_MAPPED = 'NOT_MAPPED',
    NOT_READY = 'NOT_READY',
}

export enum IconTagTooltip {
    CONFIDENTIAL = 'CONFIDENTIAL'
}

export interface IconTagPayload {
    type: ModuleReportDataTagType;
    tooltip: IconTagTooltip;
    iconName: string;
}

export interface TextTagPayload {
    type: ModuleReportDataTagType;
    value: TextTagValue;
    additional_value?: string;
}

export interface ModuleReportDataTag {
    payload: IconTagPayload | TextTagPayload;
    severity: TagSeverity
}

export interface ModuleReportResult {
    identification: ModuleReportResultIdentification;
    value?: ModuleReportResultValue;
    normality: ModuleReportResultNormality;
    important?:boolean;
    status: ModuleReportResultStatus;
    information?: ModuleReportResultInformation;
    comments: ModuleReportComment[];
    interpretations?:ModuleReportResultInterpretation;
    tags: ModuleReportDataTag[];
}


export interface ModuleReportSubCategory {
    name?: string;
    results: ModuleReportResult[];
}


export interface ModuleReportCategory {
    name?: string;
    results: ModuleReportResult[];
    sub_categories: ModuleReportSubCategory[];
    icon?: string;
    comments?: ModuleReportComment[];
}

export interface ModuleReportParentCategory {
    name: string;
    categories: ModuleReportCategory[];
}