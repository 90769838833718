import { connect } from 'react-redux';
import React, { PropsWithChildren, ReactNode } from 'react';
import LOGGER from 'core/logging/Logger';
import Page500 from 'components/pages/errors/Page500';

export interface ErrorHandlerState {
    hasError: boolean;
}

class ErrorHandler extends React.Component<PropsWithChildren<{}>> {
    state: ErrorHandlerState = { hasError: false };

    static getDerivedStateFromError(): ErrorHandlerState {
        return { hasError: true };
    }

    componentDidCatch(error: Error): void {
        LOGGER.error(error.message, null, error.stack);
    }

    render(): ReactNode {
        if (this.state.hasError) {
            return <Page500 />;
        }
        return this.props.children;
    }
}

const mapDispatchToProps = (): {} => ({});

const mapStateToProps = (): {} => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
