import React, { ReactNode, RefObject } from 'react';
import { reducer } from 'reducers/selector';
import { REDUCER_API_MESSAGING } from 'reducers/allReducers';
import MainComponent, { MainComponentProps, setup, TransDomain } from 'components/pages/common/MainComponent';
import './FeedbackMessage.scss';
import { cleanAPIFeedbackMessages, registerAPIFeedback } from 'actions/common/APIMessaginActions';
import { APIFeedbackMessage, APIFeedbackMessageType } from 'models/common/APIMessaging';
import Information from 'components/core/containers/Information';
import { SeverityType } from 'components/core/types/Types';
import { ActionDispatcher, AnyAction } from 'actions/ActionInterface';
import { AnyState } from 'core/store/Store';
import { ROUTE_FAQ } from 'core/routing/Routes';
import Alert, { AlertSeverity } from "components/designSystem/components/Alert";

export interface APIFeedbackMessageStateProps {
    messages: APIFeedbackMessage[];
}

export type ResetFunction = () => void;

export type resetMessagesHandlerInterface = (identifier: string) => void;
export const resetMessagesHandler = (dispatch: ActionDispatcher): resetMessagesHandlerInterface => (
    identifier: string,
): void => dispatch(cleanAPIFeedbackMessages(identifier));

export interface APIFeedbackMessageDispatchProps {
    message_renderer?: (message: APIFeedbackMessage | undefined, resetErrors: ResetFunction) => JSX.Element;
    register: (identifier: string) => void;
    thenDispatch: (action: AnyAction) => void;
    resetErrors: resetMessagesHandlerInterface;
}

export interface ResetCondition {
    id: string;
    action: string;
    onEvent?: (element: HTMLElement, ev: Event, resetMethod: () => void) => void;
}

export interface APIFeedbackMessageProps
    extends APIFeedbackMessageStateProps,
        APIFeedbackMessageDispatchProps,
        MainComponentProps {
    identifier: string;
    withBorderRadius?: boolean;
    resetConditions?: ResetCondition[];
    withNewDesignSystem?: boolean;
}

export const DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE = 'DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE';
export const DEFAULT_IDENTIFIER_MAIN_HEADER = 'DEFAULT_IDENTIFIER_MAIN_HEADER';

export function matchSeverity(type?: APIFeedbackMessageType): SeverityType {
    switch (type) {
        case APIFeedbackMessageType.ERROR:
            return SeverityType.FAILURE;
        case APIFeedbackMessageType.WARNING:
            return SeverityType.WARNING;
        case APIFeedbackMessageType.SUCCESS:
            return SeverityType.SUCCESS;
        default:
            return SeverityType.INFO;
    }
}

export function getAlertSeverity(type?: APIFeedbackMessageType): AlertSeverity {
    switch (type) {
        case APIFeedbackMessageType.ERROR:
            return AlertSeverity.CRITICAL;
        case APIFeedbackMessageType.WARNING:
            return AlertSeverity.WARNING;
        case APIFeedbackMessageType.SUCCESS:
            return AlertSeverity.SUCCESS;
        default:
            return AlertSeverity.HIGHLIGHT;
    }
}

class APIFeedbackMessageContainer extends MainComponent<APIFeedbackMessageProps> {
    TRANS_SUFFIX = TransDomain.MESSAGES;

    myRef: RefObject<HTMLDivElement> | undefined;

    safelyReset(): void {
        if (
            this.props.messages.filter((message: APIFeedbackMessage) => this.props.identifier === message.identifier)
                .length === 0
        ) {
            return;
        }

        this.props.resetErrors(this.props.identifier);
    }

    componentDidMount(): void {
        const {identifier} = this.props;
        this.props.register(identifier);
        const reset = (): void => this.safelyReset();

        if (this.props.resetConditions) {
            this.props.resetConditions.forEach((i) => {
                const element = document.getElementById(i.id);
                if (!element) return;

                const onEvent = i.onEvent;

                element.addEventListener(i.action, onEvent ? (ev): void => onEvent(element, ev, reset) : reset);
            });
        }
    }

    thenDispatch(action: AnyAction): void {
        this.props.thenDispatch(action);
    }

    componentDidUpdate(): void {
        this.scrollToMyRef();
    }

    render(): ReactNode {
        const {messages, identifier, withBorderRadius} = this.props;
        const resetErrors = (): void => this.props.resetErrors(identifier);

        const supportedMessages: APIFeedbackMessage[] = messages.filter(
            (message: APIFeedbackMessage) => identifier === message.identifier,
        );

        if (supportedMessages.length === 0) {
            return this.props.message_renderer ? (
                this.props.message_renderer(undefined, resetErrors)
            ) : (
                this.props.withNewDesignSystem ? <>{this.props.children}</> : <div>{this.props.children}</div>
            );
        }

        const message = supportedMessages[supportedMessages.length - 1];

        if (this.props.message_renderer) {
            return this.props.message_renderer(message, resetErrors);
        }

        const translatedMessage = this.transContent(
            message.message,
            // In waiting of a proper redesihgn of API messaging
            <>O<a className={"legacy-a"} rel={"noreferrer"} href={ROUTE_FAQ} target={"_blank"}>1</a>2</>
        );

        if (message.thenDispatch) {
            const thenDispatch = message.thenDispatch;
            setTimeout(
                () => this.thenDispatch(thenDispatch.thenDispatchAction),
                // 200 letters => 10s
                thenDispatch.thenDispatchDelay || 5000 //Math.max(5000, translatedMessage.length * 50),
            );
        }

        this.myRef = React.createRef();

        return (
            <div ref={this.myRef}>
                {this.props.withNewDesignSystem ?
                    <Alert description={translatedMessage} severity={getAlertSeverity(message.type)}/> :
                    <Information withBorderRadius={withBorderRadius} severity={matchSeverity(message.type)}>
                        {translatedMessage}
                    </Information>
                }
            </div>
        );
    }

    scrollToMyRef(): void {
        if (this.myRef && this.myRef.current) {
            window.scrollTo(0, this.myRef.current.offsetTop);
        }
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): APIFeedbackMessageDispatchProps => ({
    register: (identifier: string): void => dispatch(registerAPIFeedback(identifier)),
    thenDispatch: (action: AnyAction): void => dispatch(action),
    resetErrors: resetMessagesHandler(dispatch),
});

const mapStateToProps = (state: AnyState): APIFeedbackMessageStateProps => {
    return {
        messages: reducer(state, REDUCER_API_MESSAGING).messages,
    };
};

export default setup(APIFeedbackMessageContainer, mapStateToProps, mapDispatchToProps);
