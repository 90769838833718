import React from 'react';

export const CPSLogo = (): JSX.Element => {
    return <svg width={"24"} id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.89 116.49">

        <path className="cls-1"
              d="M51.64,6.19a3,3,0,0,1,1.56.42L95.62,31a3.15,3.15,0,0,1,1.57,2.72L97.3,82.6a3.16,3.16,0,0,1-1.56,2.72L53.42,109.88a3.18,3.18,0,0,1-1.57.42,3.14,3.14,0,0,1-1.56-.42L7.87,85.52A3.15,3.15,0,0,1,6.3,82.8L6.19,33.88a3.18,3.18,0,0,1,1.56-2.72L50.06,6.61a3.25,3.25,0,0,1,1.58-.42m0-6.19A9.29,9.29,0,0,0,47,1.26L4.65,25.81A9.31,9.31,0,0,0,0,33.9L.11,82.82a9.3,9.3,0,0,0,4.68,8.06L47.2,115.25a9.33,9.33,0,0,0,9.33,0L98.84,90.68a9.33,9.33,0,0,0,4.65-8.09l-.11-48.92a9.32,9.32,0,0,0-4.68-8.06L56.28,1.24A9.25,9.25,0,0,0,51.64,0Z"
              fill="#000091"
        />
        <circle className="cls-2" cx="100.6" cy="57.49" r="8.58" fill="#fff" strokeWidth="5.42px" stroke="#6f7072" strokeMiterlimit="10"/>
        <circle className="cls-3" cx="28.19" cy="16.27" r="8.58" fill="#fff" strokeWidth="5.42px" strokeMiterlimit="10" stroke="#e1000f"/>
        <circle className="cls-4" cx="24.63" cy="99.25" r="8.58" fill="#fff" strokeWidth="5.42px" strokeMiterlimit="10" stroke="#000091"/>
        <path className="cls-5"
              d="M68.26,54.18l-31.66.5a2.23,2.23,0,0,1-2.26-2.2l-.23-9.91A16.88,16.88,0,0,1,50.7,25.45l2.41,0A16.87,16.87,0,0,1,70.23,42l.22,9.91A2.23,2.23,0,0,1,68.26,54.18Zm-29.5-4L66,49.75l-.19-7.68A12.41,12.41,0,0,0,53.18,29.88l-2.41,0A12.41,12.41,0,0,0,38.57,42.5Z"
              fill="#e1000f" strokeMiterlimit="10" stroke="#e1000f"
        />
        <path className="cls-6"
              d="M68.71,83.08l-31.65.5a2.23,2.23,0,0,1-2.27-2.2l-.45-28.9a2.23,2.23,0,0,1,2.19-2.27l31.66-.5a2.23,2.23,0,0,1,2.26,2.2l.46,28.9A2.23,2.23,0,0,1,68.71,83.08Zm-29.49-4,27.19-.43L66,54.21l-27.2.43Z"
              fill="#000091" strokeMiterlimit="10" stroke="#000091" strokeWidth="2.17px"
        />
        <path className="cls-5"
              d="M52.71,72.32a2.23,2.23,0,0,1-2.26-2.2l-.11-6.88a2.23,2.23,0,1,1,4.46-.07l.11,6.88A2.23,2.23,0,0,1,52.71,72.32Z"
              fill="#e1000f" strokeWidth="2.17px"
        />
    </svg>
}
