import React from 'react';

const MaleIcon = (): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_923_23689)">
                <path d="M5.56231 10.7119C0.964591 15.3096 0.95361 22.7798 5.55146 27.3776C10.1493 31.9755 17.6468 31.9918 22.2446 27.3941C26.307 23.3317 26.7344 16.9768 23.6775 12.4496L27.3375 8.78972L27.3409 8.83638C27.4201 9.90714 28.3594 10.7065 29.429 10.6134C30.4871 10.5213 31.271 9.59024 31.1815 8.53194L30.7644 3.60132C30.6945 2.77584 30.0467 2.11714 29.2225 2.0336L24.2139 1.52591C23.1332 1.41637 22.1698 2.20725 22.0668 3.28856C21.9652 4.35479 22.7389 5.30487 23.8037 5.42122L24.1319 5.45709L20.3826 9.20645C15.8026 6.20267 9.57671 6.69746 5.56231 10.7119ZM18.9484 24.098C16.1532 26.8932 11.6049 26.8932 8.80956 24.0978C6.01421 21.3025 6.01421 16.7542 8.80943 13.959C11.6048 11.1636 16.1531 11.1636 18.9484 13.959C21.7438 16.7543 21.7438 21.3026 18.9484 24.098Z" fill="currentColor" fillRule="evenodd"/>
                <circle cx="14" cy="19" r="8.5" fillRule="evenodd" fill="currentColor" fillOpacity={0.5}/>
            </g>
            <defs>
                <clipPath id="clip0_923_23689">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default MaleIcon;
