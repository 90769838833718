import MainComponent, { MainComponentProps, setupRaw, TransDomain } from 'components/pages/common/MainComponent';
import React, { Fragment, ReactNode } from 'react';
import Field from 'components/utils/form/Field';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_EULA_DATA_PRIVACY, ROUTE_EULA_HEALTH_PROFESSIONAL, ROUTE_EULA_PATIENT } from 'core/routing/Routes';
import { required } from 'components/utils/form/validators';
import { EULAType } from 'models/user/AuthModels';
import SimpleBox, { SimpleBoxType } from 'components/core/containers/SimpleBox';
import LegacyEulaDataPrivacyNoticeV3 from 'components/modules/eula/versions/LegacyEulaDataPrivacyNoticeV3';

export type RequiredEula = {
    eulaType: EULAType;
    version: number;
    useNewNaming?: boolean // Only for migration
};
export interface EULAFieldsProps extends MainComponentProps {
    types: RequiredEula[];
}

class EULAFields extends MainComponent<EULAFieldsProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT + '.eula';

    getNameFromType(eulaType: EULAType, version: number, useNewNaming?: boolean): string {
        if (useNewNaming) {
            return `EULA:${eulaType}:${version}`
        }

        switch (eulaType) {
            case EULAType.HEALTH_PROFESSIONAL:
                return 'eula-acceptance';
            case EULAType.PATIENT:
                return 'eula-acceptance';
            case EULAType.DATA_PRIVACY:
                return 'data-privacy-acceptance';
            case EULAType.DATA_PRIVACY_NOTICED:
                return 'data-privacy-acceptance';
        }

        return '_unknown';
    }

    getRouteFromEulaType(eulaType: EULAType): string {
        switch (eulaType) {
            case EULAType.HEALTH_PROFESSIONAL:
                return ROUTE_EULA_HEALTH_PROFESSIONAL;
            case EULAType.PATIENT:
                return ROUTE_EULA_PATIENT;
            case EULAType.DATA_PRIVACY:
                return ROUTE_EULA_DATA_PRIVACY;
            case EULAType.DATA_PRIVACY_NOTICED:
                return ROUTE_EULA_DATA_PRIVACY;
        }

        return '_unknown';
    }

    getTransKeyFromEulaType(eulaType: EULAType): string {
        switch (eulaType) {
            case EULAType.HEALTH_PROFESSIONAL:
                return 'eula_acceptance';
            case EULAType.PATIENT:
                return 'eula_acceptance';
            case EULAType.DATA_PRIVACY:
                return 'data_privacy_acceptance';
            case EULAType.DATA_PRIVACY_NOTICED:
                return 'data_privacy_noticed_acceptance';

        }

        return '_unknown';
    }

    render(): ReactNode {
        const { types } = this.props;

        const displayNotice: boolean = types.map((t: RequiredEula) => t.eulaType).includes(EULAType.DATA_PRIVACY_NOTICED);

        return (
            <Fragment>
                {
                    displayNotice &&
                        <SimpleBox type={SimpleBoxType.SCROLLING_NOTICE}>
                            <LegacyEulaDataPrivacyNoticeV3 />
                        </SimpleBox>
                }

                {types.map((t) => (
                    <Field
                        key={t.eulaType}
                        name={this.getNameFromType(t.eulaType, t.version, t.useNewNaming)}
                        component='checkbox'
                        description={
                            <span>
                                {this.transContent(
                                    this.getTransKeyFromEulaType(t.eulaType),
                                    <>
                                        EULA accessible
                                        <a
                                            style={{ textDecoration: 'none' }}
                                            target={'_blank'}
                                            rel="noopener noreferrer"
                                            href={getRoute(this.getRouteFromEulaType(t.eulaType), {
                                                version: t.version.toString(),
                                            })}
                                            className={"legacy-a"}

                                        >
                                            here
                                        </a>
                                    </>,
                                )}
                            </span>
                        }
                        validate={required}
                    />
                ))}
            </Fragment>
        );
    }
}

export default setupRaw(EULAFields);
