import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import Image, { ImageHeight, ImageObjectFit, ImageWidth } from "components/designSystem/components/Image";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import { SmallSpacer } from "components/designSystem/containers/Spacer";
import {
    TCaption,
    TLabel500,
    TypographyColor
} from "components/designSystem/containers/Typography";
import WithPadding, { PaddingSize } from "components/designSystem/containers/WithPadding";
import * as React from 'react';
import MediaQuery from "react-responsive";

import styles from './ImgCard.module.scss';

type ImgCardProps = {
    imageUrl: string
    imageAlt: string
    title: string
    description?: string
    onClick: () => void
};

export const ImgCard = (props: ImgCardProps): JSX.Element => <div className={styles.imgCardContainer}>
    <Box background={BoxColor.WHITE} withRadius withBoxShadow={'hover'}>
        <div className={styles.imgCard} onClick={props.onClick}>
            <Image
                src={props.imageUrl}
                alt={props.imageAlt}
                width={ImageWidth.FULL}
                height={ImageHeight.CAROUSEL}
                withObjectFit={ImageObjectFit.COVER}/>
            <WithPadding paddings={[PaddingSize.MEDIUM]}>
                <SmallSpacer>
                    <div className={styles.imgCardTitle}>
                        <MediaQuery minWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
                            {(isDesktop): JSX.Element => isDesktop ?
                                <TLabel500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                    {props.title}
                                </TLabel500> : <TLabel500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                    {props.title}
                                </TLabel500>}
                        </MediaQuery>
                    </div>
                    <div className={styles.imgCardDescription}>
                        {props.description && <TCaption color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            {props.description}
                        </TCaption>}
                    </div>
                </SmallSpacer>
            </WithPadding>
        </div>
    </Box>
</div>;

export default ImgCard;