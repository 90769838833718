import * as React from "react";

const VividChart = (): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
    >
        <path
            fill="#26BD53"
            fillRule="evenodd"
            d="M20 1a3 3 0 0 0-2.011 5.226l-2.824 6.778a3.002 3.002 0 0 0-1.062.132l-2.606-3.473a3 3 0 1 0-4.508.563l-2.824 6.778a3 3 0 1 0 1.846.77l2.824-6.779a3.01 3.01 0 0 0 1.062-.131l2.606 3.473a3 3 0 1 0 4.508-.564l2.824-6.777c.055.002.11.004.165.004a3 3 0 1 0 0-6Z"
            clipRule="evenodd"
        />
        <circle cx={8.991} cy={8} r={1.769} fill="#DBFFDA"/>
        <circle cx={3.987} cy={19.966} r={1.769} fill="#DBFFDA"/>
        <circle cx={15.008} cy={16.067} r={1.769} fill="#DBFFDA"/>
        <circle cx={20.052} cy={3.944} r={1.769} fill="#DBFFDA"/>
    </svg>
);

export default VividChart;

