import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Lab = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-lab'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_167_171)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M3 6V2.5C3 1.11929 4.11929 0 5.5 0H10.5C11.8807 0 13 1.11929 13 2.5V6C14.6569 6 16 7.34315 16 9V13.5C16 14.8807 14.8807 16 13.5 16H13H11.5H4.5H3H2.5C1.11929 16 0 14.8807 0 13.5V8.5C0 7.11929 1.11929 6 2.5 6H3ZM4.5 6V14.5H5.75V11C5.75 10.0335 6.5335 9.25 7.5 9.25H8.5C9.4665 9.25 10.25 10.0335 10.25 11V14.5H11.5V6V2.5C11.5 1.94772 11.0523 1.5 10.5 1.5H5.5C4.94772 1.5 4.5 1.94772 4.5 2.5V6ZM3 7.5H2.5C1.94772 7.5 1.5 7.94772 1.5 8.5V13.5C1.5 14.0523 1.94772 14.5 2.5 14.5H3V7.5ZM13.5 14.5H13V7.5C13.8284 7.5 14.5 8.17157 14.5 9V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5ZM7.25 14.5V11C7.25 10.8619 7.36193 10.75 7.5 10.75H8.5C8.63807 10.75 8.75 10.8619 8.75 11V14.5H7.25ZM6.25018 3C6.25018 2.58579 6.58597 2.25 7.00018 2.25H9.00018C9.4144 2.25 9.75018 2.58579 9.75018 3C9.75018 3.41421 9.4144 3.75 9.00018 3.75H8.75018V4.29226L9.68895 5.85688C10.1888 6.69004 9.58871 7.75 8.61709 7.75H7.38328C6.41166 7.75 5.81152 6.69003 6.31141 5.85688L7.25018 4.29226V3.75H7.00018C6.58597 3.75 6.25018 3.41421 6.25018 3ZM8.00018 5.95774L7.82483 6.25H8.17554L8.00018 5.95774Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_167_171">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-lab'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17 23H7H5H4C2.34315 23 1 21.6569 1 20V12C1 10.3431 2.34315 9 4 9H5V4C5 2.34315 6.34314 1 8 1H16C17.6569 1 19 2.34315 19 4V9C21.2091 9 23 10.7909 23 13V20C23 21.6569 21.6569 23 20 23H19H17ZM8.5 21H7V9V4C7 3.44772 7.44772 3 8 3H16C16.5523 3 17 3.44772 17 4V9V21H15.5V15C15.5 13.8954 14.6046 13 13.5 13H10.5C9.39543 13 8.5 13.8954 8.5 15V21ZM5 11V21H4C3.44772 21 3 20.5523 3 20V12C3 11.4477 3.44772 11 4 11H5ZM19 21H20C20.5523 21 21 20.5523 21 20V13C21 11.8954 20.1046 11 19 11V21ZM13.5 15V21H10.5V15H13.5ZM11.0001 4C10.4478 4 10.0001 4.44772 10.0001 5C10.0001 5.55228 10.4478 6 11.0001 6V7.13008L9.65576 8.69842C8.54375 9.99576 9.46557 12 11.1743 12H12.8258C14.5345 12 15.4564 9.99576 14.3443 8.69842L13.0001 7.13008V6C13.5523 6 14.0001 5.55228 14.0001 5C14.0001 4.44772 13.5523 4 13.0001 4H11.0001ZM11.1743 10L12.0001 9.03659L12.8258 10H11.1743Z"
                  fill="currentColor"/>
        </svg>
    )
}