import { fork } from 'redux-saga/effects';
import articleSagas from "sagas/bioProfile/BioProfileSagas";
import medicalReportsSaga from 'sagas/medicalReports/MedicalReportsSagas';
import signInSignUpSagas from 'sagas/user/SignInSignUpSagas';
import commonSagas from 'sagas/common/CommonSagas';
import doctorBoardSagas from 'sagas/doctorBoard/DoctorBoardSagas';
import accountSagas from 'sagas/user/AccountSagas';
import { AnyIterator } from 'core/utils/Typed';
import notificationsSagas from 'sagas/user/NotificationsSagas';
import biologistDashboardSagas from 'sagas/biologistDashboard/BiologistDashboardSagas';
import modularSagas from 'sagas/modular/MainModularSaga';
import demoModularSagas from 'sagas/modular/Demo';
import ReportsSagas from 'sagas/modular/ReportsSagas';

export default function* rootSaga(): AnyIterator {
    yield fork(medicalReportsSaga);
    yield fork(signInSignUpSagas);
    yield fork(commonSagas);
    yield fork(articleSagas);
    yield fork(doctorBoardSagas);
    yield fork(accountSagas);
    yield fork(notificationsSagas);
    yield fork(biologistDashboardSagas);
    yield fork(modularSagas);
    yield fork(demoModularSagas);
    yield fork(ReportsSagas);
}
