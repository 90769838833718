import { OnSuccessPutType } from "actions/ActionInterface";
import { reSendFirstMailReport, reSendSharedReport } from "actions/medicalReports/MedicalReportsFetchActions";
import EmptyState, { ActionPayload, EmptyStateType } from "components/designSystem/components/EmptyState";
import Centered from "components/designSystem/containers/Centered";
import { MediumSpacer } from "components/designSystem/containers/Spacer";
import APIFeedbackMessageContainer from "components/pages/common/APIFeedbackMessage";
import { TransDomain } from "components/pages/common/MainComponent";
import { getApolloResource } from "core/content/cdn";
import { capitalizeFirstLetter } from "core/utils/text";
import { translationMethod } from "core/utils/trans";
import { APIFeedbackMessage } from "models/common/APIMessaging";
import { OutdatedReportType } from "models/medicalReport/ConnectionModesModel";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { REDUCER_API_MESSAGING } from "reducers/allReducers";
import { reducer } from "reducers/selector";
import _ from 'underscore';

export const FEEDBACK_IDENTIFIER_API_RESEND_SHARED_REPORT = 'FEEDBACK_IDENTIFIER_API_RESEND_SHARED_REPORT'
export const FEEDBACK_IDENTIFIER_API_RESEND_FIRST_MAIL_REPORT = 'FEEDBACK_IDENTIFIER_API_RESEND_FIRST_MAIL_REPORT'

interface LocationState {
    previousOnSuccessPut?: OnSuccessPutType
    token?: string
    type?: OutdatedReportType
}

interface reducerResponse {
    messages: APIFeedbackMessage[]
}

type ActionPayloadWithFeedback = ActionPayload & {feedbackIdentifier?: string};

const OutdatedReport = (): JSX.Element => {
    const {state} = useLocation<LocationState>();
    const dispatch = useDispatch();
    const [shouldDisableSubmitButton, setShouldDisableSubmitButton] = useState(false);

    const domain = TransDomain.OUTDATED_REPORT
    const calendar = getApolloResource('calendar.svg')

    const token = state?.token
    const type = state?.type ?? OutdatedReportType.PATIENT_REPORT

    const feedbackIdentifier: string = type === OutdatedReportType.SHARED_REPORT ?
        FEEDBACK_IDENTIFIER_API_RESEND_SHARED_REPORT : FEEDBACK_IDENTIFIER_API_RESEND_FIRST_MAIL_REPORT
    const actionPayload: ActionPayloadWithFeedback | undefined = token ? {
        name: translationMethod(type + '.action', undefined, domain, capitalizeFirstLetter),
        onClick: (): void => {
            setShouldDisableSubmitButton(true)
            if (type === OutdatedReportType.SHARED_REPORT){
                dispatch(reSendSharedReport(token, feedbackIdentifier))
            } else {
                dispatch(reSendFirstMailReport(token, feedbackIdentifier))
            }
        },
        feedbackIdentifier: feedbackIdentifier,
        disabled: shouldDisableSubmitButton
    } : undefined

    const detailKey = type + (type === OutdatedReportType.PATIENT_REPORT && token ? '.detail_with_token' : '.detail')

    // Get messages to remove button if feedback message
    const messages = actionPayload ? useSelector(
        (state: reducerResponse) => reducer(state, REDUCER_API_MESSAGING).messages.filter(
            (message: APIFeedbackMessage) => message.identifier === actionPayload.feedbackIdentifier
        )
    ) : []

    return <Centered horizontally vertically>
        <MediumSpacer>
            <EmptyState
                title={translationMethod('expired_message', undefined, domain, capitalizeFirstLetter)}
                description={translationMethod(detailKey, undefined, domain, capitalizeFirstLetter)}
                payload={{
                    type: EmptyStateType.ILLUSTRATION,
                    src: calendar.src,
                    alt: calendar.alt
                }}
                /* Button is not displayed if already sent and response received*/
                actionPayload={_.isEmpty(messages) ? actionPayload : undefined}
            />
            {
                actionPayload && <APIFeedbackMessageContainer
                    identifier={actionPayload.feedbackIdentifier}
                    withNewDesignSystem
                />
            }
        </MediumSpacer>
    </Centered>
}

export default OutdatedReport