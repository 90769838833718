import React from 'react';

const Blood = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M24.523 10.1109C29.1639 14.7034 29.1639 22.1696 24.523 26.7576C22.2754 28.9835 19.287 30.2085 16.1048 30.2085C12.927 30.2085 9.93892 28.9832 7.69097 26.7576C5.43893 24.5362 4.20459 21.5789 4.20459 18.4321C4.20459 15.2897 5.43846 12.3323 7.69097 10.1066L15.479 2.24797C15.8097 1.91734 16.4 1.91734 16.7307 2.24797L24.523 10.1109Z"/>
        </svg>
    )
}

export default Blood;
