import { ActionDispatcher } from 'actions/ActionInterface';
import { redirect } from 'actions/common/CommonActions';
import { getMyPatients } from 'actions/doctorBoard/DoctorBoardFetchActions';
import { alterContext } from "actions/modular/ModularActions";
import DropDownItem, { DropDownType } from "components/designSystem/components/DropDownItem";
import SearchBar from "components/designSystem/components/SearchBar";
import { Patient, PatientList } from 'components/modules/doctorBoard/Types';
import MainComponent, { key, MainComponentProps, setup, TransDomain } from 'components/pages/common/MainComponent';
import 'components/pages/common/PatientSearchBar.scss';
import { formatName } from "components/utils/names";
import { getPersonIdFromLegacy, getRoute } from 'core/routing/Helper';
import { ROUTE_MODULAR_PAGE } from 'core/routing/Routes';
import { AnyState } from 'core/store/Store';
import { normalizeStr } from 'core/utils/Name';
import React, { ReactNode } from 'react';
import { REDUCER_DOCTOR } from 'reducers/allReducers';
import { fetchReducer } from 'reducers/selector';
import _ from 'underscore';


export interface ScoredPatient {
    p: Patient;
    score: number;
}

export interface PatientSearchBarState {
    currentInput: string;
    filteredPatients: Patient[];
}

export interface PatientSearchBarStateProps {
    patientList?: PatientList;
}

export interface SelectOptionInterface<T> {
    options: T[];
    onOptionClick: (option: T) => void;
}

export interface PatientSearchBarDispatchProps {
    displayPatientPage: (id: string) => void;
    alterContext: (key?: string, value?: string) => void,
    getMyPatients: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export class PatientSearchBar extends MainComponent<PatientSearchBarStateProps & PatientSearchBarDispatchProps & MainComponentProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    state: PatientSearchBarState = {
        currentInput: '',
        filteredPatients: [],
    };

    static filterPatients = (input: string, patients: Patient[]): Patient[] =>
        patients
            .map((p: Patient) => ({p: p, score: PatientSearchBar._computeScore(p, input)}))
            .filter((r) => r.score > 0)
            .sort(PatientSearchBar._sort)
            .map((r) => r.p);

    static _computeScore = (p: Patient, input_: string): number => {
        const firstLast = normalizeStr(`${p.first_name} ${p.last_name}`);
        const lastFirst = normalizeStr(`${p.last_name} ${p.first_name}`);
        const input = normalizeStr(input_);

        return (
            2 * Number(lastFirst.slice(0, input.length) === input) + Number(firstLast.slice(0, input.length) === input)
        );
    };

    static _sort = (sp1: ScoredPatient, sp2: ScoredPatient): number => {
        const diffScore: number = PatientSearchBar._sortByScore(sp1, sp2);
        if (diffScore !== 0) return diffScore;
        const diffLastName = PatientSearchBar._sortByLastName(sp1.p, sp2.p);
        if (diffLastName !== 0) return diffLastName;
        return PatientSearchBar._sortByFirstName(sp1.p, sp2.p);
    };

    static _sortByScore = (sp1: ScoredPatient, sp2: ScoredPatient): number => {
        return sp2.score - sp1.score;
    };

    static _sortByLastName = (p1: Patient, p2: Patient): number => {
        if (p1.last_name < p2.last_name) return -1;
        if (p1.last_name > p2.last_name) return 1;
        return 0;
    };

    static _sortByFirstName = (p1: Patient, p2: Patient): number => {
        if (p1.first_name < p2.first_name) return -1;
        if (p1.first_name > p2.first_name) return 1;
        return 0;
    };

    componentDidMount(): void {

        this.props.getMyPatients();
    }

    componentDidUpdate(prevProps: PatientSearchBarStateProps, prevState: PatientSearchBarState): void {
        const {currentInput, filteredPatients} = this.state;
        const {patientList} = this.props;

        if (prevState.currentInput !== currentInput) {
            if (patientList === undefined || normalizeStr(currentInput) === '') {
                this.setState({filteredPatients: []});
            } else {
                const preFilteredList =
                    !_.isEmpty(filteredPatients) && !_.isNull(currentInput.match(`^${prevState.currentInput}`))
                        ? filteredPatients
                        : patientList.patients;
                const newFilteredPatients = PatientSearchBar.filterPatients(currentInput, preFilteredList);
                this.setState({filteredPatients: newFilteredPatients});
            }
        }
    }

    onFocus = (): void => {
        this.props.getMyPatients();
        this.props.onFocus && this.props.onFocus();
    };

    onBlur = (): void => {
        this.setState({currentInput: ''})
        this.props.onBlur && this.props.onBlur();
    };

    onOptionClick = (p: Patient): void => {
        const patientId = getPersonIdFromLegacy(p.id);
        this.props.displayPatientPage(patientId);
        this.props.alterContext('patient_uuid', patientId) // To ensure navigation works when already on patient page
    };

    buildOptionMap = (optionInterface: SelectOptionInterface<Patient>): ReactNode => {
        if (optionInterface && optionInterface.options.length > 0) {
            return optionInterface.options.map(
                (p, i) => <DropDownItem key={i} label={formatName(p.first_name, p.last_name)} hasDivider payload={{
                    type: DropDownType.LIST,
                    onClick: (): void => this.onOptionClick(p),
                    description: this.trans(key('born_simple', 'user')) && p.birth_date ? this.trans(key('born_simple', 'user')) + p.birth_date : undefined,
                    isChecked: false
                }}/>)
        }
    }

    handleKeyDown = (optionInterface: SelectOptionInterface<Patient>, e: React.KeyboardEvent<HTMLInputElement>, cursor: number):number => {
        let tempCursor = cursor

            if (optionInterface) {
                if (e.keyCode === 38) {
                    e.preventDefault();
                    if (cursor > 0) {
                        tempCursor = cursor - 1 ;
                    } else {
                        tempCursor = optionInterface.options.length - 1 ;
                    }
                } else if (e.keyCode === 40) {
                    e.preventDefault();
                    if (cursor < optionInterface.options.length - 1) {
                        tempCursor = cursor + 1;
                    } else {
                        tempCursor = 0;
                    }
                } else if (e.keyCode === 13) {
                    e.preventDefault();
                    if (cursor >= 0) {
                        optionInterface.onOptionClick(optionInterface.options[cursor]);
                        this.onBlur();
                    }
                }
            }
            return tempCursor
    }

    render(): ReactNode {

        const selectOptionMode: SelectOptionInterface<Patient> = {
            options: this.state.filteredPatients,
            onOptionClick: this.onOptionClick,
        }
        return (
            <SearchBar
                label={this.trans('search_bar_default_text')}
                disabled={this.props.patientList === undefined}
                buildOptionModeMap={(): ReactNode => this.buildOptionMap(selectOptionMode)}
                handleKeyDown={( e: React.KeyboardEvent<HTMLInputElement>, cursor: number):number => this.handleKeyDown(selectOptionMode, e, cursor )}
                defaultText={this.trans('search_bar_default_text')}
                descriptionPrefix={this.trans(key('born_simple', 'user'))}
                onInputChange={(newValue: string): void =>
                    this.setState({
                        currentInput: newValue,
                    })
                }
                selectOptionMode={!!selectOptionMode}
                onFocus={this.onFocus}
            />
        );
    };

}

const mapStateToProps = (state: AnyState): PatientSearchBarStateProps => ({
    patientList: fetchReducer(state, REDUCER_DOCTOR).patients,
});

const mapDispatchToProps = (dispatch: ActionDispatcher): PatientSearchBarDispatchProps => ({
    getMyPatients: (): void => dispatch(getMyPatients()),
    displayPatientPage: (patientId: string): void => dispatch(redirect(getRoute(ROUTE_MODULAR_PAGE, {pageId: 'patient_profile', patient_uuid: patientId}))),
    alterContext: (key?: string, value?: string): void => key === undefined ? undefined : dispatch(alterContext(key, value)),
});

export default setup(PatientSearchBar, mapStateToProps, mapDispatchToProps);
