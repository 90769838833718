import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const EyeOff = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-eye-off'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_147_1175)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M15.0303 2.03033C15.3232 1.73744 15.3232 1.26256 15.0303 0.96967C14.7374 0.676777 14.2626 0.676777 13.9697 0.96967L11.5649 3.37446C10.5437 2.7307 9.33574 2.25 7.99982 2.25C6.13922 2.25 4.52497 3.18144 3.30721 4.20093C2.08098 5.22751 1.17415 6.41198 0.713826 7.07218C0.322361 7.63363 0.323956 8.36874 0.714414 8.92866C1.16111 9.56922 2.03427 10.7123 3.21678 11.7226L0.96967 13.9697C0.676777 14.2626 0.676777 14.7374 0.96967 15.0303C1.26256 15.3232 1.73744 15.3232 2.03033 15.0303L15.0303 2.03033ZM4.28115 10.6582L5.23324 9.7061C4.92687 9.21006 4.74982 8.62502 4.74982 8C4.74982 6.20507 6.20489 4.75 7.99982 4.75C8.62519 4.75 9.21003 4.92715 9.7059 5.23344L10.4717 4.46762C9.69543 4.0271 8.86276 3.75 7.99982 3.75C6.64265 3.75 5.3627 4.43637 4.2701 5.35108C3.18598 6.25869 2.36631 7.32479 1.94427 7.9301C1.92683 7.9551 1.92088 7.97896 1.9209 7.99982C1.92091 8.02073 1.92695 8.04507 1.94479 8.07066C2.37096 8.68178 3.1948 9.7514 4.28115 10.6582ZM8.58818 6.35116C8.40441 6.28561 8.20643 6.25 7.99982 6.25C7.03332 6.25 6.24982 7.0335 6.24982 8C6.24982 8.20677 6.28544 8.40471 6.35097 8.58837L8.58818 6.35116ZM13.5301 4.96967C13.2373 4.67678 12.7624 4.67678 12.4695 4.96967C12.1766 5.26256 12.1766 5.73744 12.4695 6.03033C13.1894 6.75024 13.7369 7.47339 14.0546 7.92895C14.0724 7.95456 14.0786 7.97911 14.0786 8.00028C14.0786 8.02136 14.0726 8.04517 14.0554 8.0699C13.6333 8.67521 12.8137 9.74131 11.7295 10.6489C10.6369 11.5636 9.35698 12.25 7.99982 12.25C7.40993 12.25 6.83487 12.121 6.28004 11.8975C5.89583 11.7427 5.4589 11.9287 5.30413 12.3129C5.14937 12.6972 5.33537 13.1341 5.71959 13.2889C6.42232 13.5719 7.18777 13.75 7.99982 13.75C9.86041 13.75 11.4747 12.8186 12.6924 11.7991C13.9186 10.7725 14.8255 9.58802 15.2858 8.92782C15.6775 8.36597 15.6751 7.63043 15.2849 7.0709C14.9345 6.56845 14.3312 5.77068 13.5301 4.96967Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_147_1175">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-eye-off'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22.2071 3.20711C22.5976 2.81658 22.5976 2.18342 22.2071 1.79289C21.8166 1.40237 21.1834 1.40237 20.7929 1.79289L17.7785 4.80734C16.2037 3.75946 14.2735 3 12 3C8.6128 3 5.99907 4.6813 4.17022 6.49197C2.34386 8.30018 1.22435 10.3083 0.795016 11.1584C0.525121 11.6929 0.527909 12.3128 0.795912 12.8433C1.25634 13.7547 2.52802 16.0335 4.63585 17.9499L1.79289 20.7929C1.40237 21.1834 1.40237 21.8166 1.79289 22.2071C2.18342 22.5976 2.81658 22.5976 3.20711 22.2071L22.2071 3.20711ZM6.05187 16.5339L8.00907 14.5767C7.52895 13.8343 7.24996 12.9486 7.24996 12C7.24996 9.37665 9.37661 7.25 12 7.25C12.9485 7.25 13.8342 7.52899 14.5767 8.00911L16.3303 6.25552C15.0901 5.49998 13.6497 5 12 5C9.32299 5 7.18595 6.32062 5.57735 7.91323C4.00753 9.46745 3.01353 11.2129 2.61079 12C3.05728 12.8725 4.21239 14.8871 6.05187 16.5339ZM13.105 9.48075C12.7672 9.3323 12.3938 9.25 12 9.25C10.4812 9.25 9.24996 10.4812 9.24996 12C9.24996 12.3938 9.33226 12.7673 9.48071 13.1051L13.105 9.48075ZM20.855 7.60682C20.4998 7.18393 19.869 7.12907 19.4461 7.48429C19.0232 7.83951 18.9683 8.47029 19.3236 8.89318C20.3813 10.1524 21.0719 11.3801 21.3891 12C20.9864 12.7871 19.9924 14.5325 18.4226 16.0868C16.814 17.6794 14.6769 19 12 19C11.0365 19 10.1491 18.8299 9.33361 18.5413C8.81297 18.357 8.24153 18.6297 8.05726 19.1503C7.87299 19.6709 8.14567 20.2424 8.66631 20.4266C9.68298 20.7865 10.7956 21 12 21C15.3871 21 18.0008 19.3187 19.8297 17.508C21.6561 15.6998 22.7756 13.6917 23.2049 12.8416C23.4759 12.305 23.4701 11.6832 23.203 11.1548C22.8575 10.471 22.0772 9.06182 20.855 7.60682Z"
                  fill="currentColor"/>
        </svg>
    )
}