import React, { PropsWithChildren } from "react";
import Column, { ColumnWidthInREM } from "./containers/Column";
import Centered from "components/designSystem/containers/Centered";

type CenteredColumnProps = PropsWithChildren<{
    widthInRem: ColumnWidthInREM;
}>

export const CenteredColumn = (props: CenteredColumnProps): JSX.Element => <Centered>
    <Column widthInRem={props.widthInRem}>
        {props.children}
    </Column>
</Centered>

export enum ColorMap {
    /**
     * ColorMap is a simplified list of color that can used by te API to describe colors
     */
    WHITE = "WHITE",
    BLUE = "BLUE",
    YELLOW = "YELLOW",
    ORANGE = "ORANGE",
    DARK_ORANGE = "DARK_ORANGE",
    PURPLE = "PURPLE",
    GREEN = "GREEN",
    RED = "RED",
    BLACK = "BLACK",
    GREY = 'GREY',

    // Gradients
    GRADIENT_DARK_BLUE = 'GRADIENT_DARK_BLUE',
    GRADIENT_ORANGE = "GRADIENT_ORANGE",

    // Severities
    SEVERITY_VERY_LOW = 'SEVERITY_VERY_LOW',
    SEVERITY_LOW = 'SEVERITY_LOW',
    SEVERITY_MODERATE_LOW = 'SEVERITY_MODERATE_LOW',
    SEVERITY_MODERATE = 'SEVERITY_MODERATE',
    SEVERITY_MODERATE_HIGH = 'SEVERITY_MODERATE_HIGH',
    SEVERITY_HIGH = 'SEVERITY_HIGH',
    SEVERITY_VERY_HIGH = 'SEVERITY_VERY_HIGH',
}
