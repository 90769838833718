import { setPatientBioProfiles } from "actions/bioProfile/BioProfileFetchActions";
import { FEEDBACK_IDENTIFIER_UPDATE_BIO_PROFILE } from "actions/user/UserActions";
import DropDown from "components/designSystem/components/DropDown";
import DropDownItem, { DropDownType } from "components/designSystem/components/DropDownItem";
import { TypographyColor } from "components/designSystem/containers/Typography";
import { ChevronDown } from "components/designSystem/foundations/icons/ChevronDown";
import { IconSize } from "components/designSystem/foundations/IconsData";
import ListItemWithDescription from "components/designSystem/lists/ListItemWithDescription";
import { BioProfile } from "components/modules/modular/modules/bioProfiles/BioProfileSelectionModule";
import { getIconByName } from "core/content/icons/IconFinder";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";


export interface AccountInfoBioProfileSetting {
    patient_uuid: string
    profiles: BioProfile[]
    selected_profile_codes: string[]
}

export const BioProfileDropdown = ({profileSetting}: { profileSetting: AccountInfoBioProfileSetting }): JSX.Element => {
    const [selectedProfileCodes, setSelectedProfileCodes] = useState(profileSetting.selected_profile_codes)
    const userProfiles = useRef(selectedProfileCodes);
    const dispatch = useDispatch();
    useEffect(() => {
        if (userProfiles.current != selectedProfileCodes) {
            userProfiles.current = selectedProfileCodes
            dispatch(setPatientBioProfiles(profileSetting.patient_uuid, selectedProfileCodes, FEEDBACK_IDENTIFIER_UPDATE_BIO_PROFILE))
        }
    }, [selectedProfileCodes])
    const {t} = useTranslation();

    return <DropDown
        closeOnClick
        fullWidth
        title={t('account.profiles.drop_down_title')}
        triggerBuilder={(onClick: () => void): ReactElement => <ListItemWithDescription
            onClick={onClick}
            title={t('account.profiles.drop_down_title')}
            description={t('account.profiles.drop_down_description')}
            descriptionColor={TypographyColor.COLOR_TEXT_SUBDUED}
            action={<ChevronDown size={IconSize.MEDIUM}/>}
        />}
    >
        {profileSetting.profiles.map((profile) => (
            <DropDownItem
                key={profile.code}
                label={profile.name}
                payload={{
                    type: DropDownType.LIST,
                    onClick: (): void => {
                        if (selectedProfileCodes.includes(profile.code)) {
                            setSelectedProfileCodes([]);
                        } else {
                            setSelectedProfileCodes([profile.code]);
                        }
                    },
                    icon: getIconByName(profile.icon),
                    description: profile.description,
                    isChecked: selectedProfileCodes.includes(profile.code)
                }}
            />
        ))}
    </DropDown>;
};