import { ColorMap } from "components/designSystem/Aliases";
import { APIText } from "models/common/message";
import { NumericalValue } from "models/medicalReport/ReportModels";
import { ModuleMenu } from "models/modular/menu";

export enum TableDistributionDataType {
    NUMERICAL = 'NUMERICAL',
    TEXT = 'TEXT',
}

export interface SimpleTableDistributionDataValueNumerical {
    type: TableDistributionDataType.NUMERICAL;
    value: NumericalValue;
}

export interface SimpleTableDistributionDataValueText {
    type: TableDistributionDataType.TEXT;
    value: APIText;
}

export interface SimpleTableDistributionDataVariant {
    [data_variant: string]: SimpleTableDistributionDataValueNumerical | SimpleTableDistributionDataValueText;
}

export interface SimpleTableDistributionDataByIdentifierColumn {
    [column_identifier: string]: {
        color: ColorMap | null;
        values_by_variant: SimpleTableDistributionDataVariant;
    };
}

export interface SimpleTableDistributionDataByIdentifier {
    [line_identifier: string]: SimpleTableDistributionDataByIdentifierColumn;
}

export interface SimpleTableDistributionValueSwitchItem {
    identifier: string;
    name: APIText;
}

export interface SimpleTableDistributionValueSwitch {
    [value_switch: string]: SimpleTableDistributionValueSwitchItem;
}

export interface SimpleTableDistributionColumn {
    identifier: string;
    name: APIText;
    descriptions?: APIText[];
}

export interface SimpleTableDistributionLegendItem {
    color: ColorMap;
    description: APIText;
}

export interface SimpleTableDistributionLine {
    identifier: string;
    name: APIText;
    description?: APIText;
}

export interface SimpleTableDistributionModulePagePayload {
    title: APIText;
    lines: SimpleTableDistributionLine[];
    data_by_identifier: SimpleTableDistributionDataByIdentifier;
    values_switch?: SimpleTableDistributionValueSwitch;
    hide_column_names: boolean;
    hide_legend: boolean;
    columns: SimpleTableDistributionColumn[];
    legend?: SimpleTableDistributionLegendItem[];
    menu: ModuleMenu | null;
}