import { put, takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { API_RATING_RATE, RateAction, REDIRECT, RedirectAction } from 'actions/common/CommonActions';
import { getRoute } from 'core/routing/Helper';
import { AnyIterator } from 'core/utils/Typed';
import CallHandler from 'sagas/api/CallHandler';
import { connectedButtonPureAPICall, rate } from 'sagas/api/Endpoints';
import { pushAPIFeedbackMessage } from 'actions/common/APIMessaginActions';
import { TransDomain, TransKey } from 'components/pages/common/MainComponent';
import { APIFeedbackMessageFallbackCatcher, APIFeedbackMessageType } from 'models/common/APIMessaging';
import { CONNECTED_BUTTON_PURE_API_CALL, PureApiCallPayload } from 'actions/common/ConnectedButton';
import { APICallAction } from 'actions/ActionInterface';

export const redirectSaga = function* (action: RedirectAction): AnyIterator {
    yield put(
        push(
            getRoute(
                action.payload.path, action.payload.params),
                Object.assign({}, action.payload.state, {previousOnSuccessPut: action.payload.previousOnSuccessPut}
            )
        ),
    );
};

export const rateSaga = function* (action: RateAction): AnyIterator {
    const response: Record<string, never> = yield CallHandler.safelyCall(rate, action);
    if (response) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier || null,
                new TransKey('SUCCESS', TransDomain.MESSAGES),
                APIFeedbackMessageType.SUCCESS,
                undefined,
                action
            ),
        );
    }
}

export const pureAPICall = function* (action: APICallAction<PureApiCallPayload<{}>>): AnyIterator {
    yield CallHandler
        .for(connectedButtonPureAPICall, action)
        .defaultFallbackFeedbackCatcher(APIFeedbackMessageFallbackCatcher.MAIN_HEADER)
        .call();
};


export default function* commonSagas(): AnyIterator {
    yield takeLatest(REDIRECT, redirectSaga);
    yield takeLatest(API_RATING_RATE, rateSaga);
    yield takeLatest(CONNECTED_BUTTON_PURE_API_CALL, pureAPICall);
}
