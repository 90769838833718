import { ModuleV1Identification, PageV1ContextDependenciesRuleMode, RemovedItem } from 'models/modular/api';

export type OwnPayloadConstraint = Record<string, unknown>;
export type OwnPayloadDefault = {[key: string]: unknown};

export interface PageContextItem {
    value: string | null,
    contextKey: string
}

export type ConnectedContext = {[key: string]: PageContextItem}
export interface ModuleData<P = {}, O extends OwnPayloadConstraint = OwnPayloadDefault> {
    identification: ModuleV1Identification,
    pagePayload: P,
    ownPayload: O,
    contextMapping: {[key: string]: string},
    loading?: boolean, // to remove : use to force loading mode
    gone?: boolean,
    twinkle?: boolean
}

export enum ComputedContextDependencyType {
    MODULE = "M",
    PAGE = "P",
    PARTIAL = "R",
}

export interface ComputedContextDependency {
    identification: string,
    type: ComputedContextDependencyType,
    mode: PageV1ContextDependenciesRuleMode,
    removed_item: RemovedItem | null
}
