import React, { PropsWithChildren } from 'react';
import WithTranslations from 'components/pages/common/WithTranslations';
import { capitalizeFirstLetter } from 'core/utils/text';
import { TransDomain } from 'components/pages/common/MainComponent';
import { LargeSpacer, SmallSpacer } from 'components/designSystem/containers/Spacer';
import { TDisplaySmall700, TypographyColor } from 'components/designSystem/containers/Typography';

export type ReportParentCategoryInterface = PropsWithChildren<{
    name: string;
    isMobile: boolean;
}>

export default class ReportParentCategory extends WithTranslations<ReportParentCategoryInterface> {
    TRANS_SUFFIX = TransDomain.MODULES + '.report';

    render(): JSX.Element {
        return (
            <SmallSpacer>
                <div>
                    <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    {this.props.name ? capitalizeFirstLetter(this.props.name) : this.trans('others')}
                    </TDisplaySmall700>
                </div>
                {
                    this.props.isMobile ?
                        <LargeSpacer>
                            {this.props.children}
                        </LargeSpacer>
                        :
                        <SmallSpacer>
                            {this.props.children}
                        </SmallSpacer>

                }

            </SmallSpacer>
        )
    }
}