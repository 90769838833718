import { modal, ModalType } from "actions/common/CommonActions";
import TrText from "components/common/TrText";
import { TABLET_MAX_WIDTH } from "components/core/constants";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import Pill, { PillSeverity } from "components/designSystem/components/Pill";
import WithTooltip, { TooltipWidth } from "components/designSystem/components/WithTooltip";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import FlexGrow from "components/designSystem/containers/FlexGrow";
import Inline, {
    AlwaysInline,
    Grow,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import { SmallSpacer } from "components/designSystem/containers/Spacer";
import {
    TCaption,
    TLabel,
    TLabel500,
    TLabel700,
    TOverline,
    TypographyColor
} from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";
import WithPadding, { PaddingSize } from "components/designSystem/containers/WithPadding";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import Prescription from "components/modules/modular/modules/common/Prescription";
import { TransDomain } from "components/pages/common/MainComponent";
import { UserData } from "models/medicalReport/LegacyReportModels";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { getUserData } from "reducers/Shortcuts";
import _ from "underscore";

enum ReportRecommendationType {
    ML = 'ML',
    HAS_RULES = 'HAS_RULES',
}

interface RecommendedLabOrder {
    name: string
    description: string | null
}

interface ReportRecommendations {
    type: ReportRecommendationType
    recommended_laborders: RecommendedLabOrder[]
    is_user_activated: boolean
}

interface ReportRecommendationsModulePayload {
    recommendations: ReportRecommendations[]
    patient: UserData
}

/**
 * Maximum number of suggestion types displayed. A popup is available to display the full list.
 */
const MAX_RECOMMENDATION_TYPES = 2;

/**
 * Maximum number of suggestions displayed per suggestion type. A popup is available to display the full list.
 */
const MAX_RECOMMENDATIONS = 5;

const TRANS_SUFFIX = TransDomain.MODULES + '.recommendation';

const RecommendationsList = ({recommendationList}: {recommendationList: ReportRecommendations[]}): JSX.Element => <>
    {recommendationList.map((recommendations: ReportRecommendations, i: number): JSX.Element => {
        return <Grow key={i}>
            <WithPadding key={i} paddings={[PaddingSize.X_SMALL]}>
                <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                    <Pill severity={PillSeverity.INFO}/>
                    <TLabel500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                        <TrText input={{trkey: "type." + recommendations.type, trdomain: TRANS_SUFFIX}}/>
                    </TLabel500>
                </AlwaysInline>
            </WithPadding>
            {recommendations.recommended_laborders.map((recommendedLabOrder: RecommendedLabOrder, i: number): JSX.Element => {
                return <WithPadding key={i} paddings={[PaddingSize.X_SMALL]}>
                    <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                        <TLabel>•</TLabel>
                        <TCaption>{recommendedLabOrder.name}</TCaption>
                    </AlwaysInline>
                </WithPadding>
            })}
        </Grow>
    })}
</>;

const Recommendations = ({recommendationList}: {recommendationList: ReportRecommendations[]}): JSX.Element => <>
    <MediaQuery minWidth={TABLET_MAX_WIDTH}>
        <AlwaysInline justifyContent={InlineJustifyContent.FLEX_START}>
            <RecommendationsList recommendationList={recommendationList}/>
        </AlwaysInline>
    </MediaQuery>
    <MediaQuery maxWidth={TABLET_MAX_WIDTH - 1}>
        <RecommendationsList recommendationList={recommendationList}/>
    </MediaQuery>
</>;

const RecommendationsPlaceholder = (): JSX.Element => <WithMargin
    margins={[MarginSize.SMALL, MarginSize.MEDIUM]}>
    <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
        <Pill severity={PillSeverity.INFO}/>
        <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>
            <TrText input={{trkey: "none", trdomain: TRANS_SUFFIX}}/>
        </TCaption>
    </AlwaysInline>
</WithMargin>;

/**
 * Recommendations module displaying to medical practitioners an overview of the data which was out of the normal ranges as
 * well as the non-numeric data (for which Kiro isn't able to give a status).
 */
export const ReportRecommendationsModule = (): JSX.Element => {
    const {recommendations, patient} = useModulePayload<ReportRecommendationsModulePayload>();
    const userData = useSelector((state) => getUserData(state));
    const dispatch = useDispatch();
    const truncatedRecommendations = recommendations.slice(0, MAX_RECOMMENDATION_TYPES)
        .map((recommendations: ReportRecommendations): ReportRecommendations => {
            return {
                ...recommendations,
                recommended_laborders: recommendations.recommended_laborders.slice(0, MAX_RECOMMENDATIONS)
            };
        });
    const recommendationCount = recommendations.reduce((count: number, recommendations: ReportRecommendations): number => count + recommendations.recommended_laborders.length, 0);
    return <Box background={BoxColor.WHITE} withPadding withBoxShadow withFullHeight withOverflow>
        <SmallSpacer withFullHeight>
            <AlwaysInline
                justifyContent={InlineJustifyContent.SPACE_BETWEEN}
                alignItems={InlineAlignItem.CENTER}
                spacing={InlineSpacing.XXX_LARGE}
            >
                <Inline
                    spacing={InlineSpacing.SMALL}
                    alignItems={InlineAlignItem.CENTER}
                    justifyContent={InlineJustifyContent.FLEX_START}
                    preventMobileSwitch
                >
                    <TLabel700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                        <TrText input={{trkey: "title", trdomain: TRANS_SUFFIX}}/>
                    </TLabel700>
                    <WithTooltip
                        width={TooltipWidth.WIDTH_2OREM}
                        tooltip={<TrText input={{trkey: "explanation", trdomain: TRANS_SUFFIX}}/>}
                    >
                        <Icon size={IconSize.EXTRA_SMALL} name={IconName.QUESTION_CIRCLE}/>
                    </WithTooltip>
                </Inline>
                <Button
                    variant={ButtonVariant.TERTIARY}
                    size={ButtonSize.MEDIUM}
                    disabled={recommendationCount == 0}
                    onClick={(): void => {
                        dispatch(modal(
                            ModalType.CENTER,
                            (): JSX.Element => <Recommendations recommendationList={recommendations}/>,
                            {title: {default: <TrText input={{trkey: "title", trdomain: TRANS_SUFFIX}}/>}}
                        ))
                    }}>
                    <TrText input={{trkey: "see_all", trdata: {'count': recommendationCount}, trdomain: TRANS_SUFFIX}}/>
                </Button>
            </AlwaysInline>
            <FlexGrow>
                {!_.isEmpty(truncatedRecommendations) ? <Recommendations recommendationList={truncatedRecommendations}/> : <RecommendationsPlaceholder/>}
            </FlexGrow>
            <AlwaysInline justifyContent={InlineJustifyContent.SPACE_BETWEEN} alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                <TOverline color={TypographyColor.COLOR_TEXT_SUBDUED}>
                    <TrText input={{trkey: "subtitle", trdomain: TRANS_SUFFIX}}/>
                </TOverline>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.LARGE}
                    onClick={(): void => {
                        dispatch(modal(
                            ModalType.CENTER,
                            (): JSX.Element => (
                                <Prescription
                                    recommendations={recommendations}
                                    patient={patient}
                                    doctor={userData}
                                />
                            ),
                            {
                                title: {
                                    default: <TrText input={{
                                        text: {
                                            trkey: "prescript",
                                            trdomain: "medical_report.prescription"
                                        }
                                    }}/>
                                },
                                large: true
                            }
                        ))
                    }}>
                    <TrText input={{text: {trkey: "prescript", trdomain: "medical_report.prescription"}}}/>
                </Button>
            </AlwaysInline>
        </SmallSpacer>
    </Box>
}
