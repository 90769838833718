import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import React from "react";

export const FillThumbsDown = (props: IconProps): JSX.Element => {
    switch (props.size){
        case IconSize.MEDIUM:
            return (
                <svg id={"m-fill-thumbs-down"} width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path id="icon" fillRule="evenodd" clipRule="evenodd"
                          d="M11.8099 30.1684L11.7562 28.7391C11.8099 30.1684 11.8099 30.1684 11.8099 30.1684L11.8136 30.1683L11.8186 30.1681L11.8323 30.1675L11.8743 30.1653C11.9087 30.1634 11.9555 30.1605 12.0137 30.1561C12.1298 30.1473 12.2916 30.1327 12.4885 30.1086C12.8803 30.0607 13.4217 29.9742 14.0245 29.8181C15.1809 29.5185 16.7897 28.9057 17.8852 27.598C19.2475 25.9719 19.4942 23.7299 19.4898 22.1549C19.4886 21.7038 19.4664 21.2794 19.4348 20.9009H26.4412C29.1207 20.9009 31.1449 18.4721 30.6619 15.8364L28.7399 5.34837C28.3349 3.13833 26.2936 1.61493 24.0597 1.85553L7.65327 3.62251H2.69742C1.90747 3.62251 1.26709 4.26289 1.26709 5.05284V17.4109C1.26709 18.2008 1.90747 18.8412 2.69742 18.8412H7.38544L10.3258 20.3455V28.7391C10.3258 29.1278 10.484 29.4997 10.764 29.7693C11.0439 30.039 11.4215 30.183 11.8099 30.1684ZM6.29973 15.9806H4.12775V6.48317H6.29973V15.9806Z"
                          fill="currentColor"
                    />
                </svg>
        )
        default:
            return <div/>
    }
}