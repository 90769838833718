import TrText from "components/common/TrText";
import Button, { ButtonVariant } from "components/designSystem/components/Button";
import ReferenceScale, { ReferenceScaleProps } from 'components/designSystem/components/ReferenceScale';
import Inline, { InlineSpacing } from "components/designSystem/containers/Inline";
import { MediumSpacer, XLargeSpacer } from "components/designSystem/containers/Spacer";
import { TBody, TypographyColor } from "components/designSystem/containers/Typography";
import ReportInterpretationList from "components/modules/modular/modules/report/content/ReportInterpretationList";
import { TransDomain } from 'components/pages/common/MainComponent';
import { APIText } from 'models/common/message';
import { ModuleReportResultInterpretation } from "models/modular/report";
import React from 'react';
import _ from 'underscore';


export interface ResultInformationLink {
    name?: string;
    url: string;
}


export interface ResultInformation {
    description: APIText;
    links: ResultInformationLink[];
}

export type Props = {
    information: ResultInformation,
    referenceScale?: ReferenceScaleProps,
    interpretations?: ModuleReportResultInterpretation
}

const DetailsModal = (props: Props): JSX.Element => {
    return (<>
        {props.information && (
            <MediumSpacer>
                <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                    <TrText input={props.information.description}/>
                </TBody>
            </MediumSpacer>
        )}
        <XLargeSpacer>
            {props.information && !_.isEmpty(props.information.links) && <Inline spacing={InlineSpacing.X_SMALL}>
                {props.information.links.map((l: ResultInformationLink, k: number) => (
                    <Button variant={ButtonVariant.INLINE_LINK}
                            key={k}
                            onClick={{
                                href: l.url, targetBlank: true
                            }}>
                        {l.name || <TrText input={{trkey: 'report.know_more', trdomain: TransDomain.MODULES}}
                                           capitalize/>}
                    </Button>))}
            </Inline>}
            {props.referenceScale && <ReferenceScale {...props.referenceScale} />}
            {props.interpretations && <ReportInterpretationList interpretations={props.interpretations}
                                                                global_sources={props.interpretations?.global_sources}/>}
        </XLargeSpacer>
    </>);
}

export default DetailsModal;