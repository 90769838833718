import { APIText } from "models/common/message";

export enum NotificationState {
    INIT = 'INIT',
    SENT = 'SENT',
    NOT_SENT = 'NOT_SENT',
    FAILED = 'FAILED',
    OPENED = 'OPENED',
}

export interface BiologistAdminNotificationEvent {
    state: NotificationState
    datetime: Date,
    message: APIText | null
}

export interface BiologistAdminNotification {
    notification_uuid: string,
    //communication_mean: Optional[AllRawCommunicationMeansType]
    last_state: NotificationState
    last_update: Date,
    events: BiologistAdminNotificationEvent[]
}
