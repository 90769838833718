import { BaseModuleComponentProps } from 'components/modules/modular/BaseModule';
import { CallToActionModule } from "components/modules/modular/components/CallToActionModule";
import ModuleContainer from 'components/modules/modular/ModuleContainer';
import AccountModule from "components/modules/modular/modules/account/AccountModule";
import BiologistAdminSearchModule from 'components/modules/modular/modules/biologists/BiologistAdminSearchModule';
import { ArticleListModule } from 'components/modules/modular/modules/bioProfiles/ArticleListModule';
import { ArticleModule } from 'components/modules/modular/modules/bioProfiles/ArticleModule';
import { BioCurveModule } from "components/modules/modular/modules/bioProfiles/BioCurveModule";
import { BioProfileSelectionModule } from "components/modules/modular/modules/bioProfiles/BioProfileSelectionModule";
import { KiroInfoModule } from "components/modules/modular/modules/bioProfiles/KiroInfoModule";
import { PregnancyDueDateModule } from "components/modules/modular/modules/bioProfiles/PregnancyDueDateModule";
import { PregnancyProgressModule } from "components/modules/modular/modules/bioProfiles/PregnancyProgressModule";
import { DisclaimerModule } from "components/modules/modular/modules/common/DisclaimerModule";
import { HIVBannerModule } from 'components/modules/modular/modules/common/HIVBannerModule';
import LinkCard from 'components/modules/modular/modules/common/LinkCard';
import LockedModule from "components/modules/modular/modules/common/LockedModule";
import { ModularBanner } from 'components/modules/modular/modules/common/ModularBanner';
import ModularHeader from 'components/modules/modular/modules/common/ModularHeader';
import { RatingModule } from 'components/modules/modular/modules/common/RatingModule';
import { TitleModule } from 'components/modules/modular/modules/common/TitleModule';
import { DocumentsModule } from "components/modules/modular/modules/documents/DocumentsModule";
import { RawFileModule } from 'components/modules/modular/modules/RawFileModule';
import { PaymentModule } from 'components/modules/modular/modules/report/PaymentModule';
import ReportContentModule from 'components/modules/modular/modules/report/ReportContentModule';
import { ReportHeaderModule } from 'components/modules/modular/modules/report/ReportHeaderModule';
import { ReportRecommendationsModule } from 'components/modules/modular/modules/report/ReportRecommendationsModule';
import { ReportSumUpModule } from 'components/modules/modular/modules/report/ReportSumUpModule';
import ReportValidationModule from 'components/modules/modular/modules/report/ReportValidationModule';
import SubscriptionModule from 'components/modules/modular/modules/report/SubscriptionModule';
import DoctorReportsModule from 'components/modules/modular/modules/reportList/DoctorReportsModule';
import LastReportsModule from 'components/modules/modular/modules/reportList/LastReportsModule';
import PreviousReportsModule from 'components/modules/modular/modules/reportList/PreviousReportsModule';
import ReportsByPatientModule from "components/modules/modular/modules/reportList/ReportsByPatientModule";
import CohortsComparatorModule from 'components/modules/modular/modules/rwe/CohortsComparator';
import DataTableModule from 'components/modules/modular/modules/rwe/DataTableModule';
import SimpleChart from 'components/modules/modular/modules/rwe/SimpleChart';
import SimpleChartWithSwitchModule from 'components/modules/modular/modules/rwe/SimpleChartWithSwitch';
import { SimpleCounterModule } from 'components/modules/modular/modules/rwe/SimpleCounter';
import SimpleHalfPie from 'components/modules/modular/modules/rwe/SimpleHalfPie';
import SimpleMap from 'components/modules/modular/modules/rwe/SimpleMap';
import SimpleTableDistributionModule from 'components/modules/modular/modules/rwe/SimpleTableDistribution';
import TeleconsultationModule from 'components/modules/modular/modules/teleconsultation/TeleconsultationModule';
import { ModuleDisplayData, ModuleV1Type, PageContext } from 'models/modular/api';
import React, { ReactNode } from 'react';
import BiologistAdminSearchResultModule from './modules/biologists/BiologistAdminSearchResultModule';

const getFunctionalModule = (type: ModuleV1Type): (() => JSX.Element) | undefined => {
    switch (type) {
        case ModuleV1Type.ARTICLE:
            return ArticleModule;
        case ModuleV1Type.ARTICLE_LIST:
            return ArticleListModule;
        case ModuleV1Type.BIO_CURVE:
            return BioCurveModule;
        case ModuleV1Type.BIO_PROFILE_SELECTION:
            return BioProfileSelectionModule;
        case ModuleV1Type.CTA_MODULE:
            return CallToActionModule;
        case ModuleV1Type.TITLE:
            return TitleModule;
        case ModuleV1Type.HIV_BANNER:
            return HIVBannerModule;
        case ModuleV1Type.KIRO_INFO:
            return KiroInfoModule;
        case ModuleV1Type.MODULAR_BANNER:
            return ModularBanner;
        case ModuleV1Type.PAYMENT:
            return PaymentModule;
        case ModuleV1Type.PREGNANCY_DUE_DATE:
            return PregnancyDueDateModule;
        case ModuleV1Type.PREGNANCY_PROGRESS:
            return PregnancyProgressModule;
        case ModuleV1Type.RATING:
            return RatingModule;
        case ModuleV1Type.RAW_FILE:
            return RawFileModule;
        case ModuleV1Type.REPORT_HEADER:
            return ReportHeaderModule;
        case ModuleV1Type.REPORT_RECOMMENDATIONS:
            return ReportRecommendationsModule;
        case ModuleV1Type.REPORT_SUM_UP:
            return ReportSumUpModule;
        case ModuleV1Type.DISCLAIMER:
            return DisclaimerModule
        case ModuleV1Type.SIMPLE_COUNTER:
            return SimpleCounterModule;
        case ModuleV1Type.DOCUMENTS_MODULE:
            return DocumentsModule;
        case ModuleV1Type.REPORTS_BY_PATIENT:
            return ReportsByPatientModule;

        default:
            return undefined;
    }
}

const ClassModuleType = (type: ModuleV1Type): typeof React.Component => {
    switch (type) {
        case ModuleV1Type.ACCOUNT_INFO:
            return AccountModule;
        case ModuleV1Type.REPORT_VALIDATION:
            return ReportValidationModule;
        case ModuleV1Type.REPORT_CONTENT:
            return ReportContentModule;
        case ModuleV1Type.LAST_REPORTS:
            return LastReportsModule;
        case ModuleV1Type.SUBSCRIPTION:
            return SubscriptionModule;
        case ModuleV1Type.PREVIOUS_REPORTS:
            return PreviousReportsModule;
        case ModuleV1Type.SIMPLE_CHART:
            return SimpleChart;
        case ModuleV1Type.MODULAR_HEADER:
            return ModularHeader;
        case ModuleV1Type.LINK_CARD:
            return LinkCard;
        case ModuleV1Type.SIMPLE_TABLE_DISTRIBUTION:
            return SimpleTableDistributionModule;
        case ModuleV1Type.SIMPLE_HALF_PIE:
            return SimpleHalfPie;
        case ModuleV1Type.SIMPLE_CHART_WITH_SWITCH:
            return SimpleChartWithSwitchModule;
        case ModuleV1Type.COHORTS_COMPARATOR:
            return CohortsComparatorModule;
        case ModuleV1Type.DATA_TABLE:
            return DataTableModule;
        case ModuleV1Type.SIMPLE_MAP:
            return SimpleMap;
        case ModuleV1Type.TELECONSULTATION:
            return TeleconsultationModule;
        case ModuleV1Type.DOCTOR_REPORTS:
            return DoctorReportsModule;
        case ModuleV1Type.BIOLOGISTS_ADMIN_SEARCH:
            return BiologistAdminSearchModule;
        case ModuleV1Type.BIOLOGISTS_ADMIN_SEARCH_RESULT:
            return BiologistAdminSearchResultModule;
        case ModuleV1Type.LOCKED_MODULE:
            return LockedModule;
        default:
            throw Error('Unsupported module type ' + type);
    }
};

export function select(
    identifier: string,
    displayData: ModuleDisplayData,
    refreshPage: () => void,
    type: ModuleV1Type,
    pageId: string,
    displayedInSideBar?: boolean,
    pageUrlContext?: PageContext,
): ReactNode {
    const baseProps: BaseModuleComponentProps = {
        identifier,
        refreshPage,
        displayData,
        pageId,
        type,
        displayedInSideBar,
        pageUrlContext,
    };
    const FunctionalModule = getFunctionalModule(type);
    if (FunctionalModule) {
        return <ModuleContainer {...baseProps}>
            <FunctionalModule/>
        </ModuleContainer>;
    }
    const ClassModule = ClassModuleType(type);
    return <ClassModule {...baseProps} />;
}
