import React, {FC, useState} from 'react';
import classNames from "classnames";
import styles from './PinCase.module.scss';

export enum PinCaseSize {
    X_LARGE = 'XL',
    XX_LARGE = 'XXL'
}

export interface PinCaseProps {
    identifier: string;
    onChange: (value: string) => void;
    onPaste: (value: string) => void;
    next?: string;
    previous?: string;
    hidden?: boolean;
    focus?: boolean;
    autoComplete?: string;
    value?:string;
    disabled?:boolean;
    size?: PinCaseSize
}


const PinCase : FC<PinCaseProps> = ({
    size = PinCaseSize.XX_LARGE,
    ...props
}):JSX.Element => {

    const [value, setValue] = useState(props.value ?? '');
    const computedClassName = classNames(styles.pinCase, {
        [styles.pinCaseSizeXLarge]: size == PinCaseSize.X_LARGE,
        [styles.pinCaseSizeXXLarge]: size == PinCaseSize.XX_LARGE,
    })

    return <input
        id={props.identifier}
        value={value}
        className={computedClassName}
        maxLength={1}
        type={props.hidden ? 'password' : 'text'}
        inputMode={'numeric'}
        autoComplete={props.autoComplete ? props.autoComplete : 'off'}
        onPaste={
            (event:React.ClipboardEvent<HTMLInputElement>):void => {
                props.onPaste(event.clipboardData.getData("text/plain"))
                if(props.next){
                    document.getElementById(props.next)?.focus()
                }
            }
        }
        onChange={(v): void => {
            setValue(v.target.value)
            props.onChange(v.target.value)
        }}
        onKeyUp={
            (event: React.KeyboardEvent<HTMLInputElement>): void => {
                const value_ = (event.target as HTMLInputElement).value
                if (value_ !== '' && props.next ) {
                    document.getElementById(props.next)?.focus()
                    return
                }
                if (event.key == 'Backspace') {
                    if (props.previous) {
                        const elt = document.getElementById(props.previous)
                        if (elt) {
                            elt.focus();
                        }
                    }
                }

            }
        }
        disabled={props.disabled}
    />
}

export default PinCase;