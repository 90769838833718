import TrText from "components/common/TrText";
import { AvatarGroup, AvatarSize, AvatarType } from "components/designSystem/components/Avatar";
import Divider, { DividerOrientation, DividerVariant } from "components/designSystem/components/Divider";
import Pill, { PillSeverity } from "components/designSystem/components/Pill";
import { Tag, TagSeverity, TagSize } from "components/designSystem/components/Tag";
import GridRow, { GridRowItem } from "components/designSystem/containers/GridRow";
import Hoverable from "components/designSystem/containers/Hoverable";
import Inline, { AlwaysInline, InlineAlignItem, InlineSpacing } from "components/designSystem/containers/Inline";
import Typography, {
    TBody700,
    TypographyColor,
    TypographyVariant
} from "components/designSystem/containers/Typography";
import WithBackground, { WithBackgroundColor, } from "components/designSystem/containers/WithBackground";
import WithMargin, { MarginSize, WithLeftMargin } from "components/designSystem/containers/WithMargin";
import WithPadding, { PaddingSize } from "components/designSystem/containers/WithPadding";
import Icon, { NullIcon } from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { BASE_GRID_SPLIT } from "components/modules/modular/modules/report/content/models";
import { TransDomain } from 'components/pages/common/MainComponent';

import WithTranslations from 'components/pages/common/WithTranslations';
import { DateTimeFormat, formatDateTime } from "core/utils/Date";
import { capitalizeFirstLetter } from 'core/utils/text';
import { PatientData, ReportTag } from "models/doctor/DashboardModels";
import React, { Fragment } from 'react';


export interface DoctorReportsTableProps {
    rows: DoctorReportsTableRow[]
    onRowClick: (r: DoctorReportsTableRow) => void;
    minimizedVersion?: boolean;
    withStatusColumn?: boolean;
}

export interface DoctorReportsTableRow {
    uuid: string;
    receptionDate: Date;
    patient: PatientData;
    tags: ReportTag[];
    isNew?: boolean;
    isSent: boolean;
    isAiSuggestionsEnriched: boolean;
}

class DoctorReportsTable extends WithTranslations<DoctorReportsTableProps> {
    TRANS_SUFFIX = TransDomain.MEDICAL_REPORT;

    fontDivToUse = (text: string, isNew?: boolean): JSX.Element => {
        return <Typography variant={isNew ? TypographyVariant.CAPTION_700 : TypographyVariant.CAPTION}
                           color={TypographyColor.COLOR_TEXT_DEFAULT}>{text}</Typography>
    }

    buildTableRow = (): JSX.Element => {
        const {withStatusColumn} = this.props;
        return <WithMargin margins={[undefined, MarginSize.MEDIUM, MarginSize.MEDIUM, MarginSize.MEDIUM]}>
            {this.props.rows.map((r: DoctorReportsTableRow, k: number) => {
                return (
                    <div key={r.uuid} id={`report-table-row-${r.uuid}`} onClick={(): void => this.props.onRowClick(r)}>
                        <Hoverable isClickable>
                            <WithPadding paddings={[PaddingSize.SMALL, PaddingSize.SMALL]}>
                                <GridRow columnNb={BASE_GRID_SPLIT}>
                                    <GridRowItem from={1} to={withStatusColumn ? 6 : 7}>
                                        <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                                            {
                                                r.isNew &&
                                                <Fragment>
                                                    <Pill severity={PillSeverity.CRITICAL_RED}/>
                                                    {this.fontDivToUse(r.patient.first_name + ' ' + r.patient.last_name, r.isNew)}
                                                </Fragment>
                                            }
                                            {
                                                !r.isNew &&
                                                <WithLeftMargin margin={MarginSize.MEDIUM}>
                                                    {this.fontDivToUse(r.patient.first_name + ' ' + r.patient.last_name, r.isNew)}
                                                </WithLeftMargin>
                                            }
                                        </AlwaysInline>
                                    </GridRowItem>
                                    <GridRowItem from={withStatusColumn ? 6 : 7} to={withStatusColumn ? 10 : 12}>
                                        {!this.props.minimizedVersion &&
                                            this.fontDivToUse(formatDateTime(r.patient.birth_date, DateTimeFormat.DATE_MEDIUM), r.isNew)
                                        }
                                    </GridRowItem>
                                    <GridRowItem from={withStatusColumn ? 10 : 12} to={withStatusColumn ? 14 : 17}>
                                        {!this.props.minimizedVersion &&
                                            this.fontDivToUse(formatDateTime(r.receptionDate, DateTimeFormat.DATE_TIME_MEDIUM), r.isNew)
                                        }
                                    </GridRowItem>
                                    <GridRowItem from={withStatusColumn ? 14 : 17} to={withStatusColumn ? 18 : 22}>
                                        {<AvatarGroup
                                            size={AvatarSize.SMALL}
                                            payload={(r.tags.length > 0 && r.tags[0].viewer_name) ? {type: AvatarType.INITIALS, initials: r.tags[0].viewer_name.slice(0, 2)} : {type: AvatarType.EMPTY}}
                                            others={r.tags.length > 1 && <TrText input={{trkey: 'other_viewers', trdomain: TransDomain.MEDICAL_REPORT, trdata: {count: r.tags.length - 1}}}/>}
                                        >
                                            {r.tags.length > 0 && r.tags[0].viewer_name && this.fontDivToUse(r.tags[0].viewer_name, r.isNew)}
                                        </AvatarGroup>}
                                    </GridRowItem>
                                    {withStatusColumn && <GridRowItem from={18} to={22}>
                                        <Inline spacing={InlineSpacing.SMALL}>
                                            {r.isSent && <Tag size={TagSize.EXTRA_SMALL} severity={TagSeverity.CRITICAL}>
                                                <TrText input={{trkey: 'state.sent', trdomain: TransDomain.MEDICAL_REPORT}}/>
                                            </Tag>}
                                            {r.isAiSuggestionsEnriched && <Tag size={TagSize.EXTRA_SMALL} severity={TagSeverity.CRITICAL}>
                                                <TrText input={{trkey: 'state.ai_generated', trdomain: TransDomain.MEDICAL_REPORT}}/>
                                            </Tag>}
                                        </Inline>
                                    </GridRowItem>}
                                    <GridRowItem from={22} to={23}>
                                        <Icon name={IconName.CHEVRON_RIGHT} size={IconSize.EXTRA_SMALL}
                                              color={IconColor.ON_TERTIARY}
                                        />
                                    </GridRowItem>
                                </GridRow>
                            </WithPadding>
                        </Hoverable>
                        {
                            k + 1 != this.props.rows.length && <Divider
                                orientation={DividerOrientation.HORIZONTAL}
                                variant={DividerVariant.DEFAULT}
                            />
                        }
                    </div>
                )
            })}
        </WithMargin>
    }

    render(): JSX.Element {
        const {withStatusColumn} = this.props
        // Patient name | Birth Date | Report Date | Viewers | Conclusion (Optional column) | Chevron Icon
        // Column weights *with* status column (out of 21):
        // 5 | 4 | 4 | 4 | 4 | wrap
        // Column weights *without* status column (out of 21):
        // 6 | 5 | 5 | 5 | wrap
        // Note: wrapping the last column is done using position 22 (HTML wraps elements not planned in the grid)
        return (
            <div>
                {!this.props.minimizedVersion &&
                    <WithMargin margins={[MarginSize.MEDIUM, MarginSize.MEDIUM, MarginSize.SMALL, MarginSize.MEDIUM]}>
                        <WithBackground color={WithBackgroundColor.TINTED}>
                            <GridRow columnNb={BASE_GRID_SPLIT}>
                                <GridRowItem from={1} to={withStatusColumn ? 6 : 7}>
                                    <WithMargin margins={[MarginSize.SMALL]}>
                                        <TBody700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                            {this.trans('patient_name', undefined, undefined, capitalizeFirstLetter)}
                                        </TBody700>
                                    </WithMargin>
                                </GridRowItem>
                                <GridRowItem from={withStatusColumn ? 6 : 7} to={withStatusColumn ? 10 : 12}>
                                    <TBody700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                        {this.trans('birth_date', undefined, undefined, capitalizeFirstLetter)}
                                    </TBody700>
                                </GridRowItem>
                                <GridRowItem from={withStatusColumn ? 10 : 12} to={withStatusColumn ? 14 : 17}>
                                    <TBody700
                                        color={TypographyColor.COLOR_TEXT_DEFAULT}>{this.trans('reception_date', undefined, undefined, capitalizeFirstLetter)}
                                    </TBody700>
                                </GridRowItem>
                                <GridRowItem from={withStatusColumn ? 14 : 17} to={withStatusColumn ? 18 : 22}>
                                    <TBody700
                                        color={TypographyColor.COLOR_TEXT_DEFAULT}>{this.trans('accessible_by', undefined, undefined, capitalizeFirstLetter)}
                                    </TBody700>
                                </GridRowItem>
                                {withStatusColumn && <GridRowItem from={18} to={22}>
                                    <TBody700 color={TypographyColor.COLOR_TEXT_DEFAULT}><TrText input={{trkey: 'state.title', trdomain: TransDomain.MEDICAL_REPORT}}/></TBody700>
                                </GridRowItem>}
                                <GridRowItem from={22} to={23}>
                                    <NullIcon size={IconSize.EXTRA_SMALL}/>
                                </GridRowItem>
                            </GridRow>
                            <Divider variant={DividerVariant.HIGHLIGHTED} orientation={DividerOrientation.HORIZONTAL}/>
                        </WithBackground>
                    </WithMargin>
                }
                {this.buildTableRow()}
            </div>
        )
    }
}

export default DoctorReportsTable;
