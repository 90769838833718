import React, { Component, PropsWithChildren, ReactElement, ReactNode } from 'react';
import './PopUp.scss';
import Close from 'core/content/icons/Close';

export enum PopUpStyle {
    NORMAL = 'NORMAL',
    HEIGHT_AUTO = 'HEIGHT_AUTO',
}

export interface PopUpProps {
    onClose: () => void;
    style?: PopUpStyle;
    title?: string;
    disableStdClose?: boolean;
}

export type PopUpFn = (
    content: ReactElement,
    style: PopUpStyle | undefined,
    title: string | null,
    disableStdClose?: boolean,
) => void;

export default class PopUp extends Component<PropsWithChildren<PopUpProps>> {
    render(): ReactNode {
        const hasTitle = this.props.title && this.props.title.length >= 0;
        return (
            <div
                id="popup-component"
                onClick={this.props.disableStdClose ? undefined : this.props.onClose}
                className={'popup-background'}
            >
                <div className={'popup-container'}>
                    <div
                        className={'popup popup-' + (this.props.style || PopUpStyle.NORMAL)}
                        onClick={(e): void => e.stopPropagation()}
                    >
                        <div className={'popup-title ' + (hasTitle ? '' : 'popup-title-empty')}>
                            <span>{this.props.title}</span>
                            {!this.props.disableStdClose && (
                                <div className={'popup-close'} onClick={this.props.onClose}>
                                    <Close />
                                </div>
                            )}
                        </div>
                        <div className={'popup-content'}>
                            <div style={{ height: '100%', width: '100%' }} onClick={(e): void => e.stopPropagation()}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
