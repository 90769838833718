import { getFillColor } from "components/legacyDesignSystem/components/charts/builders/Line";
import { NormalityLevel } from 'components/legacyDesignSystem/components/charts/models';
import React from 'react';
import { ReferenceLine } from 'recharts';
import { ImplicitLabelType } from "recharts/types/component/Label";

export function buildPointer(
    x: number | string,
    normalityLevel?: NormalityLevel,
    label?: ImplicitLabelType,
    topOverlay?: number,
): JSX.Element {
    return <ReferenceLine
        key={'ReferenceLine' + x}
        x={x}
        stroke={getFillColor(normalityLevel ?? NormalityLevel.NORMAL)}
        strokeWidth={3}
        label={
           label
        }
        segment={[{x:x, y: -30}, {x:x, y: 400}]}
        shape={
            ((props): JSX.Element => {
                return (
                    <line x={x} stroke={props.stroke ? props.stroke.toString() : undefined} strokeWidth="3" fill="none" fillOpacity="1"
                          x1={props.x1}
                          y1={props.y1}
                          x2={props.x2}
                          y2={props.y2 - (topOverlay ? topOverlay - 10 : 0)}
                          className="recharts-reference-line-line"
                          strokeLinecap={'round'}
                    />
                )
            })
        }
    />

}