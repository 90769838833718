import React from 'react';

const Electros = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M25.8516 12.338C25.7935 12.2354 25.7092 12.15 25.6073 12.0907C25.5054 12.0313 25.3896 12 25.2716 12H16.5383L18.0025 0.812829C18.0182 0.663107 17.983 0.512453 17.9027 0.385125C17.8224 0.257798 17.7016 0.161205 17.5597 0.110899C17.4178 0.0605937 17.2631 0.0595028 17.1205 0.107802C16.9779 0.156101 16.8557 0.25098 16.7736 0.377163L6.15295 18.9842C6.09084 19.0852 6.05681 19.2009 6.05434 19.3195C6.05188 19.438 6.08108 19.5551 6.13894 19.6586C6.1968 19.7621 6.28122 19.8483 6.3835 19.9083C6.48577 19.9683 6.6022 20 6.72078 20H15.3236L14.1633 31.204C14.1519 31.3532 14.1907 31.5019 14.2736 31.6265C14.3565 31.7511 14.4786 31.8444 14.6206 31.8916C14.7627 31.9387 14.9164 31.9371 15.0573 31.8868C15.1983 31.8365 15.3183 31.7405 15.3985 31.6142L25.8436 13.0092C25.9042 12.908 25.9368 12.7926 25.9383 12.6747C25.9397 12.5568 25.9098 12.4406 25.8516 12.338Z"/>
        </svg>
    )
}

export default Electros;
