import { EULAValidation } from 'models/user/AuthModels';
import { PageContext } from 'models/modular/api';
import { DisplayData } from "models/medicalReport/ReportAccessModel";
import { APIFormItemWithPayload } from "models/common/DynamicFormModels";
import { UserMode } from "models/user/UserModels";

export enum ConnectionModeType {
    SUBSCRIPTION = 'SUBSCRIPTION',
    AS_GUEST = 'AS_GUEST',
    LOGIN = 'LOGIN',
}

export interface SubscriptionFormData {
    eula_validations: EULAValidation[];
    items: APIFormItemWithPayload[];
}

export interface AsGuestFormData {
    eula_validations: EULAValidation[];
    display_data: DisplayData;
    items: APIFormItemWithPayload[];
    with_patient_permission_check: boolean;
}

export interface CheckPatientBirthdayFormData {
    items: APIFormItemWithPayload[];
    display_data: DisplayData;
    with_patient_permission_check: boolean;
}

export interface SubscriptionPayload {
    form_data: SubscriptionFormData;
    token: string;
}

export interface AsGuestPayload {
    form_data: AsGuestFormData;
    token: string;
}

export interface LoginPayload {
    email: string;
    with_healthcare_professional_connection?: boolean;
    is_email_editable?: boolean;
}

export enum OnSuccessActionType {
    REDIRECT_TO_PAGE = 'REDIRECT_TO_PAGE',
    GIVE_PATIENT_ACCESS = 'GIVE_PATIENT_ACCESS',
    CHECK_PATIENT_BIRTHDATE = 'CHECK_PATIENT_BIRTHDATE'
}

export interface RedirectToPagePayload {
    type: OnSuccessActionType;
    page_id: string;
    context?: PageContext;
}

export interface GivePatientAccessPayload {
    type: OnSuccessActionType;
    token: string;
}

export interface CheckPatientBirthDatePayload {
    type: OnSuccessActionType;
    form_data: CheckPatientBirthdayFormData;
    token: string;
}

export type OnSuccessPayload = RedirectToPagePayload | GivePatientAccessPayload | null;

export interface ConnectionMode {
    type: ConnectionModeType;
    payload: SubscriptionPayload | AsGuestPayload | LoginPayload;
    on_success: OnSuccessPayload;
    user_type?: UserMode
}

export interface GetConnectionModesResponse {
    default: ConnectionModeType;
    modes: ConnectionMode[]
    laboratory_uuid?: string
}

export interface GivePatientAccessResponse {
    action: RedirectToPagePayload | CheckPatientBirthDatePayload;
}

export enum OutdatedReportType {
    PATIENT_REPORT = 'PATIENT_REPORT',
    SHARED_REPORT = 'SHARED_REPORT'
}
