import { takeLatest } from '@redux-saga/core/effects';
import {
    API_BIOLOGIST_COMMUNICATION_MEANS_ACTION,
    API_REFRESH_NOTIFICATION_BIOLOGIST,
    BiologistCommunicationMeansAction,
    RefreshNotificationBiologistAction
} from 'actions/biologistDashboard/BiologistDashboardFetchActions';
import { moduleApiCallSuccessAction, OwnPayloadOverrideMode } from "actions/modular/ModularActions";
import { AnyIterator } from 'core/utils/Typed';
import { put } from 'redux-saga/effects';
import CallHandler from 'sagas/api/CallHandler';
import { biologistCommunicationMeansAction, getNotificationsBiologist } from 'sagas/api/Endpoints';

export const biologistCommunicationMeansActionSaga = function* (action: BiologistCommunicationMeansAction): AnyIterator {
    yield CallHandler.for(
        biologistCommunicationMeansAction,
        action
    ).call();
}

export const refreshNotificationBiologistSaga = function* (action: RefreshNotificationBiologistAction): AnyIterator {
    const response = yield CallHandler.for(
        getNotificationsBiologist,
        action
    ).call();

    if (response) {
        yield put(moduleApiCallSuccessAction(
            action.payload.moduleIdentifier,
            {notifications: response.notifications},
            null,
            OwnPayloadOverrideMode.SPREAD,
        ));
    }
}



export default function* biologistDashboardSagas(): AnyIterator {
    yield takeLatest(API_BIOLOGIST_COMMUNICATION_MEANS_ACTION, biologistCommunicationMeansActionSaga);
    yield takeLatest(API_REFRESH_NOTIFICATION_BIOLOGIST, refreshNotificationBiologistSaga);
}

