import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';

import './Button.scss';
import TextLoader from 'components/core/items/TextLoader';

export enum ButtonType {
    DEFAULT = 'default',
    WHITE = 'white',
    DARK = 'dark',
    GREY = 'grey',
    LIGHT_GREY = 'light-grey',
}
export interface ButtonProps {
    onClick?: () => void;
    content: ReactNode;
    big?: boolean;
    wide?: boolean;
    thin?: boolean;
    type?: ButtonType;
    loading?: boolean;
    id?: string;
}

export default class Button extends Component<ButtonProps> {
    getClassName(): string {
        const type: ButtonType = this.props.type ? this.props.type : ButtonType.DEFAULT;
        return classNames('button', `button-${type}`, {
            'button-thin': this.props.thin,
            'button-big': this.props.big,
            'button-wide': this.props.wide,
        });
    }

    render(): ReactNode {
        return (
            <div className={this.getClassName()} onClick={this.props.onClick}>
                {this.props.loading ? (
                    <div
                        className={classNames({
                            'button-loading': !this.props.thin,
                            'button-thin-loading': this.props.thin,
                        })}
                    >
                        <TextLoader />
                    </div>
                ) : (
                    this.props.content
                )}
            </div>
        );
    }
}
