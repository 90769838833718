import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Tube = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-tube'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.68153 2.12903C4.68153 1.78163 4.96316 1.5 5.31056 1.5H10.3428C10.6902 1.5 10.9719 1.78163 10.9719 2.12903C10.9719 2.47644 10.6902 2.75806 10.3428 2.75806H10.1331V6.57064L13.4963 12.2881C14.0719 13.2665 13.3664 14.5 12.2312 14.5H3.76809C2.66204 14.5 1.95341 13.323 2.47092 12.3455L5.52024 6.5857V2.75806H5.31056C4.96316 2.75806 4.68153 2.47644 4.68153 2.12903ZM6.7783 2.75806V6.74194C6.7783 6.84453 6.75321 6.94558 6.7052 7.03625L5.30694 9.67742H10.5011L8.96193 7.06087C8.90506 6.9642 8.87508 6.85409 8.87508 6.74194V2.75806H6.7783ZM11.2411 10.9355H4.6409L3.58278 12.9342C3.50885 13.0738 3.61009 13.2419 3.76809 13.2419H12.2312C12.3934 13.2419 12.4942 13.0657 12.412 12.9259L11.2411 10.9355Z"
                      fill="currentColor"/>
            </svg>
        )
    } else if (props.size === IconSize.MEDIUM) {
        return (
            <svg id={'m-tube'} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7.45712 1.23077C7.45712 0.551034 8.01501 0 8.7032 0H22.2968C22.985 0 23.5429 0.551034 23.5429 1.23077C23.5429 1.9105 22.985 2.46154 22.2968 2.46154H20.8242V13.0044L28.6082 27.2829C29.7705 29.4148 28.2072 32 25.7557 32H5.24433C2.79284 32 1.22952 29.4148 2.39177 27.2829L10.1758 13.0044V2.46154H8.7032C8.01501 2.46154 7.45712 1.9105 7.45712 1.23077ZM12.668 2.46154V13.3147C12.668 13.5184 12.6168 13.7189 12.5191 13.8982L9.11635 20.1399H21.8837L18.4809 13.8982C18.3832 13.7189 18.332 13.5184 18.332 13.3147V2.46154H12.668ZM23.2256 22.6014H7.77441L4.58605 28.4499C4.31783 28.9419 4.6786 29.5385 5.24433 29.5385H25.7557C26.3214 29.5385 26.6822 28.9419 26.414 28.4499L23.2256 22.6014Z"
                      fill="currentColor"/>
            </svg>
        )
    }
    return (
        <svg id={'s-tube'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.75005 2.375C6.75005 1.89175 7.1418 1.5 7.62505 1.5H16.3751C16.8583 1.5 17.2501 1.89175 17.2501 2.375C17.2501 2.85825 16.8583 3.25 16.3751 3.25H15.5001V10.0294L20.1314 18.6305C21.0731 20.3793 19.8064 22.5 17.8202 22.5H6.17993C4.19366 22.5 2.927 20.3793 3.86869 18.6305L8.50005 10.0294V3.25H7.62505C7.1418 3.25 6.75005 2.85825 6.75005 2.375ZM10.2501 3.25V10.25C10.2501 10.3948 10.2141 10.5373 10.1455 10.6648L8.01307 14.625H15.987L13.8546 10.6648C13.786 10.5373 13.7501 10.3948 13.7501 10.25V3.25H10.2501ZM16.9293 16.375H7.07076L5.40952 19.4602C5.09562 20.0431 5.51784 20.75 6.17993 20.75H17.8202C18.4823 20.75 18.9045 20.0431 18.5906 19.4602L16.9293 16.375Z"
                  fill="currentColor"/>
        </svg>
    )
}