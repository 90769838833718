import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Calendar = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-calendar'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_147_1171)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M4.5 0.25C4.91421 0.25 5.25 0.585786 5.25 1V2H10.75V1C10.75 0.585786 11.0858 0.25 11.5 0.25C11.9142 0.25 12.25 0.585786 12.25 1V2H12.5C14.433 2 16 3.567 16 5.5V12.5C16 14.433 14.433 16 12.5 16H3.5C1.567 16 0 14.433 0 12.5V5.5C0 3.567 1.567 2 3.5 2H3.75V1C3.75 0.585786 4.08579 0.25 4.5 0.25ZM3.75 4V3.5H3.5C2.39543 3.5 1.5 4.39543 1.5 5.5V5.75H14.5V5.5C14.5 4.39543 13.6046 3.5 12.5 3.5H12.25V4C12.25 4.41421 11.9142 4.75 11.5 4.75C11.0858 4.75 10.75 4.41421 10.75 4V3.5H5.25V4C5.25 4.41421 4.91421 4.75 4.5 4.75C4.08579 4.75 3.75 4.41421 3.75 4ZM14.5 7.25H1.5V12.5C1.5 13.6046 2.39543 14.5 3.5 14.5H12.5C13.6046 14.5 14.5 13.6046 14.5 12.5V7.25Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_147_1171">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-calendar'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7 1C7.55228 1 8 1.44772 8 2V4H16V2C16 1.44772 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V4H20C21.6569 4 23 5.34315 23 7V10V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V10V7C1 5.34315 2.34315 4 4 4H6V2C6 1.44772 6.44772 1 7 1ZM3 11V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V11H3ZM21 9H3V7C3 6.44772 3.44772 6 4 6H6V7C6 7.55228 6.44772 8 7 8C7.55228 8 8 7.55228 8 7V6H16V7C16 7.55228 16.4477 8 17 8C17.5523 8 18 7.55228 18 7V6H20C20.5523 6 21 6.44772 21 7V9Z"
                  fill="currentColor"/>
        </svg>
    )
}