import { Component, ReactNode } from 'react';
import { Chart, ChartFilter } from 'models/charts/charts';
import { TranslationMethod } from 'models/common/message';
import { buildChart } from 'components/commonDesign/charts/builder';
import { numberFormatter } from 'components/commonDesign/values/SimpleNumericalValue';
import { fromLanguageToNumberFormat } from 'components/utils/LanguageToNumber';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NumericalValue } from 'models/medicalReport/ReportModels';
import { Margin } from "recharts/types/util/types";

export interface ChartComponentInterface {
    chart: Chart,
    translation: TranslationMethod,
    margin?: Partial<Margin>,
    filters?: ChartFilter[],
    chartHeight?: number
}


class ChartComponent extends Component<ChartComponentInterface & WithTranslation> {
    render(): ReactNode {
        const {chart, translation, margin, filters} = this.props
        return buildChart(
            chart,
            translation,
            margin,
            filters,
            (value: number | null | NumericalValue) => this.formatNumber(value),
            this.props.chartHeight,
        );
    }

    protected formatNumber(value: number | null | NumericalValue): string {
        if (value === null) return '';

        const formatter = Object.hasOwn(this.props, 'i18n') ?
            fromLanguageToNumberFormat(this.props.i18n.language) :
            undefined;

        if (typeof value === "object") {
            if (Object.hasOwn(value, "value") && value.value != null) {
                return numberFormatter(value.value, formatter) + (value.unit != null ? " " + value.unit : "")
            }

            return  ''
        }

        return numberFormatter(
            value,
            formatter
        )

    }
}

export default withTranslation()(ChartComponent);