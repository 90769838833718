import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { AlwaysInline } from "components/designSystem/containers/Inline";
import { TLabel500 } from "components/designSystem/containers/Typography";
import Icon from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { TransDomain } from "components/pages/common/MainComponent";
import WithTranslations from "components/pages/common/WithTranslations";
import { capitalizeFirstLetter } from "core/utils/text";
import { ModuleReportResultSource } from "models/modular/report";
import React, { Fragment, ReactElement, ReactNode } from 'react';

export interface ResourcesListProps {
    sources: ModuleReportResultSource[],
}

export default class ResourcesList extends WithTranslations<ResourcesListProps> {
    TRANS_SUFFIX = TransDomain.MODULES + '.report'

    buildReturnValue = (l: ModuleReportResultSource): ReactElement | string => {
        return l.name ? this.transApiText(l.name) : this.trans('know_more', undefined, undefined, capitalizeFirstLetter)
    }


    render(): JSX.Element {

        if (this.props.sources.length == 0) {
            return <Fragment/>
        }

        return (<AlwaysInline>
            {this.props.sources.map((s, k): ReactNode => {
                return <Button variant={ButtonVariant.LINK}
                               key={k}
                               icons={this.props.sources.length === 1 ? {right: IconName.CHEVRON_RIGHT} : undefined}
                               size={ButtonSize.EXTRA_SMALL}
                               onClick={{
                                   href: s.link.href, targetBlank: s.link.target_blank
                               }}
                >
                    <TLabel500>{this.buildReturnValue(s)}</TLabel500>
                </Button>
            }).reduce((prev, next, k) => [prev, <Icon key={k} name={IconName.DIVIDER} color={IconColor.ON_TERTIARY} size={IconSize.SMALL}/>, next])}
        </AlwaysInline>)
    }
}