import React, { ReactNode } from 'react';
import './MessagePopHeader.scss';
import MainComponent, { MainComponentProps, setupRaw, TransDomain } from 'components/pages/common/MainComponent';
import APIFeedbackMessageContainer, { DEFAULT_IDENTIFIER_MAIN_HEADER, } from 'components/pages/common/APIFeedbackMessage';

export type MessagePopHeaderProps = MainComponentProps;

class MessagePopHeader extends MainComponent<MessagePopHeaderProps> {
    TRANS_SUFFIX = TransDomain.MESSAGES;

    render(): ReactNode {
        return (
            <React.Fragment>
                <div className={'message-pop-header'}>
                    <APIFeedbackMessageContainer withBorderRadius={false} identifier={DEFAULT_IDENTIFIER_MAIN_HEADER} />
                </div>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default setupRaw(MessagePopHeader);
