import ConnectedNumericalValue from 'components/common/ConnectedNumericalValue';
import TrText from "components/common/TrText";
import { toCardStyle } from 'components/commonDesign/utils/style';
import { ColorMap } from "components/designSystem/Aliases";
import SimpleCard from 'components/legacyDesignSystem/components/cards/SimpleCard';
import Container, { ContainerProfile, MarginType } from 'components/legacyDesignSystem/components/Container';
import { Icons } from 'components/legacyDesignSystem/components/Icon';
import TextLoader from 'components/legacyDesignSystem/components/TextLoader';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import { useConnectedContextElement, useModulePayload } from "components/modules/modular/ModuleContainer";
import { APIText } from 'models/common/message';
import { NumericalValue } from 'models/medicalReport/ReportModels';
import React from 'react';

export interface SimpleCounterModulePagePayload {
    icon: Icons
    title: APIText
    counter: NumericalValue | null
    alternative_counter: NumericalValue | null
    on_click_context_value: string | null
    style?: ColorMap
}

const INTERNAL_CONTEXT_KEY = 'context_click_value'

export const SimpleCounterModule = (): JSX.Element => {
    const payload = useModulePayload<SimpleCounterModulePagePayload>();
    const [contextValue, setContextValue] = useConnectedContextElement(INTERNAL_CONTEXT_KEY);

    const onCardClick = (on_click_context_value: string, reset: boolean): void => {
        setContextValue(!reset ? on_click_context_value : undefined)
    }

    const on_click_context_value = payload.on_click_context_value;
    const isSelected = !!on_click_context_value && contextValue === on_click_context_value
    return <SimpleCard
        isSelected={isSelected}
        icon={payload.icon}
        description={<TrText input={payload.title}/>}
        style={payload.style ? toCardStyle(payload.style) : undefined}
        onClick={
            on_click_context_value ? (): void => onCardClick(on_click_context_value, isSelected) : undefined
        }
    >
        <Container profiles={[ContainerProfile.FLEX, ContainerProfile.ALIGN_FLEX_END]}>
            <Typography weight={TypographyWeight.MEDIUM} variant={TypographyVariant.BODY} profiles={[{type: MarginType.MR, value: 1}]}>
                {payload.counter ? <ConnectedNumericalValue value={payload.counter}/> : <TextLoader />}
            </Typography>
            {
                payload.alternative_counter &&
                <Typography weight={TypographyWeight.MEDIUM} variant={TypographyVariant.CAPTION}>
                    <ConnectedNumericalValue value={payload.alternative_counter}/>
                </Typography>
            }
        </Container>
    </SimpleCard>;
}
