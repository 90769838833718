import React, { Component, PropsWithChildren } from 'react';
import classNames from 'classnames';

import 'components/core/containers/DropdownMenu.scss';
import ArrowExpander, { ArrowDirection, ArrowStrokeWeight } from 'core/content/icons/ArrowExpander';

export interface DropdownMenuProps {
    title?: string
    titleComponent?: JSX.Element
    noMargin?: boolean
    lightTitle?: boolean
}

export class DropdownMenu extends Component<PropsWithChildren<DropdownMenuProps>> {
    state = {
        isOpen: false,
    }

    dropdownMenuRef = React.createRef<HTMLInputElement>();

    componentDidMount(): void {
        document.addEventListener('mousedown', (e: MouseEvent) => this.handleClickOutside(e));
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', (e: MouseEvent) => this.handleClickOutside(e));
    }

    handleClickOutside = (e: MouseEvent): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.dropdownMenuRef.current && !this.dropdownMenuRef.current.contains(e.target)) {
            this.setState({ isOpen: false });
        }
    };

    render(): JSX.Element {
        return (
            <div
                ref={this.dropdownMenuRef}
                className={classNames('dropdown-menu-container',
                    {
                        'dropdown-menu-container-margin': !this.props.noMargin
                    })}
                onClick={(): void => this.setState({ isOpen: !this.state.isOpen })}
            >
                <div className={classNames('dropdown-menu-title-container', {
                    'dropdown-menu-title-container-menu-open': this.state.isOpen,
                    'dropdown-menu-title-container-not-light': !this.props.lightTitle
                })}>
                    {
                        this.props.titleComponent ? (
                            this.props.titleComponent
                        ) : (
                            <span className={'dropdown-menu-title-name'}>
                                {this.props.title}
                            </span>
                        )
                    }
                    {
                        !this.props.lightTitle &&
                        <ArrowExpander direction={ArrowDirection.DOWN} strokeWidth={ArrowStrokeWeight.NORMAL} />
                    }

                </div>
                <div
                    className={classNames(
                        'dropdown-menu-choices-container',
                        { 'dropdown-menu-choices-visible': this.state.isOpen }
                    )}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export interface DropdownMenuItemProps {
    onClick: () => void;
    name?: React.ReactNode,
    component?: JSX.Element,
}

export const DropdownMenuItem = (props: DropdownMenuItemProps): JSX.Element => {
    if (props.name === undefined && props.component === undefined) {
        return <div />
    }
    return (
        <div
            className={'dropdown-menu-item-container'}
            onClick={props.onClick}
        >
            {
                props.component ? (
                    props.component
                ) : (
                    <span className={'dropdown-menu-item-name'}>
                        {props.name}
                    </span>
                )
            }
        </div>
    )
}