import ChartComponent from 'components/commonDesign/charts/ChartComponent';
import { MOBILE_MAX_WIDTH } from "components/core/constants";
import WithTabs, { TabObject, TabsStyle } from 'components/legacyDesignSystem/components/WithTabs';
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { Chart, ChartItem, ChartType } from 'models/charts/charts';
import { TimeSelector, TimeSelectorType, TimeSeriePayload } from 'models/charts/charts/TimeSerie';
import React, { ReactNode } from 'react';
import MediaQuery from "react-responsive";


const DEFAULT_SELECTORS: TimeSelector[] = [
    {
        type: TimeSelectorType.ALL_TIME,
        quantity: 1
    },
    {
        type: TimeSelectorType.MONTH,
        quantity: 3
    },
    {
        type: TimeSelectorType.MONTH,
        quantity: 6
    },
    {
        type: TimeSelectorType.YEAR,
        quantity: 5
    },
]

export interface ChartTimeSeriesWithSelectorProps {
    items: ChartItem[]
    payload?: TimeSeriePayload
    chartHeight?: number
}

class ChartTimeSeriesWithSelector extends WithTranslations<ChartTimeSeriesWithSelectorProps> {
    TRANS_SUFFIX = TransDomain.GRAPH;

    state: {
        selected?: TimeSelector
    } = {}


    componentDidMount(): void {
        const selectors = this.getSelectors();

        if (this.state.selected === undefined && selectors && selectors.length > 0 ) {
            this.setState({selected: selectors[0]})
        }
    }

    render(): ReactNode {

        const selectors = this.getSelectors();

        const selected = this.state.selected ?? selectors[0];

        const chart: Chart<TimeSeriePayload> = {
            type: ChartType.TIME_SERIE,
            items: this.props.items,
            payload: {
                ...(this.props.payload ? this.props.payload : {}),
                ...{
                    selectors: selectors,
                    selected: selected,
                }
            }
        };

        return <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
            {(isMobile): JSX.Element => {
                return <WithTabs
                    collapsed={isMobile}
                    tabs={this.toTabs(selectors)}
                    selectedTabId={this.buildSelectorId(selected)}
                    style={TabsStyle.SELECTORS}
                    withDropdownTitleWhenCollapse={this.trans('selector_prompt')}
                >
                    <ChartComponent
                        chart={chart}
                        translation={this.transApiText}
                        margin={{top: 32}}
                        chartHeight={this.props.chartHeight}
                    />
                </WithTabs>;
            }}
        </MediaQuery>;
    }

    private getSelectors(): TimeSelector[] {
        return this.props.payload && this.props.payload.selectors && this.props.payload.selectors.length > 0 ?
            this.props.payload.selectors : DEFAULT_SELECTORS
    }

    private buildSelectorId(selector: TimeSelector): string {
        return `${selector.type}::${selector.quantity}`
    }

    private toTabs(selectors: TimeSelector[]): TabObject[] {
        return selectors.map(
            (s) => ({
                id: this.buildSelectorId(s),
                name: this.trans(
                    `selectors.${s.quantity === 1 ? 'singular' : 'plural'}.` + s.type,
                    {quantity: s.quantity}
                ),
                onClick: (): void => {this.setState({selected: s})},
            })
        )
    }
}


export default ChartTimeSeriesWithSelector;
