import { ReferenceScaleProps } from 'components/designSystem/components/ReferenceScale';
import { APIText } from 'models/common/message';
import { ModuleReportDataTag, ValueTrend } from 'models/modular/report';
import { PillSeverity } from 'components/designSystem/components/Pill';
import React from 'react';


export interface ReportLineNumericalValue {
    value: string
    unit?: string
}

export interface ReportLinePreviousValue {
    value: ReportLineNumericalValue,
    trend: ValueTrend,
    date: Date,
}

export enum ReportLineNormality {
    UNKNOWN = 'UNKNOWN',
    NORMAL = 'NORMAL',
    BAD = 'BAD',
    PARTIALLY_BAD = 'PARTIALLY_BAD',
}

export interface Comment {
    content: APIText;
}

export interface BaseReportLineProps {
    name: string;
    isMobile: boolean;
    normality: PillSeverity;
    onDetailClick?: (ev?: React.MouseEvent) => void;
    onHistoryClick?: (ev?: React.MouseEvent) => void;
    comments?: Comment[];
    reportId?: string;
    internalId?: string;
    tags: ModuleReportDataTag[];
}

export interface NumericalReportLineProps extends BaseReportLineProps {
    value: ReportLineNumericalValue;
    additionalValue?: ReportLineNumericalValue;
    referenceScale?: ReferenceScaleProps;
    previousValue?: ReportLinePreviousValue;
    interpretation?: APIText;
}


export interface CategoricalReportLineProps extends BaseReportLineProps {
    value: string | null;
    interpretation?: APIText;
}


export interface AntibiogramBacteria {
    name: string;
}

export interface AntibiogramAntibiotic {
    name: string;
    sensibility: string | null;
}


export interface AntibiogramReportLineProps {
    isMobile: boolean;
    bacteria: AntibiogramBacteria;
    antibiotics: AntibiogramAntibiotic[];
    comments?: Comment[];
    tags: ModuleReportDataTag[];
}

export const BASE_GRID_SPLIT = 21;