import _ from 'underscore';
import { apiCallFailureAction, apiCallSuccessAction, } from 'actions/ActionInterface';
import { pushAPIFeedbackMessage } from 'actions/common/APIMessaginActions';
import { redirect, RequestApiWithTokenAction } from 'actions/common/CommonActions';
import {
    API_GET_MEDICAL_REPORT_CONNECTION_MODES,
    API_GET_RANDOM_MEDICAL_REPORT_UUID,
    API_GET_SHARED_REPORT,
    API_GIVE_PATIENT_ACCESS,
    API_RESEND_FIRST_MAIL_REPORT,
    API_RESEND_SHARED_REPORT,
    API_SHARE_REPORT,
    givePatientAccessInterface,
    ShareReportInterface,
} from 'actions/medicalReports/MedicalReportsFetchActions';
import { TransDomain, TransKey } from 'components/pages/common/MainComponent';
import { getRoute } from "core/routing/Helper";
import { ROUTE_MEDICAL_REPORT_REPORT, ROUTE_MODULAR_PAGE, } from 'core/routing/Routes';
import { AnyIterator, cast } from 'core/utils/Typed';
import { APIFeedbackMessageType } from 'models/common/APIMessaging';
import {
    GetConnectionModesResponse,
    GivePatientAccessResponse,
    OnSuccessActionType,
    RedirectToPagePayload
} from 'models/medicalReport/ConnectionModesModel';
import { RandomReportUUID } from 'models/medicalReport/LegacyReportModels';
import { GetSharedReportResponse } from "models/medicalReport/ReportAccessModel";
import { put, takeLatest } from 'redux-saga/effects';
import CallHandler from 'sagas/api/CallHandler';
import {
    getConnectionModes,
    getRandomReportUUID,
    getSharedReport,
    givePatientAccess,
    reSendFirstMailReport,
    reSendSharedReport,
    shareReport
} from 'sagas/api/Endpoints';
import { CustomError, Errors } from 'sagas/api/rest/Exceptions';

export const fetchRandomMedicalReportUUID = function* (): AnyIterator {
    const result: RandomReportUUID = yield CallHandler.safelyCall(getRandomReportUUID);
    if (result) {
        yield put(redirect(ROUTE_MEDICAL_REPORT_REPORT, { uuid: result.uuid }));
    }
};

export const connectionModesSaga = function* (action: RequestApiWithTokenAction): AnyIterator {
    try {
        const response: GetConnectionModesResponse = yield CallHandler
            .for(getConnectionModes, action)
            .forwardingErrors(Errors.OutdatedToken)
            .call();
        if (response) {
            yield put(apiCallSuccessAction(
                API_GET_MEDICAL_REPORT_CONNECTION_MODES,
                response,
                {originToken: action.payload.params ? action.payload.params.token : undefined}
            ));
        }
    }catch (e) {
        yield put(apiCallFailureAction(
            API_GET_MEDICAL_REPORT_CONNECTION_MODES
        ));
    }
};

export const givePatientAccessSaga = function* (action: givePatientAccessInterface): AnyIterator {
    const response: GivePatientAccessResponse = yield CallHandler.for(givePatientAccess, action)
        .withErrorFeedbackRule(
            (e: CustomError) => e.is(Errors.TooManyRequest),
            () => {
                return {
                    message: new TransKey('too_many_request_retry_later', TransDomain.ACCOUNT_AUTH),
                };
            },
        )
        .call();

    if (response){
        if (response.action.type === OnSuccessActionType.REDIRECT_TO_PAGE){
            const payload = cast<RedirectToPagePayload>(response.action);
            const params = Object.assign({}, payload.context, {'pageId': payload.page_id})
            yield put(redirect(getRoute(ROUTE_MODULAR_PAGE, params)))
        } else {
            yield put(apiCallSuccessAction(
                API_GIVE_PATIENT_ACCESS, response, {fromSubscription: action.payload.params?.fromSubscription}
            ))
        }
    }
}

export const shareReportSaga = function* (action: ShareReportInterface): AnyIterator {
        const response: Record<string, string> = yield CallHandler.for(shareReport, action)
            .withErrorFeedbackRule(
                (e) => e.message == 'invalid_email',
                () => {
                    return {
                        message: new TransKey('error_message', TransDomain.SHARE_REPORT_FEATURE),
                    };
                },
            )
            .call()

        if (response) {
            yield put(
                pushAPIFeedbackMessage(
                    action.payload.feedbackIdentifier ?? null,
                    new TransKey('success_message', TransDomain.SHARE_REPORT_FEATURE),
                    APIFeedbackMessageType.SUCCESS,
                )
            );
        }
};

export const getSharedReportSaga = function* (action: RequestApiWithTokenAction): AnyIterator {
    try {
        const response: GetSharedReportResponse = yield CallHandler
            .for(getSharedReport, action)
            .forwardingErrors(Errors.OutdatedToken)
            .call();
        if (response) {
            // todo: connection_modes should be removed is not used at the end of shared report feature deployed
            if (!_.isEmpty(response.connection_modes)){
                yield put(apiCallSuccessAction(API_GET_SHARED_REPORT, response))
            } else if (response.redirect_link){
                yield put(redirect(getRoute(ROUTE_MODULAR_PAGE, {
                    pageId: response.redirect_link.page_id,
                    ... response.redirect_link.context
                })))
            }
        }

    }catch (e) {
        yield put(apiCallFailureAction(API_GET_SHARED_REPORT));
    }
};

export const reSendSharedReportSaga = function* (action: RequestApiWithTokenAction): AnyIterator {
    const response: Record<string, string> = yield CallHandler.for(reSendSharedReport, action)
        .withErrorFeedbackRule(
            () => true,
            () => {
                return {
                    message: new TransKey('resend_message.error', TransDomain.OUTDATED_REPORT),
                };
            },
        )
        .call()
    if (response) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier ?? null,
                new TransKey('resend_message.success', TransDomain.OUTDATED_REPORT),
                APIFeedbackMessageType.SUCCESS,
            )
        );
    }
};

export const reSendFirstMailReportSaga = function* (action: RequestApiWithTokenAction): AnyIterator {
    const response: Record<string, string> = yield CallHandler.for(reSendFirstMailReport, action)
        .withErrorFeedbackRule(
            () => true,
            () => {
                return {
                    message: new TransKey('resend_message.error', TransDomain.OUTDATED_REPORT),
                };
            },
        )
        .call()
    if (response) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier ?? null,
                new TransKey('resend_message.success', TransDomain.OUTDATED_REPORT),
                APIFeedbackMessageType.SUCCESS,
            )
        );
    }
};

export default function* medicalReportsSaga(): AnyIterator {
    yield takeLatest(API_GET_RANDOM_MEDICAL_REPORT_UUID, fetchRandomMedicalReportUUID);
    yield takeLatest(API_GET_MEDICAL_REPORT_CONNECTION_MODES, connectionModesSaga);
    yield takeLatest(API_GIVE_PATIENT_ACCESS, givePatientAccessSaga);
    yield takeLatest(API_SHARE_REPORT, shareReportSaga);
    yield takeLatest(API_GET_SHARED_REPORT, getSharedReportSaga);
    yield takeLatest(API_RESEND_SHARED_REPORT, reSendSharedReportSaga);
    yield takeLatest(API_RESEND_FIRST_MAIL_REPORT, reSendFirstMailReportSaga);
}
