import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { buildUrl, get, post, UrlReq } from "sagas/call/api";
import { GetEndpoint, PostEndpoint } from "sagas/call/endpoints";
import { APIError } from "sagas/call/error";
import { errorProcessor } from "sagas/call/errorHandling";
import useSWR from "swr";
import { KeyedMutator } from "swr/_internal";
import useSWRMutation from "swr/mutation";

interface UseAPIResponse<Data> {
    /**
     * Based on SWRResponse
     */

    /**
     * The returned data of the fetcher function.
     */
    data: Data | undefined;
    /**
     * The error object thrown by the fetcher function.
     */
    error: APIError | undefined;
    mutate: KeyedMutator<Data>;
    isValidating: boolean;
    isLoading: boolean;
}

export function getter<Res>(): (url: string) => Promise<Res> {
    /**
     * For getter all params must be pass through url so the request is properly cached by SWR
     */
    return (
        url: string
    ) => get<Res>(
            url,
        )
}

export function poster<Req, Res>(): ((url: string, req: { arg: Req }) => Promise<Res>) {
    return (
        url: string,
        req: { arg: Req }
    ) => post<Req, Res>(
        url,
        req.arg
    )
}


/**
 * This method wrap useSWR Hook in order to mutualize API error handling
 *
 * This method does not process error retry nor revalidateOnFocus to avoid issues with PIN / EUla errors
 * @param endpoint if null api call is not made
 * @param req request payload
 * @param centralErrorSupport if true, error messages are sent to central error system (using API_MESSAGE_FEEDBACK)
 * for message to be de displayed in central error disclaimer
 */
export function useAPI<Req extends UrlReq, Res>(
    endpoint: (GetEndpoint<Req, Res>) | null,
    req: Req,
    centralErrorSupport = true
): UseAPIResponse<Res> {
    const {data, error, isLoading, mutate, isValidating} = useSWR<Res, APIError>(
        endpoint ? buildUrl(endpoint.path, req) : null,
        getter<Res>(),
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false
        })

    const dispatch = useDispatch()

    // We only process error is not isLoading && not isValidating to avoid error handling to be process several times
    // Even if deps: [error], swr Cache put back previous error (while being validating) when revisiting a path key
    useEffect(
        errorProcessor(
            dispatch,
            mutate,
            isLoading || isValidating ? undefined : error ,
            {
                fallbackToAPIFeedback: centralErrorSupport ? 'all' : undefined,
                ifNotSupported: 'ignore'
            }
        ), [error]
    )


    return {data, error, isLoading, isValidating, mutate}
}

export function useAPIPost<Req, Res >(
    endpoint: (PostEndpoint<Req, Res>),
    centralErrorSupport = true
): {trigger: (args: Req) => void, isMutating: boolean, error: APIError | undefined, response: Res | undefined, reset: () => void} {
    const {data, error, trigger, isMutating, reset} = useSWRMutation<Res, APIError, string, Req>(
        buildUrl(endpoint.path, undefined),
        poster(),

    )

    const dispatch = useDispatch()

    // We only process error is not isMutating to avoid error handling to be process several times
    useEffect(
        errorProcessor(
            dispatch,
            undefined,
            isMutating ? undefined : error,
            {
                fallbackToAPIFeedback: centralErrorSupport ? 'all' : undefined,
                ifNotSupported: 'ignore'
            }
        ),
        [error]
    )

    // trigger : with catch error here to have it handled only by error parameter
    // @ts-ignore
    return {response: data, error, trigger: ((arg: Req) => trigger(arg).catch((): void => undefined)), isMutating, reset}
}