import React from 'react';

const SmallArrowRight = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5753 6.80386C12.2535 6.23864 13.2616 6.33028 13.8268 7.00854L20.6295 15.1718C21.1235 15.7646 21.1235 16.6258 20.6295 17.2186L13.8268 25.3818C13.2616 26.0601 12.2535 26.1518 11.5753 25.5865C10.897 25.0213 10.8054 24.0133 11.3706 23.335L17.3204 16.1952L11.3706 9.05538C10.8054 8.37712 10.897 7.36908 11.5753 6.80386Z" />
        </svg>
    )
}

export default SmallArrowRight;
