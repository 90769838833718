import React from 'react';

const NotificationIcon = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <g clipPath="url(#clip0_857_15527)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.2496 28.4179C18.287 28.6759 17.3382 28.5797 16.5609 28.2332C16.0228 27.9933 15.3922 28.2351 15.1523 28.7732C14.9125 29.3112 15.1542 29.9419 15.6923 30.1817C16.921 30.7294 18.3717 30.8618 19.8018 30.4786C21.2318 30.0954 22.422 29.2555 23.2122 28.1667C23.5583 27.69 23.4523 27.023 22.9756 26.6769C22.4988 26.3309 21.8318 26.4368 21.4858 26.9136C20.9858 27.6023 20.2122 28.16 19.2496 28.4179Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7503 4.16121C8.21952 5.37524 6.30669 9.47746 7.3763 13.4693C7.47366 13.8327 7.57548 14.1654 7.67953 14.5054C7.72843 14.6652 7.77781 14.8266 7.82746 14.9935C7.97601 15.493 8.11971 16.0206 8.19726 16.5617C8.35971 17.6953 8.22466 18.8509 7.43447 20.0977C6.79653 21.212 6.96351 21.7446 7.16372 22.0287C7.45349 22.44 8.16216 22.8527 9.39444 23.1144C11.7985 23.6251 15.2609 23.3658 17.7196 22.707C20.1784 22.0482 23.3066 20.5415 25.1332 18.8972C26.0695 18.0544 26.4769 17.3427 26.5222 16.8416C26.5535 16.4955 26.4318 15.9507 25.3222 15.3047C24.0145 14.62 23.3197 13.6868 22.8936 12.6238C22.6902 12.1164 22.5509 11.5876 22.4298 11.0808C22.3893 10.9114 22.3514 10.747 22.3139 10.5842C22.234 10.2377 22.1558 9.89861 22.0584 9.53522C20.9888 5.5434 17.2811 2.94719 12.7503 4.16121ZM5.31566 14.0214C3.96962 8.99794 6.42576 3.64729 12.1982 2.10057C17.9706 0.553859 22.773 3.95958 24.119 8.98307C24.2263 9.38336 24.3188 9.78432 24.4027 10.1478C24.4381 10.3012 24.472 10.4479 24.5047 10.5851C24.6207 11.0704 24.731 11.4739 24.8738 11.83C25.1374 12.4878 25.5152 13.0018 26.3273 13.4229L26.3488 13.434L26.3697 13.4461C27.9076 14.334 28.7808 15.5527 28.6468 17.0338C28.5267 18.362 27.6067 19.541 26.5605 20.4828C24.422 22.4078 20.9647 24.0461 18.2718 24.7676C15.5789 25.4892 11.7656 25.7991 8.95116 25.2012C7.5742 24.9087 6.18794 24.3477 5.4198 23.2575C4.56325 22.0418 4.71008 20.5497 5.59798 19.0118L5.61007 18.9909L5.62309 18.9705C6.1159 18.1999 6.18602 17.5658 6.0855 16.8643C6.03108 16.4846 5.9249 16.08 5.78264 15.6016C5.74243 15.4664 5.69839 15.3225 5.65236 15.1719C5.54325 14.8152 5.42291 14.4217 5.31566 14.0214Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_857_15527">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default NotificationIcon;