import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import Container, { ContainerProps} from 'components/legacyDesignSystem/components/Container';

import 'components/legacyDesignSystem/branding/typography.scss';
import { Colors } from 'components/legacyDesignSystem/branding/constants';

import "./Typography.scss"
import { getResponsiveValue, ResponsiveValue } from 'components/legacyDesignSystem/utils/responsiveness';


export enum TypographyVariant {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    BODY = 'body',
    BODY2 = 'body2',
    CAPTION = 'caption',
    SMALL = 'small',
    XSMALL = 'xsmall',
    OVERLINE = 'overline',
    OVERLINE_SMALL = 'overline_small',
}

export enum TypographyWeight {
    BOLD = 'bold',
    MEDIUM = 'medium',
    REGULAR = 'regular',
}

export type TypographyProps = ContainerProps & PropsWithChildren<{
    variant?: TypographyVariant | ResponsiveValue<TypographyVariant>;
    weight?: TypographyWeight | ResponsiveValue<TypographyWeight>;
    color?: Colors;
    className?: string;
}>

const Typography = (props: TypographyProps): JSX.Element => {
    const containerProps = {...props};
    delete containerProps.variant;
    delete containerProps.weight;
    delete containerProps.color;
    delete containerProps.className;

    return (
        <Container
            id={props.id || undefined}
            {...containerProps}
            className={classNames(
                'typography',
                props.className,
                props.variant && getResponsiveValue(props.variant),
                props.weight && getResponsiveValue(props.weight),
                props.color,
        )}>
        {props.children}
    </Container>
    )
}

export default Typography;
