
export enum CommunicationMeanType {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
}
interface AbtractCommunicationMean {
    type: CommunicationMeanType
}
export interface CommunicationMeanEmail extends AbtractCommunicationMean {
    type: CommunicationMeanType.EMAIL
    email: string
}

export interface CommunicationMeanPhone extends AbtractCommunicationMean {
    type: CommunicationMeanType.PHONE
    phone: string
}

export type AllRawCommunicationMeansType = CommunicationMeanEmail | CommunicationMeanPhone