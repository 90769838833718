import React from 'react';

import 'components/legacyDesignSystem/components/Tag.scss';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import { Colors } from 'components/legacyDesignSystem/branding/constants';

export interface TagProps {
    title: React.ReactNode;
}

export const Tag = ({ title }: TagProps): JSX.Element => (
    <span className={'tag'}>
        <Typography
            weight={TypographyWeight.MEDIUM}
            variant={TypographyVariant.XSMALL}
            color={Colors.NEUTRALS_DARK_BLUE_GREY}
        >
            {title}
        </Typography>
    </span>
);

export default Tag;
