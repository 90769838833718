import { buildSeverityBrief } from "components/commonDesign/utils/ReportNormalityCountUtils";
import { ReportMetadataNormality } from "models/medicalReport/ReportModels";
import React from 'react';

import {TransDomain} from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import {Entity} from 'models/entities/default';
import Inline, {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
} from "components/designSystem/containers/Inline";
import WithMargin, {MarginSize, WithLeftMargin} from "components/designSystem/containers/WithMargin";
import EntityLogo from "components/commonDesign/EntityLogo";
import {AvatarSize} from "components/designSystem/components/Avatar";
import {IconName} from "components/designSystem/foundations/IconsData";
import Typography, {
    TypographyColor,
    TypographyVariant
} from "components/designSystem/containers/Typography";
import Divider, {DividerOrientation, DividerVariant} from "components/designSystem/components/Divider";
import {capitalizeFirstLetter} from "core/utils/text";
import {XSmallSpacer} from "components/designSystem/containers/Spacer";
import {formatDate} from "core/utils/Date";


export interface ReportsListProps {
    rows: ReportsListRow[];
    onRowClick: (r: ReportsListRow) => void;
    withoutHeader?: boolean;
}

export interface ReportsListRow {
    uuid: string;
    laboratory?: Entity;
    samplingDate: Date;
    isNew?: boolean;
    normalityCount?: {[k in ReportMetadataNormality]?: number | null}
}

class ReportsList extends WithTranslations<ReportsListProps> {
    TRANS_SUFFIX = TransDomain.MEDICAL_REPORT;

    fontDivToUse = (text: string, isNew?: boolean): JSX.Element => {
        return <Typography variant={isNew ? TypographyVariant.CAPTION_700 : TypographyVariant.CAPTION} color={TypographyColor.COLOR_TEXT_DEFAULT}>{text}</Typography>
    }

    render(): JSX.Element {
        return (
            <div>{
                this.props.rows.map((r: ReportsListRow, k: number) => {
                    return (<div key={r.uuid} id={`report-list-row-${r.uuid}`} onClick={(): void => {this.props.onRowClick(r)}}>
                            <WithMargin margins={[MarginSize.SMALL, MarginSize.SMALL, undefined, undefined]}>
                            <AlwaysInline alignItems={InlineAlignItem.CENTER}
                                          justifyContent={InlineJustifyContent.SPACE_BETWEEN}>
                                <AlwaysInline alignItems={InlineAlignItem.CENTER}>
                                    <WithMargin margins={[MarginSize.SMALL]}>
                                        <EntityLogo
                                            entity={ r.laboratory }
                                            asAvatar={ { size: AvatarSize.SMALL } }
                                            fallbackIcon={ IconName.TUBE }/>
                                    </WithMargin>
                                    <WithMargin margins={ [MarginSize.SMALL] }>
                                        <XSmallSpacer>
                                        {r.laboratory?.name ? (this.fontDivToUse(r.laboratory.name, r.isNew)) : this.fontDivToUse(this.trans('loading_name', undefined, undefined, capitalizeFirstLetter), r.isNew)}
                                        <Inline>
                                            {this.fontDivToUse(formatDate(r.samplingDate, 'D MMM YYYY - HH[h]mm'), r.isNew)}
                                        </Inline>
                                        </XSmallSpacer>
                                    </WithMargin>
                                </AlwaysInline>
                                <AlwaysInline>
                                    {
                                        r.normalityCount &&
                                        buildSeverityBrief(r.normalityCount)
                                    }
                                </AlwaysInline>
                            </AlwaysInline>
                            { k + 1 != this.props.rows.length &&
                                <WithLeftMargin
                                    margin={MarginSize.ELEVEN_TIMES_X_SMALL}>
                                    <Divider orientation={DividerOrientation.HORIZONTAL}
                                             variant={DividerVariant.DEFAULT}/>
                                </WithLeftMargin>
                            }
                            </WithMargin>
                        </div>
                    )
                })}
            </div>
        )
    }

}

export default ReportsList;