import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import * as React from 'react';

export const Pen = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.25 2.99993C14.25 2.30957 13.6904 1.74993 13 1.74993C12.6685 1.74993 12.3505 1.88162 12.1161 2.11604L2.88264 11.3495C2.85299 11.3792 2.83128 11.4158 2.81949 11.456L2.10522 13.8947L4.5439 13.1804C4.58413 13.1686 4.62076 13.1469 4.6504 13.1173L13.8839 3.88381C14.1183 3.64939 14.25 3.33145 14.25 2.99993ZM13 0.249927C14.5188 0.249927 15.75 1.48114 15.75 2.99993C15.75 3.72927 15.4603 4.42875 14.9445 4.94447L5.71106 14.178C5.50354 14.3855 5.24717 14.5375 4.96553 14.62L1.21082 15.7197C0.947713 15.7968 0.663528 15.7241 0.469671 15.5303C0.275814 15.3364 0.203178 15.0522 0.280239 14.7891L1.37997 11.0344C1.46246 10.7528 1.61446 10.4964 1.82198 10.2889L11.0555 1.05538C11.5712 0.53966 12.2707 0.249927 13 0.249927Z"
                  fill="currentColor"/>
        </svg>
    }

    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_349_89)">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.0004 4.85702C21.0004 3.8315 20.1691 3.00015 19.1436 3.00015C18.6511 3.00015 18.1788 3.19579 17.8306 3.54402L4.56373 16.8108L3.476 20.5246L7.18973 19.4368L20.4566 6.17002C20.8048 5.82179 21.0004 5.34949 21.0004 4.85702ZM19.1436 1.00015C21.2736 1.00015 23.0004 2.72693 23.0004 4.85702C23.0004 5.87992 22.5941 6.86093 21.8708 7.58423L8.60394 20.8511C8.36678 21.0882 8.07378 21.2619 7.7519 21.3562L2.28346 22.9579C1.93266 23.0606 1.55374 22.9638 1.29527 22.7053C1.03679 22.4468 0.939943 22.0679 1.04269 21.7171L2.64436 16.2487C2.73864 15.9268 2.91235 15.6338 3.14951 15.3966L16.4163 2.1298C17.1396 1.4065 18.1207 1.00015 19.1436 1.00015Z"
                  fill="currentColor"/>
        </g>
    </svg>
};