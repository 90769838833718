import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ChevronLeft = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-chevron-left'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M10.2803 12.0303C9.98744 12.3232 9.51256 12.3232 9.21967 12.0303L5.71967 8.53033C5.42678 8.23744 5.42678 7.76256 5.71967 7.46967L9.21967 3.96967C9.51256 3.67678 9.98744 3.67678 10.2803 3.96967C10.5732 4.26256 10.5732 4.73744 10.2803 5.03033L7.31066 8L10.2803 10.9697C10.5732 11.2626 10.5732 11.7374 10.2803 12.0303Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-chevron-left'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.2071 18.2071C14.8166 18.5976 14.1834 18.5976 13.7929 18.2071L8.29289 12.7071C8.10536 12.5196 8 12.2652 8 12C8 11.7348 8.10536 11.4804 8.29289 11.2929L13.7929 5.79289C14.1834 5.40237 14.8166 5.40237 15.2071 5.79289C15.5976 6.18342 15.5976 6.81658 15.2071 7.20711L10.4142 12L15.2071 16.7929C15.5976 17.1834 15.5976 17.8166 15.2071 18.2071Z"
                  fill="currentColor"/>
        </svg>
    )
}