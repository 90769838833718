import React, { ReactNode } from 'react';

import LogoFull from 'core/content/brand/logos/LogoFull';

import 'components/modules/eula/eula.scss';
import EulaContentV1 from 'components/modules/eula/versions/EulaContentV1';
import MainComponent, { MainComponentProps, setupRaw } from 'components/pages/common/MainComponent';
import MissingEulaVersion from 'components/modules/eula/versions/MissingVersion';

import EulaHealthProfessionalV2 from './versions/EulaHealthProfessionalV2bis';
import EulaPatientV2 from 'components/modules/eula/versions/EulaPatientV2bis';
import EulaDataPrivacyV2 from './versions/EulaDataPrivacyV2bis';
import EulaHealthProfessionalV3 from './versions/EulaHealthProfessionalV3';
import EulaPatientV3 from 'components/modules/eula/versions/EulaPatientV3';
import EulaDataPrivacyV3 from './versions/EulaDataPrivacyV3';

export enum EULAType {
    HEALTH_PROFESSIONAL = 'HEALTH_PROFESSIONAL',
    PATIENT = 'PATIENT',
    DATA_PRIVACY = 'DATA_PRIVACY',
}

export interface EULAContainerProps extends MainComponentProps {
    match: { params: { version?: string } };
    type: EULAType;
}

class RawEULAContainer extends MainComponent<EULAContainerProps> {
    selectEULAVersion(): JSX.Element | null {
        const {
            match: {
                params: { version },
            },
            type,
        } = this.props;

        if (type === EULAType.HEALTH_PROFESSIONAL) {
            if (version === undefined || version === '3') {
                return <EulaHealthProfessionalV3 />;
            } else if (version === '2') {
                return <EulaHealthProfessionalV2 />;
            } else if (version === '1') {
                return <EulaContentV1 />;
            }
        }
        if (type === EULAType.PATIENT) {
            if (version === undefined || version === '3') {
                return <EulaPatientV3 />;
            } else if (version === '2') {
                return <EulaPatientV2 />;
            } else if (version === '1') {
                return <EulaContentV1 />;
            }
        }

        if (type === EULAType.DATA_PRIVACY) {
            if (version === undefined || version === '3') {
                return <EulaDataPrivacyV3 />;
            } else if (version === '2') {
                return <EulaDataPrivacyV2 />;
            }
        }

        return <MissingEulaVersion />;
    }

    render(): ReactNode {
        return (
            <div className="eula-page">
                <LogoFull />
                {this.selectEULAVersion()}
            </div>
        );
    }
}

export default setupRaw(RawEULAContainer);
