import TrText from 'components/common/TrText';
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import WithTooltip, {
    TooltipAlignment, TooltipPosition, TooltipWidth
} from 'components/designSystem/components/WithTooltip';
import Box, { BoxColor } from 'components/designSystem/containers/Box';
import {
    AlwaysInline, InlineAlignItem, InlineJustifyContent, InlineSpacing
} from 'components/designSystem/containers/Inline';
import { MediumSpacer } from "components/designSystem/containers/Spacer";
import { TLabel500, TypographyColor } from 'components/designSystem/containers/Typography';
import WithPadding, { PaddingSize } from "components/designSystem/containers/WithPadding";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from 'components/designSystem/foundations/IconsData';
import ReportInterpretationListItem
    from "components/modules/modular/modules/report/content/ReportInterpretationListItem";
import ResourcesList from "components/modules/modular/modules/report/content/RessourcesList";
import { TransDomain } from 'components/pages/common/MainComponent';
import { translationMethod } from 'core/utils/trans';
import { toLocalTransKey } from 'models/common/message';
import { ModuleReportResultInterpretation, ModuleReportResultSource } from 'models/modular/report';
import React, { useState } from 'react';

interface ReportReportInterpretationListProps {
    interpretations: ModuleReportResultInterpretation;
    global_sources?: ModuleReportResultSource[];
}

const ReportInterpretationList = ({
                                      interpretations, global_sources
                                  }: ReportReportInterpretationListProps): JSX.Element => {
    if (interpretations.rules.length === 0) return <></>
    const [isCollapsed, setCollapsed] = useState(true); // start with a collapsed state
    const matchedRules = interpretations.rules.filter((rule) => rule.match);
    const unmatchedRules = interpretations.rules.filter((rule) => !rule.match);
    // Decide whether to display the unmatchedRules based on isCollapsed.
    const elementsToDisplay = isCollapsed ? matchedRules : [...matchedRules, ...unmatchedRules];
    const handleToggleCollapse = (): void => setCollapsed(!isCollapsed);

    return (<MediumSpacer>
        <AlwaysInline alignItems={InlineAlignItem.CENTER} justifyContent={InlineJustifyContent.FLEX_START}
                      spacing={InlineSpacing.SMALL}>
            {(matchedRules.length > 0 || !isCollapsed) && (<>
                <TLabel500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    <TrText input={toLocalTransKey("info.rules", TransDomain.MEDICAL_REPORT)}/>
                </TLabel500>
                <WithTooltip
                    position={TooltipPosition.TOP}
                    alignment={TooltipAlignment.RIGHT}
                    width={TooltipWidth.WIDTH_2OREM}
                    tooltip={<TrText capitalize
                                     input={toLocalTransKey("report.interpretation_details", TransDomain.MODULES)}/>}
                >
                    <Icon size={IconSize.EXTRA_SMALL} name={IconName.INFO_CIRCLE}/>
                </WithTooltip></>)}
        </AlwaysInline>
        <Box background={BoxColor.WHITE} withFullWidth withFullHeight>
            <MediumSpacer>
                {elementsToDisplay.map((rule, index) => (<ReportInterpretationListItem
                    key={index}
                    name={rule.name}
                    description={rule.description}
                    links={rule.specific_sources}
                    global_sources={isCollapsed ? global_sources : undefined}
                    match={rule.match}
                />))}
                {unmatchedRules.length > 0 && (<>
                    {!isCollapsed && global_sources && global_sources.length > 0 && (
                        <WithPadding paddings={[PaddingSize.X_SMALL, PaddingSize.X_SMALL]}>
                            <ResourcesList sources={global_sources}/>
                        </WithPadding>)}
                    <Button
                        icons={{right: isCollapsed ? IconName.CHEVRON_DOWN : IconName.CHEVRON_UP}}
                        variant={ButtonVariant.LINK}
                        onClick={handleToggleCollapse}
                        size={ButtonSize.SMALL}
                    >
                        <TLabel500>{isCollapsed ? translationMethod('view_more', undefined, TransDomain.GLOBAL) : translationMethod('view_less', undefined, TransDomain.GLOBAL)}</TLabel500>
                    </Button>
                </>)}
            </MediumSpacer>
        </Box>
    </MediumSpacer>);
};
export default ReportInterpretationList;