import React from 'react';

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const User = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-user'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_216_205)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M10.5 4C10.5 5.38071 9.38071 6.5 8 6.5C6.61929 6.5 5.5 5.38071 5.5 4C5.5 2.61929 6.61929 1.5 8 1.5C9.38071 1.5 10.5 2.61929 10.5 4ZM12 4C12 6.20914 10.2091 8 8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4ZM1.55488 14.5C1.90253 12.2348 3.85978 10.5 6.22222 10.5H9.77778C12.1402 10.5 14.0975 12.2348 14.4451 14.5H1.55488ZM0 15.2222C0 11.7858 2.78578 9 6.22222 9H9.77778C13.2142 9 16 11.7858 16 15.2222C16 15.6518 15.6518 16 15.2222 16H0.777778C0.348223 16 0 15.6518 0 15.2222Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_216_205">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-user'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15 6C15 7.65685 13.6569 9 12 9C10.3431 9 9 7.65685 9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6ZM17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6ZM3.13429 21C3.79349 17.5818 6.80112 15 10.4118 15H13.5882C17.1989 15 20.2065 17.5818 20.8657 21H3.13429ZM1 22.4118C1 17.2138 5.21379 13 10.4118 13H13.5882C18.7862 13 23 17.2138 23 22.4118C23 22.7366 22.7366 23 22.4118 23H1.58824C1.26336 23 1 22.7366 1 22.4118Z"
                  fill="currentColor"/>
        </svg>
    )
}

