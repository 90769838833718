import React, { FC } from 'react';
import styles from './Toggle.module.scss';
import classNames from "classnames";

export enum ToggleSize {
    LARGE = 'LARGE',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL',
}

export enum TogglePosition {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export interface ToggleProps {
    onClick: () => void;
    label: string;
    checked: boolean;
    size?: ToggleSize;
    togglePosition?: TogglePosition;
    disabled?: boolean;
}

const Toggle: FC<ToggleProps> = (
    (
        {
            size = ToggleSize.MEDIUM,
            togglePosition = TogglePosition.LEFT,
            disabled = false,
            ...props

        }): JSX.Element => {

        const container = classNames(styles.container,
            {
                [styles.containerDisabled]: disabled,
            }
        )
        const toggleBoxClass = classNames(styles.toggleBox,
            {
                [styles.toggleBoxSizeLarge]: size === ToggleSize.LARGE,
                [styles.toggleBoxSizeMedium]: size === ToggleSize.MEDIUM,
                [styles.toggleBoxSizeSmall]: size === ToggleSize.SMALL,

                [styles.toggleBoxActive]: props.checked && !disabled,
                [styles.toggleBoxNotActive]: !props.checked && !disabled,
                [styles.toggleBoxActiveDisabled]: props.checked && disabled,
                [styles.toggleBoxNotActiveDisabled]: !props.checked && disabled,
            }
        )
        const computedKnob = classNames(styles.toggleKnob,
            {
                [styles.toggleKnobSizeLarge]: size === ToggleSize.LARGE,
                [styles.toggleKnobSizeMedium]: size === ToggleSize.MEDIUM,
                [styles.toggleKnobSizeSmall]: size === ToggleSize.SMALL,

                [styles.toggleKnobActiveLarge]: props.checked && size === ToggleSize.LARGE,
                [styles.toggleKnobActiveMedium]: props.checked && size === ToggleSize.MEDIUM,
                [styles.toggleKnobActiveSmall]: props.checked && size === ToggleSize.SMALL,
                [styles.toggleKnobInactiveLarge]: !props.checked && size === ToggleSize.LARGE,
                [styles.toggleKnobInactiveMedium]: !props.checked && size === ToggleSize.MEDIUM,
                [styles.toggleKnobInactiveSmall]: !props.checked && size === ToggleSize.SMALL,
            }
        )

        const toggleBox = <div className={toggleBoxClass} onClick={(): void => {
            props.onClick();
        }}>
            <div className={computedKnob}/>
        </div>

        return (
            <div className={styles.toggleParent}>
                {togglePosition === TogglePosition.LEFT ?
                    <span className={container}>
                        {toggleBox}
                        <div className={ styles.labelRight }>{props.label}</div>
                    </span>
                    :
                    <span className={container}>
                        <div className={styles.labelLeft}>{props.label}</div>
                        {toggleBox}
                        </span>
                }
            </div>
        );
    }
)


export default Toggle;
