import React, { Fragment } from 'react';
import CONFIG from 'configuration/globals';


const EmailLink = (props: { address: string }): JSX.Element => {
  return(
    <a className={"legacy-a"} href={`mailto:${props.address}`} target='blank' rel='noopener noreferrer'>
      {props.address}
    </a>
  )
}

const FaqData = [
  {
    "validated": true,
    "name": "what_kiro",
    "subject": "Général",
    "subsubject": "Général",
    "title": "Qu'est ce que Kiro ?",
    "content": <Fragment>
      <p>Kiro est une plateforme digitale, visuelle et interactive qui améliore la communication des comptes rendus de biologie médicale entre les laboratoires, les professionnels de santé et les patients. Kiro repense complètement l&apos;expérience des résultats de biologie médicale pour offrir à nos utilisateurs des solutions adaptées à leurs besoins. Construite au plus près des praticiens, des patients et des biologistes, elle vise à proposer une biologie plus compréhensible, personnalisée et pertinente pour le bien des patients.</p>
      <p>Certains laboratoires de biologie médicale ont noué une relation de partenariat avec Kiro dans l&apos;objectif de vous offrir une expérience encore plus novatrice d’accès à vos comptes-rendus de biologie médicale.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_kiro_work",
    "subject": "Général",
    "subsubject": "Général",
    "title": "Comment fonctionne Kiro ?",
    "content": <Fragment>
      <p>Notre solution vous permet de suivre vos résultats de biologie ou ceux de vos patients n&apos;importe où, n&apos;importe quand. Il n&apos;y a aucun logiciel à installer, les mises à jour sont faites automatiquement, sans avoir à vous en préoccuper. L&apos;accès est sécurisé et toutes les données sont chiffrées, selon la réglementation en vigueur et les standards les plus exigeants.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_access_kiro",
    "subject": "Général",
    "subsubject": "Général",
    "title": "J'aimerais accéder à la plateforme Kiro et à l'ensemble de ses fonctionnalités, mais je ne sais pas comment faire.",
    "content": <Fragment>
      <p>Pour accéder à la plateforme Kiro, il vous suffit de <a className={"legacy-a"} href='/' target='blank' rel='noopener noreferrer'>cliquer ici</a>.</p>
      <p>L&apos;accès à certaines fonctionnalités et aux résultats personnalisés n&apos;est possible que si votre laboratoire de biologie médicale est partenaire de Kiro. Pour créer un compte et disposer des fonctionnalités les plus avancées, rapprochez-vous de votre laboratoire de biologie médicale.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_much_kiro",
    "subject": "Général",
    "subsubject": "Accès",
    "title": "Dois-je payer pour accéder à Kiro ?",
    "content": <Fragment>
      <p>L&apos;accès à la plateforme est gratuit et les services développés par Kiro sont mis gratuitement à votre disposition par nos laboratoires partenaires.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "why_notification",
    "subject": "Général",
    "subsubject": "Notifications",
    "title": "Pourquoi ai-je reçu une notification de la part de Kiro ?",
    "content": <Fragment>
      <p>Vous recevez une notification par e-mail pour vous indiquer que les résultats de vos analyses effectuées au laboratoire de biologie médicale sont disponibles sur la plateforme Kiro. Après avoir reçu cet e-mail, cliquez sur &quot;Accéder au compte-rendu&quot;.</p>
      <p>Pour vous assurer de recevoir nos e-mails vous permettant d&apos;accéder à vos résultats, nous vous recommandons d&apos;ajouter l&apos;adresse <EmailLink address={CONFIG.mailAddresses.nepasrepondre}/> à votre carnet d&apos;adresses.</p>
      <p>Pour consulter vos résultats d&apos;analyses, nous vous invitons à suivre les étapes décrites à la question &quot;Comment consulter mes analyses de biologie médicale sur mon compte Kiro ?&quot;.</p>
      </Fragment>,
    "images": [
      {
        "path": "/static/ue/faq/why_notification/Email.png",
        "alt": "notification-email"
      }
    ]
  },
  {
    "validated": true,
    "name": "what_beyond",
    "subject": "Général",
    "subsubject": "Général",
    "title": "Qu'est ce que Beyond ?",
    "content": <Fragment>
      <p>Beyond est un dispositif médical de classe IIa fabriqué par Kiro et dont l&apos;évaluation de la conformité est en cours par l&apos;organisme notifié DNV CE 2460. Beyond fournit aux professionnels de santé des recommandations d&apos;examens complémentaires qu&apos;ils pourraient envisager dans le cadre des soins prodigués au patient.</p>
      <p>Veuillez lire attentivement le mode d&apos;emploi, disponible à l&apos;adresse suivante : <a className={"legacy-a"} href='https://app.kiro.bio/beyond/notice/download/' target='blank' rel='noopener noreferrer'>https://app.kiro.bio/beyond/notice/download/</a></p>
      <p>Le mode d&apos;emploi (e-IFU) peut être mis à la disposition de l&apos;utilisateur sous forme papier dans un délai de sept jours. La demande peut être adressée à <EmailLink address={CONFIG.mailAddresses.info}/>.</p>
    </Fragment>
  },
  {
    "validated": true,
    "name": "who_kiro_for",
    "subject": "Conditions et confidentialité",
    "subsubject": "Conditions et confidentialité",
    "title": "A qui s'adresse Kiro ?",
    "content": <Fragment>
      <p>Kiro s&apos;adresse à des personnes généralement de plus de 18 ans capables de contracter des obligations conformément à la législation française. En conséquence, l&apos;accès à la plateforme Kiro et à ses services est réservé aux personnes physiques de plus de 18 ans.</p>
      <p>La plateforme Kiro ne peut être utilisée par les patients pour assurer la promotion d’un produit, d’un service, d’un établissement de santé, d’un professionnel de santé ou d’une pharmacie ou de manière générale à toute fin publicitaire ou promotionnelle ou professionnelle. Elle est destinée à un usage personnel ou relatif au parcours de soins pour les professionnels de santé autorisés par le laboratoire ou le patient à accéder aux résultats de biologie médicale.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_minors",
    "subject": "Conditions et confidentialité",
    "subsubject": "Mineur et autres",
    "title": "Je suis mineur ou parent de mineur.",
    "content": <Fragment>
      <p>Pour les personnes mineures, les personnes majeures vulnérables ou les personnes majeures faisant l&apos;objet d&apos;une mesure de protection juridique, l&apos;inscription et l&apos;accès à la plateforme Kiro relèvent du ou des représentants légaux (parents, tuteurs).</p>
      <p>Les personnes mineures, ou les personnes faisant l&apos;objet d&apos;une mesure de protection juridique, accèdent donc dans la plupart des cas à leur compte Kiro, sous la responsabilité de leur(s) représentant(s) légal(aux).</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "what_data_privacy",
    "subject": "Conditions et confidentialité",
    "subsubject": "Protection des données",
    "title": "Que fait Kiro pour garantir la protection des données personnelles ?",
    "content": <Fragment>
      <p>Kiro attache une importance fondamentale à la protection et à la sécurité des données personnelles. La confidentialité de ces données personnelles et le respect de la vie privée comptent parmi nos priorités majeures. Nous accordons également une place primordiale à la conformité et au respect des règles de déontologie fixées par les instances ordinales représentant les professionnels de santé et celles définies par les institutions.</p>
      <p>Kiro s’engage dans ce cadre à protéger les données personnelles conformément à la réglementation applicable et notamment le Règlement (UE) n°2016/679 du 27 avril 2016 dit « Règlement Général sur la Protection des Données » ou « RGPD » et la loi n° 78-17 du 6 janvier 1978 modifiée, dite « Loi Informatique et Liberté » modifiée.</p>
      <p>Nous souhaitons également que cet engagement puisse aller au-delà des obligations légales. C’est pourquoi, depuis le lancement de notre solution, Kiro s’assure continuellement du respect de ses obligations en matière de données personnelles et s’est donné des engagements pour protéger les données à caractère personnel des patients et des utilisateurs de sa solution.</p>
      <p>Les données personnelles de santé sont notamment hébergées en Europe auprès d&apos;hébergeurs ayant reçu la certification HDS (Hébergeur de Données de Santé). Introduite par l’Agence du Numérique en Santé (ANS, agence gouvernementale française chargée de la santé numérique), la certification HDS fournit un cadre permettant de renforcer la sécurité et la protection des données de santé à caractère personnel.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_access_cgu",
    "subject": "Conditions et confidentialité",
    "subsubject": "Conditions et confidentialité",
    "title": "Comment consutler les Conditions générales d'utilisation et la Politique de protection des données personnelles de Kiro ?",
    "content": <Fragment>
      <ul>
      <li>Si vous êtes un patient, vous pouvez consulter nos Conditions Générales d&apos;Utilisation en <a className={"legacy-a"} href='/eula-patient/' target='blank' rel='noopener noreferrer'>cliquant ici</a>.</li>
      <li>Si vous êtes un professionnel de santé, nous vous invitons à les consulter en <a className={"legacy-a"} href='/eula-health-professional/' target='blank' rel='noopener noreferrer'>cliquant ici</a>.</li>
      <li>Notre politique de protection des données personnelles est également <a className={"legacy-a"} href='/eula-data-privacy/' target='blank' rel='noopener noreferrer'>disponible ici</a>.</li>
      </ul>
      </Fragment>
  },
  {
    "validated": true,
    "name": "phishing_section",
    "subject": "Conditions et confidentialité",
    "subsubject": "Phishing",
    "title": "Phishing (notifications frauduleuses)",
    "content": <Fragment>
      <ul>
        <li><b>Qu’est-ce que le phishing ?</b></li>
        <p>
          L&apos;hameçonnage ou phishing est une forme d&apos;escroquerie sur internet.<br/><br/>
          Le fraudeur se fait passer pour un organisme que vous connaissez (banque, service des impôts, CAF, etc.), en utilisant le logo et le nom de cet organisme. Il vous envoie un mail vous demandant généralement de  &quot;mettre à jour&quot; ou de &quot;confirmer vos informations suite à un incident technique&quot;, notamment vos coordonnées bancaires (numéro de compte, codes personnels, etc.) (Source: <a className={"legacy-a"} href='https://www.cnil.fr/fr/cnil-direct/question/le-phishing-cest-quoi' target='blank' rel='noopener noreferrer'>CNIL</a>).<br/><br/>
          En confirmant ces informations ou en cliquant sur un lien frauduleux, l’internaute s’expose à la collecte de ses données dans un cadre illégitime et/ou à des fins délictueuses.<br/><br/>
          Ce type d’email ou de SMS n’est pas envoyé par le tiers de confiance en question et n’a aucun lien avec ce dernier. Pour envoyer ces messages, l’émetteur n’a pas à accéder au site ou à la base de données du tiers de confiance : il se contente d’imiter le format et le contenu des emails.<br/><br/>
          Kiro et ses laboratoires partenaires étant des sociétés connues du grand public, il est possible qu’elles attirent l’attention de personnes qui peuvent essayer de se faire passer pour elles. C’est un phénomène impossible à prévenir car des personnes malintentionnées peuvent toujours imiter l’aspect de nos e-mails pour des campagnes de phishing.<br/><br/>
          Sachez cependant que de nombreux dispositifs de sécurité existent, comme par exemple le blocage par de nombreux services de messagerie électronique de la plupart des messages frauduleux, la présence d’anti-spam intégrés par les fournisseurs d’emails, les efforts des équipes informatiques (dont celle de Kiro), en collaboration avec les fournisseurs de mails concernés pour leur demander de bloquer les mails frauduleux lorsqu’ils sont détectés.
        </p>
        <li><b>L’email que j’ai reçu ressemble à celui de Kiro mais me semble suspect, que faire ?</b></li>
        <p>
          Si vous ne reconnaissez pas les informations contenues dans un message reçu par email, il s’agit peut-être d&apos;une tentative de phishing (ou tentative &quot;d&apos;hameçonnage&quot;) et non d&apos;un message légitime envoyé par Kiro. Nous vous recommandons dans ce cas d&apos;être  vigilant et de mettre en application les bonnes pratiques en matière de lutte contre le phishing et de suivre les indications de cette page.<br/><br/>
          Nous vous invitons dans un premier temps à toujours vérifiez que l&apos;adresse email d&apos;envoi se termine par &quot;@kiro.bio.&quot; et que l&apos;adresse URL figurant dans la barre d&apos;adresse de votre navigateur  correspond bien à &quot;<a className={"legacy-a"} href='https://app.kiro.bio' target='blank' rel='noopener noreferrer'>app.kiro.bio</a>&quot; qui est celle de Kiro.<br/><br/>
          Par ailleurs, le gouvernement et la CNIL (Commission Nationale de l&quot;Informatique et des Libertés) ont publié un certain nombre de recommandations qui vous aideront à identifier les messages électroniques frauduleux :
          <ul>
            <li><a className={"legacy-a"} href='https://www.cybermalveillance.gouv.fr/tous-nos-contenus/actualites/arnaques-par-message-electronique-comment-identifier-et-dejouer-lhameconnage' target='blank' rel='noopener noreferrer'>Recommandations du gouvernement</a></li>
            <li><a className={"legacy-a"} href='https://www.cnil.fr/fr/phishing-detecter-un-message-malveillant' target='blank' rel='noopener noreferrer'>Recommandations de la CNIL</a></li>
          </ul>
          <br/>
          Sachez également que Kiro ne vous communique pas directement vos résultats de biologie par e-mail en pièce jointe, ni ne vous demande de répondre par email à la suite du message de notification pour vos résultats, et encore moins de fournir par réponse à cet email des informations personnelles y compris celles relatives à votre santé.
        </p>
        <li><b>Que faire lorsque je reçois un message frauduleux ?</b></li>
        <p>
          De manière générale, ne communiquez aucune information confidentielle (en particulier vos identifiants, mot de passe ou coordonnées bancaires) par e-mail.<br/><br/>
          En guise de bonne pratique de votre navigation internet, si vous pensez que l&apos;email est frauduleux, nous vous recommandons d&apos;être toujours particulièrement vigilant et de ne pas cliquer sur le contenu de l&apos;e-mail puis de le supprimer.<br/><br/>
          Nous vous invitons également à marquer ce message étant un SPAM afin qu&apos;il soit signalé comme tel aux outils de lutte contre le phishing mis en place par les fournisseurs internet.<br/><br/>
          En cas de doute, nous vous invitons à nous contacter à l&apos;adresse e-mail suivante : <a className={"legacy-a"} href='mailto:support@kiro.bio' target='blank' rel='noopener noreferrer'>support@kiro.bio</a> pour faire appel à un membre de notre équipe, qui sera à votre disposition pour répondre à vos questions. Il pourra vous aider à récupérer vos résultats si ces derniers sont disponibles.<br/><br/>
          De même, si vous avez reçu un e-mail de Kiro et que vous n&apos;êtes pas à l&apos;origine de la demande d&apos;e-mail ou que vous n&apos;avez pas réalisé d&apos;analyses de biologie médicale récemment, contactez-nous à <a className={"legacy-a"} href='mailto:support@kiro.bio' target='blank' rel='noopener noreferrer'>support@kiro.bio</a>.<br/><br/>
          Si vous avez accidentellement fourni des informations liées à votre compte Kiro, nous vous invitons à modifier votre mot de passe dès que possible. Vous trouverez toutes les explications dans la section dédiée &quot;<a className={"legacy-a"} href='https://app.kiro.bio/user/forgot-password' target='blank' rel='noopener noreferrer'>comment modifier mon mot de passe</a>&quot;.
        </p>
      </ul>
    </Fragment>
  },
  {
    "validated": true,
    "name": "how_access_results",
    "subject": "Accéder à mes résultats",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "Comment consulter mes résultats d'analyses de biologie médicale ?",
    "content": <Fragment>
      <p>Lorsque vos résultats de biologie médicale sont prêts à être consultés, un accès à la plateforme vous est ouvert par votre laboratoire. Vous recevrez alors un e-mail sur l&apos;adresse que vous avez communiquée à votre enregistrement à votre laboratoire de biologie médicale. Vous pouvez alors cliquer sur le lien et suivre les instructions pour accéder à vos résultats.</p>
      <p>Si votre accès n&apos;a pas été ouvert et que vous avez effectué vos prélèvements il y a plus d’une semaine, vous pouvez contacter directement votre laboratoire par téléphone ou vous rendre sur place.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_fill_email",
    "subject": "Accéder à mes résultats",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "Comment renseigner mon adresse e-mail la première fois ?",
    "content": <Fragment>
      <p>L&apos;adresse e-mail qui vous sera demandée est celle que vous avez communiquée au secrétariat du laboratoire de biologie médicale dans lequel vous vous êtes rendu. Si vous n&apos;avez pas fourni d&apos;adresse e-mail, nous vous invitons à vous rapprocher du secrétariat de votre laboratoire pour que vous puissiez l&apos;ajouter à votre dossier. Vous pourrez ainsi recevoir les notifications, et consulter vos résultats d&apos;analyses de biologie médicale directement sur la plateforme Kiro. </p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_email_broken",
    "subject": "Accéder à mes résultats",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "Comment faire si le lien envoyé par e-mail ne fonctionne pas ?",
    "content": <Fragment>
      <p>Le lien envoyé par e-mail pour consulter vos résultats d&apos;analyses de biologie médicale est valide pendant 30 jours. Une fois ce délai dépassé, le lien n&apos;est plus valide.</p>
      <ul>
        <li>Si vous n&apos;avez pas de compte Kiro, nous vous conseillons de vous rapprocher de votre laboratoire de biologie médicale afin que vous puissiez récupérer votre compte-rendu.</li>
        <li>Si vous disposez d&apos;un compte Kiro, il vous suffit de vous connecter à votre compte, et de vous rendre sur votre page d&apos;accueil. Vous pourrez ainsi retrouver tous vos résultats d&apos;analyses, ainsi que ceux des patients associés à votre compte effectués dans le même laboratoire de biologie médicale partenaire de Kiro. Pour plus d&apos;informations, nous vous invitons à vous rendre sur la question &quot;Comment accéder à mon historique ?&quot;.</li>
      </ul>
      </Fragment>
  },
  {
    "validated": true,
    "name": "what_no_email_received",
    "subject": "Accéder à mes résultats",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "Je n'ai pas reçu d'e-mail suite à mon prélèvement au laboratoire de biologie médicale.",
    "content": <Fragment>
      <p>Si vous vous êtes rendu dans un laboratoire de biologie médicale partenaire de Kiro, un e-mail devrait vous être envoyé dès que vos résultats sont prêts à être consultés. En cas de besoin, vous pouvez utiliser la fonction de récupération de vos résultats en <a className={"legacy-a"} href='/user/trigger-notification' target='blank' rel='noopener noreferrer'>cliquant ici</a>. Un e-mail vous sera alors envoyé à l&apos;adresse indiquée. Si cette adresse correspond bien à celle que vous avez renseignée à votre enregistrement au laboratoire et que vos résultats sont disponibles, vous pourrez alors y accéder de manière sécurisée.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "what_recovery_no_email",
    "subject": "Accéder à mes résultats",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "J'ai utilisé la fonction de récupération de mes résultats, mais je n'ai pas reçu d'e-mail.",
    "content": <Fragment>
      <p>Cet e-mail peut mettre quelques minutes à arriver, pensez à consulter vos spams. En cas de doute, vous pouvez contacter directement votre laboratoire ou vous rendre sur place.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "what_email_not_recognized",
    "subject": "Accéder à mes résultats",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "J'ai utilisé la fonction de récupération de mes résultats, mais l'e-mail n'est pas reconnu.",
    "content": <Fragment>
      <p>Si notre plateforme ne reconnait pas votre adresse e-mail, plusieurs raisons sont possibles. Le plus probable est que l&apos;adresse e-mail que vous avez renseignée ne corresponde pas à celle que vous avez transmise à votre laboratoire. Nous vous invitons alors à procéder aux vérifications suivantes :</p>
      <ol>
        <li>Vérifiez qu&apos;il n&apos;y a pas d&apos;erreur dans la saisie de votre adresse e-mail.</li>
        <li>Assurez-vous que c&apos;est bien l&apos;adresse e-mail que vous avez communiquée lors de votre enregistrement au laboratoire. Vous pouvez ressaisir votre adresse e-mail en <a className={"legacy-a"} href='/user/trigger-notification' target='blank' rel='noopener noreferrer'>cliquant ici</a>.</li>
        <li>Si vous vous en êtes assurés et que vous recevez toujours la même notification, vous pouvez contacter votre laboratoire ou envoyer un e-mail à <EmailLink address={CONFIG.mailAddresses.patients}/>.</li>
        <li>Si vous avez effectué vos prélèvements il y a plus d&apos;une semaine, vous pouvez contacter directement votre laboratoire par téléphone ou vous rendre sur place.</li>
      </ol>
      <p>Il est également possible que vos résultats n&apos;aient pas encore été transmis à Kiro par votre laboratoire et ne soient donc pas encore disponibles. Dans ce cas, rassurez-vous, votre laboratoire met tout en œuvre pour vous les transmettre au plus vite. Le traitement de vos analyses peut prendre quelques jours. Une fois les résultats disponibles, nous vous informerons par e-mail. Pensez à vérifier vos spams.</p>

      <p>Pour vous assurer de recevoir nos e-mails vous permettant d&apos;accéder à vos résultats, nous vous recommandons d&apos;ajouter l&apos;adresse <EmailLink address={CONFIG.mailAddresses.nepasrepondre}/> à votre carnet d&apos;adresses.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_cannot_access",
    "subject": "Accéder à mes résultats",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "J'ai bien reçu un e-mail indiquant que mes résultats sont disponibles, mais je n'arrive pas à y accéder.",
    "content": <Fragment>
      <p>Pour accéder aux résultats de biologie médicale, nous vous invitons à saisir la date de naissance du patient concerné par les résultats de biologie médicale en question. Il s&apos;agit soit de votre date de naissance, soit si vous êtes le représentant légal de la personne concernée (ex : enfant mineur), de la date de naissance du patient que vous représentez. Vérifiez donc qu&apos;il s&apos;agit bien de la date de naissance du patient venu effectué ses analyses au laboratoire et non d&apos;un autre patient. Cette date de naissance doit correspondre à celle que vous avez communiquée au laboratoire.</p>

      <p>Vous pouvez procéder aux vérifications suivantes :</p>
      <ol>
        <li>Vérifiez qu&apos;il n&apos;y a pas d&apos;erreur dans la saisie de la date de naissance en question.</li>
        <li>Vérifiez que le format de la date de naissance du patient est bien respecté. Le format est le suivant : JJ/MM/AAAA - JJ représente le jour de l&apos;année (05 si vous êtes né le 5ème jour du mois), MM représente le mois de l&apos;année et AAAA l&apos;année sur quatre chiffres.</li>
        <li>Assurez-vous que c&apos;est bien la date de naissance communiquée lors de votre enregistrement au laboratoire.</li>
        <li>En cas de besoin, vous pouvez vous rendre directement sur place dans votre laboratoire de biologie médicale (attention : votre laboratoire ne pourra pas vous communiquer cette information par téléphone et devra avoir vérifié préalablement votre identité).</li>
      </ol>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_access_features",
    "subject": "Création et gestion du compte",
    "subsubject": "Compte Kiro",
    "title": "Comment accéder à l'ensemble des fonctionnalités de la plateforme Kiro ?",
    "content": <Fragment>
      <p>Certaines fonctionnalités ne sont disponibles qu&apos;une fois connecté à votre compte Kiro de manière sécurisée. Pour accéder à l&apos;ensemble des fonctionnalités proposées par la plateforme Kiro, nous vous invitons à créer un compte.</p>
      <p>Vous pourrez alors par exemple consulter les résultats directement depuis votre compte en <a className={"legacy-a"} href='/login' target='blank' rel='noopener noreferrer'>cliquant ici</a> sans avoir à vous réinscrire, de manière simple et sécurisée.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_create_account",
    "subject": "Création et gestion du compte",
    "subsubject": "Compte Kiro",
    "title": "Comment créer un compte Kiro ?",
    "content": <Fragment>
      <p>L’accès complet à la plateforme Kiro et à ses fonctionnalités nécessite la création d’un compte utilisateur. Pour cela, vous devez disposer d’une adresse e-mail valide, communiquée à votre laboratoire, et d’un mot de passe. Une fois vos résultats disponibles sur la plateforme, vous serez invité à suivre la procédure de création de compte et à renseigner les informations dans le formulaire prévu à cet effet, puis à cliquer sur le bouton « S’inscrire ».</p>
      <p>Lors de la première connexion, nous vous invitons à renseigner votre nom, votre prénom, votre date de naissance, ainsi qu’un mot de passe sécurisé conforme aux recommandations de la CNIL. Ces recommandations sont <a className={"legacy-a"} href='https://www.cnil.fr/fr/authentification-par-mot-de-passe-les-mesures-de-securite-elementaires' target='blank' rel='noopener noreferrer'>indiquées ici</a>. Il est important que toutes les informations communiquées soient complètes et vraies en vue de la bonne utilisation de la solution et de ses services.</p>
      <p>Il est essentiel que vous puissiez fournir et maintenir des informations vraies, complètes, exactes et à jour. Nous vous invitons également à faire toutes vérifications qui semblent nécessaires ou opportunes à ce titre avant de procéder à l’utilisation de la plateforme. Vous vous engagez par exemple à ne pas créer une fausse identité de nature à induire quiconque en erreur, à ne pas usurper l’identité ou la fonction d’une autre personne physique, ni à masquer ou modifier votre âge.</p>
      <p>Notez qu’il est possible d’associer plusieurs patients dont vous avez la charge pour un même compte Kiro (pour vous et vos enfants par exemple). Pour ce faire, il vous suffit de renseigner la même adresse e-mail au secrétariat du laboratoire de biologie médicale pour les différents patients que vous souhaitez rassembler sur le même compte.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_create_password",
    "subject": "Création et gestion du compte",
    "subsubject": "Compte Kiro",
    "title": "Comment choisir et conserver mon mot de passe ?",
    "content": <Fragment>
      <p>La connexion au compte Kiro repose sur un système d’authentification sécurisée. L’accès à certaines fonctionnalités s’effectue grâce à un identifiant et un mot de passe conforme aux recommandations de la CNIL <a className={"legacy-a"} href='https://www.cnil.fr/fr/authentification-par-mot-de-passe-les-mesures-de-securite-elementaires' target='blank' rel='noopener noreferrer'>disponibles ici</a>.</p>
      <p>Pour choisir votre mot de passe, vous pouvez vous reporter aux <a className={"legacy-a"} href='https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-un-bon-mot-de-passe' target='blank' rel='noopener noreferrer'>conseils de la CNIL</a> et utiliser son <a href='https://www.cnil.fr/fr/generer-un-mot-de-passe-solide' target='blank' rel='noopener noreferrer'>générateur de mots de passe</a>.</p>
      <p>Votre identifiant ainsi que votre mot de passe sont strictement personnels et confidentiels. Il est donc important que vous puissiez les conserver de manière secrète et sécurisée et que vous ne les divulguiez pas à des tiers. Pour vous simplifier la vie, vous pouvez également utiliser un gestionnaire de mots de passe. Des informations sont notamment <a className={"legacy-a"} href='https://www.cnil.fr/fr/5-arguments-pour-adopter-le-gestionnaire-de-mots-de-passe' target='blank' rel='noopener noreferrer'>disponibles ici</a>.</p>
      <p>En cas de perte, d&apos;oubli ou de vol, il vous faudra renouveler votre mot de passe directement depuis votre compte utilisateur, ou en cliquant sur « Mot de passe oublié » situé sous le formulaire de saisie de vos identifiants de connexion.</p>
      <p> Nous vous recommandons par ailleurs vivement de renouveler votre mot de passe tous les six mois à partir de votre première connexion, en cliquant sur le bouton « Mot de passe oublié » situé sous le formulaire de saisie de vos identifiants de connexion.</p>
      <p>Dans le cas où Kiro serait amené à vous envoyer un mot de passe temporaire, il vous faudra le modifier dès votre première connexion à la solution.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_change_password",
    "subject": "Création et gestion du compte",
    "subsubject": "Compte Kiro",
    "title": "Comment modifier le mot de passe de mon compte Kiro ?",
    "content": <Fragment>
      <p>Cette fonctionnalité n&apos;est disponible que pour les patients ayant créé un compte Kiro.</p>
      <p>Dans l&apos;espace personnel de votre compte Kiro, nous vous invitons à cliquer sur votre prénom en haut à gauche de la page d&apos;accueil. Dans le menu déroulant, rendez-vous sur &quot;mon compte&quot;.</p>
      <p>En cliquant sur &quot;modifier&quot;, vous pourrez modifier votre mot de passe. N&apos;oubliez pas de le valider afin que la modification soit prise en compte. Si vous souhaitez modifier votre adresse e-mail ou toute autre information, nous vous invitons à vous rapprocher du secrétariat de votre laboratoire de biologie médicale.</p>
      </Fragment>,
    "images": [
      {
        "path": "/static/ue/faq/how_change_password/1_Aller_dans_mon_compte.png",
        "alt": "connexion"
      },
      {
        "path": "/static/ue/faq/how_change_password/2_modifier.png",
        "alt": "aller-dans-compte"
      },
      {
        "path": "/static/ue/faq/how_change_password/3_modifier_mon_mdp.png",
        "alt": "modif-mot-de-passe"
      }
    ]
  },
  {
    "validated": true,
    "name": "how_forget_password",
    "subject": "Création et gestion du compte",
    "subsubject": "Compte Kiro",
    "title": "Comment faire si j'ai oublié le mot de passe de mon compte Kiro ?",
    "content": <Fragment>
      <p>Cette fonctionnalité n&apos;est disponible que pour les patients ayant créé un compte Kiro.</p>
      <p>Sur la <a className={"legacy-a"} href='/login' target='blank' rel='noopener noreferrer'>page de connexion</a>, cliquez sur « Mot de passe oublié ? ». Vous pouvez également vous y rendre directement en <a href='/user/forgot-password' target='blank' rel='noopener noreferrer'>cliquant ici</a>.</p>
      <p>Nous vous invitons ensuite à indiquer l’adresse e-mail associée à votre compte. Vous allez recevoir un e-mail, dans lequel nous vous invitons à cliquer sur le lien contenu dans ce dernier afin que vous puissiez déterminer votre nouveau mot de passe. N&apos;oubliez pas de le valider afin que ce changement soit pris en compte.</p>
      </Fragment>,
    "images": [
      {
        "path": "/static/ue/faq/how_forget_password/1_mdp_oublie.png",
        "alt": "mot-de-passe-oublie"
      },
      {
        "path": "/static/ue/faq/how_forget_password/2_email_mdp_oublie.png",
        "alt": "e-mail-mot-de-passe-oublie"
      }
    ]
  },
  {
    "validated": true,
    "name": "how_login_logout",
    "subject": "Création et gestion du compte",
    "subsubject": "Compte Kiro",
    "title": "Comment me connecter et me déconnecter de mon compte Kiro ?",
    "content": <Fragment>
      <p>Cette fonctionnalité n&apos;est disponible que pour les patients ayant créé un compte Kiro.</p>
      <p>Lorsque les résultats de biologie médicale sont disponibles sur Kiro, vous recevez sur l&apos;adresse mail que vous avez renseignée au secrétariat de votre laboratoire, un lien pour les consulter. Nous vous invitons à cliquer sur &quot;accéder au compte-rendu&quot;. Pour accéder à l&apos;espace personnel de votre compte Kiro, renseignez votre adresse e-mail, et votre mot de passe.</p>
      <p>L&apos;adresse e-mail est celle que vous avez communiquée au secrétariat de votre laboratoire de biologie médicale, votre mot de passe est celui que vous avez renseigné lors de la création de votre compte Kiro. Si vous avez oublié votre mot de passe, nous vous invitons à vous rendre sur la question &quot;Comment faire si j&apos;ai oublié mon mot de passe ?&quot;.</p>
      <p>Vous pouvez également vous y connecter directement en <a className={"legacy-a"} href='/login' target='blank' rel='noopener noreferrer'>cliquant ici</a>.</p>
      <p>Pour vous déconnecter, cliquez sur votre prénom, en haut à droite de votre espace personnel. Dans le menu déroulant qui s&apos;affiche, cliquez sur &quot;Se déconnecter&quot;.</p>
      <p>S&apos;il ne s&apos;agit pas de votre ordinateur, utilisez une fenêtre de navigation privée pour vous connecter. Il est important de vous assurer qu’à l’issue de chaque session vous vous déconnectiez effectivement de la solution, en particulier lorsque vous y accédez à partir d’un ordinateur public.</p>
      </Fragment>,
    "images": [
      {
        "path": "/static/ue/faq/how_login/1_Lien_email.png",
        "alt": "lien-e-mail"
      },
      {
        "path": "/static/ue/faq/how_login/2_Se_connecter.png",
        "alt": "se-connecter"
      },
      {
        "path": "/static/ue/faq/how_login/3_se_deconnecter.png",
        "alt": "se-deconnecter"
      }
    ]
  },
  {
    "validated": true,
    "name": "how_delete_account",
    "subject": "Création et gestion du compte",
    "subsubject": "Compte Kiro",
    "title": "Comment supprimer mon compte Kiro ?",
    "content": <Fragment>
      <p>Cette fonctionnalité n&apos;est disponible que pour les patients ayant créé un compte Kiro.</p>
      <p>Si vous souhaitez supprimer votre compte Kiro, contactez-nous via l&apos;adresse e-mail suivante : <EmailLink address={CONFIG.mailAddresses.patients}/>.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "what_report_icons",
    "subject": "Documents",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "Que signifient les icones à côté de mes résultats d'analyses de biologie médicale ?",
    "content": <Fragment>
      <p>Lorsque vous accédez aux résultats d&apos;analyses de biologie médicale sur Kiro, vous avez à votre disposition plusieurs fonctionnalités pour les explorer.</p>
      <ul>
        <li>Vous retrouvez en haut vos informations personnelles, la date du prélèvement, votre numéro de dossier, ainsi que le nom du médecin qui vous a prescrit l&apos;analyse dont vous consultez les résultats. En dessous, vous avez la possibilité de visualiser uniquement les résultats dont les valeurs sont en dehors des valeurs de référence du laboratoire de biologie en cliquant simplement sur l&apos;interrupteur à glissière. </li>
        <li>Les analyses sont ensuite classées par &quot;familles&quot; d&apos;analyses (des grandes catégories). Le bouton à droite du bandeau de couleur vous permet d&apos;accéder aux informations concernant l&apos;examen en question. Attention toutefois, ces informations ne sont pas délivrées comme un conseil ou avis médical et elles ne doivent donc en aucun cas être prises comme tels. Si vous disposez d&apos;un compte Kiro, vous pouvez également consulter la valeur de vos précédents résultats et d&apos;une représentation de l&apos;historique sous forme de graphique.</li>
        <li>Les informations fournies sur la plateforme Kiro n’ont pas vocation à se substituer à l’avis du professionnel de santé qui vous suit ni aux consultations médicales. Elles ne constituent ni un avis ni un conseil médical ni une consultation. Seuls les professionnels de santé compétents sont habilités à prescrire et/ou dispenser les traitements et/ou examens de biologie médicale appropriés et en déterminer leur fréquence et durée. Les informations disponibles sont donc fournies à titres purement informatif et indicatif.</li>
        <li>Si vous souhaitez télécharger votre compte-rendu au format PDF tel qu&apos;il vous est transmis par votre laboratoire, nous vous invitons à vous rendre sur la question &quot;Comment télécharger mon compte-rendu de biologie médicale au format PDF ?&quot;.</li>
      </ul>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_previous_reports",
    "subject": "Documents",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "Comment retrouver mes précédents résultats d'analyses de biologie médicale et accéder à mon historique ?",
    "content": <Fragment>
      <p>Cette fonctionnalité n&apos;est disponible que pour les patients ayant créé un compte Kiro.</p>
      <p>Lorsque vous disposez d&apos;un compte Kiro, vous pouvez consulter vos résultats d&apos;analyses de biologie médicale passés disponibles au laboratoire de biologie, ainsi que ceux des patients associés à votre compte (ceux de vos enfants par exemple). Pour les consulter, connectez-vous à votre compte. Depuis la page d&apos;accueil, vous pouvez visualiser ces résultats d&apos;analyses, identifiés par le nom et prénom du patient. Vous avez ensuite la possibilité de cliquer sur les résultats que vous souhaitez consulter.</p>
      <p>Si vous n&apos;arrivez pas à accéder à l&apos;historique de vos résultats alors que vous avez créé un compte, contactez-nous à l&apos;adresse suivante : <EmailLink address={CONFIG.mailAddresses.patients}/>.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_download_pdf",
    "subject": "Documents",
    "subsubject": "Mes résultats de biologie médicale",
    "title": "Comment télécharger mon compte-rendu de biologie médicale au format PDF ?",
    "content": <Fragment>
      <p>Pour télécharger le compte-rendu de biologie médicale issu du laboratoire, il vous suffit de cliquer sur le bouton &quot;Télécharger mon compte-rendu&quot;. Ce compte-rendu se télécharge sur votre ordinateur, smartphone ou tablette en format PDF et/ou HPRIM.</p>
      <p>Il est important de télécharger ce compte-rendu d’origine à chaque fois, en particulier si l&apos;état de santé du patient concerné par les résultats le nécessite. Le compte-rendu au format PDF et/ou HPRIM délivré par votre laboratoire de biologie médicale fait foi en toutes circonstances.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "how_contact_support",
    "subject": "Contact",
    "subsubject": "Contact",
    "title": "Je ne trouve pas de réponse ou j'ai une autre question : comment contacter les équipes Kiro ?",
    "content": <Fragment>
      <p>Si vous ne trouvez pas de réponse à vos questions ici ou que vous avez d&apos;autres interrogations, vous pouvez contacter directement votre laboratoire par téléphone ou envoyer un e-mail à l&apos;adresse suivante : <EmailLink address={CONFIG.mailAddresses.patients}/>. Nous ferons notre possible pour vous répondre dans les meilleurs délais.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "what_fraudulent_message",
    "subject": "Contact",
    "subsubject": "Notifications",
    "title": "Que faire en cas de réception d’un message frauduleux ?",
    "content": <Fragment>
      <p>Si vous ne reconnaissez pas les informations contenues dans un e-mail prétenduement envoyé de la part de Kiro, il peut s&apos;agir d’une tentative d’hameçonnage (ou tentative de &quot;phishing&quot;) et non d’un véritable message envoyé par Kiro. Vérifiez toujours que l&apos;adresse e-mail d&apos;envoi correspond à celle de Kiro.</p>
      <p>Pour vous aider, sachez que Kiro ne vous communique pas vos résultats de biologie par e-mail, ni ne vous demande d&apos;envoyer par e-mail des informations personnelles y compris relatives à votre santé.</p>
      <p>Ne communiquez aucune information confidentielle (en particulier vos identifiants, mot de passe ou coordonnées bancaires) par e-mail et sachez que nous ne vous les demanderons jamais, ni par e-mail ni par téléphone.</p>
      <p>Nous vous recommendons d&apos;être particulièrement vigilant, et de ne pas cliquer sur le contenu de l&apos;e-mail si vous pensez qu&apos;il est frauduleux. En cas de doute, nous vous invitons à nous contacter à l&apos;adresse e-mail suivante : <EmailLink address={CONFIG.mailAddresses.info}/>.</p>
      <p>De même, si vous avez reçu un e-mail de Kiro et que vous n&apos;êtes pas à l&apos;origine de la demande d&apos;e-mail ou que vous n&apos;avez pas réalisé d&apos;analyses de biologie médicale récemment, contactez-nous à <EmailLink address={CONFIG.mailAddresses.info}/>.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "what_need_access_kiro",
    "subject": "Autres",
    "subsubject": "Divers",
    "title": "De quoi ai-je besoin pour accéder à Kiro ?",
    "content": <Fragment>
      <p>L’accès à la solution nécessite de disposer d’un matériel informatique (PC, MAC ou tablette), d’une connexion internet haut débit et d’un navigateur web.</p>
      <p>Nous vous recommandons d&apos;utiliser le navigateur Google Chrome. Afin d&apos;optimiser votre navigation, nous vous suggérons d&apos;utiliser la version la plus récente du navigateur.</p>
      </Fragment>
  },
  {
    "validated": true,
    "name": "kiro_updates",
    "subject": "Autres",
    "subsubject": "Divers",
    "title": "Est-il nécessaire de mettre la plateforme Kiro à jour ?",
    "content": <Fragment>
      <p>La plateforme Kiro est accessible en ligne. Il n&apos;y a aucun logiciel à installer, pour votre plus grande simplicité et facilité. Les mises à jour sont automatiques.</p>
      </Fragment>
  },
]

export default FaqData;