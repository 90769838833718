import React, { FC } from 'react';
import classNames from 'classnames';
import styles from 'components/designSystem/containers/Hoverable.module.scss';

interface HoverableProps {
    isClickable?:boolean
}

const Hoverable: FC<HoverableProps> = (
    {
       isClickable = false,
       ...props
    }

): JSX.Element => {

    return <div className={
        classNames(
            styles.hoverable,
            {
                [styles.clickable]: isClickable ,
            }
        )
    }>
        {props.children}
    </div>
}

export default Hoverable;