import { buildKey, defineAxisDataType } from 'components/commonDesign/charts/builders/utils';
import { DEFAULT_CHART_HEIGHT } from 'components/commonDesign/charts/constants';
import { Colors } from 'components/legacyDesignSystem/branding/constants';
import { buildAxis } from 'components/legacyDesignSystem/components/charts/builders/Axis';
import { buildBar } from 'components/legacyDesignSystem/components/charts/builders/Bar';
import { buildGrid } from 'components/legacyDesignSystem/components/charts/builders/Grid';
import { AxisDirection } from 'components/legacyDesignSystem/components/charts/builders/model';
import { renderToolip } from 'components/legacyDesignSystem/components/charts/builders/Tooltip';
import { ChartContainer } from 'components/legacyDesignSystem/components/charts/ChartContainer';
import Container, {
    ContainerProfile,
    FlexSpacing,
    MarginType,
    PaddingType,
} from 'components/legacyDesignSystem/components/Container';
import { IndicatorDot } from 'components/legacyDesignSystem/components/Indicator';
import MultiRangeSlider from 'components/legacyDesignSystem/components/slider/MultiRangeSlider';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import { TransDomain } from 'components/pages/common/MainComponent';
import { cast } from 'core/utils/Typed';
import {
    ChartFilter,
    ChartFilterDataKeySelector,
    ChartFilterDistributionXFilter,
    ChartFilterType,
    ChartItem,
    ChartItemType,
} from 'models/charts/charts';

import {
    DistributionPayload,
    ExtendedDistributionPayload,
    FormattedTooltipProps,
} from 'models/charts/charts/Distribution';
import { ChartItemStackedBar } from 'models/charts/items/StackedBar';
import { ValueFormatterInterface } from 'models/charts/models';
import { toAPIText, toLocalTransKey, TranslationMethod } from 'models/common/message';
import React, { Fragment, ReactNode } from 'react';
import { BarChart, Tooltip, TooltipProps } from 'recharts';
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { Margin } from "recharts/types/util/types";

// function computeValueExtremum(items: ChartItem[]): void {
//     const allPoints: number[] = [0]
//     items.forEach(
//         (item) => {
//             if (item.type === ChartItemType.DISTRIBUTION) {
//                 Object.values(cast<ChartItemStackedBar>(item.payload).data).forEach(
//                     (value) => {
//                         allPoints.push(
//                             Object.values(value).reduce((pre,curr)=>pre+curr,0)
//                         )
//                     }
//                 )
//
//             }
//         }
//     )
//
//     return 1.1 * _.min(allPoints)
// }

type BarPoint = Record<string, unknown>;
const X_AXIS = '__x';

function buildStackedBarItem(
    prefix: string,
    payload: ChartItemStackedBar,
    acceptedKeys: string[] | undefined,
): BarPoint[] {
    return Object.entries(payload.data).map(([k, v]) => {
        const i: BarPoint = {};
        i[X_AXIS] = parseFloat(k);

        Object.entries(v).forEach((ki) => {
            const key = buildKey(prefix, ki[0]);
            if (acceptedKeys !== undefined && !acceptedKeys.includes(key)) {
                return;
            }

            i[key] = ki[1];
        });
        return i;
    });
}

function buildData(items: ChartItem[], filters?: ChartFilter[]): BarPoint[] {
    let results: BarPoint[] = [];

    let acceptedKeys: string[] | undefined = undefined;

    if (filters) {
        filters.forEach((f) => {
            if (f.type === ChartFilterType.DATA_KEY_SELECTOR) {
                if (acceptedKeys === undefined) acceptedKeys = [];
                cast<string[]>(acceptedKeys).push(...cast<ChartFilterDataKeySelector>(f.payload).keys);
            }
        });
    }

    items.forEach((i) => {
        switch (i.type) {
            case ChartItemType.STACKED_BAR:
                results = [
                    ...results,
                    ...buildStackedBarItem(i.unique_id, cast<ChartItemStackedBar>(i.payload), acceptedKeys),
                ];
                break;
        }
    });

    return results;
}

function buildDistributionItem(
    prefix: string,
    payload: DistributionPayload,
    data: BarPoint[],
    minAndMaxXSelector: [number | undefined, number | undefined],
): JSX.Element[] {
    return payload.y_splitters.map(
        (splitter): JSX.Element =>
            buildBar(
                buildKey(prefix, splitter.key),
                splitter.stack_id,
                splitter.color_type,
                data,
                (entry): boolean => {
                    const xValue = entry[X_AXIS];
                    return (
                        typeof xValue === 'number' &&
                        ((minAndMaxXSelector[0] !== undefined && xValue < minAndMaxXSelector[0]) ||
                            (minAndMaxXSelector[1] !== undefined && xValue > minAndMaxXSelector[1]))
                    );
                },
            ),
    );
}

function getSelectorMinAndMaxX(filters?: ChartFilter[]): [number | undefined, number | undefined] {
    if (filters === undefined) {
        return [undefined, undefined];
    }

    let min: number | undefined = undefined;
    let max: number | undefined = undefined;

    filters.forEach((f) => {
        if (f.type === ChartFilterType.X_FILTER) {
            const pl = cast<ChartFilterDistributionXFilter>(f.payload);
            if (pl.min != null) {
                min = min == null ? pl.min : Math.max(pl.min, min);
            }

            if (pl.max != null) {
                max = max == null ? pl.max : Math.min(pl.max, max);
            }
        }
    });

    return [min, max];
}

function getMinAndMaxX(items: ChartItem[]): [number | undefined, number | undefined] {
    let min: number | undefined = undefined;
    let max: number | undefined = undefined;
    items
        .filter((i) => i.type === ChartItemType.STACKED_BAR)
        .forEach((i) =>
            Object.keys(cast<ChartItemStackedBar>(i.payload).data).forEach(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (xVal: number) => {
                    if (min === undefined || Number(min) > Number(xVal)) {
                        min = Number(xVal);
                    }
                    if (max === undefined || Number(max) < Number(xVal)) {
                        max = Number(xVal);
                    }
                },
            ),
        );

    return [min, max];
}

function FormattedTooltip(props: FormattedTooltipProps): JSX.Element | null {
    // This FormattedTooltip needs to be refactored
    // translation and formation of content is not done homogeneously (sometime inside, sometime outside (y_splitters)

    const { label, payload, y_splitters, translation } = props;
    if (!payload) return null;

    return renderToolip(
        <>
            <Container
                profiles={[
                    ContainerProfile.ALIGN_BASELINE,
                    ContainerProfile.SPACE_BETWEEN,
                    {
                        type: MarginType.MB,
                        value: 1,
                    },
                ]}
            >
                <Typography
                    weight={TypographyWeight.REGULAR}
                    variant={TypographyVariant.CAPTION}
                    color={Colors.NEUTRALS_DARK_BLUE_GREY}
                >
                    {label}
                </Typography>
            </Container>

            {payload?.map((item, i) => {
                const splitter = y_splitters.find((splitter) => {
                    if (item.dataKey && typeof item.dataKey === 'string') {
                        // As we don't have access to dataKey prefix (chart item unique id)
                        // we have to match the key like that
                        return item.dataKey.slice(-1*splitter.key.length-1) === ":" + splitter.key
                    }
                });

                return (
                    <Container
                        key={i}
                        profiles={[
                            ContainerProfile.ALIGN_BASELINE,
                            {
                                type: MarginType.MB,
                                value: 2,
                            },
                        ]}
                    >
                        {splitter && (
                            <IndicatorDot
                                key={splitter.key}
                                severity={splitter.color_type}
                                profiles={[{ type: MarginType.MR, value: 2 }]}
                            />
                        )}
                        <Typography
                            weight={TypographyWeight.REGULAR}
                            variant={TypographyVariant.SMALL}
                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                        >
                            {splitter && translation(splitter.name)} : {item.value}
                        </Typography>
                    </Container>
                );
            })}
        </>,
    );
}

export function buildDistribution(
    items: ChartItem[],
    payload: ExtendedDistributionPayload | null,
    translation: TranslationMethod,
    margin?: Partial<Margin>,
    filters?: ChartFilter[],
    valueFormatter?: ValueFormatterInterface,
): JSX.Element {
    if (!payload) {
        return <></>
    }

    const onXSelectorChange = payload.onXSelectorChange;
    const minAndMaxX = getMinAndMaxX(items);
    const minAndMaxXSelector = getSelectorMinAndMaxX(filters);
    const globalMin = minAndMaxX[0];
    const globalMax = minAndMaxX[1];
    const data = buildData(items, filters);
    const withTooltip = payload.with_tooltip;

    return (
        <Fragment>
            {payload.y_splitters.filter((s) => s.in_legend === undefined || s.in_legend).length > 0 && (
                <Container
                    profiles={[
                        ContainerProfile.JUSTIFY_CONTENT_END,

                        { type: FlexSpacing.CG, value: 4 },
                        { type: MarginType.MR, value: 4 },
                        { type: MarginType.MB, value: 2 },
                    ]}
                >
                    {payload.y_splitters.map((y_splitter) => (
                        <Container key={y_splitter.key} profiles={[ContainerProfile.ALIGN_CENTER]}>
                            <IndicatorDot
                                key={y_splitter.key}
                                severity={y_splitter.color_type}
                                profiles={[{ type: MarginType.MR, value: 2 }]}
                            />
                            <Typography>{translation(y_splitter.name)}</Typography>
                        </Container>
                    ))}
                </Container>
            )}
            <ChartContainer height={DEFAULT_CHART_HEIGHT}>
                <BarChart data={data} margin={margin}>
                    {withTooltip && (
                        <Tooltip
                            cursor={false}
                            content={
                                (props: TooltipProps<ValueType, NameType>): ReactNode => {
                                    let realLabel: ReactNode = props.label


                                    if (props.active) {
                                        const label = payload.x_ticks && props.label != undefined ? payload.x_ticks[props.label] : undefined
                                        realLabel = label ? translation(label.tooltip_label) : props.label
                                    }

                                    return <FormattedTooltip
                                        label={realLabel}
                                        y_splitters={payload.y_splitters}
                                        payload={props.payload}
                                        translation={translation}
                                    />

                                }
                            }
                        />
                    )}
                    {buildGrid('grid')}
                    {buildAxis(
                        'xAxis',
                        AxisDirection.X,
                        defineAxisDataType(payload.x_axis.type),
                        X_AXIS,
                        undefined,
                        undefined,
                        valueFormatter,
                        payload.with_axis_labels
                            ? translation(toAPIText(toLocalTransKey(`axis.${payload.x_axis.type}`, TransDomain.GRAPH)))
                            : null,
                        payload.x_ticks,
                    )}
                    {buildAxis(
                        'yAxis',
                        AxisDirection.Y,
                        defineAxisDataType(payload.y_axis.type),
                        undefined,
                        undefined,
                        undefined,
                        valueFormatter,
                        payload.with_axis_labels
                            ? translation(toAPIText(toLocalTransKey(`axis.${payload.y_axis.type}`, TransDomain.GRAPH)))
                            : null,
                        undefined,
                    )}

                    {items.map((i) => {
                        switch (i.type) {
                            case ChartItemType.STACKED_BAR:
                                return buildDistributionItem(i.unique_id, payload, data, minAndMaxXSelector);
                        }
                    })}
                </BarChart>
            </ChartContainer>
            {payload && payload.with_x_range_selector && globalMin !== undefined && globalMax !== undefined && onXSelectorChange && (
                <Container profiles={[{ type: PaddingType.PL, value: 12 }]}>
                    <MultiRangeSlider
                        min={globalMin}
                        max={globalMax}
                        defaultMin={minAndMaxXSelector[0]}
                        defaultMax={minAndMaxXSelector[1]}
                        onChange={(min, max): void => onXSelectorChange(min, max, globalMin, globalMax)}
                    />
                </Container>
            )}
        </Fragment>
    );
}

export const _private__test = {
    getMinAndMaxX,
    getSelectorMinAndMaxX,
    buildData,
};
