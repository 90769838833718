import React from 'react';

const Foetus = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M16 3C12.9688 3 10.1837 4.1052 7.97499 5.85634C8.48564 6.57475 8.89718 7.3649 9.1817 8.21838C9.57175 9.38859 10.259 10.4472 11.154 11.2914C11.5671 10.5391 12.3829 10.047 13.2946 10.1026C13.7175 10.1298 14.1013 10.2781 14.4286 10.5008C14.6834 9.95942 15.0146 9.44485 15.4615 8.99798C17.5391 6.9196 20.9229 6.9196 23.002 8.99798C25.0811 11.0771 25.0811 14.4594 23.002 16.5385C22.4664 17.0741 21.8237 17.4877 21.12 17.754C20.9623 19.7364 20.1121 21.5827 18.6928 23.002L17.9392 23.7563C15.8599 25.8356 12.4771 25.8347 10.3987 23.7563C8.95857 22.3169 8.65134 20.5384 8.75397 19.2054C8.12241 19.2522 7.47677 19.0239 6.99471 18.5426C6.10431 17.6507 6.10431 16.2009 6.99395 15.3097C8.10854 14.1945 9.66387 13.8224 11.0025 14.2179C10.9334 13.8244 10.8923 13.4273 10.8801 13.0252C9.42957 11.934 8.31116 10.4233 7.73708 8.7005C7.51811 8.0442 7.20093 7.43864 6.82053 6.87766C4.49256 9.22004 3 12.4443 3 16C3 23.1404 8.85965 29 16 29C23.1404 29 29 23.1404 29 16C29 8.85965 23.1404 3 16 3V3Z" />
            <path d="M8.12838 16.331C7.77505 16.6836 7.77505 17.1679 8.07258 17.4647C8.36939 17.7607 8.85288 17.7615 9.1497 17.4639L9.31043 17.3033C9.64922 16.9648 10.2108 17.0114 10.4892 17.4012C10.679 17.6669 10.6819 18.0232 10.4965 18.292L10.4827 18.3119C10.3399 18.5477 9.68529 20.8887 11.4758 22.6792C12.9605 24.1639 15.3774 24.1639 16.8621 22.6792L17.6156 21.9248C18.884 20.6565 19.5966 18.971 19.6226 17.179L19.6307 16.5988C19.9251 16.4918 20.9726 16.412 21.9248 15.4614C23.4096 13.9767 23.4096 11.5598 21.9248 10.0751C20.4408 8.59036 18.024 8.59036 16.5385 10.0751C15.0538 11.5598 15.0538 13.9767 16.5385 15.4614C16.7222 15.6451 16.9212 15.8078 17.1342 15.9486C17.4855 16.1807 17.6806 16.6186 17.5232 17.0091C17.3659 17.3992 16.9185 17.5928 16.5548 17.3815C16.1575 17.1508 15.7915 16.8686 15.4614 16.5385C14.3365 15.4136 13.8417 13.9087 13.9341 12.4337L13.9134 12.4324C13.9395 12.0128 13.6189 11.6498 13.1986 11.623C12.788 11.6126 12.4198 11.9176 12.3922 12.3372C12.2167 15.143 13.5254 15.901 13.6561 17.5226C13.7254 18.3827 13.5027 19.23 13.0345 19.9356C12.8019 20.2861 12.3118 20.283 12.0144 19.9855C11.7169 19.6881 11.7319 19.2079 11.9158 18.8295C12.0923 18.4662 12.1713 18.0583 12.1378 17.6453C11.982 15.7231 9.64933 14.8101 8.12838 16.331Z" />
        </svg>
    )
}

export default Foetus;
