import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const FillThumbsUp = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.MEDIUM:
            return (
                <svg id={"m-fill-thumbs-up"} width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path id="icon" fillRule="evenodd" clipRule="evenodd"
                          d="M11.8094 1.83158L11.7557 3.2609C11.8094 1.83158 11.8094 1.83158 11.8094 1.83158L11.8131 1.83172L11.8181 1.83192L11.8318 1.83253L11.8738 1.83469C11.9082 1.8366 11.9551 1.83953 12.0132 1.84391C12.1293 1.85268 12.2911 1.86731 12.488 1.89138C12.8798 1.93925 13.4212 2.02576 14.024 2.18189C15.1805 2.48145 16.7892 3.0943 17.8848 4.40205C19.247 6.02815 19.4937 8.27006 19.4893 9.84506C19.4881 10.2962 19.4659 10.7206 19.4343 11.0991H26.4407C29.1203 11.0991 31.1444 13.5279 30.6614 16.1636L28.7394 26.6516C28.3344 28.8617 26.2931 30.3851 24.0592 30.1445L7.65278 28.3775H2.69693C1.90698 28.3775 1.2666 27.7371 1.2666 26.9472V14.5891C1.2666 13.7992 1.90698 13.1588 2.69693 13.1588H7.38495L10.3254 11.6545V3.2609C10.3254 2.87222 10.4835 2.50029 10.7635 2.23067C11.0434 1.96105 11.421 1.81697 11.8094 1.83158ZM6.29924 16.0194H4.12726V25.5168H6.29924V16.0194Z"
                          fill="currentColor"
                    />
                </svg>
            )
        default:
            return <div/>
    }
}