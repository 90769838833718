import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Equal = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 5.25C4.08579 5.25 3.75 5.58579 3.75 6C3.75 6.41421 4.08579 6.75 4.5 6.75H11.5C11.9142 6.75 12.25 6.41421 12.25 6C12.25 5.58579 11.9142 5.25 11.5 5.25H4.5Z" fill="currentColor"/>
                <path d="M4.5 9.25C4.08579 9.25 3.75 9.58579 3.75 10C3.75 10.4142 4.08579 10.75 4.5 10.75H11.5C11.9142 10.75 12.25 10.4142 12.25 10C12.25 9.58579 11.9142 9.25 11.5 9.25H4.5Z" fill="currentColor"/>
            </svg>

        )
    }

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 8C6.44772 8 6 8.44772 6 9C6 9.55228 6.44772 10 7 10H17C17.5523 10 18 9.55228 18 9C18 8.44772 17.5523 8 17 8H7Z" fill="currentColor"/>
            <path d="M7 14C6.44772 14 6 14.4477 6 15C6 15.5523 6.44772 16 7 16H17C17.5523 16 18 15.5523 18 15C18 14.4477 17.5523 14 17 14H7Z" fill="currentColor"/>
        </svg>

    )
}