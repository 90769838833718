import { APICallAction, OnSuccessPutType } from 'actions/ActionInterface';
import { ModuleAPICallAction } from "actions/modular/ModularActions";

export const API_BIOLOGIST_COMMUNICATION_MEANS_ACTION = 'API_BIOLOGIST_COMMUNICATION_MEANS_ACTION';
export const API_REFRESH_NOTIFICATION_BIOLOGIST = 'API_REFRESH_NOTIFICATION_BIOLOGIST';

export const FEEDBACK_IDENTIFIER_API_SEARCH_REPORT = 'FEEDBACK_IDENTIFIER_API_SEARCH_REPORT'


export type BiologistCommunicationMeansAction = APICallAction<{ token: string }>


export const biologistCommunicationMeansAction = (
    token: string,
    onSuccessPut?: OnSuccessPutType
): BiologistCommunicationMeansAction => {
    return {
        type: API_BIOLOGIST_COMMUNICATION_MEANS_ACTION,
        payload: {
            params: {
                token,
            },
            onSuccessPut
        },
    };
};


export type RefreshNotificationBiologistAction = ModuleAPICallAction<{ token: string }>


export const refreshNotificationBiologist = (
    token: string,
    moduleIdentifier: string
): RefreshNotificationBiologistAction => {
    return {
        type: API_REFRESH_NOTIFICATION_BIOLOGIST,
        payload: {
            params: {
                token,
            },
            moduleIdentifier
        },
    };
};
