import { APICallAction, callbackAction } from "actions/ActionInterface";
import { pushAPIFeedbackMessage } from "actions/common/APIMessaginActions";
import { modal, ModalType } from "actions/common/CommonActions";
import { signOut } from "actions/user/SignInSignUpActions";
import { BlockingPopUpComponentIdentifier } from "components/pages/common/FullPopList";
import { TransDomain, TransKey } from "components/pages/common/MainComponent";
import { ErrorMessageType } from "components/pages/errors/Page500";
import { APIFeedbackMessageType } from "models/common/APIMessaging";
import { Dispatch } from "react";
import { APIError, ErrorType } from "sagas/call/error";
import { KeyedMutator } from "swr/_internal";

const defaultSuffix = TransDomain.MESSAGES + '.default';

export interface ErrorForwardingConfig {
    fallbackToAPIFeedback?: 'all' | ErrorType[];
    // if error cannot be properly handled by generator, what behaviour is expected
    // default is raise
    ifNotSupported?: 'raise' | 'ignore'
}

function formatErrorMessage(error: APIError): TransKey {
    switch (error.type) {
        case ErrorType.ForbiddenAccess:
            return new TransKey(ErrorMessageType.FORBIDDEN, defaultSuffix);
        case ErrorType.BadGateway:
            return new TransKey(ErrorMessageType.BADGATEWAY, defaultSuffix);
        case ErrorType.OutdatedToken:
            return new TransKey(ErrorMessageType.OUTDATED_RESOURCE, defaultSuffix);
        default:
            return new TransKey(ErrorMessageType.SERVER, defaultSuffix);

    }

}
function* generator<Res>(
    callback?: KeyedMutator<Res>,
    error?: APIError,
            action?: APICallAction<unknown>,
    errorForwardingConfig?: ErrorForwardingConfig
): Generator {

    const fallbackToAPIFeedback = errorForwardingConfig?.fallbackToAPIFeedback
    if (error) {

        if (!error.type) {
            if (fallbackToAPIFeedback) {
                yield pushAPIFeedbackMessage(
                    action?.payload.feedbackIdentifier || null,
                    new TransKey(ErrorMessageType.SERVER,  TransDomain.MESSAGES + '.default'),
                    APIFeedbackMessageType.ERROR,
                    undefined,
                    action
                )
            }

            return
        }

        if (error.type === ErrorType.InvalidToken) {
            yield signOut(window.location.href.substr(window.location.origin.length))
            return null;
        }

        if (error.type === ErrorType.PinLocked) {
            yield modal(
                ModalType.BLOCKING,
                undefined,
                {
                    blockingModalPayload: {
                        identifier: BlockingPopUpComponentIdentifier.PIN,
                        props: {},
                        then: callback ? [callbackAction(() => callback())] : (
                            action ? [action] : undefined
                        )
                    }
                }
            )
            return null;

        }

        if (error.type === ErrorType.EULANotAccepted) {
            const eulaMessage = JSON.parse(error.message);

            yield modal(
                ModalType.BLOCKING,
                undefined,
                {
                    blockingModalPayload: {
                        identifier: BlockingPopUpComponentIdentifier.EULA,
                        props: {
                            token: eulaMessage.token,
                            validations: eulaMessage.validations,
                        },
                        then: !eulaMessage.previous_request_successful ? (
                                action ? [action] : undefined
                            )
                            :
                            (
                                action ? action.payload.onSuccessPut : undefined
                            )
                    }
                }
            )

            return null;
        }

        if (fallbackToAPIFeedback && (fallbackToAPIFeedback == 'all' || fallbackToAPIFeedback.includes(error.type))) {
            return yield pushAPIFeedbackMessage(
                action ? (action.payload.feedbackIdentifier || null) : null,
                formatErrorMessage(error),
                APIFeedbackMessageType.ERROR,
                undefined,
                action,
            )
        }

        if (errorForwardingConfig?.ifNotSupported != 'ignore') {
            throw error
        }
        return null
    }

    return null
}

export function* generatorErrorProcessor<Res>(
    dispatch: Dispatch<unknown>,
    callback?: KeyedMutator<Res>,
    error?: APIError,
    action?: APICallAction<unknown>,
    errorForwardingConfig?: ErrorForwardingConfig
): Generator {
    const gen = generator(
        callback,
        error,
        action,
        errorForwardingConfig
    )

    let next;
    while (!(next = gen.next()).done) {
        yield dispatch(
            next.value
        )
    }
}

export function errorProcessor<Res>(
    dispatch: Dispatch<unknown>,
    callback?: KeyedMutator<Res>,
    error?: APIError,
    errorForwardingConfig?: ErrorForwardingConfig
): () => void {
    return () => {
        const gen = generator(
            callback,
            error,
            undefined,
            errorForwardingConfig
        )

        let next;
        while (!(next = gen.next()).done) {
            dispatch(
                next.value
            )
        }
    }
}