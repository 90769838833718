import { AnyState } from 'core/store/Store';

export const FETCH_SUFFIX = '_FETCH';

export function fetchReducer(state: AnyState, name: string): AnyState {
    return state[name + FETCH_SUFFIX];
}

export function reducer<T = AnyState>(state: AnyState, name: string): T {
    return state[name];
}

export function formatFetchReducer(name: string): string {
    return name + FETCH_SUFFIX;
}
