import React, { ReactNode } from 'react';
import Icon from "components/designSystem/foundations/Icons";
import styles from './Checkbox.module.scss';
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";

// Indeterminate state not implemented. Not as input props.
// May help: https://www.robinwieruch.de/react-checkbox-indeterminate

export interface CheckboxProps {
    defaultChecked?: boolean,
    disabled?: boolean,
    label: ReactNode;

    // @ts-ignore
    onChange?: (event) => void;
    overrideChecked?: boolean
    error?: ReactNode
}

const Checkbox = (props: CheckboxProps): JSX.Element => (
    <div>
        <div className={styles.container}>
            <input
                type={'checkbox'}
                className={styles.checkbox}
                defaultChecked={props.defaultChecked}
                checked={props.overrideChecked}
                disabled={props.disabled}
                onChange={props.onChange}
            />
            <div className={styles.checkmark}>
                <Icon name={IconName.CHECKMARK} size={IconSize.EXTRA_SMALL}/>
            </div>
            <div className={styles.minus}>
                <Icon name={IconName.MINUS} size={IconSize.EXTRA_SMALL}/>
            </div>
            <div className={styles.label}>
                {props.label}
            </div>
        </div>
        {
            props.error && <div className={styles.error}>{props.error}</div>
        }
    </div>
)

export default Checkbox;
