export const removeMultipleSpaces = (sentence: string): string => {
    return sentence.replace(/\s\s+/g, ' ');
}

export const capitalizeFirstLetter = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export const capitalizeFirstLetters = (sentence: string): string => {
    const words = removeMultipleSpaces(sentence).split(' ')
    return words.reduce(
        (previousValue: string, currentValue: string): string => (
            previousValue + capitalizeFirstLetter(currentValue) + ' '
        ),
        '',
    ).slice(0,-1)
}
