import { Action, AnyAction, getActionPayload } from 'actions/ActionInterface';
import {
    CleanNavigation,
    NAVIGATION_ADD_ITEMS,
    NavigationAddItemsPayload, InitNavigationPayload, NAVIGATION_INIT, NAVIGATION_CLEAN
} from 'actions/common/ConnectedCarousel';

export interface NavigationPayload {
    items:  string[],
    // This action will be dispatched on carousel getting at the edge
    // to be effective, this action must lead to trigger navigationAddItems()
    fetchPreviousAction?: Action<unknown>,
    fetchNextAction?: Action<unknown>,
}

export interface ConnectedCarouselNavigatorReducer {
    payloadByIdentifiers: {[key: string]: NavigationPayload},
}

export const initialState: ConnectedCarouselNavigatorReducer = {
    payloadByIdentifiers: {}
};

export default function connectedCarouselNavigatorReducer(state = initialState, action: AnyAction): ConnectedCarouselNavigatorReducer {
    switch (action.type) {
        case NAVIGATION_INIT:

            const initAction = getActionPayload<InitNavigationPayload>(action);
            state.payloadByIdentifiers[initAction.identifier] = {
                items: initAction.defaultItems,
                fetchPreviousAction: initAction.fetchPreviousAction,
                fetchNextAction: initAction.fetchNextAction,
            };

            return {
                ...state,
            };

        case NAVIGATION_ADD_ITEMS:
            const navigationAddItems = getActionPayload<NavigationAddItemsPayload>(action);
            if (!state.payloadByIdentifiers[navigationAddItems.identifier]) {
                state.payloadByIdentifiers[navigationAddItems.identifier] = {
                    items: []
                }
            }

            state.payloadByIdentifiers[navigationAddItems.identifier].items = [
                ...state.payloadByIdentifiers[navigationAddItems.identifier].items,
                ...navigationAddItems.newItems
            ];

            return {
                ...state,
            };

        case NAVIGATION_CLEAN:
            const cleanAction = getActionPayload<CleanNavigation>(action);
            if (state.payloadByIdentifiers[cleanAction.identifier]) {
                delete state.payloadByIdentifiers[cleanAction.identifier]
            }

            return {
                ...state,
            };
        default:
            return state;
    }
}