import React from 'react';

const Burger = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <g clipPath="url(#clip0_1477_23155)">
                <path d="M16 4.57422C8.93286 4.57422 4.92235 7.81691 4.60522 13.7148H27.3948C27.0777 7.81691 23.0672 4.57422 16 4.57422Z" />
                <path d="M29 19.0469C29 19.4734 28.6649 19.8086 28.2383 19.8086H25.1406C23.9523 19.8086 23.4344 20.4027 23.0535 20.8292C22.7488 21.1645 22.5965 21.332 22.0938 21.332C21.591 21.332 21.4387 21.1645 21.134 20.8292C20.7531 20.4027 20.2352 19.8086 19.0469 19.8086C17.8585 19.8086 17.3406 20.4027 16.9598 20.8292C16.6551 21.1645 16.5027 21.332 16 21.332C15.4973 21.332 15.3449 21.1645 15.0402 20.8292C14.6594 20.4027 14.1415 19.8086 12.9531 19.8086C11.7648 19.8086 11.2469 20.4027 10.866 20.8292C10.5613 21.1645 10.409 21.332 9.90625 21.332C9.40352 21.332 9.25117 21.1645 8.94648 20.8292C8.56562 20.4027 8.04771 19.8086 6.85938 19.8086H3.76172C3.33511 19.8086 3 19.4734 3 19.0469C3 18.6203 3.33511 18.2852 3.76172 18.2852H28.2383C28.6649 18.2852 29 18.6203 29 19.0469Z" />
                <path d="M29 16C29 16.4265 28.6649 16.7617 28.2383 16.7617H3.76172C3.33511 16.7617 3 16.4265 3 16C3 15.5734 3.33511 15.2383 3.76172 15.2383H28.2383C28.6649 15.2383 29 15.5734 29 16Z" />
                <path d="M25.1406 21.332C24.6466 21.332 24.5172 21.4778 24.2018 21.8297C23.818 22.2529 23.2705 22.8555 22.0937 22.8555C20.9169 22.8555 20.3694 22.2529 20.0079 21.855C19.6701 21.4778 19.5407 21.332 19.0468 21.332C18.5528 21.332 18.4235 21.4778 18.1081 21.8297C17.7242 22.2529 17.1767 22.8555 15.9999 22.8555C14.8231 22.8555 14.2756 22.2529 13.9141 21.855C13.5764 21.4778 13.447 21.332 12.953 21.332C12.4591 21.332 12.3297 21.4778 12.0143 21.8297C11.6304 22.2529 11.083 22.8555 9.90617 22.8555C8.72937 22.8555 8.18189 22.2529 7.82038 21.855C7.48264 21.4778 7.35324 21.332 6.8593 21.332H4.65112C5.03569 24.7504 7.91011 27.4258 11.4296 27.4258H20.5702C24.0897 27.4258 26.9642 24.7504 27.3487 21.332H25.1406Z" />
            </g>
            <defs>
                <clipPath id="clip0_1477_23155">
                    <rect width="26" height="26" fill="white" transform="translate(3 3)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Burger;
