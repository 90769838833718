import React from 'react';

const HPRIM = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 14C19.243 14 17 11.757 17 9V4H10C7.243 4 5 6.243 5 9V23C5 25.757 7.243 28 10 28H22C24.757 28 27 25.757 27 23V14H22ZM10 13H12C12.552 13 13 13.4475 13 14C13 14.5525 12.552 15 12 15H10C9.448 15 9 14.5525 9 14C9 13.4475 9.448 13 10 13ZM18 23H10C9.448 23 9 22.5525 9 22C9 21.4475 9.448 21 10 21H18C18.552 21 19 21.4475 19 22C19 22.5525 18.552 23 18 23ZM18 19H10C9.448 19 9 18.5525 9 18C9 17.4475 9.448 17 10 17H18C18.552 17 19 17.4475 19 18C19 18.5525 18.552 19 18 19ZM19 9V4.586L26.414 12H22C20.3455 12 19 10.6545 19 9Z"/>
        </svg>
    )
}

export default HPRIM;
