import styles from 'components/designSystem/components/ratings/MultipleValuesRating.module.scss'
import { AlwaysInline, InlineSpacing } from "components/designSystem/containers/Inline";
import Icon from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import React, { useState } from "react";

interface IconData {
    name: IconName;
    color?: IconColor
}

// MultipleValueRating
interface RatingIconData {
    default: IconData;
    filled: IconData;
    size: IconSize;
}

interface RatingProps<T> {
    value: T;
    onClick: (value: T) => void;
    isSelected: boolean
    icons: RatingIconData
}

const Rating = <T,>(props: RatingProps<T>): JSX.Element => {
    const [isFocused, setFocused] = useState<boolean>(false);
    const isFilled = props.isSelected || isFocused
    const iconData = isFilled ? props.icons.filled : props.icons.default
    return <div
        className={styles.rating}
        onMouseEnter={(): void => setFocused(true)}
        onMouseLeave={(): void => setFocused(false)}
        onClick={(): void => props.onClick(props.value)}
    >
        <Icon size={props.icons.size} color={iconData.color} name={iconData.name}/>
    </div>
}

// MultipleValueRating group
export interface RatingValueData<T> {
    value: T;
    icons: RatingIconData
}

interface RatingGroupProps<T> {
    values: RatingValueData<T>[],
    rate: (rating: T) => void,
}

// Generic rating to to easily create multiple values rating group
export const MultipleValuesRating = <T,>(props: RatingGroupProps<T>): JSX.Element => {
    const [lastRating, setLastRating] = useState<T | undefined>(undefined);

    return <AlwaysInline spacing={InlineSpacing.X_LARGE}>
        {props.values.map(
            (ratingValueData: RatingValueData<T>, index: number) => {
                return <Rating<T>
                    key={index}
                    value={ratingValueData.value}
                    onClick={(value: T): void => {
                        setLastRating(value);
                        props.rate(value)
                    }}
                    isSelected={lastRating == ratingValueData.value}
                    icons={ratingValueData.icons}
                />
            }
        )}
    </AlwaysInline>
}