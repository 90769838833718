import {
    API_MESSAGE_FEEDBACK,
    API_MESSAGE_FEEDBACK_CLEAN,
    API_MESSAGE_FEEDBACK_REGISTER,
    CleanAPIFeedbackMessagePayload,
    PushAPIFeedbackMessagePayload,
    RegisterAPIFeedbackPayload,
} from 'actions/common/APIMessaginActions';
import { APIFeedbackMessage, APIFeedbackMessageFallbackCatcher } from 'models/common/APIMessaging';
import {
    DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE,
    DEFAULT_IDENTIFIER_MAIN_HEADER,
} from 'components/pages/common/APIFeedbackMessage';
import { AnyAction, getActionPayload } from 'actions/ActionInterface';
import { AnyState } from 'core/store/Store';

export const initialState: {
    registrations: string[];
    messages: APIFeedbackMessage[];
} = {
    registrations: [],
    messages: [],
};

export default function apiFeedbackMessagingReducers(state = initialState, action: AnyAction): AnyState {
    if (action.type === API_MESSAGE_FEEDBACK_REGISTER) {
        const registerPayload = getActionPayload<RegisterAPIFeedbackPayload>(action);
        state.registrations.push(registerPayload.identifier);

        const resetMessages = state.messages.filter(
            (message: APIFeedbackMessage) => message.identifier !== registerPayload.identifier,
        );

        return {
            ...state,
            registrations: state.registrations,
            messages: resetMessages,
        };
    } else if (action.type === API_MESSAGE_FEEDBACK) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const feedbackMessageActionPayload = getActionPayload<PushAPIFeedbackMessagePayload<any, any>>(action);

        const matchedRegistered = state.registrations.filter((identifier) => {
            return feedbackMessageActionPayload.identifier === identifier;
        });

        const baseMessage: APIFeedbackMessage = {
            message: feedbackMessageActionPayload.message,
            identifier: feedbackMessageActionPayload.identifier,
            type: feedbackMessageActionPayload.type,
            originAction: feedbackMessageActionPayload.originAction,
            extraData: feedbackMessageActionPayload.extraData,
            thenDispatch: feedbackMessageActionPayload.thenDispatch,
        };

        if (matchedRegistered.length === 0) {
            let alternativeIdentifier: string | null = null;
            switch (feedbackMessageActionPayload.fallbackCatcher) {
                case APIFeedbackMessageFallbackCatcher.MAIN_ERROR_PAGE:
                    alternativeIdentifier = DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE;
                    break;
                case APIFeedbackMessageFallbackCatcher.MAIN_HEADER:
                    alternativeIdentifier = DEFAULT_IDENTIFIER_MAIN_HEADER;
                    break;
                default:
                    alternativeIdentifier = DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE;
            }

            if (state.registrations.includes(alternativeIdentifier)) {
                baseMessage.identifier = alternativeIdentifier;
            } else {
                if (state.registrations.includes(DEFAULT_IDENTIFIER_MAIN_HEADER)) {
                    baseMessage.identifier = DEFAULT_IDENTIFIER_MAIN_HEADER;
                } else {
                    throw Error('Missing catcher');
                }
            }
        }

        return {
            ...state,
            messages: [...state.messages, baseMessage],
        };
    } else if (action.type === API_MESSAGE_FEEDBACK_CLEAN) {
        const cleanPayload = getActionPayload<CleanAPIFeedbackMessagePayload>(action);

        const resetMessages = state.messages.filter(
            (message: APIFeedbackMessage) => message.identifier !== cleanPayload.identifier,
        );

        return {
            ...state,
            messages: resetMessages,
        };
    } else if (action.type === '@@router/LOCATION_CHANGE') {
        return {
            ...state,
            messages: [],
        };
    } else {
        return state;
    }
}
