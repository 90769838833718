import { Action, APICallAction, OnSuccessPutType } from 'actions/ActionInterface';
import { redirect, RequestApiWithTokenAction, } from 'actions/common/CommonActions';
import { isRouteLegitimatePreviousPath, ROUTE_DEFAULT_MODULAR } from 'core/routing/Routes';
import { CPSRedirectionLocalData, EnforcedLocalConnectData } from 'models/user/AuthModels';

export const API_GET_PSC_URL = 'API_GET_CPS_URL';
export const API_INIT_CPS = 'API_INIT_CPS';
export const API_SIGN_IN = 'API_SIGN_IN';
export const API_SIGN_OUT = 'API_SIGN_OUT';
export const API_INIT_SSO = 'API_INIT_SSO';
export const API_CONFIRM_PIN = 'API_CONFIRM_PIN';
export const API_TWO_FA_SUBMIT_CODE = 'API_TWO_FA_SUBMIT_CODE';
export const API_VALIDATE_UELA_AND_SIGN_IN = 'API_VALIDATE_UELA_AND_SIGN_IN';
export const API_VALIDATE_UELA_AND_CONTINUE = 'API_VALIDATE_UELA_AND_CONTINUE';
export const API_RETRIEVE_AUTH_USER_DATA = 'API_RETRIEVE_AUTH_USER_DATA';
export const REGISTER_FORCED_CONNECTION_TOKEN = 'REGISTER_FORCED_CONNECTION_TOKEN';
export const API_FORCE_CONNECTION = 'API_FORCE_CONNECTION';
export const API_CREATE_ACCOUNT = 'API_CREATE_ACCOUNT';
export const REGISTER_CPS_REDIRECTION_LOCAL_DATA = 'REGISTER_CPS_REDIRECTION_LOCAL_DATA'
export const RETRIEVE_CPS_REDIRECTION_LOCAL_DATA = 'RETRIEVE_CPS_REDIRECTION_LOCAL_DATA'
export const DELETE_CPS_REDIRECTION_LOCAL_DATA = 'DELETE_CPS_REDIRECTION_LOCAL_DATA'

export interface SignInRequest {
    username: string;
    password: string;
    persist?: boolean;
}

export interface SignInActionInterface extends APICallAction<SignInRequest> {
    payload: {
        params: SignInRequest;
        feedbackIdentifier?: string;
        onSuccessPut?: OnSuccessPutType;
    };
}

export const hasOnSuccessPut = (action: APICallAction<unknown>): boolean => {
    return !!action.payload.onSuccessPut && action.payload.onSuccessPut.length > 0
}

export const signIn = (
    data: SignInRequest,
    feedbackIdentifier: string,
    previousOnSuccessPut?: OnSuccessPutType,
): SignInActionInterface => {
    return {
        type: API_SIGN_IN,
        payload: {
            params: data,
            feedbackIdentifier: feedbackIdentifier,
            onSuccessPut: previousOnSuccessPut,
        },
    };
};

export interface TwoFASubmitCodeRequest {
    code: string;
    token: string;
}

export interface TwoFASubmitAction extends APICallAction<TwoFASubmitCodeRequest> {
    payload: {
        params: TwoFASubmitCodeRequest;
        feedbackIdentifier: string;
        onSuccessPut?: OnSuccessPutType;
    };
}

export const twoFASubmitCode = (
    data: TwoFASubmitCodeRequest,
    feedbackIdentifier: string,
    onSuccessPut?: OnSuccessPutType,
): TwoFASubmitAction => {
    return {
        type: API_TWO_FA_SUBMIT_CODE,
        payload: {
            params: data,
            feedbackIdentifier: feedbackIdentifier,
            onSuccessPut: onSuccessPut,
        },
    };
};

export interface ConfirmPinRequest {
    pin: string;
}

export interface ConfirmPinActionInterface extends APICallAction<ConfirmPinRequest> {
    payload: {
        params: ConfirmPinRequest;
        onSuccessPut?: OnSuccessPutType;
        feedbackIdentifier?: string;
    };
}

export const confirmPin = (
    data: ConfirmPinRequest,
    previousOnSuccessPut?: OnSuccessPutType,
    feedbackIdentifier?: string,
): ConfirmPinActionInterface => {
    return {
        type: API_CONFIRM_PIN,
        payload: {
            params: data,
            feedbackIdentifier: feedbackIdentifier,
            onSuccessPut: previousOnSuccessPut,
        },
    };
};

export interface InitCpsSubscriptionRequest {
    code: string;
    state: string;
    localState: string;
}

export type InitCpsSubscriptionInterface = APICallAction<InitCpsSubscriptionRequest>;

export const initCps = (
    data: InitCpsSubscriptionRequest,
    feedbackIdentifier: string,
): InitCpsSubscriptionInterface => ({
    type: API_INIT_CPS,
    payload: {
        params: data,
        feedbackIdentifier,
    },
});

export interface ValidateEULAAndSignInActionInterface extends APICallAction<{ token: string }> {
    payload: {
        params: { token: string };
        previousPath?: string;
    };
}
export interface ValidateEULAAndContinueActionInterface extends APICallAction<{ token: string }> {
    payload: {
        params: { token: string };
        onSuccessPut?: OnSuccessPutType;
    };
}

export const validateEULAAndContinue = (token: string, onSuccessPut?: OnSuccessPutType): ValidateEULAAndContinueActionInterface => {
    return {
        type: API_VALIDATE_UELA_AND_CONTINUE,
        payload: {
            params: {
                token: token,
            },
            onSuccessPut,
        },
    };
};


export interface SignOutActionInterface extends APICallAction<{}> {
    payload: {
        params?: {};
        atReconnectSuccessPut?: OnSuccessPutType;
        toNewLogin?: boolean
    };
}

export const signOut = (previousPath: string | null, toNewLogin = false): SignOutActionInterface => {
    return {
        type: API_SIGN_OUT,
        payload: {
            atReconnectSuccessPut: (
                previousPath && isRouteLegitimatePreviousPath(previousPath)
            ) ? [redirect(previousPath)] : [redirect(ROUTE_DEFAULT_MODULAR)],
            toNewLogin: toNewLogin
        },
    };
};

export interface InitSSOActionPayload {
    clientID: string,
    state: string,
    scope: string | null,
    redirectURI: string | null,
    patientMail: string | null,
}
export const initSSOAction = (
    clientID: string,
    state: string,
    scope: string | null,
    redirectURI: string | null,
    patientMail: string | null,
): APICallAction<InitSSOActionPayload> => {
    return {
        type: API_INIT_SSO,
        payload: {
            params: {
                clientID, state, scope, redirectURI, patientMail
            }
        },
    };
};


export const registerForcedConnectionToken = (
    payload: EnforcedLocalConnectData,
): Action<EnforcedLocalConnectData> => {
    return {
        type: REGISTER_FORCED_CONNECTION_TOKEN,
        payload
    };
};


export interface ForceConnectionActionPayload {
    externalToken: string,
    localToken: string,
}


export const forceConnectionAction = (
    externalToken: string,
    localToken: string,
): APICallAction<ForceConnectionActionPayload> => {
    return {
        type: API_FORCE_CONNECTION,
        payload: {
            params: {
                externalToken,
                localToken
            }
        }
    };
};

export type RetrieveUserDataAction = APICallAction<{withAnonymousMode: boolean}>

export const retrieveUserData = (withAnonymousMode = false): RetrieveUserDataAction => {
    return {
        type: API_RETRIEVE_AUTH_USER_DATA,
        payload: {
            params: {
                withAnonymousMode
            }
        },
    };
};

export type RedirectToProSanteConnectInterface = APICallAction<{
    onSuccessPutAfterCPS?: OnSuccessPutType
}>;

export const redirectToProSanteConnect = (
    onSuccessPutAfterCPS?: OnSuccessPutType
): RedirectToProSanteConnectInterface => ({
    type: API_GET_PSC_URL,
    payload: {
        params: {
            onSuccessPutAfterCPS: onSuccessPutAfterCPS
        }
    },
});

export type CreateAccountInterface = APICallAction<{
    token: string;
    values: Record<string, string|boolean>,
}>;

export const createAccount = (
    token: string,
    values: Record<string, string|boolean>,
    feedbackIdentifier?: string,
    onSuccessPut?: OnSuccessPutType,
): CreateAccountInterface => ({
    type: API_CREATE_ACCOUNT,
    payload: {
        params: {
            token: token,
            values: values,
        },
        feedbackIdentifier: feedbackIdentifier,
        onSuccessPut: onSuccessPut,
    },
});

export const registerCPSRedirectionLocalData = (
    payload: CPSRedirectionLocalData
): Action<CPSRedirectionLocalData> => {
    return {
        type: REGISTER_CPS_REDIRECTION_LOCAL_DATA,
        payload
    }
};

export const retrieveCPSRedirectionLocalData = (): Action<{}> => {
    return {
        type: RETRIEVE_CPS_REDIRECTION_LOCAL_DATA,
        payload: {}
    }
};

export const deleteCPSRedirectionLocalData = (): Action<{}> => {
    return {
        type: DELETE_CPS_REDIRECTION_LOCAL_DATA,
        payload: {}
    }
};

export type AllActions = SignOutActionInterface | SignInActionInterface | RequestApiWithTokenAction |
    Action<EnforcedLocalConnectData> | Action<CPSRedirectionLocalData>;