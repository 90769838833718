import { AlertSeverity } from 'components/designSystem/components/Alert';
import { DisclaimerSeverity } from 'models/modular/communication';

export function getAlertSeverity (severity: DisclaimerSeverity): AlertSeverity {
    switch (severity) {
        case DisclaimerSeverity.SUCCESS:
            return AlertSeverity.SUCCESS
        case DisclaimerSeverity.WARNING:
            return AlertSeverity.WARNING
        case DisclaimerSeverity.ALERT:
            return AlertSeverity.CRITICAL
        default:
            return AlertSeverity.HIGHLIGHT
    }
}