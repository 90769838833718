import TrText from 'components/common/TrText';
import { ImageProfile, LayoutCard, mapLayoutColorToStyles } from "components/commonDesign/LayoutCard";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import { ColorMap } from "components/designSystem/Aliases";
import Button, { ButtonOption, ButtonSize } from 'components/designSystem/components/Button';
import FlexGrow from "components/designSystem/containers/FlexGrow";
import Spacer, { SmallSpacer, SpacerJustifyContent, SpacerSpacing } from 'components/designSystem/containers/Spacer';
import { TCaption, TDisplayXSmall500 } from 'components/designSystem/containers/Typography';
import { MarginSize, WithBottomMargin } from "components/designSystem/containers/WithMargin";
import { useChangePage, useModuleAttributes, useModulePayload } from "components/modules/modular/ModuleContainer";
import { Action, ResourceType } from "core/logging/ActionEvent";
import LOGGER from "core/logging/Logger";
import { APIText } from "models/common/message";
import { PageContext } from "models/modular/api";
import React from 'react';
import { useMediaQuery } from "react-responsive";

export type CTAContent = {
    page_id: string
    page_context?: PageContext
}

type Actions = {
    name: APIText
    content: CTAContent
}

type ModularCTAPayload = {
    title?: APIText
    description?: APIText
    actions?: Actions[]
    image?: {
        src: string,
        alt: string,
        profile: ImageProfile
        gradient: boolean
        without_margin?: boolean
    }
    layout_color?: ColorMap
}

export const CallToActionModule = (): JSX.Element => {
    const payload = useModulePayload<ModularCTAPayload>()
    const {identifier} = useModuleAttributes();
    const isDesktop = useMediaQuery({minWidth: TABLET_MAX_WIDTH_ACCESSIBLE})
    const changePage = useChangePage();
    const {boxColor, buttonVariant, typographyColor, backgroundGradientColor} = mapLayoutColorToStyles(payload.layout_color || ColorMap.BLUE);
    const handleActionClick = (action: Actions, index: number): void => {
        LOGGER.userAction(
            {
                action: Action.CLICK,
                action_payload: index.toString(),
                resource_id: identifier,
                resource_type: ResourceType.FEATURE,
            }
        )
        const changeParams = {
            pageIdentifier: action.content.page_id,
            pageContext: action.content.page_context
        };
        changePage(changeParams);
    }

    return (<LayoutCard
        color={boxColor}
        image={payload.image}
        imageProfile={payload.image?.profile}
        backgroundGradientColor={payload.image?.gradient ? backgroundGradientColor : undefined}
        imageDisplay={{withoutMargin: !payload.image?.without_margin}}
    >
        <Spacer
            spacing={isDesktop ? SpacerSpacing.LARGE : SpacerSpacing.SMALL}
            withFullHeight
            justifyContent={SpacerJustifyContent.SPACE_BETWEEN}>
            <FlexGrow/>
            <WithBottomMargin margin={MarginSize.SMALL}>
                <SmallSpacer withFullHeight>
                    {payload.title && <TDisplayXSmall500 color={typographyColor}>
                        <TrText input={payload.title}/>
                    </TDisplayXSmall500>}
                    {payload.description && <TCaption color={typographyColor}>
                        <TrText input={payload.description}/>
                    </TCaption>}
                </SmallSpacer>
            </WithBottomMargin>

            {payload.actions && payload.actions.length > 0 && (payload.actions.map((action, index) => <Button
                key={index}
                option={ButtonOption.LIGHT}
                variant={buttonVariant}
                size={ButtonSize.LARGE}
                onClick={(): void => handleActionClick(action, index)}
            >
                <TrText input={action.name}/>
            </Button>))}
        </Spacer>
    </LayoutCard>)
}