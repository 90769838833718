import React, { ReactNode } from 'react';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_EULA_DATA_PRIVACY } from 'core/routing/Routes';

import 'components/modules/eula/eula.scss';
import Alert from "components/designSystem/components/Alert";
import { TBody, TypographyColor } from "components/designSystem/containers/Typography";

interface EulaDataPrivacyNoticeV3Props {
    description: ReactNode
}

const EulaDataPrivacyNoticeV3 = (props: EulaDataPrivacyNoticeV3Props): JSX.Element => {
    return (
        <div style={{width: '100%'}}>
            <TBody color={ TypographyColor.COLOR_TEXT_DEFAULT }>
                <Alert description={props.description}/>
                <br/>
                <div style={{width: '100%'}}>
                    <div className="eula-paragraph-content">
                        <TBody color={ TypographyColor.COLOR_TEXT_DEFAULT }>
                            Les données à caractère personnel vous concernant, c'est-à-dire les données qui permettent de
                            vous identifier
                            comme une personne physique, notamment par référence à votre état civil, votre numéro
                            d’identification, votre
                            identifiant en ligne ou les données relatives à votre santé (ci-après dénommées “vos Données" ou
                            vos “Données
                            à caractère personnel”) sont collectées et traitées avec votre laboratoire de biologie médicale
                            dans le cadre de
                            l’utilisation de la plateforme Kiro.
                            <br/> <br/>
                            Kiro et votre laboratoire de biologie médicale attachent une importance fondamentale à la
                            protection et à la
                            sécurité de vos Données. La confidentialité de ces données personnelles et le respect de la vie
                            privée comptent
                            parmi nos priorités majeures.
                            <br/> <br/>
                            Nous nous engageons à protéger vos données à caractère personnel conformément à la
                            réglementation
                            applicable et notamment le Règlement (UE) n°2016/679 du 27 avril 2016 dit « Règlement Général
                            sur la
                            Protection des Données » ou « RGPD » et la loi n° 78-17 du 6 janvier 1978 modifiée, dite « Loi
                            Informatique et
                            Liberté » modifiée.
                            <br/> <br/>
                            Kiro et votre laboratoire accordent également une place primordiale à la conformité et au
                            respect des règles de
                            déontologie fixées par les instances ordinales représentant les professionnels de santé et
                            celles définies par les
                            institutions.
                            <br/> <br/>
                            Les Données personnelles de santé sont ainsi hébergées par un hébergeur ayant reçu la
                            certification HDS
                            (Hébergeur de Données de Santé). Introduite par l’Agence du Numérique en Santé (ANS), la
                            certification HDS
                            fournit un cadre permettant de renforcer la sécurité et la protection des données de santé à
                            caractère personnel.
                            <br/> <br/>
                            En fonction des Données à caractère personnel traitées, Kiro est susceptible d’agir en qualité
                            de responsable de
                            traitement ou de sous-traitant pour le compte des Acteurs de santé (votre laboratoire de
                            biologie médicale ou le
                            professionnel de santé ont accès à la plateforme Kiro le cas échéant).
                            <br/> <br/>
                            Kiro est responsable de traitement des Données à caractère personnel des utilisateurs
                            recueillies dans le cadre
                            de la création et gestion de son compte utilisateur, de leur navigation et de leur utilisation
                            de la plateforme Kiro,
                            notamment pour vous permettre de créer un compte sur la plateforme Kiro, de l'utiliser et de
                            déposer les
                            informations vous concernant.
                            <br/> <br/>
                            Votre laboratoire de biologie médicale ou votre professionnel de santé le cas échéant sont
                            responsables de
                            traitement des Données Personnelles recueillies dans le cadre de leur mission de prise en
                            charge, y compris
                            celle relative aux résultats de biologie médicale, de consultation ou de suivi du patient. Kiro
                            intervient alors en
                            tant que sous-traitant au sens de la réglementation applicable aux données personnelles.
                            <br/> <br/>
                            Qu’elle soit responsable de traitement ou sous-traitant, Kiro prend les mesures propres à
                            assurer la protection et
                            la confidentialité des données à caractère personnel qu’elle détient ou qu’elle traite dans le
                            respect des
                            dispositions du RGPD et des législations nationales.
                            <br/> <br/>
                        </TBody>
                    </div>
                </div>
                <div style={{width: '100%'}}>
                    <div className="eula-paragraph-content">
                        <TBody color={ TypographyColor.COLOR_TEXT_DEFAULT }>
                            Vos Données à caractère personnel sont utilisées pour différentes raisons :
                            <br/>
                            <ol>
                                <li>
                                    Exécuter les prestations relatives au contrat de Kiro avec le laboratoire de biologie
                                    médicale ou avec le professionnel de
                                    santé et/ou relatives aux CGUs et mettre sa plateforme (la Solution et ses Services) à
                                    disposition des utilisateurs de Kiro
                                </li>
                                <li>
                                    Envoyer aux utilisateurs des renseignements ou des notifications par e-mail, SMS ou tout
                                    autre moyen de communication
                                    concernant leurs résultats de biologie, notamment ceux effectués au laboratoire de
                                    biologie médicale
                                </li>
                                <li>
                                    Héberger et protéger vos Données à caractère personnel, ainsi que prévenir et lutter
                                    contre la fraude informatique
                                    (spamming, hacking...)
                                </li>
                                <li>
                                    Organiser, gérer et maintenir les fonctionnalités et le support de la Solution et des
                                    Services et réaliser des statistiques
                                    relatives à l'utilisation de la Solution et/ou des Services pour le compte de Kiro, du
                                    laboratoire de biologie médicale ou du
                                    professionnel de santé.
                                </li>
                                <li>
                                    Améliorer la performance et les fonctionnalités de la Solution et des Services à partir
                                    des Données Personnelles
                                    nécessaires, notamment réalisé à partir d’algorithmes d’intelligence artificielle, et
                                    notamment pour la réalisation d’études
                                    rétrospectives en lien avec l’utilisation de la Solution et des Services, dans le
                                    respect de la réglementation applicable à de
                                    telles recherches et de la Réglementation applicable à la protection des données
                                    personnelle.
                                </li>
                                <li>
                                    Pour la réalisation sous la responsabilité de chercheurs à des fins d'études
                                    rétrospectives présentant un intérêt public dans
                                    le domaine de la santé et visant à améliorer les connaissances et la prise en charge des
                                    patients, dans le respect de la
                                    réglementation applicable à de telles recherches et de la Réglementation applicable à
                                    la protection des données personnelles.
                                    Si vous le souhaitez, vous pourrez vous y opposer.
                                </li>
                                <li>
                                    Pour vous adresser des informations et vous proposer de participer à des études
                                    impliquant votre participation et présentant
                                    un intérêt public dans le domaine de la santé mises en œuvre sous la responsabilité de
                                    chercheurs votre consentement éclairé
                                    et spécifique sera recueilli systématiquement pour chacune des études proposées et vous
                                    pourrez impliquer votre médecin
                                    traitant.
                                </li>
                            </ol>
                        </TBody>
                    </div>
                </div>
                <br/>
                <div style={{width: '100%'}}>
                    <div className="eula-paragraph-content">
                        <TBody color={ TypographyColor.COLOR_TEXT_DEFAULT }>
                            Vous disposez de droits sur vos Données Personnelles et notamment d'un droit d'accès, de
                            rectification et
                            d'opposition au traitement de vos Données que vous pouvez exercer auprès du Délégué à la
                            Protection des
                            Données du responsable de traitement concerné - Pour en savoir plus sur vos droits :
                            <a className={"legacy-a"} target={'_blank'} rel="noopener noreferrer"
                               href={getRoute(ROUTE_EULA_DATA_PRIVACY, {})}>
                                Politique de Protection des Données Personnelles
                            </a>
                            <br/> <br/>
                            Pour toutes les demandes concernant des Données à caractère personnel pour lesquelles Kiro est
                            responsable
                            de traitement, si l’utilisateur souhaite savoir comment Kiro utilise ses Données à caractère
                            personnel, ou exercer
                            ses droits, l’utilisateur peut contacter Kiro par écrit à l’adresse suivante : Kiro – DPO, 1
                            rue de la Bourse, 75002
                            Paris ou par mail à <a className={"legacy-a"} href="mailto:dpo@kiro.bio">dpo@kiro.bio</a>.
                            <br/> <br/>
                            Dans ce cas, l’utilisateur doit indiquer les Données à caractère personnel qu’il souhaiterait
                            que Kiro corrige,
                            mette à jour ou supprime, en s’identifiant de manière précise avec une copie d’une pièce
                            d’identité (carte
                            d’identité ou passeport) ou tout autre élément permettant de justifier de son identité.
                            <br/>
                            Dans le cas où votre consentement constitue une base légale du traitement de vos Données à
                            caratère personnel
                            (points 6 et 7), vous pourrez à tout moment changer d'avis et sans justification, retirer votre
                            consentement en vous adressant au Délégué à la Protection des Données de Kiro : <a
                            className={"legacy-a"} href="mailto:dpo@kiro.bio">dpo@kiro.bio</a>.
                            <br/> <br/>
                            Pour toutes les demandes concernant des Données à caractère personnel pour lesquelles Kiro est
                            sous-traitant,
                            si l’utilisateur souhaite exercer ses droits, il peut contacter son laboratoire de biologie
                            médicale ou son
                            professionnel de santé Responsable de traitement.
                            <br/> <br/>
                            Dans ce cas, Kiro, à la demande du laboratoire de biologie ou du professionnel de santé
                            Responsable de
                            traitement, pourra assister ce dernier dans les suites à donner aux demandes adressées par ses
                            patients mais
                            ne pourra répondre directement aux demandes desdits patients.
                            <br/> <br/>
                            Les demandes de suppression de Données à caractère personnel seront soumises aux obligations
                            qui sont
                            imposées à Kiro, à votre laboratoire de biologie médicale et à votre professionnel de santé par
                            la loi, notamment
                            en matière de conservation ou d’archivage des documents.
                        </TBody>
                    </div>
                </div>
            </TBody>
        </div>
    );
};

export default EulaDataPrivacyNoticeV3;
