import {
    buildTickFormatter,
    buildTickProps,
    TickFormatterFunction
} from 'components/legacyDesignSystem/components/charts/builders/AxisUtilities';
import {
    CustomizedJSXLabel,
    CustomizedLabelType,
} from 'components/legacyDesignSystem/components/charts/builders/Labels';
import { AxisDataType, AxisDirection, AxisValue } from 'components/legacyDesignSystem/components/charts/builders/model';
import { AxisTickOverrider } from 'models/charts/charts/Distribution';
import React from 'react';
import { XAxis, YAxis } from 'recharts';
import { ScaleType } from "recharts/types/util/types";
import _ from 'underscore';

function buildDomain(minValue?: AxisValue, maxValue?: AxisValue): [string | number, string | number] {
    // TODO: IMPROVE
    // Quick fix to avoid display bug on scale
    const absMax = _.max([Math.abs(minValue || 0), Math.abs(maxValue || 0)]);
    const significantDigit = absMax >= 1 ? 0 : absMax >= 0.1 ? 1 : absMax >= 0.01 ? 2 : 3;
    return [
        minValue === undefined ? 'auto' : parseFloat(minValue.toFixed(significantDigit)),
        maxValue === undefined ? 'auto' : parseFloat(maxValue.toFixed(significantDigit)),
    ];
}

function buildScale(axisType: AxisDataType): ScaleType | undefined  {
    switch (axisType) {
        case AxisDataType.TIME:
            return 'time';
        case AxisDataType.NUMERICAL:
            return 'linear';
        default:
            return 'auto';
    }
}

function buildType(axisType: AxisDataType): 'number' | 'category' | undefined {
    switch (axisType) {
        case AxisDataType.TIME:
        case AxisDataType.NUMERICAL:
            return 'number';
        default:
            return undefined;
    }
}



function applyOverride(value: number, labels: AxisTickOverrider): string  {
    if (labels[value]) {
        return labels[value].axis_tick;
    }
    else {
        return value + "";
    }
}

export function buildAxis(
    key: string,
    direction: AxisDirection,
    dataType: AxisDataType,
    dataKey?: string,
    minValue?: AxisValue,
    maxValue?: AxisValue,
    tickFormatter?: TickFormatterFunction,
    name?: React.ReactNode,
    overrideTicks?: AxisTickOverrider,
    ticks?: AxisValue[],
    autoDomain?: boolean,
): JSX.Element {
    if (direction === AxisDirection.X) {
        const tickProps = buildTickProps(
            dataType,
            minValue,
            maxValue,
            ticks,
            overrideTicks ? (value: number): string => applyOverride(value, overrideTicks) : tickFormatter
        )
        return (
            <XAxis
                key={key}
                allowDataOverflow
                dataKey={dataKey}
                scale={buildScale(dataType)}
                type={buildType(dataType)}
                padding={{ left: 15, right: 15 }}
                domain={buildDomain(minValue, maxValue)}
                tick={{ fontSize: '0.875em' }}
                tickLine={false}
                tickFormatter={tickProps.tickFormatter}
                ticks={tickProps.ticks}
                axisLine={false}
                label={
                    name
                        ? {
                            content: <CustomizedJSXLabel>{name}</CustomizedJSXLabel>,
                            position: 'bottom',
                            offset: 0,
                        }
                        : undefined
                }
            />
        );
    }

    return (
        <YAxis
            width={32}
            tickLine={false}
            domain={autoDomain ? [minValue ?? 'auto', maxValue ?? 'auto'] : buildDomain(minValue, maxValue)}
            tick={{ fontSize: '0.6em' }}
            axisLine={false}
            dataKey={dataKey}
            tickFormatter={dataType !== AxisDataType.TEXT ? buildTickFormatter(dataType, tickFormatter) : undefined}
            ticks={ticks}
            label={
                name
                    ? {
                        content: <CustomizedJSXLabel type={CustomizedLabelType.VERTICAL}>{name}</CustomizedJSXLabel>,
                        position: 'left',
                        offset: 0,
                        angle: -90,
                    }
                    : undefined
            }
        />
    );
}
