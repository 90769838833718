import { ActionDispatcher } from 'actions/ActionInterface';

import { redirect } from 'actions/common/CommonActions';
import { signOut } from 'actions/user/SignInSignUpActions';
import classNames from 'classnames';
import Link from 'components/core/buttons/Link';
import NavBar from 'components/core/menus/NavBar';
import LegacyPatientSearchBar from 'components/pages/common/LegacyPatientSearchBar';
import MainComponent, { MainComponentProps, setup, TransDomain } from 'components/pages/common/MainComponent';
import Footer from 'components/pages/Footer';
import LogoFull from 'core/content/brand/logos/LogoFull';
import ArrowExpander, { ArrowDirection, ArrowStrokeWeight } from 'core/content/icons/ArrowExpander';
import MagnifyingGlass from 'core/content/icons/MagnifyingGlass';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_DEFAULT_MODULAR, ROUTE_MODULAR_PAGE } from 'core/routing/Routes';
import { AnyState } from 'core/store/Store';
import { LocalUserData, UserMode } from 'models/user/UserModels';
import React, { ReactNode } from 'react';
import { REDUCER_GLOBAL } from 'reducers/allReducers';
import { reducer } from 'reducers/selector';
import { getUserData } from 'reducers/Shortcuts';

import './WithNavBar.scss';

export interface WithNavBarDispatchProps {
    disconnect: () => void;
    redirectToDashboard: () => void;
    redirectToMyAccount: () => void;
}

export interface WithNavBarStateProps {
    userData: LocalUserData;
    isPopupOpen: boolean;
}

export interface WithNavBarProps extends MainComponentProps, WithNavBarDispatchProps, WithNavBarStateProps {
    withWhiteBackground?: boolean
}

class WithNavBar extends MainComponent<WithNavBarProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    state = { displaySearchBanner: false, isMenuOpen: false };
    menuItemsRef = React.createRef<HTMLInputElement>();

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: MouseEvent): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.menuItemsRef && !this.menuItemsRef.current.contains(event.target)) {
            this.setState({ isMenuOpen: false });
        }
    };

    onSearchBlur = (): void => {
        this.setState({ displaySearchBanner: false });
    };

    clickOnMyAccount = (event: React.MouseEvent): void => {
        event.stopPropagation();
        this.setState({ isMenuOpen: false }, this.props.redirectToMyAccount);
    };

    render(): ReactNode {
        const { children, disconnect, redirectToDashboard, userData } = this.props;
        return (
            <React.Fragment>
                <NavBar sticky>
                    <div className={'nav-container'}>
                        <div className={'nav-logo'} onClick={redirectToDashboard}>
                            <LogoFull />
                        </div>
                        {this.props.userData.chosenUserMode === UserMode.DOCTOR && (
                            <div className={'nav-search'}>
                                <div className={'nav-searchbar'}>
                                    <LegacyPatientSearchBar />
                                </div>
                                <div
                                    className={'nav-search-button'}
                                    onClick={(): void => this.setState({ displaySearchBanner: true })}
                                >
                                    <MagnifyingGlass />
                                </div>
                            </div>
                        )}
                        <div
                            ref={this.menuItemsRef}
                            className={'nav-menu-right'}
                            onClick={(): void => this.setState({ isMenuOpen: true })}
                        >
                            <div className={'nav-menu-title'}>
                                {userData.firstName}
                                <ArrowExpander direction={ArrowDirection.DOWN} strokeWidth={ArrowStrokeWeight.NORMAL} />
                            </div>
                            <div
                                className={classNames('nav-menu-items', {
                                    'nav-menu-items-visible': this.state.isMenuOpen,
                                })}
                            >
                                <Link id={'my_account'} onClick={this.clickOnMyAccount}>
                                    {this.trans('my_account')}
                                </Link>
                                <Link id={'disconnect'} onClick={disconnect}>
                                    {this.trans('disconnect')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    {this.state.displaySearchBanner && (
                        <div className={'nav-searchbanner'}>
                            <LegacyPatientSearchBar onBlur={this.onSearchBlur} />
                        </div>
                    )}
                </NavBar>
                <div
                    className={classNames('with-navbar-container', {
                        'with-navbar-container-white-background': this.props.withWhiteBackground
                    })}
                >
                    {children}
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): WithNavBarDispatchProps => ({
    disconnect: (): void => {
        dispatch(signOut(null));
    },
    redirectToDashboard: (): void => {
        dispatch(redirect(ROUTE_DEFAULT_MODULAR));
    },
    redirectToMyAccount: (): void => {
        dispatch(redirect(getRoute(ROUTE_MODULAR_PAGE, { pageId: 'account' })));
    },
});

const mapStateToProps = (state: AnyState): WithNavBarStateProps => ({
    userData: getUserData(state),
    isPopupOpen: reducer(state, REDUCER_GLOBAL).isPopupOpen,
});

export default setup(WithNavBar, mapStateToProps, mapDispatchToProps);
