import { HistogramPayload } from "models/charts/charts/Histogram";
import { ChartItemHistogramBar } from "models/charts/items/HistogramBar";
import { APIText } from 'models/common/message';
import { Value } from 'models/medicalReport/LegacyReportModels';
import { ChartItemBioValue } from 'models/charts/items/BioValue';
import { TimeSeriePayload } from 'models/charts/charts/TimeSerie';
import { DistributionPayload } from 'models/charts/charts/Distribution';
import { ChartItemStackedBar } from 'models/charts/items/StackedBar';
import { ChartItemPie } from 'models/charts/items/PieSegment';
import { HalfPiePayload } from 'models/charts/charts/HalfPie';
import { NumericalValue } from "models/medicalReport/ReportModels";
import { MapPayload } from 'models/charts/charts/Map';
import { ChartItemMapArea } from 'models/charts/items/MapArea';

export enum TimeSeriePointGraphValueReferenceType {
    HIGH = 'HIGH',
    LOW = 'LOW'
}

export interface TimeSeriePointGraphValueReference {
    value?: number,
    gravity: TimeSeriePointGraphValueReferenceType
}

export interface TimeSeriePointGraphValue {
    value?: number
    references: TimeSeriePointGraphValueReference[]
}

export enum TimeSerieTag {
    SELECTED = 'SELECTED',
    ABNORMAL = 'ABNORMAL'
}

export interface TimeSeriePoint {
    value: TimeSeriePointGraphValue,
    display_value: APIText | Value,
    date: Date,
    tags?: TimeSerieTag[]
}

export enum ChartItemType {
    BIO_VALUE_LINE = 'BIO_VALUE_LINE',
    STACKED_BAR = 'STACKED_BAR',
    HISTOGRAM_BAR = 'HISTOGRAM_BAR',
    PIE_SEGMENT = 'PIE_SEGMENT',
    TUBE_BAR = 'TUBE_BAR',
    MAP_AREA = 'MAP_AREA',
}

export interface ChartItemTubeBar {
    title: APIText;
    data: {
        color: string;
        values_by_type: {
            [identifier: string]: NumericalValue;
        };
    };
    scale_by_type: {
        [identifier: string]: {
            min: NumericalValue | null,
            max: NumericalValue | null
        };
    }
}

export type ChartItemPayload =
    | ChartItemBioValue
    | ChartItemStackedBar
    | ChartItemPie
    | ChartItemTubeBar
    | ChartItemMapArea
    | ChartItemHistogramBar;


export interface ChartItem {
    unique_id: string
    type: ChartItemType
    payload: ChartItemPayload
}

export enum ChartType {
    TIME_SERIE = 'TIME_SERIE',
    DISTRIBUTION = 'DISTRIBUTION',
    HALFPIE = 'HALFPIE',
    HISTOGRAM = 'HISTOGRAM',
    MAP = 'MAP',
    TUBE = 'TUBE',
    PIE = 'PIE',
}

export type ChartPayload = TimeSeriePayload | DistributionPayload | HalfPiePayload | MapPayload | HistogramPayload

export interface Chart<T extends ChartPayload = ChartPayload> {
    type: ChartType
    items: ChartItem[]
    payload?: T
}

export interface ChartFilterDataKeySelector {
    keys: string[]
}

export interface ChartFilterDistributionXFilter {
    min?: number
    max?: number
}

export interface ChartFilterSwitchFilter {
    active_filter: string;
}

export interface ChartFilterMapAreaSelected {
    areaId: string
}

export enum ChartFilterType {
    DATA_KEY_SELECTOR = 'DATA_KEY_SELECTOR',
    X_FILTER = 'X_FILTER',
    SWITCH = 'SWITCH',
    MAP_AREA_SELECTED = 'MAP_AREA_SELECTED'
}

export type ChartFilterPayload =
    ChartFilterDataKeySelector
    | ChartFilterDistributionXFilter
    | ChartFilterSwitchFilter
    | ChartFilterMapAreaSelected

export interface ChartFilter {
    type: ChartFilterType
    payload: ChartFilterPayload
}
