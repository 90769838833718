import { DEFAULT_CHART_HEIGHT } from 'components/commonDesign/charts/constants';
import { ColorMap } from "components/designSystem/Aliases";
import Typography from 'components/designSystem/containers/Typography';
import { getColor } from 'components/legacyDesignSystem/components/charts/builders/utils';
import { ChartContainer } from 'components/legacyDesignSystem/components/charts/ChartContainer';
import { cast } from 'core/utils/Typed';
import { ChartItemPie, ChartItemPieSegment } from 'models/charts/items/PieSegment';
import React, { Fragment } from 'react';
import { ChartItem } from 'models/charts/charts';
import { TranslationMethod } from 'models/common/message';
import { Cell, Legend, Pie, PieChart } from 'recharts';

type PieDataItem = {
    title: React.ReactNode;
    value: number | null;
    color: ColorMap;
};

const buildData = (item: ChartItem, translation: TranslationMethod): PieDataItem[] => {
    const payload = cast<ChartItemPie>(item.payload);
    return payload.segments.map((segment: ChartItemPieSegment) => ({
        title: <Typography><>{translation(segment.title)}</><>{" : " + segment.value.value + " " + segment.value.unit}</></Typography>,
        value: segment.value.value,
        color: segment.color as ColorMap,
    }));
};

export function buildPie(
    items: ChartItem[],
    translation: TranslationMethod
): JSX.Element {
    return (
        <Fragment>
            {items.map((item, i) => {
                const data = buildData(item, translation);
                return (
                    <ChartContainer key={i} height={DEFAULT_CHART_HEIGHT}>
                        <PieChart>
                            <Legend iconType="circle" layout="vertical" verticalAlign="middle" align="left" />
                            <Pie
                                data={data}
                                dataKey="value"
                                nameKey="title"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={getColor(entry.color, false)} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ChartContainer>
                );
            })}
        </Fragment>
    );
}
