import React, { Component, ReactNode } from 'react';
import { SimpleNumericalValue } from 'components/commonDesign/values/SimpleNumericalValue';
import { NumericalValue } from 'models/medicalReport/ReportModels';
import { setupRaw } from 'components/pages/common/MainComponent';
import { WithTranslation } from 'react-i18next';
import { fromLanguageToNumberFormat } from 'components/utils/LanguageToNumber';

interface ConnectedNumericalValueProps {
    value: NumericalValue | null
}

class ConnectedNumericalValue extends Component<ConnectedNumericalValueProps & WithTranslation> {
    render(): ReactNode {
        return  <SimpleNumericalValue value={this.props.value} numberFormat={fromLanguageToNumberFormat(this.props.i18n.language)}/>;
    }
}

export default setupRaw(ConnectedNumericalValue);