import {
    CLOSE_POPUP,
    MODAL_CLOSE,
    MODAL_OPEN,
    MODAL_UPDATE_EXTRA,
    ModalInterface,
    OPEN_POPUP,
    PopUpActionPayload, UpdateModalExtraInterface
} from 'actions/common/CommonActions';
import { AnyAction, getActionPayload, OnSuccessPutType } from 'actions/ActionInterface';
import { PopUpStyle } from "components/core/containers/PopUp";
import { BlockingPopUpComponentIdentifier } from "components/pages/common/FullPopList";
import { ReactElement } from "react";

export interface GlobalReducerInterface {
    modal?: ModalInterface
    isPopupOpen?: boolean
    popupContent?: ReactElement | null
    popupPathname?: string | null
    popupStyle?: PopUpStyle | null
    popupTitle?: string | null
    popUpDisableStdClose?: boolean | null
    blockingPopUp?: boolean
    redirectOnSuccessPut?: OnSuccessPutType
}
export const initialState: GlobalReducerInterface = {};

type RouterActionRedirectedPayload = {
    location?: {
        state?: {
            previousOnSuccessPut?: OnSuccessPutType
        }
    }
}
export const globalReducers = (state = initialState, action: AnyAction): GlobalReducerInterface => {
    switch (action.type) {
        case OPEN_POPUP:
            const popUpPayload = getActionPayload<PopUpActionPayload>(action);
            return {
                ...state,
                isPopupOpen: true,
                popupContent: popUpPayload.content,
                popupPathname: popUpPayload.pathname,
                popupStyle: popUpPayload.style,
                popupTitle: popUpPayload.title,
                popUpDisableStdClose: popUpPayload.disableStdClose,
            };
        case CLOSE_POPUP:
            return {
                ...state,
                isPopupOpen: false,
                popupContent: null,
                popupPathname: null,
                popupStyle: null,
                popupTitle: null,
                popUpDisableStdClose: null,
            };
        case '@@router/LOCATION_CHANGE':
            const routerActionPayload = getActionPayload<RouterActionRedirectedPayload>(action);
            return {
                ...state,
                blockingPopUp: undefined,
                redirectOnSuccessPut: routerActionPayload.location?.state?.previousOnSuccessPut
            };
        case MODAL_OPEN:
            const modalAction = getActionPayload<ModalInterface>(action);

            if (
                modalAction.extra?.blockingModalPayload?.identifier === BlockingPopUpComponentIdentifier.PIN &&
                state.modal?.extra?.blockingModalPayload?.identifier === BlockingPopUpComponentIdentifier.PIN
            ) {
                // if modal is already open on PIN no need to override it
                // This is done to avoid secondary api calls (done after the first one that triggers pin, but before pin validation)
                // to override BlockingModalPayload.then and disturb post pin validation behaviour
                // This is a temporary fix, pin "popup" should be able to propely handle
                // multiple parallel blocked calls

                return state
            }

            return {
                ...state,
                modal: modalAction,
            };
        case MODAL_CLOSE:
            return {
                ...state,
                modal: undefined,
            };
        case MODAL_UPDATE_EXTRA:
            const updateExtra = getActionPayload<UpdateModalExtraInterface>(action).extra;
            // @ts-ignore
            const modal = state['modal'] || undefined
            // @ts-ignore
            const previousExtra = modal && modal['extra'] || undefined

            if (modal === undefined || previousExtra === undefined){
                return {
                    ...state
                }
            }

            return {
                ...state,
                modal: {
                    ...modal,
                    extra: {
                        title: updateExtra.title === undefined ? previousExtra.title :
                            updateExtra.title === null ? undefined : updateExtra.title,
                        fullWidth: updateExtra.fullWidth === undefined ? previousExtra.fullWidth :
                            updateExtra.fullWidth === null ? undefined : updateExtra.fullWidth,
                        withoutCloseButton: updateExtra.withoutCloseButton === undefined ? previousExtra.withoutCloseButton :
                            updateExtra.withoutCloseButton === null ? undefined : updateExtra.withoutCloseButton,
                    }
                }
            };
        default:
            return state;
    }
};

export default globalReducers;
