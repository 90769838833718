import React, { Component, PropsWithChildren, ReactNode } from 'react';
import './SimpleBox.scss';
import classNames from 'classnames';

export enum SimpleBoxType {
    SCROLLING_NOTICE = 'scrolling_notice',
}

export interface SimpleBoxTypeProps {
    type?: SimpleBoxType;
}

export default class SimpleBox extends Component<PropsWithChildren<SimpleBoxTypeProps>> {
    render(): ReactNode {
        return <div className={
            classNames(
                'simple-box',
                {
                    'simple-box-scrolling-notice': this.props.type == SimpleBoxType.SCROLLING_NOTICE
                }
            )
        }>{this.props.children}</div>;
    }
}
