import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationFR from './locales/fr/translations.json';
import translationEN from './locales/en/translations.json';
import { LNG_LOCAL_STORAGE } from 'configuration/settings';

const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'fr',
        detection: {
            order: ['localStorage'], // do not yet add 'navigator' as we not fully support it
            caches: ['localStorage'],
            lookupLocalStorage: LNG_LOCAL_STORAGE
        },
        keySeparator: '.',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
