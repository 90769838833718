import React from 'react';

const BurgerMenu = (): JSX.Element => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M4 8.5C4 7.67157 4.67157 7 5.5 7H26.5C27.3284 7 28 7.67157 28 8.5C28 9.32843 27.3284 10 26.5 10H5.5C4.67157 10 4 9.32843 4 8.5ZM4 16.5C4 15.6716 4.67157 15 5.5 15H26.5C27.3284 15 28 15.6716 28 16.5C28 17.3284 27.3284 18 26.5 18H5.5C4.67157 18 4 17.3284 4 16.5ZM5.5 23C4.67157 23 4 23.6716 4 24.5C4 25.3284 4.67157 26 5.5 26H26.5C27.3284 26 28 25.3284 28 24.5C28 23.6716 27.3284 23 26.5 23H5.5Z" />
    </svg>

)

export default BurgerMenu;
