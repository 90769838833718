import { RawColors } from 'components/legacyDesignSystem/branding/constants';
import { NormalityLevel } from "components/legacyDesignSystem/components/charts/models";
import React, { ReactElement } from 'react';
import { DotProps, Line } from 'recharts';

export const getFillColor = (normalityLevel: NormalityLevel): string => {
    switch (normalityLevel) {
        case NormalityLevel.NORMAL:
            return RawColors.SECONDARY_SKY_BLUE;
        case NormalityLevel.ABNORMAL:
            return RawColors.SEMANTIC_ORANGE;
        case NormalityLevel.HIGHLY_ABNORMAL:
            return RawColors.SEMANTIC_ORANGE;
    }
}

function dot(normalityKey: string, active: boolean): (props: DotProps & { payload: unknown }) => ReactElement<SVGElement> {

    function buildDot(props: DotProps & { payload: unknown }): ReactElement<SVGElement> {
        if (props.cx === undefined || props.cy == undefined) {
            return <></>
        }

        // @ts-ignore
        const fillColor = getFillColor(props.payload[normalityKey]);

        return <circle
            key={props.cx + ':' + props.cy}
            cx={props.cx}
            cy={props.cy}
            r={active ? 7 : 5}
            stroke='white'
            strokeWidth={2}
            fill={fillColor}/>
    }

    return buildDot
}

export function buildLine(
    dataKey: string,
    normalityKey: string,
): JSX.Element {
    return <Line
        key={"line" + dataKey}
        type="monotone"
        dataKey={dataKey}
        stroke={RawColors.PRIMARY_LIGHT}
        strokeWidth={2}
        dot={dot(normalityKey, false)}
        activeDot={dot(normalityKey, true)}
    />
}
