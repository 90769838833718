import React from 'react';
import { Entity } from 'models/entities/default';
import { Logo, LogoProps, LogoType, SizedLogo, SizedLogoSize } from 'components/designSystem/components/Logo';
import { getEntityImg, LogoType as CoreLogoType } from 'core/content/brand/logos/LabLogos';
import Avatar, { AvatarSize, AvatarType } from 'components/designSystem/components/Avatar';
import { IconName } from "components/designSystem/foundations/IconsData";

export interface EntityLogoProps {
    alt?: string
    entity?: Entity
    type?: LogoType
    asAvatar?: { size?: AvatarSize }
    fallbackIcon?: IconName
    size?: SizedLogoSize
}

function getAlt(
    entity: Entity,
    alt?: string,
): string {
    if (alt) {
        return alt
    }

    if (entity.name) {
        return entity.name
    }

    return ""
}

const EntityLogo = (props: EntityLogoProps): JSX.Element => {
    if (!props.entity) {
        return <Avatar
            size={props.asAvatar?.size}
            payload={{
                type: AvatarType.ICON,
                name: props.fallbackIcon ? props.fallbackIcon : IconName.LAB,
            }}
        />
    }

    if (props.asAvatar) {
        return <Avatar
            size={props.asAvatar?.size}
            payload={{
                type: AvatarType.IMAGE,
                src: getEntityImg(props.entity, CoreLogoType.SYMBOL),
                alt: getAlt(props.entity, props.alt),
                fallbackIcon: props.fallbackIcon
            }}
        />
    }

    const logoProps: LogoProps = {
        alt: getAlt(props.entity, props.alt),
        type:props.type,
        imgByType: {
            [LogoType.DEFAULT]: getEntityImg(props.entity, CoreLogoType.DEFAULT),
            [LogoType.SYMBOL]: getEntityImg(props.entity, CoreLogoType.SYMBOL),
        },
        fallbackIcon:props.fallbackIcon
    }

    if (props.size) {
        return <SizedLogo
            size={props.size}
            {...logoProps}
        />
    }

    return <Logo
        {...logoProps}
    />
}

export default EntityLogo;