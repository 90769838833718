import { ActionDispatcher } from 'actions/ActionInterface';
import { triggerNotificationByIdentifier } from 'actions/user/NotificationAction';
import EntityLogo from "components/commonDesign/EntityLogo";
import Form from "components/commonDesign/form/tmpNewSystem/Form";
import FormInput from "components/commonDesign/form/tmpNewSystem/FormInput";
import CentralColumn, { CentralColumnWidth } from 'components/core/containers/CentralColumn';
import Information from 'components/core/containers/Information';
import PoweredBy from 'components/core/containers/PoweredBy';
import SimpleDropdownQuestion from 'components/core/text/SimpleDropdownQuestion';
import { SeverityType } from 'components/core/types/Types';
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { XLargeSpacer } from "components/designSystem/containers/Spacer";
import 'components/modules/user/legacy/TriggerNotificationByIdentifier.scss';
import APIFeedbackMessageContainer, { matchSeverity } from 'components/pages/common/APIFeedbackMessage';
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from "components/pages/common/WithTranslations";
import { aggregate, required, validateEmail } from "components/utils/form/tmpNewSystem/validators";
import LogoFull from 'core/content/brand/logos/LogoFull';
import { ROUTE_FAQ } from 'core/routing/Routes';
import { APIFeedbackMessage, APIFeedbackMessageType } from 'models/common/APIMessaging';
import { EntityType, LABORATORY_UUID_UNILABS } from "models/entities/default";
import React, { Fragment, ReactNode } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

export const FEEDBACK_IDENTIFIER_API_TRIGGER_NOTIFICATION = 'FEEDBACK_IDENTIFIER_API_TRIGGER_NOTIFICATION';

export interface TriggerNotificationProps {
    location: Location;
}
export interface TriggerNotificationDispatchProps {
    triggerNotification: (identifier: string, labUuid?: string) => void;
}

/**
 * Deprecated parameter for lab.
 */
export const LAB_CODE_KEY = "labcode";
export const LAB_UUID_KEY = "lab";

export const buildNotificationPath = (basePath: string, labUuid: string): string => {
    const url = new URL("http://a.co" + basePath);
    url.searchParams.set(LAB_UUID_KEY, labUuid);
    return basePath + url.search;
};


class TriggerNotificationByIdentifier extends WithTranslations<
    TriggerNotificationProps & TriggerNotificationDispatchProps
> {
    TRANS_SUFFIX = TransDomain.ACCOUNT_NOTIF;

    onSubmit = (labUuid: string | undefined) => (data: { identifier: string }): void => {
        this.props.triggerNotification(data.identifier, labUuid);
    };

    render(): ReactNode {
        // If changing the logic, please check buildNotificationPath usage
        const urlParams = new URLSearchParams(this.props.location.search);
        const labUuid: string | null = // Legacy lab code URL params still supported as fallback for compatibility
            urlParams.get(LAB_UUID_KEY) ?? (urlParams.get(LAB_CODE_KEY) == 'unilabs' ? LABORATORY_UUID_UNILABS : null);
        const onSubmit = this.onSubmit(labUuid ? labUuid : undefined);

        return (
            <CentralColumn width={CentralColumnWidth.THINER}>
                <div className={'with-logo-container'}>
                    {labUuid ? (
                        <PoweredBy
                            poweredWording={this.trans('powered_by', undefined, TransDomain.GLOBAL)}
                            logoMain={<EntityLogo entity={{id: labUuid, type: EntityType.LABORATORY}}/>}
                            logoPoweredBy={<LogoFull />}
                        />
                    ) : (
                        <LogoFull />
                    )}

                    <br />
                    <div>
                        {this.withBuilder(
                            <>
                                to <strong>email</strong> ok <br /> <br /> <i>then</i>
                            </>,
                        ).trans('trigger_by_identifier_email')}
                    </div>
                    <br />
                    <Form
                        onSubmit={onSubmit}
                    >{
                        (formState) : JSX.Element => (
                            <XLargeSpacer>
                                <FormInput
                                    name="identifier"
                                    field={{
                                        label: this.trans('trigger_by_identifier_email_input'),
                                        type: 'text',
                                        formatMethod: (value: string | number | string[]): string => {
                                            if (typeof value === 'string') {
                                                return value.trim()
                                            }
                                            return ''
                                        }
                                    }}
                                    validate={aggregate(required("email"), validateEmail())}

                                />
                                <Button id="trigger_notif_identifier_button" variant={ButtonVariant.PRIMARY}
                                        size={ButtonSize.LARGE} isSubmit disabled={formState.alreadySubmitted}
                                >
                                    {this.trans('trigger_by_identifier_send')}
                                </Button>
                            </XLargeSpacer>
                        )
                    }</Form>
                    <div className={'form_trigger_links'}>
                        <SimpleDropdownQuestion question={this.trans('trigger_by_identifier_no_email')}>
                            {this.withBuilder(
                                <>0<Link to={ROUTE_FAQ}>1</Link></>
                            ).trans('trigger_by_identifier_no_email_description')}
                        </SimpleDropdownQuestion>
                    </div>
                    <div className={'feedback-section'}>
                        <APIFeedbackMessageContainer
                            identifier={FEEDBACK_IDENTIFIER_API_TRIGGER_NOTIFICATION}
                            resetConditions={[
                                {
                                    id: 'identifier',
                                    action: 'click',
                                },
                            ]}
                            message_renderer={
                                (message?: APIFeedbackMessage): JSX.Element => {
                                    if (!message) {
                                        return (<div> </div>)
                                    }
                                    if (message.type === APIFeedbackMessageType.SUCCESS) {
                                        return (
                                            <Fragment>
                                                <Information withBorderRadius={true} severity={SeverityType.INFO}>
                                                    <div>
                                                        {this.trans('trigger_by_identifier_email_next_steps')}
                                                    </div>
                                                    <div>
                                                        <h4>
                                                            {this.trans('trigger_by_identifier_email_next_steps_if_not_mail')}
                                                        </h4>
                                                        <ul>
                                                            <li>
                                                                {this.trans('trigger_by_identifier_email_next_steps_if_not_mail_1')}
                                                            </li>
                                                            <li>
                                                                {this.withBuilder(
                                                                    <>
                                                                        You can get stuff <a className={"legacy-a"} rel="noreferrer" href={ROUTE_FAQ} target={"_blank"}>here</a>
                                                                    </>
                                                                ).trans('trigger_by_identifier_email_next_steps_if_not_mail_2')}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Information>
                                            </Fragment>
                                        )
                                    }

                                    return <Information withBorderRadius={true} severity={matchSeverity(message.type)}>
                                        {this.trans('default.ERROR', undefined, TransDomain.MESSAGES)}
                                    </Information>

                                }
                            }
                        />
                    </div>
                </div>
            </CentralColumn>
        );
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): TriggerNotificationDispatchProps => ({
    triggerNotification: (identifier: string, labUuid?: string): void => {
        dispatch(
            triggerNotificationByIdentifier({ identifier: identifier, labUuid: labUuid }, FEEDBACK_IDENTIFIER_API_TRIGGER_NOTIFICATION),
        );
    },
});

export default connect(null, mapDispatchToProps)(TriggerNotificationByIdentifier);
