import Button, { ButtonOption, ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import Image from "components/designSystem/components/Image";
import Inline, { InlineSpacing } from "components/designSystem/containers/Inline";
import { MediumSpacer, XLargeSpacer, XXLargeSpacer, XXXLargeSpacer } from "components/designSystem/containers/Spacer";
import { TDisplayXSmall, TDisplayXSmall700, TypographyColor } from "components/designSystem/containers/Typography";
import WithBackground, { WithBackgroundColor } from "components/designSystem/containers/WithBackground";
import WithMargin, {
    MarginSize,
    WithBottomMargin,
    WithLeftMargin
} from "components/designSystem/containers/WithMargin";
import Icon from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { KiroLogo, LogoColor } from "components/designSystem/foundations/logos/KiroLogo";
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { getPlatformResource } from "core/content/cdn";
import { getRoute } from 'core/routing/Helper';
import { ROUTE_FAQ } from 'core/routing/Routes';
import { capitalizeFirstLetter } from 'core/utils/text';
import React from 'react';

export enum KiroCommunicationType {
    PATIENT ,
    DOCTOR,
    RWE
}

export type KiroCommunicationProps = {
    type: KiroCommunicationType
};

export interface KiroCommunicationPointProps {
    iconName: IconName
    text: string;
}


const KiroCommunicationPoint = (props: KiroCommunicationPointProps): JSX.Element => (
    <Inline spacing={InlineSpacing.MEDIUM}>
        <Icon name={props.iconName} size={IconSize.SMALL} color={IconColor.ON_PRIMARY}/>
        <TDisplayXSmall color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>{props.text}</TDisplayXSmall>
    </Inline>
)


export default class KiroCommunication extends WithTranslations<KiroCommunicationProps> {
    TRANS_SUFFIX = TransDomain.COMMUNICATION

    private getDoctorCommunicationPoints = (): JSX.Element => <>
        <KiroCommunicationPoint
            iconName={IconName.REPORT}
            text={this.trans('com_patient_reports', undefined, undefined, capitalizeFirstLetter)}
        />
        <KiroCommunicationPoint
            iconName={IconName.SEARCH}
            text={this.trans('com_interpretations', undefined, undefined, capitalizeFirstLetter)}
        />
        <KiroCommunicationPoint
            iconName={IconName.PEN}
            text={this.trans('com_prescription', undefined, undefined, capitalizeFirstLetter)}
        />
    </>

    private getPatientCommunicationPoints = (): JSX.Element => <>
        <KiroCommunicationPoint
            iconName={IconName.REPORT}
            text={this.trans('com_detailed_report', undefined, undefined, capitalizeFirstLetter)}
        />
        <KiroCommunicationPoint
            iconName={IconName.CHART}
            text={this.trans('com_history', undefined, undefined, capitalizeFirstLetter)}
        />
    </>

    render(): JSX.Element {
        const isRWE = this.props.type == KiroCommunicationType.RWE
        const isDoctor = this.props.type == KiroCommunicationType.DOCTOR;
        const imageInfo = isRWE ? getPlatformResource('landing/evidence_login', '.svg') : undefined
        return (
            <WithBackground color={WithBackgroundColor.PRIMARY_LINEAR_GRADIENT} fitContent={isRWE}>
                {!isRWE ?
                    <WithMargin margins={[MarginSize.TEN_TIMES_MEDIUM, MarginSize.LARGE, undefined, MarginSize.LARGE]}>
                        <XXXLargeSpacer>
                            <KiroLogo color={LogoColor.WHITE}/>
                            <XXLargeSpacer>
                                <TDisplayXSmall700 color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                                    {this.trans(isDoctor ? 'com_header_doctor' : 'com_header', undefined, undefined, capitalizeFirstLetter)}
                                </TDisplayXSmall700>
                                <XLargeSpacer>
                                    {isDoctor ? this.getDoctorCommunicationPoints() : this.getPatientCommunicationPoints()}
                                </XLargeSpacer>
                                <Button
                                    variant={ButtonVariant.QUATERNARY}
                                    size={ButtonSize.LARGE}
                                    onClick={{href: getRoute(ROUTE_FAQ, {}), targetBlank: true}}
                                    option={ButtonOption.LIGHT}
                                >
                                    {this.trans('redirect_faq', undefined, undefined, capitalizeFirstLetter)}
                                </Button>
                            </XXLargeSpacer>
                        </XXXLargeSpacer>
                    </WithMargin> :
                    <WithMargin margins={[MarginSize.TEN_TIMES_MEDIUM, undefined, MarginSize.LARGE, undefined]}>
                        <MediumSpacer>
                            <WithLeftMargin margin={MarginSize.LARGE}>
                                <KiroLogo color={LogoColor.WHITE}/>
                            </WithLeftMargin>
                            {imageInfo && <WithBottomMargin margin={MarginSize.FIVE_TIMES_MEDIUM}>
                                <Image alt={imageInfo.alt} src={imageInfo.src}/>
                            </WithBottomMargin>}
                            <WithLeftMargin margin={MarginSize.LARGE}>
                                <Button
                                    variant={ButtonVariant.QUATERNARY}
                                    size={ButtonSize.LARGE}
                                    onClick={{href: getRoute(ROUTE_FAQ, {}), targetBlank: true}}
                                    option={ButtonOption.LIGHT}
                                >
                                    {this.trans('redirect_faq', undefined, undefined, capitalizeFirstLetter)}
                                </Button>
                            </WithLeftMargin>
                        </MediumSpacer>
                    </WithMargin>}
            </WithBackground>
        )
    }
}
