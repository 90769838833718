import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Pancreas = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-pancreas'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_153_230)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M4 0.25C3.58579 0.25 3.25 0.585786 3.25 1V2.16055C1.45228 3.25702 0.25 5.23754 0.25 7.5C0.25 10.9518 3.04822 13.75 6.5 13.75H8.75V15C8.75 15.4142 9.08579 15.75 9.5 15.75H12C12.4142 15.75 12.75 15.4142 12.75 15V11.8028C12.75 10.6306 12.2513 9.52438 11.3962 8.75006H11.5C12.4665 8.75006 13.25 7.96656 13.25 7.00006V6.75006L13.9616 6.75006C14.9493 6.75006 15.75 5.94936 15.75 4.96165C15.75 4.0307 15.0344 3.24542 14.09 3.18632C13.1529 3.12768 11.6631 3.04797 10.2595 3.03107C9.55834 3.02262 8.86903 3.02971 8.27629 3.0642C7.91537 3.08521 7.56046 3.11763 7.25 3.17148V1C7.25 0.585786 6.91421 0.25 6.5 0.25H4ZM5.75 3.71512V1.75H4.75V2.59971C4.75 2.88138 4.59218 3.1393 4.34138 3.26751C2.80152 4.05468 1.75 5.65519 1.75 7.5C1.75 10.1234 3.87665 12.25 6.5 12.25H9.49946C9.91367 12.25 10.25 12.5858 10.25 13V14.25H11.25V11.8028C11.25 11.234 11.0653 10.6897 10.7362 10.2449C10.2733 10.8556 9.5401 11.25 8.7146 11.25L7 11.25C5.159 11.25 3.25 9.91688 3.25 7.50006C3.25 5.57546 4.45038 4.39019 5.75 3.71512ZM7.23717 4.71157C7.41934 4.65085 7.7971 4.59463 8.36344 4.56167C8.90698 4.53004 9.55752 4.52272 10.2414 4.53096C11.6079 4.54741 13.0689 4.62535 13.9963 4.68339C14.135 4.69207 14.25 4.8074 14.25 4.96165C14.25 5.12093 14.1209 5.25006 13.9616 5.25006L12.9167 5.25006C12.2723 5.25006 11.75 5.77239 11.75 6.41673V7.00006C11.75 7.13813 11.6381 7.25006 11.5 7.25006H11.2143C10.4056 7.25006 9.75 7.90564 9.75 8.71433C9.75 9.28647 9.28644 9.75 8.7146 9.75L7 9.75C5.841 9.75 4.75 8.94924 4.75 7.50006C4.75 6.05824 5.84557 5.17544 7.23717 4.71157Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_153_230">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-pancreas'} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6 1C5.44772 1 5 1.44772 5 2V3.69527C2.37404 5.2764 1 8.13526 1 11.2857C1 16.1365 4.98945 20.5 10 20.5H13V22C13 22.5523 13.4477 23 14 23H18C18.5523 23 19 22.5523 19 22V17.0711C19 15.6441 18.4917 14.2696 17.5753 13.1895C18.6072 12.8992 19.3636 11.9512 19.3636 10.8265V10.3058L20.311 10.3058C21.7961 10.3058 23 9.10187 23 7.61677C23 6.18149 21.8705 4.98634 20.416 4.94447C19.0109 4.90402 16.8907 4.86236 14.9131 4.90062C13.9251 4.91974 12.9606 4.959 12.1329 5.03C11.738 5.06388 11.3527 5.10651 11 5.16269V2C11 1.73478 10.8946 1.48043 10.7071 1.29289C10.5196 1.10536 10.2652 1 10 1H6ZM9 5.8032V3H7V4.2853C7 4.66348 6.78666 5.0093 6.44869 5.17899C4.25272 6.28151 3 8.54306 3 11.2857C3 15.1137 6.17399 18.5 10 18.5H13.999C14.5513 18.5 15 18.9477 15 19.5V21H17V17.0711C17 16.2029 16.7177 15.3638 16.2041 14.6765C15.6715 16.0366 14.3477 17 12.7985 17L10.3636 17C7.70512 17 5 15.0377 5 11.5372C5 8.53498 6.96895 6.75571 9 5.8032ZM10.6863 7.27707C10.9647 7.18217 11.5108 7.09071 12.3038 7.02268C13.07 6.95696 13.9858 6.91894 14.9518 6.90025C16.8824 6.8629 18.9657 6.90355 20.3584 6.94364C20.7115 6.9538 21 7.24376 21 7.61677C21 7.9973 20.6915 8.30579 20.311 8.30579L18.9697 8.30579C18.0827 8.30579 17.3636 9.02485 17.3636 9.91185V10.8265C17.3636 11.0775 17.1601 11.281 16.9091 11.281H16.5171C15.378 11.281 14.4545 12.2045 14.4545 13.3436C14.4545 14.2586 13.7132 15 12.7985 15L10.3636 15C8.65852 15 7 13.7878 7 11.5372C7 9.30329 8.66134 7.96741 10.6863 7.27707Z"
                  fill="currentColor"/>
        </svg>
    )
}