// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getObject(objectName: string): any {
    const objStr = localStorage.getItem(objectName);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let obj: any = null;
    if (objStr) {
        try {
            obj = JSON.parse(objStr);
        } catch (e) {
            return null;
        }
    }

    return obj;
}

export function setLocalVariables(objectName: string, dict: {}): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let obj: any = getObject(objectName);

    if (!obj) {
        obj = {};
    }

    obj = {
        ...obj,
        ...dict,
    };

    localStorage.setItem(objectName, JSON.stringify(obj));
}

export function setLocalVariable(objectName: string, key: string, value: string | number | null): void {
    const dict: { [k: string]: string | number | null } = {};
    dict[key] = value;

    setLocalVariables(objectName, dict);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLocalObject(objectName: string, _default: any = null): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = getObject(objectName);

    if (!obj) {
        return _default;
    }

    return obj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLocalVariable(objectName: string, key: string, _default: any = null): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = getObject(objectName);

    if (!obj) {
        return _default;
    }

    return obj[key] ? obj[key] : _default;
}
