import React from 'react';

const Iron = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M13.3617 18.6343C10.858 16.1311 6.63275 14.6033 4.21527 17.0203C1.80693 19.4286 3.31138 23.6488 5.82929 26.1667C8.39488 28.7323 12.6085 30.148 14.9757 27.7807C17.3841 25.3719 15.8791 21.1522 13.3617 18.6343ZM12.186 23.9888C12.0778 24.3948 11.6586 24.6398 11.272 24.4753C10.6331 24.2034 9.8561 23.7374 9.05732 22.9387C8.25855 22.1399 7.79261 21.3629 7.52072 20.724C7.35614 20.3374 7.60114 19.9182 8.00723 19.81C8.41321 19.7019 8.8281 19.9464 9.01191 20.3242C9.22382 20.7597 9.57003 21.299 10.1337 21.8623C10.697 22.426 11.2363 22.7722 11.6718 22.9841C12.0496 23.1679 12.2941 23.5828 12.186 23.9888Z" />
            <path d="M27.7842 10.9335C25.3753 8.52513 21.1557 10.0301 18.6378 12.5475C16.1341 15.0512 14.6068 19.2765 17.0238 21.6939C19.4321 24.1023 23.6523 22.5978 26.1702 20.0799C28.6729 17.5772 30.2017 13.3515 27.7842 10.9335ZM22.9421 16.8519C22.1434 17.6506 21.3663 18.1166 20.7275 18.3885C20.3408 18.5531 19.9216 18.3081 19.8135 17.902C19.7054 17.496 19.9499 17.0811 20.3277 16.8973C20.7632 16.6854 21.3025 16.3392 21.8658 15.7755C22.4294 15.2122 22.7756 14.6729 22.9876 14.2374C23.1714 13.8596 23.5863 13.6151 23.9922 13.7232C24.3983 13.8314 24.6433 14.2506 24.4788 14.6372C24.2069 15.2761 23.7409 16.0531 22.9421 16.8519Z" />
            <path d="M9.10144 3.011C6.16609 3.011 3.01465 4.72088 3.01465 7.5761C3.01465 10.4176 6.14529 12.1412 9.10144 12.1412C12.0368 12.1412 15.1882 10.4313 15.1882 7.5761C15.1882 4.73458 12.0576 3.011 9.10144 3.011ZM9.10144 8.33695C8.17142 8.33695 7.39686 8.09904 6.86275 7.78469C6.49112 7.56597 6.53693 7.07221 6.87369 6.80286C7.1981 6.54339 7.7105 6.55146 8.10741 6.67407C8.36527 6.75373 8.69714 6.81525 9.10144 6.81525C9.50633 6.81525 9.83859 6.75355 10.0968 6.67352C10.4944 6.55027 11.0079 6.54191 11.3323 6.80278C11.6676 7.07238 11.7128 7.56496 11.3422 7.78347C10.808 8.09842 10.0327 8.33695 9.10144 8.33695Z" />
            <path d="M24.3691 24.4162C22.6622 24.4162 21.3257 25.419 21.3257 26.6988C21.3257 27.9785 22.6622 28.9813 24.3691 28.9813C26.0759 28.9813 27.4125 27.9785 27.4125 26.6988C27.4125 25.419 26.0759 24.4162 24.3691 24.4162Z" />
            <path d="M21.0757 4.098C19.869 2.8913 18.2144 2.65493 17.3095 3.55983C16.4046 4.46473 16.641 6.11933 17.8477 7.32603C18.9423 8.42064 20.6278 8.84976 21.6134 7.8637C22.6218 6.85532 22.1358 5.15812 21.0757 4.098Z" />
        </svg>
    )
}

export default Iron;
