import React from 'react';

const Bones = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M12.1913 3V6.90102C12.1913 8.68852 11.5932 10.4463 10.4625 11.9839C9.57885 13.186 9.33185 14.1917 9.72835 14.9742C10.2245 15.9561 11.4177 16 11.4296 16C13.5637 16 15.7559 15.2784 15.7596 15.277C17.1886 14.8039 18.5268 14.6596 19.5035 14.5547C20.7696 14.4178 21.6057 14.3278 21.9255 13.5995C22.2253 12.9196 21.7255 12.2397 21.5373 11.9839C20.4065 10.4463 19.8085 8.68857 19.8085 6.90102V3L12.1913 3Z" />
            <path d="M19.8085 29V25.099C19.8085 23.3115 20.4066 21.5537 21.5373 20.0161C22.421 18.814 22.668 17.8083 22.2715 17.0258C21.7753 16.0439 20.5821 16 20.5703 16C18.4361 16 16.244 16.7216 16.2402 16.723C14.8112 17.1961 13.473 17.3404 12.4963 17.4453C11.2302 17.5822 10.3942 17.6722 10.0743 18.4005C9.77453 19.0804 10.2744 19.7603 10.4626 20.0161C11.5933 21.5537 12.1914 23.3114 12.1914 25.099V29H19.8085Z" />
            <path d="M8.57504 10.6682C8.2335 10.4224 7.75458 10.4988 7.53558 10.8581C6.59254 12.4053 6.09766 14.1677 6.09766 16C6.09766 17.8323 6.59254 19.5947 7.53558 21.1419C7.75458 21.5012 8.2335 21.5776 8.57504 21.3318C8.91633 21.0862 8.99065 20.6121 8.77691 20.25C8.01877 18.9656 7.62109 17.5108 7.62109 16C7.62109 14.4892 8.01877 13.0344 8.77691 11.75C8.99065 11.3879 8.91633 10.9138 8.57504 10.6682Z" />
            <path d="M24.4644 10.8581C24.2454 10.4988 23.7665 10.4224 23.425 10.6682C23.0837 10.9138 23.0094 11.3879 23.2231 11.75C23.9812 13.0344 24.3789 14.4892 24.3789 16C24.3789 17.5108 23.9812 18.9656 23.2231 20.25C23.0094 20.6121 23.0837 21.0862 23.425 21.3318C23.7665 21.5776 24.2454 21.5012 24.4644 21.1419C25.4075 19.5947 25.9023 17.8323 25.9023 16C25.9023 14.1677 25.4075 12.4053 24.4644 10.8581Z" />
        </svg>
    )
}

export default Bones;
