import { buildCustomHalfPie } from 'components/commonDesign/charts/builders/CustomHalfPie';
import { buildCustomMap } from "components/commonDesign/charts/builders/CustomMap";
import { buildDistribution } from 'components/commonDesign/charts/builders/Distribution';
import { buildTimeSerie } from 'components/commonDesign/charts/builders/TimeSeries';
import { buildTube } from "components/commonDesign/charts/builders/Tube";
import { cast } from 'core/utils/Typed';
import { buildPie } from "components/commonDesign/charts/builders/Pie";
import { buildHistogram } from "components/commonDesign/charts/builders/Histogram";
import { Chart, ChartFilter, ChartType } from 'models/charts/charts';
import { ExtendedDistributionPayload } from 'models/charts/charts/Distribution';
import { ExtendedHalfPiePayload } from 'models/charts/charts/HalfPie';
import { ExtendedHistogramPayload } from "models/charts/charts/Histogram";
import { ExtendedMapPayload } from "models/charts/charts/Map";
import { TimeSeriePayload } from 'models/charts/charts/TimeSerie';
import { ValueFormatterInterface } from 'models/charts/models';
import { TranslationMethod } from 'models/common/message';
import { Margin } from "recharts/types/util/types";

export function buildChart(
    graph: Chart,
    translation: TranslationMethod,
    margin?: Partial<Margin>,
    filters?: ChartFilter[],
    valueFormatter?: ValueFormatterInterface,
    height?: number
): JSX.Element {
    switch (graph.type) {
        case ChartType.TIME_SERIE:
            return buildTimeSerie(
                graph.items,
                cast<TimeSeriePayload>(graph.payload),
                margin,
                height,
            )
        case ChartType.DISTRIBUTION:
            return buildDistribution(
                graph.items,
                cast<ExtendedDistributionPayload | null>(graph.payload),
                translation,
                margin,
                filters,
                valueFormatter
            )
        case ChartType.HALFPIE:
            return buildCustomHalfPie(
                graph.items,
                cast<ExtendedHalfPiePayload>(graph.payload),
                translation,
                valueFormatter
            )
        case ChartType.TUBE:
            return buildTube(
                graph.items,
                translation,
                filters,
                valueFormatter
            );
        case ChartType.MAP:
            return buildCustomMap(
                graph.items,
                cast<ExtendedMapPayload>(graph.payload),
                translation,
                filters,
                valueFormatter
            )
        case ChartType.HISTOGRAM:
            return buildHistogram(
                graph.items,
                cast<ExtendedHistogramPayload>(graph.payload),
                translation,
                filters,
                valueFormatter
            )
        case ChartType.PIE:
            return buildPie(
                graph.items,
                translation
            )
    }
}
