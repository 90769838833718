import React, { Component, FC, ReactNode, useState } from 'react'
import styles from 'components/designSystem/components/ratings/StarsRating.module.scss'
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import classNames from 'classnames';

export interface RatingStarProps {
    value: number;
    onMouseEnter: (value: number) => void;
    onMouseLeave: () => void;
    onClick: (value: number) => void;
    filled: boolean
}

export class RatingStar extends Component<RatingStarProps> {
    render(): ReactNode {
        return (
            <div
                key={this.props.value}
                className={classNames(
                    styles.ratingStar,
                    {[styles.ratingStarSelected]: this.props.filled}
                )}
                onMouseEnter={(): void => this.props.onMouseEnter(this.props.value)}
                onMouseLeave={this.props.onMouseLeave}
                onClick={(): void => this.props.onClick(this.props.value)}
            >
                <Icon name={this.props.filled ? IconName.FILL_STAR : IconName.STAR} size={IconSize.MEDIUM}/>
            </div>
        );
    }
}


export interface StarsRatingProps {
    rate: (rating: string | number) => void,
    numberOfStars: number
}

const StarsRating: FC<StarsRatingProps> = ({...props}): JSX.Element => {
    const [lastRating, setLastRating] = useState<number | undefined | null>(undefined);
    const [focusOn, setFocusOn] = useState<number | undefined | null>(undefined);

    return (
        <div className={styles.container} onMouseLeave={() : void => setFocusOn(undefined)}>
            {
                [...Array.from({length: props.numberOfStars}, (_, i) => i + 1)].map(
                    (rating: number) => (
                        <RatingStar
                            key={rating}
                            value={rating}
                            onMouseEnter={(value: number): void => setFocusOn(value)}
                            onMouseLeave={() : void => {
                                setFocusOn(lastRating)
                            }}
                            onClick={(value: number): void => {
                                setLastRating(value);
                                props.rate(value)
                            }}
                            filled={rating <= (focusOn ?? lastRating ?? -1)}
                        />
                    )
                )
            }
        </div>
    )
}

export default StarsRating;