import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Burger = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-burger'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_153_247)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M3 5H13C13 3.067 11.433 1.5 9.5 1.5H6.5C4.567 1.5 3 3.067 3 5ZM1.5 6.5V5C1.5 2.23858 3.73858 0 6.5 0H9.5C12.2614 0 14.5 2.23858 14.5 5V6.5H1.5ZM0.25 8C0.25 7.58579 0.585786 7.25 1 7.25H15C15.4142 7.25 15.75 7.58579 15.75 8C15.75 8.41421 15.4142 8.75 15 8.75H1C0.585786 8.75 0.25 8.41421 0.25 8ZM7.6459 11L9.32918 11.8416C9.75147 12.0528 10.2485 12.0528 10.6708 11.8416L12.3541 11H13C13 12.933 11.433 14.5 9.5 14.5L6.5 14.5C4.567 14.5 3 12.933 3 11H7.6459ZM14.5 9.5H12L10 10.5L8 9.5H1.5L1.5 11C1.5 13.7614 3.73858 16 6.5 16L9.5 16C12.2614 16 14.5 13.7614 14.5 11L14.5 9.5Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_153_247">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-burger'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5 8H19C19 5.23858 16.7614 3 14 3H10C7.23858 3 5 5.23858 5 8ZM3 10V8C3 4.13401 6.13401 1 10 1H14C17.866 1 21 4.13401 21 8V10H3ZM1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12ZM10 21C7.23858 21 5 18.7614 5 16L10.3944 16L12.8851 17.6604C13.5602 18.1105 14.4398 18.1105 15.1149 17.6604L17.6056 16L19 16C19 18.7614 16.7614 21 14 21L10 21ZM3 16V14H11L13.9945 15.9963C13.9978 15.9985 14.0022 15.9985 14.0055 15.9963L17 14H21V16C21 19.866 17.866 23 14 23L10 23C6.13401 23 3 19.866 3 16Z"
                  fill="currentColor"/>
        </svg>
    )
}