import React, { Component, PropsWithChildren, ReactNode } from 'react';
import './Information.scss';
import { SeverityType } from 'components/core/types/Types';
import classNames from 'classnames';

export interface InformationProps {
    severity?: SeverityType;
    withBorderRadius?: boolean;
    align?: 'center';
    noBackground?: boolean;
}

export default class Information extends Component<PropsWithChildren<InformationProps>> {
    render(): ReactNode {
        const { severity, withBorderRadius, align, noBackground } = this.props;
        const _classNames = classNames({
            information: true,
            'information-info': severity === SeverityType.INFO && !noBackground,
            'information-info-no-bg': severity === SeverityType.INFO && noBackground,
            'information-warning': severity === SeverityType.WARNING && !noBackground,
            'information-success': severity === SeverityType.SUCCESS && !noBackground,
            'information-failure': severity === SeverityType.FAILURE && !noBackground,
            'information-failure-no-bg': severity === SeverityType.FAILURE && noBackground,
            'information-radius': withBorderRadius === undefined ? true : withBorderRadius,
            'information-center': align === 'center',
        });

        return <div className={_classNames}>{this.props.children}</div>;
    }
}
