import React from 'react';

import BaseModule, { defaultBaseModuleState, ModuleState, setupModule } from 'components/modules/modular/BaseModule';
import { ModuleData } from 'models/modular/storage';
import Container, { ContainerProfile, MarginType, PaddingType } from 'components/legacyDesignSystem/components/Container';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import { APIText } from 'models/common/message';
import { Chart, ChartType } from 'models/charts/charts';
import { cast } from 'core/utils/Typed';
import { ExtendedMapPayload, MapPayload } from 'models/charts/charts/Map';
import TextLoader from 'components/legacyDesignSystem/components/TextLoader';
import { ModuleMenu } from 'models/modular/menu';
import ChartComponent from 'components/commonDesign/charts/ChartComponent';
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";

export interface SimpleMapPagePayload {
    title: APIText;
    sub_title: APIText;
    chart: Chart | null;
    allowed_context_selector_values: string[] | null;
    menu: ModuleMenu | null;
}

interface LocalState {
    selectedArea?: string;
    tooltipX?: number;
    tooltipY?: number;
    clickedArea?: string
}

const CLICK_ON_AREA_CONTEXT_KEY = 'click_on_area'

class SimpleMap extends BaseModule<{}, SimpleMapPagePayload, {}> {
    state: ModuleState<LocalState> = defaultBaseModuleState(
        {selectedArea: undefined,
            tooltipX: undefined,
            tooltipY: undefined,
            clickedArea: undefined}
    )

    componentDidMount(): void {
        if (this.props.payload && this.props.payload.pagePayload && this.props.payload.pagePayload.chart) {
            if (this.props.payload.pagePayload.chart.type === ChartType.MAP && this.props.payload.pagePayload.chart.payload) {
                this.setState({ clickedArea: cast<MapPayload>(this.props.payload.pagePayload.chart.payload).selected_area});
            }
        }
    }

    _render(payload: ModuleData<SimpleMapPagePayload, {}>): React.ReactNode {
        const {
            pagePayload: { title, sub_title, chart, menu },
        } = payload;

        const chartExtended: Chart<ExtendedMapPayload> = cast<Chart<MapPayload>>({
            ...chart,
            payload: {
                ...chart?.payload,
                onAreaHover: (areaId?: string): void => {
                    this.setState({ selectedArea: areaId });
                },
                onMapHover: (x?: number, y?: number): void => {
                    this.setState({
                        tooltipX: x,
                        tooltipY: y,
                    });
                },
                onAreaClick: (areaId: string) => {
                    this.setState(
                        {clickedArea: this.state.clickedArea === areaId ? undefined : areaId},
                        () => {
                            this.props.alterContext(
                                this.getConnectedContextKey(CLICK_ON_AREA_CONTEXT_KEY),
                                this.state.clickedArea
                            )
                        }
                    )

                },
                selected_area: this.state.selectedArea ? this.state.selectedArea : this.state.clickedArea,
                tooltipX: this.state.tooltipX,
                tooltipY: this.state.tooltipY,
            },
        });

        const displayLoader = payload.twinkle || !chart
        const content = <Container profiles={[{type: PaddingType.P, value: 4}]}>
            <Container profiles={[{ type: MarginType.MB, value: 8 }]}>
                <Container profiles={[
                    ContainerProfile.FLEX_WRAP,
                    ContainerProfile.SPACE_BETWEEN,
                    ContainerProfile.ALIGN_CENTER,
                ]}>
                    {title && (
                        <Typography weight={TypographyWeight.BOLD} variant={TypographyVariant.BODY}>
                            {this.transApiText(title)}
                        </Typography>
                    )}
                    {
                        menu &&
                        <div>
                            {this.buildModuleMenu(menu)}
                        </div>
                    }

                </Container>

                {sub_title && (
                    <Typography weight={TypographyWeight.REGULAR} variant={TypographyVariant.CAPTION}>
                        {this.transApiText(sub_title)}
                    </Typography>
                )}
            </Container>
            {chartExtended ? (
                <ChartComponent
                    chart={chartExtended}
                    translation={this.transApiText}
                />
            ) : (
                <Container profiles={[{ type: PaddingType.P, value: 30 }]}>
                    <TextLoader />
                </Container>
            )}
        </Container>

        return <Container profiles={[ContainerProfile.BORDER_MODULE]} style={{backgroundColor: "white"}}>
            {displayLoader ? <ModuleLoaderWrapper>{content}</ModuleLoaderWrapper> : content}
        </Container>;
    }
}

export default setupModule(
    SimpleMap,
    () => ({}),
    () => ({}),
);
