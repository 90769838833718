import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Ions = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-ions'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7.05396 0.335829C7.60507 -0.111943 8.39464 -0.111943 8.94574 0.335829C11.6218 2.51017 13.5103 5.96444 13.8801 9.05608C14.0662 10.6116 13.8849 12.24 13.032 13.5693C12.1409 14.9583 10.6616 15.7949 8.7449 15.9668C8.50096 15.9887 8.25257 16 7.99985 16C7.74713 16 7.49874 15.9887 7.2548 15.9668C5.33812 15.7949 3.85881 14.9583 2.96766 13.5693C2.11484 12.24 1.93355 10.6116 2.1196 9.05608C2.48938 5.96444 4.37785 2.51017 7.05396 0.335829ZM7.99985 1.5C3.22863 5.37662 1.26578 13.9237 7.38879 14.4728C7.5882 14.4907 7.79188 14.5 7.99985 14.5C8.20782 14.5 8.4115 14.4907 8.61092 14.4728C14.7339 13.9237 12.7711 5.37662 7.99985 1.5ZM8 4.25C8.41421 4.25 8.75 4.58579 8.75 5V5.75H9.5C9.91421 5.75 10.25 6.08579 10.25 6.5C10.25 6.91421 9.91421 7.25 9.5 7.25H8.75V8C8.75 8.41421 8.41421 8.75 8 8.75C7.58579 8.75 7.25 8.41421 7.25 8V7.25H6.5C6.08579 7.25 5.75 6.91421 5.75 6.5C5.75 6.08579 6.08579 5.75 6.5 5.75H7.25V5C7.25 4.58579 7.58579 4.25 8 4.25ZM6.5 10.75C6.08579 10.75 5.75 11.0858 5.75 11.5C5.75 11.9142 6.08579 12.25 6.5 12.25H9.5C9.91421 12.25 10.25 11.9142 10.25 11.5C10.25 11.0858 9.91421 10.75 9.5 10.75H6.5Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-ions'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.0001 6.5C12.6076 6.5 13.1001 6.99249 13.1001 7.6V8.7L14.2001 8.7C14.8076 8.7 15.3001 9.19249 15.3001 9.8C15.3001 10.4075 14.8076 10.9 14.2001 10.9H13.1001V12C13.1001 12.6075 12.6076 13.1 12.0001 13.1C11.3926 13.1 10.9001 12.6075 10.9001 12V10.9H9.8001C9.19258 10.9 8.7001 10.4075 8.7001 9.8C8.7001 9.19249 9.19258 8.7 9.8001 8.7H10.9001V7.6C10.9001 6.99249 11.3926 6.5 12.0001 6.5ZM9.8001 15.3C9.19258 15.3 8.7001 15.7925 8.7001 16.4C8.7001 17.0075 9.19258 17.5 9.8001 17.5H14.2001C14.8076 17.5 15.3001 17.0075 15.3001 16.4C15.3001 15.7925 14.8076 15.3 14.2001 15.3H9.8001Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.6256 1.48209C11.4291 0.839304 12.5708 0.839304 13.3742 1.48209C17.0795 4.44632 19.6797 9.17175 20.1831 13.4134C20.4366 15.5491 20.183 17.8023 18.9867 19.6466C17.735 21.5763 15.6681 22.7254 13.0191 22.956C12.6852 22.985 12.3455 23 11.9999 23C11.6544 23 11.3146 22.985 10.9807 22.956C8.3317 22.7254 6.26476 21.5763 5.01313 19.6466C3.81685 17.8023 3.5632 15.5491 3.81666 13.4134C4.32006 9.17175 6.92028 4.44632 10.6256 1.48209ZM11.9999 3.2C5.45643 8.43478 2.82332 20.0377 11.1715 20.7642C11.4419 20.7878 11.718 20.8 11.9999 20.8C12.2818 20.8 12.5579 20.7878 12.8283 20.7642C21.1765 20.0377 18.5434 8.43478 11.9999 3.2Z"
                  fill="currentColor"/>
        </svg>
    )
}