import React from 'react';
import GridRow, { GridRowItem } from 'components/designSystem/containers/GridRow';
import WithMargin, { MarginSize, WithBottomMargin } from 'components/designSystem/containers/WithMargin';
import Inline, {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from 'components/designSystem/containers/Inline';
import { TBody500, TCaption, TypographyColor } from 'components/designSystem/containers/Typography';
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import { XSmallSpacer } from "components/designSystem/containers/Spacer";
import { BASE_GRID_SPLIT, CategoricalReportLineProps } from 'components/modules/modular/modules/report/content/models';
import Pill from 'components/designSystem/components/Pill';
import {
    formatComments,
    formatTags,
    MobileResultMenu
} from 'components/modules/modular/modules/report/content/lines/utils';
import { WhiteBoxWithShadow } from 'components/designSystem/containers/Box';
import Hoverable from 'components/designSystem/containers/Hoverable';
import { IconName } from "components/designSystem/foundations/IconsData";
import WithTooltip from "components/designSystem/components/WithTooltip";
import TrText from "components/common/TrText";
import { toLocalTransKey } from "models/common/message";
import { TransDomain } from 'components/pages/common/MainComponent';
import { ModuleReportDataTagType } from "models/modular/report";

export const CategoricalLine = (
    props: CategoricalReportLineProps
): JSX.Element => {
    const notIconTags = props.tags ? props.tags.filter(
        (tag) => !(tag.payload.type === ModuleReportDataTagType.ICON)
    ) : []
    const iconTags = props.tags ? props.tags.filter(
        (tag) => tag.payload.type === ModuleReportDataTagType.ICON
    ) : []

    return props.isMobile ?
        <WhiteBoxWithShadow withOverflow>
            <WithMargin margins={[MarginSize.MEDIUM, MarginSize.MEDIUM]}>
                <XSmallSpacer>
                    <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                        <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                            <Pill severity={props.normality}/>
                            <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.name}</TCaption>
                        </AlwaysInline>
                        {formatTags(iconTags)}
                    </AlwaysInline>
                    {formatTags(notIconTags)}
                </XSmallSpacer>
            </WithMargin>
            <WithMargin margins={[MarginSize.SMALL, MarginSize.X_SMALL, MarginSize.SMALL, MarginSize.LARGE]}>
                <TBody500 color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.value}</TBody500>
            </WithMargin>
            {props.interpretation && <WithMargin margins={[MarginSize.SMALL, MarginSize.MEDIUM, MarginSize.LARGE, MarginSize.MEDIUM]}>
                <TCaption><TrText input={props.interpretation}/></TCaption>
            </WithMargin>}
            <WithBottomMargin margin={MarginSize.SMALL}>
                {formatComments(props.comments, props.isMobile)}
            </WithBottomMargin>
            <MobileResultMenu
                onDetailClick={props.onDetailClick}
                onHistoryClick={props.onHistoryClick}
            />
        </WhiteBoxWithShadow>
        :
        <>
            <Hoverable>
                <GridRow columnNb={BASE_GRID_SPLIT}>
                    <GridRowItem from={1} to={8}>
                        <WithMargin margins={[MarginSize.SMALL, MarginSize.MEDIUM]}>
                            <XSmallSpacer>
                                <Inline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                                    <Inline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                                        <Pill severity={props.normality}/>
                                        <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.name}</TCaption>
                                    </Inline>
                                    {formatTags(iconTags)}
                                </Inline>
                                {formatTags(notIconTags)}
                            </XSmallSpacer>
                        </WithMargin>
                    </GridRowItem>
                    <GridRowItem from={8} to={20}>
                        <WithMargin
                            margins={[MarginSize.SMALL, MarginSize.X_SMALL, MarginSize.SMALL, MarginSize.MEDIUM]}>
                            <TBody500 color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.value}</TBody500>
                        </WithMargin>
                    </GridRowItem>
                    <GridRowItem from={20} to={22}>
                        <WithMargin margins={[MarginSize.X_SMALL, MarginSize.SMALL]}>
                            <Inline justifyContent={InlineJustifyContent.FLEX_END}>
                                <Inline spacing={InlineSpacing.SMALL}>
                                    <WithTooltip tooltip={
                                        <TrText capitalize
                                                input={toLocalTransKey("report.details", TransDomain.MODULES)}/>
                                    }>
                                        <Button
                                            icons={{middle: IconName.INFO_CIRCLE}}
                                            variant={ButtonVariant.TERTIARY}
                                            size={ButtonSize.SMALL}
                                            onClick={props.onDetailClick}
                                            disabled={!props.onDetailClick}
                                        />
                                    </WithTooltip>
                                    <WithTooltip tooltip={
                                        <TrText capitalize
                                                input={toLocalTransKey("report.history", TransDomain.MODULES)}/>
                                    }>
                                        <Button
                                            icons={{middle: IconName.CHART}}
                                            variant={ButtonVariant.TERTIARY}
                                            size={ButtonSize.SMALL}
                                            onClick={props.onHistoryClick}
                                            disabled={!props.onHistoryClick}
                                        />
                                    </WithTooltip>
                                </Inline>
                            </Inline>
                        </WithMargin>
                    </GridRowItem>
                </GridRow>
            </Hoverable>
            {props.interpretation && <WithMargin margins={[MarginSize.MEDIUM, MarginSize.SMALL]}><TCaption><TrText input={props.interpretation}/></TCaption></WithMargin>}
            {formatComments(props.comments)}
        </>
}
