import React, { Component, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import 'components/core/text/Title.scss';

export enum TitleType {
    DEFAULT = 'default',
    TITLE = 'title',
    HEADING1 = 'heading1',
    HEADING2 = 'heading2',
    HEADING3 = 'heading3',
    HEADING4 = 'heading4',
}

export interface TitleProps {
    type?: TitleType;
    className?: string;
    align?: 'center';
}

export default class Title extends Component<PropsWithChildren<TitleProps>> {
    render(): ReactNode {
        const { children, type, className, align } = this.props;
        const type_ = type ? type : TitleType.DEFAULT;
        return (
            <div
                className={classNames(
                    `title title-${type_}`,
                    {
                        'title-centered': align === 'center',
                    },
                    className,
                )}
            >
                {children}
            </div>
        );
    }
}
