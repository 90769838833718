import classNames from 'classnames';
import { CountryFlagName } from "components/designSystem/assets/countryData";
import Icon, { NullIcon } from 'components/designSystem/foundations/Icons';
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import React, { ReactNode, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { getCountryFlag } from "../assets/countryFlags";
import styles from './TextField.module.scss';

export type TextFieldFieldTypes = string | string[] | number;

export enum RightIconType {
    INFORMATIVE,
    ERROR_ON_EMPTY,
}

export interface RightIconInfo {
    type: RightIconType,
    icon: IconName
    onClick?: () => void;
}

export interface TextFieldProps {
    label: ReactNode,
    name?: string,
    type?: "text" | "textarea" | "password" | "date" | "tel",
    defaultValue?: TextFieldFieldTypes,
    leftIconOrFlag?: { icon?: IconName, flag?: CountryFlagName },
    description?: ReactNode,
    required?: boolean,
    disabled?: boolean,
    error?: ReactNode,
    formatMethod?: (input: string, previousValue?: string) => string,
    checkMethod?: (newValue: string) => boolean,
    placeholder?: string,
    // @ts-ignore
    onChange?: (event) => void;
    // @ts-ignore
    onFocus?: (event) => void;
    // @ts-ignore
    onKeyDown?: (event) => void;
    // @ts-ignore
    onClick?: (event) => void;
    showPlaceHolder?: boolean;
    withMargins?:boolean;
    hideLabelOnActive?: boolean;
    autoCompletion?: "current-password" | "new-password" | "username" | "email" | "tel" | "bday" | "given-name" | "family-name"
    rightIcon?: RightIconInfo
    /**
     * Auto resize the height so that it expands & shrinks to fit all the content as the user types it. Only has an effect if the type is `textarea`.
     */
    autoSize?: boolean
    rows?: number
    /**
     * Use a smaller font (if very long text is expected).
     */
    compact?: boolean
}

function TextField(
    {
        type = "text", placeholder = " ",
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        formatMethod = (data: string, previousValue?: string): string => {
            return data
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        checkMethod = (newValue: string): boolean => {
            return true
        },
        showPlaceHolder = false,
        withMargins = true,
        ...props
    }: TextFieldProps
): JSX.Element {
    const [value, setValue] = useState(props.defaultValue ? props.defaultValue : "");
    // const [readOnly, setReadOnly] = useState(true);
    const [isPasswordVisible, setPasswordVisibility] = useState(type === 'password' ? false : undefined);
    const onClick = props.onClick;
    const [isFocused, setIsFocused] = useState(false);
    const onClearClick: (event: React.MouseEvent) => void = (event) => {
        event.preventDefault()
        setValue("")
        if (props.onChange) {
            props.onChange("")
        }
    };

    const passwordVisibilityClick: () => void = () => {
        setPasswordVisibility(!isPasswordVisible)
    }

    const handleFocus = (): void => {
        setIsFocused(!isFocused);
    }

    const handleUnFocus = (): void => {
        setIsFocused(!isFocused);
    }

    const isEmpty = !value;
    const isInvalid = !!props.error;

    const inputClassName = classNames(
        styles.input,
        {
            [styles.inputShowPlaceHolder]: showPlaceHolder,
            [styles.inputCompact]: props.compact,
        }
    )

    const labelHideNameOnActive = classNames(
        styles.label,
        {
            [styles.labelHideOnActive]: props.hideLabelOnActive,
            [styles.labelCompact]: props.compact,
        }
    )

    return (
        <div className={
            classNames(
                styles.wrapper,
                {
                    [styles.wrapperDisabled]: props.disabled,
                    [styles.wrapperInvalid]: isInvalid,
                    [styles.wrapperClickable]: !!props.onClick,
            })}
            onClick={
                onClick
                    ? (e): void => {
                        e.preventDefault();
                        onClick(e)
                } : undefined
            }
        >
            <div className={classNames(
                styles.inputWrapper,
                {
                    [styles.inputWrapperWithTextArea]: type === 'textarea',
                    [styles.inputWrapperWithMargin]: withMargins
                }
            )}>
                {
                    props.leftIconOrFlag?.icon &&
                    <div className={styles.leftIcon}>
                        <Icon name={props.leftIconOrFlag.icon} size={IconSize.SMALL}/>
                    </div>
                }
                {
                    props.leftIconOrFlag?.flag && getCountryFlag(props.leftIconOrFlag.flag)
                }

                {
                    type === 'textarea' ?
                        (props.autoSize ? <TextareaAutosize
                            required={props.required}
                            className={inputClassName}
                            onChange={(v): void => {
                                // @ts-ignore
                                setValue(v.target.value)
                                if (props.onChange) {
                                    props.onChange(v)
                                }
                            }}
                            placeholder={placeholder}
                            disabled={props.disabled}
                            value={value}
                            name={props.name}
                            autoComplete={props.autoCompletion ?? "off"}
                        /> : <textarea
                            required={props.required}
                            className={inputClassName}
                            onChange={(v): void => {
                                // @ts-ignore
                                if (checkMethod(v.target.value)) {
                                    const vf = formatMethod(
                                        v.target.value, value ? value.toString() : undefined
                                    )
                                    setValue(vf);
                                    if (props.onChange) {
                                        props.onChange(vf)
                                    }
                                }
                            }}
                            placeholder={placeholder}
                            disabled={props.disabled}
                            value={value}
                            name={props.name}
                            rows={props.rows}
                            autoComplete={props.autoCompletion ?? "off"}
                            onFocus={handleFocus}
                            onBlur={handleUnFocus}
                        />) :
                        <input
                            //ref={inputEl}
                            required={props.required}
                            className={inputClassName}
                            type={
                                type === 'password' && isPasswordVisible ? 'text' : type
                            }
                            name={props.name}
                            onKeyDown={props.onKeyDown}
                            onChange={(v): void => {
                                // @ts-ignore
                                if (checkMethod(v.target.value)) {
                                    const vf = formatMethod(
                                        v.target.value, value ? value.toString() : undefined
                                    )
                                    setValue(vf);
                                    if (props.onChange) {
                                        props.onChange(vf)
                                    }
                                }
                            }}
                            placeholder={placeholder}
                            disabled={props.disabled}
                            value={value}
                            autoComplete={props.autoCompletion ?? "off"}
                            onFocus={handleFocus}
                            onBlur={handleUnFocus}
                        />
                }
                {
                    isInvalid &&
                    <div className={styles.invalidIcon}>
                        <Icon name={IconName.WARNING_CIRCLE} size={IconSize.SMALL}/>
                    </div>
                }
                {
                    ["text", "textarea"].includes(type) && props.rightIcon && isEmpty && !props.disabled &&
                    <div className={classNames(styles.rightIcon, styles.rightIconClickable)}
                         onClick={props.rightIcon.onClick}>
                        <Icon
                            name={props.rightIcon.icon}
                            size={IconSize.SMALL}
                            color={props.rightIcon.type == RightIconType.ERROR_ON_EMPTY ? IconColor.CRITICAL : undefined}/>
                    </div>
                }
                {
                    isFocused && ["text", "textarea"].includes(type) && !isEmpty && !props.disabled &&
                    <div className={classNames(styles.rightIcon, {[styles.rightIconClickable]: !isEmpty})} onMouseDown={onClearClick}>
                        <Icon name={IconName.CLOSE_SM} size={IconSize.SMALL} />
                    </div>
                }
                {
                    // If no right icon is displayed, put a null icon to make sure there's space for one to appear
                    ["text", "textarea"].includes(type) && !isInvalid && isEmpty && !props.rightIcon &&
                    <div className={classNames(styles.rightIcon, {[styles.rightIconClickable]: !isEmpty})} onClick={onClearClick}>
                        <NullIcon size={IconSize.SMALL}/>
                    </div>
                }
                {
                    (type === "password") && !props.disabled &&
                    <div className={classNames(styles.rightIcon, styles.rightIconClickable)} onClick={passwordVisibilityClick}>
                        <Icon
                            size={IconSize.SMALL}
                            name={
                            props.rightIcon
                                ? props.rightIcon.icon
                                : isPasswordVisible
                                ? IconName.EYE_OFF
                                : IconName.EYE}/>
                    </div>
                }
                <div className={styles.notch}>
                    <div className={classNames(
                        styles.notchStart,
                        {
                            [styles.notchStartWithIcon]: !!props.leftIconOrFlag
                        }
                    )}/>
                    <div className={styles.notchMiddle}>
                        <div className={labelHideNameOnActive}>{props.label}{props.required ?
                            <span className={styles.labelRequired}> *</span> : ""}</div>
                    </div>
                    <div className={styles.notchEnd}/>
                </div>
            </div>
            {
                (props.description || props.error) &&
                <div className={styles.description}>
                    {props.error ? props.error : props.description}
                </div>
            }
        </div>
    )
}

export default TextField;
