import React, { Component, PropsWithChildren, ReactNode } from 'react';
import './Shaker.scss';
import classNames from 'classnames';

export interface ShakerProps {
    shake: boolean;
}

export default class Shaker extends Component<PropsWithChildren<ShakerProps>> {
    render(): ReactNode {
        return (
            <div className={'shaker-container'}>
                <div className={classNames({ 'shaked-box': this.props.shake })}>{this.props.children}</div>
            </div>
        );
    }
}
