import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Hormones = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-hormones'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_153_255)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12.2665 0.25C12.6808 0.25 13.0165 0.585786 13.0165 1V2.07536L15.1524 3.35688C15.3783 3.49242 15.5165 3.73655 15.5165 4V7C15.5165 7.26345 15.3783 7.50758 15.1524 7.64312L13.0165 8.92464V11.5C13.0165 11.7634 12.8783 12.0076 12.6524 12.1431L10.5165 13.4246V14.5C10.5165 14.9142 10.1808 15.25 9.76655 15.25C9.35234 15.25 9.01655 14.9142 9.01655 14.5V13.4246L7.26655 12.3746L5.15242 13.6431C4.91491 13.7856 4.61819 13.7856 4.38068 13.6431L2.25923 12.3703L1.37513 12.8807C1.01641 13.0878 0.557713 12.9649 0.350606 12.6062C0.143499 12.2475 0.266406 11.7888 0.625125 11.5817L1.51655 11.067L1.51655 8.5C1.51655 8.23655 1.65477 7.99242 1.88068 7.85688L4.01655 6.57536L4.01655 4C4.01655 3.73655 4.15477 3.49242 4.38068 3.35688L6.88068 1.85688C7.11819 1.71437 7.41491 1.71437 7.65242 1.85688L9.76655 3.12536L11.5165 2.07536V1C11.5165 0.585786 11.8523 0.25 12.2665 0.25ZM12.2665 3.37464L10.5165 4.42464V6.57536L12.2665 7.62536L14.0165 6.57536V4.42464L12.2665 3.37464ZM11.5165 8.92464L9.76655 7.87464L8.01655 8.92464V11.0754L9.76655 12.1254L11.5165 11.0754V8.92464ZM6.51655 11.0754V8.92464L4.76655 7.87464L3.01655 8.92464L3.01655 11.0754L4.76655 12.1254L6.51655 11.0754ZM5.51655 6.57536L7.26655 7.62536L9.01655 6.57536V4.42464L7.26655 3.37464L5.51655 4.42464V6.57536Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_153_255">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-hormones'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.0746 1.5C18.6269 1.5 19.0746 1.94772 19.0746 2.5V4.41968L22.0708 6.13176C22.3823 6.3098 22.5746 6.64115 22.5746 7V11C22.5746 11.3589 22.3823 11.6902 22.0708 11.8682L19.0746 13.5803L19.0746 17C19.0746 17.3589 18.8823 17.6902 18.5708 17.8682L15.5746 19.5803V21.5C15.5746 22.0523 15.1269 22.5 14.5746 22.5C14.0223 22.5 13.5746 22.0523 13.5746 21.5V19.5803L11.0746 18.1517L8.07077 19.8682C7.76334 20.0439 7.38593 20.0439 7.07849 19.8682L4.08939 18.1602L2.5182 19.1118C2.04581 19.3979 1.43092 19.2469 1.1448 18.7745C0.858686 18.3021 1.0097 17.6872 1.48209 17.4011L3.07463 16.4365V13C3.07463 12.6411 3.26692 12.3098 3.57849 12.1318L6.57463 10.4197V7C6.57463 6.64114 6.76691 6.3098 7.07849 6.13175L10.5785 4.13176C10.8859 3.95608 11.2633 3.95608 11.5708 4.13176L14.5746 5.84825L17.0746 4.41968V2.5C17.0746 1.94772 17.5223 1.5 18.0746 1.5ZM18.0746 6.15175L15.5746 7.58032V10.4197L18.0746 11.8483L20.5746 10.4197V7.58033L18.0746 6.15175ZM17.0746 13.5803L14.5746 12.1518L12.0746 13.5803L12.0746 16.4197L14.5746 17.8482L17.0746 16.4197L17.0746 13.5803ZM10.0746 16.4197L10.0746 13.5803L7.57462 12.1518L5.07463 13.5803V16.4197L7.57463 17.8482L10.0746 16.4197ZM8.57463 10.4197L11.0746 11.8482L13.5746 10.4197V7.58032L11.0746 6.15175L8.57463 7.58032V10.4197Z"
                  fill="currentColor"/>
        </svg>
    )
}