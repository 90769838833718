import React from "react";
import { Field as FinalField } from "react-final-form";
import TextField, { TextFieldFieldTypes, TextFieldProps } from 'components/designSystem/components/TextField';
import { FieldValidator } from 'final-form';

export interface FormInputProps {
    name: string;
    field: TextFieldProps;
    validate?: FieldValidator<TextFieldFieldTypes>;
    postFormat?: (value?: string) => TextFieldFieldTypes;
}

function FormInput(props: FormInputProps): JSX.Element {
    return (
        <FinalField
            name={props.name}
            component={'input'}
            defaultValue={props.field.defaultValue}
            validate={props.validate}
            parse={props.postFormat}
        >
            {
                ({ input, meta }): JSX.Element => {
                    return <TextField
                        name={props.name}
                        { ...props.field}
                        onChange={input.onChange}
                        error={
                            (meta.touched || meta.modified) && meta.error ? meta.error : undefined
                        }
                    />
                }
            }
        </FinalField>
    )

}

export default FormInput