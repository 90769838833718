export enum TimeSelectorType {
    ALL_TIME = 'ALL_TIME',
    MONTH = 'MONTH',
    YEAR= 'YEAR'
}

export interface TimeSelector {
    type: TimeSelectorType
    quantity: number
}


export interface TimeSeriePayload {
    selectors: TimeSelector[]
    /**
     * Appended locally to the payload (should be improved to separate local state from server state).
     */
    selected?: TimeSelector,
}