import React from 'react';

const Stomach = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M21.7383 6.65625C17.7341 6.65625 14.4766 9.91379 14.4766 13.9138L14.4436 19.854C14.4415 20.22 14.2488 20.5439 13.9283 20.7205C13.6075 20.897 13.2308 20.8865 12.9204 20.6925C11.8887 20.0478 10.7005 19.707 9.48393 19.707C5.90862 19.707 3 22.6156 3 26.191V28.2383C3 28.659 3.34099 29 3.76172 29H6.80859C7.22932 29 7.57031 28.659 7.57031 28.2383V26.1781C7.57031 25.6901 7.96724 25.293 8.45541 25.293C8.67659 25.293 8.88844 25.3751 9.0519 25.5243L9.53035 25.9609C11.678 27.9207 14.4623 29 17.3697 29C20.4763 29 23.397 27.7902 25.5937 25.5937C27.7902 23.3968 29 20.4763 29 17.3697V13.918C29 9.91379 25.7425 6.65625 21.7383 6.65625V6.65625Z" />
            <path d="M14.16 9.47659C14.976 8.08963 16.1619 6.94507 17.5814 6.17978C17.3427 5.58389 17.2188 4.94575 17.2188 4.2979V3.76172C17.2188 3.34099 16.8778 3 16.457 3H13.4102C12.9894 3 12.6484 3.34099 12.6484 3.76172V4.2979C12.6484 6.08833 13.1459 7.83989 14.0874 9.36293C14.111 9.40121 14.1358 9.43871 14.16 9.47659Z" />
        </svg>
    )
}

export default Stomach;
