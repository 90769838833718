import React from 'react';

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Antibodies = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg  id={'xs-antibodies'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_153_231)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M1.46967 0.46967C1.76256 0.176777 2.23744 0.176777 2.53033 0.46967L7.53033 5.46967C7.67098 5.61032 7.75 5.80109 7.75 6V15C7.75 15.4142 7.41421 15.75 7 15.75C6.58579 15.75 6.25 15.4142 6.25 15V6.31066L1.46967 1.53033C1.17678 1.23744 1.17678 0.762563 1.46967 0.46967ZM14.5303 0.46967C14.8232 0.762563 14.8232 1.23744 14.5303 1.53033L9.75 6.31066V15C9.75 15.4142 9.41421 15.75 9 15.75C8.58579 15.75 8.25 15.4142 8.25 15V6C8.25 5.80109 8.32902 5.61032 8.46967 5.46967L13.4697 0.46967C13.7626 0.176777 14.2374 0.176777 14.5303 0.46967ZM0.46967 2.46967C0.762563 2.17678 1.23744 2.17678 1.53033 2.46967L5.03033 5.96967C5.32322 6.26256 5.32322 6.73744 5.03033 7.03033C4.73744 7.32322 4.26256 7.32322 3.96967 7.03033L0.46967 3.53033C0.176777 3.23744 0.176777 2.76256 0.46967 2.46967ZM15.5303 2.46967C15.8232 2.76256 15.8232 3.23744 15.5303 3.53033L12.0303 7.03033C11.7374 7.32322 11.2626 7.32322 10.9697 7.03033C10.6768 6.73744 10.6768 6.26256 10.9697 5.96967L14.4697 2.46967C14.7626 2.17678 15.2374 2.17678 15.5303 2.46967Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_153_231">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg  id={'s-antibodies'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.7032 1.28896C22.0959 1.6773 22.0994 2.31046 21.711 2.70315L15 9.48942V22C15 22.5523 14.5523 23 14 23C13.4477 23 13 22.5523 13 22V9.07847C13 8.81518 13.1038 8.56252 13.289 8.37531L20.289 1.29685C20.6773 0.904151 21.3105 0.900622 21.7032 1.28896ZM2.2929 1.29289C2.68342 0.902369 3.31659 0.902369 3.70711 1.29289L10.7071 8.29289C10.8946 8.48043 11 8.73478 11 9V22C11 22.5523 10.5523 23 10 23C9.44772 23 9.00001 22.5523 9.00001 22V9.41421L2.2929 2.70711C1.90237 2.31658 1.90237 1.68342 2.2929 1.29289ZM22.7433 5.33104C23.1128 5.74155 23.0795 6.37384 22.669 6.74329L17.669 11.2433C17.2585 11.6128 16.6262 11.5795 16.2567 11.169C15.8872 10.7585 15.9205 10.1262 16.331 9.75671L21.331 5.25671C21.7415 4.88725 22.3738 4.92053 22.7433 5.33104ZM1.29289 5.29289C1.68342 4.90237 2.31658 4.90237 2.70711 5.29289L7.20711 9.79289C7.59764 10.1834 7.59764 10.8166 7.20711 11.2071C6.81659 11.5976 6.18342 11.5976 5.7929 11.2071L1.29289 6.70711C0.902369 6.31658 0.902369 5.68342 1.29289 5.29289Z"
                  fill="currentColor"/>
        </svg>
    )
}