import React from 'react';

const Prostate = (): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32">
            <path d="M16.0001 3C9.77643 3 4.71313 6.71846 4.71313 11.289C4.71313 11.4974 4.72385 11.708 4.74503 11.9151C4.85187 12.9582 5.36948 13.8906 6.20265 14.5406C7.06268 15.2116 8.18327 15.5057 9.2772 15.3475C9.51475 15.3135 9.75744 15.2954 10.0043 15.2954C12.7858 15.2954 15.0487 17.5583 15.0487 20.3398C15.0487 20.8644 15.4755 21.2912 16 21.2912C16.5246 21.2912 16.9514 20.8644 16.9514 20.3398C16.9514 17.5583 19.2143 15.2954 21.9958 15.2954C22.2427 15.2954 22.4853 15.3135 22.7228 15.348C23.8168 15.5056 24.9374 15.2117 25.7974 14.5406C26.6305 13.8906 27.1482 12.9582 27.255 11.9151C27.2762 11.7081 27.2869 11.4974 27.2869 11.2891C27.287 6.71846 22.2237 3 16.0001 3V3Z" />
            <path d="M15.0488 22.6242V28.0486C15.0488 28.5741 15.4748 29 16.0002 29C16.5256 29 16.9516 28.5741 16.9516 28.0486V22.6242C16.6585 22.7466 16.3372 22.8146 16.0002 22.8146C15.6633 22.8146 15.3419 22.7467 15.0488 22.6242Z" />
            <path d="M10.0044 16.8188C8.0629 16.8188 6.4834 18.3983 6.4834 20.3398C6.4834 24.1705 9.21564 27.2869 12.574 27.2869C13.0986 27.2869 13.5253 26.8602 13.5253 26.3356V20.3399C13.5253 18.3983 11.9458 16.8188 10.0044 16.8188ZM9.90951 20.3398C9.90951 20.7604 9.56848 21.1015 9.14779 21.1015C8.72711 21.1015 8.38607 20.7604 8.38607 20.3398C8.38607 19.9191 8.72711 19.578 9.14779 19.578C9.56848 19.578 9.90951 19.9191 9.90951 20.3398ZM11.6226 23.7659C11.6226 24.1866 11.2816 24.5276 10.8609 24.5276C10.4402 24.5276 10.0992 24.1866 10.0992 23.7659C10.0992 23.3452 10.4402 23.0042 10.8609 23.0042C11.2816 23.0042 11.6226 23.3452 11.6226 23.7659Z" />
            <path d="M21.9958 16.8188C20.0544 16.8188 18.4749 18.3983 18.4749 20.3398V26.3356C18.4749 26.8601 18.9016 27.2869 19.4262 27.2869C22.7846 27.2869 25.5168 24.1704 25.5168 20.3398C25.5168 18.3983 23.9373 16.8188 21.9958 16.8188ZM21.901 23.7659C21.901 24.1866 21.56 24.5276 21.1393 24.5276C20.7186 24.5276 20.3776 24.1866 20.3776 23.7659C20.3776 23.3452 20.7186 23.0042 21.1393 23.0042C21.56 23.0042 21.901 23.3452 21.901 23.7659ZM23.6141 20.3398C23.6141 20.7604 23.2731 21.1015 22.8524 21.1015C22.4317 21.1015 22.0907 20.7604 22.0907 20.3398C22.0907 19.9191 22.4317 19.578 22.8524 19.578C23.2731 19.578 23.6141 19.9191 23.6141 20.3398Z" />
        </svg>
    )
}

export default Prostate;
