import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import React from 'react';

export const Pdf = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-pdf'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.86663 6.36838L9.9064 4.56745C10.7534 3.10039 9.69471 1.2666 8.0007 1.2666C6.3067 1.2666 5.24797 3.10038 6.095 4.56744L7.1346 6.36809L4.84582 10.3324H2.76624C1.07232 10.3324 0.0136341 12.1662 0.860589 13.6332C1.70758 15.1002 3.82496 15.1002 4.67198 13.6332L5.71165 11.8325H10.2882L11.3279 13.6332C12.1749 15.1002 14.2923 15.1002 15.1393 13.6332C15.9862 12.1662 14.9275 10.3324 13.2336 10.3324H11.1552L8.86663 6.36838ZM7.39404 3.81744L8.0007 4.86822L8.60736 3.81744C8.87703 3.35038 8.54 2.7666 8.0007 2.7666C7.46141 2.7666 7.12437 3.35038 7.39404 3.81744ZM8.00053 7.86825L6.57784 10.3325H9.42322L8.00053 7.86825ZM12.6269 12.8832L12.0202 11.8324H13.2336C13.7728 11.8324 14.1099 12.4162 13.8402 12.8832C13.5706 13.3502 12.8966 13.3502 12.6269 12.8832ZM2.15963 12.8832C2.42923 13.3502 3.10326 13.3502 3.37296 12.8832L3.97963 11.8324H2.76624C2.22708 11.8324 1.88999 12.4162 2.15963 12.8832Z"
                      fill="currentColor"/>
            </svg>
        )
    }
    return (
        <svg id={'s-pdf'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.86663 6.36838L9.9064 4.56745C10.7534 3.10039 9.69471 1.2666 8.0007 1.2666C6.3067 1.2666 5.24797 3.10038 6.095 4.56744L7.1346 6.36809L4.84582 10.3324H2.76624C1.07232 10.3324 0.0136341 12.1662 0.860589 13.6332C1.70758 15.1002 3.82496 15.1002 4.67198 13.6332L5.71165 11.8325H10.2882L11.3279 13.6332C12.1749 15.1002 14.2923 15.1002 15.1393 13.6332C15.9862 12.1662 14.9275 10.3324 13.2336 10.3324H11.1552L8.86663 6.36838ZM7.39404 3.81744L8.0007 4.86822L8.60736 3.81744C8.87703 3.35038 8.54 2.7666 8.0007 2.7666C7.46141 2.7666 7.12437 3.35038 7.39404 3.81744ZM8.00053 7.86825L6.57784 10.3325H9.42322L8.00053 7.86825ZM12.6269 12.8832L12.0202 11.8324H13.2336C13.7728 11.8324 14.1099 12.4162 13.8402 12.8832C13.5706 13.3502 12.8966 13.3502 12.6269 12.8832ZM2.15963 12.8832C2.42923 13.3502 3.10326 13.3502 3.37296 12.8832L3.97963 11.8324H2.76624C2.22708 11.8324 1.88999 12.4162 2.15963 12.8832Z"
                  fill="currentColor"/>
        </svg>
    )
};