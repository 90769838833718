import React from 'react';

const InfoIcon = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 30C23.9558 30 30 23.9558 30 16.5C30 9.04416 23.9558 3 16.5 3C9.04416 3 3 9.04416 3 16.5C3 23.9558 9.04416 30 16.5 30ZM15 10.9333C15 10.2337 15.5671 9.66663 16.2667 9.66663C16.9662 9.66663 17.5333 10.2337 17.5333 10.9333V10.9346C17.5333 11.6342 16.9662 12.2013 16.2667 12.2013C15.5671 12.2013 15 11.6342 15 10.9346V10.9333ZM15 15.6C15 14.9004 15.5671 14.3333 16.2667 14.3333C16.9662 14.3333 17.5333 14.9004 17.5333 15.6V21.6C17.5333 22.2995 16.9662 22.8666 16.2667 22.8666C15.5671 22.8666 15 22.2995 15 21.6V15.6Z" />
        </svg>

    )
}

export default InfoIcon;