import React, { FC, ReactNode } from "react";
import styles from 'components/designSystem/components/cards/ListCard.module.scss'
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import Icon from "components/designSystem/foundations/Icons";
import Avatar, { AvatarPayload, AvatarSize } from "components/designSystem/components/Avatar";
import classNames from "classnames";

interface ListCardProps {
    label: ReactNode
    onClick: () => void;
    avatarPayload: AvatarPayload;
    isDisabled?: boolean;
}

const ListCard: FC<ListCardProps> = ({isDisabled = false, ...props}): JSX.Element => {
    return <div className={classNames(styles.listCard, {[styles.listCardDisabled]: isDisabled})}
                onClick={!isDisabled ? props.onClick : undefined}
    >
        <div className={styles.labelAndAvatar}>
            <Avatar
                size={AvatarSize.SMALL}
                payload={props.avatarPayload}
            />
            <div className={styles.label}>{props.label}</div>
        </div>
        <div className={styles.icon}>
            <Icon name={IconName.CHEVRON_RIGHT} size={IconSize.SMALL}/>
        </div>
    </div>
}

export default ListCard;