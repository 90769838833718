import React, { Component, ReactElement, ReactNode } from 'react';
import ErrorOutput from 'components/utils/form/ErrorOutput';
import { Field as FinalField } from 'react-final-form';
import 'components/utils/form/Field.scss';
import classNames from 'classnames';

export interface FieldProps {
    name: string;
    placeholder?: string | null;
    type?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate?: (value: any) => string | undefined;
    defaultValue?: string | number | string[];
    description?: ReactElement | string;
    component?: 'input' | 'checkbox' | 'select' | 'pin' | 'smallpin6'; // to improve
    options?: JSX.Element[];
    readOnly?: boolean;
    maxLength?: number;
    id?: string;
    format?: (value: string | number | string[]) => string | undefined
}

// needs rework
class Field extends Component<FieldProps> {
    render(): ReactNode {
        if (this.props.component === 'select') {
            return (
                <div>
                    <FinalField
                        name={this.props.name}
                        component={this.props.component}
                        validate={this.props.validate}
                        defaultValue={this.props.defaultValue}
                        type={this.props.type}
                    >
                        {({ input, meta }): JSX.Element => (
                            <div>
                                <select {...input}> {this.props.options} </select>
                                {this.props.type === 'gender' && (
                                    <div>
                                        {meta.error && meta.touched && (
                                            <ErrorOutput error={meta.error} type={this.props.type} />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </FinalField>
                </div>
            );
        }

        if (this.props.component === 'pin') {
            return (
                <FinalField name={this.props.name} component={'input'}>
                    {({ input }): JSX.Element => (
                        <div>
                            <div className={classNames('pin-container')}>
                                <input
                                    autoFocus={true}
                                    className={'pin4'}
                                    {...input}
                                    type="password"
                                    maxLength={this.props.maxLength}
                                    autoComplete={'nope'}
                                />
                            </div>
                        </div>
                    )}
                </FinalField>
            );
        }

        if (this.props.component === 'smallpin6') {
            return (
                <FinalField name={this.props.name} component={'input'}>
                    {({ input }): JSX.Element => (
                        <div>
                            <div className={classNames('pin-container')}>
                                <input autoFocus={true} className={'smallpin6'} {...input} type="text" maxLength={6} />
                            </div>
                        </div>
                    )}
                </FinalField>
            );
        }

        if (this.props.component === 'checkbox') {
            return (
                <FinalField
                    name={this.props.name}
                    component={'input'}
                    type={'checkbox'}
                    defaultValue={this.props.defaultValue}
                    validate={this.props.validate}
                >
                    {({ input, meta }): JSX.Element => (
                        <div className={'checkbox-container'}>
                            <div className={'checkbox-box-and-description'}>
                                <input {...input} type="checkbox" />
                                {this.props.description && (
                                    <div className={'checkbox-description'}>{this.props.description}</div>
                                )}
                            </div>
                            <div>
                                {meta.error && meta.touched && (
                                    <ErrorOutput error={meta.error} type={this.props.component} />
                                )}
                            </div>
                        </div>
                    )}
                </FinalField>
            );
        }
        return (
            <div className={this.props.readOnly ? 'field-read-only' : undefined}>
                <FinalField
                    name={this.props.name}
                    component={this.props.component || 'input'}
                    validate={this.props.validate}
                    defaultValue={this.props.defaultValue}
                    type={this.props.type}
                    format={this.props.format}
                >
                    {({ input, meta }): JSX.Element => (
                        <div>
                            <input
                                {...input}
                                placeholder={this.props.placeholder || ''}
                                type={this.props.type || 'text'}
                                {...(this.props.readOnly ? { autoComplete: 'nope' } : {})}
                                maxLength={this.props.maxLength}
                                {...(this.props.id ? { id: this.props.id } : {})}
                            />
                            {this.props.description}
                            <div>
                                {meta.error && meta.touched && (
                                    <ErrorOutput error={meta.error} type={this.props.type} />
                                )}
                            </div>
                        </div>
                    )}
                </FinalField>
            </div>
        );
    }
}

export default Field;
