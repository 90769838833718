import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Download = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-download'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.75 1C8.75 0.585786 8.41421 0.25 8 0.25C7.58579 0.25 7.25 0.585786 7.25 1V9.68934L5.53033 7.96967C5.23744 7.67678 4.76256 7.67678 4.46967 7.96967C4.17678 8.26256 4.17678 8.73744 4.46967 9.03033L7.46967 12.0303C7.76256 12.3232 8.23744 12.3232 8.53033 12.0303L11.5303 9.03033C11.8232 8.73744 11.8232 8.26256 11.5303 7.96967C11.2374 7.67678 10.7626 7.67678 10.4697 7.96967L8.75 9.68934V1ZM2.75 11.5C2.75 11.0858 2.41421 10.75 2 10.75C1.58579 10.75 1.25 11.0858 1.25 11.5V13C1.25 14.5188 2.48122 15.75 4 15.75H12C13.5188 15.75 14.75 14.5188 14.75 13V11.5C14.75 11.0858 14.4142 10.75 14 10.75C13.5858 10.75 13.25 11.0858 13.25 11.5V13C13.25 13.6904 12.6904 14.25 12 14.25H4C3.30964 14.25 2.75 13.6904 2.75 13V11.5Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-download'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13 1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1V16.4492L8.23357 13.4632C7.85822 13.0581 7.22552 13.034 6.82038 13.4093C6.41525 13.7846 6.3911 14.4173 6.76644 14.8225L11.2664 19.6796C11.4557 19.8839 11.7215 20 12 20C12.2785 20 12.5443 19.8839 12.7336 19.6796L17.2336 14.8225C17.6089 14.4173 17.5848 13.7846 17.1796 13.4093C16.7745 13.034 16.1418 13.0581 15.7664 13.4632L13 16.4492V1ZM4 18C4 17.4477 3.55228 17 3 17C2.44772 17 2 17.4477 2 18V21C2 22.6569 3.34315 24 5 24H19C20.6569 24 22 22.6569 22 21V18C22 17.4477 21.5523 17 21 17C20.4477 17 20 17.4477 20 18V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V18Z"
                  fill="currentColor"/>
        </svg>
    )
}