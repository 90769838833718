import { ModuleAPICallAction } from 'actions/modular/ModularActions';

export const API_FETCH_PATIENT_REPORTS = 'API_FETCH_PATIENT_REPORTS';

export type FetchPatientReportsPayload = {
    patientUUID: string,
}

export const fetchPatientReports = (
    moduleIdentifier: string,
    patientUUID: string,
): ModuleAPICallAction<FetchPatientReportsPayload> => {
    return {
        type: API_FETCH_PATIENT_REPORTS,
        payload: {
            params: {
                patientUUID
            },
            moduleIdentifier: moduleIdentifier
        },
    };
};
