import { APICallSuccessAction, getActionPayload, getResponse, successful } from 'actions/ActionInterface';
import {
    AllActions,
    API_INIT_CPS,
    API_INIT_SSO,
    RETRIEVE_CPS_REDIRECTION_LOCAL_DATA,
    REGISTER_CPS_REDIRECTION_LOCAL_DATA,
    DELETE_CPS_REDIRECTION_LOCAL_DATA,
    REGISTER_FORCED_CONNECTION_TOKEN,
} from 'actions/user/SignInSignUpActions';
import { CPSRedirectionLocalData, EnforcedLocalConnectData, InitSSOResponse } from 'models/user/AuthModels';
import { DoctorInfoFromPscResponse, } from 'models/user/SubscriptionModels';
import { getLocalObject } from "reducers/Utils";

export const FORCED_CONNECTION_ITEM ='kiro_fct';

type ForceConnectionElements = {[key: string]: {t: string, d: string | null}};

export interface SignInSignUpReducerInterface {
    enforcedConnectionElements: ForceConnectionElements;
    ssoInitResponse?: InitSSOResponse & {timestamp: number};
    cpsResponse?: DoctorInfoFromPscResponse;
    cpsRedirectionLocalData?: CPSRedirectionLocalData
}

function getLocalForcedConnectionElements(): ForceConnectionElements {
    const rawItems  = localStorage.getItem(FORCED_CONNECTION_ITEM);

    return rawItems ? JSON.parse(rawItems) : {};
}

export const initialState = {
    enforcedConnectionElements: getLocalForcedConnectionElements()
};


export default function signInSignUpReducers(
    state : SignInSignUpReducerInterface = initialState,
    action: AllActions | APICallSuccessAction,
): SignInSignUpReducerInterface {
    switch (action.type) {
        case successful(API_INIT_CPS):
            const doctorInfoFromPscResponse = getResponse<DoctorInfoFromPscResponse>(action);
            return {
                ...state,
                cpsResponse: doctorInfoFromPscResponse,
            };
        case successful(API_INIT_SSO):
            return {
                ...state,
                ssoInitResponse: {
                    ...getResponse<InitSSOResponse>(action),
                    timestamp: Date.now()
                }
            };
        case REGISTER_FORCED_CONNECTION_TOKEN:

            const forcedConnectionPayload  = getActionPayload<EnforcedLocalConnectData>(action);

            const items  = {
                ...getLocalForcedConnectionElements(),
                ...state.enforcedConnectionElements
            }

            items[forcedConnectionPayload.fingerprint] = {
                t: forcedConnectionPayload.token,
                d: forcedConnectionPayload.deadline
            };

            localStorage.setItem(FORCED_CONNECTION_ITEM, JSON.stringify(items));

            return {
                ...state,
                enforcedConnectionElements: items
            };
        case REGISTER_CPS_REDIRECTION_LOCAL_DATA:
            const CPSRedirectionLocalData = getActionPayload<CPSRedirectionLocalData>(action);

            localStorage.setItem(REGISTER_CPS_REDIRECTION_LOCAL_DATA, JSON.stringify(CPSRedirectionLocalData))

            return {
                ...state,
                cpsRedirectionLocalData: CPSRedirectionLocalData
            }
        case RETRIEVE_CPS_REDIRECTION_LOCAL_DATA:
            const localData: CPSRedirectionLocalData = getLocalObject(REGISTER_CPS_REDIRECTION_LOCAL_DATA)

            return {
                ...state,
                cpsRedirectionLocalData: localData ? localData : undefined
            }
        case DELETE_CPS_REDIRECTION_LOCAL_DATA:
            localStorage.removeItem(REGISTER_CPS_REDIRECTION_LOCAL_DATA)
            return {
                ...state,
                cpsRedirectionLocalData: undefined
            }
        default:
            return state;
    }
}
