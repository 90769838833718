import React, { FC } from 'react';
import classNames from "classnames";
import styles from 'components/designSystem/containers/Column.module.scss'

export enum ColumnWidthInREM {
    WIDTH_28 = 'WIDTH_28', // 448 px
    WIDTH_22 = 'WIDTH_22', // 352 px
    WIDTH_34 = 'WIDTH_34', // 544 px
    WIDTH_40 = 'WIDTH_40', // 640 px
    WIDTH_49 = 'WIDTH_49', // 784 px
}

interface ColumnProps {
    widthInRem: ColumnWidthInREM;
}

const Column: FC<ColumnProps> = ({...props}): JSX.Element => {
    return <div className={classNames({
        [styles.columnWidth28]: props.widthInRem === ColumnWidthInREM.WIDTH_28,
        [styles.columnWidth22]: props.widthInRem === ColumnWidthInREM.WIDTH_22,
        [styles.columnWidth34]: props.widthInRem === ColumnWidthInREM.WIDTH_34,
        [styles.columnWidth40]: props.widthInRem === ColumnWidthInREM.WIDTH_40,
        [styles.columnWidth49]: props.widthInRem === ColumnWidthInREM.WIDTH_49,
    })}>
        {props.children}
    </div>
}

export default Column;