import TrText from 'components/common/TrText';
import { TDisplaySmall500, TypographyColor } from "components/designSystem/containers/Typography";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { APIText } from 'models/common/message';
import React from 'react';

type TitleModulePayload = {
    content: APIText
};

export const TitleModule = (): JSX.Element => {
    const payload = useModulePayload<TitleModulePayload>();
    return <TDisplaySmall500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
        <TrText input={payload.content}/>
    </TDisplaySmall500>
};
