import React, { Component, ReactNode } from 'react';
import './PoweredBy.scss';

export interface PoweredByInterface {
    logoMain: ReactNode;
    logoPoweredBy: ReactNode;
    poweredWording?: ReactNode;
}

export default class PoweredBy extends Component<PoweredByInterface> {
    render(): ReactNode {
        return (
            <div className={'powered-by-container'}>
                <div className={'powered-by-base-container'}>
                    <div className={'powered-by-base'}>{this.props.logoMain}</div>
                </div>
                <div className={'powered-by-powerer-container'}>
                    <div className={'powered-by-powerer'}>
                        {this.props.poweredWording && (
                            <div className={'powered-by-wording'}>{this.props.poweredWording}</div>
                        )}
                        <div>{this.props.logoPoweredBy}</div>
                    </div>
                </div>
            </div>
        );
    }
}
