import { APICallAction } from 'actions/ActionInterface';

export const API_TRIGGER_NOTIFICATION_BY_IDENTIFIER = 'API_TRIGGER_NOTIFICATION_BY_IDENTIFIER';

export interface TriggerNotificationByIdentifierRequest {
    identifier: string;
    labUuid?: string
}

export type TriggerNotificationByIdentifierInterface = APICallAction<TriggerNotificationByIdentifierRequest>;

export const triggerNotificationByIdentifier = (
    data: TriggerNotificationByIdentifierRequest,
    feedbackIdentifier: string,
): TriggerNotificationByIdentifierInterface => ({
    type: API_TRIGGER_NOTIFICATION_BY_IDENTIFIER,
    payload: {
        params: data,
        feedbackIdentifier: feedbackIdentifier,
    },
});
