import React from 'react';

const Liver = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M20.1416 17.9952C19.9599 19.5927 19.031 21.0051 17.5928 21.8703C13.6928 24.2176 6.971 28.2696 5.77034 29.0361C5.36351 29.2959 4.9182 29.4333 4.48288 29.4333C3.00641 29.4333 2.00999 27.9724 1.94431 25.7114C1.88453 23.6563 1.51767 22.2213 1.09299 20.5598C0.773461 19.3096 0.444266 18.0225 0.181238 16.2704C-0.058206 14.6764 -0.580495 9.19846 2.75027 5.64348C4.66287 3.60198 7.52686 2.56674 11.263 2.56674C11.4287 2.56674 11.5974 2.56887 11.7677 2.57297C14.2028 2.63143 16.0792 3.3935 17.5039 4.90239C20.7077 8.29571 20.5317 14.5619 20.1416 17.9952ZM31.5974 7.38559C29.822 5.15722 22.0032 5.09483 21.9246 5.09483C21.3332 5.09483 21.1678 5.12005 20.9929 5.23682C20.8461 5.33476 20.7279 5.47839 20.6311 5.67558C20.5453 5.85083 20.5418 6.05539 20.6221 6.23341C22.1914 9.71338 22.0971 14.2412 21.9012 16.6302C21.8793 16.8969 21.8334 17.1709 21.7651 17.4447C21.7196 17.6273 21.7548 17.8206 21.8616 17.9753C21.9683 18.1303 22.1367 18.2317 22.3234 18.2539C22.3822 18.2608 22.4436 18.2646 22.5072 18.2646C22.5073 18.2646 22.5073 18.2646 22.5073 18.2646C24.1875 18.2646 27.0664 15.5016 27.9088 14.6549C29.6184 12.936 31.579 10.5172 31.9539 8.88302C32.0764 8.34976 31.9529 7.83205 31.5974 7.38559Z"/>
        </svg>
    )
}

export default Liver;
