import { cast } from "core/utils/Typed";
import React, { FC, Fragment, useMemo } from 'react';
import classNames from "classnames";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import styles from './Pager.module.scss';
import { IconName } from 'components/designSystem/foundations/IconsData';
import { range } from "underscore";
import { TABLET_MAX_WIDTH } from "components/core/constants";
import MediaQuery from "react-responsive";

export enum PagerType {
    WITH_PAGE_COUNTER = 'WITH_PAGE_COUNTER',
    WITHOUT_PAGE_COUNTER = 'WITHOUT_PAGE_COUNTER'
}

interface AbstractPagerPayload {
    type: PagerType;
}

interface PagerWithPageCounter extends AbstractPagerPayload{
    type: PagerType.WITH_PAGE_COUNTER,
    totalItemsCount: number,
    pageSize: number,
    pagerTranslationLabel?: JSX.Element,  // needed to translate the of word of: 1-5 OF 8 for example
}

interface PagerWithoutPageCounter extends AbstractPagerPayload{
    type: PagerType.WITHOUT_PAGE_COUNTER,
    shouldActiveNext: boolean
}

export type PagerPayload = PagerWithPageCounter | PagerWithoutPageCounter;

export interface PagerProps {
    onChangePage: (i: number) => void,
    activePosition?: number,
    payload: PagerPayload;
    disabled?:boolean;
}

const MAX_PAGE_AMOUNT_TO_DISPLAY_NO_DOT = 7

const Pager: FC<PagerProps> = (
    {
        activePosition = 1,
        disabled = false,
        ...props

    }): JSX.Element => {

    const countTotalPage = (payload: PagerWithPageCounter) : number => {
        return Math.ceil(payload.totalItemsCount / payload.pageSize);
    }

    const getNextActive = (payload: PagerPayload) : boolean => {
        if(payload.type === PagerType.WITHOUT_PAGE_COUNTER){
            return cast<PagerWithoutPageCounter>(payload).shouldActiveNext
        }

        if(payload.type === PagerType.WITH_PAGE_COUNTER){
            return activePosition != countTotalPage(payload)
        }

        return false
    }

    const buildPagerCount = (payload: PagerWithPageCounter) : JSX.Element => {
        const firstPageItem = (activePosition - 1) * payload.pageSize + 1
        const lastPageItem = Math.min((activePosition) * (payload.pageSize), payload.totalItemsCount)

        return <div className={classNames(styles.PagerCount)}>
            <div className={classNames(styles.PagerPagination)}>{firstPageItem} - {lastPageItem}</div>
            <div className={classNames(styles.PagerTotal)}> {payload.pagerTranslationLabel ?? ''} {payload.totalItemsCount}</div>
        </div>
    }

    const buildPagerPageContainer = (payload: PagerWithPageCounter) : JSX.Element => {
        const totalPageCount = countTotalPage(payload)
        const buildPagerItem = (i: number | string, k: number): JSX.Element => {
            return typeof i == 'number' ?
                <div key={"bullet" + k}
                     onClick={(): void => props.onChangePage(i)}
                     className={(i == activePosition ? classNames(styles.PagerNumberActive, styles.PagerNumber) : classNames(styles.PagerNumber))}>
                    {i}
                </div> :
                <div key={"bullet" + k}
                     className={styles.PagerNumberHidden}>
                    {i}
                </div>
        }
        const paginationRange = useMemo(() => {

            if (MAX_PAGE_AMOUNT_TO_DISPLAY_NO_DOT >= totalPageCount) {
                return range(1, totalPageCount + 1);
            }

            const leftSiblingIndex = Math.max(activePosition - 1, 1);
            const rightSiblingIndex = Math.min(activePosition + 1, totalPageCount);

            const shouldShowLeftDots = leftSiblingIndex >= 2;
            const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;
            if (!shouldShowLeftDots && shouldShowRightDots) {
                const leftRange = range(1, 5);
                return [...leftRange, "...", totalPageCount];
            }

            if (shouldShowLeftDots && !shouldShowRightDots) {
                const rightItemCount = 5;
                const rightRange = range(
                    totalPageCount - rightItemCount + 1,
                    totalPageCount + 1
                );
                return [1, "...", ...rightRange];
            }

            if (shouldShowLeftDots && shouldShowRightDots) {

                const middleRange = range(leftSiblingIndex, rightSiblingIndex + 1);
                return [1, "...", ...middleRange, "...", totalPageCount];
            }
        }, [payload.totalItemsCount, payload.pageSize, activePosition]);

        return totalPageCount > 0 ? <div className={styles.PagerPagesContainer}>
            {paginationRange && paginationRange.map(
                (i, k) => buildPagerItem(i, k)
            )}
        </div> : <Fragment/>
    }

    const content = (isMobile: boolean) : JSX.Element => {
        const previousActive = activePosition != 1
        const nextActive = getNextActive(props.payload)

        return <>
            {
                !isMobile && props.payload.type === PagerType.WITH_PAGE_COUNTER && buildPagerCount(
                    cast<PagerWithPageCounter>(props.payload)
                )
            }
            <div className={isMobile ? styles.PagerPagesMobile : styles.PagerPages}>
                <Button
                    disabled={disabled || !previousActive}
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.EXTRA_SMALL}
                    onClick={previousActive ? (): void => props.onChangePage(activePosition - 1) : undefined}
                    icons={{middle: IconName.CHEVRON_LEFT}}
                />
                {
                    props.payload.type === PagerType.WITH_PAGE_COUNTER && buildPagerPageContainer(
                        cast<PagerWithPageCounter>(props.payload)
                    )
                }
                <Button
                    disabled={disabled || !nextActive}
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.EXTRA_SMALL}
                    onClick={nextActive ? (): void => props.onChangePage(activePosition + 1) : undefined}
                    icons={{middle: IconName.CHEVRON_RIGHT}}
                />
            </div>
        </>
    }

    return <div className={classNames(styles.Pager)}>
        <MediaQuery minWidth={TABLET_MAX_WIDTH + 1}>
            {content(false)}
        </MediaQuery>
        <MediaQuery maxWidth={TABLET_MAX_WIDTH}>
            {content(true)}
        </MediaQuery>
    </div>
}

export default Pager;
