import { ColorMap } from "components/designSystem/Aliases";
import React from 'react';

import BaseModule, { defaultBaseModuleState, ModuleState, setupModule } from 'components/modules/modular/BaseModule';
import { ModuleData } from 'models/modular/storage';
import { APIText } from 'models/common/message';
import Container, {
    ContainerProfile,
    MarginType,
    PaddingType
} from 'components/legacyDesignSystem/components/Container';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import { Switch } from 'components/legacyDesignSystem/components/Switch';
import { NumericalValue } from 'models/medicalReport/ReportModels';
import 'components/modules/modular/modules/rwe/Table.scss';
import { Colors } from 'components/legacyDesignSystem/branding/constants';
import TextLoader from 'components/legacyDesignSystem/components/TextLoader';
import { Direction, getGradient } from 'components/legacyDesignSystem/utils/responsiveness';
import { severityToColor } from 'components/commonDesign/utils/style';
import { ModuleMenu } from 'models/modular/menu';
import ConnectedNumericalValue from 'components/common/ConnectedNumericalValue';
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";

interface CellColor {
    start: ColorMap,
    end: ColorMap
}

interface DataTableLine {
    identifier: string;
    name: APIText;
    color: CellColor | null
}


interface DataTableColumn {
    identifier: string;
    name: APIText;
    color: CellColor | null
}


interface CellDataDataValueType {
    [data_type: string]: NumericalValue | null;
}

interface CellData {
    elements: {
        color?: ColorMap;
        name: APIText,
        values_by_type: CellDataDataValueType;
    }[]
}
interface DataTableDataByIdentifierColumn {
    [column_identifier: string]: CellData;
}

interface DataTableDataByIdentifier {
    [line_identifier: string]: DataTableDataByIdentifierColumn;
}

interface DataTableValueSwitchItem {
    identifier: string;
    name: APIText;
    rank?: number
}

interface DataTableValueSwitch {
    [value_switch: string]: DataTableValueSwitchItem;
}

export interface SimpleTableDistributionModulePagePayload {
    title: APIText;
    lines: DataTableLine[];
    columns: DataTableColumn[];
    data_by_identifier: DataTableDataByIdentifier;
    values_switch?: DataTableValueSwitch;
    menu: ModuleMenu | null;
}

interface LocalState {
    active_switch_value?: string;
}

class DataTableModule extends BaseModule<{}, SimpleTableDistributionModulePagePayload, {}> {
    state: ModuleState<LocalState> = defaultBaseModuleState(
        {}
    )

    componentDidMount(): void {
        if (
            this.props.payload?.pagePayload?.values_switch &&
            Object.values(this.props.payload?.pagePayload?.values_switch)[0]
        ) {
            this.setState(
                { active_switch_value: Object.values(this.props.payload?.pagePayload?.values_switch).sort(this.rankSwitch)[0].identifier }
            )
        }
    }

    protected renderSwitch(values_switch: DataTableValueSwitch): JSX.Element {
        const ranked = Object.values(values_switch).sort(this.rankSwitch)
        const left = ranked[0];
        const right = ranked[1];

        return (
            <Switch
                text_left={this.transApiText(left.name)}
                onClickLeft={(): void => this.setState({ active_switch_value: left.identifier })}
                text_right={this.transApiText(right.name)}
                onClickRight={(): void => this.setState({ active_switch_value: right.identifier })}
            />
        );
    }

    protected _render(payload: ModuleData<SimpleTableDistributionModulePagePayload, {}>): React.ReactNode {
        const {
            pagePayload: { title, lines, data_by_identifier, columns, values_switch, menu },
        } = payload;

        const { active_switch_value } = this.state;
        const loading = Object.keys(data_by_identifier).length ===  0;

        const displayLoader = payload.twinkle || loading

        const columnsIndexOrder = columns.map(c => c.identifier);

        const content = <Container profiles={[{type: PaddingType.P, value: 4}]}>
            <Container
                className={'simple-table-distribution__header'}
                profiles={[
                    ContainerProfile.FLEX_WRAP,
                    ContainerProfile.SPACE_BETWEEN,
                    ContainerProfile.ALIGN_CENTER,
                    { type: MarginType.MB, value: 8 },
                ]}
            >
                {title && (
                    <Typography weight={TypographyWeight.BOLD} variant={TypographyVariant.BODY}>
                        {this.transApiText(title)}
                    </Typography>
                )}
                <Container profiles={[
                    ContainerProfile.FLEX_WRAP,
                    ContainerProfile.SPACE_BETWEEN,
                    ContainerProfile.ALIGN_CENTER,
                ]}>
                    {values_switch && this.renderSwitch(values_switch)}
                    {
                        menu &&
                        this.buildModuleMenu(menu)
                    }
                </Container>
            </Container>
            <table className={'simple-table-distribution'}>
                <tbody>
                {
                    <tr className={'simple-table-distribution__line'}>
                        <th
                            className={'simple-table-distribution__cell simple-table-distribution__header-cell'}
                            colSpan={1}
                        ></th>
                        {columns.map((column: DataTableColumn) => (
                            <th
                                key={column.identifier}
                                className={
                                    'simple-table-distribution__cell simple-table-distribution__header-cell'
                                }
                                style={
                                    column.color ?
                                        getGradient(severityToColor(column.color.start), severityToColor(column.color.end)): {}
                                }
                            >
                                <Typography
                                    weight={TypographyWeight.MEDIUM}
                                    variant={TypographyVariant.OVERLINE}
                                    color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                >
                                    {this.transApiText(column.name)}
                                </Typography>
                            </th>
                        ))}
                    </tr>
                }
                {lines &&
                lines.map((line) => (
                    <tr key={line.identifier} className={'simple-table-distribution__line'}>
                        <th
                            className={
                                'simple-table-distribution__cell simple-table-distribution__header-cell'
                            }
                            style={
                                line.color ?
                                    getGradient(severityToColor(line.color.start), severityToColor(line.color.end), Direction.TOP_BOTTOM): {}
                            }
                        >

                            <Typography
                                weight={TypographyWeight.MEDIUM}
                                variant={TypographyVariant.OVERLINE}
                                color={Colors.NEUTRALS_DARK_BLUE_GREY}
                            >
                                {this.transApiText(line.name)}
                            </Typography>
                        </th>
                        {
                            loading &&
                            columns.map((c) => <td
                                key={c.identifier}
                                className={`simple-table-distribution__cell simple-table-distribution__data-cell`}
                            >
                                <TextLoader />
                            </td>)
                        }
                        {(active_switch_value || !values_switch) && data_by_identifier[line.identifier] &&
                        Object.entries(data_by_identifier[line.identifier]).sort((keyA, keyB) => columnsIndexOrder.indexOf(keyA[0]) - columnsIndexOrder.indexOf(keyB[0])).map((cell, i) => (
                            <td
                                key={cell[0]}
                                id={cell[0]}
                                className={`simple-table-distribution__cell simple-table-distribution__data-cell`}
                            >
                                {
                                    cell[1].elements.filter(
                                        (c) => {
                                            if (!payload.pagePayload.values_switch) {
                                                return true
                                            }

                                            if (!active_switch_value || !c.values_by_type[active_switch_value]) return false;
                                            const value = c.values_by_type[active_switch_value]

                                            return value && value.value && value.value > 0
                                        }
                                    ).map(
                                        (element, index) => (
                                            <div key={index.toString() + element.name}>
                                                {
                                                    values_switch &&
                                                    <div>
                                                        <Typography
                                                            weight={TypographyWeight.MEDIUM}
                                                            variant={TypographyVariant.OVERLINE}
                                                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                                        >
                                                            {this.transApiText(element.name)}
                                                        </Typography>
                                                    </div>
                                                }

                                                <div style={{overflow: "auto"}}>
                                                    <Typography
                                                        weight={TypographyWeight.REGULAR}
                                                        variant={TypographyVariant.OVERLINE}
                                                        color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                                    >
                                                        {
                                                            !values_switch &&
                                                            Object.values(element.values_by_type).map(
                                                                (v, index) => (
                                                                    <ConnectedNumericalValue key={index.toString()} value={ v} />
                                                                )
                                                            )
                                                        }
                                                        {active_switch_value &&
                                                        element.values_by_type[active_switch_value] &&
                                                        <ConnectedNumericalValue value={ element.values_by_type[active_switch_value]} />
                                                        }
                                                    </Typography>
                                                </div>
                                            </div>

                                        )
                                    )
                                }

                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </Container>

        return <Container profiles={[ContainerProfile.BORDER_MODULE]} style={{backgroundColor: "white"}}>
            {displayLoader ? <ModuleLoaderWrapper>{content}</ModuleLoaderWrapper> : content}
        </Container>;
    }

    private rankSwitch(a: DataTableValueSwitchItem, b: DataTableValueSwitchItem): number {
        return (a.rank != null ? a.rank : -1) - (b.rank != null ? b.rank : -1)
    }
}

export default setupModule(
    DataTableModule,
    () => ({}),
    () => ({}),
);
