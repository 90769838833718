import TrText from "components/common/TrText";
import Button, { ButtonSize } from "components/designSystem/components/Button";
import TextField from "components/designSystem/components/TextField";
import Box, { BoxBorderColor, BoxColor, BoxHeight } from "components/designSystem/containers/Box";
import GridRow, { GridRowItem } from "components/designSystem/containers/GridRow";
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import { LargeSpacer, MediumSpacer, SmallSpacer, XSmallSpacer } from "components/designSystem/containers/Spacer";
import {
    TBody,
    TDisplaySmall700,
    TDisplayXSmall,
    TDisplayXSmall500,
    TLabel,
    TOverline
} from "components/designSystem/containers/Typography";
import { WithHorizontalPadding } from "components/designSystem/containers/WithPadding";
import { formatDate } from "core/utils/Date";
import { formatName } from "core/utils/Name";
import { formatPhoneNumber } from "core/utils/PhoneNumber";
import { Recommendation, RecommendedLaborder, UserData } from "models/medicalReport/LegacyReportModels";
import { LocalUserData } from "models/user/UserModels";
import React, { forwardRef, useRef } from 'react';
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";

interface PrescriptionProps {
    recommendations: Recommendation[];
    patient: UserData | null;
    doctor: LocalUserData;
}

/**
 * Identification du prescripteur sur les ordonnances - code barre RPPS
 * https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000022759086
 */
const RppsCode = ({practiceId}: { practiceId: string }): JSX.Element => <SmallSpacer>
    <TLabel><TrText input={{trkey: 'medical_report.prescription.rpps_no'}}/></TLabel>
    <Barcode
        value={practiceId}
        width={1}
        height={25}
        fontSize={12}
        textAlign={"left"}
        margin={0}
    />
</SmallSpacer>;

/**
 * Identification du prescripteur sur les ordonnances - code barre FINESS ou AM
 * https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000022759086
 */
const AmCode = ({businessStructureId}: { businessStructureId: string }): JSX.Element => <SmallSpacer>
    <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
        <TLabel><TrText input={{trkey: 'medical_report.prescription.am_no'}}/></TLabel>
    </AlwaysInline>
    <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
        <Barcode
            value={businessStructureId}
            width={1}
            height={25}
            fontSize={12}
            textAlign={"right"}
            margin={0}
        />
    </AlwaysInline>
</SmallSpacer>;

export const PrescriptionContent = forwardRef<HTMLDivElement, PrescriptionProps>(function PrescriptionContent(props: PrescriptionProps, ref): JSX.Element {
    const doctorFullName = props.doctor.firstName + ' ' + props.doctor.lastName;
    const doctorData = props.doctor.doctorData;
    return <Box
        background={BoxColor.WHITE}
        withBorder={{color: BoxBorderColor.DEFAULT}}
        withFullWidth
        withBorderRadius
        withPadding
    >
        <div ref={ref}>
            <LargeSpacer>
                <GridRow columnNb={2}>
                    <GridRowItem from={1} to={2}>
                        <MediumSpacer>
                            <TDisplaySmall700>
                                <TrText input={{
                                    trkey: 'medical_report.prescription.doctor_title',
                                    trdata: {name: doctorFullName}
                                }}/>
                            </TDisplaySmall700>
                            {doctorData?.speciality &&
                                <TDisplayXSmall>{doctorData.speciality.toUpperCase()}</TDisplayXSmall>}
                        </MediumSpacer>
                    </GridRowItem>
                    <GridRowItem from={2} to={3}>
                        <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
                            <TLabel>
                                <TrText input={{
                                    trkey: 'medical_report.prescription.prescription_day',
                                    trdata: {date: formatDate(new Date())}
                                }} capitalize/>
                            </TLabel>
                        </AlwaysInline>
                    </GridRowItem>
                </GridRow>
                <GridRow columnNb={3}>
                    <GridRowItem from={1} to={2}>
                        {doctorData?.practiceId && <RppsCode practiceId={doctorData.practiceId}/>}
                    </GridRowItem>
                    <GridRowItem from={2} to={3}>
                        <WithHorizontalPadding>
                            <AlwaysInline justifyContent={InlineJustifyContent.CENTER}>
                                <TDisplayXSmall500>
                                    <TrText input={{trkey: 'medical_report.prescription.prescription'}} capitalize/>
                                </TDisplayXSmall500>
                            </AlwaysInline>
                        </WithHorizontalPadding>
                    </GridRowItem>
                    <GridRowItem from={3} to={4}>
                        {doctorData?.businessStructureId &&
                            <AmCode businessStructureId={doctorData.businessStructureId}/>}
                    </GridRowItem>
                </GridRow>
                <MediumSpacer>
                    {props.patient &&
                        <AlwaysInline alignItems={InlineAlignItem.BASELINE} spacing={InlineSpacing.X_SMALL}>
                            <TDisplaySmall700>{formatName(props.patient.first_name, props.patient.last_name)}</TDisplaySmall700>
                            {props.patient.birth_date && <TDisplayXSmall>
                                <TrText input={{
                                    trkey: 'medical_report.prescription.patient_birth_date',
                                    trdata: {date: formatDate(props.patient.birth_date)}
                                }}/>
                            </TDisplayXSmall>}
                        </AlwaysInline>
                    }
                    <TextField
                        type={"textarea"}
                        label={<TBody><TrText input={{trkey: 'medical_report.prescription.prompt'}}/></TBody>}
                        hideLabelOnActive
                        autoSize
                        defaultValue={props.recommendations.flatMap((reco: Recommendation) => reco.recommended_laborders.map((test: RecommendedLaborder) => `- ${test.name}`)).join("\n")}/>
                </MediumSpacer>
                <XSmallSpacer>
                    <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
                        <TLabel><TrText input={{trkey: 'medical_report.prescription.signature'}}/></TLabel>
                    </AlwaysInline>
                    <Box background={BoxColor.WHITE} withFixedHeight={BoxHeight.SMALL}/>
                    <AlwaysInline justifyContent={InlineJustifyContent.CENTER}>
                        <TOverline>
                            {(doctorData?.businessAddress ||
                                    doctorData?.businessPhoneNumber ||
                                    doctorData?.businessEmail) &&
                                <TrText input={{
                                    trkey: 'medical_report.prescription.doctor_with_name',
                                    trdata: {name: doctorFullName}
                                }} capitalize/>}
                            {doctorData?.businessAddress &&
                                <> | {doctorData.businessAddress.replace(/ /g, '\u00a0')}</>}
                            {doctorData?.businessPhoneNumber &&
                                <> | <TrText input={{
                                    trkey: 'medical_report.prescription.dr_tel',
                                    trdata: {phone: formatPhoneNumber(doctorData.businessPhoneNumber).replace(/ /g, '\u00a0',)}
                                }}/></>}
                            {doctorData?.businessEmail &&
                                <> | <TrText input={{
                                    trkey: 'medical_report.prescription.dr_email',
                                    trdata: {email: doctorData.businessEmail.replace(/ /g, '\u00a0')}
                                }}/></>}
                        </TOverline>
                    </AlwaysInline>
                </XSmallSpacer>
            </LargeSpacer>
        </div>
    </Box>
});

const Prescription = (props: PrescriptionProps): JSX.Element => {
    const componentRef = useRef(null);
    const print = useReactToPrint({
        pageStyle: '@page { size: auto;  margin: 32px; } }',
        content: () => componentRef.current,
    });
    return <MediumSpacer>
        <PrescriptionContent
            recommendations={props.recommendations}
            patient={props.patient}
            doctor={props.doctor}
            ref={componentRef}
        />
        <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
            <Button onClick={print} size={ButtonSize.LARGE}>
                <TrText input={{trkey: 'global.print'}}/>
            </Button>
        </AlwaysInline>
    </MediumSpacer>
}

export default Prescription;