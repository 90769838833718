import Eula from 'components/modules/eula/Eula';
import EulaContainer, { EULAType } from 'components/modules/eula/EulaContainer';
import LegalNotice from 'components/modules/eula/LegalNotice';
import Faq from 'components/modules/faq/Faq';
import ConnectionModes from 'components/modules/medicalReport/ConnectionModes'
import OutdatedReport from "components/modules/medicalReport/OutdatedReport";
import RandomReport from 'components/modules/medicalReport/RandomReport';
import SharedReport from "components/modules/medicalReport/SharedReport";
import { AccessByConnectionModes } from "components/modules/modular/components/AccessByConnectionModes";
import ModularPage from 'components/modules/modular/ModularPage';
import AuthCPS from 'components/modules/user/AuthCPS';
import EnforcedLocalConnect from 'components/modules/user/legacy/EnforcedLocalConnect';
import ForgotPassword from 'components/modules/user/legacy/ForgotPassword';
import ResetPasswordPage from 'components/modules/user/legacy/ResetPasswordPage';
import SetPasswordPage from 'components/modules/user/legacy/SetPasswordPage';
import SSOPage from 'components/modules/user/legacy/SSOPage';
import TriggerNotificationByIdentifier, {
    buildNotificationPath
} from 'components/modules/user/legacy/TriggerNotificationByIdentifier';
import { Login } from 'components/modules/user/Login';
import PinPage from "components/modules/user/PinPage";
import RedirectToCps from "components/modules/user/RedirectToCps";
import BaseComponent from 'components/pages/common/BaseComponent';
import ModalWrapper from 'components/pages/common/ModalWrapper';
import DefaultPage from 'components/pages/DefaultPage';
import Page404 from 'components/pages/errors/Page404';
import LogginRequired from 'components/pages/LoggingRequired';
import Navigate from "components/pages/Navigate";
import WithDisconnectedNavBar from 'components/pages/WithDisconnectedNavBar'
import WithFooter from 'components/pages/WithFooter';
import WithNavBar from 'components/pages/WithNavBar';
import WithNavigation from 'components/pages/WithNavigation';
import CONFIG from "configuration/globals";
import { getPersonIdFromLegacy, getRoute } from "core/routing/Helper";
import {
    ROUTE_ACCESS_BY_CONNECTION_MODES,
    ROUTE_AUTH_CPS,
    ROUTE_CUSTOM_UNILABS,
    ROUTE_DEFAULT_MODULAR,
    ROUTE_EULA,
    ROUTE_EULA_DATA_PRIVACY,
    ROUTE_EULA_HEALTH_PROFESSIONAL,
    ROUTE_EULA_PATIENT,
    ROUTE_FAQ,
    ROUTE_FORCED_CONNECTION,
    ROUTE_GET_SHARED_REPORT,
    ROUTE_LEGAL_NOTICE,
    ROUTE_LOGIN,
    ROUTE_MEDICAL_REPORT_ACCESS,
    ROUTE_MEDICAL_REPORT_CONNECTION_MODES,
    ROUTE_MEDICAL_REPORT_PERSON_REPORTS,
    ROUTE_MEDICAL_REPORT_RANDOM_REPORT,
    ROUTE_MEDICAL_REPORT_REPORT,
    ROUTE_MODULAR_PAGE,
    ROUTE_NOTICE_DOWNLOAD,
    ROUTE_OUTDATED_REPORT_ACCESS_LINK,
    ROUTE_PIN,
    ROUTE_REDIRECT_CPS,
    ROUTE_SSO,
    ROUTE_TECHNICAL_MANUAL_DOWNLOAD,
    ROUTE_TRIGGER_NOTIFICATION,
    ROUTE_USER_FORGOT_PASSWORD,
    ROUTE_USER_RESET_PASSWORD,
    ROUTE_USER_SET_PASSWORD,
} from 'core/routing/Routes';
import { LABORATORY_UUID_UNILABS } from "models/entities/default";
import React, { Fragment, ReactNode } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';

enum RouteOption {
    FOOTER = 'FOOTER',
    NAV_BAR = 'NAV_BAR',
    WHITE_BACKGROUND = 'WHITE_BACKGROUND',
    NAVIGATION = 'NAVIGATION',
    TINTED_BACKGROUND = 'TINTED_BACKGROUND',
    HIDE_FOOTER = 'HIDE_FOOTER',
}

interface KiroRouteProps extends RouteProps {
    loginNotRequired?: boolean;
    with?: RouteOption | RouteOption[];
}

export class KiroRoute extends Route<KiroRouteProps> {
    render(): ReactNode {
        let content = super.render();

        const withs: RouteOption[] = this.props.with === undefined ? [] : (
            Array.isArray(this.props.with) ? this.props.with : [this.props.with]
        );

        if (withs.includes(RouteOption.NAVIGATION)) {
            const withNavigation = <WithNavigation
                withTintedBackground={withs.includes(RouteOption.TINTED_BACKGROUND)}
                hideFooter={withs.includes(RouteOption.HIDE_FOOTER)}
            >
                {content}
            </WithNavigation>;

            return <Fragment>
                <ModalWrapper/>
                {this.props.loginNotRequired ? withNavigation : <LogginRequired>{withNavigation}</LogginRequired>}
            </Fragment>
        }

        if (
            withs.includes(RouteOption.NAV_BAR)
            || withs.includes(RouteOption.FOOTER)
        ) {
            content = <BaseComponent>{content}</BaseComponent>;
        }

        if (this.props.loginNotRequired && withs.includes(RouteOption.NAV_BAR)) {
            content = <WithDisconnectedNavBar withWhiteBackground={withs.includes(RouteOption.WHITE_BACKGROUND)}>
                {content}
            </WithDisconnectedNavBar>;
        } else if (withs.includes(RouteOption.FOOTER)) {
            content = <WithFooter> {content}</WithFooter>;
        } else if (withs.includes(RouteOption.NAV_BAR)) {
            content = (
                <WithNavBar withWhiteBackground={withs.includes(RouteOption.WHITE_BACKGROUND)}>
                    {content}
                </WithNavBar>
            );
        }

        if (!this.props.loginNotRequired) {
            content = <LogginRequired>{content}</LogginRequired>;
        }

        return <Fragment>
            <ModalWrapper/>
            {content}
        </Fragment>
    }
}

export const appSwitch = (
    <Switch>
        <Route exact path="/">
            <Redirect to={ROUTE_DEFAULT_MODULAR}/>
        </Route>
        <Route exact path={ROUTE_NOTICE_DOWNLOAD}>
            <Navigate path={CONFIG.apiUrl + ROUTE_NOTICE_DOWNLOAD}/>
        </Route>
        <Route exact path={ROUTE_TECHNICAL_MANUAL_DOWNLOAD}>
            <Navigate path={CONFIG.apiUrl + ROUTE_TECHNICAL_MANUAL_DOWNLOAD}/>
        </Route>
        <Route exact path={ROUTE_CUSTOM_UNILABS}>
            <Redirect to={buildNotificationPath(ROUTE_TRIGGER_NOTIFICATION, LABORATORY_UUID_UNILABS)}/>
        </Route>
        <Route exact path={ROUTE_MEDICAL_REPORT_REPORT}
               render={(props: RouteComponentProps<{ uuid: string }>): JSX.Element => <Redirect
                   to={getRoute(ROUTE_MODULAR_PAGE, {pageId: 'report', report_uuid: props.match.params.uuid})}
               />}
        />
        <Route exact path={ROUTE_MEDICAL_REPORT_PERSON_REPORTS}
                   render={(props: RouteComponentProps<{ personId: string }>): JSX.Element => <Redirect
                       to={getRoute(ROUTE_MODULAR_PAGE, {pageId: 'patient_profile', patient_uuid: getPersonIdFromLegacy(props.match.params.personId)})}
                   />}
        />
        <KiroRoute
            exact
            path={ROUTE_USER_RESET_PASSWORD}
            component={ResetPasswordPage}
            loginNotRequired
            with={RouteOption.FOOTER}
        />
        <KiroRoute
            exact
            path={ROUTE_USER_SET_PASSWORD}
            component={SetPasswordPage}
            loginNotRequired
            with={RouteOption.FOOTER}
        />
        <KiroRoute
            exact
            path={ROUTE_USER_FORGOT_PASSWORD}
            component={ForgotPassword}
            loginNotRequired
            with={[RouteOption.FOOTER, RouteOption.NAV_BAR, RouteOption.WHITE_BACKGROUND]}
        />
        <KiroRoute exact path={ROUTE_LOGIN} component={Login} loginNotRequired with={undefined}/>
        <KiroRoute exact path={ROUTE_PIN} component={PinPage} loginNotRequired with={[RouteOption.NAVIGATION, RouteOption.FOOTER]}/>
        <KiroRoute exact path={ROUTE_AUTH_CPS} component={AuthCPS} loginNotRequired with={undefined}/>
        <KiroRoute exact path={ROUTE_REDIRECT_CPS} component={RedirectToCps} loginNotRequired with={undefined}/>
        <KiroRoute exact path={ROUTE_LEGAL_NOTICE} component={LegalNotice} loginNotRequired with={RouteOption.FOOTER}/>
        <KiroRoute exact path={ROUTE_EULA} component={Eula} loginNotRequired with={RouteOption.FOOTER}/>
        <KiroRoute exact path={ROUTE_SSO} component={SSOPage} loginNotRequired with={RouteOption.FOOTER}/>
        <KiroRoute exact path={ROUTE_FORCED_CONNECTION} component={EnforcedLocalConnect} loginNotRequired
                   with={RouteOption.FOOTER}/>
        <KiroRoute exact path={ROUTE_FAQ} component={Faq} loginNotRequired with={RouteOption.FOOTER}/>
        <KiroRoute
            exact
            path={ROUTE_EULA_HEALTH_PROFESSIONAL}
            render={(props): ReactNode => <EulaContainer type={EULAType.HEALTH_PROFESSIONAL} {...props} />}
            loginNotRequired
            with={RouteOption.FOOTER}
        />
        <KiroRoute
            exact
            path={ROUTE_EULA_PATIENT}
            render={(props): ReactNode => <EulaContainer type={EULAType.PATIENT} {...props} />}
            loginNotRequired
            with={RouteOption.FOOTER}
        />
        <KiroRoute
            exact
            path={ROUTE_EULA_DATA_PRIVACY}
            render={(props): ReactNode => <EulaContainer type={EULAType.DATA_PRIVACY} {...props} />}
            loginNotRequired
            with={RouteOption.FOOTER}
        />
        <KiroRoute
            exact
            path={ROUTE_TRIGGER_NOTIFICATION}
            component={TriggerNotificationByIdentifier}
            loginNotRequired
            with={RouteOption.FOOTER}
        />
        <KiroRoute
            exact
            path={ROUTE_MEDICAL_REPORT_ACCESS}
            loginNotRequired
            component={OutdatedReport}
            with={[RouteOption.NAVIGATION, RouteOption.TINTED_BACKGROUND, RouteOption.HIDE_FOOTER]}
        />
        <KiroRoute
            exact
            path={ROUTE_MEDICAL_REPORT_CONNECTION_MODES}
            loginNotRequired
            component={ConnectionModes}
            with={undefined}
        />
        <KiroRoute
            exact
            path={ROUTE_ACCESS_BY_CONNECTION_MODES}
            loginNotRequired
            component={AccessByConnectionModes}
            with={undefined}
        />
        <KiroRoute
            exact
            path={ROUTE_OUTDATED_REPORT_ACCESS_LINK}
            loginNotRequired
            component={OutdatedReport}
            with={[RouteOption.NAVIGATION, RouteOption.TINTED_BACKGROUND, RouteOption.HIDE_FOOTER]}
        />
        <KiroRoute
            exact
            path={ROUTE_GET_SHARED_REPORT}
            loginNotRequired
            component={SharedReport}
            with={undefined}
        />
        <KiroRoute
            exact
            path={ROUTE_MEDICAL_REPORT_RANDOM_REPORT}
            component={RandomReport}
            loginNotRequired={undefined}
            with={[RouteOption.NAV_BAR, RouteOption.WHITE_BACKGROUND]}
        />
        <KiroRoute
            exact
            path={ROUTE_MODULAR_PAGE}
            loginNotRequired
            component={ModularPage}
            with={[RouteOption.NAVIGATION, RouteOption.TINTED_BACKGROUND]}
        />
        <KiroRoute
            exact
            path={ROUTE_DEFAULT_MODULAR}
            component={DefaultPage}
            loginNotRequired={undefined}
            with={[RouteOption.NAVIGATION]}
        />
        <Route component={Page404}/>
    </Switch>
);

export const APP_ROUTES: Route[] = [];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapAllRoutes = (element: any, depth = 0): void => {
    if (!element || !element.props) return;

    if (element.props.path && element.props.component) {
        APP_ROUTES.push(element);
    }

    React.Children.forEach(element.props.children, (element) => {
        mapAllRoutes(element, depth + 1);
    });
};

mapAllRoutes(appSwitch);