export const getRoute = (route: string, params: { [key: string]: string | null }): string => {
    let queryString = ''
    for (const key in params) {
        const param = params[key];
        if (route.includes(':' + key)) {
            route = route.replace(':' + key, param === null ? '' : param);
        } else if (param !== null) {
            const paramString = key + '=' + param
            if (!queryString) {
                queryString += '?' + paramString
            } else {
                queryString += '&' + paramString
            }
        }
    }

    return route + queryString;
};

export const getPersonIdFromLegacy = (legacyPersonId: string): string => {
    return legacyPersonId.startsWith("p:") ? legacyPersonId.substring(2) : legacyPersonId
}