import React from 'react';

const LogoSimple = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 50 25">
            <path d="M44.6503 9.00537H17.8326C15.9343 9.00537 14.396 10.5661 14.396 12.4921C14.396 14.4181 15.9343 15.9789 17.8326 15.9789H44.6503C46.5486 15.9789 48.0869 14.4181 48.0869 12.4921C48.0869 10.5661 46.5486 9.00537 44.6503 9.00537Z" />
            <path d="M30.2543 18.0104H3.43658C1.53831 18.0104 0 19.5712 0 21.4971C0 23.4231 1.53831 24.9839 3.43658 24.9839H30.2543C32.1526 24.9839 33.6909 23.4231 33.6909 21.4971C33.6909 19.5712 32.1526 18.0104 30.2543 18.0104Z" />
            <path d="M35.4179 0.000366211H8.60016C6.70188 0.000366211 5.16357 1.56112 5.16357 3.48711C5.16357 5.41309 6.70188 6.97385 8.60016 6.97385H35.4179C37.3161 6.97385 38.8544 5.41309 38.8544 3.48711C38.8544 1.56112 37.3161 0.000366211 35.4179 0.000366211Z" />
        </svg>
    )
}

export default LogoSimple;
