import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ChevronRight = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-chevron-right'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M5.71967 3.96967C6.01256 3.67678 6.48744 3.67678 6.78033 3.96967L10.2803 7.46967C10.421 7.61032 10.5 7.80109 10.5 8C10.5 8.19891 10.421 8.38968 10.2803 8.53033L6.78033 12.0303C6.48744 12.3232 6.01256 12.3232 5.71967 12.0303C5.42678 11.7374 5.42678 11.2626 5.71967 10.9697L8.68934 8L5.71967 5.03033C5.42678 4.73744 5.42678 4.26256 5.71967 3.96967Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-chevron-right'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.79289 5.79289C9.18342 5.40237 9.81658 5.40237 10.2071 5.79289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L10.2071 18.2071C9.81658 18.5976 9.18342 18.5976 8.79289 18.2071C8.40237 17.8166 8.40237 17.1834 8.79289 16.7929L13.5858 12L8.79289 7.20711C8.40237 6.81658 8.40237 6.18342 8.79289 5.79289Z"
                  fill="currentColor"/>
        </svg>
    )
}