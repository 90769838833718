import { modal, ModalType } from "actions/common/CommonActions";
import ChartTimeSeriesWithSelector, {
    ChartTimeSeriesWithSelectorProps
} from "components/commonDesign/charts/ChartTimeSeriesWithSelector";
import { COMPACT_CHART_HEIGHT } from "components/commonDesign/charts/constants";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import { AlwaysInline, Grow, InlineSpacing } from "components/designSystem/containers/Inline";
import { LargeSpacer } from "components/designSystem/containers/Spacer";
import { TDisplayXSmall, TypographyColor } from "components/designSystem/containers/Typography";
import { PaddingSize, WithVerticalPadding } from "components/designSystem/containers/WithPadding";
import Icon from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { getIconByName } from "core/content/icons/IconFinder";
import { cast } from "core/utils/Typed";
import { TimeSeriePayload } from "models/charts/charts/TimeSerie";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";

type BioCurveModulePayload = {
    title: string
    icon: string
    chart?: ChartTimeSeriesWithSelectorProps
};

export const BioCurveModule = (): JSX.Element => {
    const payload = useModulePayload<BioCurveModulePayload>();
    const dispatch = useDispatch();
    const chart = payload.chart

    if (!chart) {
        return <></>
    }

    const iconName = getIconByName(payload.icon);
    return <Box background={BoxColor.WHITE} withPadding withBoxShadow><WithVerticalPadding padding={PaddingSize.MEDIUM}>
        <LargeSpacer>
            <AlwaysInline spacing={InlineSpacing.MEDIUM}>
                {iconName && <Icon size={IconSize.SMALL} name={iconName} color={IconColor.DEFAULT}/>}
                <TDisplayXSmall color={TypographyColor.COLOR_TEXT_DEFAULT}>{payload.title}</TDisplayXSmall>
                <Grow/>
                <Button
                    variant={ButtonVariant.QUATERNARY}
                    size={ButtonSize.SMALL}
                    icons={{middle: IconName.EXPAND}}
                    onClick={(): void => {
                        dispatch(
                            modal(
                                ModalType.CENTER,
                                (): ReactNode => <ChartTimeSeriesWithSelector
                                    items={chart.items}
                                    payload={cast<TimeSeriePayload>(chart.payload)}
                                />,
                                {
                                    title: {
                                        default: payload.title,
                                    },
                                },
                            )
                        )
                    }}/>
            </AlwaysInline>
            <ChartTimeSeriesWithSelector
                items={chart.items}
                payload={cast<TimeSeriePayload>(chart.payload)}
                chartHeight={COMPACT_CHART_HEIGHT}
            />
        </LargeSpacer>
    </WithVerticalPadding></Box>
}
