import { Moment } from "moment";
import { APIText } from "./message";

export enum FormItemType {
    TEXT = 'TEXT',
    DATE = 'DATE',
    PASSWORD = 'PASSWORD',
    GENDER = 'GENDER',
    TEL = 'TEL'
}
export interface TextPayloadBase {
    name: string;
    type: "text" | "textarea";
    is_required: boolean;
    editable: boolean;
    default_value?: string;
    auto_completion?: "username" | "email"
}

export interface APITextPayload extends TextPayloadBase {
    label?: APIText;
}

export interface TextPayload extends TextPayloadBase {
    label?: string | APIText;
}

export interface PasswordPayloadBase {
    name: string;
    type: "password";
}

export interface APIPasswordPayload extends PasswordPayloadBase {
    label?: APIText;
}

export interface PasswordPayload extends PasswordPayloadBase {
    label?: string | APIText;
    is_required?: boolean;
    auto_completion?: "new-password" | "current-password"
    defaultValue?: string;
    // @ts-ignore
    onClick?: (event) => void;
}

export interface DatePayloadBase {
    name: string;
    type: "date";
    is_required: boolean;
    auto_completion?: "bday"
    default_value?: string;
    editable?: boolean;
}

export interface APIDatePayload extends DatePayloadBase {
    label?: APIText;
}

export interface DatePayload extends DatePayloadBase {
    label?: string | APIText;
    maxDate?: Moment
}

export interface Phone{
    country_code?: number
    national_number?: string
}

export interface TelPayloadBase {
    name: string;
    type: "tel";
    is_required: boolean;
    default_value?: Phone;
    auto_completion?: "tel"
}

export interface APITelPayload extends TelPayloadBase {
    label?: APIText;
}

export interface TelPayload extends TelPayloadBase {
    label?: string | APIText;
}

export interface FormItemWithPayloadBase {
    key: string;
    type: FormItemType;
    rank: number;
}

export interface APIFormItemWithPayload extends FormItemWithPayloadBase {
    payload: APITextPayload | APIPasswordPayload | APIDatePayload | APITelPayload;
}

export interface FormItemWithPayload extends FormItemWithPayloadBase {
    payload: TextPayload | PasswordPayload | DatePayload | TelPayload;
}

