import React, { FC, ReactNode } from 'react';
import Button, { ButtonClickPayload, ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { Tag, TagSeverity, TagSize } from "components/designSystem/components/Tag"

import styles from './CommunicationBox.module.scss';
import classNames from "classnames";


export interface CallToAction {
    actionName: ReactNode;
    onClick: ButtonClickPayload;
}

interface CommunicationBoxProps {
    title: ReactNode,
    description: ReactNode
    callToAction: CallToAction
    fullWidthCTA?: boolean
    withTag?: ReactNode
    outsideAssets?: ReactNode
}

const CommunicationBox: FC<CommunicationBoxProps> = ({...props}): JSX.Element => {
    return <div className={classNames({
        [styles.communicationBoxWithAssets]: props.outsideAssets !== undefined,
    })}>
        <div className={styles.communicationBox}>
            <div className={styles.outsideAssets}>
                {props.outsideAssets}
            </div>
            {props.withTag && <Tag size={TagSize.SMALL} severity={TagSeverity.HIGHLIGHT}>{props.withTag}</Tag>}
            <div className={styles.titleAndDescription}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.description}>{props.description}</div>
            </div>
            <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.MEDIUM} fullWidth={props.fullWidthCTA}
                    onClick={props.callToAction.onClick}
            >
                {props.callToAction.actionName}
            </Button>
        </div>
    </div>
}

export default CommunicationBox;