import { put, takeEvery } from '@redux-saga/core/effects';
import {
    ModuleAPICallAction,
    moduleApiCallSuccessAction,
    OwnPayloadOverrideMode
} from 'actions/modular/ModularActions';
import CallHandler from 'sagas/api/CallHandler';
import { AnyIterator, notEmpty } from 'core/utils/Typed';
import {
    API_FETCH_PATIENT_REPORTS,
    API_GET_REPORTS_METADATA,
    FetchPatientReportsPayload,
    getReportsMetadata,
    GetReportsMetadataPayload,
} from 'actions/modular/modules/ReportsActions';
import { fetchPatientReportsCall, getReportsMetadataCall } from 'sagas/api/Endpoints';
import _ from 'underscore';
import { ReportInfo } from 'models/modular/report';

/**
 * Size of batched queries to fetch report metadata. The tokens to fetch the metadata are used as URL query params,
 * therefore it's important to limit their number in order to prevent the URL from exceeding the maximum allowed size.
 */
const REPORT_METADATA_BATCH_SIZE = 10;


interface ApiReportInfo {
    uuid: string;
    patient_uuid: string;
    sampling_date: string;
    reception_date: string;
    is_new: boolean;
    access_token: string;
    is_sent: boolean;
    is_ai_suggestions_enriched: boolean;
}


const formatApiReportInfo = (r: ApiReportInfo): ReportInfo => ({
    uuid: r.uuid,
    patientUUID: r.patient_uuid,
    samplingDate: new Date(r.sampling_date),
    receptionDate: new Date(r.reception_date),
    isNew: r.is_new,
    isSent: r.is_sent,
    isAiSuggestionsEnriched: r.is_ai_suggestions_enriched,
})

export const fetchPatientReportsSaga = function* (action: ModuleAPICallAction<FetchPatientReportsPayload>): AnyIterator {
    const response = yield CallHandler.for(fetchPatientReportsCall, action).call();

    if (response && action.payload.params) {
        const reportsByPatientUUID: { [uuid: string]: ReportInfo[] } = {}
        action.payload.params.patientUUIDs.forEach((uuid: string) => {
            reportsByPatientUUID[uuid] =
                _.keys(response.reports_by_patient_uuid).includes(uuid)
                    ? response.reports_by_patient_uuid[uuid].map(
                        (r: ApiReportInfo) => formatApiReportInfo(r)
                    ) : []
        })

        yield put(moduleApiCallSuccessAction(
            action.payload.moduleIdentifier,
            { reportsByPatientUUID: reportsByPatientUUID },
            null,
            OwnPayloadOverrideMode.SPREAD_BY_KEY,
        ));

        let access_tokens: string[] = []
        _.values(response.reports_by_patient_uuid).forEach(
            (reports: ApiReportInfo[]) => {
                access_tokens.push(
                    ...reports.map((r: ApiReportInfo) => r.access_token)
                )
            }
        )
        access_tokens = access_tokens.filter(notEmpty)

        if (!_.isEmpty(access_tokens)) {
            let i = 0
            while (i < access_tokens.length) {
                yield put(
                    getReportsMetadata(action.payload.moduleIdentifier, access_tokens.slice(i, i + REPORT_METADATA_BATCH_SIZE))
                )
                i += REPORT_METADATA_BATCH_SIZE
            }
        }
    }
};

export const getReportsMetadataSaga = function* (action: ModuleAPICallAction<GetReportsMetadataPayload>): AnyIterator {
    const response = yield CallHandler.for(getReportsMetadataCall, action).call();
    if (response) {
        yield put(moduleApiCallSuccessAction(
            action.payload.moduleIdentifier,
            {
                metadataByReportUUID: response.metadata_by_report_uuid,
            },
            null,
            OwnPayloadOverrideMode.SPREAD_BY_KEY,
        ))
    }
}

export default function* ReportsSagas(): AnyIterator {
    yield takeEvery(API_FETCH_PATIENT_REPORTS, fetchPatientReportsSaga);
    yield takeEvery(API_GET_REPORTS_METADATA, getReportsMetadataSaga);
}

