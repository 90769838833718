import { ColorMap } from "components/designSystem/Aliases";
import React from 'react';

import { Bar, BarChart, CartesianAxisProps, Cell, LabelList, ResponsiveContainer, Text, XAxis } from 'recharts';
import { DEFAULT_CHART_HEIGHT } from '../constants';
import { ChartFilter, ChartFilterSwitchFilter, ChartItem, ChartItemTubeBar, ChartItemType } from 'models/charts/charts';
import { cast } from 'core/utils/Typed';
import { APIText, TranslationMethod } from 'models/common/message';
import { BORDER_RADIUS, RawColors } from 'components/legacyDesignSystem/branding/constants';
import { chartColorsToHexCode } from 'components/legacyDesignSystem/components/charts/models';

import './Tube.scss';
import { buildAxis } from 'components/legacyDesignSystem/components/charts/builders/Axis';
import { AxisDataType, AxisDirection } from 'components/legacyDesignSystem/components/charts/builders/model';
import { ValueFormatterInterface } from 'models/charts/models';
import { NumericalValue } from 'models/medicalReport/ReportModels';

interface ChartTube {
    title: APIText;
    value: React.ReactNode;
    display_value: NumericalValue | null;
    color: string;
    scale: {
        min: number | null,
        max: number | null
    };
}


function buildData(items: ChartItem[], filters?: ChartFilter[]): ChartTube[] {
    const tubes: ChartTube[] = [];

    items.filter((p) => p.type == ChartItemType.TUBE_BAR).forEach(({ payload }): void => {
        const chartItem = cast<ChartItemTubeBar>(payload);
        const color = chartColorsToHexCode(cast<ColorMap>(chartItem.data.color));

        if (filters?.length) {
            const filter = filters[0];
            const filterPayload = cast<ChartFilterSwitchFilter>(filter.payload);
            const numericalValue = chartItem.data.values_by_type[filterPayload.active_filter];
            const scale = chartItem.scale_by_type[filterPayload.active_filter]
            if (numericalValue) {
                tubes.push({
                    title: chartItem.title,
                    value: numericalValue.value,
                    display_value: numericalValue,
                    color,
                    scale: {
                        min: scale && scale.min ? scale.min.value : null,
                        max: scale && scale.max ? scale.max.value : null,
                    }
                });

            }
        } else {
            const keys = Object.keys(chartItem.data.values_by_type)
            if (keys.length > 0) {
                const numericalValue = chartItem.data.values_by_type[keys[0]];
                const scale = chartItem.scale_by_type[keys[0]]
                if (numericalValue) {
                    tubes.push({
                        title: chartItem.title,
                        value: numericalValue.value,
                        display_value: numericalValue,
                        color,
                        scale: {
                            min: scale && scale.min ? scale.min.value : null,
                            max: scale && scale.max ? scale.max.value : null,
                        }
                    });
                }
            }

        }
    });

    return tubes;
}

class AxisTickWithWordWrap extends React.PureComponent<CartesianAxisProps> {
    render(): React.ReactElement {
        // @ts-ignore TODO: Why payload isn't in CartesianAxisProps ??
        const { x, y, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <Text x={0} y={30} dy={16} textAnchor="middle" fill="#666" width={110}>
                    {payload.value}
                </Text>
            </g>
        );
    }
}

export function buildTube(
    items: ChartItem[],
    translation: TranslationMethod,
    filters?: ChartFilter[],
    valueFormatter?: ValueFormatterInterface
): JSX.Element {
    const data = buildData(items, filters);

    const min = Math.min(...data.map((d) => d.scale.min ? d.scale.min : 0))
    const max = Math.max(...data.map((d) => d.scale.max ? d.scale.max : -1))

    return (
        <ResponsiveContainer width="100%" height={DEFAULT_CHART_HEIGHT}>
            <BarChart data={data} margin={{ bottom: 30 }}>
                <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="title.text"
                    tick={<AxisTickWithWordWrap />}
                    tickFormatter={valueFormatter}
                    interval={0}
                />
                {
                    buildAxis(
                        "title.text",
                        AxisDirection.Y,
                        AxisDataType.NUMERICAL,
                        undefined,
                        min,
                        max,
                        valueFormatter
                    )
                }
                <Bar
                    radius={BORDER_RADIUS}
                    dataKey="value"
                    fill={'#8884d8'}
                    background={{ fill: RawColors.NEUTRALS_LIGHT_BLUE_GREY, radius: BORDER_RADIUS }}
                >
                    <LabelList
                        formatter={(l: string | number): string | number | null => {
                            return (typeof l === 'string' || l == null || !valueFormatter) ? l : valueFormatter(l)
                        }}
                        dataKey="display_value"
                        position={'insideBottom'}
                        offset={16}
                        className={'tube-label'}
                    />
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
