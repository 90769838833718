import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ChevronUp = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-chevron-up'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M3.96967 10.2803C3.67678 9.98744 3.67678 9.51256 3.96967 9.21967L7.46967 5.71967C7.76256 5.42678 8.23744 5.42678 8.53033 5.71967L12.0303 9.21967C12.3232 9.51256 12.3232 9.98744 12.0303 10.2803C11.7374 10.5732 11.2626 10.5732 10.9697 10.2803L8 7.31066L5.03033 10.2803C4.73744 10.5732 4.26256 10.5732 3.96967 10.2803Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-chevron-up'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.79289 15.2071C5.40237 14.8166 5.40237 14.1834 5.79289 13.7929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.2071 13.7929C18.5976 14.1834 18.5976 14.8166 18.2071 15.2071C17.8166 15.5976 17.1834 15.5976 16.7929 15.2071L12 10.4142L7.20711 15.2071C6.81658 15.5976 6.18342 15.5976 5.79289 15.2071Z"
                  fill="currentColor"/>
        </svg>
    )
}