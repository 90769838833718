import { ActionDispatcher } from "actions/ActionInterface";
import { modal, ModalType } from "actions/common/CommonActions";
import TrText, { key } from "components/common/TrText";
import { LayoutCard } from "components/commonDesign/LayoutCard";
import Alert, { AlertSeverity } from "components/designSystem/components/Alert";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import EmptyState, { EmptyStateType, MaxWidthSize } from "components/designSystem/components/EmptyState";
import { BackgroundGradientColor, BoxColor } from "components/designSystem/containers/Box";
import Inline, { AlwaysInline, InlineJustifyContent, InlineSpacing } from "components/designSystem/containers/Inline";
import Spacer, { SpacerJustifyContent, SpacerSpacing } from "components/designSystem/containers/Spacer";
import Typography, { TypographyColor, TypographyVariant } from "components/designSystem/containers/Typography";
import { MarginSize, WithTopMargin } from "components/designSystem/containers/WithMargin";
import { IconName } from "components/designSystem/foundations/IconsData";
import { useModuleAttributes } from "components/modules/modular/ModuleContainer";
import { buildClickModuleAction } from "components/modules/modular/modules/builders/userAction";
import { TransDomain } from "components/pages/common/MainComponent";
import { getPlatformResource } from "core/content/cdn";
import LOGGER from "core/logging/Logger";
import { ModuleV1Type } from "models/modular/api";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

function ModalComponent(
    props: {
        pageId: string,
        type: ModuleV1Type
    }
): JSX.Element {

    const [clicked, setClicked] = useState(false)
    const [sendMeAnEmail, setSendMeAnEmail] = useState(false)

    const emptyStateTitle = clicked ?
        <TrText input={key("modal.then.title", TransDomain.MODULES_DOCUMENTS)}/> :
        <TrText input={key("modal.title", TransDomain.MODULES_DOCUMENTS)}/>

    const emptyStateDescription = clicked ?
        <TrText input={key("modal.then.description", TransDomain.MODULES_DOCUMENTS)}/> :
        <TrText input={key("modal.description", TransDomain.MODULES_DOCUMENTS)}/>

    const wipImage = getPlatformResource('global/work_in_progress.svg')
    return <WithTopMargin margin={MarginSize.LARGE}>
        <Spacer spacing={SpacerSpacing.MEDIUM} >
            <div>
                <EmptyState
                    title={emptyStateTitle}
                    description={emptyStateDescription}
                    maxWidth={MaxWidthSize.WIDTH_40}
                    payload={
                        clicked ?
                            {type: EmptyStateType.ILLUSTRATION, src: wipImage.src, alt: wipImage.alt} :
                            {type: EmptyStateType.ICON, name: IconName.FILE_UPLOAD}
                    }
                />
            </div>
            <AlwaysInline justifyContent={InlineJustifyContent.CENTER} spacing={InlineSpacing.LARGE}>
                {
                    clicked ? <>
                    {
                        sendMeAnEmail ?
                            <Alert description={
                                <TrText input={key("modal.then.notify_me_response", TransDomain.MODULES_DOCUMENTS)}/>
                            } severity={AlertSeverity.SUCCESS}/> :
                        <Button
                            onClick={(): void => {
                                LOGGER.userAction(
                                    buildClickModuleAction(
                                        props.pageId,
                                        props.type,
                                        'UPLOAD_NEW_UNREADY:NOTIFY_ME'
                                    )
                                )
                                setSendMeAnEmail(true)
                            }}
                            variant={ButtonVariant.SECONDARY}
                            size={ButtonSize.MEDIUM}
                        >
                            <TrText input={key("modal.then.notify_me", TransDomain.MODULES_DOCUMENTS)}/>
                        </Button>
                    }

                    </> : <>
                        <Button
                            onClick={(): void => {
                                LOGGER.userAction(
                                    buildClickModuleAction(
                                        props.pageId,
                                        props.type,
                                        'UPLOAD_NEW_UNREADY:PDF'
                                    )
                                )
                                setClicked(true)
                            }}
                            variant={ButtonVariant.SECONDARY}
                            size={ButtonSize.MEDIUM}
                        >
                            <TrText input={key("modal.types.pdf", TransDomain.MODULES_DOCUMENTS)}/>
                        </Button>
                        <Button
                            variant={ButtonVariant.SECONDARY}
                            size={ButtonSize.MEDIUM}
                            onClick={(): void => {
                                LOGGER.userAction(
                                    buildClickModuleAction(
                                        props.pageId,
                                        props.type,
                                        'UPLOAD_NEW_UNREADY:PHOTO'
                                    )
                                )
                                setClicked(true)
                            }}
                        >
                            <TrText input={key("modal.types.image", TransDomain.MODULES_DOCUMENTS)}/>
                        </Button>
                    </>
                }

            </AlwaysInline>
        </Spacer>
    </WithTopMargin>
}

export const ModalComponentForTest = ModalComponent;

function modalUpload(
    dispatch: ActionDispatcher,
    pageId: string,
    type: ModuleV1Type
): void {

    LOGGER.userAction(
        buildClickModuleAction(
            pageId,
            type,
            'UPLOAD_NEW_UNREADY'
        )
    )

    dispatch(
        modal(
            ModalType.CENTER,
            () => <ModalComponent pageId={pageId} type={type}/>
        )
    )

}

export const DocumentsModule = (): JSX.Element => {
    const dispatch = useDispatch();
    const attributes = useModuleAttributes();

    return <LayoutCard
        image={getPlatformResource('documents/module_image.svg')}
        color={BoxColor.GRADIENT_BLUE}
        backgroundGradientColor={BackgroundGradientColor.DARK_BLUE}
        imageDisplay={{heightSizingInPx: 150}}
    >
        <Spacer spacing={SpacerSpacing.MEDIUM} justifyContent={SpacerJustifyContent.SPACE_BETWEEN} withFullHeight>
            <Spacer  spacing={SpacerSpacing.SMALL}>
                <div>
                    <Typography variant={TypographyVariant.DISPLAY_X_SMALL} color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                        <TrText input={key("title", TransDomain.MODULES_DOCUMENTS)}/>
                    </Typography>
                </div>
                <div>
                    <Typography variant={TypographyVariant.BODY}  color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                        <TrText input={key("description", TransDomain.MODULES_DOCUMENTS)}/>
                    </Typography>
                </div>
            </Spacer>
            <Inline>
                <Button
                    onClick={(): void => modalUpload(dispatch, attributes.pageId, attributes.type)}
                    variant={ButtonVariant.SECONDARY}
                    size={ButtonSize.LARGE}
                    // icons={{right: IconName.SEND}}
                >
                    <TrText input={key("button", TransDomain.MODULES_DOCUMENTS)}/>
                </Button>
            </Inline>

        </Spacer>
    </LayoutCard>
}
