import { ROUTE_DEFAULT_MODULAR } from "core/routing/Routes";
import React, { ReactNode } from 'react';
import { fetchReducer } from 'reducers/selector';
import { REDUCER_ACCOUNT } from 'reducers/allReducers';
import { FEEDBACK_IDENTIFIER_RESET_PASSWORD, resetPW } from 'actions/user/UserActions';
import MainComponent, { MainComponentProps, setup, TransDomain } from 'components/pages/common/MainComponent';
import ResetPassword from 'components/modules/user/legacy/ResetPassword';
import { AnyState } from 'core/store/Store';
import { ActionDispatcher } from 'actions/ActionInterface';
import { redirect } from 'actions/common/CommonActions';

export interface ResetPasswordStateProps {
    token: string;
    validToken?: string;
}

export interface ResetPasswordDispatchProps {
    onSubmit: (password: string, confirmation: string, token: string) => void;
}

class ResetPasswordPage extends MainComponent<
    ResetPasswordStateProps & ResetPasswordDispatchProps & MainComponentProps
> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    render(): ReactNode {
        return <ResetPassword
            first_set_password={false}
            feedbackIdentifier={FEEDBACK_IDENTIFIER_RESET_PASSWORD}
            {...this.props}
        />;
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): ResetPasswordDispatchProps => ({
    onSubmit: (password: string, confirmation: string, token: string): void => {
        dispatch(resetPW(
            password,
            confirmation,
            token,
            [redirect(ROUTE_DEFAULT_MODULAR)],
            FEEDBACK_IDENTIFIER_RESET_PASSWORD
        ));
    },
});

const mapStateToProps = (
    state: AnyState,
    ownProps: { match: { params: { token: string } } },
): ResetPasswordStateProps => ({
    token: ownProps.match.params.token,
    validToken: fetchReducer(state, REDUCER_ACCOUNT).reset_pw_valid_token,
});

export default setup(ResetPasswordPage, mapStateToProps, mapDispatchToProps);
