import classNames from 'classnames';
import { AlwaysInline, InlineSpacing } from "components/designSystem/containers/Inline";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import React, { FC, ReactNode } from 'react';
import styles from './Alert.module.scss';
import Button, { ButtonClickPayload, ButtonOption, ButtonSeverity, ButtonSize, ButtonVariant } from "./Button";

export enum AlertSeverity {
    CRITICAL = 'critical',
    WARNING = 'warning',
    SUCCESS = 'success',
    HIGHLIGHT = 'highlight'
}

export interface CallToAction {
    actionName: ReactNode;
    onClick: ButtonClickPayload;
}

interface AlertProps {
    severity?: AlertSeverity;
    title?: ReactNode;
    description?: ReactNode;
    callToAction?: CallToAction;
    customIcon?: IconName;
    withCloseButton?: () => void ;
}

const getIconNameFromSeverity = (severity: AlertSeverity): IconName => {
    switch (severity){
        case AlertSeverity.CRITICAL:
            return IconName.WARNING_CIRCLE
        case AlertSeverity.WARNING:
            return IconName.WARNING_TRIANGLE
        case AlertSeverity.SUCCESS:
            return IconName.SUCCESS_CIRCLE
        default:
            return IconName.INFO_CIRCLE
    }
}

const getButtonSeverityFromAlertSeverity = (severity: AlertSeverity): undefined | ButtonSeverity => {
    switch (severity){
        case AlertSeverity.CRITICAL:
            return ButtonSeverity.CRITICAL
        case AlertSeverity.WARNING:
            return ButtonSeverity.WARNING
        case AlertSeverity.SUCCESS:
            return ButtonSeverity.SUCCESS
        default:
            return undefined
    }
}

const Alert: FC<AlertProps> = (
    {
        severity = AlertSeverity.HIGHLIGHT,
        ...props
    }): JSX.Element => {
    const computedClassName = classNames(styles.alert, {
        [styles.alertSeverityCritical]: severity === AlertSeverity.CRITICAL,
        [styles.alertSeverityWarning]: severity === AlertSeverity.WARNING,
        [styles.alertSeveritySuccess]: severity === AlertSeverity.SUCCESS,
        [styles.alertSeverityHighLight]: severity === AlertSeverity.HIGHLIGHT
    })

    return <div className={computedClassName}>
        <AlwaysInline spacing={InlineSpacing.SMALL}>
            <div className={styles.icon}>
                <Icon name={props.customIcon ?? getIconNameFromSeverity(severity)} size={IconSize.EXTRA_SMALL}/>
            </div>
            <div className={styles.content}>
                {props.title && <div className={styles.title}>{props.title}</div>}
                {props.description && <div className={styles.description}>{props.description}</div>}
                {props.callToAction && (
                    <div className={styles.buttonContainer}>
                        <Button
                            variant={ButtonVariant.SECONDARY}
                            severity={getButtonSeverityFromAlertSeverity(severity)}
                            onClick={props.callToAction.onClick}
                        >{props.callToAction.actionName}</Button>
                    </div>
                )}
            </div>
        </AlwaysInline>
        {props.withCloseButton && <Button
            variant={ButtonVariant.QUATERNARY}
            option={ButtonOption.DARK}
            size={ButtonSize.EXTRA_SMALL}
            icons={{middle: IconName.CLOSE}}
            onClick={props.withCloseButton}
        />}
    </div>
}

export default Alert;