import { FEEDBACK_IDENTIFIER_UPDATE_DOCTOR_INFO_NOTIF, updateDoctorInfo } from 'actions/user/UserActions';
import Toggle, { TogglePosition } from 'components/designSystem/components/Toggle';
import { NotificationInterval } from 'models/user/AccountModels';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function NotificationToggle({
    notifications_toggle,
}: {
    notifications_toggle: boolean;
}): JSX.Element {
    const [isChecked, setIsChecked] = useState(notifications_toggle);
    const dispatch = useDispatch();
    const handleToggle = useCallback(() => {
        setIsChecked(!isChecked);

        const notificationInterval = isChecked ? NotificationInterval.NEVER : NotificationInterval.DEFAULT;
        dispatch(
            updateDoctorInfo(
                FEEDBACK_IDENTIFIER_UPDATE_DOCTOR_INFO_NOTIF,
                undefined,
                undefined,
                undefined,
                undefined,
                notificationInterval,
            ),
        );
    }, [isChecked, dispatch]);

    return (
        <>
            <Toggle checked={isChecked} label={''} togglePosition={TogglePosition.RIGHT} onClick={handleToggle} />
        </>
    );
}
