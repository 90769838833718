import TrText from "components/common/TrText";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import { CenteredColumn } from "components/designSystem/Aliases";
import Button, { ButtonSize, ButtonTextStyle, ButtonVariant } from "components/designSystem/components/Button";
import Image, { ImageHeight, ImageObjectFit, ImageWidth } from "components/designSystem/components/Image";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import { ColumnWidthInREM } from "components/designSystem/containers/Column";
import { AlwaysInline, InlineJustifyContent } from "components/designSystem/containers/Inline";
import { MediumSpacer } from "components/designSystem/containers/Spacer";
import {
    TBody,
    TDisplayLarge,
    TDisplaySmall500, TDisplayXSmall500,
    TLabel,
    TOverlineSmall,
    TypographyColor
} from "components/designSystem/containers/Typography";
import { MarginSize, WithTopMargin } from "components/designSystem/containers/WithMargin";
import UnorderedList from "components/designSystem/lists/UnorderList";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { DelayAction } from "components/utils/DelayAction";
import { Action, ResourceType, SubResourceType } from "core/logging/ActionEvent";
import LOGGER from "core/logging/Logger";
import {
    Article,
    ArticleImagePayload,
    ArticleParagraphPayload,
    ArticleSection,
    ArticleSectionType, ArticleSubSectionPayload,
    ArticleSubtitlePayload
} from "models/bioProfiles/BioProfileModels";
import React from 'react';
import MediaQuery from "react-responsive";

interface ArticleModulePayload {
    article: Article
}

const TRACKED_ARTICLE_READ_PERCENTS = [0, 50, 100, 200]

const TRANS_SUFFIX = 'modules.article'

const SubtitleSection = (payload: ArticleSubtitlePayload): JSX.Element => <WithTopMargin
    margin={MarginSize.XX_LARGE}>
    <TDisplaySmall500>{payload.text}</TDisplaySmall500>
</WithTopMargin>;

const SubSection = (payload: ArticleSubSectionPayload): JSX.Element => <WithTopMargin margin={MarginSize.LARGE}>
    <TDisplayXSmall500>{payload.text}</TDisplayXSmall500>
</WithTopMargin>

const ParagraphSection = (payload: ArticleParagraphPayload): JSX.Element => <WithTopMargin
    margin={MarginSize.SMALL}>
    <MediumSpacer>
        {payload.text.split('\n').map((text, index) => <div key={index}>{text}</div>)}
    </MediumSpacer>
</WithTopMargin>;

const ImageSection = (payload: ArticleImagePayload): JSX.Element => <WithTopMargin margin={MarginSize.XX_LARGE}>
    <Image
        src={payload.url}
        alt={payload.description ?? ''}
        withObjectFit={ImageObjectFit.SHRINK_TO_FIT_WIDTH}
        withBorder/>
    {payload.description && <AlwaysInline justifyContent={InlineJustifyContent.CENTER}>
        <TOverlineSmall color={TypographyColor.COLOR_TEXT_DISABLED}>{payload.description}</TOverlineSmall>
    </AlwaysInline>}
</WithTopMargin>;

const Section = (section: ArticleSection): JSX.Element => {
    switch (section.type) {
        case ArticleSectionType.SUBTITLE:
            return <SubtitleSection {...section.payload}/>;
        case ArticleSectionType.SUBSECTION:
            return <SubSection {...section.payload}/>;
        case ArticleSectionType.PARAGRAPH:
            return <ParagraphSection {...section.payload}/>;
        case ArticleSectionType.IMAGE:
            return <ImageSection {...section.payload}/>;
    }
};

const Content = ({article}: { article: Article }): JSX.Element => {
    // Set default text style and color
    return <TBody color={TypographyColor.COLOR_TEXT_DEFAULT}>
        <WithTopMargin margin={MarginSize.XX_LARGE}>
            <TDisplayLarge>{article.title}</TDisplayLarge>
        </WithTopMargin>
        <WithTopMargin margin={MarginSize.SMALL}>{article.caption}</WithTopMargin>
        {article.sections.map((section, index): JSX.Element =>
            <div key={index}><Section {...section}/></div>
        )}
        {article.summary.length > 0 && <WithTopMargin margin={MarginSize.XX_LARGE}>
            <Box background={BoxColor.BLUE} withPadding withRadius>
                <MediumSpacer>
                    <TDisplaySmall500 color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                        <TrText input={{trkey: 'summary_title', trdomain: TRANS_SUFFIX}}/>
                    </TDisplaySmall500>
                    <TLabel color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                        <UnorderedList>
                            <MediumSpacer>
                                {article.summary.map((summary, index): JSX.Element =>
                                    <li key={index}>{summary}</li>
                                )}
                            </MediumSpacer>
                        </UnorderedList>
                    </TLabel>
                </MediumSpacer>
            </Box>
        </WithTopMargin>}
        {article.links.length > 0 && <WithTopMargin margin={MarginSize.XX_LARGE}>
            <TDisplaySmall500>
                <TrText input={{trkey: 'links_title', trdomain: TRANS_SUFFIX}}/>
            </TDisplaySmall500>
            <ul>
                {article.links.map((link, index): JSX.Element =>
                    <li key={index}>
                        <Button
                            variant={ButtonVariant.LINK}
                            size={ButtonSize.EXTRA_SMALL}
                            withOverrideTextStyle={ButtonTextStyle.BODY}
                            onClick={{
                                href: link.url,
                                targetBlank: true
                            }}>
                            {link.text ?? link.url}
                        </Button>
                    </li>
                )}
            </ul>
        </WithTopMargin>}
    </TBody>
};

const ReadDurationPercentTracking = ({readDurationSeconds, percent, uuid}: {
    readDurationSeconds: number,
    percent: number,
    uuid: string
}): JSX.Element => {
    const trackedDuration = percent * readDurationSeconds / 100;
    return <DelayAction
        seconds={trackedDuration}
        action={(): void => LOGGER.userAction(
            {
                action: Action.READ_ARTICLE,
                resource_id: uuid,
                resource_type: ResourceType.ARTICLE_ID,
                sub_resource_id: percent.toString(),
                sub_resource_type: SubResourceType.ARTICLE_READ_PERCENT,
                action_payload: trackedDuration.toString()
            }
        )}
    />;
};
export const ArticleModule = (): JSX.Element => {
    const {article} = useModulePayload<ArticleModulePayload>();
    const readDurationSeconds = article.read_duration_seconds;
    return <div>
        {readDurationSeconds && TRACKED_ARTICLE_READ_PERCENTS.map((percent, index) =>
            <ReadDurationPercentTracking
                key={index} readDurationSeconds={readDurationSeconds} percent={percent} uuid={article.uuid}/>
        )}
        <Image
            src={article.image_url}
            alt={article.image_alt}
            width={ImageWidth.FULL}
            height={ImageHeight.COVER}
            withObjectFit={ImageObjectFit.COVER}
            withRadius
        />
        <MediaQuery minWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
            {(match: boolean): JSX.Element => match ? <CenteredColumn widthInRem={ColumnWidthInREM.WIDTH_49}>
                <Content article={article}/>
            </CenteredColumn> : <Content article={article}/>}
        </MediaQuery>
    </div>
};
