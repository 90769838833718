import { ConnectionMode } from "models/medicalReport/ConnectionModesModel";

export type PageContext = {[key: string]: string | null}

export interface PageLinkPayload {
    page_id: string;
    context: PageContext;
}

export enum ModuleV1Type {
    TITLE  = "TITLE",
    ACCOUNT_INFO = 'ACCOUNT_INFO',
    DISCLAIMER  = "DISCLAIMER",
    REPORTS_BY_PATIENT  = "REPORTS_BY_PATIENT",
    REPORT_HEADER = "REPORT_HEADER",
    REPORT_CONTENT = "REPORT_CONTENT",
    REPORT_SUM_UP = "REPORT_SUM_UP",
    REPORT_RECOMMENDATIONS = "REPORT_RECOMMENDATIONS",
    REPORT_VALIDATION = "REPORT_VALIDATION",
    RAW_FILE = "RAW_FILE",
    LAST_REPORTS = "LAST_REPORTS",
    RATING = "RATING",
    HIV_BANNER = "HIV_BANNER",
    PAYMENT  = "PAYMENT",
    SUBSCRIPTION = "SUBSCRIPTION",
    PREVIOUS_REPORTS = "PREVIOUS_REPORTS",
    SIMPLE_COUNTER = 'SIMPLE_COUNTER',
    SIMPLE_CHART = 'SIMPLE_CHART',
    SIMPLE_TABLE_DISTRIBUTION = 'SIMPLE_TABLE_DISTRIBUTION',
    MODULAR_HEADER = 'MODULAR_HEADER',
    LINK_CARD = 'LINK_CARD',
    SIMPLE_HALF_PIE = 'SIMPLE_HALF_PIE',
    SIMPLE_CHART_WITH_SWITCH = 'SIMPLE_CHART_WITH_SWITCH',
    MODULAR_BANNER = 'MODULAR_BANNER',
    COHORTS_COMPARATOR = 'COHORTS_COMPARATOR',
    DATA_TABLE = 'DATA_TABLE',
    SIMPLE_MAP = 'SIMPLE_MAP',
    TELECONSULTATION = 'TELECONSULTATION',
    BIO_CURVE = 'BIO_CURVE',
    BIO_PROFILE_SELECTION = 'BIO_PROFILE_SELECTION',
    PREGNANCY_DUE_DATE = 'PREGNANCY_DUE_DATE',
    PREGNANCY_PROGRESS = 'PREGNANCY_PROGRESS',
    KIRO_INFO = 'KIRO_INFO',
    ARTICLE = 'ARTICLE',
    ARTICLE_LIST = 'ARTICLE_LIST',
    DOCTOR_REPORTS = 'DOCTOR_REPORTS',
    BIOLOGISTS_ADMIN_SEARCH = 'BIOLOGISTS_ADMIN_SEARCH',
    BIOLOGISTS_ADMIN_SEARCH_RESULT = 'BIOLOGISTS_ADMIN_SEARCH_RESULT',
    LOCKED_MODULE = 'LOCKED_MODULE',
    CTA_MODULE= 'CTA_MODULE',
    DOCUMENTS_MODULE = 'DOCUMENTS_MODULE',
}

export interface ModuleV1Identification {
    unique_id: string,
    iteration_id: string,
    iteration_date: Date
}

export enum PageV1ContextDependenciesRuleMode {
    REFRESH = 'REFRESH',
    STRICT = 'STRICT',
    TWINKLE = 'TWINKLE'
}

export interface RemovedItem {
    key: string
    alternative_value: {} | [] | string | null | number
}
export interface PageV1ContextDependenciesRule {
    context_key: string,
    mode: PageV1ContextDependenciesRuleMode,
    removed_item: RemovedItem | null
}

export interface PageV1ContextDependencies {
    rules: PageV1ContextDependenciesRule[],
    context_key_mapping: {[key: string]: string}
}


export enum DisplayVerticalSize {
    AUTO = 'AUTO',
    SMALL = 'SMALL',
    NORMAL = 'NORMAL',
    BIG = 'BIG',
}


export interface ModuleDisplayDataVertical {
    base_size: DisplayVerticalSize,
    min_size?: DisplayVerticalSize,
    max_size?: DisplayVerticalSize,
}

export enum DisplayHorizontalSize {
    ONE_QUARTER = 'ONE_QUARTER',
    THIRD = 'THIRD',
    HALF = 'HALF',
    FULL = 'FULL',
    THREE_QUARTER = 'THREE_QUARTER',
}

export interface ModuleDisplayDataHorizontal {
    size: DisplayHorizontalSize
}

export interface ModulePluginNavigator {
    navigation_identifier: string,
    on_item_change_alter_context_key?: string,
    item_key: string,
}

export enum ModuleGroupZone {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export enum DisplayDataCondition {
    WHEN_REDUCED_SIDE_BAR = 'WHEN_REDUCED_SIDE_BAR'
}

export interface ModuleDisplayDataDisplayContext {
    conditions?: DisplayDataCondition[]
}

export interface ModuleDisplayData {
    module_id: string;
    horizontal?: ModuleDisplayDataHorizontal,
    vertical?: ModuleDisplayDataVertical,
    navigator?: ModulePluginNavigator,
    zone?: ModuleGroupZone
    display_context?: ModuleDisplayDataDisplayContext
}

export interface ModuleV1 {
    type: ModuleV1Type,
    version: string,
    payload: {},
    identification: ModuleV1Identification,
    context_dependencies: PageV1ContextDependencies,
    loading?: boolean
    gone?: boolean
}

export enum ModuleGroupType {
    FULL = 'FULL',
    HALF = 'HALF',
    COMPARE = 'COMPARE'
}

export interface GroupModuleV1 {
    index: number
    type: ModuleGroupType
    modules: ModuleDisplayData[]
}

export enum PartialState {
    TO_LOAD = 'TO_LOAD',
    LOADING = 'LOADING',
    LOADED = 'LOADED'
}

export interface Partial {
    identifier: string,
    state: PartialState,
    payload: PageContext,
    context_dependencies: PageV1ContextDependencies
}

export enum CommonDataType {
    CONNECTION_DATA = 'CONNECTION_DATA'
}

export interface ConnectionData {
    connection_modes: ConnectionMode[]
}

export type CommonData = ConnectionData;

export interface GetPagePayloadV1 {
    modules: ModuleV1[],
    partials?: Partial[]
    common_data?: Record<CommonDataType, CommonData>
}

export enum PageDisplayLayoutType {
    FULL = 'FULL',
    COLUMN = 'COLUMN'
}

export interface PageDisplayData {
    layout: GroupModuleV1[];
    layout_side_bar?: GroupModuleV1[];
    layout_type?: PageDisplayLayoutType;
    with_back_button: boolean;
}

export interface GetPageResponse {
    version: string,
    page_id: string,
    context: PageContext,
    payload: GetPagePayloadV1,
    context_dependencies: PageV1ContextDependencies,
    display_data: PageDisplayData,
    iteration_date: Date
}

export interface GetPartialResponse {
    version: string,
    page_id: string,
    partial_identifier: string,
    context: PageContext,
    payload: GetPagePayloadV1,
}
