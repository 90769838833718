import React, {FC, useEffect, useState} from 'react';
import ErrorOutput from 'components/utils/form/ErrorOutput';
import {AlwaysInline} from "components/designSystem/containers/Inline";
import PinCase, { PinCaseSize } from "components/designSystem/components/PinCase";
import styles from './PinField.module.scss';


export interface PinFieldProps {
    name: string;
    numberOfCase?: number;
    hidden?: boolean;
    autoComplete?: string;
    onDone?: (value: string) => void;
    defaultFocus?: boolean;
    validate?: (value: unknown) => string|undefined;
    message?: string;
    touched?: boolean;
    error?: string;
    onChange?: (d: string[]) => void|undefined;
    disabled?: boolean;
    sizeCase?: PinCaseSize
}

function buildIdentifier(basename: string, index: number, pasteDate: number): string {
    return 'pin' + basename + index.toString() + pasteDate.toString();
}

const PinField : FC<PinFieldProps> = (
    {
        numberOfCase=6,
        ...props
    }):JSX.Element => {

    const [tab, setTab] = useState(Array<string>(numberOfCase).fill(""));
    const [lastPaste, setLastPaste] = useState(Date.now());

    useEffect(() => {
        if (props.defaultFocus) {
            document.getElementById(buildIdentifier(props.name, 0, lastPaste))?.focus();
        }
    }, []);

    const fullOnChange = (rank: number, onChange: ((d: string[]) => (void))|undefined) => (v: string): void => {
        const stateTab = [...tab];
        stateTab[rank] = v;
        if (!onChange) return;
        setTab(stateTab)
        onChange(stateTab);
        if (rank === numberOfCase - 1 && props.onDone) {
            props.onDone(stateTab.join(''));
        }
    };

    const onPaste = (rank: number, onChange: ((d: string[]) => (void))|undefined) => (v: string): void => {

        const parsedData = v.split("").slice(0, numberOfCase - rank)
        const stateTab = [...tab];
        for (let i = 0; i < parsedData.length; i++) {
            stateTab[rank + i] = parsedData[i]
        }
        if (!onChange) return;
        onChange(stateTab);
        setTab(stateTab);
        setLastPaste(Date.now())
        if (rank + parsedData.length - 1 === numberOfCase - 1 && props.onDone) {
            props.onDone(stateTab.join(''));
        }
    };

    return <div>
        <AlwaysInline>
            {[...Array(numberOfCase)].map((_, i) => {
                return (
                    <PinCase
                        value={tab[i]}
                        key={buildIdentifier(props.name, i, lastPaste)}
                        identifier={buildIdentifier(props.name, i, lastPaste)}
                        onChange={fullOnChange(i, props.onChange)}
                        onPaste={onPaste(i, props.onChange)}
                        autoComplete={props.autoComplete}
                        hidden={props.hidden}
                        next={
                            i < numberOfCase - 1
                                ? buildIdentifier(props.name, i + 1, lastPaste)
                                : undefined
                        }
                        previous={i > 0 ? buildIdentifier(props.name, i - 1, lastPaste) : undefined}
                        disabled={props.disabled}
                        size={props.sizeCase}
                    />
                );
            })}
        </AlwaysInline>
        {props.error &&
            <div className={styles.pinText}>
                {props.touched && <ErrorOutput error={props.error}/>}
            </div>
        }
        {props.message &&
            <div className={styles.pinText}>
                {props.message}
            </div>
        }
    </div>
}


export default PinField