import { ValidationErrors } from "final-form";
import React, { useState } from "react";
import { Form as FinalForm } from 'react-final-form';

export type FormState = {
    invalid: boolean,
    alreadySubmitted: boolean
}

export type FormProps<FormValues> = {
    onSubmit: (data: FormValues) => void;
    children: (formState: FormState) => JSX.Element;
    validate?: (values: FormValues) => ValidationErrors | Promise<ValidationErrors>;
}

function Form<FormValues>(props: FormProps<FormValues>): JSX.Element {
    const [alreadySubmitted, setAlreadySubmitted] = useState(false)
    return <FinalForm
        onSubmit={(data: FormValues) : void => {
            setAlreadySubmitted(true)
            props.onSubmit(data)
        }}
        render={({ hasValidationErrors, handleSubmit , modifiedSinceLastSubmit}): JSX.Element => {
            if (modifiedSinceLastSubmit) {
                setAlreadySubmitted(false)
            }

            return <form onSubmit={handleSubmit}>
                {
                    props.children({invalid: hasValidationErrors, alreadySubmitted: alreadySubmitted})
                }
            </form>
        }}
        validate={props.validate}
    />
}

export default Form
