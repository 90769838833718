import { setPatientBioProfiles, SetPatientBioProfilesRequest } from "actions/bioProfile/BioProfileFetchActions";
import { changePage } from "actions/modular/ModularActions";
import TrText from "components/common/TrText";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import SelectionCard from "components/designSystem/components/cards/SelectionCard";
import Image, { ImageHeight, ImageObjectFit } from "components/designSystem/components/Image";
import FlexGrow, { FlexGrowValue } from "components/designSystem/containers/FlexGrow";
import GridRow, { GridRowItem, GridSpacing } from "components/designSystem/containers/GridRow";
import Inline, { InlineJustifyContent, InlineSpacing } from "components/designSystem/containers/Inline";
import { LargeSpacer, MediumSpacer, SmallSpacer } from "components/designSystem/containers/Spacer";
import { TDisplaySmall500, TLabel, TypographyColor } from "components/designSystem/containers/Typography";
import { MarginSize, WithVerticalMargin } from "components/designSystem/containers/WithMargin";
import { SCREEN_M } from "components/designSystem/foundations/Breakpoints";
import { IconName } from "components/designSystem/foundations/IconsData";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { getIconByName } from "core/content/icons/IconFinder";
import { PageLinkPayload } from "models/modular/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { REDUCER_BIO_PROFILE } from "reducers/allReducers";
import { fetchReducer } from "reducers/selector";

export type BioProfile = { code: string, name: string, description: string, icon: string };

type BioProfileTargetPages = {
    cancel: PageLinkPayload
    validate: PageLinkPayload
};

type BioProfileSelectionModulePayload = {
    patient_uuid: string
    profiles: BioProfile[]
    pages: BioProfileTargetPages
};

type ProfileSelectionState = {
    selectedProfileCode?: string
    setSelectedProfileCode: (code: string) => void
}

const ProfileSelectionCard = (
    {profile, selectedProfileCode, setSelectedProfileCode}: { profile: BioProfile } & ProfileSelectionState
): JSX.Element =>
    <SelectionCard
        title={profile.name}
        description={profile.description}
        icon={getIconByName(profile.icon) ?? IconName.USER}
        radioButtonGroupName={'bio_profiles'}
        isSelected={profile.code == selectedProfileCode}
        onClick={(): void => setSelectedProfileCode(profile.code)}
    />;

const ProfileSelector = (
    {profiles, ...profileSelectionState}: { profiles: BioProfile[] } & ProfileSelectionState
): JSX.Element => {
    const isDesktop = useMediaQuery({minWidth: TABLET_MAX_WIDTH_ACCESSIBLE});
    if (isDesktop) {
        // 2 columns
        const profilePairs: Array<[BioProfile, BioProfile | undefined]> = []
        for (let i = 0; i < profiles.length / 2; i++) {
            profilePairs.push([profiles[i * 2], profiles[i * 2 + 1]])
        }
        return <LargeSpacer>
            {profilePairs.map(([profileLeft, profileRight], index) =>
                <GridRow key={index} columnNb={2} spacing={GridSpacing.X_LARGE}>
                    <GridRowItem from={1} to={2}>
                        <ProfileSelectionCard profile={profileLeft} {...profileSelectionState}/>
                    </GridRowItem>
                    {profileRight && <GridRowItem from={2} to={3}>
                        <ProfileSelectionCard profile={profileRight} {...profileSelectionState}/>
                    </GridRowItem>}
                </GridRow>
            )}
        </LargeSpacer>
    } else {
        // Single column
        return <MediumSpacer>
            {profiles.map((profile, index) =>
                <ProfileSelectionCard key={index} profile={profile}  {...profileSelectionState}/>
            )}
        </MediumSpacer>
    }
}

export const BioProfileSelectionModule = (): JSX.Element => {
    const payload = useModulePayload<BioProfileSelectionModulePayload>();
    const [selectedProfileCode, setSelectedProfileCode] = useState<string | undefined>()
    const profileUpdateRequest: SetPatientBioProfilesRequest = useSelector((state) => fetchReducer(state, REDUCER_BIO_PROFILE).profileUpdateRequest)
    const dispatch = useDispatch()
    const setProfiles = (patientUuid: string, profileCodes: string[]): void => {
        dispatch(setPatientBioProfiles(patientUuid, profileCodes))
    }
    const {t} = useTranslation();
    const isMobile = useMediaQuery({maxWidth: SCREEN_M});

    useEffect(() => {
        if (profileUpdateRequest?.profile_codes && selectedProfileCode) {
            const profile_codes = profileUpdateRequest.profile_codes;
            if (profile_codes.length == 1 && profile_codes[0] == selectedProfileCode) {
                dispatch(changePage(payload.pages.validate.page_id, payload.pages.validate.context))
            }
        }
    }, [profileUpdateRequest?.profile_codes, selectedProfileCode])

    // Button order is inverted on mobile
    const buttons = [
        <Button
            key={'secondary'}
            variant={ButtonVariant.QUATERNARY}
            size={ButtonSize.LARGE}
            fullWidth={isMobile}
            onClick={(): void => {
                dispatch(changePage(payload.pages.cancel.page_id, payload.pages.cancel.context))
            }}>
            <TrText input={{trkey: 'modules.bio_profile_selection.button_later'}}/>
        </Button>,
        <Button
            key={'primary'}
            size={ButtonSize.LARGE}
            disabled={!selectedProfileCode}
            fullWidth={isMobile}
            onClick={(): void => {
                if (!selectedProfileCode) return
                setProfiles(
                    payload.patient_uuid,
                    [selectedProfileCode]
                )
            }}>
            <TrText input={{trkey: 'modules.bio_profile_selection.button_submit'}}/>
        </Button>
    ];
    return <TDisplaySmall500 color={TypographyColor.COLOR_TEXT_DEFAULT}><Inline
        spacing={InlineSpacing.XX_LARGE}>
        {isMobile && <Image
            src={'https://cdn.kiro.bio/static/content/platform/bio_profiles/bio_profiles_selection_illustration_mobile.png'}
            alt={t('modules.bio_profile_selection.image_alt')}
            height={160}
            withObjectFit={ImageObjectFit.COVER_TOP}/>}
        <SmallSpacer>
            <FlexGrow/>
            <TDisplaySmall500><TrText input={{trkey: 'modules.bio_profile_selection.title'}}/></TDisplaySmall500>
            <TLabel><TrText input={{trkey: 'modules.bio_profile_selection.subtitle'}}/></TLabel>
            <WithVerticalMargin margin={MarginSize.MEDIUM}>
                <ProfileSelector
                    profiles={payload.profiles}
                    selectedProfileCode={selectedProfileCode}
                    setSelectedProfileCode={(value): void =>
                        setSelectedProfileCode(value == selectedProfileCode ? undefined : value)
                    }/>
            </WithVerticalMargin>
            <FlexGrow value={FlexGrowValue.VALUE_2}/>
            <Inline justifyContent={InlineJustifyContent.SPACE_BETWEEN} spacing={InlineSpacing.SMALL}>
                {isMobile ? buttons.reverse() : buttons}
            </Inline>
        </SmallSpacer>
        {!isMobile && <Image
            src={'https://cdn.kiro.bio/static/content/platform/bio_profiles/bio_profiles_selection_illustration_v2.png'}
            alt={t('modules.bio_profile_selection.image_alt')}
            height={ImageHeight.FULL_CONTENT}/>}
    </Inline></TDisplaySmall500>
}
