import { Entity } from "models/entities/default";
import CONFIG from "configuration/globals";

export interface ImageInfo {
    alt: string,
    src: string
}

export const getApolloResource = (name: string): ImageInfo => {
    return {
        alt: name,
        src: CONFIG.cdnUrl + '/static/content/apollo/' + name
    }
};

export const getEntityImg = (entity: Entity, name: string, extension= '.png'): ImageInfo => {
    return {
        alt: name,
        src: CONFIG.cdnUrl + '/static/ext/content/' + entity.type + '/' + entity.id + '/' + name + extension
    }
}

export const getPlatformResource = (name: string, extension?: string): ImageInfo => {

    if (name.slice(0, 8) == "https://" ||name.slice(0, 7) == "http://") {
        return {
            alt: '',
            src: name
        }
    }
    return {
        alt: name,
        src: CONFIG.cdnUrl + '/static/content/platform/' + (name[0] == '/' ? name.slice(1) : name) + (extension ?? '')
    }
};