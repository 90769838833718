import React from 'react';

export const DashboardIcon = (): JSX.Element => (

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.375 13.875C3.375 12.2181 4.71815 10.875 6.375 10.875H7.875C9.53185 10.875 10.875 12.2181 10.875 13.875V17.625C10.875 19.2819 9.53185 20.625 7.875 20.625H6.375C5.57935 20.625 4.81629 20.3089 4.25368 19.7463C3.69107 19.1837 3.375 18.4206 3.375 17.625V13.875Z" fill="currentColor"/>
        <path d="M6.375 3.375H8.25C9.69975 3.375 10.875 4.55025 10.875 6C10.875 7.44975 9.69975 8.625 8.25 8.625H5.625C4.38236 8.625 3.375 7.61764 3.375 6.375C3.375 5.57935 3.69107 4.81629 4.25368 4.25368C4.81629 3.69107 5.57935 3.375 6.375 3.375Z" fill="currentColor"/>
        <path d="M13.125 6.375C13.125 4.71815 14.4681 3.375 16.125 3.375H17.625C18.4206 3.375 19.1837 3.69107 19.7463 4.25368C20.3089 4.81629 20.625 5.57935 20.625 6.375V10.125C20.625 11.7819 19.2819 13.125 17.625 13.125H16.125C14.4681 13.125 13.125 11.7819 13.125 10.125V6.375Z" fill="currentColor"/>
        <path d="M13.125 18C13.125 16.5503 14.3003 15.375 15.75 15.375H18.375C19.6176 15.375 20.625 16.3824 20.625 17.625C20.625 18.4206 20.3089 19.1837 19.7463 19.7463C19.1837 20.3089 18.4206 20.625 17.625 20.625H15.75C14.3003 20.625 13.125 19.4497 13.125 18Z" fill="currentColor"/>
    </svg>
)