import { NumberFormat } from 'components/commonDesign/values/SimpleNumericalValue';

export function fromLanguageToNumberFormat(language: string): NumberFormat {
    switch (language) {
        case 'fr':
            return NumberFormat.EUROPEAN
        case 'en':
            return NumberFormat.ENGLISH
    }

    return NumberFormat.ENGLISH
}