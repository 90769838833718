import React, { useEffect, useRef, useState } from 'react';

import Box from 'components/core/containers/Box'
import CONFIG from 'configuration/globals';

interface ImageItem {
  path: string,
  alt: string
}

interface FaqItemProps {
  validated: boolean;
  subject: string;
  subsubject?: string;
  name: string;
  title: string;
  content: JSX.Element;
  images?: ImageItem[];
  expand: boolean;
}

const FaqItem = (props: FaqItemProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const myRef = useRef<HTMLDivElement>(null)

  useEffect(() =>{
    setIsExpanded(props.expand);
    if (props.expand) {
      const node = myRef.current
      node?.scrollIntoView() 
    }
  }, [props.expand])
  

  const blockClick = (event: React.MouseEvent): void => {
    event.stopPropagation()
  }

  const openInNewTab = (url: string): void => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div ref={myRef}>
      <Box
        title={props.title}
        key={props.name}
        onClick={(): void => {
          setIsExpanded(!isExpanded)
        }}
        clicked={isExpanded}
      >
        <div className="faq-item-container">
        { isExpanded && props.content }
          <div className="faq-item-images">
          {
            isExpanded && props.images &&
            Object.values(props.images).map(value => {
              return (
                <img 
                  src={CONFIG.cdnUrl + value?.path} 
                  alt={value?.alt} 
                  key={value?.alt} 
                  onClick={(e): void => {
                    blockClick(e);
                    openInNewTab(CONFIG.cdnUrl + value?.path)
                  }}
                />
              )
            })
          }
          </div>
        </div>
      </Box>
    </div>
  );
};
export default FaqItem;
