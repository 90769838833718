import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Shield = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-shield'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6.69535 5.2738C6.92643 4.92719 7.34674 4.67014 8.00041 4.67014C8.65407 4.67014 9.07439 4.92719 9.30546 5.2738C9.41127 5.43251 9.46295 5.58896 9.48866 5.70463C9.50165 5.7631 9.50853 5.8135 9.5122 5.85201C9.51404 5.87135 9.5151 5.88796 9.5157 5.9014L9.51629 5.91922L9.51641 5.92632L9.51643 5.9294L9.51644 5.93082L9.51644 5.93216H9.01644C9.51169 5.93216 9.51639 5.93247 9.51644 5.93216V6.56485C10.0127 6.62361 10.3976 7.04579 10.3976 7.55786V8.83592C10.3976 9.66434 9.72603 10.3359 8.8976 10.3359H7.10352C6.27509 10.3359 5.60352 9.66434 5.60352 8.83591V7.55786C5.60352 7.04589 5.98825 6.62378 6.48438 6.56489V5.93216H6.98438C6.48438 5.93216 6.48438 5.93115 6.48438 5.93082L6.48438 5.9294L6.48441 5.92632L6.48452 5.91922L6.48512 5.9014C6.48571 5.88796 6.48677 5.87135 6.48861 5.85201C6.49228 5.8135 6.49916 5.7631 6.51216 5.70463C6.53786 5.58896 6.58955 5.43251 6.69535 5.2738ZM8.51644 5.94432V6.55786H7.48438V5.94432C7.48489 5.93997 7.48602 5.93201 7.48834 5.92156C7.49439 5.89434 7.50621 5.86029 7.5274 5.8285C7.55034 5.79409 7.63804 5.67014 8.00041 5.67014C8.36277 5.67014 8.45047 5.79409 8.47341 5.8285C8.4946 5.86029 8.50642 5.89434 8.51247 5.92156C8.51479 5.93201 8.51593 5.93997 8.51644 5.94432ZM8.00098 7.81992C8.27712 7.81992 8.50098 8.04377 8.50098 8.31992V8.57392C8.50098 8.85007 8.27712 9.07392 8.00098 9.07392C7.72483 9.07392 7.50098 8.85007 7.50098 8.57392V8.31992C7.50098 8.04377 7.72483 7.81992 8.00098 7.81992Z"
                      fill="currentColor"
                />
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.26406 0.432763C8.09427 0.369092 7.90717 0.369092 7.73738 0.432763L2.24545 2.49224C1.95272 2.60201 1.75879 2.88185 1.75879 3.19448V7.99992C1.75879 10.3942 3.34308 12.3008 4.76088 13.5414C5.48553 14.1755 6.20664 14.6709 6.74511 15.0074C7.01512 15.1762 7.2412 15.3062 7.4015 15.395C7.4817 15.4394 7.54558 15.4735 7.59042 15.497C7.61284 15.5088 7.63052 15.5179 7.64311 15.5244L7.65815 15.5321L7.66273 15.5344L7.66427 15.5351C7.66451 15.5353 7.66531 15.5357 8.00072 14.8648L7.66531 15.5357C7.87646 15.6412 8.12498 15.6412 8.33613 15.5357L8.00072 14.8648C8.33613 15.5357 8.33589 15.5358 8.33613 15.5357L8.33717 15.5351L8.33871 15.5344L8.34329 15.5321L8.35833 15.5244L8.38014 15.5132L8.41102 15.497C8.45586 15.4735 8.51974 15.4394 8.59994 15.395C8.76024 15.3062 8.98632 15.1762 9.25633 15.0074C9.7948 14.6709 10.5159 14.1755 11.2406 13.5414C12.6584 12.3008 14.2427 10.3942 14.2427 7.99992V3.19448C14.2427 2.88185 14.0487 2.60201 13.756 2.49224L8.26406 0.432763ZM8.46134 13.7354C8.28282 13.847 8.12655 13.9392 8.00072 14.0111C7.87488 13.9392 7.71862 13.847 7.5401 13.7354C7.04883 13.4284 6.39696 12.9798 5.74863 12.4126C4.42046 11.2504 3.25879 9.72457 3.25879 7.99992V3.71423L8.00072 1.93601L12.7427 3.71423V7.99992C12.7427 9.72457 11.581 11.2504 10.2528 12.4126C9.60448 12.9798 8.95261 13.4284 8.46134 13.7354Z"
                      fill="currentColor"
                />
            </svg>

        )
    }

    return (
        <div/>
    )
}