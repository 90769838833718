import { ActionDispatcher, OnSuccessPutType } from 'actions/ActionInterface';
import { redirect } from "actions/common/CommonActions";
import { getConnectionModes, givePatientAccess, } from 'actions/medicalReports/MedicalReportsFetchActions';
import EntityLogo from "components/commonDesign/EntityLogo";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import { PageLoader } from "components/core/items/PageLoader";
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import LabeledDivider from "components/designSystem/components/LabeledDivider";
import { SizedLogoSize } from "components/designSystem/components/Logo";
import { LargeSpacer, MediumSpacer, XLargeSpacer } from "components/designSystem/containers/Spacer";
import { MarginSize, WithBottomMargin } from "components/designSystem/containers/WithMargin";
import { IconName } from "components/designSystem/foundations/IconsData";
import { GivePatientAccessForm } from "components/modules/medicalReport/GivePatientAccessForm";
import { KiroCommunicationType } from "components/modules/user/KiroCommunication";
import { LandingScreen } from 'components/modules/user/LandingScreen';
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { buildConnectionsModeComponent } from "components/utils/builders/ConnectionModesBuilder";
import { getRoute } from "core/routing/Helper";
import { ROUTE_MODULAR_PAGE, ROUTE_OUTDATED_REPORT_ACCESS_LINK } from "core/routing/Routes";
import { AnyState } from 'core/store/Store';
import { capitalizeFirstLetter } from 'core/utils/text';
import { cast } from 'core/utils/Typed';
import { Record } from "immutable";
import { Entity, EntityType } from "models/entities/default";
import {
    AsGuestPayload,
    CheckPatientBirthDatePayload,
    ConnectionMode,
    ConnectionModeType,
    GetConnectionModesResponse,
    GivePatientAccessResponse,
    OutdatedReportType,
} from 'models/medicalReport/ConnectionModesModel';
import React, { Fragment, ReactNode } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { REDUCER_REPORT } from 'reducers/allReducers';
import { fetchReducer } from 'reducers/selector';

interface ConnectionModesStateProps {
    token: string;
    connectionModes: {success?: GetConnectionModesResponse & { origin_token?: string}, failed?: boolean};
    givePatientAccessResp: GivePatientAccessResponse & { fromSubscription?: boolean };
}

interface ConnectionModesDispatchProps {
    getConnectionModes: (token: string) => void;
    givePatientAccess: (
        token: string, values: Record<string, string | boolean>,
        feedbackIdentifier?: string, onSuccessPut?: OnSuccessPutType
    ) => void;
    redirectToDashboard: () => void;
    redirectToOutdatedReportPage: (token: string) => void;
}

// todo : Should be renamed ReportAccess and should use AccessByConnectionModes component
class ConnectionModes extends WithTranslations<ConnectionModesStateProps & ConnectionModesDispatchProps> {
    TRANS_SUFFIX = TransDomain.MEDICAL_REPORT + '.access';

    state = {defaultComponentSelected: true}

    componentDidMount(): void {
        this.props.getConnectionModes(this.props.token);
    }

    componentDidUpdate() : void {
        if (!this.props.connectionModes || (this.props.connectionModes.success?.origin_token !== this.props.token && !this.props.connectionModes?.failed)) {
            this.props.getConnectionModes(this.props.token);
        }
    }

    getComponentByConnectionMode = (connectionMode: ConnectionMode): JSX.Element => {
        return buildConnectionsModeComponent(
            connectionMode,
            this.getTitleAndDescriptionByConnectionMode(connectionMode, 'title'),
            this.getTitleAndDescriptionByConnectionMode(connectionMode, 'description'),
            connectionMode.type === ConnectionModeType.AS_GUEST ? (
                data: Record<string, string>, token: string, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string
            ): void => {
                this.props.givePatientAccess(token, data, feedbackIdentifier, onSuccessPut)
            } : undefined
        )
    }

    getTitleAndDescriptionByConnectionMode = (connectionMode: ConnectionMode, id: 'title' | 'description'): ReactNode => {
        if (connectionMode.type === ConnectionModeType.AS_GUEST && id === 'description') {
            return this.withBuilder(<>0<strong>1</strong></>).trans(
                'default.AS_GUEST.description', {
                    'name': cast<AsGuestPayload>(connectionMode.payload).form_data.display_data.hidden_patient_name
                }
            )
        }

        if (connectionMode.type === ConnectionModeType.LOGIN && id === 'description'){
            return undefined
        }

        return this.trans(
            'default.' + connectionMode.type + '.' + id, undefined, undefined, capitalizeFirstLetter
        )
    }

    buildComponent = (
        isMobile: boolean,
        defaultMode: ConnectionMode[],
        otherModes: ConnectionMode[],
        defaultComponentSelected: boolean,
        entity: Entity | null
    ): JSX.Element => {
        const labLogoComponent: JSX.Element = entity ? (
            <WithBottomMargin margin={isMobile ? MarginSize.LARGE : MarginSize.ELEVEN_TIMES_X_SMALL}>
                <EntityLogo size={SizedLogoSize.ELEVEN_TIMES_X_SMALL} entity={entity}/>
            </WithBottomMargin>
        ) : <Fragment/>

        let content: JSX.Element = <Fragment/>;
        if (this.props.givePatientAccessResp) {
            const titleKey = this.props.givePatientAccessResp.fromSubscription ?
                'give_patient_access.from_subscription.title' : 'default.AS_GUEST.title';
            const payload = cast<CheckPatientBirthDatePayload>(this.props.givePatientAccessResp.action)
            content = <MediumSpacer>
                <GivePatientAccessForm
                    title={this.trans(titleKey, undefined, undefined, capitalizeFirstLetter)}
                    description={this.withBuilder(<>0<strong>1</strong></>).trans(
                        'default.AS_GUEST.description', {'name': payload.form_data.display_data.hidden_patient_name},
                    )}
                    items={payload.form_data.items}
                    withPatientPermission={payload.form_data.with_patient_permission_check}
                    displayData={payload.form_data.display_data}
                    onSubmit={(data: Record<string, string>, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string): void => {
                        this.props.givePatientAccess(payload.token, data, feedbackIdentifier, onSuccessPut)
                    }}
                />
                <Button
                    variant={ButtonVariant.QUATERNARY} size={ButtonSize.LARGE} fullWidth
                    onClick={(): void => this.props.redirectToDashboard()}
                >
                    {this.trans('give_patient_access.go_to_dashboard', undefined, undefined, capitalizeFirstLetter)}
                </Button>
            </MediumSpacer>
        } else {
            if (defaultMode.length !== 1) {
                return <Fragment/>
            }
            const otherComponent = otherModes.length ? this.getComponentByConnectionMode(otherModes[0]) : undefined
            const continueButtonKey = defaultMode[0].type === ConnectionModeType.SUBSCRIPTION ? '.with_subscription' : '.with_login';
            const defaultComponent = <XLargeSpacer>
                {this.getComponentByConnectionMode(defaultMode[0])}
                {otherComponent && (
                    <MediumSpacer>
                        <LabeledDivider>{this.trans('or')}</LabeledDivider>
                        <Button
                            id={'other-button'}
                            variant={ButtonVariant.SECONDARY}
                            size={ButtonSize.LARGE}
                            onClick={(): void => this.setState({
                                defaultComponentSelected: !this.state.defaultComponentSelected
                            })}
                            fullWidth
                        >
                            {this.trans(
                                'other_button.' + otherModes[0].type + continueButtonKey, undefined, undefined, capitalizeFirstLetter
                            )}
                        </Button>
                    </MediumSpacer>
                )}
            </XLargeSpacer>
            const otherComponentSelected: boolean = otherComponent !== undefined && !defaultComponentSelected

            content = <LargeSpacer>
                {otherComponentSelected && <Button
                    variant={ButtonVariant.TERTIARY}
                    size={ButtonSize.SMALL}
                    icons={{left: IconName.CHEVRON_LEFT}}
                    onClick={(): void => this.setState({
                        defaultComponentSelected: !this.state.defaultComponentSelected
                    })}
                >
                    {this.trans('back', undefined, undefined, capitalizeFirstLetter)}
                </Button>}
                <div>
                    {labLogoComponent}
                    {otherComponentSelected ? otherComponent : defaultComponent}
                </div>
            </LargeSpacer>
        }

        return <LandingScreen communicationType={KiroCommunicationType.PATIENT}>
            {content}
        </LandingScreen>
    }

    render(): ReactNode {
        if (this.props.connectionModes && this.props.connectionModes.failed) {
            this.props.redirectToOutdatedReportPage(this.props.token)
        }

        const connectionModesResponse = this.props.connectionModes?.success;
        if (!this.props.connectionModes || connectionModesResponse?.origin_token !== this.props.token) {
            return <PageLoader />
        }

        const defaultConnectionModeType: ConnectionModeType = connectionModesResponse.default
        const connectionModes: ConnectionMode[] = connectionModesResponse.modes

        return <MediaQuery minWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
            {(match: boolean): JSX.Element => this.buildComponent(
                match,
                connectionModes.filter(connectionMode => connectionMode.type === defaultConnectionModeType),
                connectionModes.filter(connectionMode => connectionMode.type !== defaultConnectionModeType),
                this.state.defaultComponentSelected,
                connectionModesResponse.laboratory_uuid ? {
                    id: connectionModesResponse.laboratory_uuid,
                    type: EntityType.LABORATORY,
                } : null
            )}
        </MediaQuery>
    }
}

const mapStateToProps = (
    state: AnyState,
    ownProps: { match: { params: { token: string } } },
): ConnectionModesStateProps => ({
    token: ownProps.match.params.token,
    connectionModes: fetchReducer(state, REDUCER_REPORT).connectionModes,
    givePatientAccessResp: fetchReducer(state, REDUCER_REPORT).givePatientAccessResp
});

const mapDispatchToProps = (dispatch: ActionDispatcher): ConnectionModesDispatchProps => ({
    getConnectionModes: (token: string): void => dispatch(getConnectionModes(token)),
    givePatientAccess: (
        token: string, values: Record<string, string | boolean>, feedbackIdentifier?: string, onSuccessPut?: OnSuccessPutType
    ): void => dispatch(
        givePatientAccess(token, values, true, feedbackIdentifier, false, onSuccessPut)
    ),
    redirectToDashboard: (): void => {
        dispatch(redirect(getRoute(ROUTE_MODULAR_PAGE, {pageId: 'dashboard'})));
    },
    redirectToOutdatedReportPage: (token: string): void => {
        dispatch(
            redirect(
                ROUTE_OUTDATED_REPORT_ACCESS_LINK,
                {}, undefined, undefined, {
                    token: token,
                    type: OutdatedReportType.PATIENT_REPORT
                }
            )
        );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionModes)
