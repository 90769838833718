import { Entity } from "models/entities/default";

export interface ExternalLink {
    href: string,
    target_blank: boolean,
}

export enum TeleconsultationEntityPayloadType {
    LIVI = 'LIVI'
}

export interface LiviPaylaod {
    type: TeleconsultationEntityPayloadType,
    consult_doctor_link: ExternalLink,
    pathologies_list_link: ExternalLink,
    fees_and_reimbursement_link: ExternalLink,
}

export interface TeleconsultationEntity {
    entity: Entity,
    payload: LiviPaylaod
}