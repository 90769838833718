import { getArticleList } from "actions/bioProfile/BioProfileFetchActions";
import TrText from "components/common/TrText";
import ImgCard from "components/designSystem/components/cards/ImgCard";
import { AlwaysInline, InlineOverflow, InlineSpacing } from "components/designSystem/containers/Inline";
import { SmallSpacer } from "components/designSystem/containers/Spacer";
import { TDisplayXSmall, TypographyColor } from "components/designSystem/containers/Typography";
import { MarginSize, WithBottomMargin } from "components/designSystem/containers/WithMargin";
import { useChangePage, useModulePayload } from "components/modules/modular/ModuleContainer";
import { articlePage } from "components/modules/modular/pages/builders";
import { ArticleThumbnail } from "models/bioProfiles/BioProfileModels";
import { APIText } from "models/common/message";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { REDUCER_BIO_PROFILE } from "reducers/allReducers";
import { fetchReducer } from "reducers/selector";

interface ArticleListModulePayload {
    context?: {
        patient_uuids: string[]
    },
    category_title?: APIText,
    articles?: ArticleThumbnail[]
}

const TRANS_SUFFIX = 'modules.articles'

export const ArticleListModule = (): JSX.Element => {
    const payload = useModulePayload<ArticleListModulePayload>()
    const articleList = useSelector((state) => fetchReducer(state, REDUCER_BIO_PROFILE)?.articleList);
    const dispatch = useDispatch();
    const changePage = useChangePage();
    const {t} = useTranslation(undefined, {keyPrefix: TRANS_SUFFIX});
    useEffect(() => {
        if (payload.context) {
            dispatch(getArticleList(payload.context.patient_uuids))
        }
    }, [])

    const articles = (payload.articles ?? articleList?.articles) || [];

    if (articles.length == 0) {
        return <></>
    }

    return <SmallSpacer>
        <TDisplayXSmall color={TypographyColor.COLOR_TEXT_DEFAULT}>
            {payload.category_title ? <TrText input={payload.category_title}/> : t('title')}
        </TDisplayXSmall>
        <AlwaysInline spacing={InlineSpacing.X_LARGE} overflow={InlineOverflow.SCROLL}>
            {articles.map((article: ArticleThumbnail, index: number): JSX.Element => // Add bottom margin to make sure there's enough space to draw shadow
                <WithBottomMargin key={index} margin={MarginSize.MEDIUM}>
                    <ImgCard
                        imageUrl={article.image_url}
                        imageAlt={article.image_alt}
                        title={article.title}
                        description={article.caption}
                        onClick={(): void => {
                            changePage(articlePage(article.token))
                        }}
                    />
                </WithBottomMargin>)}
        </AlwaysInline>
    </SmallSpacer>
}

