export interface ConnectedButtonPayload {
    identifier: string,
    timestamp: number
}

export interface Action<P> {
    payload: P;
    type: string;
    connectedButtonPayload?: ConnectedButtonPayload
}

//By introducing SWR we need to have callback that are not an action that will be dispatch
// (to revalidate after PIN for example)
export interface CallbackAction extends Action<{ callback: () => void }> {
    type: 'CALLBACK_ACTION'
}

export function callbackAction(callback: () => void): CallbackAction {
    return {
        type: 'CALLBACK_ACTION',
        payload: {
            callback
        }
    }
}

export type OnSuccessPutElementType = Action<unknown>;
export type OnSuccessPutType = OnSuccessPutElementType[];
export const buildOnSuccessPut = (element?: OnSuccessPutElementType): OnSuccessPutType => {
    if (element == undefined) {
        return []
    }

    return [element];
};

export type APICallAction<T> = Action<{
    params?: T;
    feedbackIdentifier?: string;
    onSuccessPut?: OnSuccessPutType;
    preventRetryOnError?: boolean;
    /*
     * Prevent api call to be made again after "legitimate" errors (2FA, Eula Validation...)
     * For example, when making a reset password, a Eula validation error might be thrown.
     * After EULA validation is made, "reset password" is done again (=> error). This does not
     * mean that we should remove this default behaviour, as most of the time after such errors (Pin...)
     * you actually want to do the initial call that first failed
     *
     * We'll need to improve how API call are automatically done again after errors such as 2FA, Eula Validation
     * This should be done when revamping the way we make API calls
     */
}>;

export type APICallSuccessAction<R = {}, E = {}> = Action<{
    response: R;
    extra: E;
}>;

export type ApiCallFailureAction<T = unknown> = Action<{originAction?: APICallAction<T>}>;

export const EmptyAction = {
    payload: {},
    type: 'EMPTY',
};

export type AnyAction = Action<{}>;
export type ActionDispatcher = <T = {}>(action: Action<T>) => void;

export function successful(apiCallType: string): string {
    return apiCallType + '_SUCCESS';
}

export function apiCallSuccessAction<R = unknown, E = unknown>(type: string, response: R | null = null, extra: E | null = null): APICallSuccessAction<R | null, E | null> {
    return {
        payload: {
            response: response,
            extra: extra,
        },
        type: successful(type),
    };
}

export function failure(apiCallType: string): string {
    return apiCallType + '_FAILURE';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function apiCallFailureAction<T = unknown>(type: string, originAction: APICallAction<T> | undefined = undefined): ApiCallFailureAction<T> {
    return {
        payload: {
            originAction
        },
        type: failure(type),
    };
}

export function getActionPayload<T>(v: Action<{}>): T {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (<Action<T>>v).payload;
}

export function castResponseAction<R, E = undefined>(v: Action<{}>): APICallSuccessAction<R, E> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <APICallSuccessAction<R, E>>v;
}

export function getResponse<R>(v: Action<{}>): R {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (<APICallSuccessAction<R>>v).payload.response;
}

export function getExtra<E>(v: Action<{}>): E {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (<APICallSuccessAction<{}, E>>v).payload.extra;
}
