import { cast } from "core/utils/Typed";
import { useState } from "react";
import { UrlReq } from "sagas/call/api";
import { GetEndpoint } from "sagas/call/endpoints";
import { useAPI } from "sagas/call/swr";
import { SWRResponse } from "swr/_internal";

interface Process<T> {
    processed: boolean,
    payload: T | undefined,
    successful?: boolean
}

/**
 *  This methods helps calling an API endpoint by batch
 *  This method is stateful, everytime newItems (each being identified by a unique key)
 *  are sent the method record them as "to process"
 *  Important ! The batch loading is processed using state update (and so component re-redering)
 *
 * @param endpoint The endpoint to be called by useAPI
 * @param reqBuilder A Method that build Req for url from a list of items
 * @param recordResponse Return the list of items keys processed by response
 * so the system can mark them as processed
 * @param newItems a list of new items to be handled
 * @param batchSize
 */
export function batchRetrieve<P, Req extends UrlReq, Res>(
    endpoint: GetEndpoint<Req, Res>,
    reqBuilder: (i: P[]) => Req,
    recordResponse: (resp: Res) => string[],
    newItems?: Map<string, P>,
    batchSize = 5
): SWRResponse<Res> {
    const [processingHistory, setProcessingHistory] = useState<Map<string, Process<P>>>(new Map());


    // First we record all new entries for processingHistory
    // if we find out new ones setProcessingHistory will be call
    // and module will be re-render
    const toAdd: Map<string, Process<P>> = new Map()
    if (newItems) {
        newItems.forEach(
            (value, key) => {
                if (!processingHistory.has(key)) {
                    toAdd.set(
                        key,
                        {
                            processed: false,
                            payload: value,
                            successful: undefined
                        }
                    )
                }
            }
        )

        if (toAdd.size > 0) {
            setProcessingHistory(
                new Map([
                    ...processingHistory,
                    ...toAdd
                ])
            )
        }
    }


    const itemsToProcess: [string, Process<P>][] = Array.from(
        processingHistory.entries()
    ).filter(
        (p) => !p[1].processed && p[1].payload != undefined
    ).slice(0, batchSize)

    const {data, isLoading, error, isValidating, mutate} = useAPI(
        itemsToProcess.length > 0 ? endpoint : null,
        reqBuilder(cast<P[], (P | undefined)[]>(
            itemsToProcess.map(
                (p) => p[1].payload
            )
        ))
    )

    if (data) {
        const processed: Map<string, Process<P>> = new Map()
        recordResponse(data).map(
            (k: string) => {
                processed.set(
                    k,
                    {
                        processed:  true,
                        payload: undefined,
                        successful: true
                    }
                )
            }
        )

        // We do that to avoid infinie retry
        itemsToProcess.forEach(
            (p) => {
                if (!processed.has(p[0])) {
                    processed.set(
                        p[0],
                        {
                            processed:  true,
                            payload: undefined,
                            successful: false
                        }
                    )
                }
            }
        )


        if (processed.size > 0) {
            setProcessingHistory(
                new Map([
                    ...processingHistory,
                    ...processed
                ])
            )
        }

    }

    return {data, isLoading, error, isValidating, mutate}
}
