function caseNameWord(_word: string): string {
    if (_word.length === 0) {
        return _word;
    }

    const word = _word.toLowerCase();
    return word.replace(/^.{1}/g, word[0].toUpperCase());
}

export const formatName = (firstname: string, lastname: string, reverse = false): string => {
    const formattedFistname = firstname
        ? firstname
              .split(' ')
              .map((name) => caseNameWord(name))
              .join(' ')
        : '';

    const formattedLastname = lastname
        ? lastname
              .split(' ')
              .map((name) => name.toUpperCase())
              .join(' ')
        : '';

    return reverse
        ? `${formattedLastname}${firstname && lastname ? ' ' : ''}${formattedFistname}`
        : `${formattedFistname}${firstname && lastname ? ' ' : ''}${formattedLastname}`;
};

export const normalizeStr = (str: string): string => {
    const mapAccents: { [key: string]: string } = {
        a: 'á|à|ã|â|À|Á|Ã|Â',
        e: 'é|è|ê|É|È|Ê',
        i: 'í|ì|î|Í|Ì|Î',
        o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ',
    };

    str = str.toLowerCase().replace(/\s+/g, ' ').replace(/-|_/g, ' ');
    for (const pattern in mapAccents) {
        str = str.replace(new RegExp(mapAccents[pattern], 'g'), pattern);
    }

    return str;
};
