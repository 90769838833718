import { EULAType } from "models/user/AuthModels";

export const getNameFromType = (eulaType: EULAType, version: number, useNewNaming?: boolean): string => {
    if (useNewNaming) {
        return `EULA:${eulaType}:${version}`
    }

    switch (eulaType) {
        case EULAType.HEALTH_PROFESSIONAL:
            return 'eula-acceptance';
        case EULAType.PATIENT:
            return 'eula-acceptance';
        case EULAType.DATA_PRIVACY:
            return 'data-privacy-acceptance';
        case EULAType.DATA_PRIVACY_NOTICED:
            return 'data-privacy-acceptance';
    }

    return '_unknown';
}