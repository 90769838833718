import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ThumbsDown = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={"xs-thumbs-down"} width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6.02755 15.2495L6 14.5C6.02755 15.2495 6.02734 15.2495 6.02755 15.2495L6.02944 15.2494L6.03195 15.2493L6.03885 15.249L6.05993 15.248C6.07714 15.247 6.10058 15.2456 6.12962 15.2435C6.18762 15.2392 6.26836 15.232 6.36656 15.2203C6.56189 15.197 6.83194 15.1548 7.13268 15.0787C7.70742 14.9332 8.51594 14.6338 9.06944 13.9881C9.76031 13.1821 9.88342 12.0733 9.88122 11.3031C9.88066 11.106 9.87176 10.9195 9.85864 10.75H12.5901C14.312 10.75 15.6104 9.18559 15.293 7.49321L14.5772 3.67538C14.3131 2.26686 13.0116 1.29726 11.5864 1.44728L3.96063 2.25H1.5C1.08579 2.25 0.75 2.58579 0.75 3V9C0.75 9.41421 1.08579 9.75 1.5 9.75H3.82295L5.25 10.4635V14.5C5.25 14.7037 5.33285 14.8986 5.4795 15.04C5.62616 15.1813 5.824 15.257 6.02755 15.2495ZM3.25 8.25H2.25V3.75H3.25V8.25ZM4.75 3.6752L11.7434 2.93904C12.3913 2.87085 12.9829 3.31158 13.1029 3.95181L13.8187 7.76964C13.963 8.53891 13.3728 9.25 12.5901 9.25H9C8.77238 9.25 8.55708 9.35337 8.41474 9.53099C8.27266 9.70828 8.21863 9.9404 8.26775 10.1622C8.26784 10.1626 8.26793 10.163 8.26802 10.1634L9 10C8.26802 10.1634 8.26806 10.1636 8.26802 10.1634L8.27085 10.177C8.27347 10.1897 8.27759 10.2104 8.28274 10.2382C8.29305 10.2939 8.30736 10.3776 8.3219 10.4828C8.35116 10.6945 8.38031 10.9861 8.38123 11.3074C8.38318 11.9907 8.25629 12.6319 7.93056 13.0119C7.68037 13.3038 7.23889 13.5045 6.76455 13.6246C6.75969 13.6258 6.75484 13.627 6.75 13.6282V10C6.75 9.71592 6.5895 9.45622 6.33541 9.32918L4.75 8.53647V3.6752ZM5.97051 13.7506C5.96999 13.7506 5.97026 13.7506 5.97051 13.7506V13.7506Z"
                      fill="currentColor"
                />
            </svg>
        )
    } else if (props.size === IconSize.MEDIUM) {
        return (
            <svg id={"m-thumbs-down"} width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.8099 30.1684L11.7562 28.7391C11.8099 30.1684 11.8099 30.1684 11.8099 30.1684L11.8136 30.1683L11.8186 30.1681L11.8323 30.1675L11.8743 30.1653C11.9087 30.1634 11.9555 30.1605 12.0137 30.1561C12.1298 30.1473 12.2916 30.1327 12.4885 30.1086C12.8803 30.0607 13.4217 29.9742 14.0245 29.8181C15.1809 29.5185 16.7897 28.9057 17.8852 27.598C19.2475 25.9719 19.4942 23.7299 19.4898 22.1549C19.4886 21.7038 19.4664 21.2794 19.4348 20.9009H26.4412C29.1207 20.9009 31.1449 18.4721 30.6619 15.8364L28.7399 5.34837C28.3349 3.13833 26.2936 1.61493 24.0597 1.85553L7.65327 3.62251H2.69742C1.90747 3.62251 1.26709 4.26289 1.26709 5.05284V17.4109C1.26709 18.2008 1.90747 18.8412 2.69742 18.8412H7.38544L10.3258 20.3455V28.7391C10.3258 29.1278 10.484 29.4997 10.764 29.7693C11.0439 30.039 11.4215 30.183 11.8099 30.1684ZM6.29973 15.9806H4.12775V6.48317H6.29973V15.9806ZM9.16039 6.3374L24.366 4.69974C25.1106 4.61954 25.7911 5.12734 25.9261 5.86402L27.8481 16.3521C28.0091 17.2307 27.3344 18.0402 26.4412 18.0402H17.7953C17.3625 18.0402 16.9529 18.2363 16.6813 18.5734C16.4102 18.9101 16.3059 19.3514 16.3975 19.7738L16.3979 19.7755L16.4041 19.8058C16.4095 19.833 16.418 19.8765 16.4285 19.9348C16.4496 20.0514 16.4788 20.2259 16.5084 20.4449C16.5679 20.8855 16.6273 21.4929 16.6292 22.1629C16.6331 23.5816 16.3766 24.9442 15.6924 25.7609C15.1701 26.3844 14.2625 26.8014 13.3071 27.0488C13.2666 27.0594 13.2264 27.0694 13.1865 27.0791V19.4706C13.1865 18.9335 12.8857 18.4418 12.4076 18.1972L9.16039 16.536V6.3374Z"
                      fill="currentColor"
                />
            </svg>
        )
    }
    return (
        <svg id={"s-thumbs-down"} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.37092 21.9993L9.33333 21C9.37092 21.9993 9.37063 21.9993 9.37092 21.9993L9.3735 21.9992L9.37698 21.9991L9.38655 21.9986L9.41592 21.9971C9.43993 21.9958 9.47272 21.9937 9.51337 21.9907C9.59456 21.9845 9.7077 21.9743 9.84536 21.9575C10.1192 21.924 10.4978 21.8635 10.9192 21.7544C11.7277 21.5449 12.8525 21.1165 13.6184 20.2022C14.5708 19.0653 14.7433 17.4979 14.7402 16.3968C14.7394 16.0813 14.7238 15.7846 14.7018 15.52H19.6002C21.4736 15.52 22.8887 13.822 22.551 11.9792L21.2073 4.64662C20.9241 3.10149 19.497 2.03642 17.9352 2.20463L6.46483 3.44H3C2.44772 3.44 2 3.88771 2 4.44V13.08C2 13.6323 2.44772 14.08 3 14.08H6.27758L8.33333 15.1317V21C8.33333 21.2717 8.44392 21.5318 8.63964 21.7203C8.83537 21.9088 9.09938 22.0095 9.37092 21.9993ZM5.51852 12.08H4V5.44H5.51852V12.08ZM7.51852 5.33808L18.1494 4.19313C18.67 4.13706 19.1457 4.49208 19.2401 5.00712L20.5838 12.3397C20.6964 12.954 20.2247 13.52 19.6002 13.52H13.5556C13.2529 13.52 12.9665 13.6571 12.7767 13.8928C12.5871 14.1282 12.5142 14.4367 12.5783 14.732C12.5784 14.7324 12.5785 14.7328 12.5785 14.7332L12.5829 14.7544C12.5867 14.7734 12.5926 14.8038 12.6 14.8445C12.6147 14.9261 12.6351 15.0481 12.6558 15.2012C12.6974 15.5092 12.7389 15.9339 12.7402 16.4023C12.743 17.3942 12.5637 18.3468 12.0853 18.9178C11.7201 19.3537 11.0856 19.6453 10.4177 19.8183C10.3893 19.8256 10.3612 19.8327 10.3333 19.8395V14.52C10.3333 14.1445 10.123 13.8007 9.78877 13.6297L7.51852 12.4683V5.33808ZM9.29316 20.0008C9.29238 20.0008 9.29286 20.0008 9.29316 20.0008V20.0008Z"
                  fill="currentColor"
            />
        </svg>
    )
}