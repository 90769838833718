import classNames from "classnames";
import { PropsWithChildren } from "react";
import * as React from 'react';
import styles from 'components/designSystem/containers/FlexGrow.module.scss'

export enum FlexGrowValue {
    VALUE_1 = 'VALUE_1',
    VALUE_2 = 'VALUE_2',
    VALUE_8 = 'VALUE_8'
}
type FlexGrowProps  = PropsWithChildren<{
    value?: FlexGrowValue
}>;

/**
 * Component which grows to fill remaining space when used within a flex container.
 */
const FlexGrow = (props: FlexGrowProps): JSX.Element => {
    const value = props.value ?? FlexGrowValue.VALUE_1;

    return <div className={classNames({
        [styles.flexGrowVALUE_1]: value === FlexGrowValue.VALUE_1,
        [styles.flexGrowVALUE_2]: value === FlexGrowValue.VALUE_2,
        [styles.flexGrowVALUE_8]: value === FlexGrowValue.VALUE_8,
    })}>
        {props.children}
    </div>
};

export const FlexGrow2 = (props: FlexGrowProps): JSX.Element =>
    <FlexGrow value={FlexGrowValue.VALUE_2} {...props} >{props.children}</FlexGrow>;
export const FlexGrow8 = (props: FlexGrowProps): JSX.Element =>
    <FlexGrow value={FlexGrowValue.VALUE_8} {...props} >{props.children}</FlexGrow>;

export default FlexGrow;