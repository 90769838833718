import { ActionDispatcher } from 'actions/ActionInterface';
import { FEEDBACK_IDENTIFIER_RESET_KNOWN_PASSWORD, resetKnownPW } from 'actions/user/UserActions';
import Form from "components/commonDesign/form/tmpNewSystem/Form";
import FormInput from "components/commonDesign/form/tmpNewSystem/FormInput";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { LargeSpacer, MediumSpacer } from "components/designSystem/containers/Spacer";
import APIFeedbackMessageContainer from 'components/pages/common/APIFeedbackMessage';
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from "components/pages/common/WithTranslations";
import { aggregate, password, required } from "components/utils/form/tmpNewSystem/validators";
import { mustMatch } from 'components/utils/form/validators';
import { ValidationErrors } from "final-form";
import React, { ReactNode } from 'react';
import { connect } from "react-redux";

export interface ResetKnownPasswordDispatchProps {
    onSubmit: (oldPassword: string, newPassword: string) => void;
}

class ResetKnownPassword extends WithTranslations<ResetKnownPasswordDispatchProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    onSubmit() {
        return (data: { oldPassword: string; newPassword: string; confirmation: string }): void => {
            this.props.onSubmit(data.oldPassword, data.newPassword);
        };
    }

    render(): ReactNode {
        return (
            <LargeSpacer>
                    <APIFeedbackMessageContainer
                        identifier={FEEDBACK_IDENTIFIER_RESET_KNOWN_PASSWORD}
                        withNewDesignSystem
                        resetConditions={[
                            {id: 'identifier', action: 'click'},
                        ]}/>

                <div className={'form-container'}>
                    <Form
                        onSubmit={this.onSubmit()}
                        validate={(values): ValidationErrors => {
                            const validationErrors: ValidationErrors = {}

                            const newPwdMustBeDiff = values.oldPassword && values.newPassword ? !mustMatch(
                                values.oldPassword, values.newPassword
                            ) : undefined;
                            const confirmationMustMatch = values.newPassword && values.confirmation ? mustMatch(
                                values.newPassword, values.confirmation
                            ) : undefined

                            if (newPwdMustBeDiff) {
                                validationErrors['newPassword'] = this.trans(
                                    'new_pwd_must_be_different', undefined, TransDomain.ACCOUNT
                                );
                            }
                            if (confirmationMustMatch) {
                                validationErrors['confirmation'] = this.trans(
                                    'must_match_passwords', undefined, 'form_errors'
                                );
                            }

                            return validationErrors;
                        }}>
                        {(formState): JSX.Element => (
                            <LargeSpacer>
                                <MediumSpacer>
                                    <FormInput
                                        name={"oldPassword"}
                                        field={{
                                            required: true,
                                            label: this.trans('old_password'),
                                            type: "password"
                                        }}
                                        validate={required("password")}
                                    />
                                    <FormInput
                                        name={"newPassword"}
                                        field={{
                                            required: true,
                                            label: this.trans('new_password'),
                                            type: "password"
                                        }}
                                        validate={aggregate(required("password"), password())}
                                    />
                                    <FormInput
                                        name={"confirmation"}
                                        field={{
                                            required: true,
                                            label: this.trans('confirm_new_password'),
                                            type: "password"
                                        }}
                                        validate={required("password")}
                                    />
                                </MediumSpacer>
                                <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} isSubmit
                                        fullWidth disabled={formState.alreadySubmitted || formState.invalid}
                                >
                                    {this.trans('save', undefined, TransDomain.ACCOUNT)}
                                </Button>
                            </LargeSpacer>
                        )}
                    </Form>
                </div>
            </LargeSpacer>
        );
    }
}

const mapStateToProps = (): {} => ({});
const mapDispatchToProps = (dispatch: ActionDispatcher): ResetKnownPasswordDispatchProps => ({
    onSubmit: (oldPassword: string, newPassword: string): void =>
        dispatch(resetKnownPW(oldPassword, newPassword, FEEDBACK_IDENTIFIER_RESET_KNOWN_PASSWORD)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetKnownPassword);
