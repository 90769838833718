import { TransKey } from 'components/pages/common/MainComponent';
import { AnyAction } from 'actions/ActionInterface';

export enum APIFeedbackMessageFallbackCatcher {
    MAIN_ERROR_PAGE,
    MAIN_HEADER,
}

export enum APIFeedbackMessageType {
    INFO,
    SUCCESS,
    WARNING,
    ERROR,
}

export interface APIFeedbackMessageThenDispatch {
    thenDispatchAction: AnyAction;
    thenDispatchDelay?: number;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface APIFeedbackMessage<A = any, T = undefined> {
    identifier: string | null;
    message: string | TransKey;
    type?: APIFeedbackMessageType;
    originAction: A;
    extraData: T;
    thenDispatch?: APIFeedbackMessageThenDispatch;
}
