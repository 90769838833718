import TrText from "components/common/TrText";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import Button, { ButtonOption, ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import Box, { BackgroundGradientColor } from "components/designSystem/containers/Box";
import FlexGrow from "components/designSystem/containers/FlexGrow";
import Spacer, { SpacerSpacing } from "components/designSystem/containers/Spacer";
import { TDisplaySmall500, TypographyColor } from "components/designSystem/containers/Typography";
import { WithTopMargin } from "components/designSystem/containers/WithMargin";
import { IconName } from "components/designSystem/foundations/IconsData";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import React from "react";
import { useMediaQuery } from "react-responsive";

type KiroInfoModulePayload = { url: string };

export const KiroInfoModule = (): JSX.Element => {
    const payload = useModulePayload<KiroInfoModulePayload>();
    const isDesktop = useMediaQuery({minWidth: TABLET_MAX_WIDTH_ACCESSIBLE});
    return <Box
        background={{
            imageUrl: 'https://cdn.kiro.bio/static/content/platform/global/kiro_cover.png',
            gradient: BackgroundGradientColor.DARK_GREY_BLUE
        }}
        withRadius
        withFullHeight
        withPadding>
        <Spacer spacing={isDesktop ? SpacerSpacing.LARGE : SpacerSpacing.SMALL} withFullHeight>
            <FlexGrow/>
            <TDisplaySmall500 color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                <TrText input={{trkey: 'modules.kiro_info.title'}}/>
            </TDisplaySmall500>
            <FlexGrow/>
            <WithTopMargin>
                <Button
                    variant={ButtonVariant.SECONDARY}
                    option={ButtonOption.DARK}
                    size={ButtonSize.LARGE}
                    icons={{right: IconName.EXTERNAL_LINK}}
                    onClick={{href: payload.url, targetBlank: true}}>
                    <TrText input={{trkey: 'modules.kiro_info.button'}}/>
                </Button>
            </WithTopMargin>
        </Spacer>
    </Box>;
}
