import { NumericalValue } from 'models/medicalReport/ReportModels';

export enum ChartTag {
    SELECTED  = 'SELECTED',
    ABNORMAL  = 'ABNORMAL'
}

export enum ChartNormality {
    NORMAL = 'NORMAL',
    HIGH = 'HIGH',
    LOW = 'LOW'
}

export type ValueFormatterInterface = (value: number | null | NumericalValue) => string
