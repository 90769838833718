import React from 'react';

const ArrowRightUp = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.89222 25.1078C7.50404 25.7196 8.496 25.7196 9.10782 25.1078L22.4333 11.7822V20.2739C22.4333 21.1392 23.1348 21.8406 24 21.8406C24.8653 21.8406 25.5667 21.1392 25.5667 20.2739V7.99996C25.5667 7.13471 24.8653 6.43329 24 6.43329H11.726C10.8608 6.43329 10.1594 7.13471 10.1594 7.99996C10.1594 8.8652 10.8608 9.56662 11.726 9.56662H20.2177L6.89222 22.8922C6.28039 23.504 6.28039 24.4959 6.89222 25.1078Z"
            />
        </svg>
    )
}

export default ArrowRightUp;
