import { NormalityLevel } from "components/legacyDesignSystem/components/charts/models";
import { CodedValue } from "models/medicalReport/LegacyReportModels";
import React, { ReactElement } from 'react';

import "./styles.scss"


export enum ValueDirection  {
    START = 'start',
    END = 'end'
}


export function renderValue(
    value: number | string | CodedValue | null | undefined,
    unit: string | undefined | null,
    x: number,
    y: number,
    dx?: number,
    dy?: number,
    direction: ValueDirection = ValueDirection.END,
    normalityLevel?: NormalityLevel,
): ReactElement[] {
    const result: ReactElement[] = []
    if (value === undefined || value === null ) {
        return result
    }

    result.push(
        <text
            key={"textValue"}
            className={normalityLevel == undefined || normalityLevel == NormalityLevel.NORMAL ? 'neutralsDarkBlueGrey' : 'semanticYellow' }
            x={x}
            dx={dx}
            y={y}
            dy={dy}
            textAnchor={direction}
        >
            <tspan className={'bold'} fontSize={24}>{value}</tspan>
            {unit && <tspan className={'regular'} fontSize={14}>{'\u2009'}{unit}</tspan>}
        </text>
    )
    return result
}
