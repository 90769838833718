import React, { ReactNode } from 'react';
import MainComponent, { MainComponentProps, setupRaw, TransDomain } from 'components/pages/common/MainComponent';

export type MissingEulaVersionProps = MainComponentProps;
class MissingEulaVersion extends MainComponent<MissingEulaVersionProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT + '.eula';

    render(): ReactNode {
        return (
            <div>
                {this.transContent(
                    'missing_version',
                    <>
                        Missing version, click
                        <span
                            className={'missing-version-reload-button'}
                            onClick={(): void => window.location.reload()}
                        >
                            here
                        </span>
                    </>,
                )}
            </div>
        );
    }
}

export default setupRaw(MissingEulaVersion);
