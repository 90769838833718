import classNames from "classnames";
import Badge, { BadgeSeverity } from "components/designSystem/components/Badge";
import Button, { ButtonVariant } from "components/designSystem/components/Button";
import Divider, { DividerOrientation, DividerVariant } from "components/designSystem/components/Divider";
import DropDown from "components/designSystem/components/DropDown";
import DropDownItem, { DropDownType } from "components/designSystem/components/DropDownItem";
import { AlwaysInline, InlineJustifyContent } from "components/designSystem/containers/Inline";
import { IconName } from "components/designSystem/foundations/IconsData";
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './Tabs.module.scss'

const MAX_TABS_BEFORE_OVERFLOW = 3
const MAX_TABS_AFTER_OVERFLOW = 3
const MAX_TABS_MOBILE = 1

export interface TabProps {
    name: string,
    id: string,
    onClick: () => void,
    badgeValue?: number,
    isSelected?: boolean,
    badgeSeverity?: BadgeSeverity
    isAction?: boolean;
}

export const Tab: FC<TabProps> = (
    {
        isSelected = false,
        ...props
    }): JSX.Element => {

    const computedTextClass = classNames({
        [styles.tabSelectedText]: isSelected,
        [styles.tabText]: !isSelected,
    })

    return <div className={classNames(styles.tabContainer)} onClick={props.onClick}>
        <div className={styles.tab}>
            <span className={computedTextClass}>{props.name}</span>
            {props.badgeValue &&
                <Badge severity={props.badgeSeverity}>{props.badgeValue}</Badge>}
        </div>
        {isSelected &&
            <div className={styles.tabSelectedBar}/>}
    </div>
}

interface TabBarProps {
    fullWidth?: boolean,
    actionLabel?: ReactNode,
    tabs: TabProps[],
    isMobile?:boolean,
    selectedTab?: string
}

const buildTabBar = (tabsLength:number, tabs:TabProps[],dropDownItems:TabProps[], selectedTab?:string, isMobile = false): JSX.Element => {
    let isSelected = false
    const maxTabsInBarDesktop = (tabsLength > MAX_TABS_BEFORE_OVERFLOW) ? MAX_TABS_AFTER_OVERFLOW : MAX_TABS_BEFORE_OVERFLOW
    const maxTabsInBar = isMobile ? MAX_TABS_MOBILE : maxTabsInBarDesktop
    return <div className={styles.tabs}>
        {tabs.map((t: TabProps, k: number) => {
            if (k < (isSelected ? maxTabsInBar : maxTabsInBar - 1) || t.id == selectedTab) {
                if (t.id == selectedTab) {
                    isSelected = true
                }
                return <Tab key={k} id={t.id} name={t.name} isSelected={t.id == selectedTab}
                            onClick={t.onClick}/>
            } else {
                dropDownItems.push(t)
            }
        })}
    </div>
}

export const Tabs: FC<PropsWithChildren<TabBarProps>> = (
    {
        ...props
    }): JSX.Element => {
    const dropDownItems: TabProps[] = []


    return <div>
        <AlwaysInline justifyContent={InlineJustifyContent.SPACE_BETWEEN}>
            {buildTabBar(props.tabs.length, props.tabs, dropDownItems, props.selectedTab, props.isMobile)}
            {dropDownItems.length > 0 &&
                <DropDown title={props.actionLabel} triggerBuilder={
                    (onClick: () => void): ReactNode => (
                        <Button key={'button'} variant={ButtonVariant.QUATERNARY} icons={{right: IconName.CHEVRON_DOWN}}
                                onClick={onClick}>{props.actionLabel}</Button>
                    )
                } closeOnClick>
                    {dropDownItems.map((t: TabProps, k: number) => {
                        return <DropDownItem key={k}
                                             label={t.name}
                                             payload={{type: DropDownType.LIST, onClick: t.onClick}}/>
                    })}
                </DropDown>
            }
        </AlwaysInline>
        <Divider variant={DividerVariant.HIGHLIGHTED} orientation={DividerOrientation.HORIZONTAL}/>
    </div>
}