import { ActionDispatcher } from 'actions/ActionInterface';
import { initNavigation } from 'actions/common/ConnectedCarousel';
import { fetchPatientReports, FetchPatientReportsFilters } from 'actions/modular/modules/ReportsActions';
import EntityLogo from "components/commonDesign/EntityLogo";
import { MOBILE_MAX_WIDTH } from "components/core/constants";
import { AvatarSize } from "components/designSystem/components/Avatar";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import GridRow, { GridRowItem } from "components/designSystem/containers/GridRow";
import Inline, { AlwaysInline, InlineAlignItem, InlineSpacing } from "components/designSystem/containers/Inline";
import { MediumSpacer } from "components/designSystem/containers/Spacer";
import { TDisplayXSmall500, TLabel, TypographyColor } from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";
import Icon from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import BaseModule, { OwnProps, setupModule } from 'components/modules/modular/BaseModule';
import { BASE_GRID_SPLIT } from "components/modules/modular/modules/report/content/models";
import { reportPage } from "components/modules/modular/pages/builders";
import { TransDomain } from 'components/pages/common/MainComponent';
import { formatDate } from "core/utils/Date";
import { capitalizeFirstLetter } from 'core/utils/text';
import { ReportMetadata } from "models/medicalReport/ReportModels";
import { ReportInfo } from 'models/modular/report';
import { ModuleData, OwnPayloadConstraint } from 'models/modular/storage';
import React, { ReactNode } from 'react';
import MediaQuery from "react-responsive";
import _ from 'underscore';
import 'components/modules/modular/modules/reportList/LastReportsModule.scss';


export interface LastReportsModulePayload {
    patient_uuids: string[];
    report_uuid: string;
}

export interface LastReportsModuleDispatchProps {
    getReports: (patientUUIDs: string[], filters: FetchPatientReportsFilters) => void;
    setupNavigation: (identifier: string, defaultItems: string[]) => void;
}

interface OwnPayload extends OwnPayloadConstraint {
    reportsByPatientUUID: {
        [uuid: string]: ReportInfo[];
    }
    metadataByReportUUID: {
        [uuid: string]: ReportMetadata,
    }
}


class LastReportsModule extends BaseModule<LastReportsModuleDispatchProps,
    LastReportsModulePayload,
    OwnPayload> {
    TRANS_SUFFIX = TransDomain.MODULES + '.last_reports'

    componentDidMount(): void {
        if (this.props.payload) {
            const reportUuid = this.props.payload?.pagePayload.report_uuid;
            // If report UUID is provided, fetch the report, otherwise all latest
            if (reportUuid) {
                this.props.getReports(
                    this.props.payload.pagePayload.patient_uuids,
                    {reportUuid}
                )
            } else {
                this.props.getReports(
                    this.props.payload.pagePayload.patient_uuids,
                    {onlyLastReports: true}
                )
            }
        }
    }

    componentDidUpdate(): void {
        if (!this.props.payload?.pagePayload.report_uuid) {
            const missingPatientUUIDs = this.getMissingPatientUUIDs();
            if (!_.isEmpty(missingPatientUUIDs)) {
                this.props.getReports(missingPatientUUIDs, {onlyLastReports: true})
            }
        }
    }

    getMissingPatientUUIDs = (): string[] => {
        return this.props.payload
            ? _.difference(
                this.props.payload.pagePayload.patient_uuids,
                _.keys(this.props.payload.ownPayload.reportsByPatientUUID),
            )
            : []
    }


    flattenReports = (): ReportInfo[] => {
        if (this.props.payload) {
            const reports: ReportInfo[] = []
            this.props.payload.pagePayload.patient_uuids.forEach((p: string): void => {
                reports.push(...this.props.payload?.ownPayload.reportsByPatientUUID[p] || [])
            })
            return reports
        }
        return []
    }


    goToReport = (
        uuid: string,
        reports: string[],
    ): void => {
        const navigationKey = 'last_reports_navigation'
        this.props.setupNavigation(
            navigationKey,
            reports
        );
        this.props.changePage(reportPage(uuid, navigationKey, uuid == this.props.payload?.pagePayload.report_uuid))
    }

    buildNewResultLine = (r: ReportInfo, k: number, isMobile: boolean, onClick: () => void): JSX.Element => {
        const metaData = this.props.payload?.ownPayload.metadataByReportUUID?.[r.uuid]
        const entity = metaData && metaData.entity
        return <Inline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM} key={k}>
            <Box background={BoxColor.WHITE} withRadius withFullWidth>
                <WithMargin margins={[MarginSize.SMALL]}>
                    {!isMobile &&
                        <GridRow columnNb={BASE_GRID_SPLIT}>
                            <GridRowItem from={1} to={8}>
                                <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                                    <EntityLogo
                                        entity={entity}
                                        asAvatar={{size: AvatarSize.SMALL}}
                                        fallbackIcon={IconName.TUBE}
                                    />
                                    {entity?.name ?
                                        <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>{entity.name}</TLabel> :
                                        <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                            {this.trans('loading_name', undefined, undefined, capitalizeFirstLetter)}
                                        </TLabel>
                                    }
                                </AlwaysInline>
                            </GridRowItem>
                            <GridRowItem from={9} to={21}>
                                <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                    {this.trans('sampling_date', undefined, undefined, capitalizeFirstLetter)}
                                    {" " + formatDate(r.samplingDate, 'ddd D MMM YYYY - HH[h]mm')}
                                </TLabel>
                            </GridRowItem>
                        </GridRow>
                    }
                    {isMobile &&
                        <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                            <EntityLogo
                                entity={entity}
                                asAvatar={{size: AvatarSize.SMALL}}
                                fallbackIcon={IconName.TUBE}
                            />
                            <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                {formatDate(r.samplingDate, 'ddd D MMM YYYY - HH[h]mm')}
                            </TLabel>
                        </AlwaysInline>
                    }
                </WithMargin>
            </Box>
            <Button variant={ButtonVariant.PRIMARY} onClick={onClick} size={ButtonSize.LARGE} fullWidth={isMobile}>
                {this.trans('view_details', undefined, undefined, capitalizeFirstLetter)}
            </Button>
        </Inline>
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _render(payload: ModuleData<LastReportsModulePayload, OwnPayload>): ReactNode {
        if (!_.isEmpty(this.getMissingPatientUUIDs())) {
            return this.loading()
        }
        let reports = this.flattenReports()
        const reportLink = this.props.payload?.pagePayload.report_uuid
        if (reportLink) {
            reports = reports.filter((report ) => report.uuid == reportLink)
        }
        if (_.isEmpty(reports)) {
            return undefined
        } else {
            const onClick = (reportUUID: string) => (): void => {
                this.goToReport(
                    reportUUID,
                    reports.map(
                        (r: ReportInfo): string => r.uuid
                    ),
                )
            }
            return (
                <Box background={BoxColor.INTENSE} withBoxShadow>
                    <WithMargin margins={[MarginSize.MEDIUM]}>
                        <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
                            {(match: boolean): JSX.Element => <MediumSpacer>
                                <AlwaysInline spacing={InlineSpacing.SMALL} alignItems={match ? InlineAlignItem.START : InlineAlignItem.CENTER}>
                                    <WithMargin margins={match ? [MarginSize.X_SMALL, undefined, undefined, undefined] : [undefined]}>
                                        <Icon name={IconName.FILL_BELL} size={IconSize.EXTRA_SMALL} color={IconColor.DEFAULT}/>
                                    </WithMargin>
                                    <TDisplayXSmall500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                        {reportLink ? this.trans('linked_result') : this.trans('new_results', undefined, undefined, capitalizeFirstLetter)}
                                    </TDisplayXSmall500>
                                </AlwaysInline>
                                {reports.map(
                                    (r: ReportInfo, k: number) => {
                                        return this.buildNewResultLine(r, k, match, onClick(r.uuid))
                                    }
                                )}
                            </MediumSpacer>}
                        </MediaQuery>
                    </WithMargin>
                </Box>
            )
        }
    }
}

const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (
    dispatch: ActionDispatcher,
    ownProps: OwnProps
): LastReportsModuleDispatchProps => ({
    getReports: (patientUUIDs: string[], filters: FetchPatientReportsFilters): void => {
        dispatch(fetchPatientReports(
            ownProps.identifier,
            patientUUIDs,
            filters
        ))
    },
    setupNavigation: (identifier: string, defaultItems: string[]): void => dispatch(
        initNavigation(identifier, defaultItems)
    ),
});

export default setupModule(LastReportsModule, mapStateToProps, mapDispatchToProps);
