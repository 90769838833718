import { ColorMap } from "components/designSystem/Aliases";
import { APIText } from 'models/common/message';

export enum MapType {
    FRANCE = 'FRANCE',
}

export interface MapLegendItem {
    label: APIText;
}

export interface MapPayload {
    selected_area?: string;
    type: MapType;
    density_color: ColorMap;
    legend: MapLegendItem[];
    reversed?: boolean
}

export interface ExtendedMapPayload extends MapPayload {
    onAreaHover?: (areaId?: string) => void;
    onMapHover?: (x?: number, y?: number) => void;
    tooltipX?: number,
    tooltipY?: number,
    onAreaClick?: (areaId: string) => void
}
