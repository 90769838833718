import { Action, ActionDispatcher } from 'actions/ActionInterface';
import { initNavigation } from 'actions/common/ConnectedCarousel';
import { getDoctorDashboard, GetDoctorDashboardAction } from "actions/doctorBoard/DoctorBoardFetchActions";
import TrText from "components/common/TrText";
import DoctorReportsList, { DoctorReportsListRow } from "components/commonDesign/DoctorReportsList";
import DoctorReportsTable, { DoctorReportsTableRow } from "components/commonDesign/DoctorReportsTable";
import { TABLET_MAX_WIDTH } from 'components/core/constants';
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";
import Toggle, { TogglePosition, ToggleSize } from "components/designSystem/components/Toggle";
import Box, { BoxColor, BoxHeight, WhiteBoxWithShadow } from "components/designSystem/containers/Box";
import Inline, { AlwaysInline, InlineAlignItem, InlineJustifyContent } from "components/designSystem/containers/Inline";
import Pager, { PagerType } from "components/designSystem/containers/navigation/Pager";
import { MediumSpacer } from "components/designSystem/containers/Spacer";
import TextJustifier, { TextJustification } from "components/designSystem/containers/TextJustifier";
import { TCaption, TDisplayMedium, TypographyColor } from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize, WithBottomMargin } from "components/designSystem/containers/WithMargin";
import BaseModule, { defaultBaseModuleState, ModuleState, setupModule, } from 'components/modules/modular/BaseModule';
import { reportPage } from "components/modules/modular/pages/builders";
import { TransDomain } from "components/pages/common/MainComponent";
import { AnyState } from "core/store/Store";
import { capitalizeFirstLetter } from "core/utils/text";
import { APIText } from "models/common/message";
import { DoctorDashboardDataWithMode, ReportData } from "models/doctor/DashboardModels";
import { ReportMetadata } from 'models/medicalReport/ReportModels';
import { ReportInfo } from 'models/modular/report';
import { ModuleData, OwnPayloadConstraint } from 'models/modular/storage';
import React, { ReactNode } from 'react';
import MediaQuery from 'react-responsive';
import { REDUCER_DOCTOR } from "reducers/allReducers";
import { fetchReducer } from "reducers/selector";


interface DoctorReportsModuleDispatchProps {
    getDashboardData: (onlyAsReceiver: boolean, nextPageToken?: string) => void;
    setupNavigation: (
        identifier: string,
        defaultItems: string[],
        fetchPreviousAction?: GetDoctorDashboardAction,
        fetchNextAction?: GetDoctorDashboardAction,
    ) => void;
}

export type ReportViewer = {
    uuid: string;
    is_current_user: boolean;
}

export type Report = {
    uuid: string;
    last_result_date: Date;
    last_view_date: Date;
    viewers: ReportViewer[];
}

export type DoctorDashboardData = {
    reports: ReportData[];
    next_pagination_token?: string | null;
};

interface DoctorReportsModulePayload {
    results: DoctorDashboardData;
    title: APIText;
    with_status_column: boolean;
}

interface OwnPayload extends OwnPayloadConstraint {
    reportsByPatientUUID: {
        [uuid: string]: ReportInfo[];
    }
    metadataByReportUUID: {
        [uuid: string]: ReportMetadata,
    }
}

interface DoctorReportsModuleState {
    activePage: number;
    allReports: boolean;
    isLoadingSwitchMode: boolean;
    isLoadingMoreReports: boolean;
}

interface DoctorReportsModuleStateProps {
    doctorDashboard: DoctorDashboardDataWithMode;
}


const REPORT_PER_PAGE_FOR_MOBILE = 5;
const REPORT_PER_PAGE_FOR_DESKTOP = 20;

/**
 * As we don't have a dedicated component for mobile, we take the maximum report per page to handle screen size
 * modification when it is already loaded
 * A better implementation should be to forced the re-rendering when screen is reduced or to have two components :
 * one for mobile and one for desktop
 * */
const MAX_REPORT_PER_PAGE = Math.max(REPORT_PER_PAGE_FOR_MOBILE, REPORT_PER_PAGE_FOR_DESKTOP);

type DoctorReportsModuleProps = DoctorReportsModuleDispatchProps & DoctorReportsModuleStateProps;

class DoctorReportsModule extends BaseModule<DoctorReportsModuleProps, DoctorReportsModulePayload, OwnPayload> {
    TRANS_SUFFIX = TransDomain.DOCTOR + '.dashboard'

    state: ModuleState<DoctorReportsModuleState> = defaultBaseModuleState({
        activePage: 1,
        allReports: false,
        isLoadingSwitchMode: false,
        isLoadingMoreReports: false,
    })

    componentDidMount(): void {
        this.props.getDashboardData(!this.state.allReports);
        if (!this.props.doctorDashboard) {
            // Show loading indicator while waiting for initial load
            this.setState({isLoadingMoreReports: true, isLoadingSwitchMode: true})
        }
    }

    componentDidUpdate(prevProps: DoctorReportsModuleProps): void {
        const lastIndex = MAX_REPORT_PER_PAGE * this.state.activePage;
        const isLoading = this.state.isLoadingMoreReports || this.state.isLoadingSwitchMode
        if (!isLoading &&
            this.props.doctorDashboard &&
            this.props.doctorDashboard.next_pagination_token &&
            this.props.doctorDashboard.reports.length < lastIndex
        ){
            this.props.getDashboardData(
                !this.state.allReports, this.props.doctorDashboard.next_pagination_token
            )
            this.setState({isLoadingMoreReports: true})
        }

        if (prevProps.doctorDashboard && this.props.doctorDashboard) { // Received a new response
            if (prevProps.doctorDashboard.onlyAsReceiver !== this.props.doctorDashboard.onlyAsReceiver) {
                // New response has changed to match the new switch value
                this.setState({isLoadingSwitchMode: false});
            }

            if (prevProps.doctorDashboard.next_pagination_token !== this.props.doctorDashboard.next_pagination_token) {
                // New page response
                this.setState({isLoadingMoreReports: false});
            }
        } else if (this.props.doctorDashboard) { // Received initial response
            this.setState({isLoadingSwitchMode: false, isLoadingMoreReports: false});
        }
    }

    switchSelector(): void {
        this.setState({
            allReports: !this.state.allReports,
            isLoadingSwitchMode: true,
            activePage: 1
        }, () => {
            this.props.getDashboardData(!this.state.allReports)
        });
    }

    goToReport = (
        uuid: string,
        reports: string[]
    ): void => {
        const navigationKey = 'report_dashboard_' + uuid
        if (reports[reports.length - 1] == uuid && this.props.doctorDashboard.next_pagination_token) {
            this.props.getDashboardData(!this.state.allReports, this.props.doctorDashboard.next_pagination_token)
            const reportsNewValues: ReportData[] = this.props.doctorDashboard?.reports ? this.props.doctorDashboard.reports : []
            if (reports.length > 0) {
                reports = reportsNewValues.map((report: ReportData) => report.report_uuid)
            }
        }

        this.props.setupNavigation(
            navigationKey,
            reports,
            undefined,
            this.props.doctorDashboard.next_pagination_token ? getDoctorDashboard(
                this.props.doctorDashboard?.next_pagination_token,
                !this.state.allReports,
                navigationKey
            ) : undefined
        )

        this.props.changePage(reportPage(uuid, navigationKey))
    }

    buildHeader = (isMobile: boolean, title: APIText): JSX.Element => {
        const titleComponent = <TDisplayMedium color={TypographyColor.COLOR_TEXT_DEFAULT}>
            <TrText input={title} postTrans={capitalizeFirstLetter}/>
        </TDisplayMedium>
        const toggle = <Toggle
            label={this.trans('filters.all_reports', undefined, undefined, capitalizeFirstLetter)}
            togglePosition={TogglePosition.RIGHT}
            onClick={(): void => this.switchSelector()}
            checked={this.state.allReports}
            size={ToggleSize.SMALL}
            disabled={this.state.isLoadingSwitchMode}
        />;
        return isMobile ? <MediumSpacer>
            <TextJustifier justify={TextJustification.CENTER}>{titleComponent}</TextJustifier>
            {toggle}
        </MediumSpacer> : <AlwaysInline justifyContent={InlineJustifyContent.SPACE_BETWEEN} alignItems={InlineAlignItem.CENTER}>
            {titleComponent}
            {toggle}
        </AlwaysInline>
    }

    buildReportList = (isMobile: boolean, reports: ReportData[], numberOfVisibleRows: number): JSX.Element => {
        const reportUuids: string[] = reports.length > 0 ? reports.map(
            (report: ReportData) => report.report_uuid
        ) : []

        const currentReportsVisible: ReportData[] = reports.slice(
            ((this.state.activePage - 1) * numberOfVisibleRows), (this.state.activePage * numberOfVisibleRows)
        )

        const rows: DoctorReportsTableRow[] = currentReportsVisible.map((r: ReportData) => ({
            uuid: r.report_uuid,
            patient: r.patient,
            receptionDate: (new Date(r.last_result_date)),
            tags: r.tags,
            isNew: !r.tags.some((tag) => !!tag.is_current_user),
            isSent: r.is_sent,
            isAiSuggestionsEnriched: r.is_ai_suggestions_enriched,
        }))

        return isMobile ? <DoctorReportsList
            rows={rows}
            onRowClick={
                (r: DoctorReportsListRow): void => this.goToReport(r.uuid, reportUuids)
            }
        /> : <DoctorReportsTable
            rows={rows}
            onRowClick={
                (r: DoctorReportsTableRow): void => this.goToReport(r.uuid, reportUuids)
            }
            withStatusColumn={this.props.payload?.pagePayload.with_status_column}
        />
    }

    private buildEmptyContentPlaceholder(): JSX.Element {
        return <WithMargin margins={[MarginSize.LARGE, MarginSize.X_LARGE]}>
            <TextJustifier justify={TextJustification.CENTER}>
                <TCaption>
                    <TrText input={{
                        trkey: this.state.allReports ? 'dashboard_reports_all_empty' : 'dashboard_reports_personal_empty',
                        trdomain: TransDomain.DOCTOR
                    }}/>
                </TCaption>
            </TextJustifier>
        </WithMargin>;
    }

    private buildLoadingIndicator(): JSX.Element {
        return <ModuleLoaderWrapper>
            <Box background={BoxColor.WHITE} withFixedHeight={BoxHeight.MEDIUM}/>
        </ModuleLoaderWrapper>;
    }

    private buildReportsContent(isMobile: boolean, reports: ReportData[], numberOfVisibleRows: number): JSX.Element {
        const shouldActiveNext = reports.length > (numberOfVisibleRows * this.state.activePage) ||
            !!this.props.doctorDashboard?.next_pagination_token
        return <>
            <WithMargin
                margins={isMobile ? [MarginSize.MEDIUM, MarginSize.SMALL] : [undefined, undefined]}>
                {this.buildReportList(isMobile, reports, numberOfVisibleRows)}
            </WithMargin>
            <WithMargin margins={isMobile ? [MarginSize.MEDIUM] : [MarginSize.MEDIUM, MarginSize.LARGE]}>
                <Inline justifyContent={InlineJustifyContent.FLEX_END}>
                    <Pager
                        activePosition={this.state.activePage}
                        payload={{
                            type: PagerType.WITHOUT_PAGE_COUNTER,
                            shouldActiveNext: shouldActiveNext
                        }}
                        onChangePage={(i): void => {
                            const lastReportIndex = i * MAX_REPORT_PER_PAGE;
                            if (reports.length < lastReportIndex && this.props.doctorDashboard?.next_pagination_token) {
                                this.props.getDashboardData(
                                    !this.state.allReports, this.props.doctorDashboard?.next_pagination_token
                                )
                            }
                            this.setState({activePage: i})
                        }}
                        disabled={this.state.isLoadingMoreReports || this.state.isLoadingSwitchMode}
                    />
                </Inline>
            </WithMargin>
        </>;
    }

    buildContent = (
        isMobile: boolean, payload: ModuleData<DoctorReportsModulePayload, OwnPayload>
    ): JSX.Element => {
        const numberOfVisibleRows = isMobile ? REPORT_PER_PAGE_FOR_MOBILE : REPORT_PER_PAGE_FOR_DESKTOP

        const reports: ReportData[] = this.props.doctorDashboard && !this.state.isLoadingSwitchMode ?
            this.props.doctorDashboard.reports : []
        const isLoading = this.state.isLoadingMoreReports || this.state.isLoadingSwitchMode;
        return <>
            <WithBottomMargin margin={isMobile ? MarginSize.MEDIUM : MarginSize.X_LARGE}>
                {this.buildHeader(isMobile, payload.pagePayload.title)}
            </WithBottomMargin>
            <WhiteBoxWithShadow>
                {isLoading ? this.buildLoadingIndicator() : reports.length > 0 ? this.buildReportsContent(isMobile, reports, numberOfVisibleRows) : this.buildEmptyContentPlaceholder()}
            </WhiteBoxWithShadow>
        </>
    }

    _render(payload: ModuleData<DoctorReportsModulePayload, OwnPayload>): ReactNode {
        return <>
            <MediaQuery maxWidth={TABLET_MAX_WIDTH}>
                {this.buildContent(true, payload)}
            </MediaQuery>
            <MediaQuery minWidth={TABLET_MAX_WIDTH + 1}>
                {this.buildContent(false, payload)}
            </MediaQuery>
        </>
    }
}

const mapStateToProps = (state: AnyState): DoctorReportsModuleStateProps => ({
    doctorDashboard: fetchReducer(state, REDUCER_DOCTOR).dashboard,
});

const mapDispatchToProps = (
    dispatch: ActionDispatcher,
): DoctorReportsModuleDispatchProps => ({
    getDashboardData: (onlyAsReceiver: boolean, nextPageToken?: string, navigationKey?: string,): void =>
        dispatch(getDoctorDashboard(nextPageToken, onlyAsReceiver, navigationKey)),
    setupNavigation: (
        identifier: string, defaultItems: string[],
        fetchPreviousAction?: Action<unknown>, fetchNextAction?: Action<unknown>
    ): void => dispatch(
        initNavigation(identifier, defaultItems, fetchPreviousAction, fetchNextAction)
    ),
});


export default setupModule(DoctorReportsModule, mapStateToProps, mapDispatchToProps);
