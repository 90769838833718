// If date, timestamp in milliseconds must be provided
export type AxisValue = number

export enum AxisDataType {
    TIME = 'TIME',
    NUMERICAL = 'NUMERICAL',
    TEXT = 'TEXT'
}

export enum AxisDirection {
    X = 'X',
    Y = 'Y'
}