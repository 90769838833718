import React, { FC, Fragment } from "react";

interface NavigateProps {
    path: string;
}

const Navigate: FC<NavigateProps> = ({...props}) => {
    window.location.replace(props.path)
    return <Fragment/>
}

export default Navigate;