import { BlockingPopUpComponentIdentifier } from 'components/pages/common/FullPopList';
import React, { ReactElement } from 'react';
import EulaValidationPage from 'components/modules/eula/EulaValidationPage';
import TwoFaCode from 'components/modules/user/TwoFACode';
import { OnSuccessPutType } from 'actions/ActionInterface';
import PinPage from "components/modules/user/PinPage";


export const buildBlockingPopUpComponent = (
    identifier: BlockingPopUpComponentIdentifier,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any,
    previousOnSuccessPut: OnSuccessPutType,
    backToPreviousPage: () => void,
): ReactElement => {

    switch (identifier) {
        case BlockingPopUpComponentIdentifier.EULA:
            return <
                EulaValidationPage
                {...props}
                onSuccessPut={previousOnSuccessPut}
            />;
        case BlockingPopUpComponentIdentifier.TWOFA:
            return <TwoFaCode
                {...props}
                onSuccessPut={previousOnSuccessPut}
                backToPreviousPage={backToPreviousPage}
            />
        case BlockingPopUpComponentIdentifier.PIN:
            return <PinPage
                {...props}
                onSuccessPut={previousOnSuccessPut}
            />
        default:
            throw Error('Unsupported FullPopUpComponentIdentifier: ' + identifier);
    }
}