import { ModuleAPICallAction } from 'actions/modular/ModularActions';

export const API_GRAPH_LOAD_ITEM = 'API_GRAPH_LOAD_ITEM';


export type LoadItemsPayload = {
    itemsIdentifiers: string[],
    patientUUID: string,
}

export const loadItems = (
    moduleIdentifier: string,
    itemsIdentifiers: string[],
    patientUUID: string,
): ModuleAPICallAction<LoadItemsPayload> => {
    return {
        type: API_GRAPH_LOAD_ITEM,
        payload: {
            params: {
                itemsIdentifiers,
                patientUUID
            },
            moduleIdentifier: moduleIdentifier
        },
    };
};
