import React from 'react';

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const LogOut = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-logout'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_216_206)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H9C9.41421 0.25 9.75 0.585786 9.75 1C9.75 1.41421 9.41421 1.75 9 1.75H2C1.86193 1.75 1.75 1.86193 1.75 2V14C1.75 14.1381 1.86193 14.25 2 14.25H9C9.41421 14.25 9.75 14.5858 9.75 15C9.75 15.4142 9.41421 15.75 9 15.75H2C1.0335 15.75 0.25 14.9665 0.25 14V2ZM4.4165 8C4.4165 7.58579 4.75229 7.25 5.1665 7.25H13.1895L10.4698 4.53033C10.1769 4.23744 10.1769 3.76256 10.4698 3.46967C10.7627 3.17678 11.2376 3.17678 11.5305 3.46967L15.5305 7.46972C15.8234 7.76262 15.8234 8.23749 15.5305 8.53038L11.5303 12.5305C11.2374 12.8234 10.7626 12.8234 10.4697 12.5305C10.1768 12.2377 10.1768 11.7628 10.4697 11.4699L13.1896 8.75H5.1665C4.75229 8.75 4.4165 8.41421 4.4165 8Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_216_206">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-logout'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3 1C1.89543 1 1 1.89543 1 3V21C1 22.1046 1.89543 23 3 23H13C13.5523 23 14 22.5523 14 22C14 21.4477 13.5523 21 13 21H3V3H13C13.5523 3 14 2.55228 14 2C14 1.44772 13.5523 1 13 1H3ZM15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289L23 12C23 12.016 22.9996 12.032 22.9989 12.048C22.9874 12.2877 22.8902 12.524 22.7071 12.7071L16.7071 18.7071C16.3166 19.0976 15.6834 19.0976 15.2929 18.7071C14.9024 18.3166 14.9024 17.6834 15.2929 17.2929L19.5858 13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H19.5858L15.2929 6.70711C14.9024 6.31658 14.9024 5.68342 15.2929 5.29289ZM16.7071 5.29289L23 11.9908C22.9976 11.7379 22.9 11.4858 22.7071 11.2929L16.7071 5.29289Z"
                  fill="currentColor"/>
        </svg>
    )
}

