export enum ActionEvent {
    CLICK_INFO_TEST = 'CLICK_INFO_TEST',
    CLICK_CHART_TEST = 'CLICK_CHART_TEST',
    CLICK_SUBSCRIPTION_FORM_INPUT = 'CLICK_SUB_FORM_I',
}

export enum Resource {
    FAQ = 'FAQ',
}


export enum ResourceType {
    REPORT = 'REPORT',
    SPA_MODULE = 'SPA_MODULE',
    PAGE_ID = 'PAGE_ID',
    ARTICLE_ID = 'ARTICLE_ID',
    FEATURE = 'FEATURE',
}


export enum Action {
    CLICK_INFO = 'CLICK_INFO',
    CLICK_HISTORY = 'CLICK_HISTORY',
    CLICK_ON_TEST_MOBILE = 'CLICK_ON_TEST_MOBILE',
    CLICK_MODULE = 'CLICK_MODULE',
    VIEW_MODULE = 'VIEW_MODULE',
    CLICK_NAV_BAR_MENU = 'CLICK_NAV_BAR_MENU',
    READ_ARTICLE = 'READ_ARTICLE',
    CLICK = 'CLICK',
    READ_REPORT = 'READ_REPORT'
}


export enum SubResourceType {
    FAQ_QUESTION_ID = 'FAQ_QUESTION_ID',
    INTERNAL_TEST_ID = 'INTERNAL_TEST_ID',
    CLICK_BY_MODULE_TYPE = 'CLICK_BY_MODULE_TYPE',
    MODULE_TYPE = 'MODULE_TYPE',
    ARTICLE_READ_PERCENT = 'ARTICLE_READ_PERCENT',
    REPORT_VIEW_TYPE = 'REPORT_VIEW_TYPE'
}


export interface UserActionEvent {
    action: Action;
    resource_id?: Resource | string;
    resource_type?: ResourceType;
    action_payload?: string;
    sub_resource_id?: string;
    sub_resource_type?: SubResourceType;
}
