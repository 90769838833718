import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'components/core/constants';
import { RawColors } from 'components/legacyDesignSystem/branding/constants';

export interface ResponsiveValue<T extends number | string> {
    desktop: T;
    mobile?: T;
    tablet?: T;
}


export const getResponsiveValue = <T extends number | string>(value: T | ResponsiveValue<T>): T => {
    if (typeof value === 'object' && Object.keys(value).includes('desktop')) {
        if (window.screen.width < MOBILE_MAX_WIDTH && value.mobile !== undefined
        ) {
            return value.mobile
        } else if (window.screen.width < TABLET_MAX_WIDTH && value.tablet !== undefined) {
            return value.tablet
        } else {
            return value.desktop
        }
    }
    // @ts-ignore
    return value
}

export enum Direction {
    LEFT_RIGHT = 'to right',
    TOP_BOTTOM = 'to bottom'
}

export const getGradient = (from: RawColors, to: RawColors, direction: Direction = Direction.LEFT_RIGHT): {"background": string} => {
    return {
        "background": `linear-gradient(${direction}, ${from}, ${to})`
    }
}