import classNames from "classnames";
import { AlwaysInline, InlineAlignItem, InlineSpacing } from "components/designSystem/containers/Inline";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { cast } from "core/utils/Typed";
import React, { FC, Fragment, ReactNode, useState } from 'react';

import styles from "./Avatar.module.scss"

export enum AvatarType {
    INITIALS = 'initials',
    ICON = 'icon',
    IMAGE = 'image',
    EMPTY = 'empty'
}

export enum AvatarSize {
    LARGE = 'L',
    MEDIUM = 'M',
    SMALL = 'S',
}

interface AbstractPayload {
    type: AvatarType
}

interface InitialsPayload extends AbstractPayload {
    type: AvatarType.INITIALS
    initials: string
}

interface ImgPayload extends AbstractPayload {
    type: AvatarType.IMAGE
    src: string
    alt?: string
    fallbackIcon?: IconName
}

interface IconPayload extends AbstractPayload {
    type: AvatarType.ICON,
    name: IconName
}

interface EmptyPayload extends AbstractPayload {
    type: AvatarType.EMPTY
}

export type AvatarPayload = ImgPayload | InitialsPayload | IconPayload | EmptyPayload;

export interface AvatarProps {
    size?: AvatarSize,
    payload: AvatarPayload
}

const transformPayload = (payload: AbstractPayload) : AvatarPayload | undefined => {
    if (payload.type === AvatarType.IMAGE){
        return cast<ImgPayload>(payload)
    }

    if(payload.type === AvatarType.ICON) {
        return cast<IconPayload>(payload)
    }

    if(payload.type === AvatarType.INITIALS){
        return cast<InitialsPayload>(payload)
    }

    if(payload.type === AvatarType.EMPTY){
        return cast<EmptyPayload>(payload)
    }
}

const Avatar : FC<AvatarProps> = (
    {
        size = AvatarSize.MEDIUM,
        ...props
    }
): JSX.Element => {

    let payload = transformPayload(props.payload);
    if (payload === undefined){
        return <Fragment/>
    }

    const [useIconFallBack, setUseIconFallBack] = useState(false);
    const onErrorLoad: () => void = () => {
        setUseIconFallBack(true)
    };

    if (useIconFallBack && payload.type === AvatarType.IMAGE && payload.fallbackIcon) {
        payload = {
            type: AvatarType.ICON,
            name: payload.fallbackIcon
        }
    }


    const computedClassName = classNames(styles.avatar, {
        [styles.avatarTypeInitials]: payload.type === AvatarType.INITIALS,
        [styles.avatarTypeIcon]: payload.type === AvatarType.ICON,
        [styles.avatarTypeImage]: payload.type === AvatarType.IMAGE,

        [styles.avatarSizeLarge]: size === AvatarSize.LARGE,
        [styles.avatarSizeMedium]: size === AvatarSize.MEDIUM,
        [styles.avatarSizeSmall]: size === AvatarSize.SMALL,
    })

    return <div className={computedClassName}>
        {payload.type === AvatarType.IMAGE && <img src={payload.src} alt={payload.alt ? payload.alt : 'img'} onError={onErrorLoad}/>}
        {payload.type === AvatarType.ICON && <Icon name={payload.name} size={size === AvatarSize.SMALL ? IconSize.EXTRA_SMALL : IconSize.SMALL}/>}
        {payload.type === AvatarType.INITIALS && payload.initials}
    </div>
}

type AvatarGroupProps = AvatarProps & {
    size: AvatarSize
    children?: ReactNode
    others?: ReactNode
}

/**
 * Represents a group entities with the first member's avatar and name displayed and a text indicating for the number
 * of other entities.
 */
export const AvatarGroup = (props: AvatarGroupProps): JSX.Element => <AlwaysInline spacing={InlineSpacing.SMALL} alignItems={InlineAlignItem.CENTER}>
    <Avatar {...props}/>
    {props.children}
    {props.others && <div className={classNames(styles.avatarGroupExtraCount)}>{props.others}</div>}
</AlwaysInline>

export default Avatar;