import React from "react";
import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";


export const Stethoscope = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.EXTRA_SMALL:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        fillRule="evenodd" clipRule="evenodd"
                        d="M3.75 1C3.75 0.585786 3.41421 0.25 3 0.25C2.58579 0.25 2.25 0.585786 2.25 1V1.25H1.72727C0.911398 1.25 0.25 1.9114 0.25 2.72727V6C0.25 8.36935 1.98476 10.3335 4.25344 10.6917C4.3543 13.5024 6.66467 15.75 9.5 15.75C12.3995 15.75 14.75 13.3995 14.75 10.5V9.58159C15.3412 9.30073 15.75 8.6981 15.75 8C15.75 7.0335 14.9665 6.25 14 6.25C13.0335 6.25 12.25 7.0335 12.25 8C12.25 8.6981 12.6588 9.30073 13.25 9.58159V10.5C13.25 12.5711 11.5711 14.25 9.5 14.25C7.49277 14.25 5.85388 12.673 5.75475 10.6904C8.01941 10.3288 9.75 8.3665 9.75 6V2.72727C9.75 1.9114 9.0886 1.25 8.27273 1.25H7.75V1C7.75 0.585786 7.41421 0.25 7 0.25C6.58579 0.25 6.25 0.585786 6.25 1V2V3C6.25 3.41421 6.58579 3.75 7 3.75C7.41421 3.75 7.75 3.41421 7.75 3V2.75H8.25V6C8.25 7.79493 6.79493 9.25 5 9.25C3.20507 9.25 1.75 7.79493 1.75 6V2.75H2.25V3C2.25 3.41421 2.58579 3.75 3 3.75C3.41421 3.75 3.75 3.41421 3.75 3V2V1ZM14.25 8C14.25 8.13807 14.1381 8.25 14 8.25C13.8619 8.25 13.75 8.13807 13.75 8C13.75 7.86193 13.8619 7.75 14 7.75C14.1381 7.75 14.25 7.86193 14.25 8Z"
                        fill="currentColor"
                    />
                </svg>
            )
    }
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd" clipRule="evenodd"
            d="M5.5 1.14282C6.05228 1.14282 6.5 1.59054 6.5 2.14282V5.99996C6.5 6.55225 6.05228 6.99996 5.5 6.99996C4.94772 6.99996 4.5 6.55225 4.5 5.99996V5H3L3 8.96428C3 11.7454 5.25457 14 8.03571 14C10.8169 14 13.0714 11.7454 13.0714 8.96428V5H11.5V5.99996C11.5 6.55225 11.0523 6.99996 10.5 6.99996C9.94771 6.99996 9.5 6.55225 9.5 5.99996V2.14282C9.5 1.59054 9.94771 1.14282 10.5 1.14282C11.0523 1.14282 11.5 1.59054 11.5 2.14282V3H13.0714C14.176 3 15.0714 3.89543 15.0714 5V8.96428C15.0714 12.5229 12.4295 15.4644 9 15.9345V16C9 18.7614 11.2386 21 14 21C16.7614 21 19 18.7614 19 16V14.3293C17.8348 13.9175 17 12.8062 17 11.5C17 9.84315 18.3431 8.5 20 8.5C21.6569 8.5 23 9.84315 23 11.5C23 12.8062 22.1652 13.9175 21 14.3293V16C21 19.866 17.866 23 14 23C10.134 23 7 19.866 7 16V15.9243C3.60533 15.4235 1 12.4981 1 8.96428V5C1 3.89543 1.89543 3 3 3H4.5V2.14282C4.5 1.59054 4.94772 1.14282 5.5 1.14282ZM20 10.5C19.4477 10.5 19 10.9477 19 11.5C19 12.0523 19.4477 12.5 20 12.5C20.5523 12.5 21 12.0523 21 11.5C21 10.9477 20.5523 10.5 20 10.5Z"
            fill="currentColor"
        />
    </svg>
}