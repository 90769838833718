import React, { Component, ReactNode } from 'react';

export enum ArrowDirection {
    UP = 'up',
    DOWN = 'down',
    RIGHT = 'right',
    LEFT = 'left',
}

export enum ArrowStrokeWeight {
    BOLD = 10,
    NORMAL = 1,
    THIN = 0.1,
}

export interface ArrowExpanderProps {
    direction: ArrowDirection;
    strokeWidth?: ArrowStrokeWeight;
}

export default class ArrowExpander extends Component<ArrowExpanderProps> {
    render(): ReactNode {
        const { direction, strokeWidth } = this.props;
        const sw = strokeWidth ? strokeWidth : ArrowStrokeWeight.THIN;
        if (direction === ArrowDirection.DOWN) {
            return (
                <svg viewBox="0 0 16 16" strokeWidth={sw} fill="#4F4F4F">
                    <path
                        stroke="#4F4F4F"
                        d="M14.25 5.25L8.35355 11.1464C8.15829 11.3417 7.84171 11.3417 7.64645 11.1464L1.75 5.25C1.61193 5.11193 1.61193 4.88807 1.75 4.75C1.88807 4.61193 2.11193 4.61193 2.25 4.75L8 10.5L13.75 4.75C13.8881 4.61193 14.1119 4.61193 14.25 4.75C14.3881 4.88807 14.3881 5.11193 14.25 5.25Z"
                    />
                </svg>
            );
        } else if (direction === ArrowDirection.UP) {
            return (
                <svg viewBox="0 0 16 16" strokeWidth={sw} fill="#4F4F4F">
                    <path
                        stroke="#4F4F4F"
                        d="M1.75 10.75L7.64645 4.85355C7.84171 4.65829 8.15829 4.65829 8.35355 4.85355L14.25 10.75C14.3881 10.8881 14.3881 11.1119 14.25 11.25C14.1119 11.3881 13.8881 11.3881 13.75 11.25L8 5.5L2.25 11.25C2.11193 11.3881 1.88807 11.3881 1.75 11.25C1.61193 11.1119 1.61193 10.8881 1.75 10.75Z"
                    />
                </svg>
            );
        } else if (direction === ArrowDirection.RIGHT) {
            return (
                <svg width="100%" height="100%" viewBox="100 0 300 512" fill="#4F4F4F">
                    <path
                        stroke="#4F4F4F"
                        d="M367.454,246.949l-204.799-204.8c-2.418-2.417-5.633-3.749-9.051-3.749s-6.634,1.332-9.051,3.75   c-2.417,2.418-3.749,5.632-3.749,9.051c0,3.419,1.332,6.633,3.749,9.051L340.301,256L144.552,451.75   c-2.417,2.417-3.749,5.632-3.749,9.05c0,3.419,1.332,6.633,3.75,9.052c2.417,2.417,5.632,3.748,9.051,3.748   s6.633-1.331,9.051-3.749l204.799-204.8C372.445,260.061,372.445,251.94,367.454,246.949z"
                    />
                </svg>
            );
        } else {
            return (
                <svg width="100%" height="100%" viewBox="100 0 300 512" fill="#4F4F4F">
                    <path
                        stroke="#4F4F4F"
                        d="M171.699,256L367.448,60.251c2.418-2.418,3.749-5.632,3.749-9.051c0-3.419-1.331-6.633-3.749-9.052   c-2.419-2.417-5.633-3.749-9.051-3.749c-3.419,0-6.632,1.332-9.051,3.75l-204.801,204.8c-4.991,4.991-4.991,13.111,0,18.101   l204.801,204.802c2.418,2.417,5.632,3.748,9.05,3.748s6.632-1.331,9.051-3.749c2.418-2.418,3.749-5.632,3.749-9.051   c0-3.418-1.331-6.633-3.749-9.051L171.699,256z"
                    />
                </svg>
            );
        }
    }
}
