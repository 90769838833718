import TrText from "components/common/TrText";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import Image, { ImageObjectFit, ImageWidth } from "components/designSystem/components/Image";
import { WhiteBoxWithShadow } from "components/designSystem/containers/Box";
import Inline, { AlwaysInline, InlineAlignItem } from "components/designSystem/containers/Inline";
import { SmallSpacer } from "components/designSystem/containers/Spacer";
import { CenteredText } from "components/designSystem/containers/TextJustifier";
import Typography, {
    TBody,
    TDisplayMedium700,
    TDisplayXSmall,
    TypographyColor,
    TypographyVariant
} from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";
import { PaddingSize, WithTopPadding } from "components/designSystem/containers/WithPadding";
import { IconName } from "components/designSystem/foundations/IconsData";
import { useModuleAttributes, useModulePayload } from "components/modules/modular/ModuleContainer";
import { buildClickModuleAction } from "components/modules/modular/modules/builders/userAction";
import styles from "components/modules/modular/modules/common/HIVBannerModule.module.scss";
import { TransDomain } from "components/pages/common/MainComponent";
import { getEntityImg, ImageInfo } from "core/content/cdn";
import LOGGER from "core/logging/Logger";
import { APIText } from "models/common/message";
import { Entity } from "models/entities/default";
import { ExternalLink } from "models/modular/teleconsultation";
import React from 'react';
import { useTranslation } from "react-i18next";

interface HIVBannerText {
    text_0: APIText
    text_1: APIText
    text_2: APIText
}

interface HIVBannerModulePayload {
    title: APIText
    sub_title: APIText
    link: ExternalLink
    text: HIVBannerText
    entity: Entity
    warning?: APIText
}

const TRANS_SUFFIX = TransDomain.MODULES + '.hiv_banner'

export const HIVBannerModule = (): JSX.Element => {
    const payload = useModulePayload<HIVBannerModulePayload>();
    const context = useModuleAttributes();
    const {t} = useTranslation(undefined, {keyPrefix: TRANS_SUFFIX});

    const ribbon: ImageInfo = getEntityImg(payload.entity, 'hiv_banner/ribbon')
    const crossword: ImageInfo = getEntityImg(payload.entity, 'hiv_banner/crosswords')
    const partial_unilabs_logo: ImageInfo = getEntityImg(payload.entity, 'hiv_banner/partial_unilabs_logo', '.svg')
    const background_image = "url(" + partial_unilabs_logo.src + ")"

    return <WhiteBoxWithShadow>
        <div className={styles.hivBanner}>
            <WithTopPadding padding={PaddingSize.LARGE}>
                <WithMargin margins={[undefined, MarginSize.MEDIUM, MarginSize.LARGE, MarginSize.MEDIUM]}>
                    <SmallSpacer>
                        <CenteredText>
                            <TDisplayXSmall color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                <TrText input={payload.title}/>
                            </TDisplayXSmall>
                        </CenteredText>
                        <CenteredText>
                            <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                                <TrText input={payload.sub_title}/>
                            </TBody>
                        </CenteredText>
                    </SmallSpacer>
                </WithMargin>
                <Inline>
                    <div className={styles.texts} style={{backgroundImage: background_image}}>
                        <WithMargin
                            margins={[MarginSize.LARGE_PLUS_MEDIUM, undefined, MarginSize.X_LARGE, MarginSize.X_SMALL]}>
                            <div className={styles.overlap}>
                                <div className={styles.textsRedText}>
                                    <TDisplayMedium700>
                                        <TrText input={payload.text.text_0}/>
                                    </TDisplayMedium700>
                                </div>
                                <AlwaysInline alignItems={InlineAlignItem.CENTER}>
                                    <div className={styles.textsRedBackground}>
                                        <WithMargin margins={[MarginSize.SMALL]}>
                                            <TDisplayMedium700><TrText
                                                input={payload.text.text_1}/></TDisplayMedium700>
                                        </WithMargin>
                                    </div>
                                    <Image alt={ribbon.alt} src={ribbon.src}/>
                                </AlwaysInline>
                                <div className={styles.textsRedText}>
                                    <TDisplayMedium700>
                                        <TrText input={payload.text.text_2}/>
                                    </TDisplayMedium700>
                                </div>
                            </div>
                        </WithMargin>
                    </div>
                    <div className={styles.crossWords}>
                        <WithMargin margins={[MarginSize.LARGE]}>
                            <Image alt={crossword.alt} src={crossword.src} width={ImageWidth.FULL}
                                   withObjectFit={ImageObjectFit.SCALE_DOWN}/>
                        </WithMargin>
                    </div>
                </Inline>
                <div className={styles.button}>
                    <Button size={ButtonSize.LARGE} icons={{right: IconName.EXTERNAL_LINK}}
                            variant={ButtonVariant.SECONDARY} onClick={{
                        href: payload.link.href,
                        targetBlank: payload.link.target_blank,
                        onClick: (): void => LOGGER.userAction(buildClickModuleAction(
                            context.pageId,
                            context.type,
                            undefined
                        ))
                    }}>
                        {t('learn_more')}
                    </Button>
                    {
                        payload.warning &&
                        <Typography
                            variant={TypographyVariant.OVERLINE_SMALL}
                            color={TypographyColor.COLOR_TEXT_SUBDUED}
                        >
                            <TrText input={payload.warning}/>
                        </Typography>
                    }

                </div>
            </WithTopPadding>
        </div>
    </WhiteBoxWithShadow>
}
