import CONFIG, { Env } from 'configuration/globals';
import { ActionEvent, UserActionEvent } from 'core/logging/ActionEvent';
import { getOptions } from 'sagas/api/rest/RequestContent';
import { formatUrl } from 'sagas/api/rest/Url';
import { CallMethod } from "sagas/call/api";

export enum Severity {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    CRITICAL = 'CRITICAL',
    EVENT = 'EVENT',
    USER_ACTION = 'USER_ACTION',
}

export type Log = {
    message: string;
    severity: Severity;
    domain: string | null;
    stacktrace: string | null;
};

export class Logger {
    logs: Log[];

    endpoint: string;

    constructor() {
        this.logs = [];

        this.endpoint = formatUrl(CONFIG.apiUrl, '/monitoring/client/logs');
    }

    info(message: string, domain: string | null = null): void {
        this.log({
            message: message,
            severity: Severity.INFO,
            domain: domain,
            stacktrace: null,
        });
    }

    error(message: string, domain: string | null = null, stacktrace: string | null = null): void {
        this.log({
            message: message,
            severity: Severity.ERROR,
            domain: domain,
            stacktrace: stacktrace,
        });
    }

    warning(message: string, domain: string | null = null): void {
        this.log({
            message: message,
            severity: Severity.WARNING,
            domain: domain,
            stacktrace: null,
        });
    }

    critical(message: string, domain: string | null = null, stacktrace: string | null = null): void {
        this.log({
            message: message,
            severity: Severity.CRITICAL,
            domain: domain,
            stacktrace: stacktrace,
        });
    }

    event(eventName: ActionEvent, value?: string): void {
        this.log({
            message: value || '__NULL__',
            severity: Severity.EVENT,
            domain: eventName,
            stacktrace: null,
        });
    }

    userAction(userAction: UserActionEvent): void {
        this.log({
            message: JSON.stringify(userAction),
            severity: Severity.USER_ACTION,
            domain: userAction.action,
            stacktrace: null,
        });
    }

    log(log: Log): void {
        this.logs.push(log);
    }

    format(logs: Log[]): Log[] {
        const formattedLogs = [];

        for (const log of logs) {
            formattedLogs.push({
                message: log.message,
                domain: log.domain,
                severity: log.severity,
                stacktrace: log.stacktrace,
            });
        }

        return formattedLogs;
    }

    pushLogs(): void {
        if (this.logs.length === 0) {
            return;
        }

        this.push(this.logs);
        this.logs = [];
    }

    push(logs: Log[]): void {
        try {
            fetch(
                this.endpoint,
                getOptions(
                    {
                        logs: this.format(logs),
                        source: 'SPA',
                    },
                    CallMethod.POST,
                ),
            );
        } catch (e) {
            return;
        }
    }

    cleanLogs(): void {
        if (CONFIG.env != Env.TEST) {
            throw Error("This method cannot be called outside of tests")
        }

        this.logs = []
    }

    getLogs(): Log[] {
        if (CONFIG.env != Env.TEST) {
            throw Error("This method cannot be called outside of tests")
        }

        return this.logs
    }


}

const LOGGER = new Logger();
if (CONFIG.env !== Env.TEST) {
    setInterval(function () {
        LOGGER.pushLogs();
    }, 5000);
}

export default LOGGER;
