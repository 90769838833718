import React from "react";
import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Inflammation = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-inflammation'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.6875 8C13.6875 11.1411 11.1411 13.6875 8 13.6875C4.85888 13.6875 2.3125 11.1411 2.3125 8C2.3125 4.85888 4.85888 2.3125 8 2.3125C11.1411 2.3125 13.6875 4.85888 13.6875 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8 11.0625C9.69137 11.0625 11.0625 9.69137 11.0625 8C11.0625 6.30863 9.69137 4.9375 8 4.9375C6.30863 4.9375 4.9375 6.30863 4.9375 8C4.9375 9.69137 6.30863 11.0625 8 11.0625ZM8 12.375C10.4162 12.375 12.375 10.4162 12.375 8C12.375 5.58375 10.4162 3.625 8 3.625C5.58375 3.625 3.625 5.58375 3.625 8C3.625 10.4162 5.58375 12.375 8 12.375ZM8 8.4375C8.24162 8.4375 8.4375 8.24162 8.4375 8C8.4375 7.75838 8.24162 7.5625 8 7.5625C7.75838 7.5625 7.5625 7.75838 7.5625 8C7.5625 8.24162 7.75838 8.4375 8 8.4375ZM8 9.75C8.9665 9.75 9.75 8.9665 9.75 8C9.75 7.0335 8.9665 6.25 8 6.25C7.0335 6.25 6.25 7.0335 6.25 8C6.25 8.9665 7.0335 9.75 8 9.75Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-inflammation'} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M20.3333 12C20.3333 16.6024 16.6024 20.3333 12 20.3333C7.39763 20.3333 3.66667 16.6024 3.66667 12C3.66667 7.39763 7.39763 3.66667 12 3.66667C16.6024 3.66667 20.3333 7.39763 20.3333 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17ZM12 18.6667C15.6819 18.6667 18.6667 15.6819 18.6667 12C18.6667 8.3181 15.6819 5.33333 12 5.33333C8.3181 5.33333 5.33333 8.3181 5.33333 12C5.33333 15.6819 8.3181 18.6667 12 18.6667ZM12 13.6667C12.9205 13.6667 13.6667 12.9205 13.6667 12C13.6667 11.0795 12.9205 10.3333 12 10.3333C11.0795 10.3333 10.3333 11.0795 10.3333 12C10.3333 12.9205 11.0795 13.6667 12 13.6667ZM12 15.3333C13.8409 15.3333 15.3333 13.8409 15.3333 12C15.3333 10.1591 13.8409 8.66667 12 8.66667C10.1591 8.66667 8.66667 10.1591 8.66667 12C8.66667 13.8409 10.1591 15.3333 12 15.3333Z"
                  fill="currentColor"/>
        </svg>
    )
}