import React from 'react';

const Download = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path d="M8.01553 25.982C5.3529 25.982 5.3529 29.9759 8.01553 29.9759H23.9849C26.6476 29.9759 26.6476 25.982 23.9849 25.982H8.01553Z" />
            <path d="M15.9958 2.00023C14.8915 2.0164 14.0093 2.92479 14.0255 4.02915V16.1987L11.4381 13.6133C11.0621 13.2268 10.5459 13.0085 10.0067 13.0079C8.2129 13.0084 7.32685 15.1879 8.61146 16.4399L14.6123 22.4407C15.3931 23.2207 16.6581 23.2207 17.4389 22.4407L23.4388 16.4399C25.3298 14.5529 22.4926 11.7197 20.6082 13.6133L18.0257 16.1958V4.02915C18.0422 2.90145 17.1235 1.98316 15.9958 2.00023Z" />
        </svg>

    )
}

export default Download;
