import TrText from "components/common/TrText";
import { Record } from "immutable";
import { toLocalTransKey } from "models/common/message";
import React, { ReactElement, ReactNode } from "react";

export function translationMethod(
    key: string,
    data?: Record<string, unknown>,
    domain?: string,
    postTrans?: (value: string) => string,
    builder?: (value: string) => ReactNode
): ReactElement {
    return <TrText
        input={toLocalTransKey(key, domain)}
        postTrans={postTrans}
        builder={builder}
    />
}