import React from 'react';

const MoreIcon = (): JSX.Element => {
    return (
        <svg width="32" height="7" viewBox="0 0 32 7" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M-1.57356e-05 3.20163C-1.57356e-05 4.96898 1.4327 6.40329 3.20004 6.40329C4.96738 6.40329 6.40009 4.96898 6.40009 3.20163C6.40009 1.43429 4.96738 -2.07506e-05 3.20004 -2.07506e-05C1.4327 -2.07506e-05 -1.57356e-05 1.43429 -1.57356e-05 3.20163ZM16.0007 6.40329C14.2334 6.40329 12.8006 4.96898 12.8006 3.20163C12.8006 1.43429 14.2334 -2.07506e-05 16.0007 -2.07506e-05C17.768 -2.07506e-05 19.2008 1.43429 19.2008 3.20163C19.2008 4.96898 17.768 6.40329 16.0007 6.40329ZM28.7999 6.40329C27.0326 6.40329 25.5999 4.96898 25.5999 3.20163C25.5999 1.43429 27.0326 -2.07506e-05 28.7999 -2.07506e-05C30.5673 -2.07506e-05 32 1.43429 32 3.20163C32 4.96898 30.5673 6.40329 28.7999 6.40329Z" />
        </svg>


    )
}

export default MoreIcon;
