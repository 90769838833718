import React, { ReactNode } from 'react';

import styles from './RadioButton.module.scss';

export interface RadioButtonProps {
    defaultChecked?: boolean,
    disabled?:boolean,
    label?: ReactNode;
    groupName:string;

    // @ts-ignore
    onChange?: (event) => void;
    overrideChecked?: boolean
}

const RadioButton = (props: RadioButtonProps): JSX.Element => (
    <div className={styles.container}>
        <input
            type={'radio'}
            className={styles.radio}
            defaultChecked={props.defaultChecked}
            checked={props.overrideChecked}
            onChange={props.onChange}
            disabled={props.disabled}
            name={props.groupName}
        />
        {
            props.label && <div className={styles.label}>
                {props.label}
            </div>
        }
    </div>
)

export default RadioButton;
