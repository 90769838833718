import classNames from 'classnames';
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import React, { FC, ReactNode } from 'react';
import styles from "./Button.module.scss"

export enum ButtonVariant {
    PRIMARY = 'P',
    SECONDARY = 'S',
    TERTIARY = 'T',
    QUATERNARY = 'Q',
    LINK = 'L',
    /**
     * Inline clickable link to be included in a text block. If this variant is set, the button size is ignored, it
     * just wraps the content.
     */
    INLINE_LINK = 'I'
}

export enum ButtonSize {
    LARGE = 'L',
    MEDIUM = 'M',
    SMALL = 'S',
    EXTRA_SMALL = 'XS'
}

export enum ButtonSeverity {
    SUCCESS = 'S',
    WARNING = 'W',
    CRITICAL = 'C',
}

export enum ButtonOption {
    LIGHT = 'light',
    DARK = 'dark'
}

export interface ButtonExternalLink {
    href: string,
    targetBlank?: boolean
    onClick?: () => void;
}

export interface ButtonIcons {
    left?: IconName,
    middle?: IconName,
    right?: IconName,
}

export type ButtonClickPayload = (() => void) | ButtonExternalLink;

export enum ButtonTextStyle {
    CAPTION = 'CAPTION',
    LABEL = 'LABEL',
    BODY = 'BODY'
}

export interface ButtonProps {
    variant?: ButtonVariant,
    size?: ButtonSize,
    onClick?: ButtonClickPayload,
    icons?: ButtonIcons,
    severity?: ButtonSeverity,
    fullWidth?: boolean,
    disabled?: boolean,
    isSubmit?: boolean,
    isActive?: boolean,
    id?: string,
    option?: ButtonOption,
    withOverrideTextStyle?: ButtonTextStyle
    round?: boolean
}

export function formatButton(
    onClick: ButtonClickPayload | undefined,
    content: ReactNode,
    classNames_: string | undefined = undefined,
    disabled: boolean | undefined = undefined,
    isSubmit: boolean | undefined = undefined,
    id: string | undefined = undefined,
    useDiv = false
): JSX.Element {
    if (onClick && !(typeof onClick === 'function')) {
        if (disabled) {
            return <button
                id={id}
                className={classNames_}
                disabled={disabled}
            >
                {content}
            </button>
        }

        return <a
            id={id}
            href={onClick.href}
            target={onClick.targetBlank ? '_blank' : undefined}
            rel={"noreferrer"}
            className={classNames_}
            onClick={onClick.onClick}
        >
            {content}
        </a>
    }

    if (useDiv) {
        return <div
            id={id}
            className={classNames_}
            onClick={onClick}
        >
            {content}
        </div>
    }

    return <button
        id={id}
        onClick={onClick}
        className={classNames_}
        disabled={disabled}
        type={isSubmit ? 'submit' : undefined}
    >
        {content}
    </button>

}

const Button: FC<ButtonProps> = (
    {
        variant = ButtonVariant.PRIMARY,
        size = ButtonSize.MEDIUM,
        ...props
    }
): JSX.Element => {
    const isLink = variant === ButtonVariant.LINK || variant === ButtonVariant.INLINE_LINK
    const isNotInline = variant != ButtonVariant.INLINE_LINK;
    const computedClassNames = classNames(
        styles.button,
        {
            [styles.buttonVariantPrimary]: variant === ButtonVariant.PRIMARY,
            [styles.buttonVariantPrimaryForceActive]: variant === ButtonVariant.PRIMARY && props.isActive,
            [styles.buttonVariantSecondary]: variant === ButtonVariant.SECONDARY,
            [styles.buttonVariantSecondaryForceActive]: variant === ButtonVariant.SECONDARY && props.isActive,
            [styles.buttonVariantTertiary]: variant === ButtonVariant.TERTIARY,
            [styles.buttonVariantTertiaryForceActive]: variant === ButtonVariant.TERTIARY && props.isActive,
            [styles.buttonVariantQuaternary]: variant === ButtonVariant.QUATERNARY,
            [styles.buttonVariantQuaternaryForceActive]: variant === ButtonVariant.QUATERNARY && props.isActive,
            [styles.buttonVariantLink]: isLink,
            [styles.buttonVariantLinkForceActive]: isLink && props.isActive,
            [styles.buttonVariantInlineLink]: variant === ButtonVariant.INLINE_LINK,

            [styles.buttonSizeLarge]: size === ButtonSize.LARGE && isNotInline,
            [styles.buttonSizeLargeWithContent]: size === ButtonSize.LARGE && props.children && isNotInline,
            [styles.buttonSizeMedium]: size === ButtonSize.MEDIUM && isNotInline,
            [styles.buttonSizeMediumWithContent]: size === ButtonSize.MEDIUM && props.children && isNotInline,
            [styles.buttonSizeSmall]: size === ButtonSize.SMALL && isNotInline,
            [styles.buttonSizeSmallWithContent]: size === ButtonSize.SMALL && props.children && isNotInline,
            [styles.buttonSizeExtraSmall]: size === ButtonSize.EXTRA_SMALL && isNotInline,

            [styles.buttonFullWidth]: props.fullWidth,

            [styles.severityCritical]: props.severity === ButtonSeverity.CRITICAL,
            [styles.severityWarning]: props.severity === ButtonSeverity.WARNING,
            [styles.severitySuccess]: props.severity === ButtonSeverity.SUCCESS,

            [styles.optionLight]: props.option === ButtonOption.LIGHT,
            [styles.optionDark]: props.option === ButtonOption.DARK,

            [styles.buttonWithOverrideTextStyleCaption]: props.withOverrideTextStyle === ButtonTextStyle.CAPTION || (
                !props.withOverrideTextStyle && isLink
            ),
            [styles.buttonWithOverrideTextStyleLabel]: props.withOverrideTextStyle === ButtonTextStyle.LABEL,
            [styles.buttonWithOverrideTextStyleBody]: props.withOverrideTextStyle === ButtonTextStyle.BODY,

            [styles.buttonRound]: props.round,
        }
    )

    const content = variant === ButtonVariant.INLINE_LINK ? <> {
        props.children && <span className={styles.component}>{props.children}</span>
    } </> : <>
        {
            props.icons && props.icons.left &&
            <div className={classNames(styles.component, styles.icon)}>
                <Icon name={props.icons.left} size={IconSize.EXTRA_SMALL}/>
            </div>
        }
        {
            props.icons && props.icons.middle &&
            <div className={classNames(styles.component, styles.icon)}>
                <Icon name={props.icons.middle} size={IconSize.EXTRA_SMALL}/>
            </div>
        }
        {
            props.children &&
            <div className={variant === ButtonVariant.LINK ? undefined : styles.component}>
                {
                    props.children
                }
            </div>
        }
        {
            props.icons && props.icons.right &&
            <div className={classNames(styles.component, styles.icon)}>
                <Icon name={props.icons.right} size={IconSize.EXTRA_SMALL}/>
            </div>
        }
    </>

    return formatButton(
        props.onClick,
        content,
        computedClassNames,
        props.disabled,
        props.isSubmit,
        props.id,
    )

}
export default Button;
