export function getApiTransKey(key_or_str: string): string | null {
    return  key_or_str.substr(0, 1) === ":" ? key_or_str.substr(1) : null
}


export function apiKeyFormat<T>(
    content: string,
    entity: T,
    builders: {[key: string]: (entity: T) => JSX.Element | JSX.Element[] | string},
    default_builder: (entity: T, key: string | null) => JSX.Element | JSX.Element[] | string
): JSX.Element | JSX.Element[] | string {
    const apiKey = getApiTransKey(content);
    return  apiKey !== null && apiKey in builders ? builders[apiKey](entity) : default_builder(entity, apiKey);
}