import classNames from "classnames";
import Button, { ButtonClickPayload } from "components/designSystem/components/Button";
import { cast } from "core/utils/Typed";
import React, { FC, ReactNode } from 'react';
import styles from 'components/designSystem/components/EmptyState.module.scss';
import Icon from 'components/designSystem/foundations/Icons';
import { TBody, TDisplaySmall500, TypographyColor } from 'components/designSystem/containers/Typography';
import { SmallSpacer, XLargeSpacer } from 'components/designSystem/containers/Spacer';
import { CenteredText } from 'components/designSystem/containers/TextJustifier';
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import Image from "components/designSystem/components/Image";
import Centered from "components/designSystem/containers/Centered";

export enum MaxWidthSize {
    WIDTH_25 = 'WIDTH_25',
    WIDTH_40 = 'WIDTH_40',
    WIDTH_50 = 'WIDTH_50'
}

export enum EmptyStateType {
    ILLUSTRATION = 'ILLUSTRATION',
    ICON = 'ICON'
}

interface AbstractEmptyStatePayload {
    type: EmptyStateType
}

interface ImagePayload extends AbstractEmptyStatePayload{
    type: EmptyStateType.ILLUSTRATION,
    src: string;
    alt: string;
}

interface IconPayload extends AbstractEmptyStatePayload {
    type: EmptyStateType.ICON,
    name: IconName;
}

export interface ActionPayload {
    name: ReactNode,
    onClick: ButtonClickPayload,
    fullWidth?: boolean,
    disabled?: boolean
}

type EntityPayload = ImagePayload | IconPayload

interface EmptyStateProps {
    title?: ReactNode;
    description?: ReactNode;
    payload: EntityPayload;
    actionPayload?: ActionPayload;
    maxWidth?: MaxWidthSize;
}

const transformPayload = (payload: AbstractEmptyStatePayload) : EntityPayload | undefined => {
    if (payload.type === EmptyStateType.ILLUSTRATION){
        return cast<ImagePayload>(payload)
    }

    if(payload.type === EmptyStateType.ICON) {
        return cast<IconPayload>(payload)
    }
}

const EmptyState : FC<EmptyStateProps> = (
    {
        maxWidth = MaxWidthSize.WIDTH_25,
        ...props
    }):JSX.Element => {

    const payload = transformPayload(props.payload)

    return <div className={classNames(styles.container, {
        [styles.containerMaxWidthWITDH_25]: maxWidth === MaxWidthSize.WIDTH_25,
        [styles.containerMaxWidthWITDH_40]: maxWidth === MaxWidthSize.WIDTH_40,
        [styles.containerMaxWidthWITDH_50]: maxWidth === MaxWidthSize.WIDTH_50,
    })}>
        <XLargeSpacer>
            {
                payload && payload.type === EmptyStateType.ICON && <Centered>
                    <Icon name={payload.name} color={IconColor.DEFAULT} size={IconSize.EXTRA_EXTRA_LARGE}/>
                </Centered>
            }
            {
                payload && payload.type === EmptyStateType.ILLUSTRATION && <Centered>
                    <Image src={payload.src} alt={payload.alt} />
                </Centered>
            }
            <SmallSpacer>
                {
                    props.title && <CenteredText>
                        <TDisplaySmall500 color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.title}</TDisplaySmall500>
                    </CenteredText>
                }
                {
                    props.description && <CenteredText>
                        <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            {props.description}
                        </TBody>
                    </CenteredText>
                }
            </SmallSpacer>
            {
                props.actionPayload && <Centered>
                    <Button
                        onClick={props.actionPayload.onClick}
                        fullWidth={!!props.actionPayload.fullWidth}
                        disabled={props.actionPayload.disabled}
                    >
                        {props.actionPayload.name}
                    </Button>
                </Centered>
            }
        </XLargeSpacer>
    </div>
}

export default EmptyState;