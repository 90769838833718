import { Component } from 'react';
import { TransDomain } from 'components/pages/common/MainComponent';
import { withTranslation, WithTranslation } from 'react-i18next';
import { formatDate, formatDateTime } from 'core/utils/Date';
import i18n from 'i18next';


interface TrDateProps {
    date: Date | number | string,
    withTime?: boolean
}

export function getDate(date: Date | number | string, withTime = false): string {
    return (withTime ? formatDateTime : formatDate)(
        date,
        undefined,
        i18n.language
    )
}

class TrDate extends Component<TrDateProps & WithTranslation> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;
    render(): string {
        return (this.props.withTime ? formatDateTime : formatDate)(
            this.props.date,
            undefined,
            this.props.i18n ? this.props.i18n.language : 'fr'
        )
    }

}

export default withTranslation()(TrDate);
