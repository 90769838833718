import React, { Component, Fragment, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { reducer } from 'reducers/selector';
import Page500 from 'components/pages/errors/Page500';
import { REDUCER_GLOBAL } from 'reducers/allReducers';
import { connect } from 'react-redux';
import PopUp, { PopUpStyle } from 'components/core/containers/PopUp';
import { closePopUp } from 'actions/common/CommonActions';
import './BaseComponent.scss';
import APIFeedbackMessageContainer, { DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE, } from 'components/pages/common/APIFeedbackMessage';
import { APIFeedbackMessage, APIFeedbackMessageType } from 'models/common/APIMessaging';
import { ActionDispatcher } from 'actions/ActionInterface';
import { AnyState } from 'core/store/Store';

export interface BaseComponentStateProps {
    popupContent?: ReactElement | string;
    popupStyle?: PopUpStyle;
    popupTitle?: string;
    popUpDisableStdClose?: boolean;
    popupLocationPathname?: string;
    currentLocationPathname: string;
}

export interface BaseComponentDispatchProps {
    closePopUp: () => void;
}

class BaseComponent extends Component<PropsWithChildren<BaseComponentStateProps & BaseComponentDispatchProps>> {
    render(): ReactNode {
        return (
            <Fragment>
                {this.props.popupContent && this.props.currentLocationPathname === this.props.popupLocationPathname && (
                    <PopUp
                        onClose={this.props.closePopUp}
                        style={this.props.popupStyle}
                        title={this.props.popupTitle}
                        disableStdClose={this.props.popUpDisableStdClose}
                    >
                        {this.props.popupContent}
                    </PopUp>
                )}
                <APIFeedbackMessageContainer
                    identifier={DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE}
                    message_renderer={(message?: APIFeedbackMessage): ReactNode =>
                        message && message.type && message.type > APIFeedbackMessageType.WARNING ? (
                            <Page500 message={message.message} />
                        ) : <div className={'base-component'}>{this.props.children}</div>
                    }
                />
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): BaseComponentDispatchProps => ({
    closePopUp: (): void => dispatch(closePopUp()),
});

const mapStateToProps = (state: AnyState): BaseComponentStateProps => ({
    popupContent: reducer(state, REDUCER_GLOBAL).popupContent,
    popupStyle: reducer(state, REDUCER_GLOBAL).popupStyle,
    popupTitle: reducer(state, REDUCER_GLOBAL).popupTitle,
    popUpDisableStdClose: reducer(state, REDUCER_GLOBAL).popUpDisableStdClose,
    popupLocationPathname: reducer(state, REDUCER_GLOBAL).popupPathname,
    currentLocationPathname: state.router.location.pathname,
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseComponent);
