import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Kidneys = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-kidneys'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.35935 4.81189L4.86687 3.54308C5.06672 3.04347 4.69877 2.5 4.16068 2.5C3.17464 2.5 2.27578 3.11142 1.90823 4.03795C1.40106 5.31644 1.40267 6.74968 1.91501 8.01986L2.18682 8.69371C2.38335 9.18094 2.85608 9.5 3.38145 9.5C4.29278 9.5 4.91594 8.57958 4.57748 7.73343L4.35935 7.18811C4.05427 6.42541 4.05427 5.57459 4.35935 4.81189ZM4.16068 1C2.55436 1 1.10624 1.99172 0.51393 3.48484C-0.134203 5.11867 -0.133599 6.95089 0.523912 8.58097L0.795719 9.25482C1.2211 10.3094 2.2443 11 3.38145 11C5.35398 11 6.70277 9.00779 5.97019 7.17634L5.79393 6.7357L6.24968 6.96357V14C6.24968 14.4143 6.58546 14.75 6.99968 14.75C7.41389 14.75 7.74968 14.4143 7.74968 14V6.50004C7.74968 6.21596 7.58917 5.95627 7.33509 5.82922L5.86249 5.09292L6.25959 4.10016C6.85355 2.61526 5.75997 1 4.16068 1ZM11.6402 4.81189L11.1326 3.54308C10.9328 3.04347 11.3007 2.5 11.8388 2.5C12.8249 2.5 13.7237 3.11142 14.0913 4.03795C14.5985 5.31644 14.5968 6.74968 14.0845 8.01986L13.8127 8.69371C13.6162 9.18094 13.1434 9.5 12.6181 9.5C11.7067 9.5 11.0836 8.57958 11.422 7.73343L11.6402 7.18811C11.9452 6.42541 11.9452 5.57459 11.6402 4.81189ZM11.8388 1C13.4452 1 14.8933 1.99172 15.4856 3.48484C16.1337 5.11867 16.1331 6.95089 15.4756 8.58097L15.2038 9.25482C14.7784 10.3094 13.7552 11 12.6181 11C10.6455 11 9.29674 9.00779 10.0293 7.17634L10.2056 6.7356L9.74968 6.96357V14C9.74968 14.4143 9.41389 14.75 8.99968 14.75C8.58546 14.75 8.24968 14.4143 8.24968 14V6.50004C8.24968 6.21596 8.41018 5.95627 8.66427 5.82922L10.137 5.09286L9.73992 4.10016C9.14596 2.61526 10.2395 1 11.8388 1Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-kidneys'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.23206 7.394L6.96955 5.51677C7.25579 4.78815 6.71863 4 5.93581 4C4.53776 4 3.28294 4.8578 2.77541 6.16047L2.73484 6.26459C2.03675 8.05636 2.04239 10.0459 2.75063 11.8337L3.14761 12.8357C3.42601 13.5385 4.10526 14 4.86114 14C6.1602 14 7.0516 12.6921 6.57659 11.483L6.23206 10.606C5.82653 9.57373 5.82653 8.42627 6.23206 7.394ZM5.93581 2C3.71339 2 1.71866 3.36361 0.911854 5.43441L0.871286 5.53853C-0.0101528 7.80089 -0.00302941 10.313 0.891226 12.5703L1.28821 13.5724C1.8687 15.0377 3.28503 16 4.86114 16C7.56986 16 9.42855 13.2728 8.4381 10.7517L8.33946 10.5006L9.00003 10.7208V20.5C9.00003 21.0523 9.44774 21.5 10 21.5C10.5523 21.5 11 21.0523 11 20.5V10C11 9.5696 10.7246 9.18747 10.3163 9.05135L8.03538 8.29105C8.05268 8.23529 8.07208 8.18 8.09357 8.12531L8.83105 6.24807C9.63274 4.20742 8.12829 2 5.93581 2ZM17.7679 7.394L17.0305 5.51677C16.7442 4.78815 17.2814 4 18.0642 4C19.4622 4 20.7171 4.8578 21.2246 6.16047L21.2652 6.26459C21.9632 8.05636 21.9576 10.0459 21.2494 11.8337L20.8524 12.8357C20.574 13.5385 19.8947 14 19.1389 14C17.8398 14 16.9484 12.6921 17.4234 11.483L17.7679 10.606C18.1735 9.57373 18.1735 8.42627 17.7679 7.394ZM18.0642 2C20.2866 2 22.2813 3.36361 23.0881 5.43441L23.1287 5.53853C24.0102 7.80089 24.003 10.313 23.1088 12.5703L22.7118 13.5724C22.1313 15.0377 20.715 16 19.1389 16C16.4301 16 14.5715 13.2728 15.5619 10.7517L15.7014 10.3965L15 10.6771V20.5C15 21.0523 14.5523 21.5 14 21.5C13.4477 21.5 13 21.0523 13 20.5V10C13 9.59113 13.249 9.22342 13.6286 9.07156L15.9182 8.15575C15.9143 8.14558 15.9104 8.13543 15.9064 8.12531L15.1689 6.24807C14.3673 4.20742 15.8717 2 18.0642 2Z"
                  fill="currentColor"/>
        </svg>
    )
}