import React from 'react';

const Pancreas = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M16.7365 18.3509C15.7624 18.8344 14.6757 19.101 13.5523 19.101C9.59303 19.101 6.37193 15.8799 6.37193 11.9206C6.37193 10.2561 6.94134 8.72224 7.89536 7.50319V7.02528C7.89536 6.60461 7.55432 6.26357 7.13364 6.26357H3.76172C3.34105 6.26357 3 6.60461 3 7.02528V11.9206C3 16.9241 7.07616 20.8411 11.9206 20.8411H13.5523V24.9747C13.5523 25.3954 13.8934 25.7365 14.3141 25.7365H17.6318C18.0525 25.7365 18.3935 25.3954 18.3935 24.9747V21.6029C18.3935 20.2681 17.7399 19.0837 16.7365 18.3509Z" />
            <path d="M28.2382 6.26357H13.5522C10.433 6.26357 7.89526 8.80131 7.89526 11.9206C7.89526 15.0399 10.433 17.5776 13.5522 17.5776C15.7608 17.5776 17.7577 16.2866 18.6802 14.314H21.7111C25.7302 14.3141 28.9999 11.0443 28.9999 7.02528C28.9999 6.60456 28.6589 6.26357 28.2382 6.26357Z" />
        </svg>
    )
}
export default Pancreas;
