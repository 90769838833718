import { IconName } from "components/designSystem/foundations/IconsData";


export enum CategoryIconNames {
    BURGER = 'BURGER',
    CAKE = 'CAKE',
    DROP = 'DROP',
    FRUITS = 'FRUITS',
    IRON = 'IRON',
    KIDNEY = 'KIDNEY',
    LIVER = 'LIVER',
    MAGNIFYING_GLASS = 'M_GLASS',
    MOLECULE = 'MOLECULE',
    TARGET = 'TARGET',
    HEART = 'HEART',
    ION = 'ION',
    COAGULATION = 'COAGULATION',
    ABO = 'ABO',
    TEST_TUBE = 'TEST_TUBE',
}


const ICON_MAP: Record<CategoryIconNames, IconName> = {
    [CategoryIconNames.BURGER]: IconName.BURGER,
    [CategoryIconNames.CAKE]: IconName.CAKE,
    [CategoryIconNames.DROP]: IconName.BLOOD,
    [CategoryIconNames.FRUITS]: IconName.APPLE,
    [CategoryIconNames.IRON]: IconName.PROTEIN,
    [CategoryIconNames.KIDNEY]: IconName.KIDNEYS,
    [CategoryIconNames.LIVER]: IconName.LIVER,
    [CategoryIconNames.TARGET]: IconName.INFLAMMATION,
    [CategoryIconNames.HEART]: IconName.HEART,
    [CategoryIconNames.ION]: IconName.IONS,
    [CategoryIconNames.COAGULATION]: IconName.BLOOD,
    [CategoryIconNames.ABO]: IconName.ANTIBODIES,
    [CategoryIconNames.TEST_TUBE]: IconName.TUBE,
    [CategoryIconNames.MOLECULE]: IconName.TUBE,
    [CategoryIconNames.MAGNIFYING_GLASS]: IconName.TUBE,
}

export function getIconByName(iconName?: string): IconName | undefined {
    if (!iconName) {
        return undefined
    }

    // for legacy category Icons
    if (Object.keys(ICON_MAP).includes(iconName)) {
        // @ts-ignore
        return ICON_MAP[iconName]
    }

    if (Object.keys(IconName).includes(iconName)) {
        // @ts-ignore
        return IconName[iconName]
    }

    return undefined
}
