import { KiroCommunicationType } from "components/modules/user/KiroCommunication";
import { LandingScreen } from 'components/modules/user/LandingScreen'
import LoginForm from "components/modules/user/LoginForm";
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import CONFIG, { PlatformType } from "configuration/globals";
import { capitalizeFirstLetter } from 'core/utils/text';
import React from 'react';


export class Login extends WithTranslations<{}> {
    TRANS_SUFFIX = TransDomain.ACCESS

    render(): JSX.Element {
        return <LandingScreen
            communicationType={CONFIG.platform === PlatformType.EVIDENCE ? KiroCommunicationType.RWE : KiroCommunicationType.PATIENT}>
            <LoginForm
                title={this.trans('login', undefined, undefined, capitalizeFirstLetter)}
                withPersist
                withProfessionalHealthConnection
            />
        </LandingScreen>
    }
}
