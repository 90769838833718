import React from 'react';

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const FillStar = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.EXTRA_SMALL:
            return (
                <svg id={'xs-fill-star'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_121_469)">
                        <path
                            d="M8.67894 0.6813C8.55537 0.418078 8.2908 0.25 8.00002 0.25C7.70924 0.25 7.44466 0.418078 7.3211 0.6813L5.32797 4.92721L0.886084 5.60991C0.608311 5.65261 0.377715 5.84701 0.288666 6.11356C0.199616 6.38012 0.267057 6.67408 0.463391 6.87517L3.69884 10.1889L2.9338 14.8793C2.88756 15.1628 3.00729 15.4477 3.24211 15.6132C3.47694 15.7786 3.7856 15.7953 4.03696 15.6563L8.00002 13.4648L11.9631 15.6563C12.2144 15.7953 12.5231 15.7786 12.7579 15.6132C12.9927 15.4477 13.1125 15.1628 13.0662 14.8793L12.3012 10.1889L15.5366 6.87517C15.733 6.67408 15.8004 6.38012 15.7114 6.11356C15.6223 5.84701 15.3917 5.65261 15.114 5.60991L10.6721 4.92721L8.67894 0.6813Z"
                            fill="currentColor"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_121_469">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            )
        case IconSize.MEDIUM:
            return (
                <svg id={'m-fill-star'} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_317_118)">
                        <path
                            d="M17.1316 1.46883C16.9256 1.03013 16.4847 0.75 16 0.75C15.5154 0.75 15.0744 1.03013 14.8685 1.46883L10.8256 10.0813L1.81014 11.4669C1.34719 11.5381 0.962859 11.8621 0.814443 12.3063C0.666026 12.7506 0.778429 13.2405 1.10565 13.5757L7.66473 20.2934L6.11433 29.7988C6.03727 30.2712 6.23682 30.7462 6.62819 31.0219C7.01956 31.2976 7.534 31.3256 7.95294 31.0939L16 26.644L24.0471 31.0939C24.4661 31.3256 24.9805 31.2976 25.3719 31.0219C25.7632 30.7462 25.9628 30.2712 25.8857 29.7988L24.3353 20.2934L30.8944 13.5757C31.2216 13.2405 31.334 12.7506 31.1856 12.3063C31.0372 11.8621 30.6529 11.5381 30.1899 11.4669L21.1744 10.0813L17.1316 1.46883Z"
                            fill="currentColor"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_317_118">
                            <rect width="32" height="32" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            )
        case IconSize.EXTRA_EXTRA_LARGE:
            return (
                <svg id={'xl-fill-star'}  width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_121_467)">
                        <path
                            d="M25.8105 1.15013C25.481 0.448209 24.7755 0 24 0C23.2246 0 22.5191 0.448209 22.1896 1.15013L15.8446 14.6668L1.69623 16.8413C0.955497 16.9551 0.340575 17.4735 0.103108 18.1844C-0.134358 18.8952 0.0454862 19.6791 0.569042 20.2153L10.8636 30.7588L8.43014 45.678C8.30683 46.434 8.62611 47.194 9.2523 47.6351C9.8785 48.0762 10.7016 48.1209 11.3719 47.7502L24 40.767L36.6282 47.7502C37.2985 48.1209 38.1216 48.0762 38.7478 47.6351C39.374 47.194 39.6933 46.434 39.57 45.678L37.1365 30.7588L47.4311 20.2153C47.9546 19.6791 48.1345 18.8952 47.897 18.1844C47.6595 17.4735 47.0446 16.9551 46.3039 16.8413L32.1555 14.6668L25.8105 1.15013Z"
                            fill="currentColor"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_121_467">
                            <rect width="48" height="48" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            )
        default:
            return (
                <svg id={'s-fill-star'} width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_121_468)">
                        <path
                            d="M12.9052 0.575066C12.7405 0.224105 12.3877 0 12 0C11.6123 0 11.2596 0.224105 11.0948 0.575066L7.92229 7.33338L0.848113 8.42065C0.477749 8.47757 0.170287 8.73677 0.0515541 9.09218C-0.0671791 9.44758 0.0227431 9.83954 0.284521 10.1077L5.43179 15.3794L4.21507 22.839C4.15342 23.217 4.31305 23.597 4.62615 23.8175C4.93925 24.0381 5.3508 24.0604 5.68595 23.8751L12 20.3835L18.3141 23.8751C18.6492 24.0604 19.0608 24.0381 19.3739 23.8175C19.687 23.597 19.8466 23.217 19.785 22.839L18.5683 15.3794L23.7155 10.1077C23.9773 9.83954 24.0672 9.44758 23.9485 9.09218C23.8298 8.73677 23.5223 8.47757 23.1519 8.42065L16.0778 7.33338L12.9052 0.575066Z"
                            fill="currentColor"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_121_468">
                            <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            )
    }
}

