import TrText from "components/common/TrText";
import { MOBILE_MAX_WIDTH, SUBSCRIPTION_MODULE_BREAKPOINT } from "components/core/constants";
import { CenteredColumn } from "components/designSystem/Aliases";
import { WhiteBoxWithShadow } from "components/designSystem/containers/Box";
import { ColumnWidthInREM } from "components/designSystem/containers/Column";
import { SmallSpacer, XLargeSpacer } from "components/designSystem/containers/Spacer";
import { CenteredText } from "components/designSystem/containers/TextJustifier";
import { TBody, TDisplayXSmall, TypographyColor } from "components/designSystem/containers/Typography";
import WithAbsolutePosition, {
    WithAbsolutePositionAlignmentY
} from "components/designSystem/containers/WithAbsolutePosition";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";
import { PaddingSize, WithHorizontalPadding } from "components/designSystem/containers/WithPadding";
import { SubscriptionModuleIllustration } from "components/designSystem/illustrations/SubscriptionModule";
import BaseModule, { setupModule } from 'components/modules/modular/BaseModule';
import CreateAccountForm from "components/modules/user/CreateAccountForm";
import { TransDomain } from "components/pages/common/MainComponent";
import { buildOnSuccessAction } from "components/utils/builders/Actions";
import { cast } from 'core/utils/Typed';
import { APIFormItemWithPayload, FormItemWithPayload } from "models/common/DynamicFormModels";
import { APIText } from "models/common/message";
import { OnSuccessPayload, SubscriptionPayload } from "models/medicalReport/ConnectionModesModel";
import { ModuleData } from "models/modular/storage";
import React, { Fragment, ReactNode } from 'react';
import MediaQuery from "react-responsive";

interface SubscriptionModulePayload {
    title: APIText
    description: APIText
    data: SubscriptionPayload
    on_success: OnSuccessPayload
}

class SubscriptionModule extends BaseModule<{}, SubscriptionModulePayload, {}> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    buildFormItemWithPayload = (items: APIFormItemWithPayload[]): FormItemWithPayload[] => (
        items.map((item: APIFormItemWithPayload) => {
            const label: APIText | undefined = item.payload.label
            return {
                ...item,
                payload: {
                    ...item.payload,
                    label: label
                        ? cast<string>(this.transApiText(label))
                        : undefined
                }
            }
        })
    )

    buildContent = (
        isMobile: boolean,
        withImage: boolean,
        subscriptionPayload: SubscriptionPayload,
        on_success: OnSuccessPayload,
        title: APIText,
        description: APIText
    ): JSX.Element => {
        const onSuccessAction = buildOnSuccessAction(on_success, true)
        const createAccountForm = <CreateAccountForm
            token={subscriptionPayload.token}
            thenPut={onSuccessAction ? [onSuccessAction] : undefined}
            items={subscriptionPayload.form_data.items}
            eulaValidations={subscriptionPayload.form_data.eula_validations}
        />

        return <WithMargin margins={[MarginSize.X_LARGE, undefined]}>
            {!(isMobile || !withImage) &&
            <WithAbsolutePosition alignmentY={WithAbsolutePositionAlignmentY.CENTER}>
                <SubscriptionModuleIllustration/>
            </WithAbsolutePosition>}
            <XLargeSpacer>
                <SmallSpacer>
                    <CenteredText>
                        <TDisplayXSmall color={TypographyColor.COLOR_TEXT_DEFAULT}>
                            <TrText input={title}/>
                        </TDisplayXSmall>
                    </CenteredText>
                    <CenteredText>
                        <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            <TrText input={description}/>
                        </TBody>
                    </CenteredText>
                </SmallSpacer>
                {!isMobile ? <CenteredColumn widthInRem={ColumnWidthInREM.WIDTH_28}>
                    {createAccountForm}
                </CenteredColumn> : <WithHorizontalPadding padding={PaddingSize.MEDIUM}>
                    {createAccountForm}
                </WithHorizontalPadding>}
            </XLargeSpacer>
        </WithMargin>
    }

    _render(payload: ModuleData<SubscriptionModulePayload, {}>): ReactNode {
        const subscriptionPayload = payload.pagePayload.data

        if (subscriptionPayload === undefined) {
            return <Fragment/>
        }

        return <WhiteBoxWithShadow>
            <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
                {this.buildContent(
                    true,
                    false,
                    subscriptionPayload,
                    payload.pagePayload.on_success,
                    payload.pagePayload.title,
                    payload.pagePayload.description
                )}
            </MediaQuery>
            <MediaQuery minWidth={MOBILE_MAX_WIDTH + 1} maxWidth={SUBSCRIPTION_MODULE_BREAKPOINT}>
                {this.buildContent(
                    false,
                    false,
                    subscriptionPayload,
                    payload.pagePayload.on_success,
                    payload.pagePayload.title,
                    payload.pagePayload.description
                )}
            </MediaQuery>
            <MediaQuery minWidth={SUBSCRIPTION_MODULE_BREAKPOINT + 1}>
                {this.buildContent(
                    false,
                    true,
                    subscriptionPayload,
                    payload.pagePayload.on_success,
                    payload.pagePayload.title,
                    payload.pagePayload.description
                )}
            </MediaQuery>
        </WhiteBoxWithShadow>
    }
}

export default setupModule(SubscriptionModule, () => ({}), () => ({}));
