import classNames from "classnames";
import { Allergy } from 'components/designSystem/foundations/icons/Allergy';
import { Antibodies } from "components/designSystem/foundations/icons/Antibodies";
import { Apple } from "components/designSystem/foundations/icons/Apple";
import { ArrowDown } from "components/designSystem/foundations/icons/ArrowDown";
import { ArrowLeft } from "components/designSystem/foundations/icons/ArrowLeft";
import { ArrowRight } from "components/designSystem/foundations/icons/ArrowRight";
import { ArrowRightDown } from "components/designSystem/foundations/icons/ArrowRightDown";
import { ArrowRightUp } from "components/designSystem/foundations/icons/ArrowRightUp";
import { ArrowUp } from "components/designSystem/foundations/icons/ArrowUp";
import { Article } from "components/designSystem/foundations/icons/Article";
import { Bacteria } from "components/designSystem/foundations/icons/Bacteria"
import { Bell } from "components/designSystem/foundations/icons/Bell"
import { Blood } from "components/designSystem/foundations/icons/Blood"
import { Bones } from "components/designSystem/foundations/icons/Bones"
import { Brain } from "components/designSystem/foundations/icons/Brain"
import { Burger } from "components/designSystem/foundations/icons/Burger"
import { BurgerMenu } from "components/designSystem/foundations/icons/BurgerMenu"
import { Cake } from "components/designSystem/foundations/icons/Cake"
import { Calendar } from "components/designSystem/foundations/icons/Calendar"
import { Cells } from "components/designSystem/foundations/icons/Cells"
import { Chart } from "components/designSystem/foundations/icons/Chart"
import { CheckMark } from "components/designSystem/foundations/icons/CheckMark"
import { ChevronDown } from "components/designSystem/foundations/icons/ChevronDown"
import { ChevronLeft } from "components/designSystem/foundations/icons/ChevronLeft"
import { ChevronRight } from "components/designSystem/foundations/icons/ChevronRight"
import { ChevronUp } from "components/designSystem/foundations/icons/ChevronUp"
import { Close } from "components/designSystem/foundations/icons/Close"
import { Dashboard } from "components/designSystem/foundations/icons/Dashboard"
import { DashBoardSquare } from "components/designSystem/foundations/icons/DashboardSquare"
import { Divider } from "components/designSystem/foundations/icons/Divider"
import { Download } from "components/designSystem/foundations/icons/Download"
import { Drugs } from "components/designSystem/foundations/icons/Drugs"
import { Equal } from 'components/designSystem/foundations/icons/Equal';
import { Expand } from "components/designSystem/foundations/icons/Expand";
import { ExternalLink } from "components/designSystem/foundations/icons/ExternalLink";
import { Eye } from "components/designSystem/foundations/icons/Eye"
import { EyeOff } from "components/designSystem/foundations/icons/EyeOff"
import { Fertility } from "components/designSystem/foundations/icons/Fertility"
import { Fetus } from "components/designSystem/foundations/icons/Fetus"
import { FileUpload } from "components/designSystem/foundations/icons/FileUpload";
import FillBell from "components/designSystem/foundations/icons/FillBell";
import FillBlood from "components/designSystem/foundations/icons/FillBlood";
import FillBurger from "components/designSystem/foundations/icons/FillBurger";
import FillCake from "components/designSystem/foundations/icons/FillCake";
import FillInflammation from "components/designSystem/foundations/icons/FillInflammation";
import FillKidneys from "components/designSystem/foundations/icons/FillKidneys";
import { FillStar } from "components/designSystem/foundations/icons/FillStar";
import { FillThumbsDown } from "components/designSystem/foundations/icons/FillThumbsDown";
import { FillThumbsUp } from "components/designSystem/foundations/icons/FillThumbsUp";
import FillTube from "components/designSystem/foundations/icons/FillTube";
import { Globe } from 'components/designSystem/foundations/icons/Globe';
import { Heart } from "components/designSystem/foundations/icons/Heart"
import { Hormones } from "components/designSystem/foundations/icons/Hormones"
import { Hprim } from "components/designSystem/foundations/icons/Hprim";
import { Inflammation } from "components/designSystem/foundations/icons/Inflammation"
import { InfoCircle } from "components/designSystem/foundations/icons/InfoCircle"
import { Ions } from "components/designSystem/foundations/icons/Ions"
import { Jar } from "components/designSystem/foundations/icons/Jar"
import { Kidneys } from "components/designSystem/foundations/icons/Kidneys"
import { Lab } from "components/designSystem/foundations/icons/Lab";
import LegacyCompare from 'components/designSystem/foundations/icons/LegacyCompare';
import LegacyPeopleIcon from 'components/designSystem/foundations/icons/LegacyPeople';
import { Liver } from "components/designSystem/foundations/icons/Liver"
import { LockClose } from "components/designSystem/foundations/icons/LockClose";
import { LockOpen } from "components/designSystem/foundations/icons/LockOpen"
import { LogOut } from "components/designSystem/foundations/icons/LogOut"
import { Lollipop } from "components/designSystem/foundations/icons/Lollipop"
import { Minus } from "components/designSystem/foundations/icons/Minus"
import { Pancreas } from "components/designSystem/foundations/icons/Pancreas"
import { Pdf } from "components/designSystem/foundations/icons/Pdf";
import { Pen } from "components/designSystem/foundations/icons/Pen";
import { Phone } from "components/designSystem/foundations/icons/Phone";
import { Plus } from "components/designSystem/foundations/icons/Plus"
import { Prostate } from "components/designSystem/foundations/icons/Prostate"
import { Protein } from "components/designSystem/foundations/icons/Protein"
import { QuestionCircle } from "components/designSystem/foundations/icons/QuestionCircle";
import { Refresh } from "components/designSystem/foundations/icons/Refresh";
import { Report } from "components/designSystem/foundations/icons/Report"
import { Search } from "components/designSystem/foundations/icons/Search"
import { Send } from "components/designSystem/foundations/icons/Send";
import { Settings } from 'components/designSystem/foundations/icons/Settings';
import { Shield } from "components/designSystem/foundations/icons/Shield";
import { Star } from "components/designSystem/foundations/icons/Star"
import { Stethoscope } from "components/designSystem/foundations/icons/Stethoscope";
import { Stomach } from "components/designSystem/foundations/icons/Stomach"
import { Stroller } from "components/designSystem/foundations/icons/Stroller";
import { SuccessCircle } from "components/designSystem/foundations/icons/SuccessCircle"
import { Sweet } from "components/designSystem/foundations/icons/Sweet"
import { Syringe } from "components/designSystem/foundations/icons/Syringe";
import { ThumbsDown } from "components/designSystem/foundations/icons/ThumbsDown";
import { ThumbsUp } from "components/designSystem/foundations/icons/ThumbsUp";
import { Trash } from "components/designSystem/foundations/icons/Trash";
import { Tube } from "components/designSystem/foundations/icons/Tube"
import { User } from "components/designSystem/foundations/icons/User"
import { Virus } from "components/designSystem/foundations/icons/Virus"
import VividChart from "components/designSystem/foundations/icons/VividChart";
import VividHcp from "components/designSystem/foundations/icons/VividHcp";
import VividHeart from "components/designSystem/foundations/icons/VividHeart";
import VividPacifier from "components/designSystem/foundations/icons/VividPacifier";
import VividUser from "components/designSystem/foundations/icons/VividUser";
import { WarningCircle } from "components/designSystem/foundations/icons/WarningCircle"
import { WarningTriangle } from "components/designSystem/foundations/icons/WarningTriangle"
import { IconColor, IconName, IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import LOGGER from "core/logging/Logger";
import React, { FC, Fragment } from "react";
import styles from './Icons.module.scss'
import { CloseSM } from "./icons/CloseSM";

const getIcon = (name: IconName, size: IconSize) : JSX.Element => {
    switch (name) {
        case IconName.ALLERGY:
            return <Allergy size={size}/>
        case IconName.ANTIBODIES:
            return <Antibodies size={size} />
        case IconName.APPLE:
            return <Apple size={size} />
        case IconName.ARROW_DOWN:
            return <ArrowDown size={size} />
        case IconName.ARROW_LEFT:
            return <ArrowLeft size={size} />
        case IconName.ARROW_RIGHT:
            return <ArrowRight size={size} />
        case IconName.ARROW_RIGHT_DOWN:
            return <ArrowRightDown size={size} />
        case IconName.ARROW_RIGHT_UP:
            return <ArrowRightUp size={size} />
        case IconName.ARROW_UP:
            return <ArrowUp size={size} />
        case IconName.BACTERIA:
            return <Bacteria size={size} />
        case IconName.BELL:
            return <Bell size={size} />
        case IconName.BLOOD:
            return <Blood size={size} />
        case IconName.BONES:
            return <Bones size={size} />
        case IconName.BRAIN:
            return <Brain size={size} />
        case IconName.BURGER:
            return <Burger size={size} />
        case IconName.BURGER_MENU:
            return <BurgerMenu size={size} />
        case IconName.CAKE:
            return <Cake size={size} />
        case IconName.CALENDAR:
            return <Calendar size={size} />
        case IconName.CELLS:
            return <Cells size={size} />
        case IconName.CHART:
            return <Chart size={size} />
        case IconName.CHECKMARK:
            return <CheckMark size={size} />
        case IconName.CHEVRON_DOWN:
            return <ChevronDown size={size} />
        case IconName.CHEVRON_LEFT:
            return <ChevronLeft size={size} />
        case IconName.CHEVRON_RIGHT:
            return <ChevronRight size={size} />
        case IconName.CHEVRON_UP:
            return <ChevronUp size={size} />
        case IconName.CLOSE:
            return <Close size={size} />
        case IconName.CLOSE_SM:
            return <CloseSM size={size} />
        case IconName.DASHBOARD:
            return <Dashboard size={size} />
        case IconName.DASHBOARD_SQUARE:
            return <DashBoardSquare size={size} />
        case IconName.DIVIDER:
            return<Divider size={size}/>
        case IconName.DOWNLOAD:
            return <Download size={size} />
        case IconName.DRUGS:
            return <Drugs size={size} />
        case IconName.EQUAL:
            return <Equal size={size} />
        case IconName.EXPAND:
            return <Expand size={size} />
        case IconName.EXTERNAL_LINK:
            return <ExternalLink size={size} />
        case IconName.EYE:
            return <Eye size={size} />
        case IconName.EYE_OFF:
            return <EyeOff size={size} />
        case IconName.FERTILITY:
            return <Fertility size={size} />
        case IconName.FETUS:
            return <Fetus size={size} />
        case IconName.GLOBE:
            return <Globe size={size} />
        case IconName.HEART:
            return <Heart size={size} />
        case IconName.HORMONES:
            return <Hormones size={size} />
        case IconName.INFLAMMATION:
            return <Inflammation size={size} />
        case IconName.INFO_CIRCLE:
            return <InfoCircle size={size} />
        case IconName.IONS:
            return <Ions size={size} />
        case IconName.JAR:
            return <Jar size={size} />
        case IconName.KIDNEYS:
            return <Kidneys size={size} />
        case IconName.LAB:
            return <Lab size={size} />
        case IconName.LIVER:
            return <Liver size={size} />
        case IconName.LOCK_CLOSE:
            return <LockClose size={size} />
        case IconName.LOCK_OPEN:
            return <LockOpen size={size} />
        case IconName.LOGOUT:
            return <LogOut size={size} />
        case IconName.LOLLIPOP:
            return <Lollipop size={size} />
        case IconName.MINUS:
            return <Minus size={size} />
        case IconName.PANCREAS:
            return <Pancreas size={size} />
        case IconName.PEN:
            return <Pen size={size}/>
        case IconName.PLUS:
            return <Plus size={size} />
        case IconName.PROSTATE:
            return <Prostate size={size} />
        case IconName.PROTEIN:
            return <Protein size={size} />
        case IconName.QUESTION_CIRCLE:
            return <QuestionCircle size={size}/>
        case IconName.REFRESH:
            return <Refresh size={size} />
        case IconName.REPORT:
            return <Report size={size} />
        case IconName.SEARCH:
            return <Search size={size} />
        case IconName.SETTINGS:
            return <Settings size={size} />
        case IconName.SHIELD:
            return <Shield size={size} />
        case IconName.STAR:
            return <Star size={size} />
        case IconName.STOMACH:
            return <Stomach size={size} />
        case IconName.STROLLER:
            return <Stroller/>
        case IconName.SUCCESS_CIRCLE:
            return <SuccessCircle size={size} />
        case IconName.SWEET:
            return <Sweet size={size} />
        case IconName.TUBE:
            return <Tube size={size} />
        case IconName.USER:
           return <User size={size} />
        case IconName.VIRUS:
            return <Virus size={size} />
        case IconName.WARNING_CIRCLE:
            return <WarningCircle size={size} />
        case IconName.WARNING_TRIANGLE:
            return <WarningTriangle size={size} />
        case IconName.FILL_STAR:
            return <FillStar size={size} />
        case IconName.PEOPLE:
            return <LegacyPeopleIcon />
        case IconName.COMPARE:
            return <LegacyCompare />
        case IconName.SYRINGE:
            return <Syringe size={size} />
        case IconName.PHONE:
            return <Phone size={size} />
        case IconName.SEND:
            return <Send size={size} />
        case IconName.STETHOSCOPE:
            return <Stethoscope size={size} />
        case IconName.TRASH:
            return <Trash size={size} />
        case IconName.THUMBS_DOWN:
            return <ThumbsDown size={size} />
        case IconName.THUMBS_UP:
            return <ThumbsUp size={size} />
        case IconName.FILE_UPLOAD:
            return <FileUpload size={size} />
        case IconName.FILL_BELL:
            return <FillBell size={size} />
        case IconName.FILL_BLOOD:
            return <FillBlood size={size}/>
        case IconName.FILL_BURGER:
            return <FillBurger size={size}/>
        case IconName.FILL_CAKE:
            return <FillCake size={size}/>
        case IconName.FILL_KIDNEYS:
            return <FillKidneys size={size} />;
        case IconName.FILL_INFLAMMATION:
            return <FillInflammation size={size} />;
        case IconName.FILL_THUMBS_DOWN:
            return <FillThumbsDown size={size} />
        case IconName.FILL_THUMBS_UP:
            return <FillThumbsUp size={size} />
        case IconName.FILL_TUBE:
            return <FillTube size={size} />
        case IconName.VIVID_CHART:
            return <VividChart/>
        case IconName.VIVID_HCP:
            return <VividHcp/>
        case IconName.VIVID_HEART:
            return <VividHeart/>
        case IconName.VIVID_PACIFIER:
            return <VividPacifier/>
        case IconName.VIVID_USER:
            return <VividUser/>
        case IconName.FEMALE:
        case IconName.MALE:
        case IconName.UNKNOWN_GENDER:
            return <Fragment/>
        case IconName.HPRIM:
            return <Hprim size={size} />
        case IconName.PDF:
            return <Pdf size={size} />
        case IconName.ARTICLE:
            return <Article size={size} />
    }

    LOGGER.critical(
        "Icon Name is not defined " + name
    )

    return <Fragment/>

}

interface ContainerIconProps {
    name: IconName
    color?: IconColor
}

const Icon : FC<IconProps & ContainerIconProps> = ({...props}) : JSX.Element => {
    return <div className={classNames(styles.containerIcon, {
        [styles.containerIconColorOnPrimary]: props.color === IconColor.ON_PRIMARY,
        [styles.containerIconColorDefault]: props.color === IconColor.DEFAULT,
        [styles.containerIconColorOnTertiary]: props.color === IconColor.ON_TERTIARY,
        [styles.containerIconColorCritical]: props.color === IconColor.CRITICAL,
        [styles.containerIconColorSubdued]: props.color === IconColor.SUBDUED,

        [styles.containerIconSizeXXLarge]: props.size === IconSize.EXTRA_EXTRA_LARGE,

    })}>
        {getIcon(props.name, props.size)}
    </div>
}

// Used to put a <tag> with same icon size but empty
export const NullIcon: FC<{size: IconSize}> = (props): JSX.Element => {
    return <div
        className={
            classNames(
                styles.containerIcon,
                styles.nullIcon,
                {[styles.nullIconSizeExtraSmall]: props.size === IconSize.EXTRA_SMALL}
            )
        }
    />
}
export default Icon;