import React, { Component, ReactElement, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getApiTransKey } from 'core/translation/api_keys';

export type MainComponentProps = WithTranslation;

export enum TransDomain {
    GLOBAL = 'global',
    MEDICAL_REPORT = 'medical_report',
    MEDICAL_REPORT_TAGS = 'medical_report.tags',
    MEDICAL_REPORT_INVOICES = 'medical_report.report_invoices',
    ACCOUNT = 'account',
    ACCOUNT_AUTH = 'account.auth',
    ACCOUNT_NOTIF = 'account.notifications',
    ACCOUNT_2FA = 'account.2FA',
    ACCOUNT_SSO = 'account.sso',
    MESSAGES = 'messages',
    DOCTOR = 'doctor',
    BIOLOGIST = 'biologist',
    PATIENT = 'patient',
    USER = 'user',
    PRESCRIPTION = 'medical_report.prescription',
    COMMON_DESIGN = 'common_design',
    MODULES = 'modules',
    DESIGN_SYSTEM = 'design_system', // to remove, design system si not supposed to rely on redux base tech
    GRAPH = 'graph',
    COMMUNICATION = 'communication',
    ACCESS = 'access',

    SHARE_REPORT_FEATURE = 'features.share_report',
    OUTDATED_REPORT = 'access.outdated_report',
    MODULES_DOCUMENTS = 'modules.documents'
}

export class TransKey {
    key: string;
    suffix: string | null;

    constructor(key: string, suffix: string | null = null) {
        this.key = key;
        this.suffix = suffix;
    }

    format(): string {
        return this.suffix ? this.suffix + '.' + this.key : this.key;
    }
}

export function key(key: string, suffix: string | null = null): TransKey {
    return new TransKey(key, suffix);
}

class MainComponent<T extends MainComponentProps = MainComponentProps, S = {}> extends Component<T, S> {
    TRANS_SUFFIX: string | null = null;

    getFormattedKey(_key: string | TransKey): string {
        let formattedKey: string;

        if (_key instanceof TransKey) {
            formattedKey = _key.format();
        } else {
            formattedKey = key(_key, this.TRANS_SUFFIX).format();
        }

        return formattedKey;
    }

    transApiKey(_key: string, data: {} = {}): string {
        const tr_key = getApiTransKey(_key);
        if (tr_key === null) {
            return _key;
        }

        const formattedKey = this.getFormattedKey(
            key(tr_key, null)
        );

        return this.props.t(formattedKey, data);
    }

    trans(_key: string | TransKey, data: {} = {}): string {
        const formattedKey = this.getFormattedKey(_key);
        return this.props.t(formattedKey, data);
    }

    // Pass {styled} between <> </>
    transContent(_key: string | TransKey, styled: JSX.Element, data: {} = {}): ReactElement {
        const formattedKey = this.getFormattedKey(_key);
        return (
            <Trans i18nKey={formattedKey} values={data}>
                {styled.props.children}
            </Trans>
        );
    }

    render(): ReactNode {
        return <div>{this.props.children}</div>;
    }
}

// rework typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setup(C: any, mapStateToProps: any, mapDispatchToProps: any): any {
    if (mapStateToProps == null && mapDispatchToProps == null) {
        return withTranslation()(C);
    }

    return connect(mapStateToProps, mapDispatchToProps)(withTranslation()(C));
}

export function setupRaw<P extends MainComponentProps>(
    c: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithTranslation>> {
    return withTranslation('translation', { withRef: true })(c);
}

export default MainComponent;
