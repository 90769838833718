import { call, race } from 'redux-saga/effects';
import CONFIG from 'configuration/globals';
import { appendQueryParams, formatUrl } from 'sagas/api/rest/Url';
import { getOptions } from 'sagas/api/rest/RequestContent';
import { GET_METHOD, POST_METHOD } from 'sagas/api/rest/Models';
import { checkResponse } from 'sagas/api/rest/ErrorHandling';
import { CustomError, Errors } from 'sagas/api/rest/Exceptions';
import LOGGER from 'core/logging/Logger';
import { CallMethod } from "sagas/call/api";

export const FEEDBACK_IDENTIFIER_REQUIRED_EULA = 'FEEDBACK_IDENTIFIER_REQUIRED_EULA';

export function getUrl(endpoint: string): string {
    return formatUrl(CONFIG.apiUrl, endpoint);
}

export const isRedirectionLegit = (fromUrl: string, toUrl: string): boolean => {
    const diff = toUrl.length - fromUrl.length;
    if (diff > 1 || diff < -1) {
        return false;
    }

    for (let i = 0; i < fromUrl.length; i++) {
        if (fromUrl[i] !== toUrl[i] && fromUrl[i - diff] !== toUrl[i] && fromUrl[i] !== toUrl[i + diff]) {
            return false;
        }
    }
    return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parsePayload = async (response: any): Promise<{}> => {
    let payload = await response.json();
    if (Array.isArray(payload)) {
        payload = { payload };
    }

    return payload;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* request(url: string, parameters = {}, method = GET_METHOD, retryCount = 0): any {
    const options: {} = getOptions(parameters, method == GET_METHOD ? CallMethod.GET : CallMethod.POST , retryCount);

    let response: Response;
    let completeUrl = url;
    try {
        if (method === GET_METHOD) {
            completeUrl = appendQueryParams(url, parameters);
        }

        response = yield fetch(completeUrl, options);
    } catch (e) {
        LOGGER.error('Error while calling api on ' + url);
        throw new CustomError(Errors.ServerError, '');
    }

    // Work around for Safari not including headers in redirected request
    if (response.status === 401 && response.redirected) {
        if (isRedirectionLegit(completeUrl, response.url)) {
            try {
                response = yield fetch(response.url, options);
            } catch (e) {
                LOGGER.error('Error while calling api on ' + url);
                throw new CustomError(Errors.ServerError, '');
            }
        } else {
            throw new CustomError(Errors.RedirectionError, '');
        }
    }

    yield checkResponse(response);

    return yield parsePayload(response);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* apiFetch(url: string, parameters: {}, method = GET_METHOD, retryCount = 0): any {
    const { response } = yield race({
        response: call(request, url, parameters, method, retryCount),
    });

    return response;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* callApi({ endpoint = '', parameters = {}, method = GET_METHOD} = {}, retryCount = 0): any {
    if (!endpoint) {
        throw new Error('No endpoint defined');
    }

    const url = yield call(getUrl, endpoint);

    return yield call(apiFetch, url, parameters, method, retryCount);
}

/**
 * Method to use to perform GET queries to the API
 * @param endpoint
 * @param parameters
 * @param retryCount
 */
export const get = <T>(endpoint: string, parameters = {}, retryCount = 0): T => {
    return callApi({ endpoint, parameters, method: 'GET' }, retryCount);
};

/**
 * Method to use to perform POST queries to the API
 * @param endpoint
 * @param parameters
 */
export const post = <T>(endpoint: string, parameters = {}): T => {
    return callApi({ endpoint, parameters, method: POST_METHOD });
};
