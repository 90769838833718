import {
    AnyAction,
    ApiCallFailureAction,
    failure,
    getActionPayload,
    getResponse,
    successful
} from 'actions/ActionInterface';
import {
    ALTER_CONTEXT,
    AlterContextPayload,
    API_MODULAR_GET_PAGE,
    API_MODULAR_LOAD_PARTIAL,
    API_MODULAR_LOAD_PARTIALS,
    castModuleAPICallSuccessAction,
    CHANGE_PAGE,
    ChangePagePayload,
    LoadPartialAction,
    LoadPartialsAction,
    OwnPayloadOverrideMode,
    SUCCESSFUL_MODULE_API_CALL,
} from 'actions/modular/ModularActions';
import {
    GetPageResponse,
    GetPartialResponse,
    ModuleV1,
    PageContext,
    PageV1ContextDependenciesRuleMode,
    PartialState, RemovedItem
} from 'models/modular/api';
import { ComputedContextDependency, ComputedContextDependencyType, ModuleData } from 'models/modular/storage';
import _ from 'underscore';
import { cast } from 'core/utils/Typed';


export interface PartialPayload {
    state: PartialState,
    payload: PageContext
}

export interface ReducerInterface {
    currentPage?: GetPageResponse
    modulesData: {[key: string]: ModuleData}
    partials: {[key: string]: PartialPayload},
    contextDependencies: {[key: string]: ComputedContextDependency[]},
    forceReload: boolean,
    isSwitching: boolean,
    loading: boolean,
    loadingIteration: number,
    loadingNextIteration: number,
    failed: boolean
}

export const initialState: ReducerInterface = {
    currentPage: undefined,
    modulesData: {},
    partials: {},
    contextDependencies: {},
    forceReload: false,
    isSwitching: false,
    loading: false,
    loadingIteration: 0,
    loadingNextIteration: 0,
    failed: false,
};

function updateModuleData(
    modules: ModuleV1[],
    modulesData: {[key: string]: ModuleData},
    contextDependencies: {[key: string]: ComputedContextDependency[]},
    identifiers: string[]
): void {
    for (const module of modules) {
        if (!Object.hasOwn(modulesData, module.identification.unique_id)) {
            modulesData[module.identification.unique_id] = {
                identification: module.identification,
                pagePayload: module.payload,
                ownPayload: {},
                contextMapping: {},
                loading: module.loading,
                gone: module.gone,
                twinkle: false
            }
        } else {
            modulesData[module.identification.unique_id].pagePayload = module.payload;
            modulesData[module.identification.unique_id].identification = module.identification;
            modulesData[module.identification.unique_id].loading = module.loading
            modulesData[module.identification.unique_id].gone = module.gone
            modulesData[module.identification.unique_id].twinkle = false
        }

        for (const [ctx_key, internal_key] of Object.entries(module.context_dependencies.context_key_mapping)) {
            modulesData[module.identification.unique_id].contextMapping[ctx_key] = internal_key;
        }

        for (const contextDependency of module.context_dependencies.rules) {

            if (!Object.hasOwn(contextDependencies, contextDependency.context_key)) {
                contextDependencies[contextDependency.context_key] = []
            }

            contextDependencies[contextDependency.context_key].push(
                {
                    identification: module.identification.unique_id,
                    type: ComputedContextDependencyType.MODULE,
                    mode: contextDependency.mode,
                    removed_item: contextDependency.removed_item
                }
            )
        }

        identifiers.push(module.identification.unique_id)
    }
}

export default function modularReducers(state = initialState, action: AnyAction): ReducerInterface {
    switch (action.type) {
        case CHANGE_PAGE:
            const changePage = getActionPayload<ChangePagePayload>(action);

            if (state.currentPage && state.currentPage.page_id == changePage.pageId) {
                return state
            }

            if (state.currentPage) {
                state.currentPage.context = {}
            }

            return {
                ...state,
                isSwitching: true,
                modulesData: {},
                partials: {},
                contextDependencies: {},
                loading: false,
                failed: false,
            };

        case ALTER_CONTEXT:

            const alterContext = getActionPayload<AlterContextPayload>(action);

            const contextDependenciesAlter = state.contextDependencies;

            const removeData: string[] = [];
            const makeGlims: string[] = []
            const removeFromList: string[] = [];
            const alterData: {identification: string, removed_item: RemovedItem}[] = []

            let loadingNextIteration = state.loadingNextIteration;

            let currentPage = {...state.currentPage} as GetPageResponse | undefined;
            // let currentPage = state.currentPage;
            // if (currentPage && currentPage.context[alterContext.contextKey] === alterContext.value) {
            //     return state
            // }

            const newPartials = {...state.partials}

            if (Object.hasOwn(contextDependenciesAlter, alterContext.contextKey)) {

                for (const element of contextDependenciesAlter[alterContext.contextKey]) {

                    if (element.type === ComputedContextDependencyType.MODULE) {

                        if (element.mode === PageV1ContextDependenciesRuleMode.TWINKLE) {
                            makeGlims.push(element.identification)
                        } else {
                            if (element.removed_item) {
                                alterData.push(
                                    {
                                        identification: element.identification,
                                        removed_item: element.removed_item,
                                    }
                                )
                            } else {
                                removeData.push(element.identification);
                            }

                            if (element.mode == PageV1ContextDependenciesRuleMode.STRICT) {
                                removeFromList.push(element.identification)
                            }
                        }


                    } else if (element.type === ComputedContextDependencyType.PAGE) {
                        if (loadingNextIteration <= state.loadingIteration) {
                            loadingNextIteration = state.loadingIteration + 1;
                        }

                        if (element.mode == PageV1ContextDependenciesRuleMode.STRICT) {
                            currentPage = undefined
                        }
                    } else if (element.type === ComputedContextDependencyType.PARTIAL) {
                        if (element.mode == PageV1ContextDependenciesRuleMode.STRICT) {
                            delete newPartials[element.identification]
                        } else if (element.mode == PageV1ContextDependenciesRuleMode.REFRESH) {
                            newPartials[element.identification] = {
                                payload: state.partials[element.identification]?.payload,
                                state: PartialState.TO_LOAD
                            }
                        }
                    }
                }
            }

            if (currentPage) {
                currentPage.payload.modules = currentPage.payload.modules.filter(
                    (m: ModuleV1) => !removeFromList.includes(m.identification.unique_id)
                );

                currentPage.context[alterContext.contextKey] = alterContext.value
            }

            const modulesDataAlter: {[key: string]: ModuleData} =  _.omit(state.modulesData, ...removeData);
            alterData.forEach(
                (item) => {
                    const element = cast<ModuleData<{ [key: string]: unknown }>>({...modulesDataAlter[item.identification]})
                    if (Object.hasOwn(element.pagePayload, item.removed_item.key)) {
                        element.pagePayload[item.removed_item.key] = item.removed_item.alternative_value
                    }
                    modulesDataAlter[item.identification] = element
                }
            )

            makeGlims.forEach(
                (item) => {
                    const element = cast<ModuleData<{ [key: string]: unknown }>>({...modulesDataAlter[item]})
                    element.twinkle = true
                    modulesDataAlter[item] = element
                }
            )

            return {
                ...state,
                contextDependencies: contextDependenciesAlter,
                modulesData: modulesDataAlter,
                partials: newPartials,
                currentPage: currentPage,
                forceReload: loadingNextIteration > state.loadingIteration,
                isSwitching: false,
                loadingIteration: state.loadingIteration,
                loadingNextIteration: loadingNextIteration,
                failed: false,
            }
        case failure(API_MODULAR_GET_PAGE):
            return {
                ...state,
                loading: false,
                isSwitching: false,
                failed: true
            };
        case API_MODULAR_GET_PAGE:
            return {
                ...state,
                loadingIteration: state.loadingIteration + 1,
                loading: state.loadingIteration < state.loadingNextIteration,
                forceReload: false,
            };
        case successful(API_MODULAR_GET_PAGE):
            const pageResponse = getResponse<GetPageResponse>(action);
            const switchPage = state.currentPage && state.currentPage.page_id !== pageResponse.page_id;
            const contextDependencies = switchPage ? {} : state.contextDependencies;
            const modulesData = switchPage ? {} : state.modulesData;
            const identifiers: string[] = [];
            const partials: {[id: string]: PartialPayload} = {}

            updateModuleData(
                pageResponse.payload.modules,
                modulesData,
                contextDependencies,
                identifiers
            )

            pageResponse.payload.partials?.forEach(
                (partial) => {
                    partials[partial.identifier] = {payload: partial.payload, state: partial.state};

                    partial.context_dependencies.rules.forEach(
                        (rule) => {
                            if (!Object.hasOwn(contextDependencies, rule.context_key)) {
                                contextDependencies[rule.context_key] = []
                            }

                            contextDependencies[rule.context_key].push(
                                {
                                    identification: partial.identifier,
                                    type: ComputedContextDependencyType.PARTIAL,
                                    mode: rule.mode,
                                    removed_item: null
                                }
                            )
                        }
                    )
                }
            )

            for (const contextDependency of pageResponse.context_dependencies.rules) {

                if (!Object.hasOwn(contextDependencies, contextDependency.context_key)) {
                    contextDependencies[contextDependency.context_key] = []
                }

                contextDependencies[contextDependency.context_key].push(
                    {
                        identification: pageResponse.page_id,
                        type: ComputedContextDependencyType.PAGE,
                        mode: contextDependency.mode,
                        removed_item: contextDependency.removed_item
                    }
                )
            }

            Object.keys(modulesData).forEach((k) => {
                if (!identifiers.includes(k)) {
                    _.omit(modulesData, k)
                }
            });

            if (switchPage) {
                state.currentPage = undefined;
                state.contextDependencies = {};
                state.modulesData = {};
            }

            return {
                ...state,
                currentPage: pageResponse,
                contextDependencies: contextDependencies,
                modulesData: modulesData,
                partials: partials,
                forceReload: false,
                loading: state.loadingIteration < state.loadingNextIteration,
                isSwitching: false,
                failed: false,
            };
        case API_MODULAR_LOAD_PARTIALS:

            const loadPartialAction = getActionPayload<{ params: LoadPartialsAction }>(action);

            const toLoadPartials = {...state.partials}
            loadPartialAction.params.partials.forEach(
                (partial) => {
                    if (Object.hasOwn(toLoadPartials, partial.identifier) && toLoadPartials[partial.identifier].state === PartialState.TO_LOAD) {
                        toLoadPartials[partial.identifier].state = PartialState.LOADING
                    }
                }
            )

            return {
                ...state,
                partials: toLoadPartials
            }
        case successful(API_MODULAR_LOAD_PARTIAL):
            const partialResponse = getResponse<GetPartialResponse>(action);
            if (partialResponse.page_id != state.currentPage?.page_id) {
                return state
            }

            const contextDependenciesPartial = state.contextDependencies;
            const modulesDataPartial = state.modulesData;
            updateModuleData(
                partialResponse.payload.modules,
                modulesDataPartial,
                contextDependenciesPartial,
                []
            )


            const refreshLoadPartials = {...state.partials}

            if (Object.hasOwn(refreshLoadPartials, partialResponse.partial_identifier)) {
                refreshLoadPartials[partialResponse.partial_identifier].state = PartialState.LOADED
            }

            return {
                ...state,
                contextDependencies: contextDependenciesPartial,
                modulesData: modulesDataPartial,
                partials: refreshLoadPartials
            }
        case failure(API_MODULAR_LOAD_PARTIAL):

            const failurePartial = cast<ApiCallFailureAction<LoadPartialAction>>(action)

            const refreshLoadPartialsFails = {...state.partials}

            if (
                failurePartial.payload.originAction &&
                failurePartial.payload.originAction.payload.params &&
                Object.hasOwn(refreshLoadPartialsFails, failurePartial.payload.originAction.payload.params.identifier)) {
                refreshLoadPartialsFails[failurePartial.payload.originAction.payload.params.identifier].state = PartialState.LOADED
            }

            return {
                ...state,
                partials: refreshLoadPartialsFails
            }

        case SUCCESSFUL_MODULE_API_CALL:
            const moduleApiSuccessAction = castModuleAPICallSuccessAction(action);
            const moduleData = state.modulesData[moduleApiSuccessAction.payload.moduleIdentifier];
            if (moduleData && moduleApiSuccessAction.payload.response) {
                if (moduleApiSuccessAction.payload.overrideMode === OwnPayloadOverrideMode.STRICT) {
                    moduleData.ownPayload = moduleApiSuccessAction.payload.response;
                } else if (moduleApiSuccessAction.payload.overrideMode === OwnPayloadOverrideMode.SPREAD) {
                    moduleData.ownPayload = {
                        ...moduleData.ownPayload,
                        ...moduleApiSuccessAction.payload.response
                    }
                } else if (moduleApiSuccessAction.payload.overrideMode === OwnPayloadOverrideMode.SPREAD_BY_KEY) {
                    for (const [key, newData] of Object.entries(moduleApiSuccessAction.payload.response)) {
                        const existingData = Object.hasOwn(moduleData.ownPayload, key) ? moduleData.ownPayload[key] : {};

                        if (typeof existingData == 'object' && typeof newData === 'object') {
                            moduleData.ownPayload[key] = {
                                ...existingData,
                                ...newData
                            }
                        } else {
                            moduleData.ownPayload[key] = newData
                        }
                    }
                }

                state.modulesData[moduleApiSuccessAction.payload.moduleIdentifier] = moduleData
            }

            return {
                ...state,
                modulesData: state.modulesData
            };

        default:
            return state;
    }
}
