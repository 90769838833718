import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from "./NavBar.module.scss"
import MediaQuery from 'react-responsive';
import { SCREEN_L } from 'components/designSystem/foundations/Breakpoints';

export interface NavBarProps {
    sticky?: boolean;
    content?: ReactNode
    mobileContent?: ReactNode
    //switchSideExpansion?: () => void
}

const NavBar: FC<NavBarProps> = (props) =>
    <div id="nav-bar" className={classNames(styles.navBar, {
        [styles.navBarSticky]: props.sticky,
    })}>
        <div className={styles.content}>
            <MediaQuery minWidth={SCREEN_L+1}>
                {props.content}
            </MediaQuery>
            <MediaQuery maxWidth={SCREEN_L}>
                {props.mobileContent ? props.mobileContent : props.content}
            </MediaQuery>
        </div>

    </div>;

export default NavBar;
