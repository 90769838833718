import React from "react";

export const Stroller = (): JSX.Element => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <g clipPath="url(#clip0_1525_4745)">
            <path d="M12.906 11.0523C12.5923 11.0523 12.2915 11.1076 12.0122 11.2085L10.8037 9.99157C12.4313 9.26884 13.5675 7.6301 13.5675 5.72367C13.5675 5.666 13.5595 5.61033 13.5458 5.55697C14.7578 4.71647 15.552 3.30962 15.552 1.7145C15.552 1.34664 15.2558 1.04844 14.8905 1.04844C14.5252 1.04844 14.229 1.34664 14.229 1.7145C14.229 3.55534 12.7498 5.04489 10.9216 5.04489C10.8778 5.04489 10.835 5.04942 10.7936 5.0576H6.29123V1.0618C6.29123 0.721446 6.03639 0.435824 5.7003 0.399512C5.67463 0.396734 5.63469 0.393362 5.58173 0.390239C4.8228 0.345654 3.92372 0.453808 3.04499 0.848708C1.35876 1.60647 0.337891 3.20334 0.337891 5.72367C0.337891 8.29891 2.4108 10.3862 4.96826 10.3862H5.5764L3.96769 12.411C3.86273 12.3938 3.75511 12.3844 3.64528 12.3844C2.54903 12.3844 1.66083 13.2788 1.66083 14.3826C1.66083 15.4865 2.54903 16.3809 3.64528 16.3809C4.74153 16.3809 5.62973 15.4865 5.62973 14.3826C5.62973 13.8792 5.44459 13.4197 5.13963 13.0684L7.27062 10.3862H8.93715C9.06257 10.3862 9.18679 10.381 9.3097 10.3711L10.9036 11.9761C10.503 12.443 10.2601 13.051 10.2601 13.7165C10.2601 15.1881 11.4447 16.3808 12.906 16.3808C14.3674 16.3808 15.552 15.1881 15.552 13.7165C15.552 12.245 14.3675 11.0523 12.906 11.0523ZM3.64528 15.0487C3.27968 15.0487 2.98381 14.7508 2.98381 14.3827C2.98381 14.0145 3.27968 13.7166 3.64528 13.7166C4.01089 13.7166 4.30675 14.0145 4.30675 14.3827C4.30675 14.7508 4.01092 15.0487 3.64528 15.0487ZM3.58414 2.06519C4.04378 1.85862 4.52588 1.75562 4.96826 1.72409V5.05757H1.68995C1.83528 3.47285 2.51787 2.54436 3.58414 2.06519ZM4.96826 9.05406C3.36796 9.05406 2.03338 7.91001 1.72697 6.38976H5.62973H12.1784C11.872 7.91004 10.5374 9.05406 8.93712 9.05406H4.96826ZM12.906 15.0487C12.1753 15.0487 11.5831 14.4524 11.5831 13.7166C11.5831 12.9807 12.1753 12.3844 12.906 12.3844C13.6368 12.3844 14.229 12.9807 14.229 13.7166C14.229 14.4524 13.6368 15.0487 12.906 15.0487Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_1525_4745">
                <rect width="15.8897" height="16" fill="currentColor" transform="translate(0 0.380859)"/>
            </clipPath>
        </defs>
    </svg>
}