import React from 'react';

import 'components/modules/eula/eula.scss';
import Title, { TitleType } from 'components/core/text/Title';
import LogoFull from 'core/content/brand/logos/LogoFull';

const LegalNotice = (): JSX.Element => {
    return (
        <div>
            <div className="eula-page">
                <LogoFull />
                <Title type={TitleType.HEADING1} className={'eula-title'}>
                    MENTIONS LEGALES
                </Title>
                <div className="eula-paragraph">
                    <div className="eula-paragraph-content">
                        <div className="eula-paragraph-title">1. Éditeur</div>
                        La société Kiro est une société par actions simplifiée au capital de 13.426,85 euros, dont le
                        siège social est situé 23, boulevard François Robert, 13009 MARSEILLE, immatriculée au Registre
                        du Commerce et des Sociétés de Marseille sous le numéro 851 014 407 (ci-après « Kiro »).
                        <br />
                        <br />
                        Directeur de la publication : Monsieur Alexandre GUENOUN, Président.
                    </div>
                </div>
                <div className="eula-paragraph">
                    <div className="eula-paragraph-content">
                        <div className="eula-paragraph-title">2. Hébergement</div>
                        Les données et les serveurs sont hébergés par :
                        <br />
                        <br />
                        OVH
                        <br />
                        Société par actions simplifiée au capital de 10.069.020,00 euros
                        <br />
                        Siège social : 2 rue Kellermann, 59100 Roubaix – FRANCE
                        <br />
                        Registre du Commerce et des Sociétés de Lille Métropole, numéro 424 761 419
                        <br />
                        <br />
                        Google Cloud Platform - Google France
                        <br />
                        Société à responsabilité limitée au capital de 464.884.017,00 euros
                        <br />
                        Siège social : 8, rue de Londres, 75009 Paris – France
                        <br />
                        Registre du Commerce et des Sociétés de Paris, numéro 443 061 841
                        <br />
                        <br />
                        <b>Hébergement des données de santé à caractère personnel</b>
                        <br />
                        Dans le respect de l’article L. 1111-8 du Code de la Santé Publique, les données de santé à
                        caractère personnel sont hébergées sur des serveurs dédiés mis à disposition de Kiro par un{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                                'https://esante.gouv.fr/labels-certifications/hds/certification-des-hebergeurs-de-donnees-de-sante'
                            }
                            className={"legacy-a"}

                        >
                            hébergeur certifié de données de santé
                        </a>{' '}
                        (certificat HDS n°FR053558).
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LegalNotice;
