import {
    FetchPatientReportsRequest,
    FetchPatientReportsResponse,
    GetReportsMetadataRequest,
    GetReportsMetadataResponse
} from "actions/modular/modules/ReportsActions";
import { GetEndpoint, HTTPVerb } from "sagas/call/endpoints";

export const FetchPatientReportsEndpoint: GetEndpoint<FetchPatientReportsRequest, FetchPatientReportsResponse> = {
    path: '/medical-reports/access/get-patients-reports',
    verb: HTTPVerb.GET
}



export const GetReportsMetadataEndpoint: GetEndpoint<GetReportsMetadataRequest, GetReportsMetadataResponse> = {
    path: '/medical-reports/reports/metadata',
    verb: HTTPVerb.GET
}

