import React, {Component, ReactNode} from 'react';

import 'components/legacyDesignSystem/components/Switch.scss';

export interface SwitchProps {
    onClickLeft: () => void;
    onClickRight: () => void;
    text_left: ReactNode;
    text_right: ReactNode;
}

export interface SwitchState {
    toggle: boolean;
}

export class Switch extends Component<SwitchProps> {
    state: SwitchState = {
        toggle: false,
    };

    handleOnClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        e.preventDefault();
        this.state.toggle ? this.props.onClickLeft() : this.props.onClickRight();
        this.setState({ toggle: !this.state.toggle });
    }

    render(): JSX.Element {
        const { text_left, text_right } = this.props;
        const { toggle } = this.state;

        return (
            <div className={'switch__background'}>
                <a className={'switch__container legacy-a'} onClick={(e): void => this.handleOnClick(e)}>
                    {!toggle ? (
                        <>
                            <span className={'switch__tab switch__tab--active'}>{text_left}</span>
                            <span className={'switch__tab'}>{text_right}</span>
                        </>
                    ) : (
                        <>
                            <span className={'switch__tab'}>{text_left}</span>
                            <span className={'switch__tab switch__tab--active'}>{text_right}</span>
                        </>
                    )}
                </a>
            </div>
        );
    }
}
