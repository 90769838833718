import React, { FC } from 'react';

export enum LogoColor {
    COLORED = 'COLORED',
    WHITE = 'WHITE',
    CURRENT_COLOR = 'CURRENT_COLOR'
}

export enum LogoType {
    DEFAULT = 'DEFAULT',
    SYMBOL = 'SYMBOL'
}


export interface LogoProps {
    color?: LogoColor;
    type?: LogoType;
    small?: boolean
}


export const KiroLogo: FC<LogoProps> = ({
                                            color = LogoColor.COLORED,
                                            type = LogoType.DEFAULT,
                                            small = false
                                        }): JSX.Element => {
    if (type === LogoType.DEFAULT) {
        if (color == LogoColor.COLORED) {
            return (
                <svg width={small ? "111" : "149"} height={small ? "24" : "32"} viewBox="0 0 410 88" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                     <path
                       fill="url(#logo-org-full_svg__a)"
                       d="M43.625 88C68.133 88 88 68.301 88 44 88 19.7 68.133 0 43.625 0a44.43 44.43 0 0 0-33.36 14.984h22.269c4.512 0 8.168 3.625 8.168 8.099s-3.656 8.099-8.168 8.099H1.162A43.396 43.396 0 0 0 0 35.9h63.555c4.512 0 8.168 3.625 8.168 8.099s-3.656 8.099-8.168 8.099H0a43.39 43.39 0 0 0 1.162 4.719h46.33c4.511 0 8.168 3.625 8.168 8.099s-3.657 8.099-8.169 8.099H10.265A44.43 44.43 0 0 0 43.625 88Z"
                     />
                     <path
                       fill="#44506A"
                       fillRule="evenodd"
                       d="M369.868 4c-22.598 0-39.315 17.026-39.315 40.054C330.553 67.182 347.27 84 369.868 84c22.498 0 39.315-16.818 39.315-39.946C409.19 21.032 392.366 4 369.868 4Zm0 67.909c-15.24 0-25.962-11.561-25.962-27.855s10.722-27.963 25.962-27.963c15.139 0 25.969 11.669 25.969 27.963s-10.83 27.855-25.969 27.855Z"
                       clipRule="evenodd"
                     />
                     <path fill="#44506A" d="M206.822 5.37h13.138v77.267h-13.138V5.37Z" />
                     <path
                       fill="#44506A"
                       fillRule="evenodd"
                       d="M279.571 5.37c15.139 0 27.331 8.72 27.331 24.491 0 11.037-6.103 18.61-14.924 22.182l18.603 30.587h-15.347l-17.241-28.385h-13.139V82.63h-13.037V5.37h27.754Zm-14.723 37.422h13.561c9.151 0 15.14-4.418 15.14-12.93 0-8.413-6.096-13.039-15.14-13.039h-13.561v25.969Z"
                       clipRule="evenodd"
                     />
                     <path
                       fill="#44506A"
                       d="M161.733 6.632h17.449l-26.344 30.15 31.903 45.848h-16.012l-24.8-35.642-11.534 13.199V82.63h-13.139V5.37h13.139v34.844l29.338-33.582Z"
                     />
                     <defs>
                       <linearGradient
                         id="logo-org-full_svg__a"
                         x1={-0.755}
                         x2={100.608}
                         y1={44.005}
                         y2={44.005}
                         gradientUnits="userSpaceOnUse"
                       >
                         <stop offset={0.314} stopColor="#00B0F0" />
                         <stop offset={0.836} stopColor="#0285D0" />
                         <stop offset={0.951} stopColor="#027BC8" />
                       </linearGradient>
                     </defs>
                </svg>
            )
        }

        // Color White
        return (
            <svg width="149" height="32" viewBox="0 0 410 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.6249 88.2483C68.1326 88.2483 88 68.5495 88 44.2483C88 19.9478 68.1326 0.248291 43.6249 0.248291C30.329 0.248291 18.3988 6.04646 10.2659 15.2319H32.5339C37.0458 15.2319 40.7021 18.8573 40.7021 23.331C40.7021 27.8048 37.0458 31.4302 32.5339 31.4302H1.16236C0.690485 32.9691 0.301363 34.5436 0.000131449 36.1488H63.5549C68.0668 36.1488 71.7231 39.7743 71.7231 44.248C71.7231 48.7218 68.0668 52.3472 63.5549 52.3472H0C0.301199 53.9524 0.690285 55.527 1.16212 57.0658H47.4914C52.0033 57.0658 55.6596 60.6913 55.6596 65.165C55.6596 69.6388 52.0033 73.2642 47.4914 73.2642H10.2652C18.3981 82.45 30.3286 88.2483 43.6249 88.2483Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M369.868 4.24829C347.27 4.24829 330.553 21.2741 330.553 44.302C330.553 67.4305 347.27 84.2483 369.868 84.2483C392.366 84.2483 409.183 67.4305 409.183 44.302C409.19 21.2808 392.366 4.24829 369.868 4.24829ZM369.868 72.157C354.628 72.157 343.906 60.5961 343.906 44.302C343.906 28.0079 354.628 16.3396 369.868 16.3396C385.007 16.3396 395.837 28.0079 395.837 44.302C395.837 60.5961 385.007 72.157 369.868 72.157Z" fill="white"/>
                <path d="M206.822 5.61792H219.96V82.8854H206.822V5.61792Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M279.571 5.61792C294.71 5.61792 306.902 14.339 306.902 30.1094C306.902 41.1466 300.799 48.7196 291.978 52.2913L310.581 82.8787H295.234L277.993 54.4933H264.854V82.8787H251.817V5.61792H279.571ZM264.848 43.0399H278.409C287.56 43.0399 293.549 38.6223 293.549 30.1094C293.549 21.6971 287.453 17.0714 278.409 17.0714H264.848V43.0399Z" fill="white"/>
                <path d="M161.733 6.88007H179.182L152.838 37.0311L184.741 82.8787H168.729L143.929 47.2359L132.395 60.4349V82.8787H119.256V5.61792H132.395V40.4618L161.733 6.88007Z" fill="white"/>
            </svg>
        )
    }

    // Type == Symbol
    if (color === LogoColor.COLORED) {
        return (
            <svg width="46" height={small ? "16" : "25"} viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.6249 88C68.1326 88 88 68.3012 88 44C88 19.6995 68.1326 0 43.6249 0C30.329 0 18.3988 5.79817 10.2659 14.9836H32.5339C37.0458 14.9836 40.7021 18.609 40.7021 23.0828C40.7021 27.5565 37.0458 31.1819 32.5339 31.1819H1.16236C0.690485 32.7208 0.301363 34.2954 0.000131449 35.9006H63.5549C68.0668 35.9006 71.7231 39.526 71.7231 43.9997C71.7231 48.4735 68.0668 52.0989 63.5549 52.0989H0C0.301199 53.7041 0.690285 55.2787 1.16212 56.8175H47.4914C52.0033 56.8175 55.6596 60.443 55.6596 64.9167C55.6596 69.3905 52.0033 73.0159 47.4914 73.0159H10.2652C18.3981 82.2017 30.3286 88 43.6249 88Z" fill="url(#paint0_linear_694_336)"/>
                <defs>
                    <linearGradient id="paint0_linear_694_336" x1="-0.754568" y1="44.0047" x2="100.608" y2="44.0047" gradientUnits="userSpaceOnUse">
                    <stop offset="0.3142" stopColor="#00B0F0"/>
                    <stop offset="0.8358" stopColor="#0285D0"/>
                    <stop offset="0.9513" stopColor="#027BC8"/>
                    </linearGradient>
                </defs>
            </svg>
        )
    } else { 
        const fillColor: string = color === LogoColor.CURRENT_COLOR ? 'currentColor' : 'white'
        return <svg width="46" height={small ? "16" : "25"} viewBox="0 0 88 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.6249 88.9573C68.1326 88.9573 88 69.2585 88 44.9573C88 20.6569 68.1326 0.957336 43.6249 0.957336C30.329 0.957336 18.3988 6.75551 10.2659 15.9409H32.5339C37.0458 15.9409 40.7021 19.5663 40.7021 24.0401C40.7021 28.5139 37.0458 32.1393 32.5339 32.1393H1.16236C0.690485 33.6781 0.301363 35.2527 0.000131449 36.8579H63.5549C68.0668 36.8579 71.7231 40.4833 71.7231 44.9571C71.7231 49.4309 68.0668 53.0563 63.5549 53.0563H0C0.301199 54.6615 0.690285 56.236 1.16212 57.7749H47.4914C52.0033 57.7749 55.6596 61.4003 55.6596 65.8741C55.6596 70.3478 52.0033 73.9733 47.4914 73.9733H10.2652C18.3981 83.159 30.3286 88.9573 43.6249 88.9573Z"
                fill={fillColor}/>
        </svg>
    }
}