import classNames from 'classnames';
import styles from 'components/designSystem/containers/Box.module.scss';
import { Property } from "csstype";
import React, { CSSProperties, FC, PropsWithChildren } from 'react';

export enum BoxColor {
    WHITE = 'WHITE',
    BLUE = 'BLUE',
    BOLD = 'BOLD',
    INTENSE = 'INTENSE',
    YELLOW = 'YELLOW',
    ORANGE = 'ORANGE',
    DARK_ORANGE = 'DARK_ORANGE',
    LAVENDERWEB = 'LAVENDERWEB',
    VIOLET_BLUE = 'VIOLET_BLUE',
    NONE = 'NONE',

    // Gradient
    GRADIENT_BLUE = 'GRADIENT_BLUE',
    GRADIENT_DARK_BLUE = 'GRADIENT_DARK_BLUE',
    GRADIENT_ORANGE = 'GRADIENT_ORANGE',

}

export enum BackgroundGradientColor {
    // TODO : Find a way to move it to CSS
    DARK_GREY_BLUE = 'rgba(0, 25, 72, 0.50)',
    DARK_BLUE = 'rgba(7, 17, 107, 0.70)',
    BLACK = 'rgba(0, 0, 0, 0.4)',
}

interface BackgroundImage {
    imageUrl: string
    position?: Property.BackgroundPosition;
    gradient?: BackgroundGradientColor
}

type BoxBackground = BoxColor | BackgroundImage;

export enum BoxHeight {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    NORMAL = 'NORMAL',
    HEIGHT_29 = 'HEIGHT_29'
}

export enum BoxWidth {
    WIDTH_LARGE = 'LARGE',
    WIDTH_50 = 'WIDTH_50'
}

export enum BoxBorderColor {
    DEFAULT = 'DEFAULT',
    DISABLED = 'DISABLED'
}

interface BoxProps {
    background: BoxBackground
    /**
     * Set 'hover' for the shadow to increase when the cursor hovers the element, giving the impression that the
     * element is raised (for interactive elements only).
     */
    withBoxShadow?: boolean | 'hover'
    withFixedHeight?: BoxHeight
    withBorderRadius?: boolean
    withBorder?: { color: BoxBorderColor }
    withRadius?: boolean
    withPadding?: boolean
    withFixedWidth?: BoxWidth;
    withFullWidth?: boolean
    withFullHeight?: boolean;
    // Fix to proper display  FormWithDataPrivacyPopUp (especially on iphone 7)
    // without it the box is too big and we can't see button
    // for now it's not customizable and : 50vh
    withMaxHeight?: boolean;
    /**
     * Allow the content to overflow the box frame. Useful to display tooltips for content within the box, which might
     * extend beyond the box's bounds.
     */
    withOverflow?: boolean;
}
const isImage = (background: BoxBackground): background is BackgroundImage => typeof background === "object";

const Box: FC<BoxProps> = (
    props
): JSX.Element => {
    const style: CSSProperties = {}
    if (isImage(props.background)) {
        const backgroundLayers: Array<string> = []
        if (props.background.gradient) {
            backgroundLayers.push(`linear-gradient(0deg, ${props.background.gradient} 0%, ${props.background.gradient} 100%)`)
        }
        backgroundLayers.push(`url(${props.background.imageUrl})`)
        style.backgroundImage = backgroundLayers.join(', ')
        if (props.background.position) {
            style.backgroundPosition = props.background.position
        }
    }
    return <div
        style={Object.keys(style).length > 0 ? style : undefined}
        className={
            classNames(
                styles.box,
                {
                    [styles.boxColorWhite]: props.background === BoxColor.WHITE,
                    [styles.boxColorBlue]: props.background === BoxColor.BLUE,
                    [styles.boxColorBold]: props.background === BoxColor.BOLD,
                    [styles.boxColorIntense]: props.background === BoxColor.INTENSE,
                    [styles.boxColorLavenderWeb]: props.background === BoxColor.LAVENDERWEB,
                    [styles.boxColorVioletBlue]: props.background === BoxColor.VIOLET_BLUE,
                    [styles.boxColorOrange]: props.background === BoxColor.ORANGE,
                    [styles.boxColorDarkOrange]: props.background === BoxColor.DARK_ORANGE,
                    [styles.boxColorYellow]: props.background === BoxColor.YELLOW,

                    [styles.boxColorGradientBlue]: props.background === BoxColor.GRADIENT_BLUE,
                    [styles.boxColorGradientDarkBlue]: props.background === BoxColor.GRADIENT_DARK_BLUE,
                    [styles.boxColorGradientOrange]: props.background === BoxColor.GRADIENT_ORANGE,

                    [styles.boxImageBackground]: isImage(props.background),
                    [styles.boxWithBoxShadow]: props.withBoxShadow,
                    [styles.boxWithBoxShadowHoverEffect]: props.withBoxShadow == 'hover',

                    // Overscroll
                    [styles.boxWithFixedHeightHEIGHT_SMALL]: props.withFixedHeight === BoxHeight.SMALL,
                    [styles.boxWithFixedHeightHEIGHT_MEDIUM]: props.withFixedHeight === BoxHeight.MEDIUM,
                    [styles.boxWithFixedHeightHEIGHT_NORMAL]: props.withFixedHeight === BoxHeight.NORMAL,
                    [styles.boxWithFixedHeightHEIGHT_29]: props.withFixedHeight === BoxHeight.HEIGHT_29,
                    [styles.boxWithMaxHeight]: props.withMaxHeight,
                    [styles.boxWithFullWidth]: props.withFullWidth,
                    [styles.boxWithFullHeight]: props.withFullHeight,
                    [styles.boxWithFixedWidthWIDTH_LARGE]: props.withFixedWidth === BoxWidth.WIDTH_LARGE,
                    [styles.boxWithFixedWidthWIDTH_50]: props.withFixedWidth === BoxWidth.WIDTH_50,
                    [styles.boxWithBorderRadius]: props.withBorderRadius,
                    [styles.boxWithRadius]: props.withRadius,
                    [styles.boxWithPadding]: props.withPadding,
                    [styles.boxWithOverflow]: props.withOverflow,
                }
            )
        }>
        {props.children}
    </div>
}

export const WhiteBoxWithShadow = (props: PropsWithChildren<{
    withOverflow?: boolean;
}>): JSX.Element =>
    <Box background={BoxColor.WHITE} withBoxShadow withOverflow={props.withOverflow}>
        {props.children}
    </Box>;

type OverscrollProps = PropsWithChildren<{
    withFixedHeight?: BoxHeight
    withFixedWidth?: BoxWidth
}>;

export const OverScrollBox = (props: OverscrollProps): JSX.Element =>
    <Box background={BoxColor.WHITE} withFixedHeight={props.withFixedHeight} withFixedWidth={props.withFixedWidth}
         withBorderRadius
         withBorder={{color: BoxBorderColor.DISABLED}} withPadding withMaxHeight
    >
        {props.children}
    </Box>;

export default Box;