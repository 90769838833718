import { PillSeverity } from "components/designSystem/components/Pill";
import SeverityBrief from "components/designSystem/components/SeverityBrief";
import { removeNulls } from "core/utils/List";
import { ReportMetadataNormality } from "models/medicalReport/ReportModels";
import React, { ReactNode } from "react";

export function buildSeverityBrief(normalityCount: {[k in ReportMetadataNormality]?: number | null}): ReactNode {
    const severities = removeNulls(
        Object.entries(normalityCount).map(
            ([k, v]) => {
                switch (k) {
                    case ReportMetadataNormality.NORMAL:
                        return {severity: PillSeverity.SAFE, count: v}
                    case ReportMetadataNormality.PARTIALLY_BAD:
                        return {severity: PillSeverity.WARNING, count: v}
                    case ReportMetadataNormality.BAD:
                        return {severity: PillSeverity.CRITICAL, count: v}
                    case ReportMetadataNormality.UNKNOWN:
                        return {severity: PillSeverity.EMPTY, count: v}
                    default:
                        return null
                }
            }
        ))

    if (severities.length == 0) {
        return undefined
    }

    return  <SeverityBrief
        severities={severities}
    />
}
