import { ResetPasswordResponse } from "models/user/AuthModels";
import { HTTPVerb, PostEndpoint } from "sagas/call/endpoints";

export const ResetPasswordRequest: PostEndpoint<{ password: string; confirmation: string; token: string }, ResetPasswordResponse> = {
    path: '/user/reset-password',
    verb: HTTPVerb.POST
}


export const SetPinRequest: PostEndpoint<{ pin: string }, {  }> = {
    path: '/user/set-pin',
    verb: HTTPVerb.POST
}
