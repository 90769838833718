import { MultipleValuesRating } from "components/designSystem/components/ratings/MultipleValueRating";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import React from 'react'

enum LikeDislikeRatingValues {
    LIKE = 'LIKE',
    DISLIKE = 'DISLIKE'
}

export interface LikeDislikeRatingProps {
    rate: (rating: string) => void,
}

const LikeDislikeRating = (props: LikeDislikeRatingProps): JSX.Element => {
    return <MultipleValuesRating<string>
        values={[{
            value: LikeDislikeRatingValues.LIKE.valueOf(),
            icons: {
                default: {name: IconName.THUMBS_UP, color: IconColor.SUBDUED},
                filled: {name: IconName.FILL_THUMBS_UP, color: IconColor.DEFAULT},
                size: IconSize.MEDIUM
            }
        }, {
            value: LikeDislikeRatingValues.DISLIKE.valueOf(),
            icons: {
                default: {name: IconName.THUMBS_DOWN, color: IconColor.SUBDUED},
                filled: {name: IconName.FILL_THUMBS_DOWN, color: IconColor.DEFAULT},
                size: IconSize.MEDIUM
            }
        }]}
        rate={props.rate}
    />
}

export default LikeDislikeRating;