import React from 'react';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_EULA_DATA_PRIVACY } from 'core/routing/Routes';

import 'components/modules/eula/eula.scss';
import Title, { TitleType } from 'components/core/text/Title';

const EulaPatientV3 = (): JSX.Element => {
    return (
        <div>
            <Title type={TitleType.HEADING1} className={'eula-title'}>
                Conditions Générales d'Utilisation - Patients
            </Title>
            <div className="eula-paragraph">
                <div className="eula-paragraph-content">
                    <b>Bienvenue sur Kiro ! </b>
                    Kiro (ci-après « <b>Kiro</b> » pour les besoins des présentes) est une solution simple et sécurisée
                    de suivi des analyses de biologie médicale, à destination des professionnels de santé et des
                    patients. Notre objectif est d’améliorer l’expérience du parcours de soins, à travers des échanges
                    plus fluides, interactifs et pertinents. Les présentes conditions d'utilisation traduisent le
                    fonctionnement de Kiro et certains principes fondamentaux de notre démarche. Elles sont destinées
                    aux patients et contribuent à définir votre relation avec Kiro lorsque vous interagissez avec notre
                    solution et nos services. Il est essentiel de bien comprendre ces conditions, car vous devez
                    obligatoirement les accepter pour utiliser notre solution et nos services. Nous vous invitons donc à
                    les lire attentivement. Si vous avez une quelconque question sur nos conditions générales
                    d’utilisation, n’hésitez pas à envoyer un e-mail à{' '}
                    <a className={"legacy-a"} href="mailto:contact@kiro.bio">contact@kiro.bio</a>.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">1. Définitions</div>
                <div className="eula-paragraph-content">
                    Dans les présentes conditions générales d’utilisation, les termes identifiés ci-dessous par une
                    majuscule ont la signification suivante, qu’ils soient employés au singulier ou au pluriel:
                    <ul>
                        <li>
                            <b>CGU</b> : désigne les présentes conditions générales d’utilisation ;
                        </li>
                        <br />
                        <li>
                            <b>Compte Kiro</b> : désigne un compte permettant à un Utilisateur de se connecter à la
                            Solution de manière authentifiée et sécurisée et d’accéder à un ensemble de fonctionnalités
                            spécifiques à la nature de l’Utilisateur ;
                        </li>
                        <br />
                        <li>
                            <b>Contenus</b> : désigne l’ensemble des textes, images, fonctionnalités et plus largement
                            tout élément existant au sein de la Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Compte Rendu de Biologie</b> : désigne l’ensemble des résultats d’analyses de biologie
                            médicale pratiqués sur les Patients, reçus par l’Utilisateur sur la Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Données Personnelles</b> : désigne toute donnée qui, au sens de la Réglementation
                            applicable à la protection des données personnelles, permet de désigner ou d'identifier,
                            directement ou indirectement, une personne physique, notamment par référence à son état
                            civil, un identifiant, un numéro d’identification, des données de localisation, un
                            identifiant en ligne ou à un ou plusieurs éléments spécifiques qui lui sont propres ;
                        </li>
                        <br />
                        <li>
                            <b>Données Personnelles de Santé</b> : désigne une Donnée Personnelle relative à la santé
                            physique ou mentale d'une personne physique, y compris la prestation de services de soins de
                            santé, qui révèlent les informations sur l'état de santé de cette personne, indépendamment
                            de leur source, qu'elles proviennent par exemple d'un médecin ou d'un autre Professionnel de
                            Santé, d'un hôpital, d'un dispositif médical ou d'un test de diagnostic in vitro et
                            recueillies par exemple à l’occasion d’activités de prévention, de diagnostic, de soins ou
                            de suivi social et médico-social ;
                        </li>
                        <br />
                        <li>
                            <b>Données Patients</b> : désigne les Données Personnelles relatives au Patient, y compris
                            les Données Personnelles de Santé et les données de biologie médicale, collectées par le
                            Laboratoire ou reçues et collectées par le Professionnel de Santé dans le cadre du parcours
                            de soins du Patient auquel Kiro n’est pas partie, et communiquées à l’Utilisateur au travers
                            de la Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Identifiants</b> : désigne l’identifiant personnel associé au compte de l’Utilisateur
                            ainsi que tout code confidentiel ou mot de passe délivré à l’Utilisateur par Kiro puis
                            modifié par l’Utilisateur et permettant à l’Utilisateur de s’identifier afin d’accéder à la
                            Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Laboratoire</b> : désigne le laboratoire de biologie médicale ayant souscrit un contrat
                            de partenariat avec Kiro aux fins de permettre audit laboratoire de proposer les Services
                            aux Utilisateurs, au travers de la Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Patient</b> : désigne toute personne physique prise en charge par un Professionnel de
                            Santé et dont les Données Patients sont traitées au travers de la Solution par le
                            Laboratoire ou l’Utilisateur ;
                        </li>
                        <br />
                        <li>
                            <b>Professionnel de santé</b> : désigne tout professionnel de santé exerçant au sein d'une
                            structure libérale ou d'un établissement de santé, notamment enregistré auprès de son ordre
                            professionnel ou de son autorité d'enregistrement ;
                        </li>
                        <br />
                        <li>
                            <b>Réglementation applicable à la protection des données personnelles</b> : désigne toute
                            réglementation applicable à la protection des données personnelles et en particulier le
                            Règlement (UE) n°2016/679 du Parlement Européen et du Conseil du 27 avril 2016 dit «
                            Règlement Général sur la Protection des Données » et la loi n°78-17 du 6 janvier 1978
                            modifiée dite « Loi Informatique et Libertés » ;
                        </li>
                        <br />
                        <li>
                            <b>Services</b> : désignent conjointement le Service de Visualisation, le Service de
                            Délégation et le service d’aide à la prescription et au diagnostic, proposés par le
                            Laboratoire aux Utilisateurs à travers la Solution en exécution d’un contrat de partenariat
                            entre Kiro et le Laboratoire, et mis à disposition des Utilisateurs autorisés à y accéder à
                            travers la Solution. Le service d’aide à la prescription et au diagnostic n’est pas
                            disponible pour les Patients ;
                        </li>
                        <br />
                        <li>
                            <b>Service de Délégation</b> : désigne le service permettant à un Utilisateur Patient
                            d’autoriser un autre Utilisateur à consulter ses documents et ses Comptes Rendus de Biologie
                            et/ou à réaliser des actions à sa place, et mis à disposition par le Laboratoire aux
                            Utilisateurs autorisés à y accéder à travers la Solution, et tel que décrit à l’article «
                            Description de la Solution » ci-après ;
                        </li>
                        <br />
                        <li>
                            <b>Service de Visualisation</b> : désigne le service d’aide à la visualisation du
                            compte-rendu d’examens et des résultats de biologie médicale du Patient, à partir des
                            informations fournies par le Laboratoire et mis à disposition par le Laboratoire aux
                            Utilisateurs autorisés à y accéder à travers la Solution, et tel que décrit à l’article «
                            Description de la Solution » ci-après ;
                        </li>
                        <br />
                        <li>
                            <b>Solution</b> : désigne la solution logicielle développée par Kiro, mise à disposition des
                            Utilisateurs et accessible par Internet à l’adresse{' '}
                            <a className={"legacy-a"} target={'_blank'} rel="noopener noreferrer" href="https://app.kiro.bio">
                                app.kiro.bio
                            </a>
                        </li>
                        <br />
                        <li>
                            <b>Utilisateur</b> : désigne le Patient ou le Professionnel de Santé et, le cas échéant, les
                            membres de son équipe soumis au secret professionnel et agissant sous la responsabilité du
                            Professionnel de Santé et/ou tout autre Professionnel de Santé autorisé à accéder aux
                            Données Patients conformément aux dispositions légales et déontologiques applicables, qui
                            dispose d’un accès et utilise la Solution.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">2. Objet des CGU, acceptation et version en vigueur</div>
                <div className="eula-paragraph-content">
                    <div className="eula-paragraph-subtitle-1">2.1 Objet</div>
                    Les présentes CGU ont pour objet d’encadrer les modalités et conditions d’accès et d’utilisation de
                    la Solution, des Services et des Contenus, ainsi que de définir les droits et obligations de
                    l’Utilisateur dans ce cadre. Les présentes CGU sont destinées aux Patients. Les CGU constituent un
                    contrat entre l’Utilisateur et Kiro. Il est essentiel de bien comprendre ces conditions, car vous
                    devez obligatoirement les accepter pour utiliser la Solution et les Services. Nous vous invitons à
                    en prendre attentivement connaissance.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">2.2 Acceptation des CGU</div>
                    L’accès et l’utilisation de la Solution, des Contenus et des Services sont soumis au préalable à la
                    prise de connaissance et à l’acceptation expresse et sans réserve par l’Utilisateur des CGU. En
                    cliquant sur le bouton « Se Connecter » vous reconnaissez avoir pris connaissance et accepter
                    l’intégralité des présentes CGU. L’utilisation de la Solution et des Services vaut également
                    acception des présentes CGU. Le défaut d’acceptation des CGU impliquera l’impossibilité pour
                    l’Utilisateur d’accéder et d’utiliser la Solution et les Services.
                    <br />
                    <br />
                    L’Utilisateur est, dans le même temps, invité expressément à lire{' '}
                    <a
                        target={'_blank'}
                        rel="noopener noreferrer"
                        href={getRoute(ROUTE_EULA_DATA_PRIVACY, { version: null })}
                    >
                        la politique de protection des données personnelles
                    </a>{' '}
                    de la Solution. La politique de protection des données personnelles fait partie intégrante des CGU.
                    En utilisant la Solution et les Services, l’Utilisateur reconnait expressément avoir lu la politique
                    de protection des données personnelles et en avoir accepté les termes.
                    <br />
                    <br />
                    Si l’Utilisateur n’est pas en accord avec tout ou partie des CGU, il lui est vivement recommandé de
                    ne pas utiliser la Solution, les Contenus et les Services.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">2.3 Version en vigueur</div>
                    Kiro est libre de modifier et/ou de mettre à jour à tout moment les CGU sans préavis, afin notamment
                    de prendre en compte toute évolution légale, réglementaire, jurisprudentielle et/ou technique.
                    L’Utilisateur est donc informé que l’unique version des CGU qui fait foi est celle qui se trouve en
                    ligne au moment de la connexion de l’Utilisateur, telles qu’elles sont disponibles à l’adresse
                    suivante https://app.kiro.bio/eula-patient/, ce qu’il reconnaît et accepte sans restriction,
                    s’engageant à s’y référer systématiquement lors de chaque connexion. L’accès et l’utilisation de la
                    Solution et des Services seront soumis aux CGU en vigueur au moment de cet accès et de cette
                    utilisation.
                    <br />
                    <br />
                    Le fait de continuer à utiliser la Solution et/ou les Services après toute modification des CGU
                    entraîne l’acceptation des modifications des CGU. En cas de non-respect par l’Utilisateur des CGU,
                    Kiro se réserve le droit de de suspendre ou de bloquer définitivement l’accès de l’Utilisateur à la
                    Solution et/ou aux Services, de plein droit et sans préavis.
                    <br />
                    <br />
                    Dans l'hypothèse où l'une des clauses des CGU serait nulle ou inapplicables pour quelque raison que
                    ce soit, y compris du fait d'un changement de législation ou de réglementation ou déclarée comme
                    telle par une décision de justice définitive, cela ne saurait en aucun cas affecter la validité et
                    le respect des autres clauses des CGU, de sorte que, sauf impossibilité, les CGU poursuivent leurs
                    effets sans discontinuité. L’Utilisateur et Kiro resteront liées par les autres stipulations des CGU
                    et s’efforceront de remédier aux clauses inapplicables dans le même esprit que celui qui a présidé
                    lors de la conclusion.
                    <br />
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">3. Description de la Solution et des Services</div>
                <div className="eula-paragraph-content">
                    La Solution est une plateforme logicielle de suivi des analyses de biologie médicale, à destination
                    des Professionnels de Santé et des Patients. Elle offre la possibilité à l’Utilisateur de recevoir
                    sur son Compte Kiro des informations, des documents et des Comptes Rendus de Biologie de manière
                    dématérialisée ainsi qu’à d’autres fonctionnalités dont des notifications et d’accéder, s’il est
                    autorisé par le Laboratoire, aux Services, réalisés notamment grâce à des algorithmes d’intelligence
                    artificielle. La Solution et les Services utilisent pour la réception des documents et Comptes
                    Rendus de Biologie, les canaux de réception existants, notamment par le biais des messageries
                    sécurisées de santé, à travers la Solution, ou une transmission directe du Laboratoire et/ou des
                    Professionnels de Santé sur la Solution. L’Utilisateur peut accéder à l’historique de ses Comptes
                    Rendus de Biologie et peut également rechercher un Compte Rendu de Biologie transmis par le
                    Professionnel de Santé ou le Laboratoire.
                    <br />
                    <br />
                    Il est entendu et accepté que l’accès à la Solution est strictement limité aux seules Données
                    Patients des Patients pour lesquels l’Utilisateur est (i) ledit Patient qui s’est rendu au
                    laboratoire de biologie médicale ayant réalisé et validé le compte rendu et les résultats de
                    biologie médicale disponible sur la Solution et/ou les Services, (ii) un Utilisateur tiers autorisé
                    expressément par le Patient à consulter ses documents et à réaliser des actions à sa place à travers
                    le Service de Délégation ou (iii) autorisé expressément par le Patient en tant que représentant
                    légal (parents, tuteurs).
                    <br />
                    <br />
                    Kiro n’a aucun contrôle sur l’accès aux Données Patients qui pourrait être donné par le Laboratoire
                    ou un Utilisateur à un autre Utilisateur ou un tiers. En aucun cas la responsabilité de Kiro ne
                    saurait être engagée de ce fait, l’Utilisateur étant seul maitre de la bonne utilisation de la
                    Solution et des Services, conformément à l’article « Utilisation de la Solution » ci-après et, plus
                    généralement, des lois et réglementations applicables.
                    <br />
                    <br />
                    En plus de la Solution et de ses fonctionnalités, Kiro offre à certains Utilisateurs Patients la
                    possibilité d’utiliser différents Services :
                    <ul>
                        <li>un Service de Délégation</li>
                        <li>un Service de Visualisation</li>
                    </ul>
                    <b>MISE EN GARDE CONCERNANT L’ENSEMBLE DE LA SOLUTION ET DES SERVICES : </b>Les informations
                    fournies par la Solution, les Contenus et les Services n’ont en aucun cas vocation à se substituer à
                    l’avis du Professionnel de Santé et aux consultations médicales avec le Professionnel de Santé ni
                    constituer un conseil médical ou une consultation d’aucune sorte. Seuls les Professionnels de Santé
                    compétents sont habilités à prescrire et/ou dispenser les traitements et/ou examens de biologie
                    médicale appropriés et en déterminer leur fréquence et durée. Les informations et Contenus sont
                    fournis purement à titre informatif et indicatif dans le cadre de la Solution et des Services, et
                    tendent à être scientifiquement exacts au moment de leur publication, fiables et pertinents. Malgré
                    tout le soin apporté au traitement des informations et des Contenus, Kiro décline toute
                    responsabilité concernant les erreurs ou omissions portant sur les informations diffusées dans le
                    cadre de la Solution ou des Services.
                    <br />
                    <br />
                    KIRO NE SAURAIT ÊTRE TENUE RESPONSABLE DE L'INTERPRÉTATION OU DE L’UTILISATION DES CONTENUS ET DES
                    INFORMATIONS DIFFUSÉS PAR L'INTERMÉDIAIRE DE LA SOLUTION ET/OU DES SERVICES, NI DES CONSÉQUENCES.
                    <br />
                    <br />
                    De manière générale, l’Utilisateur reconnait expressément que les informations contenues dans un
                    Compte Rendu de Biologie ne constituent qu’une partie de l'image biologique complexe de la santé ou
                    de la maladie du Patient. L’UTILISATEUR RECONNAÎT ÉGALEMENT QU’IL PREND SEUL LES DÉCISIONS
                    CONCERNANT SA SANTÉ AU REGARD DES INFORMATIONS ET DES PRÉCONISATIONS FOURNIES PAR LE PROFESSIONNEL
                    DE SANTÉ HABITUELLEMENT EN CHARGE DE SON SUIVI, ET CE MALGRE DES SERVICES RENDUS A L’OCCASION DE
                    L’UTILISATION DE LA SOLUTION ET/OU DES SERVICES. Il appartient à l’Utilisateur de vérifier
                    préalablement l'adéquation des Contenus et des Services à ses besoins. La Solution et/ou les
                    Services ne dispense pas l’Utilisateur de vérifier les informations disponibles auprès des autorités
                    et toutes autres sources officielles.
                    <br />
                    <br />
                    L’UTILISATEUR RECONNAIT QUE LA SOLUTION, LES CONTENUS ET/OU LES SERVICES NE PERMETTENT EN AUCUN CAS
                    LA REALISATION DE DIAGNOSTIC, DE PRESCRIPTION OU LA PRISE EN CHARGE MEDICALE ET NE CONSTITUENT NI
                    DIRECTEMENT, NI INDIRECTEMENT UNE CONSULTATION MEDICALE. LES INFORMATIONS GENERALES SUR DES
                    THEMATIQUES EN LIEN AVEC LA SANTE QUI PEUVENT LUI ETRE FOURNIES LE SONT A TITRE INDICATIF.
                    <br />
                    <br />
                    L’Utilisateur conserve toujours le choix des sources et des Professionnels de Santé qu’il consulte
                    conformément aux dispositions légales et déontologiques applicables aux professions réglementées et
                    aux dispositions contraignantes applicables aux professions non réglementées, et sous leur
                    responsabilité exclusive ; Kiro n’endosse aucune responsabilité à ce titre. Il est recommandé à
                    l’Utilisateur de contacter le laboratoire qui a validé les résultats des analyses et le Compte Rendu
                    de Biologie ou tout autre laboratoire dans lequel le Patient a fait ses examens, si les conditions
                    d’utilisation qu’il juge nécessaires ne sont pas réunies, ou pour une meilleure prise en charge.
                    <br />
                    <br />
                    TOUTE SITUATION D’URGENCE MÉDICALE DOIT CONDUIRE L’UTILISATEUR À CONTACTER IMMÉDIATEMENT LES
                    SERVICES D’URGENCE COMPÉTENTS. L’UTILISATEUR RECONNAIT EXPRESSEMENT AVOIR ETE INFORME QUE LA
                    SOLUTION, LES CONTENUS ET LES SERVICES NE PERMETTENT PAS LA GESTION NI LE SUIVI DES SITUATIONS
                    D’URGENCE.
                    <br />
                    <br />
                    Kiro ne garantit en aucun cas à l’Utilisateur l’exhaustivité et/ou la qualité des résultats et des
                    Comptes Rendus de Biologie visibles sur la Solution et les Services. Les résultats et Comptes Rendus
                    de Biologie sont édités exclusivement et sous la seule responsabilité du laboratoire qui a validé
                    les résultats et le Compte Rendu de Biologie, la Solution et les Services étant uniquement un moyen
                    de communication et un moyen d'afficher lesdits résultats. De même, Kiro ne saurait garantir à
                    l’Utilisateur que l’ensemble des Comptes Rendus de Biologie du Patient soit historisé et/ou
                    disponibles sur la Solution. Cette historisation et/ou disponibilité dépendent des choix du Patient
                    et de l’Utilisateur, notamment concernant ses canaux de réception. Le Patient est par ailleurs libre
                    du choix du laboratoire de biologie médicale dans lequel il réalise les examens qui lui sont
                    prescrits. En particulier si ce laboratoire n’a pas souscrit de contrat avec Kiro ou si le
                    Laboratoire ne les a pas communiqués à Kiro, les Comptes Rendus de Biologie réalisés peuvent ne pas
                    être disponibles dans l’historique du Patient sur la Solution.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-2">Service de Délégation</div>
                    Le Service de Délégation offre à l’Utilisateur la possibilité d’autoriser à une personne physique
                    autre que le Patient (ci-après la « <b>Personne Autorisée</b>
                    {' '}»), à consulter ses documents et ses Comptes Rendus de Biologie et plus généralement ses
                    Données Patients et/ou à réaliser des actions à sa place. En déléguant l’accès à ses Données
                    Patients, l’Utilisateur peut permettre l’accès à tout ou partie de ses résultats et Comptes Rendus
                    de Biologie à la Personne Autorisée sans communiquer ses Identifiants, à partir de son Compte Kiro.
                    <br />
                    <br />
                    La Personne Autorisée peut être :
                    <ul>
                        <li>un membre de la famille du Patient, ou une personne de confiance</li>
                        <li>un Professionnel de Santé</li>
                        <li>plus généralement un autre Utilisateur connu du Patient</li>
                    </ul>
                    Pour visualiser les documents et Comptes Rendus de Biologie de l’Utilisateur, la Personne Autorisée
                    devra disposer d’un Compte Kiro. Si la Personne Autorisée ne dispose pas d’un Compte Kiro, elle
                    devra préalablement s’inscrire sur la Solution avant de pouvoir accéder aux documents et Comptes
                    Rendus de Biologie pour lesquels elle a été autorisée.
                    <br />
                    <br />
                    L’Utilisateur accepte et reconnait que le fait d’autoriser une Personne Autorisée à consulter ses
                    documents et ses Comptes Rendus de Biologie et plus généralement ses Données Patients et/ou à
                    réaliser des actions à sa place constitue un consentement exprès, volontaire, libre et éclairé de la
                    part de l’Utilisateur. En particulier, l’Utilisateur accepte et reconnait qu’une telle autorisation
                    constitue de sa part une manifestation de volonté, libre, spécifique, éclairée et univoque par
                    laquelle l’Utilisateur accepte que des Données Personnelles le concernant fassent l'objet d'une
                    utilisation et/ou d’un traitement de la part de la Personne Autorisé. La finalité du recueil de ce
                    consentement de l’Utilisateur est définie par la volonté de l’Utilisateur de partager ses documents,
                    ses Comptes Rendus de Biologie et ses Données Patients avec la Personne Autorisée. L’Utilisateur
                    pourra à tout moment retirer l’autorisation délivrée à la Personne Autorisée, soit à partir du
                    Service de Délégation, soit en envoyant sa demande à Kiro.
                    <br />
                    <br />
                    En conséquence, toute autorisation recueillie à travers l’utilisation du Service de Délégation sur
                    le Compte Kiro de l’Utilisateur et les communications de données qui en résultent, seront réputées
                    être un consentement exprès et préalable de de l’Utilisateur recueilli de façon dématérialisée au
                    sens et dans les conditions définies de la Réglementation applicable à la protection des données
                    personnelles et avoir été effectué sous la responsabilité exclusive de l’Utilisateur, dans les
                    conditions définies à l’article « Convention de preuve » des présentes CGU.
                    <br />
                    <br />
                    L’Utilisateur accepte et reconnait avoir été préalablement informé des catégories d’informations
                    ayant vocation à être partagées avec la Personne Autorisée, de la nature des supports utilisés pour
                    partager lesdites informations, des mesures prises pour préserver leur sécurité et notamment les
                    restrictions d’accès, du caractère de preuve de consentement de cette autorisation, ainsi que dans
                    le cas où la Personne Autorisée est un Professionnel de Santé, de l’identité et de la catégorie du
                    Professionnel de Santé autorisé ainsi que sa qualité de responsable de traitement au sens de la
                    Réglementation applicable à la protection des données personnelles pour lesdites Données Patients.
                    <br />
                    <br />
                    Il est précisé que la Personne Autorisée n'aura plus accès aux documents et Comptes Rendus de
                    Biologie de l’Utilisateur en cas de révocation de son mandat par l’Utilisateur, de perte de la
                    qualité de représentant légal et en cas de décès ou de mise sous un régime de protection de
                    l’Utilisateur. Il est par ailleurs précisé que la Personne Autorisée agissant en qualité de
                    représentant légal d’un mineur ou d’un majeur protégé doit se conformer aux dispositions légales
                    et/ou décisions judiciaires définissant le régime de protection desdits protégés.
                    <br />
                    <br />
                    <b>MISE EN GARDE : </b>Kiro met en garde l’Utilisateur que les Données Personnelles partagées dans
                    le cadre du Service de Délégation sont des données sensibles et confidentielles, qui demandent une
                    vigilance particulière, y compris de la part de l’Utilisateur et de la Personne Autorisée.
                    <br />
                    <br />
                    L’utilisation du Service de Délégation se fait sous l’entière responsabilité de l’Utilisateur.
                    L’Utilisateur reconnaît être informé qu’il assume exclusivement les conséquences du partage de ses
                    données auprès de la Personne Autorisée. L’Utilisateur est le seul responsable des autorisations
                    attribuées à travers le Service de Délégation.
                    <br />
                    <br />
                    En aucun cas Kiro ne saurait être tenue responsable des conséquences éventuelles pouvant résulter
                    d’une quelconque erreur d’autorisation, de délégation, de gestion ou plus généralement d’utilisation
                    de la Solution ou du Service de Délégation par l’Utilisateur ou une Personne Autorisée par
                    l’Utilisateur. De même, Kiro ne saurait être tenue responsable de l'interprétation ou de
                    l’utilisation par la Personne Autorisée des Contenus et informations diffusés par l'intermédiaire de
                    la Solution et/ou des Services, ni des conséquences sur l’Utilisateur.
                    <br />
                    <br />
                    L’accord d’une délégation ou d’une autorisation par l’Utilisateur à tout tiers est fait sous la
                    seule et unique responsabilité de l’Utilisateur ; le Laboratoire n’est pas informé de la mise en
                    place de ces délégations et ne peut être tenu responsable en cas de changement de situation (ex :
                    séparation, divorce, ...) qui devront nécessiter la mise à jour des autorisations fournies par
                    l’Utilisateur. L’Utilisateur reconnait être informé qu’il assume seul et exclusivement la
                    non-modification de ses autorisations.
                    <br />
                    <br />
                    Kiro se réserve le droit de suspendre, bloquer ou résilier de plein droit, temporairement ou
                    définitivement, tout ou partie des autorisations de la Personne Autorisée, sans mise en demeure
                    préalable, sans préavis ni indemnité de quelque nature que ce soit, en cas d’utilisation de la
                    Solution ou des Services non conforme aux CGU, en cas d’infraction, manquement ou violation à l’une
                    quelconque des dispositions des présentes CGU, en cas de tentative de connexion non autorisée par
                    une utilisation frauduleuse du système ou par une usurpation de codes d’accès ou des Identifiants,
                    ou plus généralement en cas de violation des lois et réglementations en vigueur.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-2">Service de Visualisation</div>
                    Le Service de Visualisation offre à l’Utilisateur une meilleure visibilité dans l’affichage des
                    Comptes Rendus de Biologie du Patient qui lui auront été transmis par le Laboratoire au travers de
                    la Solution ainsi que des données médicales enregistrées par le Laboratoire, conformément au contrat
                    passé entre Kiro et le Laboratoire.
                    <br />
                    <br />
                    Le Service de Visualisation dispose d’une fonctionnalité permettant à l’Utilisateur de télécharger,
                    à tout moment, le Compte Rendu de Biologie d’origine du Laboratoire sous format HPRIM et/ou PDF
                    conforme aux Articles D6211-3 et R. 6222-3 du Code de la Santé Publique (ci-après le{' '}
                    <b>« Compte Rendu d’Origine »</b>), selon le format de distribution choisi par le Laboratoire.
                    L’Utilisateur reconnait et accepte expressément qu’il est de sa responsabilité de télécharger en
                    toutes circonstances le Compte Rendu d’Origine pour s’y référer, en particulier si l’état de santé
                    du Patient le nécessite. Le Compte Rendu d’Origine délivré par le Laboratoire au format HPRIM ou PDF
                    prévaudra en tout état de cause en cas de doute ou suspicion de doute quant à la qualité et/ou la
                    pertinence des éléments et Contenus fournis par le Service de Visualisation et plus généralement la
                    Solution.
                    <br />
                    <br />
                    <b>MISE EN GARDE : </b>
                    LES INFORMATIONS ET LES CONTENUS, ET NOTAMMENT LES DESCRIPRIONS, MIS A DISPOSITION DE L’UTILISATEUR
                    SUR LA SOLUTION ET SUR LE SERVICE DE VISUALISATION SONT FOURNIS A L’UTILISATEUR A TITRE STRICTEMENT
                    INFORMATIF ET INDICATIF. Ils sont généralement disponibles en ligne ou auprès de différentes
                    sources, et ne sont pas délivrés par Kiro comme un conseil ou avis médical. Ils ne doivent donc en
                    aucun cas être pris comme tel. Kiro fait ses meilleurs efforts pour maintenir le Service de
                    Visualisation à jour et diffuser des informations fiables, licites et validées par des experts
                    compétents (médecins et/ou biologistes notamment). Cependant, malgré tout le soin et l’attention
                    apportés à la sélection des sources et à la rédaction des Contenus et informations, Kiro ne saurait
                    pour autant garantir l’intégrité, l'exactitude, l'exhaustivité, l’actualité ou autre qualité des
                    informations et Contenus fournis.
                    <br />
                    <br />
                    LE SERVICE DE VISUALISATION EST FOURNI PAR LE LABORTOIRE AUX PATIENTS DANS LE RESPECT DE LEURS
                    COMPÉTENCES, EN EXÉCUTION DE LEURS OBLIGATIONS LÉGALES, RÉGLEMENTAIRES, DÉONTOLOGIQUES ET
                    CONVENTIONNELLES ET SOUS LEUR RESPONSABILITÉ EXCLUSIVE ; KIRO N’ENDOSSE AUCUNE RESPONSABILITÉ A CE
                    TITRE.
                    <br />
                    <br />
                    Kiro ne saurait être tenue responsable des conséquences éventuelles pouvant résulter d’une erreur de
                    prescription, de délivrance, d’administration, d’indication, de prise en charge du Patient ou plus
                    généralement d’utilisation de la Solution ou des Services.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-2">
                        Notifications et autres fonctionnalités de la Solution
                    </div>
                    L’Utilisateur autorise expressément le Laboratoire, à travers Kiro, et/ou Kiro à lui notifier par
                    SMS, « notifications push » et/ou e-mail toute information pratique concernant le Laboratoire, ou
                    toute information au sujet des Comptes Rendus de Biologie reçus par l’Utilisateur et mis à sa
                    disposition sur la Solution.
                    <br />
                    <br />
                    <b>MISE EN GARDE :</b> Kiro et le Laboratoire déclinent toute responsabilité en cas d’erreur dans la
                    saisie par l’Utilisateur de ses coordonnées retardant ou rendant impossible l’envoi de toute
                    notification nécessaire au bon fonctionnement et à la bonne administration des Services et/ou de la
                    Solution. Il appartient à l’Utilisateur de faire toutes vérifications qui semblent nécessaires ou
                    opportunes avant de procéder à l’utilisation de la Solution et/ou des Services. L’exactitude et la
                    mise à jour de ces données sont sous l’entière responsabilité de l’Utilisateur.
                    <br />
                    <br />
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">4. Gratuité des Services</div>
                <div className="eula-paragraph-content">
                    L’accès à la Solution pour la réception de Comptes Rendus de Biologie, à l’exception des Services,
                    par l’Utilisateur est gratuit. Les Services développés par Kiro sont mis gratuitement à disposition
                    de l’Utilisateur par le Laboratoire, conformément au contrat passé entre ce dernier et Kiro.
                    <br />
                    <br />
                    Tous les coûts afférents à l’accès à la Solution et/ou aux Services et à leur utilisation que ce
                    soient les équipements (notamment ordinateur, téléphone, moyens de communication électronique), les
                    frais logiciels, matériels ou les autres frais de communications électroniques (notamment les coûts
                    d’accès à Internet) résultant de leur utilisation, sont exclusivement à la charge de l’Utilisateur.
                    L’Utilisateur est seul redevable de leurs prix. Il appartient à l’Utilisateur de s’informer du prix
                    d’utilisation desdits équipements ou services auprès des fournisseurs concernés.
                    <br />
                    <br />
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">5. Inscription et modalités d’accès à la Solution</div>
                <div className="eula-paragraph-content">
                    <div className="eula-paragraph-subtitle-1">5.1 Conditions préalables</div>
                    La Solution et les Services s’adressent généralement à des personnes de plus de 18 ans capables de
                    contracter des obligations conformément à la législation française. En conséquence, l’accès à la
                    Solution et/ou aux Services est réservé aux personnes physiques de plus de 18 ans.
                    <br />
                    <br />
                    Les Utilisateurs s’interdisent d’utiliser la Solution, ses Contenus et les Services pour assurer la
                    promotion d’un produit, d’un service, d’un établissement de santé, d’un professionnel de santé ou
                    d’une pharmacie ou de manière générale à toute fin publicitaire ou promotionnelle ou
                    professionnelle. La Solution, les Contenus et les Services sont destinés à l'usage personnel des
                    Utilisateurs et ne peuvent pas être utilisés au bénéfice d’une entreprise ou de tout autre
                    organisme. Les sociétés commerciales, et de manière générale tout organisme ou entité ou profession
                    réglementée ne peuvent pas en tant que tels devenir Utilisateurs. Toute utilisation en fraude des
                    droits de Kiro expose l’utilisateur à des poursuites.
                    <br />
                    <br />
                    Les personnes mineures, les personnes majeures vulnérables ou les personnes majeures faisant l’objet
                    d’une mesure de protection juridique, l’inscription et l’accès à la Solution et aux Services
                    relèvent des droits exercés par le ou les représentants légaux (parents, tuteurs), conformément à la
                    loi, et est nécessairement effectué et mis en œuvre par ces derniers. Les personnes mineures, ou les
                    personnes faisant l’objet d’une mesure de protection juridique, accèdent donc à leur Compte Kiro,
                    sauf pour les situations prévues par la réglementation en vigueur, sous la responsabilité de leur(s)
                    représentant(s) légal(aux). L’Utilisateur peut utiliser la Solution et les Services pour le compte
                    de tiers sur lesquels il dispose de l’autorité parentale ou pour lesquels il est reconnu tuteur ou
                    curateur conformément au droit français. Kiro se réserve la possibilité de demander tout
                    justificatif de nature à établir l’autorité parentale ou le pouvoir de représentation légal de
                    l’Utilisateur.
                    <br />
                    <br />
                    Les Utilisateurs ne peuvent utiliser les Services et Contenus de la Solution à d’autres fins que
                    pour leur destination définie aux présentes CGU. L’Utilisateur s’engage à utiliser la Solution et
                    les Services dans le respect de ses obligations légales, réglementaires, et déontologiques,
                    notamment à respecter les règles relatives au partage du secret médical. L’Utilisateur est seul
                    responsable de l’utilisation des documents et Compte Rendus de Biologie reçus dans le cadre de la
                    Solution et des Services et apprécie seul leur pertinence et leur sensibilité.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">
                        5.2 Création d’un Compte Kiro par un Utilisateur Patient
                    </div>
                    L’accès à la Solution nécessite la création d’un compte Utilisateur. Pour cela, l’Utilisateur doit
                    disposer d’une adresse email valide et d’un mot de passe. L’Utilisateur est invité à suivre la
                    procédure de demande de création de compte disponible sur la Solution et à renseigner les
                    informations sollicitées dans le formulaire prévu à cet effet, puis à cliquer sur le bouton «
                    S’inscrire ». Lors de la première connexion, l’Utilisateur se verra demander son nom, son prénom, sa
                    date de naissance, ainsi qu’un mot de passe sécurisé conforme aux{' '}
                    <a
                        rel="noopener noreferrer"
                        target={'_blank'}
                        href={
                            'https://www.cnil.fr/fr/' +
                            'authentification-par-mot-de-passe-les-mesures-de-securite-elementaires'
                        }
                    >
                        recommandations de la CNIL
                    </a>
                    . L’Utilisateur s’engage à compléter toutes les Informations nécessaires qui lui sont demandées en
                    vue de la bonne utilisation de la Solution et des Services.
                    <br />
                    <br />
                    Dans le cas où Kiro serait amené à envoyer un mot de passe temporaire à l’Utilisateur, ce dernier
                    doit modifier le mot de passe temporaire envoyé par Kiro dès sa première connexion à la Solution.
                    <br />
                    <br />
                    Il appartient à l’Utilisateur de fournir et maintenir des informations vraies, complètes exactes, et
                    à jour et faire toutes vérifications qui semblent nécessaires ou opportunes à ce titre avant de
                    procéder à l’utilisation de la Solution ou des Services. Il s’engage notamment à ne pas créer une
                    fausse identité de nature à induire Kiro ou les tiers en erreur, à ne pas usurper l’identité ou la
                    fonction d’une autre personne physique, ni à masquer ou modifier son âge. L’Utilisateur accepte et
                    s’engage à maintenir immédiatement à jour et complètes ses informations en cas de changement de
                    situation ou de modification concernant les données qu’il a communiquées initialement. La
                    responsabilité de Kiro ne saurait en aucune circonstance être engagée en cas de retard ou
                    d’impossibilité de fournir la Solution ou les Services du fait d’une information erronée ou
                    incomplète dans la saisie des informations concernant l’Utilisateur sur son compte. L’exactitude et
                    la mise à jour de ces données sont sous l’entière responsabilité de l’Utilisateur et sont
                    nécessaires à sa bonne prise en charge dans le parcours de soins.
                    <br />
                    <br />
                    Dans l’hypothèse où l’Utilisateur communiquerait des informations fausses, trompeuses, inexactes,
                    périmées, incomplètes, ou de nature à induire en erreur, Kiro se réserve la possibilité de suspendre
                    ou résilier le compte de l’Utilisateur, immédiatement, sans préavis ni indemnité, et lui refuser
                    l’accès, de façon temporaire ou définitive à tout ou partie de la Solution et des Services.
                    <br />
                    <br />
                    Le consentement de l’Utilisateur à l’utilisation de la Solution implique qu’il pourra être contacté
                    par l’ensemble des Professionnels de Santé utilisant la Solution et recevoir des documents, Contenus
                    et Compte Rendu de Biologie de la part des Laboratoires ou Kiro. Le consentement de l’Utilisateur
                    est conservé et vaut jusqu’à ce qu’il demande la résiliation de son Compte Kiro et la reprise de la
                    réception papier des Compte Rendu de Biologie qui lui seront adressés.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">5.3 Conditions d’accès et authentification</div>
                    La connexion au Compte Kiro de chaque Utilisateur repose sur un système d’authentification forte et
                    sécurisée. L’accès à tout ou partie de la Solution est sécurisé et s’effectue grâce à un identifiant
                    et un mot de passe ou par tout autre dispositif d’authentification conforme à la législation et aux
                    recommandations de la CNIL applicables à l’Utilisateur. Les Identifiants de l’Utilisateur sont
                    strictement personnels et confidentiels et sont placés sous la responsabilité exclusive de
                    l’Utilisateur. L’Utilisateur s’engage à conserver ces Identifiants de manière secrète et sécurisée
                    et s’interdit de les divulguer à des tiers sous quelque forme que ce soit. L’Utilisateur s’engage
                    ainsi à s’assurer qu’à l’issue de chaque session, il se déconnecte effectivement de la Solution, en
                    particulier lorsqu’il y accède à partir d’un ordinateur public.
                    <br />
                    <br />
                    En cas de perte, détournement, oubli, utilisation ou suspicion d’utilisation non autorisée par un
                    tiers ou vol de tout ou partie de ses Identifiants, l’Utilisateur doit modifier son mot de passe,
                    directement depuis son compte Utilisateur, ou en cliquant sur le bouton « Mot de passe oublié »
                    situé sous le formulaire de saisie des identifiants de connexion, et en avertir Kiro dans les plus
                    brefs délais.
                    <br />
                    <br />
                    Par ailleurs, l’Utilisateur devra renouveler son mot de passe tous les six mois à partir de sa
                    première connexion, conformément aux recommandations de la CNIL, en cliquant sur le bouton « Mot de
                    passe oublié » situé sous le formulaire de saisie des identifiants de connexion.
                    <br />
                    <br />
                    Tout accès, utilisation de la Solution et des Servies et transmission de données effectués à partir
                    du Compte Kiro d’un Utilisateur suite à son authentification sera réputé avoir été effectué par ce
                    dernier et sous sa responsabilité exclusive, dans les conditions définies à l’article « Convention
                    de preuve » des présentes CGU. En particulier, l’Utilisateur est seul responsable de l’accès et
                    l’utilisation de la Solution avec ses Identifiants, même si l’Utilisateur préenregistre sur son
                    ordinateur, son téléphone mobile ou sur n’importe quel autre type d’équipement ses Identifiants,
                    permettant ainsi la connexion automatique à la Solution. Kiro ne pourra être tenue pour responsable
                    de la perte, du détournement, de l’utilisation non autorisée des Identifiants de l’Utilisateur ou de
                    leur utilisation frauduleuse ainsi que de leurs conséquences ou de tout dommage survenant en cas de
                    manquement de l’Utilisateur à son obligation de confidentialité des Identifiants, qui relèvent tous
                    de la seule responsabilité de l’Utilisateur.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">5.4 Configuration minimale requise</div>
                    L’accès à la Solution nécessite de disposer d’un matériel informatique (PC, MAC ou tablette), d’une
                    connexion internet haut débit et d’un navigateur web, à la charge de l’Utilisateur. La Solution est
                    développée pour une navigation sur Google Chrome. Afin d'optimiser votre navigation, nous vous
                    recommandons d'utiliser la version la plus récente du navigateur. Compte tenu des changements
                    inhérents à la programmation, la configuration minimale requise peut être amenée à changer.
                    <br />
                    <br />
                    L’Utilisateur est seul responsable du bon fonctionnement et de la sécurisation appropriée de son
                    équipement informatique ainsi que de son accès internet. Les Utilisateurs reconnaissent que le bon
                    fonctionnement de la Solution est soumis aux conditions d’accès au réseau Internet et à ses limites
                    et restrictions éventuelles (couverture, saturation, disponibilité). Le dispositif
                    d’authentification de l’Utilisateur est strictement personnel et confidentiel.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">5.5 Disponibilité de la Solution</div>
                    L’Utilisateur reconnaît que son utilisation de la Solution se fait à ses risques et périls. La
                    Solution et les Services sont fournis à l’Utilisateur « en l’état » et Kiro s’efforcera de rendre la
                    Solution et les Services accessible 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure,
                    en cas de périodes de maintenance programmée ou en cas d’un évènement hors du contrôle de Kiro, et
                    sous réserve des cas d’interruption, suspension ou limitation dans le cadre d’opérations de
                    maintenance et/ou de mises à jour nécessaires au bon fonctionnement de la Solution ou des Services,
                    de dysfonctionnement du réseau ou des serveurs, des pannes éventuelles, des aléas techniques liés à
                    la nature du réseau internet, des actes de malveillance, de toute atteinte portée au matériel ou aux
                    logiciels de Kiro, ou en cas de tout autre événement échappant au contrôle de Kiro.
                    <br />
                    <br />
                    Kiro se réserve également la possibilité d’interrompre, de suspendre ou de limiter l’accès à tout ou
                    partie de la Solution ou des Services, sans préavis, notamment en raison de contraintes d’ordre
                    juridique ou technique, pour des raisons de sécurité, de maintenance ou d’amélioration de la
                    Solution ou des Services. Toute perte de données résultant d’une interruption de la Solution ou des
                    Services résultera d’un cas fortuit ne pouvant être imputé à Kiro.
                    <br />
                    <br />
                    Par ailleurs, l’accès à la Solution implique pour l’Utilisateur la connaissance et l’acceptation des
                    caractéristiques et des limites d’internet, du navigateur web, et des caractéristiques techniques de
                    son mode de connexion qui peut être affecté par des ralentissements ou des indisponibilités qui
                    peuvent perturber ou empêcher l’accès à la Solution ou aux Services. Kiro décline toute
                    responsabilité concernant les difficultés d’accès à la Solution ou aux Services en raison de
                    perturbations du réseau internet, ou de toute incompatibilité de la Solution ou des Services avec le
                    matériel de l’Utilisateur. Plus généralement, Kiro ne pourra en aucun cas être tenue pour
                    responsable en raison d’une interruption de la Solution ou des Services quel que soit la cause, la
                    durée ou la fréquence de cette interruption.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">5.6 Sécurité</div>
                    Kiro recommande à l’Utilisateur d’installer et d’activer sur son matériel informatique tout outil de
                    sécurité informatique tel qu’un antivirus ou un pare-feu et de s’assurer de posséder un système
                    d’exploitation à jour, notamment des derniers correctifs de sécurité. L’Utilisateur est informé que
                    l’accès à la Solution implique la connaissance et l’acceptation des risques inhérents à toute
                    connexion et transmission sur internet, l’absence de protection de certaines données contre des
                    détournements éventuels et les risques de contamination par d’éventuels virus circulant sur le
                    réseau. Il appartient en conséquence à l’Utilisateur de prendre toutes les mesures appropriées de
                    façon à protéger ses propres données et/ou logiciels stockés sur son équipement informatique, ainsi
                    que les informations qu’il communique au travers de la Solution ou des Services, contre toute
                    atteinte. Kiro ne pourra pas être tenue responsable de toute faille de sécurité relevant de
                    l’absence de sécurisation du matériel informatique de l’Utilisateur. Kiro ne pourra également pas
                    être tenue responsable de l’installation et du fonctionnement des terminaux utilisés par
                    l’Utilisateur pour accéder à la Solution et non fournis par Kiro. L’accès à tout ou partie de la
                    Solution et des Services est sécurisé et s’effectue grâce à ses Identifiants. L’Utilisateur est seul
                    responsable des mesures à mettre en œuvre pour assurer la confidentialité de ses Identifiants. Les
                    communications entre le terminal de l’Utilisateur et la Solution sont chiffrées à l’aide d’un
                    certificat SSL.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">5.7 Fonctionnalités, maintenance et mises à jour</div>
                    La liste des fonctionnalités accessible est susceptible d’être corrigée et/ou modifiée
                    unilatéralement sans prévis par Kiro, par l’ajout de nouvelles fonctionnalités, la modification des
                    fonctionnalités préexistantes, ou le retrait de fonctionnalités, sans que cette correction et/ou
                    modification ne puisse ouvrir droit à un quelconque recours de la part de l’Utilisateur. Kiro peut à
                    tout moment procéder à des mises à jour ou à des correctifs. L’Utilisateur s’interdit de faire
                    obstacle au déploiement des mises à jour, correctifs, ou autres opérations de maintenance programmée
                    ou non programmée.
                    <br />
                    <br />
                    En cas de dysfonctionnement de quelque nature que ce soit lors de l’utilisation des Services ou en
                    cas de découverte d’une anomalie de fonctionnement par l’Utilisateur lors de l’utilisation des
                    Services, l’Utilisateur devra la notifier immédiatement au Laboratoire et Kiro. Pour être traitée,
                    l’anomalie devra être décrite par l’Utilisateur de manière précise et documentée. La méthode de
                    traitement des anomalies sera laissée à la discrétion de Kiro et pourra prendre la forme d'une
                    correction de programme, de la fourniture d’une solution de contournement ou de tout autre moyen
                    permettant d'éviter la reproduction de l'anomalie concernée.
                    <br />
                    <br />
                    Sans préjudice de toute autre disposition des CGU, Kiro sera dégagée de plein droit de toute
                    obligation et responsabilité dans le cas où l’anomalie résulterait d’une mauvaise utilisation de la
                    Solution, des Services ou d’une utilisation de la Solution et/ou des Services non-conforme aux
                    dispositions des présentes, d’une intervention de l’Utilisateur ou d’un tiers sur la Solution ou les
                    Services non autorisée préalablement par écrit par Kiro, ou d’un programme informatique non fourni
                    par Kiro.
                    <br />
                    <br />
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">6. Utilisation de la Solution</div>
                <div className="eula-paragraph-content">
                    <div className="eula-paragraph-subtitle-1">6.1 Obligations et informations préalables</div>
                    De manière générale, l’Utilisateur est informé que les données qu’il renseigne sont déterminantes
                    pour la bonne utilisation de la Solution, des Contenus et des Services et la qualité de sa prise en
                    charge par un Professionnel de Santé. Il lui appartient donc de renseigner et de déclarer des
                    informations fiables, complètes, exactes et actualisées.
                    <br />
                    <br />
                    L’Utilisateur s'engage à n'utiliser la Solution, les Contenus et les Services que dans le cadre
                    prévu aux présentes, de manière loyale et conformément à leur destination.
                    <br />
                    <br />
                    L’Utilisateur reconnaît expressément avoir été informé que la Solution, les Contenus et les Services
                    :
                    <ul>
                        <li>ne sont pas un dispositif médical ;</li>
                        <li>
                            ne sont pas un outil de télé-expertise, ou plus généralement de télémédecine : ainsi, aucun
                            acte médical, aucune consultation ni diagnostic n’est réalisé par le biais des services
                            proposés par la Solution ;
                        </li>
                        <li>ne permettent pas la gestion ni le suivi des situations d’urgence.</li>
                    </ul>
                    A titre informatif, les Professionnels de Santé sont soumis à utiliser la Solution et les Services
                    dans le respect de leurs obligations légales, réglementaires et déontologiques telles qu’elles
                    résultent en particulier des articles L. 1110-4, L. 1110-12, R. 1110-1 et suivants du code de la
                    santé publique, de l’article 226-13 du code pénal et du code de déontologie régissant l’exercice de
                    leur profession, le cas échéant, et s’engagent à respecter le secret médical. Ils ne peuvent
                    communiquer, directement ou indirectement, hors de l'équipe de soins, l’une quelconque des Données
                    Patients soumise au secret médical, a tout tiers non autorisé.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">6.2 Engagements de l’Utilisateur</div>
                    Chaque Utilisateur s’engage au moment de toute utilisation à valider l’adéquation de la Solution, du
                    Contenus et/ou des Services à ses besoins et à son état de santé. Chaque Utilisateur reconnaît qu’il
                    prend seul toute décision fondée sur la Solution, les Contenus ou les Services, et que Kiro n’assume
                    aucune responsabilité au titre de la gestion d’une situation d’urgence.
                    <br />
                    <br />
                    De manière générale, l’Utilisateur s’engage à :
                    <ul>
                        <li>ne pas gêner ou entraver l’utilisation par les autres Utilisateurs de la Solution</li>
                        <li>
                            protéger et garantir le maintien de la confidentialité de ses Identifiants, étant précisé
                            qu’il demeure pleinement responsable de toutes les activités qui se produisent sur son
                            Compte Kiro, qu’il en ait ou non connaissance ;
                        </li>
                        <li>
                            à ne pas utiliser la Solution ou les Services au bénéfice d’une action commerciale ou
                            promotionnelle quelconque ;
                        </li>
                        <li>
                            à utiliser la Solution et les Services dans le respect des lois, règlementations et droits
                            des tiers, notamment des droits de propriété intellectuelle et industrielle ;
                        </li>
                        <li>
                            à ne pas diffuser du contenu illicite ou des informations contraires à l’ordre public et aux
                            bonnes mœurs, et de manière générale à ne pas accomplir tout acte constitutif d’une
                            infraction pénale.
                        </li>
                    </ul>
                    L’Utilisateur reconnaît expressément avoir été informé du fait que :
                    <ul>
                        <li>
                            la Solution est susceptible de contenir des données à caractère personnel sensibles
                            relatives à sa santé ;
                        </li>
                        <li>
                            les Services et Contenus fournis dans le cadre de la Solution ne peuvent être utilisés à
                            d’autres fins que leur destination définie aux présentes CGU ;
                        </li>
                        <li>
                            l’utilisation de la Solution, des Contenus et des Services est sous sa responsabilité
                            exclusive.
                        </li>
                    </ul>
                    De manière générale, l’Utilisateur s’engage à utiliser la Solution, les Contenus, les Services et
                    les Données Patients qui lui sont communiquées conformément aux CGU, dans le respect de ses
                    obligations légales et déontologiques et, plus généralement, des lois et réglementations
                    internationales, européennes et nationales applicables en France, et à notifier Kiro de tout contenu
                    illicite.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">6.3 Responsabilités de l’Utilisateur</div>
                    L'Utilisateur est seul maître de la bonne utilisation, avec discernement et esprit, de la Solution,
                    des Contenus, des Services et des données qu’ils contiennent et, en particulier, des Données
                    Patients. En toutes hypothèses, tout Utilisateur est intégralement et exclusivement responsable des
                    actes et des décisions qu’il prend en connaissance de cause, ainsi que des conséquences du
                    traitement de données à caractère personnel mis en œuvre dans le cadre de la Solution et/ou des
                    Services.
                    <br />
                    <br />
                    L’utilisation de la Solution ou des Services ne dilue, ne modifie ou n’atténue ni directement, ni
                    indirectement la responsabilité et les obligations médicales et/ou dans leur domaine de compétence
                    en santé/sanitaire (i) des Laboratoires vis-à-vis des Utilisateurs et (ii) des Professionnel de
                    Santé envers les autres Utilisateurs et/ou leurs Patients. Les Professionnels de Santé exercent leur
                    pratique professionnelle en toute indépendance, selon leurs obligations déontologiques et légales
                    personnelles et sous leur responsabilité exclusive. Chaque Professionnel de Santé est intégralement
                    et exclusivement responsable des actes et services fournis par lui à un Utilisateur conformément aux
                    obligations légales, réglementaires et déontologiques qui président à l’exercice de son activité
                    professionnelle et du traitement de données à caractère personnel ainsi mis en œuvre à ce titre.
                    <br />
                    <br />
                    La responsabilité d’un tiers et en particulier du Professionnel de Santé ou du Laboratoire ne pourra
                    en aucun cas être engagée dans l'hypothèse où l’Utilisateur transmettrait des données incomplètes,
                    tronquées, trompeuses, inexactes, insuffisantes, périmées, trompeuses ou erronées. L’Utilisateur est
                    en effet seul responsable de l’exactitude, de la cohérence et de l'exhaustivité des données, et
                    notamment des Données Patients, et documents à caractères médicaux qu’il aura transmis à travers la
                    Solution et/ou les Services. En aucun cas, Kiro ou le Laboratoire ne sera en mesure d’effectuer des
                    vérifications de l'exactitude et de l'exhaustivité des données saisies par l’Utilisateur.
                    <br />
                    <br />
                    Kiro ne saurait garantir l'exactitude, l'exhaustivité, l’actualité ou autre qualité des Données
                    Patients transmises par le Laboratoire ou traitées par l’Utilisateur sur la Solution ou les
                    Services. L’Utilisateur est seul responsable de l’intégrité, l’exactitude et la qualité des Données
                    Patients qu’il traite sur la Solution et/ou les Services.
                    <br />
                    <br />
                    L’Utilisateur est seul responsable de l’exactitude, de la cohérence et de l'exhaustivité des données
                    et pièces médicales qu’il aura transmises, par le biais de la Solution et/ou des Services. En aucun
                    cas, Kiro, le Laboratoire ou le Professionnel de Santé ne seront en mesure d’effectuer des
                    vérifications concernant l'exactitude, l'exhaustivité ou tout autre qualité des données saisies par
                    l’Utilisateur.
                    <br />
                    <br />
                    L’Utilisateur met en œuvre sous sa responsabilité exclusive l’ensemble des mesures de sécurité
                    utiles et pertinentes pour les besoins de la protection des accès à son compte sur la Solution et
                    aux données qu’il contient. L’Utilisateur est également responsable de tout dommage survenant à son
                    système informatique, à la Solution et/ou ses Contenus, ainsi que, de la perte de données qui
                    pourraient résulter de son utilisation de la Solution ou des Services. A ce titre, l’Utilisateur est
                    responsable de la mise en place d’anti-virus et autres mesures de sécurisation de son système
                    informatique, afin de garantir la sécurité des Données Patients conformément au paragraphe
                    ci-dessus.
                    <br />
                    <br />
                    Chaque Utilisateur est responsable de la mise en œuvre au sein de son ordinateur d’une solution et
                    des mesures de sécurité de nature à prévenir la propagation de virus et de manière générale du bon
                    fonctionnement et de la maintenance de l’équipement lui permettant de bénéficier de la Solution et
                    des Services, en ce compris les frais d’abonnement et de connexion à internet. D’une manière
                    générale, la sécurité de la Solution impose aux Utilisateurs de respecter les mesures de sécurité
                    définies par la CNIL (
                    <a
                        href={
                            'https://www.cnil.fr/fr/authentification-par-mot-de-passe-les-mesures-de-securite-elementaires'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://www.cnil.fr/fr/authentification-par-mot-de-passe-les-mesures-de-securite-elementaires
                    </a>
                    ) et par l’Agence du Numérique en Santé (anciennement ASIP Santé) et notamment les règles relatives
                    à la définition et au changement des éléments d’authentification.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">6.4 Comportements prohibés</div>
                    Il est strictement interdit d’utiliser la Solution et/ou les Services aux fins suivantes :
                    <ul>
                        <li>
                            l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la
                            sécurité des tiers,
                        </li>
                        <li>l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,</li>

                        <li>
                            l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un ou
                            plusieurs des actes et activités décrits ci-dessus,
                        </li>
                        <li>
                            et plus généralement toute pratique détournant la Solution ou les Services à des fins autres
                            que celles pour lesquelles ils ont été conçus.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">7. Limitations de responsabilités de Kiro</div>
                <div className="eula-paragraph-content">
                    Kiro fait ses meilleurs efforts pour mettre à disposition un service de qualité à ses Utilisateurs.
                    Kiro est tenue à une obligation de moyens au titre de la mise à disposition de la Solution et des
                    Services proposés dans les limites définies aux CGU. L’Utilisateur reconnaît que Kiro n’est pas tenu
                    à une obligation générale de surveillance des contributions.
                    <br />
                    <br />
                    L’accès et l’utilisation de la Solution et des Services par l’Utilisateur ne sauraient en aucun cas
                    engager la responsabilité de Kiro, à quelque titre que ce soit ; cet accès et cette utilisation se
                    font sous l’entière responsabilité de l’Utilisateur. En conséquence, Kiro ne pourra pas être tenue
                    responsables des actes, services rendus et/ou décisions prises par un Professionnel de Santé
                    vis-à-vis du Patient à partir de la Solution, de ses Contenus ou des Services.
                    <br />
                    <br />
                    En particulier, Kiro n’est pas responsable des dommages qui résultent de :
                    <ul>
                        <li>
                            la transmission par l’Utilisateur à des tiers de toute information concernant son état de
                            santé ;
                        </li>
                        <li>la divulgation par l’Utilisateur à des tiers de tout ou partie de ses Identifiants ;</li>
                        <li>de l’utilisation par l’Utilisateur des Identifiants d'un autre Utilisateur ;</li>
                        <li>
                            la transmission par l’Utilisateur d’informations incomplètes, périmées, tronquées, erronées,
                            trompeuses, inexactes, ou de défaut de transmission par l’Utilisateur d’informations utiles
                            et/ou nécessaires à la fourniture de la Solution ou des Services ;
                        </li>
                        <li>la mauvaise transmission par le Laboratoire des Données Patients ;</li>
                        <li>
                            des Services fournis par les Laboratoires à travers la Solution, ces derniers étant seuls
                            responsables à l’égard des Utilisateurs des Services qu’ils fournissent ;
                        </li>
                        <li>
                            des actions résultantes de l’utilisation des Services par le Professionnel de Santé, ces
                            derniers étant seuls responsables à l’égard des Patients des Services qu’ils fournissent.
                        </li>
                    </ul>
                    Kiro n’assume en effet aucun service de nature médicale ou de santé dans le cadre de la Solution
                    et/ou des Services, chaque Professionnel de Santé assumant, pour ce qui le concerne, exclusivement
                    la responsabilité de tous les actes, services et/ou décisions prises à partir et à travers la
                    Solution et/ou les Services.
                    <br />
                    <br />
                    Kiro n’assume aucune responsabilité concernant l’exactitude, la sécurité, l’intégrité ou
                    l’exhaustivité des informations transmises par le Laboratoire, l’Utilisateur ou le Professionnel de
                    Santé. Le Laboratoire et l’Utilisateur seront en particulier tenus de s'assurer que toutes les
                    autorisations nécessaires ont bien été données par les Patients, et que les réglementations
                    applicables notamment en matière de santé et de protection des données personnelles sont respectées.
                    <br />
                    <br />
                    De plus, malgré ses efforts pour assurer la continuité et de la qualité de la Solution et des
                    Services, Kiro ne pourra pas être tenue pour responsable, en cas de dysfonctionnement :
                    <ul>
                        <li>
                            de dommages liés à l’impossibilité temporaire d’accéder à la Solution ou à l’un des Services
                            proposés par le site internet https://app.kiro.bio ;
                        </li>
                        <li>
                            d’un mauvais traitement de la Solution ou des Service et de leurs conséquences découlant
                            d’un dysfonctionnement ou d’un accès restreint ou dégradé à Internet ;
                        </li>
                        <li>
                            de tout dommage ni des conséquences résultant de l’utilisation de l’Utilisateur de la
                            Solution, de ses Services et Contenus ;
                        </li>
                        <li>
                            de l’impossibilité d’avoir accès à la Solution, aux Services et aux Contenus, à l’exception
                            des dommages directs consécutifs à une faute lourde ou intentionnelle ;
                        </li>
                        <li>
                            de l’inaccessibilité de la Solution, de ses Contenus et de ses Services causée par des cas
                            de force majeure. L’exécution des présentes CGU pourra être suspendue tant que le cas de
                            force majeure durera ;
                        </li>
                        <li>
                            relative à l’utilisation d’un contenu tiers proposé dans la Solution ou dans les Services.
                            Kiro ne vérifie pas les contenus et les sites qui peuvent être liés à la Solution et n’est
                            en aucun cas responsable du contenu des sites vers lesquels la Solution ou les Services
                            redirigent.
                        </li>
                    </ul>
                    Les informations et Contenus diffusés sur la Solution et/ou les Services sont fournis à titre
                    strictement et exclusivement informatif et indicatif. Malgré tout le soin et l’attention apportés à
                    la sélection des sources et à la rédaction des Contenus et informations, Kiro ne saurait pour autant
                    garantir l’intégrité, l'exactitude, l'exhaustivité, l’actualité ou une quelconque autre qualité des
                    informations diffusées sur la Solution et les Services.
                    <br />
                    <br />
                    Kiro ne consent aucune garantie expresse ou implicite, en ce y compris, sans que cette énumération
                    ne soit limitative, quant à la non violation des droits d'un tiers, la continuité, performance et/ou
                    pérennité de la Solution ou des Services et/ou quant à l’aptitude à un usage particulier ou
                    l’adéquation de la Solution ou des Services aux besoins de l’Utilisateur, ni ne garantit que la
                    Solution et les Services sont exemptes d’anomalies, erreurs ou bugs ou qu’ils fonctionneront sans
                    panne ni interruption. La Solution et les Services sont diffusés « en l’état » et selon leurs
                    disponibilités.
                    <br />
                    <br />
                    De plus, chaque Utilisateur reconnaît que Kiro ne pourra être tenue pour responsable de tout dommage
                    direct ou indirect, matériel ou immatériel, quelles qu’en soient les causes, et notamment de tout
                    dommage direct ou indirect, matériel ou immatériel, résultant de ou en relation avec l’accès à la
                    Solution et/ou aux Services, leur utilisation, leur dysfonctionnement ou leur indisponibilité
                    quelles qu’en soient la nature et la durée. Kiro exclut expressément sa responsabilité pour tout
                    dommage, direct ou indirect, matériel ou immatériel, pouvant être causés par l’éventuelle diffusion
                    de virus quelle qu'en soit la forme, de bug(s), chevaux de Troie, ou tout autre composant
                    susceptible de causer un dommage, voire de tout programme ou d'application incompatible avec
                    l'infrastructure utilisée par l'Utilisateur, dont Kiro ne saurait garantir que la Solution ou ses
                    serveurs en seront à tout moment exempt. Kiro exclut également expressément sa responsabilité pour
                    tout dommage subi par l'Utilisateur par le fait d'une panne, interruption, maintenance, problème
                    technique, coupure du réseau téléphonique ou des réseaux de télécommunications, surcharge, défaut de
                    continuité des réseaux de télécommunications et notamment d’internet, omission, négligence ou faute
                    de tiers ou de l'Utilisateur ou d’une incompatibilité de la configuration de l’ordinateur de
                    l’Utilisateur.
                    <br />
                    <br />
                    L’Utilisateur reconnait expressément que les suspensions, interruptions ou limitations
                    susmentionnées pourront intervenir à tout moment sans préavis et qu’elles n’ouvriront droit à aucune
                    obligation, ni indemnisation à son profit quel que soit la cause, la durée ou la fréquence de cette
                    interruption. Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon à
                    protéger ses propres matériels, données, et/ou logiciels stockés sur son équipement informatique
                    contre toute atteinte.
                    <br />
                    <br />
                    L’Utilisateur reconnaît que la vitesse de transmission des informations ne dépend pas de la Solution
                    ou des Services, mais des caractéristiques inhérentes aux réseaux de communications électronique et
                    des caractéristiques techniques de son mode de connexion et de son accès internet.
                    <br />
                    <br />
                    La responsabilité de Kiro ne saurait être engagée, d’une façon générale, dans tous les cas où
                    l'inexécution, la mauvaise exécution ou le retard d’exécution de ses obligations résulterait d'un
                    cas de force majeure ou cas fortuit indépendant de sa volonté, du fait de l’Utilisateur ou d'un
                    tiers. La responsabilité de Kiro ne peut pas être recherchée pour des actes réalisés par
                    l’Utilisateur ou un tiers utilisant la Solution ou les Services.
                    <br />
                    <br />
                    Aucune recommandation, aucune interprétation ni aucune information, qu'elles soient orales ou
                    écrites, obtenues par l’Utilisateur lors de l’utilisation de la Solution ou des Services ne sont
                    susceptibles de créer de garanties non expressément prévues par les CGU ni d’entraîner la
                    responsabilité de Kiro en cas de dommages, de quelque nature qu’ils soient, causés à l’Utilisateur
                    ou à des tiers du fait de l’utilisation de la Solution ou des Services, en violation des
                    dispositions du présent article et plus généralement du non-respect des CGU.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">8. Durée, suspension et résiliation</div>
                <div className="eula-paragraph-content">
                    Les CGU entrent en vigueur à compter de leur acceptation par l’Utilisateur et pour toute la durée
                    d’accès et d’utilisation de la Solution et/ou des Services par l’Utilisateur.
                    <br />
                    <br />
                    Kiro pourra, unilatéralement, temporairement ou définitivement, suspendre ou résilier de plein droit
                    l’accès de l’Utilisateur à tout ou partie de la Solution et/ou des Services, sans mise en demeure
                    préalable, sans préavis ni indemnité de quelque nature que ce soit, en cas d’utilisation de la
                    Solution ou des Services non conforme aux CGU, en cas d’infractions ou violations des CGU et en
                    particulier de l’Article 6 « Utilisation de la Solution », en cas de manquement à l’une quelconque
                    des obligations décrites aux présentes CGU, en cas de tentative de connexion non autorisée par une
                    utilisation frauduleuse du système ou par une usurpation de codes d’accès ou des Identifiants, ou
                    plus généralement en cas de violation des lois et réglementations applicables.
                    <br />
                    <br />
                    Kiro pourra prononcer cette suspension ou résiliation sans préjudice de tous les autres droits,
                    actions et recours, y compris de tous dommages et intérêts, dont Kiro pourrait disposer en vue de la
                    réparation du préjudice qu’elle pourrait avoir subi du fait de ces manquements.
                    <br />
                    <br />
                    Il se peut que l’accès à la Solution ou aux Services soit suspendu ou résilié après un
                    dysfonctionnement technique ayant conduit par erreur à la désactivation de l’inscription de
                    l’Utilisateur.
                    <br />
                    <br />
                    L’Utilisateur peut à tout moment résilier la souscription de son Compte Kiro en contactant Kiro ou
                    le Laboratoire. L’Utilisateur est informé que la clôture de son Compte Kiro n’entraînera pas la
                    suppression automatique des Données Personnelles le concernant. Ces données seront conservées dans
                    les conditions définies dans la politique de protection des données personnelles. A l’issue de cette
                    action, l’Utilisateur ne sera plus en mesure d’accéder à la Solution, aux Contenus et aux Services.
                    L’Utilisateur assume exclusivement toute responsabilité en cas de clôture de son accès à la
                    Solution.
                    <br />
                    <br />
                    La Solution et les Services prendront fin de plein droit, sans notification préalable en cas de
                    clôture du compte ou cessation d’exploitation de la Solution par Kiro. En cas d’empêchement
                    définitif relevant d’un cas de force majeure, tout accès à la Solution sera clôturé.
                    <br />
                    <br />
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">9. Sites tiers – Liens hypertextes</div>
                <div className="eula-paragraph-content">
                    Pour le cas où la Solution renverrait ou contiendrait des liens hypertextes renvoyant vers des sites
                    internet édités par des tiers (ci-après les <b>« Sites Tiers »</b>) sur lesquels Kiro n'exerce
                    aucune sorte de contrôle, Kiro n'assume aucune responsabilité quant au contenu ou toute autre
                    information ou donnée, disponibles sur ou à partir de ces Sites Tiers ou au contenu vers lequel les
                    Sites Tiers peuvent renvoyer, y compris ceux des partenaires.
                    <br />
                    <br />
                    La présence de liens hypertextes vers des Sites Tiers ne signifie en aucun cas que Kiro approuve de
                    quelque façon que ce soit les contenus des Sites Tiers. Kiro ne saurait être responsable d'aucune
                    modification ou mise à jour concernant les Sites Tiers, ni de la transmission d'informations à
                    partir des Sites Tiers, ou du mauvais fonctionnement de ceux-ci. En conséquence, l’Utilisateur
                    reconnait être seul responsable de l’accès et de l’utilisation de ces Sites Tiers. Kiro ne pourra
                    être tenue responsable de tous dommages ou pertes avérés ou allégués consécutifs ou en relation avec
                    l'utilisation ou le fait d'avoir fait confiance aux contenus, à des biens ou des services
                    disponibles sur ces Sites Tiers.
                    <br />
                    <br />
                    L’Utilisateur n’est pas autorisé à créer de lien hypertexte vers la Solution.
                    <br />
                    <br />
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">10. Propriété intellectuelle</div>
                <div className="eula-paragraph-content">
                    La Solution, en ce compris les Services, et tous autres éléments qui la compose, ainsi que de
                    manière non limitative les marques, dénominations et logos de la Solution et de Kiro, mais également
                    l’arborescence de la Solution, son plan de navigation, la conception et l’organisation de ses
                    rubriques, leurs titres, les bases de données, leur structure et leurs contenus, existants ou à
                    venir, sont protégés par des droits de propriété intellectuelle (tels que notamment tous droits
                    d'auteur, droits sur les brevets, les marques, droit des producteurs de base de données, et tout
                    autre droit de propriété intellectuelle existant ou futur, français et internationaux) et sont la
                    propriété exclusive de Kiro.
                    <br />
                    <br />
                    En conséquence, sauf autorisation expresse, écrite et préalable de Kiro, il est strictement interdit
                    de représenter, de reproduire et/ou d’exploiter, totalement ou partiellement, la Solution ou l’un
                    quelconque des éléments qui la compose ainsi que les Services, sous quelque forme et par quelque
                    moyen que ce soit. A ce titre, il est notamment interdit à l’Utilisateur de copier, modifier,
                    assembler, décompiler, altérer, traduire en toutes langues ou tous langages, vendre, louer, prêter,
                    diffuser, distribuer ou transférer tout ou partie de la Solution ou d’un quelconque élément qui la
                    compose ainsi que des Services, mettre sur le marché à titre gratuit ou onéreux, créer des œuvres
                    dérivées à partir de ces œuvres, autoriser un tiers à commettre de tels actes ou lui permettre de le
                    faire.
                    <br />
                    <br />
                    L’utilisation de la Solution et/ou des Services ne confère en aucune façon à l’Utilisateur un droit
                    de propriété et/ou un droit de propriété intellectuelle sur la Solution à l’exception d’un droit
                    personnel d’accès, non exclusif et limité exclusivement à l’utilisation de la Solution conformément
                    à son objet et ses finalités et dans le respect des CGU. Le non-respect des dispositions du présent
                    article constitue une violation des droits de propriété intellectuelle de Kiro et/ou des tiers
                    concédant et est susceptible d’entraîner des poursuites civiles et pénales.
                    <br />
                    <br />
                </div>
            </div>

            <div className="eula-paragraph">
                <div id={'data-privacy'} className="eula-paragraph-title">
                    11. Protection des Données Personnelles
                </div>
                <div className="eula-paragraph-content">
                    <div className="eula-paragraph-subtitle-1">11.1 Données à caractère personnel</div>
                    L’Utilisateur reconnaît donner son consentement explicite quant à l’hébergement de ses Données
                    Personnelles.
                    <br />
                    <br />
                    Kiro s’engage à protéger les Données Personnelles en conformité avec la réglementation applicable et
                    notamment le Règlement (UE) n°2016/679 du 27 avril 2016 dit « Règlement Général sur la Protection
                    des Données » ou « RGPD » et la loi n° 78-17 du 6 janvier 1978 modifiée, dite « Loi Informatique et
                    Liberté » modifiée.
                    <br />
                    <br />
                    Dans le cadre de la Solution et des Services :
                    <ul>
                        <li>
                            Chaque Laboratoire ayant souscrit un contrat de partenariat avec Kiro et auprès duquel le
                            Patient a réalisé des examens de biologie médicale est responsable de traitement des Données
                            Personnelles de Santé et des Données Patients (i) collectées par le Laboratoire aux fins
                            d’examens de biologie médicale du Patient et (ii) transmises à Kiro pour le fonctionnement
                            de ses Services et leur communication aux Utilisateurs au travers de la Solution. Kiro
                            intervient en qualité de sous-traitant du Laboratoire au sens de la Réglementation
                            applicable à la protection des données personnelles.
                        </li>
                        <li>
                            Chaque Utilisateur Professionnel de Santé est responsable de traitement des Données
                            Personnelles de Santé (i) collectées par l’Utilisateur Professionnel de Santé dans le cadre
                            de son activité et transmises à Kiro pour y être intégrées dans la Solution et/ou les
                            Services ou (ii) collectées par l’Utilisateur Professionnel de Santé par l’intermédiaire de
                            la Solution et/ou des Services. Kiro intervient en qualité de sous-traitant de l’Utilisateur
                            Professionnel de Santé au sens de la Réglementation applicable à la protection des données
                            personnelles.
                        </li>
                        <li>
                            Kiro est responsable de traitement des Données Personnelles collectées dans le cadre (i) de
                            la création du Compte Kiro de l’Utilisateur, (ii) de sa navigation sur la Solution et (iii)
                            de la création de statistiques relatives à l'utilisation de la Solution.
                        </li>
                    </ul>
                    En aucun cas la responsabilité de Kiro ne saurait être engagée en cas de non-respect par le
                    Laboratoire et/ou l’Utilisateur Professionnel de Santé de ses obligations en tant que responsable de
                    traitement.
                    <br />
                    <br />
                    Kiro invite expressément l’Utilisateur à prendre connaissance sa politique de protection des données
                    personnelles, disponible à l’adresse suivante :{' '}
                    <a
                        target={'_blank'}
                        rel="noopener noreferrer"
                        href={getRoute(ROUTE_EULA_DATA_PRIVACY, { version: null })}
                    >
                        {window.location.protocol}
                        {'//'}
                        {window.location.hostname}
                        {getRoute(ROUTE_EULA_DATA_PRIVACY, { version: null })}
                    </a>{' '}
                    qui fait partie intégrante des présentes CGU.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">11.2 Cookie et adresse IP</div>
                    Kiro invite expressément l’Utilisateur à consulter sa politique relative à la protection des données
                    personnelles qui fait partie intégrante des présentes CGU en matière de cookies.
                    <br />
                    <br />
                    A la demande des autorités judiciaires, Kiro peut transmettre l’adresse IP de l’Utilisateur, afin
                    que ce dernier soit identifié en coopération avec son fournisseur d’accès à Internet.
                    <br />
                    <br />
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">12. Convention Preuve</div>
                <div className="eula-paragraph-content">
                    L’Utilisateur reconnaît que les registres informatisés conservés au sein des serveurs de traitement
                    de fichiers électroniques de Kiro, de même que les messages reçus par voie électronique et plus
                    généralement les documents électroniques échangés entre Kiro et l’Utilisateur seront considérés
                    comme les preuves des échanges et des communications entre l’Utilisateur et Kiro. Les Utilisateurs
                    s’engagent à ne pas contester leur force probante et reconnaissent la même valeur probante aux
                    écrits et documents électroniques transmis dans le cadre de Kiro qu’aux écrits et documents papiers
                    ou d’origine au sens de l’article 1366 du Code civil.
                    <br />
                    <br />
                    L’Utilisateur reconnaît et accepte, qu’après authentification, toute manifestation de volonté par le
                    biais de l’utilisation des fonctionnalités proposées au sein de la Solution ou des Services
                    constitue une signature électronique et caractérise la preuve du consentement au sens des
                    dispositions du Code Civil. En cas de conflit entre les registres informatisés de Kiro et tout
                    document papier ou électronique, les registres informatisés de Kiro primeront sur les documents de
                    l’Utilisateur et seront seuls admis à titre de preuve.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">13. Force majeure</div>
                <div className="eula-paragraph-content">
                    Sera considéré comme un cas de force majeure, un événement échappant au contrôle du débiteur, qui ne
                    pouvait être raisonnablement prévu lors de l’acceptation des CGU et dont les effets ne peuvent être
                    évités par des mesures appropriées, et qui empêche l'exécution de son obligation par le débiteur.
                    <br />
                    <br />
                    Le débiteur ne pourra être tenu pour responsable si la non-exécution ou le retard dans l'exécution
                    de l'une quelconque de ses obligations, telles que décrites dans les présentes CGU découle d'un cas
                    de force majeure, au sens de l'article 1218 du Code civil. Le cas de force majeure suspend les
                    obligations de la partie concernée pendant le temps où jouera la force majeure si cet évènement est
                    temporaire. Néanmoins, l’Utilisateur et Kiro s’efforceront d’en minimiser dans toute la mesure du
                    possible les conséquences.
                    <br />
                    <br />A défaut, si l'empêchement est définitif, les parties seront libérées de leurs obligations
                    dans les conditions prévues aux articles 1351 et 1351-1 du Code civil.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">14. Non renonciation</div>
                <div className="eula-paragraph-content">
                    Le fait pour l’une ou l’autre des parties aux présentes CGU de ne pas se prévaloir d’une ou
                    plusieurs stipulations des CGU ne pourra en aucun cas impliquer la renonciation par cette partie à
                    s’en prévaloir ultérieurement.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">15. Cession</div>
                <div className="eula-paragraph-content">
                    L’Utilisateur n’est pas autorisé à céder les droits dont il dispose en application des CGU à un
                    tiers, sans l’autorisation expresse et préalable de Kiro.
                    <br />
                    <br />
                    Kiro peut céder les CGU à tout moment à une filiale ou à un successeur, quelle que soit l’opération.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">16. Territorialité</div>
                <div className="eula-paragraph-content">
                    La Solution s’adresse aux Utilisateurs résidants en France. Aussi, en utilisant la Solution ou les
                    Services, l’Utilisateur se soumet au droit français. En effet, les législations, réglementations et
                    pratiques médicales, ainsi que la législation nationale sur la protection des données personnelles
                    de santé, peuvent être différentes dans les autres pays que la France et nécessiter des adaptations
                    de l’utilisation de la Solution et/ou des Services. Kiro ne saurait être responsable en cas de
                    violation par l’Utilisateur de la législation nationale.
                </div>
            </div>

            <div className="eula-paragraph">
                <div className="eula-paragraph-title">17. Droit applicable et juridictions compétentes</div>
                <div className="eula-paragraph-content">
                    Les CGU seront exécutées et interprétées conformément au droit français. Les parties déclarent leur
                    intention de chercher une solution amiable à toute difficulté qui pourrait surgir à propos de la
                    validité, de l’interprétation ou de l’exécution des CGU. Dans les limites permises par la loi, en
                    cas de désaccord persistant, le litige sera soumis au tribunal compétent de Marseille, auquel
                    l’Utilisateur et Kiro attribuent compétence territoriale, quel que soit le lieu d’exécution ou le
                    domicile du défendeur. Cette attribution de compétence s’applique également en cas de procédure en
                    référé, de pluralité de défendeurs ou d’appel en garantie.
                </div>
            </div>
        </div>
    );
};

export default EulaPatientV3;
