import React, { FC } from 'react';

import styles from "components/designSystem/containers/Inline.module.scss"
import classNames from 'classnames';

export enum InlineSpacing {
    X_SMALL = 'X_SMALL', // 4px
    SMALL = 'SMALL', // 8px
    MEDIUM = 'MEDIUM', // 16px
    LARGE = 'LARGE', // 24px
    X_LARGE = 'X_LARGE', // 32px
    XX_LARGE = 'XX_LARGE', // 48px
    XXX_LARGE = 'XXX_LARGE' // 64px
}

export enum InlineJustifyContent {
    FLEX_END = 'FLEX_END',
    CENTER = 'CENTER',
    FLEX_START = 'FLEX_START',
    SPACE_BETWEEN = 'SPACE_BETWEEN',
}
export enum InlineAlignItem {
    END = 'END',
    CENTER = 'CENTER',
    START = 'START',
    BASELINE = 'BASELINE',
    STRETCH = 'STRETCH',
}

export enum InlineOverflow {
    WRAP = 'WRAP',
    SCROLL = 'SCROLL'
}

interface InlineProps {
    /**
     * Spacing [Horizontal, Vertical] between children - provide a single value to set both.
     */
    spacing?: InlineSpacing | [InlineSpacing, InlineSpacing]
    justifyContent?: InlineJustifyContent
    alignItems?: InlineAlignItem
    preventMobileSwitch?: boolean
    overflow?: InlineOverflow
    onClick?: () => void
    withFullHeight?: boolean;
}

export const Grow: FC<{justifyContent?: InlineJustifyContent}> = (props): JSX.Element => (
    <div className={classNames(
        styles.itemGrow,
        {[styles.itemGrowJustifyCenter]: props.justifyContent === InlineJustifyContent.CENTER}
    )}>
        {props.children}
    </div>
)

/**
 * The purpose of this component is to put children inline and add space between if needed
 * Also switch from inline to inline-block on mobile
 */
const Inline: FC<InlineProps> = (
    props
): JSX.Element => {

    let spacingHorizontal, spacingVertical: InlineSpacing | undefined = undefined;
    if (typeof props.spacing == 'string') {
        spacingHorizontal = spacingVertical = props.spacing
    }
    if (typeof props.spacing == 'object') {
        [spacingHorizontal, spacingVertical] = props.spacing
    }

    return <div className={
        classNames(
            styles.inlineContainer,
            {
                [styles.inlineContainerClickable]: !!props.onClick,

                [styles.inlineContainerSpaceColumnsXSmall]: spacingHorizontal === InlineSpacing.X_SMALL,
                [styles.inlineContainerSpaceColumnsSmall]: spacingHorizontal === InlineSpacing.SMALL,
                [styles.inlineContainerSpaceColumnsMedium]: spacingHorizontal === InlineSpacing.MEDIUM,
                [styles.inlineContainerSpaceColumnsLarge]: spacingHorizontal === InlineSpacing.LARGE,
                [styles.inlineContainerSpaceColumnsXLarge]: spacingHorizontal === InlineSpacing.X_LARGE,
                [styles.inlineContainerSpaceColumnsXXLarge]: spacingHorizontal === InlineSpacing.XX_LARGE,
                [styles.inlineContainerSpaceColumnsXXXLarge]: spacingHorizontal === InlineSpacing.XXX_LARGE,
                [styles.inlineContainerSpaceRowsXSmall]: spacingVertical === InlineSpacing.X_SMALL,
                [styles.inlineContainerSpaceRowsSmall]: spacingVertical === InlineSpacing.SMALL,
                [styles.inlineContainerSpaceRowsMedium]: spacingVertical === InlineSpacing.MEDIUM,
                [styles.inlineContainerSpaceRowsLarge]: spacingVertical === InlineSpacing.LARGE,
                [styles.inlineContainerSpaceRowsXLarge]: spacingVertical === InlineSpacing.X_LARGE,
                [styles.inlineContainerSpaceRowsXXLarge]: spacingVertical === InlineSpacing.XX_LARGE,
                [styles.inlineContainerSpaceRowsXXXLarge]: spacingVertical === InlineSpacing.XXX_LARGE,
                [styles.inlineContainerJustifyContentFlexEnd]: props.justifyContent === InlineJustifyContent.FLEX_END,
                [styles.inlineContainerJustifyContentCenter]: props.justifyContent === InlineJustifyContent.CENTER,
                [styles.inlineContainerJustifyContentFlexStart]: props.justifyContent === InlineJustifyContent.FLEX_START,
                [styles.inlineContainerJustifyContentSpaceBetween]: props.justifyContent === InlineJustifyContent.SPACE_BETWEEN,

                [styles.inlineContainerAlignItemCenter]: props.alignItems === InlineAlignItem.CENTER,
                [styles.inlineContainerAlignItemStart]: props.alignItems === InlineAlignItem.START,
                [styles.inlineContainerAlignItemEnd]: props.alignItems === InlineAlignItem.END,
                [styles.inlineContainerAlignItemBaseline]: props.alignItems === InlineAlignItem.BASELINE,
                [styles.inlineContainerAlignItemStretch]: props.alignItems === InlineAlignItem.STRETCH,

                [styles.inlineContainerSwitchMobile]: !props.preventMobileSwitch,

                [styles.inlineContainerOverflowWrap]: props.overflow == InlineOverflow.WRAP,
                [styles.inlineContainerOverflowScroll] : props.overflow == InlineOverflow.SCROLL,

                [styles.inlineContainerWithFullHeight] : props.withFullHeight,
            }
        )
    }
                onClick={props.onClick}
    >
        {props.children}
    </div>
}

export const AlwaysInline: FC<InlineProps> = (props) => <Inline {...props} preventMobileSwitch>{props.children}</Inline>

export default Inline;