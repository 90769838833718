import * as React from "react";

const VividHeart = (): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
    >
        <path
            fill="#FFBCBC"
            d="M8.25 4.5 12 6l2.625-1.5 2.625.375L19.125 6 21 9l-.375 2.25-1.5 3.375-3 3.75L13.5 20.25l-1.875.375-3.375-2.25L4.5 14.25 2.625 10.5l.75-3.75 2.25-1.5L8.25 4.5Z"
        />
        <path
            fill="#FB4F4F"
            fillRule="evenodd"
            d="M12 4.944a6.076 6.076 0 0 0-8.193.523c-2.488 2.56-1.745 6.385-.09 8.845 1.63 2.424 5.024 5.553 7.925 7.004a.8.8 0 0 0 .716 0c2.901-1.451 6.295-4.58 7.925-7.004 1.655-2.46 2.398-6.284-.09-8.845A6.076 6.076 0 0 0 12 4.944Zm-.574 1.638a4.48 4.48 0 0 0-6.471 0c-1.72 1.77-1.372 4.664.09 6.838 1.42 2.111 4.393 4.884 6.955 6.278 2.562-1.394 5.535-4.167 6.955-6.278 1.462-2.174 1.81-5.068.09-6.838a4.48 4.48 0 0 0-6.471 0 .8.8 0 0 1-1.148 0Z"
            clipRule="evenodd"
        />
    </svg>
);

export default VividHeart;

