import TrText from 'components/common/TrText';
import FormattedValue from 'components/core/data/FormattedValue';
import { Colors } from 'components/legacyDesignSystem/branding/constants';
import { AxisDataType } from "components/legacyDesignSystem/components/charts/builders/model";
import { renderToolip } from 'components/legacyDesignSystem/components/charts/builders/Tooltip';
import { renderValue, ValueDirection } from 'components/legacyDesignSystem/components/charts/builders/Value';
import { NormalityLevel } from 'components/legacyDesignSystem/components/charts/models';
import Container, { ContainerProfile } from 'components/legacyDesignSystem/components/Container';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import { cast } from 'core/utils/Typed';
import { ChartAxisType } from "models/charts/charts/common";
import { ChartTag } from 'models/charts/models';
import { APIText } from 'models/common/message';
import { Value } from 'models/medicalReport/LegacyReportModels';
import React, { Fragment, ReactElement } from 'react';
import { TooltipProps } from 'recharts';
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";


export function buildValueDisplay(
    value: APIText | Value,
    x: number,
    y: number,
    dx?: number,
    dy?: number,
    direction?: ValueDirection,
    normalityLevel?: NormalityLevel,
): ReactElement[] {
    let realValue: string | number | undefined | null = undefined;
    let realUnit: string | undefined | null = undefined;
    if (Object.hasOwn(value, "value") && Object.hasOwn(value, "type")) {
        const vValue: Value | undefined = cast<Value>(value, false)
        if (vValue !== undefined) {
            if (vValue.value !== null && typeof vValue.value === "object") {
                realValue = vValue.value.wording
                realUnit = vValue.unit
            } else {
                realValue = vValue.value
                realUnit = vValue.unit
            }
        }
    } else {
        // @ts-ignore
        realValue = <TrText input={value}/>
    }

    return renderValue(
        realValue,
        realUnit,
        x,
        y,
        dx,
        dy,
        direction,
        normalityLevel
    )
}

export const buildNormalityLevel = (tags?: ChartTag[]): NormalityLevel => {
    if (tags === undefined) {
        return NormalityLevel.NORMAL
    }

    if (tags.includes(ChartTag.ABNORMAL)) {
        return NormalityLevel.ABNORMAL
    }

    return NormalityLevel.NORMAL
};

export const buildValueToolip = (
    valueRetriever: (payloads: ReadonlyArray<Payload<ValueType, NameType>>) => ({ date: string | undefined, value: APIText | Value | undefined })[],
): ((props: TooltipProps<ValueType, NameType>) => JSX.Element | null) => {
        const f = (props: TooltipProps<ValueType, NameType>): JSX.Element | null => {
            const values: ({ date: string | undefined, value: APIText | Value | undefined })[] = props.payload ? valueRetriever(props.payload) : [];
            return renderToolip(
                (
                    <Fragment>
                        {
                            values.map(
                                (value, index) => {
                                    let result = undefined;

                                    if (value.value) {
                                        if (Object.hasOwn(value.value, "value") && Object.hasOwn(value.value, "type")) {
                                            result = <FormattedValue value={cast<Value>(value.value)} singleResult />
                                        } else {
                                            result = <TrText input={cast<APIText>(value.value)} />
                                        }
                                    }

                                    return <Container
                                        key={index}
                                        profiles={[ContainerProfile.FLEX_COLUMN, ContainerProfile.ALIGN_FLEX_START]}
                                    >
                                        {value.date &&
                                            <Typography
                                                weight={TypographyWeight.REGULAR}
                                                variant={TypographyVariant.SMALL}
                                                color={Colors.NEUTRALS_BLUE_GREY}
                                            >
                                                {value.date}
                                            </Typography>
                                        }
                                        {result}
                                    </Container>
                                }
                            )
                        }
                    </Fragment>

                )
            )
        };

    return f
}

export function buildKey(prefix: string, id: string): string {
    return `${prefix}:${id}`
}

export function defineAxisDataType(axisType: ChartAxisType): AxisDataType {
    switch (axisType) {
        case ChartAxisType.GROUP:
        case ChartAxisType.MONTHS:
            return AxisDataType.TEXT;
        case ChartAxisType.COUNT:
        case ChartAxisType.AGE:
        case ChartAxisType.VALUE:
        default:
            return AxisDataType.NUMERICAL;

    }
}
