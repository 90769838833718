import React, { MouseEventHandler, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ButtonSize, ButtonStyle } from 'components/legacyDesignSystem/components/buttons/models';


import "./Button.scss"

export interface IconButtonProps {
    size: ButtonSize,
    style: ButtonStyle,
    inactive?: boolean
    onClick?: MouseEventHandler;
}

const IconButton = (
    props: PropsWithChildren<IconButtonProps>
): JSX.Element => {

    const style: {[key: string]: boolean} = {}

    style[`icon-button--${props.style ? props.style : props.style}--inactive`] = !!props.inactive;
    style[`icon-button--${props.style ? props.style : props.style}`] = !props.inactive;

    return (
        <div
            className={
                classNames(
                    `icon-button--${props.size}`,
                    style
                )
            }
            onClick={props.onClick}
        >
            {props.children}
        </div>

    )
}

export default IconButton;