import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const WarningCircle = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-warning-circle'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_129_547)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 2.75C8.41421 2.75 8.75 3.08579 8.75 3.5V9.5C8.75 9.91421 8.41421 10.25 8 10.25C7.58579 10.25 7.25 9.91421 7.25 9.5V3.5C7.25 3.08579 7.58579 2.75 8 2.75ZM8 13C8.55229 13 9 12.5523 9 12C9 11.4477 8.55229 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_129_547">
                        <rect width="16" height="16" fill="currentColor"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-warning-circle'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_129_546)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 4C12.5523 4 13 4.44772 13 5V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V5C11 4.44772 11.4477 4 12 4ZM12 20C12.6904 20 13.25 19.4404 13.25 18.75C13.25 18.0596 12.6904 17.5 12 17.5C11.3096 17.5 10.75 18.0596 10.75 18.75C10.75 19.4404 11.3096 20 12 20Z"
                      fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_129_546">
                    <rect width="24" height="24" fill="currentColor"/>
                </clipPath>
            </defs>
        </svg>
    )
}