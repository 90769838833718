import React from 'react';

const SmallArrowDown = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M6.80392 11.3419C6.2387 12.0201 6.33034 13.0282 7.0086 13.5934L15.1718 20.3961C15.7647 20.8901 16.6258 20.8901 17.2187 20.3961L25.3819 13.5934C26.0602 13.0282 26.1518 12.0201 25.5866 11.3419C25.0214 10.6636 24.0133 10.572 23.3351 11.1372L16.1953 17.087L9.05544 11.1372C8.37718 10.572 7.36914 10.6636 6.80392 11.3419Z" />
        </svg>

    )
}

export default SmallArrowDown;
