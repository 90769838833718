import classNames from 'classnames';
import styles from 'components/designSystem/components/Tag.module.scss';
import Icon from 'components/designSystem/foundations/Icons';
import { IconName, IconSize } from 'components/designSystem/foundations/IconsData';
import React, { FC } from 'react';

export enum TagSize {
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL',
    EXTRA_SMALL = 'EXTRA_SMALL',
}

export enum TagSeverity {
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL',
    HIGHLIGHT = 'HIGHLIGHT'
}

export enum TagStyle {
    BORDER,
    FILL
}

interface TagProps{
    size?: TagSize,
    severity?: TagSeverity,
    style?: TagStyle;
    icons?: {left?: IconName, right?: IconName}
    singleLine?: boolean
    onlyIcon?: boolean
}

export const Tag: FC<TagProps> = ({size = TagSize.MEDIUM, severity = TagSeverity.INFO, style = TagStyle.BORDER, ...props}) : JSX.Element => {
    const className = classNames(
        styles.tag,
        {
            [styles.tagSizeXSmall]: size === TagSize.EXTRA_SMALL,
            [styles.tagSizeSmall]: size === TagSize.SMALL,
            [styles.tagSizeMedium]: size === TagSize.MEDIUM,

            [styles.tagSeverityInfo]: severity === TagSeverity.INFO,
            [styles.tagSeveritySuccess]: severity === TagSeverity.SUCCESS,
            [styles.tagSeverityWarning]: severity === TagSeverity.WARNING,
            [styles.tagSeverityCritical]: severity === TagSeverity.CRITICAL,
            [styles.tagSeverityHighlight]: severity === TagSeverity.HIGHLIGHT,

            [styles.tagStyleBorder]: style == TagStyle.BORDER,

            [styles.tagSingleLine]: props.singleLine,
        }
    )

    return <div className={className}>
        {
            props.icons && props.icons.left &&
            <Icon size={IconSize.EXTRA_SMALL} name={props.icons.left}/>
        }
        {
            React.Children.count(props.children)>0 && <div>{props.children}</div>
        }
        {
            props.icons && props.icons.right &&
            <Icon size={IconSize.EXTRA_SMALL} name={props.icons.right}/>
        }
    </div>
}

interface IconTagProps {
    severity: TagSeverity
    icon: IconName
}

export const IconTag = (props: IconTagProps) : JSX.Element => {
    return <Tag size={TagSize.MEDIUM} style={TagStyle.FILL} icons={{left: props.icon}} severity={props.severity}/>
}
