import React from 'react';

const ArrowRight = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 16C4.5 16.7729 5.11297 17.4231 5.89796 17.4231L22.8341 17.4231L17.5145 23.0941C16.9853 23.6582 17.0006 24.5517 17.5502 25.0965C18.1075 25.6489 19 25.6317 19.5366 25.0597L27.1131 16.9828C27.629 16.4329 27.629 15.5671 27.1131 15.0172L19.5366 6.94026C19 6.3683 18.1075 6.35109 17.5502 6.9035C17.0006 7.44832 16.9853 8.34176 17.5145 8.90589L22.8341 14.5769L5.89796 14.5769C5.11297 14.5769 4.5 15.2272 4.5 16Z"
            />
        </svg>
    )
}

export default ArrowRight;