import { ModalType } from 'actions/common/CommonActions';
import { Colors } from 'components/legacyDesignSystem/branding/constants';
import SimpleCard from 'components/legacyDesignSystem/components/cards/SimpleCard';
import Container, { ContainerProfile, MarginType } from 'components/legacyDesignSystem/components/Container';
import { Icon, Icons } from 'components/legacyDesignSystem/components/Icon';
import Tag from 'components/legacyDesignSystem/components/Tag';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';

import BaseModule, { setupModule } from 'components/modules/modular/BaseModule';
import 'components/modules/modular/modules/rwe/CohortsComparator.scss';
import { APIText } from 'models/common/message';
import { ModuleData } from 'models/modular/storage';
import React from 'react';

enum CohortComparatorSide {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export interface CohortComparatorTag {
    title: APIText;
}

export interface CohortComparatorHeader {
    uuid: string;
    icon: Icons;
    title: APIText;
    subtitle: APIText | null;
    description: APIText | null;
    tags: CohortComparatorTag[];
}

export interface CohortComparatorItem {
    uuid: string;
    name: APIText;
    icon: Icons;
    subtitle: APIText;
    description: APIText;
}

export interface CohortsComparatorModulePagePayload {
    available_cohorts: CohortComparatorItem[];
    left_cohort?: CohortComparatorHeader;
    right_cohort?: CohortComparatorHeader;
}

const CONTEXT_KEY_COHORT_UUID_L = 'cohort_uuid_l';
const CONTEXT_KEY_COHORT_UUID_R = 'cohort_uuid_r';

class CohortsComparatorModule extends BaseModule<{}, CohortsComparatorModulePagePayload, {}> {

    protected selectCohort(cohort: CohortComparatorItem, side: CohortComparatorSide): void {
        side === CohortComparatorSide.LEFT
            ? this.props.alterContext(this.getConnectedContextKey(CONTEXT_KEY_COHORT_UUID_L), cohort.uuid)
            : this.props.alterContext(this.getConnectedContextKey(CONTEXT_KEY_COHORT_UUID_R), cohort.uuid);
    }

    protected renderCohortSelector(side: CohortComparatorSide): React.ReactNode {
        return (
            <Container
                profiles={[ContainerProfile.FLEX_COLUMN, ContainerProfile.ALIGN_CENTER]}
                onClick={(): void =>
                    this.props.modal(
                        ModalType.SIDE_RIGHT,
                        (close: () => void): JSX.Element => (
                            <React.Fragment>
                                {this.props.payload?.pagePayload.available_cohorts.map(
                                    (cohort: CohortComparatorItem): JSX.Element => {
                                        return (
                                            <Container key={cohort.uuid} profiles={[{ type: MarginType.MB, value: 4 }]}>
                                                <SimpleCard onClick={(): void => {
                                                    this.selectCohort(cohort, side);
                                                    close()
                                                }}>
                                                    {cohort.icon && (
                                                        <Container profiles={[{ type: MarginType.MB, value: 4 }]}>
                                                            <Icon
                                                                width={10}
                                                                height={10}
                                                                name={cohort.icon}
                                                                color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                                            />
                                                        </Container>
                                                    )}
                                                    <Container>
                                                        {cohort.name && (
                                                            <Container>
                                                                <Typography
                                                                    color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                                                    weight={TypographyWeight.MEDIUM}
                                                                    variant={TypographyVariant.BODY2}
                                                                >
                                                                    {cohort.name}
                                                                </Typography>
                                                            </Container>
                                                        )}
                                                        {cohort.subtitle && (
                                                            <Container>
                                                                <Typography variant={TypographyVariant.CAPTION}>
                                                                    {cohort.description}
                                                                </Typography>
                                                            </Container>
                                                        )}
                                                    </Container>
                                                </SimpleCard>
                                            </Container>
                                        );
                                    },
                                )}
                            </React.Fragment>
                        ),
                        { title: this.trans('rwe.compare.modal_title') },
                    )
                }
            >
                <Icon width={10} height={10} name={Icons.PLUS} color={Colors.PRIMARY_MAIN} />
                <Typography
                    weight={TypographyWeight.BOLD}
                    variant={TypographyVariant.CAPTION}
                    color={Colors.PRIMARY_MAIN}
                >
                    {this.trans('modules.cohort_comparator.add_cohort')}
                </Typography>
            </Container>
        );
    }

    protected renderCohortHeader(cohort: CohortComparatorHeader): React.ReactNode {
        const onCloseHandler = (): void => {
            if (this.props.payload?.pagePayload?.left_cohort?.uuid === cohort.uuid) {
                this.props.alterContext(CONTEXT_KEY_COHORT_UUID_L, undefined);
            }
            if (this.props.payload?.pagePayload?.right_cohort?.uuid === cohort.uuid) {
                this.props.alterContext(CONTEXT_KEY_COHORT_UUID_R, undefined);
            }
        };

        return (
            <Container profiles={[ContainerProfile.FLEX]}>
                {cohort.icon && (
                    <Container profiles={[{ type: MarginType.MR, value: 4 }]}>
                        <Icon
                            width={10}
                            height={10}
                            name={cohort.icon}
                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                            centered
                            circle
                            withBackground
                        />
                    </Container>
                )}
                <Container profiles={[ContainerProfile.FLEX_COLUMN, ContainerProfile.FLEX_GROW_1]}>
                    {cohort.title && (
                        <Typography
                            weight={TypographyWeight.BOLD}
                            variant={TypographyVariant.BODY2}
                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                        >
                            {this.transApiText(cohort.title)}
                        </Typography>
                    )}
                    {cohort.subtitle && (
                        <Typography
                            weight={TypographyWeight.REGULAR}
                            variant={TypographyVariant.OVERLINE}
                            color={Colors.NEUTRALS_BLUE_GREY}
                        >
                            {this.transApiText(cohort.subtitle)}
                        </Typography>
                    )}
                    {cohort.description && (
                        <Container
                            profiles={[
                                {
                                    type: MarginType.MT,
                                    value: 2,
                                },
                            ]}
                        >
                            <Typography
                                weight={TypographyWeight.REGULAR}
                                variant={TypographyVariant.OVERLINE}
                                color={Colors.NEUTRALS_BLUE_GREY}
                            >
                                {this.transApiText(cohort.description)}
                            </Typography>
                        </Container>
                    )}
                    {cohort.tags && cohort.tags.length > 0 && (
                        <Container
                            profiles={[
                                {
                                    type: MarginType.MT,
                                    value: 6,
                                },
                            ]}
                        >
                            {cohort.tags.map((tag: CohortComparatorTag, i) => (
                                <Tag key={i} title={this.transApiText(tag.title)} />
                            ))}
                        </Container>
                    )}
                </Container>
                <Container onClick={onCloseHandler}>
                    <Icon width={6} height={6} name={Icons.CLOSE} color={Colors.NEUTRALS_DARK_BLUE_GREY} />
                </Container>
            </Container>
        );
    }

    protected _render({
        pagePayload: { left_cohort, right_cohort },
    }: ModuleData<CohortsComparatorModulePagePayload, {}>): React.ReactNode {
        return (
            <SimpleCard>
                <Container
                    className={'cohort-comparator'}
                    profiles={[ContainerProfile.FLEX, ContainerProfile.ALIGN_CENTER]}
                >
                    {left_cohort && this.renderCohortHeader(left_cohort)}
                    {!left_cohort && this.renderCohortSelector(CohortComparatorSide.LEFT)}
                    <Container>
                        {right_cohort && this.renderCohortHeader(right_cohort)}
                        {!right_cohort && this.renderCohortSelector(CohortComparatorSide.RIGHT)}
                    </Container>
                </Container>
            </SimpleCard>
        );
    }
}

export default setupModule(
    CohortsComparatorModule,
    () => ({}),
    () => ({}),
);
