import React from 'react';

const Bacteria = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="14"/>
            <path d="M16 2C8.28037 2 2 8.28037 2 16C2 23.7196 8.28037 30 16 30C23.7196 30 30 23.7196 30 16C30 8.28037 23.7196 2 16 2ZM16 27.9392C9.41672 27.9392 4.06084 22.5833 4.06084 16C4.06084 9.41672 9.41672 4.06084 16 4.06084C22.5833 4.06084 27.9392 9.41672 27.9392 16C27.9392 22.5833 22.5833 27.9392 16 27.9392Z"/>
            <path d="M16.4374 8.62414L12.2906 8.00589C11.728 7.92151 11.2035 8.31028 11.1196 8.87307C11.0356 9.43586 11.4239 9.96021 11.9867 10.0442C16.4684 10.7123 16.1771 10.6738 16.2868 10.6738C16.7886 10.6738 17.2284 10.3065 17.3046 9.79521C17.3886 9.23237 17.0003 8.70803 16.4374 8.62414Z" fill="white"/>
            <path d="M15.9998 17.3239H14.9627C14.9182 17.1939 14.866 17.0675 14.8058 16.9456L15.5393 16.2122C15.9417 15.8098 15.9417 15.1574 15.5393 14.755C15.137 14.3526 14.4845 14.3526 14.0821 14.755L13.3487 15.4883C13.2268 15.4282 13.1004 15.376 12.9703 15.3315V14.2944C12.9703 13.7253 12.509 13.264 11.9399 13.264C11.3708 13.264 10.9095 13.7253 10.9095 14.2944V15.3315C10.7795 15.376 10.6531 15.4281 10.5311 15.4883L9.79776 14.755C9.39542 14.3526 8.74295 14.3526 8.34055 14.755C7.93816 15.1573 7.93816 15.8098 8.34055 16.2122L9.07402 16.9456C9.01387 17.0676 8.96164 17.1939 8.91718 17.3239H7.88003C7.31095 17.3239 6.84961 17.7853 6.84961 18.3543C6.84961 18.9234 7.31095 19.3848 7.88003 19.3848H8.91713C8.96159 19.5148 9.01376 19.6412 9.07397 19.7631L8.34061 20.4966C7.93822 20.899 7.93822 21.5514 8.34061 21.9538C8.74311 22.3562 9.39542 22.3562 9.79781 21.9538L10.5313 21.2204C10.6532 21.2805 10.7796 21.3327 10.9096 21.3772V22.4143C10.9096 22.9834 11.3709 23.4447 11.94 23.4447C12.5091 23.4447 12.9704 22.9834 12.9704 22.4143V21.3772C13.1004 21.3327 13.2268 21.2806 13.3487 21.2204L14.0821 21.9538C14.4845 22.3562 15.137 22.3562 15.5393 21.9538C15.9417 21.5515 15.9417 20.899 15.5393 20.4966L14.8059 19.7631C14.866 19.6412 14.9183 19.5148 14.9627 19.3848H15.9998C16.5689 19.3848 17.0302 18.9234 17.0302 18.3543C17.0302 17.7853 16.5689 17.3239 15.9998 17.3239ZM11.9399 19.4878C11.315 19.4878 10.8065 18.9793 10.8065 18.3543C10.8065 17.7294 11.315 17.2209 11.9399 17.2209C12.5649 17.2209 13.0734 17.7294 13.0734 18.3543C13.0734 18.9793 12.5649 19.4878 11.9399 19.4878Z" fill="white"/>
            <path d="M22.4342 10.8021C20.7171 10.8021 19.3201 12.1992 19.3201 13.9163C19.3201 15.6334 20.7171 17.0304 22.4342 17.0304C24.1513 17.0304 25.5484 15.6334 25.5484 13.9163C25.5484 12.1992 24.1514 10.8021 22.4342 10.8021ZM22.4342 14.9696C21.8534 14.9696 21.3809 14.4971 21.3809 13.9163C21.3809 13.3355 21.8534 12.863 22.4342 12.863C23.015 12.863 23.4875 13.3355 23.4875 13.9163C23.4875 14.4971 23.015 14.9696 22.4342 14.9696Z" fill="white"/>
            <path d="M22.0352 20.6177C21.9563 20.0541 21.4351 19.661 20.8718 19.7402C20.3082 19.8191 19.9153 20.3399 19.9943 20.9036L20.1047 21.6921H18.9997C18.4306 21.6921 17.9692 22.1534 17.9692 22.7225C17.9692 23.2916 18.4306 23.7529 18.9997 23.7529H21.2895C21.9156 23.7529 22.3967 23.1986 22.31 22.5795L22.0352 20.6177Z" fill="white"/>
        </svg>
    )
}

export default Bacteria;
