import {BlockingPopUpComponentIdentifier} from "components/pages/common/FullPopList";
import React, { Component, PropsWithChildren, ReactNode } from 'react';
import { AnyState } from 'core/store/Store';
import { reducer } from 'reducers/selector';
import { REDUCER_GLOBAL } from 'reducers/allReducers';
import { connect } from 'react-redux';
import {
    closeModal,
    updateModalExtra,
    ModalContentBuilder,
    ModalExtra,
    ModalInterface,
    ModalType,
    NullableModelExtra, BlockingModalPayload,
} from 'actions/common/CommonActions';
import { SideModal, SideModalType } from 'components/legacyDesignSystem/components/modals/SideModal';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import Container, { ContainerProfile, MarginType, PaddingType } from 'components/legacyDesignSystem/components/Container';
import CloseIcon from 'components/legacyDesignSystem/branding/icons/CloseIcon';
import IconButton from 'components/legacyDesignSystem/components/buttons/IconButton';
import { ButtonSize, ButtonStyle } from 'components/legacyDesignSystem/components/buttons/models';
import { ActionDispatcher } from 'actions/ActionInterface';
import { Colors } from 'components/legacyDesignSystem/branding/constants';
import Modal from "components/designSystem/components/Modal";
import { buildBlockingPopUpComponent } from "./FullPopUpDeclaration";

export interface ModalWrapperStateProps {
    modal?: ModalInterface
}

export interface ModalWrapperDispatchProps {
    close: () => void,
    updateExtra: (extra: NullableModelExtra) => void
}

class ModalWrapper extends Component<PropsWithChildren<ModalWrapperStateProps & ModalWrapperDispatchProps>> {

    componentDidUpdate(): void {
        if (!this.props.modal) {
            document.body.style.overflow = "unset";
        }
    }

    render(): ReactNode {
        const modal = this.props.modal;

        if (!modal) {
            return <div />
        }

        document.body.style.overflow = "hidden";

        if (modal.type == ModalType.SIDE_RIGHT && modal.contentBuilder) {
            return this.buildSide(modal.contentBuilder, modal.extra)
        }

        if (modal.type == ModalType.BLOCKING && modal.extra && modal.extra.blockingModalPayload) {
            return this.buildBlocking(modal.extra.blockingModalPayload)
        }

        if (modal.type == ModalType.CENTER && modal.contentBuilder) {
            return this.buildModal(modal.contentBuilder, modal.extra)
        }

        return <div />
    }

    protected close(): void {
        this.props.close()
    }

    buildModal(contentBuilder: ModalContentBuilder, extra?: ModalExtra):ReactNode {
        return (
            <Modal title={extra && extra.title && typeof extra.title !== 'string' ? extra.title : {default: undefined}}
                   fullWidth={extra?.fullWidth}
                   large={extra?.large}
                   close={(): void => this.close()}
                   withoutCloseButton={extra && extra.withoutCloseButton ? extra.withoutCloseButton : undefined}
            >
                {contentBuilder(
                    (): void => this.close(),
                    (extra: NullableModelExtra): void =>this.props.updateExtra(extra)
                )}
            </Modal>
        )
    }

    buildSide(
        contentBuilder: ModalContentBuilder,
        extra?: ModalExtra
    ): ReactNode {
        return <SideModal type={SideModalType.LEFT} close={(): void => this.close()}>
            <Container profiles={[
                { type: PaddingType.PT, value: 20 },
                { type: PaddingType.PR, value: 6 },
                { type: PaddingType.PB, value: 10 },
                { type: PaddingType.PL, value: 6 },
            ]}>
                <Container profiles={[ContainerProfile.SPACE_BETWEEN, ContainerProfile.ALIGN_CENTER, {type: MarginType.MB, value: 8}]}>
                    {
                        extra && extra.title ?
                        <Typography
                            variant={TypographyVariant.BODY}
                            weight={TypographyWeight.BOLD}
                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                        >
                            {extra.title}
                        </Typography> : <div> </div>
                    }
                    <div>
                        <IconButton size={ButtonSize.SMALL} style={ButtonStyle.TERTIARY} onClick={(): void => this.close()}>
                            {CloseIcon()}
                        </IconButton>
                    </div>
                </Container>
                <Container>
                    {contentBuilder(
                        (): void => this.close(),
                        (extra: NullableModelExtra): void =>this.props.updateExtra(extra)
                    )}
                </Container>
            </Container>
        </SideModal>
    }

    buildBlocking(
        blockingPopUp: BlockingModalPayload
    ): ReactNode {
        // remove buildBlockingPopUpComponent of Modal Wrapper when evens are not modal props anymore in CallHandler
        // contentBuilder should be no more optional

        return <Modal title={{default: undefined}} close={() : void => undefined} withoutCloseButton
                      fullWidth={blockingPopUp.identifier !== BlockingPopUpComponentIdentifier.TWOFA}
                      fullHeight={blockingPopUp.identifier !== BlockingPopUpComponentIdentifier.TWOFA}
        >
            {buildBlockingPopUpComponent(
                blockingPopUp.identifier,
                blockingPopUp.props,
                blockingPopUp.then ? [
                    closeModal(), ...blockingPopUp.then
                ] : [closeModal()],
                this.props.close
            )}
        </Modal>
    }

}


const mapStateToProps = (state: AnyState): ModalWrapperStateProps => ({
    modal: reducer(state, REDUCER_GLOBAL).modal,
});

const mapDispatchToProps = (dispatch: ActionDispatcher):ModalWrapperDispatchProps => ({
    close: (): void => dispatch(closeModal()),
    updateExtra: (extra: NullableModelExtra) : void => dispatch(updateModalExtra(extra))
});


export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);
