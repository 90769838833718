import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import React from 'react';

export const Hprim = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (<svg id={'xs-hprim'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.23438 6.26208C4.14109 6.26208 4.05557 6.31402 4.01255 6.39679C3.96953 6.47956 3.97617 6.5794 4.02977 6.65574L4.97186 7.99748L4.02949 9.34533C3.97609 9.42171 3.9696 9.52145 4.01266 9.6041C4.05572 9.68675 4.14118 9.73858 4.23438 9.73858H5.1382C5.22074 9.73858 5.29795 9.69785 5.34455 9.62972L5.72602 9.07199L6.1074 9.6297C6.15399 9.69784 6.23121 9.73858 6.31376 9.73858H7.21733C7.31052 9.73858 7.39598 9.68675 7.43904 9.6041C7.4821 9.52145 7.47561 9.42171 7.42222 9.34534L6.47986 7.99748L7.42193 6.65574C7.47553 6.5794 7.48217 6.47956 7.43915 6.39679C7.39613 6.31402 7.31061 6.26208 7.21733 6.26208H6.31376C6.23183 6.26208 6.15509 6.30223 6.10838 6.36954L5.72602 6.92046L5.34357 6.36952C5.29685 6.30222 5.22012 6.26208 5.1382 6.26208H4.23438Z"
                fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.53 1.50355C10.53 1.28143 10.4316 1.07075 10.2612 0.928247C10.0908 0.785747 9.86607 0.726094 9.64746 0.765358L1.64574 2.20252C1.28838 2.2667 1.02832 2.57764 1.02832 2.94071V13.0398C1.02832 13.4022 1.28746 13.7127 1.644 13.7776L9.64572 15.2342C9.86455 15.2741 10.0898 15.2147 10.2605 15.0722C10.4313 14.9297 10.53 14.7188 10.53 14.4963V1.50355ZM2.52832 12.414V3.568L9.03004 2.40025V13.5975L2.52832 12.414Z"
                  fill="currentColor"/>
            <path
                d="M11.9858 2.18652C11.5716 2.18652 11.2358 2.52231 11.2358 2.93652C11.2358 3.35074 11.5716 3.68652 11.9858 3.68652H13.4728V12.3135H11.9858C11.5716 12.3135 11.2358 12.6493 11.2358 13.0635C11.2358 13.4778 11.5716 13.8135 11.9858 13.8135H14.2228C14.637 13.8135 14.9728 13.4778 14.9728 13.0635V2.93652C14.9728 2.52231 14.637 2.18652 14.2228 2.18652H11.9858Z"
                fill="currentColor"/>
        </svg>)
    }
    return (
        <svg id={'s-hprim'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.23438 6.26208C4.14109 6.26208 4.05557 6.31402 4.01255 6.39679C3.96953 6.47956 3.97617 6.5794 4.02977 6.65574L4.97186 7.99748L4.02949 9.34533C3.97609 9.42171 3.9696 9.52145 4.01266 9.6041C4.05572 9.68675 4.14118 9.73858 4.23438 9.73858H5.1382C5.22074 9.73858 5.29795 9.69785 5.34455 9.62972L5.72602 9.07199L6.1074 9.6297C6.15399 9.69784 6.23121 9.73858 6.31376 9.73858H7.21733C7.31052 9.73858 7.39598 9.68675 7.43904 9.6041C7.4821 9.52145 7.47561 9.42171 7.42222 9.34534L6.47986 7.99748L7.42193 6.65574C7.47553 6.5794 7.48217 6.47956 7.43915 6.39679C7.39613 6.31402 7.31061 6.26208 7.21733 6.26208H6.31376C6.23183 6.26208 6.15509 6.30223 6.10838 6.36954L5.72602 6.92046L5.34357 6.36952C5.29685 6.30222 5.22012 6.26208 5.1382 6.26208H4.23438Z"
                fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.53 1.50355C10.53 1.28143 10.4316 1.07075 10.2612 0.928247C10.0908 0.785747 9.86607 0.726094 9.64746 0.765358L1.64574 2.20252C1.28838 2.2667 1.02832 2.57764 1.02832 2.94071V13.0398C1.02832 13.4022 1.28746 13.7127 1.644 13.7776L9.64572 15.2342C9.86455 15.2741 10.0898 15.2147 10.2605 15.0722C10.4313 14.9297 10.53 14.7188 10.53 14.4963V1.50355ZM2.52832 12.414V3.568L9.03004 2.40025V13.5975L2.52832 12.414Z"
                  fill="currentColor"/>
            <path
                d="M11.9858 2.18652C11.5716 2.18652 11.2358 2.52231 11.2358 2.93652C11.2358 3.35074 11.5716 3.68652 11.9858 3.68652H13.4728V12.3135H11.9858C11.5716 12.3135 11.2358 12.6493 11.2358 13.0635C11.2358 13.4778 11.5716 13.8135 11.9858 13.8135H14.2228C14.637 13.8135 14.9728 13.4778 14.9728 13.0635V2.93652C14.9728 2.52231 14.637 2.18652 14.2228 2.18652H11.9858Z"
                fill="currentColor"/>
        </svg>)
};
