import React, { FC } from 'react';
import styles from 'components/designSystem/containers/Centered.module.scss'
import classNames from "classnames";

interface CenteredProps {
    vertically?: boolean
    horizontally?: boolean
}

const Centered: FC<CenteredProps> = (
    {
        horizontally = true, //to avoid breaking components that used an old version of centered
        ...props
    }
): JSX.Element => {

    return <div className={classNames(
        styles.container,
        {
            [styles.containerHorizontally]: horizontally,
            [styles.containerVertically]: props.vertically,
        }
    )}>
        {props.children}
    </div>
}

export default Centered;