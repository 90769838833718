import React from 'react';

export const SuccessIcon = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path d="M16.0001 2.66669C13.363 2.66669 10.7851 3.44867 8.59248 4.91376C6.39983 6.37884 4.69086 8.46123 3.68169 10.8976C2.67253 13.3339 2.40848 16.0148 2.92295 18.6012C3.43742 21.1876 4.7073 23.5634 6.572 25.4281C8.4367 27.2928 10.8125 28.5627 13.3989 29.0772C15.9853 29.5916 18.6662 29.3276 21.1025 28.3184C23.5389 27.3092 25.6213 25.6003 27.0863 23.4076C28.5514 21.215 29.3334 18.6371 29.3334 16C29.3334 14.2491 28.9885 12.5152 28.3185 10.8976C27.6484 9.2799 26.6663 7.81004 25.4282 6.57193C24.1901 5.33382 22.7202 4.35169 21.1025 3.68163C19.4849 3.01156 17.751 2.66669 16.0001 2.66669ZM23.5681 13.6494L15.5681 20.9827C15.3148 21.2149 14.9816 21.3404 14.6381 21.333C14.2945 21.3255 13.9671 21.1857 13.7241 20.9427L9.72409 16.9427C9.59674 16.8197 9.49516 16.6726 9.42529 16.5099C9.35541 16.3472 9.31863 16.1723 9.31709 15.9952C9.31555 15.8182 9.34928 15.6426 9.41633 15.4787C9.48337 15.3149 9.58237 15.166 9.70756 15.0408C9.83275 14.9156 9.98162 14.8166 10.1455 14.7496C10.3093 14.6826 10.4849 14.6488 10.662 14.6504C10.839 14.6519 11.014 14.6887 11.1766 14.7586C11.3393 14.8284 11.4864 14.93 11.6094 15.0574L14.7068 18.1547L21.7654 11.684C22.026 11.445 22.3709 11.3192 22.7243 11.3345C23.0776 11.3498 23.4104 11.5047 23.6494 11.7654C23.8885 12.026 24.0142 12.3709 23.9989 12.7242C23.9837 13.0775 23.8287 13.4103 23.5681 13.6494Z"/>
        </svg>
    )
}
