import React from 'react';

const isLocalHost = (hostname: string): boolean =>
    !!(
        hostname === 'app.kiro.local' ||
        hostname === 'evidence.kiro.local' ||
        hostname === 'localhost' ||
        hostname === '[::1]' ||
        hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );

type HttpsRedirectProps = {};

export function HttpsRedirect(props: React.PropsWithChildren<HttpsRedirectProps>): JSX.Element | null {
    if (
        typeof window !== 'undefined' &&
        window.location &&
        window.location.protocol === 'http:' &&
        !isLocalHost(window.location.hostname)
    ) {
        window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
        return null;
    }

    return <div style={{ height: '100%' }}>{props.children}</div>;
}
