import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ArrowDown = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg  id={'xs-arrow-down'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.00017 2.58325C8.41438 2.58325 8.75017 2.91904 8.75017 3.33325V12.6666C8.75017 13.0808 8.41438 13.4166 8.00017 13.4166C7.58595 13.4166 7.25017 13.0808 7.25017 12.6666V3.33325C7.25017 2.91904 7.58595 2.58325 8.00017 2.58325Z"
                      fill="currentColor"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.80317 7.46958C3.09606 7.17668 3.57093 7.17668 3.86383 7.46958L8.00016 11.6059L12.1365 7.46958C12.4294 7.17668 12.9043 7.17668 13.1972 7.46958C13.4901 7.76247 13.4901 8.23735 13.1972 8.53024L8.53049 13.1969C8.2376 13.4898 7.76273 13.4898 7.46983 13.1969L2.80317 8.53024C2.51027 8.23735 2.51027 7.76247 2.80317 7.46958Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg  id={'s-arrow-down'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L12 17.5858L18.2929 11.2929C18.6834 10.9024 19.3166 10.9024 19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929Z"
                  fill="currentColor"/>
        </svg>
    )
}