import React from 'react';

const MagnifyingGlass = (): JSX.Element => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <circle cx="8.5" cy="8.5" r="7.5" stroke="#073C65" strokeWidth="2" />
        <line
            x1="14.4128"
            y1="14.0631"
            x2="18.5499"
            y2="18.5872"
            stroke="#073C65"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default MagnifyingGlass;
