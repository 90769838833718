import React, { FC, ReactNode } from 'react';
import styles from "./Modal.module.scss"
import classNames from 'classnames';
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import {TabProps, Tabs} from "components/designSystem/components/Tabs";
import { IconName } from "components/designSystem/foundations/IconsData";
import { BadgeSeverity } from "components/designSystem/components/Badge";

export interface ModalTabPayload {
    id: string,
    name: string,
    badgeValue?: number,
    badgeSeverity?: BadgeSeverity,
    onClick: () => void;
}

export interface ModalTitle {
    default?: ReactNode;
    selectedTab?: string,
    tabs?: ModalTabPayload[];
}

interface ModalProps {
    title: ModalTitle;
    close: () => void;
    fullWidth?: boolean;
    fullHeight?: boolean;
    large?: boolean;
    withoutCloseButton?: boolean;
}

const buildTabs = (tabs: ModalTabPayload[]): TabProps[] => {
    const tabProps: TabProps[] = []
    tabs.map((tab: ModalTabPayload) => {
        tabProps.push({
            name: tab.name,
            id: tab.id,
            onClick: tab.onClick,
            badgeValue: tab.badgeValue,
            badgeSeverity: tab.badgeSeverity
        })
    })
    return tabProps
}

const buildTitle = (title: ModalTitle): JSX.Element => {
    if (title.tabs) {
        return <Tabs selectedTab={title.selectedTab} tabs={buildTabs(title.tabs)}/>
    }
    return <div className={styles.title}>{title.default}</div>
}

const Modal: FC<ModalProps> = ({...props}): JSX.Element => {
    const hasTitle = props.title.tabs || props.title.default
    const closeButton = !props.withoutCloseButton && <Button
        variant={ButtonVariant.QUATERNARY}
        size={ButtonSize.SMALL}
        icons={{middle: IconName.CLOSE}}
        onClick={props.close}
    />

    return <div className={styles.wrapper}>
        <div className={styles.background} onClick={(): void => props.close()}/>
        <div className={classNames(styles.modal, {
            [styles.modalFullWidth]: props.fullWidth,
            [styles.modalFullHeight]: props.fullHeight,
            [styles.modalLarge]: props.large,
        })}>
            {
                hasTitle ? (
                    <div className={styles.titleContainer}>
                        {buildTitle(props.title)}
                        {closeButton}
                    </div>
                ) : <div className={styles.onlyClosedButton}>
                    {closeButton}
                </div>
            }
            <div>{props.children}</div>
        </div>
    </div>
}

export default Modal;