import { getDate } from 'components/common/TrDate';
import TrText from "components/common/TrText";
import Alert from "components/designSystem/components/Alert";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { getButtonInfo } from 'components/modules/modular/modules/helpers/callToActions';
import { getAlertSeverity } from "components/modules/modular/modules/transformers/modelsMapper";
import { TransDomain } from 'components/pages/common/MainComponent';
import { CallToAction, DisclaimerSeverity } from 'models/modular/communication';
import React from 'react';


interface PaymentModulePayload {
    severity: DisclaimerSeverity
    amount_due: string
    currency: string
    date: Date
    call_to_action?: CallToAction
}

const TRANSLATION_DOMAIN = TransDomain.MEDICAL_REPORT_INVOICES;

export const PaymentModule = (): JSX.Element => {
    const payload = useModulePayload<PaymentModulePayload>()
    const button_info = payload.call_to_action ? getButtonInfo(payload.call_to_action) : undefined
    return (
        <Alert
            severity={getAlertSeverity(payload.severity)}
            title={<TrText input={{trkey: 'title', trdomain: TRANSLATION_DOMAIN}}/>}
            description={<TrText
                input={{
                    trkey: 'disclaimer',
                    trdata: {
                        'amount_due': payload.amount_due,
                        'currency': payload.currency,
                        'date': getDate(payload.date)
                    },
                    trdomain: TRANSLATION_DOMAIN
                }}>
                <>0<strong>1</strong>2<br/>3<br/><i>5</i></>
            </TrText>
            }
            callToAction={button_info ? {
                actionName: button_info.actionName,
                onClick: button_info.onClick
            } : undefined}
        />
    )
};
