import React from 'react';

const Drugs = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <g clipPath="url(#clip0_1510_15756)">
                <path d="M26.9786 5.00423C24.3048 2.33186 19.9555 2.33186 17.2817 5.00423L11.6284 10.6575L18.0469 17.076C19.3679 15.9759 21.0427 15.2871 22.892 15.2871C23.9527 15.2871 24.9621 15.5074 25.8805 15.9009L26.9786 14.7012C29.6517 12.0281 29.6517 7.67812 26.9786 5.00423Z" />
                <path d="M16.9786 18.1621L10.5513 11.7348L5.01823 17.2732C2.34516 19.9463 2.34516 24.2963 5.01823 26.9702C7.69202 29.6424 12.0921 29.6934 14.7659 27.0209L15.8876 25.8939C15.4941 24.9754 15.2738 23.9661 15.2738 22.9053C15.2739 21.1035 15.9289 19.4679 16.9786 18.1621Z" />
                <path d="M16.7974 22.9054C16.7974 26.0104 19.1279 28.5444 22.1302 28.9232V16.8876C19.1279 17.2663 16.7974 19.8003 16.7974 22.9054Z" />
                <path d="M23.6538 16.8876V28.9232C26.6561 28.5444 28.9866 26.0104 28.9866 22.9053C28.9866 19.8003 26.6561 17.2663 23.6538 16.8876Z" />
            </g>
            <defs>
                <clipPath id="clip0_1510_15756">
                    <rect width="26" height="26" fill="white" transform="translate(3 3)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Drugs;
