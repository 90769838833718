import { ActionDispatcher } from 'actions/ActionInterface';
import { redirect } from 'actions/common/CommonActions';
import { PageLoader } from "components/core/items/PageLoader";
import { setup, TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_LEGACY, ROUTE_MODULAR_PAGE } from 'core/routing/Routes';
import { AnyState } from 'core/store/Store';
import { PageContext } from 'models/modular/api';
import { Navigation, PageLinkType } from 'models/user/NavigationModels';
import React, { ReactNode } from 'react';
import { REDUCER_USER_DATA } from 'reducers/allReducers';
import { reducer } from 'reducers/selector';
import { UserDataReducerInterface } from 'reducers/user/UserDataReducers';

export interface DefaultPageStateProps {
    navigation?: Navigation
}

export interface DefaultPageDispatchProps {
    redirectToPage: (pageId: string, context?: PageContext) => void;
    redirectToLegacy: (pageId: string) => void;
}

class DefaultPage extends WithTranslations<DefaultPageStateProps & DefaultPageDispatchProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    private redirect(): void {
        const navigation = this.props.navigation;
        if (navigation == null) return;

        // Use home navigation link if available
        const home = navigation.home;
        if (home == null) return;

        if (home.type === PageLinkType.MODULAR_PAGE) {
            this.props.redirectToPage(home.page, home.context)
        } else if (home.type === PageLinkType.LEGACY_PAGE) {
            this.props.redirectToLegacy(home.page);
        }
    }

    render(): ReactNode {
        this.redirect()
        return <PageLoader />
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): DefaultPageDispatchProps => ({
    redirectToPage: (pageId: string, context?: PageContext): void => {
        dispatch(redirect(
            getRoute(ROUTE_MODULAR_PAGE, {pageId}),
            context ? context : {}
        ));
    },
    redirectToLegacy: (pageId): void => {
        dispatch(redirect(ROUTE_LEGACY + pageId));
    },
});


const mapStateToProps = (state: AnyState): DefaultPageStateProps => ({
    navigation: reducer<UserDataReducerInterface>(state, REDUCER_USER_DATA).navigation,
});

export default setup(DefaultPage, mapStateToProps, mapDispatchToProps);
