import React, { ReactNode } from 'react';
import _ from 'underscore';
import classNames from 'classnames';

import { redirect } from 'actions/common/CommonActions';
import { getMyPatients } from 'actions/doctorBoard/DoctorBoardFetchActions';
import MainComponent, { key, MainComponentProps, setup, TransDomain } from 'components/pages/common/MainComponent';
import LegacySearchBar from "components/core/inputs/LegacySearchBar";
import { Patient, PatientList } from 'components/modules/doctorBoard/Types';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_MEDICAL_REPORT_PERSON_REPORTS } from 'core/routing/Routes';
import { formatName, normalizeStr } from 'core/utils/Name';
import { formatDate } from 'core/utils/Date';
import { REDUCER_DOCTOR } from 'reducers/allReducers';
import { fetchReducer } from 'reducers/selector';

import 'components/pages/common/PatientSearchBar.scss';
import { ActionDispatcher } from 'actions/ActionInterface';
import { AnyState } from 'core/store/Store';

export interface ScoredPatient {
    p: Patient;
    score: number;
}

export interface PatientSearchBarState {
    currentInput: string;
    filteredPatients: Patient[];
}

export interface PatientSearchBarStateProps {
    patientList?: PatientList;
}

export interface PatientSearchBarDispatchProps {
    displayPersonPage: (id: string) => void;
    getMyPatients: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export class LegacyPatientSearchBar extends MainComponent<PatientSearchBarStateProps & PatientSearchBarDispatchProps & MainComponentProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    state: PatientSearchBarState = {
        currentInput: '',
        filteredPatients: [],
    };

    componentDidMount(): void {

        this.props.getMyPatients();
    }

    componentDidUpdate(prevProps: PatientSearchBarStateProps, prevState: PatientSearchBarState): void {
        const { currentInput, filteredPatients } = this.state;
        const { patientList } = this.props;

        if (prevState.currentInput !== currentInput) {
            if (patientList === undefined || normalizeStr(currentInput) === '') {
                this.setState({ filteredPatients: [] });
            } else {
                const preFilteredList =
                    !_.isEmpty(filteredPatients) && !_.isNull(currentInput.match(`^${prevState.currentInput}`))
                        ? filteredPatients
                        : patientList.patients;
                const newFilteredPatients = LegacyPatientSearchBar.filterPatients(currentInput, preFilteredList);
                this.setState({ filteredPatients: newFilteredPatients });
            }
        }
    }

    onFocus = (): void => {
        this.props.getMyPatients();
        this.props.onFocus && this.props.onFocus();
    };

    onBlur = (): void => {
        this.setState({ currentInput: '' })
        this.props.onBlur && this.props.onBlur();
    };

    static filterPatients = (input: string, patients: Patient[]): Patient[] =>
        patients
            .map((p: Patient) => ({ p: p, score: LegacyPatientSearchBar._computeScore(p, input) }))
            .filter((r) => r.score > 0)
            .sort(LegacyPatientSearchBar._sort)
            .map((r) => r.p);

    static _computeScore = (p: Patient, input_: string): number => {
        const firstLast = normalizeStr(`${p.first_name} ${p.last_name}`);
        const lastFirst = normalizeStr(`${p.last_name} ${p.first_name}`);
        const input = normalizeStr(input_);

        return (
            2 * Number(lastFirst.slice(0, input.length) === input) + Number(firstLast.slice(0, input.length) === input)
        );
    };

    static _sort = (sp1: ScoredPatient, sp2: ScoredPatient): number => {
        const diffScore: number = LegacyPatientSearchBar._sortByScore(sp1, sp2);
        if (diffScore !== 0) return diffScore;
        const diffLastName = LegacyPatientSearchBar._sortByLastName(sp1.p, sp2.p);
        if (diffLastName !== 0) return diffLastName;
        return LegacyPatientSearchBar._sortByFirstName(sp1.p, sp2.p);
    };

    static _sortByScore = (sp1: ScoredPatient, sp2: ScoredPatient): number => {
        return sp2.score - sp1.score;
    };

    static _sortByLastName = (p1: Patient, p2: Patient): number => {
        if (p1.last_name < p2.last_name) return -1;
        if (p1.last_name > p2.last_name) return 1;
        return 0;
    };

    static _sortByFirstName = (p1: Patient, p2: Patient): number => {
        if (p1.first_name < p2.first_name) return -1;
        if (p1.first_name > p2.first_name) return 1;
        return 0;
    };

    onOptionClick = (p: Patient): void => {
        this.props.displayPersonPage(p.id);
    };

    renderOptionRow = (p: Patient, index: number | string | undefined, cursor: number): ReactNode => {
        return (
            <div
                key={index}
                className={classNames('search-bar-patient-row-container', {
                    'search-bar-patient-row-container-active': cursor === index,
                })}
                onClick={(): void => this.props.displayPersonPage(p.id)}
            >
                <div className="search-bar-patient-row">
                    <span className="search-bar-patient-name">{formatName(p.first_name, p.last_name)}</span>
                    {!_.isUndefined(p.birth_date) && !_.isNull(p.birth_date) && (
                        <span className="search-bar-patient-birthdate">
                            {this.transContent(key('born', 'user'), <><span>formatted_date</span></>, {
                                date: formatDate(p.birth_date),
                            })}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    render(): ReactNode {
        return (
            <LegacySearchBar
                disabled={this.props.patientList === undefined}
                defaultText={this.trans('search_bar_default_text')}
                onInputChange={(newValue: string): void =>
                    this.setState({
                        currentInput: newValue,
                    })
                }
                selectOptionMode={{
                    options: this.state.filteredPatients,
                    renderOptionRow: this.renderOptionRow,
                    onOptionClick: this.onOptionClick,
                }}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
            />
        );
    }
}

const mapStateToProps = (state: AnyState): PatientSearchBarStateProps => ({
    patientList: fetchReducer(state, REDUCER_DOCTOR).patients,
});

const mapDispatchToProps = (dispatch: ActionDispatcher): PatientSearchBarDispatchProps => ({
    getMyPatients: (): void => dispatch(getMyPatients()),
    displayPersonPage: (personId: string): void =>
        dispatch(redirect(getRoute(ROUTE_MEDICAL_REPORT_PERSON_REPORTS, {personId: personId}))),
});

export default setup(LegacyPatientSearchBar, mapStateToProps, mapDispatchToProps);
