import { ActionDispatcher } from 'actions/ActionInterface';
import { forgotPW } from 'actions/user/UserActions';
import Form, { FormState } from 'components/commonDesign/form/tmpNewSystem/Form';
import FormInput from "components/commonDesign/form/tmpNewSystem/FormInput";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import { CenteredColumn } from "components/designSystem/Aliases";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { ColumnWidthInREM } from "components/designSystem/containers/Column";
import { XLargeSpacer, XSmallSpacer } from "components/designSystem/containers/Spacer";
import { TBody, TDisplaySmall700, TypographyColor } from "components/designSystem/containers/Typography";
import { MarginSize, WithTopMargin } from "components/designSystem/containers/WithMargin";
import APIFeedbackMessageContainer from 'components/pages/common/APIFeedbackMessage';
import { TransDomain } from "components/pages/common/MainComponent";
import WithTranslations from "components/pages/common/WithTranslations";
import { required } from "components/utils/form/tmpNewSystem/validators";
import React from 'react';
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';

export interface ForgotPasswordDispatchProps {
    onSubmit: (email: string) => void;
}

export const FEEDBACK_IDENTIFIER_API_FORGOT_PW = 'FEEDBACK_IDENTIFIER_API_FORGOT_PW';

class ForgotPassword extends WithTranslations<ForgotPasswordDispatchProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    onSubmit(): (data: { email: string }) => void {
        return (data: { email: string }): void => {
            this.props.onSubmit(data.email);
        };
    }

    render(): JSX.Element {
        return <MediaQuery minWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
            {(match: boolean): JSX.Element => match ? this.buildContent(false) : this.buildContent(true)}
        </MediaQuery>
    }

    private buildContent(isMobile: boolean): JSX.Element {
        return <WithTopMargin margin={MarginSize.LARGE}>
            <CenteredColumn widthInRem={isMobile ? ColumnWidthInREM.WIDTH_22 : ColumnWidthInREM.WIDTH_28}>
                <XLargeSpacer>
                    <APIFeedbackMessageContainer
                        identifier={FEEDBACK_IDENTIFIER_API_FORGOT_PW}
                        withNewDesignSystem
                    />
                    <XSmallSpacer>
                        <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                            {this.trans('reset_password_title')}
                        </TDisplaySmall700>
                        <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            {this.trans('reset_password_explanation')}
                        </TBody>
                    </XSmallSpacer>
                    <Form onSubmit={this.onSubmit()}>
                        {(formState: FormState): JSX.Element => {
                            return (
                                <XLargeSpacer>
                                    <FormInput
                                        name={'email'}
                                        field={{
                                            label: this.trans('email'),
                                            type: "text",
                                            required: true,
                                        }}
                                        validate={required('email')}
                                    />
                                    <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} isSubmit fullWidth
                                            disabled={formState.alreadySubmitted}
                                    >
                                        {this.trans('validate', undefined, TransDomain.GLOBAL)}
                                    </Button>
                                </XLargeSpacer>
                            )
                        }}
                    </Form>
                </XLargeSpacer>
            </CenteredColumn>
        </WithTopMargin>
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): ForgotPasswordDispatchProps => ({
    onSubmit: (email: string): void => {
        dispatch(forgotPW(email, FEEDBACK_IDENTIFIER_API_FORGOT_PW));
    },
});

const mapStateToProps = (): {} => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
