import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const InfoCircle = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-info-circle'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_147_1164)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75ZM8 3.5C7.44772 3.5 7 3.94772 7 4.5C7 5.05228 7.44772 5.5 8 5.5C8.55229 5.5 9 5.05228 9 4.5C9 3.94772 8.55229 3.5 8 3.5Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_147_1164">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-info-circle'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_147_1163)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 19C11.4477 19 11 18.5523 11 18V10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44771 13 10V18C13 18.5523 12.5523 19 12 19ZM12 5C11.3096 5 10.75 5.55964 10.75 6.25C10.75 6.94036 11.3096 7.5 12 7.5C12.6904 7.5 13.25 6.94036 13.25 6.25C13.25 5.55964 12.6904 5 12 5Z"
                      fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_147_1163">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}