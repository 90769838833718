import { API_GET_MY_DOCTOR_DASHBOARD, API_GET_MY_PATIENTS } from 'actions/doctorBoard/DoctorBoardFetchActions';
import { APICallSuccessAction, getActionPayload, successful } from 'actions/ActionInterface';
import { AnyState } from 'core/store/Store';
import { DoctorDashboardData, DoctorDashboardDataWithMode } from 'models/doctor/DashboardModels';

interface DoctorBoardFetchReducerState {
    dashboard?: DoctorDashboardDataWithMode;
}
export const initialState: DoctorBoardFetchReducerState = {
    dashboard: undefined,
};

export default function doctorBoardFetchReducers(state = initialState, action: APICallSuccessAction): AnyState {
    switch (action.type) {
        case successful(API_GET_MY_PATIENTS):
            return {
                ...state,
                patients: action.payload.response,
            };
        case successful(API_GET_MY_DOCTOR_DASHBOARD):
            const doctorDashboardActionPayload = getActionPayload<{
                response: DoctorDashboardData;
                extra: { isForNavigation: boolean; onlyAsReceiver: boolean };
            }>(action);
            return {
                ...state,
                dashboard: {
                    ...doctorDashboardActionPayload.response,
                    onlyAsReceiver: doctorDashboardActionPayload.extra.onlyAsReceiver,
                },
            };

        default:
            return state;
    }
}
