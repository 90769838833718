import React, { FC } from "react";
import Loader, { LoaderType } from "components/designSystem/components/Loader";
import { toLocalTransKey } from "models/common/message";
import { TransDomain } from "components/pages/common/MainComponent";
import TrText from "components/common/TrText";
import { capitalizeFirstLetter } from "core/utils/text";
import { KiroLogo, LogoColor, LogoType } from "components/designSystem/foundations/logos/KiroLogo";

export const PageLoader: FC = () => {
    return <Loader payload={{
        type: LoaderType.PAGE,
        icon: <KiroLogo
            type={LogoType.SYMBOL} color={LogoColor.CURRENT_COLOR} small
        />,
        label: <TrText
            input={toLocalTransKey("loader_label", TransDomain.GLOBAL)}
            postTrans={capitalizeFirstLetter}
        />
    }}/>
}