import { CustomError, Errors } from 'sagas/api/rest/Exceptions';

export const checkResponse = async (response: Response | undefined): Promise<void> => {
    if (response === undefined) {
        throw new CustomError(Errors.UnknownError, 'Undefined response');
    }
    const internalErrorId: string | null = response.headers.get('X-INTERNAL-ERROR-ID'); // legacy
    const internalErrorCode: string | null = response.headers.get('X-INTERNAL-ERROR-CODE');

    let payload = '';
    let code;
    let payloadJson;
    switch (response.status) {
        case 200:
            return;
        case 400:
            payload = await response.text();
            throw new CustomError(Errors.RequestError, payload);
        case 401:
            if (internalErrorId === 'TWO_FA_REQUIRED') {
                throw new CustomError(Errors.Needs2FA, await response.text());
            }
            try {
                payloadJson = await response.json();
                code = payloadJson['detail'].substr(0, 6);
            } catch (e) {
                throw new CustomError(Errors.InvalidToken, payload);
            }

            if (code === '[P001]') {
                throw new CustomError(Errors.PinLocked, payload);
            }

            throw new CustomError(Errors.InvalidToken, payload);
        case 403:
            try {
                payload = await response.text();
                code = payload.substr(0, 6);
            } catch (e) {
                throw new CustomError(Errors.ForbiddenAccess, payload);
            }

            if (code === '[C001]') {
                throw new CustomError(Errors.IPNotAllowed, payload);
            } else if (code === '[I001]') {
                throw new CustomError(Errors.OutdatedToken);
            } else {
                throw new CustomError(Errors.ForbiddenAccess, payload);
            }
        case 404:
            throw new CustomError(Errors.MissingResource, payload);
        case 405:
            throw new CustomError(Errors.MethodNotAllowed, payload);
        case 408:
            throw new CustomError(Errors.Timeout, payload);
        case 409:
            payload = await response.text();
            throw new CustomError(Errors.Conflict, payload);
        case 412:
            throw new CustomError(Errors.PreconditionFailed, payload);
        case 429:
            throw new CustomError(Errors.TooManyRequest, payload);
        case 451:
            payload = await response.text();
            throw new CustomError(
                internalErrorId === 'EULA_NOT_ACCEPTED' ? Errors.EULANotAccepted : Errors.UnavailableForLegalReason,
                payload,
            );
        case 502:
            throw new CustomError(Errors.BadGateway, payload);
        case 503:
            if (internalErrorCode == "5031") {
                throw new CustomError(
                    Errors.RetryLater,
                    response.headers.get('Retry-After')
                );
            }

            throw new CustomError(Errors.ServiceUnavailable, payload);
        default:
            throw new CustomError(Errors.ServerError, payload);
    }
};
