import React, { Component, PropsWithChildren, ReactNode } from "react";
import classNames from "classnames";

import "./Button.scss"

export enum ButtonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    TERTIARY_LIGHT = 'tertiary-light',
}

export enum ButtonSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export type ButtonProps = PropsWithChildren<React.HTMLProps<HTMLButtonElement> & {
    customType?: ButtonType;
    customSize?: ButtonSize;
    onDarkBackground?: boolean;
    disabled?: boolean;
    // overwrite type because React.HTMLProps<HTMLButtonElement> gives type string | undefined
    type?: "submit" | "reset" | "button";
}>

export interface RedirectButtonProps extends ButtonProps {
    redirectUrl: string;
}


export class Button extends Component<PropsWithChildren<ButtonProps>>{
    getClassName(
        buttonType?: ButtonType,
        buttonSize?: ButtonSize,
        onDarkBackground?: boolean,
        disabled?: boolean,
): string {
        const type: ButtonType = buttonType ? buttonType : ButtonType.PRIMARY

        let size: ButtonSize | undefined = undefined
        if (![ButtonType.TERTIARY, ButtonType.TERTIARY_LIGHT].includes(type)) {
            size = buttonSize || ButtonSize.MEDIUM
        }

        return classNames(
            'content-button-container',
            `button--${type}`,
            !!onDarkBackground && `button--${type}--onDarkBackground`,
            !!size && `button--${size}`,
            !!disabled && `button--disabled button--${type}--disabled`,
        );
    }

    render(): ReactNode {

        const buttonProps = {...this.props}
        delete buttonProps.customType
        delete buttonProps.customSize
        delete buttonProps.onDarkBackground
        delete buttonProps.type

        return (
            <button
                {...buttonProps}
                type={this.props.type || "button"}
                className={this.getClassName(
                    this.props.customType,
                    this.props.customSize,
                    this.props.onDarkBackground,
                    this.props.disabled,
                )}
            >
                {this.props.children}
            </button>
        )
    }
}

export const RedirectButton = (props: RedirectButtonProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,react/prop-types
    const {redirectUrl, onClick, ...buttonProps} = props

    return (
        <a className={"legacy-a"} href={props.redirectUrl} target={'_blank'} rel={'noreferrer'}>
            {/* @ts-ignore*/}
            <Button
                {...buttonProps}
                onClick={(): void => {
                    // @ts-ignore
                    // eslint-disable-next-line react/prop-types
                    !!props.onClick && props.onClick()
                }}
            />
        </a>
    )
}
