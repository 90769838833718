import EntityLogo from "components/commonDesign/EntityLogo";
import LogoFull from 'core/content/brand/logos/LogoFull';
import Swap from 'core/content/icons/Swap';
import { Entity } from 'models/entities/default';
import React, { Component, ReactNode } from 'react';
import './LinkWithKiro.scss';


export interface LinkWithKiroInterface {
    linked?: ReactNode;
    entity: Entity
}

export default class LinkWithKiro extends Component<LinkWithKiroInterface> {
    render(): ReactNode {
        return (
            <div className={'link-with-container'}>
                <div className={"link-item"}>
                    <EntityLogo entity={this.props.entity}/>
                </div>
                <div className={"link-item link-item-icon"}>
                    <Swap />
                </div>
                <div className={"link-item"}>
                    <LogoFull />
                </div>
            </div>
        );
    }
}
