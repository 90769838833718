import { AnyState } from 'core/store/Store';
import { REDUCER_MODULAR } from 'reducers/allReducers';
import { ConnectedContext, ModuleData } from 'models/modular/storage';
import { GetPageResponse } from 'models/modular/api';
import { fromEntries } from 'core/utils/Getters';

export function modulePayload<T = {}>(state: AnyState, identifier: string): ModuleData<T> | undefined {
    return state[REDUCER_MODULAR].modulesData[identifier];
}

export function moduleConnectedContext(state: AnyState, identifier: string): ConnectedContext {
    const page: GetPageResponse | undefined = state[REDUCER_MODULAR].currentPage;

    if (!page) {
        return {}
    }

    const moduleData: ModuleData | undefined = state[REDUCER_MODULAR].modulesData[identifier];

    if (!moduleData) {
        return {}
    }
    return fromEntries(Object.entries(moduleData.contextMapping).map(
        ([ctxKey, internalKey]) => [internalKey, {value: page.context[ctxKey], contextKey: ctxKey}]
    ));

}


