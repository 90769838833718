import classnames from "classnames";
import { ColorMap } from "components/designSystem/Aliases";
import { colorMapToFillClassDefault, colorMapToStrokeClass } from "components/designSystem/utils/colorMap";
import React, { Fragment } from 'react';

import { ChartItem, ChartItemType } from 'models/charts/charts';
import { APIText, TranslationMethod } from 'models/common/message';
import { NumericalValue } from 'models/medicalReport/ReportModels';
import { ChartItemPie, ChartItemPieSegment } from 'models/charts/items/PieSegment';
import { ExtendedHalfPiePayload } from 'models/charts/charts/HalfPie';
import { cast } from 'core/utils/Typed';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import { Colors } from 'components/legacyDesignSystem/branding/constants';
import Container, { ContainerProfile, MarginType } from 'components/legacyDesignSystem/components/Container';

import './CustomHalfPie.scss';
import { ValueFormatterInterface } from 'models/charts/models';

const MAGIC_RATIO = 15.91549430918954;

export interface SimpleHalfPieItem {
    title: APIText;
    value: NumericalValue;
    additional_value?: NumericalValue;
    color: ColorMap;
}

interface HalfPieSegment {
    dash: string;
    color: ColorMap;
    offset: number;
}


export function buildCustomHalfPie(
    items: ChartItem[],
    payload: ExtendedHalfPiePayload,
    translator: TranslationMethod,
    valueFormatter?: ValueFormatterInterface
): JSX.Element {
    const { selected_segment, onSegmentHover } = payload;

    const defineStrokeWidth = (i: number): number => {
        return selected_segment === i ? 3 : 2;
    };

    const renderActiveClass = (i: number, className: string): string => {
        return selected_segment === i ? className : '';
    };

    const renderPieSegment = (translator: TranslationMethod, data: ChartItem): JSX.Element | undefined => {
        if (data.type === ChartItemType.PIE_SEGMENT) {
            const { segments } = cast<ChartItemPie>(data.payload);

            // Calculations
            const total = segments.reduce(
                (acc: number, curr: ChartItemPieSegment) => (curr.value.value ? acc + curr.value.value : 0),
                0,
            );
            const chartSegments: HalfPieSegment[] = [];

            segments.reduce((acc: number, curr: ChartItemPieSegment) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const dashValue = acc && curr.value.value ? (curr.value.value! / total) * 50 : (curr.value.value! / total) * 50;
                const offset = acc ? 100 - acc + 50 : 50;
                chartSegments.push({
                    dash: `${dashValue} ${100 - dashValue}`,
                    offset,
                    color: curr.color,
                });
                return acc + dashValue;
            }, 0);

            return (
                <>
                    <Container profiles={[ContainerProfile.RELATIVE]}>
                        <svg width="100%" viewBox="0 0 42 21">
                            {chartSegments.map((segment: HalfPieSegment, i) => {
                                return (
                                    <circle
                                        key={i}
                                        className={classnames('custom-half-pie__segment', colorMapToStrokeClass(segment.color))}
                                        onMouseEnter={onSegmentHover ? (): void => onSegmentHover(i) : undefined}
                                        cx="21"
                                        cy="21"
                                        r={MAGIC_RATIO}
                                        fill="transparent"
                                        stroke={segment.color}
                                        strokeWidth={defineStrokeWidth(i)}
                                        strokeDasharray={segment.dash}
                                        strokeDashoffset={segment.offset}
                                    />
                                );
                            })}
                            <circle cx="21" cy="21" r={MAGIC_RATIO} fill="#fff" />
                        </svg>
                        {segments.map((item: SimpleHalfPieItem, i: number) => (
                            <div
                                key={i}
                                className={`custom-half-pie__stage ${renderActiveClass(
                                    i,
                                    'custom-half-pie__stage--active',
                                )}`}
                            >
                                <Typography
                                    variant={TypographyVariant.BODY}
                                    weight={TypographyWeight.MEDIUM}
                                    color={Colors.NEUTRALS_BLUE_GREY2}
                                >
                                    {translator(item.title)}
                                </Typography>
                                <Typography
                                    variant={TypographyVariant.H3}
                                    weight={TypographyWeight.BOLD}
                                    color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                >
                                    {valueFormatter ? valueFormatter(item.value) : item.value.raw_full_display}
                                </Typography>
                                {item.additional_value && (
                                    <Typography
                                        variant={TypographyVariant.BODY}
                                        weight={TypographyWeight.REGULAR}
                                        color={Colors.NEUTRALS_BLUE_GREY}
                                    >
                                        {valueFormatter ? valueFormatter(item.additional_value) : item.additional_value.raw_full_display}
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </Container>
                    <Container
                        profiles={[
                            ContainerProfile.JUSTIFY_CONTENT_CENTER,
                            ContainerProfile.FLEX_WRAP,
                            { type: MarginType.MT, value: 8 },
                            { type: MarginType.MB, value: 4 },
                        ]}
                    >
                        {segments.map((item: SimpleHalfPieItem, i: number) => (
                            <Container
                                key={i}
                                onMouseEnter={onSegmentHover ? (): void => onSegmentHover(i) : undefined}
                                profiles={[
                                    { type: MarginType.ML, value: 5 },
                                    { type: MarginType.MR, value: 5 },
                                    { type: MarginType.MB, value: 5 },
                                ]}
                                className={'custom-half-pie__legend_item'}
                            >
                                <Container
                                    profiles={[
                                        ContainerProfile.FLEX,
                                        ContainerProfile.RELATIVE,
                                        ContainerProfile.ALIGN_FLEX_START,
                                    ]}
                                >
                                    <span
                                        className={
                                            classnames(
                                                'custom-half-pie__legend_color',
                                                renderActiveClass(i, 'custom-half-pie__legend_color--active'),
                                                colorMapToFillClassDefault(item.color)
                                            )
                                        }
                                    />
                                    {selected_segment === i ? (
                                        <Typography
                                            variant={TypographyVariant.SMALL}
                                            weight={TypographyWeight.BOLD}
                                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                        >
                                            {translator(item.title)}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant={TypographyVariant.SMALL}
                                            weight={TypographyWeight.REGULAR}
                                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                        >
                                            {translator(item.title)}
                                        </Typography>
                                    )}
                                </Container>
                                <Container
                                    profiles={[
                                        ContainerProfile.FLEX,
                                        ContainerProfile.RELATIVE,
                                        ContainerProfile.ALIGN_BASELINE,
                                    ]}
                                >
                                    <Container
                                        profiles={[
                                            { type: MarginType.MR, value: 1 },
                                        ]}
                                    >
                                        <Typography
                                            variant={TypographyVariant.XSMALL}
                                            weight={TypographyWeight.MEDIUM}
                                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                        >
                                            {valueFormatter ? valueFormatter(item.value) : item.value.raw_full_display}
                                        </Typography>
                                    </Container>
                                    {item.additional_value && (
                                        <Typography
                                            variant={TypographyVariant.OVERLINE}
                                            weight={TypographyWeight.REGULAR}
                                            color={Colors.NEUTRALS_BLUE_GREY}
                                        >
                                            {valueFormatter ? valueFormatter(item.additional_value) : item.additional_value.raw_full_display}
                                        </Typography>
                                    )}
                                </Container>
                            </Container>
                        ))}
                    </Container>
                </>
            );
        }
    };

    return (
        <Fragment>
            {items.map((item, i) => {
                switch (item.type) {
                    case ChartItemType.PIE_SEGMENT:
                        return <Fragment key={i}>{renderPieSegment(translator, item)}</Fragment>;
                }
            })}
        </Fragment>
    );
}
