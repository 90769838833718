import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ExternalLink = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-external-link'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M1.25 13C1.25 13.9665 2.0335 14.75 3 14.75L13 14.75C13.9665 14.75 14.75 13.9665 14.75 13V11.4737C14.75 11.0595 14.4142 10.7237 14 10.7237C13.5858 10.7237 13.25 11.0595 13.25 11.4737V13C13.25 13.1381 13.1381 13.25 13 13.25L3 13.25C2.86193 13.25 2.75 13.1381 2.75 13L2.75 3C2.75 2.86193 2.86193 2.75 3 2.75L5.15789 2.75C5.57211 2.75 5.90789 2.41421 5.90789 2C5.90789 1.58579 5.57211 1.25 5.15789 1.25H3C2.0335 1.25 1.25 2.0335 1.25 3V13ZM7.25 2C7.25 1.58579 7.58579 1.25 8 1.25H13C13.9665 1.25 14.75 2.0335 14.75 3V8C14.75 8.41421 14.4142 8.75 14 8.75C13.5858 8.75 13.25 8.41421 13.25 8V3.81066L8.53033 8.53033C8.23744 8.82322 7.76256 8.82322 7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967L12.1893 2.75H8C7.58579 2.75 7.25 2.41421 7.25 2Z" />
            </svg>

        )
    }

    return (
        <svg id={'s-external-link'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M1.25 13C1.25 13.9665 2.0335 14.75 3 14.75L13 14.75C13.9665 14.75 14.75 13.9665 14.75 13V11.4737C14.75 11.0595 14.4142 10.7237 14 10.7237C13.5858 10.7237 13.25 11.0595 13.25 11.4737V13C13.25 13.1381 13.1381 13.25 13 13.25L3 13.25C2.86193 13.25 2.75 13.1381 2.75 13L2.75 3C2.75 2.86193 2.86193 2.75 3 2.75L5.15789 2.75C5.57211 2.75 5.90789 2.41421 5.90789 2C5.90789 1.58579 5.57211 1.25 5.15789 1.25H3C2.0335 1.25 1.25 2.0335 1.25 3V13ZM7.25 2C7.25 1.58579 7.58579 1.25 8 1.25H13C13.9665 1.25 14.75 2.0335 14.75 3V8C14.75 8.41421 14.4142 8.75 14 8.75C13.5858 8.75 13.25 8.41421 13.25 8V3.81066L8.53033 8.53033C8.23744 8.82322 7.76256 8.82322 7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967L12.1893 2.75H8C7.58579 2.75 7.25 2.41421 7.25 2Z"/>
        </svg>

    )
}