import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import * as React from 'react';

export const QuestionCircle = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-question-circle'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="icon">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.99991 1.49866C4.4093 1.49866 1.49854 4.40942 1.49854 8.00003C1.49854 11.5906 4.4093 14.5014 7.99991 14.5014C11.5905 14.5014 14.5013 11.5906 14.5013 8.00003C14.5013 4.40942 11.5905 1.49866 7.99991 1.49866ZM-0.00146484 8.00003C-0.00146484 3.58099 3.58087 -0.00134277 7.99991 -0.00134277C12.4189 -0.00134277 16.0013 3.58099 16.0013 8.00003C16.0013 12.4191 12.4189 16.0014 7.99991 16.0014C3.58087 16.0014 -0.00146484 12.4191 -0.00146484 8.00003Z" fill="currentColor"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.26088 5.56522C7.11069 5.75862 7.02879 6.02831 6.99823 6.27573C6.95422 6.632 6.62973 6.88515 6.27345 6.84114C5.91717 6.79714 5.66403 6.47264 5.70803 6.11637C5.75432 5.74163 5.88678 5.21512 6.23415 4.76784C6.60448 4.29099 7.18336 3.95363 7.99149 3.95363C7.99907 3.95363 8.00664 3.95376 8.0142 3.95403C8.93278 3.98614 9.55573 4.35879 9.91683 4.90723C10.2491 5.41194 10.301 5.97779 10.2964 6.32643C10.2953 6.9323 10.0927 7.34779 9.78349 7.73488C9.65458 7.89629 9.50149 8.05985 9.34977 8.22196C9.33761 8.23494 9.32547 8.24792 9.31334 8.26089C9.14401 8.44197 8.95984 8.64055 8.75647 8.88441C8.64802 9.01444 8.48617 9.28631 8.48617 9.71873C8.48617 10.0777 8.19515 10.3687 7.83617 10.3687C7.47718 10.3687 7.18617 10.0777 7.18617 9.71873C7.18617 8.93677 7.48586 8.37821 7.7581 8.05178C7.98642 7.77801 8.19172 7.55701 8.36379 7.37299C8.37142 7.36483 8.37895 7.35678 8.38639 7.34883C8.55247 7.17125 8.67189 7.04357 8.76771 6.92359C8.93642 6.71236 8.99638 6.5734 8.99638 6.32159V6.31197C8.9998 6.08093 8.95895 5.81639 8.83104 5.62211C8.73111 5.47033 8.52456 5.27533 7.98041 5.25367C7.58626 5.25636 7.38694 5.40291 7.26088 5.56522Z" fill="currentColor"/>
                    <path d="M8.364 11.4704C8.364 11.7629 8.12687 12 7.83435 12C7.54183 12 7.3047 11.7629 7.3047 11.4704C7.3047 11.1779 7.54183 10.9407 7.83435 10.9407C8.12687 10.9407 8.364 11.1779 8.364 11.4704Z" fill="currentColor"/>
                </g>
            </svg>
        )
    }

    return <svg id={'s-question-circle'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8147 8.20581C10.5745 8.51505 10.4472 8.94 10.3999 9.32284C10.339 9.81614 9.8897 10.1667 9.39639 10.1057C8.90309 10.0448 8.55258 9.59549 8.61351 9.10218C8.68257 8.54306 8.87986 7.76251 9.39302 7.10174C9.93799 6.40003 10.79 5.90152 11.9873 5.90152C11.9978 5.90152 12.0082 5.9017 12.0187 5.90207C13.3858 5.94987 14.3022 6.50268 14.8321 7.30745C15.3221 8.05172 15.3998 8.88916 15.3928 9.40933C15.3914 10.3039 15.0937 10.9151 14.6347 11.4899C14.4417 11.7315 14.2121 11.9768 13.9818 12.2229C13.9636 12.2424 13.9454 12.2618 13.9272 12.2813C13.6706 12.5557 13.3904 12.8578 13.0809 13.2289C12.9061 13.4385 12.6521 13.8687 12.6521 14.546C12.6521 15.0431 12.2492 15.446 11.7521 15.446C11.255 15.446 10.8521 15.0431 10.8521 14.546C10.8521 13.3847 11.2969 12.5576 11.6985 12.0761C12.0426 11.6635 12.3521 11.3303 12.6124 11.0519C12.6245 11.039 12.6364 11.0263 12.6481 11.0137C12.8981 10.7464 13.081 10.5508 13.2282 10.3666C13.4928 10.0353 13.5928 9.80692 13.5928 9.40259V9.38927C13.5981 9.03188 13.5356 8.61168 13.3286 8.29729C13.1605 8.04186 12.8208 7.73492 11.9719 7.70157C11.3477 7.70535 11.0213 7.9397 10.8147 8.20581Z" fill="currentColor"/>
            <path d="M12.5512 17.1981C12.5512 17.641 12.1922 18 11.7493 18C11.3064 18 10.9474 17.641 10.9474 17.1981C10.9474 16.7552 11.3064 16.3962 11.7493 16.3962C12.1922 16.3962 12.5512 16.7552 12.5512 17.1981Z" fill="currentColor"/>
        </g>
    </svg>
};