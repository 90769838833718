import React from 'react';

const UserInfoIcon = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M24.4919 4H8.50839C6.57834 4 5 5.56828 5 7.50838V24.6181C5 26.5484 6.57834 28.1265 8.50839 28.1265H24.4919C26.4219 28.1265 28 26.5484 28 24.6181V7.50838C28 5.56828 26.4219 4 24.4919 4ZM16.4461 24.4461H11.6524C11.0524 24.4461 10.5667 23.9601 10.5667 23.3605C10.5667 22.761 11.0524 22.275 11.6524 22.275H16.4461C17.0462 22.275 17.5317 22.761 17.5317 23.3605C17.5317 23.9601 17.0462 24.4461 16.4461 24.4461ZM21.3458 19.2602H11.6524C11.0524 19.2602 10.5667 18.774 10.5667 18.1747C10.5667 17.5751 11.0524 17.0889 11.6524 17.0889H21.3458C21.9459 17.0889 22.4316 17.5751 22.4316 18.1747C22.4316 18.774 21.9459 19.2602 21.3458 19.2602ZM22.7865 9.48432L19.3041 13.366C18.949 13.7614 18.3595 13.8397 17.9123 13.5562L14.5338 11.4016L11.8029 14.251C11.3881 14.6844 10.7003 14.698 10.269 14.2839C9.83529 13.8689 9.82057 13.1819 10.2361 12.749L13.5827 9.25632C13.9423 8.88278 14.5146 8.8148 14.9502 9.09213L18.3035 11.2303L21.1711 8.03409C21.5716 7.5879 22.2588 7.55281 22.704 7.95138C23.1502 8.35167 23.1873 9.03813 22.7865 9.48432Z" />
        </svg>
    )
}

export default UserInfoIcon;
