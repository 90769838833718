import React, { ReactNode } from 'react';

import classNames from 'classnames';

import 'components/legacyDesignSystem/components/LinearLoader.scss';

export interface LinearLoaderProps {
    active: boolean;
}

export class LinearLoader extends React.Component<LinearLoaderProps> {
    render(): ReactNode {
        return (
            <div
                id={'linear-loader'}
                className={classNames(
                    'linear-loader-container',
                    !this.props.active && 'linear-loader-hidden'
                )}
            >
                <div className="linear-loader-indeterminate" />
            </div>
        );
    }
}
