
import styles from "./DropDownItem.module.scss";
import classNames from "classnames";
import { CountryFlagName } from "components/designSystem/assets/countryData";
import { getCountryFlag } from "components/designSystem/assets/countryFlags";
import Badge, { BadgeSeverity, BadgeSize } from "components/designSystem/components/Badge";
import { ButtonClickPayload, formatButton } from 'components/designSystem/components/Button';
import Checkbox from "components/designSystem/components/Checkbox";
import Divider, { DividerOrientation, DividerVariant } from "components/designSystem/components/Divider";
import RadioButton from "components/designSystem/components/RadioButton";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { cast } from "core/utils/Typed";
import React, { FC, Fragment, ReactNode, useState } from "react";


export enum DropDownItemSeverity {
    CRITICAL = 'CRITICAL'
}

export enum DropDownType {
    RADIOBUTTON = 'radiobutton',
    CHECKBOX = 'checkbox',
    FLAG = 'flag',
    LIST = 'list',
    ACTION = 'action'
}

interface AbstractPayload {
    type: DropDownType
}

interface RadioButtonPayload extends AbstractPayload {
    type: DropDownType.RADIOBUTTON
    badgeValue?: number
    isChecked?: boolean,
    groupName: string,
    onClick: () => void,
}

interface CheckBoxPayload extends AbstractPayload {
    type: DropDownType.CHECKBOX
    isChecked?: boolean,
    groupName: string,
    // @ts-ignore
    onChange?: (event) => void,
    onClick: () => void,

}

interface ActionPayload extends AbstractPayload {
    type: DropDownType.ACTION,
    onClick: ButtonClickPayload,
    icon?: IconName,
    severity?: DropDownItemSeverity,
}

interface FlagPayload extends AbstractPayload {
    type: DropDownType.FLAG
    flagCountry: CountryFlagName,
    onClick: () => void,
}

interface ListPayload extends AbstractPayload {
    type: DropDownType.LIST
    isChecked?: boolean,
    description?: string,
    icon?: IconName,
    onClick: () => void,
}

type DropDownPayload = RadioButtonPayload | CheckBoxPayload | ActionPayload | FlagPayload | ListPayload


export interface DropDownItemProps {
    label: ReactNode,
    payload: DropDownPayload,
    disabled?: boolean,
    hasDivider?: boolean,
}


const transformPayload = (payload: AbstractPayload): DropDownPayload | undefined => {
    if (payload.type === DropDownType.RADIOBUTTON) {
        return cast<RadioButtonPayload>(payload)
    }
    if (payload.type === DropDownType.CHECKBOX) {
        return cast<CheckBoxPayload>(payload)
    }
    if (payload.type === DropDownType.ACTION) {
        return cast<ActionPayload>(payload)
    }
    if (payload.type === DropDownType.FLAG) {
        return cast<FlagPayload>(payload)
    }
    if (payload.type === DropDownType.LIST) {
        return cast<ListPayload>(payload)
    }

}


const DropDownItem: FC<DropDownItemProps> = (
    props
): JSX.Element => {
    const payload = transformPayload(props.payload);
    if (payload === undefined) {
        return <Fragment/>
    }

    const computedClassName = classNames(styles.ddItemContainer, styles.ddItemContainerItem, {
        [styles.ddItemContainerDisabled]: !!props.disabled,
        [styles.ddItemContainerEnabled]: !props.disabled,
    })

    let defaultIsSelected = false;
    switch (payload.type) {
        case DropDownType.RADIOBUTTON:
            defaultIsSelected = !!payload.isChecked
            break;
        case DropDownType.CHECKBOX:
            defaultIsSelected = !!payload.isChecked
            break;

    }

    const [isClicked, setIsClicked] = useState(defaultIsSelected);

    return (
        <div>
            {payload.type === DropDownType.RADIOBUTTON &&
                <div className={computedClassName} onClick={(): void => {
                    setIsClicked(!isClicked)
                    payload.onClick()
                }}>
                    <RadioButton label={props.label} groupName={payload.groupName} disabled={props.disabled}
                                 overrideChecked={isClicked}
                                 onChange={(event): void => setIsClicked(event.target.checked)}
                    />
                    {payload.badgeValue !== undefined &&
                        <div className={classNames(styles.ddItemContainerTypeBadge)}>
                            <Badge size={BadgeSize.MEDIUM}
                                   severity={BadgeSeverity.CRITICAL}>{payload.badgeValue}</Badge>
                        </div>
                    }
                </div>
            }
            {payload.type === DropDownType.CHECKBOX &&
                <div className={computedClassName} onClick={(): void => {
                    setIsClicked(!isClicked)
                    payload.onClick()
                }}>
                    <Checkbox label={props.label} overrideChecked={isClicked}
                              onChange={(event): void => setIsClicked(event.target.checked)} disabled={props.disabled}/>
                </div>
            }
            {payload.type === DropDownType.FLAG &&
                <div className={computedClassName} onClick={payload.onClick}>
                    <div
                        className={classNames(styles.ddItemContainerTypeFlag)}>{getCountryFlag(payload.flagCountry, '1rem', '1.385rem')}</div>
                    <div className={classNames(styles.ddItemContainerTypeFlagLabel)}>{props.label}</div>
                </div>
            }
            {payload.type === DropDownType.LIST &&
                <div className={computedClassName} onClick={payload.onClick}>
                    {payload.icon && <Icon size={IconSize.EXTRA_SMALL} name={payload.icon}/>}
                    <div className={classNames(styles.ddItemContainerList, payload.icon && styles.ddItemContainerListWithIcon)} onClick={payload.onClick}>
                        {props.label}
                        {payload.description &&
                                <div className={styles.ddItemContainerDescription}>
                                    {payload.description}
                                </div>
                        }
                    </div>
                    {payload.isChecked &&
                        <div className={classNames(styles.ddItemContainerTypeList)}>
                            <Icon size={IconSize.EXTRA_SMALL} name={IconName.CHECKMARK}/>
                        </div>
                    }
                </div>
            }
            {payload.type === DropDownType.ACTION &&
                formatButton(
                    payload.onClick,
                    <>
                        <div><Icon size={IconSize.EXTRA_SMALL} name={payload.icon ?? IconName.PLUS}/></div>
                        <div className={classNames(styles.ddItemContainerTypeAction)}>
                            {props.label}
                        </div>
                    </>,
                    classNames(styles.ddItemContainer, styles.ddItemContainerItem, {
                        [styles.ddItemContainerDisabled]: !!props.disabled,
                        [styles.ddItemContainerEnabled]: !props.disabled,
                        [styles.ddItemContainerTypeActionSeverityCritical]: payload.severity === DropDownItemSeverity.CRITICAL,

                    }),
                    false, false, undefined,
                    true
                )
            }
            {props.hasDivider &&
                <div className={classNames(styles.ddItemContainerDivider)}>
                    <Divider variant={DividerVariant.DEFAULT} orientation={DividerOrientation.HORIZONTAL}/>
                </div>
            }
        </div>


    )
}

export default DropDownItem
