import { validateEULAAndContinue, } from 'actions/user/SignInSignUpActions';
import { EULAValidation } from 'models/user/AuthModels';
import ValidateEULA from 'components/modules/eula/ValidateEULA';
import React, { ReactNode } from 'react';
import { ActionDispatcher, OnSuccessPutType } from 'actions/ActionInterface';
import MainComponent, { MainComponentProps, setup } from 'components/pages/common/MainComponent';
import WithLogo from 'components/modules/user/legacy/WithLogo';
import CentralColumn from 'components/core/containers/CentralColumn';

export interface EulaValidationPageDispatchProps {
    validateEULAAndContinue: (token: string, onSuccessPut?: OnSuccessPutType) => void;
}


export interface EulaValidationPageStateProps extends MainComponentProps {
    token: string;
    validations: EULAValidation[];
    onSuccessPut?: OnSuccessPutType;
}


class EulaValidationPage extends MainComponent<EulaValidationPageDispatchProps & EulaValidationPageStateProps> {
    render(): ReactNode {
        return (
            <CentralColumn>
                <WithLogo>
                    <ValidateEULA
                        onValidate={(): void =>
                            this.props.validateEULAAndContinue(
                                this.props.token,
                                this.props.onSuccessPut,
                            )
                        }
                        validations={this.props.validations}
                    />
                </WithLogo>
            </CentralColumn>
        );
    }
}

const mapStateToProps = (): {} => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): EulaValidationPageDispatchProps => ({
    validateEULAAndContinue: (token: string, onSuccessPut?: OnSuccessPutType): void => {
        dispatch(validateEULAAndContinue(token, onSuccessPut));
    },
});

export default setup(EulaValidationPage, mapStateToProps, mapDispatchToProps);
