import { ModalType } from 'actions/common/CommonActions';
import ChartTimeSeriesWithSelector from 'components/commonDesign/charts/ChartTimeSeriesWithSelector';
import EmptyState, { EmptyStateType } from 'components/designSystem/components/EmptyState';
import { PillSeverity } from 'components/designSystem/components/Pill';
import {
    ReferenceScaleLabelOrientation,
    ReferenceScaleProps,
    ReferenceScaleSeverity
} from 'components/designSystem/components/ReferenceScale';
import Toggle, { TogglePosition, ToggleSize } from "components/designSystem/components/Toggle";
import Centered from "components/designSystem/containers/Centered";
import FlexGrow from "components/designSystem/containers/FlexGrow";
import Inline, {
    AlwaysInline,
    InlineAlignItem,
    InlineSpacing,
} from 'components/designSystem/containers/Inline';
import { MediumSpacer, XLargeSpacer } from 'components/designSystem/containers/Spacer';
import { TCaption, TypographyColor } from 'components/designSystem/containers/Typography';
import WithMargin, { MarginSize } from 'components/designSystem/containers/WithMargin';
import { SCREEN_M } from 'components/designSystem/foundations/Breakpoints';
import { IconName } from "components/designSystem/foundations/IconsData";
import BaseModule, { defaultBaseModuleState, ModuleState, setupModule } from 'components/modules/modular/BaseModule';
import DetailsModal from 'components/modules/modular/modules/report/content/DetailsModal';
import { AntibiogramLine } from 'components/modules/modular/modules/report/content/lines/AntibiogramLine';
import { CategoricalLine } from 'components/modules/modular/modules/report/content/lines/CategoricalLine';
import { NumericalLine } from 'components/modules/modular/modules/report/content/lines/NumericalLine';
import ReportCategory from 'components/modules/modular/modules/report/content/ReportCategory';
import ReportParentCategory from 'components/modules/modular/modules/report/content/ReportParentCategory';
import ReportSubCategory from 'components/modules/modular/modules/report/content/ReportSubCategory';
import { TransDomain } from 'components/pages/common/MainComponent';
import { getIconByName } from "core/content/icons/IconFinder";
import { Action, ResourceType, SubResourceType } from 'core/logging/ActionEvent';
import { logWrapperOnClick } from 'core/logging/EventWrapper';
import { capitalizeFirstLetter } from 'core/utils/text';
import { cast } from 'core/utils/Typed';
import { TimeSeriePayload } from 'models/charts/charts/TimeSerie';
import { APIText } from 'models/common/message';
import {
    AntibiogramResultPayload,
    CategoricalResultPayload,
    ModuleReportCategory,
    ModuleReportParentCategory,
    ModuleReportResult,
    ModuleReportResultNormality,
    ModuleReportResultValueType,
    ModuleReportSubCategory,
    NumericalResultPayload,
    ReferenceScale,
    RSLabel,
    RSLineSlice,
    RSPoint,
    RSSeverity,
} from 'models/modular/report';
import { ModuleData } from 'models/modular/storage';
import React, { Fragment, ReactNode } from 'react';
import MediaQuery from 'react-responsive';
import _ from 'underscore';


export interface ReportContentModulePayload {
    report_uuid: string;
    parent_categories: ModuleReportParentCategory[];
    disclaimer?: APIText;
    with_interpretations: boolean;
    with_critical_data_redacted: boolean;
}

interface ReportContentModuleState {
    displayOnlyImportantResults: boolean
    isInterpretationsDisplayEnabled: boolean
}


class ReportContentModule extends BaseModule<Record<string, never>, ReportContentModulePayload, Record<string, never>> {
    TRANS_SUFFIX = TransDomain.MODULES + '.report'

    state: ModuleState<ReportContentModuleState> = defaultBaseModuleState({
        isInterpretationsDisplayEnabled: false,
        displayOnlyImportantResults: false,
    })

    getSeverity = (severity?: RSSeverity): ReferenceScaleSeverity | undefined => {
        switch (severity) {
            case RSSeverity.ABNORMAL:
                return ReferenceScaleSeverity.WARNING
            case RSSeverity.EXTREME:
                return ReferenceScaleSeverity.CRITICAL
            case RSSeverity.NORMAL:
                return ReferenceScaleSeverity.SAFE
        }

        return undefined
    }

    static hasImportantResult = (c: ModuleReportCategory): boolean => {
        return c.results.concat(...c.sub_categories.map(s => s.results)).some((r: ModuleReportResult) => r.important)
    }

    static subCategoryHasImportantResult = (c: ModuleReportSubCategory): boolean => {
        return c.results.some((r: ModuleReportResult) => r.important)
    }

    createSubCategories = (isMobile: boolean, c: ModuleReportCategory, uuid: string, onlyImportant: boolean): JSX.Element => {
        return <>
            {c.sub_categories.map((sc: ModuleReportSubCategory, k: number) => (
                <ReportSubCategory isMobile={isMobile} key={"listed_" + k.toString()} name={sc.name}
                                   hideContent={onlyImportant && !ReportContentModule.subCategoryHasImportantResult(sc)}>
                    {sc.results.map((r: ModuleReportResult, l: number): JSX.Element => {
                        return (
                            this.renderResult(
                                r,
                                uuid,
                                l,
                                isMobile,
                                onlyImportant,
                            )
                        )
                    })}
                </ReportSubCategory>
            ))}
            <ReportSubCategory key={"remaining"} isMobile={isMobile}
                               hideContent={onlyImportant && !ReportContentModule.hasImportantResult(c)}>
                {c.results.map((r: ModuleReportResult, k: number): JSX.Element => {
                    return this.renderResult(
                        r,
                        uuid,
                        k,
                        isMobile,
                        onlyImportant,
                    )
                })}
            </ReportSubCategory>
        </>
    }

    getReportLineNormality = (normality: ModuleReportResultNormality): PillSeverity => {
        if (normality === ModuleReportResultNormality.NORMAL) {
            return PillSeverity.SAFE
        } else if (normality === ModuleReportResultNormality.PARTIALLY_BAD) {
            return PillSeverity.WARNING
        } else if (normality === ModuleReportResultNormality.BAD) {
            if (this.props.payload?.pagePayload.with_critical_data_redacted) {
                return PillSeverity.CRITICAL
            } else {
                return PillSeverity.CRITICAL_RED
            }
        } else {
            return PillSeverity.NEUTRAL
        }
    }

    buildRefScale = (
        apiReferenceScale?: ReferenceScale,
        reduced?: boolean,
    ): ReferenceScaleProps|undefined => {
        // @ts-ignore
        return apiReferenceScale ? ({
            line: {
                slices: apiReferenceScale.line.slices.map((s: RSLineSlice) => ({
                    endPosition: s.end_position,
                    severity: this.getSeverity(s.severity) ?? ReferenceScaleSeverity.SAFE,
                })),
                labels: apiReferenceScale.line.labels.map((l: RSLabel) => (
                    {
                        position: l.position,
                        value: l.value,
                        orientation: l.position < 50 ? ReferenceScaleLabelOrientation.RIGHT : ReferenceScaleLabelOrientation.LEFT
                    }
                )),
                // zones: apiReferenceScale.line.zones.map((z: RSZone) => ({
                //     startPosition: z.start_position,
                //     endPosition: z.end_position,
                //     value: this.transApiText(z.value, capitalizeFirstLetter),
                // })),
            },
            points: apiReferenceScale.points.map(
                (p: RSPoint) => ({
                    position: p.position,
                    value: p.value,
                    severity: this.getSeverity(p.severity)
                })
            ),
            withValue: !reduced,
        }) : undefined
    }

    private static wrapClickMethod(
        action: Action,
        reportId: string,
        internalId: string|undefined,
        method: () => void
    ): (ev?: React.MouseEvent) => void {
        return logWrapperOnClick(
            method,
            action,
            reportId,
            ResourceType.REPORT,
            undefined,
            internalId,
            SubResourceType.INTERNAL_TEST_ID,
        )
    }

    renderNumericalResult = (
        r: ModuleReportResult,
        reportUUID: string,
        key: number|string,
        isMobile: boolean
    ): JSX.Element => {
        if (r.value === undefined) {
            return <div />
        }
        const valuePayload = cast<NumericalResultPayload>(r.value.payload)
        const historyChart = valuePayload.history.history_chart
        if (valuePayload.value.display_value === undefined) {
            return <div />
        }
        const value = {
            value: valuePayload.value.display_value,
            unit: valuePayload.value.unit,
        }

        const additionalValue = valuePayload.additional_value?.display_value
            ? {
                value: valuePayload.additional_value?.display_value,
                unit: valuePayload.additional_value?.unit,
            } : undefined

        const previousValue = valuePayload.history.previous_value?.value.display_value
            ? {
                date: new Date(Date.parse(valuePayload.history.previous_value.date)),
                trend: valuePayload.history.previous_value.trend,
                value: {
                    value: valuePayload.history.previous_value.value.display_value,
                    unit: valuePayload.history.previous_value.value.unit,
                },
            }
            : undefined

        const interpretation = this.state.isInterpretationsDisplayEnabled ? r.interpretations?.content : undefined

        return <NumericalLine
            isMobile={isMobile}
            key={key}
            name={r.identification.name}
            normality={this.getReportLineNormality(r.normality)}
            value={value}
            additionalValue={additionalValue}
            referenceScale={this.buildRefScale(valuePayload.reference_scale, true)}
            previousValue={previousValue}
            interpretation={interpretation}
            onDetailClick={(r.information || r.interpretations) && (
                ReportContentModule.wrapClickMethod(
                    Action.CLICK_INFO,
                    reportUUID,
                    r.identification.internal_id,
                    (): void => this.props.modal(
                        ModalType.CENTER,
                        (): JSX.Element => (
                            <DetailsModal
                                // @ts-ignore
                                information={r.information}
                                referenceScale={this.buildRefScale(
                                    valuePayload.reference_scale,
                                    false,
                                )}
                                interpretations={r.interpretations}
                            />
                        ),
                        {title: {default: r.identification.name}}
                    )
                )
            )}
            onHistoryClick={historyChart && (
                ReportContentModule.wrapClickMethod(
                    Action.CLICK_HISTORY,
                    reportUUID,
                    r.identification.internal_id,
                    (): void => this.props.modal(
                        ModalType.CENTER,
                        () => (
                            <ChartTimeSeriesWithSelector
                                items={historyChart.items}
                                payload={cast<TimeSeriePayload>(historyChart.payload)}
                            />
                        ),
                        {
                            fullWidth: true,
                            title: {default: this.trans('history', undefined, undefined, capitalizeFirstLetter)}
                        }
                    )
                )
            )}
            reportId={reportUUID}
            internalId={r.identification.internal_id}
            tags={r.tags}
            comments={r.comments}
        />
    }

    renderCategoricalResult = (
        r: ModuleReportResult,
        reportUUID: string,
        key: number | string,
        isMobile: boolean
    ): JSX.Element => {
        if (r.value === undefined) {
            return <div />
        }
        const valuePayload = cast<CategoricalResultPayload>(r.value.payload)
        const interpretation = this.state.isInterpretationsDisplayEnabled ? r.interpretations?.content : undefined
        return <CategoricalLine
            isMobile={isMobile}
            key={key}
            name={r.identification.name}
            normality={this.getReportLineNormality(r.normality)}
            comments={r.comments}
            value={valuePayload.value.display_value}
            interpretation={interpretation}
            onDetailClick={r.information && (
                ReportContentModule.wrapClickMethod(
                    Action.CLICK_INFO,
                    reportUUID,
                    r.identification.internal_id,
                    (): void => this.props.modal(
                        ModalType.CENTER,
                        (): JSX.Element => (
                            <DetailsModal
                                name={r.identification.name}
                                // @ts-ignore
                                information={r.information}
                                interpretations={r.interpretations}
                            />
                        ),
                        {title: {default: r.identification.name}}
                    )
                )

            )}
            reportId={reportUUID}
            internalId={r.identification.internal_id}
            tags={r.tags}
        />
    }

    renderAntibiogramResult = (
        r: ModuleReportResult,
        key: number | string,
        isMobile: boolean
    ): JSX.Element => {
        if (r.value === undefined) {
            return <div />
        }
        const valuePayload = cast<AntibiogramResultPayload>(r.value.payload)
        return <AntibiogramLine
            key={key}
            bacteria={valuePayload.value.bacteria}
            antibiotics={valuePayload.value.antibiotics}
            comments={r.comments}
            isMobile={isMobile}
            tags={r.tags}
        />
    }

    renderResult(
        r: ModuleReportResult,
        reportUUID: string,
        key: number | string,
        isMobile: boolean,
        onlyImportant?: boolean,
    ): JSX.Element {
        if (onlyImportant && !r.important) {
            return <div key={key}/>
        }
        if (r.value === undefined) {
            return <div key={key}/>
        }
        if (r.value?.type === ModuleReportResultValueType.NUMERICAL) {
            return this.renderNumericalResult(r, reportUUID, key, isMobile)
        } else if (r.value?.type === ModuleReportResultValueType.CATEGORICAL) {
            return this.renderCategoricalResult(r, reportUUID, key, isMobile)
        } else if (r.value?.type === ModuleReportResultValueType.ANTIBIOGRAM) {
            return this.renderAntibiogramResult(r, key, isMobile)
        } else {
            return <Fragment key={key}/>
        }
    }

    buildContent(isMobile: boolean, payload: ModuleData<ReportContentModulePayload, Record<string, never>>): ReactNode {

        const lines = !_.isEmpty(payload.pagePayload.parent_categories) ? (
            payload.pagePayload.parent_categories.map(
                (pc: ModuleReportParentCategory, i: number): JSX.Element => (
                    <ReportParentCategory isMobile={isMobile} key={i} name={pc.name}>
                        {pc.categories.map((c: ModuleReportCategory, j: number): JSX.Element => (
                            <ReportCategory isMobile={isMobile}
                                            key={j}
                                            name={c.name}
                                            iconName={getIconByName(c.icon)}
                                            onlyShowImportantResults={this.state.displayOnlyImportantResults}
                                            hasImportantResults={ReportContentModule.hasImportantResult(c)}
                                            childrenMapping={(showOnlyImportantResults: boolean): JSX.Element => this.createSubCategories(isMobile, c, payload.pagePayload.report_uuid, showOnlyImportantResults)}
                                            comments={c.comments}
                            >
                            </ReportCategory>
                        ))}
                    </ReportParentCategory>
                ))
        ) : <Fragment/>

        const hasResultWithInterpretation = !_.isEmpty(payload.pagePayload.parent_categories) &&
            payload.pagePayload.parent_categories.some((pc) => pc.categories.some((c) => c.results.some((r) => r.interpretations?.content) || c.sub_categories.some((sc) => (sc.results.some((r) => r.interpretations?.content)))));
        return !_.isEmpty(payload.pagePayload.parent_categories) ? (
            <MediumSpacer>
                <Inline alignItems={isMobile ? InlineAlignItem.END : InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                    {payload.pagePayload.disclaimer &&
                        <TCaption color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            {this.transApiText(payload.pagePayload.disclaimer, capitalizeFirstLetter)}
                        </TCaption>
                    }
                    <FlexGrow/>
                    <AlwaysInline alignItems={isMobile ? InlineAlignItem.END : InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                        {payload.pagePayload.with_interpretations && <Toggle
                            label={this.trans('see_interpretations', undefined, undefined, capitalizeFirstLetter)}
                            togglePosition={TogglePosition.RIGHT}
                            onClick={(): void => this.setState({isInterpretationsDisplayEnabled: !this.state.isInterpretationsDisplayEnabled})}
                            checked={this.state.isInterpretationsDisplayEnabled}
                            size={ToggleSize.SMALL}
                            disabled={!hasResultWithInterpretation}/>}
                        <Toggle label={this.trans('all_results', undefined, undefined, capitalizeFirstLetter)}
                                togglePosition={TogglePosition.RIGHT}
                                onClick={(): void => this.setState({displayOnlyImportantResults: !this.state.displayOnlyImportantResults})}
                                checked={!this.state.displayOnlyImportantResults}
                                size={ToggleSize.SMALL}/>
                    </AlwaysInline>
                </Inline>
                <XLargeSpacer>
                    {lines}
                </XLargeSpacer>
            </MediumSpacer>
        ) : (
            <Centered vertically horizontally>
                <WithMargin margins={[MarginSize.MEDIUM, MarginSize.X_LARGE]}>
                    <EmptyState
                        payload={{
                            type: EmptyStateType.ICON,
                            name: IconName.REPORT
                        }}
                        title={this.trans('empty_report', undefined, undefined, capitalizeFirstLetter)}
                        description={this.trans('empty_report_info', undefined, undefined, capitalizeFirstLetter)}
                    />
                </WithMargin>
            </Centered>
        )
    }

    _render(payload: ModuleData<ReportContentModulePayload, Record<string, never>>): ReactNode {
        return <>
            <MediaQuery minWidth={SCREEN_M}>
                {this.buildContent(false, payload)}
            </MediaQuery>
            <MediaQuery maxWidth={SCREEN_M - 1}>
                {this.buildContent(true, payload)}
            </MediaQuery>
        </>
    }
}

const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (): Record<string, never> => ({});


export default setupModule(ReportContentModule, mapStateToProps, mapDispatchToProps);
