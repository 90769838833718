import React, { Component, ReactNode } from 'react';
import { getRandomMedicalReport } from 'actions/medicalReports/MedicalReportsFetchActions';
import '../../utils/navigation/Navigation.scss';
import MyLoader from 'components/core/items/MyLoader';
import { setup } from 'components/pages/common/MainComponent';
import { ActionDispatcher } from 'actions/ActionInterface';

export interface RandomReportDispatchProps {
    getRandomMedicalReport: () => void;
}

class RandomReport extends Component<RandomReportDispatchProps> {
    componentDidMount(): void {
        this.props.getRandomMedicalReport();
    }

    render(): ReactNode {
        return <MyLoader />;
    }
}

const mapStateToProps = (): {} => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): RandomReportDispatchProps => ({
    getRandomMedicalReport: (): void => dispatch(getRandomMedicalReport()),
});

export default setup(RandomReport, mapStateToProps, mapDispatchToProps);
