import React from "react";
import { Field as FinalField } from "react-final-form";
import { FieldValidator } from 'final-form';
import Checkbox, { CheckboxProps } from 'components/designSystem/components/Checkbox';

export interface FormCheckboxProps {
    name: string;
    field: CheckboxProps;
    validate?: FieldValidator<boolean>;
}

function FormCheckbox(props: FormCheckboxProps): JSX.Element {

    return (
        <FinalField
            type={"checkbox"}
            name={props.name}
            component={'input'}
            validate={props.validate}
        >
            {
                ({ input , meta}): JSX.Element => {
                    return <Checkbox
                        label={props.field.label}
                        disabled={props.field.disabled}
                        onChange={input.onChange}
                        defaultChecked={props.field.defaultChecked}
                        error={(meta.touched || meta.modified) && meta.error ? meta.error : undefined}
                    />
                }
            }
        </FinalField>
    )
}

export default FormCheckbox