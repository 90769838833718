import CONFIG from "configuration/globals";
import { getOptions } from "sagas/api/rest/RequestContent";
import { handleError } from "sagas/call/error";


export enum CallMethod {
    POST = 'POST',
    GET = 'GET'
}

async function handleResponse<Req>(
    r: Response,
): Promise<Req> {
    if (Math.floor(r.status/100) != 2) {
        const content = await r.text()
        handleError(r.status, content, r)
    }

    return r.json().then(
        (
            jsonPayload
        ): Promise<Req> => {

            return jsonPayload
        }
    )
}

export function callApi<Req = undefined, Res = undefined>(
    url: string,
    method: CallMethod = CallMethod.GET,
    requestPayload?: Req,
): Promise<Res> {
    const options: {} = getOptions(requestPayload, method);

    return fetch(
        url,
        options
    ).then(
        (r) => handleResponse(r)
    )
}

export function post<Req = undefined, Res = undefined>(
    url: string,
    requestPayload?: Req,
): Promise<Res> {
    return callApi(
        url,
        CallMethod.POST,
        requestPayload,
    )
}

export function get<Res = undefined>(
    url: string,
): Promise<Res> {
    return callApi(
        url,
        CallMethod.GET,
        undefined,
    )
}

export type UrlReq = {[k: string]: string | number | null | boolean | string[]} | undefined

export function buildUrl<Req extends UrlReq = undefined>(
    path: string,
    req: Req,
): string {
    const base = new URL(path, CONFIG.apiUrl )

    if (req) {
        Object.entries(req)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .forEach(
                (item) => {
                    if (item[1] != null) {
                        if (Array.isArray(item[1])) {
                            item[1].sort().forEach(
                                (i) => {
                                    base.searchParams.append(
                                        item[0],
                                        i
                                    )
                                }
                            )
                        } else {
                            if (item[1] != null) {
                                base.searchParams.append(
                                    item[0],
                                    item[1].toString()
                                )
                            }
                        }
                    }
                }
            )
    }

    return base.href
}