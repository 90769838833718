import React, { FC, useState } from 'react';
import Icon from 'components/designSystem/foundations/Icons';
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import classNames from 'classnames';
import styles from './Logo.module.scss'

export enum LogoType {
    DEFAULT = 'DEFAULT',
    SYMBOL = 'SYMBOL'
}

export interface LogoProps {
    alt: string;
    type?: LogoType;
    imgByType: { [type_: string]: string };
    fallbackIcon?: IconName
}

function getLogoUrl(type: LogoType, imgByType: { [type_: string]: string }): string {
    if (Object.hasOwn(imgByType, type)) {
        return imgByType[type]
    }

    return ""
}

export const Logo: FC<LogoProps> = ({type = LogoType.DEFAULT, ...otherProps}): JSX.Element => {
    const [useIconFallBack, setUseIconFallBack] = useState(false);

    const onErrorLoad: () => void = () => {
        setUseIconFallBack(true)
    };

    if (useIconFallBack) {
        return <Icon name={otherProps.fallbackIcon ? otherProps.fallbackIcon : IconName.WARNING_CIRCLE} size={IconSize.SMALL}/>
    }

    return <img
        className={styles.logoImg}
        alt={otherProps.alt}
        src={getLogoUrl(type, otherProps.imgByType)}
        onError={onErrorLoad}
    />
}

export enum SizedLogoSize {
    X_LARGE = 'X_LARGE',
    ELEVEN_TIMES_X_SMALL = 'ELEVEN_TIMES_X_SMALL'
}


interface SizedLogoInterface {
    size: SizedLogoSize
}

export const SizedLogo: FC<SizedLogoInterface & LogoProps> = ({size, ...logoProps}): JSX.Element => {
    return <div className={
        classNames(
            styles.sizedLogo,
            {
                [styles.sizedLogoSizeXLarge]: size === SizedLogoSize.X_LARGE,
                [styles.sizedLogoSizeElevenTimeXSmall]: size === SizedLogoSize.ELEVEN_TIMES_X_SMALL
            }
        )
    }>
        <Logo {...logoProps} />
    </div>
}

export const XLargeLogo: FC<LogoProps> = (props) => <SizedLogo size={SizedLogoSize.X_LARGE} {...props}/>