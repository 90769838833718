import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './ReferenceScale.module.scss'

export enum ReferenceScaleSeverity {
    NEUTRAL = 'NEUTRAL',
    SAFE = 'SAFE',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL',

}
export interface ReferenceScaleLineSliceComponentProps {
    startPosition: number;
    endPosition: number;
    severity: ReferenceScaleSeverity;
}


export enum ReferenceScaleLabelOrientation {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export interface ReferenceScaleLabelProps {
    position: number;
    value: string;
    orientation?: ReferenceScaleLabelOrientation
}

// export interface ReferenceScaleZoneProps {
//     startPosition: number;
//     endPosition: number;
//     value: string;
// }

export interface ReferenceScaleLine {
    slices: ReferenceScaleLineSliceProps[];
    labels: ReferenceScaleLabelProps[];
    // ReferenceScaleZoneProps has been developed in the last version of the refscale (which this one is based on)
    // As it maybe be needed in the coming weeks we leave it for now
    // Could be removed
    //zones: ReferenceScaleZoneProps[];
}

export interface ReferenceScalePoint {
    position: number;
    value: string;
    severity?: ReferenceScaleSeverity;
}


export interface ReferenceScaleProps {
    line: ReferenceScaleLine;
    points: ReferenceScalePoint[];
    withValue?: boolean;
}

export interface ReferenceScaleLineSliceProps {
    endPosition: number;
    severity: ReferenceScaleSeverity;
}

export interface ReferenceScalePointComponentProps {
    position: number;
    value: string;
    severity: ReferenceScaleSeverity;
    onlyDisplayPoint?: boolean;
}

const POINT_CONTAINER_WIDTH_IN_PCT = 30;

function sortSlices(s1: ReferenceScaleLineSliceProps, s2: ReferenceScaleLineSliceProps): number {
    return s1.endPosition - s2.endPosition
}

function getOrderedSlices(slices: ReferenceScaleLineSliceProps[]): ReferenceScaleLineSliceComponentProps[] {
    const orderedSlices: ReferenceScaleLineSliceComponentProps[] = []
    let startPosition: number | null = null;
    slices.sort(sortSlices).forEach((s: ReferenceScaleLineSliceProps) => {
        orderedSlices.push({
            startPosition: startPosition ? startPosition : 0,
            endPosition: s.endPosition,
            severity: s.severity,
        })
        startPosition = s.endPosition
    })

    return orderedSlices;
}


// const ReferenceScaleZone = (props: ReferenceScaleZoneProps): JSX.Element => (
//     <div
//         className={styles.referenceScaleZone}
//         style={{
//             left: `${props.startPosition}%`,
//             width: `${props.endPosition - props.startPosition}%`
//         }}
//     >
//         {props.value}
//     </div>
// )


const ReferenceScaleLineSlice = (props: ReferenceScaleLineSliceComponentProps): JSX.Element => {
    return <div
        className={classNames(
            styles.referenceScaleLineSlice,
            props.startPosition === 0 && styles.referenceScaleLineSliceFirst,
            props.endPosition === 100 && styles.referenceScaleLineSliceLast,
            props.severity === ReferenceScaleSeverity.SAFE && styles.referenceScaleLineSliceSeveritySafe,
            props.severity === ReferenceScaleSeverity.WARNING && styles.referenceScaleLineSliceSeverityWarning,
            props.severity === ReferenceScaleSeverity.CRITICAL && styles.referenceScaleLineSliceSeverityCritical,
            props.severity === ReferenceScaleSeverity.NEUTRAL && styles.referenceScaleLineSliceSeverityNeutral,
        )}
        style={{
            width: `${props.endPosition - props.startPosition}%`,
        }}
    />
}


const ReferenceScaleLabel = (props: ReferenceScaleLabelProps): JSX.Element => {
    const style_ = props.orientation === ReferenceScaleLabelOrientation.LEFT ? {
        left: `${props.position}%`
    } : {
        right: `${100 - props.position}%`
    }

    return <div
        className={styles.referenceScaleLabel}
        style={style_}
    >
        {props.value}
    </div>
}

const ReferenceScalePoint = (props: ReferenceScalePointComponentProps): JSX.Element => {
    const left_ = Math.min(props.position - POINT_CONTAINER_WIDTH_IN_PCT/2, 100 - POINT_CONTAINER_WIDTH_IN_PCT);
    const insideLeft = (props.position - left_) / POINT_CONTAINER_WIDTH_IN_PCT * 100;

    const positionType = Math.sign(insideLeft - 50) * ( Math.abs(insideLeft - 50) > 5 ? 1 : 0)
    let labelPointStyle = {}
    if (positionType !== 0) {
        labelPointStyle = positionType > 0 ? {
            paddingRight: `${100 - insideLeft}%`,
        } : {
            paddingLeft:`${insideLeft}%`
        }
    }
    return  <div
        className={classNames(
            styles.referenceScalePointContainer,
        )}

        style={{
            left: `${left_}%`,
        }}
    >
        <div
            className={classNames(
                styles.referenceScalePointDisc,
                props.severity === ReferenceScaleSeverity.SAFE && styles.referenceScalePointDiscSeveritySafe,
                props.severity === ReferenceScaleSeverity.WARNING && styles.referenceScalePointDiscSeverityWarning,
                props.severity === ReferenceScaleSeverity.CRITICAL && styles.referenceScalePointDiscSeverityCritical,
            )}
            style={{left: `${insideLeft}%`}}
        />
        {
            !props.onlyDisplayPoint &&
            <>
                <div
                    className={classNames(
                        styles.referenceScalePointDashedLine,
                        props.severity === ReferenceScaleSeverity.SAFE && styles.referenceScalePointDashedLineSeveritySafe,
                        props.severity === ReferenceScaleSeverity.WARNING && styles.referenceScalePointDashedLineSeverityWarning,
                        props.severity === ReferenceScaleSeverity.CRITICAL && styles.referenceScalePointDashedLineSeverityCritical,
                    )}
                    style={{left: `calc(${insideLeft}% + 0.4rem)`}}

                />
                <div
                    className={classNames(
                        styles.referenceScalePointLabel,
                        {
                            [styles.referenceScalePointLabelLeft]: positionType === -1,
                            [styles.referenceScalePointLabelRight]: positionType === 1,
                        }
                    )}

                    style={labelPointStyle}

                >{props.value}</div>
            </>
        }
    </div>
}

function getPointColor(
    pointPosition: number,
    orderedSlices: ReferenceScaleLineSliceComponentProps[],
    severity?: ReferenceScaleSeverity
): ReferenceScaleSeverity {
    if (severity) {
        return severity
    }

    const slices = orderedSlices.filter((s: ReferenceScaleLineSliceComponentProps) => (
        s.endPosition >= pointPosition
    ))
    return slices.length > 0 ? slices[0].severity : ReferenceScaleSeverity.NEUTRAL
}

const ReferenceScale: FC<ReferenceScaleProps> = ({withValue = true, ...props}): JSX.Element => {
    const { line, points } = props;
    const orderedSlices = getOrderedSlices(line.slices)
    return (
        <div
            className={classNames(
                styles.referenceScaleContainer,
            )}
        >
            {
                line.labels &&
                <div className={styles.referenceScaleLabelContainer}>
                    {line.labels.map((label: ReferenceScaleLabelProps, k: number) => (
                        <ReferenceScaleLabel key={k} {...label} />
                    ))}
                </div>
            }
            <div className={styles.referenceScaleLineSliceContainer}>
                {orderedSlices.map((s: ReferenceScaleLineSliceComponentProps, k: number) => (
                    <ReferenceScaleLineSlice key={k} {...s} />
                ))}
            </div>
            <div className={classNames(styles.referenceScalePointsContainer, withValue && styles.referenceScalePointsContainerWithValue)}>
                {points.map((p: ReferenceScalePoint, k: number) => (
                    <ReferenceScalePoint
                        key={k}
                        position={p.position}
                        value={p.value}
                        severity={getPointColor(p.position, orderedSlices, p.severity)}
                        onlyDisplayPoint={!withValue}
                    />
                ))}
            </div>
            {/*{hasValue && line.zones.map((zone: ReferenceScaleZoneProps, k: number) => (*/}
            {/*    <ReferenceScaleZone key={k} {...zone} />*/}
            {/*))}*/}
        </div>
    )

}
export default ReferenceScale;