import ChartComponent from 'components/commonDesign/charts/ChartComponent';
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";
import Container, {
    ContainerProfile,
    MarginType,
    PaddingType
} from 'components/legacyDesignSystem/components/Container';
import TextLoader from 'components/legacyDesignSystem/components/TextLoader';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';

import BaseModule, { defaultBaseModuleState, ModuleState, setupModule } from 'components/modules/modular/BaseModule';
import { cast } from 'core/utils/Typed';
import { Chart } from 'models/charts/charts';
import { ExtendedHalfPiePayload, HalfPiePayload } from 'models/charts/charts/HalfPie';
import { APIText } from 'models/common/message';
import { ModuleData } from 'models/modular/storage';
import React from 'react';

export interface SimpleHalfPiePagePayload {
    title: APIText;
    sub_title: APIText;
    chart: Chart | null;
    allowed_context_selector_values: string[] | null;
}

interface LocalState {
    selected_segment?: number;
}

class SimpleHalfPie extends BaseModule<{}, SimpleHalfPiePagePayload, {}> {
    state: ModuleState<LocalState> = defaultBaseModuleState(
        {
            selected_segment: 0,
        }
    )

    _render(payload: ModuleData<SimpleHalfPiePagePayload, {}>): React.ReactNode {
        const {
            pagePayload: { title, sub_title },
        } = payload;

        const chart: Chart<ExtendedHalfPiePayload> | null = cast<Chart<HalfPiePayload>| null>(payload.pagePayload.chart);

        if (chart && chart.payload) {
            chart.payload = {
                ...chart.payload,
                selected_segment: this.state.selected_segment,
                onSegmentHover: (segmentIndex: number): void => {
                    this.setState({ selected_segment: segmentIndex });
                },
            };
        }

        const displayLoader = payload.twinkle || !chart
        const content = <Container profiles={[{type: PaddingType.P, value: 4}]}>
            <Container profiles={[{ type: MarginType.MB, value: 8 }]}>
                {title && (
                    <Typography weight={TypographyWeight.BOLD} variant={TypographyVariant.BODY}>
                        {this.transApiText(title)}
                    </Typography>
                )}
                {sub_title && (
                    <Typography weight={TypographyWeight.REGULAR} variant={TypographyVariant.CAPTION}>
                        {this.transApiText(sub_title)}
                    </Typography>
                )}
            </Container>
            {
                chart ?
                    <Container>
                        <ChartComponent
                            chart={chart}
                            translation={this.transApiText}
                        />
                    </Container> :
                    <Container profiles={[{type: PaddingType.P, value: 30}]}>
                        <TextLoader />
                    </Container>
            }

        </Container>

        return <Container profiles={[ContainerProfile.BORDER_MODULE]} style={{backgroundColor: "white"}}>
            {displayLoader ? <ModuleLoaderWrapper>{content}</ModuleLoaderWrapper> : content}
        </Container>
    }
}

export default setupModule(
    SimpleHalfPie,
    () => ({}),
    () => ({}),
);
