import React, { FC } from 'react';
import classNames from 'classnames';
import styles from 'components/designSystem/containers/TextJustifier.module.scss';


export enum TextJustification {
    START = 'START',
    END = 'END',
    CENTER = 'CENTER',
    JUSTIFY = 'JUSTIFY',
}

interface TextJustifierProps {
    justify?: TextJustification
}

const TextJustifier: FC<TextJustifierProps> = (
    props
): JSX.Element => {

    return <div className={
        classNames(
            {
                [styles.textJustifierTextAlignStart]: props.justify === TextJustification.START,
                [styles.textJustifierTextAlignEnd]: props.justify === TextJustification.END,
                [styles.textJustifierTextAlignCenter]: props.justify === TextJustification.CENTER,
                [styles.textJustifierTextAlignJustify]: props.justify === TextJustification.JUSTIFY,
            }
        )
    }>
        {props.children}
    </div>
}

export const LeftAlignedText: FC<TextJustifierProps> = (props) => <TextJustifier justify={TextJustification.START}>{props.children}</TextJustifier>
export const RightAlignedText: FC<TextJustifierProps> = (props) => <TextJustifier justify={TextJustification.END}>{props.children}</TextJustifier>
export const CenteredText: FC<TextJustifierProps> = (props) => <TextJustifier justify={TextJustification.CENTER}>{props.children}</TextJustifier>
export const JustifiedText: FC<TextJustifierProps> = (props) => <TextJustifier justify={TextJustification.JUSTIFY}>{props.children}</TextJustifier>

export default TextJustifier;