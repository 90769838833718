import { AnyIterator } from 'core/utils/Typed';
import { put, takeLatest } from '@redux-saga/core/effects';
import { ModuleAPICallAction, moduleApiCallSuccessAction } from 'actions/modular/ModularActions';
import { API_DEMO_MODULE, DemoPayload } from 'actions/modular/modules/demo/TestActions';
import CallHandler from 'sagas/api/CallHandler';
import { demoModuleCall, demoModuleFetchBiograhCall, demoModuleFetchPatientReportsCall } from 'sagas/api/Endpoints';
import { API_FETCH_PATIENT_REPORTS, FetchPatientReportsPayload } from 'actions/modular/modules/demo/MyReportsActions';
import { API_GRAPH_LOAD_ITEM, LoadItemsPayload } from 'actions/modular/modules/demo/BioGraphActions';


export const fetchPatientReportsSaga = function* (action: ModuleAPICallAction<FetchPatientReportsPayload>): AnyIterator {
    const response = yield CallHandler.for(demoModuleFetchPatientReportsCall, action).call();

    if (response && action.payload.params) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pl: any = {
            reportsByPatientUUID: {}
        };

        pl.reportsByPatientUUID[action.payload.params.patientUUID] = response.reports;
        yield put(moduleApiCallSuccessAction(
            action.payload.moduleIdentifier,
            pl
        ));
    }
};

export const loadItemSaga = function* (action: ModuleAPICallAction<LoadItemsPayload>): AnyIterator {
    yield CallHandler.for(demoModuleFetchBiograhCall, action).call();
    if (action.payload.params) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pl: any = {
            timeSeriesByIdentifier: {}
        };
        action.payload.params.itemsIdentifiers.forEach(
            (i) => {
                pl.timeSeriesByIdentifier[i] = []
            }
        );

        yield put(moduleApiCallSuccessAction(
            action.payload.moduleIdentifier,
            pl
        ));
    }
};


export const demoModule = function* (action: ModuleAPICallAction<DemoPayload>): AnyIterator {
    const response: {[key: string]: string} = yield CallHandler.for(demoModuleCall, action).call();
    if (response) {
        yield put(moduleApiCallSuccessAction(
            action.payload.moduleIdentifier,
            response
        ));
    }
};

export default function* demoModularSagas(): AnyIterator {
    yield takeLatest(API_DEMO_MODULE, demoModule);
    yield takeLatest(API_FETCH_PATIENT_REPORTS, fetchPatientReportsSaga);
    yield takeLatest(API_GRAPH_LOAD_ITEM, loadItemSaga);
}
