import React, { Component, Fragment, ReactNode } from 'react';
import LanguagePicker, { Language } from "components/designSystem/components/LanguagePicker";
import { CountryFlagName } from "components/designSystem/assets/countryData";
import { setupRaw, TransDomain } from 'components/pages/common/MainComponent';
import { WithTranslation } from 'react-i18next';

const LANG: Language[] = [
    {name: 'Français', shortName: 'FR', flag: CountryFlagName.FRANCE, identifier: 'fr'},
    {name: 'English', shortName: 'EN', flag: CountryFlagName.UK, identifier: 'en'}
]

function find(identifier: string): Language | undefined {
    return LANG.find((l) => l.identifier === identifier)
}

class LanguageSelector extends Component<WithTranslation>{

    switchSelector(identifier: string): void {
        this.props.i18n.changeLanguage(identifier, () => window.location.reload());
    }

    render(): ReactNode {
        if (this.props.i18n === undefined) {
            return <div/>
        }

        const selectedLanguage = find(this.props.i18n.language)

        if (!selectedLanguage) {
            return <Fragment/>
        }

        return <LanguagePicker selectedLanguage={selectedLanguage} languages={LANG} title={this.props.t(TransDomain.GLOBAL + ".app_language")}
                               onChange={(identifier): void => this.switchSelector(identifier)}/>
    }

}
export default setupRaw(LanguageSelector);