import { updatePregnantProfile } from "actions/bioProfile/BioProfileFetchActions";
import { modal, ModalType } from "actions/common/CommonActions";
import { loadPageAction } from "actions/modular/ModularActions";
import Form, { FormState } from "components/commonDesign/form/tmpNewSystem/Form";
import { FormItem } from "components/commonDesign/form/tmpNewSystem/FormItemBuilder";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import DropDown from "components/designSystem/components/DropDown";
import DropDownItem, { DropDownType } from "components/designSystem/components/DropDownItem";
import TextField, { RightIconType } from "components/designSystem/components/TextField";
import FlexGrow from "components/designSystem/containers/FlexGrow";
import { MediumSpacer, SmallSpacer } from "components/designSystem/containers/Spacer";
import { TCaption, TLabel500, TypographyColor } from "components/designSystem/containers/Typography";
import { IconName } from "components/designSystem/foundations/IconsData";
import { useModuleAttributes, useModulePayload } from "components/modules/modular/ModuleContainer";
import { PregnancyStatus } from "components/modules/modular/modules/bioProfiles/PregnancyStatus";
import { TransDomain } from "components/pages/common/MainComponent";
import { getPlatformResource } from "core/content/cdn";
import { Action, ResourceType } from "core/logging/ActionEvent";
import LOGGER from "core/logging/Logger";
import { PregnancyDateType } from "models/bioProfiles/BioProfileModels";
import { FormItemType } from "models/common/DynamicFormModels";
import moment, { Moment } from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { REDUCER_BIO_PROFILE } from "reducers/allReducers";
import { fetchReducer } from "reducers/selector";

interface PregnancyStatusModulePayload {
    token: string
    max_due_date: string
    max_conception_date: string
    max_last_period_date: string
}

export const PregnancyDateForm = ({modulePayload, close}: { modulePayload: PregnancyStatusModulePayload, close: () => void }): JSX.Element => {
    const {t} = useTranslation(undefined, {keyPrefix: TransDomain.MODULES + '.pregnancy_due_date.date_popup'});
    const dispatch = useDispatch();
    const [dateType, setDateType] = useState(PregnancyDateType.DUE_DATE);
    let maxDate: Moment;
    switch (dateType) {
        case PregnancyDateType.DUE_DATE:
            maxDate = moment(modulePayload.max_due_date);
            break
        case PregnancyDateType.CONCEPTION_DATE:
            maxDate = moment(modulePayload.max_conception_date);
            break;
        case PregnancyDateType.LAST_PERIOD_DATE:
            maxDate = moment(modulePayload.max_last_period_date);
            break;
    }

    const isPregnancyDateUpdated = useSelector((state) => fetchReducer(state, REDUCER_BIO_PROFILE)?.isPregnancyDateUpdated)
    useEffect(() => {
        if (isPregnancyDateUpdated) {
            close()
        }
    }, [isPregnancyDateUpdated]);
    return <SmallSpacer>
        <TLabel500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
            {t('prompt')}
        </TLabel500>
        <DropDown
            triggerBuilder={(onClick: () => void, isOpen): JSX.Element => {
                return <TextField
                    label={t(`date.${dateType}`)}
                    rightIcon={{
                        type: RightIconType.INFORMATIVE,
                        icon: isOpen ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN,
                    }}
                    onClick={onClick}
                />
            }}
            closeOnClick>
            {Object.values(PregnancyDateType).map((pregnancyDateType) =>
                <DropDownItem
                    key={pregnancyDateType.valueOf()}
                    label={t(`date.${pregnancyDateType}`)}
                    payload={
                        {
                            type: DropDownType.LIST,
                            onClick: () => setDateType(pregnancyDateType),
                            isChecked: dateType == pregnancyDateType
                        }
                    }
                />
            )}
        </DropDown>
        {<Form onSubmit={(data: Record<string, string>): void => {
            dispatch(
                updatePregnantProfile(
                    modulePayload.token,
                    dateType,
                    data['pregnancy_date'],
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                )
            )
        }}>
            {(formState: FormState): JSX.Element =>
                <MediumSpacer>
                    <FormItem formItem={{
                        key: 'pregnancy_date',
                        type: FormItemType.DATE,
                        payload: {
                            name: 'pregnancy_date',
                            type: 'date',
                            is_required: true,
                            label: t('date_label'),
                            maxDate
                        },
                        rank: 1,
                    }}/>
                    <Button disabled={formState.invalid} variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LARGE}
                            isSubmit fullWidth>
                        {t('save')}
                    </Button>
                </MediumSpacer>
            }
        </Form>}
    </SmallSpacer>
}

export const PregnancyDueDateModule = (): JSX.Element => {
    const payload = useModulePayload<PregnancyStatusModulePayload>();
    const dispatch = useDispatch();
    const {t} = useTranslation(undefined, {keyPrefix: TransDomain.MODULES + '.pregnancy_due_date'});
    const {pageId, context} = useModuleAttributes();
    return <PregnancyStatus image={getPlatformResource('pregnancy/pregnancy_month1.svg')}>
        <MediumSpacer withFullHeight>
            <FlexGrow/>
            <SmallSpacer>
                <TLabel500 color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                    {t('title_default')}
                </TLabel500>
                <TCaption color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                    {t('description_default')}
                </TCaption>
            </SmallSpacer>
            <FlexGrow/>
            <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.LARGE}
                onClick={(): void => {
                    LOGGER.userAction(
                        {
                            action: Action.CLICK,
                            resource_id: 'pregnancy:set_due_date',
                            resource_type: ResourceType.FEATURE,
                        }
                    )
                    dispatch(
                        modal(
                            ModalType.SIDE_RIGHT,
                            (close: () => void) => <PregnancyDateForm
                                modulePayload={payload}
                                close={(): void => {
                                    close()
                                    dispatch(loadPageAction(pageId, context))
                                }}
                            />,
                            {
                                title: t('date_popup.title')
                            }
                        )
                    )
                }}>
                {t('button_default')}
            </Button>
        </MediumSpacer>
    </PregnancyStatus>
};
