import FormCheckbox from "components/commonDesign/form/tmpNewSystem/FormCheckbox";

import SimpleBox, { SimpleBoxType } from 'components/core/containers/SimpleBox';
import Button, { ButtonSize, ButtonTextStyle, ButtonVariant } from "components/designSystem/components/Button";
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineOverflow
} from "components/designSystem/containers/Inline";
import { MediumSpacer } from "components/designSystem/containers/Spacer";
import LegacyEulaDataPrivacyNoticeV3 from "components/modules/eula/versions/LegacyEulaDataPrivacyNoticeV3";
import { getNameFromType } from "components/modules/modular/modules/helpers/eulaFields";
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from "components/pages/common/WithTranslations";

import { required } from 'components/utils/form/tmpNewSystem/validators';

import { getRoute } from 'core/routing/Helper';
import { ROUTE_EULA_DATA_PRIVACY, ROUTE_EULA_HEALTH_PROFESSIONAL, ROUTE_EULA_PATIENT } from 'core/routing/Routes';
import { EULAType } from 'models/user/AuthModels';
import React, { Fragment, ReactNode } from 'react';


export type RequiredEula = {
    eulaType: EULAType;
    version: number;
    useNewNaming?: boolean // Only for migration
};

export interface EULAFieldsProps {
    types: RequiredEula[];
}

export class EULAFields extends WithTranslations<EULAFieldsProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT + '.eula';

    getRouteFromEulaType(eulaType: EULAType): string {
        switch (eulaType) {
            case EULAType.HEALTH_PROFESSIONAL:
                return ROUTE_EULA_HEALTH_PROFESSIONAL;
            case EULAType.PATIENT:
                return ROUTE_EULA_PATIENT;
            case EULAType.DATA_PRIVACY:
                return ROUTE_EULA_DATA_PRIVACY;
            case EULAType.DATA_PRIVACY_NOTICED:
                return ROUTE_EULA_DATA_PRIVACY;
        }

        return '_unknown';
    }

    getTransKeyFromEulaType(eulaType: EULAType): string {
        switch (eulaType) {
            case EULAType.HEALTH_PROFESSIONAL:
                return 'eula_acceptance';
            case EULAType.PATIENT:
                return 'eula_acceptance';
            case EULAType.DATA_PRIVACY:
                return 'data_privacy_acceptance';
            case EULAType.DATA_PRIVACY_NOTICED:
                return 'data_privacy_noticed_acceptance';

        }

        return '_unknown';
    }

    render(): ReactNode {
        const {types} = this.props;

        const displayNotice: boolean = types.map((t: RequiredEula) => t.eulaType).includes(EULAType.DATA_PRIVACY_NOTICED);

        return (
            <Fragment>
                {
                    displayNotice &&
                    <SimpleBox type={SimpleBoxType.SCROLLING_NOTICE}>
                        <LegacyEulaDataPrivacyNoticeV3/>
                    </SimpleBox>
                }

                <MediumSpacer>
                    {types.map((t) => (
                        <FormCheckbox
                            key={t.eulaType}
                            name={getNameFromType(t.eulaType, t.version, t.useNewNaming)}
                            field={{
                                label: <AlwaysInline
                                    alignItems={InlineAlignItem.CENTER}
                                    overflow={InlineOverflow.WRAP}
                                    justifyContent={InlineJustifyContent.FLEX_START}>
                                    {this.withBuilder(
                                        <>
                                            EULA accessible
                                            <Button
                                                variant={ButtonVariant.LINK}
                                                size={ButtonSize.EXTRA_SMALL}
                                                onClick={{
                                                    href: getRoute(
                                                        this.getRouteFromEulaType(t.eulaType),
                                                        {version: t.version.toString()}
                                                    ),
                                                    targetBlank: true
                                                }}
                                                withOverrideTextStyle={ButtonTextStyle.LABEL}
                                            >
                                                here
                                            </Button>
                                        </>,
                                    ).trans(
                                        this.getTransKeyFromEulaType(t.eulaType)
                                    )}
                                </AlwaysInline>
                            }}
                            validate={required()}
                        />
                    ))}
                </MediumSpacer>
            </Fragment>
        );
    }
}
