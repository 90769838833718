import React from 'react';

export const DefaultLabLogo = (): JSX.Element => (
    <svg width="100%" height="100%" viewBox="0 0 32 32" fill="#FFFFFF">
        <path d="M12.9999 4V11.723C12.9999 11.9043 12.9507 12.0821 12.8574 12.2375L4.90862 25.4855C4.81758 25.6372 4.76843 25.8104 4.76619 25.9874C4.76396 26.1643 4.80872 26.3387 4.89591 26.4927C4.9831 26.6467 5.10959 26.7748 5.26248 26.8639C5.41536 26.953 5.58915 27 5.76612 27H26.2337C26.4107 27 26.5845 26.953 26.7374 26.8639C26.8903 26.7748 27.0168 26.6467 27.1039 26.4927C27.1911 26.3387 27.2359 26.1643 27.2337 25.9874C27.2314 25.8104 27.1823 25.6372 27.0912 25.4855L19.1424 12.2375C19.0492 12.0821 18.9999 11.9043 18.9999 11.723V4" stroke="#001948" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 4H21" stroke="#001948" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.82715 20.6206C9.30512 19.5433 11.8422 18.9211 16 21C20.4631 23.2315 23.0587 22.3507 24.4815 21.1357" stroke="#001948" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default DefaultLabLogo;
