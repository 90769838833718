import React from 'react';

export interface ToRemoveAPIText {
    raw?: string,
    translation_key?: {
        key: string,
        domain: string,
        params?: Record<string, unknown>
    },

}


export interface LocalTransKey {
    trkey: string;
    trdomain?: string;
    trdata?: Record<string, unknown>
}

export function toLocalTransKey(key: string, domain?: string, data?: Record<string, unknown>): LocalTransKey {
    return {trkey: key, trdomain: domain, trdata: data}
}

export interface APIText {
    text: string | LocalTransKey,
    markup?: boolean
}

export function toAPIText(messageOrKey: string | LocalTransKey): APIText {
    return {text: messageOrKey}
}
export const isLocalTransKey = (text: string | LocalTransKey): boolean => {
    return (typeof text !== 'string') && Object.keys(text).includes('trkey')
}

export type TranslationMethod = (apiText: APIText, postTrans?: (value: string) => string) => React.ReactNode;

