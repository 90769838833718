import { ColorMap } from "components/designSystem/Aliases";
import { RawColors } from 'components/legacyDesignSystem/branding/constants';

export const chartColorsToHexCode = (color: ColorMap): string => {
    switch (color) {
        case ColorMap.ORANGE:
            return '#DFA205';
        case ColorMap.GREY:
            return RawColors.NEUTRALS_LIGHT_BLUE_GREY;
        case ColorMap.DARK_ORANGE:
            return '#DF6005';
        case ColorMap.PURPLE:
            return '#7B3EFC';
        case ColorMap.GREEN:
            return '#3EC100';
        case ColorMap.BLUE:
            return RawColors.NEUTRALS_DARK_BLUE_GREY;
        case ColorMap.YELLOW:
            return '#E0D703';
        case ColorMap.RED:
            return '#DF1205';
        case ColorMap.BLACK:
            return '#000000';
        default:
            return '#9b9b9b';
    }
};

export enum NormalityLevel {
    NORMAL = 'NORMAL',
    ABNORMAL = 'ABNORMAL',
    HIGHLY_ABNORMAL = 'HIGHLY_ABNORMAL',
}

const normalityToColorNeutral: {[key: string]: string} = {}
normalityToColorNeutral[NormalityLevel.NORMAL] = RawColors.PRIMARY_LIGHT;
normalityToColorNeutral[NormalityLevel.ABNORMAL] = RawColors.SEMANTIC_YELLOW;
normalityToColorNeutral[NormalityLevel.HIGHLY_ABNORMAL] = RawColors.SEMANTIC_RED;

const normalityToColor: {[key: string]: string} = {}
normalityToColor[NormalityLevel.NORMAL] = RawColors.SEMANTIC_GREEN;
normalityToColor[NormalityLevel.ABNORMAL] = RawColors.SEMANTIC_YELLOW;
normalityToColor[NormalityLevel.HIGHLY_ABNORMAL] = RawColors.SEMANTIC_RED;


export const getNormalityColorNeutral = (level?: NormalityLevel): string => level ? normalityToColorNeutral[level] : NormalityLevel.NORMAL;
export const getNormalityColor = (level?: NormalityLevel): string => level ? normalityToColor[level] : NormalityLevel.NORMAL;
