import React, { Component, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import './CentralColumn.scss';

export enum CentralColumnWidth {
    DEFAULT = 'DEFAULT',
    THIN = 'THIN',
    THINER = 'THINER',
}

export interface CentralColumnProps {
    className?: string;
    width?: CentralColumnWidth;
}

export default class CentralColumn extends Component<PropsWithChildren<CentralColumnProps>> {
    render(): ReactNode {
        return (
            <div
                className={classNames(
                    'central-column',
                    {
                        'central-column-thin': this.props.width === CentralColumnWidth.THIN,
                        'central-column-thiner': this.props.width === CentralColumnWidth.THINER,
                    },
                    this.props.className,
                )}
            >
                {this.props.children}
            </div>
        );
    }
}
