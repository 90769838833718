import React, { FC } from 'react';
import classNames from "classnames";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import styles from './Carousel.module.scss';
import { IconName } from 'components/designSystem/foundations/IconsData';


export interface CarouselProps {
    bulletQty: number,
    onPrevious?: () => void,
    onNext?: () => void,
    navigationLength?: number,
    activePosition?: number,
    isVertical?: boolean;
    small?: boolean
}

const Carousel : FC<CarouselProps> = (
    {
        activePosition = 1,
        isVertical=true,
        ...props

    }):JSX.Element => {
    const navigationLength = props.navigationLength !== undefined ? props.navigationLength : Math.max(props.bulletQty, 1);
    let activeBulletIndex: number | undefined = undefined;
    const bulletQty = Math.min(navigationLength, props.bulletQty);

    if (activePosition !== undefined && navigationLength ) {
        const slotSize = bulletQty > 1 ? navigationLength / bulletQty : navigationLength;
        activeBulletIndex = Math.floor(activePosition / slotSize);
    } else {
        activeBulletIndex = 0
    }
    const previousActive: boolean =(activePosition !== undefined && navigationLength > 0) ? activePosition > 0 : false;
    const nextActive: boolean = (activePosition !== undefined && navigationLength > 0) ? activePosition < navigationLength - 1 : false;

    const computedClassNames = classNames(styles.Carousel,
        {
            [styles.CarouselNoBullet]: props.small || bulletQty === 0,
            [styles.CarouselOrientationHorizontal]: !isVertical ,
            [styles.CarouselOrientationVertical]: isVertical,
        }
    )
    const arrowPrevious = (isVertical ? IconName.ARROW_UP : IconName.ARROW_LEFT)
    const arrowNext = (isVertical ? IconName.ARROW_DOWN : IconName.ARROW_RIGHT)
    return <div>
        {
            !props.small ?
                <div className={
                    computedClassNames
                }>
                    <Button
                        disabled={!previousActive}
                        variant={ButtonVariant.SECONDARY}
                        size={ButtonSize.SMALL}
                        onClick={previousActive ? props.onPrevious : undefined}
                        icons={{middle: arrowPrevious}}
                        round={true}
                    />
                    {
                        bulletQty > 0 &&
                        <div className={isVertical ? styles.CarouselContainerVertical: styles.CarouselContainerHorizontal}>
                            {isVertical && Array.from(Array(bulletQty).keys()).map(
                                (i: number) =>
                                    <div key={"bullet"+i} className={(i == activeBulletIndex ? classNames(styles.DotActive, styles.DotVertical) : classNames(styles.Dot, styles.DotVertical))} />
                            )}
                            {!isVertical && Array.from(Array(bulletQty).keys()).map(
                                (i: number) =>
                                    <div key={"bullet"+i} className={(i == activeBulletIndex ? classNames(styles.DotActive, styles.DotHorizontal) : classNames(styles.Dot, styles.DotHorizontal))} />
                            )}
                        </div>
                    }
                    <Button
                        disabled={!nextActive}
                        variant={ButtonVariant.SECONDARY}
                        size={ButtonSize.SMALL}
                        onClick={nextActive ? props.onNext : undefined}
                        icons={{middle: arrowNext}}
                        round={true}
                    />
                </div> :
                <div className={computedClassNames}>
                    <Button
                        disabled={!previousActive}
                        variant={ButtonVariant.SECONDARY}
                        size={ButtonSize.SMALL}
                        onClick={previousActive ? props.onPrevious : undefined}
                        icons={{middle: arrowPrevious}}
                        round={true}
                    />
                    <Button
                        disabled={!nextActive}
                        variant={ButtonVariant.SECONDARY}
                        size={ButtonSize.SMALL}
                        onClick={nextActive ? props.onNext : undefined}
                        icons={{middle: arrowNext}}
                        round={true}
                    />
                </div>
        }
    </div>
}

export default Carousel;
