import React, { FC, Fragment, useState } from "react";
import { AvatarType } from "components/designSystem/components/Avatar";
import { IconName } from "components/designSystem/foundations/IconsData";
import MediaQuery from "react-responsive";
import { MOBILE_MAX_WIDTH } from "components/core/constants";
import { TeleconsultationEntity, TeleconsultationEntityPayloadType } from "models/modular/teleconsultation";
import LiviFlow from "components/modules/modular/modules/teleconsultation/LiviFlow";
import Column, { ColumnWidthInREM } from "components/designSystem/containers/Column";
import List from "components/designSystem/lists/List";
import ListCard from "components/designSystem/components/cards/ListCard";
import { getEntityImg, LogoType as CoreLogoType } from "core/content/brand/logos/LabLogos";


interface TeleconsultationSelectorProps {
    entities: TeleconsultationEntity[];
    onSelectEntity: () => void;
    onDeSelectEntity: () => void;
    onOpenEntity: () => void;
}

const TeleconsultationSelector: FC<TeleconsultationSelectorProps> = (
    {...props}
): JSX.Element => {
    const [selectedEntity, setSelectedEntity] = useState<TeleconsultationEntity | undefined>(undefined);

    const onDeSelectEntity: () => void = (): void => {
        props.onDeSelectEntity()
        setSelectedEntity(undefined)
    }

    let content: JSX.Element;
    switch (selectedEntity?.payload.type) {
        case undefined:
            content = <List>
                {props.entities.map((teleconsultationEntity) => (
                    <ListCard
                        key={teleconsultationEntity.entity.name}
                        label={teleconsultationEntity.entity.name}
                        avatarPayload={{
                            type: AvatarType.IMAGE,
                            src: getEntityImg(teleconsultationEntity.entity, CoreLogoType.SYMBOL),
                            alt: teleconsultationEntity.entity.name,
                            fallbackIcon: IconName.TUBE
                        }}
                        onClick={(): void => {
                            props.onSelectEntity();
                            setSelectedEntity(teleconsultationEntity);
                        }}
                    />
                ))}
            </List>
            break;
        case TeleconsultationEntityPayloadType.LIVI:
            content = <LiviFlow
                entity={selectedEntity?.entity}
                payload={selectedEntity?.payload}
                onDeSelectEntity={onDeSelectEntity}
                onOpenEntity={props.onOpenEntity}
            />
            break;
        default:
            content = <Fragment/>
            break;
    }

    return <>
        <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
            {content}
        </MediaQuery>
        <MediaQuery minWidth={MOBILE_MAX_WIDTH + 1}>
            <Column widthInRem={ColumnWidthInREM.WIDTH_40}>
                {content}
            </Column>
        </MediaQuery>
    </>
};

export default TeleconsultationSelector;