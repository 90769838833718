import { UserMode } from 'models/user/UserModels';
import { OnSuccessPutType } from 'actions/ActionInterface';

export type AuthUserData = {
    user_modes: UserMode[];
    first_name: string;
    last_name: string;
    doctor_data?: {
        practice_id: string | null;
        business_structure_id: string | null;
        business_structure_type: string | null;
        speciality: string | null;
        business_address: string | null;
        business_email: string | null;
        business_phone_number: string | null;
    };
    uuid: string;
    propose_pin?: boolean;
};

export type AuthResponse = {
    token: string;
    data?: AuthUserData;
};

export type AuthUserDataResponse = {
    data: AuthUserData;
};

export type ResetPasswordResponse = {
    auth_data?: AuthResponse;
};

export enum EULAType {
    HEALTH_PROFESSIONAL = 'HEALTH_PROFESSIONAL',
    PATIENT = 'PATIENT',
    DATA_PRIVACY = 'DATA_PRIVACY',
    DATA_PRIVACY_NOTICED = 'DATA_PRIVACY_NOTICED'
}

export type EULAValidation = {
    type: EULAType;
    control_version: number;
    is_eula_update: boolean;
    use_new_naming?: boolean; // only for migration
};

export enum TwoFaCodeFormat {
    SIX_DIGITS = 'SIX_DIGITS',
}

export enum InitSSOAction {
    REDIRECT = 'REDIRECT',
    LOGIN = 'LOGIN',
    CHECK_LOGIN = 'CHECK_LOGIN',
    SUBSCRIBE = 'SUBSCRIBE', // Not sent by API
}

export type EnforcedLocalConnectData = {
    fingerprint: string,
    token: string,
    deadline: string | null
}

export type ClientInfo = {
    name: string
    uuid: string
}

export type InitSSOResponse = {
    action: InitSSOAction;
    client_info: ClientInfo;
    validation_url?: string | null;
    expected_account_mail?: string | null;
    actual_account_mail?: string | null;
    redirect_uri: string;
    state?: string | null;
    enforced_local_connect_data?: EnforcedLocalConnectData | null;
};

export type CreateAccountResponse = {
    auth_data?: AuthResponse;
};

export type CPSRedirectionLocalData = {
    state: string,
    deadline: string,
    onSuccessPut?: OnSuccessPutType,
}

export type LocalStateInfo = {
    identifier: string,
    deadline: string
}

export type GetPscUrlResponse = {
    url: string,
    local_state: LocalStateInfo
}
