import { Action, APICallAction, APICallSuccessAction } from 'actions/ActionInterface';
import { PageContext } from 'models/modular/api';
import { OwnPayloadConstraint } from 'models/modular/storage';

export const API_MODULAR_GET_PAGE = 'API_MODULAR_GET_PAGE';
export const API_MODULAR_LOAD_PARTIALS = 'API_MODULAR_LOAD_PARTIALS';
export const API_MODULAR_LOAD_PARTIAL = 'API_MODULAR_LOAD_PARTIAL';
export const ALTER_CONTEXT = 'ALTER_CONTEXT';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const SUCCESSFUL_MODULE_API_CALL = 'SUCCESSFUL_MODULE_API_CALL';

export interface LoadPageAction {
    pageId: string,
    context?: PageContext
}

export const loadPageAction = (pageId: string, context?: PageContext): APICallAction<LoadPageAction> => ({
    type: API_MODULAR_GET_PAGE,
    payload: {
        params: {
            pageId: pageId,
            context: context
        }
    },
});

export interface PartialData {
    identifier: string,
    payload: PageContext
}


export interface LoadPartialsAction {
    pageId: string,
    partials: PartialData[]
}

export const loadPartialsAction = (pageId: string, partials: PartialData[]): APICallAction<LoadPartialsAction> => ({
    type: API_MODULAR_LOAD_PARTIALS,
    payload: {
        params: {
            partials: partials,
            pageId
        }
    },
});

export interface LoadPartialAction {
    pageId: string,
    identifier: string,
    payload: PageContext
}

export const loadPartialAction = (pageId: string, identifier: string, payload: PageContext): APICallAction<LoadPartialAction> => ({
    type: API_MODULAR_LOAD_PARTIAL,
    payload: {
        params: {
            identifier: identifier,
            payload: payload,
            pageId
        }
    },
});



export interface AlterContextPayload {
    contextKey: string,
    value: string | null
}

export const alterContext = (contextKey: string, value?: string): Action<AlterContextPayload> => ({
    type: ALTER_CONTEXT,
    payload: {
        contextKey: contextKey,
        value: value !== undefined ? value : null
    },
});

export interface ChangePagePayload {
    pageId: string,
    context?: PageContext,
    originPageId?: string
    originContext?: PageContext
}

export const changePage = (pageId: string, context?: PageContext, originPageId?: string, originContext?: PageContext): Action<ChangePagePayload> => {
    return {
        type: CHANGE_PAGE,
        payload: {
            pageId: pageId,
            context: context,
            originPageId: originPageId,
            originContext: originContext
        }
    };
};

export enum OwnPayloadOverrideMode {
    STRICT = 'STRICT',
    SPREAD = 'SPREAD',
    SPREAD_BY_KEY = 'SPREAD_BY_KEY'
}

export type moduleAppendix = {payload: {moduleIdentifier: string}}
export type successAppendix = {payload: {overrideMode: OwnPayloadOverrideMode}}
export type ModuleAPICallAction<T> = APICallAction<T> & moduleAppendix;
export type ModuleAPICallSuccessAction<R extends Record<string, unknown> | null, E> = APICallSuccessAction<R, E> & moduleAppendix & successAppendix;


export function moduleApiCallSuccessAction<R extends OwnPayloadConstraint | null, E>(
    moduleIdentifier: string,
    response: R | null = null,
    extra: E | null = null,
    overrideMode: OwnPayloadOverrideMode = OwnPayloadOverrideMode.SPREAD_BY_KEY
): ModuleAPICallSuccessAction<R | null, E | null> {
    return {
        payload: {
            response: response,
            extra: extra,
            moduleIdentifier: moduleIdentifier,
            overrideMode: overrideMode
        },
        type: SUCCESSFUL_MODULE_API_CALL,
    };
}

export function castModuleAPICallSuccessAction<R extends (OwnPayloadConstraint | null), E = undefined>(v: Action<{}>): ModuleAPICallSuccessAction<R, E> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <ModuleAPICallSuccessAction<R, E>>v;
}



