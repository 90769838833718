import { ColorMap } from "components/designSystem/Aliases";
import { chartColorsToHexCode } from 'components/legacyDesignSystem/components/charts/models';

function hex(i: number): string {
    const x = i.toString(16);
    return (x.length == 1) ? '0' + x : x;
}

function interpolate(c1: string, c2: string, ratio: number): string {
    const c1clean = c1.substring(1)
    const c2clean = c2.substring(1)
    const r = Math.ceil(parseInt(c1clean.substring(0,2), 16) * ratio + parseInt(c2clean.substring(0,2), 16) * (1-ratio));
    const g = Math.ceil(parseInt(c1clean.substring(2,4), 16) * ratio + parseInt(c2clean.substring(2,4), 16) * (1-ratio));
    const b = Math.ceil(parseInt(c1clean.substring(4,6), 16) * ratio + parseInt(c2clean.substring(4,6), 16) * (1-ratio));

    return  hex(r) + hex(g) + hex(b);
}

export function computeDensityColor(color: ColorMap, density: number, densityFactor = 1): string {
    const r =  interpolate(chartColorsToHexCode(color), chartColorsToHexCode(ColorMap.GREY), density/10)
    return "#" + r + Math.round(densityFactor * 255).toString(16)
}
