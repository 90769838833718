import React, { ReactNode } from 'react';

import MainComponent, { MainComponentProps, setupRaw, TransDomain } from 'components/pages/common/MainComponent';

import 'components/pages/WithFooter.scss';
import Footer from 'components/pages/Footer';

class WithFooter extends MainComponent<MainComponentProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    render(): ReactNode {
        return (
            <div className={'login-not-required-page'}>
                {this.props.children}
                <Footer />
            </div>
        );
    }
}

export default setupRaw(WithFooter);
