import React, { CSSProperties, HTMLAttributes } from 'react';

import classNames from 'classnames';

import 'components/legacyDesignSystem/components/Container.scss';
import { SPACING_UNIT } from 'components/legacyDesignSystem/branding/constants';
import { getResponsiveValue, ResponsiveValue } from 'components/legacyDesignSystem/utils/responsiveness';


export enum ContainerProfile {
    FLEX = 'FLEX',
    FLEX_COLUMN = 'FC',
    BACKGROUND_WHITE = 'BGW',
    BACKGROUND_LIGHT_BLUE_GREY = 'BGLBG',
    BACKGROUND_BLUE_GRADIENT = 'BGBG',
    BORDER_RADIUS_1 = 'BR1',
    BORDER_RADIUS_2 = 'BR2',
    WIDTH_100PCT = 'W100PCT',
    HEIGHT_100PCT = 'H100PCT',
    MIN_HEIGHT_100PCT = 'MH100PCT',
    SPACE_BETWEEN = 'SPBET',
    SPACE_AROUND = 'SPAROUND',
    ALIGN_CENTER = 'ALCENT',
    BORDER_1 = 'B1',
    ALIGN_TXT_CENTER = 'TXTC',
    JUSTIFY_CONTENT_CENTER = 'JCC',
    JUSTIFY_CONTENT_END = 'JCE',
    ALIGN_BASELINE = 'ALBL',
    ALIGN_FLEX_START = 'ALFS',
    ALIGN_FLEX_END = 'ALFE',
    FLEX_WRAP = 'FWRAP',
    WHITESPACE_NOWRAP = 'WSNOWRAP',
    BOX_SHADOW_2 = 'BS2',
    FLEX_BASIS_100PCT = 'FB100PCT',
    FLEX_GROW_1 = 'FGROW1',
    BORDER_MODULE = 'BRDMOD',
    RELATIVE= 'RELATIVE'
}

export enum PaddingType {
    P = 'padding',
    PL = 'paddingLeft',
    PT = 'paddingTop',
    PR = 'paddingRight',
    PB = 'paddingBottom',
}

export enum MarginType {
    M = 'margin',
    ML = 'marginLeft',
    MT = 'marginTop',
    MR = 'marginRight',
    MB = 'marginBottom',
}

export type ResponsiveSpacingValue =  ResponsiveValue<number>

export interface Spacing {
    type: PaddingType | MarginType | 'minWidth' | 'maxWidth' | 'height' | FlexSpacing
    value: number | ResponsiveSpacingValue
}

export enum FlexSpacing {
    RG = 'rowGap',
    CG = 'columnGap'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInstanceOfSpacing = (object: any): boolean => (
    object instanceof Object && 'type' in object && 'value' in object
)

export type Profile = ContainerProfile | Spacing

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
    profiles?: Profile[];
}


const splitProfiles = (profiles: Profile[]): [Spacing[], ContainerProfile[]] => {
    const spacing: Spacing[] = []
    const containerProfiles: ContainerProfile[] = []

    profiles.forEach((p: Profile): void => {
        // @ts-ignore
        isInstanceOfSpacing(p)  ? spacing.push(p) : containerProfiles.push(p)
    })
    return [spacing, containerProfiles]
}


const Container = (props: ContainerProps): JSX.Element => {
    const divProps = { ...props }
    delete divProps.profiles

    if (props.profiles?.find(
        (p) => p === ContainerProfile.BORDER_MODULE
    )) {
        props.profiles?.push(ContainerProfile.BORDER_RADIUS_2, ContainerProfile.BORDER_1)
    }

    const profiles: [Spacing[], ContainerProfile[]] = props.profiles ? splitProfiles(props.profiles) : [[], []]
    const style: CSSProperties = {...props.style}
    profiles[0].forEach((p: Spacing): void => {
        // @ts-ignore
        style[p.type] = `${getResponsiveValue(p.value) * SPACING_UNIT}px`
    })

    return (
        <div
            id={props.id || 'container'}
            {...divProps}
            className={classNames(
                'container',
                props.className,
                profiles[1].map((p: ContainerProfile): string => `container--${p}`),
                !!props.onClick && 'clickable',
            )}
            style={style}
        />
    )
}

export default Container;
