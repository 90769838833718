import { ChangePageParams } from "components/modules/modular/BaseModule";
import { PageIdentifier } from 'components/modules/modular/pages/identifiers';

export const articlePage = (articleToken: string): ChangePageParams => ({
    pageIdentifier: PageIdentifier.ARTICLE,
    pageContext: {
        token: articleToken,
    },
})

export const bioProfilePage = (patientUuid: string): ChangePageParams => ({
    pageIdentifier: PageIdentifier.BIO_PROFILE,
    pageContext: {
        patient_uuid: patientUuid
    },
})

export const reportPage = (reportUUID: string, navigatorKey?: string, from_access?: boolean): ChangePageParams => ({
    pageIdentifier: PageIdentifier.REPORT_PAGE,
    pageContext: {
        report_uuid: reportUUID,
        ...(navigatorKey ? {with_navigator: navigatorKey} : {}),
        ...(from_access ? {from_access: '1'} : {}),
    }
})
