import React, { CSSProperties, PropsWithChildren } from 'react';

import classNames from 'classnames';

import 'components/legacyDesignSystem/components/WithTooltip.scss';


export enum TooltipPosition {
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
    TOP_RIGHT = 'TOP_RIGHT',
}

export type WithTooltipProps = PropsWithChildren<{
    text?: string;
    position?: TooltipPosition;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
    hidden?: boolean;
}>;

const WithTooltip = (props: WithTooltipProps): JSX.Element => {
    const position = props.position || TooltipPosition.TOP_RIGHT;

    return (
        <div
            id={'with-tooltip-container'}
            className={classNames('with-tooltip-container', props.className)}
            style={props.style}
            onClick={props.onClick ? props.onClick : (): void => undefined}
        >
            {props.children}
            <span
                className={classNames(
                    'tooltip-class',
                    {
                        'tooltip--tr': position === TooltipPosition.TOP_RIGHT,
                        'tooltip--br': position === TooltipPosition.BOTTOM_RIGHT,
                        'tooltip--hidden': props.hidden,
                    }
                )}
            >
                {props.text}
            </span>
        </div>
    );
}

export default WithTooltip;
