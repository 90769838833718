import React from 'react';
import { Action, Resource, ResourceType, SubResourceType } from 'core/logging/ActionEvent';
import LOGGER from 'core/logging/Logger';

export const logWrapperOnClick = (
    call: (ev?: React.MouseEvent) => void,
    action: Action,
    resourceId?: Resource | string,
    resourceType?: ResourceType,
    actionPayload?: string,
    subResourceId?: string,
    subResourceType?: SubResourceType,
): ((ev?: React.MouseEvent) => void) => {
    return (ev?: React.MouseEvent): void => {
        LOGGER.userAction({
            action: action,
            resource_id: resourceId,
            resource_type: resourceType,
            action_payload: actionPayload,
            sub_resource_id: subResourceId,
            sub_resource_type: subResourceType,
        });
        call(ev);
    };
};

export interface LogClickAttributes {
    action: Action,
    resourceId?: Resource | string,
    resourceType?: ResourceType,
    actionPayload?: string,
    subResourceId?: string,
    subResourceType?: SubResourceType,
}
