import { APICallAction, apiCallSuccessAction } from 'actions/ActionInterface';
import { modal, ModalType, redirect, } from 'actions/common/CommonActions';
import {
    API_CONFIRM_PIN,
    API_CREATE_ACCOUNT,
    API_FORCE_CONNECTION,
    API_GET_PSC_URL,
    API_INIT_CPS,
    API_INIT_SSO,
    API_RETRIEVE_AUTH_USER_DATA,
    API_SIGN_IN,
    API_SIGN_OUT,
    API_TWO_FA_SUBMIT_CODE,
    API_VALIDATE_UELA_AND_CONTINUE,
    API_VALIDATE_UELA_AND_SIGN_IN,
    ConfirmPinActionInterface,
    CreateAccountInterface,
    ForceConnectionActionPayload,
    hasOnSuccessPut,
    InitCpsSubscriptionInterface,
    InitSSOActionPayload,
    RedirectToProSanteConnectInterface,
    RetrieveUserDataAction,
    SignInActionInterface,
    SignOutActionInterface,
    TwoFASubmitAction,
    ValidateEULAAndContinueActionInterface,
    ValidateEULAAndSignInActionInterface,
} from 'actions/user/SignInSignUpActions';
import { BlockingPopUpComponentIdentifier } from 'components/pages/common/FullPopList';
import { TransDomain, TransKey } from 'components/pages/common/MainComponent';
import { getRoute } from 'core/routing/Helper';
import {
    isRouteLegitimatePreviousPath,
    ROUTE_DEFAULT_MODULAR, ROUTE_REDIRECT_CPS,
    ROUTE_LOGIN,
    ROUTE_MODULAR_PAGE,
} from 'core/routing/Routes';
import { AnyIterator } from 'core/utils/Typed';
import { APIFeedbackMessageFallbackCatcher } from 'models/common/APIMessaging';
import { AuthResponse, AuthUserDataResponse, GetPscUrlResponse } from 'models/user/AuthModels';
import { DoctorInfoFromPscResponse, } from 'models/user/SubscriptionModels';
import { put, takeLatest } from 'redux-saga/effects';
import CallHandler from 'sagas/api/CallHandler';
import {
    confirmPin,
    createAccount,
    forceConnection,
    getDoctorInfo,
    getPscUrl,
    initSSO,
    retrieveAuthUserData,
    signIn,
    signOut,
    submit2FACode,
    validateEULA,
} from 'sagas/api/Endpoints';
import { CustomError, Errors } from 'sagas/api/rest/Exceptions';
import _ from "underscore";

export const signInSaga = function* (action: SignInActionInterface): AnyIterator {
    try {
        const result: AuthResponse = yield CallHandler.for(signIn, action)
            .defaultFallbackFeedbackCatcher(APIFeedbackMessageFallbackCatcher.MAIN_HEADER)
            .forwardingErrors(Errors.Needs2FA)
            .withErrorFeedbackRule(
                (e: CustomError) => e.is(Errors.TooManyRequest),
                () => {
                    return {
                        message: new TransKey('too_many_request_retry_later', TransDomain.ACCOUNT_AUTH),
                    };
                },
            )
            .withErrorFeedbackRule(
                (e: CustomError) => e.is(Errors.ForbiddenAccess),
                () => {
                    return {
                        message: new TransKey('connection_failure', TransDomain.ACCOUNT),
                    };
                }
            )
            .withErrorFeedbackRule(
                (e: CustomError) => e.is(Errors.IPNotAllowed),
                () => {
                    return {
                        message: new TransKey('connection_failure_ip_not_allowed', TransDomain.ACCOUNT),
                    };
                }
            )
            .call();

        if (result) {
            yield put(apiCallSuccessAction(API_SIGN_IN, result));
            if (!hasOnSuccessPut(action)) {
                yield put(redirect(ROUTE_DEFAULT_MODULAR));
            }
        }
    } catch (e) {
        const message = JSON.parse((e as Error).message);
        yield put(
            modal(
                ModalType.BLOCKING,
                undefined,
                {
                    blockingModalPayload: {
                        identifier: BlockingPopUpComponentIdentifier.TWOFA,
                        props: {
                            token: message.token,
                            partialEmail: message.extra_data.partial_email,
                            timeoutInSeconds: message.extra_data.remaining_time_in_seconds,
                            codeFormat: message.extra_data.code_format,
                        },
                        then: action.payload.onSuccessPut
                    }}
            )
        )
    }

};

export const getDoctorInfoSaga = function* (action: InitCpsSubscriptionInterface): AnyIterator {
    const result: DoctorInfoFromPscResponse = yield CallHandler.for(getDoctorInfo, action)
        .call();

    if (result) {
        yield put(apiCallSuccessAction(API_INIT_CPS, result));
    }
};

export const redirectToPscSaga = function* (action: RedirectToProSanteConnectInterface): AnyIterator {
    const result: GetPscUrlResponse = yield CallHandler.for(getPscUrl, action).call();
    if (result) {
        yield put(redirect(ROUTE_REDIRECT_CPS, {}, undefined, undefined, {
            cpsUrl: result.url,
            cpsRedirectionData: {
                state: result.local_state.identifier,
                deadline: result.local_state.deadline,
                onSuccessPut: action.payload.params?.onSuccessPutAfterCPS
            }
        }))
    }
};

export const validateEULAAndSignInSaga = function* (action: ValidateEULAAndSignInActionInterface): AnyIterator {
    yield CallHandler.for(validateEULA, action).call();

    if (action.payload.previousPath && isRouteLegitimatePreviousPath(action.payload.previousPath)) {
        yield put(redirect(action.payload.previousPath));
    } else {
        yield put(redirect(ROUTE_DEFAULT_MODULAR));
    }
};

export const validateEULAAndContinueSaga = function* (action: ValidateEULAAndContinueActionInterface): AnyIterator {
    yield CallHandler.for(validateEULA, action).call();
    if (!hasOnSuccessPut(action)) {
        yield put(redirect(ROUTE_DEFAULT_MODULAR));
    }
};

export const retrieveAuthUserDataSaga = function* (action: RetrieveUserDataAction): AnyIterator {
    const result: AuthUserDataResponse | null = yield CallHandler.for(retrieveAuthUserData, action)
        .defaultFallbackFeedbackCatcher(APIFeedbackMessageFallbackCatcher.MAIN_HEADER)
        .call();

    if (result) {
        yield put(apiCallSuccessAction(API_RETRIEVE_AUTH_USER_DATA, result));
    }
};

export const signOutSaga = function* (action: SignOutActionInterface): AnyIterator {
    try {
        yield CallHandler.for(signOut)
            .defaultFallbackFeedbackCatcher(APIFeedbackMessageFallbackCatcher.MAIN_HEADER)
            .call();
    } catch (e) {
        // ignored
    } finally {
        yield put(apiCallSuccessAction(API_SIGN_OUT, {}));
        yield put(redirect(ROUTE_LOGIN, {}, undefined, action.payload.atReconnectSuccessPut));
    }
};

export const confirmPinSaga = function* (action: ConfirmPinActionInterface): AnyIterator {

    try {
        const result = yield CallHandler.for(confirmPin, action)
            .withErrorFeedbackRule(
                (e: CustomError) => e.is(Errors.ForbiddenAccess),
                () => {
                    return {
                        message: new TransKey('wrong_pin', TransDomain.ACCOUNT),
                    };
                },
            )
            .withErrorFeedbackRule(
                (e: CustomError) => e.is(Errors.TooManyRequest),
                () => {
                    return {
                        message: new TransKey('too_many_request_reconnect', TransDomain.ACCOUNT_AUTH),
                        extraData: {tooManyRequest: true}
                    };
                },
            )
            .forwardingErrors(Errors.PreconditionFailed)
            .call();

        if (result) {
            if (!action.payload.onSuccessPut) {
                yield put(redirect(ROUTE_DEFAULT_MODULAR))
            }
        }
    } catch (e) {
        return yield put(redirect(ROUTE_DEFAULT_MODULAR));
    }
};

export const submit2FACodeSaga = function* (action: TwoFASubmitAction): AnyIterator {
    yield CallHandler.for(submit2FACode, action)
        .withErrorFeedbackRule(
            (e: CustomError) => e.is(Errors.TooManyRequest),
            () => {
                return {
                    message: new TransKey('too_many_request_reconnect', TransDomain.ACCOUNT_AUTH),
                    extraData: {tooManyRequest: true}
                };
            },
        )
        .withErrorFeedbackRule(
            (e: CustomError) => e.is(Errors.ForbiddenAccess),
            () => {
                return {
                    message: new TransKey('wrong_code', TransDomain.ACCOUNT_2FA),
                };
            },
        )
        .call();

};

export const initSSOSaga = function* (action: APICallAction<InitSSOActionPayload>): AnyIterator {
    const response = yield CallHandler.for(initSSO, action)
        .call();

    if (response) {
        yield put(apiCallSuccessAction(API_INIT_SSO, response));
    }
};

export const forceConnectionSaga = function* (action: APICallAction<ForceConnectionActionPayload>): AnyIterator {
    const response = yield CallHandler.for(forceConnection, action)
        .call();

    if (response) {
        yield put(redirect(ROUTE_DEFAULT_MODULAR))
        yield put(apiCallSuccessAction(API_INIT_SSO, response));
    }
};

export const createAccountSaga = function* (action: CreateAccountInterface): AnyIterator {
    const response = yield CallHandler.for(createAccount, action)
        .withErrorFeedbackRule(
            (e: CustomError) => e.is(Errors.Conflict),
            (e) => {
                return {
                    identifier: action.payload.feedbackIdentifier,
                    message: `subscription.${e.message}`,
                };
            },
        )
        .call();

    if (response) {
        if (action.payload.onSuccessPut === undefined || _.isEmpty(action.payload.onSuccessPut)){
            yield put(redirect(getRoute(ROUTE_MODULAR_PAGE, {pageId: 'dashboard'})));
        }
    }
};

export default function* signInSignUpSagas(): AnyIterator {
    yield takeLatest(API_INIT_CPS, getDoctorInfoSaga);
    yield takeLatest(API_SIGN_IN, signInSaga);
    yield takeLatest(API_SIGN_OUT, signOutSaga);
    yield takeLatest(API_GET_PSC_URL, redirectToPscSaga);
    yield takeLatest(API_VALIDATE_UELA_AND_SIGN_IN, validateEULAAndSignInSaga);
    yield takeLatest(API_VALIDATE_UELA_AND_CONTINUE, validateEULAAndContinueSaga);
    yield takeLatest(API_RETRIEVE_AUTH_USER_DATA, retrieveAuthUserDataSaga);
    yield takeLatest(API_CONFIRM_PIN, confirmPinSaga);
    yield takeLatest(API_TWO_FA_SUBMIT_CODE, submit2FACodeSaga);
    yield takeLatest(API_INIT_SSO, initSSOSaga);
    yield takeLatest(API_FORCE_CONNECTION, forceConnectionSaga);
    yield takeLatest(API_CREATE_ACCOUNT, createAccountSaga);
}
