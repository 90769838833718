import React from 'react';

const PeopleIcon = (): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 18.0695C22.0582 18.0716 21.1267 18.2159 20.2608 18.4937C19.3949 18.7715 18.6125 19.1771 17.96 19.6864C16.5378 18.7999 14.8084 18.2355 12.9741 18.0592C11.1398 17.883 9.27647 18.1021 7.60217 18.691C5.92787 19.2799 4.51183 20.2142 3.51972 21.3846C2.52762 22.5551 2.00049 23.9132 2 25.3C2 25.4857 2.09833 25.6637 2.27337 25.795C2.4484 25.9263 2.6858 26 2.93333 26H20.536C20.7835 26 21.0209 25.9263 21.196 25.795C21.371 25.6637 21.4693 25.4857 21.4693 25.3C21.4678 24.8705 22.6021 25.4227 22.5 25L29.0667 24.0191C29.3142 24.0191 29.5516 23.9454 29.7266 23.8141C29.9017 23.6828 30 23.5048 30 23.3192C29.998 21.9273 29.2599 20.5929 27.9476 19.6087C26.6352 18.6245 24.8559 18.071 23 18.0695Z" fill="currentColor" fillOpacity="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.43447 19.8608C3.86394 20.8572 3.15385 22.0347 3.15385 23.0198C3.15385 23.4868 3.30494 23.8349 3.59838 24.1409C3.91931 24.4755 4.44407 24.7897 5.21554 25.0495C6.767 25.5721 8.98089 25.7674 11.5 25.7674C14.0313 25.7674 16.244 25.5649 17.7906 25.0364C18.5595 24.7737 19.0821 24.457 19.4019 24.1204C19.695 23.8119 19.8462 23.4608 19.8462 22.9917C19.8462 22.0119 19.1346 20.8411 17.5585 19.8494C16.0222 18.8827 13.8633 18.2326 11.5 18.2326C9.12391 18.2326 6.96633 18.8888 5.43447 19.8608ZM4.31 17.9566C6.21219 16.7497 8.76615 16 11.5 16C14.2187 16 16.7713 16.7424 18.6761 17.9409C20.541 19.1143 22 20.8813 22 22.9917C22 24.0679 21.612 24.9758 20.9367 25.6868C20.2881 26.3696 19.4131 26.8328 18.4649 27.1568C16.5775 27.8017 14.0787 28 11.5 28C8.93715 28 6.4395 27.8092 4.54991 27.1728C3.60086 26.8531 2.72271 26.3945 2.07087 25.7148C1.39155 25.0065 1 24.0987 1 23.0198C1 20.9133 2.44647 19.139 4.31 17.9566Z" fill="currentColor"/>
            <circle cx="11.5" cy="9" r="5" fill="currentColor" fillOpacity="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.50003 8.9982C5.50101 5.68463 8.18762 3 11.5 3C14.813 3 17.5 5.68561 17.5 9C17.5 12.3131 14.8132 15 11.5 15H11.4647L11.4611 15C8.15867 14.9881 5.48909 12.3008 5.50003 8.9982ZM5.50003 8.9982C5.50004 8.99759 5.50004 8.99699 5.50004 8.99639L6.49999 9H5.50003C5.50003 8.9994 5.50003 8.9988 5.50003 8.9982ZM11.5 5C9.29131 5 7.50003 6.79046 7.50003 9V9.00361H7.50003C7.4921 11.2015 9.26859 12.9911 11.4666 13H11.5C13.7086 13 15.5 11.2086 15.5 9C15.5 6.79046 13.7087 5 11.5 5Z" fill="currentColor"/>
            <circle cx="23.0013" cy="11" r="3.33333" fill="currentColor" fillOpacity="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19 10.9988C19.0007 8.78975 20.7917 7 23 7C25.2087 7 27 8.7904 27 11C27 13.2088 25.2088 15 23 15H22.9765L22.9741 15C20.7724 14.9921 18.9927 13.2005 19 10.9988ZM19 10.9988C19 10.9984 19 10.998 19 10.9976L19.6667 11H19C19 10.9996 19 10.9992 19 10.9988ZM23 8.33333C21.5275 8.33333 20.3334 9.52697 20.3334 11V11.0024H20.3334C20.3281 12.4677 21.5124 13.6608 22.9777 13.6667H23C24.4724 13.6667 25.6667 12.4724 25.6667 11C25.6667 9.52697 24.4725 8.33333 23 8.33333Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1661 19.8956C18.0443 20.6429 20.7064 21.505 20.7064 22.2438C20.7064 22.594 22.6992 23.0903 20.9986 23.0903C21.2278 23.3413 19.6448 23.5824 20.1958 23.7773C21.304 24.1693 21.6993 24.3256 23.4986 24.3256C25.3067 24.3256 26.8872 24.1737 27.9919 23.7773C28.5411 23.5803 28.9144 23.3428 29.1429 23.0903C29.3522 22.8589 29.4602 22.5956 29.4602 22.2438C29.4602 21.5089 28.9519 20.6308 27.8261 19.887C26.7288 19.1621 25.1867 18.6744 23.4986 18.6744C21.8014 18.6744 20.2603 19.1666 19.1661 19.8956ZM18.3629 18.4675C19.7216 17.5623 21.5459 17 23.4986 17C25.4405 17 27.2638 17.5568 28.6244 18.4557C29.9565 19.3357 30.9986 20.661 30.9986 22.2438C30.9986 23.0509 30.7215 23.7319 30.2391 24.2651C29.7758 24.7772 29.1508 25.1246 28.4736 25.3676C27.1254 25.8513 25.3405 26 23.4986 26C21.668 26 19.884 25.8569 18.5343 25.3796C17.8564 25.1398 20.4641 25.0098 19.9985 24.5C19.5133 23.9687 20.4986 22.3092 20.4986 21.5C20.4986 19.9201 17.0318 19.3543 18.3629 18.4675Z" fill="currentColor"/>
        </svg>
    )
}

export default PeopleIcon;
