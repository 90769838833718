import React from 'react';

const Jar = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <g clipPath="url(#clip0_1513_16303)">
                <path d="M26.0208 12.75H5.97909C4.93367 12.75 4.08325 11.8996 4.08325 10.8542V9.22917C4.08325 8.18375 4.93367 7.33334 5.97909 7.33334H26.0208C27.0662 7.33334 27.9166 8.18375 27.9166 9.22917V10.8542C27.9166 11.8996 27.0662 12.75 26.0208 12.75Z" />
                <path d="M23.8541 8.95833H8.14575C7.69725 8.95833 7.33325 8.59433 7.33325 8.14583V5.97917C7.33325 4.33575 8.669 3 10.3124 3H21.6874C23.3308 3 24.6666 4.33575 24.6666 5.97917V8.14583C24.6666 8.59433 24.3026 8.95833 23.8541 8.95833Z" />
                <path d="M16 21.4167V18.1667C16 17.5698 15.5147 17.0833 14.9167 17.0833H6.25V22.5H14.9167C15.5147 22.5 16 22.0136 16 21.4167Z" />
                <path d="M24.9375 10.5833H7.0625C6.614 10.5833 6.25 10.9473 6.25 11.3958V14.9167H14.9167C16.7085 14.9167 18.1667 16.3748 18.1667 18.1667V21.4167C18.1667 23.2085 16.7085 24.6667 14.9167 24.6667H6.25V26.0209C6.25 27.6643 7.58575 29 9.22917 29H22.7708C24.4142 29 25.75 27.6643 25.75 26.0209V11.3958C25.75 10.9473 25.386 10.5833 24.9375 10.5833Z" />
            </g>
            <defs>
                <clipPath id="clip0_1513_16303">
                    <rect width="26" height="26" fill="white" transform="translate(3 3)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Jar;
