import classNames from 'classnames';
import styles from 'components/designSystem/containers/GridRow.module.scss';
import React, { FC } from 'react';

export enum GridRowAlign {
    CENTER,
    TOP,
    NONE,
}

export enum GridRowSize {
    MEDIUM, // 92px height
    FULL
}

export enum GridSpacing {
    X_LARGE = 'X_LARGE', // 32px
}

interface GridRowProps {
    columnNb: number,
    align?: GridRowAlign
    /**
     * The row size, applied as minimum height.
     */
    size?: GridRowSize
    spacing?: GridSpacing
}

const GridRow: FC<GridRowProps> = ({align = GridRowAlign.CENTER, ...props}): JSX.Element => {

    return <div
        className={
            classNames(
                styles.container,
                {
                    [styles.containerAlignCenter]: align == GridRowAlign.CENTER,
                    [styles.containerAlignTop]: align == GridRowAlign.TOP,
                    [styles.containerSizeMedium]: props.size == GridRowSize.MEDIUM,
                    [styles.containerSizeFull]: props.size == GridRowSize.FULL,
                    [styles.containerSpacingXLarge]: props.spacing == GridSpacing.X_LARGE,
                }
            )
        }
        style={{gridTemplateColumns: `repeat(${props.columnNb}, 1fr)`}}

    >
        {props.children}
    </div>
}

/**
 * Properties to determine the position and width of a `GridRowItem` within its `GridRow`.
 */
interface GridRowItemProps {
    /**
     * The start index, inclusive. Should generally be between 1 and the number of columns. If this value is greater
     * than the number of columns, then the grid row will add space for the content of this item, wrapping to its width.
     */
    from: number,
    /**
     * The end index, exclusive. Should generally be between 2 and the number of columns plus one, and strictly greater
     * than the start index `from`.
     */
    to: number,
}

export const GridRowItem: FC<GridRowItemProps> = (
    props
): JSX.Element => {

    return <div
        style={{gridColumnStart: props.from, gridColumnEnd: props.to }}
    >
        {props.children}
    </div>
}

export default GridRow;