import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const CloseSM = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'s-close-sm'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M3.93403 3.93415C4.22692 3.64125 4.70179 3.64125 4.99469 3.93415L7.99989 6.93935L11.0051 3.93415C11.298 3.64125 11.7729 3.64125 12.0658 3.93415C12.3586 4.22704 12.3586 4.70191 12.0658 4.99481L9.06055 8.00001L12.0658 11.0052C12.3586 11.2981 12.3586 11.773 12.0658 12.0659C11.7729 12.3588 11.298 12.3588 11.0051 12.0659L7.99989 9.06067L4.99469 12.0659C4.70179 12.3588 4.22692 12.3588 3.93403 12.0659C3.64113 11.773 3.64113 11.2981 3.93403 11.0052L6.93923 8.00001L3.93403 4.99481C3.64113 4.70191 3.64113 4.22704 3.93403 3.93415Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-close-sm'} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.34319 6.34319C6.73371 5.95266 7.36688 5.95266 7.7574 6.34319L12 10.5858L16.2427 6.34319C16.6332 5.95266 17.2664 5.95266 17.6569 6.34319C18.0474 6.73371 18.0474 7.36688 17.6569 7.7574L13.4143 12L17.6569 16.2427C18.0474 16.6332 18.0474 17.2664 17.6569 17.6569C17.2664 18.0474 16.6332 18.0474 16.2427 17.6569L12 13.4143L7.7574 17.6569C7.36688 18.0474 6.73371 18.0474 6.34319 17.6569C5.95266 17.2664 5.95266 16.6332 6.34319 16.2427L10.5858 12L6.34319 7.7574C5.95266 7.36688 5.95266 6.73371 6.34319 6.34319Z"
                  fill="currentColor"/>
        </svg>
    )
}