import React, { Component, ReactNode } from 'react';

export interface CloseProps {
    size?: string;
}

export default class Close extends Component<CloseProps> {
    render(): ReactNode {
        return (
            <svg
                className="close_svg__icon"
                style={{
                    width: this.props.size ? this.props.size : '1em',
                    height: this.props.size ? this.props.size : '1em',
                    verticalAlign: 'middle',
                }}
                viewBox="0 0 1024 1024"
                fill="currentColor"
                overflow="hidden"
                width="1em"
                height="1em"
                {...this.props}
            >
                <path d="M914.199 200.297l-90.495-90.495-311.703 321.76-311.703-321.76-90.494 90.495L431.563 512l-321.76 311.703 90.495 90.495L512 592.438l311.703 311.704 80.441-80.441-311.703-311.703L914.2 200.294z" />
            </svg>
        );
    }
}
