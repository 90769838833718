import React from 'react';

const CloseIcon = (): JSX.Element => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.03764 4.27267C6.2741 3.50913 5.03615 3.50913 4.27261 4.27267C3.50907 5.03621 3.50907 6.27416 4.27261 7.0377L13.2349 16L4.27261 24.9623C3.50907 25.7259 3.50907 26.9638 4.27261 27.7274C5.03615 28.4909 6.2741 28.4909 7.03764 27.7274L16 18.765L24.9623 27.7274C25.7258 28.4909 26.9638 28.4909 27.7273 27.7274C28.4908 26.9638 28.4908 25.7259 27.7273 24.9623L18.765 16L27.7273 7.0377C28.4908 6.27416 28.4908 5.03621 27.7273 4.27267C26.9638 3.50913 25.7258 3.50913 24.9623 4.27267L16 13.235L7.03764 4.27267Z"
        />
    </svg>
)

export default CloseIcon;
