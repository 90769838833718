export enum ErrorType {
    // To avoid code zero to be used
    // as we have if (!error.type)
    _,
    Unknown,
    InvalidToken,
    PinLocked,
    EULANotAccepted,
    InvalidRequest,
    TwoFARequired,
    ForbiddenAccess,
    IPNotAllowed,
    OutdatedToken,
    MissingResource,
    MethodNotAllowed,
    Timeout,
    Conflict,
    PreconditionFailed,
    TooManyRequest,
    UnavailableForLegalReason,
    RetryLater,
    ServiceUnavailable,
    BadGateway
}


export class APIError extends Error {
    type: ErrorType
    message: string

    constructor(message: string, type: ErrorType = ErrorType.Unknown ) {
        super(message)
        this.message = message;
        this.type = type
    }
}

function getCode(content: string): string | null {
    try {
        const payloadJson = JSON.parse(content)
        return payloadJson['detail'].substr(0, 6);
    } catch (e) {
        // Ignored
    }

    return null
}

export function handleError(
    status: number,
    content: string,
    response: Response
): void {

    // This code are deprecated
    const internalErrorId: string | null = response.headers.get('X-INTERNAL-ERROR-ID'); // legacy
    const internalErrorCode: string | null = response.headers.get('X-INTERNAL-ERROR-CODE');
    const code = getCode(content)

    switch (status) {
        case 400:
            throw new APIError(content, ErrorType.InvalidRequest);

        case 401:
            if (internalErrorId === 'TWO_FA_REQUIRED') {
                throw new APIError(content, ErrorType.TwoFARequired);
            }
            if (code === '[P001]') {
                throw new APIError(content, ErrorType.PinLocked);
            }

            throw new APIError(
                content,
                ErrorType.InvalidToken
            )
        case 403:
            if (code === '[C001]') {
                throw new APIError(content, ErrorType.IPNotAllowed);
            }

            if (code === '[I001]') {
                throw new APIError(content, ErrorType.OutdatedToken);
            }

            throw new APIError(content, ErrorType.ForbiddenAccess);
        case 404:
            throw new APIError(content, ErrorType.MissingResource);
        case 405:
            throw new APIError(content, ErrorType.MethodNotAllowed);
        case 408:
            throw new APIError(content, ErrorType.Timeout);
        case 409:
            throw new APIError(content, ErrorType.Conflict);
        case 412:
            throw new APIError(content, ErrorType.PreconditionFailed);
        case 429:
            throw new APIError(content, ErrorType.TooManyRequest);
        case 451:
            throw new APIError(
                content,
                internalErrorId === 'EULA_NOT_ACCEPTED' ? ErrorType.EULANotAccepted : ErrorType.UnavailableForLegalReason
            );
        case 502:
            throw new APIError(content, ErrorType.BadGateway);
        case 503:
            if (internalErrorCode == "5031") {
                throw new APIError(response.headers.get('Retry-After') || '60', ErrorType.RetryLater);
            }
            throw new APIError(content, ErrorType.ServiceUnavailable);

    }

    throw new APIError(content, ErrorType.Unknown)
}
