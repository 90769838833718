import classNames from 'classnames';
import { ColorMap } from "components/designSystem/Aliases";
import Container, { ContainerProps } from 'components/legacyDesignSystem/components/Container';

import 'components/legacyDesignSystem/components/Indicator.scss';
import React from 'react';


export enum IndicatorSeverity {
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
}

export interface IndicatorDotProps extends ContainerProps {
    severity: IndicatorSeverity | ColorMap
}

export const IndicatorDot = (props: IndicatorDotProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {severity, ...containerProps} = props
    return (
        <Container {...containerProps} className={classNames(
            'indicator-dot',
            `indicator-dot--${props.severity}`
        )}/>
    )
}

export interface IndicatorProps {
    severity: IndicatorSeverity;
    number: number;
}
