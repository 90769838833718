import React from 'react';

const SmallArrowLeft = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M20.4247 6.80386C19.7465 6.23864 18.7384 6.33028 18.1732 7.00854L11.3705 15.1718C10.8765 15.7646 10.8765 16.6258 11.3705 17.2186L18.1732 25.3818C18.7384 26.0601 19.7465 26.1518 20.4247 25.5865C21.103 25.0213 21.1947 24.0133 20.6294 23.335L14.6796 16.1952L20.6294 9.05538C21.1947 8.37712 21.103 7.36908 20.4247 6.80386Z" />
        </svg>

    )
}

export default SmallArrowLeft;