import * as React from "react";

const VividHcp = (): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
    >
        <g clipPath="url(#hcp_svg__a)">
            <path
                fill="#D1FFF1"
                d="m16.41 4.69 6.479 3.649-2.42 8.266v3.351l-16.906-.26v-3.09l-2.42-6.591V7.967L4.569 6.03l5.064-1.34h6.777Z"
            />
            <path
                stroke="#29C898"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={1.5}
                d="m20.473 16.942 2.824-8.472S19.266 4.233 12 4.233C4.734 4.233.703 8.47.703 8.47l2.824 8.472h16.946ZM20.473 19.767H3.527v-2.825h16.946v2.825Z"
            />
            <path
                fill="#29C898"
                d="M15.53 9.676a.5.5 0 0 0-.5-.5h-1.118a.5.5 0 0 1-.5-.5V7.558a.5.5 0 0 0-.5-.5h-1.824a.5.5 0 0 0-.5.5v1.118a.5.5 0 0 1-.5.5H8.97a.5.5 0 0 0-.5.5V11.5a.5.5 0 0 0 .5.5h1.118a.5.5 0 0 1 .5.5v1.118a.5.5 0 0 0 .5.5h1.824a.5.5 0 0 0 .5-.5V12.5a.5.5 0 0 1 .5-.5h1.118a.5.5 0 0 0 .5-.5V9.676Z"
            />
        </g>
        <defs>
            <clipPath id="hcp_svg__a">
                <path fill="#fff" d="M0 0h24v24H0z"/>
            </clipPath>
        </defs>
    </svg>
);

export default VividHcp;

