import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Send = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-send-arrow'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M14.5078 1.30746C14.5432 1.33229 14.5769 1.3604 14.6081 1.39169C14.6549 1.43847 14.6946 1.49045 14.7268 1.54608C14.7444 1.57642 14.7597 1.60774 14.7727 1.63977C14.8402 1.80598 14.8471 1.99423 14.7857 2.16978L10.5311 14.3257C10.4295 14.616 10.1611 14.8148 9.85381 14.8273C9.54655 14.8399 9.26277 14.6636 9.13788 14.3826L6.82385 9.17599L1.61727 6.86195C1.33626 6.73706 1.15997 6.45328 1.1725 6.14602C1.18504 5.83876 1.38387 5.57029 1.67412 5.4687L13.8108 1.22087C14.0393 1.13404 14.3018 1.16291 14.5078 1.30746ZM11.2232 3.71574L3.95519 6.25955L7.22579 7.71315L11.2232 3.71574ZM8.28654 8.77373L12.2843 4.77594L9.74028 12.0446L8.28654 8.77373Z"
                      fill="currentColor"
                />
            </svg>
        )
    }

    return (
        <svg id={'s-send-arrow'} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.997 0.92258C21.9793 0.69304 21.8827 0.46845 21.7071 0.292893C21.4206 0.0064317 21.0036 -0.0699015 20.6476 0.0638935L0.669655 7.05617C0.282654 7.19162 0.0175501 7.54959 0.000836285 7.95926C-0.0158775 8.36894 0.219186 8.74731 0.593867 8.91384L9.2424 12.7576L13.0862 21.4062C13.2527 21.7808 13.6311 22.0159 14.0408 21.9992C14.4504 21.9825 14.8084 21.7174 14.9439 21.3304L21.9368 1.35057C21.9881 1.21326 22.0082 1.06694 21.997 0.92258ZM17.1943 3.39152L3.71108 8.11063L9.77852 10.8073L17.1943 3.39152ZM11.1927 12.2215L18.6086 4.80566L13.8894 18.289L11.1927 12.2215Z"
                  fill="currentColor"
            />
        </svg>
    )
}

