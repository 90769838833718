import { ColorMap } from "../Aliases";
import style from "../foundations/ColorMap.module.scss"



export function colorMapToFillClass(colorMap: ColorMap): string {
    switch (colorMap) {
        case ColorMap.WHITE:
            return style.colorMapFillWHITE;
        case ColorMap.BLUE:
            return style.colorMapFillBLUE;
        case ColorMap.YELLOW:
            return style.colorMapFillYELLOW;
        case ColorMap.ORANGE:
            return style.colorMapFillORANGE;
        case ColorMap.DARK_ORANGE:
            return style.colorMapFillDARK_ORANGE;
        case ColorMap.PURPLE:
            return style.colorMapFillPURPLE;
        case ColorMap.GREEN:
            return style.colorMapFillGREEN;
        case ColorMap.RED:
            return style.colorMapFillRED;
        case ColorMap.GREY:
            return style.colorMapFillGREY;
        case ColorMap.BLACK:
            return style.colorMapFillBLACK;
        case ColorMap.GRADIENT_DARK_BLUE:
            return style.colorMapFillGRADIENT_DARK_BLUE;
        case ColorMap.GRADIENT_ORANGE:
            return style.colorMapFillGRADIENT_ORANGE;
        case ColorMap.SEVERITY_VERY_LOW:
            return style.colorMapFillSEVERITY_VERY_LOW;
        case ColorMap.SEVERITY_LOW:
            return style.colorMapFillSEVERITY_LOW;
        case ColorMap.SEVERITY_MODERATE_LOW:
            return style.colorMapFillSEVERITY_MODERATE_LOW;
        case ColorMap.SEVERITY_MODERATE:
            return style.colorMapFillSEVERITY_MODERATE;
        case ColorMap.SEVERITY_MODERATE_HIGH:
            return style.colorMapFillSEVERITY_MODERATE_HIGH;
        case ColorMap.SEVERITY_HIGH:
            return style.colorMapFillSEVERITY_HIGH;
        case ColorMap.SEVERITY_VERY_HIGH:
            return style.colorMapFillSEVERITY_VERY_HIGH;

    }
}

export function colorMapToStrokeClass(colorMap: ColorMap): string {
    switch (colorMap) {
        case ColorMap.WHITE:
            return style.colorMapStrokeWHITE;
        case ColorMap.BLUE:
            return style.colorMapStrokeBLUE;
        case ColorMap.YELLOW:
            return style.colorMapStrokeYELLOW;
        case ColorMap.ORANGE:
            return style.colorMapStrokeORANGE;
        case ColorMap.DARK_ORANGE:
            return style.colorMapStrokeDARK_ORANGE;
        case ColorMap.PURPLE:
            return style.colorMapStrokePURPLE;
        case ColorMap.GREEN:
            return style.colorMapStrokeGREEN;
        case ColorMap.RED:
            return style.colorMapStrokeRED;
        case ColorMap.GREY:
            return style.colorMapStrokeGREY;
        case ColorMap.BLACK:
            return style.colorMapStrokeBLACK;
        case ColorMap.GRADIENT_DARK_BLUE:
            return style.colorMapStrokeGRADIENT_DARK_BLUE;
        case ColorMap.GRADIENT_ORANGE:
            return style.colorMapStrokeGRADIENT_ORANGE;
        case ColorMap.SEVERITY_VERY_LOW:
            return style.colorMapStrokeSEVERITY_VERY_LOW;
        case ColorMap.SEVERITY_LOW:
            return style.colorMapStrokeSEVERITY_LOW;
        case ColorMap.SEVERITY_MODERATE_LOW:
            return style.colorMapStrokeSEVERITY_MODERATE_LOW;
        case ColorMap.SEVERITY_MODERATE:
            return style.colorMapStrokeSEVERITY_MODERATE;
        case ColorMap.SEVERITY_MODERATE_HIGH:
            return style.colorMapStrokeSEVERITY_MODERATE_HIGH;
        case ColorMap.SEVERITY_HIGH:
            return style.colorMapStrokeSEVERITY_HIGH;
        case ColorMap.SEVERITY_VERY_HIGH:
            return style.colorMapStrokeSEVERITY_VERY_HIGH;

    }
}

export function colorMapToFillClassDefault(colorMap: ColorMap | null | undefined, fallback?: ColorMap | null): string {
    if (colorMap == null) {

        if (fallback == null) {
            return ""
        }

        return colorMapToFillClass(fallback)
    }

    return colorMapToFillClass(colorMap)
}