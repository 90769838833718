import {
    CONNECTED_BUTTON_FEEDBACK,
    CONNECTED_BUTTON_PURE_API_CALL,
    ConnectedButtonFeedback,
    ConnectedButtonFeedbackType
} from 'actions/common/ConnectedButton';
import { AnyAction, getActionPayload } from 'actions/ActionInterface';

export interface ConnectedButtonState {
    type: ConnectedButtonFeedbackType,
    lastUpdateTs: number
}

export interface ConnectedButtonReducer {
    stateByIdentifier: {[key: string]: ConnectedButtonState}
}

export const initialState: ConnectedButtonReducer = {
    stateByIdentifier: {},
};

export default function connectedButtonReducer(state = initialState, action: AnyAction): ConnectedButtonReducer {
    switch (action.type) {
        case CONNECTED_BUTTON_FEEDBACK:
            const routerActionPayload = getActionPayload<ConnectedButtonFeedback>(action);
            state.stateByIdentifier[routerActionPayload.identifier] = {
                type: routerActionPayload.type,
                lastUpdateTs: routerActionPayload.feedbackTimestamp
            };
            return {
                ...state,
            };
        case CONNECTED_BUTTON_PURE_API_CALL:
            if (action.connectedButtonPayload) {
                if (
                    Object.hasOwn(state.stateByIdentifier, action.connectedButtonPayload.identifier) &&
                    state.stateByIdentifier[action.connectedButtonPayload.identifier].lastUpdateTs >= action.connectedButtonPayload.timestamp
                ) {
                    return state
                }

                state.stateByIdentifier[action.connectedButtonPayload.identifier] = {
                    type: ConnectedButtonFeedbackType.IN_PROGRESS,
                    lastUpdateTs: action.connectedButtonPayload.timestamp
                }
            }
            return {
                ...state,
            };
        default:
            return state;
    }
}