
import { detect } from 'detect-browser';
import i18n from 'i18next';
import { CallMethod } from "sagas/call/api";

const browserData = detect();
let browserFormatted = '';
if (browserData) {
    browserFormatted = browserData.name + '::' + browserData.version + '::' + browserData.os;
}

interface Headers {
    'Content-Type'?: string;
    Authorization?: string;
    'X-Device-Info'?: string;
    Accept?: string;
    'X-CSRFToken'?: string;
    'X-Retry-Count'?: string;
    'Accept-Language'?: string
}

export interface Options {
    headers: Headers;
    body?: string;
    method: string;
    redirect?: string;
    credentials: string;
}

function getCookie(name: string): string | null {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const getHeader = (method = CallMethod.GET, retryCount = 0): Headers => {
    const headers: Headers = {
        'X-Device-Info': browserFormatted,
        'X-Retry-Count': retryCount.toString(),
        'Accept-Language': i18n.language,
    };

    if (method === CallMethod.POST) {
        const csrftoken = getCookie('csrftoken');
        headers['X-CSRFToken'] = csrftoken ? csrftoken : '';
        headers['Content-Type'] = 'application/json';
    }

    headers['Accept'] = 'application/json';

    return headers;
};

export function getOptions<Req = {}>(params: Req, method: CallMethod = CallMethod.GET, retryCount = 0): {} {
    const options: Options = {
        headers: getHeader(method, retryCount),
        method: method,
        credentials: 'include',
    };

    if (method === CallMethod.POST) {
        options.body = JSON.stringify(params);
    }

    return JSON.parse(JSON.stringify(options));
};
