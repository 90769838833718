import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import 'components/core/menus/NavBar.scss';

const NavBar: FC<{ sticky?: boolean }> = (props: PropsWithChildren<{ sticky?: boolean }>) =>
    <div className={classNames('navbar', { 'navbar-sticky': props.sticky })}>
        {props.children}
    </div>;

export default NavBar;
