import { ActionDispatcher } from "actions/ActionInterface";
import {
    biologistCommunicationMeansAction,
    refreshNotificationBiologist
} from "actions/biologistDashboard/BiologistDashboardFetchActions";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import TextView from "components/designSystem/components/TextView";
import WithTooltip, { TooltipWidth } from "components/designSystem/components/WithTooltip";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import Inline, { AlwaysInline, Grow, InlineSpacing } from "components/designSystem/containers/Inline";
import Spacer, { SpacerSpacing } from "components/designSystem/containers/Spacer";
import Typography, { TypographyColor, TypographyVariant } from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize, WithTopMargin } from "components/designSystem/containers/WithMargin";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import ListItemWithDescription from "components/designSystem/lists/ListItemWithDescription";
import BaseModule, { setupModule } from 'components/modules/modular/BaseModule';
import { TransDomain } from "components/pages/common/MainComponent";
import { formatDateTime } from "core/utils/Date";
import { cast } from "core/utils/Typed";
import { BiologistAdminNotification, NotificationState } from "models/biologist/DashboardModels";
import { APIText } from "models/common/message";
import { ModuleData, OwnPayloadConstraint } from 'models/modular/storage';
import {
    AllRawCommunicationMeansType,
    CommunicationMeanEmail,
    CommunicationMeanPhone,
    CommunicationMeanType
} from "models/user/CommunicationMeansModels";
import React, { ReactNode } from 'react';

enum BiologistAdminSearchCommunicationMeansActionType {
    RESEND_NOTIFICATION = 'RESEND_NOTIFICATION'
}

interface BiologistAdminSearchCommunicationMeansAction {
    type: BiologistAdminSearchCommunicationMeansActionType,
    token: string
}

enum  CommunicationMeansStatus {
    VALID = 'VALID',
    INVALID = 'INVALID'
}

interface BiologistAdminSearchCommunicationMeans {
    communication_mean: AllRawCommunicationMeansType,
    status: CommunicationMeansStatus,
    status_reason: APIText,
    allowed_actions: BiologistAdminSearchCommunicationMeansAction[]

}
interface BiologistAdminSearchPatient {
    first_name: string | null,
    last_name: string | null,
    birth_date: Date | null,
    communication_means: BiologistAdminSearchCommunicationMeans[],

}
interface BiologistAdminSearchResultModulePayload {
    report_external_identifier: string,
    patient: BiologistAdminSearchPatient,
    notifications: BiologistAdminNotification[],
    refresh_notification_token: string
}

interface BiologistAdminSearchResultOwnPayload extends OwnPayloadConstraint {
    notifications?: BiologistAdminNotification[],
}

interface BiologistAdminSearchResultModuleDispatch {
    refreshNotifications: (token: string, moduleIdentifier: string) => void,
    biologistCommunicationMeansAction: (token: string, refreshToken: string, moduleIdentifier: string) => void,
}

class BiologistAdminSearchResultModule extends BaseModule<
    BiologistAdminSearchResultModuleDispatch,
    BiologistAdminSearchResultModulePayload,
    BiologistAdminSearchResultOwnPayload
    > {

    TRANS_SUFFIX = TransDomain.BIOLOGIST

    _render(payload: ModuleData<BiologistAdminSearchResultModulePayload, BiologistAdminSearchResultOwnPayload>): ReactNode {
        return <WithTopMargin margin={MarginSize.LARGE}>
            <Spacer spacing={SpacerSpacing.LARGE}>
                <Typography variant={TypographyVariant.DISPLAY_X_SMALL_700}>
                    {
                        this.trans('report_identification', {id: payload.pagePayload.report_external_identifier})
                    }
                </Typography>
                <Spacer spacing={SpacerSpacing.MEDIUM}>
                    <Box background={BoxColor.WHITE} withBoxShadow withRadius>
                        {this.buildPatientInformation(payload.pagePayload.patient)}
                        {this.buildEmailAddresses(payload.pagePayload.patient, payload.pagePayload.refresh_notification_token)}
                    </Box>
                    <Box background={BoxColor.WHITE} withBoxShadow withRadius>
                        {this.buildNotifications(
                            payload.ownPayload.notifications ?? payload.pagePayload.notifications,
                            payload.pagePayload.refresh_notification_token
                        )}
                    </Box>
                </Spacer>

            </Spacer>
        </WithTopMargin>
    }

    private buildPatientInformation(patient: BiologistAdminSearchPatient): ReactNode {

        const elements: ReactNode[] = [
            <TextView
                key={"patient_first_name"}
                title={this.trans('patient_first_name')}
                description={patient.first_name}
                withDivider
            />,
            <TextView
                key={"patient_birth_date"}
                title={this.trans('patient_birth_date')}
                description={patient.birth_date}
                withDivider
            />,
            <TextView
                key={"patient_last_name"}
                title={this.trans('patient_last_name')}
                description={patient.last_name}
                withDivider
            />
        ]


        patient.communication_means.filter((cm) => cm.communication_mean.type == CommunicationMeanType.PHONE).forEach(
            (cm, index) => elements.push(
                <TextView
                    key={"phone_"+ index}
                    title={this.trans('patient_phone')}
                    description={cast<CommunicationMeanPhone>(cm.communication_mean).phone}
                    withDivider
                />
            )
        )

        return <WithMargin margins={[MarginSize.LARGE, MarginSize.MEDIUM]}>
            <Spacer spacing={SpacerSpacing.LARGE}>
                <div>
                    <Typography variant={TypographyVariant.DISPLAY_X_SMALL}>
                        {this.trans('patient_information')}
                    </Typography>
                </div>
                <div>
                    {
                        BiologistAdminSearchResultModule.buildCardItems(
                            elements
                        )
                    }
                </div>
            </Spacer>
        </WithMargin>
    }

    private buildEmailAddresses(patient: BiologistAdminSearchPatient, refreshToken: string): ReactNode {
        const allEMails = patient.communication_means
            .filter((cm) => cm.communication_mean.type == CommunicationMeanType.EMAIL);

        return <WithMargin margins={[MarginSize.LARGE, MarginSize.MEDIUM]}>
            <Spacer spacing={SpacerSpacing.LARGE}>
                <div>
                    <Typography variant={TypographyVariant.BODY}>
                        {this.trans('associated_emails')}
                    </Typography>
                </div>
                <div>
                    {
                        allEMails.length > 0 ?
                            BiologistAdminSearchResultModule.buildCardItems(
                            allEMails.map(
                                (cm, index) => (
                                    <TextView
                                        key={"patient_email"+index}
                                        title={
                                            <AlwaysInline>
                                                <Grow>
                                                    {this.trans('patient_email')}
                                                </Grow>

                                                {
                                                    cm.status === CommunicationMeansStatus.INVALID &&
                                                    <WithTooltip
                                                        width={TooltipWidth.XXXLARGE}
                                                        tooltip={
                                                            cm.status_reason ? this.transApiText(cm.status_reason) :
                                                                this.trans('invalid_email')}
                                                    >
                                                        <Icon name={IconName.INFO_CIRCLE} size={IconSize.EXTRA_SMALL} />
                                                    </WithTooltip>
                                                }
                                            </AlwaysInline>
                                        }
                                        titleColor={
                                            cm.status == CommunicationMeansStatus.INVALID ? TypographyColor.COLOR_TEXT_DISABLED : TypographyColor.COLOR_TEXT_SUBDUED
                                        }
                                        description={ cast<CommunicationMeanEmail>(cm.communication_mean).email}
                                        descriptionColor={
                                            cm.status == CommunicationMeansStatus.INVALID ? TypographyColor.COLOR_TEXT_DISABLED : TypographyColor.COLOR_TEXT_DEFAULT
                                        }
                                        withDivider
                                        action={
                                            cm.allowed_actions
                                                .filter((a) => a.type === BiologistAdminSearchCommunicationMeansActionType.RESEND_NOTIFICATION)
                                                .map((a, index) => <Button
                                                    key={index}
                                                    size={ButtonSize.SMALL}
                                                    variant={ButtonVariant.SECONDARY}
                                                    onClick={
                                                        (): void => this.props.biologistCommunicationMeansAction(
                                                            a.token,
                                                            refreshToken,
                                                            this.props.identifier
                                                        )
                                                    }
                                                >{this.trans("resend")}</Button>)
                                        }
                                    />
                                )
                            )

                        ) : this.trans('no_associated_emails')
                    }
                </div>
            </Spacer>
        </WithMargin>
    }

    static buildCardItems(items: ReactNode[] , numberOfColumns = 2): ReactNode {
        return <Spacer spacing={SpacerSpacing.MEDIUM}>
                {
                    [...Array(Math.ceil(items.length/numberOfColumns)).keys()].map(
                        (line_index): ReactNode =>
                            (
                                <Inline key={line_index} spacing={InlineSpacing.X_LARGE}>
                                    {
                                        [...Array(numberOfColumns).keys()].map(
                                            (column_index) => items[numberOfColumns*line_index+column_index]
                                        ).map(
                                            (item, index) => {
                                                return <Grow key={index}>
                                                    {
                                                        item
                                                    }
                                                </Grow>
                                            }
                                        )
                                    }
                                </Inline>
                            )
                    )
                }
            </Spacer>
    }

    private buildNotificationDescription(notification: BiologistAdminNotification): {text: ReactNode, color: TypographyColor} {

        if ([NotificationState.INIT].includes(notification.last_state)) {
            return {text: this.trans('in_progress'), color: TypographyColor.COLOR_TEXT_WARNING}
        }

        if ([NotificationState.SENT, NotificationState.OPENED].includes(notification.last_state)) {
            return {
                text: this.trans(
                    notification.last_state == NotificationState.OPENED ? 'opened' : 'sent',
                    {
                        date: formatDateTime(notification.last_update)
                    }
                ),
                color: TypographyColor.COLOR_TEXT_SUCCESS
            }
        }

        if ([NotificationState.NOT_SENT, NotificationState.FAILED].includes(notification.last_state)) {
            return {
                text: this.trans(
                    notification.last_state == NotificationState.FAILED ? 'mail_failed' : 'not_sent',
                    {
                        date: formatDateTime(notification.last_update)
                    }
                ),
                color: TypographyColor.COLOR_TEXT_CRITICAL
            }
        }

        return {text: notification.last_state, color: TypographyColor.COLOR_TEXT_DEFAULT}
    }

    private buildNotifications(
        notifications: BiologistAdminNotification[],
        refreshToken: string
    ): ReactNode {
        const elements: ReactNode[] = notifications.map(
            (notification, index): ReactNode => {
                const description = this.buildNotificationDescription(notification)
                return <ListItemWithDescription
                    key={index}
                    title={this.trans('patient_email')}
                    description={description.text}
                    descriptionColor={description.color}
                />
            }
        )

        return <WithMargin margins={[MarginSize.LARGE, MarginSize.MEDIUM]}>
            <Spacer spacing={SpacerSpacing.LARGE}>
                <AlwaysInline>
                    <Grow>
                        <Typography variant={TypographyVariant.DISPLAY_X_SMALL}>
                            {this.trans('notifications')}
                        </Typography>
                    </Grow>
                    <Button
                        id={"refresh_"}
                        icons={{right: IconName.REFRESH}}
                        variant={ButtonVariant.TERTIARY}
                        size={ButtonSize.SMALL}
                        onClick={
                            (): void => this.props.refreshNotifications(refreshToken, this.props.identifier)
                        }
                    >
                        {this.trans('refresh')}
                    </Button>

                </AlwaysInline>
                <div>
                    {
                        elements.length > 0 ?
                            BiologistAdminSearchResultModule.buildCardItems(
                            elements
                        ):
                            this.trans("no_notifications")
                    }
                </div>
            </Spacer>
        </WithMargin>
    }
}

const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): BiologistAdminSearchResultModuleDispatch => (
    {
        refreshNotifications: (token: string, moduleIdentifier: string) => dispatch(refreshNotificationBiologist(token, moduleIdentifier)),
        biologistCommunicationMeansAction: (
            token: string,
            refreshToken: string,
            moduleIdentifier: string
        ) => dispatch(biologistCommunicationMeansAction(
            token,
            [refreshNotificationBiologist(refreshToken, moduleIdentifier)]
        )),
    }
);


export default setupModule(BiologistAdminSearchResultModule, mapStateToProps, mapDispatchToProps);
