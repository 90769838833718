import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ArrowRight = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-arrow-right'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.5835 7.99991C2.5835 7.58569 2.91928 7.24991 3.3335 7.24991H12.6668C13.081 7.24991 13.4168 7.58569 13.4168 7.99991C13.4168 8.41412 13.081 8.74991 12.6668 8.74991H3.3335C2.91928 8.74991 2.5835 8.41412 2.5835 7.99991Z"
                      fill="currentColor"
                />
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7.46983 2.80292C7.76272 2.51003 8.2376 2.51003 8.53049 2.80292L13.1972 7.46959C13.49 7.76248 13.49 8.23736 13.1972 8.53025L8.53049 13.1969C8.2376 13.4898 7.76272 13.4898 7.46983 13.1969C7.17694 12.904 7.17694 12.4291 7.46983 12.1363L11.6062 7.99992L7.46983 3.86358C7.17694 3.57069 7.17694 3.09582 7.46983 2.80292Z"
                      fill="currentColor"
                />
            </svg>
        )
    }

    return (
        <svg id={'s-arrow-right'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                  fill="currentColor"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z"
                  fill="currentColor"
            />
        </svg>
    )
}