import React from 'react';

const ExternalIcon = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9342 3.43335C11.6176 3.43335 8.30423 4.02203 6.16343 6.16257C4.02258 8.30315 3.43359 11.6164 3.43359 15.9334C3.43359 20.2608 4.04961 23.5702 6.20241 25.7078C8.35258 27.8427 11.6594 28.4333 15.9342 28.4333C20.2089 28.4333 23.5155 27.8428 25.6654 25.7077C27.8179 23.5701 28.4336 20.2607 28.4336 15.9334C28.4336 15.1162 27.7712 14.4538 26.954 14.4538C26.1368 14.4538 25.4744 15.1162 25.4744 15.9334C25.4744 20.1777 24.8184 22.3783 23.5802 23.608C22.3395 24.8402 20.1364 25.4742 15.9342 25.4742C11.7319 25.4742 9.52848 24.8402 8.28743 23.6079C7.049 22.3782 6.39278 20.1776 6.39278 15.9334C6.39278 11.6785 7.02896 9.4818 8.25576 8.25516C9.48262 7.02846 11.6795 6.39253 15.9342 6.39253C16.7513 6.39253 17.4138 5.7301 17.4138 4.91294C17.4138 4.09579 16.7513 3.43335 15.9342 3.43335ZM25.7295 4.65784L20.8316 4.65784C20.0144 4.65784 19.352 5.32028 19.352 6.13743C19.352 6.95459 20.0144 7.61702 20.8316 7.61702H22.1575L17.3363 12.4381C16.7585 13.016 16.7585 13.9528 17.3363 14.5306C17.9142 15.1084 18.851 15.1084 19.4288 14.5306L24.2499 9.70948V11.0354C24.2499 11.8525 24.9124 12.515 25.7295 12.515C26.5467 12.515 27.2091 11.8525 27.2091 11.0354V6.13743C27.2091 5.74502 27.0532 5.36868 26.7757 5.0912C26.4983 4.81372 26.1219 4.65784 25.7295 4.65784Z"
            />
        </svg>
    )
}

export default ExternalIcon;