import { Env, ENV } from 'configuration/globals';
import React, { Component, ReactElement, ReactNode } from 'react';
import { ResponsiveContainer } from 'recharts';
import "./Chart.scss"

export interface ChartContainerProps {
    children: ReactElement;
    height: number
}

export class ChartContainer extends Component<ChartContainerProps> {

    render(): ReactNode {
        return <div className={"chart-wrapper"} style={{height: this.props.height + 'px'}}>
            <ResponsiveContainer {...(ENV === Env.TEST ? { width: 100, aspect: 1 } : {})}>
                {this.props.children}
            </ResponsiveContainer>
        </div>
    }
}
