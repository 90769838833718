import { APIText } from 'models/common/message';
import NavBar from 'components/core/menus/NavBar';
import { PageContext } from 'models/modular/api';
import { IconName } from 'components/designSystem/foundations/IconsData';

export interface SideBarMenuActionModularPage {
    page_id: string
    context?: PageContext
}

export interface SideBarMenuActionLegacy {
    page_id: string
}

export enum PageLinkType {
    MODULAR_PAGE = 'MODULAR_PAGE',
    LEGACY_PAGE = 'LEGACY_PAGE'
}

export type SideBarMenuActionPayloads = SideBarMenuActionModularPage | SideBarMenuActionLegacy

export interface SideBarMenuAction {
    type: PageLinkType
    payload: SideBarMenuActionPayloads
}
export interface SideBarMenu {
    name: APIText
    icon: IconName
    action: SideBarMenuAction
}

export interface SideBar {
    menus: SideBarMenu[]
}

export interface NavBar {
    with_language_selector: boolean
    with_search: boolean
    with_account: boolean
}

export interface PageLink {
    page: string
    type: PageLinkType
    context?: PageContext
}

export type Navigation = GetNavigationResponse;

export interface GetNavigationResponse {
    navbar: NavBar
    sidebar?: SideBar
    home?: PageLink
}
