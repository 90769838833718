import DropDown, { OpenCloseBehavior } from "components/designSystem/components/DropDown";
import TextField from "components/designSystem/components/TextField";
import { IconName } from "components/designSystem/foundations/IconsData";
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';


export interface SearchBarProps {
    label: string
    defaultText?: string;
    buildOptionModeMap: () => ReactNode;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, cursor: number) => number;
    onSearch?: (value: string) => void;
    selectOptionMode?: boolean;
    onInputChange?: (newValue: string) => void;
    descriptionPrefix?: string;
    onFocus?: () => void;
    disabled?: boolean;
}

const SearchBar: FC<SearchBarProps> = (
    props
): JSX.Element => {

    const [cursor, setCursor] = useState(-1)

    const searchBarRef = React.createRef<HTMLInputElement>();
    const inputRef = React.createRef<HTMLInputElement>();
    const optionsRef = React.createRef<HTMLInputElement>();
    const previousCursor = useRef<number>()
    let dispatchChange: ReturnType<typeof setInterval> | null = null;



    //for componentDidUpdate
    useEffect(() => {
        previousCursor.current = cursor

        if (!!optionsRef.current && !!optionsRef.current.firstElementChild) {
            const optionsListHeight = optionsRef.current.clientHeight;
            const optionHeight = optionsRef.current.firstElementChild.clientHeight;
            const cursorLimit = Math.trunc(optionsListHeight / optionHeight) - 1;

            if (previousCursor.current < cursor && cursor > cursorLimit) {
                optionsRef.current.scrollTop = (cursor - cursorLimit) * optionHeight;
            } else if (
                previousCursor.current > cursor &&
                optionsRef.current.scrollTop / optionHeight > cursor
            ) {
                optionsRef.current.scrollTop = cursor * optionHeight;
            }
        }
    }, [cursor]);

    const focus = (): void => {
        const node: HTMLDivElement | null = inputRef.current;
        if (node) {
            node.focus();
            !!props.onFocus && props.onFocus();
        }
    };

    const onChange = (newValue: string): void => {
        if (dispatchChange) {
            clearTimeout(dispatchChange);
        }
        setCursor(-1)
        dispatchChange = setTimeout(
            () => props.onInputChange && props.onInputChange(newValue),
            500,
        );
    };


    // blurTopOffset is a hack to make top bar still displayed, can be improved
    return <DropDown
        openCloseBehavior={OpenCloseBehavior.FOCUS_CLICK_OUTSIDE}
        closeOnClick
        fullWidth
        openFromTopMobile
        blurTopOffset={"64px"}
        triggerBuilder={(): JSX.Element => (
            <div ref={searchBarRef}>
                <div ref={inputRef}>
                    <TextField
                        label={props.label}
                        leftIconOrFlag={{icon: IconName.SEARCH}}
                        onFocus={focus}
                        hideLabelOnActive
                        withMargins={false}
                        onChange={onChange}
                    />
                </div>
            </div>)
        }
    >
        {props.buildOptionModeMap()}
    </DropDown>

}

export default SearchBar;