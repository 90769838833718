import * as React from "react";

const VividUser = (): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
    >
        <ellipse cx={12.19} cy={6.159} fill="#95E1FF" rx={3.289} ry={3.358}/>
        <path
            fill="#95E1FF"
            d="M21.71 18.855C22.335 23 17.37 23 12.018 23c-5.354 0-10.18-1.088-9.694-4.145 0-2.288 4.34-4.144 9.694-4.144 5.354 0 9.694 1.856 9.694 4.144Z"
        />
        <path
            fill="#30BBFB"
            fillRule="evenodd"
            d="M15 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm2 0A5 5 0 1 1 7 6a5 5 0 0 1 10 0ZM3.134 21c.66-3.418 3.667-6 7.278-6h3.176a7.414 7.414 0 0 1 7.278 6H3.134ZM1 22.412A9.412 9.412 0 0 1 10.412 13h3.176A9.412 9.412 0 0 1 23 22.412a.588.588 0 0 1-.588.588H1.588A.588.588 0 0 1 1 22.412Z"
            clipRule="evenodd"
        />
    </svg>
);

export default VividUser;

