import classNames from "classnames";
import Button, { ButtonOption, ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';

import styles from "components/designSystem/components/DropDownContent.module.scss";
import { TDisplayXSmall700, TypographyColor } from "components/designSystem/containers/Typography";
import { IconName } from "components/designSystem/foundations/IconsData";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";


interface DropdownContentProps {
    title?: string | ReactNode,
    fullWidth?: boolean,
    onClick: () => void;
    closeOnClick: boolean
    openFromTopMobile?: boolean
    rightAlign?: boolean
}

const DropdownContent: FC<DropdownContentProps> = (
    { rightAlign: rightAlign, ...props}
): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);
    const [isUp, setIsUp] = useState(false);
    const [isRight, setIsRight] = useState(false);

    const set = (): void => {
        const {innerWidth: windowWidth, innerHeight: windowHeight} = window;
        setIsUp(false);
        setIsRight(!!rightAlign);
        if (!isUp && ref.current && typeof ref.current.getBoundingClientRect === "function" && (windowHeight - ref.current.getBoundingClientRect()['bottom']) < 0) {
            setIsUp(true);
        }
        if (!isRight && ref.current && typeof ref.current.getBoundingClientRect === "function" && (windowWidth - ref.current.getBoundingClientRect()[rightAlign ? 'left' : 'right']) < 0) {
            setIsRight(!rightAlign);
        }
    };

    useEffect(() => {
        set()
        window.addEventListener('resize', set);
        window.addEventListener('scroll', set);
        return (): void => {
            window.removeEventListener('resize', set);
            window.removeEventListener('scroll', set)
        };
    }, []);

    return <div ref={ref}  className={
        classNames(styles.container,
            {
                [styles.containerFullWidth]: props.fullWidth,
                [styles.containerLeft]: isRight,
                [styles.containerUp]: isUp,
                [styles.containerBottom]: props.openFromTopMobile,
                [styles.containerTop]: !props.openFromTopMobile
            },
        )
    }>
        <div className={styles.title}>
            {props.title &&
                <TDisplayXSmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>{props.title}</TDisplayXSmall700>
            }
            {
                !props.openFromTopMobile &&
                <div className={classNames(styles.titleCross)} onClick={props.onClick}>
                    <Button
                        variant={ButtonVariant.QUATERNARY}
                        option={ButtonOption.DARK}
                        icons={{middle: IconName.CLOSE}}
                        size={ButtonSize.EXTRA_SMALL}
                    />
                </div>
            }
        </div>
        <div className={styles.subContainer} onClick={props.closeOnClick ? props.onClick : undefined}>
            {props.children}
        </div>
    </div>
}

export default DropdownContent;