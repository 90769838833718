import { ActionDispatcher } from "actions/ActionInterface";
import { redirect } from "actions/common/CommonActions";
import { retrieveUserData, signOut } from "actions/user/SignInSignUpActions";
import { getNavigationContent } from "actions/user/UserActions";
import TrText from 'components/common/TrText';
import { WithFooter } from 'components/commonDesign/Footer';
import { MOBILE_MAX_WIDTH } from "components/core/constants";
import MyLoader from 'components/core/items/MyLoader';
import { AvatarType } from "components/designSystem/components/Avatar";
import Button, { ButtonExternalLink, ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import DropDown from 'components/designSystem/components/DropDown';
import DropDownItem, { DropDownItemSeverity, DropDownType } from 'components/designSystem/components/DropDownItem';
import ProfileMenu from 'components/designSystem/components/ProfileMenu';
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from 'components/designSystem/containers/Inline';
import NavigationWrapper, { NavigationContent } from 'components/designSystem/containers/navigation/NavigationWrapper';
import { SideMenuItemProps } from 'components/designSystem/containers/navigation/SideBar';
import WithBackground, { WithBackgroundColor } from "components/designSystem/containers/WithBackground";
import { MarginSize, WithBottomMargin } from "components/designSystem/containers/WithMargin";
import { IconName } from 'components/designSystem/foundations/IconsData';
import { KiroLogo } from 'components/designSystem/foundations/logos/KiroLogo';
import SideBarModularContent from "components/modules/modular/SideBarModularContent";
import { setup, TransDomain } from 'components/pages/common/MainComponent';
import PatientSearchBar from "components/pages/common/PatientSearchBar";
import WithTranslations from 'components/pages/common/WithTranslations';
import MobileNavigationHeader from "components/pages/MobileNavigationHeader";
import { formatName } from 'components/utils/names';
import { Action, ResourceType } from "core/logging/ActionEvent";
import LOGGER from "core/logging/Logger";
import { getRoute } from "core/routing/Helper";
import { ROUTE_ACCOUNT, ROUTE_DEFAULT_MODULAR, ROUTE_LEGACY, ROUTE_MODULAR_PAGE } from 'core/routing/Routes';
import { AnyState } from "core/store/Store";
import { cast } from 'core/utils/Typed';
import { GetPageResponse, PageContext, PageDisplayLayoutType } from 'models/modular/api';
import {
    Navigation,
    PageLinkType,
    SideBarMenu,
    SideBarMenuActionLegacy,
    SideBarMenuActionModularPage
} from 'models/user/NavigationModels';
import { LocalUserData, UserMode } from 'models/user/UserModels';
import React, { Fragment, ReactNode } from 'react';
import MediaQuery from "react-responsive";
import { REDUCER_MODULAR, REDUCER_USER_DATA } from "reducers/allReducers";
import { ReducerInterface } from "reducers/modular/ModularReducer";
import { reducer } from "reducers/selector";
import { UserDataReducerInterface } from "reducers/user/UserDataReducers";

export interface WithNavigationProps {
    withTintedBackground?: boolean
    hideFooter?: boolean
}

export interface WithNavigationStateProps {
    userData?: LocalUserData | null;
    navigation?: Navigation
    currentPage?: GetPageResponse

}

export interface WithNavigationDispatchProps {
    disconnect: (toNewLogin: boolean) => void;
    redirectToDashboard: () => void;
    redirectToMyAccount: () => void;
    retrieveUserData: () => void;
    getNavigation: () => void;
    redirectToPage: (pageId: string, context?: PageContext) => void;
    redirectToLegacy: (pageId: string) => void;
}

interface WithNavigationState {
    sideExpanded: boolean;
}

class WithNavigation extends WithTranslations<WithNavigationProps & WithNavigationStateProps & WithNavigationDispatchProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    state: WithNavigationState = {
        sideExpanded: false
    }

    componentDidMount(): void {
        if (!this.props.userData) {
            this.props.retrieveUserData()
            return
        } else if (!this.props.navigation) {
            this.props.getNavigation()
        }
    }

    componentDidUpdate(prevProps: Readonly<WithNavigationStateProps & WithNavigationDispatchProps>): void {
        if (!this.props.userData) {
            this.props.retrieveUserData()
            return
        }

        if ((!prevProps.userData && this.props.userData) || (
                prevProps.userData && this.props.userData && (
                    prevProps.userData.chosenUserMode != this.props.userData.chosenUserMode ||
                    prevProps.userData.uuid != this.props.userData.uuid
                )
            )
        ) {
            this.props.getNavigation()
        }
    }

    isSelected(menu: SideBarMenu): boolean {
        const legacyName = window.location.pathname && window.location.pathname.startsWith(ROUTE_LEGACY) ?
            window.location.pathname.substr(ROUTE_LEGACY.length) : undefined

        if (!legacyName && menu.action.type === PageLinkType.MODULAR_PAGE) {
            if (!this.props.currentPage) {
                return false
            }

            return cast<SideBarMenuActionModularPage>(menu.action.payload).page_id === this.props.currentPage.page_id
        }


        if (legacyName && menu.action.type === PageLinkType.LEGACY_PAGE) {
            if (!menu.action.payload) {
                return false
            }

            return cast<SideBarMenuActionModularPage>(menu.action.payload).page_id === legacyName
        }
        return false
    }

    onClickWrapper(method: () => void): () => void {
        return (): void => {
            this.setState(
                {
                    ...this.state,
                    sideExpanded: false
                }
            )
            method()
        }
    }

    getOnClick(menu: SideBarMenu): () => void {
        if (menu.action.type === PageLinkType.MODULAR_PAGE) {
            const payload = cast<SideBarMenuActionModularPage>(menu.action.payload);
            return (): void => {
                LOGGER.userAction({
                    action: Action.CLICK_NAV_BAR_MENU,
                    resource_id: payload.page_id,
                    resource_type: ResourceType.PAGE_ID,
                    sub_resource_id: undefined,
                    sub_resource_type: undefined,
                })
                this.props.redirectToPage(payload.page_id, payload.context)
            }
        }

        if (menu.action.type === PageLinkType.LEGACY_PAGE) {
            const payload = cast<SideBarMenuActionLegacy>(menu.action.payload);
            return (): void => this.props.redirectToLegacy(payload.page_id)
        }

        return (): void => {
            return;
        }
    }

    buildProfileItem(userData?: LocalUserData | null, withName?: boolean): ReactNode {
        return <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
            <div>
                {
                    this.props.navigation?.navbar?.with_account && userData && userData.uuid ?
                        <DropDown
                            triggerBuilder={
                                (onClick: () => void, isOpen): JSX.Element => (
                                    <ProfileMenu
                                        active={isOpen}
                                        onClick={onClick}
                                        name={withName ? formatName(userData.firstName, userData.lastName) : undefined}
                                        avatarPayload={
                                            {
                                                type: AvatarType.INITIALS,
                                                initials: (userData.firstName ? userData.firstName[0] : "") +
                                                    (userData.lastName ? userData.lastName[0] : "")
                                            }
                                        }
                                    />)
                            }
                            closeOnClick
                        >
                            <DropDownItem
                                label={this.trans('my_account')}
                                payload={
                                    {
                                        type: DropDownType.ACTION,
                                        icon: IconName.USER,
                                        onClick: (): void => this.props.redirectToPage('account')
                                    }
                                }
                            />
                            <DropDownItem
                                label={this.trans('disconnect')}
                                payload={
                                    {
                                        type: DropDownType.ACTION,
                                        icon: IconName.LOGOUT,
                                        onClick: (): void => this.props.disconnect(userData && userData.chosenUserMode === UserMode.PATIENT),
                                        severity: DropDownItemSeverity.CRITICAL
                                    }
                                }
                            />
                        </DropDown>
                        :
                        ""
                }
            </div>
        </AlwaysInline>
    }

    buildKiroLogo(onMobile: boolean): React.ReactNode {
        const onClick = this.getHomeLink();
        return <AlwaysInline alignItems={InlineAlignItem.CENTER} onClick={onClick}>
            <KiroLogo small={onMobile}/>
        </AlwaysInline>
    }


    private getHomeLink(): (() => void) | undefined {
        const home = this.props.navigation?.home;
        // Apply home link if defined
        return home ? (): void => this.props.redirectToPage(home.page, home.context) : undefined
    }

    render(): ReactNode {
        const userData = this.props.userData

        if (userData === undefined) {
            return <MyLoader/>;
        }
        const backLink = this.getBackLink();

        const immersive = this.props.currentPage?.display_data.layout_type == PageDisplayLayoutType.FULL;
        const content = this.props.hideFooter ?
            <NavigationContent immersive={immersive}>
                {this.props.children}
            </NavigationContent>
            :
            <WithFooter>
                <NavigationContent immersive={immersive}>
                    {
                        backLink &&
                        <MediaQuery minWidth={MOBILE_MAX_WIDTH + 1}>
                            {(isDesktop): JSX.Element => <WithBottomMargin margin={MarginSize.MEDIUM}>
                                <Button
                                    size={isDesktop ? ButtonSize.MEDIUM : ButtonSize.EXTRA_SMALL}
                                    variant={ButtonVariant.QUATERNARY}
                                    icons={{left: IconName.CHEVRON_LEFT}}
                                    onClick={backLink}
                                >
                                    {this.trans('return_button.default', undefined, 'navigation')}
                                </Button>
                            </WithBottomMargin>}
                        </MediaQuery>
                    }
                    {this.props.children}
                </NavigationContent>
            </WithFooter>

        const isLegacyPage = window.location.pathname && window.location.pathname.startsWith(ROUTE_LEGACY)

        return <Fragment>
            <NavigationWrapper
                navBarProps={
                    {
                        sticky: true,
                        content: (
                            <AlwaysInline justifyContent={InlineJustifyContent.SPACE_BETWEEN}>
                                {this.buildKiroLogo(false)}
                                {this.props.navigation?.navbar.with_search && <PatientSearchBar/>}
                                {this.buildProfileItem(userData, true)}
                            </AlwaysInline>
                        ),
                        mobileContent: (
                            <MobileNavigationHeader
                                onClickSideBar={
                                    this.props.navigation && this.props.navigation.sidebar ?
                                        (): void => {this.setState({sideExpanded: !this.state.sideExpanded})} :
                                        undefined
                                }
                                withSearchPatient={this.props.navigation?.navbar.with_search}
                                buildLogo={(): ReactNode => this.buildKiroLogo(true)}
                                buildProfileItem={():ReactNode => this.buildProfileItem(userData, false)}
                            />
                        )
                    }
                }

                sideBarProps={
                    (this.props.navigation && !immersive && this.props.navigation.sidebar) ? {
                        sticky: true,
                        menuItems: this.props.navigation.sidebar.menus.map<SideMenuItemProps>(
                            (menu) => ({
                                icon: menu.icon,
                                name: <TrText input={menu.name}/>,
                                onClick: this.onClickWrapper(this.getOnClick(menu)),
                                isSelected: this.isSelected(menu)
                            })
                        ),
                        forceExpand: this.state.sideExpanded,
                        clickOutside: (): void => this.setState(
                            {sideExpanded: false}
                        ),
                        children: !isLegacyPage ? <SideBarModularContent/> : <Fragment/>
                    } : undefined
                }
            >
                {
                    this.props.withTintedBackground ?
                        <WithBackground color={WithBackgroundColor.TINTED} disabledWhenPrinting>
                            {content}
                        </WithBackground>
                        :
                        content
                }
            </NavigationWrapper>
        </Fragment>
    }

    private getBackLink(): (() => void) | undefined | ButtonExternalLink {
        const currentPage = this.props.currentPage;
        if (currentPage == null) {
            return undefined
        }
        if (!currentPage.display_data.with_back_button) {
            return undefined
        }
        const pageContext = currentPage.context;
        if (pageContext != null) {
            const originPage = pageContext['origin_page'];
            if (originPage != null) {
                let params = {pageId: originPage};
                const originContext = pageContext['origin_context']
                if (originContext) {
                    params = {...params, ...JSON.parse(originContext)}
                }
                return {
                    href: getRoute(ROUTE_MODULAR_PAGE, params),
                    targetBlank: false
                };
            }
        }
        return this.getHomeLink();
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): WithNavigationDispatchProps => ({
    disconnect: (toNewLogin: boolean): void => {
        dispatch(signOut(null, toNewLogin));
    },
    redirectToDashboard: (): void => {
        dispatch(redirect(ROUTE_DEFAULT_MODULAR));
    },
    redirectToMyAccount: (): void => {
        dispatch(redirect(ROUTE_ACCOUNT));
    },
    retrieveUserData: (): void => {
        dispatch(retrieveUserData(true));
    },
    getNavigation: (): void => {
        dispatch(getNavigationContent())
    },
    redirectToPage: (pageId: string, context?: PageContext): void => {
        dispatch(redirect(
            getRoute(ROUTE_MODULAR_PAGE, {pageId}),
            context ? context : {}
        ));
    },
    redirectToLegacy: (pageId): void => {
        dispatch(redirect(ROUTE_LEGACY + pageId));
    },
});


const mapStateToProps = (state: AnyState): WithNavigationStateProps => ({
    userData: reducer<UserDataReducerInterface>(state, REDUCER_USER_DATA).userData,
    navigation: reducer<UserDataReducerInterface>(state, REDUCER_USER_DATA).navigation,
    currentPage: reducer<ReducerInterface>(state, REDUCER_MODULAR).currentPage,
});

export default setup(WithNavigation, mapStateToProps, mapDispatchToProps);
