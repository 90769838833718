import React, { FC } from 'react';
import styles from 'components/designSystem/components/ProfileMenu.module.scss';
import Avatar, { AvatarPayload } from 'components/designSystem/components/Avatar';
import classNames from 'classnames';
import { AlwaysInline, InlineAlignItem, InlineSpacing } from 'components/designSystem/containers/Inline';
import { TBody } from 'components/designSystem/containers/Typography';

export interface ProfileMenuProps {
    avatarPayload: AvatarPayload;
    onClick?: () => void;
    name?: string;
    active?: boolean
}

const ProfileMenu : FC<ProfileMenuProps> = (
    props
):JSX.Element => {

    return <div
        tabIndex={props.onClick ? -1 : undefined}
        className={classNames(
            styles.profileMenu,
            {
                [styles.profileMenuClickable]: !!props.onClick,
                [styles.profileMenuActive]: props.active
            },
        )}
        onClick={props.onClick}>
        <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
            {
                props.name &&
                <TBody>{props.name}</TBody>
            }
            <Avatar
                payload={props.avatarPayload}
            />
        </AlwaysInline>
    </div>
}

export default ProfileMenu;