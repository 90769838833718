import React, { ReactNode } from 'react';

import {
    CallToAction,
    CallToActionLinkPayload,
    CallToActionPayload,
    CallToActionType
} from 'models/modular/communication';
import { cast } from 'core/utils/Typed';
import { ButtonType, RedirectButton } from 'components/legacyDesignSystem/components/Button';
import TrText from 'components/common/TrText';
import { CallToAction as AlertCallToAction } from 'components/designSystem/components/Alert'

export function buildLinkCTA(
    payload: CallToActionLinkPayload,
    onDarkBackground?: boolean,
): ReactNode {
    return <RedirectButton
        redirectUrl={payload.url}
        customType={ButtonType.SECONDARY}
        onDarkBackground={onDarkBackground}
    >
        {payload.name ? <TrText input={payload.name} />  : payload.url}
    </RedirectButton>
}

export function buildCTA(cta: CallToAction, onDarkBackground?: boolean): ReactNode {
    switch (cta.type) {
        case CallToActionType.LINK:
            return buildLinkCTA(
                cast<CallToActionLinkPayload>(cta.payload),
                onDarkBackground,
            )
    }
}

export function getLinkInfo(
    payload: CallToActionPayload
): AlertCallToAction {
    return {
        actionName: payload.name ? <TrText input={payload.name} />  : payload.url,
        onClick: {
            href: payload.url,
            targetBlank: true
        }
    }
}

export function getButtonInfo(cta: CallToAction): AlertCallToAction {
    switch (cta.type) {
        case CallToActionType.LINK:
            return getLinkInfo(
                cast<CallToActionLinkPayload>(cta.payload),
            )
    }
}