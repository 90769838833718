export const LABORATORY_UUID_UNILABS = '0014177a-005f-44a8-ad59-140b33666e60'

export enum EntityType {
    PARTNER_CLIENT = 'PARTNER_CLIENT',
    LABORATORY = 'LABORATORY',
}

export interface Entity {
    id: string
    type: EntityType,
    name?: string
}