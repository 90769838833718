import { ModuleAPICallAction } from 'actions/modular/ModularActions';

export const API_DEMO_MODULE = 'API_DEMO_MODULE';


export type DemoPayload = {
    value: string
}

export const demo = (
    identifier: string,
    value: string,
): ModuleAPICallAction<DemoPayload> => {
    return {
        type: API_DEMO_MODULE,
        payload: {
            params: {
                value: value,
            },
            moduleIdentifier: identifier
        },
    };
};
