import { RawColors } from 'components/legacyDesignSystem/branding/constants';
import React from 'react';
import { Area } from 'recharts';

export enum AreaType {
    NORMALITY = 'NORMALITY'
}

export function buildArea(datatKey: string): JSX.Element {
    return  <Area
        key={"area" + datatKey}
        type="monotone"
        dataKey={datatKey}
        stroke={'none'}
        fill={RawColors.SECONDARY_LIGHT_BLUE_GREEN}
        fillOpacity={0.8}
    />
}