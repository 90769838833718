import Avatar, { AvatarSize, AvatarType } from "components/designSystem/components/Avatar";
import Divider, { DividerOrientation, DividerVariant } from "components/designSystem/components/Divider";
import Inline, {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import { MediumSpacer, SmallSpacer } from "components/designSystem/containers/Spacer";
import Typography, {
    TOverline,
    TypographyColor,
    TypographyVariant
} from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize, WithTopMargin } from "components/designSystem/containers/WithMargin";
import { TransDomain } from 'components/pages/common/MainComponent';

import WithTranslations from 'components/pages/common/WithTranslations';
import { formatDate } from "core/utils/Date";
import { capitalizeFirstLetter } from "core/utils/text";
import { PatientData, ReportTag } from "models/doctor/DashboardModels";
import React from 'react';


export interface DoctorReportsListProps {
    rows: DoctorReportsListRow[];
    onRowClick: (r: DoctorReportsListRow) => void;
    withoutHeader?: boolean;
}

export interface DoctorReportsListRow {
    uuid: string;
    receptionDate: Date;
    patient: PatientData;
    tags: ReportTag[];
    isNew?: boolean;
}

class DoctorReportsList extends WithTranslations<DoctorReportsListProps> {
    TRANS_SUFFIX = TransDomain.MEDICAL_REPORT;

    fontDivToUse = (text: string, isNew?: boolean): JSX.Element => {
        return <Typography
            variant={isNew ? TypographyVariant.CAPTION_700 : TypographyVariant.CAPTION}
            color={TypographyColor.COLOR_TEXT_DEFAULT}
        >
            {text}
        </Typography>
    }

    render(): JSX.Element {
        return <WithTopMargin margin={MarginSize.MEDIUM}>
            {
                this.props.rows.map((r: DoctorReportsListRow, k: number) => {
                    return <div key={r.uuid} id={`report-list-row-${r.uuid}`} onClick={(): void => {
                        this.props.onRowClick(r)
                    }}>
                        <WithMargin margins={[MarginSize.MEDIUM]}>
                            <MediumSpacer>
                                <SmallSpacer>
                                    <AlwaysInline alignItems={InlineAlignItem.CENTER}
                                                  justifyContent={InlineJustifyContent.SPACE_BETWEEN}
                                    >
                                        <AlwaysInline spacing={InlineSpacing.SMALL} alignItems={InlineAlignItem.CENTER}>
                                            {this.fontDivToUse(r.patient.first_name + ' ' + r.patient.last_name, r.isNew)}
                                            <TOverline>
                                                {this.trans('born', undefined, undefined, capitalizeFirstLetter)}
                                                {formatDate(r.patient.birth_date, 'D MMM YYYY')}
                                            </TOverline>
                                        </AlwaysInline>
                                    </AlwaysInline>
                                    <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.X_SMALL}>
                                        {this.fontDivToUse(this.trans('reception_date', undefined, undefined, capitalizeFirstLetter), r.isNew)}
                                        {this.fontDivToUse(formatDate(r.receptionDate, 'D MMM YYYY - HH[h]mm'), r.isNew)}
                                    </AlwaysInline>
                                </SmallSpacer>
                                {
                                    r.tags.length > 0 && r.tags[0].viewer_name && <SmallSpacer>
                                        <Inline>
                                            <TOverline>
                                                {this.trans('accessible_by', undefined, undefined, capitalizeFirstLetter)}
                                            </TOverline>
                                        </Inline>
                                        <AlwaysInline spacing={InlineSpacing.SMALL} alignItems={InlineAlignItem.CENTER}>
                                            <Avatar
                                                size={AvatarSize.SMALL}
                                                payload={{
                                                    type: AvatarType.INITIALS,
                                                    initials: r.tags[0].viewer_name.slice(0, 2),
                                                }}
                                            />
                                            {
                                                this.fontDivToUse(r.tags[0].viewer_name, r.isNew)
                                            }
                                        </AlwaysInline>
                                    </SmallSpacer>
                                }
                                {
                                    k + 1 != this.props.rows.length && <Divider
                                        orientation={DividerOrientation.HORIZONTAL} variant={DividerVariant.DEFAULT}
                                    />
                                }
                            </MediumSpacer>
                        </WithMargin>
                    </div>
                })
            }
        </WithTopMargin>
    }

}

export default DoctorReportsList;