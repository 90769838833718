import TrText from "components/common/TrText";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import Inline, { AlwaysInline, InlineAlignItem, InlineSpacing } from "components/designSystem/containers/Inline";
import { TBody, TBody500, TypographyColor } from "components/designSystem/containers/Typography";
import { MarginSize, WithTopMargin } from "components/designSystem/containers/WithMargin";
import WithPadding, { PaddingSize } from "components/designSystem/containers/WithPadding";
import ResourcesList from "components/modules/modular/modules/report/content/RessourcesList";
import { APIText } from "models/common/message";
import { ModuleReportResultSource } from "models/modular/report";
import { ExternalLink } from "models/modular/teleconsultation";
import React from 'react';

export interface ResultInformationLink {
    name?: APIText;
    link: ExternalLink;
}

export type Props = {
    name?: APIText,
    description: APIText,
    links?: ResultInformationLink[],
    global_sources?: ModuleReportResultSource[];
    match: boolean,
}

const ReportInterpretationListItem = (props: Props): JSX.Element => {
    const { match, name, description, links, global_sources,  } = props;
    const boxColor = match ? BoxColor.LAVENDERWEB : BoxColor.WHITE;
    const textColor = match ? TypographyColor.COLOR_TEXT_DEFAULT : TypographyColor.COLOR_TEXT_SUBDUED;

    return (
        <AlwaysInline spacing={InlineSpacing.SMALL} alignItems={InlineAlignItem.START}>
            <Box withRadius background={boxColor} withFullHeight withFullWidth>
                <WithPadding paddings={[PaddingSize.SMALL, PaddingSize.SMALL]}>
                    {name &&
                        <TBody500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                            <TrText input={name}/>
                        </TBody500>
                    }
                    <Inline>
                        {description &&
                            <TBody color={textColor}>
                                <TrText input={description}/>
                            </TBody>
                        }
                    </Inline>
                    {(links && links.length > 0) &&
                        <WithTopMargin margin={MarginSize.MEDIUM}>
                            <ResourcesList sources={links}/>
                        </WithTopMargin>
                    }
                    {(match && global_sources && global_sources.length > 0) &&
                        <WithTopMargin margin={MarginSize.MEDIUM}>
                            <ResourcesList sources={global_sources}/>
                        </WithTopMargin>
                    }
                </WithPadding>
            </Box>
        </AlwaysInline>
    );
};

export default ReportInterpretationListItem;
