import React from 'react';

const Cake = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <g clipPath="url(#clip0_1510_15899)">
                <path d="M25.3359 22.2534C25.209 22.3282 25.0754 22.3898 24.9375 22.4385V29C25.4769 28.8487 26.3048 28.5476 27.0719 27.917C28.3513 26.8653 29 25.3226 29 23.3316V18.5434C28.7111 19.0154 28.3826 19.4715 28.0149 19.9085C26.7975 21.3553 25.5696 22.1157 25.3359 22.2534Z" />
                <path d="M21.1289 22.5699C21.1289 23.0453 21.0193 23.4955 20.8242 23.8969L23.414 24.7988V22.4392L21.1289 21.6434V22.5699Z" />
                <path d="M15.0352 19.5212L3 15.3298V17.6894L15.0352 21.8808V19.5212Z" />
                <path d="M18.082 25.6167C16.7451 25.6167 15.6074 24.7508 15.1981 23.5507L3 19.3026V21.3003C3 21.6244 3.20511 21.913 3.51116 22.0196L23.4141 28.951V26.412L19.7316 25.1295C19.2558 25.4372 18.6896 25.6167 18.082 25.6167Z" />
                <path d="M11.7344 9.34766C11.7344 11.1677 13.2151 12.6484 15.0352 12.6484C16.8552 12.6484 18.3359 11.1677 18.3359 9.34766C18.3359 7.76923 17.222 6.44678 15.7392 6.12325C15.5261 4.86301 14.7312 3.77396 13.5589 3.18779L13.3445 3.08059C12.9684 2.8925 12.5108 3.04499 12.3226 3.42123C12.1344 3.79747 12.2869 4.25506 12.6632 4.44315L12.8777 4.55035C13.5383 4.88074 14.0066 5.46736 14.1889 6.15733C12.7776 6.53199 11.7344 7.82006 11.7344 9.34766Z" />
                <path d="M29 13.1753C29 12.965 28.9496 7.99495 24.5625 5.40973C24.3693 5.29587 24.1358 5.27297 23.9242 5.34701C23.284 5.57106 21.5041 6.19099 19.2371 6.98008C19.633 7.67995 19.8594 8.48783 19.8594 9.34766C19.8594 12.0077 17.6952 14.1719 15.0352 14.1719C12.6313 14.1719 10.6327 12.4045 10.2699 10.101C7.15446 11.1855 4.50439 12.1084 3.5101 12.4564C3.20434 12.5634 2.99975 12.852 3 13.176C3.00025 13.4998 3.20531 13.7882 3.51116 13.8947L16.5586 18.4385V22.5698C16.5586 23.4112 17.2406 24.0933 18.082 24.0933C18.9234 24.0933 19.6055 23.4112 19.6055 22.5698V19.4996L23.9252 21.004C24.0067 21.0324 24.0913 21.0464 24.1757 21.0464C24.3102 21.0464 24.444 21.0107 24.5625 20.9409C24.7437 20.8342 29 18.2674 29 13.1753Z" />
            </g>
            <defs>
                <clipPath id="clip0_1510_15899">
                    <rect width="26" height="26" fill="white" transform="translate(3 3)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Cake;
