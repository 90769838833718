import { ColorMap } from "components/designSystem/Aliases";
import { RawColors } from 'components/legacyDesignSystem/branding/constants';

function transformColor(color: ColorMap): RawColors {
    // TO be removed by proper css classes once charts are properly moved to design system
    switch (color) {
        case ColorMap.BLUE:
            return RawColors.SECONDARY_NAVY_BLUE
        case ColorMap.RED:
            return RawColors.SEMANTIC_RED
        case ColorMap.ORANGE:
            return RawColors.SEMANTIC_ORANGE
        case ColorMap.YELLOW:
            return RawColors.SEMANTIC_YELLOW
        case ColorMap.DARK_ORANGE:
            return RawColors.SEMANTIC_ORANGE
        case ColorMap.PURPLE:
            return RawColors.SECONDARY_PURPLE
        case ColorMap.GREEN:
            return RawColors.SEMANTIC_GREEN
        default:
            return RawColors.NEUTRALS_DARK_BLUE_GREY
    }
}

export function getColor(color: ColorMap, isIgnored = false): string {
    return transformColor(
        color
    ) + (isIgnored ? '10' : '')
}

