import React, { Component, PropsWithChildren, ReactNode } from 'react';
import './Box.scss';
import { getOrDefault } from 'core/utils/Getters';
import ArrowExpander, { ArrowDirection } from 'core/content/icons/ArrowExpander';
import Title, { TitleType } from 'components/core/text/Title';

export interface BoxProps {
    title?: ReactNode;
    icon?: string;
    onClick?: () => void;
    clicked?: boolean;
    iconTitle?: React.FunctionComponent;
}

export default class Box extends Component<PropsWithChildren<BoxProps>> {
    render(): ReactNode {
        // properly default
        const onClick = getOrDefault(this.props.onClick, (): void => {
            return;
        });
        const clickable = this.props.onClick !== undefined;

        return (
            <div
                onClick={(): void => {
                    onClick();
                }}
                className={'box ' + (clickable ? 'box-clickable ' : '')}
            >
                <Title type={TitleType.HEADING2}>
                    {this.props.iconTitle ? this.props.iconTitle({}) : ''} {this.props.title}
                </Title>
                <div className="box-content">{this.props.children}</div>
                {clickable && (
                    <div className={'box-arrow-container'}>
                        <ArrowExpander direction={this.props.clicked ? ArrowDirection.UP : ArrowDirection.DOWN} />
                    </div>
                )}
            </div>
        );
    }
}
