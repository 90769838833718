import React from 'react';

const UnknownGenderIcon = (): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_933_4193)">
                <path d="M16.8149 9.4137L16.8149 6.44259L12.305 6.44259L12.305 9.38185C7.07353 10.3912 3 15.0306 3 20.5878C3 26.8805 8.17558 32 14.5187 32C20.862 32 26 26.8806 26 20.5878C26 15.0907 22.0464 10.4896 16.8149 9.4137ZM14.5338 27.5258C10.6775 27.5258 7.54004 24.4133 7.54004 20.5877C7.54004 16.7618 10.6775 13.6493 14.5338 13.6493C18.3903 13.6493 21.5278 16.7618 21.5278 20.5877C21.5276 24.4135 18.3903 27.5258 14.5338 27.5258Z" fill="currentColor"/>
                <circle cx="14.5" cy="20.5" r="8.5" fill="currentColor" fillOpacity={0.5}/>
                <path d="M18.5081 8.76322L16.1353 7.34314L14.5007 6.48056L12.8661 7.34313L10.4933 8.76322C10.3105 8.87284 10.1073 8.9464 9.89526 8.97968C9.68322 9.01295 9.46651 9.00531 9.25751 8.95716C9.04851 8.90903 8.85133 8.82134 8.67724 8.69912C8.50314 8.5769 8.35554 8.42254 8.24289 8.24487C8.13023 8.0672 8.05472 7.8697 8.02067 7.66366C7.98662 7.45762 7.9947 7.24708 8.04445 7.04407C8.09421 6.84107 8.18465 6.64958 8.31062 6.48056C8.43659 6.31154 8.59561 6.16829 8.77859 6.05901L11.3841 4.4996L8.77859 2.94019C8.40949 2.71928 8.14583 2.36496 8.04561 1.95519C7.9454 1.54541 8.01685 1.11374 8.24423 0.755135C8.47161 0.396534 8.83631 0.140378 9.25809 0.0430169C9.67987 -0.0543439 10.1242 0.0150666 10.4933 0.235979L14.5007 2.63441L18.5081 0.235979C18.8771 0.0159031 19.321 -0.0528881 19.7423 0.0447082C20.1635 0.142305 20.5277 0.398311 20.7548 0.756524C20.9819 1.11474 21.0535 1.54588 20.9537 1.95529C20.854 2.3647 20.5911 2.71893 20.2227 2.94019L17.6172 4.4996L20.2228 6.05901C20.4055 6.16839 20.5643 6.31168 20.6901 6.48069C20.8158 6.6497 20.9061 6.84111 20.9557 7.04402C21.0053 7.24692 21.0133 7.45733 20.9793 7.66323C20.9452 7.86914 20.8697 8.0665 20.7571 8.24407C20.6445 8.42163 20.497 8.5759 20.3231 8.69809C20.1491 8.82028 19.9521 8.90798 19.7433 8.95618C19.5344 9.00439 19.3178 9.01216 19.1059 8.97905C18.894 8.94595 18.6908 8.87261 18.5081 8.76322Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_933_4193">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>


    )
}

export default UnknownGenderIcon;
