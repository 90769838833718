import { ColorMap } from "components/designSystem/Aliases";
import { RawColors } from 'components/legacyDesignSystem/branding/constants';
import { Bar, Cell, RectangleProps } from 'recharts';
import React, { ReactElement } from 'react';
import { getColor } from 'components/legacyDesignSystem/components/charts/builders/utils';

const Y_OFFSET = 5;
const roundedBar = (props: RectangleProps): ReactElement<SVGElement> => {
    const { fill, x, y, width, height } = props;
    return (
        <rect
            x={x}
            y={y ? y-Y_OFFSET : undefined}
            width={width ? Math.min(width, 20) : undefined}
            height={height ? (height > Y_OFFSET ? Math.max(Y_OFFSET, height - Y_OFFSET) : height) : undefined}
            rx="10"
            fill={fill}
        />
    );
};

const Background = (props: RectangleProps): JSX.Element => {
    const { fill, x, y, width, height } = props;
    return <rect x={x} y={y} width={width} height={height} rx="6" fill={fill} />;
};

export function buildSimpleBar(
    dataKey: string,
    name: string,
    color: ColorMap,
    withBackground = false
): JSX.Element {

    return (
        <Bar
            maxBarSize={24}
            key={'line' + dataKey}
            dataKey={dataKey}
            name={name}
            fill={getColor(color)}
            radius={6}
            background={
                withBackground ? <Background fill={RawColors.NEUTRALS_LIGHT_BLUE_GREY} /> : { fill: 'transparent' }
            }
        >
            <Cell fill={getColor(color, false)} />
        </Bar>
    );
}

export function buildBar(
    dataKey: string,
    stackId: string,
    color: ColorMap,
    data: Record<string, unknown>[],
    isInactive?: (entry: Record<string, unknown>) => boolean,
    withBackground = false,
): JSX.Element {
    return (
        <Bar
            key={'line' + dataKey}
            dataKey={dataKey}
            stackId={stackId}
            fill={getColor(color)}
            shape={roundedBar}
            maxBarSize={20}

            background={
                withBackground ? <Background fill={RawColors.NEUTRALS_LIGHT_BLUE_GREY} /> : { fill: 'transparent' }
            }
        >
            {data.map((entry, index): JSX.Element => {
                return <Cell key={index} fill={getColor(color, isInactive ? isInactive(entry) : false)} />;
            })}
        </Bar>
    );
}

// TODO: Factorize this two methods ↓ ↓
/* export function buildBar(
    dataKey: string,
    stackId: string,
    color: ColorMap,
    data: Record<string, unknown>[],
    isInactive?: (entry: Record<string, unknown>) => boolean,
): JSX.Element {
    return (
        <Bar key={'line' + dataKey} dataKey={dataKey} stackId={stackId} fill={getColor(color)} shape={roundedBar}>
            {data.map((entry, index): JSX.Element => {
                return <Cell key={index} fill={getColor(color, isInactive ? isInactive(entry) : false)} />;
            })}
        </Bar>
    );
}

export function buildSimpleBar(dataKey: string, color: ColorMap, withBackground = false): JSX.Element {
    return (
        <Bar
            key={'line' + dataKey}
            dataKey={dataKey}
            fill={getColor(color)}
            radius={6}
            background={
                withBackground ? <Background fill={RawColors.NEUTRALS_LIGHT_BLUE_GREY} /> : { fill: 'transparent' }
            }
            maxBarSize={24}
        >
            <Cell fill={getColor(color, false)} />
        </Bar>
    );
} */
