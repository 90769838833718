import React from "react";
import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";


export const Star = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.EXTRA_SMALL:
            return (
                <svg id={'s-star'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_121_362)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.00002 0.25C8.2908 0.25 8.55537 0.418078 8.67894 0.6813L10.6721 4.92721L15.114 5.60991C15.3917 5.65261 15.6223 5.84701 15.7114 6.11356C15.8004 6.38012 15.733 6.67408 15.5366 6.87517L12.3012 10.1889L13.0662 14.8793C13.1125 15.1628 12.9927 15.4477 12.7579 15.6132C12.5231 15.7786 12.2144 15.7953 11.9631 15.6563L8.00002 13.4648L4.03696 15.6563C3.7856 15.7953 3.47694 15.7786 3.24211 15.6132C3.00729 15.4477 2.88756 15.1628 2.9338 14.8793L3.69884 10.1889L0.463391 6.87517C0.267057 6.67408 0.199616 6.38012 0.288666 6.11356C0.377715 5.84701 0.608311 5.65261 0.886084 5.60991L5.32797 4.92721L7.3211 0.6813C7.44466 0.418078 7.70924 0.25 8.00002 0.25ZM8.00002 2.76498L6.51594 5.92648C6.40976 6.15267 6.19792 6.31112 5.95095 6.34908L2.55567 6.87092L5.03665 9.4119C5.20301 9.58229 5.27857 9.82156 5.24024 10.0566L4.66291 13.5961L7.63708 11.9514C7.86292 11.8266 8.13712 11.8266 8.36296 11.9514L11.3371 13.5961L10.7598 10.0566C10.7215 9.82156 10.797 9.58229 10.9634 9.4119L13.4444 6.87092L10.0491 6.34908C9.80212 6.31112 9.59028 6.15267 9.4841 5.92648L8.00002 2.76498Z"
                              fill="currentColor"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_121_362">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            )
        case IconSize.MEDIUM:
            return (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_317_119)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M16 0.75C16.4847 0.75 16.9256 1.03013 17.1316 1.46883L21.1744 10.0813L30.1899 11.4669C30.6529 11.5381 31.0372 11.8621 31.1856 12.3063C31.334 12.7506 31.2216 13.2405 30.8944 13.5757L24.3353 20.2934L25.8857 29.7988C25.9628 30.2712 25.7632 30.7462 25.3719 31.0219C24.9805 31.2976 24.4661 31.3256 24.0471 31.0939L16 26.644L7.95294 31.0939C7.534 31.3256 7.01956 31.2976 6.62819 31.0219C6.23682 30.7462 6.03727 30.2712 6.11433 29.7988L7.66473 20.2934L1.10565 13.5757C0.778429 13.2405 0.666026 12.7506 0.814443 12.3063C0.962859 11.8621 1.34719 11.5381 1.81014 11.4669L10.8256 10.0813L14.8685 1.46883C15.0744 1.03013 15.5154 0.75 16 0.75ZM16 4.94164L12.8056 11.7467C12.6286 12.1237 12.2755 12.3878 11.8639 12.4511L4.59278 13.5686L9.89441 18.9985C10.1717 19.2824 10.2976 19.6812 10.2337 20.0729L8.99619 27.6602L15.3951 24.1217C15.7715 23.9135 16.2285 23.9135 16.6049 24.1217L23.0039 27.6602L21.7663 20.0729C21.7024 19.6812 21.8284 19.2824 22.1056 18.9985L27.4073 13.5686L20.1361 12.4511C19.7245 12.3878 19.3715 12.1237 19.1945 11.7467L16 4.94164Z"
                              fill="currentColor"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_317_119">
                            <rect width="32" height="32" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            )
        case IconSize.EXTRA_EXTRA_LARGE:
            return (
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_121_364)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M24 0C24.7755 0 25.481 0.448209 25.8105 1.15013L32.1555 14.6668L46.3039 16.8413C47.0446 16.9551 47.6595 17.4735 47.897 18.1844C48.1345 18.8952 47.9546 19.6791 47.4311 20.2153L37.1365 30.7588L39.57 45.678C39.6933 46.434 39.374 47.194 38.7478 47.6351C38.1216 48.0762 37.2985 48.1209 36.6282 47.7502L24 40.767L11.3719 47.7502C10.7016 48.1209 9.8785 48.0762 9.2523 47.6351C8.62611 47.194 8.30683 46.434 8.43014 45.678L10.8636 30.7588L0.569042 20.2153C0.0454862 19.6791 -0.134358 18.8952 0.103108 18.1844C0.340575 17.4735 0.955497 16.9551 1.69623 16.8413L15.8446 14.6668L22.1896 1.15013C22.5191 0.448209 23.2246 0 24 0ZM24 6.70662L19.0125 17.3315C18.7294 17.9346 18.1644 18.3572 17.5059 18.4584L6.14845 20.204L14.4311 28.6869C14.8747 29.1413 15.0762 29.7793 14.974 30.4061L13.0411 42.2563L23.0322 36.7314C23.6345 36.3983 24.3656 36.3983 24.9679 36.7314L34.959 42.2563L33.0261 30.4061C32.9239 29.7793 33.1254 29.1413 33.569 28.6869L41.8517 20.204L30.4942 18.4584C29.8357 18.3572 29.2707 17.9346 28.9876 17.3315L24 6.70662Z"
                              fill="currentColor"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_121_364">
                            <rect width="48" height="48" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            )
    }

    return (
        <svg id={'s-star'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_121_363)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12 0C12.3877 0 12.7405 0.224105 12.9052 0.575066L16.0778 7.33338L23.1519 8.42065C23.5223 8.47757 23.8298 8.73677 23.9485 9.09218C24.0672 9.44758 23.9773 9.83954 23.7155 10.1077L18.5683 15.3794L19.785 22.839C19.8466 23.217 19.687 23.597 19.3739 23.8175C19.0608 24.0381 18.6492 24.0604 18.3141 23.8751L12 20.3835L5.68595 23.8751C5.3508 24.0604 4.93925 24.0381 4.62615 23.8175C4.31305 23.597 4.15342 23.217 4.21507 22.839L5.43179 15.3794L0.284521 10.1077C0.0227431 9.83954 -0.0671791 9.44758 0.0515541 9.09218C0.170287 8.73677 0.477749 8.47757 0.848113 8.42065L7.92229 7.33338L11.0948 0.575066C11.2596 0.224105 11.6123 0 12 0ZM12 3.35331L9.50625 8.66573C9.36468 8.96731 9.08222 9.17858 8.75294 9.22919L3.07422 10.102L7.21553 14.3435C7.43735 14.5706 7.5381 14.8897 7.48698 15.203L6.52055 21.1282L11.5161 18.3657C11.8172 18.1992 12.1828 18.1992 12.4839 18.3657L17.4795 21.1282L16.5131 15.203C16.462 14.8897 16.5627 14.5706 16.7845 14.3435L20.9258 10.102L15.2471 9.22919C14.9178 9.17858 14.6354 8.96731 14.4938 8.66573L12 3.35331Z"
                      fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_121_363">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}