import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ArrowRightDown = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-arrow-right-down'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.13617 4.13642C4.42907 3.84352 4.90394 3.84352 5.19683 4.13642L11.8635 10.8031C12.1564 11.096 12.1564 11.5709 11.8635 11.8637C11.5706 12.1566 11.0957 12.1566 10.8028 11.8637L4.13617 5.19708C3.84328 4.90418 3.84328 4.42931 4.13617 4.13642Z"
                      fill="currentColor"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.3332 3.91675C11.7474 3.91675 12.0832 4.25253 12.0832 4.66675V11.3334C12.0832 11.7476 11.7474 12.0834 11.3332 12.0834H4.6665C4.25229 12.0834 3.9165 11.7476 3.9165 11.3334C3.9165 10.9192 4.25229 10.5834 4.6665 10.5834H10.5832V4.66675C10.5832 4.25253 10.919 3.91675 11.3332 3.91675Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-arrow-right-down'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17 6C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18H7C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16H16V7C16 6.44772 16.4477 6 17 6Z"
                  fill="currentColor"/>
        </svg>
    )
}