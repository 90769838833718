import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Report = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-report'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M5 1.5C5 0.671573 5.67157 0 6.5 0H9.5C10.3284 0 11 0.671573 11 1.5H11.5C12.8807 1.5 14 2.61929 14 4V13.5C14 14.8807 12.8807 16 11.5 16H4.5C3.11929 16 2 14.8807 2 13.5V4C2 2.61929 3.11929 1.5 4.5 1.5H5ZM9.5 4.5C10.3284 4.5 11 3.82843 11 3H11.5C12.0523 3 12.5 3.44772 12.5 4V13.5C12.5 14.0523 12.0523 14.5 11.5 14.5H4.5C3.94772 14.5 3.5 14.0523 3.5 13.5V4C3.5 3.44772 3.94772 3 4.5 3H5C5 3.82843 5.67157 4.5 6.5 4.5H9.5ZM9.5 1.5V3H6.5V1.5H9.5ZM5.5 6.75C5.08579 6.75 4.75 7.08579 4.75 7.5C4.75 7.91421 5.08579 8.25 5.5 8.25H8C8.41421 8.25 8.75 7.91421 8.75 7.5C8.75 7.08579 8.41421 6.75 8 6.75H5.5ZM5.5 9.25C5.08579 9.25 4.75 9.58579 4.75 10C4.75 10.4142 5.08579 10.75 5.5 10.75H10C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25H5.5Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-report'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7 2C7 0.89543 7.89543 0 9 0H15C16.1046 0 17 0.89543 17 2H19C20.6569 2 22 3.34315 22 5V21C22 22.6569 20.6569 24 19 24H5C3.34315 24 2 22.6569 2 21V5C2 3.34315 3.34315 2 5 2H7ZM15 6C16.1046 6 17 5.10457 17 4H19C19.5523 4 20 4.44772 20 5V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V5C4 4.44772 4.44772 4 5 4H7C7 5.10457 7.89543 6 9 6H15ZM15 2V4H9V2H15ZM7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H12.5C13.0523 12 13.5 11.5523 13.5 11C13.5 10.4477 13.0523 10 12.5 10H7ZM7 14C6.44772 14 6 14.4477 6 15C6 15.5523 6.44772 16 7 16H17C17.5523 16 18 15.5523 18 15C18 14.4477 17.5523 14 17 14H7Z"
                  fill="currentColor"/>
        </svg>
    )
}