import React, { FC } from 'react';
import classNames from "classnames";

import styles from 'components/designSystem/components/Image.module.scss';

export enum ImageObjectFit {
    COVER = 'COVER',
    COVER_TOP = 'COVER_TOP',
    SCALE_DOWN = 'SCALE_DOWN',
    SHRINK_TO_FIT_WIDTH = 'SHRINK_TO_WIDTH',}

export enum ImageHeight {
    /**
     * Height for carousel image.
     */
    CAROUSEL= 'CAROUSEL',
    /**
     * Height for cover image.
     */
    COVER = 'COVER',
    /**
     * Match the height of the container.
     */
    FULL_CONTENT = 'FULL_CONTENT',
}

export enum ImageWidth {
    FULL = 'FULL'
}

/**
 * Quote from [w3schools](https://www.w3schools.com/tags/att_img_height.asp):
 *
 * > Always specify both the height and width attributes for images. If height and width are set, the space required
 * > for the image is reserved when the page is loaded. However, without these attributes, the browser does not know
 * > the size of the image, and cannot reserve the appropriate space to it. The effect will be that the page layout
 * > will change during loading (while the images load).
 *
 * As such, if the image affects the content structure, prefer setting its height and width attributes explicitly.
 */
interface ImageProps{
    alt: string,
    src: string,
    height?: number | ImageHeight
    width?: number | ImageWidth
    withObjectFit?: ImageObjectFit
    withBorder?: boolean
    withRadius?: boolean
    overrideStyle?: {[key: string]: string}
}

const Image : FC<ImageProps> = ({...props}): JSX.Element => {
    return <img className={classNames(styles.image, {
        [styles.imageFullWidth]: props.width == ImageWidth.FULL,
        [styles.imageHeightCarousel]: props.height === ImageHeight.CAROUSEL,
        [styles.imageHeightCover]: props.height === ImageHeight.COVER,
        [styles.imageHeightFullContent]: props.height === ImageHeight.FULL_CONTENT,
        [styles.imageWithObjectFitCover]: props.withObjectFit === ImageObjectFit.COVER || props.withObjectFit == ImageObjectFit.COVER_TOP,
        [styles.imageWithObjectFitCoverTop]: props.withObjectFit === ImageObjectFit.COVER_TOP,
        [styles.imageWithObjectFitScaleDown]: props.withObjectFit === ImageObjectFit.SCALE_DOWN,
        [styles.imageWithObjectFitShrinkToFitWidth]: props.withObjectFit === ImageObjectFit.SHRINK_TO_FIT_WIDTH,
        [styles.imageBorder]: props.withBorder,
        [styles.imageRadius]: props.withRadius,
    })}
        alt={props.alt}
        src={props.src}
        height={typeof props.height == 'number' ? props.height : undefined}
        width={typeof props.width == 'number' ? props.width : undefined}
        style={props.overrideStyle}
    />
}

export default Image;