import { APIText } from 'models/common/message';

export enum DisclaimerSeverity {
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    ALERT = 'ALERT',
    COMMUNICATION = 'COMMUNICATION',
}

export enum CallToActionType {
    LINK = 'LINK',
}

export interface CallToActionLinkPayload {
    url: string
    name: APIText
    open_new_tab?: boolean
}

export type CallToActionPayload = CallToActionLinkPayload

export interface CallToAction {
    type: CallToActionType
    payload: CallToActionPayload
}