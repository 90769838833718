import React, { Component, ReactNode } from 'react';

import 'components/core/items/Loader.scss';

export type MyLoaderProps = {};

export default class TextLoader extends Component<MyLoaderProps> {
    render(): ReactNode {
        return (
            <div id={'text-loader'} className={'text-loader'}>
                <a className={"legacy-a"} href={'https://loading.io/icon/'}> </a>
            </div>
        );
    }
}
