import React from 'react';
import { NumericalValue } from 'models/medicalReport/ReportModels';

export enum NumberFormat {
    EUROPEAN,
    ENGLISH
}

export const numberFormatter = (value: number, numberFormat?: NumberFormat): string => {

    switch (numberFormat) {
        case NumberFormat.ENGLISH:
            return Intl.NumberFormat("en-US").format(value)
        case NumberFormat.EUROPEAN:
            return Intl.NumberFormat("fr-FR").format(value)
    }

    return Intl.NumberFormat("en-US").format(value)
}
export const SimpleNumericalValue = (props: {value: NumericalValue | null, numberFormat?: NumberFormat}): JSX.Element | null => (
    props.value && props.value.value != null ? <>
        <span>
            {numberFormatter(props.value.value, props.numberFormat ? props.numberFormat : NumberFormat.EUROPEAN)}
        </span>
        {
            props.value.unit &&
            <span>
            {props.value.unit}
        </span>
        }
    </> : null

)