import Button, { ButtonVariant } from "components/designSystem/components/Button";
import Centered from "components/designSystem/containers/Centered";
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import WithBackground, {
    WithBackgroundColor,
    WithBackgroundRadius
} from "components/designSystem/containers/WithBackground";
import WithPadding, { PaddingSize } from "components/designSystem/containers/WithPadding";
import React, { ReactNode, useState } from 'react';

export enum SelectedButton {
    RIGHT = 'RIGHT',
    LEFT = 'LEFT'
}

export interface ToggleButtonData {
    onClick: () => void;
    content: ReactNode;
    id?: string;
}

interface ToggleButtonProps {
    leftButton: ToggleButtonData,
    rightButton: ToggleButtonData,
    defaultButtonSelected?: SelectedButton
}

export const ToggleButton = (props: ToggleButtonProps) : JSX.Element => {
    const [buttonSelected, selectButton] = useState<SelectedButton>(props.defaultButtonSelected ?? SelectedButton.LEFT)

    return <Centered>
        <WithBackground color={WithBackgroundColor.SURFACE_INTENSE} radius={WithBackgroundRadius.BORDER_RADIUS_S} fitContent>
            <WithPadding paddings={[PaddingSize.SMALL]}>
                <AlwaysInline spacing={InlineSpacing.X_SMALL} justifyContent={InlineJustifyContent.CENTER} alignItems={InlineAlignItem.CENTER}>
                    <Button
                        id={props.leftButton.id}
                        variant={buttonSelected === SelectedButton.LEFT ? ButtonVariant.PRIMARY : ButtonVariant.QUATERNARY}
                        onClick={(): void => {
                            selectButton(SelectedButton.LEFT)
                            props.leftButton.onClick()
                        }}
                    >
                        {props.leftButton.content}
                    </Button>
                    <Button
                        id={props.rightButton.id}
                        variant={buttonSelected === SelectedButton.RIGHT ? ButtonVariant.PRIMARY : ButtonVariant.QUATERNARY}
                        onClick={(): void => {
                            selectButton(SelectedButton.RIGHT)
                            props.rightButton.onClick()
                        }}
                    >
                        {props.rightButton.content}
                    </Button>
                </AlwaysInline>
            </WithPadding>
        </WithBackground>
    </Centered>
}