import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import * as React from 'react';


const FillKidneys = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.SMALL:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.771384 5.22726C1.65985 2.98758 3.83203 1.5 6.2415 1.5C8.64044 1.5 10.2808 3.92289 9.38987 6.15024L8.79423 7.63934L11.0031 8.74379C11.3842 8.93435 11.625 9.3239 11.625 9.75002V21C11.625 21.6213 11.1213 22.125 10.5 22.125C9.87868 22.125 9.375 21.6213 9.375 21V10.4453L8.69137 10.1035L8.95578 10.7645C10.0546 13.5117 8.03145 16.5 5.07267 16.5C3.36694 16.5 1.83214 15.4641 1.19407 13.8822L0.786357 12.8715C-0.199909 10.4263 -0.200815 7.67801 0.771384 5.22726Z"
                        fill="currentColor"/>
                    <path
                        d="M12.9969 8.74379L15.206 7.63925L14.6104 6.15024C13.7194 3.92289 15.3598 1.5 17.7587 1.5C20.1682 1.5 22.3404 2.98758 23.2289 5.22726C24.2011 7.67801 24.2002 10.4263 23.2139 12.8715L22.8062 13.8822C22.1681 15.4641 20.6333 16.5 18.9276 16.5C15.9688 16.5 13.9456 13.5117 15.0445 10.7645L15.3089 10.1033L14.625 10.4453V21C14.625 21.6213 14.1213 22.125 13.5 22.125C12.8787 22.125 12.375 21.6213 12.375 21V9.75002C12.375 9.3239 12.6158 8.93435 12.9969 8.74379Z"
                        fill="currentColor"/>
                </svg>

            )
        default:
            return <div/>
    }
};

export default FillKidneys;
