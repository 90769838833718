import React from 'react';

const Heart = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M5.07602 7.2888C8.05618 4.22156 12.759 3.98942 15.9999 6.59234C19.2409 3.98942 23.9437 4.22158 26.9238 7.28882C30.2413 10.7033 29.2501 15.8022 27.044 19.0831C24.8711 22.3145 20.3455 26.4865 16.477 28.4207C16.1767 28.5709 15.8232 28.5709 15.5229 28.4207C11.6544 26.4865 7.1288 22.3145 4.95588 19.083C2.74974 15.8022 1.75851 10.7033 5.07602 7.2888Z" />
        </svg>
    )
}

export default Heart;
