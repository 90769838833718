import React, { FC } from 'react';

import styles from "components/designSystem/containers/WithPadding.module.scss"
import classNames from 'classnames';

export enum PaddingSize {
    X_SMALL = 'X_SMALL', // 4px
    SMALL = 'SMALL', // 8px
    MEDIUM = 'MEDIUM', // 16px
    LARGE = 'LARGE', // 24px
    X_LARGE = 'X_LARGE', // 32px
    FIVE_TIMES_MEDIUM = 'FIVE_TIMES_MEDIUM', // 50px
    TEN_TIMES_MEDIUM = 'TEN_TIMES_MEDIUM' // 16Opx
}

interface WithPaddingProps {
    // same as css all / top bottom / top right bottom left
    paddings: (PaddingSize | undefined)[]
}


const WithPadding: FC<WithPaddingProps> = (
    props
): JSX.Element => {
    const {paddings} = props;

    let paddingTop: PaddingSize | undefined = undefined
    let paddingRight: PaddingSize | undefined = undefined
    let paddingBottom: PaddingSize | undefined = undefined
    let paddingLeft: PaddingSize | undefined = undefined

    switch (paddings.length) {
        case 1:
            paddingTop = paddingRight = paddingBottom = paddingLeft = paddings[0];
            break;
        case 2:
            paddingTop = paddingBottom = paddings[0];
            paddingRight = paddingLeft = paddings[1];
            break;

        case 4:
            paddingTop = paddings[0];
            paddingRight = paddings[1];
            paddingBottom = paddings[2];
            paddingLeft = paddings[3];
            break;

    }

    return <div className={
        classNames(

            styles.withMargin,
            {
                // Top
                [styles.withPaddingTopXSmall]: paddingTop === PaddingSize.X_SMALL,
                [styles.withPaddingTopSmall]: paddingTop === PaddingSize.SMALL,
                [styles.withPaddingTopMedium]: paddingTop === PaddingSize.MEDIUM,
                [styles.withPaddingTopLarge]: paddingTop === PaddingSize.LARGE,
                [styles.withPaddingTopXLarge]: paddingTop === PaddingSize.X_LARGE,
                [styles.withPaddingTopTenTimesMedium]: paddingTop === PaddingSize.TEN_TIMES_MEDIUM,
                [styles.withPaddingTopFiveTimesMedium]: paddingTop === PaddingSize.FIVE_TIMES_MEDIUM,

                // Right
                [styles.withPaddingRightXSmall]: paddingRight=== PaddingSize.X_SMALL,
                [styles.withPaddingRightSmall]: paddingRight=== PaddingSize.SMALL,
                [styles.withPaddingRightMedium]: paddingRight === PaddingSize.MEDIUM,
                [styles.withPaddingRightLarge]: paddingRight === PaddingSize.LARGE,
                [styles.withPaddingRightXLarge]: paddingRight === PaddingSize.X_LARGE,
                [styles.withPaddingRightTenTimesMedium]: paddingRight === PaddingSize.TEN_TIMES_MEDIUM,
                [styles.withPaddingRightFiveTimesMedium]: paddingRight === PaddingSize.FIVE_TIMES_MEDIUM,

                // Bottom
                [styles.withPaddingBottomXSmall]: paddingBottom === PaddingSize.X_SMALL,
                [styles.withPaddingBottomSmall]: paddingBottom === PaddingSize.SMALL,
                [styles.withPaddingBottomMedium]: paddingBottom === PaddingSize.MEDIUM,
                [styles.withPaddingBottomLarge]: paddingBottom === PaddingSize.LARGE,
                [styles.withPaddingBottomXLarge]: paddingBottom === PaddingSize.X_LARGE,
                [styles.withPaddingBottomTenTimesMedium]: paddingBottom === PaddingSize.TEN_TIMES_MEDIUM,
                [styles.withPaddingBottomFiveTimesMedium]: paddingBottom === PaddingSize.FIVE_TIMES_MEDIUM,

                // Left
                [styles.withPaddingLeftXSmall]: paddingLeft === PaddingSize.X_SMALL,
                [styles.withPaddingLeftSmall]: paddingLeft === PaddingSize.SMALL,
                [styles.withPaddingLeftMedium]: paddingLeft === PaddingSize.MEDIUM,
                [styles.withPaddingLeftLarge]: paddingLeft === PaddingSize.LARGE,
                [styles.withPaddingLeftXLarge]: paddingLeft === PaddingSize.X_LARGE,
                [styles.withPaddingLeftTenTimesMedium]: paddingLeft === PaddingSize.TEN_TIMES_MEDIUM,
                [styles.withPaddingLeftFiveTimesMedium]: paddingLeft === PaddingSize.FIVE_TIMES_MEDIUM,
            }
        )

    }>
        {props.children}
    </div>
}

interface SinglePaddingShortCut {
    padding?: PaddingSize
}

export const WithVerticalPadding: FC<SinglePaddingShortCut> = ({padding = PaddingSize.MEDIUM, ...props}): JSX.Element => <WithPadding paddings={[padding, undefined]}>{props.children}</WithPadding>
export const WithHorizontalPadding: FC<SinglePaddingShortCut> = ({padding = PaddingSize.MEDIUM, ...props}): JSX.Element => <WithPadding paddings={[undefined, padding]}>{props.children}</WithPadding>

export const WithTopPadding: FC<SinglePaddingShortCut> = ({padding = PaddingSize.MEDIUM, ...props}): JSX.Element => <WithPadding paddings={[padding, undefined, undefined, undefined]}>{props.children}</WithPadding>
export const WithRightPadding: FC<SinglePaddingShortCut> = ({padding = PaddingSize.MEDIUM, ...props}): JSX.Element => <WithPadding paddings={[undefined, padding, undefined, undefined]}>{props.children}</WithPadding>
export const WithBottomPadding: FC<SinglePaddingShortCut> = ({padding = PaddingSize.MEDIUM, ...props}): JSX.Element => <WithPadding paddings={[undefined, undefined, padding, undefined]}>{props.children}</WithPadding>
export const WithLeftPadding: FC<SinglePaddingShortCut> = ({padding = PaddingSize.MEDIUM, ...props}): JSX.Element => <WithPadding paddings={[undefined, undefined, undefined, padding]}>{props.children}</WithPadding>

export default WithPadding;