import React, { ReactNode } from 'react';

import 'components/core/items/LinearLoader.scss';
import classNames from 'classnames';

export interface LinearLoaderProps {
    active: boolean;
}

export class LinearLoader extends React.Component<LinearLoaderProps> {
    render(): ReactNode {
        return (
            <div className={classNames('linear-loader-container', !this.props.active && 'linear-loader-hidden')}>
                <div className="linear-loader-indeterminate" />
            </div>
        );
    }
}
