import TrText from 'components/common/TrText';
import Pill, { PillSeverity } from 'components/designSystem/components/Pill';
import { Tag, TagSeverity, TagSize } from 'components/designSystem/components/Tag';
import { WhiteBoxWithShadow } from 'components/designSystem/containers/Box';
import { AlwaysInline, Grow, InlineAlignItem, InlineSpacing } from 'components/designSystem/containers/Inline';
import { XSmallSpacer } from 'components/designSystem/containers/Spacer';
import { TBody700, TCaption, TypographyColor } from 'components/designSystem/containers/Typography';
import WithMargin, {
    MarginSize,
    WithBottomMargin,
    WithHorizontalMargin,
    WithRightMargin,
    WithTopMargin
} from 'components/designSystem/containers/WithMargin';
import { formatComments, formatTags } from 'components/modules/modular/modules/report/content/lines/utils';
import {
    AntibiogramAntibiotic,
    AntibiogramReportLineProps
} from 'components/modules/modular/modules/report/content/models';
import { TransDomain } from 'components/pages/common/MainComponent';
import { capitalizeFirstLetter } from 'core/utils/text';
import { toLocalTransKey } from 'models/common/message';
import { ModuleReportDataTagType } from "models/modular/report";
import React from 'react';

function content(props: AntibiogramReportLineProps): JSX.Element {
    const notIconTags = props.tags ? props.tags.filter(
        (tag) => !(tag.payload.type === ModuleReportDataTagType.ICON)
    ) : []
    const iconTags = props.tags ? props.tags.filter(
        (tag) => tag.payload.type === ModuleReportDataTagType.ICON
    ) : []

    return <>
        <WithHorizontalMargin margin={MarginSize.SMALL}>
            <TBody700 color={TypographyColor.COLOR_TEXT_SUBDUED}>
                <AlwaysInline alignItems={InlineAlignItem.CENTER}>
                    <Grow>
                        <XSmallSpacer>
                            <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.MEDIUM}>
                                {capitalizeFirstLetter(props.bacteria.name)}
                                {formatTags(iconTags)}
                            </AlwaysInline>
                            {formatTags(notIconTags)}
                        </XSmallSpacer>
                    </Grow>
                    <WithRightMargin margin={MarginSize.SMALL}>
                        <TrText input={toLocalTransKey("sensibility", TransDomain.MEDICAL_REPORT)}/>
                    </WithRightMargin>
                </AlwaysInline>
            </TBody700>
            <WithMargin margins={[MarginSize.SMALL, MarginSize.SMALL]}>
                {
                    props.antibiotics.map((a: AntibiogramAntibiotic, k: number): JSX.Element => (
                        <WithTopMargin margin={MarginSize.SMALL} key={k}>
                            <AlwaysInline alignItems={InlineAlignItem.CENTER}>
                                <Grow>
                                    <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                                        <Pill severity={PillSeverity.NEUTRAL}/>
                                        <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>{a.name}</TCaption>
                                    </AlwaysInline>
                                </Grow>
                                <div>
                                    <Tag size={TagSize.SMALL}
                                         severity={TagSeverity.INFO}>{a.sensibility ? capitalizeFirstLetter(a.sensibility) : ''}</Tag>
                                </div>
                            </AlwaysInline>
                        </WithTopMargin>
                    ))
                }
            </WithMargin>
        </WithHorizontalMargin>
        <WithBottomMargin margin={props.isMobile ? undefined : MarginSize.MEDIUM}>
            {formatComments(props.comments, props.isMobile)}
        </WithBottomMargin>
    </>
}

export const AntibiogramLine = (
    props: AntibiogramReportLineProps
): JSX.Element => (
    props.isMobile ? <WhiteBoxWithShadow>
        <WithMargin margins={[MarginSize.MEDIUM, MarginSize.SMALL]}>
            {content(props)}
        </WithMargin>
    </WhiteBoxWithShadow> : content(props)
)
