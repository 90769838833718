import React from 'react';
import { CountryFlagsProps } from "components/designSystem/assets/countryData";

export const UnitedKingdom = (props: CountryFlagsProps): JSX.Element => {

    return (
        <svg width={props.width} height={props.height} viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_223_892)">
                <g clipPath="url(#clip1_223_892)">
                    <path d="M0 4.059V8H5.628L0 4.059ZM4.664 26H13V20.163L4.664 26ZM23 20.164V26H31.335L23 20.164ZM0 18V21.941L5.63 18H0ZM31.337 0H23V5.837L31.337 0ZM36 21.942V18H30.369L36 21.942ZM36 8V4.059L30.371 8H36ZM13 0H4.664L13 5.837V0Z" fill="#194184"/>
                    <path d="M25.14 18L34.852 24.801C35.323 24.322 35.66 23.719 35.842 23.052L28.627 18H25.14ZM13 18H10.859L1.148 24.8C1.669 25.33 2.337 25.709 3.086 25.885L13 18.943V18ZM23 8.00001H25.141L34.852 1.20001C34.331 0.670006 33.664 0.291005 32.915 0.115005L23 7.05701V8.00001ZM10.859 8.00001L1.148 1.20001C0.677001 1.68001 0.340001 2.28201 0.157001 2.94901L7.372 8.00001H10.859Z" fill="#C72E2A"/>
                    <path d="M36 16H21V26H23V20.164L31.335 26H32C33.117 26 34.126 25.539 34.852 24.801L25.14 18H28.627L35.842 23.052C35.935 22.715 36 22.366 36 22V21.942L30.369 18H36V16ZM0 16V18H5.63L0 21.941V22C0 23.091 0.439 24.078 1.148 24.8L10.859 18H13V18.943L3.086 25.884C3.38 25.954 3.684 26 4 26H4.664L13 20.163V26H15V16H0ZM36 4C36 2.909 35.561 1.922 34.852 1.2L25.141 8H23V7.057L32.915 0.115C32.62 0.0459998 32.316 0 32 0H31.337L23 5.837V0H21V10H36V8H30.371L36 4.059V4ZM13 0V5.837L4.664 0H4C2.882 0 1.874 0.461 1.148 1.2L10.859 8H7.372L0.157 2.949C0.065 3.286 0 3.634 0 4V4.059L5.628 8H0V10H15V0H13Z" fill="#EDEDF2"/>
                    <path d="M21 10V0H15V10H0V16H15V26H21V16H36V10H21Z" fill="#C72E2A"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_223_892">
                    <rect width="36" height="26" rx="4" fill="white"/>
                </clipPath>
                <clipPath id="clip1_223_892">
                    <rect width="36" height="26" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
