import React from 'react';

const Kidneys = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M17.755 19.1705C16.8859 16.8605 17.7092 14.0359 19.6647 12.5435C24.0503 10.5595 23.7758 7.07159 23.0211 5.27048C22.4035 3.80672 20.4423 1.00499 16.2683 2.36012C13.0892 3.31499 10.3104 5.53922 8.53213 8.38669C6.63953 11.3885 4.2838 21.7263 10.4247 26.6436C12.2258 28.0903 17.8121 29.8113 20.1221 25.9346C21.0884 23.5274 18.1781 20.3255 17.755 19.1705Z"/>
            <path d="M20.9798 15.2824H18.9557C18.647 15.9914 18.5097 16.7747 18.5555 17.5523H20.957C22.2263 17.5523 23.2669 18.5758 23.2669 19.8623V28.865C23.2669 29.4919 23.7751 30 24.4019 30C25.0288 30 25.5369 29.4919 25.5369 28.865V19.8395C25.5369 17.3236 23.4957 15.2824 20.9798 15.2824Z" />
            <path d="M20.4023 13.4126H24.2247C24.8515 13.4126 25.3597 12.9045 25.3597 12.2777C25.3597 11.6508 24.8515 11.1427 24.2247 11.1427H23.4271C23.0497 11.8574 20.694 13.2125 20.4023 13.4126Z" />
        </svg>
    )
}

export default Kidneys;
