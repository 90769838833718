import TrText from "components/common/TrText";
import Button, { ButtonOption, ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import Inline, {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineOverflow,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import { TCaption, TypographyColor } from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";
import { SCREEN_L } from 'components/designSystem/foundations/Breakpoints';
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_EULA, ROUTE_EULA_DATA_PRIVACY, ROUTE_FAQ, ROUTE_LEGAL_NOTICE } from 'core/routing/Routes';
import { capitalizeFirstLetters } from 'core/utils/text';
import moment from "moment";
import React, { Fragment, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';


const FOOTER_ELTS: [string, string][] = [
    ['faq', ROUTE_FAQ],
    ['legal_notice', ROUTE_LEGAL_NOTICE],
    ['end_user_license_agreement', ROUTE_EULA],
    ['data_privacy', getRoute(ROUTE_EULA_DATA_PRIVACY, {version: null})],
]


export class Footer extends WithTranslations<{}> {
    TRANS_SUFFIX = TransDomain.GLOBAL

    buildContent = (isMobile: boolean): JSX.Element => {
        const footerElts = FOOTER_ELTS.map((elt: string[]): JSX.Element => (
            <Button
                key={elt[0]}
                onClick={{href: elt[1], targetBlank: true}}
                variant={ButtonVariant.LINK}
                option={ButtonOption.DARK}
                size={ButtonSize.EXTRA_SMALL}
            >
                {this.trans(elt[0], undefined, undefined, capitalizeFirstLetters)}
            </Button>
        ))
        const brand = <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>
            <TrText input={{trkey: 'brand', trdomain: TransDomain.GLOBAL, trdata: {year: moment().year()}}}/>
        </TCaption>
        const inlineSpacing: InlineSpacing = isMobile ? InlineSpacing.SMALL : InlineSpacing.X_LARGE
        const bottomMargin: MarginSize = isMobile ? MarginSize.MEDIUM : MarginSize.LARGE
        const leftAndRightMargin: MarginSize | undefined = isMobile ? MarginSize.SMALL : undefined
        return <WithMargin margins={[undefined, leftAndRightMargin, bottomMargin, leftAndRightMargin]}>
            <Inline spacing={inlineSpacing} alignItems={InlineAlignItem.CENTER}
                    justifyContent={InlineJustifyContent.CENTER}
                    overflow={InlineOverflow.WRAP}
            >
                <AlwaysInline spacing={inlineSpacing} alignItems={InlineAlignItem.CENTER}>
                    {brand}{footerElts.slice(0, 2)}
                </AlwaysInline>
                <Inline spacing={inlineSpacing} alignItems={InlineAlignItem.CENTER}>
                    {footerElts.slice(2)}
                </Inline>
            </Inline>
        </WithMargin>
    }

    render(): JSX.Element {
        return (
            <Fragment>
                <MediaQuery minWidth={SCREEN_L + 1}>
                    {this.buildContent(false)}
                </MediaQuery>
                <MediaQuery maxWidth={SCREEN_L}>
                    {this.buildContent(true)}
                </MediaQuery>
            </Fragment>
        )
    }
}

export const WithFooter = (props: PropsWithChildren<{}>): JSX.Element => {
    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            rowGap: '36px'
        }}
    >
        {props.children}
        <Footer/>
    </div>
}