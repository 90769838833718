import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { AlwaysInline, Grow, InlineJustifyContent } from "components/designSystem/containers/Inline";
import { IconName } from "components/designSystem/foundations/IconsData";
import PatientSearchBar from "components/pages/common/PatientSearchBar";
import { LocalUserData } from "models/user/UserModels";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";

interface NavigationHeaderProps {
    onClickSideBar?: () => void;
    buildLogo: () => ReactNode;
    withSearchPatient?: boolean;
    buildProfileItem: (userData?: LocalUserData | null, withName?: boolean) => ReactNode;
}

const MobileNavigationHeader: FC<NavigationHeaderProps> = (
    props
): JSX.Element => {
    const [showSearchPatient, setShowSearchPatient] = useState(false)
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: Event): void => {
            // @ts-ignore
            if (ref.current && !ref.current.contains(event.target) && showSearchPatient) {
                setShowSearchPatient(false)
            }
        };
        document.addEventListener("click", handleClickOutside);

        return (): void => {
            document.removeEventListener("click", handleClickOutside);
        };
    });


    return showSearchPatient ? <div ref={ref}>
        <PatientSearchBar/>
    </div> : <AlwaysInline>
        {
            props.onClickSideBar && <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.LARGE}
                icons={{middle: IconName.BURGER_MENU}}
                onClick={props.onClickSideBar}
            />
        }
        {
            <Grow justifyContent={InlineJustifyContent.CENTER}>
                {props.buildLogo()}
            </Grow>
        }
        <AlwaysInline>
            {
                props.withSearchPatient &&
                <Button
                    variant={ButtonVariant.SECONDARY}
                    size={ButtonSize.LARGE}
                    icons={{middle: IconName.SEARCH}}
                    onClick={(): void => {
                        setShowSearchPatient(true);
                    }}
                />
            }
            {
                props.buildProfileItem()
            }
        </AlwaysInline>
    </AlwaysInline>
}

export default MobileNavigationHeader