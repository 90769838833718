import classNames from "classnames";
import Pill, { PillSeverity } from "components/designSystem/components/Pill";
import styles from "components/designSystem/components/SeverityBrief.module.scss";
import { AlwaysInline, InlineAlignItem, InlineSpacing } from "components/designSystem/containers/Inline";
import Typography, { TypographyColor, TypographyVariant } from "components/designSystem/containers/Typography";
import React, { FC } from "react";

export interface SeverityBriefProps {
    severities: {
        severity: PillSeverity,
        count: number | undefined | null
    }[]
}

const SeverityBrief = (
    props: SeverityBriefProps
): JSX.Element => {
    return <div className={classNames(styles.container)}>
        <AlwaysInline spacing={InlineSpacing.SMALL}>
            {
                props.severities.map((s, i) => <AlwaysInline
                    spacing={InlineSpacing.SMALL}
                    alignItems={InlineAlignItem.CENTER}
                    key={i}
                >
                    <Pill severity={s.severity}/>
                    <div className={classNames(styles.item)}>
                        <Typography variant={TypographyVariant.BODY_500} color={TypographyColor.COLOR_TEXT_DEFAULT}>
                            {s.count == null ? "-" : s.count }
                        </Typography>
                    </div>

                </AlwaysInline>)
            }
        </AlwaysInline>

    </div>

}

export default SeverityBrief;