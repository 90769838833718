import React from 'react';

const Fertility = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M22.1345 10.6172C22.9759 10.6172 23.658 9.93513 23.658 9.09376C23.658 8.25239 22.9759 7.57032 22.1345 7.57032C21.2932 7.57032 20.6111 8.25239 20.6111 9.09376C20.6111 9.93513 21.2932 10.6172 22.1345 10.6172Z" />
            <path d="M20.6111 3C16.411 3 12.9939 6.41707 12.9939 10.6172C12.9939 10.9051 13.0096 11.1905 13.0411 11.4723C14.9667 10.5745 17.0427 10.9336 18.2878 12.1787C19.6889 13.5798 19.926 15.9502 18.689 17.9891C19.3121 18.1511 19.9575 18.2344 20.6111 18.2344C24.8112 18.2344 28.2283 14.8173 28.2283 10.6172C28.2283 6.41707 24.8112 3 20.6111 3ZM22.1345 12.1406C20.4547 12.1406 19.0876 10.7736 19.0876 9.09375C19.0876 7.41391 20.4547 6.04688 22.1345 6.04688C23.8144 6.04688 25.1814 7.41391 25.1814 9.09375C25.1814 10.7736 23.8144 12.1406 22.1345 12.1406Z" />
            <path d="M12.3628 13.7948C11.0731 15.0845 10.7894 16.7273 11.315 17.8981C10.8237 18.5073 10.4867 19.2237 10.3314 20.0005C10.0647 21.334 9.03071 22.368 7.69725 22.6347C5.1716 23.1398 3.40189 25.5453 3.83782 28.2582C3.90456 28.6736 4.3371 28.8998 4.73925 28.7763C5.14139 28.6528 5.35779 28.2257 5.31547 27.8072C5.13368 26.0093 6.31303 24.4652 7.99599 24.1285C9.93452 23.7409 11.4375 22.2378 11.8253 20.2993C11.9162 19.8445 12.0976 19.4199 12.3579 19.045C13.5296 19.7056 15.2906 19.485 16.6717 18.1037C18.2681 16.5072 18.3238 14.3696 17.2103 13.2561C16.1083 12.1541 13.975 12.1823 12.3628 13.7948Z" />
        </svg>
    )
}

export default Fertility;
