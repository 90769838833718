import _ from "underscore";

export function secureOrder<D>(
    previousOrder: D[],
    newData: D[],
    indexOf?: ( collection: D[], value: D) => number
): D[] {
    const returnList:D[] = previousOrder
    const indexOf_ = indexOf ?? _.indexOf
    for (let i = 0; i < newData.length; i++) {
        const index = indexOf_(previousOrder, newData[i])
        if (index > -1){
            returnList[index] = newData[i]
        }
        else {
            returnList.push(newData[i])
        }
    }

    return [...returnList]

}

