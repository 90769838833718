import { Action, APICallAction, ConnectedButtonPayload } from 'actions/ActionInterface';

export const CONNECTED_BUTTON_FEEDBACK = 'CONNECTED_BUTTON_FEEDBACK';
export const CONNECTED_BUTTON_PURE_API_CALL = 'CONNECTED_BUTTON_PURE_API_CALL';


export enum ConnectedButtonFeedbackType {
    SUCCEED = 'SUCCEED',
    FAILED = 'SUCCESSFUL',
    IN_PROGRESS = 'IN_PROGRESS',
}

export type ConnectedButtonFeedback = {
    identifier: string,
    type: ConnectedButtonFeedbackType,
    feedbackTimestamp: number
};

export const connectedButtonFeedback = (
    payload: ConnectedButtonPayload,
    type: ConnectedButtonFeedbackType = ConnectedButtonFeedbackType.SUCCEED,
): Action<ConnectedButtonFeedback> => {

    return {
        type: CONNECTED_BUTTON_FEEDBACK,
        payload: {
            identifier: payload.identifier,
            type: type,
            feedbackTimestamp: Date.now()
        },
    };
};

export enum ApiCallType {
    GET = 'GET',
    POST = 'POST',

}

export type PureApiCallPayload<T> = {
    path: string,
    params: T,
    type: ApiCallType,
};

export const pureAPICall = <T>(
    identifier: string,
    path: string,
    params: T,
    type?: ApiCallType
): APICallAction<PureApiCallPayload<T>> => {

    return {
        type: CONNECTED_BUTTON_PURE_API_CALL,
        payload: {
            params: {
                path,
                params,
                type: type ? type : ApiCallType.GET,
            },

        },
        connectedButtonPayload: {
            identifier,
            timestamp: Date.now()
        }
    };
};