import React, {FC} from 'react';
import styles from './Divider.module.scss';
import classNames from "classnames";


export enum DividerOrientation {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

export enum DividerVariant {
    DEFAULT = 'subdued',
    HIGHLIGHTED = 'default',
}

export enum DividerSize {
    MEDIUM = 'MEDIUM'
}

export interface DividerProps {
    orientation?: DividerOrientation;
    variant: DividerVariant;
    size?: DividerSize;
}

const Divider : FC<DividerProps> = (
    {
        orientation = DividerOrientation.HORIZONTAL,
        ...props
    }):JSX.Element => {

    const computedClassNames = classNames(styles.divider,
        {
            [styles.dividerOrientationHorizontal]: orientation === DividerOrientation.HORIZONTAL,
            [styles.dividerOrientationVertical]: orientation === DividerOrientation.VERTICAL,

            [styles.dividerVariantDefault]: props.variant === DividerVariant.DEFAULT,
            [styles.dividerVariantHighlighted]: props.variant === DividerVariant.HIGHLIGHTED,

            [styles.dividerSizeMedium]: props.size === DividerSize.MEDIUM
        }
    )
    return <div className={computedClassNames}>
    </div>
}

export default Divider;