import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Globe = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_320_174)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 7.74709 0.0117362 7.49692 0.0346937 7.25C0.412794 3.18341 3.83463 0 8 0C12.1654 0 15.5872 3.18341 15.9653 7.25C15.9883 7.49692 16 7.74709 16 8C16 8.25291 15.9883 8.50308 15.9653 8.75C15.5872 12.8166 12.1654 16 8 16C3.83463 16 0.412794 12.8166 0.0346937 8.75C0.0117362 8.50308 0 8.25291 0 8ZM5.54912 14.0221C3.39536 13.1447 1.81801 11.1454 1.5428 8.75H3.76851C3.83882 10.1358 4.11252 11.3674 4.64141 12.5007C4.89056 13.0346 5.1928 13.5391 5.54912 14.0221ZM8.00211 14.5C8.0014 14.5 8.0007 14.5 8 14.5C7.9993 14.5 7.99859 14.5 7.99789 14.5C7.08237 13.6543 6.43593 12.799 6.00069 11.8664C5.57785 10.9603 5.33901 9.94837 5.27066 8.75H10.7293C10.661 9.94837 10.4221 10.9603 9.99932 11.8664C9.56407 12.799 8.91763 13.6543 8.00211 14.5ZM10.4509 14.0221C12.6046 13.1447 14.182 11.1454 14.4572 8.75H12.2315C12.1612 10.1358 11.8875 11.3674 11.3586 12.5007C11.1094 13.0346 10.8072 13.5391 10.4509 14.0221ZM12.2315 7.25H14.4572C14.182 4.8546 12.6046 2.85532 10.4508 1.9779C10.8072 2.46092 11.1094 2.96543 11.3586 3.49935C11.8875 4.63267 12.1612 5.86423 12.2315 7.25ZM7.99794 1.5C7.99862 1.5 7.99931 1.5 8 1.5C8.00069 1.5 8.00137 1.5 8.00206 1.5C8.91761 2.34568 9.56406 3.20099 9.99932 4.13368C10.4221 5.03974 10.661 6.05165 10.7293 7.25H5.27066C5.33902 6.05165 5.57786 5.03974 6.00069 4.13368C6.43594 3.20099 7.08239 2.34568 7.99794 1.5ZM5.54917 1.9779C3.39538 2.85532 1.81801 4.8546 1.5428 7.25H3.76851C3.83883 5.86423 4.11252 4.63267 4.64141 3.49935C4.89057 2.96543 5.19282 2.46092 5.54917 1.9779Z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_320_174">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_320_173)">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 12C0 11.6632 0.0138713 11.3297 0.0410738 11C0.549225 4.84047 5.70934 0 12 0C18.2907 0 23.4508 4.84047 23.9589 11C23.9861 11.3297 24 11.6632 24 12C24 12.3368 23.9861 12.6703 23.9589 13C23.4508 19.1595 18.2907 24 12 24C5.70934 24 0.549225 19.1595 0.0410738 13C0.0138713 12.6703 0 12.3368 0 12ZM9.20085 21.603C5.34891 20.4821 2.45709 17.1064 2.04938 13H6.02187C6.11419 15.0578 6.50277 16.8781 7.26256 18.5496C7.75798 19.6396 8.40236 20.6467 9.20085 21.603ZM14.7991 21.603C18.6511 20.4821 21.5429 17.1064 21.9506 13H17.9781C17.8858 15.0578 17.4972 16.8781 16.7374 18.5496C16.242 19.6396 15.5976 20.6467 14.7991 21.603ZM17.9781 11H21.9506C21.5429 6.89356 18.6511 3.5179 14.7991 2.39703C15.5976 3.35327 16.242 4.36046 16.7374 5.4504C17.4972 7.12194 17.8858 8.94224 17.9781 11ZM9.2009 2.39703C5.34893 3.5179 2.45709 6.89356 2.04938 11H6.02187C6.11419 8.94224 6.50277 7.12194 7.26256 5.4504C7.75799 4.36046 8.40239 3.35327 9.2009 2.39703ZM8.02408 11C8.11436 9.18272 8.4598 7.64969 9.08329 6.27801C9.71872 4.88007 10.6639 3.60361 12 2.34469C13.3361 3.60361 14.2813 4.88007 14.9167 6.27801C15.5402 7.64969 15.8856 9.18272 15.9759 11H8.02408ZM8.02408 13H15.9759C15.8856 14.8173 15.5402 16.3503 14.9167 17.722C14.2813 19.12 13.3361 20.3964 12 21.6554C10.6639 20.3964 9.71872 19.12 9.08329 17.722C8.4598 16.3503 8.11436 14.8173 8.02408 13Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_320_173">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}