import React, { PropsWithChildren } from 'react';

import LogoFull from 'core/content/brand/logos/LogoFull';

import 'components/modules/user/legacy/WithLogo.scss';

const WithLogo = (props: PropsWithChildren<{}>): JSX.Element => (
    <div className={'with-logo-container'}>
        <div className={'with-logo-logo-container'}>
            <LogoFull />
        </div>
        {props.children}
    </div>
);

export default WithLogo;
