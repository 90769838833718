import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Close = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-close'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.53033 1.46967C2.23744 1.17678 1.76256 1.17678 1.46967 1.46967C1.17678 1.76256 1.17678 2.23744 1.46967 2.53033L6.93934 8L1.46967 13.4697C1.17678 13.7626 1.17678 14.2374 1.46967 14.5303C1.76256 14.8232 2.23744 14.8232 2.53033 14.5303L8 9.06066L13.4697 14.5303C13.7626 14.8232 14.2374 14.8232 14.5303 14.5303C14.8232 14.2374 14.8232 13.7626 14.5303 13.4697L9.06066 8L14.5303 2.53033C14.8232 2.23744 14.8232 1.76256 14.5303 1.46967C14.2374 1.17678 13.7626 1.17678 13.4697 1.46967L8 6.93934L2.53033 1.46967Z"
                    fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-close'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L10.5858 12L2.29289 20.2929C1.90237 20.6834 1.90237 21.3166 2.29289 21.7071C2.68342 22.0976 3.31658 22.0976 3.70711 21.7071L12 13.4142L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L13.4142 12L21.7071 3.70711C22.0976 3.31658 22.0976 2.68342 21.7071 2.29289C21.3166 1.90237 20.6834 1.90237 20.2929 2.29289L12 10.5858L3.70711 2.29289Z"
                fill="currentColor"/>
        </svg>
    )
}