import { UrlReq } from "sagas/call/api";

export type PostUrl = () => string

export enum HTTPVerb {
    'GET',
    'POST'
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface GetEndpoint<Req extends UrlReq, Res> {
    verb: HTTPVerb.GET;
    path: string;
}

export interface PostEndpoint<Req, Res> {
    verb: HTTPVerb.POST;
    path: string;
}

export type EndpointFactory<
    Req extends UrlReq,
    Res,
    PathPayload = void,
    > = (pathPayload: PathPayload) => GetEndpoint<Req, Res>