import Form from "components/commonDesign/form/tmpNewSystem/Form";
import FormInput from "components/commonDesign/form/tmpNewSystem/FormInput";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import Description from 'components/core/text/Description';
import { CenteredColumn } from "components/designSystem/Aliases";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { ColumnWidthInREM } from "components/designSystem/containers/Column";
import { MediumSpacer, XLargeSpacer } from "components/designSystem/containers/Spacer";
import { MarginSize, WithTopMargin } from "components/designSystem/containers/WithMargin";
import APIFeedbackMessageContainer from 'components/pages/common/APIFeedbackMessage';
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from "components/pages/common/WithTranslations";
import { required } from "components/utils/form/tmpNewSystem/validators";
import { mustMatch } from "components/utils/form/validators";
import { ValidationErrors } from "final-form";
import React from 'react';
import MediaQuery from 'react-responsive';

export interface ResetPasswordProps {
    token: string;
    validToken?: string;
    onSubmit: (password: string, confirmation: string, token: string) => void;
    first_set_password: boolean;
    feedbackIdentifier?: string;
}

export default class ResetPassword extends WithTranslations<ResetPasswordProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    componentDidMount(): void {
        if (!this.props.validToken || this.props.validToken !== this.props.token) {
            //check token
        }
    }

    onSubmit() {
        return (data: { password: string; confirmation: string }): void => {
            this.props.onSubmit(data.password, data.confirmation, this.props.token);
        };
    }

    render(): JSX.Element {
        return <MediaQuery minWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
            {(match: boolean): JSX.Element => match ? this.buildContent(false) : this.buildContent(true)}
        </MediaQuery>
    }

    private buildContent(isMobile: boolean): JSX.Element {
        return <WithTopMargin margin={MarginSize.LARGE}>
            <CenteredColumn widthInRem={isMobile ? ColumnWidthInREM.WIDTH_22 : ColumnWidthInREM.WIDTH_28}>
                <XLargeSpacer>
                    {
                        this.props.feedbackIdentifier &&
                        <APIFeedbackMessageContainer identifier={this.props.feedbackIdentifier}/>
                    }
                    {this.props.first_set_password && (
                        <Description isolated italic>
                            {this.trans('first_password_setup')}
                        </Description>
                    )}
                    <Form
                        onSubmit={this.onSubmit()}
                        validate={(values: Record<string, unknown>): ValidationErrors => {
                        const validationErrors: ValidationErrors = {}
                        if (values.password && values.confirmation){
                            if (mustMatch(values.password, values.confirmation)) {
                                validationErrors['confirmation'] = this.trans(
                                    'must_match_passwords', undefined, 'form_errors'
                                );
                            }
                        }
                        return validationErrors;
                    }}>
                        {
                            (formState): JSX.Element => {
                                return <XLargeSpacer>
                                    <MediumSpacer>
                                        <FormInput
                                            name={"password"}
                                            field={{
                                                label: this.props.first_set_password ? this.trans('password') :
                                                    this.trans('new_password'),
                                                type: "password"
                                            }}
                                            validate={required("password")}
                                        />
                                        <FormInput
                                            name={"confirmation"}
                                            field={{
                                                label: this.trans('confirm_new_password'),
                                                type: "password"
                                            }}
                                            validate={required("password")}
                                        />
                                    </MediumSpacer>
                                    <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}
                                            isSubmit fullWidth disabled={formState.alreadySubmitted}
                                    >
                                        {this.trans('validate', undefined, TransDomain.GLOBAL)}
                                    </Button>
                                </XLargeSpacer>
                            }
                        }
                    </Form>
                </XLargeSpacer>
            </CenteredColumn>
        </WithTopMargin>
    }
}
