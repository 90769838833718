import React from 'react';

const Cells = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <g clipPath="url(#clip0_1481_23282)">
                <path d="M10.363 19.5543C10.7837 19.5543 11.1248 19.2133 11.1248 18.7926C11.1248 18.3719 10.7837 18.0309 10.363 18.0309C9.94235 18.0309 9.60132 18.3719 9.60132 18.7926C9.60132 19.2133 9.94235 19.5543 10.363 19.5543Z" />
                <path d="M17.1172 9.09395C17.5379 9.09395 17.8789 8.75292 17.8789 8.33223C17.8789 7.91154 17.5379 7.57051 17.1172 7.57051C16.6965 7.57051 16.3555 7.91154 16.3555 8.33223C16.3555 8.75292 16.6965 9.09395 17.1172 9.09395Z" />
                <path d="M14.7382 23.1479C15.0476 22.9693 15.2382 22.6391 15.2382 22.2819V17.8111C15.2382 17.4538 15.0476 17.1236 14.7382 16.945L10.845 14.6975C10.5356 14.5189 10.1545 14.5189 9.8451 14.6975L3.24072 18.5102C3.90387 21.9175 5.88927 24.8068 8.60028 26.6913L14.7382 23.1479ZM10.363 16.5076C11.6231 16.5076 12.6483 17.5326 12.6483 18.7926C12.6483 20.0526 11.6232 21.0777 10.363 21.0777C9.10291 21.0777 8.07779 20.0526 8.07779 18.7926C8.07779 17.5326 9.10296 16.5076 10.363 16.5076Z" />
                <path d="M9.09371 13.3722C9.40313 13.1936 9.59375 12.8635 9.59375 12.5062V4.68182C5.33447 7.10094 2.69095 11.7469 3.029 16.8734L9.09371 13.3722Z" fill="#001948"/>
                <path d="M11.1172 12.5062C11.1172 12.8635 11.3078 13.1936 11.6172 13.3723L15.5 15.6138C15.8094 15.7924 16.1905 15.7924 16.4999 15.6138L20.3826 13.3723C20.6921 13.1936 20.8827 12.8635 20.8827 12.5062V3.94545C17.7699 2.68521 14.232 2.68445 11.1172 3.94545V12.5062ZM17.1172 6.04718C18.3773 6.04718 19.4025 7.07226 19.4025 8.33224C19.4025 9.59223 18.3773 10.6173 17.1172 10.6173C15.8571 10.6173 14.8319 9.59223 14.8319 8.33224C14.8319 7.07226 15.8571 6.04718 17.1172 6.04718Z" />
                <path d="M22.1549 14.6975C21.8455 14.5189 21.4643 14.5189 21.1549 14.6975L17.2618 16.945C16.9523 17.1236 16.7617 17.4538 16.7617 17.8111V22.2819C16.7617 22.6391 16.9523 22.9693 17.2618 23.1479L23.3997 26.6913C26.1138 24.8047 28.0968 21.914 28.7593 18.5102L22.1549 14.6975ZM22.8557 21.7886C21.5956 21.7886 20.5705 20.7635 20.5705 19.5035C20.5705 18.2435 21.5956 17.2184 22.8557 17.2184C24.1159 17.2184 25.141 18.2435 25.141 19.5035C25.141 20.7635 24.1159 21.7886 22.8557 21.7886Z" />
                <path d="M25.4458 10.9219C25.4458 11.3419 25.7875 11.6836 26.2076 11.6836C26.7632 11.6836 27.2407 11.0704 26.7883 10.3682C26.7848 10.3628 26.7814 10.3575 26.7782 10.352C26.0693 9.88042 25.4458 10.3631 25.4458 10.9219Z" />
                <path d="M22.8557 20.2652C23.2764 20.2652 23.6174 19.9242 23.6174 19.5035C23.6174 19.0828 23.2764 18.7418 22.8557 18.7418C22.435 18.7418 22.094 19.0828 22.094 19.5035C22.094 19.9242 22.435 20.2652 22.8557 20.2652Z" />
                <path d="M16.5001 24.4671C16.1907 24.2885 15.8095 24.2885 15.5001 24.4671L10.0952 27.5874C13.7788 29.4691 18.2144 29.4727 21.9051 27.5874L16.5001 24.4671Z" />
                <path d="M22.4063 12.5062C22.4063 12.8635 22.5969 13.1936 22.9063 13.3722L28.971 16.8734C29.0814 15.1991 28.88 13.481 28.3127 11.8093C27.9488 12.6714 27.1126 13.207 26.2075 13.207C24.9474 13.207 23.9222 12.1819 23.9222 10.9219C23.9222 9.46628 25.2826 8.34437 26.7632 8.7036C25.6491 7.06783 24.1557 5.67546 22.4063 4.68182L22.4063 12.5062Z" />
            </g>
            <defs>
                <clipPath id="clip0_1481_23282">
                    <rect width="26" height="26" fill="white" transform="translate(3 3)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Cells;
