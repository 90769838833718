import Divider, { DividerVariant } from "components/designSystem/components/Divider";
import { AlwaysInline, Grow, InlineAlignItem } from "components/designSystem/containers/Inline";
import Spacer, { SpacerSpacing } from "components/designSystem/containers/Spacer";
import Typography, { TypographyColor, TypographyVariant } from "components/designSystem/containers/Typography";
import React, { FC, ReactNode } from "react";

interface TextViewProps {
    title: ReactNode,
    titleColor?: TypographyColor,
    description :ReactNode,
    descriptionColor?: TypographyColor,
    action?: ReactNode,
    withDivider?: boolean
}

/**
 * https://zeroheight.com/3d9d5f5d4/p/68a3fb-text-views/b/01b2ef
 *
 * "Card" with title / description & cta
 */
const TextView: FC<TextViewProps> = (
    props
): JSX.Element => {

    return  <Spacer spacing={SpacerSpacing.SMALL}>
        <AlwaysInline alignItems={InlineAlignItem.CENTER}>
            <Grow>
                <Spacer spacing={SpacerSpacing.SMALL}>
                    <div>
                        <Typography variant={TypographyVariant.CAPTION} color={props.titleColor}>
                            {props.title}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant={TypographyVariant.LABEL} color={props.descriptionColor}>
                            {props.description}
                        </Typography>
                    </div>

                </Spacer>
            </Grow>
            {props.action}
        </AlwaysInline>
        {
            props.withDivider &&
            <Divider variant={DividerVariant.DEFAULT}/>
        }
    </Spacer>
}

export default TextView;
