import {
    API_TRIGGER_NOTIFICATION_BY_IDENTIFIER,
    TriggerNotificationByIdentifierRequest,
} from 'actions/user/NotificationAction';
import { AnyIterator } from 'core/utils/Typed';
import { put, takeLatest } from '@redux-saga/core/effects';
import { APICallAction } from 'actions/ActionInterface';
import CallHandler from 'sagas/api/CallHandler';
import { triggerNotificationByIdentifier } from 'sagas/api/Endpoints';
import { pushAPIFeedbackMessage } from 'actions/common/APIMessaginActions';
import { TransDomain, TransKey } from 'components/pages/common/MainComponent';
import { APIFeedbackMessageType } from 'models/common/APIMessaging';

export const triggerNotificationByIdentifierSaga = function* (
    action: APICallAction<TriggerNotificationByIdentifierRequest>,
): AnyIterator {
    const result = yield CallHandler.for(triggerNotificationByIdentifier, action)
        .call();

    if (result) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier || null,
                new TransKey('trigger_by_identifier_email_sent', TransDomain.ACCOUNT_NOTIF),
                APIFeedbackMessageType.SUCCESS,
            ),
        );
    }
};

export default function* notificationsSagas(): AnyIterator {
    yield takeLatest(API_TRIGGER_NOTIFICATION_BY_IDENTIFIER, triggerNotificationByIdentifierSaga);
}
