import React from 'react';

const SmallArrowUp = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.80392 20.8581C6.2387 20.1798 6.33034 19.1718 7.0086 18.6066L15.1718 11.8039C15.7647 11.3098 16.6258 11.3098 17.2187 11.8039L25.3819 18.6066C26.0602 19.1718 26.1518 20.1798 25.5866 20.8581C25.0214 21.5364 24.0133 21.628 23.3351 21.0628L16.1953 15.1129L9.05544 21.0628C8.37718 21.628 7.36914 21.5364 6.80392 20.8581L7.08133 20.6269L6.80392 20.8581Z"
            />
        </svg>

    )
}

export default SmallArrowUp;
