import React from 'react';

const Allergy = (): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32">
            <path d="M26.0121 5.52119C26.0121 4.84778 25.7498 4.21464 25.2737 3.73846C24.7975 3.26229 24.1643 3 23.4909 3C22.8174 3 22.1843 3.26229 21.7081 3.73846L20.0293 5.41724L19.2159 4.60382C18.9185 4.30635 18.4362 4.30635 18.1387 4.60382C17.8412 4.9013 17.8412 5.38357 18.1387 5.68105L18.4655 6.00782L11.6366 12.8367C10.5705 13.9029 9.75948 15.217 9.28331 16.6471L7.68258 18.2478C7.3851 18.5453 7.3851 19.0276 7.68258 19.3251L9.68707 21.3295C9.83581 21.4783 10.0308 21.5527 10.2257 21.5527C10.4206 21.5527 10.6156 21.4783 10.7642 21.3295L12.365 19.7288C13.7952 19.2526 15.1092 18.4417 16.1753 17.3755L21.8119 11.7389L23.0042 10.5466L23.331 10.8734C23.4798 11.0221 23.6747 11.0965 23.8696 11.0965C24.0645 11.0965 24.2595 11.0222 24.4082 10.8734C24.7057 10.5759 24.7057 10.0936 24.4082 9.79616L23.5948 8.98274L25.2736 7.30396C25.7498 6.82779 26.0121 6.19465 26.0121 5.52119ZM20.958 10.4384H16.1894L19.5427 7.0851L21.9271 9.46938L20.958 10.4384Z" />
            <path d="M7.86056 22.4538C8.00148 22.2398 8.24051 22.1111 8.4967 22.1111C8.75289 22.1111 8.99192 22.2398 9.13284 22.4537C9.57225 23.1209 11.0059 25.3835 11.0059 26.4908C11.0059 27.8744 9.88024 29 8.4967 29C7.11317 29 5.98755 27.8744 5.98755 26.4909C5.98755 25.3836 7.42115 23.1209 7.86056 22.4538Z" />
        </svg>
    )
}

export default Allergy;
