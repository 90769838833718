import { buildKey } from 'components/commonDesign/charts/builders/utils';
import ChartComponent from 'components/commonDesign/charts/ChartComponent';
import { CHART_TEXT_SPACING } from 'components/commonDesign/charts/constants';
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";
import Container, {
    ContainerProfile,
    MarginType,
    PaddingType
} from 'components/legacyDesignSystem/components/Container';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';

import BaseModule, { defaultBaseModuleState, ModuleState, setupModule } from 'components/modules/modular/BaseModule';
import { cast } from 'core/utils/Typed';
import { Chart, ChartFilter, ChartFilterType } from 'models/charts/charts';
import { DistributionPayload, ExtendedDistributionPayload } from 'models/charts/charts/Distribution';
import { APIText } from 'models/common/message';
import { ModuleMenu } from 'models/modular/menu';
import { ModuleData } from 'models/modular/storage';
import React from 'react';

export interface SimpleChartModulePagePayload {
    title: APIText
    sub_title: APIText
    chart: Chart
    allowed_context_selector_values: string[] | null
    menu: ModuleMenu | null
}

interface LocalState {
    clicked?: boolean
}

const INTERNAL_CONTEXT_KEY_SPLITTER = 'context_key_splitter'
const INTERNAL_CONTEXT_KEY_MIN_X = 'context_key_min_x'
const INTERNAL_CONTEXT_KEY_MAX_X = 'context_key_max_x'

class SimpleChartModule extends BaseModule<{}, SimpleChartModulePagePayload, {}> {

    state: ModuleState<LocalState> = defaultBaseModuleState(
        {}
    )

    protected _render(payload: ModuleData<SimpleChartModulePagePayload, {}>): React.ReactNode {
        let filters: ChartFilter[] | undefined = undefined;

        if (payload.pagePayload.allowed_context_selector_values) {
            const value = this.getConnectedContextValue(INTERNAL_CONTEXT_KEY_SPLITTER)
            if (value && payload.pagePayload.allowed_context_selector_values.includes(value)) {
                filters = []

                payload.pagePayload.chart.items.forEach(
                    (i) => {
                        cast<ChartFilter[]>(filters).push(
                            {
                                type: ChartFilterType.DATA_KEY_SELECTOR,
                                payload: {
                                    keys: [buildKey(i.unique_id, value)]
                                }
                            }
                        )
                    }
                )
            }
        }

        const minX = this.getConnectedContextValue(INTERNAL_CONTEXT_KEY_MIN_X);
        const maxX = this.getConnectedContextValue(INTERNAL_CONTEXT_KEY_MAX_X);

        if (minX !== null || maxX !== null) {
            if (filters === undefined) {
                filters = []
            }

            filters.push(
                {
                    type: ChartFilterType.X_FILTER,
                    payload: {
                        min: minX == null ? undefined : parseInt(minX),
                        max: maxX == null ? undefined : parseInt(maxX),
                    }
                }
            )
        }

        const chart: Chart<ExtendedDistributionPayload> | null = cast<Chart<DistributionPayload>>(payload.pagePayload.chart)

        if (chart && chart.payload) {
            chart.payload = {
                ...chart.payload,
                onXSelectorChange: (min: number, max: number, globalMin: number, globalMax: number): void => {
                    this.props.alterContext(
                        this.getConnectedContextKey(INTERNAL_CONTEXT_KEY_MIN_X),
                        min !== globalMin ? min.toString() : undefined
                    )

                    this.props.alterContext(
                        this.getConnectedContextKey(INTERNAL_CONTEXT_KEY_MAX_X),
                        max !== globalMax ? max.toString() : undefined
                    )

                }
            }
        }

        const displayLoader = payload.twinkle || !chart
        const content = <Container profiles={[{type: PaddingType.P, value: 4}]}>
            <Container profiles={[{type: MarginType.MB, value: 4}]}>
                <Container profiles={[
                    ContainerProfile.FLEX_WRAP,
                    ContainerProfile.SPACE_BETWEEN,
                    ContainerProfile.ALIGN_CENTER,
                ]}>
                    {payload.pagePayload.title && (
                        <Typography weight={TypographyWeight.BOLD} variant={TypographyVariant.BODY}>
                            {this.transApiText(payload.pagePayload.title)}
                        </Typography>
                    )}
                    {
                        payload.pagePayload.menu &&
                        <div>
                            {this.buildModuleMenu(payload.pagePayload.menu)}
                        </div>
                    }

                </Container>
                {
                    payload.pagePayload.sub_title &&
                    <Typography
                        weight={TypographyWeight.REGULAR}
                        variant={TypographyVariant.CAPTION}
                    >{this.transApiText(payload.pagePayload.sub_title)}</Typography>
                }
            </Container>
            {
                chart &&
                <ChartComponent
                    chart={chart}
                    translation={this.transApiText}
                    margin={{bottom: CHART_TEXT_SPACING, left: CHART_TEXT_SPACING}}
                    filters={filters}
                />
            }

        </Container>

        return <Container profiles={[ContainerProfile.BORDER_MODULE]} style={{backgroundColor: "white"}}>
            {displayLoader ? <ModuleLoaderWrapper>{content}</ModuleLoaderWrapper> : content}
        </Container>
    }
}

export default setupModule(SimpleChartModule, () => ({}), () => ({}));
