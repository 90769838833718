export enum Env {
    DEV = 'development',
    PROD = 'production',
    TEST = 'test',
}

export const ENV: Env = Object.values(Env).filter((value) => process.env.NODE_ENV === value.toString())[0];

export enum PlatformType {
    DEFAULT = 'DEFAULT',
    EVIDENCE = 'EVIDENCE'
}

type ConfigType = {
    env: string;
    platform: PlatformType;
    apiUrl: string;
    contactLinks: {
        doctor: string;
        patient: string;
    };
    cdnUrl: string;
    mailAddresses: {
        nepasrepondre: string;
        patients: string;
        info: string;
    }
};

export function getAPIUrl(env: Env, hostname: string): string {
    if (env === Env.DEV || env === Env.TEST) {
        if (['app.kiro.local', 'evidence.kiro.local'].includes(hostname)) {
            return 'http://api.kiro.local';
        }

        return 'http://api.byond.local';
    }

    if (hostname === 'app.staging.byond.bio') {
        return 'https://api.staging.byond.bio';
    }

    const rgx = new RegExp('^app\\.staging(\\d*)\\.kiro\\.bio$')

    const stagingResult = rgx.exec(hostname)
    if (stagingResult) {
        return `https://api.staging${stagingResult[1]}.kiro.bio`;
    }

    if (hostname === 'evidence.staging.kiro.bio') {
        return 'https://api.staging.kiro.bio';
    }

    if (hostname === 'app.clinical.kiro.bio') {
        return 'https://api.clinical.kiro.bio';
    }

    if (hostname === 'app.kiro.bio') {
        return 'https://api.kiro.bio';
    }

    if (hostname === 'evidence.kiro.bio') {
        return 'https://api.kiro.bio';
    }

    return 'https://api.byond.bio';
}

function getPlatform() : PlatformType {
    if (['evidence.kiro.bio', 'evidence.staging.kiro.bio', 'evidence.kiro.local'].includes(window.location.hostname)){
        return PlatformType.EVIDENCE;
    }
    return PlatformType.DEFAULT;
}

const CONFIG: ConfigType = {
    env: ENV,
    apiUrl: getAPIUrl(ENV, window.location.hostname),
    platform: getPlatform(),
    contactLinks: {
        doctor:
            'https://docs.google.com/forms/d/e/1FAIpQLSeIaL9z0NyHKaZxmvpx04sIUYom0_sHbgU163I0zQCHK4RO6A/viewform?usp=pp_url&entry.469392113=$uuid',
        patient:
            'https://docs.google.com/forms/d/e/1FAIpQLScTVLKnjU7XZQIcNooNlmNA7mlMcmAp0dKgIQMBfsNtlLJqoQ/viewform?usp=pp_url&entry.469392113=$uuid',
    },
    cdnUrl: 'https://cdn.kiro.bio',
    mailAddresses: {
        nepasrepondre: 'nepasrepondre@kiro.bio',
        patients: 'patients@kiro.bio',
        info: 'info@kiro.bio'
    }
};

export function envIs(...envs: Env[]): boolean {
    return envs.indexOf(ENV) >= 0;
}

export default CONFIG;
