import { defineAxisDataType } from "components/commonDesign/charts/builders/utils";
import { DEFAULT_CHART_HEIGHT } from 'components/commonDesign/charts/constants';
import { toChartColor } from 'components/commonDesign/utils/style';
import { Colors } from "components/legacyDesignSystem/branding/constants";
import { buildAxis } from 'components/legacyDesignSystem/components/charts/builders/Axis';
import { buildSimpleBar } from 'components/legacyDesignSystem/components/charts/builders/Bar';
import { AxisDirection } from 'components/legacyDesignSystem/components/charts/builders/model';
import { renderToolip } from "components/legacyDesignSystem/components/charts/builders/Tooltip";
import { ChartContainer } from 'components/legacyDesignSystem/components/charts/ChartContainer';
import Container, { ContainerProfile, MarginType } from "components/legacyDesignSystem/components/Container";
import { IndicatorDot } from "components/legacyDesignSystem/components/Indicator";
import Typography, { TypographyVariant, TypographyWeight } from "components/legacyDesignSystem/components/Typography";
import { cast } from 'core/utils/Typed';
import { ChartItemHistogramBar } from 'models/charts/items/HistogramBar';
import React, { Fragment } from 'react';
import { ChartFilter, ChartItem, ChartItemType } from 'models/charts/charts';
import {
    ExtendedHistogramPayload,
    HistogramFormattedTooltipProps
} from 'models/charts/charts/Histogram';
import { ValueFormatterInterface } from 'models/charts/models';
import { TranslationMethod } from 'models/common/message';
import { BarChart, Legend, Tooltip } from 'recharts';
import { Formatter } from "recharts/types/component/DefaultLegendContent";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";

type BarPoint = Record<string, unknown>;
const X_AXIS = '__x';

function buildHistogramBarItem(payload: ChartItemHistogramBar, acceptedKeys: string[] | undefined): BarPoint[] {
    return Object.entries(payload.data).map(([k, v]) => {
        const i: BarPoint = {};
        i[X_AXIS] = parseFloat(k);

        Object.entries(v).forEach((ki) => {
            const key = ki[0];
            if (acceptedKeys !== undefined && !acceptedKeys.includes(key)) {
                return;
            }

            i[key] = ki[1];
        });
        return i;
    });
}

function buildData(items: ChartItem[]): BarPoint[] {
    let results: BarPoint[] = [];

    const acceptedKeys: string[] | undefined = undefined;

    items.forEach((i) => {
        switch (i.type) {
            case ChartItemType.HISTOGRAM_BAR:
                results = [...results, ...buildHistogramBarItem(cast<ChartItemHistogramBar>(i.payload), acceptedKeys)];
                break;
        }
    });
    return results;
}

function buildHistogramItem(payload: ExtendedHistogramPayload, translation: TranslationMethod): JSX.Element[] {
    return payload.groups.map((group) =>
        buildSimpleBar(
            group.key,
            // @ts-ignore
            translation(group.name),
            toChartColor(group.color_type),
            !!payload.chart_item_scale?.max
        ),
    );
}

function FormattedTooltip(props: HistogramFormattedTooltipProps): JSX.Element | null {
    const {groups, x_ticks, translation, payload} = props;

    if (!payload) return null;

    // Dirty as fuck todo revamp FormattedTooltip

    const first_element = Object.values(payload)[0];
    const x_key = first_element != null ? first_element.payload[X_AXIS] : null;
    const overrided_label = x_key != null && x_ticks && x_ticks[x_key];
    const x_label = x_ticks != null && overrided_label ? x_ticks[x_key].tooltip_label : x_key;

    return renderToolip(
        <Fragment>
            <Container
                profiles={[
                    ContainerProfile.ALIGN_BASELINE,
                    ContainerProfile.SPACE_BETWEEN,
                    {
                        type: MarginType.MB,
                        value: 1,
                    },
                ]}
            >
                <Typography
                    weight={TypographyWeight.REGULAR}
                    variant={TypographyVariant.CAPTION}
                    color={Colors.NEUTRALS_DARK_BLUE_GREY}
                >
                    {overrided_label ? translation(x_label) : x_label}
                </Typography>
            </Container>

            {payload?.map((item, i) => {
                const group = groups.find((g) => {
                    if (item.dataKey && typeof item.dataKey === 'string') {
                        return g.key == item.dataKey
                    }
                });
                return (
                    <Container
                        key={i}
                        profiles={[
                            ContainerProfile.ALIGN_BASELINE,
                            {
                                type: MarginType.MB,
                                value: 2,
                            },
                        ]}
                    >
                        {group && (
                            <IndicatorDot
                                key={group.key}
                                severity={group.color_type}
                                profiles={[{type: MarginType.MR, value: 2}]}
                            />
                        )}
                        <Typography
                            weight={TypographyWeight.REGULAR}
                            variant={TypographyVariant.SMALL}
                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                        >
                            {group && translation(group.name)} : {item.value}
                        </Typography>
                    </Container>
                );
            })}
        </Fragment>,
    );
}

export function buildHistogram(
    items: ChartItem[],
    payload: ExtendedHistogramPayload,
    translation: TranslationMethod,
    filters?: ChartFilter[],
    valueFormatter?: ValueFormatterInterface,
): JSX.Element {
    const data = buildData(items);

    const legendFormatter: Formatter = (value?: Payload<ValueType, NameType>['value']): string | React.ReactNode => {
        if (payload.groups) {
            const override = payload.groups.find(group => group.key === value)
            if (override) {
                return translation(override.name)
            }
        }
        return value
    }

    return (
        <Fragment>
            <ChartContainer height={DEFAULT_CHART_HEIGHT}>
                <BarChart data={data} barCategoryGap={'25%'}>
                    {payload.with_legend && payload.groups.length > 0 && (
                        <Legend
                            iconType="circle"
                            iconSize={12}
                            verticalAlign="top"
                            align="right"
                            wrapperStyle={{bottom: '100%'}}
                            formatter={legendFormatter}
                        />
                    )}
                    {payload.with_tooltips ?
                        <Tooltip
                            cursor={false}
                            content={
                                <FormattedTooltip
                                    groups={payload.groups}
                                    x_ticks={payload.x_ticks}
                                    translation={translation}
                                />}
                        />
                        : undefined}
                    {buildAxis(
                        'xAxis',
                        AxisDirection.X,
                        defineAxisDataType(payload.x_axis.type),
                        X_AXIS,
                        undefined,
                        undefined,
                        valueFormatter,
                        undefined,
                        payload.x_ticks,
                    )}
                    {buildAxis(
                        'yAxis',
                        AxisDirection.Y,
                        defineAxisDataType(payload.y_axis.type),
                        undefined,
                        payload.chart_item_scale && payload.chart_item_scale.min?.value
                            ? payload.chart_item_scale.min?.value
                            : undefined,
                        payload.chart_item_scale && payload.chart_item_scale.max?.value
                            ? payload.chart_item_scale.max?.value
                            : undefined,
                        valueFormatter,
                        undefined, undefined,
                    )}

                    {buildHistogramItem(payload, translation)}
                </BarChart>
            </ChartContainer>
        </Fragment>
    );
}
