import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const SuccessCircle = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-success-circle'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_180_227)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 6.53033C12.3232 6.23744 12.3232 5.76256 12.0303 5.46967C11.7374 5.17678 11.2626 5.17678 10.9697 5.46967L7 9.43934L5.03033 7.46967C4.73744 7.17678 4.26256 7.17678 3.96967 7.46967C3.67678 7.76256 3.67678 8.23744 3.96967 8.53033L6.46967 11.0303C6.76256 11.3232 7.23744 11.3232 7.53033 11.0303L12.0303 6.53033Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_180_227">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-success-circle'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_180_226)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM18.4849 9.20711C18.8754 8.81658 18.8754 8.18342 18.4849 7.79289C18.0944 7.40237 17.4612 7.40237 17.0707 7.79289L10.8889 13.9747L7.70711 10.7929C7.31658 10.4024 6.68342 10.4024 6.29289 10.7929C5.90237 11.1834 5.90237 11.8166 6.29289 12.2071L10.1818 16.096C10.5723 16.4865 11.2055 16.4865 11.596 16.096L18.4849 9.20711Z"
                      fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_180_226">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}