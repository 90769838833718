
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import styles from "./SideBar.module.scss"
import { IconName, IconSize } from 'components/designSystem/foundations/IconsData';
import Icon from 'components/designSystem/foundations/Icons';


export interface SideMenuItemProps {
    icon: IconName,
    name: ReactNode,
    onClick: () => void,
    isSelected?: boolean,
    notifications?: number
}

export type SideBarProps = PropsWithChildren<{
    menuItems?: SideMenuItemProps[]
    sticky?: boolean
    forceExpand?: boolean
    clickOutside?: () => void
}>

export const SideMenuItem = (props: SideMenuItemProps): JSX.Element => (
    <button
        onClick={props.onClick}
        className={classNames(
            styles.item,
            {[styles.itemSelected]: props.isSelected })}
    >
        <div className={"side-menu-item-icon"}>
            <Icon name={props.icon} size={IconSize.SMALL}/>
        </div>
        <div className={styles.itemName}>
            {props.name}
        </div>
    </button>
)


const SideBar: FC<SideBarProps> = (
    props
): JSX.Element => {

    return <div id="side-bar" className={
        classNames(
            styles.sideBar, {
                [styles.sideBarSticky]: props.sticky,
                [styles.sideBarForceExpand]: props.forceExpand,
            })}
                onClick={(): void => {
                    if (props.clickOutside) {
                        props.clickOutside()
                    }
                }}
    >
        <div className={styles.container} onClick={(e): void => e.stopPropagation()}>
            <div className={styles.menuItems}>
                {props.menuItems && props.menuItems.map(
                    (item, index) => <SideMenuItem key={index} {...item} />
                )}
            </div>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    </div>

}

export default SideBar;
