import {
    buildKey,
    buildNormalityLevel,
    buildValueDisplay,
    buildValueToolip
} from 'components/commonDesign/charts/builders/utils';
import { DEFAULT_CHART_HEIGHT } from 'components/commonDesign/charts/constants';
import { buildArea } from 'components/legacyDesignSystem/components/charts/builders/Area';
import { buildAxis } from 'components/legacyDesignSystem/components/charts/builders/Axis';
import { buildLine } from 'components/legacyDesignSystem/components/charts/builders/Line';
import { AxisDataType, AxisDirection } from 'components/legacyDesignSystem/components/charts/builders/model';
import { buildPointer } from 'components/legacyDesignSystem/components/charts/builders/Pointer';
import { ValueDirection } from 'components/legacyDesignSystem/components/charts/builders/Value';
import { ChartContainer } from 'components/legacyDesignSystem/components/charts/ChartContainer';
import { NormalityLevel } from 'components/legacyDesignSystem/components/charts/models';
import { DateTimeFormat, formatDateTime } from "core/utils/Date";
import { cast } from 'core/utils/Typed';
import { ChartItem, ChartItemType } from 'models/charts/charts';
import { TimeSelector, TimeSelectorType, TimeSeriePayload } from 'models/charts/charts/TimeSerie';
import { ChartItemBioValue, ChartItemBioValuePointValueNormalityZone } from 'models/charts/items/BioValue';
import { ChartNormality, ChartTag } from 'models/charts/models';
import { APIText } from 'models/common/message';
import { Value } from 'models/medicalReport/LegacyReportModels';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { ComposedChart, Label, Tooltip } from 'recharts';
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { CartesianViewBox } from "recharts/types/util/types";
import { Margin } from "recharts/types/util/types";
import _ from 'underscore';

type  PointPayload = {date: number} & Record<string, unknown>;

const KEY_BIO_VALUE_VALUE = 'value';
const KEY_BIO_VALUE_DISPLAY = 'display';
const KEY_BIO_VALUE_NORMALITY_ZONE = 'normalityZone';
const KEY_BIO_VALUE_NORMALITY_LEVEL = 'normalityLevel';

export function buildNormalityZone(references: ChartItemBioValuePointValueNormalityZone[]): [number|undefined, number|undefined]{
    const supportedReferences = references.filter(
        (r) => [ChartNormality.HIGH, ChartNormality.LOW].includes(r.normality)
    );

    if (references.length == 0) {
        return [undefined, undefined]
    }
    const result: [number|undefined, number|undefined] = [0, Infinity]

    supportedReferences.forEach(
        (r) => {
            if (r.normality === ChartNormality.HIGH) {
                result[1] = r.value
            } else if (r.normality === ChartNormality.LOW) {
                result[0] = r.value
            }
        }
    )

    return result
}



function buildBioValueLineData(prefix: string, payload: ChartItemBioValue): PointPayload[] {
    return payload.points.map(
        (p) => {
            const result: PointPayload = {date: (new Date(p.date)).getTime()};
            result[buildKey(prefix, KEY_BIO_VALUE_VALUE)] = p.value.value
            result[buildKey(prefix, KEY_BIO_VALUE_DISPLAY)] = p.display_value
            result[buildKey(prefix, KEY_BIO_VALUE_NORMALITY_ZONE)] = buildNormalityZone(p.value.references)
            result[buildKey(prefix, KEY_BIO_VALUE_NORMALITY_LEVEL)] = buildNormalityLevel(p.tags)
            return result
        }
    )
}

interface TooltipKey {
    dataKey: string,
    displayKey: string
}

function getTooltipKeys(items: ChartItem[]): TooltipKey[] {

    const results: TooltipKey[] = []
    items.map(
        (i) => {
            switch (i.type) {
                case ChartItemType.BIO_VALUE_LINE:
                    results.push(
                        {
                            dataKey: buildKey(i.unique_id, KEY_BIO_VALUE_VALUE),
                            displayKey: buildKey(i.unique_id, KEY_BIO_VALUE_DISPLAY)
                        }

                    )
                    break
            }
        }
    )

    return results
}

function buildData(items: ChartItem[]): PointPayload[] {

    let results: PointPayload[] = []

    items.forEach(
        (i) => {
            switch (i.type) {
                case ChartItemType.BIO_VALUE_LINE:
                    results = [
                        ...results,
                        ...buildBioValueLineData(
                            i.unique_id,
                            cast<ChartItemBioValue>(i.payload)
                        )
                    ];
                    break
            }
        }
    )

    return results
}


function buildBioValueLine(
    prefix:string,
    payload: ChartItemBioValue,
    margin?: Partial<Margin>,
): JSX.Element[] {

    let minSelectedIndex = Infinity;

    const selectedPoints = payload.points.filter(
        (p, i) => {
            if (p.tags && p.tags.includes(ChartTag.SELECTED)) {
                if (i < minSelectedIndex) {
                    minSelectedIndex = i
                }
                return true
            }

            return false
        }
    );

    const topOverlay = (margin && margin.top ? -1 * margin.top : 0);
    const components = [
        buildArea(buildKey(prefix, KEY_BIO_VALUE_NORMALITY_ZONE)),
    ];

    if (selectedPoints && selectedPoints.length > 0) {
        selectedPoints.forEach(
            (selectedPoint) => {
                const normalityLevel = selectedPoint.tags && selectedPoint.tags.includes(ChartTag.ABNORMAL) ? NormalityLevel.ABNORMAL : NormalityLevel.NORMAL;
                components.push(
                    buildPointer(
                        (new Date(selectedPoint.date)).getTime(),
                        normalityLevel,
                        <Label
                            content={
                                (props): ReactElement[] => {
                                    const viewBox = props.viewBox as CartesianViewBox;
                                    if (viewBox.x == undefined) return []
                                    if (viewBox.y == undefined) return []
                                    const direction = viewBox.x > 100 ? ValueDirection.END : ValueDirection.START;
                                    return buildValueDisplay(
                                        selectedPoint.display_value,
                                        viewBox.x,
                                        viewBox.y,
                                        (direction === ValueDirection.END ? -1 : 1) * 16,
                                        topOverlay + 24,
                                        direction,
                                        normalityLevel
                                    )
                                }

                            }
                            position="insideTopRight"
                            fill="#696969"
                            offset={20}
                        />,
                        topOverlay !== 0 ? -1 * topOverlay - 20 : 0
                    )
                )
            }
        )

    }

    components.push(
        buildLine(
            buildKey(prefix, KEY_BIO_VALUE_VALUE),
            buildKey(prefix, KEY_BIO_VALUE_NORMALITY_LEVEL),
        ),
    )

    return components
}

export function computeTimeExtremum(items: ChartItem[], activeSelector?: TimeSelector): [number | undefined, number | undefined] {
    const dataPointTimestamps = items.flatMap((item) =>
        item.type === ChartItemType.BIO_VALUE_LINE ? cast<ChartItemBioValue>(item.payload).points.map((p) => new Date(p.date).getTime()) : []
    )

    if (dataPointTimestamps.length === 0) return [undefined, undefined]

    const minDataPointMomentTimestamp = _.min(dataPointTimestamps)
    const maxDataPointMomentTimestamp = _.max(dataPointTimestamps)
    switch (activeSelector?.type) {
        case undefined:
        case TimeSelectorType.ALL_TIME:
            return [minDataPointMomentTimestamp, maxDataPointMomentTimestamp]
        case TimeSelectorType.MONTH:
            return [moment(maxDataPointMomentTimestamp).subtract(activeSelector.quantity, "months").valueOf(), maxDataPointMomentTimestamp]
        case TimeSelectorType.YEAR:
            return [moment(maxDataPointMomentTimestamp).subtract(activeSelector.quantity, "years").valueOf(), maxDataPointMomentTimestamp]
    }
}

function computeValueExtremum(items: ChartItem[]): {rangeMin?: number, rangeMax?: number, refRange?: [number, number]} {
    const allPoints: number[] = []
    const referencePoints: number[] = []
    items.forEach(
        (item) => {
            if (item.type === ChartItemType.BIO_VALUE_LINE) {
                cast<ChartItemBioValue>(item.payload).points.forEach(
                    (point) => {
                        if (point.value) {
                            if (point.value.value !== undefined && point.value.value !== null) {
                                allPoints.push(point.value.value)
                            }

                            if (point.value.references) {
                                point.value.references.forEach(
                                    (ref) => {
                                        if (ref.value !== undefined && point.value.value !== null) {
                                            allPoints.push(ref.value)
                                            referencePoints.push(ref.value)
                                        }
                                    }
                                )
                            }
                        }
                    }
                )
            }
        }
    )

    if (allPoints.length === 0) {
        return {}
    }

    const rangeMin = 0.9 * _.min(allPoints);
    const rangeMax = 1.1 * _.max(allPoints);

    let refRange: [number, number] | undefined = undefined;
    if (referencePoints.length > 0) {
        const refMin = _.min(referencePoints);
        const refMax = _.max(referencePoints);
        if (refMin != refMax) {
            refRange = [refMin, refMax];
        }
    }

    return {rangeMin, rangeMax, refRange}
}

export function buildTimeSerie(
    items: ChartItem[],
    payload: TimeSeriePayload,
    margin?: Partial<Margin>,
    height?: number,
): JSX.Element {
    const [minTimestamp, maxTimestamp] = computeTimeExtremum(items, payload.selected);
    const {rangeMin, rangeMax, refRange} = computeValueExtremum(items)
    const tooltipKeys = getTooltipKeys(items);
    return <ChartContainer height={height ?? DEFAULT_CHART_HEIGHT}>
        <ComposedChart data={
            buildData(items)
        } margin={margin}>
            {
                buildAxis(
                    "xAxis",
                    AxisDirection.X,
                    AxisDataType.TIME,
                    'date',
                    minTimestamp,
                    maxTimestamp,
                )
            }
            {
                buildAxis(
                    "yAxis",
                    AxisDirection.Y,
                    AxisDataType.NUMERICAL,
                    undefined,
                    rangeMin,
                    rangeMax,
                    undefined,
                    undefined,
                    undefined,
                    refRange,
                    true
                )
            }
            {
                items.map(
                    (i) => {
                        switch (i.type) {
                            case ChartItemType.BIO_VALUE_LINE:
                                return buildBioValueLine(
                                    i.unique_id,
                                    cast<ChartItemBioValue>(i.payload),
                                    margin,
                                )
                        }
                    }
                )
            }

            <Tooltip
                key={"toolip"}
                content={
                    buildValueToolip(
                        (payloads: ReadonlyArray<Payload<ValueType, NameType>>): ({ date: string | undefined, value: APIText | Value | undefined })[] => {
                            const values: ({ date: string | undefined, value: APIText | Value | undefined })[] = []
                            payloads.forEach(
                                (p) => {
                                    tooltipKeys.forEach(
                                        (dk) => {
                                            if (p.dataKey === dk.dataKey && p.payload && p.payload[dk.displayKey]) {
                                                values.push({
                                                    date: formatDateTime(p.payload.date, DateTimeFormat.DATE_MEDIUM),
                                                    value: p.payload[dk.displayKey]
                                                })
                                            }
                                        })
                                }
                            )

                            return values
                        },
                    )
                }
            />

        </ComposedChart>
    </ChartContainer>
}
