import React, { Component, PropsWithChildren, ReactNode } from 'react';
import 'components/core/text/SimpleDropdownQuestionProps.scss';

export interface SimpleDropdownQuestionProps {
    question: ReactNode;
}

export default class SimpleDropdownQuestion extends Component<PropsWithChildren<SimpleDropdownQuestionProps>> {
    state: { opened: boolean } = { opened: false };

    switchOpen = (): void => {
        this.setState({ opened: !this.state.opened });
    };

    render(): ReactNode {
        const switchOpen = this.switchOpen;
        return (
            <span>
                <span className={'dropdown-question-question'} onClick={switchOpen}>
                    {this.props.question}
                </span>
                {this.state.opened && (
                    <div className={'dropdown-question-answer'}>
                        <i>{this.props.children}</i>
                    </div>
                )}
            </span>
        );
    }
}
