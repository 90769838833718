import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import * as React from 'react';

const FillCake = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.SMALL:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd" clipRule="evenodd"
                        d="M1.72119 19.9624C1.72119 21.205 2.72855 22.2124 3.97119 22.2124H20.0285C21.2711 22.2124 22.2785 21.205 22.2785 19.9624L22.2785 14.4041L22.2784 14.394L22.2784 14.3858L22.2783 14.3716L22.2777 14.3261C22.2772 14.2883 22.2763 14.2358 22.2746 14.17C22.2713 14.0386 22.2652 13.8535 22.2538 13.6267C22.2311 13.175 22.187 12.548 22.1005 11.8437C21.9386 10.5236 21.5924 8.59677 20.7485 7.11485C19.8683 5.56897 18.3038 4.25581 17.1917 3.42704C16.598 2.98465 16.0512 2.62126 15.6525 2.36798C15.4522 2.2408 15.2871 2.13997 15.1692 2.06938C15.1103 2.03405 15.063 2.0062 15.0289 1.9863L14.9879 1.96245L14.9751 1.95509L14.9677 1.95084C14.0648 1.43569 12.9266 1.60608 12.2141 2.36303L10.0271 4.68673C11.2048 5.3508 12 6.61341 12 8.06175C12 10.2002 10.2665 11.9337 8.12805 11.9337C6.7636 11.9337 5.56399 11.2279 4.87437 10.1615L2.33274 12.862C1.93992 13.2794 1.72119 13.8309 1.72119 14.4041V19.9624ZM14.9677 1.95084L13.8526 3.9051L14.8274 2.19629C14.9671 1.95136 14.9677 1.95084 14.9677 1.95084ZM20.0285 14.4041C22.2784 14.394 22.2784 14.394 22.2784 14.394L20.0285 14.4041ZM20.0284 18.617H3.97119V16.367H20.0284V18.617ZM3.97119 15.2203H20.0273V14.0841C20.0273 13.4576 19.9735 12.9703 19.9735 12.9703H5.31192L3.97119 14.3906V15.2203Z"
                        fill="currentColor"/>
                    <path
                        d="M6.43697 2.86917C6.668 2.52537 7.13399 2.43396 7.47779 2.66499L7.47994 2.66644L7.48345 2.66882L7.49441 2.6763L7.53112 2.70181C7.56175 2.72333 7.60444 2.75383 7.65602 2.79204C7.75866 2.86808 7.89919 2.97663 8.05139 3.10738C8.33308 3.34938 8.7417 3.73972 8.96547 4.18726C9.22525 4.70682 9.25216 5.32701 9.23056 5.7926C9.22832 5.84092 9.22155 5.88796 9.21069 5.93333C10.1214 6.29724 10.7648 7.18757 10.7648 8.22819C10.7648 9.5925 9.65885 10.6985 8.29453 10.6985C6.93022 10.6985 5.82422 9.5925 5.82422 8.22819C5.82422 7.05667 6.63972 6.07562 7.73401 5.82174C7.73128 5.78936 7.73063 5.75642 7.73217 5.72309C7.75055 5.32699 7.7073 5.02502 7.62383 4.85808C7.54252 4.69547 7.33355 4.46821 7.07392 4.24516C6.95546 4.14339 6.8444 4.05755 6.76311 3.99733C6.72273 3.96741 6.69037 3.94432 6.66895 3.92927L6.64557 3.91302L6.64115 3.90999C6.29749 3.67892 6.20598 3.21291 6.43697 2.86917Z"
                        fill="currentColor"/>
                </svg>
            )
        default:
            return <div/>
    }
};

export default FillCake;