import {
    GivePatientAccessPayload,
    OnSuccessActionType,
    OnSuccessPayload, RedirectToPagePayload
} from "models/medicalReport/ConnectionModesModel";
import { OnSuccessPutElementType } from "actions/ActionInterface";
import { cast } from "core/utils/Typed";
import {
    FEEDBACK_IDENTIFIER_API_GIVE_PATIENT_ACCESS,
    givePatientAccess
} from "actions/medicalReports/MedicalReportsFetchActions";
import { redirect } from "actions/common/CommonActions";
import { getRoute } from "core/routing/Helper";
import { ROUTE_MODULAR_PAGE } from "core/routing/Routes";

export function buildOnSuccessAction(
    on_success: OnSuccessPayload,
    fromSubscription = false
): OnSuccessPutElementType | undefined {
    if (!on_success) {
        return undefined
    }

    if (on_success.type == OnSuccessActionType.GIVE_PATIENT_ACCESS) {
        const on_success_payload = cast<GivePatientAccessPayload>(on_success);
        return givePatientAccess(
            on_success_payload.token, {}, true, FEEDBACK_IDENTIFIER_API_GIVE_PATIENT_ACCESS, fromSubscription
        )
    }
    if (on_success.type == OnSuccessActionType.REDIRECT_TO_PAGE) {
        const on_success_payload = cast<RedirectToPagePayload>(on_success);
        const params = Object.assign({}, on_success_payload.context, {'pageId': on_success_payload.page_id})
        return redirect(getRoute(ROUTE_MODULAR_PAGE, params))
    }
    return undefined
}