import React, { FC, PropsWithChildren } from 'react';

import styles from "components/designSystem/containers/Typography.module.scss"
import classNames from 'classnames';

export enum TypographyVariant {
    DISPLAY_X_LARGE = 'DISPLAY_X_LARGE',
    DISPLAY_LARGE = 'DISPLAY_LARGE',
    DISPLAY_MEDIUM = 'DISPLAY_MEDIUM',
    DISPLAY_MEDIUM_700 = 'DISPLAY_MEDIUM_700',
    DISPLAY_SMALL = 'DISPLAY_SMALL',
    DISPLAY_SMALL_500 = 'DISPLAY_SMALL_500',
    DISPLAY_SMALL_700 = 'DISPLAY_SMALL_700',
    DISPLAY_X_SMALL = 'DISPLAY_X_SMALL',
    DISPLAY_X_SMALL_500 = 'DISPLAY_X_SMALL_500',
    DISPLAY_X_SMALL_700 = 'DISPLAY_X_SMALL_700',
    BUTTON = 'BUTTON',
    LABEL = 'LABEL',
    LABEL_500 = 'LABEL_500',
    LABEL_700 = 'LABEL_700',
    BODY = 'BODY',
    BODY_500 = 'BODY_500',
    BODY_700 = 'BODY_700',
    CAPTION = 'CAPTION',
    CAPTION_500 = 'CAPTION_500',
    CAPTION_700 = 'CAPTION_700',
    OVERLINE = 'OVERLINE',
    OVERLINE_SMALL = 'OVERLINE_SMALL',
}

export enum TypographyColor {
    COLOR_TEXT_DEFAULT = 'COLOR_TEXT_DEFAULT',
    COLOR_TEXT_SUBDUED = 'COLOR_TEXT_SUBDUED',
    COLOR_TEXT_DISABLED = 'COLOR_TEXT_DISABLED',
    COLOR_TEXT_ON_PRIMARY = 'COLOR_TEXT_ON_PRIMARY',
    COLOR_TEXT_SUCCESS = 'COLOR_TEXT_SUCCESS',
    COLOR_TEXT_WARNING = 'COLOR_TEXT_WARNING',
    COLOR_TEXT_CRITICAL = 'COLOR_TEXT_CRITICAL'
}

interface TypographyProps {
    variant?: TypographyVariant
    color?: TypographyColor
    italic?: boolean
}

const Typography: FC<TypographyProps> = (
    {
        variant = TypographyVariant.BODY,
        ...props
    }
): JSX.Element => {
    return <span className={
        classNames(
            styles.typography,
            {
                [styles.typographyVariantDisplayXLarge]: variant === TypographyVariant.DISPLAY_X_LARGE,
                [styles.typographyVariantDisplayLarge]: variant === TypographyVariant.DISPLAY_LARGE,
                [styles.typographyVariantDisplayMedium]: variant === TypographyVariant.DISPLAY_MEDIUM,
                [styles.typographyVariantDisplayMediumS700]: variant === TypographyVariant.DISPLAY_MEDIUM_700,
                [styles.typographyVariantDisplaySmall]: variant === TypographyVariant.DISPLAY_SMALL,
                [styles.typographyVariantDisplaySmallS500]: variant === TypographyVariant.DISPLAY_SMALL_500,
                [styles.typographyVariantDisplaySmallS700]: variant === TypographyVariant.DISPLAY_SMALL_700,
                [styles.typographyVariantDisplayXSmall]: variant === TypographyVariant.DISPLAY_X_SMALL,
                [styles.typographyVariantDisplayXSmallS500]: variant === TypographyVariant.DISPLAY_X_SMALL_500,
                [styles.typographyVariantDisplayXSmallS700]: variant === TypographyVariant.DISPLAY_X_SMALL_700,
                [styles.typographyVariantButton]: variant === TypographyVariant.BUTTON,
                [styles.typographyVariantLabel]: variant === TypographyVariant.LABEL,
                [styles.typographyVariantLabelS500]: variant === TypographyVariant.LABEL_500,
                [styles.typographyVariantLabelS700]: variant === TypographyVariant.LABEL_700,
                [styles.typographyVariantBody]: variant === TypographyVariant.BODY,
                [styles.typographyVariantBodyS500]: variant === TypographyVariant.BODY_500,
                [styles.typographyVariantBodyS700]: variant === TypographyVariant.BODY_700,
                [styles.typographyVariantCaption]: variant === TypographyVariant.CAPTION,
                [styles.typographyVariantCaptionS500]: variant === TypographyVariant.CAPTION_500,
                [styles.typographyVariantCaptionS700]: variant === TypographyVariant.CAPTION_700,
                [styles.typographyVariantOverline]: variant === TypographyVariant.OVERLINE,
                [styles.typographyVariantOverlineSmall]: variant === TypographyVariant.OVERLINE_SMALL,

                [styles.typographyColorTextDefault]: props.color === TypographyColor.COLOR_TEXT_DEFAULT,
                [styles.typographyColorTextSubdued]: props.color === TypographyColor.COLOR_TEXT_SUBDUED,
                [styles.typographyColorTextDisabled]: props.color === TypographyColor.COLOR_TEXT_DISABLED,
                [styles.typographyColorTextOnPrimary]: props.color === TypographyColor.COLOR_TEXT_ON_PRIMARY,
                [styles.typographyColorTextSuccess]: props.color === TypographyColor.COLOR_TEXT_SUCCESS,
                [styles.typographyColorTextWarning]: props.color === TypographyColor.COLOR_TEXT_WARNING,
                [styles.typographyColorTextCritical]: props.color === TypographyColor.COLOR_TEXT_CRITICAL,
                
                [styles.typographyFontStyleItalic]: props.italic
            }
        )

    }>
        {props.children}
    </span>
}

type TypographySubPropsProps = PropsWithChildren<{color?: TypographyColor, italic?: boolean}>

export const TDisplayXLarge = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_X_LARGE} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplayLarge = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_LARGE} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplayMedium = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_MEDIUM} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplayMedium700 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_MEDIUM_700} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplaySmall = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_SMALL} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplaySmall500 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_SMALL_500} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplaySmall700 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_SMALL_700} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplayXSmall = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_X_SMALL} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplayXSmall500 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_X_SMALL_500} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TDisplayXSmall700 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.DISPLAY_X_SMALL_700} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TButton = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.BUTTON} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TLabel = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.LABEL} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TLabel500 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.LABEL_500} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TLabel700 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.LABEL_700} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TBody = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.BODY} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TBody500 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.BODY_500} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TBody700 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.BODY_700} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TCaption = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.CAPTION} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TCaption500 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.CAPTION_500} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TCaption700 = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.CAPTION_700} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TOverline = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.OVERLINE} color={props.color} italic={props.italic}>{props.children}</Typography>;
export const TOverlineSmall = (props: TypographySubPropsProps): JSX.Element => <Typography variant={TypographyVariant.OVERLINE_SMALL} color={props.color} italic={props.italic}>{props.children}</Typography>;

export default Typography;