import React, { FC, PropsWithChildren } from 'react';

import styles from "components/designSystem/containers/Spacer.module.scss"
import classNames from 'classnames';

export enum SpacerSpacing {
    X_SMALL = 'X_SMALL', // 4px
    SMALL = 'SMALL', // 8px
    MEDIUM = 'MEDIUM', // 16px
    LARGE = 'LARGE', // 24px
    X_LARGE = 'X_LARGE', // 32px
    XX_LARGE = 'XX_LARGE', // 48px
    XXX_LARGE = 'XXX_LARGE', // 64px
}

export enum SpacerJustifyContent {
    SPACE_BETWEEN = 'SPACE_BETWEEN'
}

interface SpacerProps extends SizedSpacer {
    spacing?: SpacerSpacing
}

/**
 * The purpose of this component is to add space between its children
 */
const Spacer: FC<SpacerProps> = (
    {
        spacing = SpacerSpacing.SMALL,
        ...props
    }
): JSX.Element => {

    return <div className={
        classNames(
            styles.spacer,
            {
                [styles.spacerSpaceXSmall]: spacing === SpacerSpacing.X_SMALL,
                [styles.spacerSpaceSmall]: spacing === SpacerSpacing.SMALL,
                [styles.spacerSpaceMedium]: spacing === SpacerSpacing.MEDIUM,
                [styles.spacerSpaceLarge]: spacing === SpacerSpacing.LARGE,
                [styles.spacerSpaceXLarge]: spacing === SpacerSpacing.X_LARGE,
                [styles.spacerSpaceXXLarge]: spacing === SpacerSpacing.XX_LARGE,
                [styles.spacerSpaceXXXLarge]: spacing === SpacerSpacing.XXX_LARGE,
                [styles.spacerJustifyContentSpaceBetween]: props.justifyContent === SpacerJustifyContent.SPACE_BETWEEN,
                [styles.spacerFullHeight]: props.withFullHeight == true
            }
        )

    }>
        {props.children}
    </div>
}

type SizedSpacer = PropsWithChildren<{justifyContent?: SpacerJustifyContent, withFullHeight?: boolean}>

export const XSmallSpacer = (props: SizedSpacer): JSX.Element => <Spacer spacing={SpacerSpacing.X_SMALL} {...props}>{props.children}</Spacer>;
export const SmallSpacer = (props: SizedSpacer): JSX.Element => <Spacer spacing={SpacerSpacing.SMALL} {...props}>{props.children}</Spacer>;
export const MediumSpacer = (props: SizedSpacer): JSX.Element => <Spacer spacing={SpacerSpacing.MEDIUM} {...props}>{props.children}</Spacer>;
export const LargeSpacer = (props: SizedSpacer): JSX.Element => <Spacer spacing={SpacerSpacing.LARGE} {...props}>{props.children}</Spacer>;
export const XLargeSpacer = (props: SizedSpacer): JSX.Element => <Spacer spacing={SpacerSpacing.X_LARGE} {...props}>{props.children}</Spacer>;
export const XXLargeSpacer = (props: SizedSpacer): JSX.Element => <Spacer spacing={SpacerSpacing.XX_LARGE} {...props}>{props.children}</Spacer>;
export const XXXLargeSpacer = (props: SizedSpacer): JSX.Element => <Spacer spacing={SpacerSpacing.XXX_LARGE} {...props}>{props.children}</Spacer>;

export default Spacer;