import Box, { BoxColor } from "components/designSystem/containers/Box";
import GridRow, { GridRowAlign, GridRowItem, GridRowSize } from "components/designSystem/containers/GridRow";
import { ImageInfo } from "core/content/cdn";
import * as React from "react";
import { PropsWithChildren } from "react";

type Props = {
    image: ImageInfo
};

export const PregnancyStatus = (props: PropsWithChildren<Props>): JSX.Element =>
    <Box background={BoxColor.VIOLET_BLUE} withRadius withOverflow withFullHeight>
        <GridRow columnNb={3} align={GridRowAlign.NONE} size={GridRowSize.FULL}>
            <GridRowItem from={1} to={2}>
                <Box withFullWidth withFullHeight background={{
                    imageUrl: props.image.src,
                    // Vertical position is not center because we want the illustration of the foetus to be centered in container
                    // This illustration is at about 35% from the top of the whole image
                    position: 'center 35%',
                }}/>
            </GridRowItem>
            <GridRowItem from={2} to={4}>
                <Box background={BoxColor.VIOLET_BLUE} withPadding withRadius withOverflow withFullHeight>
                    {props.children}
                </Box>
            </GridRowItem>
        </GridRow>
    </Box>;
