import { CustomError, Errors } from 'sagas/api/rest/Exceptions';
import { APICallAction } from 'actions/ActionInterface';
import { APIFeedbackMessageFallbackCatcher } from 'models/common/APIMessaging';
import { TransKey } from 'components/pages/common/MainComponent';

export interface FeedbackConfiguration<T = unknown> {
    message?: string | TransKey;
    identifier?: string;
    extraData?: T;
}

export interface ErrorFeedbackRule<T = unknown> {
    matcher: (e: CustomError) => boolean;
    configuration: (e: CustomError) => FeedbackConfiguration<T>;
}
export default class CallHandlerBase<T, R> {
    fn: (params: T, retryCount?: number) => R;
    action?: APICallAction<T>;
    forwardedErrors?: Errors[];
    forwardAllErrors = false;
    fallbackFeedbackCatcher: APIFeedbackMessageFallbackCatcher = APIFeedbackMessageFallbackCatcher.MAIN_ERROR_PAGE;
    feedbackRules: ErrorFeedbackRule[] = [];

    constructor(fn: ((params: T, retryCount?: number) => R), action?: APICallAction<T>) {
        this.fn = fn;
        this.action = action;
    }

    static forwardingAllErrors<T, R>(fn: (params: T, retryCount?: number) => R, action?: APICallAction<T>): CallHandlerBase<T, R> {
        const caller = new this(fn, action);
        caller.forwardAllErrors = true;
        return caller;
    }

    static for<T, R>(fn: (params: T, retryCount?: number) => R, action?: APICallAction<T>): CallHandlerBase<T, R> {
        return new this(fn, action);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static safelyCall<T, R>(fn: (params: T, retryCount?: number) => R, action?: APICallAction<T>): any {
        return new this(fn, action).call();
    }

    forwardingErrors(...forwardedErrors: Errors[]): CallHandlerBase<T, R> {
        this.forwardedErrors = forwardedErrors;
        return this;
    }

    withErrorFeedbackRule<ED = unknown>(
        matcher: (e: CustomError) => boolean,
        conf: (e: CustomError) => FeedbackConfiguration<ED>,
    ): CallHandlerBase<T, R> {
        this.feedbackRules.push({
            matcher: matcher,
            configuration: conf,
        });

        return this;
    }

    defaultFallbackFeedbackCatcher(catcher: APIFeedbackMessageFallbackCatcher): CallHandlerBase<T, R> {
        this.fallbackFeedbackCatcher = catcher;
        return this;
    }

    // Only for tests purposes
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    withErrorTriggered(e: CustomError): CallHandlerBase<T, R> {
        throw Error('Not Implemented');
    }

    // eslint-disable-next-line
    *call(): Generator<unknown, R | null, R | null> {
        throw Error('Not Implemented');
    }
}
