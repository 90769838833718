import React, { Fragment, ReactNode } from "react";
import { ActionDispatcher, OnSuccessPutType } from "actions/ActionInterface";
import { closeModal, modal, ModalContentBuilder, ModalType } from "actions/common/CommonActions";
import { PopUpStyle } from "components/core/containers/PopUp";
import { connect } from "react-redux";
import { Record } from "immutable";
import { EULAType, EULAValidation } from "models/user/AuthModels";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import WithTranslations from "components/pages/common/WithTranslations";
import { TransDomain } from "components/pages/common/MainComponent";
import Form, { FormState } from "components/commonDesign/form/tmpNewSystem/Form";
import _ from "underscore";
import EulaDataPrivacyNoticeV3 from "components/modules/eula/versions/EulaDataPrivacyNoticeV3";
import { capitalizeFirstLetter } from "core/utils/text";
import { getNameFromType } from "components/modules/modular/modules/helpers/eulaFields";
import { LargeSpacer } from "components/designSystem/containers/Spacer";
import { AlwaysInline, InlineSpacing } from "components/designSystem/containers/Inline";
import { BoxHeight, OverScrollBox } from "components/designSystem/containers/Box";
import APIFeedbackMessageContainer from "components/pages/common/APIFeedbackMessage";

export const FEEDBACK_IDENTIFIER_DATA_PRIVACY_FORM = 'FEEDBACK_IDENTIFIER_DATA_PRIVACY_FORM';

interface FormWithDataPrivacyPopUpProps {
    onSubmit: (data: Record<string, string>, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string) => void;
    dataPrivacy: EULAValidation[]
    children: (formState: FormState) => JSX.Element
}

interface FormWithDataPrivacyPopUpDispatchProps {
    popup: (content: ModalContentBuilder, style: PopUpStyle | undefined, title: string | null) => void;
}


class FormWithDataPrivacyPopUp extends WithTranslations<FormWithDataPrivacyPopUpProps & FormWithDataPrivacyPopUpDispatchProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT

    onSubmit = (previousData: Record<string, string>): void => this.props.popup((close: () => void) => (
            <Form
                onSubmit={(data: Record<string, string | boolean>): void => {
                    data[getNameFromType(
                        this.props.dataPrivacy[0].type,
                        this.props.dataPrivacy[0].control_version,
                        this.props.dataPrivacy[0].use_new_naming,
                    )] = true
                    this.props.onSubmit(
                        Object.assign({}, data, previousData),
                        [closeModal()],
                        FEEDBACK_IDENTIFIER_DATA_PRIVACY_FORM
                    );
                }}>
                {(formState: FormState): JSX.Element => {
                    return (
                        <>
                            <LargeSpacer>
                                <OverScrollBox withFixedHeight={BoxHeight.HEIGHT_29}>
                                    <EulaDataPrivacyNoticeV3 description={
                                        <>
                                            <div>{this.trans('eula.description_part_1', undefined, undefined, capitalizeFirstLetter)}</div>
                                            <br/>
                                            <div>{this.trans('eula.description_part_2', undefined, undefined, capitalizeFirstLetter)}</div>
                                        </>
                                    }/>
                                </OverScrollBox>
                                <APIFeedbackMessageContainer
                                    identifier={FEEDBACK_IDENTIFIER_DATA_PRIVACY_FORM} withNewDesignSystem
                                />
                                <AlwaysInline spacing={InlineSpacing.MEDIUM}>
                                    <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} fullWidth
                                            onClick={close}>
                                        {this.trans('cancel')}
                                    </Button>
                                    <Button disabled={formState.invalid} variant={ButtonVariant.PRIMARY}
                                            size={ButtonSize.LARGE} fullWidth isSubmit>
                                        {this.trans('accept', undefined, undefined, capitalizeFirstLetter)}
                                    </Button>
                                </AlwaysInline>
                            </LargeSpacer>
                        </>
                    )
                }}
            </Form>
        ),
        undefined,
        this.trans('user_notice', undefined, undefined, capitalizeFirstLetter)
    )

    render(): ReactNode {
        const notDataPrivacy: EULAValidation[] = this.props.dataPrivacy.filter(value => value.type !== EULAType.DATA_PRIVACY_NOTICED);
        if (!_.isEmpty(notDataPrivacy)) {
            return <Fragment/>
        }

        return <Form onSubmit={this.onSubmit}>
            {this.props.children}
        </Form>;
    }
}

const mapStateToProps = (): {} => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): FormWithDataPrivacyPopUpDispatchProps => ({
    popup: (content: ModalContentBuilder, style: PopUpStyle | undefined, title: string | null): void =>
        dispatch(modal(
            ModalType.CENTER,
            content,
            {
                title: title ? {default: title} : undefined
            },
        )),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormWithDataPrivacyPopUp);