import React from 'react';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_EULA } from 'core/routing/Routes';

import 'components/modules/eula/eula.scss';
import Title, { TitleType } from 'components/core/text/Title';

const EulaDataPrivacyV3 = (): JSX.Element => {
    return (
        <div>
            <Title type={TitleType.HEADING1} className={'eula-title'}>
                POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES
            </Title>
            <div className="eula-paragraph">
                <div className="eula-paragraph-content">
                    Kiro attache une importance fondamentale à la protection et à la sécurité des données personnelles.
                    La confidentialité de ces données personnelles et le respect de la vie privée comptent parmi nos
                    priorités majeures.
                    <br />
                    <br />
                    Kiro s’engage à protéger les données personnelles conformément à la réglementation applicable et
                    notamment le Règlement (UE) n°2016/679 du 27 avril 2016 dit « Règlement Général sur la Protection
                    des Données » ou « RGPD » et la loi n° 78-17 du 6 janvier 1978 modifiée, dite « Loi Informatique et
                    Liberté » modifiée.
                    <br />
                    <br />
                    Nous souhaitons également que cet engagement puisse aller au-delà des obligations légales. C’est
                    pourquoi, depuis le lancement de notre solution, Kiro s’assure continuellement du respect de ses
                    obligations en matière de données personnelles et s’est donné des engagements pour protéger les
                    données à caractère personnel des patients et des utilisateurs de sa solution.
                    <br />
                    <br />
                    Kiro accorde une place primordiale à la conformité et au respect des règles de déontologie fixées
                    par les instances ordinales représentant les professionnels de santé et celles définies par les
                    institutions.
                    <br />
                    <br />
                    Les données personnelles de santé sont notamment hébergées chez un hébergeur ayant reçu la
                    certification HDS (Hébergeur de Données de Santé). Introduite par l’Agence du Numérique en Santé
                    (ANS), la certification HDS fournit un cadre permettant de renforcer la sécurité et la protection
                    des données de santé à caractère personnel.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">1. Définitions</div>

                <div className="eula-paragraph-content">
                    Dans la présente politique de protection des données personnelles, les termes identifiés ci-dessous
                    par une majuscule ont la signification suivante, qu’ils soient employés au singulier ou au pluriel :
                    <ul>
                        <li>
                            <b>CGU</b> : désigne les présentes conditions générales d’utilisation;
                        </li>
                        <br />
                        <li>
                            <b>Compte Kiro</b> : désigne un compte permettant à un Utilisateur de se connecter à la
                            Solution de manière authentifiée et sécurisée et d’accéder à un ensemble de fonctionnalités
                            spécifiques à la nature de l’Utilisateur ;
                        </li>
                        <br />

                        <li>
                            <b>Contenus</b> : désigne l’ensemble des textes, images, fonctionnalités et plus largement
                            tout élément existant au sein de la Solutions;
                        </li>
                        <br />
                        <li>
                            <b>Compte Rendu de Biologie</b> : désigne l’ensemble des résultats d’analyses de biologie
                            médicale pratiqués sur les Patients, reçus par l’Utilisateur sur la Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Données Personnelles</b> : désigne toute donnée qui, au sens de la Réglementation
                            applicable à la protection des données personnelles, permet de désigner ou d'identifier,
                            directement ou indirectement, une personne physique, notamment par référence à son état
                            civil, un identifiant, un numéro d’identification, des données de localisation, un
                            identifiant en ligne ou à un ou plusieurs éléments spécifiques qui lui sont propres ;
                        </li>
                        <br />
                        <li>
                            <b>Données Personnelles de Santé</b> : désigne une Donnée Personnelle relative à la santé
                            physique ou mentale d'une personne physique, y compris la prestation de services de soins de
                            santé, qui révèlent les informations sur l'état de santé de cette personne, indépendamment
                            de leur source, qu'elles proviennent par exemple d'un médecin ou d'un autre Professionnel de
                            Santé, d'un hôpital, d'un dispositif médical ou d'un test de diagnostic in vitro et
                            recueillies par exemple à l’occasion d’activités de prévention, de diagnostic, de soins ou
                            de suivi social et médico-social ;
                        </li>
                        <br />
                        <li>
                            <b>Données Patients</b> : désigne les Données Personnelles relatives au Patient, y compris
                            les Données Personnelles de Santé et les données de biologie médicale, collectées par le
                            Laboratoire ou reçues et collectées par le Professionnel de Santé dans le cadre du parcours
                            de soins du Patient auquel Kiro n’est pas partie, et communiquées à l’Utilisateur au travers
                            de la Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Identifiants</b> : désigne l’identifiant personnel associé au compte de l’Utilisateur
                            ainsi que tout code confidentiel ou mot de passe délivré à l’Utilisateur par Kiro puis
                            modifié par l’Utilisateur et permettant à l’Utilisateur de s’identifier afin d’accéder à la
                            Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Laboratoire</b> : désigne le laboratoire de biologie médicale ayant souscrit un contrat
                            de partenariat avec Kiro aux fins de permettre audit laboratoire de proposer les Services
                            aux Utilisateurs, au travers de la Solution ;
                        </li>
                        <br />
                        <li>
                            <b>Patient</b> : désigne toute personne physique prise en charge par un Professionnel de
                            Santé et dont les Données Patients sont traitées au travers de la Solution par le
                            Laboratoire ou l’Utilisateur ;
                        </li>
                        <br />
                        <li>
                            <b>Politique de Protection des Données Personnelles</b> ou <b>Politique</b> : désigne la
                            présente politique de protection des données personnelles ;
                        </li>
                        <br />
                        <li>
                            <b>Professionnel de santé</b> : désigne tout professionnel de santé exerçant au sein d'une
                            structure libérale ou d'un établissement de santé, notamment enregistré auprès de son ordre
                            professionnel ou de son autorité d'enregistrement ;
                        </li>
                        <br />

                        <li>
                            <b>Réglementation applicable à la protection des données personnelles</b> : désigne toute
                            églementation applicable à la protection des données personnelles et en particulier le
                            Règlement (UE) n°2016/679 du Parlement Européen et du Conseil du 27 avril 2016 dit «
                            Règlement Général sur la Protection des Données » et la loi n°78-17 du 6 janvier 1978
                            modifiée dite « Loi Informatique et Libertés » ;
                        </li>
                        <br />
                        <li>
                            <b>Responsable de Traitement</b> : désigne la personne physique ou morale, qui détermine les
                            finalités et les moyens des traitements des Données Personnelles ;
                        </li>
                        <br />
                        <li>
                            <b>Services</b> : désignent conjointement le Service de Visualisation, le Service de
                            Délégation et le Service d’Aide à la Prescription et au Diagnostic, proposés par le
                            Laboratoire aux Utilisateurs à travers la Solution en exécution d’un contrat de partenariat
                            entre Kiro et le Laboratoire, et mis à disposition des Utilisateurs autorisés à y accéder à
                            travers la Solution
                        </li>
                        <br />
                        <li>
                            <b>Solution</b> : désigne la solution logicielle développée par Kiro, mise à disposition des
                            Utilisateurs et accessible par Internet à l’adresse https://app.kiro.bio ;
                        </li>
                        <br />
                        <li>
                            <b>Sous-traitant</b> : désigne la personne physique ou morale, qui traite des Données
                            Personnelles pour le compte du Responsable de Traitement ;
                        </li>
                        <br />
                        <li>
                            <b>Sous-traitant Prestataire de Services</b> : désigne un sous-traitant tiers mandaté par
                            Kiro qui, dans le cadre de sa prestation de services pour le compte de Kiro, est autorisé à
                            traiter des Données Personnelles ;
                        </li>
                        <br />
                        <li>
                            <b>Utilisateur</b> : désigne le Patient ou le Professionnel de Santé et, le cas échéant, les
                            membres de son équipe soumis au secret professionnel et agissant sous la responsabilité du
                            Professionnel de Santé et/ou tout autre Professionnel de Santé autorisé à accéder aux
                            Données Patients conformément aux dispositions légales et déontologiques applicables, qui
                            dispose d’un accès et utilise la Solution.
                        </li>
                    </ul>
                    Tous les termes identifiés par une majuscule, s’ils ne sont pas définis dans la Politique de
                    Protection des Données Personnelles, ont le sens qui leur est donné dans les CGU.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">2. Objet de la présente politique</div>

                <div className="eula-paragraph-content">
                    La présente Politique de Protection des Données Personnelles a pour objet d’informer et de présenter
                    aux visiteurs et aux Utilisateurs la manière dont nous protégeons les Données Personnelles traitées
                    dans notre Solution.
                    <br />
                    <br />
                    Elle a pour objectif d’expliquer comment Kiro, les Laboratoires partenaires ou les Professionnels de
                    Santé inscrits sur la Solution traitent les Données Personnelles des Utilisateurs et des Patients
                    lors de leurs utilisations de la Solution et/ou des Services. Elle décrit également les raisons de
                    cette collecte et ce qui en est fait, et rappelle les droits des Utilisateurs sur leurs Données
                    Personnelles et les moyens pour les exercer. Elle présente enfin les mesures de sécurité et les
                    différents engagements de Kiro pour protéger leur confidentialité.
                    <br />
                    <br />
                    Les Données Personnelles collectées dans le cadre de la Solution et des Services sont des données
                    sensibles et confidentielles, qui demandent une vigilance particulière, y compris de la part de
                    l’Utilisateur. C’est pourquoi nous vous invitons à lire attentivement la présente Politique.
                    Certaines Données Personnelles doivent être considérées comme des données à caractère personnelles
                    de santé, et sont dénommées dans le présent document des Données Personnelles de Santé.
                    <br />
                    <br />
                    Pour permettre la réception par l’Utilisateur d’informations et de documents médicaux de la part
                    d’un laboratoire de biologie médicale, l’Utilisateur et/ou le Professionnel de Santé communique et
                    traite certaines Données Personnelles. Si l’Utilisateur ne souhaite pas communiquer ces
                    informations, certaines parties ou fonctionnalités de la Solution et/ou des Services peuvent ne pas
                    fonctionner ou ne pas être accessibles, et il se peut que Kiro soit dans l’impossibilité de répondre
                    à sa demande.
                    <br />
                    <br />
                    La Politique de Protection des Données Personnelles fait partie intégrante des{' '}
                    <a className={"legacy-a"} target={'_blank'} rel="noopener noreferrer" href={getRoute(ROUTE_EULA, {})}>
                        CGU
                    </a>
                    . Elle s’applique aux visiteurs et aux Utilisateurs. Elle peut être modifiée, complétée ou mise à
                    jour, afin notamment de prendre en compte toute évolution légale, réglementaire, jurisprudentielle
                    et/ou technique.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">3. Identité des Responsables de Traitement</div>

                <div className="eula-paragraph-content">
                    Pour rappel, au sens de la Réglementation applicable à la protection des données personnelles, le
                    Responsable de Traitement est la personne morale ou physique qui détermine les finalités et les
                    moyens d’un traitement, c’est à dire l’objectif et la façon de le réaliser. Le Sous-traitant est la
                    personne physique ou morale qui traite des données pour le compte du Responsable de Traitement. Le
                    Sous-traitant agit sur instruction et sous l’autorité du Responsable de Traitement.
                    <br />
                    <br />
                    La responsabilité de la collecte et du traitement des Données Personnelles dans le cadre de la
                    Solution et les Services est déterminée comme suit :
                    <br />
                    <br />
                    <b>1. Chaque Laboratoire</b> ayant souscrit un contrat de partenariat avec Kiro et auprès duquel le
                    Patient a réalisé des examens de biologie médicale est Responsable de Traitement des Données
                    Personnelles de Santé et des Données Patients (i) collectées par le Laboratoire aux fins d’examens
                    de biologie médicale du Patient conformément à la réglementation applicable à l’activité du
                    Laboratoire et (ii) transmises à Kiro pour le fonctionnement de ses Services et leur communication
                    aux Utilisateurs au travers de la Solution. Kiro intervient en qualité de Sous-traitant du
                    Laboratoire au sens de la Réglementation applicable à la protection des données personnelles.
                    <br />
                    <br />
                    <b>2. Chaque Utilisateur Professionnel de Santé</b> est Responsable de Traitement des Données
                    Personnelles de Santé (i) collectées par l’Utilisateur Professionnel de Santé dans le cadre de son
                    activité et transmises à Kiro pour y être intégrées dans la Solution et/ou les Services ou (ii)
                    collectées par l’Utilisateur Professionnel de Santé par l’intermédiaire de la Solution et/ou des
                    Services. Kiro intervient en qualité de Sous-traitant de l’Utilisateur Professionnel de Santé au
                    sens de la Réglementation applicable à la protection des données personnelles.
                    <br />
                    <br />
                    <b>3. Kiro</b> est Responsable de Traitement des Données Personnelles collectées dans le cadre (i)
                    de la création du Compte Kiro de l’Utilisateur, (ii) de sa navigation sur la Solution et (iii) de la
                    création de statistiques relatives à l'utilisation de la Solution.
                    <br />
                    <br />
                    L’Utilisateur s’engage notamment à respecter la Réglementation applicable à la protection des
                    données personnelles et à se conformer à{' '}
                    <a
                        rel="noopener noreferrer"
                        target={'_blank'}
                        className={"legacy-a"}

                        href={'https://esante.gouv.fr/securite/pgssi-s/espace-de-publication'}
                    >
                        Politique Générale de Sécurité des Systèmes d’Information de Santé (PGSSI-S)
                    </a>{' '}
                    définies par l’Agence du Numérique en Santé ainsi qu’aux{' '}
                    <a
                        rel="noopener noreferrer"
                        target={'_blank'}
                        className={"legacy-a"}

                        href={'https://www.cnil.fr/fr/la-cnil-publie-trois-referentiels-pour-le-secteur-de-la-sante'}
                    >
                        référentiels adoptés par la CNIL
                    </a>{' '}
                    concernant les traitements des Données Personnelles de Santé
                    <br />
                    <br />
                    Dans tous les cas, Kiro prend les mesures propres à assurer la protection et la confidentialité des
                    Données Personnelles qu’elle détient ou qu’elle traite pour le compte du Responsable de Traitement
                    dans le respect des dispositions de la Réglementation applicable à la protection des données
                    personnelles.
                </div>
                <div className="eula-paragraph">
                    <div className="eula-paragraph-title">4. Finalités du traitement des Données Personnelles</div>

                    <div className="eula-paragraph-content">
                        Kiro traite les Données Personnelles de l’Utilisateur pour lui permettre de suivre des analyses
                        de biologie médicale notamment à partir de documents et de Comptes Rendus de Biologie échangés
                        entre l’Utilisateur et le laboratoire de biologie médicale qui a réalisé les analyses de
                        biologie du Patient ou entre le Professionnel de Santé et le Patient. Ces interactions et les
                        traitements des Données Personnelles qui en résultent, sont nécessaires à la prise en charge des
                        Patients par le Professionnel de Santé dans le cadre du parcours de soins, et permettent par
                        exemple d’établir un diagnostic ou de mettre en place des actions préventives.
                        <br />
                        <br />
                        Les Données Personnelles communiquées par l’Utilisateur sont nécessaires pour son accès à la
                        Solution et/ou aux Services et pour son utilisation ainsi que pour répondre à ses demandes et
                        assurer le support et la maintenance de la Solution et/ou des Services.
                        <br />
                        <br />
                        En conséquence, le recueil de vos Données Personnelles a pour fondement :
                        <ul>
                            <li>
                                l’intérêt légitime de Kiro pour l’exécution de ses obligations contractuelles, notamment
                                envers les Laboratoires ou les Utilisateurs ;
                            </li>
                            <li>
                                l’intérêt légitime de Kiro à assurer le meilleur fonctionnement de sa Solution et
                                améliorer en permanence la qualité de ses Services pour proposer à ses Utilisateurs le
                                meilleur suivi possible de leurs analyses de biologie médicale dans le cadre de leur
                                parcours de soins et de la prise en charge par un Professionnel de Santé ;
                            </li>
                            <li>
                                l’intérêt légitime de Kiro pour assurer la sécurité de sa Solution et de ses Services,
                                ainsi qu’améliorer leurs fonctionnalités  ;
                            </li>
                            <li>
                                l’intérêt légitime de Kiro à réaliser des enquêtes facultatives sur ses Services et ses
                                fonctionnalités en vue de les améliorer ;
                            </li>
                            <li>
                                l’intérêt légitime de Kiro à produire des données statistiques anonymisées relatives à
                                l'impact de Kiro sur l’activité des laboratoires de biologie médicale, des
                                Professionnels de santé et plus généralement du secteur de la santé afin de communiquer
                                sur ses outils, sa technologie et améliorer ses services existants ou à venir ;
                            </li>
                            <li>
                                la nécessité pour Kiro de respecter des obligations légales et/ou nécessaire à la
                                constatation, l’exercice ou la défense d’un droit en justice ;
                            </li>
                            <li>
                                le recueil du consentement de ses Utilisateurs lorsque celui-ci est requis par la
                                réglementation en vigueur.
                            </li>
                        </ul>
                        Les Données Personnelles des Utilisateurs de la Solution et/ou des Services sont principalement
                        traitées pour :
                        <ul>
                            <li>
                                exécuter les contrats conclus entre Kiro et les Utilisateurs ou entre Kiro et les
                                Laboratoires et fournir aux Utilisateurs les informations et services demandés à travers
                                la Solution ;
                            </li>
                            <li>
                                permettre leur navigation et l’utilisation de la Solution et/ou des Services (selon
                                leurs autorisations) ;
                            </li>
                            <li>visualiser des documents et Comptes Rendus de Biologie ;</li>
                            <li>recevoir des documents et Comptes Rendus de Biologie de la part des Laboratoires ;</li>
                            <li>recevoir des documents et Comptes Rendus de Biologie des Professionnels de Santé ;</li>
                            <li>
                                leur permettre de gérer la récupération des documents et Comptes Rendus de Biologie de
                                leurs proches ou des personnes autorisées et leurs transmissions aux Professionnels de
                                Santé ;
                            </li>
                            <li>
                                envoyer aux Utilisateurs des renseignements ou des notifications (comme par exemple
                                suite à la réception d’un Compte Rendu de Biologie) par e-mail, SMS ou tout autre moyen
                                de communication ;
                            </li>
                            <li>
                                leur permettre d’être pris en charge de façon optimale par le Professionnel de Santé ;
                            </li>
                            <li>leur permettre de bénéficier de la prestation de conseil du Laboratoire.</li>
                        </ul>
                        Pour fournir les Services, le traitement effectué consiste à extraire des informations des
                        Comptes Rendus de Biologie et d’autres informations envoyées par les Laboratoires, puis de les
                        utiliser pour accomplir les finalités du traitement.
                        <br />
                        <br />
                        Par ailleurs, les Données Personnelles des Utilisateurs sont également collectées pour :
                        <ul>
                            <li>
                                améliorer et optimiser la navigation sur la Solution et l’utilisations de nos Services ;
                            </li>
                            <li>
                                gérer la Solution et nos Services et effectuer des opérations techniques internes dans
                                le cadre de résolution de problèmes, analyse de données, tests, recherches et études ;
                            </li>
                            <li>prévenir et lutter contre la fraude informatique (spamming, hacking…) ;</li>
                            <li>
                                mener des enquêtes facultatives sur les Services et la Solution et leurs possibles
                                évolutions (ces enquêtes sont réalisées de manière anonyme ou sont anonymisées) ;
                            </li>
                            <li>
                                mener des enquêtes facultatives sur la qualité de la prise en charge des Utilisateurs
                                par le Laboratoire ou les Professionnels de Santé (à l’initiative des Laboratoires ou
                                des Professionnels de Santé) ;
                            </li>
                            <li>
                                nous assurer du respect de (i) la législation applicable, (ii) nos conditions générales
                                d’utilisation, et (iii) notre politique de confidentialité. En cas de manquements de la
                                part de l’Utilisateur, nous pourrons être amenés à suspendre son compte sur notre
                                Solution ;
                            </li>
                            <li>
                                effectuer des statistiques anonymisées sur l’utilisation de l’outil qui seront
                                communiquées au public, un reporting interne pour les équipes de recherche &
                                développement de Kiro ainsi qu’un reporting à destination des Laboratoires et/ou des
                                Professionnels de Santé (sans qu’aucune Données Personnelles de Santé ne soit utilisée).
                            </li>
                        </ul>
                        Vous retrouverez plus de détails sur la gestion des cookies nous permettant d’atteindre
                        certaines de ces finalités dans notre Politique en matière de cookies.
                        <br />
                        <br />
                    </div>
                </div>
                <div className="eula-paragraph">
                    <div className="eula-paragraph-title">5. Typologie des Données Personnelles traitées</div>

                    <div className="eula-paragraph-content">
                        <div className="eula-paragraph-subtitle-1">5.1 Pour le compte du Laboratoire</div>
                        Kiro est susceptible de traiter, en tant que Sous-traitant pour le compte du Laboratoire
                        agissant en tant que Responsable de Traitement, tout ou partie des Données Personnelles et des
                        Données Patients suivantes, afin de permettre à l’Utilisateur la récupération de documents et
                        Comptes Rendus de Biologie ainsi que pour accéder, bénéficier et utiliser les Services autorisés
                        :
                        <ul>
                            <li>
                                Des Données Personnelles administratives, telles que : nom (et nom de naissance),
                                prénom, sexe, date de naissance ;
                            </li>
                            <li>
                                Des Données Personnelles de contact, telles que : numéro de téléphone, adresse email,
                                adresse postale ;
                            </li>
                            <li>
                                Des Données Personnelles d’identification du Professionnel de Santé, telles que :
                                N°RPPS, numéro d’ordre, discipline / spécialité, qualification, département
                                d'inscription, adresse postale professionnelle, numéro de téléphone professionnel,
                                adresse email sécurisée ;
                            </li>
                            <li>
                                Des Données Personnelles de Santé et notamment les réponses aux questionnaires médicaux,
                                les renseignements cliniques, les comptes rendus médicaux et les Comptes Rendus de
                                Biologie réalisés par le Patient auprès du Laboratoire ou collectées par le Laboratoire
                                lors de la réalisation de l’examen de biologie médicale du Patient et transmises à Kiro
                                pour les besoins de la Solution et/ou des Services ;
                            </li>
                            <li>
                                Médecin traitant et prescripteur ainsi que spécialité du praticien ou du Professionnel
                                de Santé ;
                            </li>
                            <li>L’historique de prise en charge du Patient ;</li>
                            <li>
                                Des Données Personnelles appartenant au dossier médical du Patient que le Laboratoire
                                souhaite partager avec l'Utilisateur ou un autre Professionnel de Santé appartenant à
                                l’équipe de soins du Patient ;
                            </li>
                            <li>
                                Ces mêmes Données Personnelles concernant les proches pour lesquels l’Utilisateur a
                                autorisé l’accès.
                            </li>
                        </ul>
                        <div className="eula-paragraph-subtitle-1">
                            5.2 Pour le compte de l’Utilisateur Professionnel de Santé
                        </div>
                        Kiro est susceptible de traiter, en tant que Sous-traitant pour le compte de l’Utilisateur
                        Professionnel de Santé agissant en tant que Responsable de Traitement, afin de permettre la
                        récupération de documents et Comptes Rendus de Biologie ainsi que pour accéder, bénéficier et
                        utiliser les Services autorisés, tout ou partie des Données Personnelles et des Données Patients
                        suivantes :
                        <ul>
                            <li>
                                Des Données Personnelles administratives, telles que : nom (et nom de naissance),
                                prénom, sexe, date de naissance ;
                            </li>
                            <li>
                                Des Données Personnelles de contact, telles que : numéro de téléphone, adresse email,
                                adresse postale ;
                            </li>
                            <li>
                                Des Données Personnelles d’identification du Professionnel de Santé, telles que :
                                N°RPPS, numéro d’ordre, discipline / spécialité, qualification, département
                                d'inscription, adresse postale professionnelle, numéro de téléphone professionnel,
                                adresse email sécurisée ;
                            </li>
                            <li>
                                Des Données Personnelles de Santé et notamment les réponses aux questionnaires médicaux,
                                les renseignements cliniques, les comptes rendus médicaux et les Comptes Rendus de
                                Biologie concernant le Patient, qui ont été collectées par l’Utilisateur Professionnel
                                de Santé lors du parcours de soins ou de la prise en charge du Patient et transmises à
                                Kiro dans le cadre de l’utilisation de la Solution et/ou des Services ;
                            </li>
                            <li>
                                Médecin traitant et prescripteur ainsi que spécialité du praticien ou du Professionnel
                                de Santé ;
                            </li>
                            <li>L’historique de prise en charge du Patient ;</li>
                            <li>
                                Des Données Personnelles appartenant au dossier médical du Patient que l’Utilisateur
                                Professionnel de Santé souhaite intégrer au dossier du Patient ou partager avec
                                l’Utilisateur Patient ou un autre Utilisateur Professionnel de Santé appartenant à
                                l’équipe de soins du Patient ;
                            </li>
                            <li>
                                Ces mêmes Données Personnelles concernant les proches pour lesquels l’Utilisateur a
                                autorisé l’accès.
                            </li>
                        </ul>
                        <div className="eula-paragraph-subtitle-1">5.3 Kiro, en tant que Responsable de Traitement</div>
                        Kiro est susceptible de traiter, en tant que Responsable de Traitement, pour permettre la bonne
                        utilisation de la Solution et/ou des Services, tout ou partie des Données Personnelles suivantes
                        :
                        <ul>
                            <li>
                                Des Données Personnelles administratives (collectées principalement à l’inscription de
                                l’Utilisateur), telles que : nom (et nom de naissance), prénom, sexe, date de naissance
                                ;
                            </li>
                            <li>
                                Des Données Personnelles de contact, telles que : numéro de téléphone, adresse email,
                                adresse postale ;
                            </li>
                            <li>
                                Des Données Personnelles de contact (collectées principalement à l’inscription de
                                l’Utilisateur et mises à jour ensuite par ce dernier), telles que : numéro de téléphone,
                                adresse email, adresse postale ;
                            </li>
                            <li>
                                Des Données Personnelles d’identification du Professionnel de Santé (collectées
                                principalement à l’inscription du Professionnel de Santé et mises à jour ensuite par ce
                                dernier), telles que : N°RPPS, numéro d’ordre, discipline / spécialité, qualification,
                                département d'inscription, adresse postale professionnelle, numéro de téléphone
                                professionnel, adresse email sécurisée ;
                            </li>
                            <li>Des données de connexion et d’utilisation de la Solution et des Services ;</li>
                            <li>Des données sur le matériel informatique utilisé pour la navigation ;</li>
                            <li>
                                Des Données Personnelles de traçabilité et de navigation sur la Solution et/ou les
                                Services, telles que : adresse IP, logs, cookies.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">
                    6. Engagement des Utilisateurs pour la protection des Données Personnelles
                </div>

                <div className="eula-paragraph-content">
                    Les Données Patients communiquées à l’Utilisateur au travers de la Solution sont strictement
                    couvertes par le secret professionnel, ce que l’Utilisateur s’engage à respecter et à faire
                    respecter aux membres de son équipe amenés à utiliser la Solution et/ou les Services et accéder aux
                    Données Patients pour leur traitement aux fins de prise en charge du Patient.
                    <br />
                    <br />
                    L'Utilisateur s'engage à ne pas enregistrer, copier ou diffuser tout contenu ou extrait de contenu
                    en lien avec la Solution ou les Services quel qu'en soit le moyen, le support, le procédé ou la
                    finalité. L'Utilisateur est informé que toute atteinte au droit à l’image, au respect de la vie
                    privé ou au secret professionnel et médical peut faire l’objet de sanctions, y compris pénales.
                    <br />
                    <br />
                    Le Laboratoire et/ou l’Utilisateur Professionnel de Santé, en tant que Responsable de Traitement,
                    s’engage à traiter les Données Personnelles dans le strict respect de la Réglementation applicable à
                    la protection des données personnelles. Il appartient au Laboratoire ou à l’Utilisateur le cas
                    échéant (i) d’informer les Patients du traitement de leurs Données Personnelles notamment par Kiro,
                    (ii) d’assurer à tout moment le respect des droits qu’ils détiennent sur leurs Données Personnelles
                    et (iii) de procéder à toute notification de violation de données aux autorités compétentes et, le
                    cas échéant, aux personnes concernées.
                    <br />
                    <br />
                    En particulier, le Laboratoire et/ou l’Utilisateur Professionnel de Santé, en tant que Responsable
                    de Traitement de Données Personnelles de Santé, est responsable :
                    <ul>
                        <li>De la supervision des traitements des Données Personnelles de Santé ;</li>
                        <li>
                            Du respect des formalités déclaratives auprès de la CNIL nécessaires aux traitements
                            auxquels il a recours par l’utilisation de la Solution et/ou des Services.
                        </li>
                    </ul>
                    A ce titre, Kiro agit comme sous-traitant du Laboratoire et/ou de l’Utilisateur Professionnel de
                    Santé, au sens de la Réglementation applicable à la protection des données personnelles et, à ce
                    titre, s’engage à respecter l’ensemble des obligations mises à sa charge au sein du contrat qui le
                    lie au Laboratoire et aux CGU, ces obligations étant en tout état de cause conformes à la
                    Réglementation applicable à la protection des données personnelles et, en particulier, à l’article
                    28 du RGPD.
                    <br />
                    <br />
                    En aucun cas la responsabilité de Kiro ne saurait être engagée en cas de non-respect par le
                    Laboratoire et/ou l’Utilisateur Professionnel de Santé de ses obligations en tant que Responsables
                    de Traitement.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">
                    7. Engagements de Kiro pour la protection des Données Personnelles
                </div>

                <div className="eula-paragraph-content">
                    <b>
                        Chaque Utilisateur reste maître de ses Données Personnelles. Kiro n’en dispose pas librement.
                        Les Données Personnelles de l’Utilisateur ne seront pas communiquées à des entreprises
                        commerciales ou publicitaires.
                    </b>
                    <br />
                    <br />
                    Les Données Personnelles de Santé sont strictement destinées aux Utilisateurs concernés pour
                    l’utilisation de la Solution et/ou des Services, conformément aux CGU et à la Politique de
                    Protection des Données Personnelles. Kiro s’engage à les protéger en conformité avec la
                    Réglementation applicable à la protection des données personnelles.
                    <br />
                    <br />
                    <b>
                        Kiro ne procède à aucun transfert de Données Personnelles de Santé vers les pays qui ne sont
                        membres ni de l’Union Européenne, ni de l’Espace Économique Européen.
                    </b>
                    <br />
                    <br />
                    Lorsque Kiro est Responsable de Traitement des Données Personnelles de l’Utilisateur, Kiro s’engage
                    à ne pas divulguer, céder, louer ou transmettre les Données Personnelles de l’Utilisateur à des
                    tiers autres que le Laboratoire, l’Utilisateur et ses Sous-traitants Prestataires de Services
                    désignés à l’article 10 « Hébergement et Sous-traitants Prestataires de Services » ci-après,
                    conformément aux contrats qui les lie et qui ne saurait déroger au présent article et, en tout état
                    de cause à la Réglementation applicable à la protection des données personnelles.
                    <br />
                    <br />
                    Kiro pourrait toutefois être amenée à communiquer les Données Personnelles traitées à des tiers
                    lorsqu’une telle communication sera requise par la loi, une disposition réglementaire ou une
                    décision judiciaire, ou si cette communication est nécessaire pour assurer la protection et la
                    défense de ses droits.
                    <br />
                    <br />
                    Lorsque Kiro est Sous-traitant, Kiro s’engage à :
                    <ul>
                        <li>
                            procéder aux traitements qui lui sont confiés, uniquement dans le cadre des finalités
                            faisant l’objet des traitements et de l’exécution de ses obligations contractuelles et à
                            n’agir que sur la base des instructions documentées du Responsable de Traitement ;
                        </li>
                        <li>
                            assurer la confidentialité des Données Personnelles et veiller à ce que chaque personne
                            qu’elle autorise à traiter les dites données s'engage à respecter la confidentialité ou soit
                            soumise à une obligation appropriée de confidentialité ;
                        </li>

                        <li>
                            mettre à la disposition du Responsable de Traitement, sous condition de respect d’un
                            engagement de confidentialité, toutes les informations nécessaires pour démontrer le respect
                            des obligations à sa charge ;
                        </li>
                        <li>
                            notifier dans les meilleurs délais le Responsable de Traitement en cas de violation des
                            Données Patients dont il aurait connaissance ;
                        </li>
                        <li>
                            héberger chez un hébergeur ayant reçu la certification HDS (Hébergeur de Données de Santé)
                            les Données Personnelles de Santé.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">8. Durées de conservation</div>

                <div className="eula-paragraph-content">
                    Kiro conserve les Données Personnelles uniquement le temps nécessaire pour les finalités poursuivies
                    pendant la durée d’utilisation de la Solution et/ou des Services par l’Utilisateur, conformément aux
                    dispositions légales et elles seront archivées à la clôture du Compte Kiro de l’Utilisateur pendant
                    la durée légale nécessaire au respect des obligations légales de Kiro et aux fins de preuve pour la
                    constatation, l’exercice ou la défense d’un droit en justice. Kiro n’est en revanche pas garant des
                    obligations incombant au Responsable de Traitement en matière de durée de conservation des Données
                    Personnelles.
                    <br />
                    <br />
                    Les durées de conservation des Données Personnelles collectées via la Solution et/ou les Services
                    sont différentes selon les types de données. Le choix de durées de conservation des Données
                    Personnelles de Santé sont ceux déterminés par la CNIL dans son{' '}
                    <a
                        target={'_blank'}
                        rel="noopener noreferrer"
                        className={"legacy-a"}

                        href={
                            'https://www.cnil.fr/sites/default/files/atoms/files/referentiel_-_traitements_dans_le_domaine_de_la_sante_hors_recherches.pdf'
                        }
                    >
                        référentiel de durées de conservation pour les traitements de données dans le domaine de la
                        santé
                    </a>{' '}
                     et indiqués ci-dessous :
                    <br />
                    <br />
                    <div>
                        <table>
                            <tr>
                                <th>Détails </th>
                                <th>Durée de conservation en base active </th>
                                <th>Durées de conservation en base intermédiaire </th>
                                <th>Fondements juridiques Textes de références </th>
                            </tr>
                            <tr>
                                <td>Dossier patient </td>
                                <td>5 ans à compter de la dernière intervention sur le dossier </td>
                                <td>15 ans </td>
                                <td rowSpan={3}>
                                    <a
                                        rel="noopener noreferrer"
                                        target={'_blank'}
                                        href={
                                            'https://www.cnil.fr/fr/declaration/ns-053-laboratoires-danalyse-medicale'
                                        }
                                        className={"legacy-a"}

                                    >
                                        Norme simplifiée n°53{' '}
                                    </a>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Relevé chronologique des analyses effectuées par le laboratoire ou transmises à un
                                    autre laboratoire (pour les laboratoires d’analyses de biologie médicale soumis aux
                                    dispositions de la loi du 11 juillet 1975){' '}
                                </td>
                                <td>N/A </td>
                                <td>10 ans </td>
                            </tr>
                            <tr>
                                <td>
                                    {' '}
                                    Dossiers et livres de registre (pour laboratoires réalisant des analyses dans les
                                    établissements de santé){' '}
                                </td>
                                <td colSpan={2}>
                                    <i>Voir la durée pour les établissements de santé</i>{' '}
                                </td>
                            </tr>
                            <tr>
                                <td>Analyses génétiques (comptes rendu d'analyses et commentaires explicatifs) </td>
                                <td>
                                    Le temps de la réalisation des comptes rendus et des commentaires explicatifs des
                                    analyses{' '}
                                </td>
                                <td>30 ans </td>
                                <td>
                                    <a
                                        rel="noopener noreferrer"
                                        target={'_blank'}
                                        href={
                                            'https://www.legifrance.gouv.fr/affichCodeArticle.do;jsessionid=9A5C5AD1D645F41A8DBA8FC23E79F313.tplgfr23s_1?idArticle=LEGIARTI000018615509&cidTexte=LEGITEXT000006072665&dateTexte=20130623&categorieLien=id&oldAction='
                                        }
                                        className={"legacy-a"}

                                    >
                                        Article R. 1131-20 du CSP{' '}
                                    </a>{' '}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    <br />
                    Les autres Données Personnelles sont conservées le temps nécessaire pour les finalités poursuivies
                    et pendant une durée limitée conforme aux dispositions légales à compter de leur collecte ou du
                    dernier contact avec Kiro.
                    <br />
                    <br />
                    Les documents médicaux et les Comptes Rendus de Biologie qui ont été transmis aux Utilisateurs et/ou
                    au Responsable de Traitement ou que le Responsable de Traitement a transmis à Kiro peuvent toutefois
                    être conservés aussi longtemps que d’autres Laboratoires et/ou Utilisateurs destinataires de ces
                    documents ou Comptes Rendus de Biologie restent actifs sur la Solution et/ou les Services.
                    <br />
                    <br />
                    À l’issue des délais de conservation ou au terme des CGU pour quelque raison que ce soit, le Compte
                    Kiro de l’Utilisateur sera inactivé. Selon le choix du Responsable de Traitement, Kiro pourra soit
                    (i) détruire les Données Personnelles, soit (ii) restituer au Responsable de Traitement qui en fait
                    la demande les Données Personnelles traitées sur la Solution et/ou les Services. Kiro archivera une
                    copie des Données Personnelles dans des conditions de sécurité et de confidentialité conformes à la
                    Réglementation applicable à la protection des données personnelles, aux fins de preuve pour la
                    constatation, l’exercice ou la défense d’un droit en justice.
                    <br />
                    <br />
                    L’Utilisateur est informé qu’une copie des Données Personnelles sera également archivée aux fins de
                    permettre, pour les Utilisateurs qui ne s’y seront pas opposés, leur réutilisation à des fins
                    secondaires de recherche par le Laboratoire et/ou Kiro, dans les conditions et selon les modalités
                    prévues par la réglementation applicable à ce type de recherche.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">9. Sécurité des Données Personnelles</div>

                <div className="eula-paragraph-content">
                    Kiro met en place et maintient toutes les mesures techniques et organisationnelles de sécurité et de
                    confidentialité appropriées de nature à garantir une protection adéquate des Données Personnelles
                    traitées, adaptées aux risques engendrés par leur traitement. Ces mesures visent notamment à
                    protéger les Données Personnelles contre leur destruction, altération ou divulgation à des tiers non
                    autorisés.
                    <br />
                    <br />A titre informatif, Kiro a notamment mis en place les mesures suivantes pour protéger la
                    confidentialité, l’intégrité et la sécurité des Données Personnelles :
                    <ul>
                        <li>la pseudonymisation et / ou le chiffrement des connexions et des Données Personnelles ;</li>
                        <li>
                            une procédure visant à tester, à analyser et à évaluer régulièrement l'efficacité des
                            mesures techniques et organisationnelles pour assurer la sécurité des traitements ;
                        </li>
                        <li>le chiffrement de l’ensemble des connexions au serveur selon le protocole HTTPS ;</li>
                        <li>
                            l’hébergement des Données Personnelles de Santé auprès d’un hébergeur de données de santé
                            ayant reçu la certification par l’Agence du Numérique en Santé (ANS) ;
                        </li>
                        <li>
                            la capacité d’assurer le rétablissement de la disponibilité des Données Personnelles et
                            l'accès à celles-ci dans des délais appropriés en cas d'incident physique ou technique.
                        </li>
                    </ul>
                    L’ensemble des accès à la Solution et aux Services, ainsi que les actions menées par l’Utilisateur
                    sur les Données Patients sont tracés et consignés quotidiennement en base de données.
                    <br />
                    <br />
                    Kiro ainsi que chaque Utilisateur et Responsable de Traitement s’engagent à prendre les mesures
                    appropriées afin de garantir que tout salarié, partenaire, Sous-traitant Prestataires de Services et
                    toute personne physique agissant sous l’autorité du Responsable de Traitement ou sous celle de Kiro,
                    est dûment habilité à accéder aux Données Personnelles. Les Professionnels de Santé qui utilisent la
                    Solution et /ou les Services sont soumis au secret sur les informations de santé et relatives à la
                    vie privée des Patients parvenues à leur connaissance par quelque moyen que ce soit et ce
                    conformément à leurs obligations légales, réglementaires et déontologiques telles qu’elles résultent
                    en particulier des articles L. 1110-4, L. 1110-12, R. 1110-1 et suivants du code de la santé
                    publique, de l’article 226-13 du code pénal et du code de déontologie régissant l’exercice de leur
                    profession, le cas échéant.
                    <br />
                    <br />
                    Chaque Utilisateur est invité à mettre en œuvre, sous sa responsabilité exclusive, l’ensemble des
                    mesures de sécurité utiles et pertinentes pour les besoins de la protection des accès à son Compte
                    Kiro, à son ordinateur, son téléphone mobile ainsi que tout équipement ou matériel informatique, et
                    à l’ensemble des données qu’il contient, y compris des Données Personnelles, accessibles sur la
                    Solution et/ou les Services, en particulier vis- à-vis des tiers.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">10. Hébergement et Sous-traitants Prestataires de Services</div>

                <div className="eula-paragraph-content">
                    Conformément aux dispositions de l’article 28 du RGPD, le Responsable de Traitement autorise de
                    manière générale Kiro à avoir recours à des Sous-traitants Prestataires de Services pour mener des
                    activités de traitement spécifiques.
                    <br />
                    <br />
                    Les Sous-traitants Prestataires de Services ayant contractualisé avec Kiro à ce stade, et en cours à
                    la date des présentes, sont OVH (hébergeur), Google Cloud Services (hébergeur), l’ANS (Agence du
                    Numérique en Santé), et Apicem (messagerie sécurisée de santé). Le Responsable de Traitement a le
                    droit de s’opposer au recours à un Sous-traitant Prestataire de Services à compter de la date de
                    réception de cette information.
                    <br />
                    <br />
                    Par ailleurs, Kiro héberge les données chez différents hébergeurs :
                    <ul>
                        <li>
                            la société OVH, société par actions simplifiée au capital de 10.069.020,00 euros
                            immatriculée au Registre du commerce et des sociétés de Lille Métropole sous le numéro de
                            424 761 419, dont le siège social est situé au 2 rue Kellermann, 59100 Roubaix
                        </li>
                        <li>
                            la société Google Cloud Services, société à responsabilité limitée au capital de
                            464.884.017,00 euros immatriculée au Registre du commerce et des sociétés de Paris sous le
                            numéro de 443 061 841, dont le siège social est situé au 8, rue de Londres, 75009 Paris
                        </li>
                    </ul>
                    Dans le respect de l’article L. 1111-8 du Code de la Santé Publique, la Solution ainsi que les
                    Données Personnelles de Santé, sont hébergées sur des serveurs dédiés mis à disposition de Kiro par
                    <a
                        rel="noopener noreferrer"
                        target={'_blank'}
                        href={
                            'https://esante.gouv.fr/labels-certifications/hds/certification-des-hebergeurs-de-donnees-de-sante'
                        }
                        className={"legacy-a"}

                    >
                        un hébergeur de données de santé ayant reçu la certification HDS (Hébergeur de Données de Santé)
                    </a>{' '}
                    et référencé par l’Agence du Numérique en Santé (certificat HDS n°FR053558). Cet hébergeur est
                    astreint au secret professionnel dans les conditions et sous les peines prévues à l’article 226-13
                    du code pénal.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">11. Droit des Utilisateurs</div>

                <div className="eula-paragraph-content">
                    Conformément à la Réglementation applicable à la protection des données personnelles, l’Utilisateur
                    disposez d’un droit d’accès, de rectification, de limitation, d’opposition et de portabilité sur ses
                    Données Personnelles, qu’il peut exercer pour motifs légitimes, et sous réserve des motifs légitimes
                    impérieux dont pourrait justifier Kiro pour conserver ses Données Personnelles. L’Utilisateur peut
                    exercer ses droits à tout moment en envoyant sa demande complète par courrier postal à l’adresse :
                    Kiro SAS, 23 boulevard François Robert, 13009 Marseille. Dans ce cas, l’Utilisateur doit indiquer
                    les Données Personnelles qu’il souhaiterait que Kiro corrige, mette à jour ou supprime, en
                    s’identifiant de manière précise avec une copie d’une pièce d’identité (carte d’identité ou
                    passeport) ou tout autre élément permettant de justifier de son identité. Les demandes de
                    suppression de Données Personnelles seront soumises aux obligations qui sont imposées à Kiro par la
                    loi, notamment en matière de conservation ou d’archivage des documents.
                    <br />
                    <br />
                    En cas de demande, Kiro en informera dans les meilleurs délais le Responsable de Traitement et lui
                    fournira les informations nécessaires pour la transmission des Données Personnelles.
                    <br />
                    <br />
                    Le Responsable de Traitement reconnaît, de manière générale, être exclusivement responsable de
                    l’information préalable et du recueil du consentement des Utilisateurs, et en particulier des
                    Patients, dans le respect des dispositions de la Réglementation applicable à la protection des
                    données personnelles. L’Utilisateur Professionnel de Santé, en tant que Responsable de Traitement,
                    se doit de nommer une personne dans son organisation qui sera en mesure de communiquer à Kiro un
                    Professionnel de Santé à chaque fois que cela s’avèrera nécessaire, par exemple pour tout problème
                    nécessitant l’accès aux Données Personnelles de Santé ou relatif à la gestion des relations avec le
                    Patient. Le Responsable de Traitement doit s’assurer de la disponibilité de cette personne, et de
                    communiquer à Kiro son remplaçant notamment en cas de départ de celui-ci.
                    <br />
                    <br />
                    L’Utilisateur reconnait et accepte qu’il ne dispose pas du droit à l’effacement de ses données,
                    justifié par le respect des obligations légales mises à la charge de Kiro notamment en matière de
                    règles d’accès et de traçabilité des accès aux Données Personnelles par les Utilisateurs.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">11.1 Droit d’information des Utilisateurs</div>
                    Les Utilisateurs sont informés de la collecte et du traitement de leurs Données Personnelles et de
                    leurs droits dans :
                    <ul>
                        <li>
                            la présente Politique de Protection des Données Personnelles disponible à tout moment sur la
                            Solution ou lors de la création d’un Compte Kiro ;
                        </li>
                        <li>
                            les CGU disponibles à tout moment sur la Solution ou lors de la création d’un Compte Kiro ;
                        </li>
                        <li>lors de la création d’un Compte Kiro.</li>
                    </ul>
                    Par ailleurs, dans le cas où l’Utilisateur Professionnel de Santé ne fait pas partie de la même
                    équipe de soins (au sens de l’article L. 1110-12 du Code de la Santé Publique) que le Professionnel
                    de Santé prescripteur des examens de biologie médicale du Patient, Kiro informe l’Utilisateur
                    Professionnel de Santé que le Patient doit expressément consentir, par le biais d’une case à cocher
                    ou de tout autre moyen à communiquer par écrit par l’Utilisateur Professionnel de Santé à Kiro :
                    <ul>
                        <li>
                            à la collecte et au traitement des Données Personnelles de Santé et des Données Patients ;
                        </li>
                        <li>au partage d’informations le concernant explicitement.</li>
                    </ul>
                    La délivrance d’une information, et le recueil du consentement du Patient doivent être effectués par
                    l’Utilisateur Professionnel de Santé.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">
                        11.2 Droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de
                        complétude, et à la limitation du traitement (article 18 RGPD) des données des Utilisateurs
                    </div>
                    L’Utilisateur peut à tout moment demander à Kiro :
                    <ul>
                        <li>des précisions sur ses Données Personnelles traitées par Kiro ;</li>
                        <li>de modifier ses Données Personnelles ;</li>
                        <li>de limiter les traitements de ses Données Personnelles.</li>
                    </ul>
                    <div className="eula-paragraph-subtitle-1"> 11.3 Droit à la portabilité</div>
                    L’Utilisateur a le droit de récupérer les Données Personnelles qu’il a fournies. Kiro s’engage à lui
                    fournir ses Données Personnelles dans un format couramment utilisé et lisible.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1"> 11.4 Droits d’opposition</div>
                    L’Utilisateur dispose également d’un droit d’opposition à ce que ses Données Personnelles fassent
                    l’objet d’un traitement.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">12. Capacité et mineurs</div>

                <div className="eula-paragraph-content">
                    La Solution et les Services s’adressent généralement à des personnes de plus de 18 ans capables de
                    contracter des obligations conformément à la législation du pays dans lequel se trouve
                    l’Utilisateur.
                    <br />
                    <br />
                    Les personnes mineures ou les personnes majeures vulnérables faisant l’objet d’une mesure de
                    protection juridique, doivent obtenir le consentement préalable de leurs représentants légaux
                    (parents, tuteurs) préalablement à la saisie de leurs données sur la Solution et/ou les Services.
                    Sauf obligation légale de confidentialité ou liée au secret médical, Kiro pourra directement
                    informer ce responsable (i) des catégories spécifiques de Données Personnelles recueillies auprès de
                    la personne concernée (ii) de la possibilité de s’opposer à la collecte, l’utilisation ou la
                    conservation de celles-ci.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">13. Gestion des cookies</div>

                <div className="eula-paragraph-content">
                    Un « cookie » est une information stockée sur l’appareil d’une personne qui navigue sur un site
                    internet. Il permet d’identifier l’appareil de cette personne à chaque visite.
                    <br />
                    <br />
                    Kiro utilise des cookies pour offrir aux Utilisateurs une meilleure expérience de navigation sur la
                    Solution et/ou les Services. Les cookies permettent également de mesurer et améliorer la Solution
                    et/ou les Services proposés par Kiro. L’Utilisateur peut accepter ou refuser les cookies. Si
                    l’Utilisateur refuse les cookies, certaines parties de la Solution et/ou des Services peuvent ne pas
                    fonctionner sur son appareil et il pourrait ne pas pouvoir accéder à certaines fonctionnalités de la
                    Solution et/ou des Services. Plus d’informations à ce sujet sont disponibles sur notre Politique en
                    matière de cookies.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">14. Autres éléments</div>

                <div className="eula-paragraph-content">
                    <div className="eula-paragraph-subtitle-1">
                        {' '}
                        14.1 Notification des violations de Données Personnelles (article 33 du RGPD)
                    </div>
                    Le Responsable de Traitement s’engage à notifier à l’autorité de contrôle compétente, dans les
                    meilleurs délais, toute violation de Données Personnelles dont il aurait connaissance. Kiro s’engage
                    à informer le Responsable de Traitement, dans les meilleurs délais après en avoir pris connaissance,
                    de toute violation de Données Personnelles portant sur les traitements relevant de la responsabilité
                    du Responsable de Traitement, et à prendre les mesures appropriées pour limiter le risque et
                    protéger les Données Personnelles. La notification sera adressée par Kiro au Responsable de
                    Traitement par email, et contiendra, autant que possible, tout élément d’information utile au
                    Responsable de Traitement afin de lui permettre de notifier, si nécessaire, la violation à
                    l’autorité de contrôle compétente. La notification adressée au Responsable de Traitement par Kiro ne
                    saurait en aucun cas constituer une reconnaissance de faute ou de responsabilité de ce dernier.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1"> 14.2 Certification</div>
                    Kiro s’engage à fournir sur demande du Responsable de Traitement les preuves des certifications de
                    son hébergeur HDS (Hébergeur de Données de Santé), et à l’informer d’éventuels changements relatifs
                    à cette certification dans un délai de 30 jours.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">
                        {' '}
                        14.3 Analyse d’impact relative à la protection des Données Personnelles (article 35 du RGPD)
                    </div>
                    Le Responsable de Traitement s’engage à réaliser une analyse d’impact pour s’assurer de la
                    conformité des traitements à la Réglementation applicable à la protection des données personnelles,
                    lorsqu’ils sont susceptibles d’engendrer un risque élevé pour les droits et libertés des personnes
                    concernées. Dans l’hypothèse où Kiro aurait connaissance d’un risque élevé pour les droits et
                    libertés des personnes concernées par les traitements, Kiro informera dans les meilleurs délais le
                    Responsable de Traitement d’un tel risque. Kiro pourra également apporter au Responsable de
                    Traitement son assistance dans le cadre de la réalisation d’analyses d’impact relative à la vie
                    privée et/ou dans le cadre de formalités qui seraient à accomplir par le Responsable de Traitement.
                    Ce dernier reconnait et accepte que la prestation d’assistance qui serait à accomplir dans ce cadre
                    fera l’objet d’une proposition de services séparée de la part de Kiro.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1"> 14.4 Audit</div>
                    Kiro pourra mettre à la disposition du Responsable de Traitement, sous condition de respect d’un
                    engagement de confidentialité, toutes les informations nécessaires pour vérifier la conformité de
                    Kiro aux dispositions de la présente Politique et pour permettre la réalisation d'audits. Le
                    Responsable de Traitement s’engage à notifier la demande d’audit à Kiro par écrit, en respectant un
                    délai raisonnable avant sa tenue, qui ne saurait être inférieur à préavis de trente (30) jours.
                    L’audit sera réalisé aux frais exclusifs du Responsable de Traitement. Un tel audit peut avoir lieu
                    à tout moment, dans la limite d’un audit par année civile. L’audit devra être réalisé par un expert
                    indépendant et reconnu, dont le choix devra être validé par Kiro au minimum dix (10) jours avant le
                    début de l’audit. Un tel audit fera l’objet d’une convention tripartite et l’auditeur, personne
                    physique désignée, sera soumis aux conditions de respect d’un engagement strict de confidentialité
                    et au secret des affaires.
                    <br />
                    <br />
                    En tout état de cause, les opérations d’audit ne devront pas perturber le fonctionnement de la
                    Solution et/ou des Services mis en œuvre par Kiro au-delà des contraintes inhérentes à un audit.
                    L’audit ne pourra pas porter sur des informations non spécifiques au Responsable de Traitement, ceci
                    afin de préserver la confidentialité des informations propres aux autres clients de Kiro ou des
                    informations dont la divulgation serait susceptible de porter atteinte à la sécurité des autres
                    clients, aux Données Patients ou d’autres Données Personnelles les concernant. Un exemplaire du
                    rapport d’audit rédigé par l’auditeur sera remis à chaque partie. Si les conclusions de l’audit
                    contiennent des recommandations, leurs conditions de mise en œuvre seront étudiées
                    contradictoirement dans les meilleurs délais entre le Responsable de Traitement et Kiro.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">15. Modification de la présente Politique</div>

                <div className="eula-paragraph-content">
                    Kiro se réserve le droit de modifier, compléter et/ou de mettre à jour à tout moment la présente
                    Politique, afin notamment de prendre en compte toute évolution légale, réglementaire,
                    jurisprudentielle et/ou technique et procèdera à la publication de la version modifiée sur la
                    Solution.
                </div>
            </div>

            <div className="eula-paragraph">
                <div className="eula-paragraph-title">16. Contact</div>

                <div className="eula-paragraph-content">
                    Pour toute question en lien avec le traitement des Données Personnelles par Kiro, question ou
                    réclamation concernant le respect par Kiro de la présente Politique, ou si l’Utilisateur souhaite
                    faire part à Kiro de recommandations ou de commentaires visant à améliorer la qualité de la présente
                    politique, l’Utilisateur peut contacter Kiro par écrit à l’adresse suivante : Kiro – DPO, 23
                    boulevard François Robert, 13009 Marseille ou <a className={"legacy-a"} href="mailto:contact@kiro.bio">contact@kiro.bio</a>
                    .
                </div>
            </div>
        </div>
    );
};

export default EulaDataPrivacyV3;
