import { put, takeLatest } from '@redux-saga/core/effects';
import {
    API_GET_MY_DOCTOR_DASHBOARD,
    API_GET_MY_PATIENTS,
    GetDoctorDashboardAction,
} from 'actions/doctorBoard/DoctorBoardFetchActions';
import { getDoctorDashboard, getMyPatients } from 'sagas/api/Endpoints';
import { PatientList } from 'components/modules/doctorBoard/Types';
import CallHandler from 'sagas/api/CallHandler';
import { apiCallSuccessAction } from 'actions/ActionInterface';
import { DoctorDashboardData } from 'models/doctor/DashboardModels';
import { AnyIterator } from 'core/utils/Typed';

export const fetchMyPatients = function* (): AnyIterator {
    const result: PatientList = yield CallHandler.safelyCall(getMyPatients);
    if (result) {
        yield put(apiCallSuccessAction(API_GET_MY_PATIENTS, result));
    }
};

export const fetchDoctorDashboard = function* (action: GetDoctorDashboardAction): AnyIterator {
    const data: DoctorDashboardData = yield CallHandler.safelyCall(getDoctorDashboard, action);
    const isForNavigation = action.payload.navigationKey != undefined;
    const onlyAsReceiver = action.payload.onlyAsReceiver;
    if (data) {
        yield put(apiCallSuccessAction(API_GET_MY_DOCTOR_DASHBOARD, data, { isForNavigation, onlyAsReceiver }));
    }
};

export default function* doctorBoardSagas(): AnyIterator {
    yield takeLatest(API_GET_MY_PATIENTS, fetchMyPatients);
    yield takeLatest(API_GET_MY_DOCTOR_DASHBOARD, fetchDoctorDashboard);
}
