export enum IconSize {
    EXTRA_SMALL = 'xs', // 16
    SMALL = 's', // 24
    EXTRA_EXTRA_LARGE = 'xxl', // 48
    MEDIUM = 'm', // 32
}

export interface IconProps {
    size: IconSize;
}

export enum IconName {
    // OUTLINE
    ALLERGY = 'ALLERGY',
    ANTIBODIES = 'ANTIBODIES',
    APPLE = 'APPLE',
    ARROW_DOWN = 'ARROW_DOWN',
    ARROW_LEFT = 'ARROW_LEFT',
    ARROW_RIGHT = 'ARROW_RIGHT',
    ARROW_RIGHT_DOWN = 'ARROW_RIGHT_DOWN',
    ARROW_RIGHT_UP = 'ARROW_RIGHT_UP',
    ARROW_UP = 'ARROW_UP',
    ARTICLE = 'ARTICLE',
    BACTERIA = 'BACTERIA',
    BELL = 'BELL',
    BLOOD = 'BLOOD',
    BONES = 'BONES',
    BRAIN = 'BRAIN',
    BURGER = 'BURGER',
    BURGER_MENU = 'BURGER_MENU',
    CAKE = 'CAKE',
    CALENDAR = 'CALENDAR',
    CELLS = 'CELLS',
    CHART = 'CHART',
    CHECKMARK = 'CHECKMARK',
    CHEVRON_DOWN = 'CHEVRON_DOWN',
    CHEVRON_LEFT = 'CHEVRON_LEFT',
    CHEVRON_RIGHT = 'CHEVRON_RIGHT',
    CHEVRON_UP = 'CHEVRON_UP',
    CLOSE = 'CLOSE',
    CLOSE_SM = 'CLOSE_SM',
    COMPARE = 'COMPARE',
    DASHBOARD = 'DASHBOARD',
    DASHBOARD_SQUARE = 'DASHBOARD_SQUARE',
    DIVIDER = 'DIVIDER',
    DOWNLOAD = 'DOWNLOAD',
    DRUGS = 'DRUGS',
    EQUAL = 'EQUAL',
    EXPAND = 'EXPAND',
    EXTERNAL_LINK = 'EXTERNAL_LINK',
    EYE = 'EYE',
    EYE_OFF = 'EYE_OFF',
    FERTILITY = 'FERTILITY',
    FETUS = 'FETUS',
    FILE_UPLOAD = 'FILE_UPLOAD',
    GLOBE = 'GLOBE',
    HEART = 'HEART',
    HORMONES = 'HORMONES',
    HPRIM = 'HPRIM',
    INFLAMMATION = 'INFLAMMATION',
    INFO_CIRCLE = 'INFO_CIRCLE',
    IONS = 'IONS',
    JAR = 'JAR',
    KIDNEYS = 'KIDNEYS',
    LAB = 'LAB',
    LIVER = 'LIVER',
    LOCK_CLOSE = 'LOCK_CLOSE',
    LOCK_OPEN = 'LOCK_OPEN',
    LOGOUT = 'LOGOUT',
    LOLLIPOP = 'LOLLIPOP',
    MINUS = 'MINUS',
    PANCREAS = 'PANCREAS',
    PDF = 'PDF',
    PEN = 'PEN',
    PEOPLE = 'PEOPLE',
    PHONE = 'PHONE',
    PLUS = 'PLUS',
    PROSTATE = 'PROSTATE',
    PROTEIN = 'PROTEIN',
    QUESTION_CIRCLE = 'QUESTION_CIRCLE',
    REFRESH = 'REFRESH',
    REPORT = 'REPORT',
    SEARCH = 'SEARCH',
    SETTINGS = 'SETTINGS',
    SHIELD = 'SHIELD',
    STAR = 'STAR',
    SEND = 'SEND',
    STETHOSCOPE = 'STETHOSCOPE',
    STOMACH = 'STOMACH',
    STROLLER = 'STROLLER',
    SUCCESS_CIRCLE = 'SUCCESS_CIRCLE',
    SWEET = 'SWEET',
    SYRINGE = 'SYRINGE',
    THUMBS_DOWN = 'THUMBS_DOWN',
    THUMBS_UP = 'THUMBS_UP',
    TRASH = 'TRASH',
    TUBE = 'TUBE',
    USER = 'USER',
    VIRUS = 'VIRUS',
    WARNING_CIRCLE = 'WARNING_CIRCLE',
    WARNING_TRIANGLE = 'WARNING_TRIANGLE',


    // FILL
    FILL_BELL = 'FILL_BELL',
    FILL_BLOOD = 'FILL_BLOOD',
    FILL_BURGER = 'FILL_BURGER',
    FILL_CAKE = 'FILL_CAKE',
    FILL_KIDNEYS = 'FILL_KIDNEYS',
    FILL_INFLAMMATION = 'FILL_INFLAMMATION',
    FILL_STAR = 'FILL_STAR',
    FILL_THUMBS_DOWN = 'FILL_THUMBS_DOWN',
    FILL_THUMBS_UP = 'FILL_THUMBS_UP',
    FILL_TUBE = 'FILL_TUBE',

    // VIVID
    VIVID_CHART = 'VIVID_CHART',
    VIVID_HCP = 'VIVID_HCP',
    VIVID_HEART = 'VIVID_HEART',
    VIVID_PACIFIER = 'VIVID_PACIFIER',
    VIVID_USER = 'VIVID_USER',

    FEMALE = 'FEMALE',
    MALE = 'MALE',
    UNKNOWN_GENDER = 'UNKNOWN_GENDER',
}

export enum IconColor {
    ON_PRIMARY = 'ON_PRIMARY',
    DEFAULT = 'DEFAULT',
    ON_TERTIARY = 'ON_TERTIARY',
    CRITICAL = 'CRITICAL',
    SUBDUED = 'SUBDUED'
}