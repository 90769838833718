import { ActionDispatcher, OnSuccessPutType } from 'actions/ActionInterface';
import { confirmPin, signOut } from 'actions/user/SignInSignUpActions';
import Form from "components/commonDesign/form/tmpNewSystem/Form";
import Shaker from "components/core/containers/Shaker";
import { CenteredColumn } from "components/designSystem/Aliases";
import Alert from "components/designSystem/components/Alert";
import Button, { ButtonTextStyle, ButtonVariant } from "components/designSystem/components/Button";
import EmptyState, { EmptyStateType } from "components/designSystem/components/EmptyState";
import PinField from "components/designSystem/components/PinField";
import Centered from "components/designSystem/containers/Centered";
import { ColumnWidthInREM } from "components/designSystem/containers/Column";
import { MediumSpacer, XLargeSpacer } from "components/designSystem/containers/Spacer";
import { IconName } from "components/designSystem/foundations/IconsData";
import APIFeedbackMessageContainer, {
    getAlertSeverity,
    ResetFunction
} from "components/pages/common/APIFeedbackMessage";
import { setup, TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { PIN_LENGTH } from "configuration/settings";
import { cast } from "core/utils/Typed";
import { APIFeedbackMessage } from "models/common/APIMessaging";
import React, { ReactNode } from "react";
import { Field as FinalField } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

export const FEEDBACK_IDENTIFIER_API_PIN_CONFIRMATION = 'FEEDBACK_IDENTIFIER_API_PIN_CONFIRMATION'

export interface PinFormStateProps {
    onSuccessPut?: OnSuccessPutType;
}

export interface PinFormDispatchProps {
    onSubmit: (data: { pin: string }, onSuccessPut?: OnSuccessPutType) => void;
    disconnect: () => void;
}

type PinExtraData = {
    tooManyRequest?: boolean
}

class PinPage extends WithTranslations<PinFormStateProps & PinFormDispatchProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    onSubmit(onSuccessPut?: OnSuccessPutType) {
        return (data: { pin: string }): void => {
            if (!data.pin || data.pin.length < PIN_LENGTH) return;
            this.props.onSubmit(data, onSuccessPut);
        };
    }

    formBuilder(): JSX.Element {
        let tries = 0
        const name = 'pin';
        const directSubmit = (pin: string): void => this.props.onSubmit({pin: pin}, this.props.onSuccessPut);
        return <Form
            onSubmit={(this.onSubmit(this.props.onSuccessPut))}
        >
            {(): JSX.Element =>
                <FinalField
                    name={name}
                    parse={(value: string[]): string => {
                        return value.join('');
                    }}
                >
                    {({input: {onChange}, meta}): JSX.Element => {
                        return (
                            <APIFeedbackMessageContainer
                                identifier={FEEDBACK_IDENTIFIER_API_PIN_CONFIRMATION}
                                message_renderer={(
                                    message: APIFeedbackMessage | undefined,
                                    resetErrors: ResetFunction
                                ): JSX.Element => {
                                    if (message) {
                                        tries += 1;
                                    }
                                    const disableForm = message && message.extraData &&
                                        cast<PinExtraData>(message.extraData).tooManyRequest
                                    return (
                                        <MediumSpacer>
                                            <Shaker shake={!!message}>
                                                <Centered>
                                                    <PinField
                                                        key={'key-f-' + tries}
                                                        name={name}
                                                        numberOfCase={PIN_LENGTH}
                                                        onDone={(v): void => directSubmit(v)}
                                                        hidden={true}
                                                        autoComplete={'one-time-code'}
                                                        defaultFocus={true}
                                                        error={meta.error}
                                                        touched={meta.touched}
                                                        onChange={onChange}
                                                        disabled={disableForm}
                                                    />
                                                    <OnChange key={name} name={name}>
                                                        {(v: string): void => {
                                                            if (message && v.length < PIN_LENGTH) {
                                                                resetErrors();
                                                            }
                                                        }}
                                                    </OnChange>
                                                </Centered>
                                            </Shaker>
                                            <Centered>
                                                <Button variant={ButtonVariant.INLINE_LINK}
                                                        withOverrideTextStyle={ButtonTextStyle.LABEL}
                                                        onClick={this.props.disconnect}
                                                >
                                                    {this.trans('pin_disconnect')}
                                                </Button>
                                            </Centered>
                                            {message && (
                                                <Alert
                                                    severity={getAlertSeverity(message.type)}
                                                    description={this.fromLegacyTrans(message.message)}
                                                />
                                            )}
                                        </MediumSpacer>
                                    );
                                }}
                            />
                        )
                    }}
                </FinalField>
            }
        </Form>


    }

    render(): ReactNode {
        return (
            <CenteredColumn widthInRem={ColumnWidthInREM.WIDTH_28}>
                <XLargeSpacer>
                    <Centered>
                        <EmptyState
                            title={this.trans('pin_locked')}
                            description={this.trans('pin_explaination')}
                            payload={{
                                type: EmptyStateType.ICON,
                                name: IconName.LOCK_CLOSE
                            }}
                        />
                    </Centered>
                    {this.formBuilder()}
                </XLargeSpacer>
            </CenteredColumn>
        )
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): PinFormDispatchProps => ({
    onSubmit: (data: { pin: string }, previousOnSuccessPut?: OnSuccessPutType): void => {
        dispatch(confirmPin({
            pin: data.pin,
        }, previousOnSuccessPut, FEEDBACK_IDENTIFIER_API_PIN_CONFIRMATION,),);
    }, disconnect: (): void => {
        dispatch(signOut(null));
    },
});

export default setup(PinPage, () => ({}), mapDispatchToProps);
