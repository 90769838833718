import Form from "components/commonDesign/form/tmpNewSystem/Form";
import FormInput from "components/commonDesign/form/tmpNewSystem/FormInput";
import Button, { ButtonSize } from "components/designSystem/components/Button";
import EmptyState, { EmptyStateType } from "components/designSystem/components/EmptyState";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import Inline, {
    AlwaysInline,
    Grow,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";
import { IconName } from "components/designSystem/foundations/IconsData";
import BaseModule, { setupModule } from 'components/modules/modular/BaseModule';
import { TransDomain } from 'components/pages/common/MainComponent';
import { required } from "components/utils/form/tmpNewSystem/validators";
import { APIText } from "models/common/message";
import { ModuleData } from 'models/modular/storage';
import React, { ReactNode } from 'react';

const SEARCH_KEY  = "search"
interface BiologistAdminSearchModulePayload {
    search_placeholder: APIText,
    with_empty_state: boolean
}


class BiologistAdminSearchModule extends BaseModule<Record<string, unknown>, BiologistAdminSearchModulePayload, Record<string, unknown>> {

    TRANS_SUFFIX =  TransDomain.BIOLOGIST

    _render(payload: ModuleData<BiologistAdminSearchModulePayload, Record<string, unknown>>): ReactNode {
        return <>
            <Box background={BoxColor.WHITE} withBoxShadow withRadius>
                <WithMargin margins={[MarginSize.LARGE]}>
                    <Form onSubmit={
                        (data: {[SEARCH_KEY]: string}): void => {
                            this.props.alterContext(
                                this.getConnectedContextKey(SEARCH_KEY),
                                data[SEARCH_KEY]
                            )
                        }
                    }>
                        {
                            (): JSX.Element => (
                                <AlwaysInline spacing={InlineSpacing.LARGE} alignItems={InlineAlignItem.CENTER}>
                                    <Grow>
                                        <FormInput
                                            name={SEARCH_KEY}
                                            field={
                                                {
                                                    label: this.transApiText(payload.pagePayload.search_placeholder),
                                                    leftIconOrFlag:{icon: IconName.SEARCH},
                                                    hideLabelOnActive: true
                                                }
                                            }
                                            validate={required()}
                                        />
                                    </Grow>
                                    <Button isSubmit size={ButtonSize.LARGE}>Search</Button>
                                </AlwaysInline>
                            )
                        }
                    </Form>
                </WithMargin>

            </Box>
            {
                payload.pagePayload.with_empty_state &&
                <Inline justifyContent={InlineJustifyContent.CENTER}>
                    <WithMargin margins={[MarginSize.XX_LARGE, MarginSize.X_LARGE, undefined, MarginSize.X_LARGE]}>
                        <EmptyState
                            title={this.trans('empty_state.title')}
                            description={this.trans('empty_state.description')}
                            payload={{
                                name: IconName.SEARCH,
                                type: EmptyStateType.ICON
                            }}
                        />
                    </WithMargin>

                </Inline>

            }
        </>

    }
}

const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (): Record<string, never> => ({});


export default setupModule(BiologistAdminSearchModule, mapStateToProps, mapDispatchToProps);
