import Inline, { InlineJustifyContent } from 'components/designSystem/containers/Inline';
import Spacer from 'components/designSystem/containers/Spacer';
import { PdfViewer } from 'components/legacyDesignSystem/components/PdfViewer';
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import React from 'react';


export enum RawFileType {
    PDF = 'PDF',
    HPRIM = 'HPRIM',
}

export interface RawFile {
    link: string;
    type: RawFileType
}

export interface RawFileModulePayload {
    files: RawFile[];
}

export const RawFileModule = (): JSX.Element => {
    const {files} = useModulePayload<RawFileModulePayload>();
    return <Spacer>
        {files.map((f: RawFile, k: number) =>
            f.type === RawFileType.PDF ?
                <Inline key={k} justifyContent={InlineJustifyContent.CENTER}><PdfViewer link={f.link}/></Inline> :
                <div key={k}/>
        )}
    </Spacer>
};
