import TrText from "components/common/TrText";
import Pill, { PillSeverity } from "components/designSystem/components/Pill";
import { TransDomain } from "components/pages/common/MainComponent";
import { toLocalTransKey } from "models/common/message";
import { ModuleReportComment } from "models/modular/report";
import React, { Component, Fragment, PropsWithChildren } from 'react';
import { capitalizeFirstLetter } from 'core/utils/text';
import { MediumSpacer, SmallSpacer } from 'components/designSystem/containers/Spacer';
import { TBody, TCaption, TDisplayXSmall700, TypographyColor } from 'components/designSystem/containers/Typography';
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from 'components/designSystem/containers/Inline';
import Icon from 'components/designSystem/foundations/Icons';
import WithMargin, { MarginSize, WithTopMargin } from 'components/designSystem/containers/WithMargin';
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { WhiteBoxWithShadow } from 'components/designSystem/containers/Box';
import WithPadding, { PaddingSize } from 'components/designSystem/containers/WithPadding';
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { formatComments } from "components/modules/modular/modules/report/content/lines/utils";


export type ReportCategoryInterface = PropsWithChildren<{
    isMobile: boolean;
    onlyShowImportantResults?: boolean;
    hasImportantResults?: boolean;
    name?: string;
    iconName?: IconName;
    childrenMapping: (showOnlyImportantResults: boolean) => JSX.Element;
    comments?: ModuleReportComment[]
}>


export default class ReportCategory extends Component<ReportCategoryInterface> {
    state = {
        contentIsCollapsed: this.props.onlyShowImportantResults ?? false,
        onlyShowImportantResults: this.props.onlyShowImportantResults ?? false,
    }

    componentDidUpdate(prevProps: ReportCategoryInterface): void {
        if (prevProps.onlyShowImportantResults !== this.props.onlyShowImportantResults) {
            this.setState({contentIsCollapsed: this.props.onlyShowImportantResults});
            this.setState({onlyShowImportantResults: this.props.onlyShowImportantResults});
        }
    }

    noImportantResultForCategory(isMobile: boolean): JSX.Element {
        const margins = isMobile ? [MarginSize.MEDIUM, MarginSize.MEDIUM] :
            [MarginSize.SMALL, MarginSize.LARGE, MarginSize.SMALL, MarginSize.LARGE_PLUS_MEDIUM]
        return <div>
            <WithMargin margins={margins}>
                <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                    <Pill severity={PillSeverity.SAFE}/>
                    {isMobile && <TBody color={TypographyColor.COLOR_TEXT_DEFAULT}>
                        <TrText input={toLocalTransKey("report.no_anomaly", TransDomain.MODULES)}/>
                    </TBody>}
                    {!isMobile && <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>
                        <TrText input={toLocalTransKey("report.no_anomaly", TransDomain.MODULES)}/>
                    </TCaption>}
                </AlwaysInline>
            </WithMargin>
        </div>
    }

    resultsToDisplay(): JSX.Element {
        if (!this.state.contentIsCollapsed) {
            return this.props.childrenMapping(false)
        }
        if (this.state.onlyShowImportantResults) {
            if (this.props.hasImportantResults) {
                return this.props.childrenMapping(true)
            }
            return this.noImportantResultForCategory(this.props.isMobile)
        }
        return <Fragment/>
    }

    render(): JSX.Element {
        const content = (
            <SmallSpacer>
                <AlwaysInline
                    justifyContent={this.props.name ? InlineJustifyContent.SPACE_BETWEEN : InlineJustifyContent.FLEX_END}>
                    {this.props.name &&
                        <AlwaysInline spacing={InlineSpacing.SMALL}>
                            <Icon name={this.props.iconName ?? IconName.TUBE} size={IconSize.SMALL} color={IconColor.DEFAULT}/>
                            <TDisplayXSmall700
                                color={TypographyColor.COLOR_TEXT_DEFAULT}>{capitalizeFirstLetter(this.props.name)}</TDisplayXSmall700>
                        </AlwaysInline>
                    }
                    <Button variant={ButtonVariant.QUATERNARY}
                            size={ButtonSize.SMALL}
                            icons={{middle: this.state.contentIsCollapsed ? IconName.CHEVRON_DOWN : IconName.CHEVRON_UP}}
                            onClick={ (): void => {this.setState({contentIsCollapsed: !this.state.contentIsCollapsed});}}>
                    </Button>
                </AlwaysInline>
                {
                    this.props.isMobile ?
                        <SmallSpacer>
                            {this.resultsToDisplay()}
                        </SmallSpacer> :
                        <MediumSpacer>
                            {this.resultsToDisplay()}
                        </MediumSpacer>
                }
                {
                    this.props.comments && !this.state.contentIsCollapsed &&
                    formatComments(this.props.comments, this.props.isMobile, true)
                }
            </SmallSpacer>
        )
        if (this.props.isMobile) {
            return <WithTopMargin margin={MarginSize.X_SMALL}>
                {content}
            </WithTopMargin>
        }
        return <WhiteBoxWithShadow>
            <WithPadding paddings={[PaddingSize.LARGE, PaddingSize.MEDIUM]}>
                {content}
            </WithPadding>
        </WhiteBoxWithShadow>
    }
}