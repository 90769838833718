import { detect } from 'detect-browser';

const browserData = detect();

export const isSafari = (): boolean => browserData !== null && browserData.name === 'safari';

export const DEV_OPTIONS_KEYS = 'dev_options';

export const isDevOptionActivated = (optionName: string): boolean => {
    const rawOptions = localStorage.getItem(DEV_OPTIONS_KEYS);

    if (rawOptions) {
        const options = rawOptions.split(',');
        return options.includes(optionName);
    }
    return false;
};

export enum BrowserType {
    CHROME = "Google Chrome / Chromium",
    EDGE = "Microsoft Edge",
    FIREFOX = "Mozilla Firefox",
    IE = "Microsoft Internet Explorer",
    OPERA = "Opera",
    SAFARI = "Safari",
    SAMSUNG_INTERNET = "Samsung Internet",
    UNKNOWN = "Unknown",
}

export interface Browser {
    type: BrowserType;
    version: string;
}

const getBrowserVersion = (userAgent: string, browserName: string): string => {
    if (browserName === 'Safari') {
        const version = userAgent.match('Version/[0-9.]+')
        return version !== null ? version[0].split('/')[1] : ''
    }
    const browserAndVersion = userAgent.match(`${browserName}/[0-9.]+`)
    if (browserAndVersion === null) {
        return '';
    }
    return browserAndVersion[0].split('/')[1]
}

export const whichBrowser = (): Browser => {
    // source: https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator#example_1_browser_detect_and_return_a_string
    const sUsrAg = navigator.userAgent;

    // The order matters here, and this may report false positives for unlisted browsers.
    if (sUsrAg.indexOf('Firefox') > -1) {
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        return {
            type: BrowserType.FIREFOX,
            version: getBrowserVersion(sUsrAg, 'Firefox'),
        }
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
        return {
            type: BrowserType.SAMSUNG_INTERNET,
            version: getBrowserVersion(sUsrAg, 'SamsungBrowser')
        }
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        const version = getBrowserVersion(sUsrAg, 'Opera') || getBrowserVersion(sUsrAg, 'OPR')
        return {
            type: BrowserType.OPERA,
            version: version,
        }
    } else if (sUsrAg.indexOf('Trident') > -1) {
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        return {
            type: BrowserType.IE,
            version: getBrowserVersion(sUsrAg, 'Trident')
        }
    } else if (sUsrAg.indexOf('Edge') > -1 || sUsrAg.indexOf('Edg') > -1) {
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        return {
            type: BrowserType.EDGE,
            version: getBrowserVersion(sUsrAg, 'Edge') || getBrowserVersion(sUsrAg, 'Edg')
        }
    } else if (sUsrAg.indexOf('Chrome') > -1) {
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        return {
            type: BrowserType.CHROME,
            version: getBrowserVersion(sUsrAg, 'Chrome')
        }
    } else if (sUsrAg.indexOf("Safari") > -1) {
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        return {
            type: BrowserType.SAFARI,
            version: getBrowserVersion(sUsrAg, 'Safari')
        }
    } else {
        return {
            type: BrowserType.UNKNOWN,
            version: ''
        }
    }
}

export const isBrowserVersionOk = (references: Browser[]): boolean => {
    let isOk = true;
    const browser = whichBrowser();
    references.forEach((b: Browser) => {
        if (browser.type === b.type) {
            const version = browser.version.split('.')
            const minVersion = b.version.split('.')
            version.every((elt: string, i: number) => {
                if (i < minVersion.length && parseInt(elt) < parseInt(minVersion[i])) {
                    isOk = false
                    return false;
                } else if (i > minVersion.length || parseInt(elt) > parseInt(minVersion[i])) {
                    return false;
                }
                return true;
            })
        }
    })
    return isOk;
}
