import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import React from "react";

export const Divider = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-divider'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8 13.75C7.58579 13.75 7.25 13.4142 7.25 13V3C7.25 2.58579 7.58579 2.25 8 2.25C8.41421 2.25 8.75 2.58579 8.75 3V13C8.75 13.4142 8.41421 13.75 8 13.75Z"
                      fill="currentColor"/>
            </svg>

        )
    }

    return (
        <svg id={'s-divider'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 20C11.4477 20 11 19.5523 11 19V5C11 4.44771 11.4477 4 12 4C12.5523 4 13 4.44771 13 5V19C13 19.5523 12.5523 20 12 20Z"
                  fill="currentColor"/>
        </svg>

    )
}