import Divider, { DividerVariant } from "components/designSystem/components/Divider";
import { AlwaysInline, Grow, InlineAlignItem } from "components/designSystem/containers/Inline";
import Spacer, { SpacerSpacing } from "components/designSystem/containers/Spacer";
import Typography, { TypographyColor, TypographyVariant } from "components/designSystem/containers/Typography";
import React, { FC, ReactNode } from "react";
import Hoverable from "components/designSystem/containers/Hoverable";

interface ListItemWithDescriptionProps {
    title: ReactNode,
    description :ReactNode,
    descriptionColor?: TypographyColor,
    action?: ReactNode,
    withDivider?: boolean,
    onClick?: () => void,
}

/**
 * https://zeroheight.com/3d9d5f5d4/p/2410db-lists/b/9793c9
 */
const ListItemWithDescription: FC<ListItemWithDescriptionProps> = (props): JSX.Element => {

    const content = (
        <Spacer spacing={SpacerSpacing.SMALL}>
            <AlwaysInline alignItems={InlineAlignItem.CENTER} onClick={props.onClick}>
                <Grow>
                    <Spacer spacing={SpacerSpacing.SMALL}>
                        <div>
                            <Typography variant={TypographyVariant.LABEL}>{props.title}</Typography>
                        </div>
                        <div>
                            <Typography variant={TypographyVariant.CAPTION} color={props.descriptionColor}>
                                {props.description}
                            </Typography>
                        </div>
                    </Spacer>
                </Grow>
                {props.action}
            </AlwaysInline>
            {props.withDivider && <Divider variant={DividerVariant.DEFAULT} />}
        </Spacer>
    );
    if (props.onClick) {
        return <Hoverable>
            {content}
        </Hoverable>

    }
    return content
};

export default ListItemWithDescription;
