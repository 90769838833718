import { redirect } from "actions/common/CommonActions";
import { getSharedReport } from "actions/medicalReports/MedicalReportsFetchActions";
import LOGGER from "core/logging/Logger";
import { ROUTE_OUTDATED_REPORT_ACCESS_LINK } from "core/routing/Routes";
import { OutdatedReportType } from "models/medicalReport/ConnectionModesModel";
import { GetSharedReportResponse } from "models/medicalReport/ReportAccessModel";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { REDUCER_REPORT } from "reducers/allReducers";
import { fetchReducer } from "reducers/selector";

interface sharedReportResponse {
    failed?: boolean;
    success?: GetSharedReportResponse
}

const SharedReport = (): JSX.Element => {
    const { token } = useParams<{token?: string}>();
    const dispatch = useDispatch();
    const sharedReportResponse: sharedReportResponse = useSelector(
        (state) => fetchReducer(state, REDUCER_REPORT).sharedReportResponse
    )

    if (token && !sharedReportResponse) {
        dispatch(getSharedReport(token))
    }

    if (sharedReportResponse) {
        if (sharedReportResponse.failed) {
            dispatch(redirect(
                ROUTE_OUTDATED_REPORT_ACCESS_LINK, {},
                undefined, undefined, {
                    type: OutdatedReportType.SHARED_REPORT,
                    token: token
                }
            ))
        } else if (sharedReportResponse.success) {
            LOGGER.critical(
                'Connection modes in shared report response is deactivated for now'
            )
            return <Fragment/>
        }
    }

    return <Fragment/>
}

export default SharedReport;
