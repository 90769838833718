import { ActionDispatcher } from 'actions/ActionInterface';

import { redirect } from 'actions/common/CommonActions';
import Button, { ButtonType } from 'components/core/buttons/Button';
import Link from 'components/core/buttons/Link';
import MainComponent, { MainComponentProps, setup } from 'components/pages/common/MainComponent';
import { APP_ROUTES } from 'core/routing/Router';
import { ROUTE_DEFAULT_MODULAR } from 'core/routing/Routes';
import React, { ReactNode } from 'react';
import { matchPath, Route } from 'react-router';

import './Navigation.scss';

export interface ReturnButtonStateProps {
    path?: string;
    type?: ButtonType;
}

export interface ReturnButtonDispatchProps {
    redirectTo: (route: string) => void;
}

const SUPPORTED_COMPONENTS: { [key: string]: string } = {};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getComponentKey = (component?: React.ComponentType<any>): string => {
    if (!component || !component.displayName || !(component.displayName in SUPPORTED_COMPONENTS)) {
        return 'default';
    }
    return SUPPORTED_COMPONENTS[component.displayName];
};

const matchComponentName = (url: string): string => {
    if (!url) return getComponentKey();

    const route: Route | undefined = APP_ROUTES.find((route: Route) => {
        if (route.props.path === undefined) return false;
        const path = Array.isArray(route.props.path) ? route.props.path[0] : route.props.path;
        return matchPath(url, path) !== null;
    });

    if (route === undefined || route.props.component === undefined) {
        return getComponentKey();
    }
    return getComponentKey(route.props.component);
};

class ReturnButton extends MainComponent<ReturnButtonStateProps & ReturnButtonDispatchProps & MainComponentProps> {
    TRANS_SUFFIX = 'navigation';

    render(): ReactNode {
        const { redirectTo, type } = this.props;
        const path = this.props.path || ROUTE_DEFAULT_MODULAR;

        return (
            <Button
                type={type}
                content={
                    <Link className={'return-button-link'} onClick={(): void => redirectTo(path)}>
                        {this.trans('return_button.' + matchComponentName(path))}
                    </Link>
                }
            />
        );
    }
}

const mapStateToProps = (): {} => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): ReturnButtonDispatchProps => ({
    redirectTo: (route: string): void => dispatch(redirect(route)),
});

export default setup(ReturnButton, mapStateToProps, mapDispatchToProps);
