import React, {Component, Fragment, PropsWithChildren} from 'react';
import { capitalizeFirstLetter } from 'core/utils/text';
import WithMargin, { MarginSize, WithHorizontalMargin } from 'components/designSystem/containers/WithMargin';
import { SmallSpacer } from 'components/designSystem/containers/Spacer';
import { TBody700, TypographyColor } from 'components/designSystem/containers/Typography';

export type ReportSubCategoryInterface = PropsWithChildren<{
    name?: string;
    isMobile: boolean
    hideContent?:boolean
}>

export default class ReportSubCategory extends Component<ReportSubCategoryInterface> {

    buildContent(isMobile: boolean): JSX.Element {
        return (
            <SmallSpacer>
                {this.props.name &&
                    <WithMargin margins={[MarginSize.MEDIUM, undefined, undefined, isMobile ? MarginSize.X_LARGE : MarginSize.SMALL]}>
                        <TBody700 color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            {capitalizeFirstLetter(this.props.name)}
                        </TBody700>
                    </WithMargin>
                }
                {
                    this.props.isMobile ?
                        <SmallSpacer>
                            {this.props.children}
                        </SmallSpacer> :
                        <div>
                            {this.props.children}
                        </div>
                }
            </SmallSpacer>
        )
    }
    render(): JSX.Element {
        if (this.props.hideContent){
            return <Fragment/>
        }
        if (this.props.isMobile) {
            return this.buildContent(this.props.isMobile)
        }
        return (
            <WithHorizontalMargin margin={MarginSize.LARGE}>
                {this.buildContent(this.props.isMobile)}
            </WithHorizontalMargin>
        )
    }
}