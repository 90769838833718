import { DisplayHorizontalSize, DisplayVerticalSize, ModuleDisplayData } from 'models/modular/api';
import { DisplayConfiguration } from 'components/modules/modular/BaseModule';

function computeHorizontalClassFromConfiguration(defaultSize: DisplayHorizontalSize): string[] {
    if (defaultSize === DisplayHorizontalSize.ONE_QUARTER) {
        return ["module_H_ONE_QUARTER"]
    }

    if (defaultSize === DisplayHorizontalSize.THIRD) {
        return ["module_H_THIRD"]
    }

    if (defaultSize === DisplayHorizontalSize.HALF) {
        return ["module_H_HALF"]
    }

    if (defaultSize === DisplayHorizontalSize.THREE_QUARTER) {
        return ["module_H_THREE_QUARTER"]
    }

    return []
}

const HORIZONTAL_SIZE_ORDER = [
    DisplayHorizontalSize.ONE_QUARTER,
    DisplayHorizontalSize.HALF,
    DisplayHorizontalSize.THREE_QUARTER,
    DisplayHorizontalSize.FULL,
]

function computeDisplayHorizontalSize(
    overiddenSize: DisplayHorizontalSize,
    minSize?: DisplayHorizontalSize,
    maxSize?: DisplayHorizontalSize,
): DisplayHorizontalSize {
    const overridePosition = HORIZONTAL_SIZE_ORDER.indexOf(overiddenSize);
    const minPosition = minSize ? HORIZONTAL_SIZE_ORDER.indexOf(minSize) : -1;
    const maxPosition = maxSize ? HORIZONTAL_SIZE_ORDER.indexOf(maxSize) : 999;

    if (minPosition > overridePosition) {
        return minSize ? minSize : overiddenSize
    }

    if (maxPosition < overridePosition) {
        return maxSize ? maxSize : overiddenSize
    }

    return overiddenSize
}

export function computeHorizontalClasses(
    display: DisplayConfiguration,
    moduleDisplayData?: ModuleDisplayData
): string[] {
    if (!moduleDisplayData || !moduleDisplayData.horizontal) {
        return computeHorizontalClassFromConfiguration(
            display.horizontalConfiguration.defaultSize
        )
    }

    return computeHorizontalClassFromConfiguration(
        computeDisplayHorizontalSize(
            moduleDisplayData.horizontal.size,
            display.horizontalConfiguration.minSize,
            display.horizontalConfiguration.maxSize
        )
    )
}


function computeVerticalClassFromConfiguration(
    defaultSize: DisplayVerticalSize,
    minSize?: DisplayVerticalSize,
    maxSize?: DisplayVerticalSize,
): string[] {
    const classes = [];

    switch (defaultSize) {
        case DisplayVerticalSize.AUTO:
            classes.push("module_V_AUTO"); break;
        case DisplayVerticalSize.SMALL:
            classes.push("module_V_SMALL"); break;
        case DisplayVerticalSize.NORMAL:
            classes.push("module_V_NORMAL"); break;
        case DisplayVerticalSize.BIG:
            classes.push("module_V_BIG"); break;
        default:
            classes.push("module_V_NORMAL"); break;
    }

    switch (minSize) {
        case DisplayVerticalSize.BIG:
            classes.push("module_V_MIN_BIG"); break;
        case DisplayVerticalSize.NORMAL:
            classes.push("module_V_MIN_NORMAL"); break;
        case DisplayVerticalSize.SMALL:
            classes.push("module_V_MIN_SMALL"); break;
    }

    switch (maxSize) {
        case DisplayVerticalSize.BIG:
            classes.push("module_V_MAX_BIG"); break;
        case DisplayVerticalSize.NORMAL:
            classes.push("module_V_MAX_NORMAL"); break;
        case DisplayVerticalSize.SMALL:
            classes.push("module_V_MAX_SMALL"); break;
    }

    return classes
}

const VERTICAL_SIZE_ORDER = [
    DisplayVerticalSize.SMALL,
    DisplayVerticalSize.NORMAL,
    DisplayVerticalSize.BIG,
    DisplayVerticalSize.AUTO,
]

function computeDisplayVerticalSize(
    overiddenSize: DisplayVerticalSize,
    overiddenMinSize?: DisplayVerticalSize,
    overiddenMaxSize?: DisplayVerticalSize,
    minSize?: DisplayVerticalSize,
    maxSize?: DisplayVerticalSize,
): [DisplayVerticalSize, DisplayVerticalSize?, DisplayVerticalSize?] {
    const overrideMinPosition = overiddenMinSize ? VERTICAL_SIZE_ORDER.indexOf(overiddenMinSize) : -1;
    const overrideMaxPosition = overiddenMaxSize ? VERTICAL_SIZE_ORDER.indexOf(overiddenMaxSize) : 999;
    const defaultMinPosition = minSize ? VERTICAL_SIZE_ORDER.indexOf(minSize) : -1;
    const defaultMaxPosition = maxSize ? VERTICAL_SIZE_ORDER.indexOf(maxSize) : 999;

    const finalMin = overrideMinPosition >= defaultMinPosition ? overrideMinPosition : defaultMinPosition;
    const finalMax = overrideMaxPosition <= defaultMaxPosition ? overrideMaxPosition : defaultMaxPosition;

    return [
        overiddenSize,
        VERTICAL_SIZE_ORDER[finalMin],
        VERTICAL_SIZE_ORDER[finalMax],
    ]
}

export function computeVerticalClasses(
    display: DisplayConfiguration,
    moduleDisplayData?: ModuleDisplayData,
): string[] {
    if (!moduleDisplayData || !moduleDisplayData.vertical) {
        return computeVerticalClassFromConfiguration(
            display.verticalConfiguration.defaultSize,
            display.verticalConfiguration.minSize,
            display.verticalConfiguration.maxSize,
        )
    }

    return computeVerticalClassFromConfiguration(
        ...computeDisplayVerticalSize(
            moduleDisplayData.vertical.base_size,
            moduleDisplayData.vertical.min_size,
            moduleDisplayData.vertical.max_size,
            display.verticalConfiguration.minSize,
            display.verticalConfiguration.maxSize
        )
    )
}
