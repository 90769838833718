import { ActionDispatcher, APICallAction } from 'actions/ActionInterface';
import { modal, ModalContentBuilder, ModalExtra, ModalType } from 'actions/common/CommonActions';
import { alterContext, changePage, ModuleAPICallAction } from 'actions/modular/ModularActions';
import classNames from 'classnames';
import { DropdownMenu, DropdownMenuItem } from 'components/core/containers/DropdownMenu';
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";
import { Button, ButtonType } from 'components/legacyDesignSystem/components/Button';
import { Icon, Icons } from 'components/legacyDesignSystem/components/Icon';
import {
    computeHorizontalClasses,
    computeVerticalClasses
} from 'components/modules/modular/BaseModuleDisplayUtilities';
import { buildViewModuleAction } from 'components/modules/modular/modules/builders/userAction';
import WithTranslations from 'components/pages/common/WithTranslations';
import LOGGER from 'core/logging/Logger';
import { AnyState } from 'core/store/Store';
import { cast } from 'core/utils/Typed';
import {
    DisplayHorizontalSize,
    DisplayVerticalSize,
    ModuleDisplayData,
    ModuleV1Type,
    PageContext
} from 'models/modular/api';
import { ModuleMenu, ModuleMenuItem, ModuleMenuItemSimpleLink } from 'models/modular/menu';
import { ConnectedContext, ModuleData, OwnPayloadConstraint, OwnPayloadDefault } from 'models/modular/storage';
import React, { Fragment, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import { moduleConnectedContext, modulePayload } from 'reducers/modular/utils';

import "./Modular.scss"

export interface BaseModuleStateProps<P, O extends OwnPayloadConstraint = OwnPayloadDefault> {
    payload?: ModuleData<P, O>,
    connectedContext: ConnectedContext
}

export type ChangePageParams = { pageIdentifier: string, pageContext?: PageContext };

export interface BaseModuleDispatchProps {
    alterContext: (key?: string, value?: string) => void,
    changePage: ((params: ChangePageParams) => void)
    modal: (type: ModalType, contentBuilder: ModalContentBuilder, extra?: ModalExtra) => void
}

export interface BaseModuleComponentProps {
    identifier: string,
    pageId: string,
    pageUrlContext?: PageContext,
    refreshPage: () => void
    displayData: ModuleDisplayData,
    type: ModuleV1Type,
    displayedInSideBar?: boolean
}

export interface BaseModuleProps<P, O extends OwnPayloadConstraint = OwnPayloadDefault> extends BaseModuleStateProps<P, O>, BaseModuleDispatchProps, BaseModuleComponentProps {
    context: PageContext
}

export interface DisplayHorizontalConfiguration {
    defaultSize: DisplayHorizontalSize,
    minSize?: DisplayHorizontalSize,
    maxSize?: DisplayHorizontalSize,

}

export interface DisplayVerticalConfiguration {
    defaultSize: DisplayVerticalSize,
    minSize?: DisplayVerticalSize,
    maxSize?: DisplayVerticalSize,

}


export interface DisplayConfiguration {
    horizontalConfiguration: DisplayHorizontalConfiguration
    verticalConfiguration: DisplayVerticalConfiguration
}

export interface BaseModuleState {
    viewed: boolean
}

export type ModuleState<T> = BaseModuleState & T;

export function defaultBaseModuleState<T>(state: T): ModuleState<T> {
    return {
        ...{
            viewed: false,
        }, ...state
    }
}

abstract class BaseModule<OwnProps, PagePayload, OwnPayload extends OwnPayloadConstraint = OwnPayloadDefault> extends WithTranslations<OwnProps & BaseModuleProps<PagePayload, OwnPayload>> {
    state: BaseModuleState = defaultBaseModuleState({})

    protected abstract _render(payload: ModuleData<PagePayload, OwnPayload>): ReactNode;

    protected _renderSideBar(payload: ModuleData<PagePayload, OwnPayload>): ReactNode {
        LOGGER.critical(
            '_renderSideBar not implemented for ' + payload.identification.unique_id
        )
        return <Fragment/>
    }

    private updateView(inView: boolean): void {
        if (inView && !this.state.viewed) {
            this.setState({viewed: true})
        }
    }

    render(): ReactNode {
        const forceTyping: BaseModuleProps<PagePayload, OwnPayload> = this.props;
        const component = forceTyping.payload && !forceTyping.payload.loading ?
            forceTyping.displayedInSideBar ? this._renderSideBar(forceTyping.payload) : this._render(forceTyping.payload)
            : this.loading()
        if (component === undefined) {
            return <Fragment/>
        }

        const defaultDisplayConfiguration = {
            horizontalConfiguration: {defaultSize: DisplayHorizontalSize.FULL},
            verticalConfiguration: {defaultSize: DisplayVerticalSize.AUTO},
        };
        const classNames_ = classNames(
            "module",
            computeHorizontalClasses(defaultDisplayConfiguration, forceTyping.displayData),
            computeVerticalClasses(defaultDisplayConfiguration, forceTyping.displayData),
        )

        if (!('IntersectionObserver' in window) ||
            !('IntersectionObserverEntry' in window) ||
            !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
            return <div className={classNames_}>{component}</div>
        }

        return <InView onChange={(inView: boolean): void => this.updateView(inView)}>
            {({inView, ref}): ReactNode => {
                if (!this.state.viewed && inView && (forceTyping.payload && !forceTyping.payload.loading)) {
                    LOGGER.userAction(
                        buildViewModuleAction(
                            this.props.pageId,
                            this.props.type,
                            this.props.identifier,
                            forceTyping.displayedInSideBar ? 'in_side_bar' : undefined
                        )
                    )
                }

                return (
                    <div ref={ref} className={classNames_}>{component}</div>
                )
            }}
        </InView>
    }


    protected loading(): ReactNode {
        return <ModuleLoaderWrapper>
            <div className={"module-loading module_V_NORMAL"}/>
        </ModuleLoaderWrapper>
    }

    protected getConnectedContextValue(internalKey: string): string | null {
        const v = this.props.connectedContext[internalKey];
        return v === undefined ? null : v.value
    }

    protected getConnectedContextKey(internalKey: string): string | undefined {
        const v = this.props.connectedContext[internalKey];
        return v === undefined ? undefined : v.contextKey
    }

    protected isConnectedContextKeySetUp = (internalKey: string): boolean => (
        Object.hasOwn(this.props.connectedContext, internalKey)
    )

    // This method shall not be used by Modules as they are connected to contaxt value through getConnectedContextKey
    // or alterContext
    private getContextValue(contextKey: string): string | undefined {
        if (!this.props.payload) {
            return undefined
        }
        const internalKey = this.props.payload.contextMapping[contextKey];

        if (internalKey && Object.hasOwn(this.props.connectedContext, internalKey)) {
            const value = this.props.connectedContext[internalKey].value;
            return value ? value : undefined;
        }

        return undefined
    }

    protected buildModuleMenu(menu: ModuleMenu): ReactNode {
        return <DropdownMenu
            titleComponent={<Button customType={ButtonType.TERTIARY}><Icon name={Icons.MORE}/></Button>}
            noMargin={true}
            lightTitle
        >
            {
                menu.items.map((item, index) => this.buildModuleMenuItem(item, index))
            }
        </DropdownMenu>
    }

    private buildModuleMenuItem(item: ModuleMenuItem, index: number): ReactNode {
        if (item.content.type == "SIMPLE_LINK") {
            const content = cast<ModuleMenuItemSimpleLink>(item.content);
            return <DropdownMenuItem
                key={index}
                name={
                    <>
                        {item.icon &&
                            <Icon name={Icons.DOWNLOAD} inText/>
                        }
                        {this.transApiText(item.title)}
                    </>
                }
                onClick={(): void => {
                    window.open(content.url, content.new_tab ? '_blank' : undefined);
                }}
            />
        }
    }

}


export function buildModuleAPICallAction<T>(apiCallAction: APICallAction<T>, identifier: string): ModuleAPICallAction<T> {
    return {
        ...apiCallAction,
        ...{
            payload: {
                ...apiCallAction.payload,
                moduleIdentifier: identifier
            }
        }
    }
}

export type OwnProps<T = unknown> = BaseModuleProps<T>;

export function setupModule<S, D, P>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    C: any,
    _mapStateToProps: (
        state: AnyState,
        ownProps: OwnProps
    ) => S,
    _mapDispatchToProps: (
        dispatch: ActionDispatcher,
        ownProps: OwnProps
    ) => D,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {

    const finalMapStateToProps = (
        state: AnyState,
        ownProps: OwnProps
    ): BaseModuleStateProps<P> & S => (
        {
            ...{
                payload: modulePayload<P>(state, ownProps.identifier),
                connectedContext: moduleConnectedContext(state, ownProps.identifier)
            },
            ..._mapStateToProps(state, ownProps)
        }
    );

    const finalMapDispatchToProps = (
        dispatch: ActionDispatcher,
        ownProps: OwnProps,
    ): BaseModuleDispatchProps & D => (
        {
            ...{
                alterContext: (key?: string, value?: string): void => key === undefined ? undefined : dispatch(alterContext(key, value)),
                changePage: (params: ChangePageParams): void => dispatch(changePage(params.pageIdentifier, params.pageContext, ownProps.pageId, ownProps.pageUrlContext)),
                modal: (type: ModalType, content: ModalContentBuilder, extra?: ModalExtra): void => dispatch(modal(type, content, extra))
            },
            ..._mapDispatchToProps(dispatch, ownProps),
        }
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return connect(finalMapStateToProps, finalMapDispatchToProps)(withTranslation()(C));
}

export default BaseModule;
