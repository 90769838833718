import { WithFooter } from 'components/commonDesign/Footer';
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import { CenteredColumn } from "components/designSystem/Aliases";
import { ColumnWidthInREM } from "components/designSystem/containers/Column";
import { AlwaysInline, InlineAlignItem, InlineJustifyContent } from "components/designSystem/containers/Inline";
import { LargeSpacer } from "components/designSystem/containers/Spacer";
import WithMargin, { MarginSize, WithBottomMargin, WithTopMargin } from "components/designSystem/containers/WithMargin";
import { KiroLogo } from "components/designSystem/foundations/logos/KiroLogo";
import LanguageSelector from "components/modules/modular/components/LanguageSelector";
import KiroCommunication, { KiroCommunicationType } from 'components/modules/user/KiroCommunication';
import React, { Component, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';

export type LandingProps = PropsWithChildren<{
    communicationType: KiroCommunicationType
}>


export class LandingScreen extends Component<LandingProps> {

    private buildContent(isMobile: boolean): JSX.Element {
        return <WithFooter>
            <CenteredColumn widthInRem={isMobile ? ColumnWidthInREM.WIDTH_22 : ColumnWidthInREM.WIDTH_28}>
                <WithMargin margins={isMobile ? [undefined, MarginSize.MEDIUM] : [undefined]}>
                    <WithTopMargin margin={MarginSize.LARGE}>
                        <LargeSpacer>
                            {/*Bottom Margin is added to be aligned with KiroCommunication when not in Mobile*/}
                            <WithBottomMargin margin={isMobile ? MarginSize.X_LARGE : MarginSize.FIVE_TIMES_MEDIUM}>
                                <AlwaysInline justifyContent={
                                    isMobile ? InlineJustifyContent.SPACE_BETWEEN : InlineJustifyContent.FLEX_END
                                } alignItems={InlineAlignItem.CENTER}>
                                    {isMobile && <KiroLogo small/>}
                                    <LanguageSelector/>
                                </AlwaysInline>
                            </WithBottomMargin>
                            {this.props.children}
                        </LargeSpacer>
                    </WithTopMargin>
                </WithMargin>
            </CenteredColumn>
        </WithFooter>
    }

    private renderDesktop(): JSX.Element {
        return <>
            <div style={{
                position: 'fixed',
                width: '32rem',
                height: '100%',
            }}>
                <KiroCommunication type={this.props.communicationType}/>
            </div>
            <div
                style={{
                    position: 'fixed',
                    left: '32rem',
                    width: `calc(100% - 32rem)`,
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                {this.buildContent(false)}
            </div>
        </>
    }

    private renderMobile(): JSX.Element {
        return <div
            style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                overflow: 'auto',
            }}
        >
            {this.buildContent(true)}
        </div>
    }

    render(): JSX.Element {
        return <MediaQuery minWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
            {(match: boolean): JSX.Element => match ? this.renderDesktop() : this.renderMobile()}
        </MediaQuery>
    }
}