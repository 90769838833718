import {ModuleV1Type} from 'models/modular/api';
import {Action, ResourceType, SubResourceType, UserActionEvent} from 'core/logging/ActionEvent';

export function buildResourceType(module: ModuleV1Type, clickName: string | undefined): string{
    return clickName === undefined ? module : [module.valueOf(), clickName].join(':')
}

export function buildClickModuleAction(pageId: string, module: ModuleV1Type, subResourceClickName: string | undefined):
    UserActionEvent {
    return {
        action: Action.CLICK_MODULE,
        resource_id: pageId,
        resource_type: ResourceType.PAGE_ID,
        sub_resource_id: buildResourceType(module, subResourceClickName),
        sub_resource_type: SubResourceType.CLICK_BY_MODULE_TYPE
    }
}

export function buildViewModuleAction(
    pageId: string,
    moduleV1Type: ModuleV1Type,
    moduleIdentifier: string,
    subResourceClickName: string | undefined
): UserActionEvent {
    return {
        action: Action.VIEW_MODULE,
        action_payload: moduleIdentifier,
        resource_id: pageId,
        resource_type: ResourceType.PAGE_ID,
        sub_resource_id: buildResourceType(moduleV1Type, subResourceClickName),
        sub_resource_type: SubResourceType.MODULE_TYPE,
    }
}