import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import _ from 'underscore';

import 'components/core/inputs/SearchBar.scss';
import MagnifyingGlass from 'core/content/icons/MagnifyingGlass';

export interface SelectOptionInterface<T> {
    options: T[];
    renderOptionRow: (option: T, index: number, cursor: number) => ReactNode;
    onOptionClick: (option: T) => void;
}

export interface LegacySearchBarProps<T> {
    defaultText?: string;
    onSearch?: (value: string) => void;
    selectOptionMode?: SelectOptionInterface<T>;
    onInputChange?: (newValue: string) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    disabled?: boolean;
}

export interface SearchBarState {
    cursor: number;
    isLoading: boolean;
}

export default class LegacySearchBar<T> extends Component<LegacySearchBarProps<T>> {
    state: SearchBarState = {
        cursor: -1,
        isLoading: false,
    };

    searchBarRef = React.createRef<HTMLInputElement>();
    inputRef = React.createRef<HTMLInputElement>();
    optionsRef = React.createRef<HTMLInputElement>();
    dispatchChange: ReturnType<typeof setInterval> | null = null;

    componentDidMount(): void {
        document.addEventListener('mousedown', (e: MouseEvent) => this.handleClickOutside(e));
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', (e: MouseEvent) => this.handleClickOutside(e));
    }

    componentDidUpdate(prevProps: LegacySearchBarProps<T>, prevState: SearchBarState): void {
        if (prevProps.selectOptionMode?.options !== this.props.selectOptionMode?.options) {
            this.setState({ isLoading: false });
        }
        if (!!this.optionsRef.current && !!this.optionsRef.current.firstElementChild) {
            const optionsListHeight = this.optionsRef.current.clientHeight;
            const optionHeight = this.optionsRef.current.firstElementChild.clientHeight;
            const cursorLimit = Math.trunc(optionsListHeight / optionHeight) - 1;

            if (prevState.cursor < this.state.cursor && this.state.cursor > cursorLimit) {
                this.optionsRef.current.scrollTop = (this.state.cursor - cursorLimit) * optionHeight;
            } else if (
                prevState.cursor > this.state.cursor &&
                this.optionsRef.current.scrollTop / optionHeight > this.state.cursor
            ) {
                this.optionsRef.current.scrollTop = this.state.cursor * optionHeight;
            }
        }
    }

    focus = (): void => {
        const node: HTMLDivElement | null = this.inputRef.current;
        if (node) {
            node.focus();
            !!this.props.onFocus && this.props.onFocus();
        }
    };

    onBlur = (): void => {
        if (!!this.props.selectOptionMode && !!this.inputRef.current) {
            this.inputRef.current.value = '';
        }
        !!this.props.onBlur && this.props.onBlur();
        this.inputRef.current?.blur();
    };

    onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        const newValue = e.currentTarget.value;
        if (this.dispatchChange) {
            clearTimeout(this.dispatchChange);
        }
        this.setState({ isLoading: true, cursor: -1 });
        this.dispatchChange = setTimeout(
            () => this.props.onInputChange && this.props.onInputChange(newValue),
            500,
        );
    };

    handleClickOutside = (e: MouseEvent): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.searchBarRef.current && !this.searchBarRef.current.contains(e.target)) {
            this.onBlur();
        }
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        const { selectOptionMode } = this.props;
        const { cursor } = this.state;
        if (selectOptionMode) {
            if (e.keyCode === 38) {
                e.preventDefault();
                if (cursor > 0) {
                    this.setState({ cursor: cursor - 1 });
                } else {
                    this.setState({ cursor: selectOptionMode.options.length - 1 });
                }
            } else if (e.keyCode === 40) {
                e.preventDefault();
                if (cursor < selectOptionMode.options.length - 1) {
                    this.setState({ cursor: cursor + 1 });
                } else {
                    this.setState({ cursor: 0 });
                }
            } else if (e.keyCode === 13) {
                e.preventDefault();
                if (cursor >= 0) {
                    selectOptionMode.onOptionClick(selectOptionMode.options[cursor]);
                    this.onBlur();
                }
            }
        } else if (e.keyCode === 13) {
            this.onSearch()
        }
    };

    onSearch = (): void => {
        if (this.props.onSearch && this.inputRef.current) {
            this.props.onSearch(this.inputRef.current.value)
        }
    }

    render(): ReactNode {
        const { defaultText, disabled, selectOptionMode } = this.props;
        const { cursor, isLoading } = this.state;

        const classNameContainer = classNames('search-container', {
            'search-container-disabled': disabled ? disabled : false,
        });

        const classNameInput = classNames(
            'search-bar-input',
            {
                'search-bar-select-option-input': !!selectOptionMode,
                'searchbar-input-loading': !!selectOptionMode && isLoading
            }
        );

        const classNameOptions = classNames({
            'search-bar-options': !_.isEmpty(selectOptionMode?.options),
        });

        return (
            <div ref={this.searchBarRef} className={classNameContainer}>
                <div className={'search-bar-input-and-button-container'}>
                    <input
                        ref={this.inputRef}
                        className={classNameInput}
                        placeholder={defaultText}
                        onChange={this.onChange}
                        onFocus={this.focus}
                        onKeyDown={this.handleKeyDown}
                    />
                    {!this.props.selectOptionMode &&
                        <div
                          className={'search-bar-button'}
                          onClick={this.onSearch}
                        >
                          <MagnifyingGlass />
                        </div>
                    }
                </div>
                {!!selectOptionMode &&
                    <div
                        ref={this.optionsRef}
                        className={classNameOptions}
                        onClick={(e: React.MouseEvent): void => {
                            e.stopPropagation();
                            this.onBlur();
                        }}
                    >
                        {!_.isEmpty(selectOptionMode.options) && selectOptionMode.options.map(
                            (p, i) => selectOptionMode.renderOptionRow(p, i, cursor)
                        )}
                    </div>
                }
            </div>
        );
    }
}
