import Navigate from "components/pages/Navigate";
import React, { useEffect } from 'react';
import { registerCPSRedirectionLocalData } from "actions/user/SignInSignUpActions";
import { PageLoader } from "components/core/items/PageLoader";
import { AnyState } from "core/store/Store";
import { CPSRedirectionLocalData } from "models/user/AuthModels";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { REDUCER_SIGN_IN_UP } from "reducers/allReducers";
import { reducer } from "reducers/selector";
import { SignInSignUpReducerInterface } from "reducers/user/SignInSignUpFetchReducers";

interface LocationState {
    cpsUrl: string
    cpsRedirectionData: CPSRedirectionLocalData
}

// This component ensured that redirection to CPS is only done when local data is stored
const RedirectToCps = (): JSX.Element => {
    const dispatch = useDispatch();

    const {state} = useLocation<LocationState>();
    const cpsUrl = state && state.cpsUrl ? state.cpsUrl : undefined
    const cpsRegistrationData = state && state.cpsRedirectionData ? state.cpsRedirectionData : undefined

    const localData: CPSRedirectionLocalData | undefined = useSelector((state: AnyState)  =>
        reducer<SignInSignUpReducerInterface>(state, REDUCER_SIGN_IN_UP).cpsRedirectionLocalData
    )

    useEffect(() => {
        if(cpsRegistrationData && localData != cpsRegistrationData){
            dispatch(registerCPSRedirectionLocalData(cpsRegistrationData))
        }
    }, [cpsRegistrationData, localData])

    if(cpsUrl && localData){
        return <Navigate path={state.cpsUrl}/>
    }

    return <PageLoader/>
}

export default RedirectToCps;
