import React from 'react';

const Apple = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M26.7076 12.2034C29.0091 15.7545 29.3536 20.6358 27.5835 24.6413L26.7993 26.4187C25.1135 30.2299 21.9326 32.3481 18.4877 31.9523C16.8387 31.7581 15.1639 31.7581 13.5111 31.9523C13.2325 31.9816 12.9578 32 12.6865 32C9.56414 32 6.74975 29.9222 5.19957 26.4187L4.41534 24.6413C2.64536 20.6358 2.99346 15.7545 5.29489 12.2034C6.92201 9.69314 9.33705 8.25286 11.9242 8.25286H13.4927C13.6649 8.25286 13.8335 8.26015 14.0021 8.27114C14.4125 8.30041 14.8267 8.3188 15.2407 8.32979C15.1967 6.69898 14.812 3.67936 13.3204 2.44431C12.9247 2.11449 12.8697 1.5208 13.1995 1.12134C13.5257 0.721875 14.1157 0.666925 14.5151 0.996743C15.1967 1.56476 15.7025 2.33071 16.0726 3.1626C16.5968 1.33755 18.2715 0 20.2578 0H23.6806C24.1974 0 24.6151 0.417739 24.6151 0.938206V3.37893C24.6151 5.79027 22.6619 7.7509 20.2578 7.7509H17.0805C17.0952 7.96341 17.1025 8.15406 17.1062 8.31892C17.4031 8.30793 17.6999 8.29323 17.9968 8.27125C18.1653 8.26026 18.3339 8.25297 18.5061 8.25297H20.0746C22.6618 8.25286 25.0805 9.69302 26.7076 12.2034ZM25.8757 23.879C27.393 20.4416 27.1034 16.2601 25.1392 13.2258C23.8456 11.2286 22.0462 10.1292 20.0746 10.1292H18.5061C18.3778 10.1292 18.2532 10.1364 18.1287 10.1438C16.7178 10.2465 15.2849 10.2465 13.8667 10.1438C13.7458 10.1366 13.6212 10.1292 13.4929 10.1292H11.9245C9.95284 10.1292 8.15718 11.2285 6.85988 13.2258C4.89567 16.2601 4.60611 20.4416 6.12332 23.879L6.91125 25.6564C8.2525 28.6907 10.7042 30.3912 13.2951 30.087C15.0908 29.8781 16.9084 29.8781 18.7042 30.087C21.2988 30.3876 23.7505 28.6908 25.0917 25.6564L25.8757 23.879ZM22.7461 3.37881V1.8763H20.2578C18.8872 1.8763 17.7696 2.99405 17.7696 4.37197V5.87449H20.2578C21.6284 5.87449 22.7461 4.75674 22.7461 3.37881Z" />
        </svg>
    )
}

export default Apple;
