import {secureOrder} from "components/common/OrderHelper";
import React, { Component, ReactNode } from 'react';
import { AnyState } from 'core/store/Store';
import { Action, ActionDispatcher } from 'actions/ActionInterface';
import { connect } from 'react-redux';
import { reducer } from 'reducers/selector';
import { REDUCER_CONNECTED_CAROUSEL } from 'reducers/allReducers';
import { ConnectedCarouselNavigatorReducer, NavigationPayload } from 'reducers/global/ConnectedCarousel';
import { navigationAddItems } from 'actions/common/ConnectedCarousel';
import Carousel from 'components/designSystem/components/Carousel';


interface ConnectedCarouselNavigatorProps {
    identifier: string,
    onChange?: (item: string) => void,

    initItems?: string[],
    initPosition?: number,
    initItem?: string,
    bulletQty?: number,
    isVertical?: boolean,
    small?: boolean,
    secureOrder?: boolean
}

interface ConnectedCarouselNavigatorStateProps {
    payload?: NavigationPayload,
}

interface ConnectedCarouselNavigatorDispatchProps {
    dispatchAction: (action: Action<unknown>) => void
}

class ConnectedCarouselNavigator extends Component<
    ConnectedCarouselNavigatorProps & ConnectedCarouselNavigatorStateProps & ConnectedCarouselNavigatorDispatchProps
    > {

    state = {
        position: 0,

        /**
         * this is made to help maintain order when loading more results in from API when navigating
         */
        actualOrder: []
    }



    componentDidMount(): void {
        const initPosition = (this.props.initItem && this.props.payload)? this.props.payload.items.indexOf(this.props.initItem) : undefined
        if (this.props.initPosition) {
            this.setState({position: this.props.initPosition})
        } else if (this.props.initItem && this.props.payload) {
            this.setState({position: initPosition})
        }
        this.updateActualOrder()

        if (this.props.payload && this.props.payload.items.length && initPosition === this.props.payload.items.length - 1 && this.props.payload.fetchNextAction) {

            this.props.dispatchAction(
                this.props.payload.fetchNextAction
            )
        }
    }

    componentDidUpdate():void {

        if(this.props.payload?.items && this.state.actualOrder && this.props.payload.items.length > this.state.actualOrder.length){
            this.updateActualOrder()
        }
    }

    private updateActualOrder(): void {

        if (this.props.secureOrder && this.props.payload?.items) {

            this.setState(
                {
                    actualOrder: secureOrder(this.state.actualOrder, this.props.payload.items)
                }
            )

            return
        }

        this.setState(
            {
                actualOrder: this.props.payload?.items
            }
        )

    }

    render(): ReactNode {

        const bulletQty = this.props.bulletQty == null ? 5 : this.props.bulletQty;

        if (this.props.payload === undefined) {
            this.props.dispatchAction(
                navigationAddItems(
                    this.props.identifier,
                    this.props.initItems ? this.props.initItems : []
                )
            )

            return  <Carousel
                activePosition={this.state.position}
                navigationLength={5}
                bulletQty={bulletQty}
                isVertical={this.props.isVertical}
                small={this.props.small}
            />

        }
        return <Carousel
            bulletQty={bulletQty}
            isVertical={this.props.isVertical}
            onPrevious={(): void => this.onPrevious()}
            onNext={(): void => this.onNext()}
            navigationLength={this.props.payload.items.length}
            activePosition={this.state.position}
            small={this.props.small}
        />

    }

    private onPrevious(): void {
        if (this.props.payload === undefined) {
            return
        }

        const newPosition = this.state.position - 1;
        this.setState({position: newPosition})

        if (this.props.onChange && newPosition >= 0) {
            this.props.onChange(this.props.payload.items[newPosition])
        }

        if (newPosition === 0 && this.props.payload && this.props.payload.fetchPreviousAction) {
            this.props.dispatchAction(
                this.props.payload.fetchPreviousAction
            )
        }
    }

    private onNext(): void {

        if (this.props.payload === undefined) {
            return
        }

        const newPosition = this.state.position + 1;

        this.setState({position: newPosition})
        if (this.props.onChange && newPosition < this.props.payload.items.length) {
            this.props.onChange(this.props.payload.items[newPosition])
        }

        if (newPosition === this.props.payload.items.length - 1 && this.props.payload && this.props.payload.fetchNextAction) {
            this.props.dispatchAction(
                this.props.payload.fetchNextAction
            )
        }
    }
}

const mapStateToProps = (state: AnyState, ownProps: {identifier: string}): ConnectedCarouselNavigatorStateProps => ({
    payload: reducer<ConnectedCarouselNavigatorReducer>(state, REDUCER_CONNECTED_CAROUSEL).payloadByIdentifiers[ownProps.identifier],
});


const mapDispatchToProps = (dispatch: ActionDispatcher): ConnectedCarouselNavigatorDispatchProps => ({
    dispatchAction: (action: Action<unknown>): void => {
        dispatch(action)
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedCarouselNavigator)
