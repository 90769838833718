import React, { FC, ReactNode, useState } from "react";
import { LargeSpacer, MediumSpacer, XLargeSpacer } from "components/designSystem/containers/Spacer";
import Button, {
    ButtonSize,
    ButtonTextStyle,
    ButtonVariant
} from "components/designSystem/components/Button";
import { TBody, TDisplaySmall700, TypographyColor } from "components/designSystem/containers/Typography";
import TrText from "components/common/TrText";
import { toLocalTransKey } from "models/common/message";
import { TransDomain } from "components/pages/common/MainComponent";
import { LiviPaylaod } from "models/modular/teleconsultation";
import { OverScrollBox } from "components/designSystem/containers/Box";
import UnorderedList from "components/designSystem/lists/UnorderList";
import { getEntityImg } from "core/content/cdn";
import { Entity } from "models/entities/default";
import { IconName } from "components/designSystem/foundations/IconsData";
import Image from "components/designSystem/components/Image";

interface LiviFlowProps {
    entity: Entity,
    payload: LiviPaylaod
    onDeSelectEntity: () => void;
    onOpenEntity: () => void;
}

const translate = (key: string, builder?: JSX.Element): ReactNode => {
    const domain = TransDomain.MODULES + '.teleconsultation.flows.livi'
    return <TrText capitalize input={toLocalTransKey(key, domain)}>
        {builder && builder}
    </TrText>
}

const LiviFlow: FC<LiviFlowProps> = ({...props}): JSX.Element => {
    const [step, setStep] = useState(1);
    const stepKey = 'step_' + step + '.'

    const imageInfo = getEntityImg(props.entity, 'illustration_1')

    return <XLargeSpacer>
        <LargeSpacer>
            <Button variant={ButtonVariant.TERTIARY} size={ButtonSize.MEDIUM}
                    icons={{left: IconName.CHEVRON_LEFT}}
                    onClick={step === 2 ? (): void => setStep(1) : props.onDeSelectEntity}>
                <TrText capitalize input={toLocalTransKey(
                    'return', TransDomain.MODULES + '.teleconsultation.flows'
                )}/>
            </Button>
            <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                {translate(stepKey + 'title')}
            </TDisplaySmall700>
            <TBody color={TypographyColor.COLOR_TEXT_DEFAULT}>
                {step === 1 ?
                    <MediumSpacer>
                        <Image alt={imageInfo.alt} src={imageInfo.src}/>
                        <div>
                            {translate(stepKey + 'paragraph_1')}
                            <br/><br/>
                            {translate(stepKey + 'paragraph_2')}
                        </div>
                        <Button variant={ButtonVariant.INLINE_LINK}
                                withOverrideTextStyle={ButtonTextStyle.LABEL}
                                onClick={(): void => setStep(2)}
                        >
                            {translate(stepKey + 'help_link')}
                        </Button>
                    </MediumSpacer> : <OverScrollBox>
                        {translate(stepKey + 'paragraph_1_a')}
                        <UnorderedList>
                            {translate(stepKey + 'paragraph_1_b', <>0
                                <li>1</li>
                            </>)}
                        </UnorderedList>
                        <br/>
                        {translate(stepKey + 'paragraph_2_a')}
                        <br/>
                        {translate(stepKey + 'paragraph_2_b_1')}
                        <UnorderedList>
                            <li>{translate(
                                stepKey + 'paragraph_2_b_2',
                                <>0
                                    <Button variant={ButtonVariant.INLINE_LINK}
                                            withOverrideTextStyle={ButtonTextStyle.LABEL}
                                            onClick={{
                                                href: props.payload.pathologies_list_link.href,
                                                targetBlank: props.payload.pathologies_list_link.target_blank
                                            }}
                                    >
                                        1
                                    </Button>
                                </>
                            )}</li>
                            <li>{translate(
                                stepKey + 'paragraph_2_b_3',
                                <>0
                                    <Button variant={ButtonVariant.INLINE_LINK}
                                            withOverrideTextStyle={ButtonTextStyle.LABEL}
                                            onClick={{
                                                href: props.payload.fees_and_reimbursement_link.href,
                                                targetBlank: props.payload.fees_and_reimbursement_link.target_blank
                                            }}
                                    >
                                        1
                                    </Button>
                                </>
                            )}</li>
                        </UnorderedList>
                    </OverScrollBox>
                }
            </TBody>
        </LargeSpacer>
        <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} fullWidth onClick={{
            href: props.payload.consult_doctor_link.href,
            targetBlank: props.payload.consult_doctor_link.target_blank,
            onClick: props.onOpenEntity
        }}>
            {translate('action_name')}
        </Button>
    </XLargeSpacer>
}

export default LiviFlow;