import TrText from "components/common/TrText";
import Form from 'components/commonDesign/form/tmpNewSystem/Form';
import Alert, { AlertSeverity } from "components/designSystem/components/Alert";
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import PinField from 'components/designSystem/components/PinField';
import Centered from 'components/designSystem/containers/Centered';
import { LargeSpacer } from 'components/designSystem/containers/Spacer';
import { TransDomain } from 'components/pages/common/MainComponent';
import { pin } from 'components/utils/form/validators';
import { PIN_LENGTH } from 'configuration/settings';
import { translationMethod } from "core/utils/trans";
import { toLocalTransKey } from "models/common/message";
import React, { FC, useState } from 'react';
import { Field as FinalField } from 'react-final-form';
import { useAPIPost } from "sagas/call/swr";
import { SetPinRequest } from "sagas/endpoints/user";

export const SetPin: FC<{}> = (): JSX.Element => {
    const [isPinComplete, setIsPinComplete] = useState<boolean>(false);

    const {response, trigger, error} = useAPIPost(
        SetPinRequest,
        false
    )

    const handlePinFieldChange = (value: string[]): void => {
        setIsPinComplete(!!value && value.length === PIN_LENGTH && !value.includes(''));
    };

    return (
        <Form onSubmit={
            (args: {newPin: string}): void => {
                return trigger({pin: args.newPin})
            }
        }>
            {(): JSX.Element => (
                <LargeSpacer>
                    <Centered>
                        <FinalField
                            name={'newPin'}
                        >
                            {
                                (props): JSX.Element =>
                                    <PinField
                                        name={'newPin'}
                                        numberOfCase={PIN_LENGTH}
                                        hidden
                                        autoComplete={'one-time-code'}
                                        defaultFocus
                                        validate={pin}
                                        onChange={
                                            (v): void => {
                                                handlePinFieldChange(v);
                                                // Small hack to make it work with final form
                                                // eslint-disable-next-line react/prop-types
                                                props.input.onChange(
                                                    {
                                                        target: {
                                                            value: v.join("")
                                                        }
                                                    }
                                                )

                                            }

                                        }
                                    />

                            }

                        </FinalField>
                    </Centered>

                    <Centered>
                        <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} isSubmit
                                disabled={!isPinComplete || !!response} fullWidth>
                            {translationMethod('save', undefined, TransDomain.ACCOUNT)}
                        </Button>
                    </Centered>
                    {
                        response && <Alert description={<TrText input={toLocalTransKey('update_value_successful', TransDomain.ACCOUNT)} />} severity={AlertSeverity.SUCCESS}/>
                    }
                    {
                        error && <Alert description={error.message} severity={AlertSeverity.CRITICAL}/>
                    }

                </LargeSpacer>
            )}
        </Form>
    );
};

