import { htmlLineBeaks } from "components/utils/transformers/Text";
import React, { Component, ReactElement, ReactNode } from 'react';
import { TransDomain } from 'components/pages/common/MainComponent';
import { APIText, LocalTransKey } from 'models/common/message';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'core/utils/text';
import { renderToString } from "react-dom/server";

export type TrTextInput = APIText | LocalTransKey | string;

interface TrTextProps {
    input: TrTextInput,
    markup?: boolean,
    children?: JSX.Element,
    // Will not apply if translation result is not a pure text. Is applied before Markup
    postTrans?: (value: string) => string
    capitalize?: boolean,
    builder?: (value: string) => ReactNode
}

export function key(key: string, domain?: string, data?: Record<string, unknown>): LocalTransKey {
    return {
        trkey: key,
        trdomain: domain,
        trdata: data,
    }
}

export function renderString(input: string | ReactElement): string {
    if (typeof input === 'string' ){
        return input;
    }
    return renderToString(input);
}

function simpleMarkup(text: string): ReactElement | string {
    // replace by a remarkjs lib, or simple-text-parser or custom made
    return text
}

/**
 * Usage:
 *  <TrText input={key("multiple-reports-warning", TransDomain.BIOLOGIST)} />
 *       => Il existe plusieurs rapports correspondant à ce numéro de dossier
 *  <TrText input={key("doctor_subscription_explanation", TransDomain.ACCOUNT, {rpps: "_rpps"})}>
 *      <>0<strong>1</strong>2</>
 *  </TrText>
 *      => Le RPPS <strong>_rpps</strong> ne correspond à aucun compte existant chez Kiro. Afin de finaliser votre
 *          inscription, veuillez fournir ou mettre à jour les informations suivantes.
 *
 *  <TrText input="Ok" />
 *      => Ok
 *
 *  <TrText input={apiTextInstance} />
 *
 */
class TrText extends Component<TrTextProps & WithTranslation> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    protected formatLocalTransKey(key: LocalTransKey): string {
        return key.trdomain ? key.trdomain + '.' + key.trkey : key.trkey;
    }

    protected _translate(transKey: LocalTransKey): ReactElement | string {
        if (this.props.children) {
            return (
                <Trans i18nKey={this.formatLocalTransKey(transKey)} values={transKey.trdata}>
                    {this.props.children.props.children}
                </Trans>
            );
        }
        return this.postTrans(this.props.t(this.formatLocalTransKey(transKey), transKey.trdata));
    }

    protected translate(text: TrTextInput): ReactElement | string {
        if (typeof text === "string") {
            return  this.props.markup ? simpleMarkup(this.postTrans(text)) : this.postTrans(text)
        }

        if (typeof text === "object") {
            if (Object.hasOwn(text, 'trkey')) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return this._translate(text);
            }

            if (Object.hasOwn(text, 'text')) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const apiText: APIText = text;

                if (typeof apiText.text === "string") {
                    return apiText.markup || this.props.markup ? simpleMarkup(this.postTrans(apiText.text)) : <>{htmlLineBeaks(this.postTrans(apiText.text))}</>;
                }

                if (typeof apiText.text === 'object') {
                    if (Object.hasOwn(apiText.text, 'trkey')) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return this._translate(apiText.text);
                    }
                }
            }
        }

        return ''
    }

    render(): ReactNode {
        if (this.props.builder) {
            return this.props.builder(renderString(this.translate(this.props.input)))
        }

        return this.translate(this.props.input)
    }

    private postTrans(value: string): string {
        const postTrans = this.props.postTrans ? this.props.postTrans : (
            this.props.capitalize ? capitalizeFirstLetter : undefined
        )
        return (postTrans ? postTrans : (s: string): string => s)(value)
    }
}

export default withTranslation()(TrText);
