import TrText from 'components/common/TrText';
import { TransKey } from 'components/pages/common/MainComponent';
import { APIText } from 'models/common/message';
import React, { Component, ReactElement, ReactNode } from 'react';

class TsxBuilder {
    tsxBuilder: JSX.Element;
    domain?: string;

    constructor(tsxBuilder: JSX.Element, domain?: string) {
        this.tsxBuilder = tsxBuilder
        this.domain = domain
    }

    trans(key: string, data?: Record<string, unknown>, domain?: string): ReactElement {
        return <TrText input={{
            trkey: key,
            trdomain: domain ? domain : this.domain,
            trdata: data
        }}>
            {this.tsxBuilder}
        </TrText>
    }
}

class WithTranslations<T, S = {}> extends Component<T, S> {
    TRANS_SUFFIX: string | undefined = undefined;

    withBuilder(tsxBuilder: JSX.Element): TsxBuilder {
        return new TsxBuilder(tsxBuilder, this.TRANS_SUFFIX)
    }

    trans(
        key: string,
        data?: Record<string, unknown>,
        domain?: string,
        // Will not apply if translation result is not a pure text. Is applied before Markup
        postTrans?: (value: string) => string,
        builder?: (value: string) => ReactNode
    ): string {
        // "Globally" safe as TrText cannot produce a non string without children

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <TrText
            input={{
                trkey: key,
                trdomain: domain ? domain : this.TRANS_SUFFIX,
                trdata: data
            }}
            postTrans={postTrans}
            builder={builder}
        />
    }

    transApiText(apiText: APIText, postTrans?: (value: string) => string): ReactElement {
        return <TrText
            input={apiText}
            postTrans={postTrans}
        />
    }

    fromLegacyTrans(key: string | TransKey): string {
        if (typeof key === "string") {
            return this.trans(key)
        }

        return this.trans(key.key, {}, key.suffix ? key.suffix : undefined)
    }
}

export default WithTranslations;
