import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Dashboard = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-dashboard'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_181_155)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M5 0H2.5C1.11929 0 0 1.11929 0 2.5V7C0 8.38071 1.11929 9.5 2.5 9.5H5C6.38071 9.5 7.5 8.38071 7.5 7V2.5C7.5 1.11929 6.38071 0 5 0ZM6 2.5C6 1.94772 5.55228 1.5 5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V7C1.5 7.55228 1.94772 8 2.5 8H5C5.55228 8 6 7.55228 6 7V2.5ZM5 10.5H2.5C1.11929 10.5 0 11.6193 0 13V13.5C0 14.8807 1.11929 16 2.5 16H5C6.38071 16 7.5 14.8807 7.5 13.5V13C7.5 11.6193 6.38071 10.5 5 10.5ZM6 13C6 12.4477 5.55228 12 5 12H2.5C1.94772 12 1.5 12.4477 1.5 13V13.5C1.5 14.0523 1.94772 14.5 2.5 14.5H5C5.55228 14.5 6 14.0523 6 13.5V13ZM11 0H13.5C14.8807 0 16 1.11929 16 2.5V3C16 4.38071 14.8807 5.5 13.5 5.5H11C9.61929 5.5 8.5 4.38071 8.5 3V2.5C8.5 1.11929 9.61929 0 11 0ZM13.5 1.5C14.0523 1.5 14.5 1.94772 14.5 2.5V3C14.5 3.55228 14.0523 4 13.5 4H11C10.4477 4 10 3.55228 10 3V2.5C10 1.94772 10.4477 1.5 11 1.5H13.5ZM13.5 6.5H11C9.61929 6.5 8.5 7.61929 8.5 9V13.5C8.5 14.8807 9.61929 16 11 16H13.5C14.8807 16 16 14.8807 16 13.5V9C16 7.61929 14.8807 6.5 13.5 6.5ZM14.5 9C14.5 8.44772 14.0523 8 13.5 8H11C10.4477 8 10 8.44772 10 9V13.5C10 14.0523 10.4477 14.5 11 14.5H13.5C14.0523 14.5 14.5 14.0523 14.5 13.5V9Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_181_155">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-dashboard'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4 1C2.34315 1 1 2.34315 1 4V6C1 7.65685 2.34315 9 4 9H8C9.65685 9 11 7.65685 11 6V4C11 2.34315 9.65685 1 8 1H4ZM3 4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 3.44772 9 4V6C9 6.55228 8.55228 7 8 7H4C3.44772 7 3 6.55228 3 6V4ZM4 11C2.34315 11 1 12.3431 1 14V20C1 21.6569 2.34315 23 4 23H8C9.65685 23 11 21.6569 11 20V14C11 12.3431 9.65685 11 8 11H4ZM3 14C3 13.4477 3.44772 13 4 13H8C8.55228 13 9 13.4477 9 14V20C9 20.5523 8.55228 21 8 21H4C3.44772 21 3 20.5523 3 20V14ZM13 4C13 2.34315 14.3431 1 16 1H20C21.6569 1 23 2.34315 23 4V10C23 11.6569 21.6569 13 20 13H16C14.3431 13 13 11.6569 13 10V4ZM16 3C15.4477 3 15 3.44772 15 4V10C15 10.5523 15.4477 11 16 11H20C20.5523 11 21 10.5523 21 10V4C21 3.44772 20.5523 3 20 3H16ZM16 15C14.3431 15 13 16.3431 13 18V20C13 21.6569 14.3431 23 16 23H19.5C21.1569 23 22.5 21.6569 22.5 20V18C22.5 16.3431 21.1569 15 19.5 15H16ZM15 18C15 17.4477 15.4477 17 16 17H19.5C20.0523 17 20.5 17.4477 20.5 18V20C20.5 20.5523 20.0523 21 19.5 21H16C15.4477 21 15 20.5523 15 20V18Z"
                  fill="currentColor"/>
        </svg>
    )
}