import { Action } from 'actions/ActionInterface';
import {
    APIFeedbackMessageFallbackCatcher,
    APIFeedbackMessageThenDispatch,
    APIFeedbackMessageType,
} from 'models/common/APIMessaging';
import { TransKey } from 'components/pages/common/MainComponent';

export const API_MESSAGE_FEEDBACK = 'API_MESSAGE_FEEDBACK';
export const API_MESSAGE_FEEDBACK_CLEAN = 'API_MESSAGE_FEEDBACK_CLEAN';
export const API_MESSAGE_FEEDBACK_REGISTER = 'API_MESSAGE_FEEDBACK_REGISTER';

export type RegisterAPIFeedbackPayload = { identifier: string };

export const registerAPIFeedback = (identifier: string): Action<RegisterAPIFeedbackPayload> => {
    return {
        type: API_MESSAGE_FEEDBACK_REGISTER,
        payload: {
            identifier: identifier,
        },
    };
};

export type PushAPIFeedbackMessagePayload<A, E> = {
    identifier: string | null;
    message: string | TransKey;
    type: APIFeedbackMessageType;
    fallbackCatcher?: APIFeedbackMessageFallbackCatcher;
    originAction?: A;
    extraData?: E;
    thenDispatch?: APIFeedbackMessageThenDispatch;
};

export const pushAPIFeedbackMessage = <A, E>(
    identifier: string | null,
    message: string | TransKey,
    type?: APIFeedbackMessageType,
    fallbackCatcher?: APIFeedbackMessageFallbackCatcher,
    originAction?: A,
    extraData?: E,
    thenDispatch?: APIFeedbackMessageThenDispatch,
): Action<PushAPIFeedbackMessagePayload<A, E>> => {
    return {
        type: API_MESSAGE_FEEDBACK,
        payload: {
            identifier: identifier,
            message: message,
            type: type || APIFeedbackMessageType.INFO,
            fallbackCatcher: fallbackCatcher,
            originAction: originAction,
            extraData: extraData,
            thenDispatch: thenDispatch,
        },
    };
};

export type CleanAPIFeedbackMessagePayload = {
    identifier: string;
};

export const cleanAPIFeedbackMessages = (identifier: string): Action<CleanAPIFeedbackMessagePayload> => {
    return {
        type: API_MESSAGE_FEEDBACK_CLEAN,
        payload: {
            identifier: identifier,
        },
    };
};
