import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from './Loader.module.scss'

export enum LoaderType {
    PAGE = 'PAGE',
    MODULE = 'MODULE'
}

interface LoaderPayload {
    type: LoaderType
    icon: ReactNode
}

interface PageLoaderPayload extends LoaderPayload {
    type: LoaderType.PAGE
    label: ReactNode
}

interface ModuleLoaderPayload extends LoaderPayload {
    type: LoaderType.MODULE
}

interface LoaderProps {
    payload: PageLoaderPayload | ModuleLoaderPayload;
}

const Loader: FC<LoaderProps> = ({...props}): JSX.Element => {
    return <div className={classNames(
        styles.loader, {
            [styles.loaderModule]: props.payload.type === LoaderType.MODULE,
            [styles.loaderPage]: props.payload.type === LoaderType.PAGE,
        }
    )}>
        {props.payload.type === LoaderType.PAGE &&
            <div className={styles.container}>
                <div className={styles.circleLoader}>
                    <div className={styles.icon}>{props.payload.icon}</div>
                </div>
                <span className={styles.label}>
                    <span className={styles.labelText}>{props.payload.label}</span>
                    <span className={styles.labelLoading}/>
                </span>
            </div>
        }
        {props.payload.type === LoaderType.MODULE &&
            <>
                <div className={styles.filter}>
                    <div className={styles.icon}>{props.payload.icon}</div>
                </div>
                <div className={styles.content}>{props.children}</div>
            </>
        }
    </div>
}

export default Loader