import { alterContext, changePage } from "actions/modular/ModularActions";
import BaseModule, { ChangePageParams, DisplayConfiguration, setupModule } from "components/modules/modular/BaseModule";
import { ModuleV1Type, PageContext } from "models/modular/api";
import { ConnectedContext, ModuleData } from "models/modular/storage";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useDispatch } from "react-redux";

const ModulePayloadContext = createContext<unknown>(null);

/**
 * Get the current module payload received from the page payload.
 */
export const useModulePayload = <T, >(): T => useContext(ModulePayloadContext) as T

export type ModuleAttributes = {
    identifier: string
    pageId: string
    type: ModuleV1Type
    context?: PageContext
    urlContext?: PageContext
}

const ModuleAttributesContext = createContext<ModuleAttributes | undefined>(undefined);

/**
 * Get the current module attributes received from the page.
 */
export const useModuleAttributes = (): ModuleAttributes => useContext(ModuleAttributesContext) as ModuleAttributes

const ModuleConnectedContextContext = createContext<ConnectedContext>({});

/**
 * Helper hook to bind the change page action to the page attributes.
 */
export const useChangePage: () => (params: ChangePageParams) => void = () => {
    const dispatch = useDispatch()
    const attributes = useModuleAttributes()
    return (params: ChangePageParams) => {
        dispatch(changePage(params.pageIdentifier, params.pageContext, attributes.pageId, attributes.urlContext))
    }
}

/**
 * Use a connected context element for the current module. Returns a pair with the current value and a setter to update
 * it. If the element isn't set up in the connected context, useConnectedContextElement becomes an abstraction to useState.
 */
export const useConnectedContextElement = (
    key: string,
    initValue?: string | null
): [string | null, (value?: string) => void] => {
    const connectedContext = useContext(ModuleConnectedContextContext);
    const dispatch = useDispatch();
    const connectedContextElement = connectedContext[key];

    const [value, setValue]  = useState<string | null>(initValue !== undefined ? initValue : null);

    if (connectedContextElement === undefined) {
        return [value, (value?: string): void => setValue(value === undefined ? null : value)]
    } else {
        const initVal = connectedContextElement.value == null && initValue !== undefined ? initValue: connectedContextElement.value

        if (connectedContextElement.value == null  && initVal != null) {
            dispatch(alterContext(connectedContextElement.contextKey, initVal))
        }

        return [
            initVal,
            (value): void => {
                dispatch(alterContext(connectedContextElement.contextKey, value))
            }
        ]
    }
}

export interface ModuleContainerProps {
    children: ReactNode
    displayConfiguration?: DisplayConfiguration
}

class ModuleContainer extends BaseModule<ModuleContainerProps, {}> {

    protected _render(payload: ModuleData): React.ReactNode {
        return <ModuleAttributesContext.Provider value={{
                identifier: this.props.identifier,
                pageId: this.props.pageId,
                type: this.props.type,
                context: this.props.context,
                urlContext: this.props.pageUrlContext,
            }}>
            <ModuleConnectedContextContext.Provider value={this.props.connectedContext}>
                <ModulePayloadContext.Provider value={payload.pagePayload}>
                    {this.props.children}
                </ModulePayloadContext.Provider>
            </ModuleConnectedContextContext.Provider>
        </ModuleAttributesContext.Provider>
    }
}

const mapStateToProps = (): {} => ({});
const mapDispatchToProps = (): {} => ({});
export default setupModule(ModuleContainer, mapStateToProps, mapDispatchToProps);
