import React from 'react';

const Pie = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M28.25 18.8107H27.3162C25.4357 14.2981 20.9954 11.3107 16.0625 11.3107C11.1296 11.3107 6.68931 14.2981 4.80881 18.8107H3.875C1.80681 18.8107 0 20.4925 0 22.5607C0 26.6406 5.27106 27.2289 8.19169 25.2826C9.58512 24.3542 11.3832 24.3542 12.7766 25.2826C14.8018 26.6321 17.4165 26.6321 19.4417 25.2826C20.8351 24.3542 22.6332 24.3542 24.0266 25.2826C26.9304 27.2194 32 26.6069 32 22.5607C32 20.4925 30.3182 18.8107 28.25 18.8107ZM12.9753 18.536L11.1003 20.411C10.7341 20.7772 10.1408 20.7772 9.77462 20.411C9.40844 20.0448 9.40844 19.4515 9.77462 19.0853L11.6496 17.2103C12.0158 16.8441 12.6091 16.8441 12.9753 17.2103C13.3415 17.5765 13.3416 18.1698 12.9753 18.536ZM17 19.7482C17 20.2664 16.5807 20.6857 16.0625 20.6857C15.5443 20.6857 15.125 20.2664 15.125 19.7482V17.8732C15.125 17.355 15.5443 16.9357 16.0625 16.9357C16.5807 16.9357 17 17.355 17 17.8732V19.7482ZM22.3503 20.411C21.9841 20.7772 21.3908 20.7772 21.0246 20.411L19.1496 18.536C18.7834 18.1698 18.7834 17.5765 19.1496 17.2103C19.5158 16.8441 20.1091 16.8441 20.4753 17.2103L22.3503 19.0853C22.7166 19.4516 22.7166 20.0448 22.3503 20.411Z" />
            <path d="M11.8792 5.59506C13.773 4.33256 13.7721 1.42181 11.8792 0.160002C11.4453 -0.128373 10.8657 -0.0111856 10.5792 0.420002C10.2917 0.851189 10.4089 1.4335 10.8392 1.72006C11.6217 2.24113 11.6217 3.51394 10.8392 4.035C8.94542 5.2975 8.9463 8.08313 10.8392 9.34506C11.2772 9.63538 11.8564 9.50988 12.1392 9.08506C12.4267 8.65388 12.3104 8.0725 11.8792 7.785C11.0967 7.26394 11.0967 6.11613 11.8792 5.59506Z" />
            <path d="M16.5667 5.59506C18.4605 4.33256 18.4596 1.42181 16.5667 0.160002C16.1328 -0.128373 15.5532 -0.0111856 15.2667 0.420002C14.9792 0.851189 15.0964 1.4335 15.5267 1.72006C16.3092 2.24113 16.3092 3.51394 15.5267 4.035C13.6329 5.2975 13.6338 8.08313 15.5267 9.34506C15.9647 9.63538 16.5439 9.50988 16.8267 9.08506C17.1142 8.65388 16.9979 8.0725 16.5667 7.785C15.7842 7.26394 15.7842 6.11613 16.5667 5.59506Z"/>
            <path d="M21.2542 5.59506C23.148 4.33256 23.1471 1.42181 21.2542 0.160002C20.8194 -0.128373 20.2407 -0.0111856 19.9542 0.420002C19.6667 0.851189 19.7839 1.4335 20.2142 1.72006C20.9967 2.24113 20.9967 3.51394 20.2142 4.035C18.3204 5.2975 18.3213 8.08313 20.2142 9.34506C20.6522 9.63538 21.2314 9.50988 21.5142 9.08506C21.8017 8.65388 21.6854 8.0725 21.2542 7.785C20.4717 7.26394 20.4717 6.11613 21.2542 5.59506Z" />
            <path d="M22.9241 26.8426C22.1638 26.3369 21.1891 26.3306 20.4193 26.8426C17.7872 28.5961 14.3559 28.6293 11.6741 26.8426C10.9138 26.3369 9.93906 26.3306 9.16925 26.8426C7.80638 27.7505 6.13175 28.2618 4.177 28.1988L5.26819 30.4436C5.74794 31.4022 6.712 31.9982 7.78406 31.9982H24.2159C25.288 31.9982 26.2521 31.4022 26.7318 30.4436L27.8277 28.1894C25.9124 28.2448 24.2551 27.7299 22.9241 26.8426Z" fill="#001948"/>
        </svg>
    )
}

export default Pie;
