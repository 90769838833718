import React from 'react';

const Antibodies = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <g clipPath="url(#clip0_1510_15645)">
                <path d="M9.89816 14.3059C9.89816 15.3459 9.09389 16.2278 8.03157 16.296C7.47371 16.3303 6.96958 16.1409 6.58587 15.8031L5.65799 14.9875C5.47821 14.8295 5.46061 14.5556 5.61867 14.3759L7.6816 12.0297C7.83961 11.85 8.11337 11.8324 8.29309 11.9904L9.22107 12.8061C9.66903 13.1998 9.89816 13.7518 9.89816 14.3059Z" />
                <path d="M15.1334 11.6724V25.8803C15.1334 26.9733 14.2597 27.8866 13.1856 27.9164C12.0564 27.9479 11.1251 27.0427 11.1251 25.9128V12.5618L9.24437 10.9082C9.06464 10.7502 9.04704 10.4764 9.2051 10.2966L11.2836 7.93307C11.4417 7.75334 11.7155 7.73579 11.8952 7.8938L14.4341 10.1264C14.8783 10.5169 15.1334 11.0803 15.1334 11.6724Z" />
                <path d="M22.7557 10.9082L20.875 12.5617V25.9127C20.875 27.0217 19.9773 27.9169 18.8714 27.9169C17.7564 27.9169 16.8667 26.979 16.8667 25.8802V11.6723C16.8667 11.0803 17.1218 10.5169 17.566 10.1264L20.1049 7.8938C20.2847 7.73575 20.5585 7.75335 20.7165 7.93307L22.795 10.2966C22.9531 10.4763 22.9355 10.7502 22.7557 10.9082Z" />
                <path d="M26.3814 14.3759C26.5394 14.5556 26.5218 14.8295 26.3421 14.9875L25.4142 15.803C24.5841 16.5333 23.3212 16.4448 22.5981 15.6221C21.8711 14.7961 21.9524 13.5324 22.779 12.806L23.707 11.9903C23.8867 11.8323 24.1604 11.8499 24.3184 12.0296L26.3814 14.3759Z" />
                <path d="M6.31343 10.2494L6.99165 10.8457C7.17143 11.0037 7.18903 11.2775 7.03098 11.4573L4.96804 13.8034C4.81003 13.9832 4.53622 14.0008 4.3565 13.8428L3.67822 13.2465C2.85987 12.5277 2.76107 11.2681 3.49676 10.4304C4.22368 9.60373 5.48685 9.52248 6.31343 10.2494Z" />
                <path d="M10.6325 7.36107L8.55438 9.72458C8.39638 9.9043 8.12257 9.92191 7.94279 9.76385L5.5041 7.61955C4.68185 6.89643 4.57297 5.63543 5.26143 4.80776C5.98271 3.94131 7.27702 3.83287 8.1301 4.58351L10.5932 6.74953C10.7729 6.90753 10.7905 7.18134 10.6325 7.36107Z" />
                <path d="M26.4958 7.61954L24.0571 9.76384C23.8774 9.9219 23.6036 9.90429 23.4455 9.72457L21.3674 7.36106C21.2094 7.18134 21.227 6.90752 21.4067 6.74952L23.8698 4.5835C24.7179 3.83731 26.0129 3.93616 26.7385 4.80775C27.4269 5.63542 27.3181 6.89647 26.4958 7.61954Z" />
                <path d="M28.322 13.2464L27.6437 13.8427C27.464 14.0007 27.1902 13.9831 27.0322 13.8034L24.9693 11.4572C24.8112 11.2775 24.8288 11.0036 25.0086 10.8456L25.6868 10.2493C26.5134 9.52243 27.7766 9.60368 28.5035 10.4303C29.2227 11.2492 29.1596 12.5107 28.322 13.2464Z" />
            </g>
            <defs>
                <clipPath id="clip0_1510_15645">
                    <rect width="26" height="26" fill="white" transform="translate(3 3)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Antibodies;
