import { APICallAction, OnSuccessPutType } from 'actions/ActionInterface';
import { NotificationInterval } from 'models/user/AccountModels';

export const API_RESET_KNOWN_PW = 'API_RESET_KNOWN_PW';
export const API_RESET_PW = 'API_RESET_PW';
export const API_FORGOT_PW = 'API_FORGOT_PW';
export const API_UPDATE_DOCTOR_INFO = 'API_UPDATE_DOCTOR_INFO';
export const API_SET_PIN = 'API_SET_PIN';

export const API_GET_NAVIGATION_CONTENT = 'API_GET_NAVIGATION_CONTENT';

export const FEEDBACK_IDENTIFIER_RESET_KNOWN_PASSWORD = 'FEEDBACK_IDENTIFIER_RESET_KNOWN_PASSWORD';
export const FEEDBACK_IDENTIFIER_RESET_PASSWORD = 'FEEDBACK_IDENTIFIER_RESET_PASSWORD';
export const FEEDBACK_IDENTIFIER_UPDATE_DOCTOR_INFO = 'FEEDBACK_IDENTIFIER_UPDATE_DOCTOR_INFO';
export const FEEDBACK_IDENTIFIER_UPDATE_DOCTOR_INFO_NOTIF = 'FEEDBACK_IDENTIFIER_UPDATE_DOCTOR_INFO_NOTIF';
export const FEEDBACK_IDENTIFIER_UPDATE_BIO_PROFILE = 'FEEDBACK_IDENTIFIER_UPDATE_BIO_PROFILE';
export const FEEDBACK_IDENTIFIER_SET_PIN = 'FEEDBACK_IDENTIFIER_SET_PIN';

export type ResetKnownPWInterface = APICallAction<{
    oldPassword: string;
    newPassword: string;
}>;

export type ResetPWInterface = APICallAction<{
    password: string;
    confirmation: string;
    token: string;
}>;

export type UpdateDoctorInfoInterface = APICallAction<{
    speciality?: string | null;
    businessAddress?: string | null;
    businessPhoneNumber?: string | null;
    businessEmail?: string | null;
    notificationInterval?: NotificationInterval | null;
}>;

export const resetKnownPW = (
    oldPassword: string,
    newPassword: string,
    feedbackIdentifier: string,
): ResetKnownPWInterface => {
    return {
        type: API_RESET_KNOWN_PW,
        payload: {
            params: {
                oldPassword: oldPassword,
                newPassword: newPassword,
            },
            feedbackIdentifier: feedbackIdentifier,
        },
    };
};

export const updateDoctorInfo = (
    feedbackIdentifier: string,
    speciality?: string | null,
    businessAddress?: string | null,
    businessPhoneNumber?: string | null,
    businessEmail?: string | null,
    notificationInterval?: NotificationInterval | null,
): UpdateDoctorInfoInterface => {
    return {
        type: API_UPDATE_DOCTOR_INFO,
        payload: {
            params: {
                speciality: speciality,
                businessAddress: businessAddress,
                businessPhoneNumber: businessPhoneNumber,
                businessEmail: businessEmail,
                notificationInterval: notificationInterval,
            },
            feedbackIdentifier: feedbackIdentifier,
        },
    };
};

export const resetPW = (
    password: string,
    confirmation: string,
    token: string,
    onSuccessPut: OnSuccessPutType,
    feedbackIdentifier?: string,
): ResetPWInterface => {
    return {
        type: API_RESET_PW,
        payload: {
            params: {
                password: password,
                confirmation: confirmation,
                token: token,
            },
            feedbackIdentifier,
            onSuccessPut,
            preventRetryOnError: true
        },
    };
};

export type forgotPWInterface = APICallAction<{ email: string }>;

export const forgotPW = (email: string, feedbackIdentifier: string): forgotPWInterface => {
    return {
        type: API_FORGOT_PW,
        payload: {
            params: {
                email: email,
            },
            feedbackIdentifier,
        },
    };
};

export type GetNavigationAction = APICallAction<{}>;

export const getNavigationContent = (): GetNavigationAction => {
    return {
        type: API_GET_NAVIGATION_CONTENT,
        payload: {
        },
    };
};
