import { Colors } from 'components/legacyDesignSystem/branding/constants';
import Container, { ContainerProfile, MarginType } from 'components/legacyDesignSystem/components/Container';
import { Icon, Icons } from 'components/legacyDesignSystem/components/Icon';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import BaseModule, { setupModule } from 'components/modules/modular/BaseModule';
import { APIText } from 'models/common/message';

import { ModuleData } from 'models/modular/storage';
import React, { ReactNode } from 'react';

interface ModularHeaderPagePayload {
    icon: Icons;
    title: APIText;
    sub_title?: APIText;
    description?: APIText;
}

class ModularHeader extends BaseModule<{}, ModularHeaderPagePayload, {}> {

    _render({
        pagePayload: { icon, title, sub_title, description },
    }: ModuleData<ModularHeaderPagePayload, {}>): ReactNode {
        return (
            <>
                <Container profiles={[ContainerProfile.FLEX]}>
                    {icon && (
                        <Container profiles={[{ type: MarginType.MR, value: 6 }]}>
                            <Icon
                                width={18}
                                height={18}
                                name={icon}
                                color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                centered
                                withBackground
                            />
                        </Container>
                    )}
                    <Container>
                        {title && (
                            <Typography
                                weight={TypographyWeight.BOLD}
                                variant={TypographyVariant.H3}
                                color={Colors.NEUTRALS_DARK_BLUE_GREY}
                            >
                                {this.transApiText(title)}
                            </Typography>
                        )}
                        {sub_title && (
                            <Typography
                                weight={TypographyWeight.REGULAR}
                                variant={TypographyVariant.BODY}
                                color={Colors.NEUTRALS_BLUE_GREY}
                            >
                                {this.transApiText(sub_title)}
                            </Typography>
                        )}
                    </Container>
                </Container>

                {description && (
                    <Container
                        profiles={[
                            {
                                type: MarginType.MT,
                                value: 6,
                            },
                        ]}
                    >
                        <Typography
                            weight={TypographyWeight.REGULAR}
                            variant={TypographyVariant.BODY}
                            color={Colors.NEUTRALS_BLUE_GREY}
                        >
                            {this.transApiText(description)}
                        </Typography>
                    </Container>
                )}
            </>
        );
    }
}

export default setupModule(
    ModularHeader,
    () => ({}),
    () => ({}),
);
