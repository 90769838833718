import React, { ReactNode } from 'react';
import 'components/pages/errors/Errors.scss';
import MainComponent, {
    MainComponentProps,
    setupRaw,
    TransDomain,
    TransKey,
} from 'components/pages/common/MainComponent';

export enum ErrorMessageType {
    SERVER = 'SERVER',
    CONNECTION = 'CONNECTION',
    FORBIDDEN = 'FORBIDDEN',
    LOCAL = 'LOCAL',
    BADGATEWAY = 'BADGATEWAY',
    OUTDATED_RESOURCE = 'OUTDATED_RESOURCE',
}

export interface Page500Props extends MainComponentProps {
    message?: string | TransKey;
}

class Page500 extends MainComponent<Page500Props> {
    TRANS_SUFFIX = TransDomain.MESSAGES + '.default';
    render(): ReactNode {
        const mes =
            typeof this.props.message === 'string' || !this.props.message
                ? this.trans(ErrorMessageType.SERVER)
                : this.trans(this.props.message);
        return (
            <div className={'errorPage'}>
                <div className={'errorPage-title'}>{this.trans('OOPS')}</div>
                <div className={'errorPage-subtitle'}>{mes}</div>
            </div>
        );
    }
}

export default setupRaw(Page500);
