import TrText from "components/common/TrText";
import { Colors } from 'components/legacyDesignSystem/branding/constants';
import Container, { ContainerProfile, MarginType } from 'components/legacyDesignSystem/components/Container';
import { Icon, Icons } from 'components/legacyDesignSystem/components/Icon';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { APIText } from 'models/common/message';
import React from 'react';

interface ModularBannerPagePayload {
    title: APIText;
    icon?: Icons;
    sub_title?: APIText;
}

export const ModularBanner = (): JSX.Element => {
    const {icon, title, sub_title} = useModulePayload<ModularBannerPagePayload>();
    return <Container profiles={[ContainerProfile.FLEX_COLUMN, ContainerProfile.ALIGN_CENTER]}>
        <Container>
            {icon && (
                <Icon width={12} height={12} name={icon} color={Colors.NEUTRALS_DARK_BLUE_GREY} centered/>
            )}
        </Container>
        <Container profiles={[ContainerProfile.ALIGN_TXT_CENTER, {type: MarginType.MT, value: 4}]}>
            {title && (
                <Typography
                    weight={TypographyWeight.MEDIUM}
                    variant={TypographyVariant.BODY}
                    color={Colors.NEUTRALS_DARK_BLUE_GREY}
                >
                    <TrText input={title}/>
                </Typography>
            )}
            {sub_title && (
                <Typography
                    weight={TypographyWeight.REGULAR}
                    variant={TypographyVariant.CAPTION}
                    color={Colors.NEUTRALS_BLUE_GREY}
                >
                    <TrText input={sub_title}/>
                </Typography>
            )}
        </Container>
    </Container>
};
