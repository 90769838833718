import TrText from "components/common/TrText";
import WithTooltip, { TooltipAlignment, TooltipWidth } from "components/designSystem/components/WithTooltip";
import FlexGrow from "components/designSystem/containers/FlexGrow";
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import { MediumSpacer } from "components/designSystem/containers/Spacer";
import {
    TButton,
    TDisplaySmall500,
    TDisplaySmall700,
    TLabel,
    TypographyColor
} from "components/designSystem/containers/Typography";
import Icon from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { PregnancyStatus } from "components/modules/modular/modules/bioProfiles/PregnancyStatus";
import { TransDomain } from "components/pages/common/MainComponent";
import { APIText } from "models/common/message";
import * as React from 'react';
import { useTranslation } from "react-i18next";

type PregnancyProgressModulePayload = {
    image_url: string,
    week: number
    trimester: number
    foetus_size: APIText
    foetus_weight: APIText
    remaining: APIText
};

export const PregnancyProgressModule = (): JSX.Element => {
    const payload = useModulePayload<PregnancyProgressModulePayload>();
    const {t} = useTranslation(undefined, {keyPrefix: TransDomain.MODULES + '.pregnancy_progress'});
    return <PregnancyStatus image={{src: payload.image_url, alt: 'Pregnancy silhouette'}}>
        <MediumSpacer withFullHeight>
            <AlwaysInline
                alignItems={InlineAlignItem.CENTER}
                justifyContent={InlineJustifyContent.SPACE_BETWEEN}
                spacing={InlineSpacing.MEDIUM}>
                <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                    {t('title', {week: payload.week, trimester: payload.trimester})}
                </TDisplaySmall700>
                <WithTooltip tooltip={t('tooltip')} alignment={TooltipAlignment.LEFT} width={TooltipWidth.WIDTH_2OREM}>
                    <Icon size={IconSize.EXTRA_SMALL} name={IconName.QUESTION_CIRCLE} color={IconColor.ON_PRIMARY}/>
                </WithTooltip>
            </AlwaysInline>
            <FlexGrow/>
            {(payload.foetus_size || payload.foetus_weight) && <AlwaysInline spacing={InlineSpacing.MEDIUM}>
                {payload.foetus_size && <div>
                    <div>
                        <TLabel color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                            {t('avg_height')}
                        </TLabel>
                    </div>
                    <TDisplaySmall500 color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                        <TrText input={payload.foetus_size}/>
                    </TDisplaySmall500>
                </div>}
                {payload.foetus_weight && <div>
                    <div>
                        <TLabel color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                            {t('avg_weight')}
                        </TLabel>
                    </div>
                    <TDisplaySmall500 color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                        <TrText input={payload.foetus_weight}/>
                    </TDisplaySmall500>
                </div>}
            </AlwaysInline>}
            <FlexGrow/>
            <TButton color={TypographyColor.COLOR_TEXT_ON_PRIMARY}>
                <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                    <Icon size={IconSize.SMALL} name={IconName.STROLLER}/>
                    <TrText input={payload.remaining}/>
                </AlwaysInline>
            </TButton>
        </MediumSpacer>
    </PregnancyStatus>
};