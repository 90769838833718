import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const LockClose = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-lock-close'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8 0.25C5.78152 0.25 4.53207 1.09982 3.87596 2.08397C3.5625 2.55416 3.4076 3.02097 3.33036 3.36855C3.29152 3.54331 3.27153 3.69145 3.26119 3.79999C3.25601 3.85439 3.25322 3.89925 3.25172 3.93301C3.25097 3.9499 3.25054 3.96405 3.2503 3.97527L3.25006 3.98989L3.25001 3.99552L3.25 3.9979L3.25 3.99899C3.25 3.9995 3.25 4 4 4H3.25V6H2.5C1.67157 6 1 6.67157 1 7.5V12.5C1 14.433 2.567 16 4.5 16H11.5C13.433 16 15 14.433 15 12.5V7.5C15 6.67157 14.3284 6 13.5 6H12.75V4H12C12.75 4 12.75 3.9995 12.75 3.99899L12.75 3.9979L12.75 3.99552L12.7499 3.98989L12.7497 3.97527C12.7495 3.96405 12.749 3.9499 12.7483 3.93301C12.7468 3.89925 12.744 3.85439 12.7388 3.79999C12.7285 3.69145 12.7085 3.54331 12.6696 3.36855C12.5924 3.02097 12.4375 2.55416 12.124 2.08397C11.4679 1.09982 10.2185 0.25 8 0.25ZM11.25 6V4.00611L11.2498 3.99961C11.2493 3.98942 11.2482 3.96983 11.2456 3.9422C11.2403 3.88668 11.229 3.80044 11.2054 3.69395C11.1576 3.47903 11.0625 3.19584 10.876 2.91603C10.5321 2.40018 9.78152 1.75 8 1.75C6.21848 1.75 5.46794 2.40018 5.12404 2.91603C4.9375 3.19584 4.8424 3.47903 4.79464 3.69395C4.77098 3.80044 4.75972 3.88668 4.75443 3.9422C4.7518 3.96983 4.75069 3.98942 4.75024 3.99961L4.75 4.00611V6H11.25ZM2.5 12.5V7.5H13.5V12.5C13.5 13.6046 12.6046 14.5 11.5 14.5H4.5C3.39543 14.5 2.5 13.6046 2.5 12.5ZM8 9.75C8.41421 9.75 8.75 10.0858 8.75 10.5V11.5C8.75 11.9142 8.41421 12.25 8 12.25C7.58579 12.25 7.25 11.9142 7.25 11.5V10.5C7.25 10.0858 7.58579 9.75 8 9.75Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    if (props.size === IconSize.EXTRA_EXTRA_LARGE) {
        return (
            <svg id={'xl-lock-close'} width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M23.5 0C16.6211 0 12.8306 2.77548 10.8722 5.87215C9.93228 7.3583 9.46896 8.83164 9.23833 9.92568C9.12235 10.4758 9.063 10.9404 9.03253 11.2778C9.01726 11.4468 9.00914 11.5849 9.00483 11.6871C9.00267 11.7383 9.00146 11.7805 9.0008 11.8132L9.00015 11.8551L9.00003 11.8706L9.00001 11.8769L9 11.8798C9 11.8811 9 11.8824 11 11.8824H9V17C6.79086 17 5 18.7909 5 21V39C5 43.4183 8.58172 47 13 47H35C39.4183 47 43 43.4183 43 39V21C43 18.7909 41.2091 17 39 17H38V11.8824H36C38 11.8824 38 11.8811 38 11.8798L38 11.8769L38 11.8706L37.9999 11.8551L37.9992 11.8132C37.9985 11.7805 37.9973 11.7383 37.9952 11.6871C37.9909 11.5849 37.9827 11.4468 37.9675 11.2778C37.937 10.9404 37.8777 10.4758 37.7617 9.92568C37.531 8.83164 37.0677 7.3583 36.1278 5.87215C34.1694 2.77548 30.3789 0 23.5 0ZM34 17V11.8932C33.9999 11.8875 33.9995 11.8748 33.9987 11.8556C33.9969 11.8131 33.9928 11.7389 33.9837 11.6377C33.9653 11.4346 33.927 11.1271 33.8477 10.7508C33.6877 9.99189 33.3698 8.99465 32.7472 8.0102C31.5806 6.1657 29.1211 4 23.5 4C17.8789 4 15.4194 6.1657 14.2528 8.0102C13.6302 8.99465 13.3123 9.99189 13.1523 10.7508C13.073 11.1271 13.0347 11.4346 13.0163 11.6377C13.0072 11.7389 13.0031 11.8131 13.0013 11.8556C13.0005 11.8748 13.0001 11.8875 13 11.8932V17H34ZM39 39C39 41.2091 37.2091 43 35 43H13C10.7909 43 9 41.2091 9 39V21H39V39ZM24 28C25.1046 28 26 28.8954 26 30V34C26 35.1046 25.1046 36 24 36C22.8954 36 22 35.1046 22 34V30C22 28.8954 22.8954 28 24 28Z"
                      fill="currentColor"
                />
            </svg>
        )
    }

    return (
        <svg id={'s-lock-close'} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 0C8.69284 0 6.86004 1.31291 5.90882 2.79258C5.45287 3.50184 5.22796 4.20526 5.11595 4.72797C5.05963 4.99081 5.03076 5.21299 5.01591 5.37469C5.00847 5.45572 5.0045 5.52211 5.00238 5.57144C5.00132 5.59612 5.00073 5.61659 5.0004 5.63255L5.00007 5.65308L5.00002 5.66076L5 5.66393L5 5.66536C5 5.66602 5 5.66667 6 5.66667H5V9H4C2.89543 9 2 9.89543 2 11V20C2 22.2091 3.79086 24 6 24H18C20.2091 24 22 22.2091 22 20V11C22 9.89543 21.1046 9 20 9H19V5.66667H18C19 5.66667 19 5.66602 19 5.66536L19 5.66393L19 5.66076L18.9999 5.65308L18.9996 5.63255C18.9993 5.61659 18.9987 5.59612 18.9976 5.57144C18.9955 5.52211 18.9915 5.45572 18.9841 5.37469C18.9692 5.21299 18.9404 4.99081 18.8841 4.72797C18.772 4.20526 18.5471 3.50184 18.0912 2.79258C17.14 1.31291 15.3072 0 12 0ZM17 9V5.67309L16.9995 5.65708C16.9986 5.63805 16.9967 5.60417 16.9925 5.5576C16.9839 5.46409 16.9659 5.32169 16.9284 5.14703C16.853 4.79474 16.7029 4.33149 16.4088 3.87409C15.86 3.02043 14.6928 2 12 2C9.30716 2 8.13996 3.02043 7.59118 3.87409C7.29713 4.33149 7.14704 4.79474 7.07155 5.14703C7.03412 5.32169 7.01612 5.46409 7.00753 5.5576C7.00325 5.60417 7.00136 5.63805 7.00055 5.65708L7 5.67308V9H17ZM20 20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V11H20V20ZM12 14.5C12.5523 14.5 13 14.9477 13 15.5V17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5V15.5C11 14.9477 11.4477 14.5 12 14.5Z"
                  fill="currentColor"/>
        </svg>
    )
}