import React, { FC, ReactNode } from 'react';
import DropDown from "components/designSystem/components/DropDown";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { CountryFlagName } from "components/designSystem/assets/countryData";
import DropDownItem, { DropDownType } from 'components/designSystem/components/DropDownItem';
import { IconName } from 'components/designSystem/foundations/IconsData';


export interface Language {
    identifier: string,
    name: string,
    shortName: string,
    flag: CountryFlagName,
}

export interface LanguagePickerProps {
    languages: Language[],
    selectedLanguage: Language,
    onChange : (identifier:string) => void,
    title: ReactNode
}

const LanguagePicker : FC<LanguagePickerProps> = (props):JSX.Element => {

    return <DropDown closeOnClick={true} title={props.title} triggerBuilder={
        (onClick: () => void, isOpen ) : ReactNode => (
            <Button
                key={'button'}
                icons={{left: IconName.GLOBE, right: IconName.CHEVRON_DOWN}}
                size={ButtonSize.SMALL}
                isActive={isOpen}
                variant={ButtonVariant.SECONDARY}
                onClick={onClick}
            >
                {
                    props.selectedLanguage.shortName
                }
            </Button>
        )
    }>
        {props.languages.map((language) => (
            <div key={language.name}>
                <DropDownItem
                    label={language.name}
                    payload={
                        {
                            type: DropDownType.LIST,
                            onClick: (): void => { props.onChange(language.identifier) },
                            isChecked: language.identifier === props.selectedLanguage.identifier
                        }
                    }
                />
            </div>

        ))}
    </DropDown>
}

export default LanguagePicker;