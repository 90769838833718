import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Drugs = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-drugs'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_153_262)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M13.0178 2.71023C11.9231 1.61547 10.1481 1.61547 9.05338 2.71023L6.41206 5.35154L10.3765 9.31601L13.0178 6.6747C14.1126 5.57994 14.1126 3.80499 13.0178 2.71023ZM9.31587 10.3767L5.3514 6.4122L2.71023 9.05338C1.61547 10.1481 1.61547 11.9231 2.71023 13.0178C3.80499 14.1126 5.57994 14.1126 6.67469 13.0178L9.31587 10.3767ZM7.99272 1.64957L1.64957 7.99272C-0.0309763 9.67326 -0.0309751 12.398 1.64957 14.0785C3.33011 15.759 6.05481 15.759 7.73535 14.0785L14.0785 7.73536C15.759 6.05481 15.759 3.33011 14.0785 1.64957C12.398 -0.0309737 9.67326 -0.0309778 7.99272 1.64957Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_153_262">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-drugs'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.96064 8.54622L15.5545 14.1401L19.1372 10.6084C20.7151 9.05296 20.7242 6.51001 19.1575 4.94329C17.5907 3.37656 15.0478 3.38567 13.4923 4.96358L9.96064 8.54622ZM14.1403 15.5543L8.54643 9.96043L4.96382 13.4921C3.38592 15.0475 3.37681 17.5905 4.94353 19.1572C6.51025 20.7239 9.05321 20.7148 10.6087 19.1369L14.1403 15.5543ZM7.84437 7.84412L3.55977 12.0678C1.19126 14.4026 1.17758 18.2197 3.52932 20.5714C5.88105 22.9232 9.69815 22.9095 12.033 20.541L16.2566 16.2564L20.5412 12.0327C22.9097 9.6979 22.9234 5.8808 20.5717 3.52907C18.2199 1.17734 14.4028 1.19101 12.068 3.55953L7.84437 7.84412Z"
                  fill="currentColor"/>
        </svg>
    )
}