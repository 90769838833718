
export enum PregnancyDateType {
    DUE_DATE = 'DUE_DATE',
    CONCEPTION_DATE = 'CONCEPTION_DATE',
    LAST_PERIOD_DATE = 'LAST_PERIOD_DATE',
}

export interface ArticleThumbnail {
    title: string
    image_url: string
    image_alt: string
    caption: string;
    token: string;
}

export interface ArticleList {
    articles: ArticleThumbnail[]
}

export enum ArticleSectionType {
    SUBTITLE = 'SUBTITLE',
    SUBSECTION = 'SUBSECTION',
    PARAGRAPH = 'PARAGRAPH',
    IMAGE = 'IMAGE'
}

export interface ArticleSubtitlePayload {
    text: string
}

interface ArticleSubtitle {
    type: ArticleSectionType.SUBTITLE
    payload: ArticleSubtitlePayload
}

export interface ArticleSubSectionPayload {
    text: string
}

interface ArticleSubSection {
    type: ArticleSectionType.SUBSECTION
    payload: ArticleSubSectionPayload
}


export interface ArticleParagraphPayload {
    text: string
}

interface ArticleParagraph {
    type: ArticleSectionType.PARAGRAPH
    payload: ArticleParagraphPayload
}

export interface ArticleImagePayload {
    url: string
    description?: string
}

interface ArticleImage {
    type: ArticleSectionType.IMAGE
    payload: ArticleImagePayload
}

export type ArticleSection = ArticleSubtitle | ArticleSubSection | ArticleParagraph | ArticleImage

interface ArticleLink {
    url: string
    text: string
}

export interface Article {
    uuid: string
    title: string
    caption: string
    image_url: string
    image_alt: string
    read_duration_seconds?: number
    sections: ArticleSection[]
    summary: string[]
    links: ArticleLink[]
}
