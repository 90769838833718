import { successful } from 'actions/ActionInterface';
import { API_SIGN_OUT } from 'actions/user/SignInSignUpActions';
import { LNG_LOCAL_STORAGE } from 'configuration/settings';
import { connectRouter } from 'connected-react-router';
import { AnyState } from 'core/store/Store';
import { DEV_OPTIONS_KEYS } from 'core/utils/Browser';
import bioProfileReducers from "reducers/bioProfile/BioProfileFetchReducer";
import doctorBoardFetchReducers from 'reducers/doctorBoard/DoctorBoardFetchReducers';
import apiFeedbackMessagingReducers from 'reducers/global/APIFeedbackMessagingReducer';
import connectedButtonReducer from 'reducers/global/ConnectedButton';
import connectedCarouselNavigatorReducer from 'reducers/global/ConnectedCarousel';
import globalReducers from 'reducers/global/GlobalReducers';

import medicalReportsFetchReducers from 'reducers/medicalReports/MedicalReportsFetchReducers';
import modularReducers from 'reducers/modular/ModularReducer';
import { formatFetchReducer } from 'reducers/selector';
import accountFetchReducers from 'reducers/user/AccountFetchReducers';
import signInSignUpReducers, { FORCED_CONNECTION_ITEM } from 'reducers/user/SignInSignUpFetchReducers';
import userDataReducers from 'reducers/user/UserDataReducers';
import { combineReducers } from 'redux';

export const REDUCER_GLOBAL = 'REDUCER_GLOBAL';
export const REDUCER_API_MESSAGING = 'REDUCER_API_MESSAGING';
export const REDUCER_CONNECTED_BUTTONS = 'REDUCER_CONNECTED_BUTTONS';
export const REDUCER_CONNECTED_CAROUSEL = 'REDUCER_CONNECTED_CAROUSEL';

export const REDUCER_REPORT = 'REDUCER_REPORT';
export const REDUCER_SIGN_IN_UP = 'REDUCER_SIGN_IN_UP';
export const REDUCER_DOCTOR = 'REDUCER_DOCTOR';
export const REDUCER_ACCOUNT = 'REDUCER_ACCOUNT';
export const REDUCER_USER_DATA = 'REDUCER_USER_DATA';
export const REDUCER_MODULAR = 'REDUCER_MODULAR';
export const REDUCER_BIO_PROFILE = 'REDUCER_ARTICLES';

// To improve
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReducerInterface = (state: AnyState, action: any) => any;

function buildReducers(): { [id: string]: ReducerInterface } {
    const reducers: { [id: string]: ReducerInterface } = {};
    reducers[REDUCER_GLOBAL] = globalReducers;
    reducers[REDUCER_API_MESSAGING] = apiFeedbackMessagingReducers;
    reducers[REDUCER_CONNECTED_BUTTONS] = connectedButtonReducer;
    reducers[REDUCER_CONNECTED_CAROUSEL] = connectedCarouselNavigatorReducer;

    reducers[formatFetchReducer(REDUCER_REPORT)] = medicalReportsFetchReducers;

    reducers[REDUCER_SIGN_IN_UP] = signInSignUpReducers;

    reducers[formatFetchReducer(REDUCER_BIO_PROFILE)] = bioProfileReducers;
    reducers[formatFetchReducer(REDUCER_DOCTOR)] = doctorBoardFetchReducers;
    reducers[formatFetchReducer(REDUCER_ACCOUNT)] = accountFetchReducers;

    reducers[REDUCER_USER_DATA] = userDataReducers;
    reducers[REDUCER_MODULAR] = modularReducers;

    return reducers;
}

const localStorageToKeep = [
    DEV_OPTIONS_KEYS,
    LNG_LOCAL_STORAGE,
    FORCED_CONNECTION_ITEM
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (history: any): any => {
    const appReducer = combineReducers({
        router: connectRouter(history),
        ...buildReducers(),
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (state: any, action: any): any => {
        // This makes sure that state is properly cleaned after log out
        // However it's error prone (dependence on 'successfulness' of API_SIGN_OUT,
        // successful(API_SIGN_OUT)'s reducers might need their previous state to properly behave...)
        if (action.type === successful(API_SIGN_OUT)) {
            const toKeep = localStorageToKeep.map(
                (k) => [k, localStorage.getItem(k)]
            )

            localStorage.clear();

            toKeep.forEach(
                (v) => {
                    if (v[0] && v[1] != undefined) {
                        localStorage.setItem(v[0], v[1]);
                    }
                }
            )

            return appReducer(
                {
                    router: state['router'],
                },
                action,
            );
        }

        return appReducer(state, action);
    };
};
