import DropDown from 'components/designSystem/components/DropDown';
import DropDownItem, { DropDownType } from 'components/designSystem/components/DropDownItem';
import { TypographyColor } from 'components/designSystem/containers/Typography';
import { ChevronDown } from 'components/designSystem/foundations/icons/ChevronDown';
import { IconSize } from 'components/designSystem/foundations/IconsData';
import ListItemWithDescription from 'components/designSystem/lists/ListItemWithDescription';
import { TransDomain } from 'components/pages/common/MainComponent';
import { translationMethod } from 'core/utils/trans';
import React, { ReactElement } from 'react';

export interface Language {
    id: string;
    name: string;
}

export interface LanguageSelectorProps {
    languages: Language[];
    selectedLanguage: Language;
    onChangeLanguage: (id: string) => void;
}

export const LanguageDropdown = (props: LanguageSelectorProps): ReactElement => {
    return (
        <DropDown
            closeOnClick
            fullWidth
            title={translationMethod('app_language', undefined, TransDomain.GLOBAL)}
            triggerBuilder={(onClick: () => void): ReactElement => (
                <ListItemWithDescription
                    onClick={onClick}
                    title={translationMethod('app_language', undefined, TransDomain.GLOBAL)}
                    description={props.selectedLanguage.name}
                    descriptionColor={TypographyColor.COLOR_TEXT_SUBDUED}
                    action={<ChevronDown size={IconSize.MEDIUM} />}
                />
            )}
        >
            {props.languages.map((language: Language) => (
                    <DropDownItem
                        key={language.name}
                        label={language.name}
                        payload={{
                            type: DropDownType.LIST,
                            onClick: (): void => {
                                props.onChangeLanguage(language.id);
                            },
                            isChecked: language.id === props.selectedLanguage.id,
                        }}
                    />
            ))}
        </DropDown>
    );
};
