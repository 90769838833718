import { APICallAction } from 'actions/ActionInterface';

export const API_GET_MY_PATIENTS = 'API_GET_MY_PATIENTS';
export const API_GET_MY_DOCTOR_DASHBOARD = 'API_GET_MY_DOCTOR_DASHBOARD';

export const getMyPatients = (): APICallAction<{}> => {
    return {
        type: API_GET_MY_PATIENTS,
        payload: {},
    };
};

export interface GetDoctorDashboardRequest {
    onlyAsReceiver: boolean;
    nextPageToken?: string;
}

export interface GetDoctorDashboardAction extends APICallAction<GetDoctorDashboardRequest> {
    payload: {
        params: GetDoctorDashboardRequest;
        navigationKey?: string,
        onlyAsReceiver?: boolean;
        feedbackIdentifier?: string;
    };
}

export const getDoctorDashboard = (
    nextPageToken?: string,
    onlyAsReceiver = false,
    navigationKey?: string,

): GetDoctorDashboardAction => {
    return {
        type: API_GET_MY_DOCTOR_DASHBOARD,
        payload: {
            params: {
                onlyAsReceiver,
                nextPageToken,
            },
            navigationKey,
            onlyAsReceiver,
        },
    };
};
