import { AxisDataType, AxisValue } from 'components/legacyDesignSystem/components/charts/builders/model';
import { DateTimeFormat, formatDate, formatDateTime } from 'core/utils/Date';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TickFormatterFunction = (value: any) => string;

type TimeTickUnit = 'days' | 'months' | 'years';

export interface TimeTickInterval {
    number: number;
    unit: TimeTickUnit;
}

function buildTimeInterval(
    minDateInMilliSeconds: AxisValue,
    maxDateInMilliSeconds: AxisValue
): TimeTickInterval | undefined {
    const diff = moment(maxDateInMilliSeconds).startOf('day').unix() - moment(minDateInMilliSeconds).startOf('day').unix();

    if (diff > 86400 * 4 * 365) {
        // 1 day = 86400s
        return {number: 2, unit: 'years'};
    } else if (diff > 86400 * 2 * 365) {
        return {number: 1, unit: 'years'};
    } else if (diff > 86400 * (3 / 2) * 365) {
        return {number: 6, unit: 'months'};
    } else if (diff > 86400 * 365) {
        return {number: 4, unit: 'months'};
    } else if (diff > 86400 * 180) {
        return {number: 3, unit: 'months'};
    } else if (diff > 86400 * 120) {
        return {number: 2, unit: 'months'};
    } else if (diff > 86400 * 60) {
        return {number: 1, unit: 'months'};
    } else {
        return undefined
    }
}

function* generateTimeTicks(
    minDateInMilliSeconds: AxisValue,
    maxDateInMilliSeconds: AxisValue,
    interval: TimeTickInterval,
): Generator<AxisValue> {
    yield maxDateInMilliSeconds;

    const lastTick = moment(maxDateInMilliSeconds).utc().startOf(interval.unit).unix() * 1000;
    const minDateTick = moment(minDateInMilliSeconds).utc().add(1, interval.unit).startOf(interval.unit).unix() * 1000;

    let tick = moment(lastTick).utc().subtract(interval.number, interval.unit).unix() * 1000;
    while (tick >= minDateTick) {
        const tickPeriodEnd = moment(tick).utc().endOf(interval.unit).unix() * 1000
        yield (tick + tickPeriodEnd) / 2
        tick = moment(tick).utc().subtract(interval.number, interval.unit).unix() * 1000;
    }
}

function getTimeTickUnitFormat(timeTickUnit?: TimeTickUnit, isSameYear?: boolean): string {
    switch (timeTickUnit) {
        case undefined:
        case 'days':
            return 'D MMM' + (isSameYear ? '' : ' YYYY')
        case 'months':
            return 'MMM' + (isSameYear ? '' : ' YYYY')
        case 'years':
            return 'YYYY'
    }
}

function buildTimeTickFormatter(
    timeTickUnit?: TimeTickUnit,
    minDateInMilliSeconds?: number,
    maxDateInMilliSeconds?: number
): TickFormatterFunction {
    const minDateYear = moment(minDateInMilliSeconds).utc().year()
    const maxDateYear = moment(maxDateInMilliSeconds).utc().year()
    const isSameYear = maxDateYear == minDateYear
    const tickUnitFormat = getTimeTickUnitFormat(timeTickUnit, isSameYear);
    return (unixTimeInMillis: number): string => unixTimeInMillis == maxDateInMilliSeconds ? formatDateTime(unixTimeInMillis, DateTimeFormat.DATE_MEDIUM) : formatDate(unixTimeInMillis, tickUnitFormat)
}

function buildTimeTickProps(
    minDateInMilliSeconds: AxisValue,
    maxDateInMilliSeconds: AxisValue,
    ticks?: AxisValue[],
    tickFormatter?: TickFormatterFunction,
): { ticks?: AxisValue[], tickFormatter?: TickFormatterFunction } {
    const interval = buildTimeInterval(minDateInMilliSeconds, maxDateInMilliSeconds);
    if (interval === undefined) {
        return {ticks, tickFormatter: tickFormatter ?? buildTimeTickFormatter(undefined, minDateInMilliSeconds, maxDateInMilliSeconds)}
    }
    return {
        ticks: ticks ?? [...generateTimeTicks(minDateInMilliSeconds, maxDateInMilliSeconds, interval)].reverse(),
        tickFormatter: tickFormatter ?? buildTimeTickFormatter(interval.unit, minDateInMilliSeconds, maxDateInMilliSeconds)
    }
}


export function buildTickFormatter(dataType: AxisDataType, tickFormatter?: TickFormatterFunction): TickFormatterFunction | undefined {
    if (tickFormatter) {
        return tickFormatter
    }

    if (dataType !== AxisDataType.TIME) {
        return;
    }

    return buildTimeTickFormatter()
}

export function buildTickProps(
    dataType: AxisDataType,
    minValue?: AxisValue,
    maxValue?: AxisValue,
    ticks?: AxisValue[],
    tickFormatter?: TickFormatterFunction,
): { ticks?: AxisValue[], tickFormatter?: TickFormatterFunction } {
    if (ticks && tickFormatter) {
        return {ticks, tickFormatter}
    }

    if (minValue === undefined || maxValue === undefined) {
        return {ticks, tickFormatter: buildTickFormatter(dataType, tickFormatter)}
    }

    if (dataType !== AxisDataType.TIME) {
        return {ticks, tickFormatter}
    }

    return buildTimeTickProps(minValue, maxValue, ticks, tickFormatter)
}
