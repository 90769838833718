import { AnyAction, getResponse, successful } from 'actions/ActionInterface';
import { API_RETRIEVE_AUTH_USER_DATA, API_SIGN_IN, API_SIGN_OUT } from 'actions/user/SignInSignUpActions';
import { API_GET_NAVIGATION_CONTENT } from 'actions/user/UserActions';
import { AuthResponse, AuthUserData, AuthUserDataResponse } from 'models/user/AuthModels';
import { GetNavigationResponse, Navigation } from 'models/user/NavigationModels';
import { LocalUserData, UserMode } from 'models/user/UserModels';
import { getLocalObject } from 'reducers/Utils';

const USER_DATA = 'user_data';

export const defaultUserData: LocalUserData = {
    userModes: [UserMode.PATIENT],
    chosenUserMode: UserMode.PATIENT,
    firstName: null,
    lastName: null,
};

export const initialState = {
    userData: getLocalObject(USER_DATA, null),
};

function buildUserData(rawData: AuthUserData): LocalUserData {
    const data: LocalUserData = { ...defaultUserData };

    data.firstName = rawData.first_name;
    data.lastName = rawData.last_name;
    data.uuid = rawData.uuid;

    const umods = rawData.user_modes;
    if (umods && umods.length > 0) {
        if (umods.indexOf(UserMode.DOCTOR) > -1) {
            data.chosenUserMode = UserMode.DOCTOR
        } else if (umods.indexOf(UserMode.BIOLOGIST) > -1) {
            data.chosenUserMode = UserMode.BIOLOGIST
        } else if (umods.indexOf(UserMode.ANONYMOUS) > -1) {
            data.chosenUserMode = UserMode.ANONYMOUS
        } else if (umods.indexOf(UserMode.RWE_USER) > -1) {
            data.chosenUserMode = UserMode.RWE_USER
        } else {
            data.chosenUserMode = UserMode.PATIENT
        }
    } else {
        data.chosenUserMode = UserMode.PATIENT;
    }

    if (rawData.doctor_data) {
        data.doctorData = {
            practiceId: rawData.doctor_data.practice_id,
            businessStructureId: rawData.doctor_data.business_structure_id,
            businessStructureType: rawData.doctor_data.business_structure_type,
            speciality: rawData.doctor_data.speciality,
            businessAddress: rawData.doctor_data.business_address,
            businessEmail: rawData.doctor_data.business_email,
            businessPhoneNumber: rawData.doctor_data.business_phone_number,
        };
    }

    data.userModes = umods ? umods : [];

    return data;
}

export interface UserDataReducerInterface {
    userData?: LocalUserData
    navigation?: Navigation
}

export default function userDataReducers(state = initialState, action: AnyAction): UserDataReducerInterface {
    switch (action.type) {
        case successful(API_SIGN_IN):
            const responseSignIn = getResponse<AuthResponse>(action);
            // temporary, to clean legacy, to remove in a few months (written 2020-05-13)
            localStorage.removeItem(USER_DATA);

            if (!responseSignIn.data) {
                return {
                    ...state,
                    userData: undefined,
                };
            }

            return {
                ...state,
                userData: buildUserData(responseSignIn.data),
            };

        case successful(API_RETRIEVE_AUTH_USER_DATA):
            return {
                ...state,
                userData: buildUserData(getResponse<AuthUserDataResponse>(action).data),
            };

        case successful(API_SIGN_OUT):
            // temporary, to clean legacy, to remove in a few month (written 2020-05-13)
            localStorage.removeItem(USER_DATA);

            return {
                ...state,
                userData: undefined,
                navigation: undefined
            };
        case successful(API_GET_NAVIGATION_CONTENT):
            const navigation = getResponse<GetNavigationResponse>(action);
            return {
                ...state,
                navigation: navigation
            }
    }

    return state;
}
