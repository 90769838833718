import React, { Component, Fragment, ReactNode } from 'react';

type TimerProps = {
    seconds: number;
    timeFormatter: (minutes: number, seconds: number) => ReactNode;
};

type TimerState = {
    seconds: number;
};

export const minutesAndSecondsToString = (minutes: number, seconds: number): string => {
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

export default class Timer extends Component<TimerProps, TimerState> {
    constructor(props: TimerProps) {
        super(props);
        this.state = { seconds: props.seconds };
    }

    myInterval?: ReturnType<typeof setInterval>;

    componentDidMount(): void {
        this.myInterval = setInterval(() => {
            const { seconds } = this.state;
            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1,
                }));
            }

            if (seconds === 0 && this.myInterval) {
                clearInterval(this.myInterval);
            }
        }, 1000);
    }

    componentWillUnmount(): void {
        if (this.myInterval) {
            clearInterval(this.myInterval);
        }
    }

    render(): ReactNode {
        const { seconds } = this.state;

        const minutes: number = Math.floor(seconds / 60);
        const minuteSeconds: number = seconds - minutes * 60;

        return <Fragment>{this.props.timeFormatter(minutes, minuteSeconds)}</Fragment>;
    }
}
