import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ArrowLeft = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-arrow-left'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.5835 7.99991C2.5835 7.58569 2.91928 7.24991 3.3335 7.24991H12.6668C13.081 7.24991 13.4168 7.58569 13.4168 7.99991C13.4168 8.41412 13.081 8.74991 12.6668 8.74991H3.3335C2.91928 8.74991 2.5835 8.41412 2.5835 7.99991Z"
                      fill="currentColor"
                />
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.53049 2.80292C8.82339 3.09582 8.82339 3.57069 8.53049 3.86358L4.39416 7.99992L8.53049 12.1363C8.82339 12.4291 8.82339 12.904 8.53049 13.1969C8.2376 13.4898 7.76273 13.4898 7.46983 13.1969L2.80317 8.53025C2.51027 8.23736 2.51027 7.76248 2.80317 7.46959L7.46983 2.80292C7.76273 2.51003 8.2376 2.51003 8.53049 2.80292Z"
                      fill="currentColor"
                />
            </svg>
        )
    }

    return (
        <svg id={'s-arrow-left'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                  fill="currentColor"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z"
                  fill="currentColor"/>
        </svg>
    )
}