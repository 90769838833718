import React, { FC } from 'react';
import classNames from 'classnames';
import styles from 'components/designSystem/containers/WithBackground.module.scss';

export enum WithBackgroundColor {
    TINTED = 'TINTED',
    PRIMARY_LINEAR_GRADIENT = 'PRIMARY_LINEAR_GRADIENT',
    SURFACE_INTENSE = 'SURFACE_INTENSE'
}

export enum WithBackgroundRadius {
    BORDER_RADIUS_S = 'BORDER_RADIUS_S'
}


interface WithBackgroundProps {
    color: WithBackgroundColor,
    radius?:WithBackgroundRadius,
    fitContent?:boolean,
    disabledWhenPrinting?:boolean
}

const WithBackground: FC<WithBackgroundProps> = (
    props
): JSX.Element => {

    return <div className={
        classNames(
            styles.withBackground,
            {
                [styles.withBackgroundColorTinted]: props.color === WithBackgroundColor.TINTED,
                [styles.withBackgroundColorPrimaryLinearGradient]: props.color === WithBackgroundColor.PRIMARY_LINEAR_GRADIENT,
                [styles.withBackgroundColorSurfaceIntense]: props.color === WithBackgroundColor.SURFACE_INTENSE,

                [styles.withBackgroundRadiusS]: props.radius === WithBackgroundRadius.BORDER_RADIUS_S,
                [styles.withBackgroundFitContent]: props.fitContent,

                [styles.disabledWhenPrinting]: props.disabledWhenPrinting
            }
        )
    }>
        {props.children}
    </div>
}

export default WithBackground;