import { ModalType } from "actions/common/CommonActions";
import TrText from "components/common/TrText";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import TextField from "components/designSystem/components/TextField";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import { AlwaysInline, InlineJustifyContent } from "components/designSystem/containers/Inline";
import { SmallSpacer } from "components/designSystem/containers/Spacer";
import { TLabel700, TypographyColor } from "components/designSystem/containers/Typography";
import { IconName } from "components/designSystem/foundations/IconsData";
import BaseModule, { defaultBaseModuleState, ModuleState, setupModule } from "components/modules/modular/BaseModule";
import { TransDomain } from "components/pages/common/MainComponent";
import { ModuleData } from "models/modular/storage";
import React from "react";

interface ReportValidationModulePayload {
    default_recommendation?: string
}

interface LocalState {
    recommendation?: string;
}

class ReportValidationModule extends BaseModule<{}, ReportValidationModulePayload> {
    state: ModuleState<LocalState> = defaultBaseModuleState(
        {}
    )

    private openModal(default_recommendation: string | undefined): void {
        this.props.modal(
            ModalType.CENTER,
            (): JSX.Element => <Box withFullWidth background={BoxColor.WHITE}
            >
                <SmallSpacer>
                    <TextField
                        label={<TrText
                            input={{trkey: 'validation_message_prompt', trdomain: TransDomain.MEDICAL_REPORT}}/>}
                        type={'textarea'}
                        hideLabelOnActive
                        compact
                        rows={15}
                        defaultValue={this.state.recommendation ? this.state.recommendation : default_recommendation}
                    />
                    <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
                        <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE}>
                            <TrText input={{trkey: 'validation_button', trdomain: TransDomain.MEDICAL_REPORT}}/>
                        </Button>
                    </AlwaysInline>
                </SmallSpacer>
            </Box>,
            {
                title: {
                    default: <TrText input={{trkey: 'validation_modal_title', trdomain: TransDomain.MEDICAL_REPORT}}/>
                },
                large: true
            }
        );
    }

    protected _render(payload: ModuleData<ReportValidationModulePayload, {}>): React.ReactNode {
        const defaultRecommendation = payload.pagePayload.default_recommendation;
        return <Box background={BoxColor.WHITE} withBoxShadow withPadding withFullWidth>
            <SmallSpacer>
                <AlwaysInline justifyContent={InlineJustifyContent.SPACE_BETWEEN}>
                    <TLabel700 color={TypographyColor.COLOR_TEXT_DEFAULT}>Conclusions</TLabel700>
                    <Button
                        variant={ButtonVariant.QUATERNARY}
                        icons={{middle: IconName.EXTERNAL_LINK}}
                        onClick={(): void => this.openModal(defaultRecommendation)}
                    />
                </AlwaysInline>
                <TextField
                    label={<TrText input={{trkey: 'validation_message_prompt', trdomain: TransDomain.MEDICAL_REPORT}}/>}
                    type={'textarea'}
                    hideLabelOnActive
                    compact
                    defaultValue={this.state.recommendation ? this.state.recommendation : defaultRecommendation}
                    onChange={(input): void => this.setState({recommendation: input})}
                />
                <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
                    <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE}>
                        <TrText input={{trkey: 'validation_button', trdomain: TransDomain.MEDICAL_REPORT}}/>
                    </Button>
                </AlwaysInline>
            </SmallSpacer>
        </Box>
    }
}

export default setupModule(ReportValidationModule, () => ({}), () => ({}),);