import React from 'react';

const Tube = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M15.5 21L26.5 20L28.5 22.5L30 25.5L29 28.5L27.5 29.5L24 30L15.5 21Z" />
            <path d="M9.56231 1.0688L1.0873 9.5438C0.568555 10.0626 0.568555 10.9126 1.0873 11.4313C1.60606 11.9501 2.45606 11.9501 2.97481 11.4313L3.04356 11.3626L21.2498 29.5688C23.5561 31.8751 27.2936 31.8751 29.5936 29.5688C31.8998 27.2626 31.8998 23.5251 29.5936 21.2251L11.3873 3.02505L11.4561 2.9563C11.9748 2.43755 11.9748 1.58755 11.4561 1.0688C10.9311 0.543799 10.0873 0.543799 9.56231 1.0688ZM27.7936 27.7751C26.4873 29.0813 24.3561 29.0813 23.0498 27.7751L18.0873 22.8126L27.3561 22.5938L27.7936 23.0313C29.0998 24.3376 29.0998 26.4626 27.7936 27.7751ZM24.8748 20.1126L15.6061 20.3313L4.84356 9.5688L9.58731 4.82505L24.8748 20.1126Z" />
        </svg>
    )
}

export default Tube;
