import React from 'react';
import { getRoute } from 'core/routing/Helper';
import { ROUTE_EULA_HEALTH_PROFESSIONAL } from 'core/routing/Routes';

import 'components/modules/eula/eula.scss';

const EulaCintentV1 = (): JSX.Element => {
    return (
        <div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-content">
                    Merci d'avoir choisi notre Solution et nos Services. Cette Solution est mise à disposition par la
                    société Byond, société par actions simplifiée au capital de 13.426,85 euros, dont le siège social
                    est situé 23, boulevard François Robert, 13009 MARSEILLE, immatriculée au Registre du Commerce et
                    desSociétés de Marseille sous le numéro 851 014 407 (ci-après «{' '}
                    <b>
                        <i>BYOND</i>
                    </b>{' '}
                    »).
                    <br />
                    <br />
                    L’utilisation de notre Solution et de nos Services implique votre acceptation des présentes
                    Conditions Générales d’Utilisation. Nous vous invitons à les lire attentivement.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Définitions</div>
                <div className="eula-paragraph-content">
                    Dans les présentes conditions générales d’utilisation, les termes identifiés ci-dessous par une
                    majuscule ont la signification suivante, qu’ils soient employés au singulier ou au pluriel:
                    <ul>
                        <li>
                            <b>CGU</b> : désigne les présentes conditions générales d’utilisation;
                        </li>
                        <br />
                        <li>
                            <b>Données Personnelles</b> : désigne toute donnée qui, au sens de la Réglementation
                            applicable à la protection des données personnelles, permet de désigner ou d'identifier,
                            directement ou indirectement, une personne physique, notamment par référence à son état
                            civil, un identifiant, un numéro d’identification, des données de localisation, un
                            identifiant en ligne ou à un ou plusieurs éléments spécifiques qui lui sont propres;
                        </li>
                        <br />
                        <li>
                            <b>Données Personnelles de Santé</b> : désigne une Donnée Personnelle relative à la santé
                            physique ou mentale d'une personne physique, y compris la prestation de services de soins de
                            santé, qui révèlent les informations sur l'état de santé de cette personne, indépendamment
                            de leur source, qu'elles proviennent par exemple d'un médecin ou d'un autre Professionnel de
                            la Santé, d'un hôpital, d'un dispositif médical ou d'un test de diagnostic in vitro et
                            recueillies par exemple à l’occasion d’activités de prévention, de diagnostic, de soins ou
                            de suivi social et médico-social. Ainsi, toute information médicale (une maladie, un
                            handicap, un risque de maladie, une allergie, une donnée clinique ou thérapeutique,
                            physiologique ou biologique) constitue une donnée de santé;
                        </li>
                        <br />
                        <li>
                            <b>Données Patients</b> : désigne les Données Personnelles relatives au Patient, y compris
                            les Données Personnelles de Santé et les données de biologie médicale,
                            <ol type="a">
                                <li>
                                    collectées par le Laboratoire pour les besoins de l’analyse de biologie médicale qui
                                    a été prescrite au Patient par l’Utilisateur dans le cadre du soin du Patient,
                                </li>
                                <li>
                                    transmises par le Laboratoire à BYOND, de manière sécurisée, une fois les résultats
                                    d’examens de biologie médicale validés par celui-ci conformément au processus prévu
                                    par la réglementation applicable à l’activité du Laboratoire,
                                </li>
                                <li>traitées par BYOND sur la Solution pour le compte du Laboratoire, et</li>
                                <li>
                                    communiquées à l’Utilisateur au travers de la Solution, conformément à l’article D.
                                    6211-3 du code de la santé publique, pour les besoins du suivi de son Patient;
                                </li>
                            </ol>
                        </li>
                        <br />
                        <li>
                            <b>Identifiants</b> : désigne l’identifiant personnel associé au compte de l’Utilisateur
                            ainsi que tout code confidentiel ou mot de passe délivré à l’Utilisateur par BYOND puis
                            modifié par l’Utilisateur et permettant à l’Utilisateur de s’identifier afin d’accéder à la
                            Solution,  dans les conditions de l’article « Modalités d’accès à la Solution »;
                        </li>
                        <br />
                        <li>
                            <b>Laboratoire</b> : désigne le laboratoire de biologie médicale ayant souscrit un contrat
                            de partenariat avec BYOND aux fins de permettre ledit laboratoire de communiquer aux
                            Utilisateurs des résultats des examens de biologie médicale complétés, grâce à
                            l’intelligence artificielle, des Services et pour lesquels le Laboratoire fournit aux
                            Utilisateurs un accès à la Solution de BYOND, sous forme d’une mise à disposition gratuite;
                        </li>
                        <br />
                        <li>
                            <b>Outil d’Aide à la Prescription et au Diagnostic</b> : désigne le service de
                            recommandations d’examens complémentaires de biologie médicale mise à disposition des
                            Utilisateurs par le Laboratoire par le biais de BYOND à travers la Solution;
                        </li>
                        <br />
                        <li>
                            <b>Outil de Visualisation</b> : désigne le service d’aide à la visualisation du compte-rendu
                            d’examens et des résultats de biologie médicale du Patient, à partir des informations
                            fournies par le Laboratoire et mis à disposition des Utilisateurs sur la Solution;
                        </li>
                        <br />
                        <li>
                            <b>Patient</b> : désigne le patient de l’Utilisateur dont les Données Patients sont traitées
                            au travers de la Solution;
                        </li>
                        <br />
                        <li>
                            <b>Professionnel de santé</b> : désigne les professionnels intervenant dans le secteur de la
                            santé;
                        </li>
                        <br />
                        <li>
                            <b>Réglementation applicable à la protection des données personnelles</b> : désigne toute
                            réglementation applicable à la protection des données personnelles et en particulier le
                            Règlement (UE) n°2016/679 du Parlement Européen et du Conseil du 27 avril 2016 dit
                            « Règlement Général sur la Protection des Données » et la loi n°78-17 du 6 janvier 1978
                            modifiée dite « Loi Informatique et Libertés »;
                        </li>
                        <br />
                        <li>
                            <b>Services</b> : désigne conjointement l’Outil de Visualisation et l’Outil d’Aide à la
                            Prescription et au Diagnostic, mis à disposition des Utilisateurs sur la Solution;
                        </li>
                        <br />
                        <li>
                            <b>Solution</b> : désigne la solution logicielle d’analyse de biologie médicale développée
                            par BYOND, comprenant les Services, et mise à disposition des Utilisateurs en mode SaaS
                            (Software as a Service), telle que décrite à l’article « Description de la Solution »
                            ci-après;
                        </li>
                        <br />
                        <li>
                            <b>Utilisateur</b> : désigne le Professionnel de Santé et, le cas échéant, les membres de
                            son équipe soumis au secret professionnel et agissant sous la responsabilité du
                            professionnel de santé, médecin prescripteur d’un examen de biologie médicale auprès de son
                            Patient dans le cadre de la prise en charge de ce dernier, qui dispose d’un accès à la
                            Solution et l’utilise pour visualiser, analyser et interpréter les résultats des examens
                            transmis par le Laboratoire, au travers de la Solution, dans lequel le Patient a effectué
                            ses analyses.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Objet des CGU et version en viugeur</div>
                <div className="eula-paragraph-content">
                    Les présentes CGU ont pour objet de définir les modalités et conditions d'accès et d'utilisation de
                    la Solution mise à disposition par BYOND aux Utilisateurs, ainsi que les droits et obligations de
                    l'Utilisateur dans ce cadre. Les CGU constituent un contrat entre l'Utilisateur et BYOND. L'accès à
                    la Solution est subordonné à l'acceptation expresse et sans réserve par l'Utilisateur des CGU. Le
                    défaut d'acceptation des CGU impliquera l'impossibilité pour l'Utilisateur d'accéder et d'utiliser
                    la Solution.
                    <br />
                    <br />
                    Cette acceptation s'effectue lors de la connexion de l'Utilisateur sur la Solution. L'Utilisateur
                    est alors invité à cliquer sur le lien hypertexte présentant les CGU et à les lire attentivement.
                    S'il en accepte les termes, l'Utilisateur est invité à cocher la case prévue à cet effet. Par ce
                    clic, l'Utilisateur reconnaît avoir lu les CGU, les avoir comprises et accepter pleinement
                    l'ensemble de leurs dispositions, sans restriction ni réserve.
                    <br />
                    <br />
                    L'Utilisateur est, dans le même temps invité expressément à la lire la politique de protection des
                    données personnelles de la Solution. La politique de protection des données personnelles fait partie
                    intégrante des CGU. De fait, en acceptant les présentes CGU, l'Utilisateur reconnaît expressément
                    avoir lu la politique de protection des données personnelles et en avoir accepté les termes.
                    <br />
                    <br />
                    BYOND est libre de modifier et/ou de mettre à jour à tout moment les CGU sans préavis, afin
                    notamment de prendre en compte toute évolution légale, réglementaire, jurisprudentielle et/ou
                    technique. L'accès et l'utilisation de la Solution seront soumis aux CGU en vigueur au moment de cet
                    accès et de cette utilisation. L'Utilisateur est donc expressément informé que l'unique version des
                    CGU qui fait foi est celle qui se trouve en ligne au moment de la connexion de l'Utilisateur, telles
                    qu'elles sont disponibles à l'adresse :{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getRoute(ROUTE_EULA_HEALTH_PROFESSIONAL, { version: null })}
                        className={"legacy-a"}

                    >
                        {window.location.protocol}
                        {'//'}
                        {window.location.hostname}
                        {getRoute(ROUTE_EULA_HEALTH_PROFESSIONAL, { version: null })}
                    </a>
                    , ce qu'il reconnaît et accepte sans restriction, s'engageant à s'y référer systématiquement lors de
                    chaque connexion.
                    <br />
                    <br />
                    Dans l'hypothèse où l'une des clauses des CGU serait nulle ou inapplicables pour quelque raison que
                    ce soit, y compris du fait d'un changement de législation ou de réglementation ou déclarée comme
                    telle par une décision de justice définitive, cela ne saurait en aucun cas affecter la validité et
                    le respect des autres clauses des CGU, de sorte que, sauf impossibilité, les CGU poursuivent leurs
                    effets sans discontinuité. L'Utilisateur et BYOND resteront liées par les autres stipulations des
                    CGU et s'efforceront de remédier aux clauses inapplicables dans le même esprit que celui qui a
                    présidé lors de la conclusion.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Description de la Solution</div>
                <div className="eula-paragraph-content">
                    La Solution mise à disposition par BYOND est une solution logicielle d’analyse de biologie médicale
                    développée par BYOND et mise gratuitement à disposition de l’Utilisateur en mode SaaS (Software as a
                    Service), conformément au contrat passé entre BYOND et le Laboratoire.
                    <br />
                    <br />
                    La Solution offre la possibilité à l’Utilisateur de recevoir des informations et documents de la
                    part des Laboratoires concernant ses Patients et d’accéder aux Services ainsi qu’à d’autres
                    fonctionnalités dont des notifications, notamment grâce à des algorithmes d’intelligence
                    artificielle issus du machine learning (apprentissage automatique) et du deep learning
                    (apprentissage automatique profond),
                    <br />
                    <br />
                    La Solution est disponible en ligne à l’adresse suivante : https://app.byond.bio/
                    <br />
                    <br />
                    Il est entendu et accepté que l’accès à la Solution est strictement limité aux seules Données
                    Patients des Patients suivis par l’Utilisateur, dont il est le destinataire ou le prescripteur selon
                    le Laboratoire
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">Outil de Visualisation</div>
                    L’Outil de Visualisation du compte-rendu d’examens et des résultats de biologie médicale du Patient
                    permet à l’Utilisateur une meilleure visibilité et/ou un embellissement des données du compte-rendu
                    du Laboratoire. Cet outil a pour objectif d’afficher les données médicales enregistrées par le
                    Laboratoire pour apporter un meilleur confort visuel.
                    <br />
                    <br />
                    L’Outil de Visualisation n'est pas destiné à effectuer une analyse des données existantes du
                    compte-rendu pour générer une nouvelle information spécifique au Patient et orienter son traitement
                    thérapeutique.
                    <br />
                    <br />
                    L’Outil de Visualisation permet à l’Utilisateur de rechercher un Patient ayant effectué un examen de
                    biologie médicale dans le Laboratoire et de consulter l’historique des compte-rendu du Patient,
                    conformément au contrat passé entre BYOND et le Laboratoire.
                    <br />
                    <br />
                    Pour chaque analyse, les résultats de biologie médicale du Patient sont affichés selon leur présence
                    hors des valeurs de références du Laboratoire. Ces valeurs sont calculées et propres à chaque
                    Laboratoire. Il est fortement recommandé à l’Utilisateur d’afficher la totalité des analyses de
                    biologie médicale présentes sur le compte rendu d’examen, ce que l’Utilisateur peut faire à tout
                    moment sur le compte rendu d’un Patient. L’affichage des analyses en dehors des valeurs de
                    références est réalisé dans l’unique but d’apporter un meilleur confort visuel à l’Utilisateur lors
                    de l’ouverture du compte-rendu. En aucun cas cet affichage ne dispense l’Utilisateur de consulter la
                    totalité des résultats d’analyses présents sur le compte-rendu. BYOND rejette toute responsabilité
                    si l’Utilisateur décide de ne pas afficher la totalité des résultats.
                    <br />
                    <br />
                    BYOND ne garantit pas à l’Utilisateur que l’historique contienne l’ensemble des compte-rendu du
                    Patient. En particulier, si un laboratoire de biologie médicale n’a pas souscrit de contrat avec
                    BYOND ou si le Laboratoire ne les a pas communiqués à BYOND, les compte-rendu du Patient réalisés
                    par ce laboratoire de biologie médicale ne seront pas disponibles dans l’historique du Patient sur
                    la Solution. En conséquence, il est possible que l’Utilisateur ne trouve pas un compte-rendu
                    d’examens relatif au Patient sur la Solution à travers l’outil de visualisation du compte-rendu
                    d’examens.
                    <br />
                    <br />
                    Dans le cadre de l’Outil de Visualisation du compte-rendu d’examens, BYOND peut indiquer à titre
                    purement informatif à l’Utilisateur les informations disponibles au sujet de certaines analyses de
                    biologie médicale. Ces informations, généralement disponibles en ligne ou auprès de différentes
                    sources, ne sont pas délivrée par BYOND comme un avis médical. Elles ne doivent donc en aucun cas
                    être prises comme tel.
                    <br />
                    <br />
                    Les informations contenues dans un bilan de biologie médicale ne constituent qu’une partie de
                    l'image biologique complexe de la santé ou de la maladie du Patient. L’Utilisateur, en tant que
                    Professionnel de santé, est seul juge de l’analyse du bilan de biologie médicale du Patient et de la
                    prise en charge qui doit en résulter. A ce titre, il peut interrompre au besoin à tout moment
                    l’utilisation de la Solution et contacter le Laboratoire si les conditions d’utilisation qu’il juge
                    nécessaires ne sont pas réunies.
                    <br />
                    <br />
                    Dans tous les cas, il est important que le Professionnel de Santé et le Patient communiquent
                    largement entre eux ainsi qu’avec leur laboratoire de biologie médicale, de sorte qu'ensemble ils
                    puissent apporter les informations correctes, telle que l'âge, l'appartenance ethnique, l'histoire
                    de la maladie, les signes et les symptômes, les examens de biologie médicale et d'autres examens
                    (radiologie, endoscopie, etc.), pour déterminer l’état de santé du Patient. Les informations
                    fournies par la Solution et l’Outil de Visualisation de compte rendus d’examens ne sont pas prévus
                    pour remplacer d’une quelconque façon ni d’aucune manière de telles consultations avec le
                    Professionnel de Santé ni comme conseil médical spécifique à l’état de santé du Patient.
                    <br />
                    <br />
                    BYOND rappelle à l’Utilisateur qu’en aucun cas l’Outil de Visualisation ne peut être assimilé, du
                    fait de son absence d’exhaustivité ou d’exactitude, à un service de consultation ou de conseil
                    médical aux Patients ou aux Professionnels de Santé.
                    <br />
                    <br />
                    L’Utilisateur peut à tout moment télécharger le compte rendu d’origine du Laboratoire sous format
                    HPRIM et/ou PDF, selon le format de distribution choisi par le Laboratoire. En tout état de cause,
                    ou si l’état de santé du Patient le nécessite, l’Utilisateur se doit de télécharger le document pour
                    s’y référer. Le compte rendu du Laboratoire au format HPRIM et/ou PDF prévaudra en tout état de
                    cause en cas de doute ou suspicion de doute quant à la qualité et/ou la pertinence des éléments
                    fournis par la Solution ou l’Outil de Visualisation.
                    <br />
                    <br />
                    L’Utilisateur conserve toujours le choix des sources et des Professionnels de Santé qu’il consulte
                    conformément aux dispositions applicables du Code de la santé publique, aux dispositions
                    déontologiques applicables aux professions réglementées et aux dispositions contraignantes
                    applicables aux professions non réglementées.
                    <br />
                    <br />
                    BYOND rejette toute responsabilité liée à l’utilisation de cet outil ou pour n'importe quelle
                    utilisation malencontreuse d'une information donnée par cet Outil de Visualisation ou de la
                    Solution.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">
                        Outil d’Aide à la Prescription et au Diagnostic : outil d’aide à la prescription et d’aide au
                        diagnostic par des suggestions d’examens complémentaires
                    </div>
                    L’Utilisateur peut, sur chaque compte-rendu de biologie médicale du Patient, bénéficier de
                    suggestions concernant la prescription de certains examens de biologie médicale complémentaires à
                    réaliser. Cet outil d’aide à la prescription et d’aide au diagnostic utilise notamment des profils
                    de patients similaires générés à l’aide de l’intelligence artificielle et notamment d’algorithmes de
                    machine learning (apprentissage automatique) et de deep learning (apprentissage profond). Les
                    résultats des algorithmes permettent d’afficher à l’Utilisateur des recommandations d’examens de
                    biologie médicale complémentaires, qui peuvent directement être prescrits par l’Utilisateur via la
                    Solution.
                    <br />
                    <br />
                    L’outil d’aide à la prescription permet à l’Utilisateur, en tant que Professionnel de Santé, de
                    consulter les examens suggérés par l’algorithme et de modifier librement sa prescription. Cet outil
                    d’aide à la prescription d’examens de biologie médicale complémentaire concourt à favoriser le
                    dialogue entre cliniciens et biologistes médicaux. L’Utilisateur reconnait en outre expressément que
                    le retrait, l’ajout ou le remplacement d’examens sont réalisés au mieux dans le cadre des
                    recommandations de bonnes pratiques. L’Utilisateur reconnait également expressément effectuer les
                    éventuelles prescriptions d’examens de biologie médicale en observant la plus stricte économie
                    compatible avec l’exécution des prescriptions.
                    <br />
                    <br />
                    Conformément aux obligations légales de l’Utilisateur, dans les limites fixées par la loi, et compte
                    tenu des données acquises de la science, il est seul libre et responsable de ses prescriptions qui
                    seront celles qu'il estime les plus appropriées en la circonstance. Il doit, sans négliger son
                    devoir d'assistance morale, limiter ses prescriptions et ses actes à ce qui est nécessaire à la
                    qualité, à la sécurité et à l'efficacité des soins. Il doit tenir compte des avantages, des
                    inconvénients et des conséquences des différentes investigations possibles.
                    <br />
                    <br />
                    L’Utilisateur reconnait et accepte enfin expressément que l’Outil d’Aide à la Prescription et au
                    Diagnostic et la Solution, ne concerne uniquement que les examens de biologie médicale. En aucun cas
                    cet outil n’est destiné à aider à la prescription d’autres investigations ou thérapeutiques. Par
                    ailleurs, BYOND ne garantit pas à l’Utilisateur que les suggestions et recommandations de l’Outil
                    d’Aide à la Prescription et au Diagnostic contiennent l’ensemble des examens de biologie médicale,
                    recommandations ou suggestions nécessaires à la bonne prise en charge du Patient. Il est possible
                    que l’Utilisateur ne se voit pas recommander un ou plusieurs examens de biologie médicale pourtant
                    nécessaires ou les pistes diagnostiques exactes. Les recommandations et suggestions fournies par la
                    Solution et l’Outil d’Aide à la Prescription et Diagnostic ne sont fournies à l’Utilisateur qu’à
                    titre informatif. En particulier, elles ne peuvent pas prendre en compte l’exhaustivité des
                    informations à la disposition du médecin prescripteur ou d’autres Professionnels de Santé, comme
                    l'appartenance ethnique, l’historique des maladies du Patient, les signes et les symptômes
                    d’éventuelles pathologies, les examens de biologie médicale non réalisés dans le Laboratoire, et les
                    résultats d'autres examens (radiologie, cardiologie, endoscopie, etc.). Par conséquent, s’il le juge
                    utile ou nécessaire, l’Utilisateur se devra de rajouter de tels examens dans sa prescription dont il
                    en est le seul responsable et considérer également les pistes d’investigations qui ne lui seront pas
                    présentées par l’Outil d’Aide à la Prescription et au Diagnostic ou la Solution. BYOND rappelle à
                    l’Utilisateur que l’Outil d’Aide à la Prescription et au Diagnostic ne peut absolument pas être
                    assimilé, du fait de son absence d’exhaustivité ou d’exactitude, à un service de diagnostic ou à un
                    service de conseil médical spécifique à l’état de santé du Patient pour sa prise en charge.
                    <br />
                    <br />
                    L’utilisation de la Solution ne dispense pas l’Utilisateur de vérifier les informations disponibles
                    auprès des autorités et toutes autres sources officielles. L’Utilisateur conserve toujours le choix
                    de sa prescription et de la prise en charge du Patient qu’il prend conformément à ses seules
                    compétences et connaissances, aux dispositions applicables du Code de la santé publique et aux
                    dispositions déontologiques applicables aux professions réglementées. L’utilisation de la Solution
                    ou de l’Outil d’Aide à la Prescription et au Diagnostic n’est donc en aucun cas prévue pour
                    remplacer d’une quelconque façon le jugement, la décision ou l’appréciation de l’Utilisateur en tant
                    que Professionnel de Santé dûment habilité à prescrire des examens de biologie médicale. Le
                    prescripteur est seul juge des examens à envisager et à prescrire et prend seul la responsabilité de
                    la prise en charge du Patient et de la prescription d’examens complémentaires qu’il estime adéquats
                    et appropriés selon l’état de santé du Patient. A ce titre, il peut interrompre au besoin à tout
                    moment l’utilisation de la Solution et contacter le Laboratoire si les conditions qu’il estime
                    nécessaires ne sont pas réunies.
                    <br />
                    <br />
                    BYOND ne serait être tenue responsable des conséquences éventuelles pouvant résulter d’une erreur de
                    prescription, de délivrance, d’administration, d’indication, de prise en charge du Patient ou plus
                    généralement d’utilisation de la Solution ou de l’Outil d’Aide à la Prescription et au Diagnostic.
                    <br />
                    <br />
                    En cliquant sur le bouton « imprimer », l’Utilisateur reconnait donc expressément avoir validé le
                    contenu de sa prescription et en être le seul responsable. Il reconnait également expressément par
                    ce clic, avoir lu les conditions qui précèdent, les avoir compris et accepter pleinement l’ensemble
                    de leurs dispositions, sans restriction ni réserve.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">Autres fonctionnalités et services de la Solution</div>
                    L’Utilisateur autorise le Laboratoire, via BYOND, et/ou BYOND à lui envoyer des SMS, « notifications
                    push » et e-mails dont l’objet est de lui délivrer des informations au sujet des compte-rendu de
                    biologie médicale réalisés par le Laboratoire.
                    <br />
                    <br />
                    L’Utilisateur autorise également le Laboratoire, via BYOND, et/ou BYOND à lui notifier par SMS, «
                    notifications push » ou e-mail toute information concernant les Professionnels de Santé qui font
                    partie de son « organisation » ou concernant les compte-rendu des Patients pour lesquels il est
                    destinataire selon le Laboratoire, sans pour autant être prescripteur.
                    <br />
                    <br />
                    BYOND et le Laboratoire déclinent toute responsabilité en cas d’erreur dans la saisie par
                    l’Utilisateur de ses coordonnées rendant impossible l’envoi de tout SMS, de toute « notification
                    push » ou de tout e-mail nécessaire au bon fonctionnement et à la bonne administration des Services
                    ou de la Solution. Il appartient à l’Utilisateur de faire toutes vérifications qui semblent
                    nécessaires ou opportunes à ce titre avant de procéder à l’utilisation des Services. BYOND ne
                    pourrait aucunement être responsable de l’exactitude de telles informations. L’exactitude et la mise
                    à jour de ces données sont sous l’entière responsabilité de l’Utilisateur.
                    <br />
                    <br />
                    L’utilisation de la Solution ne dilue, ne modifie ou n’atténue ni directement, ni indirectement la
                    responsabilité et les obligations des Laboratoires vis-à-vis des Utilisateurs. Les Utilisateurs qui
                    sont des Professionnels de Santé exercent leur pratique professionnelle en toute indépendance, selon
                    leurs obligations déontologiques, légales et réglementaires personnelles et sous leur responsabilité
                    exclusive.
                    <br />
                    <br />
                    L’Utilisateur s’engage à compléter toutes les Informations nécessaires qui lui sont demandées en vue
                    de la bonne utilisation de la Solution et des Services.
                    <br />
                    <br />
                    L’Utilisateur est informé du fait que l’envoi d’une prescription au Laboratoire via l’Outil d’Aide à
                    la Prescription et au Diagnostic constitue un engagement ferme de sa part, et que toute modification
                    ou erreur doit faire l’objet d’une notification au Laboratoire dans les plus brefs et sous tout
                    moyen de contact du Laboratoire, au choix de l’Utilisateur, à l’exception de la Solution.
                    L’Utilisateur reconnaît être informé qu’il assume exclusivement les conséquences de la
                    non-modification ou de l’absence de notification envers le Laboratoire et/ou le Patient concernés.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Modalités d’accès à la Solution</div>
                <div className="eula-paragraph-content">
                    <div className="eula-paragraph-subtitle-1">Création d’un compte Utilisateur </div>
                    L'accès à la Solution nécessite la création d'un compte Utilisateur. Pour cela, l'Utilisateur doit
                    disposer d'une adresse email valide et d'un numéro RPPS.
                    <br />
                    <br />
                    Dans le cadre de la phase préparatoire du projet de partenariat avec le Laboratoire, visant à tester
                    la Solution auprès de l'Utilisateur dans une version bêta-test, le compte de l'Utilisateur est créé
                    par BYOND sur demande du Laboratoire. BYOND transmet à l'Utilisateur, à l'adresse email qu'il aura
                    communiquée au Laboratoire, ses identifiants de connexion ainsi qu'un lien provisoire invitant
                    l'Utilisateur à modifier son mot de passe avant sa première connexion à la Solution.
                    <br />
                    <br />
                    Lors de la création de son compte Utilisateur, l'Utilisateur fournit des informations vraies,
                    complètes, exactes, et à jour sur son identité, comme demandées lors de la création du compte. Il
                    s'engage notamment à ne pas créer une fausse identité de nature à induire BYOND ou les tiers en
                    erreur et à ne pas usurper l'identité ou la fonction d'une autre personne physique. L'Utilisateur
                    accepte et s'engage à maintenir immédiatement à jour et complètes ses informations en cas de
                    changement de situation ou de modification concernant les données qu'il a communiquées initialement.
                    La responsabilité de BYOND ne saurait en aucune circonstance être engagée en cas de retard ou
                    d'impossibilité de fournir la Solution du fait d'une information erronée ou incomplète dans la
                    saisie des informations concernant l'Utilisateur lors de sa demande de création de compte.
                    <br />
                    <br />
                    Dans l’hypothèse où l’Utilisateur fournirait des informations fausses, incomplètes, inexactes,
                    trompeuses, périmées, ou de nature à induire en erreur, BYOND pourra, immédiatement sans préavis ni
                    indemnité, suspendre ou résilier le compte de l’Utilisateur et lui refuser l’accès, de façon
                    temporaire ou définitive à tout ou partie de la Solution.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">Conditions d’accès à la Solution</div>
                    L'accès à la Solution s'effectue par un système d'authentification. Pour utiliser la solution,
                    l'Utilisateur doit s'authentifier à l'aide de son email, tel que communiqué par le laboratoire à
                    BYOND, ainsi qu'à l'aide du mot de passe, que l'Utilisateur aura lui même défini.
                    <br />
                    <br />
                    L'Utilisateur s'engage à créer un mot de passe sécurisé et robuste contre les tentatives de
                    déchiffrement. Le mot de passe doit comporter au moins 10 caractères dont au moins une majuscule,
                    une minuscule, un caractère spécial et un chiffre.
                    <br />
                    <br />
                    L'Utilisateur s'engage à s'assurer qu'il ne communique ce mot de passe à personne, en quelques
                    circonstances. Toute utilisation de ses Identifiants par une tierce personne est interdite.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">Confidentialité des identifiants de connexion</div>
                    Le compte Utilisateur comprend notamment ses Identifiants qui sont placés sous la responsabilité
                    exclusive de l’Utilisateur. Les identifiants de connexion de l’Utilisateur sont personnels et
                    confidentiels. L’Utilisateur devra conserver ces identifiants de connexion de manière secrète et
                    sécurisée et s’engage à ne pas les divulguer à des tiers pour quelque raison, de quelque manière et
                    sous quelque forme que ce soit. Tout accès, utilisation de la Solution et transmission de données
                    effectués à partir du compte Utilisateur d’un Utilisateur sera réputé avoir été effectué par ce
                    dernier. La sauvegarde de la confidentialité des Identifiants de l’Utilisateur relève de l’entière
                    responsabilité de ce dernier. A cet égard, l’Utilisateur s’engage à s’assurer qu’à l’issue de chaque
                    session d’utilisation de la Solution, il se déconnecte effectivement de la Solution, en particulier
                    lorsqu’il y accède aux Services à partir d’un ordinateur public.
                    <br />
                    <br />
                    Pour des raisons de sécurité, l'Utilisateur est invité à renouveler son mot de passe tous les
                    quatre-vingt-dix (90) jours, en cliquant sur le bouton « Mot de passe oublié » situé sous le
                    formulaire de saisie des identifiants de connexion.
                    <br />
                    <br />
                    En cas de perte, détournement, oubli, utilisation non autorisée ou vol de tout ou partie de ses
                    Identifiants, l’Utilisateur doit modifier son mot de passe dans les plus brefs délais, directement
                    depuis son compte Utilisateur, ou en cliquant sur le bouton « Mot de passe oublié » situé sous le
                    formulaire de saisie des identifiants de connexion. L’Utilisateur est alors invité à créer un
                    nouveau mot de passe.
                    <br />
                    <br />
                    En aucun cas, BYOND ne saurait être responsable de la perte, du vol, de l’oubli, du détournement ou
                    de l’utilisation non autorisée des éléments d’authentification de l’Utilisateur ou de leur
                    utilisation frauduleuse ainsi que de leurs conséquences qui relèvent tous de la seule responsabilité
                    de l’Utilisateur. L’Utilisateur est seul responsable de l’accès et l’utilisation de la Solution avec
                    ses éléments d’authentification, y compris l’utilisation qui est faite de ses identifiants, même si
                    l’Utilisateur préenregistre sur son ordinateur, son téléphone mobile ou sur n’importe quel autre
                    type d’équipement son Identifiant, permettant ainsi la connexion automatique à la Solution.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">Configuration minimale requise</div>
                    L’accès à la Solution nécessite que l’Utilisateur dispose d’un matériel informatique (PC, MAC ou
                    tablette), d’une connexion internet haut débit et d’un navigateur web.
                    <br />
                    <br />
                    Tous les coûts afférents à l’accès à la Solution et à son utilisation que ce soient les équipements
                    (notamment ordinateur, téléphone, moyens de communication électronique), les frais matériels,
                    logiciels ou d’accès à internet sont exclusivement à la charge de l’Utilisateur, de même que les
                    frais de communications électroniques (notamment coûts téléphoniques, coûts d’accès à Internet)
                    résultant de leur utilisation. Il appartient à l’Utilisateur de s’informer du prix d’utilisation
                    desdits équipements ou services auprès des opérateurs concernés. L’Utilisateur est seul redevable de
                    leurs prix.
                    <br />
                    <br />
                    L’Utilisateur est seul responsable du bon fonctionnement et de la sécurisation appropriée de son
                    équipement informatique ainsi que son accès internet, dans le respect des réglementations
                    applicables à sa profession. Tout logiciel téléchargé par l’Utilisateur ou obtenu de toute autre
                    manière lors de l’utilisation de la Solution, l’est aux risques et périls de l’Utilisateur.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">Disponibilité de la Solution</div>
                    BYOND met en place les moyens nécessaires à la bonne marche de la Solution. BYOND prend les mesures
                    nécessaires au maintien de la continuité et de la qualité de la Solution.
                    <br />
                    <br />
                    L’Utilisateur reconnaît que son utilisation de la Solution se fait à ses risques et périls. Les
                    Services lui sont fournis « en l’état » et sont accessibles sans aucune garantie de disponibilité et
                    de régularité. BYOND s’efforcera cependant de rendre la Solution accessible 24 heures sur 24, 7
                    jours sur 7, sauf en cas de force majeure ou un d’un évènement hors du contrôle de BYOND et sauf
                    interruption, suspension ou limitation dans le cadre d’opérations de maintenance et/ou de mises à
                    jour nécessaires au bon fonctionnement de la Solution, dans les conditions prévues au paragraphe
                    « Maintenance corrective » ci-après, des pannes éventuelles, des aléas techniques liés à la nature
                    du réseau internet, des actes de malveillance ou de toute atteinte portée au matériel ou aux
                    logiciels de BYOND.
                    <br />
                    <br />
                    BYOND n’est tenue qu’à une obligation de moyens concernant l’accessibilité, le fonctionnement et la
                    disponibilité de la Solution ou de ses contenus. BYOND se réserve la possibilité d’interrompre, de
                    suspendre ou de limiter l’accès à tout ou partie de la Solution notamment en raison de contraintes
                    d’ordre juridique ou technique.
                    <br />
                    <br />
                    BYOND ne peut être tenue responsable des perturbations du réseau Internet dues à des cas de force
                    majeure au sens de la jurisprudence de la Cour de Cassation et du fait des opérations de maintenance
                    de la Solution planifiées par BYOND. BYOND ne peut également pas être tenue responsable de
                    l’installation et du fonctionnement des terminaux utilisés par l’Utilisateur pour accéder à la
                    Solution et non fournis par BYOND.
                    <br />
                    <br />
                    Plus généralement, l’Utilisateur reconnait expressément que les suspensions, interruptions ou
                    limitations susmentionnées pourront intervenir à tout moment sans préavis et qu’elles n’ouvriront
                    droit à aucune obligation, ni indemnisation à son profit. BYOND ne pourra en aucun cas être tenue
                    pour responsable en raison d’une interruption de la Solution quel que soit la cause, la durée ou la
                    fréquence de cette interruption.
                    <br />
                    <br />
                    Les taux de transfert et les temps de réponse des informations circulant à partir de la plateforme
                    de BYOND vers Internet ne sont pas garantis par BYOND.
                    <br />
                    <br />
                    L’Utilisateur reconnaît que la vitesse de transmission des informations ne dépend pas de la Solution
                    offerts par BYOND, mais des caractéristiques inhérentes aux réseaux de communications électronique
                    et des caractéristiques techniques de son mode de connexion (câble, ADSL, 3G, 4G, 5G, etc.) et de
                    son accès internet.
                    <br />
                    <br />
                    BYOND ne saurait en aucun cas être tenue de réparer d’éventuels dommages indirects subis par
                    l’Utilisateur à l’occasion de l’utilisation ou de la disponibilité de la Solution. Les dommages
                    indirects sont ceux qui ne résultent pas exclusivement et directement de la défaillance de la
                    Solution de BYOND.
                    <br />
                    <br />
                    En cas de dysfonctionnement de quelque nature que ce soit lors de l’utilisation de la Solution ou en
                    cas de découverte d’une anomalie de fonctionnement par l’Utilisateur, celui-ci devra la notifier
                    immédiatement au Laboratoire. Pour être traitée, l’anomalie devra être décrite par l’Utilisateur de
                    manière précise et documentée.
                    <br />
                    <br />
                    La méthode de traitement des anomalies sera laissée à la discrétion de BYOND et pourra prendre la
                    forme d'une correction de programme, de la fourniture d’une solution de contournement ou de tout
                    autre moyen permettant d'éviter la reproduction de l'anomalie concernée.
                    <br />
                    <br />
                    Sans préjudice de toute autre disposition des CGU, BYOND sera dégagée de plein droit de toute
                    obligation et responsabilité au titre de la maintenance dans le cas où l’anomalie résulterait d’une
                    mauvaise utilisation de la Solution ou d’une utilisation de la Solution non-conforme aux
                    dispositions des présentes, d’une intervention de l’Utilisateur ou d’un tiers sur la Solution non
                    autorisée préalablement par écrit par BYOND, ou d’un programme informatique non fourni par BYOND.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Utilisation de la Solution</div>
                <div className="eula-paragraph-content">
                    <div className="eula-paragraph-subtitle-1">Bon usage de la Solution et capacité</div>
                    L’Utilisation de la Solution est réservée aux Professionnel de Santé capables de souscrire des
                    obligations conformément au droit français ou par lequel il a été mandaté à cet effet. Compte tenu
                    de la nature et de la finalité de la Solution, l’Utilisateur doit agir en qualité de Professionnel
                    de Santé, pour les besoins de son activité professionnelle et s'engage à n'utiliser la Solution que
                    pour les besoins de cette activité exclusivement.
                    <br />
                    <br />
                    Au sens de la Réglementation applicable à la protection des données personnelles, l’Utilisateur est
                    « destinataire » des Données Patients qui lui sont communiquées par le Laboratoire au travers de la
                    Solution. A ce titre, il s’engage à traiter les Données Patients ainsi reçues que pour les besoins
                    du suivi médical du Patient concerné, à l’exclusion de toute autre finalité.
                    <br />
                    <br />
                    De manière générale, l’Utilisateur s’engage à utiliser la Solution et les Données Patients qui lui
                    sont communiquées conformément aux CGU, dans le respect de ses obligations déontologiques et, plus
                    généralement, des lois et réglementations internationales, européennes et nationales applicables en
                    France.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">Comportements prohibés</div>
                    Il est strictement interdit d’utiliser la Solution aux fins suivantes:
                    <ul>
                        <li>
                            l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la
                            sécurité des tiers,
                        </li>
                        <li>l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,</li>
                        <li>
                            l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un ou
                            plusieurs des actes et activités décrits ci-dessus,
                        </li>
                        <li>
                            et plus généralement toute pratique détournant la Solution à des fins autres que celles pour
                            lesquelles elle a été conçue.
                        </li>
                    </ul>
                    BYOND pourra suspendre tout accès et le cas échéant résilier l’accès de l’Utilisateur à la Solution
                    en cas de non-respect du présent article, sans préjudice de tous dommages et intérêts que pourrait
                    solliciter BYOND.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Sites Tiers</div>
                <div className="eula-paragraph-content">
                    Pour le cas où la Solution renverrait ou contiendrait des liens hypertextes renvoyant vers des sites
                    internet édités par des tiers (ci-après les « Sites Tiers ») sur lesquels BYOND n'exerce aucune
                    sorte de contrôle, BYOND n'assume aucune responsabilité quant au contenu des Sites Tiers ou au
                    contenu vers lequel les Sites Tiers peuvent renvoyer. Lorsque BYOND fournit sur sa Solution des
                    détails concernant un examen de biologie médicale, ces informations sont issues de sources et de
                    Sites Tiers. Ces informations ne sont fournies par BYOND qu’à titre indicatif et par souci de
                    commodité. En aucun cas BYOND ne garantit l’exactitude, l'exhaustivité ou l’actualité de telles
                    informations. La présence de liens hypertextes vers des Sites Tiers ne saurait signifier que BYOND
                    approuve de quelque façon que ce soit les contenus des Sites Tiers. BYOND n'est responsable d'aucune
                    modification ou mise à jour concernant les Sites Tiers. BYOND n'est pas responsable de la
                    transmission d'informations à partir des Sites Tiers, ni du mauvais fonctionnement de ceux-ci.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Garanties</div>
                <div className="eula-paragraph-content">
                    BYOND garantit la confidentialité et la sécurité des Données Patients conformément aux CGU et à la
                    politique de protection des données personnelles.
                    <br />
                    <br />
                    BYOND garantit la sécurité physique et logique des serveurs sur lesquels est hébergée la Solution.
                    Elle s'engage à tout mettre en œuvre pour assurer l'intégrité du réseau et des serveurs contre tout
                    acte de malveillance extérieur ou toute attaque informatique connue. Les serveurs sont protégés
                    contre les intrusions par un firewall. Les mises à jour de sécurité des systèmes d'exploitation et
                    de l'anti-virus sont installées régulièrement.
                    <br />
                    <br />
                    BYOND ne saurait garantir l'exactitude, l'exhaustivité, l’actualité ou autre qualité des Données
                    Patients traitées par l’Utilisateur sur la Solution. L’Utilisateur est seul responsable de
                    l’intégrité, l’exactitude et la qualité des Données Patients qu’il traite sur la Solution.
                    <br />
                    <br />
                    BYOND ne consent aucune garantie expresse ou implicite, en ce y compris, sans que cette énumération
                    ne soit limitative, quant à la non violation des droits d'un tiers, la continuité, performance et/ou
                    pérennité de la Solution et/ou quant à l’aptitude à un usage particulier ou l’adéquation de la
                    Solution aux besoins de l’Utilisateur, ni ne garantit qu’elle est exempte d’anomalies, erreurs ou
                    bugs ou qu’elle fonctionnera sans panne ni interruption. La Solution est diffusée « en l’état » et
                    selon sa disponibilité.
                    <br />
                    <br />
                    L’accès à la Solution implique la connaissance et l’acceptation des caractéristiques et des limites
                    d’internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour
                    consulter, interroger ou transférer des informations, les risques d’interruption, et plus
                    généralement, les risques inhérents à toute connexion et transmission sur internet, l’absence de
                    protection de certaines données contre des détournements éventuels et les risques de contamination
                    par d’éventuels virus circulant sur le réseau. BYOND ne garantit pas que la Solution ou ses serveurs
                    seront à tout moment exempt de virus, vers, chevaux de Troie ou tout autre composant susceptible de
                    causer un dommage. Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon
                    à protéger ses propres matériels, données, et/ou logiciels stockés sur son équipement informatique
                    contre toute atteinte.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Responsabilités</div>
                <div className="eula-paragraph-content">
                    L’accès et l’utilisation de la Solution par l’Utilisateur ne sauraient en aucun cas engager la
                    responsabilité de BYOND, à quelque titre que ce soit. L’accès à la Solution et son utilisation se
                    font sous l’entière responsabilité de l’Utilisateur.
                    <br />
                    <br />
                    L'Utilisateur est seul maître de la bonne utilisation, avec discernement et esprit, de la Solution
                    et des données qu’elles contiennent et, en particulier, des Données Patients.
                    <br />
                    <br />
                    Les informations diffusées sur la Solution sont fournies par BYOND à titre strictement et
                    exclusivement informatif et indicatif. BYOND fait ses meilleurs efforts pour maintenir la Solution à
                    jour et diffuser des informations fiables, licites et validées par des experts compétents
                    (biologistes notamment). Cependant, malgré tout le soin et l’attention apportés à la sélection des
                    sources et à la rédaction des contenus et informations, BYOND ne saurait pour autant garantir
                    l’intégrité, l'exactitude, l'exhaustivité, l’actualité ou une quelconque autre qualité des
                    informations diffusées sur la Solution.
                    <br />
                    <br />
                    Aucune recommandation, aucune interprétation ni aucune information, qu'elles soient orales ou
                    écrites, obtenues par l’Utilisateur lors de l’utilisation de la Solution ne sont susceptibles de
                    créer de garanties non expressément prévues par les CGU ni d’entraîner la responsabilité de BYOND en
                    cas de dommages, de quelque nature qu’ils soient, causés à l’Utilisateur ou à des tiers du fait de
                    l’utilisation de la Solution, en violation des dispositions du présent article et plus généralement
                    du non-respect des CGU.
                    <br />
                    <br />
                    L’Utilisateur accepte et reconnait en outre expressément que le compte-rendu d’analyses d’origine
                    délivré par le Laboratoire au format HPRIM et/ou PDF, téléchargeable sur la Solution et/ou
                    disponible à tout moment auprès du Laboratoire, prévaudra en tout état de cause en cas de doute ou
                    suspicion de doute quant à la qualité et/ou la pertinence des éléments fournis par la Solution. En
                    aucun cas la responsabilité de BYOND ne pourrait être recherchée pour les informations contenues sur
                    le compte-rendu du Laboratoire au format HPRIM et/ou PDF.
                    <br />
                    <br />
                    L’Utilisateur est responsable de tout dommage survenant à son système informatique, à la Solution
                    et/ou son contenu, ainsi que, de la perte de données qui pourraient résulter de son utilisation de
                    la Solution. A ce titre, l’Utilisateur est responsable de la mise en place d’anti-virus et autres
                    mesures de sécurisation de son système informatique, afin de garantir la sécurité des Données
                    Patients conformément à l’article « Garanties » ci-avant.
                    <br />
                    <br />
                    Dans toute la mesure autorisée par la loi applicable, BYOND exclut expressément sa responsabilité
                    pour tout dommage, direct ou indirect, résultant de ou en relation avec l’accès à la Solution, son
                    utilisation, son dysfonctionnement ou son indisponibilité quelles qu’en soient la nature et la
                    durée.
                    <br />
                    <br />
                    L’Utilisateur reconnait que BYOND demeure libre de corriger et/ou modifier la Solution à tout moment
                    et sans préavis, sans que cette correction et/ou modification ne puisse ouvrir droit à un quelconque
                    recours de sa part.
                    <br />
                    <br />
                    La responsabilité de BYOND ne saurait être engagée, d’une façon générale, dans tous les cas où
                    l'inexécution ou la mauvaise exécution des CGU résulterait d'un cas de force majeure ou cas fortuit
                    indépendant de sa volonté.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Propriété intellectuelle</div>
                <div className="eula-paragraph-content">
                    La Solution est protégée par des droits de propriété intellectuelle (tels que notamment tous droits
                    d'auteur, droits sur les brevets, les marques, droit des producteurs de base de données, et tout
                    autre droit de propriété intellectuelle existant ou futur, français et internationaux) et appartient
                    à BYOND.
                    <br />
                    <br />
                    La Solution et tous les éléments qui la compose sont, sauf mentions particulières, la propriété
                    exclusive de BYOND. Toutes les marques et tous les logos appartenant à BYOND ne peuvent pas être
                    utilisés par l’Utilisateur sans le consentement préalable écrit de BYOND.
                    <br />
                    <br />
                    L’utilisation de la Solution ne confère en aucune façon à l’Utilisateur un droit de propriété et/ou
                    un droit de propriété intellectuelle sur la Solution à l’exception d’un droit personnel d’accès, non
                    exclusif et limité exclusivement à l’utilisation de la Solution conformément à son objet et ses
                    finalités et dans le respect des CGU.
                    <br />
                    <br />
                    En application des dispositions du Code de la propriété intellectuelle, des dispositions
                    législatives et réglementaires de tous pays et des conventions internationales, il est strictement
                    interdit de représenter, de reproduire et/ou d’exploiter, totalement ou partiellement, la Solution
                    ou d’un quelconque élément qui la compose, sous quelque forme et par quelque moyen que ce soit. A ce
                    titre, il est notamment interdit à l’Utilisateur de copier, modifier, assembler, décompiler,
                    altérer, traduire en toutes langues ou tous langages, vendre, louer, prêter, diffuser, distribuer ou
                    transférer tout ou partie de la Solution ou d’un quelconque élément qui la compose, mettre sur le
                    marché à titre gratuit ou onéreux, créer des œuvres dérivées à partir de ces œuvres, autoriser un
                    tiers à commettre de tels actes ou lui permettre de le faire, tout ou partie de la Solution ou des
                    Services fournis par BYOND ou d’un quelconque élément qui les compose, quel qu’en soient le moyen et
                    le support.
                    <br />
                    <br />
                    Le non-respect des dispositions du présent article constitue une violation des droits de propriété
                    intellectuelle de BYOND et/ou des tiers concédant et est susceptible d’entraîner des poursuites
                    civiles et pénales.
                </div>
            </div>
            <div className="eula-paragraph">
                <div id={'data-privacy'} className="eula-paragraph-title">
                    Politique de protection des données personnelles
                </div>
                <div className="eula-paragraph-content">
                    BYOND invite expressément l’Utilisateur à consulter sa politique de protection des données
                    personnelles qui fait partie intégrante des présentes CGU.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">
                        Protection des données personnelles de l’Utilisateur
                    </div>
                    BYOND s’engage à protéger les Données Personnelles de l’Utilisateur, enregistrées lors de son accès
                    et/ou son utilisation de la Solution, en tant que responsable de traitement conformément à la
                    Réglementation applicable à la protection des données personnelles.
                    <br />
                    <br />
                    Les Données Personnelles communiquées par l’Utilisateur sont nécessaires pour son accès à la
                    Solution et pour son utilisation ainsi que pour répondre à ses demandes et assurer le support et la
                    maintenance de la Solution. Ces informations sont strictement confidentielles et destinées
                    exclusivement à BYOND. Si l’Utilisateur ne souhaite pas communiquer les informations qui lui sont
                    demandées, il se peut que l’Utilisateur ne puisse pas accéder à certaines parties de la Solution, et
                    que BYOND soit dans l’impossibilité de répondre à sa demande.
                    <br />
                    <br />
                    BYOND s’engage à ne pas divulguer, céder, louer ou transmettre les Données Personnelles de
                    l’Utilisateur à des tiers autres que le Laboratoire et l’hébergeur désigné à l’article 9.4 ci-après,
                    conformément aux contrats qui les lie et qui ne saurait déroger au présent article et, en tout état
                    de cause à la Réglementation applicable à la protection des données personnelles.
                    <br />
                    <br />
                    Les Données Personnelles de l’Utilisateur seront conservées pendant la durée d’utilisation de la
                    Solution par l’Utilisateur et seront archivées à la clôture de son compte pendant la durée légale
                    nécessaire au respect des obligations légales de BYOND et aux fins de preuve pour la constatation,
                    l’exercice ou la défense d’un droit en justice.
                    <br />
                    <br />
                    Conformément à la Réglementation applicable à la protection des données personnelles, BYOND s'engage
                    à prendre toute précaution utile, au regard de la nature des Données Personnelles de l’Utilisateur
                    et des risques présentés par le traitement, pour en préserver la sécurité et la confidentialité et,
                    notamment, empêcher la destruction, la perte, l’altération, la divulgation ou l’accès non autorisé à
                    ces données.
                    <br />
                    <br />
                    L’Utilisateur dispose d’un droit d’accès, de rectification, de limitation et de portabilité des
                    Données Personnelles le concernant. L’Utilisateur reconnait et accepte qu’il ne dispose pas du droit
                    à l’effacement de ses données, justifié par le respect des obligations légales mises à la charge de
                    BYOND notamment en matière de règles d’accès et de traçabilité des accès aux Données Patients par
                    les Utilisateurs.
                    <br />
                    <br />
                    L’Utilisateur dispose également d’un droit d’opposition à ce que ses Données Personnelles fassent
                    l’objet d’un traitement, ainsi que du droit d’introduire une réclamation auprès de la Commission
                    Nationale de l’Informatique et des Libertés s’il considère que le traitement opéré par BYOND
                    constitue une violation de ses Données Personnelles.
                    <br />
                    <br />
                    L’Utilisateur peut exercer ses droits à tout moment auprès de BYOND par courriel adressé à l’adresse
                    suivante : <a className={"legacy-a"} href="mailto:contact@byond.bio">contact@byond.bio</a>.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-1">Protection des Données Patients</div>
                    Les Données Patients communiquées à l’Utilisateur au travers de la Solution sont strictement
                    couvertes par le secret professionnel, ce que l’Utilisateur s’engage à respecter et à faire
                    respecter aux membres de son équipe amenés à utiliser la Solution et accéder aux Données Patients
                    pour leur traitement aux fins de prise en charge du Patient.
                    <br />
                    <br />
                    Le traitement des Données Patients est sous la responsabilité du Laboratoire qui a réalisé les
                    examens de biologie médicale et fait appel à BYOND pour traiter, grâce à la Solution, les Données
                    Patients et les communiquer à l’Utilisateur. L’Utilisateur est ainsi informé que BYOND peut être
                    amenée à accéder aux Données Patients et à procéder à un traitement de celles-ci. A ce titre, BYOND
                    agit comme sous-traitant du Laboratoire, au sens de la Réglementation applicable à la protection des
                    données personnelles et, à ce titre, s’engage à respecter l’ensemble des obligations mises à sa
                    charge au sein du contrat qui le lie au Laboratoire, ces obligations étant en tout état de cause
                    conformes à la Réglementation applicable à la protection des données personnelles et, en
                    particulier, à l’article 28 du RGPD.
                    <br />
                    <br />
                    En aucun cas la responsabilité de BYOND ne saurait être engagée en cas de non-respect par le
                    Laboratoire de ses obligations en tant que responsable de traitement.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-2">Hébergement de la Solution</div>
                    Dans le respect de l’article L. 1111-8 du code de la santé publique, la Solution, ainsi que les
                    Données Patients, sont hébergées sur des serveurs dédiés mis à disposition de BYOND par un hébergeur
                    certifié de données de santé, la société GOOGLE CLOUD SERVICES (certificat HDS n°FR053558). Cet
                    hébergeur est astreint au secret professionnel dans les conditions et sous les peines prévues à
                    l’article 226-13 du code pénal.
                    <br />
                    <br />
                    Conformément à la Réglementation applicable à la protection des données personnelles, cet hébergeur
                    agit en tant que sous-traitant ultérieur du Laboratoire, seulement sur instruction écrite du
                    Laboratoire retranscrites dans le contrat conclu entre BYOND et l’hébergeur. Ce dernier ne dispose
                    pas du droit d'utiliser les Données Patients qu’il héberge, sauf aux fins d'exécution des
                    prestations techniques d'hébergement et de gestion des bases de données et seulement dans les
                    conditions contractuelles signées entre l'hébergeur et BYOND, dans le respect de la Réglementation
                    applicable à la protection des données personnelles.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-2">Sécurisation de la Solution</div>
                    L’Utilisateur est informé que la Solution mise à disposition par BYOND répond aux exigences de la
                    Réglementation applicable à la protection des données personnelles, en particulier s’agissant des
                    mesures de sécurité prises pour la protection des Données Personnelles de Santé contenues dans les
                    Données Patients.
                    <br />
                    <br />
                    BYOND met en œuvre toutes les mesures techniques et organisationnelles afin d’assurer la sécurité
                    des traitements de données à caractère personnel et la confidentialité des Données Personnelles.
                    <br />
                    <br />
                    A ce titre, BYOND prend toutes les précautions utiles, au regard de la nature des données et des
                    risques présentés par le traitement, afin de préserver la sécurité des données et, notamment,
                    d’empêcher qu’elles soient déformées, endommagées, ou que des tiers non autorisés y aient accès
                    (protection physique des locaux, procédés d’authentification avec accès personnel et sécurisé via
                    des identifiants et mots de passe confidentiels, journalisation des connexions, chiffrement de
                    certaines données…).
                    <br />
                    <br />
                    L’ensemble des accès à la Solution, ainsi que les actions menées par l’Utilisateur sur les Données
                    Patients sont tracés et consignés quotidiennement en base de données.
                    <br />
                    <br />
                    L’ensemble des accès à la Solution, ainsi que les actions menées par l’Utilisateur sur les Données
                    Patients sont tracés et consignés quotidiennement en base de données.
                    <br />
                    <br />
                    <div className="eula-paragraph-subtitle-2">
                        Sort des Données Patients au terme de l’utilisation de la Solution
                    </div>
                    Au terme des CGU pour quelque raison que ce soit, le compte Utilisateur sera inactivé. BYOND
                    restituera à l’Utilisateur qui en fait la demande les Données Patients traitées sur la Solution.
                    <br />
                    <br />
                    BYOND archivera une copie des Données Patients dans des conditions de sécurité et de confidentialité
                    conformes à la Réglementation applicable à la protection des données personnelles, aux fins de
                    preuve pour la constatation, l’exercice ou la défense d’un droit en justice.
                    <br />
                    <br />
                    L’Utilisateur est informé qu’une copie des Données Patients sera également archivée aux fins de
                    permettre, pour les Patients qui ne s’y seront pas opposés, leur réutilisation à des fins
                    secondaires de recherche par le Laboratoire et/ou BYOND, dans les conditions et selon les modalités
                    prévues par la réglementation applicable à ce type de recherche. L’Utilisateur est informé qu’une
                    information spécifique en ce sens a été donnée aux Patients par le Laboratoire et BYOND,
                    conformément à la réglementation précitée.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Confidentialité</div>
                <div className="eula-paragraph-content">
                    L'Utilisateur s'engage à ne pas enregistrer, copier ou diffuser tout contenu ou extrait de contenu
                    en lien avec la Solution ou les Services quel qu'en soit le moyen, le support, le procédé ou la
                    finalité. L'Utilisateur est informé que toute atteinte au droit à l’image, au respect de la vie
                    privé ou au secret professionnel et médical peut faire l’objet de sanctions, y compris pénales.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Durée, suspension et résiliation</div>
                <div className="eula-paragraph-content">
                    Les CGU entrent en vigueur à compter de leur acceptation par l’Utilisateur et pour toute la durée
                    d’accès et d’utilisation de la Solution par l’Utilisateur.
                    <br />
                    <br />
                    L’Utilisateur peut à tout moment résilier la souscription de son compte Utilisateur en contactant
                    BYOND ou le Laboratoire.
                    <br />
                    <br />
                    En cas d’utilisation de la Solution par l’Utilisateur non conforme aux CGU, en cas de manquement de
                    l’Utilisateur aux CGU, ou plus généralement en cas de violation des lois et réglementations
                    applicables, BYOND pourra suspendre ou résilier de plein droit, sans mise en demeure préalable, sans
                    préavis ni indemnité, tout ou partie de l’accès de l’Utilisateur à la Solution. BYOND pourra
                    prononcer cette suspension ou résiliation sans préjudice de tous les autres droits, actions et
                    recours dont BYOND pourrait disposer en vue de la réparation du préjudice qu’elle pourrait avoir
                    subi du fait de ces manquements.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Territorialité</div>
                <div className="eula-paragraph-content">
                    La Solution s’adresse aux Utilisateurs ayant une activité professionnelle située en France. En
                    effet, les législations, réglementations et pratiques médicales, ainsi que la législation nationale
                    sur la protection des données personnelles de santé, peuvent être différentes dans les autres pays
                    que la France et nécessiter des adaptations de l’utilisation de la Solution. BYOND ne saurait être
                    responsable en cas de violation par l’Utilisateur de la législation nationale.
                </div>
            </div>
            <div className="eula-paragraph">
                <div className="eula-paragraph-title">Droit applicable et juridictions compétentes</div>
                <div className="eula-paragraph-content">
                    Les CGU seront exécutées et interprétées conformément au droit français. Les parties déclarent leur
                    intention de chercher une solution amiable à toute difficulté qui pourrait surgir à propos de la
                    validité, de l’interprétation ou de l’exécution des CGU. Dans les limites permises par la loi, en
                    cas de désaccord persistant, le litige sera soumis au tribunal de grande instance de Marseille.
                </div>
            </div>
        </div>
    );
};

export default EulaCintentV1;
