import { shareReport } from "actions/medicalReports/MedicalReportsFetchActions";
import Form, { FormState } from "components/commonDesign/form/tmpNewSystem/Form";
import FormInput from "components/commonDesign/form/tmpNewSystem/FormInput";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import SelectionCard, { SelectionCardWidth } from "components/designSystem/components/cards/SelectionCard";
import { RightIconType } from "components/designSystem/components/TextField";
import Column, { ColumnWidthInREM } from "components/designSystem/containers/Column";
import Inline, { AlwaysInline, InlineJustifyContent, InlineSpacing } from "components/designSystem/containers/Inline";
import {
    LargeSpacer,
    MediumSpacer,
    SmallSpacer,
    XLargeSpacer,
    XSmallSpacer
} from "components/designSystem/containers/Spacer";
import { CenteredText } from "components/designSystem/containers/TextJustifier";
import {
    TCaption,
    TDisplaySmall700,
    TLabel,
    TLabel500,
    TOverline,
    TypographyColor
} from "components/designSystem/containers/Typography";
import { MarginSize, WithRightMargin } from "components/designSystem/containers/WithMargin";
import Icon from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import APIFeedbackMessageContainer from "components/pages/common/APIFeedbackMessage";
import { TransDomain } from "components/pages/common/MainComponent";
import { aggregate, required, validateEmail } from "components/utils/form/tmpNewSystem/validators";
import { capitalizeFirstLetter } from "core/utils/text";
import { translationMethod } from "core/utils/trans";
import { Record } from "immutable";
import { RecipientType } from "models/medicalReport/ReportAccessModel";
import React, { FC, Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import MediaQuery from "react-responsive";

const FEEDBACK_IDENTIFIER_SHARE_REPORT = 'FEEDBACK_IDENTIFIER_SHARE_REPORT'

interface ShareReportProps {
    reportUUID: string
}


function trans(key: string, step?: number, domain?: string): string {
    const baseKey: string = step ? "step_" + step + '.' : ''
    // @ts-ignore
    return translationMethod(
        baseKey + key,
        {},
        domain ?? TransDomain.SHARE_REPORT_FEATURE,
        capitalizeFirstLetter
    )
}

const ShareReport: FC<ShareReportProps> = ({
    ...props
}): JSX.Element => {
    const dispatch = useDispatch();
    const [selectedRecipientType, setSelectedRecipientType] = useState(RecipientType.HEALTHCARE_PROFESSIONAL);
    const [currentStep, setCurrentStep] = useState(1);
    const [addMessageClicked, setAddMessageClicked] = useState(false);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [shouldDisableSubmitButton, setShouldDisableSubmitButton] = useState(false);

    const backButton = <Button
        variant={ButtonVariant.TERTIARY}
        size={ButtonSize.SMALL}
        icons={{left: IconName.CHEVRON_LEFT}}
        onClick={(): void => {
            setCurrentStep(currentStep - 1)
            setShouldDisableSubmitButton(false)
        }}
    >
        {trans('back')}
    </Button>

    function buildFirstStepContent (isMobile: boolean) : JSX.Element {
        const cards = <Fragment>
            <SelectionCard
                title={trans('healthcare_option.title', currentStep)}
                description={trans( 'healthcare_option.description', currentStep)}
                icon={IconName.STETHOSCOPE}
                radioButtonGroupName={'recipientType'}
                isSelected={selectedRecipientType === RecipientType.HEALTHCARE_PROFESSIONAL}
                onClick={() : void => setSelectedRecipientType(RecipientType.HEALTHCARE_PROFESSIONAL)}
                width={!isMobile ? SelectionCardWidth.WIDTH_270 : undefined}
            />
            <SelectionCard
                title={trans('other_option.title', currentStep)}
                description={trans('other_option.description', currentStep)}
                icon={IconName.USER}
                radioButtonGroupName={'recipientType'}
                isSelected={selectedRecipientType === RecipientType.OTHER}
                onClick={() : void => setSelectedRecipientType(RecipientType.OTHER)}
                width={!isMobile ? SelectionCardWidth.WIDTH_270 : undefined}
            />
        </Fragment>
        return <LargeSpacer>
            {
                isMobile ? <MediumSpacer>
                    {cards}
                </MediumSpacer> : <Inline spacing={InlineSpacing.X_LARGE}>
                    {cards}
                </Inline>
            }
            <Inline
                justifyContent={InlineJustifyContent.FLEX_END}
            >
                <Button
                    size={ButtonSize.LARGE}
                    fullWidth={isMobile}
                    onClick={() : void => setCurrentStep(currentStep + 1)}
                >
                    {trans( 'continue', currentStep)}
                </Button>
            </Inline>
        </LargeSpacer>
    }

    function buildSecondStepContent (isMobile: boolean): JSX.Element {
        return <Form
            onSubmit={(data: Record<string, string>) : void => {
                setEmail(data['email'])
                setMessage(data['message'])
                setCurrentStep(currentStep+1)
            }}
        >
            {(formState: FormState): JSX.Element => {
                return <XLargeSpacer>
                    <SmallSpacer>
                        <FormInput
                            name={'email'}
                            field={
                                {
                                    label: trans('email', undefined, TransDomain.ACCESS),
                                    type: "text",
                                    required: true,
                                    defaultValue: email
                                }
                            }
                            validate={
                                aggregate(required('email'), validateEmail())
                            }
                        />
                        {
                            (!addMessageClicked && !message)? <Button
                                    id={"add-message-button"}
                                    size={ButtonSize.MEDIUM}
                                    variant={ButtonVariant.TERTIARY}
                                    onClick={() : void => setAddMessageClicked(true)}
                                >
                                    {trans( 'add_message', currentStep)}
                                </Button> : <FormInput
                                name={'message'}
                                field={
                                    {
                                        label: trans('message', currentStep),
                                        type: "textarea",
                                        required: false,
                                        defaultValue: message,
                                        rightIcon: {
                                            type: RightIconType.ERROR_ON_EMPTY,
                                            icon: IconName.TRASH,
                                            onClick: () : void => {
                                                setMessage(undefined)
                                                setAddMessageClicked(false)
                                            }
                                        }
                                    }
                                }
                            />
                        }
                    </SmallSpacer>
                    <Inline
                        justifyContent={InlineJustifyContent.FLEX_END}
                    >
                        <Button
                            size={ButtonSize.LARGE}
                            fullWidth={isMobile}
                            disabled={formState.invalid}
                            isSubmit
                        >
                            {trans( 'send', currentStep)}
                        </Button>
                    </Inline>
                </XLargeSpacer>
            }}
        </Form>
    }

    function buildThirdStepContent (): JSX.Element {
        return <SmallSpacer>
            <LargeSpacer>
                <TLabel500 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    {trans('first_line', currentStep)}
                </TLabel500>
                <AlwaysInline spacing={InlineSpacing.SMALL}>
                    <Icon size={IconSize.EXTRA_SMALL} name={IconName.USER} color={IconColor.DEFAULT}/>
                    <XSmallSpacer>
                        <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
                            {trans('sub_title_1', currentStep)}
                        </TLabel>
                        <TCaption color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            {trans('description_1', currentStep)}
                        </TCaption>
                    </XSmallSpacer>
                </AlwaysInline>
                <AlwaysInline spacing={InlineSpacing.SMALL}>
                    <Icon size={IconSize.EXTRA_SMALL} name={IconName.DOWNLOAD} color={IconColor.DEFAULT}/>
                    <XSmallSpacer>
                        <TLabel color={TypographyColor.COLOR_TEXT_DEFAULT}>
                            {trans('sub_title_2', currentStep)}
                        </TLabel>
                        <TCaption color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            {trans('description_2', currentStep)}
                        </TCaption>
                    </XSmallSpacer>
                </AlwaysInline>
                <CenteredText>
                    <TOverline color={TypographyColor.COLOR_TEXT_SUBDUED}>
                        {trans('overline', currentStep)}
                    </TOverline>
                </CenteredText>
            </LargeSpacer>
            <Button
                size={ButtonSize.LARGE}
                fullWidth
                disabled={shouldDisableSubmitButton}
                onClick={() : void => {
                    if (email)(
                        dispatch(
                                shareReport(
                                    props.reportUUID,
                                    selectedRecipientType,
                                    email,
                                    message,
                                    FEEDBACK_IDENTIFIER_SHARE_REPORT
                                )
                            )
                    )
                    setShouldDisableSubmitButton(true)
                }}
            >
                {trans('confirm', currentStep)}
            </Button>
            <APIFeedbackMessageContainer identifier={FEEDBACK_IDENTIFIER_SHARE_REPORT} withNewDesignSystem/>
        </SmallSpacer>
    }

    function buildContent (isMobile: boolean) : JSX.Element {
        return <LargeSpacer>
            {currentStep > 1 && backButton}
            {/*todo: Improve solution. WithRightMargin is added for close button (absolute position).*/}
            <WithRightMargin margin={MarginSize.LARGE}>
                <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    {trans( 'title', currentStep)}
                </TDisplaySmall700>
            </WithRightMargin>
            {currentStep === 1 && buildFirstStepContent(isMobile)}
            {currentStep === 2 && buildSecondStepContent(isMobile)}
            {currentStep === 3 && buildThirdStepContent()}
        </LargeSpacer>
    }

    return <Fragment>
        <MediaQuery minWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
            {
                (match: boolean): JSX.Element => match ? <Column widthInRem={ColumnWidthInREM.WIDTH_40}>
                    {buildContent(false)}
                </Column> : buildContent(true)
            }
        </MediaQuery>
    </Fragment>
}


export default ShareReport;
