import classNames from "classnames";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import { ColorMap } from "components/designSystem/Aliases";
import { ButtonVariant } from "components/designSystem/components/Button";
import Image from "components/designSystem/components/Image";
import Box, { BackgroundGradientColor, BoxColor } from "components/designSystem/containers/Box";
import { AlwaysInline, Grow } from "components/designSystem/containers/Inline";
import { TypographyColor } from "components/designSystem/containers/Typography";
import { ImageInfo } from "core/content/cdn";
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styles from "./LayoutCard.module.scss";

type StyleMap = {
    boxColor: BoxColor;
    buttonVariant: ButtonVariant;
    typographyColor: TypographyColor;
    backgroundGradientColor?: BackgroundGradientColor
};

export enum ImageProfile {
    /**
     * Image is stretched to fill entire background behind the content.
     */
    BACKGROUND_STRETCH = 'BACKGROUND_STRETCH',
    /**
     * Image is shrunk to fit inside the background, behind the content.
     */
    BACKGROUND_FIT = 'BACKGROUND_ILLUSTRATION',
    /**
     * On desktop, image is fit inline next to the content.
     *
     * On mobile, this behaves like [BACKGROUND_FIT].
     */
    ILLUSTRATION = 'ILLUSTRATION'
}

interface LayoutCardProps {
    children?: React.ReactNode;
    color?: BoxColor;
    image?: ImageInfo;
    imageProfile?: ImageProfile;
    // todo : rename overlay, not a gradient
    backgroundGradientColor?: BackgroundGradientColor;
    imageDisplay?: {
        withoutMargin?: boolean,
        heightSizingInPx?: number
    }
}

export const LayoutCard = ({
                               children,
                               image,
                               color = BoxColor.WHITE,
                               imageProfile = ImageProfile.ILLUSTRATION,
                               backgroundGradientColor,
                               imageDisplay
                           }: LayoutCardProps): JSX.Element => {
    const isDesktop = useMediaQuery({minWidth: TABLET_MAX_WIDTH_ACCESSIBLE});
    if (imageProfile === ImageProfile.BACKGROUND_STRETCH) {
        return <Box
            background={image ? {imageUrl: image.src, gradient: backgroundGradientColor} : color ? color : BoxColor.WHITE} withFullHeight withRadius withPadding>
            {children}
        </Box>;
    }

    if (isDesktop && imageProfile === ImageProfile.ILLUSTRATION) {
        return (<Box background={color ? color : BoxColor.WHITE} withFullHeight withPadding withRadius>
            <AlwaysInline withFullHeight>
                <Grow>{children}</Grow>
                {image && (<Image alt={image.alt} src={image.src} overrideStyle={
                    {
                        maxHeight: imageDisplay && imageDisplay.heightSizingInPx ? `${imageDisplay.heightSizingInPx}px` : "200px"
                    }
                }/>)}
            </AlwaysInline>
        </Box>)
    }
    return (<Box background={color ? color : BoxColor.WHITE} withFullHeight withRadius>
        <div className={
            classNames(
                {
                    [styles.illustrationCardMobile]: true,
                    [styles.illustrationCardMobileWithMargin]: !imageDisplay?.withoutMargin
                }
            )
        } style={{backgroundImage: image ? `url(${image.src})` : ''}}>
            <div className={classNames(
                {
                    [styles.illustrationContent]: true,
                    [styles.illustrationContentOverlayDarkGreyBlue]: backgroundGradientColor == BackgroundGradientColor.DARK_GREY_BLUE,
                    [styles.illustrationContentOverlayDarkBlue]: backgroundGradientColor == BackgroundGradientColor.DARK_BLUE,
                    [styles.illustrationContentOverlayBlack]: backgroundGradientColor == BackgroundGradientColor.BLACK,
                }

            )}>
                <Grow>{children}</Grow>
            </div>
        </div>
    </Box>)
};

export function mapLayoutColorToStyles(layoutColor: ColorMap): StyleMap {
    switch (layoutColor) {
        case ColorMap.BLUE:
            return {
                boxColor: BoxColor.BLUE,
                buttonVariant: ButtonVariant.TERTIARY,
                typographyColor: TypographyColor.COLOR_TEXT_ON_PRIMARY,
                backgroundGradientColor: BackgroundGradientColor.DARK_BLUE,
            };
        case ColorMap.GRADIENT_DARK_BLUE:
            return {
                boxColor: BoxColor.GRADIENT_DARK_BLUE,
                buttonVariant: ButtonVariant.TERTIARY,
                typographyColor: TypographyColor.COLOR_TEXT_ON_PRIMARY,
                backgroundGradientColor: BackgroundGradientColor.DARK_BLUE,
            };


        case ColorMap.GRADIENT_ORANGE:
            return {
                boxColor: BoxColor.GRADIENT_ORANGE,
                buttonVariant: ButtonVariant.TERTIARY,
                typographyColor: TypographyColor.COLOR_TEXT_ON_PRIMARY,
                backgroundGradientColor: BackgroundGradientColor.BLACK,

            };

        // You can add more cases for different colors as needed.
        default:
            return {
                boxColor: BoxColor.WHITE,
                buttonVariant: ButtonVariant.PRIMARY,
                typographyColor: TypographyColor.COLOR_TEXT_DEFAULT,
            }
    }
}