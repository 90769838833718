import { KiroCommunicationType } from "components/modules/user/KiroCommunication";
import { LandingScreen } from "components/modules/user/LandingScreen";
import { TransDomain } from "components/pages/common/MainComponent";
import { buildConnectionsModeComponent } from "components/utils/builders/ConnectionModesBuilder";
import { capitalizeFirstLetter } from "core/utils/text";
import { translationMethod } from "core/utils/trans";
import { ConnectionMode } from "models/medicalReport/ConnectionModesModel";
import { UserMode } from "models/user/UserModels";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";

interface LocationState {
    defaultConnectionMode: ConnectionMode;
}

export const AccessByConnectionModes = (): JSX.Element => {
    const domain = TransDomain.MEDICAL_REPORT + '.access';
    const {state} = useLocation<LocationState>();

    if (state === undefined || state.defaultConnectionMode === undefined) {
        return <Fragment/>
    }

    const content = buildConnectionsModeComponent(
        state.defaultConnectionMode,
        translationMethod(
            'default.' + state.defaultConnectionMode.type.valueOf() + '.title', undefined, domain, capitalizeFirstLetter
        ),
        undefined,
        undefined,
        undefined,
        translationMethod('psc_section_signup', undefined, TransDomain.ACCESS, capitalizeFirstLetter)
    )

    const communicationType = state.defaultConnectionMode.user_type == UserMode.DOCTOR ?
        KiroCommunicationType.DOCTOR : KiroCommunicationType.PATIENT

    return <LandingScreen communicationType={communicationType}>
        {content}
    </LandingScreen>
}