export const FRENCH_CODE = 33

export function formatPhoneNumber(input: string | undefined, country_code?: number) : string {
    const used_country_code = country_code || FRENCH_CODE

    if(input === undefined || input === ''){
        return ''
    }

    if (used_country_code === FRENCH_CODE) {
        const inputWithoutSpace = input.replace(/ /g, '');

        if(inputWithoutSpace == '0'){
            return '+33'
        }

        if (['+', '+3', '+33'].includes(inputWithoutSpace)) {
            return inputWithoutSpace
        }
        const match = inputWithoutSpace.match(/^(?:\+3?3?|0)([0-9]{0,9})$/)

        if (match && match.length > 1) {
            return ('+33 ' + (match[1][0] ? match[1][0] + " " : "") + (match[1].slice(1).replace(/\d{2}/g, '$& '))).trim()
        }

        return inputWithoutSpace
    }
    return input
}

export function formatDate(input: string | undefined, previousValue?: string) : string {
    if (!input){
        return '';
    }

    if(previousValue && previousValue.endsWith('/') && (previousValue.length - input.length === 1)){
        return input;
    }

    if (input.split(/\//g).length > 2){
        return input;
    }

    const valuesWithoutSeparator = input.replace(/\//g, '');

    if (valuesWithoutSeparator.length <= 1){
        return valuesWithoutSeparator;
    }

    return valuesWithoutSeparator
        .replace(/^\d{4}/, '$&/')
        .replace(/^\d{2}/, '$&/');
}