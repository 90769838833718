import TrText from 'components/common/TrText';
import FormInput from 'components/commonDesign/form/tmpNewSystem/FormInput';
import { CountryFlagName } from 'components/designSystem/assets/countryData';
import { RightIconType } from "components/designSystem/components/TextField";
import { IconName } from 'components/designSystem/foundations/IconsData';
import { TransDomain } from 'components/pages/common/MainComponent';
import { formatDate, formatPhoneNumber, FRENCH_CODE } from 'components/utils/form/tmpNewSystem/formatters';
import { checkDateInput, checkPhoneNumberInput } from 'components/utils/form/tmpNewSystem/inputCheckers';
import {
    aggregate,
    dateFormat,
    password,
    phoneFormat,
    regexFrenchDate,
    required,
} from 'components/utils/form/tmpNewSystem/validators';
import LOGGER from 'core/logging/Logger';
import { formatDate as dateFormatting } from 'core/utils/Date';

import { cast } from 'core/utils/Typed';

import {
    DatePayload,
    FormItemType,
    FormItemWithPayload,
    PasswordPayload,
    TelPayload,
    TextPayload,
} from 'models/common/DynamicFormModels';
import { APIText } from 'models/common/message';
import moment from 'moment';
import React, { Fragment, ReactElement } from 'react';
import { useTranslation } from "react-i18next";

function translateLabel(label?: APIText | string): string | JSX.Element {
    return !label ? '' : <TrText input={label} />;
}

export const TextItem = (textPayload: TextPayload): JSX.Element => (
    <FormInput
        name={textPayload.name}
        field={{
            label: translateLabel(textPayload.label),
            type: textPayload.type,
            disabled: textPayload.editable !== undefined && !textPayload.editable,
            required: textPayload.is_required,
            defaultValue: textPayload.default_value,
            autoCompletion: textPayload.auto_completion,
        }}
        validate={textPayload.is_required ? required(textPayload.type) : undefined}
    />
);

export function PasswordItem(
    {defaultValue, onClick, ...passwordPayload}: PasswordPayload,
): JSX.Element {
    return (
        <FormInput
            name={passwordPayload.name}
            field={{
                label: translateLabel(passwordPayload.label),
                type: passwordPayload.type,
                defaultValue: defaultValue,
                required: passwordPayload.is_required,
                autoCompletion: passwordPayload.auto_completion,
                onClick: onClick,
                rightIcon: onClick ? { onClick: () => undefined, icon: IconName.CHEVRON_RIGHT, type: RightIconType.ERROR_ON_EMPTY } : undefined,
            }}
            validate={aggregate(
                passwordPayload.is_required ? required('password') : null,
                password(),
            )}
        />
    );
}

export function postFormatDate(dateFormatAndPlaceholder: string): (value?: string) => string {
    return (value?: string): string => {
        if (value === undefined) {
            return '';
        }

        if (
            !['DD/MM/YYYY', 'JJ/MM/AAAA'].includes(dateFormatAndPlaceholder) ||
            !value.toString().match(regexFrenchDate)
        ) {
            return value;
        }

        // moment only understand english terms
        const realDateFormatAndPlaceholder =
            dateFormatAndPlaceholder === 'JJ/MM/AAAA' ? 'DD/MM/YYYY' : dateFormatAndPlaceholder;

        const isoFormat = 'YYYY-MM-DD';
        try {
            // KeepOffset is set to True otherwise toISOString shift the date by one day
            return moment(value, [realDateFormatAndPlaceholder, isoFormat]).toISOString(true).substring(0, 10);
        } catch (e) {
            return value;
        }
    };
}

export const DateItem = (datePayload: DatePayload): ReactElement => {
    const {t} = useTranslation();
    const dateFormatAndPlaceholder = t(`${TransDomain.ACCOUNT}.date_placeholder`)
    return <FormInput
        name={datePayload.name}
        field={{
            label: translateLabel(datePayload.label),
            type: "text",
            required: datePayload.is_required,
            // todo: should be done with a library and according to it18 code (fr / en)
            placeholder: dateFormatAndPlaceholder,
            disabled: datePayload.editable != null && !datePayload.editable,
            defaultValue: datePayload.default_value ? dateFormatting(datePayload.default_value) : '',
            formatMethod: (input: string, previousValue?: string): string =>
                formatDate(input, previousValue),
            checkMethod: (newValue: string): boolean => checkDateInput(newValue),
            showPlaceHolder: true,
            autoCompletion: datePayload.auto_completion,
        }}
        validate={aggregate(
            datePayload.is_required ? required(datePayload.type) : null,
            dateFormat(dateFormatAndPlaceholder, datePayload.maxDate),
        )}
        postFormat={postFormatDate(dateFormatAndPlaceholder)}
    />
}

export const TelItem = (telPayload: TelPayload): JSX.Element => {
    const {t} = useTranslation();
    const description = t(`${TransDomain.ACCOUNT}.phone_number_description`)

    //todo : temporary system making sure only french code is accepted
    if (telPayload.default_value && telPayload.default_value.country_code !== FRENCH_CODE) {
        LOGGER.warning('API is sending other country code for phone : ' + telPayload.default_value.country_code);
        return <Fragment />;
    }

    return <FormInput
        name={telPayload.name}
        field={{
            label: translateLabel(telPayload.label),
            type: telPayload.type,
            required: telPayload.is_required,
            // todo: should be done with a library and according to country code given by the API
            placeholder: formatPhoneNumber('0000000000', telPayload.default_value?.country_code),
            defaultValue: formatPhoneNumber(
                telPayload.default_value &&
                telPayload.default_value.country_code &&
                telPayload.default_value.national_number
                    ? '+' + telPayload.default_value.country_code + telPayload.default_value.national_number
                    : undefined,
                telPayload.default_value?.country_code,
            ),
            formatMethod: (input: string): string =>
                formatPhoneNumber(input, telPayload.default_value?.country_code),
            checkMethod: (newValue: string): boolean =>
                checkPhoneNumberInput(newValue, telPayload.default_value?.country_code),
            leftIconOrFlag: { flag: CountryFlagName.FRANCE },
            showPlaceHolder: true,
            description: description,
            autoCompletion: telPayload.auto_completion ?? 'tel',
        }}
        validate={aggregate(
            telPayload.is_required ? required(telPayload.type) : null,
            phoneFormat(telPayload.default_value?.country_code),
        )}
    />
};

export const FormItem = ({formItem}: {formItem: FormItemWithPayload}): JSX.Element => {
    let _item: JSX.Element | null;
    switch (formItem.type) {
        case FormItemType.TEXT:
            _item = <TextItem {...cast<TextPayload>(formItem.payload)}/>;
            break
        case FormItemType.PASSWORD:
            _item = <PasswordItem {...cast<PasswordPayload>(formItem.payload)}/>;
            break;
        case FormItemType.DATE:
            _item = <DateItem {...cast<DatePayload>(formItem.payload)}/>;
            break;
        case FormItemType.TEL:
            _item = <TelItem {...cast<TelPayload>(formItem.payload)}/>;
            break;
        default:
            _item = null
    }

    return <div key={formItem.key}>{_item}</div>;
};
