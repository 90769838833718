import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import * as React from 'react';

const FillBurger = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.SMALL:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.25 0C18.3921 0 21.75 3.35786 21.75 7.5V9.75H2.25V7.5C2.25 3.35786 5.60786 0 9.75 0H14.25Z"
                        fill="currentColor"/>
                    <path
                        d="M0.375 12C0.375 11.3787 0.87868 10.875 1.5 10.875H22.5C23.1213 10.875 23.625 11.3787 23.625 12C23.625 12.6213 23.1213 13.125 22.5 13.125H1.5C0.87868 13.125 0.375 12.6213 0.375 12Z"
                        fill="currentColor"/>
                    <path
                        d="M2.25 16.5L2.25 14.25L12 14.25L15 15.75L18 14.25H21.75V16.5C21.75 20.6421 18.3921 24 14.25 24L9.75 24C5.60787 24 2.25 20.6421 2.25 16.5Z"
                        fill="currentColor"/>
                </svg>
            )
        default:
            return <div/>
    }
};

export default FillBurger;