import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Chart = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-chart'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_147_1168)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M14 0.25C13.0335 0.25 12.25 1.0335 12.25 2C12.25 2.44805 12.4184 2.85677 12.6953 3.16634L10.1317 9.25488C10.0882 9.25165 10.0443 9.25 10 9.25C9.86382 9.25 9.73127 9.26556 9.60403 9.29499L7.58011 5.75312C7.68902 5.52501 7.75 5.26963 7.75 5C7.75 4.0335 6.9665 3.25 6 3.25C5.0335 3.25 4.25 4.0335 4.25 5C4.25 5.36835 4.3638 5.71011 4.55817 5.99206L1.92254 12.2517C0.991982 12.2922 0.25 13.0595 0.25 14C0.25 14.9665 1.0335 15.75 2 15.75C2.9665 15.75 3.75 14.9665 3.75 14C3.75 13.5521 3.58172 13.1435 3.30492 12.8339L5.86862 6.74514C5.91199 6.74836 5.95581 6.75 6 6.75C6.13632 6.75 6.26901 6.73441 6.39636 6.70492L8.4201 10.2465C8.31105 10.4747 8.25 10.7302 8.25 11C8.25 11.9665 9.0335 12.75 10 12.75C10.9665 12.75 11.75 11.9665 11.75 11C11.75 10.6318 11.6363 10.2901 11.442 10.0083L14.0778 3.7483C15.0082 3.70759 15.75 2.94042 15.75 2C15.75 1.0335 14.9665 0.25 14 0.25Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_147_1168">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-chart'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M20 1C18.3431 1 17 2.34315 17 4C17 4.88313 17.3816 5.67713 17.9889 6.22612L15.1646 13.0044C15.1101 13.0015 15.0552 13 15 13C14.6873 13 14.3857 13.0479 14.1022 13.1366L11.4971 9.66318C11.8148 9.18717 12 8.61521 12 8C12 6.34315 10.6569 5 9 5C7.34315 5 6 6.34315 6 8C6 8.88313 6.3816 9.67713 6.9889 10.2261L4.1646 17.0044C4.11011 17.0015 4.05523 17 4 17C2.34315 17 1 18.3431 1 20C1 21.6569 2.34315 23 4 23C5.65685 23 7 21.6569 7 20C7 19.1167 6.61827 18.3226 6.01078 17.7736L8.83497 10.9955C8.88961 10.9985 8.94463 11 9 11C9.3126 11 9.61402 10.9522 9.89737 10.8635L12.5026 14.3372C12.1851 14.8131 12 15.3849 12 16C12 17.6569 13.3431 19 15 19C16.6569 19 18 17.6569 18 16C18 15.1167 17.6183 14.3226 17.0108 13.7736L19.835 6.99554C19.8896 6.9985 19.9446 7 20 7C21.6569 7 23 5.65685 23 4C23 2.34315 21.6569 1 20 1Z"
                  fill="currentColor"/>
        </svg>
    )
}