import { ButtonType } from 'components/core/buttons/Button';
import MainComponent, { MainComponentProps, setupRaw, TransDomain } from 'components/pages/common/MainComponent';
import 'components/pages/errors/Errors.scss';
import ReturnButton from 'components/utils/navigation/ReturnButton';
import { ROUTE_DEFAULT_MODULAR } from 'core/routing/Routes';
import React, { ReactNode } from 'react';

export type Page404Props = MainComponentProps;
class Page404 extends MainComponent<Page404Props> {
    TRANS_SUFFIX = TransDomain.MESSAGES + '.default';
    render(): ReactNode {
        return (
            <div className={'errorPage'}>
                <div className={'errorPage-title'}>{this.trans('OOPS')}</div>
                <div className={'errorPage-subtitle'}>{this.trans('NOTFOUND')}</div>
                <ReturnButton type={ButtonType.WHITE} path={ROUTE_DEFAULT_MODULAR} />
            </div>
        );
    }
}

export default setupRaw(Page404);
