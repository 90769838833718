import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import * as React from 'react';

const FillTube = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.SMALL:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd" clipRule="evenodd"
                        d="M6.37528 1.5C6.37528 0.87868 6.87896 0.375 7.50028 0.375H16.5003C17.1216 0.375 17.6253 0.87868 17.6253 1.5C17.6253 2.12132 17.1216 2.625 16.5003 2.625H16.1253V9.44365L22.1402 19.6691C23.1696 21.419 21.9079 23.625 19.8777 23.625H4.74164C2.7635 23.625 1.49615 21.52 2.42169 19.7718L7.87528 9.47058V2.625H7.50028C6.87896 2.625 6.37528 2.12132 6.37528 1.5ZM10.1253 2.625V9.75C10.1253 9.93349 10.0804 10.1142 9.99454 10.2764L7.4938 15H16.7833L14.0306 10.3204C13.9289 10.1475 13.8753 9.95058 13.8753 9.75V2.625H10.1253Z"
                        fill="currentColor"/>
                </svg>
            )
        default:
            return <div/>
    }
};

export default FillTube;