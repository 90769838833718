import { APICallAction, OnSuccessPutType } from 'actions/ActionInterface';
import { requestApiWithToken } from 'actions/common/CommonActions';
import { RecipientType } from "models/medicalReport/ReportAccessModel";

export const API_GET_RANDOM_MEDICAL_REPORT_UUID = 'API_GET_RANDOM_MEDICAL_REPORT_UUID';
export const API_GET_MEDICAL_REPORT_CONNECTION_MODES = 'API_GET_MEDICAL_REPORT_CONNECTION_MODES';
export const API_GIVE_PATIENT_ACCESS = 'API_GIVE_PATIENT_ACCESS';
export const API_SHARE_REPORT = 'API_SHARE_REPORT';
export const API_GET_SHARED_REPORT = 'API_GET_SHARED_REPORT';
export const API_RESEND_SHARED_REPORT = 'API_RESEND_SHARED_REPORT'
export const API_RESEND_FIRST_MAIL_REPORT = 'API_RESEND_FIRST_MAIL_REPORT'

export const FEEDBACK_IDENTIFIER_API_GIVE_PATIENT_ACCESS = 'FEEDBACK_IDENTIFIER_API_GIVE_PATIENT_ACCESS'

export const getRandomMedicalReport = (): APICallAction<{}> => {
    return {
        type: API_GET_RANDOM_MEDICAL_REPORT_UUID,
        payload: {},
    };
};

export const getConnectionModes = requestApiWithToken(API_GET_MEDICAL_REPORT_CONNECTION_MODES);

export type givePatientAccessInterface = APICallAction<{
    token: string;
    values: Record<string, string | boolean>;
    from_access: boolean;
    fromSubscription: boolean
}>;

export const givePatientAccess = (
    token: string,
    values: Record<string, string | boolean>,
    from_access: boolean,
    feedbackIdentifier?: string,
    fromSubscription = false,
    onSuccessPut?: OnSuccessPutType
): givePatientAccessInterface => ({
    type: API_GIVE_PATIENT_ACCESS,
    payload: {
        params: {
            token,
            values,
            from_access,
            fromSubscription
        },
        feedbackIdentifier: feedbackIdentifier,
        onSuccessPut: onSuccessPut
    },
});

export type ShareReportInterface = APICallAction<{
    report_uuid: string;
    recipient_type: RecipientType;
    recipient_email: string;
    message?: string;
}>;

export const shareReport = (
    report_uuid: string,
    recipient_type: RecipientType,
    recipient_email: string,
    message?: string,
    feedbackIdentifier?: string,
    onSuccessPut?: OnSuccessPutType
): ShareReportInterface => ({
    type: API_SHARE_REPORT,
    payload: {
        params: {
            report_uuid,
            recipient_type,
            recipient_email,
            message
        },
        feedbackIdentifier: feedbackIdentifier,
        onSuccessPut: onSuccessPut
    }
});

export const getSharedReport = requestApiWithToken(API_GET_SHARED_REPORT);
export const reSendSharedReport = requestApiWithToken(API_RESEND_SHARED_REPORT);
export const reSendFirstMailReport = requestApiWithToken(API_RESEND_FIRST_MAIL_REPORT);

