import React, { ReactNode } from 'react';

import 'components/core/items/Loader.scss';
import MainComponent, { MainComponentProps, setupRaw, TransDomain } from 'components/pages/common/MainComponent';

export type MyLoaderProps = MainComponentProps;

class MyLoader extends MainComponent<MyLoaderProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    render(): ReactNode {
        return <div className={'my-loader'}>{this.trans('loading')}</div>;
    }
}

export default setupRaw(MyLoader);
