import React, { FC } from 'react';
import Divider, { DividerSize, DividerVariant } from "components/designSystem/components/Divider";

import styles from './LabeledDivider.module.scss'

interface LabeledDividerProps {
    dividerSize?: DividerSize
}

const LabeledDivider : FC<LabeledDividerProps> = (
    {dividerSize= DividerSize.MEDIUM, ...props}
) : JSX.Element => {
    return <div className={styles.labeledDivider}>
        <Divider size={dividerSize} variant={DividerVariant.DEFAULT}/>
        {props.children}
        <Divider size={dividerSize} variant={DividerVariant.DEFAULT}/>
    </div>
}

export default LabeledDivider;