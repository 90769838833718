import React from 'react';

export const SubscriptionModuleIllustration = (): JSX.Element => {
    return (
        <svg width="109" height="522" viewBox="0 0 109 522" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <rect width="8.03916" height="8.03916" rx="4.01958" transform="matrix(0.714177 0.699965 -0.714177 0.699965 76.847 267.289)" fill="#009DD6"/>
            </g>
            <g opacity="0.5">
                <rect width="8.03916" height="8.03916" rx="4.01958" transform="matrix(0.714177 0.699965 -0.714177 0.699965 55.3135 277.136)" fill="#009DD6"/>
            </g>
            <rect width="34.1664" height="34.1664" rx="17.0832" transform="matrix(-0.714177 0.699965 -0.714177 -0.699965 95.6687 36.0034)" fill="#009DD6"/>
            <rect width="48.235" height="48.235" rx="24.1175" transform="matrix(0.714177 0.699965 -0.714177 0.699965 66.5132 61.6509)" fill="#009DD6"/>
            <rect width="8.03916" height="8.03916" rx="4.01958" transform="matrix(0.714177 0.699965 -0.714177 0.699965 19.433 8.44141)" fill="#009DD6"/>
            <g opacity="0.5">
                <rect width="8.03916" height="8.03916" rx="4.01958" transform="matrix(0.714177 0.699965 -0.714177 0.699965 90.8396 220.008)" fill="#009DD6"/>
            </g>
            <g opacity="0.5">
                <rect width="32.1567" height="32.1567" rx="16.0783" transform="matrix(0.714177 0.699965 -0.714177 0.699965 62.4296 175.282)" fill="#009DD6"/>
            </g>
            <rect width="8.03916" height="8.03916" rx="4.01958" transform="matrix(0.714177 0.699965 -0.714177 0.699965 39.5265 0)" fill="#009DD6"/>
            <g opacity="0.25">
                <rect width="24.1175" height="24.1175" rx="12.0587" transform="matrix(0.714177 0.699965 -0.714177 0.699965 73.9573 361.442)" fill="#009DD6"/>
            </g>
            <g opacity="0.25">
                <rect width="40.1958" height="40.1958" rx="20.0979" transform="matrix(0.714177 0.699965 -0.714177 0.699965 28.7069 465.402)" fill="#009DD6"/>
            </g>
            <g opacity="0.5">
                <rect width="28.1371" height="28.1371" rx="14.0685" transform="matrix(0.714177 0.699965 -0.714177 0.699965 78.0635 129.346)" fill="#009DD6"/>
            </g>
            <g opacity="0.25">
                <rect width="32.1567" height="32.1567" rx="16.0783" transform="matrix(0.714177 0.699965 -0.714177 0.699965 51.3292 406.17)" fill="#009DD6"/>
            </g>
            <path d="M108.5 288.268C108.5 309.753 90.7266 327.187 68.7811 327.187C46.8355 327.187 29.0624 309.753 29.0624 288.268C29.0624 266.784 46.8355 249.35 68.7811 249.35C90.7266 249.35 108.5 266.784 108.5 288.268Z" fill="white" stroke="#5EC1EC"/>
            <ellipse cx="68.7801" cy="288.269" rx="20.1093" ry="19.7092" fill="#009DD6"/>
            <ellipse cx="68.7799" cy="288.268" rx="7.54101" ry="7.39093" fill="white"/>
        </svg>
    )
}