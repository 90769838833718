import React, { Component, Fragment, ReactNode } from 'react';
import classNames from 'classnames';
import 'styles/text.scss';
import './FormattedValue.scss';
import { CodedValue, Value, ValueType } from 'models/medicalReport/LegacyReportModels';
import { getRawValue } from 'core/utils/Value';

export interface FormattedValueProps {
    value: Value;
    uniform?: boolean; // If true, units and value are displayed with the same size, default false
    bold?: boolean; // default true
    singleResult?: boolean; // default false
}

export default class FormattedValue extends Component<FormattedValueProps> {
    formatValue(value: Value, valueClassNames: string, unitClassNames: string): JSX.Element | null {
        if (value.type === ValueType.NUMERICAL) {
            const num = getRawValue<number>(value);
            return (
                <Fragment>
                    <span className={valueClassNames}>{num}</span>
                    {value.unit && <span className={unitClassNames}>{value.unit}</span>}
                </Fragment>
            );
        } else if (value.type === ValueType.CATEGORICAL) {
            const coded = getRawValue<CodedValue>(value);
            return <span className={valueClassNames}>{coded.wording ? coded.wording : coded.code}</span>;
        }
        return null;
    }

    render(): ReactNode {
        const { value, uniform, bold, singleResult } = this.props;

        const bold_ = bold === undefined ? true : bold;

        const valueClassNames = classNames({
            'bold-text': bold_,
            'value-string-value': !singleResult && value.type === ValueType.CATEGORICAL,
            'value-numerical-value': !singleResult && value.type === ValueType.NUMERICAL,
        });

        const unitClassNames = classNames({
            'formatted-value-unit': true,
            'formatted-value-unit-not-single': !singleResult,
            'small-text-light': uniform ? !uniform : true,
        });

        return (
            <span className={!singleResult ? 'formatted-value' : undefined}>
                {this.formatValue(value, valueClassNames, unitClassNames)}
            </span>
        );
    }
}
