import { ModuleData } from "models/modular/storage";
import React, { Fragment, ReactNode } from 'react';

import MainComponent, { MainComponentProps, setup } from 'components/pages/common/MainComponent';
import { AnyState } from 'core/store/Store';
import { ActionDispatcher } from 'actions/ActionInterface';
import { REDUCER_MODULAR, REDUCER_USER_DATA } from 'reducers/allReducers';
import { reducer } from 'reducers/selector';
import {
    DisplayDataCondition,
    GetPageResponse, GroupModuleV1,
    ModuleDisplayData,
    ModuleGroupType, ModuleGroupZone,
    ModuleV1,
    PageContext,
    PageDisplayLayoutType,
    PartialState
} from 'models/modular/api';
import { PartialPayload, ReducerInterface } from 'reducers/modular/ModularReducer';
import { alterContext, loadPageAction, loadPartialsAction, PartialData } from 'actions/modular/ModularActions';
import { select } from 'components/modules/modular/ModuleSelector';
import { LinearLoader } from 'components/core/items/LinearLoader';
import * as H from 'history';
import "./Modular.scss"
import classNames from 'classnames';
import { fromEntries } from 'core/utils/Getters';
import MediaQuery from 'react-responsive';
import { MOBILE_MAX_WIDTH, REDUCED_SIDE_BAR_BREAKPOINT } from 'components/core/constants';
import ConnectedCarouselNavigator from 'components/common/ConnectedCarouselNavigator';
import Container from 'components/legacyDesignSystem/components/Container';
import {UserDataReducerInterface} from "reducers/user/UserDataReducers";
import {LocalUserData} from "models/user/UserModels";
import { PageLoader } from "components/core/items/PageLoader";

export interface ModularPageStateProps {
    pageId: string,
    currentPage?: GetPageResponse,
    partials: {[key: string]: PartialPayload},
    forceReload: boolean
    loading: boolean,
    isSwitching: boolean,
    failed: boolean,
    userData?: LocalUserData | null,
    modulesData: {[key: string]: ModuleData},
}

export interface ModularPageDispatchProps {
    loadPage: (pageId: string, context: PageContext) => void,
    loadPartial: (pageId: string, partials: PartialData[]) => void,
    alterContext: (key?: string, value?: string) => void,
}


export interface ModularPageComponentProps {
    location: Location;
    history: H.History;
}

export interface ModularPageProps extends MainComponentProps, ModularPageStateProps, ModularPageDispatchProps, ModularPageComponentProps {}

class ModularPage extends MainComponent<ModularPageProps> {


    getContext(): PageContext {

        const urlParams = new URLSearchParams(this.props.location.search);
        const { currentPage, pageId } = this.props;

        const urlContext: PageContext = {};
        urlParams.forEach(
            (value, key) => {
                urlContext[key] = value
            }
        );

        if  (currentPage && ( currentPage.page_id !== pageId)) {
            return urlContext
        }

        return  {
            ...urlContext,
            ...(currentPage ? currentPage.context : {})
        };
    }

    updateUrlContext(context: PageContext): void {
        const currentPage = this.props.currentPage;
        if (!currentPage) {
            return
        }

        const urlParams = new URLSearchParams(this.props.location.search);

        // We only do a loose inequality to avoid context url formatting issues "1" == 1 in our case
        const updatedContextKey = Object.entries(context).filter(
            ([k, v]) => urlParams.get(k) != v && v !== undefined
        );

        if (
            updatedContextKey.length > 0
        ) {
            this.props.history.replace(
                {
                    pathname: this.props.location.pathname,
                    search: new URLSearchParams(
                        {
                            ...urlParams,
                            ...fromEntries(
                                Object.entries(context).filter(
                                    (a): a is [string, string] => a[1] !== null
                                ).map(
                                    ([k, v]) => [k, v.toString()]
                                )
                            )
                        }

                    ).toString()
                });
        }
    }

    componentDidMount(): void {
        const { currentPage, pageId } = this.props;

        if (!currentPage || currentPage.page_id != pageId) {
            this.props.loadPage(
                pageId,
                this.getContext()
            );
        }

    }

    componentDidUpdate(prevProps: ModularPageProps): void {
        const { currentPage, pageId, forceReload, partials } = this.props;
        const context = this.getContext();

        // Simply an update due to user data setup
        if (prevProps.userData === null && !!this.props.userData) {
            return
        }

        if (!(this.props.failed && (!this.props.userData || !this.props.userData.uuid)) &&
            (!currentPage || currentPage.page_id != pageId || forceReload)
        ) {
            this.props.loadPage(
                pageId,
                context
            )
        }


        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const partialToLoad: PartialData[] = Object.entries(partials).filter(([identifier, data]) => data.state == PartialState.TO_LOAD).map(
            ([identifier, data]) => ({
                identifier: identifier,
                payload: {
                    ...data.payload,
                    ...context
                }
            })
        )

        if (partialToLoad.length > 0) {
            this.props.loadPartial(
                pageId,
                partialToLoad
            )
        }

        this.updateUrlContext(context);
    }



    getModuleFromId = (id: string, modules: ModuleV1[]): ModuleV1 | undefined => {
        return modules.find((m) => m.identification.unique_id === id)
    }

    renderModule(
        m: ModuleV1 | undefined,
        displayData: ModuleDisplayData,
        context: PageContext,
        pageId: string,
    ): ReactNode {
        if (m === undefined) {
            return <Fragment key={displayData.module_id} />
        }

        const urlContext = this.getContext();
        let module = select(
            m.identification.unique_id,
            displayData,
            () => this.props.loadPage(this.props.pageId, urlContext),
            m.type,
            pageId,
            undefined,
            urlContext
        )

        if (displayData.navigator) {
            const navigator = displayData.navigator;
            const initItem =  navigator.on_item_change_alter_context_key ?
                context[navigator.on_item_change_alter_context_key] : undefined;

            module = (
                <div className={"module-with-navigator-wrapper"}>
                    <div className={"module-with-navigator-payload"}>
                        {module}
                    </div>
                    <div>
                        <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
                            <ConnectedCarouselNavigator
                                identifier={navigator.navigation_identifier}
                                bulletQty={5}
                                initItem={
                                    initItem ? initItem : undefined
                                }
                                onChange={
                                    (item: string): void => this.props.alterContext(navigator.on_item_change_alter_context_key, item)
                                }
                            />
                        </MediaQuery>
                        <MediaQuery minWidth={MOBILE_MAX_WIDTH + 1}>
                            <ConnectedCarouselNavigator
                                identifier={navigator.navigation_identifier}
                                bulletQty={5}
                                initItem={
                                    initItem ? initItem : undefined
                                }
                                onChange={
                                    (item: string): void => this.props.alterContext(navigator.on_item_change_alter_context_key, item)
                                }
                                isVertical={true}
                            />
                        </MediaQuery>
                    </div>
                </div>
            )
        }
        return <Fragment key={m.identification.unique_id}>
            {module}
        </Fragment>
    }

    protected buildGroup(group: GroupModuleV1, currentPage: GetPageResponse, whenReducedSideBar: boolean): ReactNode {
        const modulesFilteredAccordingContext = group.modules.filter(
            (module) => {
                const displayContextConditions = module.display_context?.conditions;
                const onlyWhenReducedSideBar = displayContextConditions && displayContextConditions.length === 1 &&
                    displayContextConditions[0] === DisplayDataCondition.WHEN_REDUCED_SIDE_BAR
                return !(!whenReducedSideBar && onlyWhenReducedSideBar)
            }
        ).filter((module) => {
            return !this.props.modulesData[module.module_id]?.gone
        })

        if (group.type === ModuleGroupType.COMPARE) {
            return <div
                key={group.index}
                className={classNames(
                    'module-group',
                    `module-group-${ModuleGroupType[ModuleGroupType.COMPARE]}`,
                )}
            >
                <div className={'module-group-COMPARE-item'}>
                    {
                        modulesFilteredAccordingContext.filter(
                            (m) => m.zone === ModuleGroupZone.LEFT
                        ).map(
                            (m: ModuleDisplayData) => this.renderModule(
                                this.getModuleFromId(
                                    m.module_id,
                                    currentPage.payload.modules
                                ),
                                m,
                                currentPage.context,
                                currentPage.page_id,
                            )
                        )
                    }
                </div>
                <div className={'module-group-COMPARE-item'}>
                    {
                        modulesFilteredAccordingContext.filter(
                            (m) => m.zone === ModuleGroupZone.RIGHT
                        ).map(
                            (m: ModuleDisplayData) => this.renderModule(
                                this.getModuleFromId(
                                    m.module_id,
                                    currentPage.payload.modules
                                ),
                                m,
                                currentPage.context,
                                currentPage.page_id,
                            )
                        )
                    }
                </div>
            </div>
        }

        if (modulesFilteredAccordingContext.length == 0) {
            return null
        }

        return <div
            key={group.index}
            className={classNames(
                'module-group',
                `module-group-${ModuleGroupType[group.type]}`,
            )}
        >
            {modulesFilteredAccordingContext.map(
                (m: ModuleDisplayData) => this.renderModule(
                    this.getModuleFromId(
                        m.module_id,
                        currentPage.payload.modules
                    ),
                    m,
                    currentPage.context,
                    currentPage.page_id,
                )
            )}
        </div>
    }

    buildContent(currentPage: GetPageResponse, whenReducedSideBar: boolean): ReactNode{
        const computedClassName = classNames(
            "modules-container",
            {
                "modules-container-full": currentPage && currentPage.display_data.layout_type === PageDisplayLayoutType.FULL,
                "modules-container-column": currentPage && currentPage.display_data.layout_type === PageDisplayLayoutType.COLUMN
            }
        )
        return <div className={computedClassName}>
            {currentPage.display_data.layout.map(
                (g) => this.buildGroup(g, currentPage, whenReducedSideBar)
            )}
        </div>
    }

    render(): ReactNode {
        const { currentPage, pageId, isSwitching, partials } = this.props;

        const displayLoader = (
            (this.props.loading || !currentPage || currentPage.page_id != pageId) && !this.props.failed
        ) || (
            partials && Object.values(partials).filter((p) => p.state === PartialState.LOADING).length > 0
        );

        if(displayLoader && (!currentPage || isSwitching)){
            return <PageLoader />
        }

        return (
            <Container>
                {
                    displayLoader && <LinearLoader active={true} />
                }
                <div>
                    {currentPage && !isSwitching &&(
                        <>
                            <MediaQuery maxWidth={REDUCED_SIDE_BAR_BREAKPOINT}>
                                {this.buildContent(currentPage, true)}
                            </MediaQuery>
                            <MediaQuery minWidth={REDUCED_SIDE_BAR_BREAKPOINT + 1}>
                                {this.buildContent(currentPage, false)}
                            </MediaQuery>
                        </>
                    )}
                </div>
            </Container>
        )
    }
}

const mapStateToProps = (state: AnyState, ownProps: { match: { params: { pageId: string }}}): ModularPageStateProps => ({
    pageId: ownProps.match.params.pageId,
    currentPage: reducer<ReducerInterface>(state, REDUCER_MODULAR).currentPage,
    partials:  reducer<ReducerInterface>(state, REDUCER_MODULAR).partials,
    forceReload: reducer<ReducerInterface>(state, REDUCER_MODULAR).forceReload,
    loading: reducer<ReducerInterface>(state, REDUCER_MODULAR).loading,
    isSwitching: reducer<ReducerInterface>(state, REDUCER_MODULAR).isSwitching,
    failed: reducer<ReducerInterface>(state, REDUCER_MODULAR).failed,
    userData: reducer<UserDataReducerInterface>(state, REDUCER_USER_DATA).userData,
    modulesData: reducer<ReducerInterface>(state, REDUCER_MODULAR).modulesData
});

const mapDispatchToProps = (dispatch: ActionDispatcher): ModularPageDispatchProps => ({
    loadPage: (pageId: string, context: PageContext): void => dispatch(loadPageAction(pageId, context)),
    loadPartial: (pageId: string, partials: PartialData[]): void => dispatch(loadPartialsAction(pageId, partials)),
    alterContext: (key?: string, value?: string): void => key === undefined ? undefined : dispatch(alterContext(key, value)),
});


export default setup(ModularPage, mapStateToProps, mapDispatchToProps);
