import classNames from 'classnames';
import DropDown from 'components/designSystem/components/DropDown';
import DropDownItem, { DropDownType } from 'components/designSystem/components/DropDownItem';
import { AlwaysInline, InlineAlignItem, InlineSpacing } from "components/designSystem/containers/Inline";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { LinearLoader } from 'components/legacyDesignSystem/components/LinearLoader';

import 'components/legacyDesignSystem/components/WithTabs.scss';
import WithTooltip from 'components/legacyDesignSystem/components/WithTooltip';
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import React, { CSSProperties, PropsWithChildren } from 'react';

export enum TabsStyle {
    DEFAULT = 'DEFAULT',
    SELECTORS = 'SELECTORS'
}

export type WithTabsProps = PropsWithChildren<{
    tabs: TabObject[],
    selectedTabId: string,
    collapsed?: boolean,
    isLoadingContent?: boolean,
    style?: TabsStyle,
    withBorder?: boolean,
    withDropdownTitleWhenCollapse?: string,
}>;

export interface TabObject {
    id: string;
    name: string;
    onClick: () => void;
    withToolTip?: boolean
}

export interface WithTabsState {
    selectedTab: TabObject
}


export type TabProps = PropsWithChildren<{
    onClick: () => void;
    isSelected?: boolean;
    style?: CSSProperties;
    tooltipValue?: string;
}>

const Tab = (props: TabProps): JSX.Element => (
    <WithTooltip
        text={props.tooltipValue}
        className={classNames(
            props.isSelected ? 'tab--selected' : 'tab')
        }
        onClick={props.onClick}
        style={props.style}
        hidden={!props.tooltipValue}
    >
        <AlwaysInline spacing={InlineSpacing.X_SMALL} alignItems={InlineAlignItem.CENTER}>
            {props.children}
        </AlwaysInline>
    </WithTooltip>
)


class WithTabs extends WithTranslations<WithTabsProps, WithTabsState> {
    TRANS_SUFFIX = TransDomain.DESIGN_SYSTEM

    constructor(props: WithTabsProps) {
        super(props);
        this.state = {
            selectedTab: props.tabs.filter(
                (t: TabObject) => t.id === props.selectedTabId
            )[0],
        }
    }

    buildCollapsedTabs = (
        tabs: TabObject[],
    ): JSX.Element => {
        return <div id={'collapsed-tabs'} className={classNames('tabs-container', 'collapsed-tabs-container', {'tabs-container-with-border': this.props.withBorder})}>
            <DropDown
                closeOnClick
                triggerBuilder={
                    (onClick: () => void): JSX.Element => (
                        <Tab
                            onClick={onClick}
                            isSelected={false}
                        >
                            <span className={'tab-name'}>{this.state.selectedTab.name}</span>
                            <Icon size={IconSize.EXTRA_SMALL} name={IconName.CHEVRON_DOWN}/>
                        </Tab>
                    )
                }
                title={this.props.withDropdownTitleWhenCollapse}
            >
                {tabs.map((t: TabObject, k: number) =>
                    <DropDownItem
                        key={k}
                        label={t.name}
                        payload={
                            {
                                type: DropDownType.RADIOBUTTON,
                                groupName: 'testgroup',
                                isChecked: t.id === this.state.selectedTab.id,
                                onClick: (): void => {
                                    this.setState({ selectedTab: t });
                                    t.onClick()
                                }
                            }
                        }
                    />
                )}
            </DropDown>
        </div>
    }

    buildExpandedTabs = (
        tabs: TabObject[],
        selectedTabId: string,
    ): JSX.Element => (
        <div className={'tabs-container'}>
            {tabs.map((tab: TabObject, k: number) => {
                const isSelected = tab.id === selectedTabId
                const zIndex = isSelected ? 101 : 100 - k
                return <Tab
                    key={k}
                    onClick={tab.onClick}
                    isSelected={isSelected}
                    tooltipValue={tab.withToolTip ? tab.name : undefined}
                    style={{
                        zIndex: zIndex,
                    }}
                >
                    <span className={'tab-name'}>{tab.name}</span>
                </Tab>
            })}
        </div>
    )


    render(): JSX.Element {
        const preset = this.props.style ? this.props.style : TabsStyle.DEFAULT;
        return (
            <div
                id={'with-tabs'}
                className={
                    classNames(
                        'with-tabs-container',
                         `with-tabs-container--${preset}`,
                        {'with-tabs-container-with-border': this.props.withBorder}
                    )
                }
            >
                {this.props.collapsed
                    ? this.buildCollapsedTabs(
                        this.props.tabs,
                    ) : this.buildExpandedTabs(
                        this.props.tabs,
                        this.props.selectedTabId
                    )
                }
                <div className={classNames(
                    'tab-content',
                    {'tab-content--loading': this.props.isLoadingContent, 'tab-content-with-border': this.props.withBorder}
                )}>
                    <LinearLoader active={!!this.props.isLoadingContent} />
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default WithTabs;
