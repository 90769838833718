export const formatPhoneNumber = (number: string | null): string => {
    if (!number) return '';

    const countryCode = number.slice(0, 3);

    if (countryCode !== '+33') return number;

    let formattedNumber = `${countryCode} ${number.slice(3, 4)}`;
    for (let i = 0; i < 4; i++) {
        formattedNumber = `${formattedNumber} ${number.slice(4 + i * 2, 6 + i * 2)}`;
    }
    return formattedNumber;
};
