import React, {FC} from 'react';
import styles from './Badge.module.scss';
import classNames from "classnames";


export enum BadgeSize {
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum BadgeSeverity {
    DEFAULT = 'default',
    CRITICAL = 'critical',
    NEUTRAL = 'neutral',
}

export interface BadgeProps {
    size?: BadgeSize;
    severity?: BadgeSeverity;
    children?: number;
}

/**
 * If you want Badge to be included within a text a having proper margin, you have to put text before
 * badge in a span <span>Hello</span><Badge>12</Badge>
 */
const Badge : FC<BadgeProps> = (
    {
    severity = BadgeSeverity.DEFAULT,
    size= BadgeSize.MEDIUM,
        ...props

}):JSX.Element => {

    const computedClassNames = classNames(styles.badge,
        {
            [styles.badgeSeverityNeutral]: severity === BadgeSeverity.NEUTRAL,
            [styles.badgeSeverityDefault]: severity === BadgeSeverity.DEFAULT,
            [styles.badgeSeverityCritical]: severity === BadgeSeverity.CRITICAL,

            [styles.badgeSizeMedium]: size === BadgeSize.MEDIUM,
            [styles.badgeSizeLarge]: size === BadgeSize.LARGE,
        }
    )
       return <span className={computedClassNames}>
        {props.children}
    </span>
}

export default Badge;