import React from 'react';

const FemaleIcon = (): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_879_14341)">
                <path d="M14.1851 22.5863L14.1851 25.5574C13.1888 25.5574 12.3811 26.3651 12.3811 27.3614L12.3811 27.5116C12.3811 28.5079 13.1888 29.3156 14.1851 29.3156L14.1851 29.745C14.1851 30.9904 15.1946 32 16.44 32C17.6854 32 18.695 30.9904 18.695 29.745L18.695 29.3156L18.8002 29.3156C19.838 29.3156 20.6793 28.4743 20.6793 27.4365C20.6793 26.3987 19.838 25.5574 18.8002 25.5574L18.695 25.5574L18.695 22.6182C23.9265 21.6088 28 16.9694 28 11.4122C28 5.11954 22.8244 1.45489e-06 16.4813 9.00354e-07C10.1381 3.45807e-07 5 5.11936 5 11.4122C5 16.9093 8.95357 21.5104 14.1851 22.5863ZM16.4662 4.4742C20.3225 4.4742 23.46 7.58669 23.46 11.4123C23.46 15.2382 20.3225 18.3507 16.4662 18.3507C12.6097 18.3507 9.47221 15.2382 9.47221 11.4123C9.47239 7.58651 12.6097 4.4742 16.4662 4.4742Z" fill="currentColor"/>
                <circle cx="16.5" cy="11.5" r="8.5" fill="currentColor" fillOpacity={0.5}/>
            </g>
            <defs>
                <clipPath id="clip0_879_14341">
                    <rect width="32" height="32" fill="currentColor"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default FemaleIcon;
