import React from 'react';

import "./styles.scss"


export function renderToolip(content: JSX.Element): JSX.Element | null {
    return (
        <div className={'tooltip-wrapper'}>
            {content}
        </div>
    );
}