import {
    GetArticleListRequest,
    SetPatientBioProfilesRequest,
    UpdatePregnantProfileRequest
} from "actions/bioProfile/BioProfileFetchActions";
import { ApiCallType, PureApiCallPayload } from 'actions/common/ConnectedButton';
import { LoadPageAction, LoadPartialAction } from 'actions/modular/ModularActions';
import {
    FetchPatientReportsPayload as FetchPatientReportsPayloadDemo
} from 'actions/modular/modules/demo/MyReportsActions';
import { DemoPayload } from 'actions/modular/modules/demo/TestActions';
import { FetchPatientReportsPayload } from 'actions/modular/modules/ReportsActions';
import { ForceConnectionActionPayload, InitSSOActionPayload } from 'actions/user/SignInSignUpActions';
import { PatientList } from 'components/modules/doctorBoard/Types';
import { DoctorDashboardData } from 'models/doctor/DashboardModels';
import { GetConnectionModesResponse, GivePatientAccessResponse } from 'models/medicalReport/ConnectionModesModel';
import { GetReportMetadataResponse, RandomReportUUID, } from 'models/medicalReport/LegacyReportModels';
import { RecipientType } from "models/medicalReport/ReportAccessModel";
import { PageContext } from 'models/modular/api';
import { AccountInfoResponse, NotificationInterval } from 'models/user/AccountModels';
import {
    AuthResponse,
    AuthUserDataResponse,
    CreateAccountResponse,
    GetPscUrlResponse,
    InitSSOResponse,
    ResetPasswordResponse,
} from 'models/user/AuthModels';
import { GetNavigationResponse } from 'models/user/NavigationModels';
import { DoctorInfoFromPscResponse, } from 'models/user/SubscriptionModels';
import { get, post } from 'sagas/api/Core';
import _ from 'underscore';

function buildGet<T extends {}, R, T2 extends {}>(
    endpoint: (string | ((params: T) => string)),
    paramsBuilder: (params: T) => T2,
): (params: T, retryCount?: number) => R {

    let endpoint_: (p: T) => string;
    if (_.isFunction(endpoint)) {
        endpoint_ = endpoint
    } else {
        endpoint_ = (): string => endpoint
    }

    return (params: T, retryCount?: number): R => get(endpoint_(params), paramsBuilder(params), retryCount ?? 0)
}
export const signIn = (
    params: { username: string; password: string, persist?: boolean }
): AuthResponse =>
    post(
        '/auth',
        {
            username: params.username,
            password: params.password,
            persist: params.persist || false,
        },
    );
export const signOut = (): void => post('/disconnect', {});
export const confirmPin = (params: { pin: string }): AuthResponse => post('/confirm-pin', params);
export const submit2FACode = (params: { code: string; token: string }): AuthResponse =>
    post('/submit-2fa-code', {
        code: params.code,
        token: params.token,
    });

export const getNavigation = (): GetNavigationResponse =>
    get('/user/navigation');

export const validateEULA = (params: { token: string }): void => post('/user/eula/validate', { token: params.token });
export const resetKnownPW = (params: { oldPassword: string; newPassword: string }): {} =>
    post('/user/reset-known-password', { old_password: params.oldPassword, new_password: params.newPassword });
export const resetPW = (params: { password: string; confirmation: string; token: string }): ResetPasswordResponse =>
    post('/user/reset-password', params);
export const forgotPW = (params: { email: string }): {} => post('/user/forgot-password', params);
export const updateDoctorInfo = (params: {
    speciality?: string | null;
    businessAddress?: string | null;
    businessPhoneNumber?: string | null;
    businessEmail?: string | null;
    notificationInterval?: NotificationInterval | null;
}): {} => {
    return post('/user/account/update-doctor-info', {
        ...(params.speciality !== undefined ? { speciality: params.speciality } : {}),
        ...(params.businessAddress !== undefined ? { business_address: params.businessAddress } : {}),
        ...(params.businessPhoneNumber !== undefined ? { business_phone_number: params.businessPhoneNumber } : {}),
        ...(params.businessEmail !== undefined ? { business_email: params.businessEmail } : {}),
        ...(params.notificationInterval
            ? { notification_interval: NotificationInterval[params.notificationInterval] }
            : {}),
    });
};

export const triggerNotificationByIdentifier = (params: { identifier: string, labUuid?: string }): void =>
    post('/user/notifications/trigger-by-identifier', {
        identification: params.identifier,
        laboratory_uuid: params.labUuid
    });

export const accountInfo = (): AccountInfoResponse => get('/user/account/info', {});

export const getDoctorInfo = (params: { code: string; state: string ; localState: string}): DoctorInfoFromPscResponse =>
    get('/user/init-cps', { code: params.code, state: params.state, local_state: params.localState});
export const getPscUrl = (): GetPscUrlResponse => get('/user/psc-url', {});
export const retrieveAuthUserData = (params: { withAnonymousMode: boolean }): AuthUserDataResponse => get('/user/my/data', {with_anonymous_mode: params.withAnonymousMode});
export const getRandomReportUUID = (): RandomReportUUID => get('/medical-reports/report/random-uuid', {});
export const getReportsMetadataCall = (params: { accessTokens: string[] }): GetReportMetadataResponse =>
    get('/medical-reports/reports/metadata', { access_tokens: params.accessTokens });
export const getMyPatients = (): PatientList => get('/doctor/my/patients', {});
export const getDoctorDashboard = (params: { onlyAsReceiver: boolean; nextPageToken?: string }): DoctorDashboardData =>
    get(
        '/doctor/my/dashboard/',
        params.nextPageToken
            ? { pagination_token: params.nextPageToken, only_as_receiver: params.onlyAsReceiver }
            : { only_as_receiver: params.onlyAsReceiver },
    );
export const setBioProfiles = (params: SetPatientBioProfilesRequest): {} =>
    post('/bio_profile/profile/set', params);
export const updateBioProfilePregnant = (params: UpdatePregnantProfileRequest): {} =>
    post('/bio_profile/profile/pregnant/update', params);
export const getArticleList = (params: GetArticleListRequest): {} =>
    get('/bio_profile/profile/articles', params);
export const setPin = (params: { pin: string }): void => post('/user/set-pin', { pin: params.pin });
export const biologistCommunicationMeansAction = (params: { token: string }): void =>
    post('/biologist/communication-means-action', { token: params.token })
export const getNotificationsBiologist = (params: { token: string }): void =>
    get('/biologist/refresh-notifications', { refresh_token: params.token })
export const rate = (params: { token: string, rating: string | number }): void =>
    post('/rating/rate', { token: params.token, rating: params.rating.toString() })
export const initSSO = (params: InitSSOActionPayload): InitSSOResponse =>
    get('/user/sso/init', {
        client_id: params.clientID,
        scope: params.scope,
        state: params.state,
        redirect_uri: params.redirectURI,
        patient_mail: params.patientMail,
    });
export const forceConnection = (params: ForceConnectionActionPayload): {} =>
    post('/user/sso/force-auth', {
        external_token: params.externalToken,
        local_token: params.localToken,
    });


export const getModularPage = (params: LoadPageAction): {} =>
    get('/modular/pages/' + params.pageId, params.context);

export const getModularPartial = buildGet<LoadPartialAction, {}, PageContext>(
    (p) => '/modular/pages/' + p.pageId + '/partial/' + p.identifier,
    (p) => p.payload
)

export const connectedButtonPureAPICall = (payload: PureApiCallPayload<{}>): {} => {
    const method = payload.type === ApiCallType.POST ? post : get;
    return method(
        payload.path,
        payload.params
    )
};

export const demoModuleCall = (params: DemoPayload): {} =>
    get('/modular/module/demo', {
        value: params.value
    });

export const demoModuleFetchPatientReportsCall = (params: FetchPatientReportsPayloadDemo): {} =>
    get('/modular/module/demo/get-patient-reports', {
        patient_uuid: params.patientUUID
    });

export const demoModuleFetchBiograhCall = (): {} =>
    get('/modular/module/demo/get-biograph-data', {
    });


export const fetchPatientReportsCall = (params: FetchPatientReportsPayload): {} =>
    get('/medical-reports/access/get-patients-reports', {
        patient_uuids: params.patientUUIDs,
        only_last_reports: params.onlyLastReports,
        report_uuids: params.reportUuid ? [params.reportUuid] : undefined
    });

export const getConnectionModes = (params: {token: string}): GetConnectionModesResponse =>
    get('/medical-reports/get-connection-modes', { token: params.token });
export const givePatientAccess = (params: {
    token: string,
    values: Record<string, string | boolean >,
    from_access: boolean
}): GivePatientAccessResponse => post('/medical-reports/give-patient-access', {
    token: params.token,
    values: params.values,
    from_access: params.from_access
});

export const createAccount = (params: { token: string; values: Record<string, string | boolean> }): CreateAccountResponse =>
    post('/user/create-account', { token: params.token, values: params.values });

export const shareReport = (
    params: {
        report_uuid: string;
        recipient_type: RecipientType;
        recipient_email: string;
        message?: string;
    }
): {} =>
    post('/medical-reports/share-report', {
        report_uuid: params.report_uuid,
        recipient_type: params.recipient_type,
        recipient_email: params.recipient_email,
        message: params.message
    });

export const getSharedReport = (
    params: {
        token: string
    }
): {} =>
    get('/medical-reports/get-shared-report', {
       token: params.token
    });

export const reSendSharedReport = (
    params: {
        token: string
    }
): {} =>
    post('/medical-reports/shared-report/resend', {
        token: params.token
    });

export const reSendFirstMailReport = (
    params: {
        token: string
    }
): {} =>
    post('/medical-reports/first-mail-report/resend', {
        token: params.token
    });