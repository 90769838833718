import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Search = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-search'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_130_628)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M14.5 6C14.5 8.48528 12.4853 10.5 10 10.5C7.51472 10.5 5.5 8.48528 5.5 6C5.5 3.51472 7.51472 1.5 10 1.5C12.4853 1.5 14.5 3.51472 14.5 6ZM16 6C16 9.31371 13.3137 12 10 12C8.61347 12 7.33678 11.5297 6.32077 10.7399L1.28033 15.7803C0.987437 16.0732 0.512563 16.0732 0.21967 15.7803C-0.0732233 15.4874 -0.0732233 15.0126 0.21967 14.7197L5.26011 9.67923C4.47031 8.66322 4 7.38653 4 6C4 2.68629 6.68629 0 10 0C13.3137 0 16 2.68629 16 6Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_130_628">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-search'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21 9.5C21 13.0899 18.0899 16 14.5 16C10.9101 16 8 13.0899 8 9.5C8 5.91015 10.9101 3 14.5 3C18.0899 3 21 5.91015 21 9.5ZM23 9.5C23 14.1944 19.1944 18 14.5 18C12.5131 18 10.6855 17.3183 9.23819 16.176L2.70711 22.7071C2.31658 23.0976 1.68342 23.0976 1.29289 22.7071C0.902369 22.3166 0.902369 21.6834 1.29289 21.2929L7.82398 14.7618C6.6817 13.3145 6 11.4869 6 9.5C6 4.80558 9.80558 1 14.5 1C19.1944 1 23 4.80558 23 9.5Z"
                  fill="currentColor"/>
        </svg>
    )
}