import { ActionDispatcher } from "actions/ActionInterface";
import { retrieveUserData } from "actions/user/SignInSignUpActions";
import { MOBILE_MAX_WIDTH } from "components/core/constants";
import EmptyState, { EmptyStateType, MaxWidthSize } from "components/designSystem/components/EmptyState";
import Column, { ColumnWidthInREM } from "components/designSystem/containers/Column";
import Spacer, { SpacerSpacing } from "components/designSystem/containers/Spacer";
import { TDisplaySmall700, TypographyColor } from "components/designSystem/containers/Typography";
import { MarginSize, WithRightMargin } from "components/designSystem/containers/WithMargin";
import { IconName } from "components/designSystem/foundations/IconsData";
import { setup, TransDomain } from "components/pages/common/MainComponent";
import WithTranslations from "components/pages/common/WithTranslations";
import { buildConnectionsModeComponent } from "components/utils/builders/ConnectionModesBuilder";
import LOGGER from "core/logging/Logger";
import { AnyState } from "core/store/Store";
import { capitalizeFirstLetter } from "core/utils/text";
import { cast } from "core/utils/Typed";
import { ConnectionMode, ConnectionModeType } from "models/medicalReport/ConnectionModesModel";
import { CommonDataType, ConnectionData, GetPageResponse } from "models/modular/api";
import { LocalUserData, UserMode } from "models/user/UserModels";
import React, { Fragment, ReactNode } from "react";
import MediaQuery from "react-responsive";
import { REDUCER_MODULAR, REDUCER_USER_DATA } from "reducers/allReducers";
import { ReducerInterface } from "reducers/modular/ModularReducer";
import { reducer } from "reducers/selector";
import { UserDataReducerInterface } from "reducers/user/UserDataReducers";


interface OnlyLoggedFeatureProps {
    connectionModes: ConnectionMode[];
    feature: () => ReactNode;
    descriptionByConnectionMode: Record<ConnectionModeType, JSX.Element>;
    title: JSX.Element
}

interface OnlyLoggedFeatureStateProps {
    userData?: LocalUserData | null;
    currentPage?: GetPageResponse;
}

interface OnlyLoggedFeatureDispatchProps {
    retrieveUserData: () => void;
}

interface OnlyLoggedFeatureState {
    userClicked: boolean;
}


class OnlyLoggedFeature extends WithTranslations<OnlyLoggedFeatureProps & OnlyLoggedFeatureStateProps & OnlyLoggedFeatureDispatchProps & OnlyLoggedFeatureState> {
    TRANS_SUFFIX = TransDomain.ACCESS + '.blocked_feature';

    state: OnlyLoggedFeatureState = {
        userClicked: false
    }

    componentDidMount(): void {
        if (!this.props.userData) {
            this.props.retrieveUserData()
        }
    }

    componentDidUpdate(): void {
        if (!this.props.userData) {
            this.props.retrieveUserData()
        }
    }

    checkConnectionMode(connectionModes: ConnectionMode[]): ConnectionMode | undefined {
        if (connectionModes.length === 0) {
            LOGGER.critical(
                'Share report should not be displayed'
            )
            return undefined
        }

        if (connectionModes.length > 1) {
            LOGGER.critical(
                'Multiple connection mode for only logged feature not yet handle'
            )
            return undefined
        }

        const connectionMode = connectionModes[0]
        if (![ConnectionModeType.SUBSCRIPTION, ConnectionModeType.LOGIN].includes(connectionMode.type)) {
            LOGGER.critical(
                connectionMode.type + ' not handle'
            )
            return undefined
        }

        return connectionMode
    }

    getTransKey(_type: ConnectionModeType, key?: 'title' | 'button' | 'description'): ReactNode {
        return this.trans(_type.valueOf() + '.' + key, undefined, undefined, capitalizeFirstLetter)
    }

    render(): ReactNode {
        if (!this.props.userData) {
            return <Fragment/>
        }

        const chosenUserMode = this.props.userData.chosenUserMode
        if (chosenUserMode !== UserMode.ANONYMOUS) {
            return this.props.feature();
        }

        let connectionModes: ConnectionMode[] = []
        if (
            this.props.currentPage &&
            this.props.currentPage.payload.common_data &&
            CommonDataType.CONNECTION_DATA in this.props.currentPage.payload.common_data
        ) {
            connectionModes = cast<ConnectionData>(this.props.currentPage.payload.common_data[CommonDataType.CONNECTION_DATA]).connection_modes
        }

        const connectionMode = this.checkConnectionMode(connectionModes)
        if (!connectionMode) {
            return <Fragment/>
        }

        const description = connectionMode.type in this.props.descriptionByConnectionMode ?
            this.props.descriptionByConnectionMode[connectionMode.type] :
            this.getTransKey(connectionMode.type, 'description')

        const content = <Spacer spacing={this.state.userClicked ? SpacerSpacing.X_SMALL : SpacerSpacing.X_LARGE} >
            {/*todo: Improve solution. WithRightMargin is added for close button (absolute position).*/}
            <WithRightMargin margin={MarginSize.LARGE}>
                <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    {this.props.title}
                </TDisplaySmall700>
            </WithRightMargin>
            {
                !this.state.userClicked ? (
                    <EmptyState
                        title={this.getTransKey(connectionMode.type, 'title')}
                        description={description}
                        payload={{type: EmptyStateType.ICON, name: IconName.LOCK_CLOSE}}
                        actionPayload={{
                            name: this.getTransKey(connectionMode.type, 'button'),
                            onClick: (): void => this.setState({userClicked: true}),
                            fullWidth: true
                        }}
                        maxWidth={MaxWidthSize.WIDTH_40}
                    />
                ) : buildConnectionsModeComponent(
                    connectionMode, undefined, description, undefined, true
                )
            }
        </Spacer>

        return <>
            <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
                {content}
            </MediaQuery>
            <MediaQuery minWidth={MOBILE_MAX_WIDTH + 1}>
                <Column widthInRem={ColumnWidthInREM.WIDTH_40}>
                    {content}
                </Column>
            </MediaQuery>
        </>
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): OnlyLoggedFeatureDispatchProps => ({
    retrieveUserData: (): void => {
        dispatch(retrieveUserData(true));
    },
});

const mapStateToProps = (state: AnyState): OnlyLoggedFeatureStateProps => ({
    userData: reducer<UserDataReducerInterface>(state, REDUCER_USER_DATA).userData,
    currentPage: reducer<ReducerInterface>(state, REDUCER_MODULAR).currentPage,
});

export default setup(OnlyLoggedFeature, mapStateToProps, mapDispatchToProps);
