import { put, takeEvery, takeLatest, takeLeading } from '@redux-saga/core/effects';
import { Action, APICallAction, apiCallFailureAction, apiCallSuccessAction } from 'actions/ActionInterface';
import { redirect } from 'actions/common/CommonActions';
import {
    API_MODULAR_GET_PAGE,
    API_MODULAR_LOAD_PARTIAL,
    API_MODULAR_LOAD_PARTIALS,
    CHANGE_PAGE,
    ChangePagePayload,
    LoadPageAction,
    LoadPartialAction,
    loadPartialAction,
    LoadPartialsAction
} from 'actions/modular/ModularActions';
import { ROUTE_MODULAR_PAGE } from 'core/routing/Routes';
import { AnyIterator } from 'core/utils/Typed';
import CallHandler from 'sagas/api/CallHandler';

import { getModularPartial } from 'sagas/api/Endpoints';
import { sagaGet } from "sagas/call/saga";
import { GetModularPageEndpoint } from "sagas/endpoints/modular";

export const getModularPageSaga = function* (action: APICallAction<LoadPageAction>): AnyIterator {
    if (!action.payload.params) {
        return
    }
    const params = action.payload.params

    const response = yield sagaGet(
        GetModularPageEndpoint(params.pageId),
        params.context,
        action,
        'all'
    )

    if (response) {
        yield put(apiCallSuccessAction(API_MODULAR_GET_PAGE, response))
    } else {
        yield put(apiCallFailureAction(API_MODULAR_GET_PAGE))

    }
};

export const getModularPartialsSaga = function* (action: APICallAction<LoadPartialsAction>): AnyIterator {
    if (action.payload.params) {

        for (const partial of action.payload.params.partials ) {
            yield put(loadPartialAction(
                action.payload.params.pageId,
                partial.identifier,
                partial.payload
            ))
        }
    }
};

export const getModularPartialSaga = function* (action: APICallAction<LoadPartialAction>): AnyIterator {
    const response: {} = yield CallHandler.for(getModularPartial, action).call();

    if (response) {
        yield put(apiCallSuccessAction(API_MODULAR_LOAD_PARTIAL, response));
    } else {
        yield put(apiCallFailureAction(API_MODULAR_LOAD_PARTIAL, action))
    }
};



export const changePageSaga = function* (action: Action<ChangePagePayload>): AnyIterator {
    let originPage = action.payload.originPageId;
    let originContext: string | null | undefined =
        action.payload.originContext ? JSON.stringify(action.payload.originContext) : undefined
    if (action.payload.originPageId == action.payload.pageId) {
        // If navigating to the current page, keep the previous origin if any
        const originPageInContext = action.payload.originContext?.origin_page;
        if (originPageInContext) {
            originPage = originPageInContext
            originContext = action.payload.originContext?.origin_context
        }
    }
    yield put(
        redirect(
            ROUTE_MODULAR_PAGE,
            {
                ...(action.payload.context ? action.payload.context : {}),
                pageId: action.payload.pageId,
                ...(originPage ? {origin_page: originPage} : {}),
                ...(originContext ? {origin_context: originContext} : {})
            }
        )
    );
};



export default function* modularSagas(): AnyIterator {

    yield takeLatest(API_MODULAR_GET_PAGE, getModularPageSaga);
    yield takeLeading(API_MODULAR_LOAD_PARTIALS, getModularPartialsSaga);
    yield takeEvery(API_MODULAR_LOAD_PARTIAL, getModularPartialSaga);
    yield takeLatest(CHANGE_PAGE, changePageSaga);
}

