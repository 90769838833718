import React from 'react';

const Inflammation = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M16.1667 8.3667C11.865 8.3667 8.3667 11.865 8.3667 16.1667C8.3667 20.4684 11.865 23.9667 16.1667 23.9667C20.4684 23.9667 23.9667 20.4684 23.9667 16.1667C23.9667 11.865 20.4684 8.3667 16.1667 8.3667ZM16.1667 21.3667C13.2989 21.3667 10.9667 19.0345 10.9667 16.1667C10.9667 13.2989 13.2989 10.9667 16.1667 10.9667C19.0345 10.9667 21.3667 13.2989 21.3667 16.1667C21.3667 19.0345 19.0345 21.3667 16.1667 21.3667Z"/>
            <path d="M16.1665 3.16669C9.1192 3.16669 3.1665 9.11939 3.1665 16.1667C3.1665 23.214 9.1192 29.1667 16.1665 29.1667C23.2138 29.1667 29.1665 23.214 29.1665 16.1667C29.1665 9.11939 23.2138 3.16669 16.1665 3.16669ZM16.1665 26.5667C10.5284 26.5667 5.7665 21.8048 5.7665 16.1667C5.7665 10.5286 10.5284 5.76669 16.1665 5.76669C21.8046 5.76669 26.5665 10.5286 26.5665 16.1667C26.5665 21.8048 21.8046 26.5667 16.1665 26.5667Z"/>
            <path d="M16.1664 13.5667C14.7611 13.5667 13.5664 14.7614 13.5664 16.1667C13.5664 17.572 14.7611 18.7667 16.1664 18.7667C17.5717 18.7667 18.7664 17.572 18.7664 16.1667C18.7664 14.7614 17.5717 13.5667 16.1664 13.5667Z"/>
        </svg>
    )
}

export default Inflammation;
