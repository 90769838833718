import React from 'react';

const Compare = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M17.6438 29.7068C17.0693 29.8373 16.4978 29.4774 16.3673 28.903C16.2392 28.3391 16.1193 27.7714 16.0078 27.2H15.9999V27.1597C15.302 23.5704 14.9336 19.8351 14.9334 16.0002C14.9332 12.1636 15.3017 8.42667 15.9999 4.83581V4.79999L16.0069 4.79999C16.1184 4.22859 16.2382 3.66089 16.3663 3.09709C16.4968 2.52261 17.0682 2.16267 17.6427 2.29314C18.2172 2.4236 18.5771 2.99507 18.4466 3.56955C18.3522 3.98556 18.2623 4.40383 18.1772 4.82427C19.7848 4.86443 21.1939 4.95977 22.4173 5.14918C24.1644 5.41967 25.6259 5.89484 26.7733 6.75555C29.1297 8.52309 29.7333 11.5711 29.7333 16.0006C29.7333 20.4301 29.1297 23.4778 26.7732 25.2451C25.6258 26.1056 24.1643 26.5806 22.4172 26.851C21.1941 27.0403 19.7853 27.1356 18.1781 27.1757C18.2632 27.5961 18.3531 28.0144 18.4476 28.4303C18.5781 29.0048 18.2182 29.5763 17.6438 29.7068ZM13.0642 5.90394C13.1034 6.49174 12.6587 7 12.0709 7.03917C8.69168 7.26433 6.92316 7.88842 5.91322 9.05322C4.87937 10.2456 4.39993 12.2711 4.39993 16.0006C4.39993 19.7301 4.87938 21.7553 5.91316 22.9474C6.92307 24.112 8.69155 24.7358 12.0708 24.9609C12.6586 25 13.1034 25.5083 13.0642 26.0961C13.0251 26.6839 12.5169 27.1286 11.9291 27.0895C8.48352 26.86 5.91868 26.21 4.30145 24.3451C2.70809 22.5077 2.2666 19.7479 2.2666 16.0006C2.2666 12.2532 2.7081 9.49327 4.30139 7.65567C5.91859 5.79051 8.4834 5.14015 11.929 4.91055C12.5168 4.87139 13.0251 5.31614 13.0642 5.90394Z" />
        </svg>
    )
}

export default Compare;
