import { ActionDispatcher, OnSuccessPutElementType, OnSuccessPutType } from 'actions/ActionInterface';
import { redirect } from 'actions/common/CommonActions';
import { redirectToProSanteConnect, signIn as signInAction } from 'actions/user/SignInSignUpActions';
import Form from 'components/commonDesign/form/tmpNewSystem/Form';
import FormCheckbox from 'components/commonDesign/form/tmpNewSystem/FormCheckbox';
import FormInput from 'components/commonDesign/form/tmpNewSystem/FormInput';
import Button, { ButtonSize, ButtonVariant } from 'components/designSystem/components/Button';
import Divider, { DividerSize, DividerVariant } from "components/designSystem/components/Divider";
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import { MediumSpacer, XLargeSpacer, XSmallSpacer } from "components/designSystem/containers/Spacer";
import { TBody, TCaption, TDisplaySmall700, TypographyColor } from "components/designSystem/containers/Typography";
import { CPSLogo } from "components/designSystem/foundations/logos/CPSLogo";
import APIFeedbackMessageContainer from 'components/pages/common/APIFeedbackMessage';
import { setup, TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { required } from 'components/utils/form/tmpNewSystem/validators';
import { ROUTE_DEFAULT_MODULAR, ROUTE_USER_FORGOT_PASSWORD } from 'core/routing/Routes';
import { AnyState } from 'core/store/Store';
import { capitalizeFirstLetter } from 'core/utils/text';
import React, { ReactNode } from 'react';
import { REDUCER_GLOBAL } from 'reducers/allReducers';
import { reducer } from 'reducers/selector';


export interface LoginFormStateProps {
    previousOnSuccessPut?: OnSuccessPutType;
}

export interface LoginFormDispatchProps {
    onSubmit: (
        data: { email: string; password: string; persist?: boolean },
        previousOnSuccessPut?: OnSuccessPutType
    ) => void;
    redirectToForgotMyPassword: () => void;
    redirectToProSanteConnect: (onSuccessPutAfterCPS?: OnSuccessPutType) => void;
}

export interface LoginFormProps extends LoginFormStateProps, LoginFormDispatchProps {
    redirectTo?: string;
    thenPut?: OnSuccessPutElementType;
    withPersist?: boolean;
    withProfessionalHealthConnection?: boolean;
    isEmailEditable?: boolean;
    prefilledEmail?: string;
    title?: ReactNode;
    description?: ReactNode;
    openNewTabForForgetPassword?: boolean;
    dividerTextForLogin?: ReactNode;
}

type FormValues = {
    email: string;
    password: string;
    persist?: boolean;
}

export const FEEDBACK_IDENTIFIER_API_SIGN_IN = 'FEEDBACK_IDENTIFIER_API_SIGN_IN';

class LoginForm extends WithTranslations<LoginFormProps> {
    TRANS_SUFFIX = TransDomain.ACCESS

    onSubmit = (then : OnSuccessPutType | undefined): (data: FormValues) => void => {
        return (
            data: { email: string; password: string; persist?: boolean }
        ): void => {
            this.props.onSubmit(data, then);
        };
    }

    buildOnSuccessPutType = () : OnSuccessPutType | undefined => {
        let then: OnSuccessPutType | undefined
        if (this.props.thenPut) {
            then = [this.props.thenPut]
        } else if (this.props.redirectTo) {
            then = [redirect(this.props.redirectTo)]
        } else if (this.props.previousOnSuccessPut) {
            then = this.props.previousOnSuccessPut
        } else {
            then = [redirect(ROUTE_DEFAULT_MODULAR)]
        }
        return then
    }

    render(): JSX.Element {
        const onClickForgotPassword = this.props.openNewTabForForgetPassword ? {
            href: ROUTE_USER_FORGOT_PASSWORD,
            targetBlank: true
        } : (): void => this.props.redirectToForgotMyPassword();
        const then : OnSuccessPutType | undefined = this.buildOnSuccessPutType()
        return (
            <div>
                <XLargeSpacer>
                    <APIFeedbackMessageContainer
                        identifier={FEEDBACK_IDENTIFIER_API_SIGN_IN} withNewDesignSystem resetConditions={[
                        {id: 'login-form-email', action: 'click'},
                        {id: 'login-form-pwd', action: 'click'},
                    ]}/>
                    <XSmallSpacer>
                        {
                            this.props.title && <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                {this.props.title}
                            </TDisplaySmall700>
                        }
                        {
                            this.props.description && <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                                {this.props.description}
                            </TBody>
                        }
                    </XSmallSpacer>
                    <MediumSpacer>
                        <Form
                            // @ts-ignore
                            onSubmit={this.onSubmit(then)}
                        >
                            {
                                (): JSX.Element => {
                                    return (
                                        <XLargeSpacer>
                                            <MediumSpacer>
                                                <MediumSpacer>
                                                    <FormInput
                                                        name={'email'}
                                                        field={
                                                            {
                                                                label: this.trans('email', undefined, undefined, capitalizeFirstLetter),
                                                                type: "text",
                                                                required: true,
                                                                defaultValue: this.props.prefilledEmail ? this.props.prefilledEmail : undefined,
                                                                disabled: !!this.props.prefilledEmail && !this.props.isEmailEditable,
                                                                autoCompletion: "username"
                                                            }
                                                        }
                                                        validate={required('email')}
                                                    />
                                                    <FormInput
                                                        name={'password'}
                                                        field={
                                                            {
                                                                label: this.trans('password', undefined, undefined, capitalizeFirstLetter),
                                                                type: "password",
                                                                required: true,
                                                                autoCompletion: "current-password"
                                                            }
                                                        }
                                                        validate={required('login_password')}
                                                    />
                                                </MediumSpacer>
                                                {this.props.withPersist && <FormCheckbox
                                                    name={'persist'}
                                                    field={{
                                                        label: this.trans('stay_sign_in', undefined, undefined, capitalizeFirstLetter),
                                                    }}
                                                />}
                                            </MediumSpacer>
                                            <Button variant={ButtonVariant.PRIMARY}
                                                    size={ButtonSize.LARGE} isSubmit fullWidth>
                                                {this.trans('log_in', undefined, undefined, capitalizeFirstLetter)}
                                            </Button>
                                        </XLargeSpacer>
                                    )
                                }
                            }
                        </Form>
                        <Button
                            id={"forgotten-password"}
                            variant={ButtonVariant.QUATERNARY}
                            size={ButtonSize.LARGE}
                            onClick={onClickForgotPassword}
                            fullWidth
                        >
                            {this.trans('forgotten_password', undefined, undefined, capitalizeFirstLetter)}
                        </Button>
                    </MediumSpacer>
                    {this.props.withProfessionalHealthConnection && <MediumSpacer>
                        <AlwaysInline justifyContent={InlineJustifyContent.CENTER} spacing={InlineSpacing.X_SMALL} alignItems={InlineAlignItem.CENTER}>
                            <Divider variant={DividerVariant.DEFAULT} size={DividerSize.MEDIUM}/>
                            <TCaption color={TypographyColor.COLOR_TEXT_DISABLED}>
                                {this.props.dividerTextForLogin ? this.props.dividerTextForLogin : this.trans('health_pro', undefined, undefined, capitalizeFirstLetter)}
                            </TCaption>
                            <Divider variant={DividerVariant.DEFAULT} size={DividerSize.MEDIUM}/>
                        </AlwaysInline>
                        <Button
                            id={'redirect-to-psc'}
                            variant={ButtonVariant.SECONDARY}
                            size={ButtonSize.LARGE}
                            onClick={() : void => {
                                this.props.redirectToProSanteConnect(then)
                            }}
                            fullWidth
                        >
                            <AlwaysInline spacing={InlineSpacing.SMALL}>
                                <CPSLogo/>
                                {this.trans('psc_url', undefined, undefined, capitalizeFirstLetter)}
                            </AlwaysInline>
                        </Button>
                    </MediumSpacer>}
                </XLargeSpacer>
            </div>
        )
    }
}

const mapStateToProps = (state: AnyState): LoginFormStateProps => ({
    previousOnSuccessPut: reducer(state, REDUCER_GLOBAL).redirectOnSuccessPut,
});

const mapDispatchToProps = (dispatch: ActionDispatcher): LoginFormDispatchProps => ({
    onSubmit: (data: { email: string; password: string; persist?: boolean }, previousOnSuccessPut?: OnSuccessPutType): void => dispatch(
        signInAction(
            {
                username: data.email,
                password: data.password,
                persist: data.persist,
            },
            FEEDBACK_IDENTIFIER_API_SIGN_IN,
            previousOnSuccessPut
        ),
    ),
    redirectToForgotMyPassword: (): void => dispatch(redirect(ROUTE_USER_FORGOT_PASSWORD)),
    redirectToProSanteConnect: (onSuccessPutAfterCPS?: OnSuccessPutType): void => dispatch(
        redirectToProSanteConnect(onSuccessPutAfterCPS)
    )
});

export default setup(LoginForm, mapStateToProps, mapDispatchToProps);
