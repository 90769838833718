import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Lollipop = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-lollipop'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_153_217)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M10.0559 0.000254599C13.3438 0.0302393 16 2.70493 16 6C16 9.31371 13.3137 12 10 12C8.61347 12 7.33678 11.5297 6.32077 10.7399L1.28033 15.7803C0.987437 16.0732 0.512563 16.0732 0.21967 15.7803C-0.0732233 15.4874 -0.0732233 15.0126 0.21967 14.7197L5.26011 9.67923C4.47031 8.66322 4 7.38653 4 6C4 2.69569 6.67108 0.0152387 9.97183 6.47446e-05C9.97509 -7.3859e-05 9.97835 -0.000209605 9.98161 -0.000342492C10.0066 -0.00136179 10.0314 -0.00114463 10.0559 0.000254599ZM5.98697 5.95974C5.76961 3.88398 7.5971 1.61945 10.0074 1.50001C12.4893 1.50401 14.5 3.51719 14.5 6C14.5 6.08266 14.4978 6.1648 14.4934 6.24636C14.4889 6.1523 14.4811 6.05826 14.4697 5.96437C14.2889 3.56464 12.0391 1.83055 9.92704 2.11916C7.8128 2.31277 6.32501 4.30676 6.61829 6.14011C6.82888 7.97158 8.5862 9.1913 10.1388 8.89289C11.6814 8.66153 12.6542 7.15367 12.3338 5.87028C12.1991 5.22289 11.802 4.74013 11.3324 4.46119C10.8838 4.19471 10.2997 4.07794 9.7695 4.27021C9.26274 4.43239 8.92002 4.7937 8.75751 5.19432C8.61266 5.5514 8.57485 6.05786 8.86387 6.46311C9.10912 6.83388 9.5145 7.01512 9.91235 6.96772C10.2345 6.92933 10.8186 6.6404 10.7592 5.97148C10.7577 5.95402 10.7555 5.93676 10.7528 5.91972C10.8058 5.99197 10.8463 6.07976 10.8668 6.18336L10.8712 6.20582L10.877 6.22798C10.9735 6.59611 10.6437 7.30631 9.90679 7.41087L9.88529 7.41392L9.86399 7.41821C9.19845 7.55232 8.21618 6.96626 8.10719 5.9573L8.10483 5.93542L8.10118 5.91371C7.94245 4.96795 8.77896 3.72306 10.0762 3.61181L10.0974 3.61L10.1184 3.60699C11.3586 3.4295 12.8646 4.51834 12.9749 6.09034L12.9763 6.11143L12.979 6.1324C13.1718 7.64945 11.8308 9.4134 9.96921 9.52016L9.94808 9.52137L9.92706 9.52378C8.11629 9.73055 6.09376 8.13646 5.99017 6.0015L5.98915 5.98058L5.98697 5.95974Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_153_217">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-lollipop'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M20.986 9.93073C20.9953 9.78836 21 9.64473 21 9.5C21 5.97878 18.2001 3.11153 14.7051 3.00318C11.3367 3.19721 8.71023 6.52065 9.02543 9.65912L9.02811 9.68579L9.02936 9.71256C9.17967 12.9427 12.09 15.2752 14.6256 14.9733L14.6548 14.9698L14.6841 14.9681C17.2953 14.812 19.2567 12.2099 18.9727 9.88003L18.9694 9.85318L18.9676 9.82619C18.8059 7.42278 16.6238 5.83418 14.8914 6.09267L14.8622 6.09702L14.8329 6.09965C13.015 6.26219 11.7611 8.10701 12.0014 9.60012L12.0059 9.62795L12.0088 9.65599C12.1721 11.2321 13.6148 12.0781 14.5382 11.8841L14.5677 11.8779L14.5975 11.8735C15.0931 11.8002 15.4938 11.5122 15.7525 11.1239C16.0215 10.7201 16.0841 10.2905 16.0118 10.0028L16.0046 9.9743L15.9991 9.94541C15.9451 9.66049 15.8053 9.45359 15.6416 9.3181C15.6949 9.42653 15.7293 9.54651 15.7402 9.67414C15.8142 10.5426 15.0784 10.9638 14.6018 11.023C14.0397 11.0928 13.4876 10.8227 13.1629 10.3101C12.7846 9.75808 12.8316 9.06803 13.0244 8.57255C13.2396 8.01932 13.7015 7.50053 14.408 7.265C15.1488 6.98469 15.9661 7.15599 16.5891 7.54181C17.241 7.94557 17.7778 8.63501 17.9586 9.54431C18.1805 10.4677 17.9333 11.4575 17.417 12.2327C16.8895 13.0246 16.0286 13.6743 14.9196 13.8475C12.7217 14.2885 10.3071 12.4844 10.0224 9.88993C9.624 7.30362 11.6424 4.39472 14.6256 4.11029C17.6106 3.68456 20.7154 6.23868 20.9612 9.66503C20.9716 9.75346 20.9798 9.84204 20.986 9.93073ZM14.8707 1.00794C19.3931 1.20201 23 4.92979 23 9.5C23 14.1944 19.1944 18 14.5 18C12.5131 18 10.6855 17.3183 9.23819 16.176L2.70711 22.7071C2.31658 23.0976 1.68342 23.0976 1.29289 22.7071C0.902369 22.3166 0.902369 21.6834 1.29289 21.2929L7.82398 14.7618C6.6817 13.3145 6 11.4869 6 9.5C6 4.80558 9.80558 1 14.5 1C14.5601 1 14.62 1.00062 14.6798 1.00186C14.687 1.00154 14.6942 1.00122 14.7014 1.00092C14.7589 0.998474 14.8155 1.00094 14.8707 1.00794Z"
                  fill="currentColor"/>
        </svg>
    )
}
