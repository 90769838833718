import React, { ReactNode } from 'react';

import LogoFull from 'core/content/brand/logos/LogoFull';
import MainComponent, { MainComponentProps, setup, TransDomain } from 'components/pages/common/MainComponent';
import Button from 'components/core/buttons/Button';
import { ActionDispatcher } from 'actions/ActionInterface';
import { redirect } from 'actions/common/CommonActions';
import { ROUTE_EULA_HEALTH_PROFESSIONAL, ROUTE_EULA_PATIENT } from 'core/routing/Routes';
import { getRoute } from 'core/routing/Helper';

import 'components/modules/eula/eula.scss';
import Title, { TitleType } from 'components/core/text/Title';

export interface EULAProps extends MainComponentProps {
    redirectToEula: (route: string) => void;
}

class Eula extends MainComponent<EULAProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT + '.eula';

    renderSpecificEulaBox = (title: ReactNode, route: string): JSX.Element => (
        <div className={'eula-box'}>
            <div className={'eula-box-title'}>{title}</div>
            <Button content={this.transContent('read', <></>)} onClick={(): void => this.props.redirectToEula(route)} />
        </div>
    );

    render(): ReactNode {
        return (
            <div className="eula-page">
                <LogoFull />
                <Title type={TitleType.HEADING1} className={'eula-title'}>
                    {this.trans('title')}
                </Title>
                <div className={'eula-body'}>
                    {this.renderSpecificEulaBox(this.trans('health_professionals'), ROUTE_EULA_HEALTH_PROFESSIONAL)}
                    {this.renderSpecificEulaBox(this.trans('patients'), ROUTE_EULA_PATIENT)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (): {} => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): {} => ({
    redirectToEula: (route: string): void => dispatch(redirect(getRoute(route, { version: null }))),
});

export default setup(Eula, mapStateToProps, mapDispatchToProps);

// TODO: Test this Component
