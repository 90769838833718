import { ActionDispatcher } from 'actions/ActionInterface';
import { redirect } from 'actions/common/CommonActions';
import { resetPW } from 'actions/user/UserActions';
import ResetPassword from 'components/modules/user/legacy/ResetPassword';
import MainComponent, { MainComponentProps, setup, TransDomain } from 'components/pages/common/MainComponent';
import { ROUTE_DEFAULT_MODULAR } from "core/routing/Routes";
import { AnyState } from 'core/store/Store';
import React, { ReactNode } from 'react';

export interface SetPasswordStateProps {
    token: string;
}

export interface SetPasswordDispatchProps {
    onSubmit: (password: string, confirmation: string, token: string) => void;
}

class SetPasswordPage extends MainComponent<SetPasswordStateProps & SetPasswordDispatchProps & MainComponentProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    render(): ReactNode {
        return <ResetPassword first_set_password={true} {...this.props} />;
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): SetPasswordDispatchProps => ({
    onSubmit: (password: string, confirmation: string, token: string): void => {
        dispatch(resetPW(password, confirmation, token, [redirect(ROUTE_DEFAULT_MODULAR)]));
    },
});

const mapStateToProps = (
    state: AnyState,
    ownProps: { match: { params: { token: string } } },
): SetPasswordStateProps => ({
    token: ownProps.match.params.token,
});

export default setup(SetPasswordPage, mapStateToProps, mapDispatchToProps);
