import React, {FC, PropsWithChildren} from 'react';

import styles from './WithAbsolutePosition.module.scss'
import classNames from "classnames";

export enum WithAbsolutePositionAlignmentY {
    CENTER = 'CENTER',
    BOTTOM = 'BOTTOM'
}

type WithAbsolutePositionProps = PropsWithChildren<{
    alignmentY?: WithAbsolutePositionAlignmentY
}>

const WithAbsolutePosition: FC<WithAbsolutePositionProps> = ({...props}): JSX.Element => {
    return <div className={classNames(styles.withAbsolutePosition,
        {
            [styles.withAbsolutePositionAlignmentYCENTER]: props.alignmentY === WithAbsolutePositionAlignmentY.CENTER,
            [styles.withAbsolutePositionAlignmentYBOTTOM]: props.alignmentY === WithAbsolutePositionAlignmentY.BOTTOM
        }
    )}>
        {props.children}
    </div>
}

export default WithAbsolutePosition;