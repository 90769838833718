import * as React from "react";

const VividPacifier = (): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
    >
        <path
            fill="#E2C6FF"
            d="m9.07 6.32 3.848 1.497 2.412 3.664.855.672.764 1.985.855 2.229-.855.977-3.42-.244-.367 1.16-1.77 2.015-2.596.52-3.542-.856-1.344-2.137-.824-2.473.824-2.443 2.657-2.412.315-1.476.234-2.341 1.955-.336Z"
        />
        <path
            fill="#E2C6FF"
            d="m16.5 2.637 2.666.402 1.51 1.66.503 2.567-1.157 2.113-1.812 1.912h-1.962l-3.17 1.258-.855-.403-1.107-1.409 1.107-1.056.855-3.573 1.409-2.566 2.013-.905Z"
        />
        <path
            stroke="#A144FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="M19.98 9.8a4.092 4.092 0 0 0-.04-5.827c-1.622-1.577-4.245-1.528-5.808.107a4.075 4.075 0 0 0-1.123 2.536c-.1 1.425-.683 2.772-1.692 3.782l-.124.125 2.226 2.227.028-.028c1.04-1.04 2.432-1.64 3.9-1.733.96-.06 1.901-.457 2.634-1.19Z"
        />
        <path
            stroke="#A144FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="M16.035 11.213c1.85 2.437 2.69 4.967 1.693 5.965-1.24 1.24-4.847-.358-7.698-3.21-2.85-2.852-4.449-6.46-3.21-7.7.996-.996 3.517-.162 5.947 1.681"
        />
        <path
            stroke="#A144FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="M13.935 17.182a5.684 5.684 0 0 1-9.449 2.332 5.688 5.688 0 0 1 2.256-9.43"
        />
        <path
            stroke="#A144FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="M11.759 15.798a3.267 3.267 0 1 1-5.563-2.617 3.253 3.253 0 0 1 1.925-.935"
        />
    </svg>
);

export default VividPacifier;

