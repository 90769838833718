import { Language, LanguageDropdown } from 'components/modules/modular/modules/account/LanguageDropdown';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const LANG: Language[] = [
    { name: 'Français', id: 'fr' },
    { name: 'English', id: 'en' },
];

const findById = (id: string): Language | undefined => {
    return LANG.find((element: Language): boolean => element.id === id);
};

export const AccountLanguageSelector = (): ReactElement | null => {
    const { i18n } = useTranslation();
    const selectedLanguage = findById(i18n.language);


    const switchSelector = (id: string): void => {
        i18n.changeLanguage(id).then();
    };

    if (!selectedLanguage) {
        return null;
    }

    return (
        <LanguageDropdown
            selectedLanguage={selectedLanguage}
            languages={LANG}
            onChangeLanguage={(id): void => switchSelector(id)}
        />
    );
};