import React, { FC } from 'react';
import styles from './Pill.module.scss';
import classNames from "classnames";


export enum PillSeverity {
    EMPTY = 'EMPTY',
    SAFE = 'SAFE',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL',
    CRITICAL_RED = 'CRITICAL_RED',
    NEUTRAL = 'NEUTRAL',
    INFO = 'INFO'
}

export interface PillProps {
    severity?: PillSeverity;
}

const Pill : FC<PillProps> = (
    props
):JSX.Element => {

    return <div className={
        classNames(styles.pill,
            {
                [styles.pillSeverityNeutral]: props.severity === PillSeverity.NEUTRAL,
                [styles.pillSeveritySafe]: props.severity === PillSeverity.SAFE,
                [styles.pillSeverityInfo]: props.severity === PillSeverity.INFO,
                [styles.pillSeverityWarning]: props.severity === PillSeverity.WARNING,
                [styles.pillSeverityCritical]: props.severity === PillSeverity.CRITICAL,
                [styles.pillSeverityCriticalRed]: props.severity === PillSeverity.CRITICAL_RED,
            }
        )
    } />
}

export default Pill;