import { APICallAction } from "actions/ActionInterface";
import { cast } from "core/utils/Typed";
import { call, put } from "redux-saga/effects";
import { buildUrl, get, post, UrlReq } from "sagas/call/api";
import { GetEndpoint, PostEndpoint } from "sagas/call/endpoints";
import { APIError, ErrorType } from "sagas/call/error";
import { generatorErrorProcessor } from "sagas/call/errorHandling";

export function* sagaGet<Req extends UrlReq, Res> (
    endpoint: GetEndpoint<Req, Res>,
    request: Req,
    action: APICallAction<unknown>,
    fallbackToAPIFeedback?: 'all' | ErrorType[]
): Generator {
    try {
        return yield call(
            (args) => get<Res>(buildUrl(endpoint.path, request)),
            request
        )
    } catch (e) {
        yield generatorErrorProcessor(
            put,
            undefined,
            cast<APIError>(e),
            action,
            {
                fallbackToAPIFeedback: fallbackToAPIFeedback
            }
        )
    }

    return null
}

export function* sagaPost<Req extends UrlReq, Res> (
    endpoint: PostEndpoint<Req, Res>,
    request: Req,
    action: APICallAction<unknown>,
    fallbackToAPIFeedback?: 'all' | ErrorType[]
): Generator {
    try {
        return yield call(
            (args) => post<Req, Res>(buildUrl(endpoint.path, undefined), request),
            request
        )
    } catch (e) {
        yield generatorErrorProcessor(
            put,
            undefined,
            cast<APIError>(e),
            action,
            {
                fallbackToAPIFeedback: fallbackToAPIFeedback
            }
        )
    }

    return null
}

