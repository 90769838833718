import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import * as React from 'react';

const FillBlood = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.SMALL:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd" clipRule="evenodd"
                        d="M13.2495 1.32352C12.519 0.739166 11.4811 0.739166 10.7507 1.32352C6.96777 4.34985 4.3133 9.17896 3.80086 13.4969C3.54303 15.6694 3.80517 17.9308 4.99719 19.7686C6.23954 21.684 8.29602 22.8377 10.9686 23.0703C11.3064 23.0997 11.6502 23.1149 12.0001 23.1149C12.3499 23.1149 12.6938 23.0997 13.0315 23.0703C15.7041 22.8377 17.7606 21.684 19.003 19.7686C20.195 17.9308 20.4571 15.6694 20.1993 13.4969C19.6869 9.17896 17.0324 4.34985 13.2495 1.32352ZM16.9876 15.4181C16.9876 14.8658 16.5399 14.4181 15.9876 14.4181C15.4353 14.4181 14.9876 14.8658 14.9876 15.4181C14.9876 16.7535 13.905 17.8361 12.5696 17.8361C12.0173 17.8361 11.5696 18.2839 11.5696 18.8361C11.5696 19.3884 12.0173 19.8361 12.5696 19.8361C15.0096 19.8361 16.9876 17.8581 16.9876 15.4181Z"
                        fill="#001948"/>
                </svg>
            )
        default:
            return <div/>
    }
};

export default FillBlood;