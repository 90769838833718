import React, { FC, PropsWithChildren } from 'react';

import styles from "components/designSystem/containers/navigation/NavigationWrapper.module.scss"

import classNames from 'classnames';
import SideBar, { SideBarProps } from 'components/designSystem/containers/navigation/SideBar';
import NavBar, { NavBarProps } from 'components/designSystem/containers/navigation/NavBar';


export interface NavigationWrapperProps {
    navBarProps?: NavBarProps
    sideBarProps?: SideBarProps
}

// This is required usage of content is due to legacy implementation
// And the need of WithFooter (which do not belong to Design system)
// but was needed to be put between contentContainer and NavigationContent
export const NavigationContent = (props: PropsWithChildren<{immersive?: boolean}>): JSX.Element => {
    return <div className={classNames(
        styles.content,
        {
            [styles.contentImmersive]: !!props.immersive
        },
    )}
    >
        {props.children}
    </div>
}

const NavigationWrapper: FC<NavigationWrapperProps> = (
    props
): JSX.Element => {
    return <div className={styles.wrapper}>
        <div className={styles.wrapperLeft}>
            {
                props.sideBarProps &&
                <SideBar {...props.sideBarProps} />
            }
        </div>
        <div className={styles.wrapperRight}>
            {
                props.navBarProps &&
                <NavBar{...props.navBarProps}  />
            }

            <div className={classNames(
                styles.contentContainer,
                {
                    [styles.contentContainerWithStickyNavBar]: props.navBarProps && props.navBarProps.sticky,
                    [styles.contentContainerWithStickySideBar]: props.sideBarProps && props.sideBarProps.sticky,
                }
            )}>
                {props.children}
            </div>
        </div>

    </div>;
}

export default NavigationWrapper;