import React, { Component, PropsWithChildren, ReactNode } from 'react';

import "./Modals.scss"

export enum SideModalType {
    LEFT = 'LEFT'
}
interface SideModalProps {
    type: SideModalType,
    close: () => void
}
export class SideModal extends Component<PropsWithChildren<SideModalProps>>{

    render(): ReactNode {

        return <div className={"modal-wrapper"} >
            <div
                className={"modals-blur"}
                onClick={(): void => this.props.close()}
            />

            <div className={`side-modal side-modal-${this.props.type}`}>
                {this.props.children}
            </div>
        </div>

    }
}