import { AnyState } from 'core/store/Store';
import { LocalUserData, UserMode } from 'models/user/UserModels';
import { useSelector } from "react-redux";
import { REDUCER_USER_DATA } from 'reducers/allReducers';
import { reducer } from 'reducers/selector';
import { defaultUserData, UserDataReducerInterface } from 'reducers/user/UserDataReducers';

export function getUserData(state: AnyState): LocalUserData {
    return reducer(state, REDUCER_USER_DATA).userData;
}

export function isProperlyLoggedIn(state: AnyState): boolean {
    const userData: LocalUserData | null = reducer(state, REDUCER_USER_DATA).userData;

    // user_data == defaultUserData <= legacy (previous mechanism put defaultUserData as default value)
    // todo replace by a safer method explicitely looking at each fields ?
    return !(!userData || userData === defaultUserData || !userData.uuid);
}

export function useUserData(): LocalUserData {
    return  useSelector<AnyState, LocalUserData | undefined>(
        (state: AnyState) => reducer<UserDataReducerInterface>(state, REDUCER_USER_DATA).userData
    ) || {
        userModes: [UserMode.ANONYMOUS],
        chosenUserMode: UserMode.ANONYMOUS,
        firstName: null,
        lastName: null,
    }
}
