import React, { Component, Fragment, ReactNode } from 'react';
import { isProperlyLoggedIn } from 'reducers/Shortcuts';
import { setup } from 'components/pages/common/MainComponent';
import MyLoader from 'components/core/items/MyLoader';
import { retrieveUserData } from 'actions/user/SignInSignUpActions';
import { ActionDispatcher } from 'actions/ActionInterface';
import { AnyState } from 'core/store/Store';

export interface LogginRequiredStateProps {
    isLoggedIn: boolean;
}

export interface LogginRequiredDispatchProps {
    retrieveUserData: () => void;
}

class LoggingRequired extends Component<LogginRequiredStateProps & LogginRequiredDispatchProps> {
    render(): ReactNode {
        if (!this.props.isLoggedIn) {
            // if retrieveUserData is not possible, user will be redirected to login page
            this.props.retrieveUserData();
            return <MyLoader />;
        }
        return <Fragment>{this.props.children}</Fragment>;
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): LogginRequiredDispatchProps => ({
    retrieveUserData: (): void => {
        dispatch(retrieveUserData());
    },
});

const mapStateToProps = (state: AnyState): LogginRequiredStateProps => ({
    isLoggedIn: isProperlyLoggedIn(state),
});

export default setup(LoggingRequired, mapStateToProps, mapDispatchToProps);
