import { APICallAction } from "actions/ActionInterface";
import { PregnancyDateType } from "models/bioProfiles/BioProfileModels";

export const API_SET_PROFILES = 'API_SET_PROFILES';
export const API_UPDATE_PREGNANT_PROFILE = 'API_UPDATE_PREGNANT_PROFILE';
export const API_GET_ARTICLE_LIST = 'API_GET_ARTICLES';

export interface SetPatientBioProfilesRequest {
    patient_uuid: string
    profile_codes: string[]
}

export type SetPatientBioProfilesAction = APICallAction<SetPatientBioProfilesRequest>

export interface UpdatePregnantProfileRequest {
    token: string
    date_type: PregnancyDateType
    date_value: string
    date_time_zone?: string
}

export type UpdatePregnantProfileAction = APICallAction<UpdatePregnantProfileRequest>

export interface GetArticleListRequest {
    patient_uuid: string[],
}

export type GetArticleListAction = APICallAction<GetArticleListRequest>

export const setPatientBioProfiles = (
    patientUuid: string,
    profileCodes: string[],
    feedbackIdentifier?: string
): SetPatientBioProfilesAction => {
    return {
        type: API_SET_PROFILES,
        payload: {
            params: {
                patient_uuid: patientUuid,
                profile_codes: profileCodes
            },
            feedbackIdentifier: feedbackIdentifier
        },
    };
};

export const updatePregnantProfile = (
    token: string,
    date_type: PregnancyDateType,
    date_value: string,
    date_time_zone?: string,
): UpdatePregnantProfileAction => {
    return {
        type: API_UPDATE_PREGNANT_PROFILE,
        payload: {
            params: {
                token,
                date_type,
                date_value,
                date_time_zone,
            },
        },
    };
};

export const getArticleList = (
    patientUuids: string[],
): GetArticleListAction => {
    return {
        type: API_GET_ARTICLE_LIST,
        payload: {
            params: {
                patient_uuid: patientUuids,
            },
        },
    };
};
