import TrText from 'components/common/TrText';
import Alert from "components/designSystem/components/Alert";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { getButtonInfo } from 'components/modules/modular/modules/helpers/callToActions';
import { getAlertSeverity } from 'components/modules/modular/modules/transformers/modelsMapper'
import { getIconByName } from "core/content/icons/IconFinder";
import { APIText } from 'models/common/message';
import { CallToAction, DisclaimerSeverity } from 'models/modular/communication';
import React, { useState } from 'react';

interface DisclaimerModulePayload {
    severity: DisclaimerSeverity
    text: APIText
    title?: APIText
    light?: boolean
    call_to_actions?: CallToAction[]
    custom_icon?: string
    is_hideable?: string
}

export const DisclaimerModule = () : JSX.Element => {
    const payload = useModulePayload<DisclaimerModulePayload>();
    const [isHidden, setIsHidden] = useState(false);

    const buttonInfo = payload.call_to_actions?.length ? getButtonInfo(payload.call_to_actions[0]) : undefined

    if (payload.is_hideable && isHidden) {
        return <div/>
    }
    return (
        <Alert
            severity={getAlertSeverity(payload.severity)}
            title={payload.title ? <TrText input={payload.title} /> : undefined}
            description={<TrText input={payload.text}>
                <>0<br/>1</>
            </TrText>}
            callToAction={buttonInfo ? {
                actionName : buttonInfo.actionName,
                onClick : buttonInfo.onClick
            } : undefined}
            customIcon={getIconByName(payload.custom_icon)}
            withCloseButton={payload.is_hideable ? () : void => setIsHidden(true) : undefined}
        />
    )
}
