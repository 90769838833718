import { ActionDispatcher } from "actions/ActionInterface";
import { redirect } from "actions/common/CommonActions";
import { MOBILE_MAX_WIDTH } from "components/core/constants";
import EmptyState, { EmptyStateType, MaxWidthSize } from "components/designSystem/components/EmptyState";
import Box from "components/designSystem/containers/Box";
import Centered from "components/designSystem/containers/Centered";
import { PaddingSize, WithVerticalPadding } from "components/designSystem/containers/WithPadding";
import { IconName } from "components/designSystem/foundations/IconsData";
import BaseModule, { setupModule } from "components/modules/modular/BaseModule";
import { buildClickModuleAction } from "components/modules/modular/modules/builders/userAction";
import { getPlatformResource } from "core/content/cdn";
import LOGGER from "core/logging/Logger";
import { ROUTE_ACCESS_BY_CONNECTION_MODES } from "core/routing/Routes";
import { AnyState } from "core/store/Store";
import { cast } from "core/utils/Typed";
import { APIText } from "models/common/message";
import { ConnectionMode } from "models/medicalReport/ConnectionModesModel";
import {
    CommonDataType,
    ConnectionData,
    DisplayHorizontalSize,
    DisplayVerticalSize,
    GetPageResponse
} from "models/modular/api";
import { ModuleData } from "models/modular/storage";
import { LocalUserData } from "models/user/UserModels";
import React, { Fragment, ReactNode } from 'react';
import MediaQuery from "react-responsive";
import { REDUCER_MODULAR, REDUCER_USER_DATA } from "reducers/allReducers";
import { ReducerInterface } from "reducers/modular/ModularReducer";
import { reducer } from "reducers/selector";
import { UserDataReducerInterface } from "reducers/user/UserDataReducers";

interface LockedModuleDispatchProps{
    redirectToAccessByConnectionMode: (connectionMode: ConnectionMode) => void
}

interface LockedModuleStateProps {
    currentPage?: GetPageResponse;
    userData?: LocalUserData | null;
}

type LockedModuleProps = LockedModuleDispatchProps & LockedModuleStateProps

interface LockedModulePayload {
    title: APIText;
    description: APIText;
    button: APIText;
    image_path: string;
}

class LockedModule extends BaseModule<LockedModuleProps, LockedModulePayload, {}>{
    DEFAULT_DISPLAY_CONFIGURATION = {
        horizontalConfiguration: { defaultSize: DisplayHorizontalSize.FULL },
        verticalConfiguration: { defaultSize: DisplayVerticalSize.AUTO },
    };

    componentDidUpdate(
        prevProps: Readonly<LockedModuleProps>
    ) : void {
        if(!this.props.userData){
            return
        }

        if ((!prevProps.userData && this.props.userData) || (prevProps.userData && this.props.userData && (
                prevProps.userData.chosenUserMode != this.props.userData.chosenUserMode ||
                prevProps.userData.uuid != this.props.userData.uuid
            )
        )) {
            this.props.refreshPage()
        }
    }

    buildContent(
        isMobile: boolean,
        title: APIText,
        description: APIText,
        button: APIText,
        imgUrl: string,
        connectionMode: ConnectionMode
    ): JSX.Element {
        return <Box withBoxShadow withRadius background={{imageUrl: imgUrl}} withPadding>
            <Centered>
                <WithVerticalPadding padding={PaddingSize.X_LARGE}>
                <EmptyState
                    title={this.transApiText(title)}
                    description={this.transApiText(description)}
                    payload={{
                        type: EmptyStateType.ICON,
                        name: IconName.LOCK_CLOSE
                    }}
                    actionPayload={{
                        name: this.transApiText(button),
                        onClick: (): void => {
                            if (this.props.pageId && this.props.type){
                                LOGGER.userAction(
                                    buildClickModuleAction(
                                        this.props.pageId,
                                        this.props.type,
                                        'log_in'
                                    )
                                )
                            }
                            this.props.redirectToAccessByConnectionMode(connectionMode)
                        }
                    }}
                    maxWidth={MaxWidthSize.WIDTH_50}
                />
                </WithVerticalPadding>
            </Centered>
        </Box>
    }

    _getAndCheckConnectionMode(): ConnectionMode | undefined {
        if (!this.props.currentPage) return undefined
        if (!this.props.currentPage.payload.common_data) return undefined
        if (!(CommonDataType.CONNECTION_DATA in this.props.currentPage.payload.common_data)) return undefined

        const connectionModes: ConnectionMode[] = cast<ConnectionData>(
            this.props.currentPage.payload.common_data[CommonDataType.CONNECTION_DATA]
        ).connection_modes

        if (connectionModes.length == 0) {
            LOGGER.critical(
                'No connection modes for Locked module ' + this.props.identifier
            )
            return undefined
        }
        if (connectionModes.length > 1) {
            LOGGER.critical(
                'More than 1 connection mode for Locked module ' + this.props.identifier
            )
            return undefined
        }
        return connectionModes[0]
    }


    _render({
        pagePayload: { title, description, button, image_path },
    }: ModuleData<LockedModulePayload, {}>): ReactNode {
        const connectionMode = this._getAndCheckConnectionMode()
        if(connectionMode == undefined){
            return <Fragment/>
        }

        const imgUrl = getPlatformResource(image_path).src
        return <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
            {(match: boolean): JSX.Element => this.buildContent(match, title, description, button, imgUrl, connectionMode)}
        </MediaQuery>
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): LockedModuleDispatchProps => ({
    redirectToAccessByConnectionMode: (connectionMode: ConnectionMode): void => {
        dispatch(
            redirect(
                ROUTE_ACCESS_BY_CONNECTION_MODES, {}, undefined, undefined,
                {
                    defaultConnectionMode: connectionMode,
                }
            )
        );
    },
});

const mapStateToProps = (state: AnyState): LockedModuleStateProps => ({
    currentPage: reducer<ReducerInterface>(state, REDUCER_MODULAR).currentPage,
    userData: reducer<UserDataReducerInterface>(state, REDUCER_USER_DATA).userData,
});

export default setupModule(LockedModule, mapStateToProps, mapDispatchToProps);
