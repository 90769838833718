import { ActionDispatcher, APICallAction } from 'actions/ActionInterface';
import { initSSOAction, InitSSOActionPayload, registerForcedConnectionToken } from 'actions/user/SignInSignUpActions';
import CentralColumn from 'components/core/containers/CentralColumn';
import LinkWithKiro from 'components/core/containers/LinkWithKiro';
import SimpleBox from 'components/core/containers/SimpleBox';
import { LinearLoader } from 'components/core/items/LinearLoader';
import Title from 'components/core/text/Title';
import Button, { ButtonVariant } from "components/designSystem/components/Button";
import WithLogo from 'components/modules/user/legacy/WithLogo';
import LoginForm from "components/modules/user/LoginForm";
import MainComponent, { MainComponentProps, setup, TransDomain, TransKey } from 'components/pages/common/MainComponent';
import { Action, ResourceType } from "core/logging/ActionEvent";
import LOGGER from "core/logging/Logger";
import { AnyState } from 'core/store/Store';
import { EntityType } from 'models/entities/default';
import { EnforcedLocalConnectData, InitSSOAction, InitSSOResponse } from 'models/user/AuthModels';
import React, { ReactNode } from 'react';
import { REDUCER_SIGN_IN_UP } from 'reducers/allReducers';
import { reducer } from 'reducers/selector';


export interface SSOPageProps extends MainComponentProps{
    location: Location;
}

interface TimedInitSSOResponse extends InitSSOResponse{
    timestamp: number
}
export interface SSOPageStateProps {
    initResponse?: TimedInitSSOResponse | null
}

export interface SSOPageDispatchProps {
    initSSO: (
        action: APICallAction<InitSSOActionPayload>,
    ) => void;
    registerForcedConnectionToken: (payload: EnforcedLocalConnectData) => void
}

const CLIENT_ID_KEY = 'client_id';
const SCOPE_KEY = 'scope';
const STATE_KEY = 'state';
const REDIRECT_URI_KEY = 'redirect_uri';
const MAIl_PATIENT = 'patient_mail';

class SSOPage extends MainComponent<SSOPageStateProps & SSOPageDispatchProps & SSOPageProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT_SSO;

    state = {
        forcedView: null,
        lastInitTimestamp: null
    };

    buildAction(): APICallAction<InitSSOActionPayload> {
        const urlParams = new URLSearchParams(this.props.location.search);
        const clientID: string | null = urlParams.get(CLIENT_ID_KEY);
        const scope: string | null = urlParams.get(SCOPE_KEY);
        const state: string | null = urlParams.get(STATE_KEY);
        const redirectURI: string | null = urlParams.get(REDIRECT_URI_KEY);
        const patientMail: string | null = urlParams.get(MAIl_PATIENT);

        if (clientID === null) {
            throw Error(`Missing ${CLIENT_ID_KEY}`)
        }

        if (state === null) {
            throw Error(`Missing ${STATE_KEY}`)
        }

        return initSSOAction(clientID, state, scope, redirectURI, patientMail)
    }

    switchView(view: InitSSOAction): void {

        if (view === InitSSOAction.SUBSCRIBE) {
            LOGGER.userAction(
                {
                    action: Action.CLICK,
                    resource_id: 'sso:no_account',
                    resource_type: ResourceType.FEATURE,
                }
            )
        }

        this.setState({
                forcedView: view,
                lastInitTimestamp: this.props.initResponse ? this.props.initResponse.timestamp : null
            }
        )
    }

    componentDidMount(): void {
        if (!this.props.initResponse) {
            this.props.initSSO(
                this.buildAction()
            )
        }
    }

    validate(redirection_url?: string | null, persistentConnection?: EnforcedLocalConnectData | null): void {

        if (persistentConnection) {
            this.props.registerForcedConnectionToken(persistentConnection)
        }

        if (redirection_url) {
            window.location.replace(redirection_url);
        }
    }

    getLoginAnnotationModule(response: InitSSOResponse, reloadAction: APICallAction<InitSSOActionPayload>): JSX.Element {
        return <div>
            <Title>
                {this.trans('connect_title')}
            </Title>
            <SimpleBox>
                <div>
                    <LinkWithKiro entity={{type: EntityType.PARTNER_CLIENT, id: response.client_info.uuid}}/>
                </div>
                <div>
                    {this.transContent(
                        'connect_login_description',
                        <>
                            0 <strong>1 client</strong> 2 <strong> 3 Kiro </strong> 4
                        </>,
                        { name: response.client_info.name },
                    )}
                </div>

            </SimpleBox>
            <SimpleBox>
                <LoginForm
                    thenPut={reloadAction}
                    prefilledEmail={response.expected_account_mail}
                />
            </SimpleBox>

            <Button variant={ButtonVariant.TERTIARY}
                    onClick={(): void => this.switchView(InitSSOAction.SUBSCRIBE)} fullWidth
            >
                {this.trans('dont_have_account')}
            </Button>
        </div>
    }

    getAcceptAndRedirect(response: InitSSOResponse): JSX.Element {
        if (!response.validation_url) {
            throw Error("Missing validation_url")
        }

        return <div>
            <Title>
                {this.trans('connect_title')}
            </Title>
            <SimpleBox>
                <div>
                    <LinkWithKiro entity={{type: EntityType.PARTNER_CLIENT, id: response.client_info.uuid}}/>
                </div>
                <div>
                    {this.transContent(
                        'accept_and_redirect_description',
                        <>
                            0 <strong>1 client</strong> 2 <strong> 3 Kiro </strong> 4
                        </>,
                        {
                            name: response.client_info.name,
                            email: response.actual_account_mail
                        },
                    )}
                </div>
            </SimpleBox>
            <Button
                onClick={
                    (): void => this.validate(
                        response.validation_url, response.enforced_local_connect_data
                    )
                }
            >
                {this.trans("accept_and_redirect", {"name": response.client_info.name})}
            </Button>
        </div>
    }

    getCheckLogin(response: InitSSOResponse): JSX.Element {
        if (!response.validation_url) {
            throw Error("Missing validation_url")
        }

        return <div>
            <Title>
                {this.trans('connect_title')}
            </Title>
            <SimpleBox>
                <div>
                    <LinkWithKiro entity={{type: EntityType.PARTNER_CLIENT, id: response.client_info.uuid}}/>
                </div>
                <div>
                    {this.transContent(
                        'check_login_description',
                        <>
                            0 <strong>1 client</strong> 2 <strong> 3 Kiro </strong> 4
                        </>,
                        {
                            current: response.actual_account_mail,
                            incoming: response.expected_account_mail,
                            partner: response.client_info.name
                        },
                    )}
                </div>
            </SimpleBox>
            <Button onClick={(): void => this.switchView(InitSSOAction.REDIRECT)}>
                {this.trans("check_login_ok")}
            </Button>
            <br />
            <Button onClick={(): void => this.switchView(InitSSOAction.LOGIN)}>
                {this.trans('check_login_nok')}
            </Button>
        </div>
    }

    getToDisplayModule(response: TimedInitSSOResponse, reloadAction: APICallAction<InitSSOActionPayload>): JSX.Element {
        const last_ts = this.state.lastInitTimestamp;
        const chosen = this.state.forcedView !== null && (
            last_ts === null || last_ts >= response.timestamp
        ) ? this.state.forcedView : response.action;

        switch (chosen) {
            case InitSSOAction.LOGIN:
                return this.getLoginAnnotationModule(response, reloadAction);
            case InitSSOAction.SUBSCRIBE:
                return <div>
                    {this.trans(new TransKey('missing_feature', TransDomain.GLOBAL))}
                </div>;
            case InitSSOAction.CHECK_LOGIN:
                return this.getCheckLogin(response);
            case InitSSOAction.REDIRECT:
                return this.getAcceptAndRedirect(response)
        }

        throw Error('Invalid Display Module ' + chosen)
    }

    render(): ReactNode {

        if (!this.props.initResponse) {
            return <LinearLoader active />;
        }

        const displayModule = this.getToDisplayModule(
            this.props.initResponse,
            this.buildAction()
        );

        return (
            <CentralColumn>
                <WithLogo>
                    {displayModule}
                </WithLogo>
            </CentralColumn>
        );
    }
}


const mapStateToProps = (state: AnyState): SSOPageStateProps => ({
    initResponse: reducer(state, REDUCER_SIGN_IN_UP).ssoInitResponse,
});

const mapDispatchToProps = (dispatch: ActionDispatcher): SSOPageDispatchProps => ({
    initSSO: (
        action: APICallAction<InitSSOActionPayload>,
    ): void => {
        dispatch(
            action,
        );
    },
    registerForcedConnectionToken: (payload: EnforcedLocalConnectData): void => {
        dispatch(
            registerForcedConnectionToken(payload),
        );
    }
});


export default setup(SSOPage, mapStateToProps, mapDispatchToProps);
