import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import React from 'react';

export const Article = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-article'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.85059 3.34448C4.43637 3.34448 4.10059 3.68027 4.10059 4.09448V7.79998C4.10059 8.2142 4.43637 8.54998 4.85059 8.54998H9.05155C9.46576 8.54998 9.80155 8.2142 9.80155 7.79998V4.09448C9.80155 3.68027 9.46576 3.34448 9.05155 3.34448H4.85059ZM5.60059 7.04998V4.84448H8.30155V7.04998H5.60059Z"
                      fill="currentColor"/>
                <path
                    d="M4.10059 9.93982C4.10059 9.52561 4.43637 9.18982 4.85059 9.18982H9.05155C9.46576 9.18982 9.80155 9.52561 9.80155 9.93982C9.80155 10.354 9.46576 10.6898 9.05155 10.6898H4.85059C4.43637 10.6898 4.10059 10.354 4.10059 9.93982Z"
                    fill="currentColor"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.0415 1.26221C1.62729 1.26221 1.2915 1.59799 1.2915 2.01221V13.9063C1.2915 14.3205 1.62729 14.6563 2.0415 14.6563H11.9957L12.0005 14.6563H13.9056C14.3198 14.6563 14.6556 14.3205 14.6556 13.9063V3.75537C14.6556 3.34116 14.3198 3.00537 13.9056 3.00537H12.7504V2.01221C12.7504 1.59799 12.4146 1.26221 12.0004 1.26221H2.0415ZM2.7915 13.1563V2.76221H11.2504V13.1563H2.7915ZM12.7505 13.1563V4.50537H13.1556V13.1563H12.7505Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-article'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.24316 5.10522C6.69088 5.10522 6.24316 5.55294 6.24316 6.10522V11.718C6.24316 12.2703 6.69088 12.718 7.24316 12.718H13.6064C14.1587 12.718 14.6064 12.2703 14.6064 11.718V6.10522C14.6064 5.55294 14.1587 5.10522 13.6064 5.10522H7.24316ZM8.24316 10.718V7.10522H12.6064V10.718H8.24316Z"
                  fill="currentColor"/>
            <path
                d="M6.24316 14.9592C6.24316 14.4069 6.69088 13.9592 7.24316 13.9592H13.6064C14.1587 13.9592 14.6064 14.4069 14.6064 14.9592C14.6064 15.5115 14.1587 15.9592 13.6064 15.9592H7.24316C6.69088 15.9592 6.24316 15.5115 6.24316 14.9592Z"
                fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.98828 1.95117C2.436 1.95117 1.98828 2.39889 1.98828 2.95117V20.9674C1.98828 21.5197 2.436 21.9674 2.98828 21.9674H20.959C21.5113 21.9674 21.959 21.5197 21.959 20.9674V5.59155C21.959 5.03927 21.5113 4.59155 20.959 4.59155H19.0732V2.95117C19.0732 2.39889 18.6255 1.95117 18.0732 1.95117H2.98828ZM19.0732 6.59155V19.9674H19.959V6.59155H19.0732ZM17.0732 3.95117H3.98828V19.9674H17.0732V3.95117Z"
                  fill="currentColor"/>
        </svg>
    )
}

