

import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const FileUpload = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_EXTRA_LARGE){
        return (
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4521 22.2905C13.4521 21.1859 14.3476 20.2905 15.4521 20.2905H24.8546C25.9592 20.2905 26.8546 21.1859 26.8546 22.2905C26.8546 23.395 25.9592 24.2905 24.8546 24.2905H15.4521C14.3476 24.2905 13.4521 23.395 13.4521 22.2905Z" fill="#001948"/>
                <path d="M13.4521 29.1286C13.4521 28.0241 14.3476 27.1286 15.4521 27.1286H18.5475C19.6521 27.1286 20.5475 28.0241 20.5475 29.1286C20.5475 30.2332 19.6521 31.1286 18.5475 31.1286H15.4521C14.3476 31.1286 13.4521 30.2332 13.4521 29.1286Z" fill="#001948"/>
                <path d="M37.7218 29.6858C37.7218 29.1335 37.2741 28.6858 36.7218 28.6858C36.1695 28.6858 35.7218 29.1335 35.7218 29.6858V32.8324H32.5752C32.0229 32.8324 31.5752 33.2801 31.5752 33.8324C31.5752 34.3847 32.0229 34.8324 32.5752 34.8324H35.7218V37.979C35.7218 38.5313 36.1695 38.979 36.7218 38.979C37.2741 38.979 37.7218 38.5313 37.7218 37.979V34.8324H40.8684C41.4207 34.8324 41.8684 34.3847 41.8684 33.8324C41.8684 33.2801 41.4207 32.8324 40.8684 32.8324H37.7218V29.6858Z" fill="#001948"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M14.9135 6.32367C15.1948 4.38985 16.8597 2.9046 18.8716 2.9046H29.1288C31.1406 2.9046 32.8056 4.38985 33.0869 6.32367H36.6764C39.4378 6.32367 41.6764 8.56224 41.6764 11.3237V23.698C45.4213 25.5323 48 29.3812 48 33.8324C48 40.0611 42.9506 45.1105 36.7219 45.1105C36.5245 45.1105 36.3282 45.1054 36.1332 45.0954H11.3237C8.56231 45.0954 6.32373 42.8568 6.32373 40.0954V11.3237C6.32373 8.56224 8.56231 6.32367 11.3237 6.32367H14.9135ZM37.6764 11.3237V22.5941C37.3617 22.5678 37.0434 22.5543 36.7219 22.5543C30.4932 22.5543 25.4438 27.6037 25.4438 33.8324C25.4438 36.5991 26.4401 39.1332 28.0935 41.0954H11.3237C10.7714 41.0954 10.3237 40.6477 10.3237 40.0954V11.3237C10.3237 10.7714 10.7714 10.3237 11.3237 10.3237H14.8716C14.8716 12.5328 16.6624 14.3237 18.8716 14.3237H29.1288C31.338 14.3237 33.1288 12.5328 33.1288 10.3237L36.6764 10.3237C37.2286 10.3237 37.6764 10.7714 37.6764 11.3237ZM18.8716 10.3237H29.1288V6.9046H18.8716V10.3237ZM36.7219 42.1105C41.2938 42.1105 45 38.4043 45 33.8324C45 29.2605 41.2938 25.5543 36.7219 25.5543C32.1501 25.5543 28.4438 29.2605 28.4438 33.8324C28.4438 38.4043 32.1501 42.1105 36.7219 42.1105Z" fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4521 22.2905C13.4521 21.1859 14.3476 20.2905 15.4521 20.2905H24.8546C25.9592 20.2905 26.8546 21.1859 26.8546 22.2905C26.8546 23.395 25.9592 24.2905 24.8546 24.2905H15.4521C14.3476 24.2905 13.4521 23.395 13.4521 22.2905Z" fill="#001948"/>
            <path d="M13.4521 29.1286C13.4521 28.0241 14.3476 27.1286 15.4521 27.1286H18.5475C19.6521 27.1286 20.5475 28.0241 20.5475 29.1286C20.5475 30.2332 19.6521 31.1286 18.5475 31.1286H15.4521C14.3476 31.1286 13.4521 30.2332 13.4521 29.1286Z" fill="#001948"/>
            <path d="M37.7218 29.6858C37.7218 29.1335 37.2741 28.6858 36.7218 28.6858C36.1695 28.6858 35.7218 29.1335 35.7218 29.6858V32.8324H32.5752C32.0229 32.8324 31.5752 33.2801 31.5752 33.8324C31.5752 34.3847 32.0229 34.8324 32.5752 34.8324H35.7218V37.979C35.7218 38.5313 36.1695 38.979 36.7218 38.979C37.2741 38.979 37.7218 38.5313 37.7218 37.979V34.8324H40.8684C41.4207 34.8324 41.8684 34.3847 41.8684 33.8324C41.8684 33.2801 41.4207 32.8324 40.8684 32.8324H37.7218V29.6858Z" fill="#001948"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.9135 6.32367C15.1948 4.38985 16.8597 2.9046 18.8716 2.9046H29.1288C31.1406 2.9046 32.8056 4.38985 33.0869 6.32367H36.6764C39.4378 6.32367 41.6764 8.56224 41.6764 11.3237V23.698C45.4213 25.5323 48 29.3812 48 33.8324C48 40.0611 42.9506 45.1105 36.7219 45.1105C36.5245 45.1105 36.3282 45.1054 36.1332 45.0954H11.3237C8.56231 45.0954 6.32373 42.8568 6.32373 40.0954V11.3237C6.32373 8.56224 8.56231 6.32367 11.3237 6.32367H14.9135ZM37.6764 11.3237V22.5941C37.3617 22.5678 37.0434 22.5543 36.7219 22.5543C30.4932 22.5543 25.4438 27.6037 25.4438 33.8324C25.4438 36.5991 26.4401 39.1332 28.0935 41.0954H11.3237C10.7714 41.0954 10.3237 40.6477 10.3237 40.0954V11.3237C10.3237 10.7714 10.7714 10.3237 11.3237 10.3237H14.8716C14.8716 12.5328 16.6624 14.3237 18.8716 14.3237H29.1288C31.338 14.3237 33.1288 12.5328 33.1288 10.3237L36.6764 10.3237C37.2286 10.3237 37.6764 10.7714 37.6764 11.3237ZM18.8716 10.3237H29.1288V6.9046H18.8716V10.3237ZM36.7219 42.1105C41.2938 42.1105 45 38.4043 45 33.8324C45 29.2605 41.2938 25.5543 36.7219 25.5543C32.1501 25.5543 28.4438 29.2605 28.4438 33.8324C28.4438 38.4043 32.1501 42.1105 36.7219 42.1105Z" fill="currentColor"/>
        </svg>
    )
}
