import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Eye = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-eyes'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.27037 5.35108C3.18625 6.25869 2.36658 7.32479 1.94454 7.9301C1.92674 7.95562 1.9209 7.97958 1.9209 8C1.9209 8.02042 1.92674 8.04438 1.94454 8.0699C2.36658 8.67521 3.18625 9.74131 4.27037 10.6489C5.36297 11.5636 6.64292 12.25 8.00009 12.25C9.35725 12.25 10.6372 11.5636 11.7298 10.6489C12.8139 9.74131 13.6336 8.67521 14.0556 8.0699C14.0734 8.04438 14.0793 8.02042 14.0793 8C14.0793 7.97958 14.0734 7.95562 14.0556 7.9301C13.6336 7.32479 12.8139 6.25869 11.7298 5.35108C10.6372 4.43637 9.35725 3.75 8.00009 3.75C6.64292 3.75 5.36297 4.43637 4.27037 5.35108ZM3.30748 4.20093C4.52524 3.18144 6.13949 2.25 8.00009 2.25C9.86068 2.25 11.4749 3.18144 12.6927 4.20093C13.9189 5.22751 14.8258 6.41198 15.2861 7.07218C15.677 7.63287 15.677 8.36713 15.2861 8.92782C14.8258 9.58802 13.9189 10.7725 12.6927 11.7991C11.4749 12.8186 9.86068 13.75 8.00009 13.75C6.13949 13.75 4.52524 12.8186 3.30748 11.7991C2.08125 10.7725 1.17442 9.58802 0.714096 8.92782C0.323166 8.36713 0.323166 7.63287 0.714096 7.07218C1.17442 6.41198 2.08125 5.22751 3.30748 4.20093ZM8.00009 6.25C7.03359 6.25 6.25009 7.0335 6.25009 8C6.25009 8.9665 7.03359 9.75 8.00009 9.75C8.96658 9.75 9.75009 8.9665 9.75009 8C9.75009 7.0335 8.96658 6.25 8.00009 6.25ZM4.75009 8C4.75009 6.20507 6.20516 4.75 8.00009 4.75C9.79501 4.75 11.2501 6.20507 11.2501 8C11.2501 9.79493 9.79501 11.25 8.00009 11.25C6.20516 11.25 4.75009 9.79493 4.75009 8Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-eyes'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.61071 12C3.01344 12.7871 4.00744 14.5325 5.57727 16.0868C7.18586 17.6794 9.32291 19 11.9999 19C14.6768 19 16.8139 17.6794 18.4225 16.0868C19.9923 14.5325 20.9863 12.7871 21.389 12C20.9863 11.2129 19.9923 9.46745 18.4225 7.91323C16.8139 6.32062 14.6768 5 11.9999 5C9.32291 5 7.18586 6.32062 5.57727 7.91323C4.00744 9.46745 3.01344 11.2129 2.61071 12ZM4.17014 6.49197C5.99898 4.6813 8.61271 3 11.9999 3C15.387 3 18.0008 4.6813 19.8296 6.49197C21.656 8.30018 22.7755 10.3083 23.2048 11.1584C23.4737 11.6909 23.4737 12.3091 23.2048 12.8416C22.7755 13.6917 21.656 15.6998 19.8296 17.508C18.0008 19.3187 15.387 21 11.9999 21C8.61271 21 5.99898 19.3187 4.17014 17.508C2.34377 15.6998 1.22427 13.6917 0.79493 12.8416C0.526039 12.3091 0.526039 11.6909 0.79493 11.1584C1.22427 10.3083 2.34377 8.30018 4.17014 6.49197ZM11.9999 9.25C10.4811 9.25 9.24987 10.4812 9.24987 12C9.24987 13.5188 10.4811 14.75 11.9999 14.75C13.5187 14.75 14.7499 13.5188 14.7499 12C14.7499 10.4812 13.5187 9.25 11.9999 9.25ZM7.24987 12C7.24987 9.37665 9.37652 7.25 11.9999 7.25C14.6232 7.25 16.7499 9.37665 16.7499 12C16.7499 14.6234 14.6232 16.75 11.9999 16.75C9.37652 16.75 7.24987 14.6234 7.24987 12Z"
                  fill="currentColor"/>
        </svg>
    )
}