import React from 'react';

export const EyeIcon = (): JSX.Element => (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M12.0002 3.7002C8.24209 3.7002 5.65825 5.32135 4.02727 7.1562C3.21758 8.0671 2.64321 9.02896
                2.26915 9.8706C1.90549 10.6888 1.7002 11.464 1.7002 12.0002C1.7002 12.5364 1.90549 13.3116
                2.26915 14.1298C2.64321 14.9714 3.21758 15.9333 4.02727 16.8442C5.65825 18.679 8.24209 20.3002
                12.0002 20.3002C15.7583 20.3002 18.3421 18.679 19.9731 16.8442C20.7828 15.9333 21.3572 14.9714
                21.7312 14.1298C22.0949 13.3116 22.3002 12.5364 22.3002 12.0002C22.3002 11.464 22.0949 10.6888
                21.7312 9.8706C21.3572 9.02896 20.7828 8.0671 19.9731 7.1562C18.3421 5.32135 15.7583 3.7002
                12.0002 3.7002ZM9.80026 12.0001C9.80026 10.7849 10.785 9.80015 12.0003 9.80015C13.2155 9.80015
                14.2003 10.7849 14.2003 12.0001C14.2003 13.2154 13.2155 14.2001 12.0003 14.2001C10.785 14.2001
                9.80026 13.2154 9.80026 12.0001ZM12.0003 8.20015C9.90137 8.20015 8.20026 9.90127 8.20026 12.0001C8.20026
                14.099 9.90137 15.8001 12.0003 15.8001C14.0991 15.8001 15.8003 14.099 15.8003 12.0001C15.8003 9.90127
                14.0991 8.20015 12.0003 8.20015Z" fill="#001948"
            />
    </svg>

)

export default EyeIcon;
