import React, { Component, ReactNode } from 'react';

import 'components/legacyDesignSystem/components/TextLoader.scss';

export default class TextLoader extends Component<{}> {
    render(): ReactNode {
        return (
            <div id={'text-loader'} className={'text-loader'}>
                <a className={"legacy-a"} href={'https://loading.io/icon/'} />
            </div>
        );
    }
}
