export function formatUrl(base: string, endpoint: string): string {
    const baseFormatted = base.replace(/\/$/, '');
    const endpointFormatted = endpoint.replace(/\/$/, '');

    return baseFormatted + endpointFormatted;
}

export const objectToQueryParams = (params: { [key: string]: string | number | string[] | number[] }): string => {
    const queryParams: string[] = [];
    for (const key in params) {
        const rv = params[key];
        let v: (string | number)[] = [];
        if (Array.isArray(rv)) {
            v = rv;
        } else {
            v = [rv];
        }

        v.filter((v) => v !== null && v!== undefined)
            .forEach((value) => queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(value)));
    }

    return queryParams.join('&');
};

export const appendQueryParams = (url: string, params = {}): string => {
    const stringParameters = objectToQueryParams(params);

    if (stringParameters === '') {
        return url;
    }

    return url + '?' + stringParameters;
};
