import React from 'react';
import { CountryFlagsProps } from "components/designSystem/assets/countryData";

export const France = (props: CountryFlagsProps): JSX.Element => {

    return (
        <svg width={props.width} height={props.height} viewBox= "0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_223_882)">
                <g clipPath="url(#clip1_223_882)">
                    <path d="M36 22C36 24.209 34.209 26 32 26H24V0H32C34.209 0 36 1.791 36 4V22Z" fill="#EE1100"/>
                    <path d="M4 0C1.791 0 0 1.791 0 4V22C0 24.209 1.791 26 4 26H12V0H4Z" fill="#000091"/>
                    <path d="M12 0H24V26H12V0Z" fill="#EDEDF2"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_223_882">
                    <rect width="36" height="26" rx="4" fill="white"/>
                </clipPath>
                <clipPath id="clip1_223_882">
                    <rect width="36" height="26" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

