import { Action } from 'actions/ActionInterface';

export const NAVIGATION_INIT = 'NAVIGATION_INIT';
export const NAVIGATION_ADD_ITEMS = 'NAVIGATION_ADD_ITEMS';
export const NAVIGATION_CLEAN = 'NAVIGATION_CLEAN';

export type InitNavigationPayload = {
    identifier: string,
    defaultItems: string[],
    fetchPreviousAction?: Action<unknown>,
    fetchNextAction?: Action<unknown>,
};

export const initNavigation = (
    identifier: string,
    defaultItems?: string[],
    fetchPreviousAction?: Action<unknown>,
    fetchNextAction?: Action<unknown>,
): Action<InitNavigationPayload> => {
    return {
        type: NAVIGATION_INIT,
        payload: {
            identifier,
            defaultItems: defaultItems ? defaultItems : [] ,
            fetchPreviousAction: fetchPreviousAction,
            fetchNextAction: fetchNextAction
        },
    };
};


export type NavigationAddItemsPayload = {
    identifier: string,
    newItems: string[],
};

export const navigationAddItems = (
    identifier: string,
    newItems: string[],
): Action<NavigationAddItemsPayload> => {

    return {
        type: NAVIGATION_ADD_ITEMS,
        payload: {
            identifier,
            newItems
        },
    };
};

export type CleanNavigation = {
    identifier: string,
};


export const cleanNavigation = (
    identifier: string,
): Action<CleanNavigation> => {

    return {
        type: NAVIGATION_CLEAN,
        payload: {
            identifier,
        },
    };
};
