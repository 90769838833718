import React, { Component, PropsWithChildren, ReactNode } from 'react';
import 'components/core/text/Description.scss';
import classNames from 'classnames';

export interface DescriptionProps {
    isolated?: boolean;
    italic?: boolean;
}

export default class Description extends Component<PropsWithChildren<DescriptionProps>> {
    render(): ReactNode {
        const { italic, isolated } = this.props;
        return (
            <div
                className={classNames(
                    'description',
                    italic && 'description-italic',
                    isolated && 'description-isolated',
                )}
            >
                {this.props.children}
            </div>
        );
    }
}
