import classNames from "classnames";
import ConnectedNumericalValue from 'components/common/ConnectedNumericalValue';
import { ModuleLoaderWrapper } from 'components/core/items/ModuleLoaderWrapper';
import { colorMapToFillClassDefault } from "components/designSystem/utils/colorMap";
import { Colors } from 'components/legacyDesignSystem/branding/constants';
import Container, {
    ContainerProfile,
    MarginType,
    PaddingType,
} from 'components/legacyDesignSystem/components/Container';
import { Switch } from 'components/legacyDesignSystem/components/Switch';
import TextLoader from 'components/legacyDesignSystem/components/TextLoader';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import BaseModule, { defaultBaseModuleState, ModuleState, setupModule } from 'components/modules/modular/BaseModule';
import 'components/modules/modular/modules/rwe/Table.scss';
import {
    SimpleTableDistributionColumn,
    SimpleTableDistributionDataValueNumerical,
    SimpleTableDistributionDataValueText,
    SimpleTableDistributionDataVariant,
    SimpleTableDistributionLegendItem,
    SimpleTableDistributionLine,
    SimpleTableDistributionModulePagePayload,
    SimpleTableDistributionValueSwitch,
    TableDistributionDataType
} from "models/charts/charts/Table";
import { ModuleData } from 'models/modular/storage';
import React from 'react';

interface LocalState {
    with_value_variants: boolean;
    active_variant?: string;
    values_switch?: SimpleTableDistributionValueSwitch;
    line_double_header: boolean;
}

class SimpleTableDistributionModule extends BaseModule<{}, SimpleTableDistributionModulePagePayload, {}> {
    state: ModuleState<LocalState> = defaultBaseModuleState({
        with_value_variants: false,
        line_double_header: false,
    });

    componentDidMount(): void {
        // Build switch
        if (
            this.props.payload &&
            this.props.payload.pagePayload.values_switch &&
            Object.keys(this.props.payload.pagePayload.values_switch).length > 1
        ) {
            this.setState({ with_value_variants: true });
            this.setState({
                active_variant: Object.values(this.props.payload.pagePayload.values_switch)[0].identifier,
            });
            this.setState({ values_switch: this.props.payload.pagePayload.values_switch });
        }

        // Calculate lines headers count
        if (this.props.payload && this.props.payload.pagePayload.lines) {
            this.props.payload.pagePayload.lines.forEach((line: SimpleTableDistributionLine) => {
                if (!this.state.line_double_header && line.description) {
                    this.setState({ line_double_header: true });
                }
            });
        }
    }

    protected renderSwitch(values_switch: SimpleTableDistributionValueSwitch): JSX.Element {
        const left = Object.values(values_switch)[0];
        const right = Object.values(values_switch)[1];

        return (
            <Switch
                text_left={this.transApiText(left.name)}
                onClickLeft={(): void => this.setState({ active_variant: left.identifier })}
                text_right={this.transApiText(right.name)}
                onClickRight={(): void => this.setState({ active_variant: right.identifier })}
            />
        );
    }

    protected renderVariant(
        variant: SimpleTableDistributionDataValueNumerical | SimpleTableDistributionDataValueText,
    ): JSX.Element {
        switch (variant.type) {
            case TableDistributionDataType.NUMERICAL:
                return <ConnectedNumericalValue value={variant.value} />;
            case TableDistributionDataType.TEXT:
                return this.transApiText(variant.value)
            default:
                return <></>;
        }
    }

    protected renderCellValue(values_by_variant: SimpleTableDistributionDataVariant): JSX.Element {
        if (this.state.with_value_variants && this.state.active_variant) {
            return this.renderVariant(values_by_variant[this.state.active_variant]);
        }
        if (!this.state.with_value_variants) {
            return this.renderVariant(Object.values(values_by_variant)[0]);
        }
        return <></>;
    }

    protected _render(payload: ModuleData<SimpleTableDistributionModulePagePayload, {}>): React.ReactNode {
        const {
            pagePayload: { title, lines, data_by_identifier, hide_column_names, hide_legend, columns, legend, menu },
        } = payload;

        const { line_double_header } = this.state;

        const loading = Object.keys(data_by_identifier).length === 0;

        const displayLoader = payload.twinkle || loading;

        const columnsIndexOrder = columns.map(c => c.identifier);

        const content = (
            <Container profiles={[{ type: PaddingType.P, value: 4 }]}>
                <Container
                    className={'simple-table-distribution__header'}
                    profiles={[
                        ContainerProfile.FLEX_WRAP,
                        ContainerProfile.SPACE_BETWEEN,
                        ContainerProfile.ALIGN_CENTER,
                        { type: MarginType.MB, value: 8 },
                    ]}
                >
                    {title && (
                        <Typography weight={TypographyWeight.BOLD} variant={TypographyVariant.BODY}>
                            {this.transApiText(title)}
                        </Typography>
                    )}
                    <Container
                        profiles={[
                            ContainerProfile.FLEX_WRAP,
                            ContainerProfile.SPACE_BETWEEN,
                            ContainerProfile.ALIGN_CENTER,
                        ]}
                    >
                        {this.state.values_switch && this.renderSwitch(this.state.values_switch)}
                        {menu && this.buildModuleMenu(menu)}
                    </Container>
                </Container>
                <table className={'simple-table-distribution'}>
                    <tbody>
                        {!hide_column_names && (
                            <tr className={'simple-table-distribution__line'}>
                                <th
                                    className={'simple-table-distribution__cell simple-table-distribution__header-cell'}
                                    colSpan={line_double_header ? 2 : 1}
                                ></th>
                                {columns.map((column: SimpleTableDistributionColumn, index: number) => (
                                    <th
                                        key={column.identifier + index}
                                        className={
                                            'simple-table-distribution__cell simple-table-distribution__header-cell'
                                        }
                                    >
                                        <Typography
                                            weight={TypographyWeight.MEDIUM}
                                            variant={TypographyVariant.OVERLINE}
                                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                        >
                                            {this.transApiText(column.name)}
                                        </Typography>
                                        {column.descriptions && (
                                            <Container>
                                                {column.descriptions.map((description, index) => (
                                                    <Typography
                                                        key={index}
                                                        weight={TypographyWeight.MEDIUM}
                                                        variant={TypographyVariant.OVERLINE}
                                                        color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                                    >
                                                        {this.transApiText(description)}
                                                    </Typography>
                                                ))}
                                            </Container>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        )}
                        {lines &&
                            lines.map((line, index) => (
                                <tr key={line.identifier + index} className={'simple-table-distribution__line'}>
                                    <th
                                        className={
                                            'simple-table-distribution__cell simple-table-distribution__header-cell'
                                        }
                                    >
                                        <Typography
                                            weight={TypographyWeight.MEDIUM}
                                            variant={TypographyVariant.OVERLINE}
                                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                        >
                                            {this.transApiText(line.name)}
                                        </Typography>
                                    </th>
                                    {line_double_header && (
                                        <th
                                            className={
                                                'simple-table-distribution__cell simple-table-distribution__header-cell'
                                            }
                                        >
                                            <Typography
                                                weight={TypographyWeight.MEDIUM}
                                                variant={TypographyVariant.OVERLINE}
                                                color={Colors.NEUTRALS_DARK_BLUE_GREY}
                                            >
                                                {line.description ? this.transApiText(line.description) : ''}
                                            </Typography>
                                        </th>
                                    )}
                                    {loading &&
                                        columns.map((c) => (
                                            <td
                                                key={c.identifier}
                                                className={`simple-table-distribution__cell simple-table-distribution__data-cell`}
                                            >
                                                <TextLoader />
                                            </td>
                                        ))}
                                    {data_by_identifier[line.identifier] &&
                                        Object.entries(data_by_identifier[line.identifier]).sort((keyA, keyB) => columnsIndexOrder.indexOf(keyA[0]) - columnsIndexOrder.indexOf(keyB[0])).map((cell, i) => (
                                            <td
                                                key={i}
                                                className={colorMapToFillClassDefault(cell[1].color)}
                                            >
                                                <Typography
                                                    weight={TypographyWeight.BOLD}
                                                    // Hack, in waiting of "simple-table-distribution__cell" is moved in design system and is properly refactored
                                                    color={
                                                        cell[1].color
                                                            ? Colors.NEUTRALS_WHITE
                                                            : Colors.NEUTRALS_DARK_BLUE_GREY
                                                    }
                                                    profiles={[ContainerProfile.ALIGN_TXT_CENTER]}
                                                >
                                                    {this.renderCellValue(cell[1].values_by_variant)}
                                                </Typography>
                                            </td>
                                        ))}
                                </tr>
                            ))}
                    </tbody>
                </table>
                {!hide_legend && legend && legend.length && (
                    <Container
                        profiles={[
                            ContainerProfile.FLEX_WRAP,
                            ContainerProfile.JUSTIFY_CONTENT_CENTER,
                            { type: MarginType.MT, value: 8 },
                        ]}
                    >
                        {legend.map((legend_item: SimpleTableDistributionLegendItem, i) => (
                            <Container
                                key={i}
                                className={'simple-table-distribution__legend_item'}
                                profiles={[
                                    ContainerProfile.ALIGN_CENTER,
                                    { type: MarginType.ML, value: 4 },
                                    { type: MarginType.MR, value: 4 },
                                    { type: MarginType.MB, value: 4 },
                                ]}
                            >
                                <span
                                    className={classNames(
                                        'simple-table-distribution__legend_color',
                                        colorMapToFillClassDefault(legend_item.color)
                                    )}
                                />
                                <Container profiles={[{ type: MarginType.ML, value: 2 }]}>
                                    <span className={'simple-table-distribution__legend_description'}>
                                        {this.transApiText(legend_item.description)}
                                    </span>
                                </Container>
                            </Container>
                        ))}
                    </Container>
                )}
            </Container>
        );

        return (
            <Container profiles={[ContainerProfile.BORDER_MODULE]} style={{ backgroundColor: 'white' }}>
                {displayLoader ? <ModuleLoaderWrapper>{content}</ModuleLoaderWrapper> : content}
            </Container>
        );
    }
}

export default setupModule(
    SimpleTableDistributionModule,
    () => ({}),
    () => ({}),
);
