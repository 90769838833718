import React from 'react';

const LogoCropped = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 46 25">
            <path d="M42.6373 9.24536H17.0287C15.216 9.24536 13.7471 10.7143 13.7471 12.527C13.7471 14.3397 15.216 15.8086 17.0287 15.8086H42.6373C44.45 15.8086 45.9189 14.3397 45.9189 12.527C45.9189 10.7143 44.45 9.24536 42.6373 9.24536Z" fill="url(#paint0_linear_239_3632)"/>
            <path d="M28.8902 17.7207H3.28164C1.46895 17.7207 0 19.1897 0 21.0023C0 22.815 1.46895 24.284 3.28164 24.284H28.8902C30.7029 24.284 32.1719 22.815 32.1719 21.0023C32.1719 19.1897 30.7029 17.7207 28.8902 17.7207Z" fill="url(#paint1_linear_239_3632)"/>
            <path d="M33.8209 0.77002H8.2123C6.39961 0.77002 4.93066 2.23897 4.93066 4.05166C4.93066 5.86435 6.39961 7.33329 8.2123 7.33329H33.8209C35.6336 7.33329 37.1025 5.86435 37.1025 4.05166C37.1025 2.23897 35.6336 0.77002 33.8209 0.77002Z" fill="url(#paint2_linear_239_3632)"/>
            <defs>
                <linearGradient id="paint0_linear_239_3632" x1="13.7454" y1="12.5273" x2="50.4895" y2="12.5273" gradientUnits="userSpaceOnUse">
                    <stop offset="0.3142" stopColor="#00B0F0"/>
                    <stop offset="0.8358" stopColor="#0285D0"/>
                    <stop offset="0.9513" stopColor="#027BC8"/>
                </linearGradient>
                <linearGradient id="paint1_linear_239_3632" x1="0.000913058" y1="21.002" x2="32.1737" y2="21.002" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#027BC8"/>
                    <stop offset="0.1581" stopColor="#0190D8"/>
                    <stop offset="0.3772" stopColor="#00A7E9"/>
                    <stop offset="0.5142" stopColor="#00B0F0"/>
                    <stop offset="0.6562" stopColor="#00A5E8"/>
                    <stop offset="0.9045" stopColor="#0288D2"/>
                    <stop offset="1" stopColor="#027BC8"/>
                </linearGradient>
                <linearGradient id="paint2_linear_239_3632" x1="4.93083" y1="4.05265" x2="37.1037" y2="4.05265" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#027BC8"/>
                    <stop offset="0.1581" stopColor="#0190D8"/>
                    <stop offset="0.3772" stopColor="#00A7E9"/>
                    <stop offset="0.5142" stopColor="#00B0F0"/>
                    <stop offset="0.6562" stopColor="#00A5E8"/>
                    <stop offset="0.9045" stopColor="#0288D2"/>
                    <stop offset="1" stopColor="#027BC8"/>
                </linearGradient>
            </defs>
        </svg>

    )
}

export default LogoCropped;
