import LoginForm from "components/modules/user/LoginForm";
import MainComponent, { MainComponentProps, setup, TransDomain, TransKey } from 'components/pages/common/MainComponent';
import React, { ReactNode } from 'react';
import { AnyState } from 'core/store/Store';
import { ActionDispatcher } from 'actions/ActionInterface';
import { reducer } from 'reducers/selector';
import { REDUCER_SIGN_IN_UP } from 'reducers/allReducers';
import { LinearLoader } from 'components/core/items/LinearLoader';
import 'components/core/buttons/Button.scss';
import { forceConnectionAction } from 'actions/user/SignInSignUpActions';
import WithLogo from 'components/modules/user/legacy/WithLogo';
import Title from 'components/core/text/Title';
import CentralColumn from 'components/core/containers/CentralColumn';
import Information from 'components/core/containers/Information';
import { SeverityType } from 'components/core/types/Types';

export interface EnforcedLocalConnectStateProps {
    fingerprints: {[key: string]: {t: string, d: string | null}},
    fingerprint?: string,
    token?: string
}

export interface EnforcedLocalConnectDispatchProps {
    forceLocalConnection: (
        externalToken: string, localToken: string,
    ) => void;
}

class EnforcedLocalConnect extends MainComponent<MainComponentProps & EnforcedLocalConnectStateProps & EnforcedLocalConnectDispatchProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT_SSO;

    render(): ReactNode {
        if (!this.props.fingerprint || !this.props.token) {
            throw new Error('Missing fingerprint or token');
        }

        if (!Object.hasOwn(this.props.fingerprints, this.props.fingerprint)) {
            return <CentralColumn >
                <br />
                <WithLogo>
                    <Information severity={SeverityType.FAILURE} >
                        {this.trans("force_no_longer_valid")}
                    </Information>
                    <br />
                    <Title>{this.trans(new TransKey('connect', TransDomain.ACCOUNT))}</Title>
                    <LoginForm/>
                </WithLogo>
            </CentralColumn>
        }

        this.props.forceLocalConnection(
            this.props.token,
            this.props.fingerprints[this.props.fingerprint].t
        );


        return <LinearLoader active={true} />
    }
}


const mapStateToProps = (
    state: AnyState,
    ownProps: { match: { params: { fingerprint?: string, token?: string } } },
): EnforcedLocalConnectStateProps => ({
    fingerprints: reducer(state, REDUCER_SIGN_IN_UP).enforcedConnectionElements,
    fingerprint: ownProps.match.params.fingerprint,
    token: ownProps.match.params.token
});

const mapDispatchToProps = (dispatch: ActionDispatcher): EnforcedLocalConnectDispatchProps => ({
    forceLocalConnection: (
        externalToken: string, localToken: string,
    ): void => {
        dispatch(
            forceConnectionAction(externalToken, localToken),
        );
    },
});


export default setup(EnforcedLocalConnect, mapStateToProps, mapDispatchToProps);
