import React, { Fragment, ReactNode } from 'react';
import _ from 'underscore';
import { setup, TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { DisplayDataCondition, GetPageResponse, ModuleDisplayData, ModuleV1, PageContext } from 'models/modular/api';
import { AnyState } from "core/store/Store";
import { reducer } from "reducers/selector";
import { REDUCER_MODULAR } from "reducers/allReducers";
import { ReducerInterface } from 'reducers/modular/ModularReducer';
import { select } from "components/modules/modular/ModuleSelector";
import { SmallSpacer } from "components/designSystem/containers/Spacer";
import { REDUCED_SIDE_BAR_BREAKPOINT } from 'components/core/constants';
import MediaQuery from "react-responsive";

interface ModularStateProps {
    currentPage?: GetPageResponse
}

class SideBarModularContent extends WithTranslations<ModularStateProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    getModuleFromId = (id: string, modules: ModuleV1[]): ModuleV1 | undefined => {
        return modules.find((m) => m.identification.unique_id === id)
    }

    renderModule(
        m: ModuleV1 | undefined,
        displayData: ModuleDisplayData,
        context: PageContext,
        pageId: string,
    ): ReactNode {
        if (m === undefined){
            return <Fragment/>
        }

        return <Fragment key={m.identification.unique_id}>{
            select(
                m.identification.unique_id,
                displayData,
                () => undefined,
                m.type,
                pageId,
                true
            )
        }</Fragment>
    }

    buildContent(currentPage: GetPageResponse, whenReducedSideBar: boolean): ReactNode{
        if (!currentPage.display_data.layout_side_bar || _.isEmpty(currentPage.display_data.layout_side_bar)){
            return <Fragment/>
        }

        return <SmallSpacer>
            {currentPage.display_data.layout_side_bar.map(
                (group) => (
                    group.modules.filter(
                        (module) => {
                            const displayContextConditions = module.display_context?.conditions;
                            const onlyWhenReducedSideBar = displayContextConditions && displayContextConditions.length === 1 &&
                                displayContextConditions[0] === DisplayDataCondition.WHEN_REDUCED_SIDE_BAR
                            return !(!whenReducedSideBar && onlyWhenReducedSideBar)
                        }
                    ).map(
                    (module) => {
                        return this.renderModule(
                            this.getModuleFromId(module.module_id, currentPage.payload.modules),
                            module,
                            currentPage.context,
                            currentPage.page_id,
                        )
                    }
                ))
            )}
        </SmallSpacer>
    }

    render(): ReactNode {
        const {currentPage} = this.props

        if (currentPage === undefined){
            return <Fragment/>
        }

        return <>
            <MediaQuery maxWidth={REDUCED_SIDE_BAR_BREAKPOINT}>
                {this.buildContent(currentPage, true)}
            </MediaQuery>
            <MediaQuery minWidth={REDUCED_SIDE_BAR_BREAKPOINT + 1}>
                {this.buildContent(currentPage, false)}
            </MediaQuery>
        </>
    }
}

const mapStateToProps = (state: AnyState): ModularStateProps => ({
    currentPage: reducer<ReducerInterface>(state, REDUCER_MODULAR).currentPage,
});

const mapDispatchToProps = (): Record<string, never> => ({});

export default setup(SideBarModularContent, mapStateToProps, mapDispatchToProps);
