import CONFIG from 'configuration/globals';
import { Entity } from 'models/entities/default';

export enum LogoType {
    DEFAULT = 'DEFAULT', // rectangle representation of the logo that includes the name
    SYMBOL = 'SYMBOL', // square representation of the logo, as much as possible only symbol
}

export function getEntityImg(
    entity: Entity,
    logoType: LogoType
): string {
    return CONFIG.cdnUrl + '/static/ext/entities/' + entity.type + '/' + logoType + '/' + entity.id + '.png'
}
