import { ModuleAPICallAction } from 'actions/modular/ModularActions';
import { ReportMetadata } from "models/medicalReport/ReportModels";
import { ApiReportInfo } from "models/modular/report";

export const API_FETCH_PATIENT_REPORTS = 'API_FETCH_REPORTS_BY_PATIENT';
export const API_GET_REPORTS_METADATA = 'API_GET_REPORTS_METADATA';

/**
 * Fetch patient reports requires filter can be either a flag to fetch only the latest reports or to fetch a specific
 * report. It generally doesn't make sense to provide both.
 */
export type FetchPatientReportsFilters = {
    onlyLastReports?: boolean
    reportUuid?: string
}

export type FetchPatientReportsPayload = { patientUUIDs: string[] } & FetchPatientReportsFilters

export interface GetReportsMetadataPayload {
    accessTokens: string[],
}


export const fetchPatientReports = (
    moduleIdentifier: string,
    patientUUIDs: string[],
    filters?: FetchPatientReportsFilters
): ModuleAPICallAction<FetchPatientReportsPayload> => {
    return {
        type: API_FETCH_PATIENT_REPORTS,
        payload: {
            params: {patientUUIDs, ...filters},
            moduleIdentifier: moduleIdentifier
        },
    };
};

export const getReportsMetadata = (
    moduleIdentifier: string,
    accessTokens: string[],
): ModuleAPICallAction<GetReportsMetadataPayload> => {
    return {
        type: API_GET_REPORTS_METADATA,
        payload: {
            params: {
                accessTokens,
            },
            moduleIdentifier: moduleIdentifier,
        },
    };
};


export type FetchPatientReportsRequest = {
    patient_uuids: string[]
}

export type FetchPatientReportsResponse = {
    reports_by_patient_uuid: {
        [uuid: string]: ApiReportInfo[]
    }
}


export type GetReportsMetadataRequest = {
    access_tokens: string[]
}

export type GetReportsMetadataResponse = {
    metadata_by_report_uuid: {
        [uuid: string]: ReportMetadata;
    };
}