import { OnSuccessPutType } from "actions/ActionInterface";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { redirect } from "actions/common/CommonActions";
import {
    retrieveCPSRedirectionLocalData,
    initCps,
    deleteCPSRedirectionLocalData
} from "actions/user/SignInSignUpActions";
import TrText from "components/common/TrText";
import { LinearLoader } from "components/core/items/LinearLoader";
import CreateAccountForm from "components/modules/user/CreateAccountForm";
import { KiroCommunicationType } from "components/modules/user/KiroCommunication";
import { LandingScreen } from "components/modules/user/LandingScreen";
import { DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE } from "components/pages/common/APIFeedbackMessage";
import { TransDomain } from "components/pages/common/MainComponent";
import LOGGER from "core/logging/Logger";
import { ROUTE_DEFAULT_MODULAR, ROUTE_LOGIN } from "core/routing/Routes";
import { AnyState } from "core/store/Store";
import { cast } from "core/utils/Typed";
import { APIFormItemWithPayload, APITextPayload } from "models/common/DynamicFormModels";
import { CPSRedirectionLocalData } from "models/user/AuthModels";
import { DoctorInfoFromPscResponse } from "models/user/SubscriptionModels";
import { REDUCER_SIGN_IN_UP } from "reducers/allReducers";
import { reducer } from "reducers/selector";
import { SignInSignUpReducerInterface } from "reducers/user/SignInSignUpFetchReducers";

const AuthCPS = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();

    const urlSearchParams = new URLSearchParams(location.search);
    const code = urlSearchParams.get('code');
    const state_ = urlSearchParams.get('state');

    const signInSignUpData: SignInSignUpReducerInterface = useSelector((state: AnyState) =>
        reducer<SignInSignUpReducerInterface>(state, REDUCER_SIGN_IN_UP)
    )
    const localData : CPSRedirectionLocalData | undefined = signInSignUpData.cpsRedirectionLocalData
    const cpsResponse : DoctorInfoFromPscResponse | undefined = signInSignUpData.cpsResponse

    useEffect(() => {
        if (!localData) {
            dispatch(retrieveCPSRedirectionLocalData())
        }
    }, [])

    useEffect(() => {
        if (localData && !cpsResponse && !!code && !!state_) {
            const isOutdated = new Date(localData.deadline) < new Date(Date.now())
            if (isOutdated) {
                LOGGER.warning(
                    'Outdated CPS redirection local data'
                )
                dispatch(redirect(ROUTE_LOGIN))
            } else {
                dispatch(
                    initCps({code, state: state_, localState: localData.state}, DEFAULT_IDENTIFIER_MAIN_ERROR_PAGE)
                )
            }
        }
    }, [localData, code, state_])

    if (!cpsResponse || !localData) {
        return <LinearLoader active/>;
    }

    let onSuccessActions : OnSuccessPutType = [deleteCPSRedirectionLocalData()]
    onSuccessActions = onSuccessActions.concat(
        localData.onSuccessPut ? localData.onSuccessPut : [redirect(ROUTE_DEFAULT_MODULAR)]
    )

    if (cpsResponse.is_existing_user) {
        onSuccessActions.forEach(
            (action) => dispatch(action)
        )
        return <LinearLoader active/>;
    }

    const doctorInfo = cpsResponse.registration_info
    if (!doctorInfo) {
        LOGGER.critical(
            'doctorInfo should not be None if not existing user'
        )
        return <LinearLoader active/>;
    }

    const rppsItem = doctorInfo.form_data.items.filter((f: APIFormItemWithPayload) => f.key === 'practice_id')[0];
    const rpps = rppsItem && cast<APITextPayload>(rppsItem.payload).default_value;
    const rppsMessage = rpps ? <TrText input={{trkey: 'account.doctor_subscription_explanation', trdata: {rpps}}}>
        <>0<span>1</span>2</>
    </TrText> : undefined

    return <LandingScreen communicationType={KiroCommunicationType.DOCTOR}>
        <CreateAccountForm
            title={<TrText input={{trkey: 'subscribe', trdomain: TransDomain.ACCOUNT}}/>}
            description={rppsMessage}
            token={doctorInfo.token}
            items={doctorInfo.form_data.items}
            eulaValidations={doctorInfo.form_data.eula_validations}
            thenPut={onSuccessActions}
        />
    </LandingScreen>
}

export default AuthCPS
