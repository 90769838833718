export enum UserMode {
    PATIENT = 'PATIENT',
    DOCTOR = 'DOCTOR',
    BIOLOGIST = 'BIOLOGIST',
    ANONYMOUS = 'ANONYMOUS',
    RWE_USER = 'RWE_USER',
}

export type DoctorData = {
    practiceId: string | null;
    businessStructureId: string | null;
    businessStructureType: string | null;
    speciality: string | null;
    businessAddress: string | null;
    businessEmail: string | null;
    businessPhoneNumber: string | null;
};

export type LocalUserData = {
    userModes: UserMode[];
    chosenUserMode: UserMode;
    firstName: string | null;
    lastName: string | null;
    doctorData?: DoctorData;
    uuid?: string;
};
