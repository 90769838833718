import { APICallAction } from 'actions/ActionInterface';
import { Entity } from 'models/entities/default';
import {
    AdditionalValue,
    CategoryNormality,
    CategoryType,
    DataResultTagType,
    Interpretation,
    Recommendation,
    ReportDataHistory,
    ReportHeader,
    Status,
    SumUp,
    SupportedReportDataTypes,
    TechnicalExtraData,
    UserData
} from 'models/medicalReport/LegacyReportModels';

export enum Normality {}
export enum UnitSystem {
    UNKNOWN = 'UNKNOWN',
    UCUM = 'UCUM'
}

export type DataResultTag  = {
    type: DataResultTagType,
    value: string | null
}

export type ReportData = {
    id: string;
    name: string;
    value: SupportedReportDataTypes;
    // todo remove "?"
    additional_value?: AdditionalValue | null;
    historical_data: ReportDataHistory;
    normality: Normality;
    description: string | null;
    extra_information_link: string | null;
    status: Status;
    comments: Comment[];
    interpretation: Interpretation | null;
    technical_extra_data: TechnicalExtraData;
    tags?: DataResultTag[]
};

export type Category = {
    id: string;
    name: string | null;
    type: CategoryType;
    sub_categories: Category[];
    data: ReportData[];
    normality: CategoryNormality;
    icon: string | null;
};

export type Report = {
    header: ReportHeader;
    content: Category[];
};

export type ReportInvoice = {
    payment_link: string | null;
    received_date: Date;
    identifier: string | null;
    amount_due: string;
    currency: string;
};

export enum RatingType {
    SIMPLE_FIVE_STARS = 'SIMPLE_FIVE_STARS'
}

export interface Rating {
    type: RatingType,
    token: string,
    extra_data: Record<string, string>,
}

export interface GetReportResponse {
    report: Report;
    patient: UserData | null;
    sum_up: SumUp;
    recommendations: Recommendation[];
    display_force_kiro_view: boolean;
    report_invoices?: ReportInvoice[];
    rating?: Rating;
}

export interface Laboratory {
    name?: string
    identifier?: string
}

/**
 * We cannot use ModuleReportResultNormality as it's not the same model
 * Maybe we need to rationalize/standardize report normality from data service report and from displayed report
 */
export enum ReportMetadataNormality {
    UNKNOWN = 'UNKNOWN',
    NORMAL = 'NORMAL',
    BAD = 'BAD',
    PARTIALLY_BAD = 'PARTIALLY_BAD',
}


export type ReportMetadata = {
    orders: string[];
    laboratory?: Laboratory;
    entity?: Entity;
    report_data_normality_count?: {[k in ReportMetadataNormality]?: number | null}
};

export type GetReportMetadataResponse = {
    metadata_by_report_uuid: {
        [uuid: string]: ReportMetadata;
    };
};

export interface ReportNavigationType {
    orderedReportUUIDS: string[];
    fetchNewReportUUIDSAction?: APICallAction<{}>;
    originRoute?: string;
}

export interface LogInfo {
    report_uuid: string;
}
export type NumericalValue = {
    value: number | null
    unit: string | null
    unit_system: UnitSystem
    significant_digits: number | null
    raw_numeric_display: string | null
    raw_full_display: string | null
}
