import { stickBody } from 'actions/common/CommonActions';
import 'App.scss';
import MessagePopHeader from 'components/pages/common/MessagePopHeader';
import ErrorHandler from 'components/pages/ErrorHandler';
import 'configuration/i18n';
import { ConnectedRouter } from 'connected-react-router';
import { HttpsRedirect } from 'core/routing/HttpsRedirect';
import { appSwitch } from 'core/routing/Router';
import createStore, { history } from 'core/store/Store';
import 'index.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import 'styles/text.scss';

history.listen(() => window.scrollTo(0, 0));

// Fix to prevent history locked when changing page without closing popup
history.listen(() => {
    stickBody(true);
});

const store = createStore();

const App: React.FC = () => {


    return (
        <HttpsRedirect>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Kiro App</title>
                {/*<link rel="canonical" href="http://mysite.com/example" />*/}
            </Helmet>
            <div className={'default-text'}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <ErrorHandler>
                            <MessagePopHeader>{appSwitch}</MessagePopHeader>
                        </ErrorHandler>
                    </ConnectedRouter>
                </Provider>
            </div>
        </HttpsRedirect>
    );
};

export default App;
