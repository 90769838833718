import { PIN_LENGTH } from 'configuration/settings';
import { isValidEmail } from 'core/utils/Email';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const required = (value: any): string | undefined => {
    return value === undefined || value === null || value === false || value === '' ? 'required' : undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mustChanged = (oldValue: any): any => (value: any): string | undefined => {
    return oldValue !== value ? undefined : 'must_change';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const password = (value: any): string | undefined => {
    const req = required(value);
    if (req) {
        return req;
    }

    if (
        value.length < 10 ||
        !value.match('.*[0-9].*') ||
        //|| !value.match('.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*')
        !value.match('.*[a-z].*') ||
        !value.match('.*[A-Z].*')
    ) {
        return 'insufficient_password_strength';
    }

    return undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pin = (value: any): string | undefined => {
    const req = required(value);
    if (req) {
        return req;
    }

    if (value.length !== PIN_LENGTH || value.match('[^0-9]')) {
        return 'invalid_pin';
    }

    return undefined;
};

export const VALIDATOR_MUST_MATCH = 'must_match';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mustMatch = (value1: any, value2: any): string | undefined => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
        if (value1.length !== value2.length) {
            return VALIDATOR_MUST_MATCH;
        }

        for (let i = 0; i < value1.length; i++) {
            if (value1[i] !== value2[i]) {
                return VALIDATOR_MUST_MATCH;
            }
        }

        return undefined;
    }
    return value1 !== value2 ? VALIDATOR_MUST_MATCH : undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateMail = (value: any): string | undefined => {
    if (typeof value === 'string' && isValidEmail(value)) {
        return undefined
    }
    return 'invalid_email_format'

}