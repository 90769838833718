export enum Errors {
    InvalidToken,
    AuthError,
    ServerError,
    ForbiddenAccess,
    IPNotAllowed,
    MissingResource,
    UnknownError,
    RedirectionError,
    RequestError,
    Conflict,
    EULANotAccepted,
    UnavailableForLegalReason,
    PinLocked,
    MethodNotAllowed,
    PreconditionFailed,
    Needs2FA,
    TooManyRequest,
    BadGateway,
    OutdatedToken,
    Timeout,
    RetryLater,
    ServiceUnavailable,
}

export class CustomError extends Error {
    type: Errors;

    constructor(type: Errors, m?: string | null) {
        super(m === null ? undefined : m);
        this.type = type;
        Object.setPrototypeOf(this, CustomError.prototype);
    }

    is(_type: Errors): boolean {
        return _type === this.type;
    }

    in(_types: Errors[]): boolean {
        for (const t of _types) {
            if (t === this.type) {
                return true;
            }
        }

        return false;
    }

    getType(): Errors {
        return this.type;
    }
}
