import React, { Component, PropsWithChildren, ReactNode } from 'react';
import './BoxCollection.scss';
import { BoxProps } from './Box';
import Title, { TitleType } from 'components/core/text/Title';

export interface BoxCollectionProps {
    boxes?: React.ReactElement<BoxProps>[];
    name?: string;
}

export default class BoxCollection extends Component<PropsWithChildren<BoxCollectionProps>> {
    render(): ReactNode {
        return (
            <div className="box-collection">
                {this.props.name && (
                    <Title type={TitleType.HEADING4} className={'box-collection-name'}>
                        {this.props.name}
                    </Title>
                )}
                <div className={'box-collection-container'}>{this.props.boxes || this.props.children}</div>
            </div>
        );
    }
}
