import { ReactElement, ReactNode } from 'react';
import { Action, APICallAction, OnSuccessPutType } from 'actions/ActionInterface';
import { PopUpStyle } from 'components/core/containers/PopUp';
import { BlockingPopUpComponentIdentifier } from 'components/pages/common/FullPopList';
import { ModalTitle } from "components/designSystem/components/Modal";

export const REDIRECT = 'REDIRECT';

export const CLOSE_POPUP = 'CLOSE_POPUP';
export const OPEN_POPUP = 'OPEN_POPUP';

export const API_RATING_RATE = 'API_RATING_RATE';

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_UPDATE_EXTRA = 'MODAL_UPDATE_EXTRA'

export type RedirectActionPayload = {
    path: string;
    previousPath: string | null;
    params: {};
    previousOnSuccessPut?: OnSuccessPutType;
    state: {};
};

export type RedirectAction = Action<RedirectActionPayload>;

// updating the class of with navbar (using isPopUp open from store) scroll to the top of the page
// In waiting of :
export function stickBody(unstick = false): void {
    if (document.getElementsByTagName('body').length > 0) {
        document.getElementsByTagName('body')[0].style.overflow = unstick ? 'inherit' : 'hidden';
    }
}

export const redirect = (
    path: string,
    params: {} = {},
    previousPath: string | undefined = undefined,
    previousOnSuccessPut: OnSuccessPutType | undefined = undefined,
    state: {} = {}
): RedirectAction => {

    return {
        type: REDIRECT,
        payload: {
            path,
            previousPath: previousPath ? previousPath : window.location.pathname,
            params,
            previousOnSuccessPut,
            state
        },
    };
};

export const closePopUp = (): Action<{}> => {
    stickBody(true);

    return {
        type: CLOSE_POPUP,
        payload: {},
    };
};

export interface PopUpActionPayload {
    content: ReactElement;
    pathname: string;
    style: PopUpStyle | null;
    title: string | null;
    disableStdClose: boolean | null;
}

export const popUp = (
    content: ReactElement,
    style: PopUpStyle | null = null,
    title: string | null = null,
    disableStdClose: boolean | null = null,
): Action<PopUpActionPayload> => {
    stickBody();

    return {
        type: OPEN_POPUP,
        payload: {
            content,
            style,
            title,
            disableStdClose,
            pathname: window.location.pathname,
        },
    };
};

export interface BlockingPopUpActionPayload<T> {
    identifier: BlockingPopUpComponentIdentifier;
    props?: T,
    previousOnSuccessPut: OnSuccessPutType
}

export type ModalExtraTitle = string | ModalTitle;

export type ModalContentBuilder = (
    close: () => void, updateExtra:  (extra: NullableModelExtra) => void
) => ReactNode


export interface BlockingModalPayload {
    identifier: BlockingPopUpComponentIdentifier;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any,
    then?: OnSuccessPutType

    //By introducing SWR we need to have callback that are not an action that will be dispatch
    // (to revalidate after PIN for example)
    // todo : check how we can merge "then" and "callback"
    callback?: () => void
}

export interface ModalExtra {
    title?: ModalExtraTitle;
    fullWidth?: boolean;
    large?: boolean;
    withoutCloseButton?: boolean;
    blockingModalPayload?: BlockingModalPayload
}


export interface NullableModelExtra {
    title?: ModalExtraTitle | null;
    fullWidth?: boolean | null;
    withoutCloseButton?: boolean | null;
    blockingModalPayload?: BlockingModalPayload | null
}

export enum ModalType {
    SIDE_RIGHT = 'SIDE_RIGHT',
    FULL = 'FULL',
    BLOCKING = 'BLOCKING',
    CENTER = 'CENTER'
}

export interface ModalInterface {
    type: ModalType;
    contentBuilder?: ModalContentBuilder,
    extra?: ModalExtra
}

export function modal(
    type: ModalType,
    contentBuilder?: ModalContentBuilder,
    extra?: ModalExtra
): Action<ModalInterface> {
    return {
        type: MODAL_OPEN,
        payload: {
            type,
            contentBuilder,
            extra
        },
    };
}

export function closeModal(): Action<{}> {
    return {
        type: MODAL_CLOSE,
        payload: {}
    };
}

export interface UpdateModalExtraInterface {
    extra: NullableModelExtra;
}

export function updateModalExtra(extra: NullableModelExtra): Action<{}> {
    return {
        type: MODAL_UPDATE_EXTRA,
        payload: {
            extra: extra
        }
    };
}


export type RequestApiWithTokenAction = APICallAction<{ token: string }>;

export const requestApiWithToken = (type: string) => (
    token: string,
    feedbackIdentifier?: string,
): RequestApiWithTokenAction => ({
    type,
    payload: {
        params: {
            token,
        },
        feedbackIdentifier,
    },
});

export type RateAction = APICallAction<{
    rating: string | number,
    token: string,
}>
type rateFunctionType = (rating: string | number, token: string, feedbackIdentifier?: string) => RateAction

export const rate = (type: string): rateFunctionType => {
    return (
        rating: string | number,
        token: string,
        feedbackIdentifier?: string,
    ): RateAction => ({
        type: type,
        payload: {
            params: {
                rating,
                token,
            },
            feedbackIdentifier,
        }
    })
}

export const getFeedbackIdentifier = (suffix: string): string => `FEEDBACK_IDENTIFIER_${suffix}`
