import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ChevronDown = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-chevron-down'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12.0303 5.71967C12.3232 6.01256 12.3232 6.48744 12.0303 6.78033L8.53033 10.2803C8.23744 10.5732 7.76256 10.5732 7.46967 10.2803L3.96967 6.78033C3.67678 6.48744 3.67678 6.01256 3.96967 5.71967C4.26256 5.42678 4.73744 5.42678 5.03033 5.71967L8 8.68934L10.9697 5.71967C11.2626 5.42678 11.7374 5.42678 12.0303 5.71967Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-chevron-down'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.2071 8.79289C18.5976 9.18342 18.5976 9.81658 18.2071 10.2071L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071L5.79289 10.2071C5.40237 9.81658 5.40237 9.18342 5.79289 8.79289C6.18342 8.40237 6.81658 8.40237 7.20711 8.79289L12 13.5858L16.7929 8.79289C17.1834 8.40237 17.8166 8.40237 18.2071 8.79289Z"
                  fill="currentColor"/>
        </svg>
    )
}