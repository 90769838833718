import TrText from "components/common/TrText";
import { FRENCH_CODE } from "components/utils/form/tmpNewSystem/formatters";

import { PIN_LENGTH } from 'configuration/settings';
import { isValidEmail } from "core/utils/Email";
import { FieldValidator } from 'final-form';
import moment, { Moment } from "moment";
import React, { ReactNode } from 'react';

const TRANS_SUFFIX = 'form_errors';
export const regexFrenchDate = /^\d{2}\/\d{2}\/\d{4}$/g;
export const regexFrenchPhone = /^\+33 \d(?: \d{2}){4}$/g


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyValue = any;


export function aggregate(...methods: (FieldValidator<AnyValue> | null)[]): FieldValidator<AnyValue> {
    return (value: AnyValue): ReactNode => {
        const results = methods.filter(
            (m) => m !== null
        ).map(
            // @ts-ignore
            (method) => method(value)
        ).filter(
            (res) => res !== undefined
        ).map(
            (res, i) => <div key={i}>{res}</div>
        )

        if (results.length) {
            return results
        }

        return undefined
    }
}

export const required = (type?: string): FieldValidator<AnyValue> => {
    // eslint-disable-next-line react/display-name
    return (value: AnyValue): ReactNode => {
        if (value === undefined || value === null || value === false || value === '') {
            return <TrText input={{trkey: 'required' + (type ? ('_' + type) : ''), trdomain: TRANS_SUFFIX}}/>
        }

        return undefined
    }
};

export function mustChange<T = string>(oldValue?: T): FieldValidator<AnyValue> {
    // eslint-disable-next-line react/display-name
    return (value: AnyValue): ReactNode => {
        if (value == oldValue) {
            return <TrText input={{trkey: 'must_change', trdomain: TRANS_SUFFIX}}/>
        }
        return undefined
    }
}

export function password(): FieldValidator<AnyValue> {
    // eslint-disable-next-line react/display-name
    return (value: AnyValue): ReactNode => {
        if (
            typeof value !== "string" ||
            value.length < 10 ||
            !value.match('.*[0-9].*') ||
            //|| !value.match('.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*')
            !value.match('.*[a-z].*') ||
            !value.match('.*[A-Z].*')
        ) {
            return <TrText input={{trkey: 'insufficient_password_strength', trdomain: TRANS_SUFFIX}}/>
        }

        return undefined
    }
}

export function pin(): FieldValidator<AnyValue> {
    // eslint-disable-next-line react/display-name
    return (value: AnyValue): ReactNode => {
        if (
            typeof value !== "string" || value.length !== PIN_LENGTH || value.match('[^0-9]')
        ) {
            return <TrText input={{trkey: 'invalid_pin', trdomain: TRANS_SUFFIX}}/>
        }

        return undefined
    }
}

export function dateFormat(dateFormat: string, maxDate?: Moment): FieldValidator<AnyValue> {
    // eslint-disable-next-line react/display-name
    return (value: AnyValue): ReactNode => {
        if (value === undefined || value === '') {
            return undefined
        }

        if (!['DD/MM/YYYY', 'JJ/MM/AAAA'].includes(dateFormat)) {
            return <TrText input={{trkey: 'invalid_format_date', trdomain: TRANS_SUFFIX}}/>
        }

        const isISOFormat = value.toString().match(/^\d{4}-\d{2}-\d{2}/g);
        const formatDateRequiredMatched = value.toString().match(regexFrenchDate);
        if (!formatDateRequiredMatched && !isISOFormat) {
            return <TrText input={{trkey: 'invalid_format_date', trdomain: TRANS_SUFFIX}}/>
        }

        const isoFormat = 'YYYY-MM-DD'
        const dateMoment = moment(value, [dateFormat, isoFormat]);
        if (!dateMoment.isValid()) {
            return <TrText input={{trkey: 'invalid_date', trdomain: TRANS_SUFFIX}}/>
        }

        if (maxDate && dateMoment > maxDate) {
            // TODO: Ideally, should print date in error in same format as expected in input
            return <TrText input={{trkey: 'max_date', trdomain: TRANS_SUFFIX, trdata: {date: maxDate.format('L')}}}/>
        }

        return undefined
    }
}

export function phoneFormat(countryCode?: number): FieldValidator<AnyValue> {
    // eslint-disable-next-line react/display-name
    return (value: AnyValue): ReactNode => {
        const used_country_code = countryCode || FRENCH_CODE

        if (value === undefined || value === '') {
            return undefined
        }

        if (used_country_code === FRENCH_CODE) {
            if (!value.toString().match(regexFrenchPhone)) {
                return <TrText input={{trkey: 'invalid_phone', trdomain: TRANS_SUFFIX}}/>
            }
        }

        return undefined
    }
}

export function validateEmail(): FieldValidator<AnyValue> {
    // eslint-disable-next-line react/display-name
    return (value: AnyValue): ReactNode => {
        if (typeof value === 'string' && isValidEmail(value) || value === undefined) {
            return undefined
        }
        return <TrText input={{trkey: 'invalid_email_format', trdomain: TRANS_SUFFIX}}/>
    }
}
