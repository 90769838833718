export function notEmpty<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyIterator = any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cast<T, F = any>(value: F, checkNotUndefined = true): T {

    if (checkNotUndefined && value === undefined) {
        throw Error("Undefined value not expected ")
    }

    // @ts-ignore
    return value
}
