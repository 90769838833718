import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fr';

// export const formatDateTime = (date: string | Date | undefined | number, locale = 'fr'): string => {
//     if (!date) {
//         return '';
//     }
//
//     switch (locale) {
//         case 'fr':
//             return moment(date).format('DD/MM/YYYY HH:mm');
//         default:
//             return moment(date).format('MM/DD/YYYY HH:mm');
//     }
// };

export const isSameDay = (
    dateA: string | Date | undefined | number | null,
    dateB: string | Date | undefined | number | null,
): boolean => {
    if (!dateA || !dateB) {
        return false;
    }

    const momentA = moment(dateA);
    const momentB = moment(dateB);

    return momentA.isSame(momentB, 'day');
};

function getLocale(locale: string | undefined = undefined): string {
    const loc = locale ?? i18n.language ?? 'fr';
    if (loc == 'en') {
        return 'en-gb'
    }

    return loc
}

export const smartPrettyDate = (date: string | Date | undefined | number, locale: string | undefined = undefined): string => {
    if (!date) {
        return '';
    }

    const chosenLocale = getLocale(locale)

    const moment_ = moment(date);

    let calendarConf: {
        sameElse: string;
        lastWeek: string;
        lastDay: string;
        sameDay: string;
        nextDay: string;
        nextWeek: string;
    } = {
        sameElse: 'LL',
        lastWeek: 'LL',
        lastDay: 'LL',
        sameDay: 'LL',
        nextDay: 'LL',
        nextWeek: 'LL',
    };

    switch (chosenLocale) {
        case 'fr':
            calendarConf = {
                ...calendarConf,
                ...{
                    sameDay: "[Aujourd'hui]",
                    lastDay: '[Hier]',
                },
            };
            break;
        case 'en':
        case 'en-gb':
            calendarConf = {
                ...calendarConf,
                ...{
                    sameDay: '[Today]',
                    lastDay: '[Yesterday]',
                },
            };
            break;
        default:
            break;
    }

    moment_.locale(chosenLocale);

    const formattedDate = moment_.calendar(undefined, calendarConf);

    if (moment_.isSame(moment().startOf('day'), 'year')) {
        if (['en', 'en-gb', 'fr'].includes(chosenLocale)) {
            return formattedDate.replace(/\d{4}/gi, '').replace(',', '');
        }
    }

    return formattedDate;
};

const rawFormatDateTime = (
    date: string | Date | undefined | number | null,
    format: string,
    locale: string | undefined = undefined
): string => {
    if (!date) {
        return '';
    }

    const moment_ = moment(date);
    moment_.locale(getLocale(locale));
    return moment_.format(format);
};

export const formatDate = (
    date: string | Date | undefined | number | null,
    format: string | undefined = undefined,
    locale: string | undefined = undefined,
): string => {
    return rawFormatDateTime(date, format === undefined ? 'L' : format, locale, )
};

export enum DateTimeFormat {
    /**
     * Medium-length date format, with includes the abbreviated month name.
     *
     * Example: "Jan 2, 2019"
     */
    DATE_MEDIUM,
    /**
     * Short date-time format suitable for use within a statement like "Validated on {{date}}", with the date displayed
     * numerically.
     *
     * Example: "01/02/2019 at 12:00 AM"
     */
    DATE_TIME_SHORT_INLINE,
    /**
     * Medium-length date-time format, which includes the abbreviated month name.
     *
     * Example: "2 Jan 2019 - 12:00 AM"
     */
    DATE_TIME_MEDIUM,
    /**
     * Long-length date-time format, which includes the abbreviated weekday.
     *
     * Example: ""
     */
    DATE_TIME_LONG,
}

const getDateTimeShortInlineFormat = (locale: string): string => {
    switch (locale) {
        case 'fr':
            return 'L [à] HH[h]mm';
        case 'en':
        case 'en-gb':
        case 'en-US':
            return 'L [at] LT';
        default:
            return 'L LT'
    }
}

const getDateTimeMediumFormat = (locale: string): string => {
    switch (locale) {
        case 'fr':
            return 'D MMM YYYY - HH[h]mm';
        case 'en':
        case 'en-gb':
        case 'en-US':
            return 'D MMM YYYY - LT';
        default:
            return 'D MMM YYYY - LT'
    }
}

const getDateTimeLong = (locale: string): string => {
    switch (locale) {
        case 'fr':
            return 'ddd D MMM YYYY - HH[h]mm';
        case 'en':
        case 'en-gb':
        case 'en-US':
            return 'ddd D MMM YYYY - LT';
        default:
            return 'ddd D MMM YYYY - LT';
    }
}

const getFormat = (format: string | undefined | DateTimeFormat = undefined, locale: string): string => {
    if (format === undefined) return getDateTimeShortInlineFormat(locale);
    switch (format) {
        case DateTimeFormat.DATE_MEDIUM:
            return 'll';
        case DateTimeFormat.DATE_TIME_SHORT_INLINE:
            return getDateTimeShortInlineFormat(locale);
        case DateTimeFormat.DATE_TIME_MEDIUM:
            return getDateTimeMediumFormat(locale);
        case DateTimeFormat.DATE_TIME_LONG:
            return getDateTimeLong(locale);
        default:
            return format
    }
}

export const formatDateTime = (
    date: string | Date | undefined | number | null,
    format: string | undefined | DateTimeFormat = undefined,
    locale: string | undefined = undefined,
): string => {
    const resolvedLocale = getLocale(locale);
    return rawFormatDateTime(date, getFormat(format, resolvedLocale), resolvedLocale)
};

export const getAge = (date: string | Date | number): number => {
    const ageDifMs = +moment() - +moment(date);
    if (ageDifMs < 0) {
        return 0;
    }
    return moment(ageDifMs).year() - 1970;
};

export const exportedForTesting = {
    getLocale
}
