import { AnyAction, getExtra, getResponse, successful } from "actions/ActionInterface";
import {
    API_GET_ARTICLE_LIST,
    API_SET_PROFILES,
    API_UPDATE_PREGNANT_PROFILE,
    SetPatientBioProfilesRequest
} from "actions/bioProfile/BioProfileFetchActions";
import { ArticleList } from "models/bioProfiles/BioProfileModels";

interface BioProfileFetchReducerState {
    profileUpdateRequest?: SetPatientBioProfilesRequest
    isPregnancyDateUpdated?: boolean
    articleList?: ArticleList;
}

export const initialState: BioProfileFetchReducerState = {};

export default function bioProfileReducers(state = initialState, action: AnyAction): BioProfileFetchReducerState {
    switch (action.type) {
        case successful(API_SET_PROFILES):
            return {
                ...state,
                profileUpdateRequest: getExtra(action)
            }
        case successful(API_UPDATE_PREGNANT_PROFILE):
            return {
                ...state,
                isPregnancyDateUpdated: true
            }
        case successful(API_GET_ARTICLE_LIST):
            return {
                ...state,
                articleList: getResponse<ArticleList>(action)
            }
        default:
            return state;
    }
}