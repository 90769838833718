import { initNavigation } from "actions/common/ConnectedCarousel";
import { GetReportsMetadataRequest, GetReportsMetadataResponse } from "actions/modular/modules/ReportsActions";
import TrText from "components/common/TrText";
import ReportsList, { ReportsListRow } from "components/commonDesign/ReportsList";
import ReportsTable from "components/commonDesign/ReportsTable";
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";
import { Tabs } from "components/designSystem/components/Tabs";
import { WhiteBoxWithShadow } from "components/designSystem/containers/Box";
import Pager, { PagerType } from "components/designSystem/containers/navigation/Pager";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";
import {
    useChangePage,
    useConnectedContextElement,
    useModulePayload
} from "components/modules/modular/ModuleContainer";
import { reportPage } from "components/modules/modular/pages/builders";
import { TransDomain } from "components/pages/common/MainComponent";
import { batchRetrieve } from "components/utils/api";
import { capitalizeFirstLetter } from "core/utils/text";
import { cast } from "core/utils/Typed";
import { ReportMetadata } from "models/medicalReport/ReportModels";
import { ApiReportInfo } from "models/modular/report";
import { UserMode } from "models/user/UserModels";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useUserData } from "reducers/Shortcuts";
import { useAPI } from "sagas/call/swr";
import { FetchPatientReportsEndpoint, GetReportsMetadataEndpoint } from "sagas/endpoints/reports";

interface PatientInfo {
    uuid: string;
    name: string;
}

interface ReportsByPatientModulePayload {
    patients: PatientInfo[];
    with_status_column: boolean;
    reportsByPatientUUID?: {
        [uuid: string]: ApiReportInfo[];
    };
    target_report_uuid: string
}

const CONTEXT_ALIAS_SELECTED_PATIENT = 'selectedPatient';
const REPORT_PER_PAGE_FOR_MOBILE = 5;
const REPORT_PER_PAGE_FOR_DESKTOP = 10;
const TRANS_PREFIX = TransDomain.MODULES + '.reports_by_patient'

const visibleRowsPerPage = (isMobile: boolean): number => {
    return isMobile ? REPORT_PER_PAGE_FOR_MOBILE : REPORT_PER_PAGE_FOR_DESKTOP
}

function updateUUIDMap<T>(newValues: {[uuid: string]: T}, oldValues: {[uuid: string]: T}, updateMethod: (v: {[uuid: string]: T}) => void): void {
    const currentKeys = Object.keys(oldValues) ?? []
    if (Object.keys(newValues).find(
        (k) => !currentKeys.includes(k)
    ) != undefined) {
        updateMethod(
            {
                ...oldValues,
                ...newValues
            }
        )
    }
}


const ReportsByPatientModule = (): JSX.Element => {
    const payload = useModulePayload<ReportsByPatientModulePayload>();
    const isMobile = !useMediaQuery({minWidth: TABLET_MAX_WIDTH_ACCESSIBLE});
    const [selectedPatient, setSelectedPatient] = useConnectedContextElement(
        CONTEXT_ALIAS_SELECTED_PATIENT,
        payload.patients &&  payload.patients.length > 0 ? payload.patients[0].uuid : null
    );

    if (!selectedPatient) {
        // If selectedPatient is null it means that payload.patients is null
        // or that CONTEXT_ALIAS_SELECTED_PATIENT is being updated
        // by useConnectedContextElement
        return <div />
    }

    const [activePageByPatient, setActivePageByPatient] = useState<{[uuid: string]: number}>({});
    if (!Object.hasOwn(activePageByPatient, selectedPatient)) {
        setActivePageByPatient(
            {
                ...activePageByPatient,
                [selectedPatient]: 0
            }
        )
    }

    // We'll record in this state all the reports by patients we retrieves
    const [reportsByPatients, setReportsByPatients] = useState<{[uuid: string]: ApiReportInfo[]}>({});
    // We'll record in this state all the report metadata we retrieves
    const [metadataByReport, setMetadataByReport] = useState<{[uuid: string]: ReportMetadata}>({});

    // if selectedPatient reports have not been retrieved (not in reportsByPatients)
    // we fetch it (useAPI(null) does not not do anything)
    const {data: dataFetchPatientReport, isLoading: fetchPatientReportIsLoading} = useAPI(
        FetchPatientReportsEndpoint,
        {
            patient_uuids: [selectedPatient]
        }
    )


    const newPatientReports = dataFetchPatientReport?.reports_by_patient_uuid
    if (newPatientReports) {
        // if newPatientReports is not None, we update reportsByPatients if needed
        // if all  newPatientReports are already in reportsByPatients we don't update
        // the state to avoid infinite loop loading
        updateUUIDMap(
            newPatientReports,
            reportsByPatients,
            setReportsByPatients
        )
    }

    const {data: dataReportsMetadata} = batchRetrieve(
        GetReportsMetadataEndpoint,
        (r: string[]): GetReportsMetadataRequest => ({access_tokens: r}),
        (r: GetReportsMetadataResponse) => Object.keys(r.metadata_by_report_uuid),
        newPatientReports ?new Map<string, string>(
            cast<[string, string][], (string | undefined)[][]>(
                Object.values(newPatientReports).flat().map(
                    (report) => [report.uuid, report.access_token]
                ).filter(
                    (k) => k[1] != undefined
                )
            )
        ) : undefined
    )

    const newMetadata = dataReportsMetadata?.metadata_by_report_uuid
    if (newMetadata) {
        updateUUIDMap(
            newMetadata,
            metadataByReport,
            setMetadataByReport
        )
    }

    const tabs = payload.patients.map((p: PatientInfo) => ({
        name: p.name,
        id: p.uuid,
        onClick: () => setSelectedPatient(p.uuid),
    }))

    if (!selectedPatient) {
        return <div/>
    }

    const dispatch = useDispatch()
    const changePage =  useChangePage()

    function goToReport(
        reportUUID: string,
        patientUUID: string,
        reports: string[],
        targetReportUUID?: string
    ): void {
        const navigationKey = 'report_patient_' + patientUUID
        dispatch(
            initNavigation(navigationKey, reports)
        )
        const newPage = reportPage(reportUUID, navigationKey, reportUUID == targetReportUUID)
        changePage(
            {
                pageIdentifier: newPage.pageIdentifier,
                pageContext: newPage.pageContext
            }
        )
    }

    const activePage = activePageByPatient[selectedPatient] ?? 0;
    const visibleRows = visibleRowsPerPage(isMobile);
    let reports: ApiReportInfo[] = []
    let visibleReports: ApiReportInfo[] = []
    if (reportsByPatients[selectedPatient]) {
        reports = reportsByPatients[selectedPatient]
        visibleReports = reports
            .slice(activePage * visibleRows, (activePage +1) * visibleRows)
    }


    const formattedTabs = tabs.length > 1 &&
            <WithMargin margins={[isMobile ? MarginSize.SMALL : MarginSize.MEDIUM]}>
                <Tabs fullWidth={true}
                      selectedTab={selectedPatient}
                      tabs={tabs}
                      isMobile={isMobile}
                      actionLabel={
                          <TrText
                              input={{
                                  trkey: 'other_persons',
                                  trdomain: TRANS_PREFIX,
                              }}
                              postTrans={capitalizeFirstLetter}
                          />
                      }/>
            </WithMargin>

    // NB : Pagination works from 1 in Pager, zero here
    // => activePosition={activePage+1} & [selectedPatient]: i-1
    const pager = reports.length > visibleRows &&
        <Pager
            activePosition={activePage+1}
            payload={{
                 type: PagerType.WITH_PAGE_COUNTER,
                 pageSize: visibleRows,
                 totalItemsCount: reports.length,
                 pagerTranslationLabel: <TrText
                    input={{
                        trkey: 'of_pager_key',
                        trdomain: TRANS_PREFIX,
                    }}
                />
            }}
            onChangePage={(i): void => setActivePageByPatient(
                 {
                     ...activePageByPatient,
                     [selectedPatient]: i-1
                 })}
        />

    const userData = useUserData()
    return isMobile ?
        <WhiteBoxWithShadow>
            <WithMargin margins={[MarginSize.MEDIUM, MarginSize.SMALL]}>
                { formattedTabs }
                {
                    fetchPatientReportIsLoading ?
                        <ModuleLoaderWrapper />
                        :
                        <ReportsList
                            rows={
                                visibleReports.map(
                                    (r: ApiReportInfo) => {
                                        const metadata = metadataByReport[r.uuid]
                                        return {
                                            uuid: r.uuid,
                                            laboratory: metadata && metadata.entity,
                                            samplingDate: new Date(r.sampling_date),
                                            indicators: [],
                                            isNew: r.is_new,
                                            normalityCount: metadata?.report_data_normality_count,
                                        }
                                    })
                            }
                            onRowClick={(r: ReportsListRow): void => goToReport(
                                r.uuid,
                                selectedPatient,
                                reports.map(r => r.uuid),
                                payload.target_report_uuid
                            )}
                        />
                }
            </WithMargin>
            <WithMargin margins={[MarginSize.MEDIUM]}>
                {pager}
            </WithMargin>
        </WhiteBoxWithShadow> :
        <WhiteBoxWithShadow>
            { formattedTabs }

            <ReportsTable
                rows={
                    visibleReports.map(
                        (r: ApiReportInfo) => {
                            const metadata = metadataByReport[r.uuid]
                            return {
                                uuid: r.uuid,
                                laboratory: metadata && metadata.entity,
                                samplingDate: (new Date(r.sampling_date)),
                                receptionDate: (new Date(r.reception_date)),
                                labOrders: metadata && metadata.orders,
                                isNew: r.is_new,
                                isSent: r.is_sent,
                                isAiSuggestionsEnriched: r.is_ai_suggestions_enriched,
                                normalityCount: metadata?.report_data_normality_count,

                            }
                        }
                    )
                }
                onRowClick={(r): void => {
                    goToReport(
                        r.uuid,
                        selectedPatient,
                        reports.map(r => r.uuid),
                        payload.target_report_uuid
                    )
                }}
                isDoctor={userData.chosenUserMode == UserMode.DOCTOR}
                withStatusColumn={payload.with_status_column}
            />
            <WithMargin margins={[MarginSize.MEDIUM, MarginSize.LARGE]}>
                {pager}
            </WithMargin>
        </WhiteBoxWithShadow>
}

export default ReportsByPatientModule