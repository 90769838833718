import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";
import * as React from 'react';

const FillBell = (props: IconProps): JSX.Element => {
    switch (props.size) {
        case IconSize.EXTRA_SMALL:
            return (
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M1.00556 5.47476C1.10831 4.24686 1.66779 2.3888 3.81277 1.15039C5.95776 -0.088017 7.84663 0.356494 8.96139 0.881461C9.81529 1.28358 10.3996 1.99638 10.8447 2.61557L12.5452 4.98117C12.8555 5.41287 13.267 5.76185 13.7436 5.9975L16.0824 7.15388C16.3303 7.27648 16.4907 7.52559 16.4996 7.80204C16.5085 8.07849 16.3645 8.33741 16.1249 8.47571L4.00059 15.4757C3.76105 15.614 3.46484 15.6092 3.22988 15.4633C2.99491 15.3174 2.85935 15.0539 2.87715 14.7779L3.04506 12.1743C3.07928 11.6438 2.98281 11.1129 2.76411 10.6283L1.5657 7.97279C1.25202 7.27773 0.926863 6.41532 1.00556 5.47476ZM10.2331 13.9183L10.2321 13.918L10.2296 13.9174L10.2252 13.9163L10.2192 13.9148C10.2163 13.9141 10.2172 13.9143 10.2172 13.9143L10.2192 13.9148C10.2241 13.916 10.2282 13.917 10.2331 13.9183ZM10.2192 13.9148C10.2236 13.9156 10.2338 13.9173 10.2506 13.9194C10.2887 13.924 10.3558 13.9289 10.4455 13.9236C10.6221 13.913 10.8939 13.8619 11.2147 13.6767C11.5355 13.4915 11.7156 13.2816 11.8131 13.134C11.8626 13.059 11.8918 12.9984 11.9069 12.9631C11.9136 12.9475 11.9173 12.9371 11.9188 12.9329C12.0326 12.5426 12.4382 12.313 12.8328 12.4188C13.2329 12.526 13.4704 12.9372 13.3632 13.3373L12.6387 13.1432C13.3632 13.3373 13.3632 13.3373 13.3632 13.3373L13.3628 13.3388L13.3624 13.3403L13.3615 13.3435L13.3595 13.3508L13.3543 13.3684C13.3503 13.3814 13.3453 13.3972 13.3391 13.4154C13.3267 13.4518 13.3095 13.4981 13.2863 13.5524C13.24 13.6608 13.1694 13.8022 13.0649 13.9605C12.8544 14.2793 12.5099 14.6609 11.9647 14.9757C11.4195 15.2905 10.9167 15.398 10.5354 15.4209C10.346 15.4322 10.1883 15.4227 10.0712 15.4086C10.0126 15.4015 9.9639 15.3933 9.9262 15.3858C9.90734 15.3821 9.89119 15.3786 9.87788 15.3755L9.86005 15.3711L9.85275 15.3693L9.84951 15.3684L9.84799 15.368C9.84799 15.368 9.84654 15.3676 10.0407 14.6432L9.84654 15.3676C9.44644 15.2604 9.20901 14.8492 9.31621 14.4491C9.42197 14.0544 9.82427 13.8182 10.2192 13.9148Z"
                          fill="currentColor"
                    />
                </svg>

            )
        default:
            return <div/>
    }
};

export default FillBell;