import { Colors } from 'components/legacyDesignSystem/branding/constants';
import SimpleCard from 'components/legacyDesignSystem/components/cards/SimpleCard';
import Container, { ContainerProfile, MarginType } from 'components/legacyDesignSystem/components/Container';
import { Icon, Icons } from 'components/legacyDesignSystem/components/Icon';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import BaseModule, { setupModule } from 'components/modules/modular/BaseModule';
import { APIText } from 'models/common/message';
import { PageLinkPayload } from 'models/modular/api';

import { ModuleData } from 'models/modular/storage';
import React, { ReactNode } from 'react';

interface LinkCardPagePayload {
    title: APIText;
    link: PageLinkPayload;
    icon: Icons;
    sub_title?: APIText;
    description?: APIText;
    right_text?: APIText;
}

class LinkCard extends BaseModule<{}, LinkCardPagePayload, {}> {

    _render({
        pagePayload: { title, link, icon, sub_title, description, right_text },
    }: ModuleData<LinkCardPagePayload, {}>): ReactNode {
        return (
            <SimpleCard
                onClick={(): void => this.props.changePage({pageIdentifier: link.page_id, pageContext: link.context})}>
                <Container
                    profiles={[ContainerProfile.FLEX, ContainerProfile.SPACE_BETWEEN, ContainerProfile.ALIGN_CENTER]}
                >
                    <Icon
                        width={10}
                        height={10}
                        name={icon}
                        color={Colors.NEUTRALS_DARK_BLUE_GREY}
                        centered
                        withBackground
                    />
                    {right_text && (
                        <Typography
                            weight={TypographyWeight.BOLD}
                            variant={TypographyVariant.OVERLINE}
                            color={Colors.NEUTRALS_BLUE_GREY}
                        >
                            {this.transApiText(right_text)}
                        </Typography>
                    )}
                </Container>
                <Container
                    profiles={[
                        {
                            type: MarginType.MT,
                            value: 4,
                        },
                        {
                            type: MarginType.MB,
                            value: 6,
                        },
                    ]}
                >
                    {title && (
                        <Typography
                            weight={TypographyWeight.BOLD}
                            variant={TypographyVariant.BODY2}
                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                        >
                            {this.transApiText(title)}
                        </Typography>
                    )}
                    {sub_title && (
                        <Typography
                            weight={TypographyWeight.REGULAR}
                            variant={TypographyVariant.CAPTION}
                            color={Colors.NEUTRALS_BLUE_GREY}
                        >
                            {this.transApiText(sub_title)}
                        </Typography>
                    )}
                </Container>
                <Container>
                    {description ? (
                        <Typography
                            weight={TypographyWeight.REGULAR}
                            variant={TypographyVariant.CAPTION}
                            color={Colors.NEUTRALS_DARK_BLUE_GREY}
                        >
                            {this.transApiText(description)}
                        </Typography>
                    ) : (<Typography
                        weight={TypographyWeight.REGULAR}
                        variant={TypographyVariant.CAPTION}
                        color={Colors.NEUTRALS_BLUE_GREY2}
                    >
                        {this.trans('modules.link_card.empty_description')}...
                    </Typography>)}
                </Container>
            </SimpleCard>
        );
    }
}

export default setupModule(
    LinkCard,
    () => ({}),
    () => ({}),
);
