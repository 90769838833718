import React, { ReactNode } from 'react';

import MainComponent, { MainComponentProps, setupRaw, TransDomain } from 'components/pages/common/MainComponent';
import { ROUTE_EULA, ROUTE_EULA_DATA_PRIVACY, ROUTE_FAQ, ROUTE_LEGAL_NOTICE } from 'core/routing/Routes';
import { getRoute } from 'core/routing/Helper';

import 'components/pages/Footer.scss';

export const Separator = (): JSX.Element => (
    <div className={'separator'}>·</div>
)

class Footer extends MainComponent<MainComponentProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    render(): ReactNode {
        return (
            <div className={'page-footer'}>
                <div>© 2019 - 2020 Kiro</div>
                <Separator/>
                <a className={"legacy-a"} target="_blank" rel="noopener noreferrer" href={getRoute(ROUTE_LEGAL_NOTICE, { version: null })}>
                {this.trans('legal_notice')}
                </a>
                <Separator/>
                <a className={"legacy-a"} target="_blank" rel="noopener noreferrer" href={getRoute(ROUTE_EULA, { version: null })}>
                    {this.trans('end_user_license_agreement')}
                </a>
                <Separator/>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getRoute(ROUTE_EULA_DATA_PRIVACY, { version: null })}
                    className={"legacy-a"}
                >
                    {this.trans('data_privacy')}
                </a>
                <Separator/>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={ROUTE_FAQ}
                    className={"legacy-a"}
                >
                    {this.trans('faq')}
                </a>
            </div>
        );
    }
}

export default setupRaw(Footer);
