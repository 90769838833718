import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import MainComponent, { MainComponentProps, setupRaw, TransDomain } from 'components/pages/common/MainComponent';
import React, { ReactNode } from 'react';
import { Form } from 'react-final-form';
import EULAFields from 'components/modules/eula/EULAFields';
import { EULAValidation } from 'models/user/AuthModels';

import 'components/modules/eula/ValidateEULA.scss';

export interface ValidateEULAProps extends MainComponentProps {
    onValidate: () => void;
    validations: EULAValidation[];
}

class ValidateEULA extends MainComponent<ValidateEULAProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT + '.eula';

    render(): ReactNode {
        const { validations } = this.props;
        return (
            <div className={'validate-eula-container'}>
                <div>
                    <Form
                        onSubmit={(): void => this.props.onValidate()}
                        render={({ handleSubmit }): ReactNode => (
                            <form className="legacy-form" onSubmit={handleSubmit}>
                                <div className={'eula-accept'}>
                                    <EULAFields
                                        types={validations.map((v) => ({
                                            eulaType: v.type,
                                            version: v.control_version,
                                            useNewNaming: v.use_new_naming
                                        }))}
                                    />
                                </div>
                                <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} isSubmit fullWidth>
                                    {this.trans('accept_and_sign_in')}
                                </Button>
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default setupRaw(ValidateEULA);
