import { AnyAction, getResponse, successful } from 'actions/ActionInterface';
import { AnyState } from 'core/store/Store';
import { API_ACCOUNT_INFO } from 'actions/user/AccountActions';
import { AccountInfoResponse } from 'models/user/AccountModels';

export const initialState = {};

export default function accountFetchReducers(state = initialState, action: AnyAction): AnyState {
    switch (action.type) {
        case successful(API_ACCOUNT_INFO):
            const accountInfoResponse = getResponse<AccountInfoResponse>(action);
            return { ...state, accountInfo: accountInfoResponse.account_info };
        default:
            return state;
    }
}
