import React from 'react';

const Orange = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 32 32">
            <g clipPath="url(#clip0_1478_23190)">
                <path d="M17.4708 8.23714C17.464 8.19968 17.4572 8.16211 17.4501 8.12509H21.1508C23.8841 8.12509 26.1385 6.03254 26.3795 3.35149C26.4025 3.09561 26.2002 2.87511 25.9433 2.87511H22.0258C19.6641 2.87511 17.6677 4.43414 17.0076 6.57788C16.4145 5.05052 15.5054 4.04761 14.6902 3.40941C13.2128 2.25277 11.7178 2.01909 11.6549 2.00969C11.1771 1.93859 10.7293 2.26775 10.658 2.74567C10.5868 3.22363 10.9141 3.66851 11.392 3.73977C11.5686 3.76815 15.5536 4.4705 15.879 9.62954C15.7909 10.0232 15.4382 10.3167 15.0168 10.3125C14.5332 10.3077 14.1518 9.89717 14.1507 9.41352C14.1501 9.11181 14.1376 8.82372 14.1146 8.54864C14.1012 8.38906 13.9522 8.27603 13.7944 8.30375C8.4982 9.23464 4.47119 13.7053 4.47119 19.0625C4.47119 25.0935 9.57406 30 15.8461 30C22.1182 30 27.2211 25.0935 27.2211 19.0625C27.2211 13.562 22.976 8.99752 17.4708 8.23714Z" />
            </g>
            <defs>
                <clipPath id="clip0_1478_23190">
                    <rect
                        width="23.6923"
                        height="28"
                        fill="white"
                        transform="translate(4 2)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Orange;
