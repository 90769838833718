import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const CheckMark = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-checkmark'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.0303 4.46967C13.3232 4.76256 13.3232 5.23744 13.0303 5.53033L7.03033 11.5303C6.73744 11.8232 6.26256 11.8232 5.96967 11.5303L2.96967 8.53033C2.67678 8.23744 2.67678 7.76256 2.96967 7.46967C3.26256 7.17678 3.73744 7.17678 4.03033 7.46967L6.5 9.93934L11.9697 4.46967C12.2626 4.17678 12.7374 4.17678 13.0303 4.46967Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-checkmark'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.2071 6.79289C19.5976 7.18342 19.5976 7.81658 19.2071 8.20711L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071L4.79289 12.2071C4.40237 11.8166 4.40237 11.1834 4.79289 10.7929C5.18342 10.4024 5.81658 10.4024 6.20711 10.7929L10 14.5858L17.7929 6.79289C18.1834 6.40237 18.8166 6.40237 19.2071 6.79289Z"
                  fill="currentColor"/>
        </svg>
    )
}