import React, { Fragment } from "react";
import { France } from "components/designSystem/assets/countryFlags/France";
import { UnitedKingdom } from "components/designSystem/assets/countryFlags/UnitedKingdom";
import { CountryFlagName } from "components/designSystem/assets/countryData";

export const getCountryFlag = (name: CountryFlagName, height?:string, width?:string) : JSX.Element => {
    height = height ?? '36px'
    width = width ?? '26px'
    switch (name) {
        case CountryFlagName.FRANCE:
            return <France width={width} height={height}/>
        case CountryFlagName.UK:
            return <UnitedKingdom width={width} height={height}/>
        default:
            return <Fragment/>
    }
}

