import { ColorMap } from "components/designSystem/Aliases";
import { SimpleCardStyle } from 'components/legacyDesignSystem/components/cards/SimpleCard';
import { RawColors } from 'components/legacyDesignSystem/branding/constants';

export function toCardStyle(apiStyle: ColorMap): SimpleCardStyle {
    switch (apiStyle) {
        case ColorMap.BLUE:
            return SimpleCardStyle.BLUE
        case ColorMap.ORANGE:
            return SimpleCardStyle.ORANGE
        case ColorMap.PURPLE:
            return SimpleCardStyle.PURPLE
        case ColorMap.GREEN:
            return SimpleCardStyle.GREEN
        default:
            return SimpleCardStyle.WHITE
    }
}

export function toChartColor(apiStyle: ColorMap): ColorMap {
    //  legacy to remove
    return apiStyle
}

export function severityToColor(severity: ColorMap): RawColors {
    switch (severity) {
        case ColorMap.SEVERITY_VERY_HIGH:
            return RawColors.TMP_VERY_HIGH
        case ColorMap.SEVERITY_HIGH:
            return RawColors.TMP_HIGH
        case ColorMap.SEVERITY_MODERATE_HIGH:
            return RawColors.TMP_MODERATE_HIGH
        case ColorMap.SEVERITY_MODERATE:
            return RawColors.TMP_MODERATE
        case ColorMap.SEVERITY_MODERATE_LOW:
            return RawColors.TMP_MODERATE_LOW
        case ColorMap.SEVERITY_LOW:
            return RawColors.TMP_LOW
        case ColorMap.SEVERITY_VERY_LOW:
            return RawColors.TMP_VERY_LOW
        default:
            return RawColors.NEUTRALS_BLUE_GREY2
    }
}