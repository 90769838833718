import React from 'react';

const ArrowRightDown = (): JSX.Element => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.89221 6.89218C7.50404 6.28036 8.49599 6.28036 9.10782 6.89218L22.4333 20.2177L22.4333 11.726C22.4333 10.8608 23.1348 10.1593 24 10.1593C24.8653 10.1593 25.5667 10.8608 25.5667 11.726L25.5667 24C25.5667 24.8652 24.8653 25.5666 24 25.5666L11.726 25.5666C10.8608 25.5667 10.1594 24.8652 10.1594 24C10.1594 23.1347 10.8608 22.4333 11.726 22.4333L20.2177 22.4333L6.89222 9.10778C6.28039 8.49596 6.28039 7.504 6.89221 6.89218Z"
            />
        </svg>
    )
}

export default ArrowRightDown;
