import React from 'react';

import 'components/modules/faq/Faq.scss';
import FaqData from 'components/modules/faq/FaqData'
import FaqItem from 'components/modules/faq/FaqItem'
import Title, { TitleType } from 'components/core/text/Title';
import LogoFull from 'core/content/brand/logos/LogoFull';
import CentralColumn from 'components/core/containers/CentralColumn';
import BoxCollection from 'components/core/containers/BoxCollection';

const Faq = (): JSX.Element => {
    const subjects = [...new Set(FaqData.map(item => item.subject))]

    const urlParams = new URLSearchParams(location.search);
    const faqId: string | null = urlParams.get('faqid');
    
    return (
        <div>
            <CentralColumn>
                <div className="faq-page">
                    <div className="logo-container">
                        <LogoFull />
                    </div>
                    <Title type={TitleType.HEADING1} align="center">
                        FOIRE AUX QUESTIONS (FAQ)
                    </Title>
                    <div className="faq-subjects">
                    {
                        subjects.map(currentSubject => {
                            const itemsList = FaqData.filter(
                                ({subject, validated}) => (
                                    (subject === currentSubject) 
                                    && (validated === true)
                                )
                            )
                            const itemsBoxes = Object.values(itemsList).map(value => {
                                const expand = value.name === faqId
                                return (<FaqItem {...value} key={value.name} expand={expand} />) 
                            })
                            return(
                                itemsList.length > 0 && 
                                <BoxCollection 
                                    key={currentSubject} 
                                    boxes={itemsBoxes} 
                                    name={currentSubject} 
                                />
                            )
                        })
                    }
                    </div>
                </div>
                <p>Toutes les images illustrant cette FAQ sont issues de dossiers de patients fictifs</p>
            </CentralColumn>
        </div>
    );
};

export default Faq;
