import { ConnectionMode } from "models/medicalReport/ConnectionModesModel";
import { PageLinkPayload } from "models/modular/api";

export interface PatientInitials {
    first_name: string | undefined;
    last_name: string | undefined;
}

export interface DisplayData {
    hidden_patient_name: string;
    patient_initials?: PatientInitials;
}

export enum RecipientType {
    HEALTHCARE_PROFESSIONAL = 'HEALTHCARE_PROFESSIONAL',
    OTHER = 'OTHER'
}

export interface GetSharedReportResponse {
    connection_modes: ConnectionMode[];
    redirect_link?: PageLinkPayload;
}