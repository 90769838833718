import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Heart = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-heart'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.34833 8.9455L1.6413 8.23847C0.0792023 6.67637 0.0792024 4.14371 1.6413 2.58161C3.2034 1.01952 5.73606 1.01952 7.29815 2.58161L8.00519 3.28865L8.71223 2.58161C10.2743 1.01951 12.807 1.01951 14.3691 2.58161C15.9312 4.1437 15.9312 6.67636 14.3691 8.23846L13.662 8.9455L13.6621 8.94557L8.00526 14.6024L8.00519 14.6024L8.00512 14.6024L2.34827 8.94557L2.34833 8.9455ZM8.00519 12.481L2.70196 7.17781C1.72565 6.2015 1.72565 4.61858 2.70196 3.64227C3.67827 2.66596 5.26118 2.66596 6.23749 3.64227L6.94453 4.34931L6.9394 4.35443L7.69159 5.09637L8.00446 5.40924L8.00661 5.4071L8.00734 5.40782L9.77289 3.64227C10.7492 2.66596 12.3321 2.66596 13.3084 3.64227C14.2847 4.61858 14.2847 6.20149 13.3084 7.1778L8.00519 12.481Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-heart'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.03976 12.6877C-0.405182 10.2427 -0.405182 6.27867 2.03976 3.83372C4.48471 1.38877 8.44875 1.38877 10.8937 3.83372L12.0003 4.94035L13.107 3.83371C15.5519 1.38876 19.516 1.38876 21.9609 3.83371C24.4058 6.27866 24.4058 10.2427 21.9609 12.6876L13.1072 21.5416C12.4959 22.1528 11.5049 22.1528 10.8937 21.5416L2.03976 12.6877ZM12.0003 19.328L3.69988 11.0275C2.17179 9.49945 2.17179 7.02192 3.69988 5.49383C5.22797 3.96574 7.70549 3.96574 9.23359 5.49383L10.3402 6.60046L10.3322 6.60848L11.5095 7.76973L11.7897 8.05299L11.8034 8.06614C11.9122 8.17051 12.0857 8.16457 12.1871 8.05299L14.7671 5.49382C16.2952 3.96573 18.7727 3.96573 20.3008 5.49382C21.8289 7.02191 21.8289 9.49944 20.3008 11.0275L12.0003 19.328Z"
                  fill="currentColor"/>
        </svg>
    )
}