import { CHART_TEXT_SPACING } from 'components/commonDesign/charts/constants';
import React, { ReactElement, ReactNode } from 'react';
import { CartesianViewBox } from "recharts/types/util/types";

export enum CustomizedLabelType {
    REGULAR = 'REG',
    VERTICAL = 'VER',
}
interface CustomizedLabelProps {
    viewBox?: CartesianViewBox;
    children: ReactNode;
    type?: CustomizedLabelType
}

export const CustomizedJSXLabel = (props: CustomizedLabelProps): ReactElement => {
    const displayType = props.type ? props.type : CustomizedLabelType.REGULAR
    return <foreignObject
        width={props.viewBox ? props.viewBox.width : undefined}
        height={props.viewBox ? props.viewBox.height : undefined}
        x={props.viewBox && props.viewBox.x ? props.viewBox.x - (displayType == CustomizedLabelType.VERTICAL ? CHART_TEXT_SPACING : 0) : undefined}
        y={props.viewBox && props.viewBox.y ? props.viewBox.y + (displayType == CustomizedLabelType.REGULAR ? CHART_TEXT_SPACING + 5 : 0) : undefined}
    >
        <div style={
            displayType == CustomizedLabelType.VERTICAL ?
            {
                height: props.viewBox ? (props.viewBox.height + 'px') : '100%' ,
                verticalAlign: 'middle',
                display: 'table-cell'
        } :
            {
                textAlign: 'center'
            }
        }>
            <div
                style={
                    displayType == CustomizedLabelType.VERTICAL ?
                    {
                        transform: 'rotate(-90deg) translateY(-100%)',
                        fontWeight: 'bold'
                    } : {
                        fontWeight: 'bold'
                    }
                }
                >
                {props.children}
            </div>
        </div>
    </foreignObject>
}
