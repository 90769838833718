export function formatName(firstName?: string | null, lastName?: string | null): string | undefined {
    if (firstName) {
        if (lastName) {
            return firstName + ' ' + lastName
        }

        return firstName
    }

    if (lastName) {
        return lastName
    }

    return undefined
}