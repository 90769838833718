import { APICallSuccessAction, failure, getExtra, getResponse, successful } from 'actions/ActionInterface';
import {
    API_GET_MEDICAL_REPORT_CONNECTION_MODES,
    API_GET_SHARED_REPORT,
    API_GIVE_PATIENT_ACCESS,
} from 'actions/medicalReports/MedicalReportsFetchActions';
import { GetConnectionModesResponse, GivePatientAccessResponse } from 'models/medicalReport/ConnectionModesModel';
import { GetSharedReportResponse } from "models/medicalReport/ReportAccessModel";
import { ReportMetadata } from "models/medicalReport/ReportModels";

export interface MedicalReportsState {
    connectionModes?: {
        success?: GetConnectionModesResponse & {originToken?: string}
        failed?: boolean
    },
    sharedReportResponse?: {
        success?: GetSharedReportResponse
        failed?: boolean
    },
    givePatientAccessResp?: GivePatientAccessResponse & {fromSubscription ?: boolean},
    metadataByReportUUID: Record<string, ReportMetadata>
}

export const initialState : MedicalReportsState = {
    metadataByReportUUID: {},
};

export default function medicalReportsFetchReducers(
    state = initialState, action: APICallSuccessAction
): MedicalReportsState {
    switch (action.type) {
        case successful(API_GET_MEDICAL_REPORT_CONNECTION_MODES):
            const getConnectionModesResponse = getResponse<GetConnectionModesResponse>(action);
            const getConnectionModeExtra = getExtra<{originToken?: string}>(action);
            return {
                ...state,
                connectionModes: {
                    success: {
                        ...getConnectionModesResponse,
                        ...{
                            origin_token: getConnectionModeExtra.originToken
                        }
                    }
                }
            }
        case failure(API_GET_MEDICAL_REPORT_CONNECTION_MODES):
            return {
                ...state,
                connectionModes: {
                    failed: true
                }
            };
        case successful(API_GIVE_PATIENT_ACCESS):
            const givePatientAccessResponse = getResponse<GivePatientAccessResponse>(action);
            const givePatientAccessExtra = getExtra<{fromSubscription?: boolean}>(action);
            return {
                ... state,
                givePatientAccessResp: {
                    ...givePatientAccessResponse,
                    ...{
                        fromSubscription: givePatientAccessExtra.fromSubscription
                    }
                }
            }
        case failure(API_GET_SHARED_REPORT):
            return {
                ...state,
                sharedReportResponse: {
                    failed: true
                }
            }
        case successful(API_GET_SHARED_REPORT):
            const getSharedReportResponse = getResponse<GetSharedReportResponse>(action);

            return {
                ... state,
                sharedReportResponse: {
                    success: getSharedReportResponse
                }
            }
        default:
            return state;
    }
}
