import { APICallAction } from 'actions/ActionInterface';
import { APIText } from "models/common/message";
import { Entity } from 'models/entities/default';

export enum Normality {
    UNKNOWN = 'UNKNOWN',
    NORMAL = 'NORMAL',
    PARTIALLY_BAD = 'PARTIALLY_BAD',
    BAD = 'BAD',
}

export enum Status {
    TODO = 'TODO',
    IN_PROGRESS = 'IN_PROGRESS',
    FINAL = 'FINAL',
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
}

export type UserData = {
    id: string;
    first_name: string;
    last_name: string;
    birth_date?: Date;
    gender?: Gender;
    phone?: string;
    birth_last_name?: string
};

export type Recommendation = {
    recommended_laborders: RecommendedLaborder[];
    is_user_activated: boolean;
    type: string;
};

export type RecommendedLaborder = {
    name: string;
    description: string | null;
};

export enum SumUpItemsSeverity {
    OK = 'OK',
    PARTIALLY_BAD = 'PARTIALLY_BAD',
}

export type SumUpItems = {
    title: string;
    value: Value;
    severity: SumUpItemsSeverity;
};

export type UnsupportedValue = {
    title: string;
    value: string;
};

export type SumUp = {
    items: SumUpItems[];
    unsupported_values: UnsupportedValue[];
};

export enum MethodAccess {
    DOWNLOAD = 'DOWNLOAD',
    DOWNLOAD_BY_UUID = 'DOWNLOAD_BY_UUID',
    EXTRA_SERVER = 'EXTRA_SERVER',
}

export enum LinkedResourceType {
    PDF = 'PDF',
    HPRIM = 'HPRIM',
    HPRIM_MEDECIN = 'HPRIM_MEDECIN',
    PDF_PATIENT = 'PDF_PATIENT',
    PDF_PRESCRIPTION = 'PDF_PRESCRIPTION',
}

export type LinkedResource = {
    access_method: MethodAccess;
    type: LinkedResourceType;
    value: string;
    display_in_app: boolean;
    icon?: string;
    title?: APIText;
};

export enum CategoryType {
    PARENT = 'PARENT_CATEGORY',
    CATEGORY = 'CATEGORY',
    SUB = 'SUB_CATEGORY',
}

export type CategoryNormality = {
    normality: Normality;
    failing_results_count: number;
    total_results_count: number;
};

export type Source = {
    name: string;
    url: string;
};

export type InterpretationRange = {
    name: string;
    content: string;
    actual_case: boolean;
    exact_value?: Value;
    min?: Value;
    max?: Value;
    sources: Source[];
};

export interface InterpretationRuleMatcher {
    exact_value?: Value;
    min?: Value;
    max?: Value;
}

export interface InterpretationRule {
    name: string;
    description: string;
    match: boolean;
    matcher: InterpretationRuleMatcher;
    specific_sources: Source[];
}

export type Interpretation = {
    content: string;
    sources: Source[];
    ranges?: InterpretationRange[];
    global_sources: Source[];
    rules: InterpretationRule[];
};

export enum ValueTrend {
    STABLE = 'STABLE',
    INCREASE = 'INCREASE',
    DECREASE = 'DECREASE',
}

export enum ValueType {
    NUMERICAL = 'NUMERICAL',
    CATEGORICAL = 'CATEGORICAL',
    STRING = 'STRING', // deprecated
}

export type CodedValue = {
    code: string | null;
    wording: string | null;
};
export type Value = {
    value: number | string | CodedValue | null;
    unit?: string;
    type: ValueType;
};

export type Comment = {
    content: string;
};

export type ReferenceScale = {
    items: ReferenceScaleItem[];
};

export enum ReferenceScaleItemType {
    MIN_VALUE = 'MIN_VALUE',
    MAX_VALUE = 'MAX_VALUE',
    PATIENT_VALUE = 'PATIENT_VALUE',
    NORM_MIN_VALUE = 'NORM_MIN_VALUE',
    NORM_MAX_VALUE = 'NORM_MAX_VALUE',
}

export type ReferenceScaleItem = {
    type: ReferenceScaleItemType;
    value: number;
    id: string;
    position: number;
};

export type RandomReportUUID = {
    uuid: string;
};

export type ReportHeader = {
    uuid: string;
    external_id: string;
    laboratory_name?: string;
    laboratory_source: string;
    sampling_datetime: Date | null;
    result_datetime: Date | null;
    linked_resources: LinkedResource[];
    prescribers: string[];
    validators: string[];
    sampler_name: string | null;
    report_annotations?: ReportAnnotation[];
};

export enum ReportAnnotation {
    IN_PROGRESS = 'IN_PROGRESS',
    EMPTY = 'EMPTY',
    WITH_EXTERNAL_ANALYSIS = 'WITH_EXTERNAL_ANALYSIS',
    WITH_CONFIDENTIAL_ANALYSIS = 'WITH_CONFIDENTIAL_ANALYSIS',
    WITH_MISSING_ANALYSIS = 'WITH_MISSING_ANALYSIS',
    REPORT_COFRAC_DISCLAIMER = 'REPORT_COFRAC_DISCLAIMER',
}

export enum ReportDataType {
    NUMERICAL = 'NUMERICAL',
    CATEGORICAL = 'CATEGORICAL',
    ANTIBIOGRAM = 'ANTIBIOGRAM',
    CULTURE = 'CULTURE',
}

export type ReportData_ = {
    type: ReportDataType;
};

export type ReportDataNumerical = ReportData_ & {
    value: Value | null;
    reference_scale: ReferenceScale | null;
};

export type ReportDataCategorical = ReportData_ & {
    value: Value | null;
};

export type ReportDataAntibiogram = ReportData_ & {
    bacteria: {
        name: string;
    };
    antibiotic_sensibilities: {
        name: string;
        value: Value | null;
    }[];
};

export type ReportDataCulture = ReportData_ & {
    culture: {
        name: string;
        value: Value;
    };
    test_results: {
        type: string;
        name: string;
        value: Value;
    }[];
};

export type SupportedReportDataTypes =
    | ReportDataNumerical
    | ReportDataCategorical
    | ReportDataAntibiogram
    | ReportDataCulture;

export type ReportDataPreviousValue = {
    value: SupportedReportDataTypes;
    datetime: Date;
    trend: ValueTrend;
};

export type ReportDataHistoricValue = {
    value: SupportedReportDataTypes;
    datetime: Date;
    is_current_report: boolean;
};

export type ReportDataHistory = {
    previous_value: ReportDataPreviousValue | null;
    history: ReportDataHistoricValue[];
};

export type AdditionalValue = {
    value: Value;
    indication: string | null;
};

export type TechnicalExtraData = {
    test_internal_id: string | null;
};

export enum DataResultTagType {
    NOT_MAPPED = 'NOT_MAPPED',
    NOT_READY = 'NOT_READY'
}

export type DataResultTag  = {
    type: DataResultTagType,
    value: string | null
}

export type ReportData = {
    id: string;
    name: string;
    value: SupportedReportDataTypes;
    // todo remove "?"
    additional_value?: AdditionalValue | null;
    historical_data: ReportDataHistory;
    normality: Normality;
    description: string | null;
    extra_information_link: string | null;
    status: Status;
    comments: Comment[];
    interpretation: Interpretation | null;
    technical_extra_data: TechnicalExtraData;
    tags?: DataResultTag[]
};

export type Category = {
    id: string;
    name: string | null;
    type: CategoryType;
    sub_categories: Category[];
    data: ReportData[];
    normality: CategoryNormality;
    icon: string | null;
};

export type Report = {
    header: ReportHeader;
    content: Category[];
};

export enum RatingType {
    SIMPLE_FIVE_STARS = 'SIMPLE_FIVE_STARS',
    LIKE_DISLIKE = 'LIKE_DISLIKE'
}

export interface Laboratory {
    name?: string
    identifier?: string
}

export type ReportMetadata = {
    orders: string[];
    laboratory?: Laboratory;
    entity?: Entity;

};


export type GetReportMetadataResponse = {
    metadata_by_report_uuid: {
        [uuid: string]: ReportMetadata;
    };
};

export interface ReportNavigationType {
    orderedReportUUIDS: string[];
    fetchNewReportUUIDSAction?: APICallAction<{}>;
    originRoute?: string;
}
