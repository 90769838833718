import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const ThumbsUp = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={"xs-thumbs-up"} width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6.02755 0.750507L6 1.5C6.02755 0.750507 6.02734 0.750499 6.02755 0.750507L6.02944 0.750578L6.03195 0.750679L6.03885 0.750977L6.05993 0.752034C6.07714 0.752969 6.10058 0.754401 6.12962 0.756543C6.18762 0.76082 6.26836 0.767958 6.36656 0.779689C6.56189 0.803022 6.83194 0.845186 7.13268 0.921323C7.70742 1.06683 8.51594 1.36616 9.06944 2.01191C9.76031 2.81792 9.88342 3.92671 9.88122 4.6969C9.88066 4.89396 9.87176 5.08052 9.85864 5.25H12.5901C14.312 5.25 15.6104 6.81441 15.293 8.50679L14.5772 12.3246C14.3131 13.7331 13.0116 14.7027 11.5864 14.5527L3.96063 13.75H1.5C1.08579 13.75 0.75 13.4142 0.75 13V7C0.75 6.58579 1.08579 6.25 1.5 6.25H3.82295L5.25 5.53648V1.5C5.25 1.29631 5.33285 1.10138 5.4795 0.960017C5.62616 0.818656 5.824 0.743024 6.02755 0.750507ZM3.25 7.75H2.25V12.25H3.25V7.75ZM4.75 12.3248L11.7434 13.061C12.3913 13.1291 12.9829 12.6884 13.1029 12.0482L13.8187 8.23036C13.963 7.46109 13.3728 6.75 12.5901 6.75H9C8.77238 6.75 8.55708 6.64663 8.41474 6.46901C8.27266 6.29172 8.21863 6.0596 8.26775 5.83782C8.26784 5.83741 8.26793 5.837 8.26802 5.83658L9 6C8.26802 5.83658 8.26806 5.83641 8.26802 5.83658L8.27085 5.82303C8.27347 5.81026 8.27759 5.78956 8.28274 5.76175C8.29305 5.70606 8.30736 5.62238 8.3219 5.51722C8.35116 5.30555 8.38031 5.01394 8.38123 4.69262C8.38318 4.00932 8.25629 3.36812 7.93056 2.98809C7.68037 2.6962 7.23889 2.49553 6.76455 2.37545C6.75969 2.37422 6.75484 2.373 6.75 2.37179V6C6.75 6.28408 6.5895 6.54378 6.33541 6.67082L4.75 7.46353V12.3248ZM5.97051 2.24942C5.96999 2.2494 5.97026 2.24941 5.97051 2.24942V2.24942Z"
                      fill="currentColor"
                />
            </svg>
        )
    } else if (props.size === IconSize.MEDIUM) {
        return (
            <svg id={"m-thumbs-up"} width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.8094 1.83158L11.7557 3.2609C11.8094 1.83158 11.809 1.83156 11.8094 1.83158L11.8131 1.83172L11.8181 1.83192L11.8318 1.83253L11.8738 1.83469C11.9082 1.8366 11.9551 1.83953 12.0132 1.84391C12.1293 1.85268 12.2911 1.86731 12.488 1.89138C12.8798 1.93925 13.4212 2.02576 14.024 2.18189C15.1805 2.48145 16.7892 3.0943 17.8848 4.40205C19.247 6.02815 19.4937 8.27006 19.4893 9.84506C19.4881 10.2962 19.4659 10.7206 19.4343 11.0991H26.4407C29.1203 11.0991 31.1444 13.5279 30.6614 16.1636L28.7394 26.6516C28.3344 28.8617 26.2931 30.3851 24.0592 30.1445L7.65278 28.3775H2.69693C1.90698 28.3775 1.2666 27.7371 1.2666 26.9472V14.5891C1.2666 13.7992 1.90698 13.1588 2.69693 13.1588H7.38495L10.3254 11.6545V3.2609C10.3254 2.87222 10.4835 2.50029 10.7635 2.23067C11.0434 1.96105 11.421 1.81697 11.8094 1.83158ZM6.29924 16.0194H4.12726V25.5168H6.29924V16.0194ZM9.1599 25.6626L24.3655 27.3003C25.1101 27.3805 25.7906 26.8727 25.9256 26.136L27.8476 15.6479C28.0086 14.7694 27.3339 13.9598 26.4407 13.9598H17.7949C17.362 13.9598 16.9524 13.7637 16.6809 13.4266C16.4097 13.0899 16.3054 12.6486 16.397 12.2262C16.3972 12.2257 16.3973 12.2251 16.3974 12.2245L16.4036 12.1942C16.4091 12.167 16.4175 12.1235 16.4281 12.0653C16.4492 11.9486 16.4783 11.7741 16.5079 11.5551C16.5674 11.1145 16.6268 10.5071 16.6287 9.83708C16.6327 8.41836 16.3761 7.05583 15.6919 6.2391C15.1696 5.61561 14.262 5.19862 13.3066 4.95115C13.2661 4.94065 13.2259 4.93056 13.186 4.92086V12.5294C13.186 13.0665 12.8852 13.5582 12.4071 13.8028L9.1599 15.464V25.6626ZM11.6982 4.69007C11.6971 4.69002 11.6978 4.69005 11.6982 4.69007V4.69007Z"
                      fill="currentColor"
                />
            </svg>
        )
    }
    return (
        <svg id={"s-thumbs-up"} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.37092 2.00071L9.33333 3C9.37092 2.00071 9.37063 2.0007 9.37092 2.00071L9.3735 2.00081L9.37698 2.00095L9.38655 2.00137L9.41592 2.00288C9.43993 2.00422 9.47272 2.00626 9.51337 2.00933C9.59456 2.01546 9.7077 2.02569 9.84536 2.04251C10.1192 2.07599 10.4978 2.13647 10.9192 2.24562C11.7277 2.45506 12.8525 2.88353 13.6184 3.79782C14.5708 4.9347 14.7433 6.5021 14.7402 7.60325C14.7394 7.91868 14.7238 8.21538 14.7018 8.48H19.6002C21.4736 8.48 22.8887 10.178 22.551 12.0208L21.2073 19.3534C20.9241 20.8985 19.497 21.9636 17.9352 21.7954L6.46483 20.56H3C2.44772 20.56 2 20.1123 2 19.56V10.92C2 10.3677 2.44772 9.92 3 9.92H6.27758L8.33333 8.86832V3C8.33333 2.72826 8.44392 2.46823 8.63964 2.27973C8.83537 2.09123 9.09938 1.99049 9.37092 2.00071ZM5.51852 11.92H4V18.56H5.51852V11.92ZM7.51852 18.6619L18.1494 19.8069C18.67 19.8629 19.1457 19.5079 19.2401 18.9929L20.5838 11.6603C20.6964 11.046 20.2247 10.48 19.6002 10.48H13.5556C13.2529 10.48 12.9665 10.3429 12.7767 10.1072C12.5871 9.87183 12.5142 9.56335 12.5783 9.26802C12.5784 9.26762 12.5785 9.26722 12.5785 9.26682L12.5829 9.24561C12.5867 9.22662 12.5926 9.19618 12.6 9.15547C12.6147 9.07395 12.6351 8.95192 12.6558 8.79879C12.6974 8.49077 12.7389 8.06613 12.7402 7.59767C12.743 6.60579 12.5637 5.65319 12.0853 5.08218C11.7201 4.64627 11.0856 4.35474 10.4177 4.18173C10.3893 4.17438 10.3612 4.16733 10.3333 4.16054V9.48C10.3333 9.85545 10.123 10.1993 9.78877 10.3703L7.51852 11.5317V18.6619ZM9.29316 3.99919C9.29238 3.99916 9.29286 3.99918 9.29316 3.99919V3.99919Z"
                  fill="currentColor"
            />
        </svg>
    )
}