import { ActionDispatcher, OnSuccessPutType } from "actions/ActionInterface";
import { createAccount } from "actions/user/SignInSignUpActions";
import Form, { FormState } from "components/commonDesign/form/tmpNewSystem/Form";
import { FormItem } from "components/commonDesign/form/tmpNewSystem/FormItemBuilder";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { MediumSpacer, XLargeSpacer, XSmallSpacer } from "components/designSystem/containers/Spacer";
import { TBody, TDisplaySmall700, TypographyColor } from "components/designSystem/containers/Typography";
import { EULAFields } from "components/modules/modular/components/EULAFields";
import FormWithDataPrivacyPopUp from "components/modules/modular/components/FormWithDataPrivacyPopUp";
import APIFeedbackMessageContainer from "components/pages/common/APIFeedbackMessage";
import { TransDomain } from 'components/pages/common/MainComponent';
import WithTranslations from 'components/pages/common/WithTranslations';
import { Record } from "immutable";
import { APIFormItemWithPayload, FormItemWithPayload } from "models/common/DynamicFormModels";
import { APIText } from "models/common/message";
import { EULAType, EULAValidation } from "models/user/AuthModels";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import _ from "underscore";

export const FEEDBACK_IDENTIFIER_API_CREATE_ACCOUNT = 'FEEDBACK_IDENTIFIER_API_CREATE_ACCOUNT';

interface CreateAccountFormProps {
    token: string;
    thenPut?: OnSuccessPutType;
    items: APIFormItemWithPayload[];
    eulaValidations: EULAValidation[];
}

export interface CreateAccountDispatchProps {
    createAccount: (data: Record<string, string>, token: string, onSuccessAction?: OnSuccessPutType, feedbackIdentifier?: string) => void;
}

interface CreateAccountProps extends CreateAccountFormProps, CreateAccountDispatchProps {
    title?: ReactNode;
    description?: ReactNode;
}

class CreateAccountForm extends WithTranslations<CreateAccountProps> {
    TRANS_SUFFIX = TransDomain.ACCOUNT

    onSubmit = (data: Record<string, string>, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string): void => {
        let onSuccess = this.props.thenPut ? this.props.thenPut : [];

        if(onSuccessPut !== undefined){
            onSuccess = [...onSuccessPut, ...onSuccess];
        }

        this.props.createAccount(
            data, this.props.token, onSuccess, feedbackIdentifier
        )
    }

    buildFormItemWithPayload = (items: APIFormItemWithPayload[]): FormItemWithPayload[] => (
        items.map((item: APIFormItemWithPayload) => {
            const label: APIText | undefined = item.payload.label
            return {
                ...item,
                payload: {
                    ...item.payload,
                    label: label
                }
            }
        })
    )

    render(): ReactNode {
        const {items, eulaValidations} = this.props
        const transformedItems = this.buildFormItemWithPayload(items)

        const formattedItems: JSX.Element[] = transformedItems.sort(
            (el1: FormItemWithPayload, el2: FormItemWithPayload) => el1.rank - el2.rank,
        ).map(
            (item: FormItemWithPayload, index) => <FormItem key={index} formItem={item}/>
        )

        const inReportEulaValidations: EULAValidation[] = eulaValidations.filter(value => value.type !== EULAType.DATA_PRIVACY_NOTICED)
        const notInReportEulaValidations: EULAValidation[] = eulaValidations.filter(value => value.type === EULAType.DATA_PRIVACY_NOTICED)

        const formContent = (formState: FormState): JSX.Element => {
            return (
                <XLargeSpacer>
                    <MediumSpacer>
                        {formattedItems.length > 0 && (
                            <MediumSpacer>
                                {formattedItems}
                            </MediumSpacer>
                        )}
                        {!_.isEmpty(inReportEulaValidations) && (
                            <EULAFields
                                types={inReportEulaValidations.map((v: EULAValidation) => ({
                                    eulaType: v.type,
                                    version: v.control_version,
                                    useNewNaming: v.use_new_naming
                                }))}
                            />
                        )}
                    </MediumSpacer>
                    <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}
                            isSubmit fullWidth disabled={formState.alreadySubmitted}
                    >
                        {this.trans('subscribe')}
                    </Button>
                </XLargeSpacer>
            )
        }

        return <div>
            <XLargeSpacer>
                <APIFeedbackMessageContainer identifier={FEEDBACK_IDENTIFIER_API_CREATE_ACCOUNT} withNewDesignSystem/>
                {(this.props.title !== undefined || this.props.description !== undefined) && (
                    <XSmallSpacer>
                        {this.props.title && <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                            {this.props.title}
                        </TDisplaySmall700>}
                        {this.props.description && <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>
                            {this.props.description}
                        </TBody>}
                    </XSmallSpacer>
                )}
                {_.isEmpty(notInReportEulaValidations) ? <Form onSubmit={(data: Record<string, string>) : void =>
                        this.onSubmit(data, undefined, FEEDBACK_IDENTIFIER_API_CREATE_ACCOUNT)
                    }>{formContent}</Form> :
                    <FormWithDataPrivacyPopUp dataPrivacy={notInReportEulaValidations} onSubmit={this.onSubmit}>
                        {formContent}
                    </FormWithDataPrivacyPopUp>
                }
            </XLargeSpacer>
        </div>
    }
}

const mapStateToProps = (): {} => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): CreateAccountDispatchProps => ({
    createAccount: (data: Record<string, string>, token: string, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string): void => dispatch(
        createAccount(token, data, feedbackIdentifier, onSuccessPut)
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm);
