import { apiCallSuccessAction, CallbackAction } from "actions/ActionInterface";
import { pushAPIFeedbackMessage } from 'actions/common/APIMessaginActions';
import { modal, ModalType } from 'actions/common/CommonActions';
import { connectedButtonFeedback, ConnectedButtonFeedbackType } from 'actions/common/ConnectedButton';
import { API_RETRIEVE_AUTH_USER_DATA, signOut } from 'actions/user/SignInSignUpActions';
import { BlockingPopUpComponentIdentifier } from 'components/pages/common/FullPopList';
import { TransDomain, TransKey } from 'components/pages/common/MainComponent';
import { ErrorMessageType } from 'components/pages/errors/Page500';
import { cast } from "core/utils/Typed";
import { APIFeedbackMessageType } from 'models/common/APIMessaging';
import { call, delay, put as putSaga } from 'redux-saga/effects';
import CallHandlerBase, { ErrorFeedbackRule, FeedbackConfiguration } from 'sagas/api/CallHandlerBase';
import { CustomError, Errors } from 'sagas/api/rest/Exceptions';

const MAX_RETRIES = 5;

/**
 * @deprecated, use sagaPost and sagaGet in sagas/call/saga instead
 */
export default class CallHandler<T, R> extends CallHandlerBase<T, R> {
    *call(): Generator<unknown, R | null, R | null> {

        let count = 0;
        let feedbackIdentifier =
            this.action && this.action.payload.feedbackIdentifier ? this.action.payload.feedbackIdentifier : null;
        const defaultSuffix = TransDomain.MESSAGES + '.default';

        while (count++ < MAX_RETRIES) {
            try {
                let response;

                if (!this.action || !this.action.payload.params) {
                    // Find a way to improve this (if action is undefined => fn(undefined) is "safe"
                    // @ts-ignore
                    response = yield call(this.fn, undefined, count-1);
                } else {
                    response = yield call(this.fn, this.action.payload.params, count-1);
                }

                if (response) {
                    if (typeof response === 'object' && 'auth_data' in response){
                        // @ts-ignore
                        yield putSaga(apiCallSuccessAction(API_RETRIEVE_AUTH_USER_DATA, response["auth_data"]))
                    }

                    if (this.action && this.action.payload.onSuccessPut && this.action.payload.onSuccessPut.length > 0) {
                        for (const item of this.action.payload.onSuccessPut) {
                            if (item.type === 'CALLBACK_ACTION') {
                                cast<CallbackAction>(item).payload.callback()
                            } else {
                                yield putSaga(item);
                            }
                        }
                    }

                    if (this.action && this.action.connectedButtonPayload) {
                        yield putSaga(
                            connectedButtonFeedback(
                                this.action.connectedButtonPayload,
                                ConnectedButtonFeedbackType.SUCCEED
                            )
                        )
                    }
                }

                return response;
            } catch (e) {

                if (!(e instanceof CustomError)) {
                    yield putSaga(
                        pushAPIFeedbackMessage(
                            feedbackIdentifier,
                            new TransKey(ErrorMessageType.SERVER, defaultSuffix),
                            APIFeedbackMessageType.ERROR,
                            this.fallbackFeedbackCatcher,
                            this.action,
                        ),
                    );

                    return null;
                }

                if (e.is(Errors.RetryLater)) {
                    const delay_in_ms = (+(new Date(e.message)) - +Date.now())
                    if (delay_in_ms > 0) {
                        yield delay(delay_in_ms)
                    } else {
                        return null
                    }

                    continue
                }

                if (this.action && this.action.connectedButtonPayload) {
                    yield putSaga(
                        connectedButtonFeedback(
                            this.action.connectedButtonPayload,
                            ConnectedButtonFeedbackType.FAILED
                        )
                    )
                }

                if (this.forwardAllErrors || e.in(this.forwardedErrors ? this.forwardedErrors : [])) {
                    throw e;
                }

                if (e.is(Errors.EULANotAccepted)) {
                    const eulaMessage = JSON.parse(e.message);

                    yield putSaga(
                        modal(
                            ModalType.BLOCKING,
                            undefined,
                            {
                                blockingModalPayload: {
                                    identifier: BlockingPopUpComponentIdentifier.EULA,
                                    props: {
                                        token: eulaMessage.token,
                                        validations: eulaMessage.validations,
                                    },
                                    then: this.action && !this.action.payload.preventRetryOnError ? [this.action]  : (
                                        this.action ? this.action.payload.onSuccessPut : undefined
                                    )
                                }}
                        )
                    );

                    return null;
                }

                if (e.is(Errors.PinLocked)) {
                    yield putSaga(
                        modal(
                            ModalType.BLOCKING,
                            undefined,
                            {
                                blockingModalPayload: {
                                    identifier: BlockingPopUpComponentIdentifier.PIN,
                                    props: {},
                                    then: this.action && !this.action.payload.preventRetryOnError ? [this.action] : (
                                        this.action ? this.action.payload.onSuccessPut : undefined
                                    )
                                }}
                        )
                    );
                    return null;
                }

                if (e.is(Errors.InvalidToken)) {
                    yield putSaga(signOut(window.location.href.substr(window.location.origin.length)));
                    return null;
                }

                let message: string | TransKey = new TransKey(ErrorMessageType.FORBIDDEN, defaultSuffix);

                if (e.is(Errors.ServerError)) {
                    message = new TransKey(ErrorMessageType.SERVER, defaultSuffix);
                } else if (e.is(Errors.ForbiddenAccess)) {
                    message = new TransKey(ErrorMessageType.FORBIDDEN, defaultSuffix);
                } else if (e.is(Errors.BadGateway)) {
                    message = new TransKey(ErrorMessageType.BADGATEWAY, defaultSuffix);
                } else if (e.is(Errors.OutdatedToken)) {
                    message = new TransKey(ErrorMessageType.OUTDATED_RESOURCE, defaultSuffix);
                } else {
                    message = new TransKey(ErrorMessageType.SERVER, defaultSuffix);
                }

                if (e.message !== '') {
                    message = new TransKey(e.message, TransDomain.MESSAGES);
                }

                const matchedRules = this.feedbackRules.filter((rule: ErrorFeedbackRule) => rule.matcher(e as CustomError));

                const feedbackCatcher = this.fallbackFeedbackCatcher;
                let extraData = undefined;

                if (matchedRules.length > 0) {
                    const matchedRule = matchedRules[0];
                    const matchedConfiguration: FeedbackConfiguration = matchedRule.configuration(e);

                    if (matchedConfiguration.message) {
                        message = matchedConfiguration.message;
                    }
                    if (matchedConfiguration.identifier) {
                        feedbackIdentifier = matchedConfiguration.identifier;
                    }
                    if (matchedConfiguration.extraData) {
                        extraData = matchedConfiguration.extraData;
                    }
                }

                yield putSaga(
                    pushAPIFeedbackMessage(
                        feedbackIdentifier,
                        message,
                        APIFeedbackMessageType.ERROR,
                        feedbackCatcher,
                        this.action,
                        extraData,
                    ),
                );

                return null;
            }
        }

        yield putSaga(
            pushAPIFeedbackMessage(
                feedbackIdentifier,
                new TransKey(ErrorMessageType.SERVER, defaultSuffix),
                APIFeedbackMessageType.ERROR,
                this.fallbackFeedbackCatcher,
                this.action,
            ),
        );

        return null
    }
}
