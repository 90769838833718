import React, { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import "./SimpleCard.scss"
import { Icon, Icons } from 'components/legacyDesignSystem/components/Icon';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';

export enum SimpleCardStyle {
    WHITE = "WHITE",
    BLUE = "BLUE",
    ORANGE = "ORANGE",
    PURPLE = "PURPLE",
    GREEN = "GREEN",
}

export type SimpleCardProps =  PropsWithChildren<
    {
        icon?: Icons,
        description?: ReactNode,
        style?: SimpleCardStyle,
        isSelected?: boolean,
        withBackground?: boolean,
        onClick?: () => void,
    }>

const SimpleCard: FC<SimpleCardProps> = (props: SimpleCardProps) => {

    const style = props.style ? props.style : SimpleCardStyle.WHITE
    return (
        <div onClick={props.onClick} className={classNames('simple-card', `simple-card--${style}`, {
            'simple-card--selected': props.isSelected,
            'simple-card--withhover': !!props.onClick,
            'simple-card--withbackground': !!props.withBackground,
        })}>
            {
                props.icon &&
                <div className={"simple-card--icon"}>
                    <Icon width={8} height={8} name={props.icon} />
                </div>
            }
            {
                props.description &&
                <div>
                    <Typography weight={TypographyWeight.REGULAR} variant={TypographyVariant.CAPTION}>{props.description}</Typography>
                </div>
            }
            {props.children}
        </div>
    )
}



export default SimpleCard;
