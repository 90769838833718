import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Cells = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-cells'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_153_256)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11 4C11 4.55228 10.5523 5 10 5C9.44771 5 9 4.55228 9 4C9 3.44772 9.44771 3 10 3C10.5523 3 11 3.44772 11 4ZM8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13ZM5 8C5.55228 8 6 7.55228 6 7C6 6.44772 5.55228 6 5 6C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8ZM5 9.5C6.38071 9.5 7.5 8.38071 7.5 7C7.5 5.61929 6.38071 4.5 5 4.5C3.61929 4.5 2.5 5.61929 2.5 7C2.5 8.38071 3.61929 9.5 5 9.5ZM10.875 8.41745C11.2418 8.20569 11.5956 8.04082 11.8685 7.9918C11.9939 7.96927 12.0522 7.98103 12.0665 7.98539C12.0696 7.98891 12.0752 7.99614 12.0826 8.00895C12.09 8.02177 12.0934 8.03022 12.0949 8.03462C12.0915 8.04924 12.0726 8.10555 11.9904 8.2029C11.8115 8.41473 11.4918 8.6387 11.125 8.85046C10.7582 9.06221 10.4044 9.22709 10.1315 9.2761C10.0061 9.29863 9.94789 9.28688 9.93352 9.28252C9.93047 9.279 9.92489 9.27177 9.91749 9.25895C9.91009 9.24614 9.90662 9.23769 9.9051 9.23329C9.9085 9.21866 9.92744 9.16236 10.0097 9.065C10.1886 8.85318 10.5082 8.6292 10.875 8.41745ZM13.3816 7.25895C13.1835 6.91578 12.8924 6.67977 12.5431 6.56308C12.2136 6.45302 11.8825 6.46528 11.6033 6.51543C11.0584 6.61331 10.5145 6.89355 10.125 7.11841C9.73555 7.34327 9.2209 7.67419 8.86368 8.09716C8.68064 8.31388 8.50449 8.59448 8.43506 8.93485C8.36145 9.29573 8.42032 9.66578 8.61845 10.009C8.81659 10.3521 9.10762 10.5881 9.45696 10.7048C9.78644 10.8149 10.1175 10.8026 10.3967 10.7525C10.9416 10.6546 11.4855 10.3744 11.875 10.1495C12.2645 9.92463 12.7791 9.59372 13.1364 9.17075C13.3194 8.95402 13.4956 8.67342 13.565 8.33306C13.6386 7.97218 13.5797 7.60213 13.3816 7.25895Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_153_256">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-cells'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_153_215)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.5 6.5C16.5 7.32843 15.8284 8 15 8C14.1716 8 13.5 7.32843 13.5 6.5C13.5 5.67157 14.1716 5 15 5C15.8284 5 16.5 5.67157 16.5 6.5ZM12 20C12.8284 20 13.5 19.3284 13.5 18.5C13.5 17.6716 12.8284 17 12 17C11.1716 17 10.5 17.6716 10.5 18.5C10.5 19.3284 11.1716 20 12 20ZM7.5 12C8.32843 12 9 11.3284 9 10.5C9 9.67157 8.32843 9 7.5 9C6.67157 9 6 9.67157 6 10.5C6 11.3284 6.67157 12 7.5 12ZM7.5 14C9.433 14 11 12.433 11 10.5C11 8.567 9.433 7 7.5 7C5.567 7 4 8.567 4 10.5C4 12.433 5.567 14 7.5 14ZM16.3482 13.067C16.9002 12.7483 17.4468 12.4914 17.8788 12.4138C18.0858 12.3766 18.1953 12.3945 18.2395 12.4092L18.2415 12.4099C18.2593 12.4158 18.2868 12.4248 18.3302 12.5C18.3736 12.5753 18.3677 12.6036 18.3639 12.6219L18.3635 12.624C18.3542 12.6696 18.3149 12.7734 18.1792 12.9341C17.896 13.2694 17.4002 13.6143 16.8482 13.933C16.2961 14.2518 15.7495 14.5087 15.3175 14.5863C15.1105 14.6235 15.001 14.6056 14.9568 14.5908L14.9548 14.5901C14.937 14.5843 14.9095 14.5753 14.8661 14.5C14.8227 14.4248 14.8286 14.3965 14.8324 14.3781L14.8328 14.3761C14.8422 14.3304 14.8814 14.2266 15.0171 14.0659C15.3003 13.7306 15.7961 13.3857 16.3482 13.067ZM20.0623 11.5C19.7799 11.0109 19.3674 10.6774 18.8731 10.5123C18.4054 10.356 17.9315 10.3723 17.5252 10.4453C16.7305 10.588 15.9305 10.9988 15.3482 11.335C14.7658 11.6712 14.0101 12.1586 13.4891 12.7755C13.2228 13.0909 12.9718 13.4932 12.8732 13.9764C12.7691 14.4869 12.8517 15.0109 13.1341 15.5C13.4164 15.9891 13.8289 16.3227 14.3232 16.4878C14.7909 16.644 15.2648 16.6278 15.6711 16.5548C16.4658 16.412 17.2658 16.0013 17.8482 15.6651C18.4305 15.3289 19.1862 14.8414 19.7072 14.2246C19.9736 13.9091 20.2246 13.5069 20.3231 13.0237C20.4273 12.5131 20.3446 11.9891 20.0623 11.5Z"
                      fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_153_215">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}