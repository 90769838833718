import classNames from "classnames";
import DropDownContent from "components/designSystem/components/DropDownContent";
import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./DropDown.module.scss";

/**
 * Behavior to open or close the drop-down.
 */
export enum OpenCloseBehavior {
    /**
     * The drop-down opens and closes on every click on its trigger, and closes when clicking outside of it.
     */
    TOGGLE_ON_CLICK,
    /**
     * The drop-down remains opens while its trigger is focused, and closes when a click outside causes it to lose
     * focus. Suitable for text field triggers.
     */
    FOCUS_CLICK_OUTSIDE,
}

interface DropDownProps {
    openCloseBehavior?: OpenCloseBehavior,
    /**
     * Build the "trigger", ie the UI element which is always visible and causes the drop-down to expand or collapse.
     * The trigger should call `onClick` when clicked to toggle its open/closed state if it uses the toggle behavior.
     */
    triggerBuilder: (onClick: () => void, isOpen: boolean) => ReactNode,
    fullWidth?: boolean,
    title?: string | ReactNode,
    closeOnClick?: boolean,
    isListOpen?: boolean,
    openFromTopMobile?: boolean,
    blurTopOffset?: string
    rightAlign?: boolean
}

const DropDown = (
    {openCloseBehavior = OpenCloseBehavior.TOGGLE_ON_CLICK, ...props}: PropsWithChildren<DropDownProps>
): JSX.Element => {

    const ref = useRef<HTMLDivElement>(null);
    const [shouldOpen, setShouldOpen] = useState(!!props.isListOpen);
    const [isFocus, setIsFocus] = useState(false);
    const isOpen = openCloseBehavior == OpenCloseBehavior.FOCUS_CLICK_OUTSIDE ? isFocus || shouldOpen : shouldOpen

    useEffect(() => {
        const handleClickOutside = (event: Event): void => {
            // @ts-ignore
            if (ref.current && !ref.current.contains(event.target)) {
                setShouldOpen(false)
            }
        };
        document.addEventListener("click", handleClickOutside);

        return (): void => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    return <div
        onFocus={(): void => {
            if (openCloseBehavior == OpenCloseBehavior.FOCUS_CLICK_OUTSIDE) {
                setShouldOpen(true)
            }
            setIsFocus(true)
        }}
        onBlur={(): void => setIsFocus(false)}
    >
        {isOpen && <div className={classNames(styles.blurScreen)} style={(props.blurTopOffset ? {top: props.blurTopOffset}: {})}/>}
        <div className={styles.wrapper} ref={ref}>
            {props.triggerBuilder((): void => {if (openCloseBehavior == OpenCloseBehavior.TOGGLE_ON_CLICK) setShouldOpen(!isOpen)}, isOpen)}
            {isOpen && React.Children.count(props.children)>0 &&
                <DropDownContent
                    title={props.title}
                    closeOnClick={!!props.closeOnClick}
                    onClick={(): void => {
                        setShouldOpen(false)
                    }}
                    fullWidth={props.fullWidth}
                    rightAlign={props.rightAlign}
                    openFromTopMobile={props.openFromTopMobile}
                >
                    {props.children}
                </DropDownContent>
            }
        </div>
    </div>

}
export default DropDown;