import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const DashBoardSquare = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-dashboard-square'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_147_1160)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M5 0H2.5C1.11929 0 0 1.11929 0 2.5V5C0 6.38071 1.11929 7.5 2.5 7.5H5C6.38071 7.5 7.5 6.38071 7.5 5V2.5C7.5 1.11929 6.38071 0 5 0ZM6 2.5C6 1.94772 5.55228 1.5 5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V5C1.5 5.55228 1.94772 6 2.5 6H5C5.55228 6 6 5.55228 6 5V2.5ZM5 8.5H2.5C1.11929 8.5 0 9.61929 0 11V13.5C0 14.8807 1.11929 16 2.5 16H5C6.38071 16 7.5 14.8807 7.5 13.5V11C7.5 9.61929 6.38071 8.5 5 8.5ZM6 11C6 10.4477 5.55228 10 5 10H2.5C1.94772 10 1.5 10.4477 1.5 11V13.5C1.5 14.0523 1.94772 14.5 2.5 14.5H5C5.55228 14.5 6 14.0523 6 13.5V11ZM11 0H13.5C14.8807 0 16 1.11929 16 2.5V5C16 6.38071 14.8807 7.5 13.5 7.5H11C9.61929 7.5 8.5 6.38071 8.5 5V2.5C8.5 1.11929 9.61929 0 11 0ZM13.5 1.5C14.0523 1.5 14.5 1.94772 14.5 2.5V5C14.5 5.55228 14.0523 6 13.5 6H11C10.4477 6 10 5.55228 10 5V2.5C10 1.94772 10.4477 1.5 11 1.5H13.5ZM13.5 8.5H11C9.61929 8.5 8.5 9.61929 8.5 11V13.5C8.5 14.8807 9.61929 16 11 16H13.5C14.8807 16 16 14.8807 16 13.5V11C16 9.61929 14.8807 8.5 13.5 8.5ZM14.5 11C14.5 10.4477 14.0523 10 13.5 10H11C10.4477 10 10 10.4477 10 11V13.5C10 14.0523 10.4477 14.5 11 14.5H13.5C14.0523 14.5 14.5 14.0523 14.5 13.5V11Z"
                          fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_147_1160">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <svg id={'s-dashboard-square'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4 1C2.34315 1 1 2.34315 1 4V8C1 9.65685 2.34315 11 4 11H8C9.65685 11 11 9.65685 11 8V4C11 2.34315 9.65685 1 8 1H4ZM3 4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 3.44772 9 4V8C9 8.55228 8.55228 9 8 9H4C3.44772 9 3 8.55228 3 8V4ZM4 13C2.34315 13 1 14.3431 1 16V20C1 21.6569 2.34315 23 4 23H8C9.65685 23 11 21.6569 11 20V16C11 14.3431 9.65685 13 8 13H4ZM3 16C3 15.4477 3.44772 15 4 15H8C8.55228 15 9 15.4477 9 16V20C9 20.5523 8.55228 21 8 21H4C3.44772 21 3 20.5523 3 20V16ZM13 4C13 2.34315 14.3431 1 16 1H20C21.6569 1 23 2.34315 23 4V8C23 9.65685 21.6569 11 20 11H16C14.3431 11 13 9.65685 13 8V4ZM16 3C15.4477 3 15 3.44772 15 4V8C15 8.55228 15.4477 9 16 9H20C20.5523 9 21 8.55228 21 8V4C21 3.44772 20.5523 3 20 3H16ZM16 13C14.3431 13 13 14.3431 13 16V20C13 21.6569 14.3431 23 16 23H19.5C21.1569 23 22.5 21.6569 22.5 20V16C22.5 14.3431 21.1569 13 19.5 13H16ZM15 16C15 15.4477 15.4477 15 16 15H19.5C20.0523 15 20.5 15.4477 20.5 16V20C20.5 20.5523 20.0523 21 19.5 21H16C15.4477 21 15 20.5523 15 20V16Z"
                  fill="currentColor"/>
        </svg>
    )
}