import { OnSuccessPutType } from "actions/ActionInterface";
import { GivePatientAccessForm } from "components/modules/medicalReport/GivePatientAccessForm";
import CreateAccountForm from "components/modules/user/CreateAccountForm";
import LoginForm from "components/modules/user/LoginForm";
import { buildOnSuccessAction } from "components/utils/builders/Actions";
import LOGGER from "core/logging/Logger";
import { cast } from "core/utils/Typed";
import { Record } from "immutable";
import {
    AsGuestPayload,
    ConnectionMode,
    ConnectionModeType,
    LoginPayload,
    SubscriptionPayload
} from "models/medicalReport/ConnectionModesModel";
import React, { Fragment, ReactNode } from "react";

export function buildConnectionsModeComponent (
    connectionMode: ConnectionMode,
    title?: ReactNode,
    description?: ReactNode,
    onSubmit?: (data: Record<string, string>, token: string, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string) => void,
    openNewTabForForgetPassword?: boolean,
    dividerTextForLogin?: ReactNode
): JSX.Element {
    if (connectionMode.type === ConnectionModeType.AS_GUEST) {
        if(!onSubmit) {
            LOGGER.critical(
                'onSubmit should not be none for AS GUEST connection mode'
            )
            return <Fragment/>
        }
        const payload = cast<AsGuestPayload>(connectionMode.payload)
        return <GivePatientAccessForm
            title={title}
            description={description}
            items={payload.form_data.items}
            eulaValidations={payload.form_data.eula_validations}
            withPatientPermission={payload.form_data.with_patient_permission_check}
            displayData={payload.form_data.display_data}
            onSubmit={(
                data: Record<string, string>, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string
            ): void => {
                onSubmit(data, payload.token, onSuccessPut, feedbackIdentifier)
            }}
        />
    }

    if (connectionMode.type === ConnectionModeType.LOGIN) {
        const payload = cast<LoginPayload>(connectionMode.payload)
        return <LoginForm
            title={title}
            description={description}
            prefilledEmail={payload.email}
            isEmailEditable={payload.is_email_editable}
            thenPut={buildOnSuccessAction(connectionMode.on_success)}
            openNewTabForForgetPassword={openNewTabForForgetPassword}
            withProfessionalHealthConnection={payload.with_healthcare_professional_connection}
            dividerTextForLogin={dividerTextForLogin}
        />
    }

    if (connectionMode.type === ConnectionModeType.SUBSCRIPTION) {
        const payload = cast<SubscriptionPayload>(connectionMode.payload)
        const onSuccessAction = buildOnSuccessAction(connectionMode.on_success, true)
        return <CreateAccountForm
            title={title}
            description={description}
            token={payload.token}
            thenPut={onSuccessAction ? [onSuccessAction] : undefined}
            items={payload.form_data.items}
            eulaValidations={payload.form_data.eula_validations}
        />
    }

    return <div/>
}