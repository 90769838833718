import TrText from "components/common/TrText";
import EntityLogo from 'components/commonDesign/EntityLogo';
import { buildSeverityBrief } from "components/commonDesign/utils/ReportNormalityCountUtils";
import { AvatarSize } from "components/designSystem/components/Avatar";
import Divider, { DividerOrientation, DividerVariant } from "components/designSystem/components/Divider";
import { Tag, TagSeverity, TagSize, TagStyle } from "components/designSystem/components/Tag";
import GridRow, { GridRowItem, GridRowSize } from "components/designSystem/containers/GridRow";
import Hoverable from "components/designSystem/containers/Hoverable";
import Inline, {
    AlwaysInline,
    InlineAlignItem,
    InlineOverflow,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import Typography, {
    TBody700,
    TypographyColor,
    TypographyVariant
} from "components/designSystem/containers/Typography";
import WithBackground, { WithBackgroundColor } from "components/designSystem/containers/WithBackground";
import WithMargin, { MarginSize, WithLeftMargin } from "components/designSystem/containers/WithMargin";
import Icon, { NullIcon } from "components/designSystem/foundations/Icons";
import { IconColor, IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { BASE_GRID_SPLIT } from "components/modules/modular/modules/report/content/models";
import { TransDomain } from 'components/pages/common/MainComponent';

import WithTranslations from 'components/pages/common/WithTranslations';
import { DateTimeFormat, formatDateTime } from "core/utils/Date";
import { capitalizeFirstLetter } from 'core/utils/text';
import { Entity } from 'models/entities/default';
import { ReportMetadataNormality } from "models/medicalReport/ReportModels";
import React from 'react';


export interface ReportsTableProps {
    rows: ReportsTableRow[]
    onRowClick: (r: ReportsTableRow) => void;
    isDoctor: boolean;
    minimizedVersion?: boolean;
    withStatusColumn?: boolean;
}

export interface ReportsTableRow {
    uuid: string;
    laboratory?: Entity;
    samplingDate: Date;
    receptionDate: Date;
    labOrders?: string[];
    isNew?: boolean;
    isSent?: boolean;
    isAiSuggestionsEnriched?: boolean;
    normalityCount?: {[k in ReportMetadataNormality]?: number | null}
}


class ReportsTable extends WithTranslations<ReportsTableProps> {
    TRANS_SUFFIX = TransDomain.MEDICAL_REPORT;

    private fontDivToUse = (text: string, isNew?: boolean): JSX.Element =>
        <Typography
            variant={isNew ? TypographyVariant.CAPTION_700 : TypographyVariant.CAPTION}
            color={TypographyColor.COLOR_TEXT_DEFAULT}>
            {text}
        </Typography>

    private buildLabLogo = (laboratory?: Entity, isNew?: boolean): JSX.Element =>
        <AlwaysInline alignItems={InlineAlignItem.CENTER}>
            <WithMargin margins={[MarginSize.SMALL]}>
                <EntityLogo
                    entity={laboratory}
                    asAvatar={{size: AvatarSize.SMALL}}
                    fallbackIcon={IconName.TUBE}/>
            </WithMargin>
            {laboratory?.name ? this.fontDivToUse(laboratory.name, isNew) : this.fontDivToUse(this.trans('loading_name', undefined, undefined, capitalizeFirstLetter), isNew)}
        </AlwaysInline>;

    private buildSamplingDate = (samplingDate: Date, isNew?: boolean): JSX.Element =>
        this.fontDivToUse(formatDateTime(samplingDate, DateTimeFormat.DATE_TIME_LONG), isNew);

    private buildExamsTags = (r: ReportsTableRow): JSX.Element =>
        <WithMargin margins={[MarginSize.SMALL, undefined]}>
            <AlwaysInline
                alignItems={InlineAlignItem.CENTER}
                spacing={[InlineSpacing.X_SMALL, InlineSpacing.SMALL]}
                overflow={InlineOverflow.WRAP}>
                {
                    r.labOrders?.map((indicator, i: number) =>
                        <Tag key={i} size={TagSize.EXTRA_SMALL} style={TagStyle.FILL} singleLine>{indicator}</Tag>)
                }
            </AlwaysInline>
        </WithMargin>

    private buildRightChevron = (): JSX.Element =>
        <Icon name={IconName.CHEVRON_RIGHT} size={IconSize.EXTRA_SMALL} color={IconColor.ON_TERTIARY}/>;

    private buildRowContentsForDoctor(r: ReportsTableRow): JSX.Element {
        const { withStatusColumn } = this.props
        return <>
            <GridRowItem from={1} to={withStatusColumn ? 5 : 6}>
                {this.buildLabLogo(r.laboratory, r.isNew)}
            </GridRowItem>
            <GridRowItem from={withStatusColumn ? 5 : 6} to={withStatusColumn ? 10 : 12}>
                {this.buildSamplingDate(r.samplingDate, r.isNew)}
            </GridRowItem>
            <GridRowItem from={withStatusColumn ? 10 : 12} to={withStatusColumn ? 19 : 22}>
                {this.buildExamsTags(r)}
            </GridRowItem>
            {withStatusColumn && <GridRowItem from={19} to={22}>
                <Inline spacing={InlineSpacing.SMALL}>
                    {r.isSent && <Tag size={TagSize.EXTRA_SMALL} severity={TagSeverity.CRITICAL}>
                        <TrText input={{trkey: 'state.sent', trdomain: TransDomain.MEDICAL_REPORT}}/>
                    </Tag>}
                    {r.isAiSuggestionsEnriched && <Tag size={TagSize.EXTRA_SMALL} severity={TagSeverity.CRITICAL}>
                        <TrText input={{trkey: 'state.ai_generated', trdomain: TransDomain.MEDICAL_REPORT}}/>
                    </Tag>}
                </Inline>
            </GridRowItem>}
            <GridRowItem from={22} to={22}>
                {this.buildRightChevron()}
            </GridRowItem>
        </>;
    }

    private buildRowContentsForPatient = (r: ReportsTableRow): JSX.Element => <>
        <GridRowItem from={1} to={8}>
            {this.buildLabLogo(r.laboratory, r.isNew)}
        </GridRowItem>
        <GridRowItem from={8} to={15}>
            {this.buildSamplingDate(r.samplingDate, r.isNew)}
        </GridRowItem>
        <GridRowItem from={15} to={20}>
            {!this.props.minimizedVersion &&
                this.fontDivToUse(formatDateTime(r.receptionDate, DateTimeFormat.DATE_TIME_LONG), r.isNew)
            }
        </GridRowItem>
        <GridRowItem from={20} to={22}>
            {
                r.normalityCount &&
                buildSeverityBrief(r.normalityCount)
            }
        </GridRowItem>
        <GridRowItem from={22} to={22}>
            {this.buildRightChevron()}
        </GridRowItem>
    </>;

    private buildTableRows = (): JSX.Element =>
        <WithMargin margins={[undefined, MarginSize.MEDIUM, MarginSize.MEDIUM, MarginSize.MEDIUM]}>
            {this.props.rows.map((r: ReportsTableRow, k: number) => {
                return <div key={r.uuid + r.samplingDate} id={`report-table-row-${r.uuid}`}
                            onClick={(): void => this.props.onRowClick(r)}>
                    <Hoverable isClickable>
                        <GridRow columnNb={BASE_GRID_SPLIT} size={this.props.isDoctor ? GridRowSize.MEDIUM : undefined}>
                            {this.props.isDoctor ? this.buildRowContentsForDoctor(r) : this.buildRowContentsForPatient(r)}
                        </GridRow>
                    </Hoverable>
                    {k + 1 != this.props.rows.length &&
                        <WithLeftMargin margin={MarginSize.ELEVEN_TIMES_X_SMALL}>
                            <Divider
                                orientation={DividerOrientation.HORIZONTAL}
                                variant={DividerVariant.DEFAULT}/>
                        </WithLeftMargin>
                    }
                </div>
            })}
        </WithMargin>

    private buildLabHeader = (): JSX.Element =>
        <WithMargin margins={[MarginSize.SMALL]}>
            <TBody700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                {this.trans('laboratory', undefined, undefined, capitalizeFirstLetter)}
            </TBody700>
        </WithMargin>;

    private buildChevronPlaceholderHeader = (): JSX.Element => <NullIcon size={IconSize.EXTRA_SMALL}/>;

    private buildSamplingDateHeader = (): JSX.Element => <TBody700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
        {this.trans('sampling_date', undefined, undefined, capitalizeFirstLetter)}
    </TBody700>;

    private buildHeaderRowForDoctor(): JSX.Element {
        // Lab name | Sampling Date | Exams | Conclusion (Optional column) | Chevron Icon
        // Column weights *with* conclusion column (out of 21):
        // 4 | 5 | 9 | 3 | wrap
        // Column weights *without* conclusion column (out of 21):
        // 5 | 6 | 10 | wrap
        // Note: wrapping the last column is done using position 22 (HTML wraps elements not planned in the grid)
        const { withStatusColumn } = this.props
        return <>
            <GridRowItem from={1} to={withStatusColumn ? 5 : 6}>
                {this.buildLabHeader()}
            </GridRowItem>
            <GridRowItem from={withStatusColumn ? 5 : 6} to={withStatusColumn ? 10 : 12}>
                <TBody700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    {this.trans('sampling_date', undefined, undefined, capitalizeFirstLetter)}
                </TBody700>
            </GridRowItem>
            <GridRowItem from={withStatusColumn ? 10 : 12} to={withStatusColumn ? 19 : 22}>
                <TBody700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    <TrText input={{trkey: 'exams', trdomain: TransDomain.MEDICAL_REPORT}}/>
                </TBody700>
            </GridRowItem>
            {withStatusColumn && <GridRowItem from={19} to={22}>
                <TBody700>
                    <TrText input={{trkey: 'state.title', trdomain: TransDomain.MEDICAL_REPORT}}/>
                </TBody700>
            </GridRowItem>}
            <GridRowItem from={22} to={22}>
                {this.buildChevronPlaceholderHeader()}
            </GridRowItem>
        </>;
    }

    private buildHeaderRowForPatient(): JSX.Element {
        return <>
            <GridRowItem from={1} to={8}>
                {this.buildLabHeader()}
            </GridRowItem>
            <GridRowItem from={8} to={15}>
                {this.buildSamplingDateHeader()}
            </GridRowItem>
            <GridRowItem from={15} to={22}>
                <TBody700
                    color={TypographyColor.COLOR_TEXT_DEFAULT}>{this.trans('reception_date', undefined, undefined, capitalizeFirstLetter)}
                </TBody700>
            </GridRowItem>
            <GridRowItem from={22} to={22}>
                {this.buildChevronPlaceholderHeader()}
            </GridRowItem>
        </>;
    }

    render = (): JSX.Element => <div>
        {!this.props.minimizedVersion &&
            <WithMargin margins={[MarginSize.MEDIUM, MarginSize.MEDIUM, MarginSize.SMALL, MarginSize.MEDIUM]}>
                <WithBackground color={WithBackgroundColor.TINTED}>
                    <GridRow columnNb={BASE_GRID_SPLIT}>
                        {this.props.isDoctor ? this.buildHeaderRowForDoctor() : this.buildHeaderRowForPatient()}
                    </GridRow>
                    <Divider variant={DividerVariant.HIGHLIGHTED} orientation={DividerOrientation.HORIZONTAL}/>
                </WithBackground>
            </WithMargin>
        }
        {this.buildTableRows()}
    </div>;
}

export default ReportsTable;
