import React from 'react';

export interface LogoFullProps {
    width?: string,
    height?: string,
}

const LogoFull = (props: LogoFullProps): JSX.Element => {
    let width = '100%'
    let height = '100%'
    if (props.width || props.height) {
        width = props.width || 'auto'
        height = props.height || 'auto'
    }

    if (window.location.hostname.includes('kiro')) {
        return (
            <svg
                id="kiro_logo_full"
                style={{ display: 'block', height: height, width: width }}
                viewBox="-30 -10 470 108"
                {...props}
            >
                <path d="M43.6249 88C68.1326 88 88 68.3012 88 44C88 19.6995 68.1326 0 43.6249 0C30.329 0 18.3988 5.79817 10.2659 14.9836H32.5339C37.0458 14.9836 40.7021 18.609 40.7021 23.0828C40.7021 27.5565 37.0458 31.1819 32.5339 31.1819H1.16236C0.690485 32.7208 0.301363 34.2954 0.000131449 35.9006H63.5549C68.0668 35.9006 71.7231 39.526 71.7231 43.9997C71.7231 48.4735 68.0668 52.0989 63.5549 52.0989H0C0.301199 53.7041 0.690285 55.2787 1.16212 56.8175H47.4914C52.0033 56.8175 55.6596 60.443 55.6596 64.9167C55.6596 69.3905 52.0033 73.0159 47.4914 73.0159H10.2652C18.3981 82.2017 30.3286 88 43.6249 88Z" fill="url(#KIRO_CMJN_svg__SVGID_1_)"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M369.868 4C347.27 4 330.553 21.0258 330.553 44.0537C330.553 67.1823 347.27 84 369.868 84C392.366 84 409.183 67.1823 409.183 44.0537C409.19 21.0325 392.366 4 369.868 4ZM369.868 71.9087C354.628 71.9087 343.906 60.3478 343.906 44.0537C343.906 27.7596 354.628 16.0913 369.868 16.0913C385.007 16.0913 395.837 27.7596 395.837 44.0537C395.837 60.3478 385.007 71.9087 369.868 71.9087Z" fill="#44506A"/>
                <path d="M206.822 5.36963H219.96V82.6371H206.822V5.36963Z" fill="#44506A"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M279.571 5.36963C294.71 5.36963 306.902 14.0907 306.902 29.8611C306.902 40.8983 300.799 48.4713 291.978 52.043L310.581 82.6304H295.234L277.993 54.2451H264.854V82.6304H251.817V5.36963H279.571ZM264.848 42.7916H278.409C287.56 42.7916 293.549 38.374 293.549 29.8611C293.549 21.4489 287.453 16.8231 278.409 16.8231H264.848V42.7916Z" fill="#44506A"/>
                <path d="M161.733 6.63177H179.182L152.838 36.7828L184.741 82.6304H168.729L143.929 46.9876L132.395 60.1866V82.6304H119.256V5.36963H132.395V40.2135L161.733 6.63177Z" fill="#44506A"/>
                <defs>
                    <linearGradient
                        id="KIRO_CMJN_svg__SVGID_1_"
                        gradientUnits="userSpaceOnUse"
                        x1={-0.754568}
                        y1={44.0047}
                        x2={100.608}
                        y2={44.0047}
                    >
                        <stop offset={0.3142} stopColor="#00B0F0" />
                        <stop offset={0.8358} stopColor="#0285D0" />
                        <stop offset={0.9513} stopColor="#027BC8" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    return (
        <svg style={{ display: 'block', height: '100%' }} viewBox="0 0 300 100" {...props}>
            <g transform={'translate(15 15)'}>
                <defs>
                    <linearGradient id="logo-full_svg__a">
                        <stop stopColor="#2d388a" offset={0} />
                        <stop stopColor="#00aeef" offset={1} />
                    </linearGradient>
                    <linearGradient id="logo-full_svg__b">
                        s
                        <stop stopColor="#2d388a" offset={0} />
                        <stop stopColor="#00aeef" offset={1} />
                    </linearGradient>
                </defs>
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M80.328 26.028L55.082 6.485c-1.581-1.224-3.896-1.955-6.191-1.955-1.256 0-2.46.221-3.482.64L15.862 17.261c-2.974 1.218-5.543 4.537-5.976 7.72L5.583 56.615C5.149 59.8 6.739 63.684 9.28 65.65l25.245 19.544c1.582 1.225 3.896 1.955 6.192 1.955 1.256 0 2.46-.221 3.482-.64l29.547-12.091c2.974-1.217 5.543-4.536 5.977-7.721l4.303-31.634c.433-3.182-1.157-7.066-3.698-9.035zm-27.824 7.385l-3.185 23.426-18.576-14.521 18.784-7.688 2.977-1.217zM18.009 22.508l29.547-12.091c.342-.14.815-.217 1.334-.217 1.034 0 2.128.309 2.722.769l3.691 2.857-1.706 13.014L13.1 43.412l2.403-17.667c.161-1.182 1.402-2.786 2.506-3.237zm24.043 58.755c-.342.141-.816.218-1.335.218-1.034 0-2.127-.31-2.721-.769L12.75 61.168c-.943-.73-1.711-2.607-1.551-3.789l1.018-7.48 12.325-5.043 25.05 19.393 11.696 9.144-19.236 7.87zM78.408 34.3l-4.303 31.633c-.161 1.183-1.402 2.786-2.506 3.238l-4.269 1.747-12.517-9.784 5.68-43.29 16.363 12.667c.945.731 1.713 2.607 1.552 3.789z"
                    transform="matrix(.62353 0 0 .62353 -3.437 3.175)"
                    fill="url(#logo-full_svg__a)"
                />
                <path
                    d="M1.494 8.886h4.6q1.718 0 2.646.41t1.46 1.26.532 1.875q0 .967-.473 1.758t-1.392 1.28q1.143.39 1.748.903t.947 1.245.342 1.592q0 1.748-1.279 2.954t-3.418 1.206H1.494V8.887zm1.416 1.416v4.64H5.48q1.504 0 2.212-.284t1.123-.884.415-1.343q0-.996-.694-1.562t-2.207-.567H2.91zm0 6.094v5.557h3.535q1.582 0 2.32-.312t1.186-.967.45-1.436q0-.957-.63-1.68t-1.734-.986q-.742-.176-2.568-.176H2.91zm10.225-3.935h1.455l3.681 8.252 3.575-8.252h1.474l-6.396 14.62h-1.455l2.06-4.717zm17.011-.274q2.471 0 4.102 1.787 1.475 1.641 1.475 3.877t-1.563 3.912-4.014 1.674q-2.46 0-4.018-1.674T24.57 17.85q0-2.226 1.475-3.867 1.63-1.797 4.101-1.797zm0 1.348q-1.708 0-2.939 1.27t-1.23 3.066q0 1.162.561 2.173t1.519 1.553 2.09.542q1.152 0 2.104-.542t1.509-1.553.556-2.173q0-1.797-1.23-3.066t-2.94-1.27zm8.106 5.83l-.02-1.142q-.029-.84.069-1.426.215-1.846.937-2.705.703-.752 1.436-1.162 1.21-.518 2.763-.489 1.211 0 2.242.61t1.47 1.641.439 3.228v5.508H46.22V18.32q0-1.855-.157-2.48-.244-1.065-.966-1.509t-2.14-.444q-1.25 0-2.226.722t-.908 1.973q-.185.8-.185 2.92v3.926h-1.387v-4.063zm22.178 1.983q.879-1.465 1.055-2.881V8.32h-1.367v5.977q-.87-1.065-1.944-1.587t-2.353-.522q-2.266 0-3.877 1.65t-1.611 4.004q0 2.314 1.62 3.955t3.907 1.64q1.309 0 2.456-.502t2.114-1.587zm-4.453.771q-1.163 0-2.134-.571t-1.548-1.587-.576-2.168q0-1.133.58-2.173t1.563-1.611 2.095-.571q1.133 0 2.148.57t1.563 1.554.547 2.192q0 1.865-1.23 3.115t-3.009 1.25z"
                    fill="url(#logo-full_svg__b)"
                    transform="matrix(3.3582 0 0 3.3582 53.983 -22.941)"
                />
            </g>
        </svg>
    );
};

export default LogoFull;
