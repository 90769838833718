import React from 'react';

const PlusIcon = (): JSX.Element => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 40 40">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 39.5c10.77 0 19.5-8.73 19.5-19.5S30.77.5 20 .5.5 9.23.5 20 9.23 39.5 20 39.5Zm-9.412-21.231a1.731 1.731 0 1 0 0 3.462h7.68v7.68c0 .956.774 1.73 1.73 1.732H20c.956 0 1.731-.775 1.731-1.731v-7.68h7.68a1.731 1.731 0 1 0 0-3.463h-7.68v-7.68a1.731 1.731 0 0 0-3.462 0v7.68h-7.68Z"
                fill="#027AC5"
            />
        </svg>
    );
};

export default PlusIcon;
