import { ActionDispatcher } from 'actions/ActionInterface';

import React, { ReactNode } from 'react';
import NavBar from 'components/core/menus/NavBar';
import MainComponent, { MainComponentProps, setup, TransDomain } from 'components/pages/common/MainComponent';
import LogoFull from 'core/content/brand/logos/LogoFull';
import { ROUTE_DEFAULT_MODULAR } from 'core/routing/Routes';
import { redirect } from "actions/common/CommonActions";

import './WithNavBar.scss';
import Footer from 'components/pages/Footer';
import classNames from 'classnames';

export interface WithDisconnectedNavBarDispatchProps {
    redirectToDefaultRoute: () => void;
}

export interface WithDisconnectedNavBarProps extends MainComponentProps, WithDisconnectedNavBarDispatchProps {
    withWhiteBackground?: boolean
}

class WithDisconnectedNavBar extends MainComponent<WithDisconnectedNavBarProps> {
    TRANS_SUFFIX = TransDomain.GLOBAL;

    render(): ReactNode {
        const { redirectToDefaultRoute } = this.props;
        return (
            <React.Fragment>
                <NavBar sticky>
                    <div className={'nav-container'}>
                        <div className={'nav-logo'} onClick={redirectToDefaultRoute}>
                            <LogoFull />
                        </div>
                    </div>
                </NavBar>
                <div
                    className={classNames('with-navbar-container', {
                        'with-navbar-container-white-background': this.props.withWhiteBackground
                    })}
                >
                    {this.props.children}
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: ActionDispatcher): WithDisconnectedNavBarDispatchProps => ({
    redirectToDefaultRoute: (): void => {
        dispatch(redirect(ROUTE_DEFAULT_MODULAR));
    },
});


export default setup(WithDisconnectedNavBar, null, mapDispatchToProps);
