import classNames from "classnames";
import RadioButton from "components/designSystem/components/RadioButton";
import { XSmallSpacer } from "components/designSystem/containers/Spacer";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import React, { FC, ReactNode } from "react";

import styles from './SelectionCard.module.scss';

export enum SelectionCardWidth {
    WIDTH_270 = 'WIDTH_270',
}

interface SelectionCardProps {
    title: ReactNode,
    description: ReactNode,
    icon: IconName,
    isDisabled?: boolean,
    width?: SelectionCardWidth,

    radioButtonGroupName: string,
    isSelected?: boolean,
    onClick?: () => void;
}

const SelectionCard: FC<SelectionCardProps> = (
    {
        isDisabled = false,
        isSelected = false,
        ...props
    }
): JSX.Element => {
    const computedClass = classNames(styles.selectionCard, {
        [styles.selectionCardEnabled]: !isDisabled,
        [styles.selectionCardDisabled]: isDisabled,
        [styles.selectionCardWidthWIDTH_270]: props.width === SelectionCardWidth.WIDTH_270,
        [styles.selectionCardNotSelected]: !isSelected,
        [styles.selectionCardSelected]: isSelected
    })
    return <div className={computedClass} onClick={props.onClick}>
        <div className={styles.radioButton}>
            <RadioButton
                groupName={props.radioButtonGroupName}
                disabled={isDisabled}
                overrideChecked={isSelected}
                onChange={props.onClick}
            />
        </div>
        <div className={styles.icon}>
            <Icon name={props.icon} size={IconSize.SMALL}/>
        </div>
        <XSmallSpacer>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.description}>{props.description}</div>
        </XSmallSpacer>
    </div>
}

export default SelectionCard;