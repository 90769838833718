import { applyMiddleware, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from '@redux-saga/core';
import { routerMiddleware } from 'connected-react-router';

import CONFIG, { Env } from 'configuration/globals';
import { createBrowserHistory } from 'history';
import allReducers from 'reducers/allReducers';
import rootSaga from 'sagas/allSagas';

let store = null;
let enhancer;
export const history = createBrowserHistory();

// Created to avoid linter any warning on state usage
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyState = any;

export const createStore = (): Store => {
    const sagaMiddleware = createSagaMiddleware();

    if (CONFIG.env === Env.DEV) {
        const composeEnhancers = composeWithDevTools({});
        enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)));
    } else {
        enhancer = applyMiddleware(sagaMiddleware, routerMiddleware(history));
    }

    store = createReduxStore(allReducers(history), enhancer);
    sagaMiddleware.run(rootSaga);

    return store;
};

export default createStore;
