import * as React from "react";
import { useEffect } from "react";

type DelayActionProps = {
    seconds: number
    action: () => void
};

export const useDelayedAction = (seconds: number, action: () => void): void => {
    useEffect(() => {
        const timeout = setTimeout(action, seconds * 1000);
        return () => clearTimeout(timeout)
    }, [])
};

/**
 * Utility component which executes an action after the specified delay, cancelling it if this component is removed
 * because the view is updated.
 *
 * From functional components, prefer to use `useDelayedAction` hook directly rather than this component.
 */
export const DelayAction = (props: DelayActionProps): JSX.Element => {
    useDelayedAction(props.seconds, props.action);
    return <></>
};