import { OnSuccessPutType } from "actions/ActionInterface";
import { FEEDBACK_IDENTIFIER_API_GIVE_PATIENT_ACCESS } from "actions/medicalReports/MedicalReportsFetchActions";
import Form, { FormState } from "components/commonDesign/form/tmpNewSystem/Form";
import FormCheckbox from "components/commonDesign/form/tmpNewSystem/FormCheckbox";
import { FormItem } from "components/commonDesign/form/tmpNewSystem/FormItemBuilder";
import Avatar, { AvatarSize, AvatarType } from "components/designSystem/components/Avatar";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import { AlwaysInline, InlineAlignItem, InlineSpacing } from "components/designSystem/containers/Inline";
import { MediumSpacer, XLargeSpacer, XSmallSpacer } from "components/designSystem/containers/Spacer";
import {
    TBody,
    TDisplaySmall700,
    TDisplayXSmall700,
    TypographyColor
} from "components/designSystem/containers/Typography";
import { EULAFields } from "components/modules/modular/components/EULAFields";
import FormWithDataPrivacyPopUp from "components/modules/modular/components/FormWithDataPrivacyPopUp";
import APIFeedbackMessageContainer from "components/pages/common/APIFeedbackMessage";
import { TransDomain } from "components/pages/common/MainComponent";
import WithTranslations from "components/pages/common/WithTranslations";
import { required } from "components/utils/form/tmpNewSystem/validators";
import { capitalizeFirstLetter } from "core/utils/text";
import { Record } from "immutable";
import { APIFormItemWithPayload, FormItemWithPayload } from "models/common/DynamicFormModels";
import { APIText } from "models/common/message";
import { DisplayData } from "models/medicalReport/ReportAccessModel";
import { EULAType, EULAValidation } from "models/user/AuthModels";
import React, { ReactNode } from "react";
import _ from "underscore";

interface GivePatientAccessFormProps {
    title?: ReactNode;
    description?: ReactNode;
    items: APIFormItemWithPayload[];
    eulaValidations?: EULAValidation[];
    withPatientPermission: boolean;
    displayData: DisplayData;
    onSubmit: (data: Record<string, string>, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string) => void;
}

export class GivePatientAccessForm extends WithTranslations <GivePatientAccessFormProps> {
    TRANS_SUFFIX = TransDomain.MEDICAL_REPORT + '.access';

    buildFormItemWithPayload = (items: APIFormItemWithPayload[]): FormItemWithPayload[] => (
        items.map((item: APIFormItemWithPayload) => {
            const label: APIText | undefined = item.payload.label
            return {
                ...item,
                payload: {
                    ...item.payload,
                    label: label
                }
            }
        })
    )

    onSubmit = (data: Record<string, string>, onSuccessPut?: OnSuccessPutType, feedbackIdentifier?: string): void => {
        this.props.onSubmit(data, onSuccessPut, feedbackIdentifier)
    }

    render(): ReactNode {
        const {title, description, items, displayData} = this.props
        const eulaValidations: EULAValidation[] = this.props.eulaValidations ? this.props.eulaValidations : [];

        const transformedItems = this.buildFormItemWithPayload(items)
        const formattedItems: JSX.Element[] = transformedItems.sort(
            (el1: FormItemWithPayload, el2: FormItemWithPayload) => el1.rank - el2.rank,
        ).map(
            (item: FormItemWithPayload, index) => <FormItem key={index} formItem={item}/>
        )

        const inReportEulaValidations: EULAValidation[] = eulaValidations.filter(value => value.type !== EULAType.DATA_PRIVACY_NOTICED)
        const notInReportEulaValidations: EULAValidation[] = eulaValidations.filter(value => value.type === EULAType.DATA_PRIVACY_NOTICED)

        const formContent = (formState: FormState): JSX.Element => {
            return (
                <XLargeSpacer>
                    <MediumSpacer>
                        {displayData && (
                            <AlwaysInline spacing={InlineSpacing.MEDIUM} alignItems={InlineAlignItem.CENTER}>
                                {displayData.patient_initials && <Avatar size={AvatarSize.SMALL} payload={{
                                    type: AvatarType.INITIALS, initials:
                                        (displayData.patient_initials.first_name ? displayData.patient_initials.first_name[0] : "") +
                                        (displayData.patient_initials.last_name ? displayData.patient_initials.last_name[0] : "")
                                }}/>}
                                <AlwaysInline>
                                    <TDisplayXSmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                                        {this.props.displayData.hidden_patient_name}
                                    </TDisplayXSmall700>
                                </AlwaysInline>
                            </AlwaysInline>
                        )}
                        {formattedItems.length > 0 && <MediumSpacer>{formattedItems}</MediumSpacer>}
                        <MediumSpacer>
                            {this.props.withPatientPermission && <FormCheckbox
                                name={'permission-checked'}
                                validate={required('checkbox')}
                                field={{label: this.trans('permission_checked', undefined, undefined, capitalizeFirstLetter)}}
                            />}
                            {!_.isEmpty(inReportEulaValidations) && (
                                <EULAFields
                                    types={inReportEulaValidations.map((v: EULAValidation) => ({
                                        eulaType: v.type,
                                        version: v.control_version,
                                        useNewNaming: v.use_new_naming
                                    }))}
                                />
                            )}
                        </MediumSpacer>
                    </MediumSpacer>
                    <Button disabled={formState.invalid} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}
                            isSubmit fullWidth>
                        {this.trans('view_report_button', undefined, undefined, capitalizeFirstLetter)}
                    </Button>
                </XLargeSpacer>
            )
        }

        return <div>
            <XLargeSpacer>
                <APIFeedbackMessageContainer identifier={FEEDBACK_IDENTIFIER_API_GIVE_PATIENT_ACCESS} withNewDesignSystem/>
                <XSmallSpacer>
                    {title && <TDisplaySmall700 color={TypographyColor.COLOR_TEXT_DEFAULT}>{title}</TDisplaySmall700>}
                    {description && <TBody color={TypographyColor.COLOR_TEXT_SUBDUED}>{description}</TBody>}
                </XSmallSpacer>
                {_.isEmpty(notInReportEulaValidations) ?
                    <Form onSubmit={(data: Record<string, string>): void =>
                        this.onSubmit(data, undefined, FEEDBACK_IDENTIFIER_API_GIVE_PATIENT_ACCESS)
                    }>{formContent}</Form> :
                    <FormWithDataPrivacyPopUp dataPrivacy={notInReportEulaValidations} onSubmit={this.onSubmit}>
                        {formContent}
                    </FormWithDataPrivacyPopUp>
                }
            </XLargeSpacer>

        </div>
    }
}