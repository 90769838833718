import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Trash = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-trash'} width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M6.84277 6.21582C7.25699 6.21582 7.59277 6.55161 7.59277 6.96582V11.2502C7.59277 11.6645 7.25699 12.0002 6.84277 12.0002C6.42856 12.0002 6.09277 11.6645 6.09277 11.2502V6.96582C6.09277 6.55161 6.42856 6.21582 6.84277 6.21582Z"
                    fill="currentColor"
                />
                <path
                    d="M9.81836 6.96582C9.81836 6.55161 9.48257 6.21582 9.06836 6.21582C8.65415 6.21582 8.31836 6.55161 8.31836 6.96582V11.2502C8.31836 11.6645 8.65415 12.0002 9.06836 12.0002C9.48257 12.0002 9.81836 11.6645 9.81836 11.2502V6.96582Z"
                    fill="currentColor"
                />
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M5.05859 3.9751V2.99194C5.05859 2.02545 5.8421 1.24194 6.80859 1.24194H9.16689C10.1334 1.24194 10.9169 2.02544 10.9169 2.99194V3.9751H13.4912C13.9054 3.9751 14.2412 4.31088 14.2412 4.7251C14.2412 5.13931 13.9054 5.4751 13.4912 5.4751H12.6157V13.008C12.6157 13.9745 11.8322 14.758 10.8657 14.758H5.15869C4.19219 14.758 3.40869 13.9745 3.40869 13.008V5.4751H2.50928C2.09506 5.4751 1.75928 5.13931 1.75928 4.7251C1.75928 4.31088 2.09506 3.9751 2.50928 3.9751H5.05859ZM6.55859 2.99194C6.55859 2.85387 6.67052 2.74194 6.80859 2.74194H9.16689C9.30496 2.74194 9.41689 2.85387 9.41689 2.99194V3.9751H6.55859V2.99194ZM5.80497 5.4751L5.80859 5.47511L5.81222 5.4751H10.1633L10.1669 5.47511L10.1705 5.4751H11.1157V13.008C11.1157 13.1461 11.0038 13.258 10.8657 13.258H5.15869C5.02062 13.258 4.90869 13.1461 4.90869 13.008V5.4751H5.80497Z"
                      fill="currentColor"
                />
            </svg>
        )
    }

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M10.2178 9.40723C10.7701 9.40723 11.2178 9.85494 11.2178 10.4072V17.0058C11.2178 17.5581 10.7701 18.0058 10.2178 18.0058C9.66549 18.0058 9.21777 17.5581 9.21777 17.0058V10.4072C9.21777 9.85494 9.66549 9.40723 10.2178 9.40723Z"
                fill="currentColor"
            />
            <path
                d="M14.6455 10.4072C14.6455 9.85494 14.1978 9.40723 13.6455 9.40723C13.0932 9.40723 12.6455 9.85494 12.6455 10.4072V17.0058C12.6455 17.5581 13.0932 18.0058 13.6455 18.0058C14.1978 18.0058 14.6455 17.5581 14.6455 17.0058V10.4072Z"
                fill="currentColor"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.625 1.74683C8.52043 1.74683 7.625 2.64226 7.625 3.74683V5.9563H3.54297C2.99068 5.9563 2.54297 6.40401 2.54297 6.9563C2.54297 7.50858 2.99068 7.9563 3.54297 7.9563H5.08398V20.2532C5.08398 21.3578 5.97941 22.2532 7.08398 22.2532H16.9538C18.0584 22.2532 18.9538 21.3578 18.9538 20.2532V7.9563L20.4566 7.9563C21.0089 7.9563 21.4566 7.50858 21.4566 6.9563C21.4566 6.40401 21.0089 5.9563 20.4566 5.9563H16.3373V3.74683C16.3373 2.64226 15.4419 1.74683 14.3374 1.74683H9.625ZM14.3373 5.9563L14.3374 3.74683H9.625V5.9563H14.3373ZM7.08398 7.9563H16.9538V20.2532H7.08398V7.9563Z"
                  fill="currentColor"
            />
        </svg>
    )
}