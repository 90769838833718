import ChartComponent from 'components/commonDesign/charts/ChartComponent';
import { ModuleLoaderWrapper } from "components/core/items/ModuleLoaderWrapper";
import TextLoader from 'components/core/items/TextLoader';
import Container, {
    ContainerProfile,
    MarginType,
    PaddingType
} from 'components/legacyDesignSystem/components/Container';
import { Switch } from 'components/legacyDesignSystem/components/Switch';
import Typography, { TypographyVariant, TypographyWeight } from 'components/legacyDesignSystem/components/Typography';
import BaseModule, { defaultBaseModuleState, ModuleState, setupModule } from 'components/modules/modular/BaseModule';
import { cast } from 'core/utils/Typed';
import { Chart, ChartFilter, ChartFilterType } from 'models/charts/charts';

import { APIText } from 'models/common/message';
import { ModuleMenu } from 'models/modular/menu';
import { ModuleData } from 'models/modular/storage';
import React from 'react';

export interface SwitchItem {
    title: APIText;
    identifier: string;
}

export interface SimpleChartWithSwitchModulePayload {
    title: APIText;
    sub_title: APIText;
    chart: Chart | null;
    values_switch?: SwitchItem[];
    menu: ModuleMenu | null;
}

interface LocalState {
    active_switch_value?: string;
}

class SimpleChartWithSwitchModule extends BaseModule<{}, SimpleChartWithSwitchModulePayload, {}> {
    state: ModuleState<LocalState> = defaultBaseModuleState(
        {}
    )

    protected renderSwitch(values_switch: SwitchItem[]): JSX.Element {
        const left = Object.values(values_switch)[0];
        const right = Object.values(values_switch)[1];
        return (
            <Switch
                text_left={this.transApiText(left.title)}
                onClickLeft={(): void => this.setState({ active_switch_value: left.identifier })}
                text_right={this.transApiText(right.title)}
                onClickRight={(): void => this.setState({ active_switch_value: right.identifier })}
            />
        );
    }

    _render(payload: ModuleData<SimpleChartWithSwitchModulePayload, {}>): React.ReactNode {
        const {
            pagePayload: { title, sub_title, chart, values_switch, menu },
        } = payload;

        const filters: ChartFilter[] = [];

        if (this.state.active_switch_value) {
            cast<ChartFilter[]>(filters).push({
                type: ChartFilterType.SWITCH,
                payload: {
                    active_filter: this.state.active_switch_value,
                },
            });
        }

        const displayLoader = payload.twinkle || !chart
        const content = <Container profiles={[{type: PaddingType.P, value: 4}]}>
            <Container profiles={[
                ContainerProfile.FLEX_WRAP,
                ContainerProfile.SPACE_BETWEEN,
                ContainerProfile.ALIGN_CENTER,
                { type: MarginType.MB, value: 8 }
            ]}>
                <div className="column">
                    {title && (
                        <Typography weight={TypographyWeight.BOLD} variant={TypographyVariant.BODY2}>
                            {this.transApiText(title)}
                        </Typography>
                    )}
                    {sub_title && (
                        <Typography weight={TypographyWeight.REGULAR} variant={TypographyVariant.CAPTION}>
                            {this.transApiText(sub_title)}
                        </Typography>
                    )}
                </div>
                <Container profiles={[
                    ContainerProfile.FLEX_WRAP,
                    ContainerProfile.SPACE_BETWEEN,
                    ContainerProfile.ALIGN_CENTER,
                ]}>
                    {values_switch && this.renderSwitch(values_switch)}
                    {
                        menu &&
                        <div>
                            {this.buildModuleMenu(menu)}
                        </div>
                    }
                </Container>


            </Container>
            { chart ? <ChartComponent
                chart={chart}
                translation={this.transApiText}
                filters={filters}
            /> : <TextLoader />}
        </Container>

        return <Container profiles={[ContainerProfile.BORDER_MODULE]} style={{backgroundColor: "white"}}>
            {displayLoader ? <ModuleLoaderWrapper>{content}</ModuleLoaderWrapper> : content}
        </Container>;
    }
}

export default setupModule(
    SimpleChartWithSwitchModule,
    () => ({}),
    () => ({}),
);
