/*
 * Taken from https://dev.to/sandra_lewis/building-a-multi-range-slider-in-react-from-scratch-4dl1
 */

import React, {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useState,
    useRef
} from "react";
import classnames from "classnames";
import "./MultiRangeSlider.scss";

interface MultiRangeSliderProps {
    min: number;
    max: number;
    onChange: (min: number, max: number) => void;
    defaultMin?: number;
    defaultMax?: number;
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = (
    {
        min,
        max,
        onChange,
        defaultMin = undefined,
        defaultMax = undefined,

    }) => {

    const [minVal, setMinVal] = useState(defaultMin === undefined ? min : defaultMin);
    const [maxVal, setMaxVal] = useState(defaultMax === undefined ? max : defaultMax);

    const minValRef = useRef<HTMLInputElement>(null);
    const maxValRef = useRef<HTMLInputElement>(null);
    const range = useRef<HTMLDivElement>(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value: number) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    return (
        <div>
            <div className="mrs-container">
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={minVal}
                    ref={minValRef}
                    onMouseUp={(): void => onChange(minVal, maxVal)}
                    onTouchEnd={(): void => onChange(minVal, maxVal)}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                        const value = Math.min(+event.target.value, maxVal - 1);
                        setMinVal(value);
                        event.target.value = value.toString();
                    }}
                    className={classnames("mrs-thumb mrs-thumb--zindex-3", {
                        "mrs-thumb--zindex-5": minVal > max - 100
                    })}
                />
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={maxVal}
                    ref={maxValRef}
                    onMouseUp={(): void => onChange(minVal, maxVal)}
                    onTouchEnd={(): void => onChange(minVal, maxVal)}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                        const value = Math.max(+event.target.value, minVal + 1);
                        setMaxVal(value);
                        event.target.value = value.toString();
                    }}
                    className="mrs-thumb mrs-thumb--zindex-4"
                />

                <div className="mrs-slider">
                    <div className="mrs-slider__track"></div>
                    <div ref={range} className="mrs-slider__range"></div>
                    {/*<div className="mrs-slider__left-value">{minVal}</div>*/}
                    {/*<div className="mrs-slider__right-value">{maxVal}</div>*/}
                </div>
            </div>
        </div>

    );
};

export default MultiRangeSlider;
