import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';

import './Link.scss';

export interface LinkProps {
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
    children: ReactNode;
    id?: string;
}

export default class Link extends Component<LinkProps> {
    render(): ReactNode {
        const { children, className, onClick, id } = this.props;

        return (
            <div id={id} className={classNames('link', className)} onClick={onClick}>
                {children}
            </div>
        );
    }
}
