import React, { FC, PropsWithChildren } from "react";
import Loader, { LoaderType } from "components/designSystem/components/Loader";
import { KiroLogo, LogoColor, LogoType } from "components/designSystem/foundations/logos/KiroLogo";

export const ModuleLoaderWrapper: FC<PropsWithChildren<{}>> = ({...props}) => {
    return <Loader payload={{
        type: LoaderType.MODULE,
        icon: <KiroLogo
            type={LogoType.SYMBOL} color={LogoColor.CURRENT_COLOR} small
        />,
    }}>{props.children}</Loader>
}