import { put, takeLatest } from '@redux-saga/core/effects';
import { APICallAction, apiCallSuccessAction } from 'actions/ActionInterface';
import { pushAPIFeedbackMessage } from 'actions/common/APIMessaginActions';
import { redirect } from 'actions/common/CommonActions';
import { API_ACCOUNT_INFO, getAccountInfo } from 'actions/user/AccountActions';
import {
    API_FORGOT_PW,
    API_GET_NAVIGATION_CONTENT,
    API_RESET_KNOWN_PW,
    API_RESET_PW,
    API_UPDATE_DOCTOR_INFO,
    forgotPWInterface,
    GetNavigationAction,
    ResetKnownPWInterface,
    ResetPWInterface,
    UpdateDoctorInfoInterface,
} from 'actions/user/UserActions';
import { TransDomain, TransKey } from 'components/pages/common/MainComponent';
import { ROUTE_DEFAULT_MODULAR } from 'core/routing/Routes';
import { AnyIterator } from 'core/utils/Typed';
import { APIFeedbackMessageType } from 'models/common/APIMessaging';
import { GetNavigationResponse } from "models/user/NavigationModels";
import CallHandler from 'sagas/api/CallHandler';
import { accountInfo, forgotPW, getNavigation, resetKnownPW, updateDoctorInfo, } from 'sagas/api/Endpoints';
import { CustomError, Errors } from 'sagas/api/rest/Exceptions';
import { sagaPost } from "sagas/call/saga";
import { ResetPasswordRequest } from "sagas/endpoints/user";

export const resetKnownPWSaga = function* (action: ResetKnownPWInterface): AnyIterator {
    const response: {} = yield CallHandler.for(resetKnownPW, action)
        .withErrorFeedbackRule(
            (e: CustomError) => !e.is(Errors.RequestError),
            () => {
                return {
                    message: new TransKey('reset_password_error', TransDomain.ACCOUNT),
                };
            },
        )
        .call();
    if (response) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier || null,
                new TransKey('reset_password_successful', TransDomain.ACCOUNT),
                APIFeedbackMessageType.SUCCESS,
            ),
        );
    }
};

export const resetPWSaga = function* (action: ResetPWInterface): AnyIterator {
    const params = action.payload.params;
    if (!params) {return; }

    try {
        const response = yield sagaPost(
            ResetPasswordRequest,
            {
                password: params.password,
                confirmation: params.confirmation,
                token: params.token,
            },
            action,
        )

        if (response) {
            yield put(redirect(ROUTE_DEFAULT_MODULAR));
        }

    } catch (e) {
        yield put(pushAPIFeedbackMessage(
            action?.payload.feedbackIdentifier || null,
            new TransKey('reset_password_error', TransDomain.ACCOUNT),
        ))
    }
};

export const forgotPWSaga = function* (action: forgotPWInterface): AnyIterator {
    const r = yield CallHandler.for(forgotPW, action).call();

    if (r) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier || null,
                new TransKey('reset_password_mail_sent', TransDomain.ACCOUNT),
                APIFeedbackMessageType.SUCCESS,
            ),
        );
    }
};

export const accountInfoSaga = function* (action: APICallAction<{}>): AnyIterator {
    const result = yield CallHandler.safelyCall(accountInfo, action);
    if (result) {
        yield put(apiCallSuccessAction(API_ACCOUNT_INFO, result));
    }
};

export const updateDoctorInfoSaga = function* (action: UpdateDoctorInfoInterface): AnyIterator {
    const response: {} = yield CallHandler.for(updateDoctorInfo, action)
        .withErrorFeedbackRule(
            (e: CustomError) => !e.is(Errors.RequestError),
            () => {
                return {
                    message: new TransKey('update_value_error', TransDomain.ACCOUNT),
                };
            },
        )
        .call();
    if (response) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier || null,
                new TransKey('update_value_successful', TransDomain.ACCOUNT),
                APIFeedbackMessageType.SUCCESS,
            ),
        );
        yield put(getAccountInfo());
    }
};

export const getNavigationContentSaga = function* (action: GetNavigationAction): AnyIterator {
    const response: GetNavigationResponse = yield CallHandler.for(getNavigation).call()

    if (response) {
        yield put(
            apiCallSuccessAction<GetNavigationResponse>(action.type, response)
        )
    }
}

export default function* accountSagas(): AnyIterator {
    yield takeLatest(API_RESET_KNOWN_PW, resetKnownPWSaga);
    yield takeLatest(API_RESET_PW, resetPWSaga);
    yield takeLatest(API_FORGOT_PW, forgotPWSaga);
    yield takeLatest(API_ACCOUNT_INFO, accountInfoSaga);
    yield takeLatest(API_UPDATE_DOCTOR_INFO, updateDoctorInfoSaga);
    yield takeLatest(API_GET_NAVIGATION_CONTENT, getNavigationContentSaga)
}
