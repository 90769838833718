import { modal, ModalType } from "actions/common/CommonActions";
import TrText from "components/common/TrText";
import Button, { ButtonSize, ButtonVariant } from "components/designSystem/components/Button";
import Pill, { PillSeverity } from "components/designSystem/components/Pill";
import WithTooltip, { TooltipWidth } from "components/designSystem/components/WithTooltip";
import Box, { BoxColor } from "components/designSystem/containers/Box";
import FlexGrow from "components/designSystem/containers/FlexGrow";
import GridRow, { GridRowItem } from "components/designSystem/containers/GridRow";
import Hoverable from "components/designSystem/containers/Hoverable";
import {
    AlwaysInline,
    InlineAlignItem,
    InlineJustifyContent,
    InlineSpacing
} from "components/designSystem/containers/Inline";
import { MediumSpacer, SmallSpacer } from "components/designSystem/containers/Spacer";
import TextJustifier, { TextJustification } from "components/designSystem/containers/TextJustifier";
import {
    TBody500,
    TCaption,
    TLabel700,
    TOverline,
    TypographyColor
} from "components/designSystem/containers/Typography";
import WithMargin, { MarginSize } from "components/designSystem/containers/WithMargin";
import Icon from "components/designSystem/foundations/Icons";
import { IconName, IconSize } from "components/designSystem/foundations/IconsData";
import { useModulePayload } from "components/modules/modular/ModuleContainer";
import { TransDomain } from "components/pages/common/MainComponent";
import { APIText } from "models/common/message";
import React from "react";
import { useDispatch } from "react-redux";


export interface SumUpNumericValue {
    value: number
    unit: string
}

export interface SumUpAbnormalValue {
    title: string
    value: SumUpNumericValue
}

export interface SumUpUnsupportedValue {
    title: string
    value: string
}

export interface SumUpModulePayload {
    title_abnormal_values: APIText
    subtitle_abnormal_values: APIText
    title_non_numeric_values: APIText
    abnormal_values: SumUpAbnormalValue[]
    non_numeric_values: SumUpUnsupportedValue[]
}

/**
 * Maximum number of results displayed for abnormal values. A popup is available to display the full list.
 */
const MAX_RESULTS = 4;

const TRANS_SUFFIX = TransDomain.MODULES + '.sum_up';

const AbnormalValue = ({value}: { value: SumUpAbnormalValue }): JSX.Element => <Hoverable>
    <GridRow columnNb={12}>
        <GridRowItem from={1} to={9}>
            <WithMargin margins={[MarginSize.X_SMALL, MarginSize.MEDIUM, MarginSize.X_SMALL, undefined]}>
                <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                    <Pill severity={PillSeverity.CRITICAL_RED}/>
                    <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>{value.title}</TCaption>
                </AlwaysInline>
            </WithMargin>
        </GridRowItem>
        <GridRowItem from={9} to={11}>
            <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
                <WithMargin
                    margins={[MarginSize.X_SMALL, MarginSize.X_SMALL, MarginSize.X_SMALL, MarginSize.MEDIUM]}>
                    <TBody500 color={TypographyColor.COLOR_TEXT_DEFAULT}>{value.value.value}</TBody500>
                </WithMargin>
            </AlwaysInline>
        </GridRowItem>
        <GridRowItem from={11} to={13}>
            <WithMargin
                margins={[MarginSize.X_SMALL, MarginSize.X_SMALL, MarginSize.X_SMALL, undefined]}>
                <TCaption color={TypographyColor.COLOR_TEXT_SUBDUED}>{value.value.unit}</TCaption>
            </WithMargin>
        </GridRowItem>
    </GridRow>
</Hoverable>;

const AbnormalValues = (): JSX.Element => {
    const payload = useModulePayload<SumUpModulePayload>();
    const dispatch = useDispatch();
    const abnormalValues = payload.abnormal_values;
    return <Box background={BoxColor.WHITE} withPadding withBoxShadow withFullHeight withOverflow>
        <SmallSpacer withFullHeight>
            <AlwaysInline justifyContent={InlineJustifyContent.SPACE_BETWEEN} alignItems={InlineAlignItem.CENTER}>
                <TLabel700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    <TrText input={payload.title_abnormal_values}/>
                </TLabel700>
                <Button
                    variant={ButtonVariant.TERTIARY}
                    size={ButtonSize.MEDIUM}
                    disabled={abnormalValues.length == 0}
                    onClick={(): void => {
                        dispatch(modal(
                            ModalType.CENTER,
                            (): JSX.Element => <>
                                {abnormalValues.map((v: SumUpAbnormalValue, i: number): JSX.Element => <AbnormalValue
                                    key={i} value={v}/>)}
                            </>,
                            {title: {default: <TrText input={payload.title_abnormal_values}/>}}
                        ))
                    }}>
                    <TrText input={{
                        trkey: 'see_all_abnormal_values',
                        trdomain: TRANS_SUFFIX,
                        trdata: {'count': abnormalValues.length}
                    }}/>
                </Button>
            </AlwaysInline>
            <FlexGrow>
                {abnormalValues.length > 0 && abnormalValues.slice(0, MAX_RESULTS).map((v: SumUpAbnormalValue, i: number): JSX.Element =>
                    <AbnormalValue key={i} value={v}/>
                )}
            </FlexGrow>
            {abnormalValues.length > 0 ?
                abnormalValues.length > MAX_RESULTS ?
                    <TOverline color={TypographyColor.COLOR_TEXT_SUBDUED}>
                        <TrText input={{trkey: 'anomalies_see_more', trdomain: TRANS_SUFFIX}}/>
                    </TOverline> :
                    <TOverline color={TypographyColor.COLOR_TEXT_SUBDUED}>
                        <TrText input={payload.subtitle_abnormal_values}/>
                    </TOverline> :
                <TOverline color={TypographyColor.COLOR_TEXT_SUBDUED}>
                    <TrText input={{trkey: 'anomalies_placeholder', trdomain: TRANS_SUFFIX}}/>
                </TOverline>
            }
        </SmallSpacer>
    </Box>;
};

const NonNumericValue = ({value}: { value: SumUpUnsupportedValue }): JSX.Element => <Hoverable>
    <GridRow columnNb={12}>
        <GridRowItem from={1} to={8}>
            <WithMargin margins={[MarginSize.X_SMALL, MarginSize.MEDIUM, MarginSize.X_SMALL, undefined]}>
                <AlwaysInline alignItems={InlineAlignItem.CENTER} spacing={InlineSpacing.SMALL}>
                    <Pill severity={PillSeverity.NEUTRAL}/>
                    <TCaption color={TypographyColor.COLOR_TEXT_DEFAULT}>{value.title}</TCaption>
                </AlwaysInline>
            </WithMargin>
        </GridRowItem>
        <GridRowItem from={8} to={13}>
            <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END} alignItems={InlineAlignItem.CENTER}>
                <WithMargin
                    margins={[MarginSize.SMALL, MarginSize.X_SMALL, MarginSize.SMALL, MarginSize.MEDIUM]}>
                    <AlwaysInline justifyContent={InlineJustifyContent.FLEX_END}>
                        <TextJustifier justify={TextJustification.END}>
                            <TBody500 color={TypographyColor.COLOR_TEXT_DEFAULT}>{value.value}</TBody500>
                        </TextJustifier>
                    </AlwaysInline>
                </WithMargin>
            </AlwaysInline>
        </GridRowItem>
    </GridRow>
</Hoverable>;

const NonNumericValues = (): JSX.Element => {
    const payload = useModulePayload<SumUpModulePayload>();
    const dispatch = useDispatch();
    const nonNumericValues = payload.non_numeric_values;
    return <Box background={BoxColor.WHITE} withPadding withBoxShadow withOverflow>
        <AlwaysInline
            alignItems={InlineAlignItem.CENTER}
            spacing={InlineSpacing.XXX_LARGE}
            justifyContent={InlineJustifyContent.SPACE_BETWEEN}
        >
            <AlwaysInline
                spacing={InlineSpacing.SMALL}
                alignItems={InlineAlignItem.CENTER}
                justifyContent={InlineJustifyContent.FLEX_START}
            >
                <TLabel700 color={TypographyColor.COLOR_TEXT_DEFAULT}>
                    <TrText input={payload.title_non_numeric_values}/>
                </TLabel700>
                <WithTooltip
                    width={TooltipWidth.WIDTH_2OREM}
                    tooltip={<TrText input={{trkey: 'non_numeric_values_explanation', trdomain: TRANS_SUFFIX}}/>}
                >
                    <Icon size={IconSize.EXTRA_SMALL} name={IconName.QUESTION_CIRCLE}/>
                </WithTooltip>
            </AlwaysInline>
            <Button
                variant={ButtonVariant.TERTIARY}
                size={ButtonSize.MEDIUM}
                disabled={nonNumericValues.length == 0}
                onClick={(): void => {
                    dispatch(modal(
                        ModalType.CENTER,
                        (): JSX.Element => <>
                            {nonNumericValues.map((v: SumUpUnsupportedValue, i: number): JSX.Element =>
                                <NonNumericValue key={i} value={v}/>
                            )}
                        </>,
                        {title: {default: <TrText input={payload.title_non_numeric_values}/>}}
                    ))
                }}>
                <TrText input={{
                    trkey: 'see_all_non_numeric_values',
                    trdata: {'count': nonNumericValues.length},
                    trdomain: TRANS_SUFFIX
                }}/>
            </Button>
        </AlwaysInline>
    </Box>;
};

/**
 * "Sum Up" module displaying to medical practitioners an overview of the data which was out of the normal ranges as
 * well as the non-numeric data (for which Kiro isn't able to give a status).
 */
export const ReportSumUpModule = (): JSX.Element => <MediumSpacer withFullHeight>
    <AbnormalValues/>
    <NonNumericValues/>
</MediumSpacer>
