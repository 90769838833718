import { ActionDispatcher } from 'actions/ActionInterface';
import { ModalType } from 'actions/common/CommonActions';
import { FEEDBACK_IDENTIFIER_UPDATE_DOCTOR_INFO, updateDoctorInfo } from 'actions/user/UserActions';
import Form from 'components/commonDesign/form/tmpNewSystem/Form';
import { DateItem, PasswordItem, TelItem, TextItem, } from 'components/commonDesign/form/tmpNewSystem/FormItemBuilder';
import { TABLET_MAX_WIDTH_ACCESSIBLE } from "components/core/constants";
import Button, { ButtonSize } from 'components/designSystem/components/Button';
import Divider, { DividerVariant } from "components/designSystem/components/Divider";
import Box, { BoxColor } from 'components/designSystem/containers/Box';
import Inline, { Grow, InlineJustifyContent, InlineSpacing } from 'components/designSystem/containers/Inline';
import { LargeSpacer, MediumSpacer } from "components/designSystem/containers/Spacer";
import Typography, { TypographyColor, TypographyVariant } from 'components/designSystem/containers/Typography';
import ListItemWithDescription from 'components/designSystem/lists/ListItemWithDescription';
import BaseModule, { setupModule } from 'components/modules/modular/BaseModule';
import { AccountLanguageSelector } from 'components/modules/modular/modules/account/AccountLanguageSelector';
import {
    AccountInfoBioProfileSetting,
    BioProfileDropdown
} from "components/modules/modular/modules/account/BioProfileDropdown";
import NotificationToggle from 'components/modules/modular/modules/account/NotificationToggle';
import { SetPin } from 'components/modules/modular/modules/account/SetPin';
import ResetKnownPassword from 'components/modules/user/legacy/ResetKnownPassword';
import { TransDomain } from 'components/pages/common/MainComponent';
import { DatePayload, PasswordPayload, TelPayload, TextPayload } from 'models/common/DynamicFormModels';
import { APIText } from 'models/common/message';
import { ModuleData } from 'models/modular/storage';
import React, { ReactNode } from 'react';
import MediaQuery from "react-responsive";

enum AccountInfoType {
    PERSONAL_DATA = 'PERSONAL_DATA',
    PROFESSIONAL_DATA = 'PROFESSIONAL_DATA',
    SETTINGS_DATA = 'SETTINGS_DATA',
    CONNECTING_DATA = 'CONNECTING_DATA',
}

interface AccountInfoPersonalPayload {
    type: AccountInfoType.PERSONAL_DATA
    first_name?: TextPayload
    last_name?: TextPayload
    birth_date?: DatePayload
}

interface AccountInfoProfessionalPayload {
    type: AccountInfoType.PROFESSIONAL_DATA
    speciality: TextPayload
    business_phone_number?: TelPayload
    rpps?: TextPayload
    business_address: TextPayload
    business_email?: TextPayload
}

interface AccountInfoConnectingPayload {
    type: AccountInfoType.CONNECTING_DATA;
    email: TextPayload;
    password: PasswordPayload;
    pin_code?: PasswordPayload
}

interface AccountInfoSettingsPayload {
    type: AccountInfoType.SETTINGS_DATA;
    notifications_activator?: boolean
    bio_profile?: AccountInfoBioProfileSetting
}

interface AccountInfoModuleProps {
    onSubmitProfessionalInfo: (
        speciality?: string | null,
        businessAddress?: string | null,
        businessPhoneNumber?: string | null,
        businessEmail?: string | null,
    ) => void;
}

interface ProfessionalInfoFormData {
    speciality: string | null;
    business_address: string | null;
    business_phone_number: string | null;
    business_email: string | null;
}

interface AccountInfoPagePayload {
    title: APIText;
    form:
        | AccountInfoConnectingPayload
        | AccountInfoPersonalPayload
        | AccountInfoProfessionalPayload
        | AccountInfoSettingsPayload;
}

class AccountInfoModule extends BaseModule<AccountInfoModuleProps, AccountInfoPagePayload, Record<string, never>> {
    TRANS_SUFFIX = TransDomain.ACCOUNT;

    _render(payload: ModuleData<AccountInfoPagePayload, Record<string, never>>): ReactNode {
        const content = this.buildContent(payload);

        return (
            <Box background={BoxColor.WHITE} withPadding withOverflow withBoxShadow withRadius>
                <LargeSpacer>
                    <Typography variant={TypographyVariant.DISPLAY_X_SMALL} color={TypographyColor.COLOR_TEXT_DEFAULT}>
                        {this.transApiText(payload.pagePayload.title)}
                    </Typography>

                    {content}
                </LargeSpacer>
            </Box>
        );
    }

    private buildAccountInfoPersonalPayload({
                                                first_name,
                                                last_name,
                                                birth_date
                                            }: AccountInfoPersonalPayload): ReactNode {
        /*
        TODO: Add phone number in the empty Grow & update the submit button if editable.
              We don't do update yet because none of the field are editable.
        */

        return (
            <Form
                onSubmit={(): void => {
                    return undefined;
                }}
            >
                {(): JSX.Element => (
                    <>
                        <MediaQuery maxWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
                            {(isMobile): ReactNode =>
                                isMobile ? (
                                    <MediumSpacer>
                                        <Grow>{first_name && <TextItem {...first_name}/>}</Grow>
                                        <Grow>{last_name && <TextItem {...last_name}/>}</Grow>
                                        <Grow>{birth_date && <DateItem {...birth_date}/>}</Grow>
                                    </MediumSpacer>
                                ) : (
                                    <MediumSpacer>
                                        <Inline spacing={InlineSpacing.XX_LARGE}>
                                            <Grow>{first_name && <TextItem {...first_name}/>}</Grow>
                                            <Grow>{last_name && <TextItem {...last_name}/>}</Grow>
                                        </Inline>

                                        <Inline spacing={InlineSpacing.XX_LARGE}>
                                            <Grow>{birth_date && <DateItem {...birth_date}/>}</Grow>
                                            <Grow/>
                                        </Inline>
                                    </MediumSpacer>
                                )
                            }
                        </MediaQuery>
                    </>
                )}
            </Form>
        );
    }


    private buildAccountInfoConnectingPayload({email, pin_code, password}: AccountInfoConnectingPayload): ReactNode {
        return (
            <Form
                onSubmit={(): void => {
                    return undefined;
                }}
            >
                {(): JSX.Element => (
                    <MediaQuery maxWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
                        {(isMobile): JSX.Element =>
                            isMobile ? (
                                <>
                                    <MediumSpacer>
                                        <Grow>{email && <TextItem {...email}/>}</Grow>

                                        <Grow>
                                            {password &&
                                                <PasswordItem
                                                    defaultValue={'..........'}
                                                    onClick={(): void =>
                                                        this.props.modal(
                                                            ModalType.CENTER,
                                                            (): ReactNode => <ResetKnownPassword/>,
                                                            {
                                                                title: {
                                                                    default: this.trans('change_password_long'),
                                                                },
                                                            },
                                                        )}
                                                    {...password}/>}
                                        </Grow>
                                        {pin_code && (
                                            <>
                                                <Grow>
                                                    <PasswordItem
                                                        defaultValue={'....'}
                                                        onClick={(): void =>
                                                            this.props.modal(ModalType.CENTER, (): ReactNode =>
                                                                <SetPin/>, {
                                                                title: {
                                                                    default: this.trans('change_pin'),
                                                                },
                                                            })}
                                                        {...pin_code}
                                                    />
                                                </Grow>
                                            </>
                                        )}
                                    </MediumSpacer>

                                </>
                            ) : (
                                <>
                                    <MediumSpacer>
                                        <Inline spacing={InlineSpacing.XX_LARGE}>
                                            <Grow>{email && <TextItem {...email}/>}</Grow>
                                            <Grow>
                                                {password &&
                                                    <PasswordItem
                                                        defaultValue={'..........'}
                                                        onClick={(): void =>
                                                            this.props.modal(
                                                                ModalType.CENTER,
                                                                (): ReactNode => <ResetKnownPassword/>,
                                                                {
                                                                    title: {
                                                                        default: this.trans('change_password_long'),
                                                                    },
                                                                },
                                                            )}
                                                        {...password}
                                                    />}
                                            </Grow>
                                        </Inline>
                                        {pin_code && (
                                            <Inline spacing={InlineSpacing.XX_LARGE}>
                                                <Grow>
                                                    <PasswordItem
                                                        defaultValue={'....'}
                                                        onClick={(): void =>
                                                            this.props.modal(ModalType.CENTER, (): ReactNode =>
                                                                <SetPin/>, {
                                                                title: {
                                                                    default: this.trans('change_pin'),
                                                                },
                                                            })}
                                                        {...pin_code}
                                                    />
                                                </Grow>
                                                <Grow/>
                                            </Inline>
                                        )}
                                    </MediumSpacer>
                                </>
                            )
                        }
                    </MediaQuery>

                )}
            </Form>
        );
    }

    private buildContent(payload: ModuleData<AccountInfoPagePayload, Record<string, never>>): ReactNode {
        switch (payload.pagePayload.form.type) {
            case AccountInfoType.PERSONAL_DATA:
                return this.buildAccountInfoPersonalPayload(payload.pagePayload.form);
            case AccountInfoType.CONNECTING_DATA:
                return this.buildAccountInfoConnectingPayload(payload.pagePayload.form);
            case AccountInfoType.PROFESSIONAL_DATA:
                return this.buildAccountInfoProfessionalPayload(payload.pagePayload.form);
            case AccountInfoType.SETTINGS_DATA:
                return this.buildAccountInfoSettingsPayload(payload.pagePayload.form);
        }
    }

    private buildAccountInfoProfessionalPayload({
                                                    speciality,
                                                    rpps,
                                                    business_email,
                                                    business_address,
                                                    business_phone_number
                                                }: AccountInfoProfessionalPayload): ReactNode {
        return (
            <Form onSubmit={this.handleFormSubmit}>
                {(formState): JSX.Element => (
                    <MediumSpacer>
                        <MediaQuery maxWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
                            {(isMobile): JSX.Element =>
                                isMobile ? (
                                    <MediumSpacer>
                                        <Grow>{speciality && <TextItem {...speciality}/>}</Grow>
                                        <Grow>{rpps && <TextItem {...rpps}/>}</Grow>
                                        <Grow>
                                            {business_phone_number && <TelItem {...business_phone_number}/>}
                                        </Grow>
                                        <Grow>{business_address && <TextItem {...business_address}/>}</Grow>
                                        <Grow>{business_email && <TextItem {...business_email}/>}</Grow>
                                    </MediumSpacer>
                                ) : (
                                    <MediumSpacer>
                                        <Inline spacing={InlineSpacing.XX_LARGE}>
                                            <Grow>{speciality && <TextItem {...speciality}/>}</Grow>
                                            <Grow>{rpps && <TextItem {...rpps}/>}</Grow>
                                        </Inline>
                                        <Inline spacing={InlineSpacing.XX_LARGE}>
                                            <Grow>
                                                {business_phone_number && <TelItem {...business_phone_number}/>}
                                            </Grow>
                                            <Grow>{business_address && <TextItem {...business_address}/>}</Grow>
                                        </Inline>
                                        <Inline spacing={InlineSpacing.XX_LARGE}>
                                            <Grow>{business_email && <TextItem {...business_email}/>}</Grow>
                                            <Grow/>
                                        </Inline>
                                    </MediumSpacer>
                                )
                            }
                        </MediaQuery>

                        <MediaQuery maxWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
                            {(isMobile): JSX.Element =>
                                isMobile ? (
                                    <Inline justifyContent={InlineJustifyContent.FLEX_START}>
                                        <Button isSubmit disabled={formState.invalid} size={ButtonSize.LARGE}>
                                            {this.trans('save', undefined, TransDomain.ACCOUNT)}
                                        </Button>
                                    </Inline>

                                ) : (
                                    <Inline justifyContent={InlineJustifyContent.FLEX_END}>
                                        <Button isSubmit disabled={formState.invalid} size={ButtonSize.LARGE}>
                                            {this.trans('save', undefined, TransDomain.ACCOUNT)}
                                        </Button>
                                    </Inline>
                                )
                            }
                        </MediaQuery>
                    </MediumSpacer>
                )}
            </Form>
        );
    }

    private handleFormSubmit = ({
                                    speciality,
                                    business_address,
                                    business_phone_number,
                                    business_email,
                                }: ProfessionalInfoFormData): void => {
        this.props.onSubmitProfessionalInfo(speciality, business_address, business_phone_number, business_email);
    };

    private buildNotificationsToggle(enabled: boolean): JSX.Element {
        return <ListItemWithDescription
            title={this.trans('notifications.my')}
            description={this.trans('notifications.daily_alert.description')}
            descriptionColor={TypographyColor.COLOR_TEXT_SUBDUED}
            action={<NotificationToggle notifications_toggle={enabled}/>}
        />
    }

    private buildAccountInfoSettingsPayload({notifications_activator, bio_profile}: AccountInfoSettingsPayload): ReactNode {
        // Note: notifications_activator and profile are mutually exclusive
        return <MediaQuery maxWidth={TABLET_MAX_WIDTH_ACCESSIBLE}>
            {(isMobile): JSX.Element => isMobile ? <MediumSpacer>
                <Grow>
                    <AccountLanguageSelector/>
                </Grow>
                {notifications_activator || bio_profile && <Divider variant={DividerVariant.DEFAULT}/>}
                {notifications_activator && <Grow>{this.buildNotificationsToggle(notifications_activator)}</Grow>}
                {bio_profile && <Grow><BioProfileDropdown profileSetting={bio_profile}/></Grow>}
            </MediumSpacer> : <Inline spacing={InlineSpacing.XX_LARGE}>
                <Grow>
                    <AccountLanguageSelector/>
                </Grow>
                {notifications_activator && <Grow>{this.buildNotificationsToggle(notifications_activator)}</Grow>}
                {bio_profile && <Grow><BioProfileDropdown profileSetting={bio_profile}/></Grow>}
            </Inline>}
        </MediaQuery>;
    }
}


const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): AccountInfoModuleProps => ({
    onSubmitProfessionalInfo: (
        speciality?: string | null,
        businessAddress?: string | null,
        businessPhoneNumber?: string | null,
        businessEmail?: string | null,
    ): void =>
        dispatch(
            updateDoctorInfo(
                FEEDBACK_IDENTIFIER_UPDATE_DOCTOR_INFO,
                speciality,
                businessAddress,
                businessPhoneNumber,
                businessEmail,
            ),
        ),
});

export default setupModule(AccountInfoModule, mapStateToProps, mapDispatchToProps);
