import React from "react";

import { IconProps, IconSize } from "components/designSystem/foundations/IconsData";

export const Blood = (props: IconProps): JSX.Element => {
    if (props.size === IconSize.EXTRA_SMALL) {
        return (
            <svg id={'xs-blood'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M7.05396 0.335829C7.60507 -0.111943 8.39464 -0.111943 8.94574 0.335829C11.6218 2.51017 13.5103 5.96444 13.8801 9.05608C14.0662 10.6116 13.8849 12.24 13.032 13.5693C12.1409 14.9583 10.6616 15.7949 8.7449 15.9668C8.50096 15.9887 8.25257 16 7.99985 16C7.74713 16 7.49874 15.9887 7.2548 15.9668C5.33812 15.7949 3.85881 14.9583 2.96766 13.5693C2.11484 12.24 1.93355 10.6116 2.1196 9.05608C2.48938 5.96444 4.37785 2.51017 7.05396 0.335829ZM7.99985 1.5C3.22863 5.37662 1.26578 13.9237 7.38879 14.4728C7.5882 14.4907 7.79188 14.5 7.99985 14.5C8.20782 14.5 8.4115 14.4907 8.61092 14.4728C14.7339 13.9237 12.7711 5.37662 7.99985 1.5Z"
                      fill="currentColor"/>
            </svg>
        )
    }

    return (
        <svg id={'s-blood'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.7504 2.43826C11.4809 1.85391 12.5188 1.85391 13.2492 2.43826C16.6177 5.13302 18.9815 9.42887 19.4391 13.2849C19.6696 15.2265 19.439 17.2748 18.3514 18.9515C17.2136 20.7058 15.3346 21.7504 12.9263 21.96C12.6228 21.9864 12.314 22 11.9998 22C11.6857 22 11.3768 21.9864 11.0733 21.96C8.66509 21.7504 6.78606 20.7058 5.64821 18.9515C4.56068 17.2748 4.33009 15.2265 4.56051 13.2849C5.01815 9.42887 7.38199 5.13302 10.7504 2.43826ZM11.9998 4C6.05121 8.75889 3.65747 19.307 11.2467 19.9675C11.4926 19.9889 11.7436 20 11.9998 20C12.2561 20 12.5071 19.9889 12.7529 19.9675C20.3422 19.307 17.9484 8.75889 11.9998 4Z"
                  fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.5 14C16.0523 14 16.5 14.4477 16.5 15C16.5 17.2091 14.7091 19 12.5 19C11.9477 19 11.5 18.5523 11.5 18C11.5 17.4477 11.9477 17 12.5 17C13.6046 17 14.5 16.1046 14.5 15C14.5 14.4477 14.9477 14 15.5 14Z"
                  fill="currentColor"/>
        </svg>
    )
}