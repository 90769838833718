export const ROUTE_LOGIN = '/login';
export const ROUTE_PIN = '/pin';
export const ROUTE_AUTH_CPS = '/user/auth/cps';
export const ROUTE_REDIRECT_CPS = '/user/redirect/cps'
export const ROUTE_ACCOUNT = '/account';
export const ROUTE_LEGACY = '/legacy/';
export const ROUTE_LEGACY_ACCOUNT = '/legacy/account';
export const ROUTE_EULA = '/eula';
export const ROUTE_SSO = '/sso';
export const ROUTE_FORCED_CONNECTION = '/user/force_connection/:token/:fingerprint';
export const ROUTE_ACCESS_BY_CONNECTION_MODES = '/access-by-connection-modes'

export const ROUTE_EULA_HEALTH_PROFESSIONAL = '/eula-health-professional/:version?';
export const ROUTE_EULA_DATA_PRIVACY = '/eula-data-privacy/:version?';
export const ROUTE_FAQ = "/faq";
export const ROUTE_TRIGGER_NOTIFICATION = '/user/trigger-notification';

export const ROUTE_LEGAL_NOTICE = '/legal-notice';
export const ROUTE_EULA_PATIENT = '/eula-patient/:version?';
export const ROUTE_USER_FORGOT_PASSWORD = '/user/forgot-password';
export const ROUTE_USER_RESET_PASSWORD = '/user/reset-password/:token';
export const ROUTE_USER_SET_PASSWORD = '/user/set-password/:token';

// Medical reports
export const ROUTE_MEDICAL_REPORT_RANDOM_REPORT = '/medical-report/random-report';
export const ROUTE_MEDICAL_REPORT_REPORT = '/medical-report/report/:uuid';
export const ROUTE_OUTDATED_REPORT_ACCESS_LINK = '/medical-report/outdated-report';

export const ROUTE_MEDICAL_REPORT_PERSON_REPORTS = '/medical-report/user/:personId/reports';
export const ROUTE_MEDICAL_REPORT_ACCESS = '/medical-report/access/:token';
export const ROUTE_MEDICAL_REPORT_CONNECTION_MODES = '/medical-report/get-connection-modes/:token';

export const ROUTE_GET_SHARED_REPORT = '/medical-report/shared-report/:token'

// Partners Routes
export const ROUTE_CUSTOM_UNILABS = '/unilabs';


// Modular
export const ROUTE_MODULAR_PAGE = '/modular/page/:pageId';
export const ROUTE_DEFAULT_MODULAR = '/modular/default';


// Beyond
// /!\ These links cannot be changed without the validation of the regulatory department (used for CE label)
export const ROUTE_NOTICE_DOWNLOAD = '/beyond/notice/download/'
export const ROUTE_TECHNICAL_MANUAL_DOWNLOAD = '/beyond/documents/technical-manual/download'

export function isRouteLegitimatePreviousPath(path: string | null | undefined): boolean {
    if (!path) {
        return false;
    }

    return ![ROUTE_LOGIN, ROUTE_PIN].includes(path);
}
