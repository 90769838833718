import { put, takeLatest } from "@redux-saga/core/effects";
import { apiCallSuccessAction } from "actions/ActionInterface";
import {
    API_GET_ARTICLE_LIST,
    API_SET_PROFILES,
    API_UPDATE_PREGNANT_PROFILE,
    GetArticleListAction,
    SetPatientBioProfilesAction,
    UpdatePregnantProfileAction
} from "actions/bioProfile/BioProfileFetchActions";
import { pushAPIFeedbackMessage } from "actions/common/APIMessaginActions";
import { TransDomain, TransKey } from "components/pages/common/MainComponent";
import { AnyIterator } from "core/utils/Typed";
import { APIFeedbackMessageType } from "models/common/APIMessaging";
import CallHandler from "sagas/api/CallHandler";
import { getArticleList, setBioProfiles, updateBioProfilePregnant } from "sagas/api/Endpoints";

export const setProfiles = function* (action: SetPatientBioProfilesAction): AnyIterator {
    const data = yield CallHandler.safelyCall(setBioProfiles, action);
    if (data) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier || null,
                new TransKey('update_value_successful', TransDomain.ACCOUNT),
                APIFeedbackMessageType.SUCCESS,
            ),
        );
        yield put(apiCallSuccessAction(API_SET_PROFILES, data, action.payload.params));
    }
}

export const updatePregnantProfile = function* (action: UpdatePregnantProfileAction): AnyIterator {
    const data = yield CallHandler.safelyCall(updateBioProfilePregnant, action);
    if (data) {
        yield put(
            pushAPIFeedbackMessage(
                action.payload.feedbackIdentifier || null,
                new TransKey('pregnancy_due_date.date_popup.success', TransDomain.MODULES),
                APIFeedbackMessageType.SUCCESS,
            ),
        );
        yield put(apiCallSuccessAction(API_UPDATE_PREGNANT_PROFILE, data, action.payload.params));
    }
}

export const fetchArticles = function* (action: GetArticleListAction): AnyIterator {
    const data = yield CallHandler.safelyCall(getArticleList, action);
    if (data) {
        yield put(apiCallSuccessAction(API_GET_ARTICLE_LIST, data));
    }
}

export default function* articleSagas(): AnyIterator {
    yield takeLatest(API_SET_PROFILES, setProfiles);
    yield takeLatest(API_UPDATE_PREGNANT_PROFILE, updatePregnantProfile);
    yield takeLatest(API_GET_ARTICLE_LIST, fetchArticles);
}
