import React, { ReactNode } from 'react';
import MainComponent, { MainComponentProps, setupRaw, TransKey } from 'components/pages/common/MainComponent';
import './ErrorOuput.scss';

export interface ErrorOutputProps extends MainComponentProps {
    error: string;
    translation_key?: TransKey;
    type?: string;
}

class ErrorOutput extends MainComponent<ErrorOutputProps> {
    TRANS_SUFFIX = 'form_errors';

    render(): ReactNode {
        let errorKey: string | TransKey = this.props.error;

        if (this.props.error === 'required') {
            errorKey = this.props.error + '_' + (this.props.type || '');
        }

        errorKey = this.props.translation_key ? this.props.translation_key : errorKey;

        return (
            <div className={'form-error'}>
                {this.trans(errorKey)}
            </div>
        )
    }
}

export default setupRaw(ErrorOutput);
