import React, { CSSProperties } from 'react';

import 'components/legacyDesignSystem/components/Icon.scss';
import classNames from 'classnames';
import { AlertIcon } from 'components/legacyDesignSystem/branding/icons/AlertIcon';
import InfoIcon from 'components/legacyDesignSystem/branding/icons/InfoIcon';
import { SuccessIcon } from 'components/legacyDesignSystem/branding/icons/SuccessIcon';
import UserInfoIcon from 'components/legacyDesignSystem/branding/icons/UserInfoIcon';
import DefaultLabLogo from 'components/legacyDesignSystem/branding/icons/DefaultLabLogo';
import SmallArrowDown from 'components/legacyDesignSystem/branding/icons/SmallArrowDown';
import CloseIcon from 'components/legacyDesignSystem/branding/icons/CloseIcon';
import ArrowCircleUp from 'components/legacyDesignSystem/branding/icons/SmallArrowUp';
import NotificationIcon from 'components/legacyDesignSystem/branding/icons/NotificationIcon';
import { Colors, SPACING_UNIT } from 'components/legacyDesignSystem/branding/constants';
import { DashboardIcon } from 'components/legacyDesignSystem/branding/icons/Dashboard';
import { ReportIcon } from 'components/legacyDesignSystem/branding/icons/ReportIcon';
import { SettingsIcon } from 'components/legacyDesignSystem/branding/icons/SettingsIcon';
import ArrowRight from 'components/legacyDesignSystem/branding/icons/ArrowRight';
import ArrowRightDown from 'components/legacyDesignSystem/branding/icons/ArrowRightDown';
import ArrowRightUp from 'components/legacyDesignSystem/branding/icons/ArrowRightUp';
import ChartIcon from 'components/legacyDesignSystem/branding/icons/ChartIcon';
import ExternalIcon from 'components/legacyDesignSystem/branding/icons/ExternalIcon';
import Folder from 'components/legacyDesignSystem/branding/icons/Folder';
import EyeIcon from "components/legacyDesignSystem/branding/icons/EyeIcon";
import LogoSimple from 'components/legacyDesignSystem/branding/icons/LogoSimple';
import SmallArrowRight from 'components/legacyDesignSystem/branding/icons/SmallArrowRight';
import SmallArrowLeft from 'components/legacyDesignSystem/branding/icons/SmallArrowLeft';
import Download from 'components/legacyDesignSystem/branding/icons/Download';
import HPRIM from 'components/legacyDesignSystem/branding/icons/HPRIM';
import BurgerMenu from 'components/legacyDesignSystem/branding/icons/BurgerMenu';
import LogoFull from 'components/legacyDesignSystem/branding/icons/LogoFull';
import LogoCropped from 'components/legacyDesignSystem/branding/icons/LogoCropped';
import Jar from 'components/legacyDesignSystem/branding/icons/Jar';
import Tube from 'components/legacyDesignSystem/branding/icons/Tube';
import Protein from 'components/legacyDesignSystem/branding/icons/Protein';
import Pie from 'components/legacyDesignSystem/branding/icons/Pie';
import Pancreas from 'components/legacyDesignSystem/branding/icons/Pancreas';
import Orange from 'components/legacyDesignSystem/branding/icons/Orange';
import Liver from 'components/legacyDesignSystem/branding/icons/Liver';
import Kidneys from 'components/legacyDesignSystem/branding/icons/Kidneys';
import Iron from 'components/legacyDesignSystem/branding/icons/Iron';
import Inflammation from 'components/legacyDesignSystem/branding/icons/Inflammation';
import Hormones from 'components/legacyDesignSystem/branding/icons/Hormones';
import Heart from 'components/legacyDesignSystem/branding/icons/Heart';
import Foetus from 'components/legacyDesignSystem/branding/icons/Foetus';
import Electros from 'components/legacyDesignSystem/branding/icons/Electros';
import Drugs from 'components/legacyDesignSystem/branding/icons/Drugs';
import Virus from 'components/legacyDesignSystem/branding/icons/Virus';
import Cells from 'components/legacyDesignSystem/branding/icons/Cells';
import Calcium from 'components/legacyDesignSystem/branding/icons/Calcium';
import Cake from 'components/legacyDesignSystem/branding/icons/Cake';
import Burger from 'components/legacyDesignSystem/branding/icons/Burger';
import Bones from 'components/legacyDesignSystem/branding/icons/Bones';
import Blood from 'components/legacyDesignSystem/branding/icons/Blood';
import Bacteria from 'components/legacyDesignSystem/branding/icons/Bacteria';
import Apple from 'components/legacyDesignSystem/branding/icons/Apple';
import Antibodies from 'components/legacyDesignSystem/branding/icons/Antibodies';
import Brain from 'components/legacyDesignSystem/branding/icons/Brain';
import Stomach from 'components/legacyDesignSystem/branding/icons/Stomach';
import Fertility from 'components/legacyDesignSystem/branding/icons/Fertility';
import Prostate from 'components/legacyDesignSystem/branding/icons/Prostate';
import Allergy from 'components/legacyDesignSystem/branding/icons/Allergy';
import FemaleIcon from 'components/legacyDesignSystem/branding/icons/FemaleIcon';
import PeopleIcon from 'components/legacyDesignSystem/branding/icons/PeopleIcon';
import MaleIcon from 'components/legacyDesignSystem/branding/icons/MaleIcon';
import UnknownGenderIcon from 'components/legacyDesignSystem/branding/icons/UnknownGenderIcon';
import PDF from 'components/legacyDesignSystem/branding/icons/PDF';
import Compare from "components/legacyDesignSystem/branding/icons/Compare";
import PlusIcon from "components/legacyDesignSystem/branding/icons/PlusIcon";
import MoreIcon from 'components/legacyDesignSystem/branding/icons/MoreIcon';

export enum Icons {
    ALERT = 'alert',
    SMALL_ARROW_RIGHT = 'small-arrow-right',
    SMALL_ARROW_LEFT = 'small-arrow-left',
    SMALL_ARROW_DOWN = 'small-arrow-down',
    SMALL_ARROW_UP = 'small-arrow-up',
    ARROW_RIGHT = 'arrow-right',
    ARROW_RIGHT_UP = 'arrow-right-up',
    ARROW_RIGHT_DOWN = 'arrow-right-down',
    CHART = 'chart',
    CLOSE = 'close',
    FOLDER = 'FOLDER',
    INFO = 'info',
    SUCCESS = 'success',
    USER_INFO = 'user-info',
    UNKOWN_LAB_LOGO = 'unknown-lab-logo',
    NOTIFICATION = 'notification',
    DASHBOARD = 'dashboard',
    REPORT = 'report',
    SETTINGS = 'settings',
    EXTERNAL = 'external',
    SEE_HIDDEN_TEXT = 'see-hidden-text',
    LOGO_SIMPLE = 'logo-simple',
    LOGO_FULL = 'logo-full',
    LOGO_CROPPED = 'logo-cropped',
    DOWNLOAD = 'DOWNLOAD',
    PDF = 'pdf',
    HPRIM = 'hprim',
    BURGER_MENU = 'burger_menu',
    COMPARE = 'compare',
    PLUS = 'plus',

    // Categories
    ALLERGY = 'ALLERGY',
    ANTIBODIES = 'ANTIBODIES',
    APPLE = 'APPLE',
    BACTERIA = 'BACTERIA',
    BLOOD = 'BLOOD',
    BONES = 'BONES',
    BRAIN = 'BRAIN',
    BURGER = 'BURGER',
    CAKE = 'CAKE',
    CALCIUM = 'CALCIUM',
    CELLS = 'CELLS',
    DRUGS = 'DRUGS',
    ELECTROS = 'ELECTROS',
    FERTILITY = 'FERTILITY',
    FOETUS = 'FOETUS',
    HEART = 'HEART',
    HORMONES = 'HORMONES',
    INFLAMMATION = 'INFLAMMATION',
    IRON = 'IRON',
    JAR = 'JAR',
    KIDNEYS = 'KIDNEYS',
    LIVER = 'LIVER',
    ORANGE = 'ORANGE',
    PANCREAS = 'PANCREAS',
    PIE = 'PIE',
    PROSTATE = 'PROSTATE',
    PROTEIN = 'PROTEIN',
    STOMACH = 'STOMACH',
    TUBE = 'TUBE',
    VIRUS = 'VIRUS',

    FEMALE = 'FEMALE',
    MALE = 'MALE',
    PEOPLE = 'PEOPLE',
    UNKNOWN_GENDER = 'UNKNOWN_GENDER',
    MORE = 'more'
}

export interface IconProps {
    name?: Icons;
    imgSrc?: string;
    alt?: string;
    circle?: boolean;
    encircled?: boolean,
    withoutPadding?: boolean,
    className?: string;
    onClick?: () => void;
    color?: Colors;
    width?: number;
    height?: number;
    defaultIcon?: Icons;
    withBackground?: boolean;
    centered?: boolean;
    inText?: boolean
}

const getIcon = (name: Icons): JSX.Element => {
    if (name === Icons.ALERT) {
        return <AlertIcon/>
    } else if (name === Icons.SMALL_ARROW_RIGHT) {
        return <SmallArrowRight/>
    } else if (name === Icons.SMALL_ARROW_LEFT) {
        return <SmallArrowLeft/>
    } else if (name === Icons.SMALL_ARROW_DOWN) {
        return <SmallArrowDown/>
    } else if (name === Icons.SMALL_ARROW_UP) {
        return <ArrowCircleUp/>
    } else if (name === Icons.ARROW_RIGHT) {
        return <ArrowRight/>
    } else if (name === Icons.ARROW_RIGHT_UP) {
        return <ArrowRightUp/>
    } else if (name === Icons.ARROW_RIGHT_DOWN) {
        return <ArrowRightDown/>
    } else if (name === Icons.CHART) {
        return <ChartIcon/>
    } else if (name === Icons.CLOSE) {
        return <CloseIcon />
    } else if (name === Icons.FOLDER) {
        return <Folder />
    } else if (name === Icons.INFO) {
        return <InfoIcon />
    } else if (name === Icons.SUCCESS) {
        return <SuccessIcon />
    }  else if (name === Icons.NOTIFICATION) {
        return <NotificationIcon />
    } else if (name === Icons.USER_INFO) {
        return <UserInfoIcon />
    } else if (name === Icons.UNKOWN_LAB_LOGO) {
        return <DefaultLabLogo />
    } else if (name === Icons.DASHBOARD) {
        return <DashboardIcon />
    } else if (name === Icons.REPORT) {
        return <ReportIcon />
    } else if (name === Icons.SETTINGS) {
        return <SettingsIcon />
    } else if (name === Icons.EXTERNAL) {
        return <ExternalIcon />
    } else if (name === Icons.SEE_HIDDEN_TEXT){
        return <EyeIcon />
    }  else if (name === Icons.LOGO_SIMPLE) {
        return <LogoSimple />
    } else if (name === Icons.LOGO_FULL) {
        return <LogoFull />
    } else if (name === Icons.LOGO_CROPPED) {
        return <LogoCropped />
    } else if (name === Icons.DOWNLOAD) {
        return <Download />
    } else if (name === Icons.PDF){
        return <PDF />
    } else if (name === Icons.HPRIM){
        return <HPRIM />
    } else if (name === Icons.BURGER_MENU) {
        return <BurgerMenu />
    } else if (name === Icons.ALLERGY) {
        return <Allergy />
    } else if (name === Icons.ANTIBODIES) {
        return <Antibodies />
    } else if (name === Icons.APPLE) {
        return <Apple />
    } else if (name === Icons.BACTERIA) {
        return <Bacteria />
    } else if (name === Icons.BLOOD) {
        return <Blood />
    } else if (name === Icons.BONES) {
        return <Bones />
    } else if (name === Icons.BRAIN) {
        return <Brain />
    } else if (name === Icons.BURGER) {
        return <Burger />
    } else if (name === Icons.CAKE) {
        return <Cake />
    } else if (name === Icons.CALCIUM) {
        return <Calcium />
    } else if (name === Icons.CELLS) {
        return <Cells />
    } else if (name === Icons.DRUGS) {
        return <Drugs />
    } else if (name === Icons.ELECTROS) {
        return <Electros />
    } else if (name === Icons.FERTILITY) {
        return <Fertility />
    } else if (name === Icons.FOETUS) {
        return <Foetus />
    } else if (name === Icons.HEART) {
        return <Heart />
    } else if (name === Icons.HORMONES) {
        return <Hormones />
    } else if (name === Icons.INFLAMMATION) {
        return <Inflammation />
    } else if (name === Icons.IRON) {
        return <Iron />
    } else if (name === Icons.JAR) {
        return <Jar />
    } else if (name === Icons.KIDNEYS) {
        return <Kidneys />
    } else if (name === Icons.LIVER) {
        return <Liver />
    } else if (name === Icons.ORANGE) {
        return <Orange />
    } else if (name === Icons.PANCREAS) {
        return <Pancreas />
    } else if (name === Icons.PIE) {
        return <Pie />
    } else if (name === Icons.PROSTATE) {
        return <Prostate />
    } else if (name === Icons.PROTEIN) {
        return <Protein />
    } else if (name === Icons.STOMACH) {
        return <Stomach />
    } else if (name === Icons.TUBE) {
        return <Tube />
    } else if (name === Icons.VIRUS) {
        return <Virus />
    } else if (name === Icons.FEMALE) {
        return <FemaleIcon />
    } else if (name === Icons.MALE) {
        return <MaleIcon />
    } else if (name === Icons.PEOPLE) {
        return <PeopleIcon />
    } else if (name === Icons.UNKNOWN_GENDER) {
        return <UnknownGenderIcon />
    } else if (name === Icons.COMPARE) {
        return <Compare />
    } else if (name === Icons.PLUS) {
        return <PlusIcon />
    } else if (name === Icons.MORE) {
        return <MoreIcon />
    }

    return <div />
}

export const Icon = (props: IconProps): JSX.Element => {
    if (!props.name && !props.imgSrc) {
        return <div />
    }

    const style: CSSProperties = {}
    if (props.width) {
        style.width = `${props.width * SPACING_UNIT}px`
        style.minWidth = `${props.width * SPACING_UNIT}px`
    }
    if (props.height) {
        style.height = `${props.height * SPACING_UNIT}px`
        style.minHeight = `${props.height * SPACING_UNIT}px`
    }
    const CustomTag = `${props.inText ? "span" : "div"}` as keyof JSX.IntrinsicElements;
    return (
        <CustomTag
            id='icon'
            className={classNames(
                'icon',
                {
                    'icon--in-text': props.inText,
                    'icon--as-div': !props.inText,
                    'circle-icon': !!props.circle,
                    'icon--with-padding': !props.withoutPadding || props.encircled,
                    'icon--encircled': props.encircled,
                    'icon--with-background': props.withBackground,
                    'icon--centered': props.centered,
                },
                props.className,
                !!props.onClick && 'clickable',
                props.color,
            )}
            style={style}
            onClick={props.onClick}
        >{props.name
            ? getIcon(props.name)
            : props.imgSrc
                ? <img src={props.imgSrc} alt={props.alt ? props.alt : 'img'} />
                : getIcon(props.defaultIcon ? props.defaultIcon : Icons.UNKOWN_LAB_LOGO)
        }
        </CustomTag>
    )
}