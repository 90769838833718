import { ActionDispatcher } from "actions/ActionInterface";
import { modal, ModalContentBuilder, ModalType, NullableModelExtra } from "actions/common/CommonActions";
import TrText from 'components/common/TrText';
import { PopUpStyle } from "components/core/containers/PopUp";
import CommunicationBox from "components/designSystem/components/CommunicationBox";
import Image from "components/designSystem/components/Image";
import BaseModule, { setupModule } from 'components/modules/modular/BaseModule';
import { buildClickModuleAction } from "components/modules/modular/modules/builders/userAction";
import TeleconsultationSelector from "components/modules/modular/modules/teleconsultation/TeleconsultationSelector";
import { TransDomain } from "components/pages/common/MainComponent";
import { getApolloResource } from "core/content/cdn";
import LOGGER from "core/logging/Logger";
import { capitalizeFirstLetter } from "core/utils/text";
import { APIText } from 'models/common/message';
import { ModuleData } from 'models/modular/storage';
import { TeleconsultationEntity } from "models/modular/teleconsultation";
import React, { ReactNode } from 'react';


interface TeleconsultationPayload {
    title: APIText
    description: APIText
    action_name: APIText
    entities: TeleconsultationEntity[]
}

interface TeleconsultationDispatchProps {
    popup: (content: ModalContentBuilder, style: PopUpStyle | undefined, title: string | null) => void;
}


class TeleconsultationModule extends BaseModule<TeleconsultationDispatchProps, TeleconsultationPayload, Record<string, never>> {
    TRANS_SUFFIX = TransDomain.MODULES + '.teleconsultation'

    openHealthcareProvidersSelectorMenu(entities: TeleconsultationEntity[]): void {
        const initialTitle = this.trans('healthcare_partners', undefined, undefined, capitalizeFirstLetter)
        this.props.popup(
            (close: () => void, updateExtra: (extra: NullableModelExtra) => void) => (
                <TeleconsultationSelector
                    entities={entities}
                    onSelectEntity={() : void => updateExtra({
                        title: null, withoutCloseButton: true
                    })}
                    onDeSelectEntity={() : void => updateExtra({
                        title: {default: initialTitle}, withoutCloseButton: false
                    })}
                    onOpenEntity={() : void => close()}
                />
            ),
            undefined,
            initialTitle
        )
    }

    buildContent(payload: TeleconsultationPayload, inSideBar: boolean): ReactNode {
        const imageInfo = getApolloResource('communication_box_teleconsultation.png')
        return (
            <CommunicationBox
                title={<TrText input={payload.title}/>}
                description={<TrText input={payload.description}/>}
                callToAction={{
                    actionName: <TrText input={payload.action_name}/>,
                    onClick: (): void => {
                        if (this.props.pageId && this.props.type){
                            LOGGER.userAction(
                                buildClickModuleAction(
                                    this.props.pageId,
                                    this.props.type,
                                    inSideBar ? 'in_side_bar' : undefined
                                )
                            )
                        }
                        this.openHealthcareProvidersSelectorMenu(payload.entities)
                    }
                }}
                fullWidthCTA={inSideBar}
                withTag={this.trans('tag')}
                outsideAssets={<Image src={imageInfo.src} alt={imageInfo.alt}/>}
            />
        )
    }

    _render(payload: ModuleData<TeleconsultationPayload, Record<string, never>>): ReactNode {
        return this.buildContent(payload.pagePayload, false)
    }

    _renderSideBar(payload: ModuleData<TeleconsultationPayload, Record<string, never>>): ReactNode {
        return this.buildContent(payload.pagePayload, true)
    }
}

const mapStateToProps = (): Record<string, never> => ({});

const mapDispatchToProps = (dispatch: ActionDispatcher): TeleconsultationDispatchProps => ({
    popup: (content: ModalContentBuilder, style: PopUpStyle | undefined, title: string | null): void =>
        dispatch(modal(
            ModalType.CENTER,
            content,
            {
                title: title ? {default: title} : undefined
            },
        )),
})

export default setupModule(TeleconsultationModule, mapStateToProps, mapDispatchToProps);
